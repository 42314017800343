import { takeLatest, call, put, select } from 'redux-saga/effects';

import {
  setSearchResultAction,
  setSearchResultSuccessAction,
  setSearchResultFailAction,
} from 'components/_common/SearchPanel/_redux/searchActions';
import { searchSelector } from 'components/_common/SearchPanel/_redux/searchSelectors';
import { MAX_SEARCH_RESULT } from 'components/_common/SearchPanel/constants';
import {
  CREATE_CATEGORY,
  GET_CATEGORY,
  UPDATE_CATEGORY,
  GET_CATEGORIES,
  SEARCH_CATEGORY,
  REMOVE_CATEGORY,
  GET_ALL_CATEGORIES,
} from 'components/Inventory/TabCategories/_redux/categoriesTypes';
import api from 'utils/requests';

import { setPaginationAction } from '../../../_common/Navigation/Pagination/_redux/paginationActions';
import { paginationSelector } from '../../../_common/Navigation/Pagination/_redux/paginationSelectors';
import {
  createCategoryAction,
  createCategoryActionFail,
  getCategoryAction,
  getCategoryActionFail,
  getCategoryActionSuccess,
  updateCategoryActionFail,
  updateCategoryAction,
  createCategoryActionSuccess,
  updateCategoryActionSuccess,
  getCategoriesActionSuccess,
  getCategoriesAction,
  getCategoriesActionFail,
  removeCategorySuccessAction,
  removeCategoryFailAction,
  removeCategoryAction,
  getAllCategoriesActionSuccess,
} from './categoriesActions';
import { transformCategoriesSearchResult } from './helpers';

// CREATE_CATEGORY
function* createCategorySaga({
  payload: { data, callback },
}: ReturnType<typeof createCategoryAction>) {
  const url = '/v1/inventories/categories';
  try {
    const {
      data: { categories },
    } = yield call(() => api.post(url, data));

    yield put(createCategoryActionSuccess(categories));
    if (callback) callback(categories);
  } catch (e) {
    if (api.isAxiosError(e)) yield put(createCategoryActionFail(e));
  }
}

function* removeCategorySaga({
  payload: { id, callback },
}: ReturnType<typeof removeCategoryAction>) {
  const url = `/v1/inventories/categories/${id}`;
  try {
    yield call(() => api.delete(url, {}));
    yield put(removeCategorySuccessAction({ id }));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(removeCategoryFailAction(e));
  }
}

// UPDATE_CATEGORY
function* updateCategorySaga({
  payload: {
    data: { id, ...rest },
    callback,
  },
}: ReturnType<typeof updateCategoryAction>) {
  const url = `/v1/inventories/categories/${id}`;
  try {
    const {
      data: { categories },
    } = yield call(() => api.patch(url, { inventories_category: rest }));
    yield put(updateCategoryActionSuccess(categories));

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(updateCategoryActionFail(e));
  }
}

// GET_CATEGORIES
function* getCategoriesSaga({
  payload: {
    data: { query = '' },
    config,
    callback,
  },
}: ReturnType<typeof getCategoriesAction>) {
  const { per_page, page } = yield select(paginationSelector);
  const url = `/v1/inventories/categories?page=${page}&per_page=${per_page}${query}`;
  const usePaginationFlag =
    config && typeof config.usePaginationFlag === 'boolean'
      ? config.usePaginationFlag
      : true;

  try {
    const {
      data: { categories, pagination },
    } = yield call(() => api.get(url));
    yield put(
      getCategoriesActionSuccess({
        categories,
        pagination: usePaginationFlag ? pagination : null,
      }),
    );
    if (usePaginationFlag) {
      yield put(setPaginationAction(pagination));
    }
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(getCategoriesActionFail(e));
  }
}

// GET_CATEGORIES
function* getAllCategoriesSaga() {
  const url = `/v1/inventories/categories?per_page=10000`;

  try {
    const {
      data: { categories },
    } = yield call(() => api.get(url));
    yield put(
      getAllCategoriesActionSuccess({
        categories,
      }),
    );
  } catch (e) {
    if (api.isAxiosError(e)) yield put(getCategoriesActionFail(e));
  }
}

// GET_CATEGORY
function* getCategorySaga({
  payload: {
    data: { id },
    callback,
  },
}: ReturnType<typeof getCategoryAction>) {
  const url = `/v1/inventories/categories/${id}`;
  try {
    const { data } = yield call(() => api.get(url));
    yield put(getCategoryActionSuccess(data.categories));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(getCategoryActionFail(e));
  }
}

// SEARCH CATEGORY
function* searchCategorySaga() {
  yield put(setSearchResultAction([]));
  let { searchValue = '' } = yield select(searchSelector);
  searchValue = searchValue.trim();

  if (searchValue) {
    try {
      const url = `/v1/inventories/categories?page=1&per_page=${MAX_SEARCH_RESULT}&search=${searchValue}`;
      const { data } = yield call(() => api.get(url));
      yield put(
        setSearchResultSuccessAction(
          transformCategoriesSearchResult(data.categories),
        ),
      );
    } catch (e) {
      if (api.isAxiosError(e)) yield put(setSearchResultFailAction(e));
    }
  }
}

export default function* categoriesSaga() {
  yield takeLatest(CREATE_CATEGORY, createCategorySaga);
  yield takeLatest(UPDATE_CATEGORY, updateCategorySaga);
  yield takeLatest(GET_CATEGORIES, getCategoriesSaga);
  yield takeLatest(GET_ALL_CATEGORIES, getAllCategoriesSaga);
  yield takeLatest(GET_CATEGORY, getCategorySaga);
  yield takeLatest(SEARCH_CATEGORY, searchCategorySaga);
  yield takeLatest(REMOVE_CATEGORY, removeCategorySaga);
}
