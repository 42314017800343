import { FC, MouseEvent } from 'react';

type Props = {
  className?: string;
  fillColor?: string;
  onClick?: (event: MouseEvent) => void;
};

const IconBasket: FC<Props> = ({ className, fillColor = 'white', onClick }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M15 5C15.5523 5 16 4.55228 16 4C16 3.44772 15.5523 3 15 3V5ZM1 3C0.447715 3 0 3.44772 0 4C0 4.55228 0.447715 5 1 5V3ZM6 0C5.44772 0 5 0.447715 5 1C5 1.55228 5.44772 2 6 2V0ZM10 2C10.5523 2 11 1.55228 11 1C11 0.447715 10.5523 0 10 0V2ZM2 4V13H4V4H2ZM5 16H11V14H5V16ZM14 13V4H12V13H14ZM13 3H3V5H13V3ZM13 5H15V3H13V5ZM3 3H1V5H3V3ZM11 16C12.6569 16 14 14.6569 14 13H12C12 13.5523 11.5523 14 11 14V16ZM2 13C2 14.6569 3.34315 16 5 16V14C4.44772 14 4 13.5523 4 13H2ZM6 2H10V0H6V2Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconBasket;
