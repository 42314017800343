import { FC } from 'react';

import { useSelector } from 'react-redux';

import { userThemeSelector } from 'redux/userSettings/userSettingsSelectors';

interface IOwnProps {
  className?: string;
}

const IconFilterAlt: FC<IOwnProps> = ({ className }) => {
  const theme = useSelector(userThemeSelector);

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9 8L15 8M9 8C9 9.10457 8.10457 10 7 10C5.89543 10 5 9.10457 5 8M9 8C9 6.89543 8.10457 6 7 6C5.89543 6 5 6.89543 5 8M1 8H5M1 16H11M11 16C11 17.1046 11.8954 18 13 18C14.1046 18 15 17.1046 15 16C15 14.8954 14.1046 14 13 14C11.8954 14 11 14.8954 11 16Z"
        stroke={theme === 'dark' ? '#fff' : '#2D2F36'}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="14"
        cy="5"
        r="4"
        fill="#FF4A3C"
        stroke="var(--whiteBtnHoverBg)"
        strokeWidth="2"
      />
    </svg>
  );
};

export default IconFilterAlt;
