import { Suspense, lazy, useEffect, useState } from 'react';

import { Skeleton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';

// Routes
import { PrivacyPolicy } from 'components/Auth/PrivacyPolicyPopUp/PrivacyPolicyPopUp';
import { RolePermissionResource } from 'redux/_common/models';

import { authSelector } from '../components/Auth/_redux/authSelectors';
import { setCurrentPermissionsAction } from '../redux/userSettings/userSettingsActions';
import { userSettingsSelector } from '../redux/userSettings/userSettingsSelectors';
import {
  mainAccountingRoute,
  weeklyPayrollsMainRoute,
  driversPayrollsMainRoute,
  driversPayrollsRoute,
  accountingPayrollsRoute,
  accountingPayrollRoute,
  accountingDeductionsRoute,
  accountingCreditsRoute,
  accountingFuelRoute,
  accountingFuelDriverRoute,
  accountingBillingRoute,
  accountingReportDriversRoute,
  accountingReportDriverItemsRoute,
  accountingReportCompanyRoute,
  accountingReportCompanyGridRoute,
  accountingDeductionsCalendarRoute,
  accountingDeductionProfileRoute,
} from './ModuleRoutes/AccountingRoutes';
import { chatRoute } from './ModuleRoutes/ChatRoutes';
import {
  mainDispatchRoute,
  mainOrdersRoute,
  orderCreateRoute,
  orderProfileRoute,
  editOrderRoute,
  mainBrokersRoute,
  brokerCreateRoute,
  brokerProfileRoute,
  editBrokerRoute,
  mainFacilitiesRoute,
  facilityCreateRoute,
  facilityProfileRoute,
  editFacilityRoute,
  mainCalendarRoute,
  publicLiveSharingRoute,
  mainOverviewRoute,
  tabOverviewRoute,
} from './ModuleRoutes/DispatchRoutes';
import {
  mainFleetRoute,
  mainClaimsRoute,
  claimsCreateRoute,
  mainGeofencesRoute,
  geofencesAlertsRoute,
  mainTollsRoute,
  tollProfileRoute,
  tollsStatisticsRoute,
  mainTrucksRoute,
  truckImportRoute,
  truckCreateRoute,
  truckProfileRoute,
  truckEditRoute,
  truckInspectionEditRoute,
  truckReviewCreateRoute,
  truckReviewEditRoute,
  truckMaintenancesRoute,
  mainVehiclesRoute,
  mainInspectionsRoute,
  mainRequestsRoute,
  mainTrailersRoute,
  trailerCreateRoute,
  trailerInspectionEditRoute,
  trailerProfileRoute,
  trailerEditRoute,
  dashboardRoute,
  settingsRoute,
  publicInspectionRoute,
  truckInspectionProfileRoute,
  trailerInspectionProfileRoute,
  settingsEditRoute,
  tandemInspectionUpdateRoute,
  claimsProfileRoute,
  subscriptionPlansRoute,
  trailerImportRoute,
  workOrderRoute,
  addWorkOrderRoute,
  editWorkOrderRoute,
  addBulkOrdersRoute,
} from './ModuleRoutes/FleetRoutes';
import {
  mainHRRoute,
  mainDriversRoute,
  driverCreateRoute,
  driverEditRoute,
  driverProfileRoute,
  applicantProfileRoute,
  applicantEditRoute,
  mainLeadsRoute,
  leadProfileRoute,
  mainEmployeesRoute,
  employeeCreateRoute,
  employeeProfileRoute,
  employeeEditRoute,
  mainArchiveRoute,
  mainUpcomingDriversRoute,
  upcomingDriverRoute,
  employeeImportRoute,
  driverImportRoute,
  createLeadRoute,
  editLeadRoute,
  upcomingDriverContractRoute,
  editUpcomingDriverContractRoute,
  analyticsRoute,
  driverContractRoute,
  editDriverContractRoute,
} from './ModuleRoutes/HumanResourcesRoutes';
import {
  mainInternalCompaniesRoute,
  addCompaniesRoute,
  editCompaniesRoute,
  mainAdminPanelRoute,
  mainRolesAndPermissionsRoute,
  roleSettingsRoute,
  permissionFormRoute,
  editSinglePermissionFormRoute,
} from './ModuleRoutes/InternalCompaniesRoutes';
import {
  mainLocationsRoute,
  locationCreateRoute,
  locationProfileRoute,
  locationEditRoute,
  mainCategoriesRoute,
  mainItemsRoute,
  itemProfileRoute,
  mainVendorsRoute,
  vendorCreateRoute,
  vendorEditRoute,
  vendorProfileRoute,
} from './ModuleRoutes/InventoryRoutes';
import {
  mainSafetyRoute,
  accidentCreateRoute,
  accidentProfileRoute,
  accidentsRoute,
  accidentUpdateRoute,
} from './ModuleRoutes/SafetyRoutes';
import {
  mainSettingsRoute,
  accountingSettingsRoute,
  gpsIntegrationsSettingsRoute,
  gpsIntegrationsSettingsEditRoute,
  orientationProcessSettingsRoute,
  contractTemplateRoute,
  createContractTemplateRoute,
  editContractTemplateRoute,
  // createOrientationProcessStepRoute,
  checklistSettingsRoute,
  updateStepRoute,
  createStepRoute,
  operationListSettingsRoute,
  createOperationRoute,
  editOperationRoute,
  scriptsRoute,
  createScriptRoute,
  editScriptRoute,
  createEmployeeContract,
  workshopRoute,
} from './ModuleRoutes/SettingsRoutes';
import {
  mainTaskManagementRoute,
  tasksRoute,
} from './ModuleRoutes/TaskManagementRoutes';
import PrivateRoutes from './PrivateRoutes/PrivateRoutes';
import PublicRoutes from './PublicRoutes/PublicRoutes';

import styles from './routes.module.scss';

const Login = lazy(() => import('components/Auth/Login/Login'));

const SignUp = lazy(() => import('components/Auth/Login/SignUp'));

const SignUpFullCompany = lazy(
  () => import('components/Auth/Login/SignUpFullCompany'),
);
const CompanyRegistration = lazy(
  () => import('components/Auth/CompanyRegistration/CompanyRegistration'),
);
const NotFound = lazy(() => import('components/NotFound/NotFound'));
const ResetEmail = lazy(() => import('components/Auth/ResetEmail/ResetEmail'));
const ResetPassword = lazy(
  () => import('components/Auth/ResetPassword/ResetPassword'),
);
const ChangePassword = lazy(
  () => import('components/Auth/ChangePassword/ChangePassword'),
);
const ForgotPassword = lazy(
  () => import('components/Auth/ForgotPassword/ForgotPassword'),
);
const Plans = lazy(
  () =>
    import(
      'components/WiseCheck/TabSettings/SettingsProfile/SubscriptionPlans/SubscriptionPlans'
    ),
);

export interface IPermissionObject {
  name: string;
  permissions: string[];
}

const MainRoutes = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector(authSelector);
  const { hr_roles_features } = useSelector(userSettingsSelector);
  const [permissionsArray, setPermissionsArray] =
    useState<IPermissionObject[]>();

  useEffect(() => {
    const constructPermissionObjects = (
      pA: string[],
      permissionsObject: { [key: string]: RolePermissionResource } | null,
    ) => {
      const permissionObjects: IPermissionObject[] = [];

      pA.forEach((permission: string) => {
        // Iterate through each feature
        // eslint-disable-next-line no-restricted-syntax
        for (const feature in permissionsObject) {
          if (
            Object.prototype.hasOwnProperty.call(permissionsObject, feature)
          ) {
            const featureData = permissionsObject[feature];
            // Check if permission exists in the feature
            const permissionFound = featureData.features.some(fea =>
              fea.permissions.some(({ id }) => id === permission),
            );
            if (permissionFound) {
              // Add permission to the corresponding feature
              const existingPermissionObject = permissionObjects.find(
                obj => obj.name === featureData.name,
              );
              if (existingPermissionObject) {
                existingPermissionObject.permissions.push(permission);
              } else {
                permissionObjects.push({
                  name: featureData.name,
                  permissions: [permission],
                });
              }
              break; // Move to the next permission
            }
          }
        }
      });

      return permissionObjects;
    };

    const permissionObjects = constructPermissionObjects(
      Object.keys(currentUser.hr_role_permissions),
      hr_roles_features,
    );

    setPermissionsArray(permissionObjects);
  }, [hr_roles_features, currentUser.hr_role_permissions]);

  useEffect(() => {
    if (permissionsArray) {
      dispatch(setCurrentPermissionsAction([...permissionsArray]));
    }
  }, [permissionsArray, dispatch]);

  const sidebarWidthPx = 233; // TODO: WTF?!

  return (
    <Suspense
      fallback={
        <div className={styles.root}>
          <Skeleton
            variant="rectangular"
            width={sidebarWidthPx}
            height={window.innerHeight}
            className={styles.sidebar}
          />
          <div className={styles.circles}>
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton variant="circular" width={20} height={20} />
          </div>
        </div>
      }
    >
      {
        <Switch>
          {/* Public Inspection */}
          {publicInspectionRoute}
          {/* Live sharing */}
          {publicLiveSharingRoute}
          {/* Generic */}
          <PublicRoutes exact path="/login" component={Login} />
          <PublicRoutes exact path="/signup" component={SignUp} />
          <PublicRoutes
            exact
            path="/employees/password"
            component={CompanyRegistration}
          />
          <PublicRoutes
            exact
            path="/employee/password/edit"
            component={ResetPassword}
          />
          <PublicRoutes exact path="/admin/email" component={ResetEmail} />
          <PublicRoutes
            exact
            path="/forgot-password"
            component={ForgotPassword}
          />
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <PublicRoutes exact path="/terms" component={PrivacyPolicy as any} />
          <PublicRoutes exact path="/plans" component={Plans} />
          <PrivateRoutes
            exact
            path="/reg"
            component={SignUpFullCompany}
            withPermission={false}
          />
          <PrivateRoutes
            exact
            path="/change-password"
            component={ChangePassword}
            withPermission={false}
          />
          {/* ***** Modules ***** */}
          {/* Fleet */}
          {mainFleetRoute(permissionsArray)}
          {dashboardRoute}
          {settingsRoute}
          {mainClaimsRoute}
          {claimsCreateRoute}
          {claimsProfileRoute}
          {mainGeofencesRoute}
          {truckInspectionProfileRoute}
          {trailerInspectionProfileRoute}
          {geofencesAlertsRoute}
          {mainTollsRoute}
          {tollProfileRoute}
          {tollsStatisticsRoute}
          {mainTrucksRoute}
          {truckImportRoute}
          {trailerImportRoute}
          {truckCreateRoute}
          {truckProfileRoute}
          {truckEditRoute}
          {settingsEditRoute}
          {subscriptionPlansRoute}
          {truckInspectionEditRoute}
          {truckReviewCreateRoute}
          {truckReviewEditRoute}
          {truckMaintenancesRoute}
          {mainVehiclesRoute}
          {mainInspectionsRoute}
          {mainRequestsRoute}
          {mainTrailersRoute}
          {trailerCreateRoute}
          {trailerProfileRoute}
          {trailerEditRoute}
          {trailerInspectionEditRoute}
          {/* Fleet: Inventory */}
          {mainLocationsRoute}
          {locationCreateRoute}
          {locationProfileRoute}
          {locationEditRoute}
          {mainCategoriesRoute}
          {mainItemsRoute}
          {itemProfileRoute}
          {mainVendorsRoute}
          {vendorCreateRoute}
          {vendorEditRoute}
          {vendorProfileRoute}
          {workOrderRoute}
          {addWorkOrderRoute}
          {editWorkOrderRoute}
          {addBulkOrdersRoute}
          {/* Safety */}
          {mainSafetyRoute(permissionsArray)}
          {accidentProfileRoute}
          {accidentsRoute}
          {accidentCreateRoute}
          {accidentUpdateRoute}
          {/* Dispatch */}
          {mainDispatchRoute(permissionsArray)}
          {mainOrdersRoute}
          {orderCreateRoute}
          {orderProfileRoute}
          {editOrderRoute}
          {mainBrokersRoute}
          {brokerCreateRoute}
          {brokerProfileRoute}
          {editBrokerRoute}
          {mainFacilitiesRoute}
          {facilityCreateRoute}
          {facilityProfileRoute}
          {editFacilityRoute}
          {mainCalendarRoute}artr
          {mainOverviewRoute}
          {tabOverviewRoute}
          {/* HumanResources */}
          {mainHRRoute(permissionsArray)}
          {editDriverContractRoute}
          {driverContractRoute}
          {upcomingDriverContractRoute}
          {editUpcomingDriverContractRoute}
          {driverImportRoute}
          {employeeImportRoute}
          {mainDriversRoute}
          {driverCreateRoute}
          {driverEditRoute}
          {driverProfileRoute}
          {applicantProfileRoute}
          {applicantEditRoute}
          {mainLeadsRoute}
          {createLeadRoute}
          {editLeadRoute}
          {leadProfileRoute}
          {mainEmployeesRoute}
          {employeeCreateRoute}
          {employeeProfileRoute}
          {employeeEditRoute}
          {mainArchiveRoute}
          {mainUpcomingDriversRoute}
          {upcomingDriverRoute}
          {analyticsRoute}
          {/* Accounting */}
          {mainAccountingRoute(permissionsArray)}
          {weeklyPayrollsMainRoute}
          {driversPayrollsMainRoute}
          {driversPayrollsRoute}
          {accountingPayrollsRoute}
          {accountingPayrollRoute}
          {accountingDeductionProfileRoute}
          {accountingDeductionsRoute}
          {accountingDeductionsCalendarRoute}
          {accountingCreditsRoute}
          {accountingFuelRoute}
          {accountingFuelDriverRoute}
          {accountingBillingRoute}
          {accountingReportDriversRoute}
          {accountingReportDriverItemsRoute}
          {accountingReportCompanyRoute}
          {accountingReportCompanyGridRoute}
          {tandemInspectionUpdateRoute}
          {/* Settings */}
          {mainSettingsRoute(permissionsArray)}
          {accountingSettingsRoute}
          {gpsIntegrationsSettingsRoute}
          {gpsIntegrationsSettingsEditRoute}
          {orientationProcessSettingsRoute}
          {contractTemplateRoute}
          {createContractTemplateRoute}
          {editContractTemplateRoute}
          {checklistSettingsRoute}
          {operationListSettingsRoute}
          {/* {createOrientationProcessStepRoute} */}
          {createStepRoute}
          {updateStepRoute}
          {createOperationRoute}
          {editOperationRoute}
          {scriptsRoute}
          {createScriptRoute}
          {editScriptRoute}
          {createEmployeeContract}
          {workshopRoute}
          {/* Chat */}
          {chatRoute}
          {/* Task Management */}
          {mainTaskManagementRoute(permissionsArray)}
          {tasksRoute}
          {/* Internal Companies */}
          {mainAdminPanelRoute(permissionsArray)}
          {mainInternalCompaniesRoute}
          {addCompaniesRoute}
          {editCompaniesRoute}
          {/* Roles and Permissions */}
          {mainRolesAndPermissionsRoute}
          {roleSettingsRoute}
          {permissionFormRoute}
          {editSinglePermissionFormRoute}
          {/* Not Found */}
          <PrivateRoutes component={NotFound} withPermission={false} />
        </Switch>
      }
    </Suspense>
  );
};

export default MainRoutes;
