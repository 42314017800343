import { IFile, ILocalFile } from '../../../../redux/_common/models';
import {
  CLEAR_LEAD_STATE,
  DELETE_LEAD_FILE_SUCCESS,
  leadActionsType,
  UPLOAD_LEAD_FILES,
  UPLOAD_LEAD_FILES_FAIL,
  UPLOAD_LEAD_FILES_SUCCESS,
  UPLOAD_LEAD_ID_FILES,
  UPLOAD_LEAD_ID_FILES_SUCCESS,
  UPLOAD_LEAD_ID_FILES_FAIL,
} from './leadsTypes';

export type LeadUploadedFile = (ILocalFile | IFile) & {
  id: number;
  file_name: string;
  file_size: number;
  content_type: string;
  urls: { thumb: string; original: string };
};

interface ILeadReducer {
  files: LeadUploadedFile[];
}

const initialState: ILeadReducer = {
  files: [],
};

const leadReducer = (
  state = initialState,
  action: leadActionsType,
): ILeadReducer => {
  switch (action.type) {
    case UPLOAD_LEAD_FILES:
    case UPLOAD_LEAD_ID_FILES: {
      return {
        ...state,
      };
    }
    case UPLOAD_LEAD_FILES_SUCCESS:
      return {
        ...state,
        files: [
          ...state.files,
          { ...action.payload.file, segmentId: action.payload.segmentId },
        ],
      };
    case UPLOAD_LEAD_ID_FILES_SUCCESS:
      return {
        ...state,
        files: [
          ...state.files,
          { ...action.payload.file, leadId: action.payload.leadId },
        ],
      };
    case UPLOAD_LEAD_FILES_FAIL:
    case UPLOAD_LEAD_ID_FILES_FAIL:
      return {
        ...state,
      };
    case DELETE_LEAD_FILE_SUCCESS:
      return {
        ...state,
        files: [...state.files.filter(file => file.id !== action.payload)],
      };
    case CLEAR_LEAD_STATE:
      return {
        ...state,
        files: [],
      };
    default:
      return { ...state };
  }
};

export default leadReducer;
