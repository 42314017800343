/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';

import { IDriver } from 'components/HumanResources/TabDrivers/_models/driverModel';
import {
  IDeleteFileAction,
  IDeleteFileActionFail,
  IDeleteFileActionSuccess,
  IUploadFilesAction,
  IUploadFilesActionFail,
  IUploadFilesActionSuccess,
} from 'redux/_common/commonTypes';

import { IApplicant, IApplicantUploadedFile } from '../_models/mainEntities';

export const GET_APPLICANT = '[@@Applicant] GET_APPLICANT';
export const GET_APPLICANT_SUCCESS = '[@@Applicant] GET_APPLICANT_SUCCESS';
export const GET_APPLICANT_FAIL = '[@@Applicant] GET_APPLICANT_FAIL';

export interface IGetApplicant {
  type: typeof GET_APPLICANT;
  id: number;
}

export interface IGetApplicantSuccess {
  type: typeof GET_APPLICANT_SUCCESS;
  payload: any;
}

export interface IGetApplicantFail {
  type: typeof GET_APPLICANT_FAIL;
  payload: any;
}

export const HIRE_APPLICANT = '[@@Applicant] HIRE_APPLICANT';
export const HIRE_APPLICANT_SUCCESS = '[@@Applicant] HIRE_APPLICANT_SUCCESS';
export const HIRE_APPLICANT_FAIL = '[@@Applicant] HIRE_APPLICANT_FAIL';

export interface IHireApplicantAction {
  type: typeof HIRE_APPLICANT;
  id: number;
}
export interface IHireApplicantActionSuccess {
  type: typeof HIRE_APPLICANT_SUCCESS;
  payload: IDriver;
}
export interface IHireApplicantActionFail {
  type: typeof HIRE_APPLICANT_FAIL;
  payload: AxiosError;
}

// UPDATE Applicant
export const UPDATE_APPLICANT = '[@@Applicant] UPDATE_APPLICANT';
export const UPDATE_APPLICANT_SUCCESS =
  '[@@Applicant] UPDATE_APPLICANT_SUCCESS';
export const UPDATE_APPLICANT_FAIL = '[@@Applicant] UPDATE_APPLICANT_FAIL';

export interface IUpdateApplicantAction {
  type: typeof UPDATE_APPLICANT;
}
export interface IUpdateApplicantActionSuccess {
  type: typeof UPDATE_APPLICANT_SUCCESS;
  payload: IApplicant;
}
export interface IUpdateApplicantActionFail {
  type: typeof UPDATE_APPLICANT_FAIL;
  payload: AxiosError;
}

// UPLOAD APPLICANT PHOTO
export const UPLOAD_APPLICANT_PHOTO = '[@@Applicant] UPLOAD_APPLICANT_PHOTO';
export const UPLOAD_APPLICANT_PHOTO_SUCCESS =
  '[@@Applicant] UPLOAD_APPLICANT_PHOTO_SUCCESS';
export const UPLOAD_APPLICANT_PHOTO_FAIL =
  '[@@Applicant] UPLOAD_APPLICANT_FILES_FAIL';

export interface IUploadApplicantPhotoAction {
  type: typeof UPLOAD_APPLICANT_PHOTO;
  payload: any;
}
export interface IUploadApplicantPhotoActionSuccess {
  type: typeof UPLOAD_APPLICANT_PHOTO_SUCCESS;
  payload: any;
}
export interface IUploadApplicantPhotoActionFail {
  type: typeof UPLOAD_APPLICANT_PHOTO_FAIL;
  payload: AxiosError;
}

// DELETE APPLICANT PHOTO
export const DELETE_APPLICANT_PHOTO = '[@@Applicant] DELETE_APPLICANT_PHOTO';
export const DELETE_APPLICANT_PHOTO_SUCCESS =
  '[@@Applicant] DELETE_APPLICANT_PHOTO_SUCCESS';
export const DELETE_APPLICANT_PHOTO_FAIL =
  '[@@Applicant] DELETE_APPLICANT_PHOTO_FAIL';

export interface IDeleteApplicantPhotoAction {
  type: typeof DELETE_APPLICANT_PHOTO;
  payload: any;
}
export interface IDeleteApplicantPhotoActionSuccess {
  type: typeof DELETE_APPLICANT_PHOTO_SUCCESS;
  payload: string;
}
export interface IDeleteApplicantPhotoActionFail {
  type: typeof DELETE_APPLICANT_PHOTO_FAIL;
  payload: AxiosError;
}

// UPLOAD APPLICANT FILES
export const UPLOAD_APPLICANT_FILES = '[@@Applicant] UPLOAD_APPLICANT_FILES';
export const UPLOAD_APPLICANT_FILES_SUCCESS =
  '[@@Applicant] UPLOAD_APPLICANT_FILES_SUCCESS';
export const UPLOAD_APPLICANT_FILES_FAIL =
  '[@@Applicant] UPLOAD_APPLICANT_FILES_FAIL';

export interface IUploadApplicantFilesAction extends IUploadFilesAction {
  type: typeof UPLOAD_APPLICANT_FILES;
}
export interface IUploadApplicantFilesActionSuccess
  extends IUploadFilesActionSuccess {
  type: typeof UPLOAD_APPLICANT_FILES_SUCCESS;
  payload: any;
}
export interface IUploadApplicantFilesActionFail
  extends IUploadFilesActionFail {
  type: typeof UPLOAD_APPLICANT_FILES_FAIL;
  payload: AxiosError;
}

// DELETE APPLICANT FILES
export const DELETE_APPLICANT_FILE = '[@@Applicant] DELETE_APPLICANT_FILE';
export const DELETE_APPLICANT_FILE_SUCCESS =
  '[@@Applicant] DELETE_APPLICANT_FILE_SUCCESS';
export const DELETE_APPLICANT_FILE_FAIL =
  '[@@Applicant] DELETE_APPLICANT_FILE_FAIL';

export interface IDeleteApplicantFileAction extends IDeleteFileAction {
  type: typeof DELETE_APPLICANT_FILE;
}
export interface IDeleteApplicantFileActionSuccess
  extends IDeleteFileActionSuccess {
  type: typeof DELETE_APPLICANT_FILE_SUCCESS;
}
export interface IDeleteApplicantFileActionFail extends IDeleteFileActionFail {
  type: typeof DELETE_APPLICANT_FILE_FAIL;
}

// CHANGE APPLICANT FILE CATEGORY
export const CHANGE_APPLICANT_FILE_CATEGORY =
  '[@@Applicant] CHANGE_APPLICANT_FILE_CATEGORY';
export const CHANGE_APPLICANT_FILE_CATEGORY_SUCCESS =
  '[@@Applicant] CHANGE_APPLICANT_FILE_CATEGORY_SUCCESS';
export const CHANGE_APPLICANT_FILE_CATEGORY_FAIL =
  '[@@Applicant] CHANGE_APPLICANT_FILE_CATEGORY_FAIL';

export interface IChangeApplicantFileCategoryAction {
  type: typeof CHANGE_APPLICANT_FILE_CATEGORY;
  payload: any;
}
export interface IChangeApplicantFileCategoryActionSuccess {
  type: typeof CHANGE_APPLICANT_FILE_CATEGORY_SUCCESS;
  payload: IApplicantUploadedFile;
}
export interface IChangeApplicantFileCategoryActionFail {
  type: typeof CHANGE_APPLICANT_FILE_CATEGORY_FAIL;
  payload: AxiosError;
}

// SET APPLICANT CHECK
export const SET_APPLICANT_CHECK = '[@@Applicant] SET_APPLICANT_CHECK';
export const SET_APPLICANT_CHECK_SUCCESS =
  '[@@Applicant] SET_APPLICANT_CHECK_SUCCESS';
export const SET_APPLICANT_CHECK_FAIL =
  '[@@Applicant] SET_APPLICANT_CHECK_FAIL';

export interface ISetApplicantCheckAction {
  type: typeof SET_APPLICANT_CHECK;
  payload: any;
}
export interface ISetApplicantCheckActionSuccess {
  type: typeof SET_APPLICANT_CHECK_SUCCESS;
  payload: any;
}
export interface ISetApplicantCheckActionFail {
  type: typeof SET_APPLICANT_CHECK_FAIL;
  payload: AxiosError;
}

// OTHER
export const SET_DELETE_APPLICANT_FILES_PENDING =
  '[@@Applicant] SET_DELETE_APPLICANT_FILES_PENDING';
export interface ISetDeleteApplicantFilesPendingAction {
  type: typeof SET_DELETE_APPLICANT_FILES_PENDING;
  payload: number;
}

export const SET_CHANGE_APPLICANT_FILES_CATEGORY_PENDING =
  '[@@Applicant] SET_CHANGE_APPLICANT_FILES_CATEGORY_PENDING';
export interface ISetChangeApplicantFilesCategoryPendingAction {
  type: typeof SET_CHANGE_APPLICANT_FILES_CATEGORY_PENDING;
  payload: {
    fileId: number;
    fileCategory: number;
  };
}

export const UNMOUNT_APPLICANT_FORM = '[@@Applicant] UNMOUNT_APPLICANT_FORM';

export interface IUnmountApplicantFormAction {
  type: typeof UNMOUNT_APPLICANT_FORM;
}

// SEARCH APPLICANT
export const SEARCH_APPLICANTS = '[@@Applicant] SEARCH_APPLICANTS';

export interface ISearchApplicantsAction {
  type: typeof SEARCH_APPLICANTS;
}

// CLEAR APPLICANT
export const ASSIGN_CHECKS_TO_APPLICANT =
  '[@@Applicant] ASSIGN_CHECKS_TO_APPLICANT';
export const ASSIGN_CHECKS_TO_APPLICANT_SUCCESS =
  '[@@Applicant] ASSIGN_CHECKS_TO_APPLICANT_SUCCESS';
export const ASSIGN_CHECKS_TO_APPLICANT_FAIL =
  '[@@Applicant] ASSIGN_CHECKS_TO_APPLICANT_FAIL';

export interface IAssignChecksToApplicantAction {
  type: typeof ASSIGN_CHECKS_TO_APPLICANT;
  payload: any;
}
export interface IAssignChecksToApplicantActionSuccess {
  type: typeof ASSIGN_CHECKS_TO_APPLICANT_SUCCESS;
  payload: any;
}
export interface IAssignChecksToApplicantActionFail {
  type: typeof ASSIGN_CHECKS_TO_APPLICANT_FAIL;
  payload: AxiosError;
}

export type applicantsTypes =
  | ISetApplicantCheckAction
  | ISetApplicantCheckActionSuccess
  | ISetApplicantCheckActionFail
  | IAssignChecksToApplicantAction
  | IAssignChecksToApplicantActionSuccess
  | IAssignChecksToApplicantActionFail
  | ISearchApplicantsAction
  | IUnmountApplicantFormAction
  | ISetChangeApplicantFilesCategoryPendingAction
  | ISetDeleteApplicantFilesPendingAction
  | IChangeApplicantFileCategoryAction
  | IChangeApplicantFileCategoryActionSuccess
  | IChangeApplicantFileCategoryActionFail
  | IHireApplicantAction
  | IHireApplicantActionSuccess
  | IHireApplicantActionFail
  | IDeleteApplicantFileAction
  | IDeleteApplicantFileActionSuccess
  | IDeleteApplicantFileActionFail
  | IUploadApplicantFilesAction
  | IUploadApplicantFilesActionSuccess
  | IUploadApplicantFilesActionFail
  | IDeleteApplicantPhotoAction
  | IDeleteApplicantPhotoActionSuccess
  | IDeleteApplicantPhotoActionFail
  | IUploadApplicantPhotoAction
  | IUploadApplicantPhotoActionSuccess
  | IUploadApplicantPhotoActionFail
  | IUpdateApplicantAction
  | IUpdateApplicantActionSuccess
  | IUpdateApplicantActionFail
  | IGetApplicant
  | IGetApplicantSuccess
  | IGetApplicantFail;
