import { takeLatest, call, put } from 'redux-saga/effects';

import {
  LOAD_SETTINGS,
  LOAD_SETTINGS_FAIL,
  LOAD_SETTINGS_SUCCESS,
} from 'redux/userSettings/userSettingsTypes';
import api from 'utils/requests';

function* getUserSettingsSaga() {
  try {
    const { data } = yield call(() => api.get('/v1/settings'));
    yield put({
      type: LOAD_SETTINGS_SUCCESS,
      payload: data,
    });
  } catch ({ response: { data } }) {
    yield put({ type: LOAD_SETTINGS_FAIL });
  }
}

export default function* settingsSaga() {
  yield takeLatest(LOAD_SETTINGS, getUserSettingsSaga);
}
