import { AxiosError } from 'axios';

import {
  ILoginResponse,
  IProfileResponse,
  IRegCompanyResponse,
} from 'components/Auth/_models/authModels';
import { IChangePassword } from 'components/Auth/_models/changePasswordModels';
import { IForgotPassword } from 'components/Auth/_models/forgotPasswordModels';
import { IUploadCroppedImgActionSuccess } from 'redux/_common/commonTypes';
import { ILogout } from 'redux/_common/models';

// LOGIN
export const LOGIN = '[@@Auth] LOGIN';
export const LOGIN_SUCCESS = '[@@Auth] LOGIN_SUCCESS';
export const LOGIN_FAIL = '[@@Auth] LOGIN_FAIL';

export interface ILoginActions {
  type: typeof LOGIN_SUCCESS;
  payload: ILoginResponse;
}

// REFRESH_TOKEN
export const REFRESH_TOKEN = '[@@Auth] REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = '[@@Auth] REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAIL = '[@@Auth] REFRESH_TOKEN_FAIL';

export interface IRefreshTokenAction {
  type: typeof REFRESH_TOKEN_SUCCESS;
  payload: {
    token: string;
    validate_at: string;
  };
}

// REFRESH_USER
export const REFRESH_USER = '[@@Auth] REFRESH_USER';
export const REFRESH_USER_SUCCESS = '[@@Auth] REFRESH_USER_SUCCESS';
export const REFRESH_USER_FAIL = '[@@Auth] REFRESH_USER_FAIL';

export const NEED_REGISTRATION = '[@@Auth] NEED_REGISTRATION';

export interface IRefreshUserAction {
  type: typeof REFRESH_USER;
}
export interface IRefreshUserActionSuccess {
  type: typeof REFRESH_USER_SUCCESS;
  payload: IProfileResponse;
}
export interface IAuthFailActions {
  type:
    | typeof LOGIN_FAIL
    | typeof REFRESH_USER_FAIL
    | typeof REFRESH_TOKEN_FAIL
    | typeof NEED_REGISTRATION;
}

// LOGOUT
export const LOGOUT = 'LOGOUT';

// FORGOT_PASSWORD
export const FORGOT_PASSWORD = '[@@Auth] FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = '[@@Auth] FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = '[@@Auth] FORGOT_PASSWORD_FAIL';

export interface IForgotPasswordAction {
  type: typeof FORGOT_PASSWORD;
  payload: IForgotPassword;
}
export interface IForgotPasswordActionSuccess {
  type: typeof FORGOT_PASSWORD_SUCCESS;
  payload: string;
}
export interface IForgotPasswordActionFail {
  type: typeof FORGOT_PASSWORD_FAIL;
}

// RESET_PASSWORD
export const RESET_PASSWORD = '[@@Auth] RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = '[@@Auth] RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = '[@@Auth] RESET_PASSWORD_FAIL';

export interface IResetPasswordAction {
  type: typeof RESET_PASSWORD;
  payload: IForgotPassword;
}
export interface IResetPasswordActionSuccess {
  type: typeof RESET_PASSWORD_SUCCESS;
  payload: string;
}
export interface IResetPasswordActionFail {
  type: typeof RESET_PASSWORD_FAIL;
}

// CHANGE_PASSWORD
export const CHANGE_PASSWORD = '[@@Auth] CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = '[@@Auth] CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = '[@@Auth] CHANGE_PASSWORD_FAIL';

export interface IChangePasswordAction {
  type: typeof CHANGE_PASSWORD;
  payload: IChangePassword;
}
export interface IChangePasswordActionSuccess {
  type: typeof CHANGE_PASSWORD_SUCCESS;
}
export interface IChangePasswordActionFail {
  type: typeof CHANGE_PASSWORD_FAIL;
}

// STATUS
export const SET_ONLINE = '[@@Auth] SET_ONLINE';
export const SET_ONLINE_SUCCESS = '[@@Auth] SET_ONLINE_SUCCESS';
export const SET_ONLINE_FAIL = '[@@Auth] SET_ONLINE_FAIL';

export interface ISetOnlineAction {
  type: typeof SET_ONLINE;
}
export interface ISetOnlineActionSuccess {
  type: typeof SET_ONLINE_SUCCESS;
}
export interface ISetOnlineActionFail {
  type: typeof SET_ONLINE_FAIL;
}

export const SET_OFFLINE = '[@@Auth] SET_OFFLINE';
export const SET_OFFLINE_SUCCESS = '[@@Auth] SET_OFFLINE_SUCCESS';
export const SET_OFFLINE_FAIL = '[@@Auth] SET_OFFLINE_FAIL';

export interface ISetOfflineAction {
  type: typeof SET_OFFLINE;
}
export interface ISetOfflineActionSuccess {
  type: typeof SET_OFFLINE_SUCCESS;
}
export interface ISetOfflineActionFail {
  type: typeof SET_OFFLINE_FAIL;
}

// Remember prev user
export const SET_PREV_USER_EMAIL = '[@@Auth] SET_PREV_USER_EMAIL';
export interface ISetPrevUserEmail {
  type: typeof SET_PREV_USER_EMAIL;
  payload: string;
}

// Register Company
export const REG_COMPANY = '[@@Auth] REG_COMPANY';
export const REG_COMPANY_SUCCESS = '[@@Auth] REG_COMPANY_SUCCESS';
export const REG_COMPANY_FAIL = '[@@Auth] REG_COMPANY_FAIL';

export const FULL_REG_COMPANY = '[@@Auth] FULL_REG_COMPANY';

export interface IRegCompanyStartActions {
  type: typeof REG_COMPANY;
}

export interface IRegCompanyActions {
  type: typeof REG_COMPANY_SUCCESS;
  payload: IRegCompanyResponse;
}

export interface IRegCompanyFail {
  type: typeof REG_COMPANY_FAIL;
  payload: AxiosError;
}

export const PLAN_REDIRECT = '[@@Auth] PLAN_REDIRECT';

export interface IPlanRedirect {
  type: typeof PLAN_REDIRECT;
  payload: string;
}

export type authActionsType =
  | IRefreshUserAction
  | ILoginActions
  | ILogout
  | IRefreshUserActionSuccess
  | ISetOfflineAction
  | ISetOfflineActionSuccess
  | ISetOfflineActionFail
  | ISetOnlineAction
  | ISetOnlineActionSuccess
  | ISetOnlineActionFail
  | IForgotPasswordAction
  | IForgotPasswordActionSuccess
  | IForgotPasswordActionFail
  | IResetPasswordAction
  | IResetPasswordActionSuccess
  | IResetPasswordActionFail
  | IChangePasswordAction
  | IChangePasswordActionSuccess
  | IChangePasswordActionFail
  | IUploadCroppedImgActionSuccess
  | IAuthFailActions
  | IRefreshTokenAction
  | ISetPrevUserEmail
  | IRegCompanyActions
  | IRegCompanyFail
  | IRegCompanyStartActions
  | IPlanRedirect;
