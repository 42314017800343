import { IPagination } from 'components/_common/Navigation/Pagination/_redux/paginationTypes';
import { IApplicantArchived } from 'components/HumanResources/TabApplicantsArchive/_models/mainEntities';
import { parseFloatString } from 'utils/helpers';
import { IWithPending, IWithLoaded, IWithError } from 'utils/models';

import {
  IApplicantUploadedFile,
  IApplicant,
  IApplicantsStatistic,
} from '../_models/mainEntities';
import { IApplicantCheck } from '../ApplicantProfile/ApplicantChecks/_models';
import {
  applicantsTypes,
  GET_APPLICANT,
  GET_APPLICANT_SUCCESS,
  GET_APPLICANT_FAIL,
  UPDATE_APPLICANT,
  UPDATE_APPLICANT_SUCCESS,
  UPDATE_APPLICANT_FAIL,
  UPLOAD_APPLICANT_FILES,
  UPLOAD_APPLICANT_FILES_SUCCESS,
  UPLOAD_APPLICANT_FILES_FAIL,
  CHANGE_APPLICANT_FILE_CATEGORY,
  CHANGE_APPLICANT_FILE_CATEGORY_SUCCESS,
  CHANGE_APPLICANT_FILE_CATEGORY_FAIL,
  DELETE_APPLICANT_FILE,
  DELETE_APPLICANT_FILE_SUCCESS,
  DELETE_APPLICANT_FILE_FAIL,
  UPLOAD_APPLICANT_PHOTO,
  UPLOAD_APPLICANT_PHOTO_SUCCESS,
  UPLOAD_APPLICANT_PHOTO_FAIL,
  DELETE_APPLICANT_PHOTO,
  DELETE_APPLICANT_PHOTO_SUCCESS,
  DELETE_APPLICANT_PHOTO_FAIL,
  SET_DELETE_APPLICANT_FILES_PENDING,
  SET_CHANGE_APPLICANT_FILES_CATEGORY_PENDING,
  UNMOUNT_APPLICANT_FORM,
  HIRE_APPLICANT,
  HIRE_APPLICANT_SUCCESS,
  HIRE_APPLICANT_FAIL,
  SET_APPLICANT_CHECK,
  SET_APPLICANT_CHECK_SUCCESS,
  SET_APPLICANT_CHECK_FAIL,
} from './applicantsTypes';

export interface IApplicantsState
  extends IWithPending,
    IWithLoaded,
    IWithError {
  list: IApplicant[];
  currentApplicant: IApplicant | IApplicantArchived | null;
  hiredApplicant: IApplicant | null;
  files: IApplicantUploadedFile[];
  pagination: IPagination | null;
  statistic: IApplicantsStatistic | null;
}

const initialState: IApplicantsState = {
  list: [],
  pagination: null,
  statistic: null,
  currentApplicant: null,
  hiredApplicant: null,
  files: [],
  _pending: false,
  _loaded: false,
  _error: null,
};

const applicantsReducer = (
  state: IApplicantsState = initialState,
  action: applicantsTypes,
) => {
  switch (action.type) {
    // GET APPLICANT
    case GET_APPLICANT:
      return { ...state, _loaded: false, _pending: true, _error: null };
    case GET_APPLICANT_SUCCESS:
      return {
        ...state,
        currentApplicant: {
          ...action.payload,
          experience: parseFloatString(action.payload.experience),
        },
        files: action.payload.files,
        _loaded: true,
        _pending: false,
      };
    case GET_APPLICANT_FAIL:
      return {
        ...state,
        _error: action.payload,
        _loaded: false,
        _pending: false,
      };

    // HIRE APPLICANT
    case HIRE_APPLICANT:
      return { ...state, _error: null, hiredApplicant: null };
    case HIRE_APPLICANT_SUCCESS:
      return { ...state, hiredApplicant: action.payload.driver };
    case HIRE_APPLICANT_FAIL:
      return { ...state, _error: action.payload };

    // UPDATE APPLICANT
    case UPDATE_APPLICANT:
      return { ...state, _error: null };
    case UPDATE_APPLICANT_SUCCESS:
      return { ...state, files: [], currentApplicant: null };
    case UPDATE_APPLICANT_FAIL:
      return { ...state, _error: action.payload };

    // UPLOAD APPLICANT FILES
    case UPLOAD_APPLICANT_FILES:
      return {
        ...state,
        _error: null,
        files: [
          ...state.files,
          ...action.payload.files.map(file => ({
            name: file.name,
            loading: true,
          })),
        ],
      };
    case UPLOAD_APPLICANT_FILES_SUCCESS:
      return {
        ...state,
        files: state.files.map((file, index: number) =>
          index === state.files.findIndex(({ loading }) => loading)
            ? { ...action.payload, loading: false }
            : file,
        ),
      };
    case UPLOAD_APPLICANT_FILES_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // CHANGE APPLICANT FILE CATEGORY
    case CHANGE_APPLICANT_FILE_CATEGORY:
      return { ...state, _error: null };
    case CHANGE_APPLICANT_FILE_CATEGORY_SUCCESS:
      return {
        ...state,
        files: state.files.map(file =>
          file.id === action.payload.id ? action.payload : file,
        ),
      };
    case CHANGE_APPLICANT_FILE_CATEGORY_FAIL:
      return { ...state, _error: action.payload };

    // DELETE APPLICANT FILE
    case DELETE_APPLICANT_FILE:
      return { ...state, _error: null };
    case DELETE_APPLICANT_FILE_SUCCESS: {
      const updatedFiles = state.files.filter(
        file => file.id !== action.payload,
      );

      return {
        ...state,
        files: updatedFiles,
        currentApplicant: { ...state.currentApplicant, files: updatedFiles },
      };
    }

    case DELETE_APPLICANT_FILE_FAIL:
      return { ...state, _error: action.payload };

    // UPLOAD APPLICANT PHOTO
    case UPLOAD_APPLICANT_PHOTO:
      return { ...state, _error: null };
    case UPLOAD_APPLICANT_PHOTO_SUCCESS:
      return { ...state };
    case UPLOAD_APPLICANT_PHOTO_FAIL:
      return { ...state, _error: action.payload };

    // DELETE APPLICANT PHOTO
    case DELETE_APPLICANT_PHOTO:
      return { ...state, _error: null };
    case DELETE_APPLICANT_PHOTO_SUCCESS:
      return { ...state };
    case DELETE_APPLICANT_PHOTO_FAIL:
      return { ...state, _error: action.payload };

    // SET APPLICANT CHECK
    case SET_APPLICANT_CHECK:
      return { ...state, _error: null };
    case SET_APPLICANT_CHECK_SUCCESS: {
      const currentApplicant = {
        ...state.currentApplicant,
        checks:
          state.currentApplicant &&
          state.currentApplicant.checks.map((check: IApplicantCheck) =>
            check.type_name === action.payload.type_name
              ? action.payload.fleets_applicants_checks
              : check,
          ),
      };

      return { ...state, currentApplicant };
    }
    case SET_APPLICANT_CHECK_FAIL:
      return { ...state, _error: action.payload };

    //  OTHER
    case SET_DELETE_APPLICANT_FILES_PENDING: {
      return {
        ...state,
        currentApplicant: state.currentApplicant
          ? {
              ...state.currentApplicant,
              deleteFilesPending: state.currentApplicant.deleteFilesPending
                ? [...state.currentApplicant.deleteFilesPending, action.payload]
                : [action.payload],
            }
          : null,
      };
    }

    case SET_CHANGE_APPLICANT_FILES_CATEGORY_PENDING: {
      const pendingFiles =
        (state.currentApplicant &&
          state.currentApplicant.changeCategoryFilesPending) ||
        [];

      const ifExist =
        !!pendingFiles &&
        pendingFiles.some(file => file.fileId === action.payload.fileId);

      return {
        ...state,
        currentApplicant: state.currentApplicant
          ? {
              ...state.currentApplicant,
              changeCategoryFilesPending: ifExist
                ? pendingFiles
                : [...pendingFiles, action.payload],
            }
          : null,
      };
    }

    case UNMOUNT_APPLICANT_FORM:
      return {
        ...state,
        files: [],
        list: [],
        currentApplicant: null,
      };

    default:
      return state;
  }
};

export default applicantsReducer;
