import { AxiosError } from 'axios';

import { IPagination } from 'components/_common/Navigation/Pagination/_redux/paginationTypes';
import { ILogout } from 'redux/_common/models';

import { ITruckMaintenance } from '../_models/truckMaintenanceModels';

// GET MAINTENANCE LIST
export const GET_ALL_TRUCKS_MAINTENANCE_LIST =
  '[@@Maintenance] GET_ALL_TRUCKS_MAINTENANCE_LIST';
export const GET_ALL_TRUCKS_MAINTENANCE_LIST_SUCCESS =
  '[@@Maintenance] GET_ALL_TRUCKS_MAINTENANCE_LIST_SUCCESS';
export const GET_ALL_TRUCKS_MAINTENANCE_LIST_FAIL =
  '[@@Maintenance] GET_ALL_TRUCKS_MAINTENANCE_LIST_FAIL';

export interface IGetAllTrucksMaintenanceList {
  type: typeof GET_ALL_TRUCKS_MAINTENANCE_LIST;
  payload: {
    data: { query?: string };
    callback?: () => void;
  };
}

export interface IGetAllTrucksMaintenanceListSuccess {
  type: typeof GET_ALL_TRUCKS_MAINTENANCE_LIST_SUCCESS;
  payload: {
    data: ITruckMaintenance[];
    pagination: IPagination;
  };
}
export interface IGetAllTrucksMaintenanceListFail {
  type: typeof GET_ALL_TRUCKS_MAINTENANCE_LIST_FAIL;
  payload: AxiosError;
}

export type allTrucksMaintenanceTypes =
  | IGetAllTrucksMaintenanceList
  | IGetAllTrucksMaintenanceListFail
  | IGetAllTrucksMaintenanceListSuccess
  | ILogout;
