import { isEmpty } from 'ramda';

export const API_VERSION = 'v1';

// Orders
export const BROKER_API_PREFIX = `/${API_VERSION}/orders/brokers`;
export const FACILITIES_API_PREFIX = `/${API_VERSION}/orders/facilities`;

export const DASHBOARD_APIS = {
  INVENTORY_LOSSES: `/${API_VERSION}/dashboard/inventory_losses`,
  INSPECTIONS_OVERVIEW: `/${API_VERSION}/dashboard/inspections_overview`,
  RECENT_INSPECTIONS: `/${API_VERSION}/fleets/inspections/vehicles`,
  TRUCKS_AND_TRAILERS_BY_STATUS: `/${API_VERSION}/dashboard/trucks_and_trailers_by_status`,
  RUNNING_OUT: `/${API_VERSION}/dashboard/running_out_inventory`,
  MOST_EXPENSIVE_CLAIM: `/${API_VERSION}/dashboard/most_expensive_claims`,
  CRITICAL_DRIVER: `/${API_VERSION}/dashboard/critical_drivers`,
  RECENT_CLAIM: `/${API_VERSION}/dashboard/recent_claims`,
  CLAIMS_OVERVIEW: `/${API_VERSION}/fleets/drivers/claims/overview`,
};
export const WISE_CHECK_APIS = {
  COMPANY_INFO: `/${API_VERSION}/employee/company_settings`,
  CHANGE_ADMIN_PASSWORD: `/${API_VERSION}/employee/profile/change_phone/get_code`,
  CONFIRM_ADMIN_PASSWORD: `/${API_VERSION}/employee/profile/change_phone/verify_code`,
  ADD_DELETE_COMPANY_PHOTO: `/${API_VERSION}/employee/company_settings/photo`,
  CHANGE_COMPANY_EMAIL: `/${API_VERSION}/employee/email`,
};

export const WISE_CHECK_SUBSCRIPTION_API_PREFIX = `/${API_VERSION}/company_subscriptions`;
export const WISE_CHECK_SUBSCRIPTION_FREE_API_PREFIX = `/${API_VERSION}/company_subscriptions/free`;
export const WISE_CHECK_SUBSCRIPTION_APIS = {
  PLANS: `${WISE_CHECK_SUBSCRIPTION_API_PREFIX}/plans`,
  CREDIT_CARD: `${WISE_CHECK_SUBSCRIPTION_API_PREFIX}/credit_card`,
  PAYMENTS_HISTORY: `${WISE_CHECK_SUBSCRIPTION_API_PREFIX}/payments`,
  SUBSCRIPTION_HISTORY: `${WISE_CHECK_SUBSCRIPTION_API_PREFIX}/history`,
};

export const DISPATCH_APIS = {
  ORDERS: `/${API_VERSION}/orders`,
  ORDER_PAYMENTS: `/${API_VERSION}/services/order_payments`,
} as const;

export const EXPORT_API = {
  TRUCKS_XLSX: `/${API_VERSION}/fleets/trucks/export.xlsx`,
  TRAILERS_XLSX: `/${API_VERSION}/fleets/trailers/export.xlsx`,
};

// Accounting
const ACCOUNT_API = `/${API_VERSION}/accountings`;

export const WEEKLY_PAYROLLS_API = `${ACCOUNT_API}/drivers/payrolls/weeks`;
export const WEEK_PAYROLLS_API = (weekId: string) =>
  `${ACCOUNT_API}/drivers/payrolls/weekly/${weekId}`;

export const DRIVERS_PAYROLLS_API = `${ACCOUNT_API}/drivers/payrolls/list`;

export const DRIVERS_PAYROLLS_ITEMS_API = (driverId: string | number) =>
  `${ACCOUNT_API}/drivers/${driverId}/payrolls`;

export const GET_DRIVER_PAYROLLS_API = (
  driverId: number,
  payrollVariable: string | number,
) => `${ACCOUNT_API}/drivers/${driverId}/payrolls/${payrollVariable}`;

export const PATCH_DRIVER_PAYROLLS_API = `${ACCOUNT_API}/drivers/payrolls/mass_update`;

export const DRIVER_PAYROLLS_OUT_OF_RANGE_ORDERS_API = (
  driverId: number,
  weekId: string,
) =>
  `${ACCOUNT_API}/drivers/${driverId}/payrolls/${weekId}/out_of_range_orders`;

export const PAYROLL_DRIVERS_API_PREFIX = `${ACCOUNT_API}/payrolls/drivers/available`;

export const PAYROLL_SETTINGS_API_PREFIX = `${ACCOUNT_API}/payroll_settings`;

export const DRIVER_PAYROLL_ITEMS_API = (driverId: number, id: number) =>
  `${ACCOUNT_API}/drivers/${driverId}/payroll_items/${id}`;

export const DRIVER_PAYROLL_ITEM_COMMENTS_API = (
  driverId: number,
  id: number,
) => `${DRIVER_PAYROLL_ITEMS_API(driverId, id)}/comments`;

export const SCHED_DEDUCTION_DRIVERS_API = (
  page?: number,
  perPage?: number,
  driverName?: string,
  driverId?: number,
) => {
  const params: string[] = [];
  if (page) params.push(`page=${page}`);
  if (perPage) params.push(`per_page=${perPage}`);
  if (driverName) params.push(`search=${driverName}`);
  if (driverId) params.push(`filter[driver_ids]=${driverId}`);
  return `${ACCOUNT_API}/scheduled_deductions/drivers${
    isEmpty(params) ? '' : `?${params.join('&')}`
  }`;
};

export const DEDUCTIONS_API = `${ACCOUNT_API}/drivers/deductions`;
export const ACCOUNTING_DEDUCTIONS_API = `${ACCOUNT_API}/drivers/deductions/list`;
export const ACCOUNTING_CREDITS_API = `${ACCOUNT_API}/drivers/credits/list`;

export const DRIVER_DEDUCTION_API = (driverId: number) =>
  `${ACCOUNT_API}/drivers/${driverId}/deductions`;

export const DRIVER_CREDIT_API = (driverId: number) =>
  `${ACCOUNT_API}/drivers/${driverId}/credits`;

export const ACCOUNTING_FUEL_API = `${ACCOUNT_API}/drivers/fuels`;

export const ACCOUNTING_DRIVER_FUEL_API = (driverId: number) =>
  `${ACCOUNT_API}/drivers/${driverId}/driver_refills`;

export const ACCOUNTING_DRIVER_FUEL_STATISTICS_API = (driverId: number) =>
  `${ACCOUNT_API}/drivers/${driverId}/refills_statistic`;

export const ACCOUNTING_BILLINGS_API = `${ACCOUNT_API}/billings/list`;

export const ACCOUNTING_BILLING_STATUS_API = `${DISPATCH_APIS.ORDERS}/billing_status`;

export const ACCOUNTING_BILLINGS_CREATE_ORDER_API = `${DISPATCH_APIS.ORDERS}/zip_file_download_tasks`;

export const ACCOUNTING_BILLINGS_GET_ORDER_API = (order_id: number) =>
  `${DISPATCH_APIS.ORDERS}/zip_file_download_tasks/${order_id}`;

export const ACCOUNTING_BILLINGS_REGENERATE_BILL_API = (billing_id: number) =>
  `${DISPATCH_APIS.ORDERS}/${billing_id}/invoice_file`;

export const ACCOUNTING_BILLINGS_MERGE_PDF_LINK_API = (billing_id: number) =>
  `${DISPATCH_APIS.ORDERS}/${billing_id}/merge_pdf_link`;

export const ACCOUNTING_EXTRA_CHARGE_FILE_API = (
  billing_id: number,
  file_id?: number,
) =>
  `${DISPATCH_APIS.ORDERS}/${billing_id}/receipt_files${
    file_id ? `/${file_id}` : ''
  }`;

export const ACCOUNTING_EXTRA_CHARGE_API = (
  billing_id: number,
  extra_charge_id?: number,
) =>
  `${DISPATCH_APIS.ORDERS}/${billing_id}/extra_charges${
    extra_charge_id ? `/${extra_charge_id}` : ''
  }`;

export const ACCOUNTING_PROOF_OF_DELIVERY_API = (
  order_id: number,
  address_id: number,
  photo_id?: number | string,
) =>
  `${DISPATCH_APIS.ORDERS}/${order_id}/addresses/${address_id}/proof_photos${
    photo_id ? `/${photo_id}` : ''
  }`;

export const ACCOUNTING_BILLINGS_BROKER_DEDUCTION_API = (id?: number) =>
  `${ACCOUNT_API}/billing_broker_deductions${id ? `/${id}` : ''}`;

export const ACCOUNTING_BILLINGS_GET_EXCEL_FILE_API = `${ACCOUNT_API}/billings/export.xlsx`;

// Accounting Widgets
export const ACCOUNTING_REPORTS_CONTRACTOR_GRAPH_API = `/${API_VERSION}/reports/contractor_graph`;
export const ACCOUNTING_REPORTS_DIVISION_GRAPH_API = `/${API_VERSION}/reports/division_graph`;
export const ACCOUNTING_REPORTS_CONTRACTOR_WORKING_DAYS_GRAPH_API = `/${API_VERSION}/reports/working_days_graph`;
export const ACCOUNTING_REPORTS_DIVISION_WORKING_DAYS_GRAPH_API = `/${API_VERSION}/reports/division_working_days`;
export const ACCOUNTING_REPORTS_CONTRACTOR_PROFIT_GRAPH_API = `/${API_VERSION}/reports/contractor_profit`;
export const ACCOUNTING_REPORTS_DIVISION_PROFIT_GRAPH_API = `/${API_VERSION}/reports/division_profit`;
export const ACCOUNTING_REPORTS_DRIVER_EFFICIENCY_GRAPH_API = `/${API_VERSION}/reports/driver_efficiency`;

export const ACCOUNTING_REPORTS_CONTRACTOR_TABLE_API = `/${API_VERSION}/reports/contractor_gross_details`;
export const ACCOUNTING_REPORTS_DIVISION_TABLE_API = `/${API_VERSION}/reports/division_gross_details`;
export const ACCOUNTING_REPORTS_CONTRACTOR_WORKING_DAYS_TABLE_API = `/${API_VERSION}/reports/working_days_details`;
export const ACCOUNTING_REPORTS_DIVISION_WORKING_DAYS_TABLE_API = `/${API_VERSION}/reports/division_working_days_details`;

// Fleets
export const APPLICANT_API_PREFIX = `/${API_VERSION}/fleets/applicants`;
export const DRIVERS_API_PREFIX = `/${API_VERSION}/fleets/drivers?stats=1`;
export const TRAILERS_API_PREFIX = `/${API_VERSION}/fleets/trailers`;
export const TRUCKS_API_PREFIX = `/${API_VERSION}/fleets/trucks`;
export const FLEET_INSPECTIONS_API_PREFIX = `/${API_VERSION}/fleets/inspections`;
export const FLEET_INSPECTION_BY_ID_API = (id: number) =>
  `/${API_VERSION}/fleets/inspections/${id}`;
export const FLEET_CLAIMS_API = 'v1/fleets/drivers/claims';

// Inventory
export const FLEET_INVENTORY_ITEMS = `/${API_VERSION}/inventories/items`;
export const INVENTORY_ITEMS = `/${API_VERSION}/inventories/items`;

// HR
export const EMPLOYEE_API_PREFIX = `/${API_VERSION}/hr/employees`;

// Employee
export const FEEDBACK_API_PREFIX = `/${API_VERSION}/employee/feedback`;
export const EMPLOYEE_PROFILE_SETTING_API = `/${API_VERSION}/employee/profile/setting`;

// GPS integrations
export const GPS_INTEGRATIONS_API = `/${API_VERSION}/gps_tracking_integrations`;
