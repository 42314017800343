import { FC } from 'react';

type Props = {
  className?: string;
};

const IconWideArrowUp: FC<Props> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.5138 15H5.50366C5.22751 15 5.00366 14.7761 5.00366 14.5V10.0015H1.86479C1.44609 10.0015 1.21283 9.51761 1.47361 9.19004L7.61844 1.47147C7.81551 1.22392 8.19007 1.21937 8.3931 1.46205L14.8507 9.18062C15.123 9.5061 14.8916 10.0015 14.4673 10.0015H11.0138V14.5C11.0138 14.7761 10.79 15 10.5138 15Z"
        stroke="#86898E"
        strokeWidth="2"
      />
    </svg>
  );
};

export default IconWideArrowUp;
