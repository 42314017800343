import {
  FC,
  PropsWithChildren,
  useRef,
  useEffect,
  memo,
  useState,
} from 'react';

import { Grow } from '@mui/material';
import clsx from 'clsx';
import noScroll from 'no-scroll';
import { createPortal } from 'react-dom';

import { IconClose } from 'assets/icons/components';
import useAttachEventListener from 'hooks/useAttachEventListener';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { getAttrName } from 'hooks/useTestAttrRef';

import { Dialog, DialogProps } from '../CustomMaterialComponents';

import styles from './Modal.module.scss';

type Props = PropsWithChildren<{
  isOpen?: boolean;
  onClose: () => void;
  closeOnOutsideClick?: boolean;
  className?: string;
  overlayClassName?: string;
  outsideElement?: boolean;
  withoutCloseBtn?: boolean;
  withoutOverlayCloseBtn?: boolean;
  hideOverlayOpacity?: boolean;
  modalHeader?: JSX.Element;
  dataTestAttr?: string;
}>;

const Modal: FC<Props> = ({
  closeOnOutsideClick = true,
  isOpen,
  onClose,
  children,
  className,
  overlayClassName,
  modalHeader,
  outsideElement = false,
  withoutCloseBtn = false,
  withoutOverlayCloseBtn = true,
  hideOverlayOpacity = false,
  dataTestAttr,
}) => {
  const modalNode = document.getElementById('modal') as HTMLElement;
  const frmRef = useRef<HTMLElement>(null);

  const [headerText, setHeaderText] = useState<string>();

  useEffect(() => {
    if (frmRef.current) {
      const header = frmRef.current.querySelector('h2');
      if (header?.textContent) {
        setHeaderText(getAttrName(header.textContent));
      }
    }
  }, [frmRef.current, children, isOpen]);

  useOnClickOutside({
    ref: frmRef,
    callback: onClose,
    listen: closeOnOutsideClick,
  });

  const onKeyDownHandler = (e: KeyboardEvent) => {
    if (e.key === 'Escape') onClose();
  };

  useAttachEventListener('keydown', onKeyDownHandler);

  useEffect(() => {
    if (isOpen) {
      noScroll.on();
    }
    return () => {
      noScroll.off();
    };
  }, [isOpen]);

  const modalComponent = (
    <>
      <div
        className={clsx(styles.modalOverlay, overlayClassName, {
          [styles.upperLevel]: !!outsideElement,
          [styles.noOverlayOpacity]: hideOverlayOpacity,
        })}
      />

      {!withoutOverlayCloseBtn && (
        <IconClose onClick={onClose} className={styles.overlayCloseIcon} />
      )}

      <div
        className={clsx(styles.modalWrapper, {
          [styles.upperLevel]: !!outsideElement,
        })}
      >
        <Grow in>
          <aside
            className={clsx(styles.modal, className)}
            ref={frmRef}
            data-test={
              headerText
                ? `${headerText}_modal_form`
                : dataTestAttr
                ? `${dataTestAttr}_modal_form`
                : 'modal_form'
            }
          >
            {modalHeader}
            {!withoutCloseBtn && (
              <button
                className={styles.closeBtn}
                data-test="close_button"
                tabIndex={-1}
                onClick={onClose}
              >
                <IconClose />
              </button>
            )}

            {children}
          </aside>
        </Grow>
      </div>
    </>
  );

  if (!isOpen) return null;

  return outsideElement
    ? createPortal(modalComponent, modalNode)
    : modalComponent;
};

export default memo(Modal);

type ModalProps = DialogProps & {
  onClose: () => void;
  className?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  classes?: { paper: string };
  dataTestAttr?: string;
};

const ModalContent = (props: ModalProps) => {
  const { onClose, className, children, dataTestAttr } = props;

  const frmRef = useRef<HTMLDivElement>(null);

  const [headerText, setHeaderText] = useState<string>();

  useEffect(() => {
    if (frmRef.current) {
      const header = frmRef.current.querySelector('h2');
      if (header?.textContent) {
        setHeaderText(getAttrName(header.textContent));
      }
    }
  }, [frmRef.current, children]);

  return (
    <div
      className={clsx(styles.root, className)}
      ref={frmRef}
      data-test={
        headerText
          ? `${headerText}_modal_form`
          : dataTestAttr
          ? `${dataTestAttr}_modal_form`
          : 'modal_form'
      }
    >
      <button
        className={styles.closeBtn}
        data-test="close_button"
        tabIndex={-1}
        onClick={onClose}
      >
        <IconClose />
      </button>
      {children}
    </div>
  );
};

export const ModalDialog: FC<ModalProps> = memo(props => {
  const { className, dataTestAttr, onClose, children, classes, ...restProps } =
    props;
  return (
    <Dialog {...restProps} onClose={onClose} classes={classes}>
      <ModalContent {...props} />
    </Dialog>
  );
});
