import { FC } from 'react';

import { useSelector } from 'react-redux';

import { userThemeSelector } from 'redux/userSettings/userSettingsSelectors';

type Props = {
  className?: string;
  fillColor?: string;
};

const LittleTrailer: FC<Props> = ({ className, fillColor = '#2C6AF6' }) => {
  const theme = useSelector(userThemeSelector);

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15 13C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11V13ZM2 11V3H0V11H2ZM13 13H15V11H13V13ZM4 11H2V13H4V11ZM13 11H8V13H13V11ZM12 3H14C14 1.89543 13.1046 1 12 1V3ZM2 3V3V1C0.89543 1 0 1.89543 0 3H2ZM0 11C0 12.1046 0.895431 13 2 13V11H2H0ZM7 12C7 12.5523 6.55228 13 6 13V15C7.65685 15 9 13.6569 9 12H7ZM6 13C5.44772 13 5 12.5523 5 12H3C3 13.6569 4.34315 15 6 15V13ZM5 12C5 11.4477 5.44772 11 6 11V9C4.34315 9 3 10.3431 3 12H5ZM6 11C6.55228 11 7 11.4477 7 12H9C9 10.3431 7.65685 9 6 9V11ZM2 3H12V1H2V3ZM12 3V12H14V3H12Z"
        fill={theme === 'dark' ? 'white' : fillColor}
      />
    </svg>
  );
};

export default LittleTrailer;
