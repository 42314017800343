import { FC } from 'react';

interface IOwnProps {
  className?: string;
  onClick?: () => void;
}

const IconExclamationMark: FC<IOwnProps> = ({ onClick, className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M12.9928 15H3.0054C1.51963 15 0.552581 13.4373 1.21553 12.1076L6.20989 2.09042C6.94624 0.613522 9.05353 0.613637 9.78973 2.09061L14.7828 12.1078C15.4455 13.4375 14.4785 15 12.9928 15Z"
          stroke="#FF4A3C"
          strokeWidth="2"
        />
        <path
          d="M8 6V9"
          stroke="#FF4A3C"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <g filter="url(#filter0_d)">
          <circle cx="8" cy="12" r="0.5" stroke="#FF4A3C" />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="3"
          y="11"
          width="10"
          height="10"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <clipPath id="clip0">
          <path d="M0 0H16V16H0V0Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconExclamationMark;
