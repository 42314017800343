import {
  ADD_EMPLOYEE_TO_LOCATION,
  ADD_EMPLOYEE_TO_LOCATION_FAIL,
  ADD_EMPLOYEE_TO_LOCATION_SUCCESS,
  CREATE_LOCATION,
  CREATE_LOCATION_FAIL,
  CREATE_LOCATION_SUCCESS,
  DELETE_LOCATION,
  DELETE_LOCATION_FAIL,
  DELETE_LOCATION_FILE,
  DELETE_LOCATION_FILE_FAIL,
  DELETE_LOCATION_FILE_SUCCESS,
  DELETE_LOCATION_SUCCESS,
  GET_LOCATION_AVAILABLE_EMPLOYEES,
  GET_LOCATION_AVAILABLE_EMPLOYEES_FAIL,
  GET_LOCATION_AVAILABLE_EMPLOYEES_SUCCESS,
  GET_LOCATION_BY_ID,
  GET_LOCATION_BY_ID_FAIL,
  GET_LOCATION_BY_ID_SUCCESS,
  GET_LOCATION_INVITED_EMPLOYEES,
  GET_LOCATION_INVITED_EMPLOYEES_FAIL,
  GET_LOCATION_INVITED_EMPLOYEES_SUCCESS,
  GET_LOCATIONS,
  GET_LOCATIONS_FAIL,
  GET_LOCATIONS_SUCCESS,
  IAddEmployeeToLocationAction,
  IAddEmployeeToLocationFailAction,
  IAddEmployeeToLocationSuccessAction,
  IClearMainLocationsFormAction,
  ICreateLocationAction,
  ICreateLocationFailAction,
  ICreateLocationSuccessAction,
  IDeleteLocationAction,
  IDeleteLocationFailAction,
  IDeleteLocationFileAction,
  IDeleteLocationFileFailAction,
  IDeleteLocationFileSuccessAction,
  IDeleteLocationSuccessAction,
  IGetLocationAvailableEmployeesAction,
  IGetLocationAvailableEmployeesFailAction,
  IGetLocationAvailableEmployeesSuccessAction,
  IGetLocationByIdAction,
  IGetLocationByIdFailAction,
  IGetLocationByIdSuccessAction,
  IGetLocationInvitedEmployeesAction,
  IGetLocationInvitedEmployeesFailAction,
  IGetLocationInvitedEmployeesSuccessAction,
  IGetLocationsAction,
  IGetLocationsFailAction,
  IGetLocationsSuccessAction,
  ISearchLocationAction,
  IUpdateLocationAction,
  IUpdateLocationFailAction,
  IUpdateLocationSuccessAction,
  IUploadLocationFileAction,
  IUploadLocationFileFailAction,
  IUploadLocationFileSuccessAction,
  SEARCH_LOCATION,
  UNMOUNT_MAIN_FORM,
  UPDATE_LOCATION,
  UPDATE_LOCATION_FAIL,
  UPDATE_LOCATION_SUCCESS,
  UPLOAD_LOCATION_FILE,
  UPLOAD_LOCATION_FILE_FAIL,
  UPLOAD_LOCATION_FILE_SUCCESS,
  RESET_LOCATION_AVAILABLE_EMPLOYEES,
  IResetLocationAvailableEmployeesAction,
} from './locationsTypes';

// Create Location
export const createLocationAction = (
  payload: ICreateLocationAction['payload'],
): ICreateLocationAction => ({
  type: CREATE_LOCATION,
  payload,
});
export const createLocationSuccessAction = (
  payload: ICreateLocationSuccessAction['payload'],
): ICreateLocationSuccessAction => ({
  type: CREATE_LOCATION_SUCCESS,
  payload,
});
export const createLocationFailAction = (
  payload: ICreateLocationFailAction['payload'],
): ICreateLocationFailAction => ({
  type: CREATE_LOCATION_FAIL,
  payload,
});

// Delete Location
export const deleteLocationAction = (
  payload: IDeleteLocationAction['payload'],
): IDeleteLocationAction => ({
  type: DELETE_LOCATION,
  payload,
});
export const deleteLocationSuccessAction = (
  payload: IDeleteLocationSuccessAction['payload'],
): IDeleteLocationSuccessAction => ({
  type: DELETE_LOCATION_SUCCESS,
  payload,
});
export const deleteLocationFailAction = (
  payload: IDeleteLocationFailAction['payload'],
): IDeleteLocationFailAction => ({
  type: DELETE_LOCATION_FAIL,
  payload,
});

// Delete Employee From Location
export const addEmployeeToLocationAction = (
  payload: IAddEmployeeToLocationAction['payload'],
): IAddEmployeeToLocationAction => ({
  type: ADD_EMPLOYEE_TO_LOCATION,
  payload,
});
export const addEmployeeToLocationSuccessAction =
  (): IAddEmployeeToLocationSuccessAction => ({
    type: ADD_EMPLOYEE_TO_LOCATION_SUCCESS,
  });
export const addEmployeeToLocationFailAction = (
  payload: IAddEmployeeToLocationFailAction['payload'],
): IAddEmployeeToLocationFailAction => ({
  type: ADD_EMPLOYEE_TO_LOCATION_FAIL,
  payload,
});

// Update Location
export const updateLocationAction = (
  payload: IUpdateLocationAction['payload'],
): IUpdateLocationAction => ({
  type: UPDATE_LOCATION,
  payload,
});
export const updateLocationSuccessAction = (
  payload: IUpdateLocationSuccessAction['payload'],
): IUpdateLocationSuccessAction => ({
  type: UPDATE_LOCATION_SUCCESS,
  payload,
});
export const updateLocationFailAction = (
  payload: IUpdateLocationFailAction['payload'],
): IUpdateLocationFailAction => ({
  type: UPDATE_LOCATION_FAIL,
  payload,
});

// Upload Location files
export const uploadLocationFileAction = (
  payload: IUploadLocationFileAction['payload'],
): IUploadLocationFileAction => ({
  type: UPLOAD_LOCATION_FILE,
  payload,
});
export const uploadLocationFileSuccessAction = (
  payload: IUploadLocationFileSuccessAction['payload'],
): IUploadLocationFileSuccessAction => ({
  type: UPLOAD_LOCATION_FILE_SUCCESS,
  payload,
});
export const uploadLocationFileFailAction = (
  payload: IUploadLocationFileFailAction['payload'],
): IUploadLocationFileFailAction => ({
  type: UPLOAD_LOCATION_FILE_FAIL,
  payload,
});

// Delete Location files
export const deleteLocationFileAction = (
  payload: IDeleteLocationFileAction['payload'],
): IDeleteLocationFileAction => ({
  type: DELETE_LOCATION_FILE,
  payload,
});
export const deleteLocationFileSuccessAction = (
  payload: IDeleteLocationFileSuccessAction['payload'],
): IDeleteLocationFileSuccessAction => ({
  type: DELETE_LOCATION_FILE_SUCCESS,
  payload,
});
export const deleteLocationFileFailAction = (
  payload: IDeleteLocationFileFailAction['payload'],
): IDeleteLocationFileFailAction => ({
  type: DELETE_LOCATION_FILE_FAIL,
  payload,
});

// Unmount main form
export const clearMainLocationsFormAction =
  (): IClearMainLocationsFormAction => ({
    type: UNMOUNT_MAIN_FORM,
  });

// Get Locations list
export const getLocationsAction = (
  payload?: IGetLocationsAction['payload'],
): IGetLocationsAction => ({
  type: GET_LOCATIONS,
  payload,
});
export const getLocationsSuccessAction = (
  payload: IGetLocationsSuccessAction['payload'],
): IGetLocationsSuccessAction => ({
  type: GET_LOCATIONS_SUCCESS,
  payload,
});
export const getLocationsFailAction = (
  payload: IGetLocationsFailAction['payload'],
): IGetLocationsFailAction => ({
  type: GET_LOCATIONS_FAIL,
  payload,
});

// Get Location Available Employees list
export const resetLocationAvailableEmployeesAction =
  (): IResetLocationAvailableEmployeesAction => ({
    type: RESET_LOCATION_AVAILABLE_EMPLOYEES,
  });
export const getLocationAvailableEmployeesAction = (
  payload: IGetLocationAvailableEmployeesAction['payload'],
): IGetLocationAvailableEmployeesAction => ({
  type: GET_LOCATION_AVAILABLE_EMPLOYEES,
  payload,
});
export const getLocationAvailableEmployeesSuccessAction = (
  payload: IGetLocationAvailableEmployeesSuccessAction['payload'],
): IGetLocationAvailableEmployeesSuccessAction => ({
  type: GET_LOCATION_AVAILABLE_EMPLOYEES_SUCCESS,
  payload,
});
export const getLocationAvailableEmployeesFailAction = (
  payload: IGetLocationAvailableEmployeesFailAction['payload'],
): IGetLocationAvailableEmployeesFailAction => ({
  type: GET_LOCATION_AVAILABLE_EMPLOYEES_FAIL,
  payload,
});

// Get Invited Location Employees list
export const getLocationInvitedEmployeesAction = (
  payload: IGetLocationInvitedEmployeesAction['payload'],
): IGetLocationInvitedEmployeesAction => ({
  type: GET_LOCATION_INVITED_EMPLOYEES,
  payload,
});
export const getLocationInvitedEmployeesSuccessAction = (
  payload: IGetLocationInvitedEmployeesSuccessAction['payload'],
): IGetLocationInvitedEmployeesSuccessAction => ({
  type: GET_LOCATION_INVITED_EMPLOYEES_SUCCESS,
  payload,
});
export const getLocationInvitedEmployeesFailAction = (
  payload: IGetLocationInvitedEmployeesFailAction['payload'],
): IGetLocationInvitedEmployeesFailAction => ({
  type: GET_LOCATION_INVITED_EMPLOYEES_FAIL,
  payload,
});

// Search location
export const searchLocationAction = (): ISearchLocationAction => ({
  type: SEARCH_LOCATION,
});

// Get Location by ID
export const getLocationByIdAction = (
  payload: IGetLocationByIdAction['payload'],
): IGetLocationByIdAction => ({
  type: GET_LOCATION_BY_ID,
  payload,
});
export const getLocationByIdSuccessAction = (
  payload: IGetLocationByIdSuccessAction['payload'],
): IGetLocationByIdSuccessAction => ({
  type: GET_LOCATION_BY_ID_SUCCESS,
  payload,
});
export const getLocationByIdFailAction = (
  payload: IGetLocationByIdFailAction['payload'],
): IGetLocationByIdFailAction => ({
  type: GET_LOCATION_BY_ID_FAIL,
  payload,
});
