import { FC } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
  onClick?: () => void;
}

const IconUserPhoto: FC<IOwnProps> = ({
  onClick,
  className,
  fillColor = '#86898E',
}) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M16 18C7.6514 18 4.94619 23.4832 4.06962 27.0367C3.67217 28.6479 5.03183 30 6.73708 30H25.2629C26.9683 30 28.3278 28.6479 27.9304 27.0367C27.0538 23.4832 24.3486 18 16 18Z"
        stroke={fillColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M16 14C19.3137 14 22 11.3137 22 8C22 4.68629 19.3137 2 16 2C12.6863 2 10 4.68629 10 8C10 11.3137 12.6863 14 16 14Z"
        stroke={fillColor}
        strokeWidth="2"
      />
    </svg>
  );
};

export default IconUserPhoto;
