import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_MAP_URL } from 'components/_common/Map/_config';
import { initGoogleScriptAction } from 'services/google/_redux/googleActions';
import { googleSelector } from 'services/google/_redux/googleSelectors';
import { appendLoadScript } from 'utils/helpers';

/*
  Loads Google Maps and sets flag to the google redux field
*/
export default function useGoogleMapsLoad() {
  // Hooks
  const dispatch = useDispatch();

  // Selectors
  const { isInitGoogleAPIs } = useSelector(googleSelector);

  // Effects
  useEffect(() => {
    if (!window.google && !isInitGoogleAPIs)
      appendLoadScript(DEFAULT_MAP_URL, () =>
        dispatch(initGoogleScriptAction(true)),
      );
  }, [dispatch]);
}
