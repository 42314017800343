import { lazy } from 'react';

import {
  PERMISSION_TRUCK,
  PERMISSION_TRAILER,
  PERMISSION_GEOFENCE,
  PERMISSION_TOLLS,
  PERMISSION_VEHICLE,
  PERMISSION_CLAIMS,
  PERMISSION_WORK_ORDER_LIST,
  PERMISSION_CREATE_WORK_ORDER,
  PERMISSION_EDIT_WORK_ORDER,
} from 'constants/permissions';

import { IPermissionObject } from '../index';
import PrivateRoutes, { redirectRoute } from '../PrivateRoutes/PrivateRoutes';
import PublicRoutes from '../PublicRoutes/PublicRoutes';

// const CreateTruck = lazy(
//   () =>
//     import('components/Fleet/TabTrucks/TruckMainForm/CreateTruck/CreateTruck'),
// );

const TrailerProfile = lazy(
  () => import('components/Fleet/TabTrailers/TrailerProfile/TrailerProfile'),
);
const TruckProfile = lazy(
  () => import('components/Fleet/TabTrucks/TruckProfile/TruckProfile'),
);
const CreateAndUpdateTruck = lazy(
  () =>
    import(
      'components/Fleet/TabTrucks/TruckMainForm/CreateAndUpdateTruck/CreateAndUpdateTruck'
    ),
);
const ImportTruck = lazy(
  () => import('components/Fleet/TabTrucks/ImportTruck/ImportTruck'),
);
const ImportTrailer = lazy(
  () => import('components/Fleet/TabTrailers/ImportTrailer/ImportTrailer'),
);
const EditSettings = lazy(
  () =>
    import(
      'components/WiseCheck/TabSettings/SettingsProfile/EditSettingsProfile/EditSettingsProfile'
    ),
);
const TruckPlanningReviewCreate = lazy(
  () =>
    import(
      'components/Fleet/TabTrucks/TruckProfile/TruckCreatePlanningReview/TruckCreatePlanningReview'
    ),
);

const UpdateTrailerInspection = lazy(
  () =>
    import(
      'components/Fleet/TabTrailers/TrailerProfile/TrailerInspections/TrailerInspectionsMainForm/UpdateTrailerInspection/UpdateTrailerInspection'
    ),
);
const CreateAndUpdateTrailer = lazy(
  () =>
    import(
      'components/Fleet/TabTrailers/MainTrailerForm/CreateAndUpdateTrailer/CreateAndUpdateTrailer'
    ),
);
const GeoFenceAlerts = lazy(
  () => import('components/Fleet/TabGeoFences/GeoFenceAlerts/GeoFenceAlerts'),
);
const UpdateTruckInspection = lazy(
  () =>
    import(
      'components/Fleet/TabTrucks/TruckProfile/TruckInspections/TruckInspectionsMainForm/UpdateTruckInspection/UpdateTruckInspection'
    ),
);
const UpdateTruckReview = lazy(
  () =>
    import(
      'components/Fleet/TabTrucks/TruckProfile/TruckMaintenance/UpdateTruckReview/UpdateTruckReview'
    ),
);
const TollProfile = lazy(
  () => import('components/Fleet/TabTolls/TollProfile/TollProfile'),
);
const TollsStatisticsType = lazy(
  () =>
    import(
      'components/Fleet/TabTolls/TollsStatistics/TollsStatisticsType/TollsStatisticsType'
    ),
);
const PublicInspection = lazy(
  () => import('components/Fleet/PublicInspection/PublicInspection'),
);
const Trucks = lazy(() => import('components/Fleet/TabTrucks/Trucks'));
const AllTrucksMaintenance = lazy(
  () => import('components/Fleet/AllTrucksMaintenance/AllTrucksMaintenance'),
);
const Vehicles = lazy(() => import('components/Fleet/TabVehicles/Vehicles'));
const Trailers = lazy(() => import('components/Fleet/TabTrailers/Trailers'));
const GeoFences = lazy(() => import('components/Fleet/TabGeoFences/GeoFences'));

const Claims = lazy(() => import('components/Fleet/TabClaims/Claims'));
const CreateClaim = lazy(
  () => import('components/Fleet/TabClaims/CreateUpdateClaim/CreateClaim'),
);
const ClaimProfile = lazy(
  () => import('components/Fleet/TabClaims/ClaimProfile/ClaimProfile'),
);

const Tolls = lazy(() => import('components/Fleet/TabTolls/Tolls'));
const Inspections = lazy(
  () => import('components/Fleet/TabInspections/Inspections'),
);
const UpdateTandemInspection = lazy(
  () =>
    import(
      'components/Fleet/TabInspections/TandemInspectionTab/UpdateTandemInspection'
    ),
);
const TruckInspectionProfile = lazy(
  () =>
    import(
      'components/Fleet/TabInspections/InspectionDetails/TruckInspectionProfile/TruckInspectionProfile'
    ),
);
const TrailerInspectionProfile = lazy(
  () =>
    import(
      'components/Fleet/TabInspections/InspectionDetails/TrailerInspectionProfile/TrailerInspectionProfile'
    ),
);
const Requests = lazy(() => import('components/Fleet/TabRequests/Requests'));
const Dashboard = lazy(() => import('components/Fleet/TabDashboard/Dashboard'));
const SettingsProfile = lazy(
  () =>
    import('components/WiseCheck/TabSettings/SettingsProfile/SettingsProfile'),
);

const SubscriptionPlans = lazy(
  () =>
    import(
      'components/WiseCheck/TabSettings/SettingsProfile/SubscriptionPlans/SubscriptionPlans'
    ),
);

const WorkOrders = lazy(
  () => import('components/Fleet/TabWorkOrders/WorkOrders'),
);

const AddEditWorkOrder = lazy(
  () =>
    import(
      'components/Fleet/TabWorkOrders/CreateAndUpdateWorkOrder/CreateAndUpdateWorkOrder'
    ),
);

const AddBulkWorkOrders = lazy(
  () =>
    import('components/Fleet/TabWorkOrders/CreateBulkOrders/CreateBulkOrders'),
);

const getRedirectURL = (permissions?: string[]) => {
  if (permissions?.includes(PERMISSION_TRUCK)) return '/fleet/trucks';
  if (permissions?.includes(PERMISSION_TRAILER)) return '/fleet/trailers';
  if (permissions?.includes(PERMISSION_GEOFENCE)) return '/fleet/geofences';
  if (permissions?.includes(PERMISSION_TOLLS)) return '/fleet/tolls';
  if (permissions?.includes(PERMISSION_VEHICLE)) return '/fleet/vehicles';

  return '/';
};

/* Fleet Main Page (redirect into) */
export const mainFleetRoute = (permissions?: IPermissionObject[]) => {
  const targetName = 'Fleet Management';
  const targetObject = permissions?.find(obj => obj.name === targetName);
  return redirectRoute(
    '/fleet',
    getRedirectURL(targetObject ? targetObject.permissions : []),
  );
};

/* Dashboard */
export const dashboardRoute = (
  <PrivateRoutes
    exact
    path="/fleet/dashboard"
    component={Dashboard}
    permissionType={PERMISSION_TRUCK}
  />
);

// WISECHECK
export const settingsRoute = (
  <PrivateRoutes
    exact
    path="/fleet/settings"
    component={SettingsProfile}
    withPermission={false}
  />
);
export const settingsEditRoute = (
  <PrivateRoutes
    exact
    path="/fleet/settings/edit"
    component={EditSettings}
    withPermission={false}
  />
);
export const subscriptionPlansRoute = (
  <PrivateRoutes
    exact
    path="/fleet/settings/plans"
    component={SubscriptionPlans}
    withPermission={false}
  />
);

/* Claims */
export const mainClaimsRoute = (
  <PrivateRoutes
    exact
    path="/fleet/claims"
    component={Claims}
    permissionType={PERMISSION_CLAIMS}
  />
);

export const claimsCreateRoute = (
  <PrivateRoutes
    exact
    path="/fleet/claims/create"
    component={CreateClaim}
    permissionType={PERMISSION_CLAIMS}
  />
);

export const claimsProfileRoute = (
  <PrivateRoutes
    exact
    path="/fleet/claims/:id"
    component={ClaimProfile}
    permissionType={PERMISSION_CLAIMS} // TODO
  />
);

/* GeoFences */
export const mainGeofencesRoute = (
  <PrivateRoutes
    exact
    path="/fleet/geofences"
    component={GeoFences}
    permissionType={PERMISSION_GEOFENCE}
  />
);
export const geofencesAlertsRoute = (
  <PrivateRoutes
    exact
    path="/fleet/geofences/:id/alerts"
    component={GeoFenceAlerts}
    permissionType={PERMISSION_GEOFENCE}
  />
);

/* Tolls */
export const mainTollsRoute = (
  <PrivateRoutes
    exact
    path="/fleet/tolls"
    component={Tolls}
    permissionType={PERMISSION_TOLLS}
  />
);
export const tollProfileRoute = (
  <PrivateRoutes
    exact
    path="/fleet/tolls/:id"
    component={TollProfile}
    permissionType={PERMISSION_TOLLS}
  />
);
export const tollsStatisticsRoute = (
  <PrivateRoutes
    exact
    path="/fleet/tolls/statistics/:type"
    component={TollsStatisticsType}
    permissionType={PERMISSION_TOLLS}
  />
);

/* Trucks */
export const mainTrucksRoute = (
  <PrivateRoutes
    exact
    path="/fleet/trucks"
    component={Trucks}
    permissionType={PERMISSION_TRUCK}
  />
);
export const truckImportRoute = (
  <PrivateRoutes
    exact
    path="/fleet/trucks/import"
    component={ImportTruck}
    permissionType={PERMISSION_TRUCK}
  />
);
export const trailerImportRoute = (
  <PrivateRoutes
    exact
    path="/fleet/trailers/import"
    component={ImportTrailer}
    permissionType={PERMISSION_TRAILER}
  />
);
export const truckCreateRoute = (
  <PrivateRoutes
    exact
    path="/fleet/trucks/create"
    component={CreateAndUpdateTruck}
    permissionType={PERMISSION_TRUCK}
  />
);
export const truckProfileRoute = (
  <PrivateRoutes
    exact
    path="/fleet/trucks/:id"
    component={TruckProfile}
    permissionType={PERMISSION_TRUCK}
  />
);
export const truckEditRoute = (
  <PrivateRoutes
    exact
    path="/fleet/trucks/:id/edit"
    component={CreateAndUpdateTruck}
    permissionType={PERMISSION_TRUCK}
  />
);
export const truckInspectionEditRoute = (
  <PrivateRoutes
    exact
    path="/fleet/inspections/edit/trucks/:truckId/:inspectionId"
    component={UpdateTruckInspection}
    permissionType={PERMISSION_TRUCK}
  />
);
export const truckReviewCreateRoute = (
  <PrivateRoutes
    exact
    path="/fleet/trucks/:id/review-create"
    component={TruckPlanningReviewCreate}
    permissionType={PERMISSION_TRUCK}
  />
);
export const truckReviewEditRoute = (
  <PrivateRoutes
    exact
    path="/fleet/trucks/:truckId/review-update/:id/:formType"
    component={UpdateTruckReview}
    permissionType={PERMISSION_TRUCK}
  />
);

/* Trucks Mainteances */
export const truckMaintenancesRoute = (
  <PrivateRoutes
    exact
    path="/fleet/trucks-maintenances"
    component={AllTrucksMaintenance}
    permissionType={PERMISSION_TRUCK}
  />
);

/* Vehicles */
export const mainVehiclesRoute = (
  <PrivateRoutes
    exact
    path="/fleet/vehicles"
    component={Vehicles}
    permissionType={PERMISSION_VEHICLE}
  />
);
/* Requests */
export const mainRequestsRoute = (
  <PrivateRoutes
    exact
    path="/fleet/requests"
    component={Requests}
    permissionType={PERMISSION_TRAILER}
  />
);

/* Inspections */
export const mainInspectionsRoute = (
  <PrivateRoutes
    exact
    path={[
      '/fleet/inspections/all',
      '/fleet/inspections/edit',
      '/fleet/inspections/trucks',
      '/fleet/inspections/trailers',
      '/fleet/inspections/drivers',
      '/fleet/inspections/alerts',
    ]}
    component={Inspections}
    // TODO: change permission to PERMISSION_INSPECTIONS when BE will be ready
    permissionType={PERMISSION_TRAILER}
  />
);
export const truckInspectionProfileRoute = (
  <PrivateRoutes
    path="/fleet/inspections/trucks/:id"
    component={TruckInspectionProfile}
    // TODO: change permission to PERMISSION_INSPECTIONS when BE will be ready
    permissionType={PERMISSION_TRAILER}
  />
);
export const trailerInspectionProfileRoute = (
  <PrivateRoutes
    path="/fleet/inspections/trailers/:id"
    component={TrailerInspectionProfile}
    // TODO: change permission to PERMISSION_INSPECTIONS when BE will be ready
    permissionType={PERMISSION_TRAILER}
  />
);

/* Trailers */
export const mainTrailersRoute = (
  <PrivateRoutes
    exact
    path="/fleet/trailers"
    component={Trailers}
    permissionType={PERMISSION_TRAILER}
  />
);
export const trailerCreateRoute = (
  <PrivateRoutes
    exact
    path="/fleet/trailers/create"
    component={CreateAndUpdateTrailer}
    permissionType={PERMISSION_TRAILER}
  />
);
export const trailerProfileRoute = (
  <PrivateRoutes
    exact
    path="/fleet/trailers/:id"
    component={TrailerProfile}
    permissionType={PERMISSION_TRAILER}
  />
);
export const trailerEditRoute = (
  <PrivateRoutes
    exact
    path="/fleet/trailers/:id/edit"
    component={CreateAndUpdateTrailer}
    permissionType={PERMISSION_TRAILER}
  />
);
export const trailerInspectionEditRoute = (
  <PrivateRoutes
    exact
    path="/fleet/inspections/edit/trailers/:trailerId/:inspectionId"
    component={UpdateTrailerInspection}
    permissionType={PERMISSION_TRAILER}
  />
);
export const tandemInspectionUpdateRoute = (
  <PrivateRoutes
    exact
    path="/fleet/inspection/tandem/:chiefType/:chiefId/:slaveType/:slaveId/:tandemId"
    component={UpdateTandemInspection}
    permissionType={PERMISSION_TRAILER}
  />
);

export const publicInspectionRoute = (
  <PublicRoutes
    exact
    path="/inspections/show"
    component={PublicInspection}
    withPermission={false}
  />
);

export const workOrderRoute = (
  <PrivateRoutes
    exact
    path="/fleet/work-orders"
    component={WorkOrders}
    permissionType={PERMISSION_WORK_ORDER_LIST}
  />
);

export const addWorkOrderRoute = (
  <PrivateRoutes
    exact
    path="/fleet/work-orders/create"
    component={AddEditWorkOrder}
    permissionType={PERMISSION_CREATE_WORK_ORDER}
  />
);

export const editWorkOrderRoute = (
  <PrivateRoutes
    exact
    path="/fleet/work-orders/edit/:id"
    component={AddEditWorkOrder}
    permissionType={PERMISSION_EDIT_WORK_ORDER}
  />
);

export const addBulkOrdersRoute = (
  <PrivateRoutes
    exact
    path="/fleet/work-orders/bulk/create"
    component={AddBulkWorkOrders}
    permissionType={PERMISSION_CREATE_WORK_ORDER}
  />
);
