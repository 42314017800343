import { startSubmit, stopSubmit, change } from 'redux-form';
import {
  call,
  put,
  takeLatest,
  select,
  takeLeading,
  takeEvery,
} from 'redux-saga/effects';

import {
  setSearchResultAction,
  setSearchResultSuccessAction,
  setSearchResultFailAction,
} from 'components/_common/SearchPanel/_redux/searchActions';
import { searchSelector } from 'components/_common/SearchPanel/_redux/searchSelectors';
import { MAX_SEARCH_RESULT } from 'components/_common/SearchPanel/constants';
import { userSettingsSelector } from 'redux/userSettings/userSettingsSelectors';
import { fileToFormData } from 'utils/converters';
import { getErrors } from 'utils/helpers';
import api from 'utils/requests';

import {
  uploadApplicantFilesAction,
  getApplicantAction,
  updateApplicantAction,
  uploadApplicantPhotoAction,
  deleteApplicantPhotoAction,
  changeApplicantFileCategoryAction,
  deleteApplicantFileAction,
  hireDriverAction,
  setApplicantCheckAction,
  assignChecksToLeadAction,
} from './applicantsActions';
import {
  GET_APPLICANT,
  UPDATE_APPLICANT,
  UPLOAD_APPLICANT_FILES,
  DELETE_APPLICANT_FILE,
  CHANGE_APPLICANT_FILE_CATEGORY,
  UPLOAD_APPLICANT_PHOTO,
  DELETE_APPLICANT_PHOTO,
  GET_APPLICANT_SUCCESS,
  GET_APPLICANT_FAIL,
  UPDATE_APPLICANT_SUCCESS,
  UPDATE_APPLICANT_FAIL,
  UPLOAD_APPLICANT_PHOTO_SUCCESS,
  UPLOAD_APPLICANT_PHOTO_FAIL,
  DELETE_APPLICANT_PHOTO_SUCCESS,
  DELETE_APPLICANT_PHOTO_FAIL,
  UPLOAD_APPLICANT_FILES_FAIL,
  UPLOAD_APPLICANT_FILES_SUCCESS,
  CHANGE_APPLICANT_FILE_CATEGORY_SUCCESS,
  CHANGE_APPLICANT_FILE_CATEGORY_FAIL,
  DELETE_APPLICANT_FILE_SUCCESS,
  DELETE_APPLICANT_FILE_FAIL,
  SEARCH_APPLICANTS,
  HIRE_APPLICANT_SUCCESS,
  HIRE_APPLICANT_FAIL,
  HIRE_APPLICANT,
  SET_APPLICANT_CHECK,
  SET_APPLICANT_CHECK_SUCCESS,
  SET_APPLICANT_CHECK_FAIL,
  ASSIGN_CHECKS_TO_APPLICANT,
  ASSIGN_CHECKS_TO_APPLICANT_SUCCESS,
  ASSIGN_CHECKS_TO_APPLICANT_FAIL,
} from './applicantsTypes';
import {
  transformApplicantsSearchResult,
  applicantFileToFormData,
  applicantFileCategoryToFormData,
} from './helpers';

function* searchApplicantsSaga() {
  const { searchValue } = yield select(searchSelector);
  if (searchValue)
    try {
      const { driver_types } = yield select(userSettingsSelector);
      yield put(setSearchResultAction([]));
      const { data } = yield call(() =>
        api.get(
          `/v1/fleets/applicants?page=1&per_page=${MAX_SEARCH_RESULT}&search=${
            searchValue || ''
          }`,
        ),
      );
      const { applicants } = data;

      yield put(
        setSearchResultSuccessAction(
          transformApplicantsSearchResult(applicants, driver_types),
        ),
      );
    } catch (e) {
      if (api.isAxiosError(e)) yield put(setSearchResultFailAction(e));
    }
}

function* getApplicantSaga({ id }: ReturnType<typeof getApplicantAction>) {
  try {
    const { data } = yield call(() => api.get(`/v1/fleets/applicants/${id}`));
    yield put({ type: GET_APPLICANT_SUCCESS, payload: data.applicant });
  } catch (e) {
    yield put({ type: GET_APPLICANT_FAIL, payload: e });
  }
}

function* hireDriverSaga({
  id,
  callback,
}: ReturnType<typeof hireDriverAction>) {
  try {
    yield put(startSubmit('hireOnDriverForm'));
    const { data } = yield call(() =>
      api.post(`/v1/fleets/applicants/${id}/hire`, {
        hire_driver_attributes: {},
      }),
    );
    yield put({ type: HIRE_APPLICANT_SUCCESS, payload: data });
    yield put(stopSubmit('hireOnDriverForm'));

    if (callback) callback(data.driver.id);
  } catch (e) {
    yield put({ type: HIRE_APPLICANT_FAIL, payload: e });
    if (api.isAxiosError(e))
      yield put(stopSubmit('hireOnDriverForm', getErrors(e)));
  }
}

function* updateApplicantSaga({
  payload: { callback, values },
}: ReturnType<typeof updateApplicantAction>) {
  try {
    yield put(startSubmit('createAndUpdateApplicantForm'));
    yield call(() =>
      api.patch(`/v1/fleets/applicants/${values.id}`, {
        fleets_applicant: values,
      }),
    );
    yield put({ type: UPDATE_APPLICANT_SUCCESS });
    yield put(stopSubmit('createAndUpdateApplicantForm'));
    if (callback) {
      callback();
    }
  } catch (e) {
    yield put({ type: UPDATE_APPLICANT_FAIL, payload: e });
    if (api.isAxiosError(e))
      yield put(stopSubmit('createAndUpdateApplicantForm', getErrors(e)));
  }
}

function* assignChecksSaga({
  payload: id,
}: ReturnType<typeof assignChecksToLeadAction>) {
  try {
    yield call(() =>
      api.patch(`/v1/fleets/applicants/${id}`, {
        fleets_applicant: {
          status: 'pending',
        },
      }),
    );
    yield put({ type: ASSIGN_CHECKS_TO_APPLICANT_SUCCESS });
  } catch (e) {
    yield put({ type: ASSIGN_CHECKS_TO_APPLICANT_FAIL, payload: e });
  }
}

// APPLICANT PHOTO START
function* uploadApplicantPhotoSaga({
  payload: { applicantId, photo, callback },
}: ReturnType<typeof uploadApplicantPhotoAction>) {
  const url = applicantId
    ? `/v1/fleets/applicants/${applicantId}/photo`
    : `/v1/fleets/applicants/photos`;

  try {
    const { data } = yield call(() =>
      api.post(url, fileToFormData(photo, 'fleets_applicants_photo[file]'), {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    );
    const uploadedPhoto = { id: data.file.id, url: data.file.urls.thumb };
    yield put(change('createAndUpdateApplicantForm', 'photo', uploadedPhoto));
    yield put({ type: UPLOAD_APPLICANT_PHOTO_SUCCESS });

    if (callback) yield put(callback);
  } catch (e) {
    yield put({ type: UPLOAD_APPLICANT_PHOTO_FAIL, payload: e });
  }
}

function* deleteApplicantPhotoSaga({
  payload: { applicantId, photoId, callback },
}: ReturnType<typeof deleteApplicantPhotoAction>) {
  const url = applicantId
    ? `/v1/fleets/applicants/${applicantId}/photo`
    : `/v1/fleets/applicants/photos/${photoId}`;

  try {
    yield call(() => api.delete(url));
    yield put(change('createAndUpdateApplicantForm', 'photo', ''));
    yield put({ type: DELETE_APPLICANT_PHOTO_SUCCESS });

    if (callback) yield put(callback);
  } catch (e) {
    yield put({ type: DELETE_APPLICANT_PHOTO_FAIL, payload: e });
  }
}
// APPLICANT PHOTO END

// APPLICANT FILES START
function* uploadApplicantFilesSaga({
  payload: { itemId: applicantId, files },
}: ReturnType<typeof uploadApplicantFilesAction>) {
  const url = applicantId
    ? `/v1/fleets/applicants/${applicantId}/files`
    : `/v1/fleets/applicants/files`;

  for (const fileObj of files) {
    const { file, fileCategory } = fileObj;
    yield call(function* foo() {
      try {
        const { data } = yield call(() =>
          api.post(
            url,
            applicantFileToFormData({ file, fileCategory: fileCategory || 0 }),
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            },
          ),
        );
        yield put({ type: UPLOAD_APPLICANT_FILES_SUCCESS, payload: data.file });
      } catch (e) {
        yield put({ type: UPLOAD_APPLICANT_FILES_FAIL, payload: e });
      }
    });
  }
}

function* changeApplicantFileCategorySaga({
  payload: { fileId, fileCategory, applicantId },
}: ReturnType<typeof changeApplicantFileCategoryAction>) {
  const url = applicantId
    ? `/v1/fleets/applicants/${applicantId}/files/${fileId}`
    : `/v1/fleets/applicants/files/${fileId}`;

  try {
    const { data } = yield call(() =>
      api.patch(url, applicantFileCategoryToFormData(fileCategory), {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    );

    yield put({
      type: CHANGE_APPLICANT_FILE_CATEGORY_SUCCESS,
      payload: data.file,
    });
  } catch (e) {
    yield put({ type: CHANGE_APPLICANT_FILE_CATEGORY_FAIL, payload: e });
  }
}

function* deleteApplicantFileSaga({
  payload: { fileId, itemId: applicantId },
}: ReturnType<typeof deleteApplicantFileAction>) {
  const url = applicantId
    ? `/v1/fleets/applicants/${applicantId}/files/${fileId}`
    : `/v1/fleets/applicants/files/${fileId}`;

  try {
    yield call(() => api.delete(url));
    yield put({ type: DELETE_APPLICANT_FILE_SUCCESS, payload: fileId });
  } catch (e) {
    yield put({ type: DELETE_APPLICANT_FILE_FAIL, payload: e });
  }
}
// APPLICANT FILES END

function* setApplicantCheckSaga({
  payload,
  callback,
}: ReturnType<typeof setApplicantCheckAction>) {
  const { applicant_id, id, type_name, result, comment, date } = payload;

  const url = id
    ? `/v1/fleets/applicants/${applicant_id}/checks/${id}`
    : `/v1/fleets/applicants/${applicant_id}/checks`;

  const dataToDispatch = {
    fleets_applicants_check: {
      type_name,
      result,
      comment,
      date,
    },
  };

  try {
    const { data } = yield call(() =>
      id ? api.patch(url, dataToDispatch) : api.post(url, dataToDispatch),
    );
    yield put({
      type: SET_APPLICANT_CHECK_SUCCESS,
      payload: { ...data, type_name },
    });

    if (callback) callback();
  } catch (e) {
    yield put({ type: SET_APPLICANT_CHECK_FAIL, payload: e });
  }
}

export default function* applicantsSaga() {
  yield takeLeading(SEARCH_APPLICANTS, searchApplicantsSaga);
  yield takeLatest(GET_APPLICANT, getApplicantSaga);
  yield takeLatest(UPDATE_APPLICANT, updateApplicantSaga);
  yield takeLatest(ASSIGN_CHECKS_TO_APPLICANT, assignChecksSaga);
  yield takeLatest(UPLOAD_APPLICANT_FILES, uploadApplicantFilesSaga);
  yield takeEvery(DELETE_APPLICANT_FILE, deleteApplicantFileSaga);
  yield takeEvery(
    CHANGE_APPLICANT_FILE_CATEGORY,
    changeApplicantFileCategorySaga,
  );
  yield takeLatest(UPLOAD_APPLICANT_PHOTO, uploadApplicantPhotoSaga);
  yield takeLeading(DELETE_APPLICANT_PHOTO, deleteApplicantPhotoSaga);
  yield takeLeading(HIRE_APPLICANT, hireDriverSaga);
  yield takeLeading(SET_APPLICANT_CHECK, setApplicantCheckSaga);
}
