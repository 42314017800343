import { call, put, takeLeading } from 'redux-saga/effects';

import api from 'utils/requests';

import {
  matchImportFileFieldsAction,
  startImportFileCreateAction,
  uploadImportFilesAction,
} from './importFilesActions';
import {
  IImportFileActionFail,
  IImportFileMatchFieldsActionFail,
  IImportFileStartCreateActionFail,
  IMPORT_FILE,
  IMPORT_FILE_FAIL,
  IMPORT_FILE_MATCH_FIELDS,
  IMPORT_FILE_MATCH_FIELDS_FAIL,
  IMPORT_FILE_START_CREATE,
  IMPORT_FILE_START_CREATE_FAIL,
  IMPORT_FILE_SUCCESS,
} from './importFilesTypes';

function* uploadImportFileSaga({
  payload: { file, type, callback },
}: ReturnType<typeof uploadImportFilesAction>) {
  const formData = new FormData();

  formData.append(`import_data[file]`, file);
  formData.append(`import_data[type]`, `${type}`);

  try {
    const { data } = yield call(() =>
      api.post('/v1/import_data', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    );
    if (data) {
      yield put({ type: IMPORT_FILE_SUCCESS, payload: data });
    }
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) {
      yield put<IImportFileActionFail>({
        type: IMPORT_FILE_FAIL,
        payload: e,
      });
    }
  }
}

function* matchImportFileFields({
  payload: { id, matched_fields, callback },
}: ReturnType<typeof matchImportFileFieldsAction>) {
  try {
    const { data } = yield call(() =>
      api.patch(`/v1/import_data/${id}`, { import_data: { matched_fields } }),
    );
    if (data) {
      yield put({
        type: IMPORT_FILE_START_CREATE,
        payload: { id, callback },
      });
    }
  } catch (e) {
    if (api.isAxiosError(e)) {
      yield put<IImportFileMatchFieldsActionFail>({
        type: IMPORT_FILE_MATCH_FIELDS_FAIL,
        payload: e,
      });
    }
  }
}

function* startCreateImportFiles({
  payload: { id, callback },
}: ReturnType<typeof startImportFileCreateAction>) {
  try {
    yield call(() => api.post(`/v1/import_data/${id}/import`));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) {
      yield put<IImportFileStartCreateActionFail>({
        type: IMPORT_FILE_START_CREATE_FAIL,
        payload: e,
      });
    }
  }
}

export default function* importFileSaga() {
  yield takeLeading(IMPORT_FILE, uploadImportFileSaga);
  yield takeLeading(IMPORT_FILE_MATCH_FIELDS, matchImportFileFields);
  yield takeLeading(IMPORT_FILE_START_CREATE, startCreateImportFiles);
}
