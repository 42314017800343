import { LOGOUT } from 'components/Auth/_redux/authTypes';
import {
  CHANGE_LANGUAGE,
  COLOR_THEME,
  initSettingsActionsType,
  LOAD_SETTINGS_FAIL,
  LOAD_SETTINGS_SUCCESS,
  SET_CURRENT_PERMISSIONS,
  SET_DESKTOP_VERSION,
} from 'redux/userSettings/userSettingsTypes';

import { IUserSettingsReducer } from './models';

const initialState: IUserSettingsReducer = {
  language: 'en',
  theme: 'light',
  isDesktop: false,
  countries: null,
  states: null,
  nationalities: null,
  languages: null,
  internal_companies: null,
  companies_contracts_types: null,
  driver_types: null,
  driver_cooperation_types: null,
  driver_endorsements: null,
  driver_restrictions: null,
  driver_class_names: null,
  driver_payroll_statuses: null,
  drivers_files_categories: null,
  truck_manufacturers: null,
  truck_types: null,
  truck_ownership_types: null,
  truck_maintenance_type: null,
  truck_maintenance_status: null,
  truck_statuses: null,
  trailer_types: null,
  trailer_availabilities: null,
  trailer_ownership_types: null,
  trailer_manufacturers: null,
  hr_permissions: null,
  hr_positions: null,
  driver_divisions: null,
  employee_divisions: null,
  employee_bonuses: null,
  employee_payroll_types: null,
  employee_statuses: null,
  orders_files_categories: null,
  order_trailer_types: null,
  order_cargo_sizes: null,
  order_types: null,
  order_status: null,
  order_extra_charge_payer_types: null,
  order_extra_charge_types: null,
  order_address_receipt_file_types: null,
  order_billing_statuses: null,
  driver_payroll_week_statuses: null,
  accounting_expense_owner_types: null,
  accounting_expense_types: null,
  accounting_expense_statuses: null,
  accounting_expense_payment_types: null,
  accountings_drivers_payroll_item_types: null,
  accountings_drivers_credit_categories: null,
  accountings_drivers_deduction_categories: null,
  accountings_drivers_deduction_frequencies: null,
  accountings_drivers_deduction_statuses: null,
  accountings_drivers_deduction_recurrence_patterns: null,
  accountings_drivers_scheduled_deduction_categories: null,
  accountings_drivers_scheduled_deduction_recurrence_patterns: null,
  broker_file_categories: null,
  broker_deduction_statuses: null,
  broker_deduction_categories: null,
  brokers_contacts_positions: null,
  tariff_rate_types: null,
  fleets_drivers_claim_statuses: null,
  fleets_lead_sources: null,
  fleets_lead_statuses: null,
  fleets_maintenance_types: null,
  fleets_maintenance_shops: null,
  fleets_maintenance_work_orders: null,
  fleets_maintenance_technicians: null,
  fleets_accident_payer_types: null,
  fleets_trucks_inspections_files_comment_types: null,

  inspection_check_depths: null,
  inspection_check_conditions: null,
  inspection_check_fuel_levels: null,
  inventories_item_units_of_measure: null,
  inventories_items_unit_assign_use_on_names: null,

  tires_brands: null,
  payroll_item_statuses: null,
  upcoming_driver_statuses: null,
  upcoming_driver_steps_files_types: null,
  upcoming_driver_procedures: null,

  // ToDo move to BE
  fleets_trucks_inspections_brakers: {
    0: { id: 0, name: '0%' },
    25: { id: 25, name: '25%' },
    50: { id: 50, name: '50%' },
    75: { id: 75, name: '75%' },
    100: { id: 100, name: '100%' },
  },
  connection: {
    active: { id: 'active', name: 'Active (0-30 min)' },
    lost: { id: 'lost', name: 'Lost (more than 30 min)' },
  },
  engines: {
    Detroit: { id: 'Detroit', name: 'Detroit' },
    Volvo: { id: 'Volvo', name: 'Volvo' },
    Paccar: { id: 'Paccar', name: 'Paccar' },
    Cummins: { id: 'Cummins', name: 'Cummins' },
  },
  inventories_categories_groups: null,
  fleets_accident_damage_types: null,
  fleets_accident_accident_types: null,
  tracking_companies: null,
  current_subscription: null,
  hr_roles_features: null,
  currentPermissions: null,
};

const userSettingsReducers = (
  state = initialState,
  action: initSettingsActionsType,
): IUserSettingsReducer => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return { ...state, language: action.payload };

    case COLOR_THEME:
      return { ...state, theme: action.payload };

    case LOAD_SETTINGS_SUCCESS:
      return { ...state, ...action.payload };

    case SET_DESKTOP_VERSION:
      return { ...state, isDesktop: true };

    case SET_CURRENT_PERMISSIONS:
      return {
        ...state,
        currentPermissions: action.payload,
      };

    case LOAD_SETTINGS_FAIL:
    case LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
};

export default userSettingsReducers;
