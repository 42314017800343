import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';

import { handleSaveInspectionDraftSuccess } from 'components/Fleet/TabInspections/AllInspectionsTab/_redux/allInspectionsActions';
import { truckInspectionsFileToFormData } from 'components/Fleet/TabTrucks/TruckProfile/TruckInspections/_redux/helpers';
import {
  addTruckInspectionPhotoCommentAction,
  deleteTruckInspectionPhotoAction,
  deleteTruckInspectionPhotoCommentAction,
  updateTruckInspectionPhotoCommentAction,
  uploadTruckInspectionPhotoAction,
  getTruckInspectionAction,
  deleteTruckInspectionAction,
  getTruckInspectionDraftAction,
  draftTruckInspectionAction,
  initializeDraftTruckInspectionAction,
} from 'components/Fleet/TabTrucks/TruckProfile/TruckInspections/_redux/truckInspectionsActions';
import {
  IUploadTruckInspectionPhotoActionFail,
  IUploadTruckInspectionPhotoActionSuccess,
  UPLOAD_TRUCK_INSPECTION_PHOTO,
  UPLOAD_TRUCK_INSPECTION_PHOTO_FAIL,
  UPLOAD_TRUCK_INSPECTION_PHOTO_SUCCESS,
  DELETE_TRUCK_INSPECTION_PHOTO,
  DELETE_TRUCK_INSPECTION_PHOTO_SUCCESS,
  DELETE_TRUCK_INSPECTION_PHOTO_FAIL,
  IDeleteTruckInspectionPhotoActionSuccess,
  IDeleteTruckInspectionPhotoActionFail,
  ADD_TRUCK_INSPECTION_PHOTO_COMMENT,
  ADD_TRUCK_INSPECTION_PHOTO_COMMENT_SUCCESS,
  ADD_TRUCK_INSPECTION_PHOTO_COMMENT_FAIL,
  IAddTruckInspectionPhotoCommentActionFail,
  IAddTruckInspectionPhotoCommentActionSuccess,
  UPDATE_TRUCK_INSPECTION_PHOTO_COMMENT_SUCCESS,
  UPDATE_TRUCK_INSPECTION_PHOTO_COMMENT_FAIL,
  UPDATE_TRUCK_INSPECTION_PHOTO_COMMENT,
  IUpdateTruckInspectionPhotoCommentActionSuccess,
  IUpdateTruckInspectionPhotoCommentActionFail,
  DELETE_TRUCK_INSPECTION_PHOTO_COMMENT_SUCCESS,
  DELETE_TRUCK_INSPECTION_PHOTO_COMMENT_FAIL,
  DELETE_TRUCK_INSPECTION_PHOTO_COMMENT,
  IDeleteTruckInspectionPhotoCommentActionSuccess,
  IDeleteTruckInspectionPhotoCommentActionFail,
  GET_TRUCK_INSPECTION,
  GET_TRUCK_INSPECTION_SUCCESS,
  GET_TRUCK_INSPECTION_FAIL,
  IGetTruckInspectionActionSuccess,
  IGetTruckInspectionActionFail,
  DELETE_TRUCK_INSPECTION,
  DELETE_TRUCK_INSPECTION_SUCCESS,
  DELETE_TRUCK_INSPECTION_FAIL,
  IDeleteTruckInspectionActionFail,
  IDeleteTruckInspectionActionSuccess,
  GET_TRUCK_INSPECTION_DRAFT,
  GET_TRUCK_INSPECTION_DRAFT_SUCCESS,
  GET_TRUCK_INSPECTION_DRAFT_FAIL,
  IGetTruckInspectionDraftActionSuccess,
  IGetTruckInspectionDraftActionFail,
  DRAFT_TRUCK_INSPECTION,
  DRAFT_TRUCK_INSPECTION_SUCCESS,
  DRAFT_TRUCK_INSPECTION_FAIL,
  IDraftTruckInspectionActionSuccess,
  IDraftTruckInspectionActionFail,
  IInitialiseDraftTruckInspectionActionSuccess,
  INITIALIZE_DRAFT_TRUCK_INSPECTION_SUCCESS,
  IInitialiseDraftTruckInspectionActionFail,
  INITIALIZE_DRAFT_TRUCK_INSPECTION_FAIL,
  INITIALIZE_DRAFT_TRUCK_INSPECTION,
} from 'components/Fleet/TabTrucks/TruckProfile/TruckInspections/_redux/truckInspectionsTypes';
import api from 'utils/requests';

// GET_TRUCK_INSPECTION_DRAFT
function* getTruckInspectionDraftSaga({
  payload: {
    data: { inspectionId },
    callback,
  },
}: ReturnType<typeof getTruckInspectionDraftAction>) {
  try {
    const { data } = yield call(() =>
      api.get(`/v1/fleets/inspections/${inspectionId}`),
    );

    yield put<IGetTruckInspectionDraftActionSuccess>({
      type: GET_TRUCK_INSPECTION_DRAFT_SUCCESS,
      payload: data,
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetTruckInspectionDraftActionFail>({
        type: GET_TRUCK_INSPECTION_DRAFT_FAIL,
        payload: e,
      });
  }
}

// DRAFT_TRUCK_INSPECTION
function* draftTruckInspectionSaga({
  payload: {
    data: { formData, inspectionId },
    callback,
  },
}: ReturnType<typeof draftTruckInspectionAction>) {
  try {
    const { data } = yield call(() =>
      api.patch(`/v1/fleets/inspections/${inspectionId}`, formData),
    );

    yield put<IDraftTruckInspectionActionSuccess>({
      type: DRAFT_TRUCK_INSPECTION_SUCCESS,
      payload: data.inspection,
    });

    yield put(handleSaveInspectionDraftSuccess());

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IDraftTruckInspectionActionFail>({
        type: DRAFT_TRUCK_INSPECTION_FAIL,
        payload: e,
      });
  }
}

function* initializeDraftTruckInspectionSaga({
  payload: {
    data: { formData },
    callback,
  },
}: ReturnType<typeof initializeDraftTruckInspectionAction>) {
  try {
    const { data } = yield call(() =>
      api.post(`/v1/fleets/inspections`, formData),
    );

    yield put<IInitialiseDraftTruckInspectionActionSuccess>({
      type: INITIALIZE_DRAFT_TRUCK_INSPECTION_SUCCESS,
      payload: data,
    });

    if (callback) callback(data);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IInitialiseDraftTruckInspectionActionFail>({
        type: INITIALIZE_DRAFT_TRUCK_INSPECTION_FAIL,
        payload: e,
      });
  }
}

// DELETE_TRUCK_INSPECTION
function* deleteTruckInspectionSaga({
  payload: {
    data: { inspectionId, truckId },
    callback,
  },
}: ReturnType<typeof deleteTruckInspectionAction>) {
  try {
    yield call(() =>
      api.delete(`/v1/fleets/trucks/${truckId}/inspections/${inspectionId}`),
    );

    yield put<IDeleteTruckInspectionActionSuccess>({
      type: DELETE_TRUCK_INSPECTION_SUCCESS,
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IDeleteTruckInspectionActionFail>({
        type: DELETE_TRUCK_INSPECTION_FAIL,
        payload: e,
      });
  }
}

function* getTruckInspectionSaga({
  payload: {
    data: { inspectionId },
    callback,
  },
}: ReturnType<typeof getTruckInspectionAction>) {
  try {
    const { data } = yield call(() =>
      api.get(`/v1/fleets/inspections/${inspectionId}`),
    );

    yield put<IGetTruckInspectionActionSuccess>({
      type: GET_TRUCK_INSPECTION_SUCCESS,
      payload: { ...data },
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetTruckInspectionActionFail>({
        type: GET_TRUCK_INSPECTION_FAIL,
        payload: e,
      });
  }
}

// UPLOAD_TRUCK_INSPECTION_PHOTO
function* uploadTruckInspectionPhotoSaga({
  payload: {
    data: { file, fieldName, view, inspectionId, uploadIndex },
    callback,
  },
}: ReturnType<typeof uploadTruckInspectionPhotoAction>) {
  try {
    const photoType = fieldName === 'photos' ? '/short_inspection' : '';
    const { data } = yield call(() =>
      api.post(
        `/v1/fleets/inspections/vehicles/${inspectionId}/files/${fieldName}${photoType}`,
        truckInspectionsFileToFormData(file),
        { headers: { 'Content-Type': 'multipart/form-data' } },
      ),
    );

    yield put<IUploadTruckInspectionPhotoActionSuccess>({
      type: UPLOAD_TRUCK_INSPECTION_PHOTO_SUCCESS,
      payload: { ...data.file, view, fieldName },
      uploadIndex,
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IUploadTruckInspectionPhotoActionFail>({
        type: UPLOAD_TRUCK_INSPECTION_PHOTO_FAIL,
        payload: e,
      });
  }
}

// DELETE_TRUCK_INSPECTION_PHOTO
function* deleteTruckInspectionPhotoSaga({
  payload: {
    data: { truckId, id },
    callback,
  },
}: ReturnType<typeof deleteTruckInspectionPhotoAction>) {
  try {
    yield call(() =>
      api.delete(`/v1/fleets/inspections/vehicles/${truckId}/files/${id}`),
    );

    yield put<IDeleteTruckInspectionPhotoActionSuccess>({
      type: DELETE_TRUCK_INSPECTION_PHOTO_SUCCESS,
      payload: id,
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IDeleteTruckInspectionPhotoActionFail>({
        type: DELETE_TRUCK_INSPECTION_PHOTO_FAIL,
        payload: e,
      });
  }
}

// ADD_TRUCK_INSPECTION_PHOTO_COMMENT
function* addTruckInspectionPhotoCommentSaga({
  payload: {
    data: { fileId, commentData },
    callback,
  },
}: ReturnType<typeof addTruckInspectionPhotoCommentAction>) {
  try {
    const { data } = yield call(() =>
      api.post(`/v1/fleets/inspections/vehicles/files/${fileId}/comments`, {
        fleets_inspections_files_comment: commentData,
      }),
    );

    yield put<IAddTruckInspectionPhotoCommentActionSuccess>({
      type: ADD_TRUCK_INSPECTION_PHOTO_COMMENT_SUCCESS,
      payload: { ...data, fileId },
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IAddTruckInspectionPhotoCommentActionFail>({
        type: ADD_TRUCK_INSPECTION_PHOTO_COMMENT_FAIL,
        payload: e,
      });
  }
}

// UPDATE_TRUCK_INSPECTION_PHOTO_COMMENT
function* updateTruckInspectionPhotoCommentSaga({
  payload: {
    data: { fileId, commentData },
    callback,
  },
}: ReturnType<typeof updateTruckInspectionPhotoCommentAction>) {
  try {
    const { data } = yield call(() =>
      api.patch(
        `/v1/fleets/inspections/vehicles/files/${fileId}/comments/${commentData.id}`,
        {
          fleets_inspections_files_comment: commentData,
        },
      ),
    );

    yield put<IUpdateTruckInspectionPhotoCommentActionSuccess>({
      type: UPDATE_TRUCK_INSPECTION_PHOTO_COMMENT_SUCCESS,
      payload: { ...data, fileId },
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IUpdateTruckInspectionPhotoCommentActionFail>({
        type: UPDATE_TRUCK_INSPECTION_PHOTO_COMMENT_FAIL,
        payload: e,
      });
  }
}

// DELETE_TRUCK_INSPECTION_PHOTO_COMMENT
function* deleteTruckInspectionPhotoCommentSaga({
  payload: {
    data: { fileId, commentId },
    callback,
  },
}: ReturnType<typeof deleteTruckInspectionPhotoCommentAction>) {
  try {
    yield call(() =>
      api.delete(
        `/v1/fleets/inspections/vehicles/files/${fileId}/comments/${commentId}`,
      ),
    );

    yield put<IDeleteTruckInspectionPhotoCommentActionSuccess>({
      type: DELETE_TRUCK_INSPECTION_PHOTO_COMMENT_SUCCESS,
      payload: { fileId, commentId },
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IDeleteTruckInspectionPhotoCommentActionFail>({
        type: DELETE_TRUCK_INSPECTION_PHOTO_COMMENT_FAIL,
        payload: e,
      });
  }
}

export default function* trucksInspectionSaga() {
  yield takeLatest(GET_TRUCK_INSPECTION_DRAFT, getTruckInspectionDraftSaga);
  // yield takeLatest(CREATE_TRUCK_INSPECTION, createTruckInspectionSaga);
  // yield takeLatest(UPDATE_TRUCK_INSPECTION, updateTruckInspectionSaga);
  yield takeLatest(DRAFT_TRUCK_INSPECTION, draftTruckInspectionSaga);
  yield takeLatest(
    INITIALIZE_DRAFT_TRUCK_INSPECTION,
    initializeDraftTruckInspectionSaga,
  );
  yield takeLatest(DELETE_TRUCK_INSPECTION, deleteTruckInspectionSaga);
  yield takeEvery(GET_TRUCK_INSPECTION, getTruckInspectionSaga);
  yield takeEvery(
    UPLOAD_TRUCK_INSPECTION_PHOTO,
    uploadTruckInspectionPhotoSaga,
  );
  yield takeLatest(
    DELETE_TRUCK_INSPECTION_PHOTO,
    deleteTruckInspectionPhotoSaga,
  );
  yield takeLatest(
    ADD_TRUCK_INSPECTION_PHOTO_COMMENT,
    addTruckInspectionPhotoCommentSaga,
  );
  yield takeLatest(
    UPDATE_TRUCK_INSPECTION_PHOTO_COMMENT,
    updateTruckInspectionPhotoCommentSaga,
  );
  yield takeLatest(
    DELETE_TRUCK_INSPECTION_PHOTO_COMMENT,
    deleteTruckInspectionPhotoCommentSaga,
  );
}
