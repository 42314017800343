import { FC } from 'react';

import { useSelector } from 'react-redux';

import { userThemeSelector } from 'redux/userSettings/userSettingsSelectors';

type Props = {
  className?: string;
  strokeColor?: string;
};

const ChevronLeft: FC<Props> = ({ className, strokeColor }) => {
  const theme = useSelector(userThemeSelector);

  return (
    <svg
      width="7"
      height="10"
      viewBox="0 0 7 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.00012 9L2.00012 5L6.00012 1"
        stroke={strokeColor || (theme === 'dark' ? '#fff' : '#2D2F36')}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ChevronLeft;
