import { AxiosError } from 'axios';

// SET_LABEL
export const SET_HR_LABEL = '[@@HumanResourcesGroup] SET_HR_LABEL';
export const SET_HR_LABEL_SUCCESS =
  '[@@HumanResourcesGroup] SET_HR_LABEL_SUCCESS';
export const SET_HR_LABEL_FAIL = '[@@HumanResourcesGroup] SET_HR_LABEL_FAIL';

export interface ISetLabelAction {
  type: typeof SET_HR_LABEL;
  payload: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: { id: number; endPoint: string; labelKey: string; values: any };
    callback?: () => void;
  };
}

export interface ISetLabelActionSuccess {
  type: typeof SET_HR_LABEL_SUCCESS;
}

export interface ISetLabelActionFail {
  type: typeof SET_HR_LABEL_FAIL;
  payload: AxiosError;
}

// DELETE_LABEL
export const DELETE_HR_LABEL = '[@@HumanResourcesGroup] DELETE_HR_LABEL';
export const DELETE_HR_LABEL_SUCCESS =
  '[@@HumanResourcesGroup] DELETE_HR_LABEL_SUCCESS';
export const DELETE_HR_LABEL_FAIL =
  '[@@HumanResourcesGroup] DELETE_HR_LABEL_FAIL';

export interface IDeleteLabelAction {
  type: typeof DELETE_HR_LABEL;
  payload: {
    data: { id: number; endPoint: string; labelKey: string };
    callback?: () => void;
  };
}

export interface IDeleteLabelActionSuccess {
  type: typeof DELETE_HR_LABEL_SUCCESS;
}

export interface IDeleteLabelActionFail {
  type: typeof DELETE_HR_LABEL_FAIL;
  payload: AxiosError;
}
