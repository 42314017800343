import { FC } from 'react';

import { useSelector } from 'react-redux';

import { userThemeSelector } from 'redux/userSettings/userSettingsSelectors';

interface IOwnProps {
  className?: string;
  strokeColor?: string;
}

const IconLink: FC<IOwnProps> = ({ className, strokeColor }) => {
  const theme = useSelector(userThemeSelector);

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7 4L8.58579 2.41421C9.36684 1.63316 10.6332 1.63316 11.4142 2.41421L13.5858 4.58579C14.3668 5.36683 14.3668 6.63317 13.5858 7.41421L12 9M4 7L2.41421 8.58579C1.63317 9.36683 1.63317 10.6332 2.41421 11.4142L4.58579 13.5858C5.36684 14.3668 6.63316 14.3668 7.41421 13.5858L9 12"
        stroke={
          strokeColor ||
          (theme === 'dark' ? 'var(--white)' : 'var(--primaryText)')
        }
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M6 10L10 6"
        stroke={
          strokeColor ||
          (theme === 'dark' ? 'var(--white)' : 'var(--primaryText)')
        }
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconLink;
