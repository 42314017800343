import { FC } from 'react';

import { useSelector } from 'react-redux';

import { userThemeSelector } from 'redux/userSettings/userSettingsSelectors';

interface IOwnProps {
  strokeColor?: string;
  className?: string;
}

const IconArrowDown: FC<IOwnProps> = ({
  strokeColor = '#2D2F36',
  className,
}) => {
  const theme = useSelector(userThemeSelector);

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12 6L8 10L4 6"
        stroke={theme === 'dark' ? '#B8B8B8' : strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconArrowDown;
