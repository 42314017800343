/* eslint-disable @typescript-eslint/no-explicit-any */
import { Company } from 'components/Settings/TabOrientationProcessSettings/CreateOrientationStep/common/types';
import { AppState } from 'redux/store';

export const orientationSelector = (state: AppState): IOrientationReducer =>
  state.orientation;

// types
interface ISetActiveCompany {
  type: typeof SET_ACTIVE_COMPANY;
  payload: Company;
}

const SET_ACTIVE_COMPANY = '[@@System_Settings] SET_ACTIVE_COMPANY';

const SET_ACTIVE_STEP = '[@@System_Settings] SET_ACTIVE_STEP';

const SET_STEPS_LIST = '[@@System_Settings] SET_STEPS_LIST';

export interface IOrientationReducer {
  activeCompany: Company | null;
  activeStep: any;
  stepsList: any;
}

interface ISetActiveStep {
  type: typeof SET_ACTIVE_STEP;
  payload: any;
}

interface ISetStepsList {
  type: typeof SET_STEPS_LIST;
  payload: any;
}

type settingsActionTypes = ISetActiveCompany | ISetActiveStep | ISetStepsList;

// actions
export const setActiveCompany = (
  payload: ISetActiveCompany['payload'],
): ISetActiveCompany => ({
  type: SET_ACTIVE_COMPANY,
  payload,
});

export const setActiveStep = (payload: any): ISetActiveStep['payload'] => ({
  type: SET_ACTIVE_STEP,
  payload,
});

export const setStepsList = (payload: any): ISetStepsList['payload'] => ({
  type: SET_STEPS_LIST,
  payload,
});

// reducer
const initialState: IOrientationReducer = {
  activeCompany: null,
  activeStep: null,
  stepsList: null,
};

export const orientationReducers = (
  state = initialState,
  action: settingsActionTypes,
): IOrientationReducer => {
  switch (action.type) {
    case SET_ACTIVE_COMPANY:
      return {
        ...state,
        activeCompany: action.payload,
      };
    case SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload,
      };
    case SET_STEPS_LIST:
      return {
        ...state,
        stepsList: action.payload,
      };
    default:
      return state;
  }
};
