/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';

import { IPagination } from 'components/_common/Navigation/Pagination/_redux/paginationTypes';
import {
  ITruckTrackingData,
  ITruckTrafficHistory,
} from 'components/Fleet/TabTrucks/_models/truckMapModel';
import {
  IDeleteFileAction,
  IDeleteFileActionFail,
  IDeleteFileActionSuccess,
  IUploadFilesAction,
  IUploadFilesActionFail,
  IUploadFilesActionSuccess,
} from 'redux/_common/commonTypes';
import { IFile, ILocalFile } from 'redux/_common/models';

import { ITruckMaintenance } from '../../AllTrucksMaintenance/_models/truckMaintenanceModels';
import {
  ITruckReview,
  ITruckReviewHeading,
} from '../../AllTrucksMaintenance/_models/truckReviewModel';
import { ICreateTruckMaintenance } from '../_models/maintenanceModel';
import { ITruck } from '../_models/truckModel';

// CREATE TRUCK
export const CREATE_TRUCK = '[@@Trucks] CREATE_TRUCK';
export const CREATE_TRUCK_SUCCESS = '[@@Trucks] CREATE_TRUCK_SUCCESS';
export const CREATE_TRUCK_FAIL = '[@@Trucks] CREATE_TRUCK_FAIL';

export interface ICreateTruck {
  type: typeof CREATE_TRUCK;
  payload: any;
}

export interface ICreateTruckSuccess {
  type: typeof CREATE_TRUCK_SUCCESS;
  payload: ITruck;
}

export interface ICreateTruckFail {
  type: typeof CREATE_TRUCK_FAIL;
  payload: AxiosError;
}

export const GET_ENGINES = '[@@Trucks] GET_ENGINES';
export const GET_ENGINES_SUCCESS = '[@@Trucks] GET_ENGINES_SUCCESS';
export const GET_ENGINES_FAIL = '[@@Trucks] GET_ENGINES_FAIL';
export interface IGetEngines {
  type: typeof GET_ENGINES;
  payload: any;
}

export interface IGetEnginesSuccess {
  type: typeof GET_ENGINES_SUCCESS;
  payload: any;
}

export interface IGetEnginesFail {
  type: typeof GET_ENGINES_FAIL;
  payload: AxiosError;
}

// CREATE TRUCK PLANNING REVIEW
export const CREATE_TRUCK_PLANNING_REVIEW =
  '[@@Trucks] CREATE_TRUCK_PLANNING_REVIEW';
export const CREATE_TRUCK_PLANNING_REVIEW_SUCCESS =
  '[@@Trucks] CREATE_TRUCK_PLANNING_REVIEW_SUCCESS';
export const CREATE_TRUCK_PLANNING_REVIEW_FAIL =
  '[@@Trucks] CREATE_TRUCK_PLANNING_REVIEW_FAIL';

export interface ICreateTruckReview {
  type: typeof CREATE_TRUCK_PLANNING_REVIEW;
  payload: {
    data: { id: number; values: ICreateTruckMaintenance };
    callback?: () => void;
  };
}

export interface ICreateTruckReviewSuccess {
  type: typeof CREATE_TRUCK_PLANNING_REVIEW_SUCCESS;
  payload: ICreateTruckMaintenance;
}

export interface ICreateTruckReviewFail {
  type: typeof CREATE_TRUCK_PLANNING_REVIEW_FAIL;
  payload: AxiosError;
}

// GET TRUCK PLANNING REVIEW SERVICES
export const GET_TRUCK_PLANNING_REVIEW_SERVICES =
  '[@@Trucks] GET_TRUCK_PLANNING_REVIEW_SERVICES';
export const GET_TRUCK_PLANNING_REVIEW_SERVICES_SUCCESS =
  '[@@Trucks] GET_TRUCK_PLANNING_REVIEW_SERVICES_SUCCESS';
export const GET_TRUCK_PLANNING_REVIEW_SERVICES_FAIL =
  '[@@Trucks] GET_TRUCK_PLANNING_REVIEW_SERVICES_FAIL';

export interface IGetTruckReviewServices {
  type: typeof GET_TRUCK_PLANNING_REVIEW_SERVICES;
  payload: {
    data: { id: number };
    callback?: () => void;
  };
}

export interface IGetTruckReviewServicesSuccess {
  type: typeof GET_TRUCK_PLANNING_REVIEW_SERVICES_SUCCESS;
  payload: {
    data: ITruckReview[];
    planning_review: ITruckReviewHeading;
    last_comment: string;
    pagination: IPagination;
  };
}

export interface IGetTruckReviewServicesFail {
  type: typeof GET_TRUCK_PLANNING_REVIEW_SERVICES_FAIL;
  payload: AxiosError;
}

// GET TRUCK PLANNING REVIEW SERVICE
export const GET_TRUCK_PLANNING_REVIEW_SERVICE =
  '[@@Trucks] GET_TRUCK_PLANNING_REVIEW_SERVICE';
export const GET_TRUCK_PLANNING_REVIEW_SERVICE_SUCCESS =
  '[@@Trucks] GET_TRUCK_PLANNING_REVIEW_SERVICE_SUCCESS';
export const GET_TRUCK_PLANNING_REVIEW_SERVICE_FAIL =
  '[@@Trucks] GET_TRUCK_PLANNING_REVIEW_SERVICE_FAIL';

export interface IGetTruckReviewService {
  type: typeof GET_TRUCK_PLANNING_REVIEW_SERVICE;
  payload: {
    data: { id: number };
    callback?: () => void;
  };
}

export interface IGetTruckReviewServiceSuccess {
  type: typeof GET_TRUCK_PLANNING_REVIEW_SERVICE_SUCCESS;
  payload: {
    data: ITruckReview;
  };
}

export interface IGetTruckReviewServiceFail {
  type: typeof GET_TRUCK_PLANNING_REVIEW_SERVICE_FAIL;
  payload: AxiosError;
}

// UPDATE TRUCK PLANNING REVIEW STATUS
export const UPDATE_TRUCK_REVIEW = '[@@Trucks] UPDATE_TRUCK_REVIEW';
export const UPDATE_TRUCK_REVIEW_SUCCESS =
  '[@@Trucks] UPDATE_TRUCK_REVIEW_SUCCESS';
export const UPDATE_TRUCK_REVIEW_FAIL = '[@@Trucks] UPDATE_TRUCK_REVIEW_FAIL';

export interface IUpdateTruckReview {
  type: typeof UPDATE_TRUCK_REVIEW;
  payload: {
    data: { id: number; values: Partial<ITruckReview> };
    callback?: () => void;
  };
}

export interface IUpdateTruckReviewSuccess {
  type: typeof UPDATE_TRUCK_REVIEW_SUCCESS;
  payload: {
    data: ITruckReview;
  };
}

export interface IUpdateTruckReviewFail {
  type: typeof UPDATE_TRUCK_REVIEW_FAIL;
  payload: AxiosError;
}

// GET TRUCKS
export const GET_TRUCKS = '[@@Trucks] GET_TRUCKS';
export const GET_TRUCKS_SUCCESS = '[@@Trucks] GET_TRUCKS_SUCCESS';
export const GET_TRUCKS_FAIL = '[@@Trucks] GET_TRUCKS_FAIL';

export interface IGetTrucks {
  type: typeof GET_TRUCKS;
  payload: string | number;
}

export interface IGetTrucksSuccess {
  type: typeof GET_TRUCKS_SUCCESS;
  payload: any;
}

export interface IGetTrucksFail {
  type: typeof GET_TRUCKS_FAIL;
  payload: AxiosError;
}

// GET TRUCK BY ID
export const GET_TRUCK = '[@@Trucks] GET_TRUCK';
export const GET_TRUCK_SUCCESS = '[@@Trucks] GET_TRUCK_SUCCESS';
export const GET_TRUCK_FAIL = '[@@Trucks] GET_TRUCK_FAIL';

export interface IGetTruck {
  type: typeof GET_TRUCK;
  payload: any;
}

export interface IGetTruckSuccess {
  type: typeof GET_TRUCK_SUCCESS;
  payload: any;
}

export interface IGetTruckFail {
  type: typeof GET_TRUCK_FAIL;
  payload: AxiosError;
}

// CLEAR TRUCK
export const CLEAR_TRUCK = '[@@Trucks] CLEAR_TRUCK';
export interface IClearTruckAction {
  type: typeof CLEAR_TRUCK;
}

// GET TRUCK MAINTENANCE LIST BY TRUCK ID
export const GET_TRUCK_MAINTENANCE_LIST =
  '[@@Trucks] GET_TRUCK_MAINTENANCE_LIST';
export const GET_TRUCK_MAINTENANCE_LIST_SUCCESS =
  '[@@Trucks] GET_TRUCK_MAINTENANCE_LIST_SUCCESS';
export const GET_TRUCK_MAINTENANCE_LIST_FAIL =
  '[@@Trucks] GET_TRUCK_MAINTENANCE_LIST_FAIL';

export interface IGetTruckMaintenanceList {
  type: typeof GET_TRUCK_MAINTENANCE_LIST;
  payload: {
    data: { id: number | string };
    callback?: () => void;
  };
}

export interface IGetTruckMaintenanceListSuccess {
  type: typeof GET_TRUCK_MAINTENANCE_LIST_SUCCESS;
  payload: {
    data: ITruckMaintenance[];
    pagination: IPagination;
  };
}
export interface IGetTruckMaintenanceListFail {
  type: typeof GET_TRUCK_MAINTENANCE_LIST_FAIL;
  payload: AxiosError;
}

// UPDATE TRUCK BY ID
export const UPDATE_TRUCK = '[@@Trucks] UPDATE_TRUCK';
export const UPDATE_TRUCK_SUCCESS = '[@@Trucks] UPDATE_TRUCK_SUCCESS';
export const UPDATE_TRUCK_FAIL = '[@@Trucks] UPDATE_TRUCK_FAIL';

export interface IUpdateTruck {
  type: typeof UPDATE_TRUCK;
  payload: any;
}

export interface IUpdateTruckSuccess {
  type: typeof UPDATE_TRUCK_SUCCESS;
  payload: any;
}

export interface IUpdateTruckFail {
  type: typeof UPDATE_TRUCK_FAIL;
  payload: AxiosError;
}

// UPDATE TRUCK BY ID
export const DELETE_TRUCK = '[@@Trucks] DELETE_TRUCK';
export const DELETE_TRUCK_SUCCESS = '[@@Trucks] DELETE_TRUCK_SUCCESS';
export const DELETE_TRUCK_FAIL = '[@@Trucks] DELETE_TRUCK_FAIL';

export interface IDeleteTruck {
  type: typeof DELETE_TRUCK;
}

export interface IDeleteTruckSuccess {
  type: typeof DELETE_TRUCK_SUCCESS;
  payload: number;
}

export interface IDeleteTruckFail {
  type: typeof DELETE_TRUCK_FAIL;
  payload: AxiosError;
}

// UPLOAD TRUCK FILES
export const UPLOAD_TRUCK_FILES = '[@@Truck] UPLOAD_TRUCK_FILES';
export const UPLOAD_TRUCK_FILES_SUCCESS =
  '[@@Truck] UPLOAD_TRUCK_FILES_SUCCESS';
export const UPLOAD_TRUCK_FILES_FAIL = '[@@Truck] UPLOAD_TRUCK_FILES_FAIL';

export interface IUploadTruckFilesAction extends IUploadFilesAction {
  type: typeof UPLOAD_TRUCK_FILES;
}
export interface IUploadTruckFilesActionSuccess
  extends IUploadFilesActionSuccess {
  type: typeof UPLOAD_TRUCK_FILES_SUCCESS;
}
export interface IUploadTruckFilesActionFail extends IUploadFilesActionFail {
  type: typeof UPLOAD_TRUCK_FILES_FAIL;
}

// UPLOAD TRUCK CLAIM_PHOTO
export const UPLOAD_TRUCK_CLAIM_PHOTO = '[@@Truck] UPLOAD_TRUCK_CLAIM_PHOTO';
export const UPLOAD_TRUCK_CLAIM_PHOTO_SUCCESS =
  '[@@Truck] UPLOAD_TRUCK_CLAIM_PHOTO_SUCCESS';
export const UPLOAD_TRUCK_CLAIM_PHOTO_FAIL =
  '[@@Truck] UPLOAD_TRUCK_CLAIM_PHOTO_FAIL';

export interface IUploadTruckClaimPhotoAction extends IUploadFilesAction {
  type: typeof UPLOAD_TRUCK_CLAIM_PHOTO;
}
export interface IUploadTruckClaimPhotoActionSuccess {
  type: typeof UPLOAD_TRUCK_CLAIM_PHOTO_SUCCESS;
  payload: { file: IFile };
}
export interface IUploadTruckClaimPhotoActionFail {
  type: typeof UPLOAD_TRUCK_CLAIM_PHOTO_FAIL;
  payload: AxiosError;
}
// DELETE TRUCK CLAIM PHOTO
export const DELETE_CLAIM_PHOTO = '[@@Items] DELETE_CLAIM_PHOTO';
export const DELETE_CLAIM_PHOTO_SUCCESS =
  '[@@Items] DELETE_CLAIM_PHOTO_SUCCESS';
export const DELETE_CLAIM_PHOTO_FAIL = '[@@Items] DELETE_CLAIM_PHOTO_FAIL';
export interface IDeleteClaimPhotoAction extends IDeleteFileAction {
  type: typeof DELETE_CLAIM_PHOTO;
}
export interface IDeleteClaimPhotoSuccessAction {
  type: typeof DELETE_CLAIM_PHOTO_SUCCESS;
  payload: { fileId: string | number; itemId?: number };
}
export interface IDeleteClaimPhotoFailAction {
  type: typeof DELETE_CLAIM_PHOTO_FAIL;
  payload: AxiosError;
}

// DELETE LOCAL CLAIM PHOTOS
export const DELETE_LOCAL_CLAIM_PHOTO = '[@@Truck] DELETE_LOCAL_CLAIM_PHOTO';
export interface IDeleteLocalClaimPhotoAction {
  type: typeof DELETE_LOCAL_CLAIM_PHOTO;
}

// UPLOAD TRUCK SERVICE FILES
export const UPLOAD_TRUCK_SERVICE_FILE = '[@@Truck] UPLOAD_TRUCK_SERVICE_FILE';
export const UPLOAD_TRUCK_SERVICE_FILE_SUCCESS =
  '[@@Truck] UPLOAD_TRUCK_SERVICE_FILE_SUCCESS';
export const UPLOAD_TRUCK_SERVICE_FILE_FAIL =
  '[@@Truck] UPLOAD_TRUCK_SERVICE_FILE_FAIL';

export interface IUploadTruckServiceFileAction {
  type: typeof UPLOAD_TRUCK_SERVICE_FILE;
  payload: {
    files: Array<ILocalFile>;
    itemId: number;
  };
}
export interface IUploadTruckServiceFileSuccessAction
  extends IUploadFilesActionSuccess {
  type: typeof UPLOAD_TRUCK_SERVICE_FILE_SUCCESS;
}
export interface IUploadTruckServiceFileFailAction
  extends IUploadFilesActionFail {
  type: typeof UPLOAD_TRUCK_SERVICE_FILE_FAIL;
}

// DELETE TRUCK SERVICE FILES
export const DELETE_TRUCK_SERVICE_FILE = '[@@Truck] DELETE_TRUCK_SERVICE_FILE';
export const DELETE_TRUCK_SERVICE_FILE_SUCCESS =
  '[@@Truck] DELETE_TRUCK_SERVICE_FILE_SUCCESS';
export const DELETE_TRUCK_SERVICE_FILE_FAIL =
  '[@@Truck] DELETE_TRUCK_SERVICE_FILE_FAIL';

export interface IDeleteTruckServiceFileAction extends IDeleteFileAction {
  type: typeof DELETE_TRUCK_SERVICE_FILE;
}
export interface IDeleteTruckServiceFileActionSuccess
  extends IDeleteFileActionSuccess {
  type: typeof DELETE_TRUCK_SERVICE_FILE_SUCCESS;
}
export interface IDeleteTruckServiceFileActionFail
  extends IDeleteFileActionFail {
  type: typeof DELETE_TRUCK_SERVICE_FILE_FAIL;
}

// DELETE TRUCK FILE
export const DELETE_TRUCK_FILE = '[@@Truck] DELETE_TRUCK_FILE';
export const DELETE_TRUCK_FILE_SUCCESS = '[@@Truck] DELETE_TRUCK_FILE_SUCCESS';
export const DELETE_TRUCK_FILE_FAIL = '[@@Truck] DELETE_TRUCK_FILE_FAIL';

export interface IDeleteTruckFileAction extends IDeleteFileAction {
  type: typeof DELETE_TRUCK_FILE;
}
export interface IDeleteTruckFileActionSuccess
  extends IDeleteFileActionSuccess {
  type: typeof DELETE_TRUCK_FILE_SUCCESS;
}
export interface IDeleteTruckFileActionFail extends IDeleteFileActionFail {
  type: typeof DELETE_TRUCK_FILE_FAIL;
}

// GET TRACKING TRUCKS
export const GET_TRACKING_TRUCKS = '[@@Truck] GET_TRACKING_TRUCKS';
export const GET_TRACKING_TRUCKS_SUCCESS =
  '[@@Truck] GET_TRACKING_TRUCKS_SUCCESS';
export const GET_TRACKING_TRUCKS_FAIL = '[@@Truck] GET_TRACKING_TRUCKS_FAIL';

export interface IGetTrackingTrucksAction {
  type: typeof GET_TRACKING_TRUCKS;
  payload: any;
}
export interface IGetTrackingTrucksActionSuccess {
  type: typeof GET_TRACKING_TRUCKS_SUCCESS;
  payload: any;
}
export interface IGetTrackingTrucksActionFail {
  type: typeof GET_TRACKING_TRUCKS_FAIL;
  payload: AxiosError;
}

// GET TRACKING TRUCKS
export const GET_TRUCK_LOGS = '[@@Truck] GET_TRUCK_LOGS';
export const GET_TRUCK_LOGS_SUCCESS = '[@@Truck] GET_TRUCK_LOGS_SUCCESS';
export const GET_TRUCK_LOGS_FAIL = '[@@Truck] GET_TRUCK_LOGS_FAIL';

export interface IGetTruckLogsAction {
  type: typeof GET_TRUCK_LOGS;
  payload: any;
}
export interface IGetTruckLogsActionSuccess {
  type: typeof GET_TRUCK_LOGS_SUCCESS;
  payload: any;
}
export interface IGetTruckLogsActionFail {
  type: typeof GET_TRUCK_LOGS_FAIL;
  payload: AxiosError;
}

// CLEAR TRUCK MARKERS
export const CLEAR_TRUCK_MARKERS = '[@@Truck] CLEAR_TRUCK_MARKERS';

export interface IClearTruckMarkersAction {
  type: typeof CLEAR_TRUCK_MARKERS;
}

// SET PIN MESSAGE
export const SET_PIN_TRUCK_MAP_VIEW = '[@@Truck] SET_PIN_TRUCK_MAP_VIEW';
export const SET_PIN_TRUCK_MAP_VIEW_SUCCESS =
  '[@@Chat] SET_PIN_TRUCK_MAP_VIEW_SUCCESS';
export const SET_PIN_TRUCK_MAP_VIEW_FAIL =
  '[@@Chat] SET_PIN_TRUCK_MAP_VIEW_FAIL';

export interface ISetPinTruckMapView {
  type: typeof SET_PIN_TRUCK_MAP_VIEW;
  payload: {
    data: { id: number };
    callback?: () => void;
  };
}

export interface ISetPinTruckMapViewFail {
  type: typeof SET_PIN_TRUCK_MAP_VIEW_FAIL;
  payload: AxiosError;
}

export interface ISetPinTruckMapViewSuccess {
  type: typeof SET_PIN_TRUCK_MAP_VIEW_SUCCESS;
  payload: number;
}

// SET UNPIN MESSAGE
export const SET_UNPIN_TRUCK_MAP_VIEW = '[@@Truck] SET_UNPIN_TRUCK_MAP_VIEW';
export const SET_UNPIN_TRUCK_MAP_VIEW_SUCCESS =
  '[@@Chat] SET_UNPIN_TRUCK_MAP_VIEW_SUCCESS';
export const SET_UNPIN_TRUCK_MAP_VIEW_FAIL =
  '[@@Chat] SET_UNPIN_TRUCK_MAP_VIEW_FAIL';

export interface ISetUnPinTruckMapView {
  type: typeof SET_UNPIN_TRUCK_MAP_VIEW;
  payload: {
    data: { id: number };
    callback?: () => void;
  };
}

export interface ISetUnPinTruckMapViewFail {
  type: typeof SET_UNPIN_TRUCK_MAP_VIEW_FAIL;
  payload: AxiosError;
}

export interface ISetUnPinTruckMapViewSuccess {
  type: typeof SET_UNPIN_TRUCK_MAP_VIEW_SUCCESS;
  payload: number;
}

// GET_TRUCK_ORDER_HISTORY
export const GET_TRUCK_TRAFFIC_HISTORY = '[@@Truck] GET_TRUCK_TRAFFIC_HISTORY';
export const GET_TRUCK_TRAFFIC_HISTORY_SUCCESS =
  '[@@Truck] GET_TRUCK_TRAFFIC_HISTORY_SUCCESS';
export const GET_TRUCK_TRAFFIC_HISTORY_FAIL =
  '[@@Truck] GET_TRUCK_TRAFFIC_HISTORY_FAIL';

export interface IGetTruckTrafficHistory {
  type: typeof GET_TRUCK_TRAFFIC_HISTORY;
  payload: {
    data: { truckVin: string; from: string; period?: number };
    callback?: () => void;
  };
}
export interface IGetTruckTrafficHistorySuccess {
  type: typeof GET_TRUCK_TRAFFIC_HISTORY_SUCCESS;
  payload: ITruckTrafficHistory;
}
export interface IGetTruckTrafficHistoryFail {
  type: typeof GET_TRUCK_TRAFFIC_HISTORY_FAIL;
  payload: AxiosError;
}

// CLEAR_TRUCK_TRAFFIC_HISTORY
export const CLEAR_TRUCK_TRAFFIC_HISTORY =
  '[@@Truck] CLEAR_TRUCK_TRAFFIC_HISTORY';
export interface IClearTruckTrafficHistory {
  type: typeof CLEAR_TRUCK_TRAFFIC_HISTORY;
}

// CLEAR_TRUCK_TOLL_LOCATION
export const CLEAR_TRUCK_TOLL_LOCATION = '[@@Truck] CLEAR_TRUCK_TOLL_LOCATION';
export interface IClearTollTruckLocation {
  type: typeof CLEAR_TRUCK_TOLL_LOCATION;
}

// GET_TRUCK_TRAFFIC_HISTORY_BY_DATE
export const GET_TRUCK_TRAFFIC_HISTORY_BY_DATE =
  '[@@Truck] GET_TRUCK_TRAFFIC_HISTORY_BY_DATE';
export const GET_TRUCK_TRAFFIC_HISTORY_BY_DATE_SUCCESS =
  '[@@Truck] GET_TRUCK_TRAFFIC_HISTORY_BY_DATE_SUCCESS';
export const GET_TRUCK_TRAFFIC_HISTORY_BY_DATE_FAIL =
  '[@@Truck] GET_TRUCK_TRAFFIC_HISTORY_BY_DATE_FAIL';

export interface IGetTruckTrafficHistoryByDate {
  type: typeof GET_TRUCK_TRAFFIC_HISTORY_BY_DATE;
  payload: {
    data: { truckVin: string; date: string };
    callback?: () => void;
  };
}
export interface IGetTruckTrafficHistoryByDateSuccess {
  type: typeof GET_TRUCK_TRAFFIC_HISTORY_BY_DATE_SUCCESS;
  payload: ITruckTrackingData[]; // ToDo need to realize
}
export interface IGetTruckTrafficHistoryByDateFail {
  type: typeof GET_TRUCK_TRAFFIC_HISTORY_BY_DATE_FAIL;
  payload: AxiosError;
}

// SET_TRUCK_REVIEW_COMMENT_VISIBILITY
export const SET_TRUCK_REVIEW_COMMENT_VISIBILITY =
  '[@@Brokers] SET_TRUCK_REVIEW_COMMENT_VISIBILITY';

export interface ISetTruckReviewCommentVisibility {
  type: typeof SET_TRUCK_REVIEW_COMMENT_VISIBILITY;
  payload: {
    data: { id: number };
  };
}

// GET_TRUCK_LOCATION
export const GET_TRUCK_LOCATION = '[@@Truck] GET_TRUCK_LOCATION';
export const GET_TRUCK_LOCATION_SUCCESS =
  '[@@Truck] GET_TRUCK_LOCATION_SUCCESS';
export const GET_TRUCK_LOCATION_FAIL = '[@@Truck] GET_TRUCK_LOCATION_FAIL';

export interface IGetTruckLocation {
  type: typeof GET_TRUCK_LOCATION;
  payload: {
    data: { truckVin: string };
    callback?: () => void;
  };
}
export interface IGetTruckLocationActionSuccess {
  type: typeof GET_TRUCK_LOCATION_SUCCESS;
  payload: ITruck;
}
export interface IGetTruckLocationActionFail {
  type: typeof GET_TRUCK_LOCATION_FAIL;
  payload: AxiosError;
}

// UNLOCK_TRUCK_INSPECTION
export const UNLOCK_TRUCK_INSPECTION = '[@@Truck] UNLOCK_TRUCK_INSPECTION';
export const UNLOCK_TRUCK_INSPECTION_FAIL =
  '[@@Truck] UNLOCK_TRUCK_INSPECTION_FAIL';

export interface IUnlockTruckInspectionAction {
  type: typeof UNLOCK_TRUCK_INSPECTION;
  payload: {
    truckId: number;
    callback?: () => void;
  };
}

export interface IUnlockTruckInspectionActionFail {
  type: typeof UNLOCK_TRUCK_INSPECTION_FAIL;
  payload: AxiosError;
}

export const CLEAR_TRUCK_ERRORS = '[@@Truck] CLEAR_TRUCK_ERRORS';
export interface IClearTruckErrorsAction {
  type: typeof CLEAR_TRUCK_ERRORS;
}

export type trucksTypes =
  | IClearTruckMarkersAction
  | IGetTruckLogsAction
  | IGetTruckLogsActionSuccess
  | IGetTruckLogsActionFail
  | IGetTrackingTrucksAction
  | IGetTrackingTrucksActionSuccess
  | IGetTrackingTrucksActionFail
  | IDeleteTruckFileAction
  | IDeleteTruckFileActionSuccess
  | IDeleteTruckFileActionFail
  | IUploadTruckFilesAction
  | IUploadTruckFilesActionSuccess
  | IUploadTruckFilesActionFail
  | IDeleteTruck
  | IDeleteTruckFail
  | IDeleteTruckSuccess
  | IUpdateTruck
  | IUpdateTruckFail
  | IUpdateTruckSuccess
  | IGetTruck
  | IGetTruckFail
  | IGetTruckSuccess
  | ICreateTruck
  | ICreateTruckFail
  | ICreateTruckSuccess
  | IGetTrucks
  | IGetTrucksFail
  | IGetTrucksSuccess
  | IClearTruckAction
  | IGetTruckMaintenanceList
  | IGetTruckMaintenanceListFail
  | IGetTruckMaintenanceListSuccess
  | ICreateTruckReview
  | ICreateTruckReviewSuccess
  | ICreateTruckReviewFail
  | ISetPinTruckMapView
  | ISetPinTruckMapViewFail
  | ISetPinTruckMapViewSuccess
  | ISetUnPinTruckMapView
  | ISetUnPinTruckMapViewFail
  | ISetUnPinTruckMapViewSuccess
  | IGetTruckTrafficHistory
  | IGetTruckTrafficHistorySuccess
  | IGetTruckTrafficHistoryFail
  | IGetTruckReviewServices
  | IGetTruckReviewServicesSuccess
  | IGetTruckReviewServicesFail
  | IUpdateTruckReview
  | IUpdateTruckReviewSuccess
  | IUpdateTruckReviewFail
  | IGetTruckTrafficHistoryByDate
  | IGetTruckTrafficHistoryByDateSuccess
  | IGetTruckTrafficHistoryByDateFail
  | IClearTruckTrafficHistory
  | IUploadTruckServiceFileAction
  | IUploadTruckServiceFileSuccessAction
  | IUploadTruckServiceFileFailAction
  | IGetTruckReviewService
  | IGetTruckReviewServiceSuccess
  | IGetTruckReviewServiceFail
  | IDeleteTruckServiceFileAction
  | IDeleteTruckServiceFileActionSuccess
  | IDeleteTruckServiceFileActionFail
  | ISetTruckReviewCommentVisibility
  | IUploadTruckClaimPhotoAction
  | IUploadTruckClaimPhotoActionSuccess
  | IUploadTruckClaimPhotoActionFail
  | IDeleteClaimPhotoAction
  | IDeleteClaimPhotoSuccessAction
  | IDeleteClaimPhotoFailAction
  | IDeleteLocalClaimPhotoAction
  | IGetTruckLocation
  | IGetTruckLocationActionSuccess
  | IGetTruckLocationActionFail
  | IClearTollTruckLocation
  | IUnlockTruckInspectionAction
  | IUnlockTruckInspectionActionFail
  | IClearTruckErrorsAction;

export type trucksEnginesTypes =
  | IGetEngines
  | IGetEnginesSuccess
  | IGetEnginesFail;
