import { FC, memo } from 'react';

type Props = {
  color?: string;
};
const IconClaim: FC<Props> = ({ color = 'var(--secondaryText)' }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5822 5.4932L8 8L8.4178 5.4932C8.46083 5.23502 8.26174 5 8 5C7.73826 5 7.53917 5.23502 7.5822 5.4932Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="8" cy="11" r="1" fill={color} />
      <rect
        x="1"
        y="1"
        width="14"
        height="14"
        rx="2"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};

export default memo(IconClaim);
