import { FC } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const IconClock: FC<IOwnProps> = ({ className, fillColor = '#86898E' }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9 5C9 4.44772 8.55228 4 8 4C7.44772 4 7 4.44772 7 5H9ZM8 8H7V8.72076L7.68377 8.94868L8 8ZM10.6838 9.94868C11.2077 10.1233 11.774 9.84017 11.9487 9.31623C12.1233 8.79228 11.8402 8.22596 11.3162 8.05132L10.6838 9.94868ZM14 8C14 11.3137 11.3137 14 8 14V16C12.4183 16 16 12.4183 16 8H14ZM8 14C4.68629 14 2 11.3137 2 8H0C0 12.4183 3.58172 16 8 16V14ZM2 8C2 4.68629 4.68629 2 8 2V0C3.58172 0 0 3.58172 0 8H2ZM8 2C11.3137 2 14 4.68629 14 8H16C16 3.58172 12.4183 0 8 0V2ZM7 5V8H9V5H7ZM7.68377 8.94868L10.6838 9.94868L11.3162 8.05132L8.31623 7.05132L7.68377 8.94868Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconClock;
