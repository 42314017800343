import { FC, memo } from 'react';

type Props = {
  state?: 'asc' | 'desc';
  className?: string;
  fillColor?: string;
  sortColor?: string;
  onClick?: () => void;
};

const IconSort: FC<Props> = ({
  className,
  onClick,
  state,
  fillColor = 'var(--secondaryText)',
  sortColor = 'var(--primaryText)',
}) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M6 1L2 5H10L6 1Z"
        fill={state === 'asc' ? sortColor : fillColor}
      />
      <path
        d="M6 11L2 7H10L6 11Z"
        fill={state === 'desc' ? sortColor : fillColor}
      />
    </svg>
  );
};

export default memo(IconSort);
