import { FC } from 'react';

import { useSelector } from 'react-redux';

import { userThemeSelector } from 'redux/userSettings/userSettingsSelectors';

const defaultStyles = {
  display: 'flex',
  flexShrink: 0,
};

type Props = {
  withoutTextFlag?: boolean;
  className?: string;
};

const FleetTecLogo: FC<Props> = ({ withoutTextFlag, className }) => {
  const theme = useSelector(userThemeSelector);

  return (
    <svg
      style={defaultStyles}
      className={className}
      width={withoutTextFlag ? '28' : '145'}
      height="28"
      // viewBox="0 0 145 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {!withoutTextFlag && (
        <>
          <path
            d="M45.2655 7.04688V9.30688H39.4455V12.8869H43.9055V15.1069H39.4455V21.0069H36.6455V7.04688H45.2655Z"
            fill={theme === 'dark' ? '#fff' : '#2D2F36'}
          />
          <path
            d="M52.8532 18.7869H57.4532V21.0069H50.0532V7.04688H52.8532V18.7869Z"
            fill={theme === 'dark' ? '#fff' : '#2D2F36'}
          />
          <path
            d="M64.8317 9.30734V12.8073H69.5319V15.0273H64.8317V18.7273H70.1319V21.0073H62.0317V7.02734H70.1319V9.30734H64.8317Z"
            fill={theme === 'dark' ? '#fff' : '#2D2F36'}
          />
          <path
            d="M78.2771 9.30734V12.8073H82.9771V15.0273H78.2771V18.7273H83.5771V21.0073H75.4771V7.02734H83.5771V9.30734H78.2771Z"
            fill={theme === 'dark' ? '#fff' : '#2D2F36'}
          />
          <path
            d="M98.4207 7.04688V9.30688H94.7007V21.0069H91.9007V9.30688H88.1807V7.04688H98.4207Z"
            fill={theme === 'dark' ? '#fff' : '#2D2F36'}
          />
          <path
            d="M112.764 7.04688V9.30688H109.044V21.0069H106.244V9.30688H102.524V7.04688H112.764Z"
            fill="#2C6AF6"
          />
          <path
            d="M120.407 9.30734V12.8073H125.107V15.0273H120.407V18.7273H125.707V21.0073H117.607V7.02734H125.707V9.30734H120.407Z"
            fill="#2C6AF6"
          />
          <path
            d="M130.372 14.0072C130.372 12.6339 130.679 11.4072 131.292 10.3272C131.919 9.23385 132.765 8.38719 133.832 7.78719C134.912 7.17385 136.119 6.86719 137.452 6.86719C139.012 6.86719 140.379 7.26719 141.552 8.06719C142.725 8.86719 143.545 9.97385 144.012 11.3872H140.792C140.472 10.7205 140.019 10.2205 139.432 9.88719C138.859 9.55385 138.192 9.38719 137.432 9.38719C136.619 9.38719 135.892 9.58052 135.252 9.96719C134.625 10.3405 134.132 10.8739 133.772 11.5672C133.425 12.2605 133.252 13.0739 133.252 14.0072C133.252 14.9272 133.425 15.7405 133.772 16.4472C134.132 17.1405 134.625 17.6805 135.252 18.0672C135.892 18.4405 136.619 18.6272 137.432 18.6272C138.192 18.6272 138.859 18.4605 139.432 18.1272C140.019 17.7805 140.472 17.2739 140.792 16.6072H144.012C143.545 18.0339 142.725 19.1472 141.552 19.9472C140.392 20.7339 139.025 21.1272 137.452 21.1272C136.119 21.1272 134.912 20.8272 133.832 20.2272C132.765 19.6139 131.919 18.7672 131.292 17.6872C130.679 16.6072 130.372 15.3805 130.372 14.0072Z"
            fill="#2C6AF6"
          />
        </>
      )}
      <path d="M28 0H0V28H28V0Z" fill="#2C6AF6" />
      <path
        d="M24.3325 4.86719L21.4659 8.60052H13.0659V10.0672H9.33252V4.86719H24.3325Z"
        fill="white"
      />
      <path
        d="M19.4003 11.2656L16.5337 14.999H13.067V19.4656L9.33366 24.3323V14.999H3.66699L6.60033 11.2656H19.4003Z"
        fill="white"
      />
    </svg>
  );
};

export default FleetTecLogo;
