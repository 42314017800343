import { FC, CSSProperties } from 'react';

type OwnProps = {
  style?: CSSProperties;
  className?: string;
  fillColor?: string;
  fillBGColor?: string;
  withoutCircle?: boolean;
  onClick?: () => void;
};

const IconActiveTruck: FC<OwnProps> = ({
  style,
  className,
  fillColor = '#2C6AF6',
  fillBGColor = 'white',
  onClick,
  withoutCircle = false,
}) => {
  return (
    <>
      {!withoutCircle && (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={style}
          className={className}
          onClick={onClick}
        >
          <circle cx="20" cy="18" r="16" fill={fillColor} />
          <path
            d="M20 34C28.8366 34 36 26.8366 36 18C36 9.16344 28.8366 2 20 2C11.1634 2 4 9.16344 4 18C4 26.8366 11.1634 34 20 34ZM26.5913 18.6806L16.1497 23.9127C15.4958 24.2267 14.7874 23.6511 15.0599 22.8663L17.1306 17.948L15.0599 13.0298C14.7874 12.3496 15.4958 11.7741 16.1497 12.088L26.5913 17.3202C27.1362 17.6341 27.1362 18.3666 26.5913 18.6806Z"
            fill={fillBGColor}
          />
        </svg>
      )}

      {withoutCircle && (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={style}
          className={className}
        >
          <path
            d="M1.15045 11.9559L12.2251 8.2471C12.8088 8.01308 12.912 7.28789 12.4167 6.90031L2.81654 0.24913C2.21339 -0.153803 1.43097 0.316183 1.60487 1.02797L2.96194 6.18891L0.218929 10.7663C-0.161394 11.5049 0.458842 12.1745 1.15045 11.9559Z"
            fill={fillColor}
          />
        </svg>
      )}
    </>
  );
};

export default IconActiveTruck;
