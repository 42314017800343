import { FC } from 'react';

type OwnProps = {};
type Props = OwnProps;

const IconFlagUkraine: FC<Props> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8H16V12.9655C16 13.1179 15.8764 13.2414 15.7241 13.2414H0.275874C0.123531 13.2414 0 13.1179 0 12.9655V8Z"
        fill="#FFE15A"
      />
      <path
        d="M16 7.99921H0V3.03369C0 2.88134 0.123531 2.75781 0.275874 2.75781H15.7241C15.8765 2.75781 16 2.88134 16 3.03369L16 7.99921Z"
        fill="#4173CD"
      />
    </svg>
  );
};

export default IconFlagUkraine;
