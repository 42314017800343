import {
  Accordion as ExpansionPanel,
  AccordionSummary as ExpansionPanelSummary,
  AccordionDetails as ExpansionPanelDetails,
} from '@mui/material';
import { withStyles } from '@mui/styles';

export const Accordion = withStyles({
  root: {
    position: 'relative',
    boxShadow: 'none !important',
    border: 'none',
    backgroundColor: 'transparent !important',
    color: 'var(--primaryBorder)',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(ExpansionPanel);

export const AccordionSummary = withStyles({
  root: {
    display: 'flex !important',
    padding: '0px 16px !important',
    backgroundColor: 'transparent',
    color: 'var(--primaryBorder)',
    minHeight: 50,
    position: 'initial',
    border: 'none !important',
    '&$expanded': {
      minHeight: 50,
      backgroundColor: 'var(--sideBarSubBg)',
      color: 'var(--primaryBorder)',
      border: 'none',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(ExpansionPanelSummary);

export const AccordionDetails = withStyles({
  root: {
    color: 'var(--primaryBorder)',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },
})(ExpansionPanelDetails);
