import { FC, useCallback, useEffect, useState } from 'react';

import { ExpandMore } from '@mui/icons-material';
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from '@mui/material';
import { AxiosError } from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  IconAlertCircle,
  IconAttention,
  IconCalendar,
  IconClose,
  IconInfo,
  IconSimpleNotification,
} from 'assets/icons/components';
import Typography from 'components/_common/CommonTypography/CommonTypography';
import {
  BlackTooltip,
  BlueCheckbox,
} from 'components/_common/CustomMaterialComponents';
import Button from 'components/_common/FormElements/Button/Button';
import { formatPhoneNumberExt } from 'components/_common/FormElements/FormField/Input/PhoneNumberInput';
import Message from 'components/_common/Message/Message';
import { ModalDialog } from 'components/_common/Modal/Modal';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/_common/Navigation/MainSidebar/_elements';
import { authSelector } from 'components/Auth/_redux/authSelectors';
import { DEFAULT_DATE_AND_TIME_FORMAT_LOWER_TIME } from 'constants/dateFormats';
import { PERMISSION_TRAILER, PERMISSION_TRUCK } from 'constants/permissions';
import useCheckUserPermission from 'hooks/useCheckUserPermission';
import useToggle from 'hooks/useToggle';
import { mergeDateTimeAndConvertToISO } from 'utils/converters';
import { getErrorMessage, upperFirstLetter } from 'utils/helpers';
import api from 'utils/requests';

import DOTUpdateModal from './DOTUpdateModal/DOTUpdateModal';

import styles from './DOTPopUp.module.scss';

type HeadCell = {
  disablePadding: boolean;
  id: string;
  label: string | JSX.Element;
  numeric: boolean;
};

type SendItemType = {
  id: number;
  dot_exp_date?: string;
  reminder_dot_exp_date?: string | null;
};

type RowItem = {
  id: number;
  dot_exp_date: string;
  status: string;
  unit_number: string;
  dot_exp_days: number;
  driver?: { id: number; full_name: string; phone_number: string };
  reminder_dot_exp_date?: string;
};

type RowData = {
  trailers: Array<RowItem>;
  trucks: Array<RowItem>;
};

const headCells: HeadCell[] = [
  {
    id: 'unit',
    numeric: false,
    disablePadding: true,
    label: 'Unit #',
  },
  { id: 'driver', numeric: false, disablePadding: false, label: 'Driver' },
  { id: 'phone', numeric: false, disablePadding: false, label: 'Phone' },
  {
    id: 'days',
    numeric: true,
    disablePadding: false,
    label: (
      <div>
        Days{' '}
        <BlackTooltip title="After this amount of days current DOT Inspection will be illegal">
          <span>
            <IconInfo />
          </span>
        </BlackTooltip>
      </div>
    ),
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Vehicle Status',
  },
];

type EnhancedTableProps = {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
};

const EnhancedTableHead: FC<EnhancedTableProps> = ({
  onSelectAllClick,
  numSelected,
  rowCount,
}) => (
  <TableHead>
    <TableRow className={styles.tableHeadRow}>
      <TableCell padding="checkbox">
        <BlueCheckbox
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={rowCount > 0 && numSelected === rowCount}
          onChange={onSelectAllClick}
          inputProps={{ 'aria-label': 'select all' }}
        />
      </TableCell>
      {headCells.map(headCell => (
        <TableCell
          key={headCell.id}
          align={headCell.numeric ? 'right' : 'left'}
          padding={headCell.disablePadding ? 'none' : 'normal'}
        >
          {headCell.label}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

const DOTTableBody = ({
  data = [],
  selected,
  type,
  handleClick,
  handleUpdateClick,
}: {
  data: Array<RowItem>;
  selected: number[];
  type: 'trucks' | 'trailers';
  handleClick: (id: number) => void;
  handleUpdateClick: (id: number) => void;
}) => {
  const isSelected = (id: number) => selected.includes(id);

  return (
    <TableBody>
      {data.map(row => {
        const isItemSelected = isSelected(row.id);
        return (
          <TableRow hover key={row.id}>
            <TableCell padding="checkbox">
              <BlueCheckbox
                checked={isItemSelected}
                onClick={() => handleClick(row.id)}
              />
            </TableCell>
            <TableCell component="th" scope="row" padding="none">
              <div className={styles.unitNumber}>
                <Link to={`/fleet/${type}/${row.id}`}>{row.unit_number}</Link>
                {row.reminder_dot_exp_date && (
                  <BlackTooltip
                    classes={{ tooltip: styles.reminderTooltip }}
                    title={
                      <div className={styles.reminder}>
                        Reminder:{' '}
                        <span>
                          {moment(row.reminder_dot_exp_date).format(
                            DEFAULT_DATE_AND_TIME_FORMAT_LOWER_TIME,
                          )}
                        </span>
                      </div>
                    }
                  >
                    <span>
                      <IconSimpleNotification />
                    </span>
                  </BlackTooltip>
                )}
              </div>
            </TableCell>
            <TableCell>{row.driver?.full_name || '—'}</TableCell>
            <TableCell>
              {formatPhoneNumberExt(row.driver?.phone_number)}
            </TableCell>
            <TableCell align="center">
              {row.dot_exp_days <= 0 ? 0 : row.dot_exp_days}
            </TableCell>
            <TableCell align="justify">
              <div className={styles.status}>
                <span className={styles[`status-${row.status}`]}>
                  {upperFirstLetter(row.status)}
                </span>
                <span onClick={() => handleUpdateClick(row.id)}>
                  <IconCalendar />
                </span>
              </div>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

type AccordionContentProps = {
  type: 'trucks' | 'trailers';
  selected: number[];
  data: Array<RowItem>;
  defaultExpanded: boolean;
  handleSelectAllClick: (v: boolean) => void;
  handleClick: (id: number) => void;
  handleUpdateClick: (id: number) => void;
};

const AccordionContent = ({
  type,
  selected,
  data,
  defaultExpanded,
  handleSelectAllClick,
  handleClick,
  handleUpdateClick,
}: AccordionContentProps) => {
  return data?.length ? (
    <Accordion
      defaultExpanded={defaultExpanded}
      classes={{ root: styles.accordion }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore classes={{ root: styles.accordionIcon }} />}
      >
        <div className={styles.accordionSummary}>
          {upperFirstLetter(type)}
          <span>{data.length} Units</span>
        </div>
      </AccordionSummary>
      <AccordionDetails className={styles.accordionDetails}>
        <Table className={styles.table}>
          <EnhancedTableHead
            numSelected={selected.length}
            onSelectAllClick={({ target }) =>
              handleSelectAllClick(target.checked)
            }
            rowCount={data.length}
          />

          <DOTTableBody
            handleClick={handleClick}
            handleUpdateClick={handleUpdateClick}
            data={data}
            selected={selected}
            type={type}
          />
        </Table>
      </AccordionDetails>
    </Accordion>
  ) : null;
};

const defaultData = {
  trailers: [],
  trucks: [],
};

type Props = {
  withButton?: boolean;
  vehicleType?: keyof typeof defaultData;
};

/**
 * Modal with expired DOT inspections
 * @params withButton - show button for open popup
 */
const DOTPopUp = ({ withButton, vehicleType }: Props) => {
  const { isOpen, open, close } = useToggle();
  const {
    isOpen: isUpdateModalOpen,
    open: openUpdateModal,
    close: closeUpdateModal,
  } = useToggle();

  const [selected, setSelected] = useState<{
    trailers: number[];
    trucks: number[];
  }>(defaultData);

  const [rows, setRows] = useState<RowData>(defaultData);

  const [error, setError] = useState<AxiosError>();
  const [modalType, setModalType] = useState<'update' | 'remind'>();
  const [oneItemUpdate, setOneItemUpdate] = useState<{
    id: number;
    type: string;
  }>();

  const handleOpenModalClick = (newType: 'update' | 'remind') => {
    setModalType(newType);
    openUpdateModal();
  };

  const { token, email } = useSelector(authSelector);

  const { hasEditPermission } = useCheckUserPermission(
    [PERMISSION_TRUCK, PERMISSION_TRAILER],
    false,
  );

  const getDotData = (mustBeOpen?: boolean) => {
    api
      .get('/v1/fleets/inspections/dot_list')
      .then(({ data }) => {
        if (data?.trailers || data?.trucks) {
          setRows(data);
          if (mustBeOpen && (data?.trailers?.length || data?.trucks?.length)) {
            open();
          }
        }
      })
      .catch(e => {
        if (api.isAxiosError(e)) setError(e);
        throw e;
      });
  };

  const handleClose = async () => {
    setSelected(defaultData);
    close();

    const allData: RowData = await api
      .get('/v1/fleets/inspections/dot_list')
      .then(({ data }) => data)
      .catch(e => {
        if (api.isAxiosError(e)) setError(e);
        throw e;
      });

    setRows(allData);

    if (allData?.trailers?.length || allData?.trucks?.length) {
      const valuesWithExpDate: RowData = Object.entries(allData).reduce(
        (acc, [key, values]) => {
          return {
            ...acc,
            [key]: values.filter(
              ({ reminder_dot_exp_date }) =>
                reminder_dot_exp_date &&
                moment(reminder_dot_exp_date).isAfter(moment()) &&
                moment(reminder_dot_exp_date).isSameOrBefore(
                  moment().endOf('day'),
                ),
            ),
          };
        },
        { trucks: [], trailers: [] },
      );

      // sort values by date of reminder
      const valuesByDate = Object.entries(valuesWithExpDate).reduce(
        (acc, [key, values]) => {
          if (Array.isArray(values) && values.length)
            values.forEach(({ reminder_dot_exp_date }) => {
              if (reminder_dot_exp_date) {
                const valuesWithSameDate = [...values].filter(
                  item => item?.reminder_dot_exp_date === reminder_dot_exp_date,
                );
                // eslint-disable-next-line no-param-reassign
                acc = {
                  ...acc,
                  [reminder_dot_exp_date]: {
                    ...acc[reminder_dot_exp_date],
                    [key]: valuesWithSameDate,
                  },
                };
              }
            });
          return acc;
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {} as any,
      );

      Object.entries(valuesByDate).forEach(([key, values]) => {
        const millisecondsLeft = moment(key).diff(moment());
        setTimeout(() => {
          setRows(values as RowData);
          open();
        }, millisecondsLeft);
      });

      // get values with past reminder date and clear this field
      const valuesWithPastReminderDate = Object.entries(allData).reduce(
        (acc, [key, values]) => {
          return {
            ...acc,
            [key]: values.filter(
              ({ reminder_dot_exp_date }) =>
                reminder_dot_exp_date &&
                moment(reminder_dot_exp_date).isBefore(moment()),
            ),
          };
        },
        { trucks: [], trailers: [] },
      );

      const resData: {
        type: 'update';
        truck_ids: Array<SendItemType>;
        trailer_ids: Array<SendItemType>;
      } = {
        type: 'update',
        truck_ids: [],
        trailer_ids: [],
      };

      if (valuesWithPastReminderDate.trucks.length) {
        resData.truck_ids = valuesWithPastReminderDate.trucks.map(({ id }) => ({
          id,
          reminder_dot_exp_date: null,
        }));
      }

      if (valuesWithPastReminderDate.trailers.length) {
        resData.trailer_ids = valuesWithPastReminderDate.trailers.map(
          ({ id }) => ({
            id,
            reminder_dot_exp_date: null,
          }),
        );
      }

      // clear values with past exp date
      if (resData.truck_ids?.length || resData.trailer_ids?.length) {
        api.patch('/v1/fleets/inspections/dot_set', resData).catch(e => {
          if (api.isAxiosError(e)) setError(e);
          throw e;
        });
      }
    }
  };

  useEffect(() => {
    if (token && email && hasEditPermission) {
      // open only once a day for each user
      const lastTime = localStorage.getItem(`${email}_DotPopupLastTimeShowed`);

      if (
        !lastTime ||
        moment(lastTime).isBefore(moment(), 'days') ||
        withButton
      ) {
        getDotData(!withButton);
        if (!withButton) {
          localStorage.setItem(
            `${email}_DotPopupLastTimeShowed`,
            new Date().toISOString(),
          );
        }
      }
    }
  }, [token, email, hasEditPermission, withButton]);

  const handleCloseUpdateModal = () => {
    closeUpdateModal();
    setModalType(undefined);
  };

  const handleUpdateClick = (id: number, type: string) => {
    setModalType('update');
    setOneItemUpdate({ id, type });
    openUpdateModal();
  };

  const handleClick = useCallback(
    (id: number, type: keyof typeof rows) => {
      const selectedIndex = selected[type].indexOf(id);
      let newSelected: number[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected[type], id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected[type].slice(1));
      } else if (selectedIndex === selected[type].length - 1) {
        newSelected = newSelected.concat(selected[type].slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected[type].slice(0, selectedIndex),
          selected[type].slice(selectedIndex + 1),
        );
      }

      setSelected(prev => ({
        ...prev,
        [type]: newSelected,
      }));
    },
    [selected],
  );

  const handleSelectAllClick = (
    isChecked: boolean,
    type: keyof typeof rows,
  ) => {
    if (isChecked) {
      const newSelecteds = [...rows[type].map(n => n.id)];
      setSelected(prev => ({
        ...prev,
        [type]: newSelecteds,
      }));
      return;
    }
    setSelected(prev => ({
      ...prev,
      [type]: [],
    }));
  };

  const updateData = (dateItem: { [key: string]: string }) => {
    const updatedData = { ...rows };

    const updatedValues: RowData = Object.entries(updatedData).reduce(
      (acc, [key, values]) => {
        return {
          ...acc,
          [key]:
            modalType === 'update'
              ? values.filter(({ id }) =>
                  oneItemUpdate?.id
                    ? !(oneItemUpdate?.id === id && oneItemUpdate.type === key)
                    : !selected[key as keyof typeof selected].includes(id),
                )
              : values.map(item =>
                  selected[key as keyof typeof selected].includes(item.id)
                    ? { ...item, ...dateItem }
                    : item,
                ),
        };
      },
      { trucks: [], trailers: [] },
    );

    setRows(updatedValues);
  };

  const onSubmit = ({ date, time }: { date: string; time?: string }) => {
    const resDate = time
      ? mergeDateTimeAndConvertToISO(date, time).toString()
      : date;

    const dateKey =
      modalType === 'update' ? 'dot_exp_date' : 'reminder_dot_exp_date';

    const resData: {
      truck_ids: Array<SendItemType>;
      trailer_ids: Array<SendItemType>;
    } = {
      truck_ids: [],
      trailer_ids: [],
    };

    if (oneItemUpdate?.id) {
      resData[oneItemUpdate.type === 'trucks' ? 'truck_ids' : 'trailer_ids'] = [
        {
          id: oneItemUpdate.id,
          dot_exp_date: resDate,
        },
      ];
    } else {
      if (selected.trucks.length) {
        resData.truck_ids = selected.trucks.map(id => ({
          id,
          [dateKey]: resDate,
        }));
      }

      if (selected.trailers.length) {
        resData.trailer_ids = selected.trailers.map(id => ({
          id,
          [dateKey]: resDate,
        }));
      }
    }

    api
      .patch('/v1/fleets/inspections/dot_set', resData)
      .then(() => {
        handleCloseUpdateModal();
        updateData({ [dateKey]: resDate });
      })
      .catch(e => {
        if (api.isAxiosError(e)) setError(e);
        throw e;
      })
      .finally(() => {
        setOneItemUpdate(undefined);
        setSelected(defaultData);
      });
  };

  const selectedCount = selected.trucks.length + selected.trailers.length;
  const allItem = rows.trucks.length + rows.trailers.length;
  const isDataExist = vehicleType ? !!rows[vehicleType]?.length : false;

  return (
    <>
      {withButton && isDataExist && (
        <Button
          text="DOT Required"
          theme="white"
          icon={<IconAttention fillColor="var(--primaryText)" />}
          onClick={() => getDotData(true)}
        />
      )}

      <ModalDialog
        className={styles.modal}
        open={isOpen}
        closeOnOutsideClick={false}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClose={handleClose}
        dataTestAttr="dots"
      >
        <div className={styles.popupRoot}>
          <IconAlertCircle className={styles.icon} />

          <Typography variant="h2">DOT Inspections Expire Alerts</Typography>

          {!allItem && <p>List is empty</p>}

          <div className={styles.popupContent}>
            <AccordionContent
              type="trucks"
              defaultExpanded={!vehicleType || vehicleType === 'trucks'}
              data={rows.trucks}
              selected={selected.trucks}
              handleSelectAllClick={v => handleSelectAllClick(v, 'trucks')}
              handleClick={n => handleClick(n, 'trucks')}
              handleUpdateClick={id => handleUpdateClick(id, 'trucks')}
            />
            <AccordionContent
              type="trailers"
              defaultExpanded={!vehicleType || vehicleType === 'trailers'}
              data={rows.trailers}
              selected={selected.trailers}
              handleSelectAllClick={v => handleSelectAllClick(v, 'trailers')}
              handleClick={n => handleClick(n, 'trailers')}
              handleUpdateClick={id => handleUpdateClick(id, 'trailers')}
            />
          </div>
        </div>

        <div className={styles.footer}>
          {selectedCount > 0 && (
            <>
              <div>
                <IconClose onClick={() => setSelected(defaultData)} />
                {selectedCount} items selected
              </div>
              <div>
                <Button
                  text="Set a Reminder"
                  theme="white"
                  onClick={() => handleOpenModalClick('remind')}
                />
                <Button
                  text="Update DOT Date"
                  theme="white"
                  onClick={() => handleOpenModalClick('update')}
                />
              </div>
            </>
          )}
        </div>

        {(modalType || oneItemUpdate?.id) && (
          <DOTUpdateModal
            isOpen={isUpdateModalOpen}
            onClose={handleCloseUpdateModal}
            onSubmit={onSubmit}
            type={modalType}
          />
        )}
      </ModalDialog>

      {error && <Message message={getErrorMessage(error)} type="error" />}
    </>
  );
};

export default DOTPopUp;
