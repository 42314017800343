import { IInspection } from '../_models/vehicleInspectionModels';

const inventoryData = {
  critical_checks: [],
  inventory: [],
};

// eslint-disable-next-line import/prefer-default-export
export const defaultInspectionData: IInspection = {
  id: 0,
  created_at: '',
  company_id: 0,
  trailer_is_main: false,
  future_check_type: undefined,
  force_check_type: undefined,
  check_type: 'check_in',
  'tandem?': false,
  'solo?': false,
  inspection_locked: undefined,
  vehicle_inspections: [],
  inventories_data: {
    truck: inventoryData,
    trailer: inventoryData,
    driver: inventoryData,
  },
};
