import {
  FC,
  PropsWithChildren,
  ReactNode,
  ChangeEvent,
  MouseEvent,
  KeyboardEvent,
  FormEvent,
  useRef,
  useEffect,
  useState,
  useMemo,
  useCallback,
  memo,
} from 'react';

import { ClickAwayListener, Popper } from '@mui/material';
import clsx from 'clsx';
import { isEmpty } from 'ramda';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import {
  IconUserPhoto,
  IconArrowDown,
  IconClose16,
  IconClose,
} from 'assets/icons/components';
import {
  ISelectOption,
  ISelectOptions,
} from 'components/_common/FormElements/FormField/Select/_models/selectModels';
import useDebounceCallback from 'hooks/useDebounceCallback';
import useTestAttrRef, { getAttrName } from 'hooks/useTestAttrRef';
import useToggle from 'hooks/useToggle';

import Option from '../Select/Option/Option';

import styles from './CustomAutocomplete.module.scss';

type OwnProps = PropsWithChildren<{
  placeholder: string;
  className: string;
  id: string;
  options: ISelectOptions;
  disabled?: boolean;
  withAddBtn?: JSX.Element;
  dropDownIcon?: boolean;
  clearIcon?: boolean;
  autoSelectFirstItem?: boolean;
  focusOnRender?: boolean;
  preventSelectOnTab?: boolean;
  labelText?: string;
  dataTestPrefix?: string;
  onSelect?: (option: ISelectOption) => void;
  onSearch?: (value: string) => void;
  onInputFocus?: () => void;
  handleOnBlur: (option: ISelectOption) => void;
  isPortalDisabled?: boolean;
}>;

type Props = OwnProps & WrappedFieldInputProps & WrappedFieldMetaProps;

// random string for autocomplete
const AUTOCOMPLETE_STR = () => Math.random().toString(36);

const CustomAutocomplete: FC<Partial<Props>> = ({
  placeholder,
  className,
  options,
  disabled,
  value,
  touched,
  error,
  name,
  withAddBtn,
  dropDownIcon,
  clearIcon,
  focusOnRender,
  onInputFocus,
  preventSelectOnTab,
  dataTestPrefix,
  children,
  labelText,
  onChange,
  onBlur,
  onSelect,
  handleOnBlur,
  onSearch,
  isPortalDisabled = true,
}) => {
  const { isOpen, open, close, toggleVisibility } = useToggle();
  const debouncedSearch = useDebounceCallback(onSearch);
  const [cursor, setCursor] = useState(-1);

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useTestAttrRef<HTMLInputElement>(
    null,
    labelText &&
      getAttrName((dataTestPrefix ? `${dataTestPrefix}_` : '') + labelText),
  );
  const addButtonRef = useRef<HTMLInputElement>(null);
  const inputPos = useRef(0);

  const focusInput = () => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  };

  const handleArrowClick = () => {
    if (!isOpen) {
      focusInput();
    }
    toggleVisibility();
  };

  useEffect(() => {
    if (focusOnRender) {
      focusInput();
    }
  }, [focusOnRender]);

  useEffect(() => {
    if (addButtonRef.current && withAddBtn && cursor === 0)
      addButtonRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
  }, [addButtonRef.current, cursor]);

  const handleOnSelect = (option: ISelectOption, refocusInput = true) => {
    if (onChange) onChange(option);
    if (onSelect) onSelect(option);

    setTimeout(() => {
      if (option && inputRef.current) {
        const valueLength = option.name.length;
        inputRef.current.setSelectionRange(valueLength, valueLength);
        close();
        if (refocusInput) focusInput();
      }
    });
    if (onSearch) onSearch('');
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    inputPos.current = e.target.selectionStart || 0;
    if (onChange) onChange({ id: null, name: e.target.value });
  };

  const handleClear = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setCursor(-1);
    if (onChange) onChange({ id: null, name: '' });
  };

  useEffect(() => {
    if (inputRef.current)
      inputRef.current.setSelectionRange(inputPos.current, inputPos.current);
  }, [value?.name]);

  useEffect(() => {
    if (options && value?.id && !value?.name) {
      const id = `${value.id}`;
      const option = options[id];
      if (option) handleOnSelect(option);
    }
  }, [value?.id, options]);

  useEffect(() => {
    setCursor(
      isOpen && Object.keys(options || {}).length ? (withAddBtn ? 1 : 0) : -1,
    );
  }, [isOpen, options, withAddBtn]);

  const handleOnInput = (e: FormEvent<HTMLInputElement>) => {
    if (!isOpen) open();
    debouncedSearch(e.currentTarget.value.trim().toLocaleLowerCase());
  };

  const handleBlur = () => {
    if (onBlur) onBlur({ ...value });
    if (handleOnBlur) handleOnBlur({ ...value });

    if (inputRef.current) {
      inputRef.current.readOnly = true;
    }
  };

  const handleClose = (withInputFocus?: boolean) => {
    close();
    if (withInputFocus) {
      focusInput();
    }
  };

  const handleAddButtonClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    handleClose(true);
  };

  const handleAddButtonKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && withAddBtn?.props?.onClick) {
      e.preventDefault();
      e.stopPropagation();
      handleClose(true);
      withAddBtn.props.onClick();
    }
    if (e.key === 'Tab' && e.shiftKey) {
      handleClose(true);
    }
  };

  const handleInputKeyDown = (event: KeyboardEvent) => {
    const { key } = event;

    if ((key === 'ArrowDown' || key === 'ArrowUp') && !isOpen) {
      open();
    }

    const optionsLength =
      Object.keys(options || {}).length + (withAddBtn ? 1 : 0);

    switch (key) {
      case 'Tab':
        if (isOpen && !event.shiftKey && !preventSelectOnTab) {
          const selectedOption =
            options && Object.values(options)[withAddBtn ? cursor - 1 : cursor];
          if (selectedOption) handleOnSelect(selectedOption, false);
        }
        close();
        break;
      case 'Enter':
        event.preventDefault();

        if (isOpen) {
          if (withAddBtn && cursor === 0) {
            handleAddButtonKeyDown(event);
          } else {
            const selectedOption =
              options &&
              Object.values(options)[withAddBtn ? cursor - 1 : cursor];
            if (selectedOption) handleOnSelect(selectedOption);
            close();
          }
        }
        break;
      case 'ArrowDown':
        // prevent scroll
        event.preventDefault();

        if (options) {
          if (isOpen) {
            setCursor(currCursor =>
              currCursor < optionsLength - 1 ? currCursor + 1 : 0,
            );
          } else {
            setCursor(-1);
          }
        }
        break;
      case 'ArrowUp':
        // prevent scroll
        event.preventDefault();

        if (options) {
          if (isOpen) {
            setCursor(cursor <= 0 ? optionsLength - 1 : cursor - 1);
          } else {
            setCursor(-1);
          }
        }
        break;
      default:
        break;
    }
  };

  const handleFocus = () => {
    if (onInputFocus) onInputFocus();
    // Needed to hide browser autocomplete
    if (inputRef.current && inputRef.current.readOnly) {
      inputRef.current.removeAttribute('readonly');
    }
  };

  const hasPhoto = value?.id && value?.photo_url_thumb;
  const isPerson =
    value?.versionKey === 'driver' ||
    value?.versionKey === 'dispatcher' ||
    value?.versionKey === 'employee';

  const getChildren = (): ReactNode => (
    <>
      {withAddBtn && (
        <div
          tabIndex={-1}
          className={clsx(
            styles.addButton,
            cursor === 0 && styles.activeAddButton,
          )}
          ref={addButtonRef}
          onKeyDown={handleAddButtonKeyDown}
          onClick={handleAddButtonClick}
        >
          {withAddBtn}
        </div>
      )}
      {isOpen &&
        !!options &&
        Object.keys(options).map((key, i) => {
          const index = withAddBtn ? i + 1 : i;
          return (
            <Option
              className={cursor === index ? styles.activeTitle : undefined}
              key={`${index}_${options[key].id}`}
              onSelectOption={handleOnSelect}
              option={options[key]}
              toggleVisibility={handleClose}
              inputValue={value?.name || ''}
              highlight={cursor === index}
            />
          );
        })}
      {isEmpty(options) && placeholder !== 'Type an Invoice ID' && (
        <li>{`No results found for "${value?.name || ''}"`}</li>
      )}
      {isEmpty(options) && placeholder === 'Type an Invoice ID' && (
        <li>{`Create an invoice ID "${value?.name || ''}"`}</li>
      )}
    </>
  );

  return (
    <div
      className={clsx(styles.root, {
        [styles.multiLine]: !!children,
        [styles.dropdownIsClosed]: !isOpen,
        [styles.disabled]: disabled,
        [styles.hasError]: touched && !!error,
        [styles.hasPhoto]: hasPhoto || isPerson,
      })}
      ref={containerRef}
    >
      {hasPhoto ? (
        <img src={value.photo_url_thumb} alt="" />
      ) : (
        isPerson && <IconUserPhoto className={styles.defaultAvatar} />
      )}

      {children}

      <input
        onKeyDown={handleInputKeyDown}
        type="text"
        placeholder={placeholder}
        name={name}
        value-id={value?.id || undefined}
        value={value?.name || ''}
        onClick={toggleVisibility}
        onChange={handleOnChange}
        onInput={handleOnInput}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={clsx(styles.input, className, {
          focused: isOpen,
        })}
        disabled={disabled}
        autoComplete={AUTOCOMPLETE_STR()}
        ref={inputRef}
        autoCorrect="off"
        spellCheck={false}
        readOnly
        tabIndex={0}
        onMouseDown={event => event.stopPropagation()}
      />

      <ClickAwayListener mouseEvent="onMouseDown" onClickAway={close}>
        <Popper
          style={{ zIndex: 100 }}
          open={isOpen}
          anchorEl={containerRef.current}
          disablePortal={isPortalDisabled}
        >
          <div
            className={styles.popperBody}
            style={{
              width: containerRef.current?.clientWidth,
            }}
          >
            <div className={styles.popperContent}>{getChildren()}</div>
          </div>
        </Popper>
      </ClickAwayListener>
      {clearIcon && !disabled && !isOpen && value?.name ? (
        <button
          className={styles.dropDownIcon}
          data-test={`${
            inputRef?.current?.getAttribute('data-test') || ''
          }_clear_button`}
          onClick={handleClear}
          type="button"
          tabIndex={-1}
        >
          <IconClose16 fillColor="var(--primaryText)" />
        </button>
      ) : (
        dropDownIcon &&
        !disabled && (
          <button
            className={clsx(styles.dropDownIcon, { [styles.rotate]: isOpen })}
            data-test={`${
              inputRef?.current?.getAttribute('data-test') || ''
            }_dropdown_button`}
            onClick={handleArrowClick}
            type="button"
            tabIndex={-1}
          >
            <IconArrowDown />
          </button>
        )
      )}
    </div>
  );
};
export default memo(CustomAutocomplete);

const FilteredAutocomplete: FC<Partial<Props>> = memo(
  ({
    clearIcon = true,
    dropDownIcon = true,
    value,
    onChange,
    options,
    ...restProps
  }) => {
    const [currValue, setCurrValue] = useState<ISelectOption>();

    useEffect(() => {
      if (value != null) {
        const option = Object.values(options || {}).find(v => v.id === value);
        setCurrValue(option);
      }
    }, [options, value]);

    const filteredOptions = useMemo(() => {
      const result: ISelectOptions = {};
      const currValueName = String(currValue?.name || '').toLowerCase();
      Object.entries(options || {}).forEach(([k, v]) => {
        if (!currValueName || v.name.toLowerCase().startsWith(currValueName))
          result[k] = v;
      });
      return result;
    }, [options, currValue]);

    const handleOnChange = useCallback(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (o: any) => {
        const option: ISelectOption = o;
        setCurrValue(option);
        if (onChange) onChange(option?.id);
      },
      [onChange],
    );

    const handleOnBlur = useCallback(() => {
      // NOTE: unrem if it necessary to clear wrong entered value on lost focus
      // setTimeout(
      //   () => setCurrValue(cv => (cv?.id != null ? cv : undefined)),
      //   1000,
      // );
    }, []);

    return (
      <CustomAutocomplete
        {...restProps}
        clearIcon={clearIcon}
        dropDownIcon={dropDownIcon}
        options={filteredOptions}
        value={currValue}
        onChange={handleOnChange}
        onBlur={undefined}
        handleOnBlur={handleOnBlur}
      />
    );
  },
);

export { FilteredAutocomplete };

/**
 * MultiAutocomplete component for selecting and displaying (as Chip-cards) multiple items
 * @param options - object with all possible options to select
 * @param value - array of ids (number or string) of currently selected options
 * @param onChange - on value changed callback (send new ids array of currently selected options)
 * @returns Component
 */
const MultiAutocomplete: FC<Partial<Props>> = memo(
  ({ value, onChange, options, ...restProps }) => {
    const [currValue, setCurrValue] = useState<ISelectOption>();
    const [selectedOptions, setSelectedOptions] = useState<ISelectOption[]>([]);

    useEffect(() => {
      const selOptions: ISelectOption[] = [];
      const ids: ISelectOption['id'][] | null = value;
      if (ids && ids.length > 0) {
        ids.forEach(id => {
          const option = Object.values(options || {}).find(v => v.id === id);
          if (option) selOptions.push(option);
        });
      }
      setSelectedOptions(selOptions);
    }, [options, value]);

    useEffect(() => {
      if (options && '0' in options) {
        setSelectedOptions([]);
        setCurrValue(options['0']);
      }
    }, [options]);

    const filteredOptions = useMemo(() => {
      const result: ISelectOptions = {};
      const currValueName = String(currValue?.name || '').toLowerCase();
      if (
        currValueName !== 'all companies' &&
        currValueName !== 'all contracts'
      ) {
        Object.entries(options || {}).forEach(([k, v]) => {
          if (
            !selectedOptions.find(val => val.id === v.id) &&
            (!currValueName || v.name.toLowerCase().startsWith(currValueName))
          )
            result[k] = v;
        });
        return result;
      }
      return options;
    }, [options, currValue, selectedOptions]);

    const handleOnChange = useCallback(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (o: any) => {
        let option: ISelectOption | undefined = o;
        if (option?.id) {
          // Save option because its cleared
          const tmpOption = option;
          setSelectedOptions(prevOptions => {
            const newOptions = tmpOption
              ? [...prevOptions, tmpOption]
              : prevOptions;
            if (onChange) onChange(newOptions.map(({ id }) => id));
            return newOptions;
          });
          // Clear entered value in input
          option = undefined;
        } else if (option?.id === 0) {
          setSelectedOptions([]);
          setCurrValue(undefined);
          if (onChange) onChange([]);
        }
        setCurrValue(option);
      },
      [onChange],
    );

    const handleOnBlur = useCallback(() => {
      // NOTE: unrem if it necessary to clear wrong entered value on lost focus
      // setTimeout(
      //   () => setCurrValue(cv => (cv?.id != null ? cv : undefined)),
      //   1000,
      // );
    }, []);

    return (
      <CustomAutocomplete
        {...restProps}
        dropDownIcon
        options={filteredOptions}
        value={currValue}
        onChange={handleOnChange}
        onBlur={undefined}
        handleOnBlur={handleOnBlur}
      >
        {selectedOptions.map(chip => (
          <div
            className={clsx(styles.chip, { [styles.errorChip]: chip.error })}
            key={chip.id}
          >
            <span>{chip.name}</span>
            <button
              data-test={`${chip.id}_chip_remove_button`}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedOptions(currOptions => {
                  const newOptions = currOptions.filter(
                    ({ id }) => id !== chip.id,
                  );
                  if (onChange) onChange(newOptions.map(({ id }) => id));
                  if (newOptions.length === 0 && options && '0' in options) {
                    setSelectedOptions([]);
                    setCurrValue(options['0']);
                    if (onChange) onChange([]);
                  }
                  return newOptions;
                });
              }}
              type="button"
              tabIndex={-1}
            >
              <IconClose width={16} height={16} />
            </button>
          </div>
        ))}
      </CustomAutocomplete>
    );
  },
);

export { MultiAutocomplete };
