import { LOGOUT } from 'components/Auth/_redux/authTypes';
import {
  CLEAR_FILTERS,
  GET_OVERVIEW,
  GET_OVERVIEW_FAIL,
  GET_OVERVIEW_SUCCESS,
  IOverviewReducer,
  overviewActionsType,
  SET_FILTER,
  SET_FILTER_COUNT,
  SET_FILTER_KEY,
  SET_WIDGET_VIEW,
  SET_WIDGETS,
  UPDATE_FILTER,
} from 'components/Dispatch/TabReports/Overview/_redux/overviewTypes';

const widgets = [
  { key: 'total_gross', label: 'Total Gross', checked: true },
  { key: 'rate_per_miles', label: 'Rate Per Mile', checked: true },
  { key: 'trucks_drivers', label: 'Drivers and Trucks', checked: true },
  { key: 'avg_gross_per_driver', label: 'Avg Gross Per Driver', checked: true },
  { key: 'avg_gross_per_truck', label: 'Avg Gross Per Truck', checked: true },
  { key: 'loads', label: 'Loads', checked: true },
  { key: 'avg_load_rate', label: 'Avg Load Rate', checked: true },
  { key: 'miles', label: 'Miles', checked: true },
  { key: 'order_types', label: 'Order Types', checked: true },
];

const initialState: IOverviewReducer = {
  widgets,
  widgetView: 'bar',
  _pending: false,
  _loaded: false,
  _error: null,
  reports: {
    charts: {},
  },
  filterCount: 0,
};

const overviewReducer = (
  state = initialState,
  action: overviewActionsType,
): IOverviewReducer => {
  switch (action.type) {
    case GET_OVERVIEW:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };

    case GET_OVERVIEW_SUCCESS:
      return {
        ...state,
        _loaded: true,
        _pending: false,
        reports: action.payload,
      };

    case GET_OVERVIEW_FAIL:
      return {
        ...initialState,
        _error: action.payload,
      };

    case SET_WIDGETS:
      return {
        ...state,
        widgets: action.payload,
        _loaded: true,
      };

    case SET_WIDGET_VIEW:
      return {
        ...state,
        widgetView: action.payload,
        _loaded: true,
      };

    case SET_FILTER_KEY:
      return {
        ...state,
        filterKey: action.payload ? action.payload : undefined,
        _loaded: true,
        _pending: false,
        _error: null,
      };

    case SET_FILTER:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        filters: {
          ...state.filters,
          [action.payload.key]: action.payload.filters,
        },
      };

    case UPDATE_FILTER: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const updated = state.filters[action.payload.key].filter(
        (f: string) => f !== action.payload.id.toString(),
      );
      if (updated.length > 0) {
        return {
          ...state,
          _loaded: false,
          _pending: true,
          filters: {
            ...state.filters,
            [action.payload.key]: [...updated],
          },
        };
      }

      return {
        ...state,
        _loaded: false,
        _pending: true,
        filters: undefined,
      };
    }

    case CLEAR_FILTERS:
      if (action.payload) {
        const copy = { ...state.filters };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        action.payload.forEach((f: keyof FilterPayload) => delete copy[f]);
        return {
          ...state,
          _loaded: false,
          _pending: true,
          filters: { ...copy },
        };
      }

      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
        filterKey: undefined,
        filters: undefined,
      };

    case SET_FILTER_COUNT:
      return {
        ...state,
        _loaded: true,
        _pending: false,
        _error: null,
        filterCount: action.payload,
      };

    case LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
};

export default overviewReducer;
