import { FC } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const LittleSuitcase: FC<IOwnProps> = ({
  className,
  fillColor = '#86898E',
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11 5H8H5M11 5H13C14.1046 5 15 5.89543 15 7V9V13C15 14.1046 14.1046 15 13 15H3C1.89543 15 1 14.1046 1 13V7C1 5.89543 1.89543 5 3 5H5M11 5H5M11 5V3C11 1.89543 10.1046 1 9 1H7C5.89543 1 5 1.89543 5 3V5"
        stroke={fillColor}
        strokeWidth="2"
      />
    </svg>
  );
};

export default LittleSuitcase;
