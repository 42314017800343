import { useRef, useCallback } from 'react';

/**
 * Returns a memoized function that will only call the passed function when it hasn't been called for the delay period
 * @param callback The function to be called
 * @param delay Wait period (in ms) after function hasn't been called for
 * @returns A memoized function that is debounced
 */
export default function useDebounceCallback(callback?: Function, delay = 300) {
  const timerId = useRef<NodeJS.Timeout>();

  return useCallback(
    (...args: unknown[]) => {
      // clear timer
      if (timerId.current) clearTimeout(timerId.current);
      timerId.current = undefined;

      if (callback)
        timerId.current = setTimeout(() => {
          // clear timer
          if (timerId.current) clearTimeout(timerId.current);
          timerId.current = undefined;

          return callback(...args);
        }, delay);
    },
    [callback, delay],
  );
}
