import moment from 'moment';
import { isEmpty } from 'ramda';

import { LOGOUT } from 'components/Auth/_redux/authTypes';
import { SERVER_DATE_FORMAT } from 'constants/dateFormats';

import { IDriversPayrollItem } from '../_models/payrollsModels';
import {
  GET_DRIVER_PAYROLLS,
  GET_DRIVER_PAYROLLS_FAIL,
  GET_DRIVER_PAYROLLS_SUCCESS,
  GET_DRIVERS,
  GET_DRIVERS_FAIL,
  GET_DRIVERS_PAYROLLS_ITEMS,
  GET_DRIVERS_PAYROLLS_ITEMS_FAIL,
  GET_DRIVERS_PAYROLLS_ITEMS_SUCCESS,
  GET_DRIVERS_SUCCESS,
  GET_PAYROLL_ITEM_COMMENTS,
  GET_PAYROLL_ITEM_COMMENTS_FAIL,
  GET_PAYROLL_ITEM_COMMENTS_SUCCESS,
  GET_PAYROLLS,
  GET_PAYROLLS_FAIL,
  GET_PAYROLLS_SUCCESS,
  IAccountingPayrollsReducer,
  PATCH_DRIVER_PAYROLLS,
  PATCH_DRIVER_PAYROLLS_FAIL,
  PATCH_DRIVER_PAYROLLS_SUCCESS,
  payrollsActionTypes,
  UPDATE_DRIVER_PAYROLL_STATUS_FAIL,
  UPDATE_DRIVER_PAYROLL_STATUS_SUCCESS,
  UPDATE_PAYROLL_ITEM,
} from './payrollsTypes';

const initialState: IAccountingPayrollsReducer = {
  driversPayrollsItems: {
    list: [],
    pagination: undefined,
    statistics: undefined,
    _pending: false,
    _loaded: false,
    _error: null,
  },

  payrolls: {
    list: [],
    statistics: undefined,
    pagination: undefined,
    _pending: false,
    _loaded: false,
    _error: null,
  },

  payrollItems: {
    driverId: undefined,
    status: undefined,
    list: [],
    totalAmount: 0, // contains sum of amounts of 'added' items only
    hasComments: false,
    commentsCount: 0,
    pdfId: 0,
    _pending: false,
    _loaded: false,
    _error: null,
  },

  drivers: {
    list: [],
    pagination: undefined,
    _pending: false,
    _loaded: false,
    _error: null,
  },

  payrollItemComments: {
    comments: [],
    _pending: false,
    _loaded: false,
    _error: null,
  },
};

const payrollsReducers = (
  state = initialState,
  action: payrollsActionTypes,
): IAccountingPayrollsReducer => {
  switch (action.type) {
    case GET_DRIVERS_PAYROLLS_ITEMS:
      return {
        ...state,
        driversPayrollsItems: {
          ...state.driversPayrollsItems,
          _loaded: false,
          _pending: true,
          _error: null,
        },
      };

    case GET_DRIVERS_PAYROLLS_ITEMS_FAIL:
      return {
        ...state,
        driversPayrollsItems: {
          ...state.driversPayrollsItems,
          _error: action.payload,
          _loaded: false,
          _pending: false,
        },
      };

    case GET_DRIVERS_PAYROLLS_ITEMS_SUCCESS:
      return {
        ...state,
        driversPayrollsItems: {
          ...state.driversPayrollsItems,
          ...action.payload,
          _loaded: true,
          _pending: false,
        },
      };

    case UPDATE_DRIVER_PAYROLL_STATUS_SUCCESS: {
      const { status, payroll_number } = action.payload;
      const { list: payrollsList } = state.driversPayrollsItems;
      const payrollIndex = payrollsList.findIndex(
        (item: IDriversPayrollItem) => item.payroll_number === payroll_number,
      );

      const newList = [...payrollsList];

      if (payrollIndex >= 0) {
        newList[payrollIndex] = { ...payrollsList[payrollIndex], status };
      }

      return {
        ...state,
        driversPayrollsItems: {
          ...state.driversPayrollsItems,
          list: [...newList],
        },
      };
    }

    case UPDATE_DRIVER_PAYROLL_STATUS_FAIL:
      return {
        ...state,
        driversPayrollsItems: {
          ...state.driversPayrollsItems,
          _error: action.payload,
          _loaded: false,
          _pending: false,
        },
      };

    case GET_PAYROLLS:
      return {
        ...state,
        payrolls: {
          ...state.payrolls,
          _loaded: false,
          _pending: true,
          _error: null,
        },
      };

    case GET_PAYROLLS_FAIL:
      return {
        ...state,
        payrolls: {
          ...state.payrolls,
          _error: action.payload,
          _loaded: false,
          _pending: false,
        },
      };

    case GET_PAYROLLS_SUCCESS:
      return {
        ...state,
        payrolls: {
          ...state.payrolls,
          ...action.payload,
          _loaded: true,
          _pending: false,
        },
      };

    case GET_DRIVER_PAYROLLS:
    case PATCH_DRIVER_PAYROLLS:
      return {
        ...state,
        payrollItems: {
          ...state.payrollItems,
          _loaded: false,
          _pending: true,
          _error: null,
        },
      };

    case GET_DRIVER_PAYROLLS_FAIL:
    case PATCH_DRIVER_PAYROLLS_FAIL:
      return {
        ...state,
        payrollItems: {
          ...state.payrollItems,
          _error: action.payload,
          _loaded: false,
          _pending: false,
        },
      };

    case GET_DRIVER_PAYROLLS_SUCCESS: {
      const totalAmount = action.payload.list.reduce(
        (sum, { status, amount }) => (status === 'added' ? sum + amount : sum),
        0,
      );

      if (
        !isEmpty(state.payrollItems.list) &&
        state.payrollItems.driverId === action.payload.driverId
      )
        for (const newItem of action.payload.list)
          if (
            !state.payrollItems.list.find(oldItem => oldItem.id === newItem.id)
          )
            newItem.isNew = true;

      return {
        ...state,
        payrollItems: {
          ...state.payrollItems,
          ...action.payload,
          totalAmount,
          _loaded: true,
          _pending: false,
        },
      };
    }

    case UPDATE_PAYROLL_ITEM: {
      const { id, status } = action.payload;
      const index = state.payrollItems.list.findIndex(item => item.id === id);
      if (index !== -1) {
        const list = [...state.payrollItems.list];
        list[index] = { ...list[index], new_status: status, isNew: false };
        const totalAmount =
          state.payrollItems.totalAmount +
          (status === 'added' ? list[index].amount : -list[index].amount);
        return {
          ...state,
          payrollItems: {
            ...state.payrollItems,
            list,
            totalAmount,
          },
        };
      }
      return state;
    }

    case PATCH_DRIVER_PAYROLLS_SUCCESS: {
      const { driverId, payrollStatus, items } = action.payload;
      let { list: driversList } = state.drivers;
      let { list: payrollsList } = state.payrolls;

      const drivers = Array.isArray(driverId) ? driverId : [driverId];
      for (const driver of drivers) {
        const driverIndex = driversList.findIndex(
          ({ id, payroll_status }) =>
            id === driver && payroll_status !== payrollStatus,
        );
        if (driverIndex !== -1) {
          if (driversList === state.drivers.list)
            driversList = [...driversList];
          driversList[driverIndex] = {
            ...driversList[driverIndex],
            payroll_status: payrollStatus,
          };
        }

        const payrollIndex = payrollsList.findIndex(
          ({ driver_id, status }) =>
            driver_id === driver && status !== payrollStatus,
        );
        if (payrollIndex !== -1) {
          if (payrollsList === state.payrolls.list)
            payrollsList = [...payrollsList];
          payrollsList[payrollIndex] = {
            ...payrollsList[payrollIndex],
            status: payrollStatus,
          };
          if (payrollStatus === 'paid')
            payrollsList[payrollIndex].paid_date =
              moment().format(SERVER_DATE_FORMAT);
        }
      }

      let { list: itemsList, totalAmount } = state.payrollItems;
      if (!isEmpty(items)) {
        itemsList = [...itemsList];
        items.forEach(({ id, status }) => {
          const itemIndex = itemsList.findIndex(item => item.id === id);
          if (itemIndex !== -1) {
            itemsList[itemIndex] = { ...itemsList[itemIndex], status };
            delete itemsList[itemIndex].new_status;
          }
        });
        totalAmount = itemsList.reduce(
          (sum, { status, amount }) =>
            status === 'added' ? sum + amount : sum,
          0,
        );
      }
      return {
        ...state,
        drivers: {
          ...state.drivers,
          list: driversList,
        },
        payrolls: {
          ...state.payrolls,
          list: payrollsList,
        },
        payrollItems: {
          ...state.payrollItems,
          status: payrollStatus,
          list: itemsList,
          totalAmount,
          _loaded: true,
          _pending: false,
        },
      };
    }

    case GET_DRIVERS:
      return {
        ...state,
        drivers: {
          ...state.drivers,
          _loaded: false,
          _pending: true,
          _error: null,
        },
      };

    case GET_DRIVERS_FAIL:
      return {
        ...state,
        drivers: {
          ...state.drivers,
          _error: action.payload,
          _loaded: false,
          _pending: false,
        },
      };

    case GET_DRIVERS_SUCCESS:
      return {
        ...state,
        drivers: {
          ...state.drivers,
          list: action.payload,
          _loaded: true,
          _pending: false,
        },
      };

    case GET_PAYROLL_ITEM_COMMENTS:
      return {
        ...state,
        payrollItemComments: {
          ...state.payrollItemComments,
          _loaded: false,
          _pending: true,
          _error: null,
        },
      };

    case GET_PAYROLL_ITEM_COMMENTS_SUCCESS:
      return {
        ...state,
        payrollItemComments: {
          ...state.payrollItemComments,
          comments: action.payload.comments,
          _loaded: true,
          _pending: false,
          _error: null,
        },
      };

    case GET_PAYROLL_ITEM_COMMENTS_FAIL:
      return {
        ...state,
        payrollItemComments: {
          ...state.payrollItemComments,
          _loaded: false,
          _pending: false,
          _error: action.payload,
        },
      };

    case LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
};

export default payrollsReducers;
