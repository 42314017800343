import { LOGOUT } from 'components/Auth/_redux/authTypes';

import {
  ADD_DEDUCTION_FAIL,
  CANCEL_DEDUCTION_FAIL,
  CREATE_ACCOUNT_ENTRY,
  DELETE_ACCOUNT_ENTRY,
  DELETE_ACCOUNT_ENTRY_FAIL,
  DELETE_ACCOUNT_ENTRY_SUCCESS,
  DELETE_DEDUCTION_FAIL,
  GET_ACCOUNT_ENTRIES_FAIL,
  GET_ACCOUNT_ENTRIES_SUCCESS,
  GET_ACCOUNT_FUEL,
  GET_ACCOUNT_FUEL_FAIL,
  GET_ACCOUNT_FUEL_SUCCESS,
  GET_DEDUCTION_DETAILS,
  GET_DEDUCTION_DETAILS_FAIL,
  GET_DEDUCTION_DETAILS_SUCCESS,
  GET_DEDUCTION_PAYMENTS_FAIL,
  GET_DEDUCTION_PAYMENTS_SUCCESS,
  GET_DEDUCTIONS_FAIL,
  GET_DEDUCTIONS_SUCCESS,
  GET_DRIVER_FUEL,
  GET_DRIVER_FUEL_FAIL,
  GET_DRIVER_FUEL_SUCCESS,
  GET_SCHED_DEDUCTION_DRIVERS,
  GET_SCHED_DEDUCTION_DRIVERS_FAIL,
  GET_SCHED_DEDUCTION_DRIVERS_SUCCESS,
  IAccountingPayrollItemsReducer,
  payrollItemsActionTypes,
  STORE_ACCOUNT_ENTRY_FAIL,
  STORE_ACCOUNT_ENTRY_SUCCESS,
  UPDATE_ACCOUNT_ENTRY,
} from './payrollItemsTypes';

const initialState: IAccountingPayrollItemsReducer = {
  schedDeductionDrivers: {
    list: [],
    pagination: undefined,
    _pending: false,
    _loaded: false,
    _error: null,
  },

  deductions: {
    list: [],
    deductionDetails: null,
    deductionPayments: {
      list: [],
      pagination: undefined,
      _loaded: false,
      _error: null,
    },
    pagination: undefined,
    _loaded: false,
    _error: null,
  },

  credits: {
    list: [],
    pagination: undefined,
    _loaded: false,
    _error: null,
  },

  fuel: {
    list: [],
    isFiltered: false,
    statistics: undefined,
    pagination: undefined,
    _pending: false,
    _loaded: false,
    _error: null,
  },

  fuelDriver: {
    list: [],
    isFiltered: false,
    statistics: undefined,
    pagination: undefined,
    _pending: false,
    _loaded: false,
    _error: null,
  },
};

const payrollItemsReducers = (
  state = initialState,
  action: payrollItemsActionTypes,
): IAccountingPayrollItemsReducer => {
  switch (action.type) {
    case GET_SCHED_DEDUCTION_DRIVERS:
      return {
        ...state,
        schedDeductionDrivers: {
          ...state.schedDeductionDrivers,
          _loaded: false,
          _pending: true,
          _error: null,
        },
      };

    case GET_SCHED_DEDUCTION_DRIVERS_FAIL:
      return {
        ...state,
        schedDeductionDrivers: {
          ...state.schedDeductionDrivers,
          _error: action.payload,
          _loaded: false,
          _pending: false,
        },
      };

    case GET_SCHED_DEDUCTION_DRIVERS_SUCCESS:
      return {
        ...state,
        schedDeductionDrivers: {
          ...state.schedDeductionDrivers,
          ...action.payload,
          _loaded: true,
          _pending: false,
        },
      };

    case CREATE_ACCOUNT_ENTRY:
    case UPDATE_ACCOUNT_ENTRY:
    case DELETE_ACCOUNT_ENTRY: {
      const { entryType } = action.payload;

      switch (entryType) {
        case 'credit':
          return {
            ...state,
            credits: {
              ...state.credits,
              _loaded: false,
              _error: null,
            },
          };

        default:
      }
      return state;
    }

    case GET_ACCOUNT_ENTRIES_FAIL:
    case STORE_ACCOUNT_ENTRY_FAIL:
    case DELETE_ACCOUNT_ENTRY_FAIL: {
      const { entryType, error } = action.payload;

      switch (entryType) {
        case 'credit':
          return {
            ...state,
            credits: {
              ...state.credits,
              _error: error,
              _loaded: false,
            },
          };

        default:
      }
      return state;
    }

    case GET_ACCOUNT_ENTRIES_SUCCESS: {
      const { entryType, list, pagination } = action.payload;

      switch (entryType) {
        case 'credit':
          return {
            ...state,
            credits: {
              ...state.credits,
              list,
              pagination,
              _loaded: true,
            },
          };

        default:
      }
      return state;
    }

    case STORE_ACCOUNT_ENTRY_SUCCESS: {
      const { entryType, entry } = action.payload;
      let { list } = state.credits;
      const index = list.findIndex(
        item => item.id === entry.id && item.driver_id === entry.driver_id,
      );
      if (index >= 0) {
        list = [...list];
        list[index] = { ...list[index], ...entry };
      }

      switch (entryType) {
        case 'credit':
          return {
            ...state,
            credits: {
              ...state.credits,
              list,
              _loaded: true,
            },
          };

        default:
      }
      return state;
    }

    case DELETE_ACCOUNT_ENTRY_SUCCESS: {
      const { driverId, entryId, entryType } = action.payload;
      let { list } = state.credits;
      let { pagination } = state.credits;
      const index = list.findIndex(
        item => item.id === entryId && item.driver_id === driverId,
      );
      if (index >= 0) {
        list = [...list];
        list.splice(index, 1);
        if (pagination)
          pagination = { ...pagination, total: pagination.total - 1 };
      }

      switch (entryType) {
        case 'credit':
          return {
            ...state,
            credits: {
              ...state.credits,
              list,
              pagination,
              _loaded: true,
            },
          };

        default:
      }
      return state;
    }

    case GET_DEDUCTIONS_SUCCESS: {
      const { list, pagination } = action.payload;
      return {
        ...state,
        deductions: {
          ...state.deductions,
          list,
          pagination,
          _loaded: true,
        },
      };
    }

    case GET_DEDUCTIONS_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        deductions: {
          ...state.deductions,
          _error: error,
          _loaded: false,
        },
      };
    }

    case GET_DEDUCTION_DETAILS: {
      return {
        ...state,
        deductions: {
          ...state.deductions,
          _loaded: false,
        },
      };
    }

    case GET_DEDUCTION_DETAILS_SUCCESS: {
      const { deductionDetails } = action.payload;
      return {
        ...state,
        deductions: {
          ...state.deductions,
          _loaded: true,
          deductionDetails,
        },
      };
    }

    case GET_DEDUCTION_DETAILS_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        deductions: {
          ...state.deductions,
          _error: error,
          _loaded: false,
        },
      };
    }

    case GET_DEDUCTION_PAYMENTS_SUCCESS: {
      const { payroll_items, pagination } = action.payload;
      return {
        ...state,
        deductions: {
          ...state.deductions,
          deductionPayments: {
            ...state.deductions.deductionPayments,
            list: payroll_items,
            pagination,
            _loaded: true,
          },
        },
      };
    }

    case GET_DEDUCTION_PAYMENTS_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        deductions: {
          ...state.deductions,
          deductionPayments: {
            ...state.deductions.deductionPayments,
            _error: error,
          },
        },
      };
    }

    case ADD_DEDUCTION_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        deductions: {
          ...state.deductions,
          _error: error,
          _loaded: false,
        },
      };
    }

    case DELETE_DEDUCTION_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        deductions: {
          ...state.deductions,
          _error: error,
          _loaded: false,
        },
      };
    }

    case CANCEL_DEDUCTION_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        deductions: {
          ...state.deductions,
          _error: error,
          _loaded: false,
        },
      };
    }

    case GET_ACCOUNT_FUEL:
      return {
        ...state,
        fuel: {
          ...state.fuel,
          _loaded: false,
          _pending: true,
          _error: null,
        },
      };

    case GET_ACCOUNT_FUEL_FAIL:
      return {
        ...state,
        fuel: {
          ...state.fuel,
          _error: action.payload,
          _loaded: false,
          _pending: false,
        },
      };

    case GET_ACCOUNT_FUEL_SUCCESS:
      return {
        ...state,
        fuel: {
          ...state.fuel,
          ...action.payload,
          _loaded: true,
          _pending: false,
        },
      };

    case GET_DRIVER_FUEL:
      return {
        ...state,
        fuelDriver: {
          ...state.fuelDriver,
          _loaded: false,
          _pending: true,
          _error: null,
        },
      };

    case GET_DRIVER_FUEL_FAIL:
      return {
        ...state,
        fuelDriver: {
          ...state.fuelDriver,
          _error: action.payload,
          _loaded: false,
          _pending: false,
        },
      };

    case GET_DRIVER_FUEL_SUCCESS:
      return {
        ...state,
        fuelDriver: {
          ...state.fuelDriver,
          ...action.payload,
          _loaded: true,
          _pending: false,
        },
      };

    case LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
};

export default payrollItemsReducers;
