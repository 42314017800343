import { IOrderAddressReceiptPhoto } from 'components/Dispatch/TabOrders/_models/orderModels';

export const orderFileToFormData = ({
  file,
  visible_for_driver,
  category_id,
}: {
  file: File;
  category_id: number;
  visible_for_driver: boolean;
}) => {
  if (!file) return null;

  const formData = new FormData();

  formData.append(`orders_file[file]`, file);
  formData.append(`orders_file[category_id]`, `${category_id}`);
  formData.append(
    `orders_file[visible_for_driver]`,
    `${Number(!!visible_for_driver)}`,
  );

  return formData;
};

export const orderFileCategoryToFormData = ({
  visible_for_driver,
  category_id,
}: {
  category_id: number;
  visible_for_driver: boolean;
}) => {
  const formData = new FormData();

  formData.append(`orders_file[category_id]`, `${category_id}`);
  formData.append(
    `orders_file[visible_for_driver]`,
    `${Number(visible_for_driver)}`,
  );

  return formData;
};

export const orderAddressFileToFormData = (
  fileCategory?: 'cargo_photos' | 'proof_photos' | 'receipt_files' | string, // @todo
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fileObj?: any,
) => {
  if (!fileObj || !fileObj.file) return null;

  const formData = new FormData();
  let field;
  switch (fileCategory) {
    case 'cargo_photos': {
      field = 'orders_addresses_files_cargo_photo';
      break;
    }
    case 'proof_photos': {
      field = 'orders_addresses_files_proof_photo';
      break;
    }
    case 'receipt_files': {
      field = 'orders_addresses_receipt_file';
      break;
    }
    default: {
      return null;
    }
  }

  if (fileCategory === 'receipt_files') {
    formData.append(
      `orders_addresses_receipt_file[receipt_type]`,
      `${fileObj.receipt_type}`,
    );
    formData.append(
      `orders_addresses_receipt_file[amount]`,
      `${fileObj.amount}`,
    );
  }

  if (fileObj.blob) {
    formData.append(`${field}[file]`, fileObj.blob, fileObj.file_name);
  }

  return formData;
};

export const orderAddressReceiptToFormData = (
  photo: IOrderAddressReceiptPhoto,
) => {
  const formData = new FormData();

  formData.append(
    `orders_addresses_receipt_file[receipt_type]`,
    `${photo.receipt_type}`,
  );
  formData.append(`orders_addresses_receipt_file[amount]`, `${photo.amount}`);

  return formData;
};
