import { FC, useCallback } from 'react';

import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import { MaskedTimePicker } from '../CustomTimePicker/CustomTimePicker';

import styles from './TimeRangePicker.module.scss';

type OwnProps = {
  className: string;
  id: string;
  placeholder: string;
  formatDate: string;
  focusTimeToOnRender?: boolean;
  focusTimeFromOnRender?: boolean;
  step?: number;
  ampm?: boolean;
};

type Props = OwnProps & WrappedFieldInputProps & WrappedFieldMetaProps;

const TimeRangePicker: FC<Partial<Props>> = ({
  name,
  value,
  onChange,
  className,
  step,
  placeholder,
  invalid,
  touched,
  ampm = true,
  focusTimeToOnRender,
  focusTimeFromOnRender,
}) => {
  const { from, to } = value || {};

  const handleTimeFromChange = useCallback(
    (newFrom?: string | null) => {
      if (onChange) {
        onChange({
          from: newFrom,
          to,
        });
      }
    },
    [onChange, to],
  );

  const handleTimeToChange = useCallback(
    (newTo?: string | null) => {
      if (onChange) {
        onChange({
          from,
          to: newTo,
        });
      }
    },
    [onChange, from],
  );

  return (
    <div className={styles.root}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MaskedTimePicker
          value={from}
          onChange={handleTimeFromChange}
          className={className}
          name={`${name}_from`}
          ampm={ampm}
          step={step}
          placeholder={placeholder}
          invalid={invalid && !from}
          touched={touched}
          focusOnRender={focusTimeFromOnRender}
        />

        <span className={styles.line} />

        <MaskedTimePicker
          value={to}
          onChange={handleTimeToChange}
          className={className}
          name={`${name}_to`}
          ampm={ampm}
          step={step}
          placeholder={placeholder}
          invalid={invalid && !to}
          touched={touched}
          focusOnRender={focusTimeToOnRender}
        />
      </LocalizationProvider>
    </div>
  );
};

export default TimeRangePicker;
