import { FC } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const IconSettingsWise: FC<IOwnProps> = ({
  className,
  fillColor = 'var(--sideBarIcon)',
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g>
        <path
          d="M9 4L15 4M9 4C9 5.10457 8.10457 6 7 6C5.89543 6 5 5.10457 5 4M9 4C9 2.89543 8.10457 2 7 2C5.89543 2 5 2.89543 5 4M1 4H5M1 12H11M11 12C11 13.1046 11.8954 14 13 14C14.1046 14 15 13.1046 15 12C15 10.8954 14.1046 10 13 10C11.8954 10 11 10.8954 11 12Z"
          stroke={fillColor}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

export default IconSettingsWise;
