import { useSelector } from 'react-redux';

import { userThemeSelector } from 'redux/userSettings/userSettingsSelectors';

type Props = {
  className?: string;
  fillColor?: string;
};

const LittleMoney = ({ className, fillColor = '#2C6AF6' }: Props) => {
  const theme = useSelector(userThemeSelector);
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15 0H1C0.4 0 0 0.4 0 1V11C0 11.6 0.4 12 1 12H15C15.6 12 16 11.6 16 11V1C16 0.4 15.6 0 15 0ZM14 10H2V2H14V10Z"
        fill={theme === 'dark' ? 'white' : fillColor}
      />
      <path
        d="M8 8C9.10457 8 10 7.10457 10 6C10 4.89543 9.10457 4 8 4C6.89543 4 6 4.89543 6 6C6 7.10457 6.89543 8 8 8Z"
        fill={theme === 'dark' ? 'white' : fillColor}
      />
    </svg>
  );
};

export default LittleMoney;
