import { stopSubmit, startSubmit } from 'redux-form';
import {
  call,
  put,
  takeLeading,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';

import {
  createAccidentAction,
  getAccidentAction,
  getAccidentsAction,
  updateAccidentAction,
  sendAccidentPaymentAction,
  uploadAccidentFilesAction,
  deleteAccidentFileAction,
  getAccidentActivityAction,
  createAccidentActivityItemAction,
  deleteAccidentAction,
  uploadAccidentPaymentFilesAction,
} from 'components/Safety/TabAccidents/_redux/accidentsActions';
import {
  CREATE_ACCIDENT,
  CREATE_ACCIDENT_SUCCESS,
  CREATE_ACCIDENT_FAIL,
  GET_ACCIDENTS,
  GET_ACCIDENTS_SUCCESS,
  GET_ACCIDENTS_FAIL,
  DELETE_ACCIDENT,
  DELETE_ACCIDENT_SUCCESS,
  DELETE_ACCIDENT_FAIL,
  UPDATE_ACCIDENT,
  UPDATE_ACCIDENT_SUCCESS,
  UPDATE_ACCIDENT_FAIL,
  GET_ACCIDENT,
  GET_ACCIDENT_SUCCESS,
  GET_ACCIDENT_FAIL,
  SEND_ACCIDENT_PAYMENT,
  SEND_ACCIDENT_PAYMENT_SUCCESS,
  SEND_ACCIDENT_PAYMENT_FAIL,
  UPLOAD_ACCIDENT_FILES,
  UPLOAD_ACCIDENT_FILES_SUCCESS,
  UPLOAD_ACCIDENT_FILES_FAIL,
  UPLOAD_ACCIDENT_PAYMENT_FILES,
  UPLOAD_ACCIDENT_PAYMENT_FILES_SUCCESS,
  UPLOAD_ACCIDENT_PAYMENT_FILES_FAIL,
  DELETE_ACCIDENT_FILES,
  DELETE_ACCIDENT_FILES_SUCCESS,
  DELETE_ACCIDENT_FILES_FAIL,
  GET_ACCIDENT_ACTIVITY,
  GET_ACCIDENT_ACTIVITY_SUCCESS,
  GET_ACCIDENT_ACTIVITY_FAIL,
  CREATE_ACCIDENT_ACTIVITY_ITEM,
  CREATE_ACCIDENT_ACTIVITY_ITEM_SUCCESS,
  CREATE_ACCIDENT_ACTIVITY_ITEM_FAIL,
  ICreateAccidentActionSuccess,
  ICreateAccidentActionFail,
  IGetAccidentsActionSuccess,
  IGetAccidentsActionFail,
  IUpdateAccidentActionSuccess,
  IUpdateAccidentActionFail,
  IGetAccidentActionSuccess,
  IGetAccidentActionFail,
  ISendAccidentPaymentActionSuccess,
  ISendAccidentPaymentActionFail,
  IGetAccidentActivityActionSuccess,
  IGetAccidentActivityActionFail,
  ICreateAccidentActivityItemActionSuccess,
  ICreateAccidentActivityItemActionFail,
  IDeleteAccidentActionSuccess,
  IDeleteAccidentActionFail,
} from 'components/Safety/TabAccidents/_redux/accidentsTypes';
import { fileToFormData } from 'utils/converters';
import api from 'utils/requests';

function* createAccidentSaga({
  payload: { callback, accident },
}: ReturnType<typeof createAccidentAction>) {
  try {
    yield put(startSubmit('createAndUpdateAccidentForm'));
    const { data } = yield call(() =>
      api.post('/v1/fleets/accidents', { fleets_accident: accident }),
    );
    yield put<ICreateAccidentActionSuccess>({
      type: CREATE_ACCIDENT_SUCCESS,
      payload: data.accident,
    });
    yield put(stopSubmit('createAndUpdateAccidentForm'));
    if (callback) callback(data.accident);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<ICreateAccidentActionFail>({
        type: CREATE_ACCIDENT_FAIL,
        payload: e,
      });
    yield put(stopSubmit('createAndUpdateAccidentForm'));
  }
}

function* getAccidentsSaga({
  params,
  query,
  callback,
}: ReturnType<typeof getAccidentsAction>) {
  try {
    const { data } = yield call(() =>
      api.post(`/v1/fleets/accidents/list${query}`, { filter: params }),
    );
    yield put<IGetAccidentsActionSuccess>({
      type: GET_ACCIDENTS_SUCCESS,
      payload: data,
    });

    if (callback) callback(data.accidents);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetAccidentsActionFail>({
        type: GET_ACCIDENTS_FAIL,
        payload: e,
      });
  }
}

function* updateAccidentSaga({
  payload: { callback, accident },
}: ReturnType<typeof updateAccidentAction>) {
  try {
    yield put(startSubmit('createAndUpdateAccidentForm'));
    const { data } = yield call(() =>
      api.patch(`/v1/fleets/accidents/${accident.id}`, {
        fleets_accident: accident,
      }),
    );
    yield put<IUpdateAccidentActionSuccess>({
      type: UPDATE_ACCIDENT_SUCCESS,
      payload: data.accident,
    });
    yield put(stopSubmit('createAndUpdateAccidentForm'));
    if (callback) callback(data.accident);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IUpdateAccidentActionFail>({
        type: UPDATE_ACCIDENT_FAIL,
        payload: e,
      });
    yield put(stopSubmit('createAndUpdateAccidentForm'));
  }
}

function* getAccidentSaga({
  payload: { id, callback },
}: ReturnType<typeof getAccidentAction>) {
  try {
    const { data } = yield call(() => api.get(`/v1/fleets/accidents/${id}`));
    yield put<IGetAccidentActionSuccess>({
      type: GET_ACCIDENT_SUCCESS,
      payload: data,
    });
    if (callback) callback(data.accident);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetAccidentActionFail>({
        type: GET_ACCIDENT_FAIL,
        payload: e,
      });
  }
}

function* deleteAccidentSaga({
  payload: { id, callback },
}: ReturnType<typeof deleteAccidentAction>) {
  try {
    yield call(() => api.delete(`/v1/fleets/accidents/${id}`));
    yield put<IDeleteAccidentActionSuccess>({
      type: DELETE_ACCIDENT_SUCCESS,
      id,
    });
    if (callback) callback(id);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IDeleteAccidentActionFail>({
        type: DELETE_ACCIDENT_FAIL,
        payload: e,
      });
  }
}

function* createAccidentActivityItemSaga({
  payload: {
    data: { id, log_message },
    callback,
  },
}: ReturnType<typeof createAccidentActivityItemAction>) {
  try {
    yield call(() =>
      api.post(`/v1/fleets/accidents/${id}/log`, {
        fleets_accident: { log_message },
      }),
    );
    yield put<ICreateAccidentActivityItemActionSuccess>({
      type: CREATE_ACCIDENT_ACTIVITY_ITEM_SUCCESS,
    });
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<ICreateAccidentActivityItemActionFail>({
        type: CREATE_ACCIDENT_ACTIVITY_ITEM_FAIL,
        payload: e,
      });
  }
}

function* sendAccidentPaymentSaga({
  payload: { callback, fleets_accidents_payment, id },
}: ReturnType<typeof sendAccidentPaymentAction>) {
  try {
    yield put(startSubmit('paymentForm'));
    yield call(() =>
      api.post(`/v1/fleets/accidents/${id}/payments`, {
        fleets_accidents_payment,
      }),
    );
    yield put<ISendAccidentPaymentActionSuccess>({
      type: SEND_ACCIDENT_PAYMENT_SUCCESS,
    });
    yield put(stopSubmit('paymentForm'));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<ISendAccidentPaymentActionFail>({
        type: SEND_ACCIDENT_PAYMENT_FAIL,
        payload: e,
      });
    yield put(stopSubmit('paymentForm'));
  }
}

function* uploadAccidentFilesSaga({
  payload: { files, itemId },
}: ReturnType<typeof uploadAccidentFilesAction>) {
  const url = itemId
    ? `/v1/fleets/accidents/${itemId}/files`
    : `/v1/fleets/accidents/files`;
  for (const fileObj of files) {
    yield call(function* processFiles() {
      try {
        const { data } = yield call(() =>
          api.post(
            url,
            fileToFormData(fileObj.file, `fleets_accidents_file[file]`),
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            },
          ),
        );
        yield put({
          type: UPLOAD_ACCIDENT_FILES_SUCCESS,
          payload: data.file,
        });
      } catch (e) {
        yield put({ type: UPLOAD_ACCIDENT_FILES_FAIL, payload: e });
      }
    });
  }
}

function* uploadAccidentPaymentFilesSaga({
  payload: { files, itemId },
}: ReturnType<typeof uploadAccidentPaymentFilesAction>) {
  const url = itemId
    ? `/v1/fleets/accidents/${itemId}/files`
    : `/v1/fleets/accidents/files`;
  for (const fileObj of files) {
    yield call(function* processFiles() {
      try {
        const { data } = yield call(() =>
          api.post(
            url,
            fileToFormData(fileObj.file, `fleets_accidents_file[file]`),
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            },
          ),
        );
        yield put({
          type: UPLOAD_ACCIDENT_PAYMENT_FILES_SUCCESS,
          payload: data.file,
        });
      } catch (e) {
        yield put({ type: UPLOAD_ACCIDENT_PAYMENT_FILES_FAIL, payload: e });
      }
    });
  }
}

function* deleteAccidentFileSaga({
  payload: { itemId, fileId },
}: ReturnType<typeof deleteAccidentFileAction>) {
  const url = itemId
    ? `/v1/fleets/accidents/${itemId}/files/${fileId}`
    : `/v1/fleets/accidents/files/${fileId}`;
  try {
    yield call(() => api.delete(url));
    yield put({
      type: DELETE_ACCIDENT_FILES_SUCCESS,
      payload: fileId,
    });
  } catch (e) {
    yield put({ type: DELETE_ACCIDENT_FILES_FAIL, payload: e });
  }
}

function* getAccidentActivitySaga({
  payload: { id },
}: ReturnType<typeof getAccidentActivityAction>) {
  try {
    const { data } = yield call(() =>
      api.get(`/v1/fleets/accidents/${id}/logs`),
    );
    yield put<IGetAccidentActivityActionSuccess>({
      type: GET_ACCIDENT_ACTIVITY_SUCCESS,
      payload: data,
    });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetAccidentActivityActionFail>({
        type: GET_ACCIDENT_ACTIVITY_FAIL,
        payload: e,
      });
  }
}

export default function* accidentsSaga() {
  yield takeLeading(CREATE_ACCIDENT, createAccidentSaga);
  yield takeLatest(UPDATE_ACCIDENT, updateAccidentSaga);
  yield takeLatest(GET_ACCIDENTS, getAccidentsSaga);
  yield takeLatest(GET_ACCIDENT, getAccidentSaga);
  yield takeLatest(DELETE_ACCIDENT, deleteAccidentSaga);
  yield takeLeading(
    CREATE_ACCIDENT_ACTIVITY_ITEM,
    createAccidentActivityItemSaga,
  );
  yield takeLeading(SEND_ACCIDENT_PAYMENT, sendAccidentPaymentSaga);
  yield takeLeading(UPLOAD_ACCIDENT_FILES, uploadAccidentFilesSaga);
  yield takeLeading(
    UPLOAD_ACCIDENT_PAYMENT_FILES,
    uploadAccidentPaymentFilesSaga,
  );
  yield takeEvery(DELETE_ACCIDENT_FILES, deleteAccidentFileSaga);
  yield takeLatest(GET_ACCIDENT_ACTIVITY, getAccidentActivitySaga);
}
