import { AxiosError } from 'axios';

import { IPagination } from 'components/_common/Navigation/Pagination/_redux/paginationTypes';
import {
  IBroker,
  IBrokerRate,
} from 'components/Dispatch/TabBrokers/_models/brokerModel';
import {
  IDeleteFileAction,
  IDeleteFileActionFail,
  IDeleteFileActionSuccess,
  IUploadFilesAction,
  IUploadFilesActionFail,
  IUploadFilesActionSuccess,
} from 'redux/_common/commonTypes';
import { ILogout } from 'redux/_common/models';
import { DefaultRateSortKey } from 'redux/userSettings/models';
import { IDefaultReducer } from 'utils/models';

import { brokerEmployeeActionsType } from './brokerEmployeeTypes';

export interface IBrokersReducer extends Omit<IDefaultReducer, 'pagination'> {
  list: IBroker[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  files: any[];
  ratesData: IBrokerRate[];
  currentBroker: IBroker | null;
}

// GET BROKERS
export const GET_BROKERS = '[@@Brokers] GET_BROKERS';
export const GET_BROKERS_SUCCESS = '[@@Brokers] GET_BROKERS_SUCCESS';
export const GET_BROKERS_FAIL = '[@@Brokers] GET_BROKERS_FAIL';

export interface IGetBrokersAction {
  type: typeof GET_BROKERS;
}
export interface IGetBrokersActionSuccess {
  type: typeof GET_BROKERS_SUCCESS;
  payload: { brokers: IBroker[]; pagination: IPagination };
}
export interface IGetBrokersActionFail {
  type: typeof GET_BROKERS_FAIL;
  payload: AxiosError;
}

// GET BROKER
export const GET_BROKER = '[@@Brokers] GET_BROKER';
export const GET_BROKER_SUCCESS = '[@@Brokers] GET_BROKER_SUCCESS';
export const GET_BROKER_FAIL = '[@@Brokers] GET_BROKER_FAIL';

export interface IGetBrokerAction {
  type: typeof GET_BROKER;
}
export interface IGetBrokerActionSuccess {
  type: typeof GET_BROKER_SUCCESS;
  payload: IBroker;
}
export interface IGetBrokerActionFail {
  type: typeof GET_BROKER_FAIL;
  payload: AxiosError;
}

// GET BROKER RATES LIST
export const GET_BROKER_RATES_LIST = '[@@Brokers] GET_BROKER_RATES_LIST';
export const GET_BROKER_RATES_LIST_SUCCESS =
  '[@@Brokers] GET_BROKER_RATES_LIST_SUCCESS';
export const GET_BROKER_RATES_LIST_FAIL =
  '[@@Brokers] GET_BROKER_RATES_LIST_FAIL';

export interface IGetBrokerRatesListAction {
  type: typeof GET_BROKER_RATES_LIST;
  payload: {
    data: { id: string | number };
    callback?: () => void;
    config?: { sort: DefaultRateSortKey };
  };
}
export interface IGetBrokerRatesListActionSuccess {
  type: typeof GET_BROKER_RATES_LIST_SUCCESS;
  payload: IBrokerRate[];
}
export interface IGetBrokerRatesListActionFail {
  type: typeof GET_BROKER_RATES_LIST_FAIL;
  payload: AxiosError;
}

// SEARCH BROKERS
export const SEARCH_BROKERS = '[@@Brokers] SEARCH_BROKERS';

export interface ISearchBrokersAction {
  type: typeof SEARCH_BROKERS;
}
export interface ISearchBrokersActionFail {
  type: typeof GET_BROKERS_FAIL;
  payload: AxiosError;
}

// CREATE BROKER
export const CREATE_BROKER = '[@@Brokers] CREATE_BROKER';
export const CREATE_BROKER_SUCCESS = '[@@Brokers] CREATE_BROKER_SUCCESS';
export const CREATE_BROKER_FAIL = '[@@Brokers] CREATE_BROKER_FAIL';

export interface ICreateBrokerAction {
  type: typeof CREATE_BROKER;
}
export interface ICreateBrokerActionSuccess {
  type: typeof CREATE_BROKER_SUCCESS;
  payload: IBroker;
}
export interface ICreateBrokerActionFail {
  type: typeof CREATE_BROKER_FAIL;
  payload: AxiosError;
}

// UPDATE BROKER
export const UPDATE_BROKER = '[@@Brokers] UPDATE_BROKER';
export const UPDATE_BROKER_SUCCESS = '[@@Brokers] UPDATE_BROKER_SUCCESS';
export const UPDATE_BROKER_FAIL = '[@@Brokers] UPDATE_BROKER_FAIL';

export interface IUpdateBrokerAction {
  type: typeof UPDATE_BROKER;
}
export interface IUpdateBrokerActionSuccess {
  type: typeof UPDATE_BROKER_SUCCESS;
  payload: IBroker;
}
export interface IUpdateBrokerActionFail {
  type: typeof UPDATE_BROKER_FAIL;
  payload: AxiosError;
}

// DELETE BROKER
export const DELETE_BROKER = '[@@Brokers] DELETE_BROKER';
export const DELETE_BROKER_SUCCESS = '[@@Brokers] DELETE_BROKER_SUCCESS';
export const DELETE_BROKER_FAIL = '[@@Brokers] DELETE_BROKER_FAIL';

export interface IDeleteBrokerAction {
  type: typeof DELETE_BROKER;
}
export interface IDeleteBrokerActionSuccess {
  type: typeof DELETE_BROKER_SUCCESS;
  payload: number;
}
export interface IDeleteBrokerActionFail {
  type: typeof DELETE_BROKER_FAIL;
  payload: AxiosError;
}

// UPLOAD BROKER FILES
export const UPLOAD_BROKER_FILES = '[@@Brokers] UPLOAD_BROKER_FILES';
export const UPLOAD_BROKER_FILES_SUCCESS =
  '[@@Brokers] UPLOAD_BROKER_FILES_SUCCESS';
export const UPLOAD_BROKER_FILES_FAIL = '[@@Brokers] UPLOAD_BROKER_FILES_FAIL';

export interface IUploadBrokerFilesAction extends IUploadFilesAction {
  type: typeof UPLOAD_BROKER_FILES;
}
export interface IUploadBrokerFilesActionSuccess
  extends IUploadFilesActionSuccess {
  type: typeof UPLOAD_BROKER_FILES_SUCCESS;
}
export interface IUploadBrokerFilesActionFail extends IUploadFilesActionFail {
  type: typeof UPLOAD_BROKER_FILES_FAIL;
}

// DELETE BROKER FILE
export const DELETE_BROKER_FILE = '[@@Brokers] DELETE_BROKER_FILE';
export const DELETE_BROKER_FILE_SUCCESS =
  '[@@Brokers] DELETE_BROKER_FILE_SUCCESS';
export const DELETE_BROKER_FILE_FAIL = '[@@Brokers] DELETE_BROKER_FILE_FAIL';

export interface IDeleteBrokerFileAction extends IDeleteFileAction {
  type: typeof DELETE_BROKER_FILE;
}
export interface IDeleteBrokerFileActionSuccess
  extends IDeleteFileActionSuccess {
  type: typeof DELETE_BROKER_FILE_SUCCESS;
}
export interface IDeleteBrokerFileActionFail extends IDeleteFileActionFail {
  type: typeof DELETE_BROKER_FILE_FAIL;
}

// SET BROKER RATE
export const SET_BROKER_RATE = '[@@Brokers] SET_BROKER_RATE';
export const SET_BROKER_RATE_SUCCESS = '[@@Brokers] SET_BROKER_RATE_SUCCESS';
export const SET_BROKER_RATE_FAIL = '[@@Brokers] SET_BROKER_RATE_FAIL';

export interface ISetBrokerRateAction {
  type: typeof SET_BROKER_RATE;
  payload: Partial<IBrokerRate>;
}
export interface ISetBrokerRateActionSuccess {
  type: typeof SET_BROKER_RATE_SUCCESS;
}
export interface ISetBrokerRateActionFail {
  type: typeof SET_BROKER_RATE_FAIL;
  payload: AxiosError;
}

// SET_BROKER_COMMENT_VISIBILITY
export const SET_BROKER_COMMENT_VISIBILITY =
  '[@@Brokers] SET_BROKER_COMMENT_VISIBILITY';

export interface ISetBrokerRateCommentVisibility {
  type: typeof SET_BROKER_COMMENT_VISIBILITY;
  payload: {
    data: { id: number };
  };
}

// UNMOUNT BROKER FORM
export const UNMOUNT_BROKERS_FORM = '[@@Brokers] UNMOUNT_BROKERS_FORM';

export type brokersActionsType =
  | brokerEmployeeActionsType
  | IGetBrokersAction
  | IGetBrokersActionSuccess
  | IGetBrokersActionFail
  | IGetBrokerAction
  | IGetBrokerActionSuccess
  | IGetBrokerActionFail
  | ISearchBrokersAction
  | ISearchBrokersActionFail
  | ICreateBrokerAction
  | ICreateBrokerActionSuccess
  | ICreateBrokerActionFail
  | IUpdateBrokerAction
  | IUpdateBrokerActionSuccess
  | IUpdateBrokerActionFail
  | IDeleteBrokerAction
  | IDeleteBrokerActionSuccess
  | IDeleteBrokerActionFail
  | IUploadBrokerFilesAction
  | IUploadBrokerFilesActionSuccess
  | IUploadBrokerFilesActionFail
  | IDeleteBrokerFileAction
  | IDeleteBrokerFileActionSuccess
  | IDeleteBrokerFileActionFail
  | ISetBrokerRateAction
  | ISetBrokerRateActionSuccess
  | ISetBrokerRateActionFail
  | IGetBrokerRatesListAction
  | IGetBrokerRatesListActionSuccess
  | IGetBrokerRatesListActionFail
  | ISetBrokerRateCommentVisibility
  | ILogout;
