import { LOGOUT } from 'components/Auth/_redux/authTypes';

import {
  IAccountingBillingReducer,
  billingActionTypes,
  GET_BILLING_ITEMS,
  GET_BILLING_ITEMS_FAIL,
  GET_BILLING_ITEMS_SUCCESS,
  ADD_EXTRA_CHARGES_SUCCESS,
  UPDATE_EXTRA_CHARGE_SUCCESS,
  DELETE_EXTRA_CHARGE_SUCCESS,
  ADD_PROOF_OF_DELIVERIES_SUCCESS,
  DELETE_PROOF_OF_DELIVERY_SUCCESS,
  REGENERATE_BILL_SUCCESS,
  CREATE_BROKER_DEDUCTION_SUCCESS,
  UPDATE_BROKER_DEDUCTION_SUCCESS,
  DELETE_BROKER_DEDUCTION_SUCCESS,
  PATCH_BILLING_ITEMS_SUCCESS,
} from './billingTypes';

const initialState: IAccountingBillingReducer = {
  billingItems: {
    list: [],
    statistics: undefined,
    pagination: undefined,
    _pending: false,
    _loaded: false,
    _error: null,
  },
};

const billingReducers = (
  state = initialState,
  action: billingActionTypes,
): IAccountingBillingReducer => {
  switch (action.type) {
    case GET_BILLING_ITEMS:
      return {
        ...state,
        billingItems: {
          ...state.billingItems,
          _loaded: false,
          _pending: true,
          _error: null,
        },
      };

    case GET_BILLING_ITEMS_FAIL:
      return {
        ...state,
        billingItems: {
          ...state.billingItems,
          _error: action.payload,
          _loaded: false,
          _pending: false,
        },
      };

    case GET_BILLING_ITEMS_SUCCESS:
      return {
        ...state,
        billingItems: {
          ...state.billingItems,
          ...action.payload,
          _loaded: true,
          _pending: false,
        },
      };

    case ADD_EXTRA_CHARGES_SUCCESS: {
      const list = [...state.billingItems.list];
      for (const item of action.items) {
        const billingIndex = list.findIndex(rec => rec.id === item.order_id);
        if (billingIndex !== -1) {
          list[billingIndex] = { ...list[billingIndex] };
          const billing = list[billingIndex];
          billing.extra_charges = [...billing.extra_charges, item];
          billing.broker_extra_charges_amount += item.amount;
          billing.total_amount += item.amount;
        }
      }

      return {
        ...state,
        billingItems: {
          ...state.billingItems,
          list,
          _loaded: true,
          _pending: false,
        },
      };
    }

    case PATCH_BILLING_ITEMS_SUCCESS: {
      const list = [...state.billingItems.list];
      action.items.forEach(newBill => {
        const billingIndex = list.findIndex(rec => rec.id === newBill.id);

        if (billingIndex >= 0) {
          list[billingIndex].billing_status = newBill.billing_status;
          list[billingIndex].paid_amount =
            newBill.additional_params?.paid_amount || 0;
        }
      });

      return {
        ...state,
        billingItems: {
          ...state.billingItems,
          list,
          _loaded: true,
          _pending: false,
        },
      };
    }

    case UPDATE_EXTRA_CHARGE_SUCCESS: {
      const { order_id, id, amount } = action.payload;

      const list = [...state.billingItems.list];
      const billingIndex = list.findIndex(rec => rec.id === order_id);
      if (billingIndex !== -1) {
        list[billingIndex] = { ...list[billingIndex] };
        const billing = list[billingIndex];
        billing.extra_charges = [...billing.extra_charges];
        const itemIndex = billing.extra_charges.findIndex(rec => rec.id === id);
        if (itemIndex === -1) {
          billing.extra_charges.push(action.payload);
        } else {
          billing.broker_extra_charges_amount -=
            billing.extra_charges[itemIndex].amount;
          billing.total_amount -= billing.extra_charges[itemIndex].amount;

          billing.extra_charges[itemIndex] = action.payload;
        }
        billing.broker_extra_charges_amount += amount;
        billing.total_amount += amount;
      }
      return {
        ...state,
        billingItems: {
          ...state.billingItems,
          list,
          _loaded: true,
          _pending: false,
        },
      };
    }

    case DELETE_EXTRA_CHARGE_SUCCESS: {
      const { order_id, id, amount } = action.payload;

      const list = [...state.billingItems.list];
      const billingIndex = list.findIndex(rec => rec.id === order_id);
      if (billingIndex !== -1) {
        list[billingIndex] = { ...list[billingIndex] };
        const billing = list[billingIndex];
        billing.extra_charges = [...billing.extra_charges];
        const itemIndex = billing.extra_charges.findIndex(rec => rec.id === id);
        if (itemIndex !== -1) {
          billing.extra_charges.splice(itemIndex, 1);
          billing.broker_extra_charges_amount -= amount;
          billing.total_amount -= amount;
        }
      }
      return {
        ...state,
        billingItems: {
          ...state.billingItems,
          list,
          _loaded: true,
          _pending: false,
        },
      };
    }

    case ADD_PROOF_OF_DELIVERIES_SUCCESS: {
      const list = [...state.billingItems.list];
      for (const photo of action.photos) {
        const billingIndex = list.findIndex(rec => rec.id === photo.order_id);
        if (billingIndex !== -1) {
          list[billingIndex] = { ...list[billingIndex] };
          const billing = list[billingIndex];
          billing.proof_of_deliveries = [...billing.proof_of_deliveries];
          const podIndex = billing.proof_of_deliveries.findIndex(
            rec => rec.id === photo.address_id,
          );
          if (podIndex !== -1) {
            billing.proof_of_deliveries[podIndex] = {
              ...billing.proof_of_deliveries[podIndex],
            };
            const proof_of_delivery = billing.proof_of_deliveries[podIndex];
            proof_of_delivery.photos = [...proof_of_delivery.photos, photo];
          }
        }
      }

      return {
        ...state,
        billingItems: {
          ...state.billingItems,
          list,
          _loaded: true,
          _pending: false,
        },
      };
    }

    case DELETE_PROOF_OF_DELIVERY_SUCCESS: {
      const { id, order_id, address_id } = action.payload;

      const list = [...state.billingItems.list];
      const billingIndex = list.findIndex(rec => rec.id === order_id);
      if (billingIndex !== -1) {
        list[billingIndex] = { ...list[billingIndex] };
        const billing = list[billingIndex];
        billing.proof_of_deliveries = [...billing.proof_of_deliveries];
        const podIndex = billing.proof_of_deliveries.findIndex(
          rec => rec.id === address_id,
        );
        if (podIndex !== -1) {
          billing.proof_of_deliveries[podIndex] = {
            ...billing.proof_of_deliveries[podIndex],
          };
          const proof_of_delivery = billing.proof_of_deliveries[podIndex];
          proof_of_delivery.photos = [...proof_of_delivery.photos];
          const itemIndex = proof_of_delivery.photos.findIndex(
            rec => rec.id === id,
          );
          if (itemIndex !== -1) proof_of_delivery.photos.splice(itemIndex, 1);
        }
      }
      return {
        ...state,
        billingItems: {
          ...state.billingItems,
          list,
          _loaded: true,
          _pending: false,
        },
      };
    }

    case CREATE_BROKER_DEDUCTION_SUCCESS: {
      const { order_id, amount } = action.item;

      const list = [...state.billingItems.list];
      const billingIndex = list.findIndex(rec => rec.id === order_id);
      if (billingIndex !== -1) {
        list[billingIndex] = { ...list[billingIndex] };
        const billing = list[billingIndex];
        billing.broker_deductions = [...billing.broker_deductions, action.item];
        billing.broker_deductions_amount += amount;
        billing.total_amount -= amount;
      }

      return {
        ...state,
        billingItems: {
          ...state.billingItems,
          list,
          _loaded: true,
          _pending: false,
        },
      };
    }

    case UPDATE_BROKER_DEDUCTION_SUCCESS: {
      const { order_id, id, amount } = action.item;

      const list = [...state.billingItems.list];
      const billingIndex = list.findIndex(rec => rec.id === order_id);
      if (billingIndex !== -1) {
        list[billingIndex] = { ...list[billingIndex] };
        const billing = list[billingIndex];
        billing.broker_deductions = [...billing.broker_deductions];
        const itemIndex = billing.broker_deductions.findIndex(
          rec => rec.id === id,
        );
        if (itemIndex === -1) {
          billing.broker_deductions.push(action.item);
        } else {
          billing.broker_deductions_amount -=
            billing.broker_deductions[itemIndex].amount;
          billing.total_amount += billing.broker_deductions[itemIndex].amount;

          billing.broker_deductions[itemIndex] = action.item;
        }
        billing.broker_deductions_amount += amount;
        billing.total_amount -= amount;
      }
      return {
        ...state,
        billingItems: {
          ...state.billingItems,
          list,
          _loaded: true,
          _pending: false,
        },
      };
    }

    case DELETE_BROKER_DEDUCTION_SUCCESS: {
      const { order_id, id, amount } = action.item;

      const list = [...state.billingItems.list];
      const billingIndex = list.findIndex(rec => rec.id === order_id);
      if (billingIndex !== -1) {
        list[billingIndex] = { ...list[billingIndex] };
        const billing = list[billingIndex];
        billing.broker_deductions = [...billing.broker_deductions];
        const itemIndex = billing.broker_deductions.findIndex(
          rec => rec.id === id,
        );
        if (itemIndex !== -1) {
          billing.broker_deductions.splice(itemIndex, 1);
          billing.broker_deductions_amount -= amount;
          billing.total_amount += amount;
        }
      }
      return {
        ...state,
        billingItems: {
          ...state.billingItems,
          list,
          _loaded: true,
          _pending: false,
        },
      };
    }

    case REGENERATE_BILL_SUCCESS: {
      const {
        order_id,
        invoice: pdf_link,
        invoice_date,
        merge_pdf_link,
      } = action.payload;

      const list = [...state.billingItems.list];
      const billingIndex = list.findIndex(rec => rec.id === order_id);
      if (billingIndex !== -1) {
        list[billingIndex] = {
          ...list[billingIndex],
          pdf_link,
          merge_pdf_link,
          invoice_date: invoice_date || list[billingIndex].invoice_date,
        };
      }
      return {
        ...state,
        billingItems: {
          ...state.billingItems,
          list,
          _loaded: true,
          _pending: false,
        },
      };
    }

    case LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
};

export default billingReducers;
