import { FC } from 'react';

import { useSelector } from 'react-redux';

import { userThemeSelector } from 'redux/userSettings/userSettingsSelectors';

type Props = {
  className?: string;
  fillColor?: string;
};

const IconEngine: FC<Props> = ({ className, fillColor = '#86898E' }) => {
  const theme = useSelector(userThemeSelector);

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={theme === 'dark' ? 'white' : fillColor}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.99978 14.9983C6.58219 14.9982 5.19802 14.5678 4.0304 13.7639C2.86279 12.96 1.96677 11.8205 1.46089 10.4962C0.95501 9.17199 0.863119 7.72535 1.19737 6.34772C1.45271 5.29536 1.94752 4.32117 2.63789 3.49849C2.99276 3.0756 3.63123 3.11459 3.99947 3.52589C4.36795 3.93747 4.32287 4.56532 3.99249 5.00807C3.59157 5.54536 3.30069 6.16063 3.14093 6.81871C2.90202 7.80278 2.96755 8.83621 3.32884 9.78223C3.69014 10.7282 4.33018 11.5423 5.16425 12.1166C5.99833 12.6908 6.98712 12.9983 7.99978 12.9983C9.01245 12.9983 10.0012 12.6908 10.8353 12.1166C11.6694 11.5423 12.3094 10.7282 12.6707 9.78223C13.032 8.83621 13.0976 7.80278 12.8586 6.81871C12.6988 6.1604 12.4078 5.54493 12.0067 5.00751C11.6764 4.56506 11.6312 3.93764 11.9994 3.52617C12.3674 3.11476 13.0059 3.07566 13.3607 3.49852C14.051 4.32113 14.5458 5.29523 14.8011 6.34748C15.1354 7.72501 15.0436 9.17157 14.5379 10.4958C14.0321 11.82 13.1362 12.9595 11.9688 13.7634C10.8013 14.5674 9.41729 14.998 7.99978 14.9983Z"
        className={className}
      />
      <path
        d="M9 6V1C9 0.447715 8.55228 0 8 0C7.44772 0 7 0.447715 7 1V6C7 6.55228 7.44772 7 8 7C8.55228 7 9 6.55228 9 6Z"
        className={className}
      />
    </svg>
  );
};

export default IconEngine;
