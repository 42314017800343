import { FC } from 'react';

type Props = {
  className?: string;
  strokeColor?: string;
};

const IconInfo: FC<Props> = ({
  className,
  strokeColor = 'var(--primaryText)',
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="8" cy="8" r="7" stroke={strokeColor} strokeWidth="2" />
      <path
        d="M8 8V11"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <g>
        <circle cx="8" cy="5" r="0.5" stroke={strokeColor} />
      </g>
    </svg>
  );
};

export default IconInfo;
