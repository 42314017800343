import { call, put, select, takeEvery } from 'redux-saga/effects';

import { API_VERSION } from 'constants/api';
import IndexedDb from 'indexedDB';
import api from 'utils/requests';

import { resourcesSelector } from './resourcesSelectors';
import {
  Resources,
  GET_RESOURCES_AUTOCOMPLETE_DATA,
  GET_RESOURCES_AUTOCOMPLETE_DATA_INIT,
  GET_RESOURCES_AUTOCOMPLETE_DATA_SUCCESS,
  GET_RESOURCES_AUTOCOMPLETE_DATA_FAILED,
  IGetResourcesAutocompleteAction,
  IGetResourcesAutocompleteActionFailed,
  IGetResourcesAutocompleteActionSuccess,
} from './resourcesTypes';

const resourcesUrl = `${API_VERSION}/services/resources`;
const resourcesTypes =
  'resources=trucks,trailers,drivers,dispatchers,inspectors,employees,internal_companies';

const UPDATE_INTERVAL = 2 * 60 * 1000; // 2 minutes

// save data into indexed DB
const putDataIntoIndexedDB = async (data?: object[]) => {
  if (data) {
    const indexedDb = new IndexedDb('test');
    await indexedDb.createObjectStore(['drivers']);
    await indexedDb.putBulkValue('drivers', data);
  }
};

function* getResourcesAutocompleteData({
  callback,
  force,
}: IGetResourcesAutocompleteAction) {
  try {
    const { _pending, _updatedTime } = yield select(resourcesSelector);
    if (!force && (_pending || Date.now() - _updatedTime < UPDATE_INTERVAL)) {
      return;
    }

    yield put({
      type: GET_RESOURCES_AUTOCOMPLETE_DATA_INIT,
    });
    const { data }: { data: Resources } = yield call(() =>
      api.get(`${resourcesUrl}/?${resourcesTypes}`),
    );

    putDataIntoIndexedDB(data.drivers);

    // convert inspector ids
    data.inspectors = data.inspectors?.map(i => ({
      ...i,
      id: `${i.type}:${i.id}`,
    }));

    // join retrieved trucks and trailers collections into common vehicles
    data.vehicles = [];
    if (data.trucks)
      for (const { id, unit_number } of data.trucks)
        data.vehicles.push({
          id: `truck:${id}`,
          type: 'truck',
          unit_number,
        });
    if (data.trailers)
      for (const { id, unit_number } of data.trailers)
        data.vehicles.push({
          id: `trailer:${id}`,
          type: 'trailer',
          unit_number,
        });

    yield put<IGetResourcesAutocompleteActionSuccess>({
      type: GET_RESOURCES_AUTOCOMPLETE_DATA_SUCCESS,
      payload: data,
    });
    if (callback) callback(data);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetResourcesAutocompleteActionFailed>({
        type: GET_RESOURCES_AUTOCOMPLETE_DATA_FAILED,
        payload: e,
      });
    else {
      console.error(e);
      throw e;
    }
  }
}

export default function* resourcesAutocompleteSaga() {
  yield takeEvery(
    GET_RESOURCES_AUTOCOMPLETE_DATA,
    getResourcesAutocompleteData,
  );
}
