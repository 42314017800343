import { FC, MouseEvent, memo } from 'react';

import { useSelector } from 'react-redux';

import { userThemeSelector } from 'redux/userSettings/userSettingsSelectors';

interface IOwnProps {
  className?: string;
  fillColor?: string;
  onClick?: (event: MouseEvent) => void;
}

const IconClose16: FC<IOwnProps> = ({ className, fillColor, onClick }) => {
  const theme = useSelector(userThemeSelector);

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M12 4L4 12M11.9931 11.9931L4.00691 4.00693"
        strokeWidth="2"
        strokeLinecap="round"
        stroke={fillColor || (theme === 'dark' ? 'white' : '#FF4A3C')}
      />
    </svg>
  );
};

export default memo(IconClose16);
