import { LOGOUT } from 'components/Auth/_redux/authTypes';

import { IPermissionObject } from '../../routes';
import {
  IDefaultSettingsStringKey,
  ISettingsCountry,
  ISettingsState,
  IDefaultSettingsNumberKey,
  Themes,
} from './models';

// CHANGE_LANGUAGE
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export interface IChangeLanguageActions {
  type: typeof CHANGE_LANGUAGE;
  payload: string;
}

// COLOR_THEME
export const COLOR_THEME = 'COLOR_THEME';
export interface IChangeThemeActions {
  type: typeof COLOR_THEME;
  payload: Themes;
}

// SET_DESKTOP_VERSION
export const SET_DESKTOP_VERSION = '[@@Settings] SET_DESKTOP_VERSION';
export interface ISetDesktopVersionActions {
  type: typeof SET_DESKTOP_VERSION;
}

// LOAD_SETTINGS
export const LOAD_SETTINGS = 'LOAD_SETTINGS';
export const LOAD_SETTINGS_SUCCESS = 'LOAD_SETTINGS_SUCCESS';
export const LOAD_SETTINGS_FAIL = 'LOAD_SETTINGS_FAIL';

export interface IGetSettingsSuccessActions {
  type: typeof LOAD_SETTINGS_SUCCESS;
  payload: {
    driverTypes: IDefaultSettingsStringKey;
    countries: { [key: number]: ISettingsCountry };
    states: { [key: number]: ISettingsState };
    internalCompanies: IDefaultSettingsNumberKey;
  };
}

export const SET_CURRENT_PERMISSIONS = '[@@Settings] SET_CURRENT_PERMISSIONS';

export interface ISetCurrentPermissionsActions {
  type: typeof SET_CURRENT_PERMISSIONS;
  payload: IPermissionObject[];
}

// OTHER
export interface IClearActions {
  type: typeof LOAD_SETTINGS_FAIL | typeof LOGOUT;
}

export type initSettingsActionsType =
  | IChangeLanguageActions
  | IGetSettingsSuccessActions
  | IChangeThemeActions
  | ISetDesktopVersionActions
  | IClearActions
  | ISetCurrentPermissionsActions;
// ACTION END
