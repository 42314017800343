/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';

import clsx from 'clsx';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import { IconStar } from 'assets/icons/components';
import { RateSlider } from 'components/_common/CustomMaterialComponents';

import styles from './Rate.module.scss';

interface IOwnProps {
  id: string;
  placeholder: string;
  className: string;
  icon: string | JSX.Element;
  min: number;
  max: number;
  defaultValue: number | string;
  step: number;
}

type Props = Partial<
  IOwnProps & WrappedFieldInputProps & WrappedFieldMetaProps
>;

const ThumbComponent = (props: any) => {
  const { children, ownerState: sliderValue } = props;

  return (
    <span {...props}>
      {children}
      <div className={styles.starBlock}>
        <IconStar fillColor="white" className={styles.icon} />
        <span className={styles.thumbValue}>{sliderValue.value}</span>
      </div>
    </span>
  );
};

const Rate: FC<Props> = ({
  min = 1,
  step = 1,
  max = 10,
  defaultValue = 5,
  className,
  id,
  name,
  onBlur,
  onChange,
  value,
}) => {
  const handleChange = (e: any, newValue: number | number[]) => {
    if (onChange) onChange(newValue);
  };

  return (
    <div className={clsx(styles.root, className)}>
      <span className={styles.markLeft}>{min}</span>
      <RateSlider
        id={id}
        name={name}
        value={value || defaultValue}
        step={step}
        min={min}
        max={max}
        onBlur={onBlur}
        onChange={handleChange}
        components={{ Thumb: ThumbComponent }}
      />
      <span className={styles.markRight}>{max}</span>
    </div>
  );
};

export default Rate;
