type Props = {
  className?: string;
  strokeColor?: string;
};

const IconPlus16 = ({ className, strokeColor = '#2C6AF6' }: Props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M1 6H6M11 6L6 6M6 6L6 1M6 6V11"
      stroke={strokeColor}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default IconPlus16;
