import axios from 'axios';

import { logoutAction } from 'components/Auth/_redux/authActions';
import { store } from 'redux/store';

// Common
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.interceptors.response.use(
  response => response,
  error => {
    // 401: Authorization required
    if (error.response.status === 401) store.dispatch(logoutAction());
    // if (error.response.status === 403) {
    //   window.location.href = '/reg';
    // }
    return Promise.reject(error);
  },
);

// Trucks
export const apiTrucksTracking = axios.create({
  baseURL: process.env.REACT_APP_TRUCK_TRACKING_URL,
});

// Trailers
export const apiTrailersTracking = axios.create({
  baseURL: process.env.REACT_APP_TRAILERS_TRACKING_URL,
});

export const setAuthHeaderToken = (token: string) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  apiTrucksTracking.defaults.headers.common.Authorization = `Bearer ${token}`;
  apiTrailersTracking.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const clearAuthHeaderToken = () => {
  axios.defaults.headers.common.Authorization = false;
  apiTrucksTracking.defaults.headers.common.Authorization = false;
  apiTrailersTracking.defaults.headers.common.Authorization = false;
};

export default axios;
