import moment from 'moment';

export const convertToIntegerNumber = (value: string): number | null => {
  if (!value || Number.isNaN(value)) return null;

  const newValue = value.replace(/[^\d.-]/g, '');
  return Number(newValue);
};

export const convertToNumbersAndLettersWithDash = (
  value: string,
): string | undefined => {
  if (!value) return value;
  return value.replace(/[^a-zA-Z0-9/-]/g, '');
};

export const convertToNumberWithoutComma = (value: string): number => {
  if (!value || Number.isNaN(value)) return 0;
  return parseInt(value, 10);
};

/**
 * Converts file to formData with given name
 * @param file - File | Blob
 * @param name - name of the file
 * @returns formData with file
 */
export const fileToFormData = (
  file: File | Blob,
  name: string,
): FormData | null => {
  if (!file) return null;
  const formData = new FormData();

  const fileName =
    file instanceof File ? file.name : file.type?.replace('/', '.');

  formData.append(name, file, fileName);
  return formData;
};

export const convertDateToLocalString = (value: string | null) => {
  return value && new Date(value).toLocaleString();
};

export const mergeDateAndTime = (
  dateValue?: string,
  timeValue?: string,
): Date => {
  const date = dateValue ? new Date(dateValue) : new Date();
  if (timeValue) {
    let time = moment(timeValue, 'hh:mm a');
    // if time is not in AM/PM format
    if (!time.isValid()) {
      time = moment(timeValue);
    }

    date.setHours(time.hours());
    date.setMinutes(time.minutes());
  }
  return date;
};

// Merge time value to date argument and convert to ISO date string
export const mergeDateTimeAndConvertToISO = (
  dateValue: string,
  timeValue: string,
): string => mergeDateAndTime(dateValue, timeValue).toISOString();
