import { all } from 'redux-saga/effects';

import resourcesAutocompleteSaga from 'components/_common/FormElements/ResourcesAutocomplete/_redux/resourcesSagas';
import feedbackSaga from 'components/_common/Modal/FeedbackModal/_redux/feedbackSaga';
import billingSagas from 'components/Accounting/TabBilling/_redux/billingSagas';
import payrollItemsSagas from 'components/Accounting/TabPayrollItems/_redux/payrollItemsSagas';
import payrollsSagas from 'components/Accounting/TabPayrolls/_redux/payrollsSagas';
import authSaga from 'components/Auth/_redux/authSagas';
import chatSaga from 'components/Chat/_redux/chatSaga';
import brokersSaga from 'components/Dispatch/TabBrokers/_redux/brokersSagas';
import calendarSaga from 'components/Dispatch/TabCalendar/_redux/calendarSagas';
import facilitiesSaga from 'components/Dispatch/TabFacilities/_redux/facilitiesSagas';
import ordersSaga from 'components/Dispatch/TabOrders/_redux/ordersSagas';
import overviewSaga from 'components/Dispatch/TabReports/Overview/_redux/overviewSagas';
import fleetsSagas from 'components/Fleet/_redux/fleetGroupSaga';
import maintenanceSaga from 'components/Fleet/AllTrucksMaintenance/_redux/allTrucksMaintenanceSaga';
import claimsSaga from 'components/Fleet/TabClaims/_redux/claimsSagas';
import geoFencesSaga from 'components/Fleet/TabGeoFences/_redux/geoFencesSaga';
import tabInspectionsSaga from 'components/Fleet/TabInspections/_redux/tabInspectionsSaga';
import tollsSaga from 'components/Fleet/TabTolls/_redux/tollsSaga';
import trailersSaga from 'components/Fleet/TabTrailers/_redux/trailersSaga';
import trailersInspectionSaga from 'components/Fleet/TabTrailers/TrailerProfile/TrailerInspections/_redux/trailerInspectionsSaga';
import trucksSaga from 'components/Fleet/TabTrucks/_redux/trucksSaga';
import trucksInspectionSaga from 'components/Fleet/TabTrucks/TruckProfile/TruckInspections/_redux/truckInspectionsSaga';
import humanResourcesSagas from 'components/HumanResources/_redux/humanResourcesGroupSaga';
import applicantsSaga from 'components/HumanResources/TabApplicants/_redux/applicantsSaga';
import applicantsArchiveSaga from 'components/HumanResources/TabApplicantsArchive/_redux/applicantsArchiveSaga';
import driversSaga from 'components/HumanResources/TabDrivers/_redux/driversSagas';
import employeesSaga from 'components/HumanResources/TabEmployees/_redux/employeesSagas';
import upcomingDriverSaga from 'components/HumanResources/TabUpcomingDrivers/_redux/upcomingDriverSagas';
import categoriesSaga from 'components/Inventory/TabCategories/_redux/categoriesSaga';
import itemsSaga from 'components/Inventory/TabItems/_redux/itemsSaga';
import locationsSaga from 'components/Inventory/TabLocations/_redux/locationsSaga';
import vendorsSaga from 'components/Inventory/TabVendors/_redux/vendorsSaga';
import accidentsSaga from 'components/Safety/TabAccidents/_redux/accidentsSagas';
import accidentsStatisticsSaga from 'components/Safety/TabAccidents/AccidentsStatistics/_redux/accidentsStatisticsSagas';
import settingsSagas from 'components/Settings/_redux/settingsSagas';
import companySettingsSaga from 'components/WiseCheck/TabSettings/_redux/companySettingsSaga';
import settingsSaga from 'redux/userSettings/userSettingsSagas';

import internalCompaniesSaga from '../components/AdminPanel/InternalCompany/_redux/internalCompanySagas';
import roleAndPermissionsSaga from '../components/AdminPanel/RolesAndPermissions/_redux/RolesAndPermissionsSagas';
import allInspectionsSaga from '../components/Fleet/TabInspections/AllInspectionsTab/_redux/allInspectionsSaga';
import tandemInspectionSaga from '../components/Fleet/TabInspections/TandemInspectionTab/_redux/tandemInspectionsSaga';
import leadSaga from '../components/HumanResources/TabLeads/_redux/leadsSaga';
import scriptsSaga from '../components/Settings/TabScripts/_redux/scriptsReducer/scriptsSaga';
import commonSaga from './_common/commonSagas';
import importFileSaga from './importFiles/importFileSaga';

export default function* rootSaga() {
  yield all([
    // Common
    authSaga(),
    settingsSaga(),
    commonSaga(),
    feedbackSaga(),
    resourcesAutocompleteSaga(),

    // Fleet
    fleetsSagas(),
    trailersSaga(),
    trailersInspectionSaga(),
    allInspectionsSaga(),
    trucksSaga(),
    trucksInspectionSaga(),
    tandemInspectionSaga(),
    maintenanceSaga(),
    claimsSaga(),
    geoFencesSaga(),
    tollsSaga(),
    accidentsSaga(),
    accidentsStatisticsSaga(),
    tabInspectionsSaga(),

    // Inventory
    locationsSaga(),
    categoriesSaga(),
    itemsSaga(),
    vendorsSaga(),

    // Dispatch
    ordersSaga(),
    calendarSaga(),
    facilitiesSaga(),
    brokersSaga(),
    overviewSaga(),

    // HR
    humanResourcesSagas(),
    employeesSaga(),
    driversSaga(),
    upcomingDriverSaga(),
    applicantsSaga(),
    applicantsArchiveSaga(),

    // Accounting
    payrollsSagas(),
    payrollItemsSagas(),
    billingSagas(),
    settingsSagas(),

    // WiseCheck
    companySettingsSaga(),

    // Chat
    chatSaga(),
    internalCompaniesSaga(),
    roleAndPermissionsSaga(),

    // import files
    importFileSaga(),
    scriptsSaga(),
    leadSaga(),
  ]);
}
