import { FC, MouseEvent, useEffect, useRef, useCallback, memo } from 'react';

import { ToggleButtonGroup } from '@mui/material';
import clsx from 'clsx';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import { IconInfo } from 'assets/icons/components';
import {
  BlackTooltip,
  ToggleButton,
} from 'components/_common/CustomMaterialComponents';

import styles from './CustomToggleButton.module.scss';

type Value = string | number;

type OwnProps = {
  config: Record<Value, string>;
  tooltipTextConfig?: Record<Value, string>;
  tooltipStyles?: string;
  className?: string;
  defaultValue?: Value;
  disabled?: boolean;
  focusOnRender?: boolean;
};

type Props = OwnProps & WrappedFieldInputProps & WrappedFieldMetaProps;

const CustomToggleButton: FC<Partial<Props>> = ({
  config,
  tooltipTextConfig,
  tooltipStyles,
  className,
  defaultValue,
  value,
  onChange,
  disabled,
  focusOnRender,
}) => {
  const firstCheckedItem = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (focusOnRender && firstCheckedItem?.current)
      firstCheckedItem.current.focus();
  }, [focusOnRender]);

  const onChangeHandle = useCallback(
    (e: MouseEvent, newValue: Value) => {
      e.stopPropagation();
      if (onChange && newValue) onChange(newValue);
    },
    [onChange],
  );

  return (
    <ToggleButtonGroup
      className={clsx(styles.root, className)}
      value={`${value || defaultValue}`}
      exclusive
      onChange={onChangeHandle}
    >
      {Object.entries(config || {}).map(([id, label], index) => (
        <ToggleButton
          key={id}
          ref={index === 0 ? firstCheckedItem : null}
          value={`${id}`}
          disabled={disabled}
        >
          {tooltipTextConfig ? (
            <div className={styles.lableAndIcon}>
              <div>{label}</div>
              <BlackTooltip
                title={tooltipTextConfig[index]}
                arrow
                classes={
                  tooltipStyles
                    ? {
                        tooltip: tooltipStyles,
                      }
                    : undefined
                }
              >
                <span>
                  <IconInfo strokeColor="var(--secondaryText)" />
                </span>
              </BlackTooltip>
            </div>
          ) : (
            <div>{label}</div>
          )}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default memo(CustomToggleButton);
