import { cloneElement, FC, MouseEvent, useMemo, useRef, useState } from 'react';

import { IconArrowRight, IconCheck } from 'assets/icons/components';
import { Popover } from 'components/_common/CustomMaterialComponents';

import OptionItem from '../OptionItem/OptionItem';
import { IOption } from '../optionModels';

import styles from './OptionsPopover.module.scss';

type Props = {
  id: number | string;
  type?: string;
  status?: string;
  inspection_id?: string | number;
  inspector_name?: string;
  onAction?: (key: string, e: MouseEvent) => void;
  classNameDropdownItem?: string;
  commentsCount?: number;
};

type PopoverPosition = {
  anchorOrigin: {
    vertical: 'top' | 'center' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
  transformOrigin: {
    vertical: 'top' | 'center' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
};

const OptionsDropdownBody: FC<Props & { item: IOption }> = ({
  id,
  item,
  onAction,
  type,
  status,
  inspection_id,
  inspector_name,
  classNameDropdownItem,
  commentsCount,
}) => {
  const ref = useRef(null);
  const [isOpen, setOpened] = useState(false);

  const onMouseEnter = (key: string, e: MouseEvent) => {
    if (item.children) {
      e.stopPropagation();
      e.preventDefault();
      setOpened(prev => !prev);
    } else if (onAction) {
      onAction(key, e);
    }
  };

  return (
    <OptionItem
      id={id}
      type={type}
      status={status}
      inspection_id={inspection_id}
      inspector_name={inspector_name}
      key={item?.key}
      item={item}
      onAction={onMouseEnter}
      className={styles.optionItem}
      classNameDropdownItem={classNameDropdownItem}
      ref={ref}
    >
      {item.title === 'Comments' && commentsCount && commentsCount > 0 ? (
        <span className={styles.commentsLength}>{commentsCount}</span>
      ) : null}
      {item.active && <IconCheck />}
      {item.children && (
        <>
          <IconArrowRight className={styles.arrow} />
          {cloneElement(
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            <OptionsPopover
              id={id}
              config={item.children}
              isOpen={isOpen}
              parentElement={ref.current}
              position="left"
              onAction={onAction}
            />,
          )}
        </>
      )}
    </OptionItem>
  );
};

type PopupProps = {
  isOpen?: boolean;
  onClose?: () => void;
  parentElement?: Element | null;
  config: readonly IOption[];
  position?: 'left' | 'right' | 'topLeft' | 'topRight' | 'center' | 'bottom';
};

const OptionsPopover: FC<Props & PopupProps> = props => {
  const { parentElement, isOpen, config, onClose, ...restProps } = props;

  const getPosition = () => {
    const { position } = props;
    if (position) return position;
    if (!parentElement) return 'left';
    return parentElement.getBoundingClientRect().y < 0.5 * window.innerHeight
      ? 'left'
      : 'topLeft';
  };
  const position = getPosition();

  // pls read https://material-ui.com/components/popover/#anchor-playground
  const positionProps = useMemo((): PopoverPosition => {
    // TODO - add another positions: [topRight, center...]
    switch (position) {
      case 'topLeft':
        return {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        };
      case 'bottom':
        return {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        };
      default:
        // returned if position === "left"
        return {
          anchorOrigin: {
            vertical: 'center',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'center',
            horizontal: 'right',
          },
        };
    }
  }, [position]);

  return (
    <Popover
      anchorEl={parentElement}
      open={!!isOpen}
      {...positionProps}
      onClose={onClose}
      id={`${restProps.id}`}
    >
      {config.map((item: IOption) => (
        <OptionsDropdownBody key={item.key} {...restProps} item={item} />
      ))}
    </Popover>
  );
};

export default OptionsPopover;
