import { LOGOUT } from 'components/Auth/_redux/authTypes';
import {
  CREATE_BROKER,
  CREATE_BROKER_FAIL,
  CREATE_BROKER_SUCCESS,
  DELETE_BROKER,
  DELETE_BROKER_FAIL,
  DELETE_BROKER_FILE,
  DELETE_BROKER_FILE_FAIL,
  DELETE_BROKER_FILE_SUCCESS,
  DELETE_BROKER_SUCCESS,
  brokersActionsType,
  GET_BROKER,
  GET_BROKER_FAIL,
  GET_BROKER_SUCCESS,
  GET_BROKERS,
  GET_BROKERS_FAIL,
  GET_BROKERS_SUCCESS,
  IBrokersReducer,
  UPDATE_BROKER,
  UPDATE_BROKER_FAIL,
  UPDATE_BROKER_SUCCESS,
  UPLOAD_BROKER_FILES,
  UPLOAD_BROKER_FILES_FAIL,
  UPLOAD_BROKER_FILES_SUCCESS,
  GET_BROKER_RATES_LIST,
  GET_BROKER_RATES_LIST_SUCCESS,
  GET_BROKER_RATES_LIST_FAIL,
  SET_BROKER_COMMENT_VISIBILITY,
} from 'components/Dispatch/TabBrokers/_redux/brokersTypes';

import { IBroker, IBrokerContact } from '../_models/brokerModel';
import {
  CREATE_BROKER_EMPLOYEE,
  CREATE_BROKER_EMPLOYEE_FAIL,
  CREATE_BROKER_EMPLOYEE_SUCCESS,
  DELETE_BROKER_EMPLOYEE,
  DELETE_BROKER_EMPLOYEE_SUCCESS,
  DELETE_BROKER_EMPLOYEE_FAIL,
  UPDATE_BROKER_EMPLOYEE,
  UPDATE_BROKER_EMPLOYEE_FAIL,
  UPDATE_BROKER_EMPLOYEE_SUCCESS,
} from './brokerEmployeeTypes';

const initialState: IBrokersReducer = {
  list: [],
  files: [],
  ratesData: [],
  currentBroker: null,
  _pending: false,
  _loaded: false,
  _error: null,
};

const brokersReducer = (
  state = initialState,
  action: brokersActionsType,
): IBrokersReducer => {
  switch (action.type) {
    // GET BROKERS
    case GET_BROKERS:
      return {
        ...state,
        files: [],
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case GET_BROKERS_SUCCESS:
      return {
        ...state,
        list: action.payload.brokers,
        _loaded: true,
        _pending: false,
      };
    case GET_BROKERS_FAIL:
      return {
        ...initialState,
        _error: action.payload,
        _loaded: false,
        _pending: false,
      };

    // GET BROKER
    case GET_BROKER:
      return { ...state, _loaded: false, _pending: true, _error: null };
    case GET_BROKER_SUCCESS:
      return {
        ...state,
        currentBroker: action.payload,
        files: action.payload.files || [],
        _loaded: true,
        _pending: false,
      };
    case GET_BROKER_FAIL:
      return {
        ...state,
        _error: action.payload,
        _loaded: false,
        _pending: false,
      };
    // GET BROKER RATES
    case GET_BROKER_RATES_LIST:
      return { ...state, _error: null };
    case GET_BROKER_RATES_LIST_SUCCESS:
      return {
        ...state,
        ratesData:
          action.payload.map(rate => {
            return {
              ...rate,
              commentVisibility: false,
            };
          }) || [],
      };
    case GET_BROKER_RATES_LIST_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    case SET_BROKER_COMMENT_VISIBILITY:
      return {
        ...state,
        ratesData: state.ratesData.map(rate => {
          const shouldToggle = rate.id === action.payload.data.id;
          const isVisibile = !rate.commentVisibility;
          return {
            ...rate,
            commentVisibility: shouldToggle && isVisibile,
          };
        }),
      };

    // CREATE BROKER
    case CREATE_BROKER:
      return { ...state };
    case CREATE_BROKER_SUCCESS:
      return { ...state, files: [], currentBroker: null };
    case CREATE_BROKER_FAIL:
      return { ...state, _error: action.payload };

    // UPDATE BROKER
    case UPDATE_BROKER:
      return { ...state };
    case UPDATE_BROKER_SUCCESS:
      return { ...state, files: [], currentBroker: null };
    case UPDATE_BROKER_FAIL:
      return { ...state, _error: action.payload };

    // DELETE BROKER
    case DELETE_BROKER:
      return { ...state };
    case DELETE_BROKER_SUCCESS:
      return { ...state, files: [], currentBroker: null };
    case DELETE_BROKER_FAIL:
      return { ...state, _error: action.payload };

    // UPLOAD BROKER FILES
    case UPLOAD_BROKER_FILES:
      return {
        ...state,
        files: [
          ...state.files,
          ...action.payload.files.map(file => ({
            ...file,
            loading: true,
          })),
        ],
      };
    case UPLOAD_BROKER_FILES_SUCCESS:
      return {
        ...state,
        files: state.files.map((file, index: number) =>
          index === state.files.findIndex(({ loading }) => loading)
            ? { ...action.payload, loading: false }
            : file,
        ),
      };
    case UPLOAD_BROKER_FILES_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // DELETE_BROKER_FILE
    case DELETE_BROKER_FILE:
      return {
        ...state,
      };
    case DELETE_BROKER_FILE_SUCCESS:
      return {
        ...state,
        files: state.files.filter(file => file.id !== action.payload),
      };
    case DELETE_BROKER_FILE_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // CREATE BROKER EMPLOYEE
    case CREATE_BROKER_EMPLOYEE:
      return { ...state, _error: null };
    case CREATE_BROKER_EMPLOYEE_SUCCESS:
      return {
        ...state,
        currentBroker: {
          ...state.currentBroker,
          contacts: [...(state.currentBroker?.contacts || []), action.payload],
        } as IBroker,
      };
    case CREATE_BROKER_EMPLOYEE_FAIL:
      return { ...state, _error: action.payload };

    // UPDATE BROKER EMPLOYEE
    case UPDATE_BROKER_EMPLOYEE:
      return { ...state, _error: null };
    case UPDATE_BROKER_EMPLOYEE_SUCCESS:
      return {
        ...state,
        currentBroker: {
          ...state.currentBroker,
          contacts: (state.currentBroker?.contacts || []).map(employee =>
            employee.id === action.payload.id ? action.payload : employee,
          ),
        } as IBroker,
      };
    case UPDATE_BROKER_EMPLOYEE_FAIL:
      return { ...state, _error: action.payload };

    // DELETE_BROKER_EMPLOYEE
    case DELETE_BROKER_EMPLOYEE:
      return {
        ...state,
        _error: null,
      };

    case DELETE_BROKER_EMPLOYEE_SUCCESS:
      return {
        ...state,
        currentBroker: {
          ...state.currentBroker,
          contacts: (state.currentBroker?.contacts || []).filter(
            (employee: IBrokerContact) => employee.id !== action.payload,
          ),
        } as IBroker,
      };
    case DELETE_BROKER_EMPLOYEE_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // OTHERS
    case LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
};

export default brokersReducer;
