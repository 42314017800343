import { FC } from 'react';

import { useSelector } from 'react-redux';

import { userThemeSelector } from 'redux/userSettings/userSettingsSelectors';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const LittleTruck: FC<IOwnProps> = ({ className, fillColor = '#2C6AF6' }) => {
  const theme = useSelector(userThemeSelector);

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10 13C10 14.1046 9.10457 15 8 15C6.89543 15 6 14.1046 6 13M10 13C10 11.8954 9.10457 11 8 11C6.89543 11 6 11.8954 6 13M10 13H14C14.5523 13 15 12.5523 15 12V7.78078C15 7.32191 14.6877 6.92193 14.2425 6.81063L11.4018 6.10046C11.1445 6.03614 10.9233 5.87224 10.7869 5.64481L8.2913 1.4855C8.11058 1.1843 7.78507 1 7.43381 1H2C1.44772 1 1 1.44772 1 2V12C1 12.5523 1.44772 13 2 13H6"
        strokeWidth="2"
        stroke={theme === 'dark' ? 'white' : fillColor}
      />
    </svg>
  );
};

export default LittleTruck;
