import { FC } from 'react';

import { useSelector } from 'react-redux';

import { userThemeSelector } from 'redux/userSettings/userSettingsSelectors';

type OwnProps = {
  className?: string;
};

const IconFileCommon: FC<OwnProps> = ({ className }) => {
  const theme = useSelector(userThemeSelector);

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19 2V8C19 9.10457 19.8954 10 21 10H28"
        stroke={theme === 'dark' ? '#fff' : '#2D2F36'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M28 27V10.7578C28 10.224 27.7866 9.71242 27.4074 9.33681L20.5846 2.57903C20.2101 2.20809 19.7043 2 19.1772 2H6C4.89543 2 4 2.89543 4 4L4 27"
        stroke={theme === 'dark' ? '#fff' : '#2D2F36'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M4 28V29C4 30.1046 4.89543 31 6 31H26C27.1046 31 28 30.1046 28 29V28"
        stroke={theme === 'dark' ? '#fff' : '#2D2F36'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <line
        x1="10"
        y1="17"
        x2="22"
        y2="17"
        stroke={theme === 'dark' ? '#fff' : '#2D2F36'}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="10"
        y1="23"
        x2="22"
        y2="23"
        stroke={theme === 'dark' ? '#fff' : '#2D2F36'}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconFileCommon;
