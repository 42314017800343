export const SET_PER_PAGE = '[@@Pagination] SET_PER_PAGE';

export interface IPagination {
  page: number;
  total: number;
  per_page: number;
  pages: number;
}

export interface ISetPerPage {
  type: typeof SET_PER_PAGE;
  payload: number;
}

export const SET_PAGE = '[@@Pagination] SET_PAGE';

export interface ISetPage {
  type: typeof SET_PAGE;
  payload: number;
}

export const SET_PAGES = '[@@Pagination] SET_PAGES';

export interface ISetPages {
  type: typeof SET_PAGES;
  payload: number;
}

export const SET_PAGINATION = '[@@Pagination] SET_PAGINATION';

export interface ISetPaginationAction {
  type: typeof SET_PAGINATION;
  payload: Partial<IPagination>;
}

export type paginationTypes =
  | ISetPerPage
  | ISetPage
  | ISetPages
  | ISetPaginationAction;
