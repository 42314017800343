import { FC, CSSProperties, useState, useMemo, memo, useCallback } from 'react';

import moment from 'moment';

import {
  NotificationsChannelItem,
  NotificationsChannelMessage,
  NotificationsChannelTask,
  MessageTextParam,
} from 'socket/channels';

import { Checkbox } from '../CustomMaterialComponents';
import { getPeriodAgo } from '../TimeRange';

import styles from './NotificationMessages.module.scss';

export function getFormattedMessage(
  text: string,
  text_params: NotificationsChannelItem['text_params'],
): string | JSX.Element[] {
  if (!text_params) return text;

  const parts: JSX.Element[] = [];

  let restText = text;
  let b = 0;
  do {
    b = restText.indexOf('{', b);
    if (b >= 0) {
      const e = restText.indexOf('}', b);
      if (e >= 0) {
        const paramName = restText.substring(b + 1, e);
        const param = text_params[paramName];

        if (param) {
          parts.push(<>{restText.substring(0, b)}</>);
          restText = restText.substring(e + 1);

          const { value, color, is_bold, is_underline, url } =
            typeof param === 'string'
              ? ({ value: param } as MessageTextParam)
              : param;

          // eslint-disable-next-line react/jsx-no-useless-fragment
          let part: JSX.Element = <>{value}</>;
          let style: CSSProperties | undefined;
          if (color) style = { color };

          if (url) part = <a href={url}>{value}</a>;
          if (is_underline) part = <u>{part}</u>;

          if (is_bold) part = <b style={style}>{part}</b>;
          else if (color) part = <span style={style}>{part}</span>;

          parts.push(part);
          b = 0;
        } else b = e;
      }
    }
  } while (b >= 0);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (restText) parts.push(<>{restText}</>);

  return parts.map((p, key) => ({ ...p, key }));
}

export const Message: FC<{
  message: NotificationsChannelMessage;
  onMarkAsRead: (id: number) => void;
}> = memo(({ message, onMarkAsRead }) => {
  const { text, text_params, created_at, is_read } = message;

  const formattedMessage = useMemo(
    () => getFormattedMessage(text, text_params),
    [text, text_params],
  );

  const ago = useMemo(() => getPeriodAgo(moment(created_at)), [created_at]);

  return (
    <div className={styles.message}>
      {!is_read && <div className={styles.notRead} />}
      <div className={styles.body}>
        {formattedMessage}

        <span
          className={styles.markAsRead}
          onClick={() => onMarkAsRead(message.id)}
        >
          {is_read ? 'Mark as Unread' : 'Mark as Read'}
        </span>
      </div>
      <div className={styles.ago}>{ago}</div>
    </div>
  );
});

export const Task: FC<{
  task: NotificationsChannelTask;
  onMarkAsDone: (id: number, isDone: boolean) => void;
}> = memo(({ task, onMarkAsDone }) => {
  const { id, text, text_params, expired_at, is_done } = task;
  const [isDone, setIsDone] = useState(is_done);

  const formattedMessage = useMemo(
    () => getFormattedMessage(text, text_params),
    [text, text_params],
  );

  const ago = useMemo(() => getPeriodAgo(moment(expired_at)), [expired_at]);

  const onClickHandle = useCallback(() => {
    setIsDone(done => {
      onMarkAsDone(id, !done);
      return !done;
    });
  }, [id, onMarkAsDone]);

  return (
    <div className={styles.message}>
      <Checkbox
        className={styles.checkBox}
        checked={isDone}
        onClick={onClickHandle}
      />
      <div className={styles.body}>{formattedMessage}</div>
      <div className={styles.ago}>{ago}</div>
    </div>
  );
});
