import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { AppState } from 'redux/store';

export default function useGetFormValues(formName: string, fields: string[]) {
  const selector = formValueSelector(formName);
  const formValues = useSelector((state: AppState) =>
    selector(state, ...fields),
  );

  return { formValues };
}
