import { startSubmit, stopSubmit } from 'redux-form';
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects';

import { CREATE_CLAIM_FORM_NAME } from 'components/Fleet/TabClaims/_constants/constants';
import {
  addEditClaimNoteAction,
  addEditClaimNoteFailAction,
  createClaimAction,
  createClaimFailAction,
  deleteClaimAction,
  deleteClaimFailAction,
  deleteClaimFileAction,
  deleteClaimIssueFileAction,
  uploadClaimFilesAction,
  uploadClaimIssueFilesAction,
} from 'components/Fleet/TabClaims/_redux/claimsActions';
import {
  ADD_EDIT_CLAIM_NOTE,
  CREATE_CLAIM,
  CREATE_CLAIM_FAIL,
  CREATE_CLAIM_SUCCESS,
  DELETE_CLAIM,
  DELETE_CLAIM_FILE,
  DELETE_CLAIM_FILE_FAIL,
  DELETE_CLAIM_FILE_SUCCESS,
  DELETE_CLAIM_ISSUE_FILE,
  DELETE_CLAIM_ISSUE_FILE_FAIL,
  DELETE_CLAIM_ISSUE_FILE_SUCCESS,
  UPLOAD_CLAIM_FILES,
  UPLOAD_CLAIM_FILES_FAIL,
  UPLOAD_CLAIM_FILES_SUCCESS,
  UPLOAD_CLAIM_ISSUE_FILES,
  UPLOAD_CLAIM_ISSUE_FILES_FAIL,
  UPLOAD_CLAIM_ISSUE_FILES_SUCCESS,
} from 'components/Fleet/TabClaims/_redux/claimsTypes';
import { fileToFormData } from 'utils/converters';
import { getErrors } from 'utils/helpers';
import api from 'utils/requests';

// Create / Delete Claim
function* createClaimSaga({
  payload: { callback, values },
}: ReturnType<typeof createClaimAction>) {
  try {
    yield put(startSubmit(CREATE_CLAIM_FORM_NAME));
    yield call(() => api.post('/v1/fleets/drivers/claims', { claim: values }));
    callback();
    yield put({ type: CREATE_CLAIM_SUCCESS });
    yield put(stopSubmit(CREATE_CLAIM_FORM_NAME));
  } catch (e) {
    yield put({ type: CREATE_CLAIM_FAIL, payload: e });
    if (api.isAxiosError(e)) {
      yield put(createClaimFailAction(e));
      yield put(stopSubmit(CREATE_CLAIM_FORM_NAME, getErrors(e)));
    }
  }
}
function* deleteClaimSaga({
  payload: { id, callback },
}: ReturnType<typeof deleteClaimAction>) {
  try {
    yield call(() => api.delete(`v1/fleets/drivers/claims/${id}`));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(deleteClaimFailAction(e));
  }
}
function* addEditClaimNoteSaga({
  payload: { id, comment, callback },
}: ReturnType<typeof addEditClaimNoteAction>) {
  try {
    yield call(() =>
      api.patch(`v1/fleets/drivers/claims/${id}`, {
        claim: { comment },
      }),
    );
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(addEditClaimNoteFailAction(e));
  }
}

// CLAIMS FILES
function* uploadClaimFilesSaga({
  payload: { files, itemId: claim_id },
}: ReturnType<typeof uploadClaimFilesAction>) {
  const url = claim_id
    ? `/v1/fleets/drivers/claims/${claim_id}/files`
    : `/v1/fleets/drivers/claims/files`;

  for (const fileObj of files) {
    yield call(function* foo() {
      try {
        const { data } = yield call(() =>
          api.post(
            url,
            fileToFormData(fileObj.file, `fleets_drivers_claims_file[file]`),
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            },
          ),
        );
        yield put({ type: UPLOAD_CLAIM_FILES_SUCCESS, payload: data.file });
      } catch (e) {
        yield put({ type: UPLOAD_CLAIM_FILES_FAIL, payload: e });
      }
    });
  }
}

function* deleteClaimFileSaga({
  payload: { fileId, itemId: claim_id },
}: ReturnType<typeof deleteClaimFileAction>) {
  const url = claim_id
    ? `v1/fleets/drivers/claims/${claim_id}/files/${fileId}`
    : `v1/fleets/drivers/claims/files/${fileId}`;
  try {
    yield call(() => api.delete(url));
    yield put({ type: DELETE_CLAIM_FILE_SUCCESS, payload: fileId });
  } catch (e) {
    yield put({ type: DELETE_CLAIM_FILE_FAIL, payload: e });
  }
}

// CLAIM ISSUE FILES
function* uploadClaimIssueFilesSaga({
  payload: { files, issue_id, claim_id },
}: ReturnType<typeof uploadClaimIssueFilesAction>) {
  let url = '/v1/fleets/drivers/claims/issues/files';

  if (claim_id && issue_id) {
    url = `/v1/fleets/drivers/claims/${claim_id}/issues/${issue_id}/files`;
  }

  for (const fileObj of files) {
    yield call(function* foo() {
      try {
        const { data } = yield call(() =>
          api.post(
            url,
            fileToFormData(
              fileObj.file,
              `fleets_drivers_claims_issues_file[file]`,
            ),
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            },
          ),
        );
        yield put({
          type: UPLOAD_CLAIM_ISSUE_FILES_SUCCESS,
          payload: { file: data.file, issue_id },
        });
      } catch (e) {
        yield put({ type: UPLOAD_CLAIM_ISSUE_FILES_FAIL, payload: e });
      }
    });
  }
}

function* deleteClaimIssueFileSaga({
  payload: { fileId, issue_id, claim_id },
}: ReturnType<typeof deleteClaimIssueFileAction>) {
  let url = `/v1/fleets/drivers/claims/issues/files/${fileId}`;

  if (claim_id && issue_id) {
    url = `/v1/fleets/drivers/claims/${claim_id}/issues/${issue_id}/files/${fileId}`;
  }

  try {
    yield call(() => api.delete(url));
    yield put({
      type: DELETE_CLAIM_ISSUE_FILE_SUCCESS,
      payload: { fileId, issue_id },
    });
  } catch (e) {
    yield put({ type: DELETE_CLAIM_ISSUE_FILE_FAIL, payload: e });
  }
}

export default function* claimsSaga() {
  yield takeLeading(CREATE_CLAIM, createClaimSaga);
  yield takeLeading(DELETE_CLAIM, deleteClaimSaga);
  yield takeLatest(ADD_EDIT_CLAIM_NOTE, addEditClaimNoteSaga);
  yield takeLatest(UPLOAD_CLAIM_FILES, uploadClaimFilesSaga);
  yield takeLatest(DELETE_CLAIM_FILE, deleteClaimFileSaga);
  yield takeLatest(UPLOAD_CLAIM_ISSUE_FILES, uploadClaimIssueFilesSaga);
  yield takeLatest(DELETE_CLAIM_ISSUE_FILE, deleteClaimIssueFileSaga);
}
