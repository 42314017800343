import { LOGOUT } from 'components/Auth/_redux/authTypes';

import {
  ISchedulerReducer,
  schedulerActionsType,
  GET_SCHEDULER,
  GET_SCHEDULER_SUCCESS,
  GET_SCHEDULER_FAIL,
} from './calendarTypes';

const initialState: ISchedulerReducer = {
  drivers: [],
  data: [],
  date_to: undefined,
  date_from: undefined,
  _pending: false,
  _loaded: false,
  _error: null,
};

const schedulerReducers = (
  state = initialState,
  action: schedulerActionsType,
): ISchedulerReducer => {
  switch (action.type) {
    case GET_SCHEDULER:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };

    case GET_SCHEDULER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        _loaded: true,
        _pending: false,
      };

    case GET_SCHEDULER_FAIL:
      return {
        ...initialState,
        _error: action.payload,
      };

    case LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
};

export default schedulerReducers;
