import {
  cloneElement,
  CSSProperties,
  FC,
  ReactElement,
  useEffect,
  useRef,
  MouseEvent,
} from 'react';

import { Fade } from '@mui/material';
import clsx from 'clsx';

import useOnClickOutside from 'hooks/useOnClickOutside';
import useToggle from 'hooks/useToggle';

import styles from './Menu.module.scss';

type Props = {
  enterBtn: JSX.Element;
  className?: string;
  enterBtnClassName?: { active?: string; default?: string };
  withTransition?: boolean;
  isVisible?: boolean;
  style?: CSSProperties;
  children: JSX.Element;
};

const Menu: FC<Props> = ({
  className,
  enterBtnClassName,
  enterBtn,
  children,
  withTransition = true,
  isVisible = false,
  style,
}) => {
  const { close, isOpen, open, toggleVisibility } = useToggle(isVisible);
  const menuRef = useRef(null);
  useOnClickOutside({ ref: menuRef, callback: close });

  useEffect(() => {
    if (isVisible && !isOpen) open();
    if (!isVisible && isOpen) close();
  }, [isVisible]);

  const childElements = (
    <div>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      {cloneElement(children as ReactElement<any>, { onClose: close })}
    </div>
  );

  const onToggleClick = (e: MouseEvent) => {
    e.stopPropagation();
    toggleVisibility();
  };

  return (
    <div
      className={clsx(styles.root, className)}
      ref={menuRef}
      style={{ ...style }}
    >
      <div
        onClick={onToggleClick}
        className={clsx(
          enterBtnClassName && enterBtnClassName.default,
          isOpen && enterBtnClassName && enterBtnClassName.active,
        )}
      >
        {enterBtn}
      </div>

      {withTransition ? (
        <Fade in={isOpen}>{childElements}</Fade>
      ) : (
        isOpen && childElements
      )}
    </div>
  );
};

export default Menu;
