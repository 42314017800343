import { useRef, useState, useMemo, useCallback, memo } from 'react';

import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  IconTasks,
  IconNotification,
  IconUserPhoto,
  IconChat,
  IconSupportCenter,
} from 'assets/icons/components';
import { Popover } from 'components/_common/CustomMaterialComponents';
import SearchInput from 'components/_common/FormElements/SearchInput/SearchInput';
import Menu from 'components/_common/Menu/Menu';
import UserDropdown from 'components/_common/Navigation/NewMainHeader/UserDropdown/UserDropdown';
import {
  NotificationMessages,
  NotificationTasks,
} from 'components/_common/NotificationMessages/NotificationMessages';
import { useSearchPanel } from 'components/_common/SearchPanel/NewSearchPanel';
import { authSelector } from 'components/Auth/_redux/authSelectors';
import { unreadMessagesCountSelector } from 'components/Chat/_redux/chatSelectors';
import { socketChannelSelector } from 'redux/socket/socketSelectors';
import {
  NotificationsChannelItem,
  NotificationsChannelMessage,
  NotificationsChannelTask,
  isMessage,
  isTask,
  NOTIFICATIONS_CHANNEL_ID,
} from 'socket/channels';

import useToggle from '../../../../hooks/useToggle';
import SupportCenterModal from '../../../Fleet/_common/SupportCenterModal/SupportCenterModal';

import styles from './NewMainHeader.module.scss';

const NewMainHeader = () => {
  const history = useHistory();

  const btnMessagesRef = useRef<HTMLButtonElement>(null);

  const [popover, openPopover] = useState<'messages' | 'tasks'>();
  const closePopover = () => openPopover(undefined);

  const { setValue: setSearchValue, ...searchInputProps } =
    useSearchPanel(null);

  const {
    isOpen: isSupportModalOpen,
    open: openSupportModal,
    close: closeSupportModal,
  } = useToggle();

  const { photo_urls } = useSelector(authSelector);
  const unreadChatMessagesCount = useSelector(unreadMessagesCountSelector);

  const { messages: notificationItems } = useSelector(
    socketChannelSelector<NotificationsChannelItem>(NOTIFICATIONS_CHANNEL_ID),
  );

  const [tasks, messages, unreadMessagesCount] = useMemo(() => {
    const ts: NotificationsChannelTask[] = [];
    const ms: NotificationsChannelMessage[] = [];
    notificationItems.forEach(msg => {
      if (isTask(msg) && !msg.is_done) ts.push(msg);
      if (isMessage(msg)) ms.push(msg);
    });
    return [ts, ms, ms.reduce((acc, m) => acc + (m?.is_read ? 0 : 1), 0)];
  }, [notificationItems]);

  const onChatIconClickHandler = useCallback(() => {
    history.push('/chat');
  }, [history]);

  const onChangeSearch = useCallback((value: string) => {
    const query = value?.length ? value.replace('#', '') : value;
    setSearchValue(query);
  }, []);

  const withShadow = useMemo(() => {
    const {
      location: { pathname },
    } = history;
    return pathname?.includes('chat') || pathname?.includes('fleet/settings');
  }, [history.location.pathname]);

  return (
    <>
      <div
        className={clsx(styles.root, {
          [styles.withShadow]: withShadow,
        })}
      >
        <header className={styles.mainWrapper}>
          <div id="dvl-search-container" className={styles.searchContainer}>
            <SearchInput
              className={styles.newSearchInput}
              {...searchInputProps}
              onChange={onChangeSearch}
            />
          </div>

          <section className={styles.rightIconsContainer}>
            <button className={styles.chatIcon} onClick={openSupportModal}>
              <IconSupportCenter />
            </button>

            <button
              className={styles.chatIcon}
              onClick={onChatIconClickHandler}
            >
              <IconChat />
              {!!unreadChatMessagesCount && (
                <span className={styles.count}>
                  {unreadChatMessagesCount < 100
                    ? unreadChatMessagesCount
                    : '99+'}
                </span>
              )}
            </button>

            <button
              className={!tasks.length ? styles.disabled : undefined}
              disabled={!tasks.length}
              onClick={() => openPopover('tasks')}
            >
              <IconTasks />
              {!!tasks.length && (
                <span className={styles.count}>
                  {tasks.length < 100 ? tasks.length : '99+'}
                </span>
              )}
            </button>

            <button
              ref={btnMessagesRef}
              className={!messages.length ? styles.disabled : undefined}
              disabled={!messages.length}
              onClick={() => openPopover('messages')}
            >
              <IconNotification />
              {!!unreadMessagesCount && (
                <span className={styles.count}>
                  {unreadMessagesCount < 100 ? unreadMessagesCount : '99+'}
                </span>
              )}
            </button>

            <Menu
              className={styles.userLogo}
              enterBtn={
                photo_urls.thumb ? (
                  <img
                    src={photo_urls.thumb}
                    alt="user logo"
                    className={styles.userLogo}
                  />
                ) : (
                  <IconUserPhoto
                    className={clsx(styles.userLogo, styles.withoutLogo)}
                  />
                )
              }
            >
              <UserDropdown />
            </Menu>
          </section>
        </header>
      </div>

      {popover && (
        <Popover
          open
          anchorEl={btnMessagesRef.current}
          onClose={closePopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          {popover === 'messages' && (
            <NotificationMessages
              messages={messages}
              unreadCount={unreadMessagesCount}
              onClose={closePopover}
            />
          )}
          {popover === 'tasks' && (
            <NotificationTasks tasks={tasks} onClose={closePopover} />
          )}
        </Popover>
      )}
      {isSupportModalOpen && (
        <SupportCenterModal
          isOpen={isSupportModalOpen}
          close={closeSupportModal}
        />
      )}
    </>
  );
};

export default memo(NewMainHeader);
