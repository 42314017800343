import { memo } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { getAttrName } from 'hooks/useTestAttrRef';

import { SideBarDropMenuItem } from '../_models';

import styles from './SidebarDropMenu.module.scss';

type Props = {
  item: SideBarDropMenuItem;
  selected?: boolean;
};

const SidebarDropMenuLink = ({ item, selected = false }: Props) => {
  const { id, path, title } = item;

  return (
    <Link
      key={id}
      to={path}
      className={clsx(styles.menuLink, {
        [styles.selected]: selected,
      })}
      data-test={`main_sidebar_${getAttrName(path)}_link`}
    >
      {title}
    </Link>
  );
};

export default memo(SidebarDropMenuLink);
