import { FC, memo } from 'react';

type Props = {
  className?: string;
  fillColor?: string;
};

const IconAlertCircle: FC<Props> = ({ className, fillColor = 'white' }) => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="36" cy="36" r="36" fill="#FF4A3C" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.3411 25.9364C34.4629 23.7867 37.5392 23.7869 38.6606 25.9367L47.3602 42.613C48.4021 44.6103 46.953 47.0005 44.7003 47.0005H27.299C25.0461 47.0005 23.5971 44.61 24.6393 42.6127L33.3411 25.9364ZM36.8874 26.8617C36.5136 26.1451 35.4882 26.145 35.1143 26.8616L26.4124 43.5379C26.065 44.2037 26.548 45.0005 27.299 45.0005H44.7003C45.4512 45.0005 45.9342 44.2038 45.5869 43.538L36.8874 26.8617ZM35.9994 31.0005C36.5517 31.0005 36.9994 31.4482 36.9994 32.0005V37.0005C36.9994 37.5528 36.5517 38.0005 35.9994 38.0005C35.4472 38.0005 34.9994 37.5528 34.9994 37.0005V32.0005C34.9994 31.4482 35.4472 31.0005 35.9994 31.0005ZM36.9994 41.0005C36.9994 41.5528 36.5517 42.0005 35.9994 42.0005C35.4472 42.0005 34.9994 41.5528 34.9994 41.0005C34.9994 40.4482 35.4472 40.0005 35.9994 40.0005C36.5517 40.0005 36.9994 40.4482 36.9994 41.0005Z"
      fill={fillColor}
    />
  </svg>
);

export default memo(IconAlertCircle);
