import { FC } from 'react';

import { useSelector } from 'react-redux';

import { userThemeSelector } from 'redux/userSettings/userSettingsSelectors';

interface IOwnProps {
  className?: string;
}

const IconArrowLeft: FC<IOwnProps> = ({ className }) => {
  const theme = useSelector(userThemeSelector);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.7071 6.70711C12.0976 6.31658 12.0976 5.68342 11.7071 5.29289C11.3166 4.90237 10.6834 4.90237 10.2929 5.29289L11.7071 6.70711ZM5 12L4.29289 11.2929L3.58579 12L4.29289 12.7071L5 12ZM10.2929 18.7071C10.6834 19.0976 11.3166 19.0976 11.7071 18.7071C12.0976 18.3166 12.0976 17.6834 11.7071 17.2929L10.2929 18.7071ZM19.5 13C20.0523 13 20.5 12.5523 20.5 12C20.5 11.4477 20.0523 11 19.5 11V13ZM10.2929 5.29289L4.29289 11.2929L5.70711 12.7071L11.7071 6.70711L10.2929 5.29289ZM4.29289 12.7071L10.2929 18.7071L11.7071 17.2929L5.70711 11.2929L4.29289 12.7071ZM5 13H19.5V11H5V13Z"
        fill={theme === 'dark' ? '#fff' : '#2D2F36'}
      />
    </svg>
  );
};

export default IconArrowLeft;
