import { ILocalFile } from 'redux/_common/models';

const FILE_KEY = 'upcoming_drivers_onboardings_steps_file';

const formValuesKeys = [
  'number',
  'state_id',
  'exp_date',
  'date',
  'issue_date',
  'status',
  'note',
  'contract_type',
];

export const upcomingDriverFilesToFormData = (file: ILocalFile) => {
  const formData = new FormData();

  formData.append(`${FILE_KEY}[file]`, file.file);
  formData.append(`${FILE_KEY}[file_type]`, `${file.category_id}`);

  Object.entries(file).forEach(([key, value]) => {
    if (formValuesKeys.includes(key))
      formData.append(`${FILE_KEY}[${key}]`, `${value}`);
  });

  return formData;
};

export const upcomingDriverTestFilesToFormData = (file: ILocalFile) => {
  const formData = new FormData();
  formData.append(
    `upcoming_drivers_onboardings_steps_tests_file[file]`,
    file.file,
  );
  return formData;
};
