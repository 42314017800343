import { IconWarehouse } from 'assets/icons/components';
import { ISearchResult } from 'components/_common/SearchPanel/models';

import { ILocation } from '../_models/locationModels';

// eslint-disable-next-line import/prefer-default-export
export const transformLocationsSearchResult = (
  payload: ILocation[],
): ISearchResult[] => {
  return payload.map((item: ILocation) => {
    return {
      id: item.id,
      title: item.name,
      additional: `${item.zip_code} ${item.address}, ${item.city}`,
      url: `/fleet/inventory/locations/${item.id}`,
      icon: <IconWarehouse strokeColor="var(--primaryText)" size="14" />,
    };
  });
};
