import { FC } from 'react';

interface IOwnProps {
  className?: string;
}

const IconAttachFile: FC<IOwnProps> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15 5C15 4.44772 14.5523 4 14 4C13.4477 4 13 4.44772 13 5H15ZM7 5C7 4.44772 6.55228 4 6 4C5.44772 4 5 4.44772 5 5H7ZM3 5C3 3.34315 4.34315 2 6 2V0C3.23858 0 1 2.23858 1 5H3ZM6 2C7.65685 2 9 3.34315 9 5H11C11 2.23858 8.76142 0 6 0V2ZM1 5V9H3V5H1ZM9 5V9H11V5H9ZM13 9C13 11.7614 10.7614 14 8 14V16C11.866 16 15 12.866 15 9H13ZM8 14C5.23858 14 3 11.7614 3 9H1C1 12.866 4.13401 16 8 16V14ZM15 9V5H13V9H15ZM9 9C9 9.55228 8.55228 10 8 10V12C9.65685 12 11 10.6569 11 9H9ZM8 10C7.44772 10 7 9.55229 7 9H5C5 10.6569 6.34315 12 8 12V10ZM7 9V5H5V9H7Z"
        fill="#86898E"
      />
    </svg>
  );
};

export default IconAttachFile;
