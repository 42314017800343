import { AxiosError } from 'axios';

import { IPagination } from 'components/_common/Navigation/Pagination/_redux/paginationTypes';
import { IBrokerRate } from 'components/Dispatch/TabBrokers/_models/brokerModel';
import {
  IDriver,
  IDriversStatistic,
  IDriverUploadedFile,
  IDriverRates,
} from 'components/HumanResources/TabDrivers/_models/driverModel';
import {
  IDeleteFileAction,
  IDeleteFileActionFail,
  IDeleteFileActionSuccess,
  IUploadFilesAction,
  IUploadFilesActionFail,
  IUploadFilesActionSuccess,
} from 'redux/_common/commonTypes';
import { ILogout } from 'redux/_common/models';
import { DefaultRateSortKey } from 'redux/userSettings/models';

// GET DRIVERS
export const GET_DRIVERS = '[@@Driver] GET_DRIVERS';
export const GET_DRIVERS_SUCCESS = '[@@Driver] GET_DRIVERS_SUCCESS';
export const GET_DRIVERS_FAIL = '[@@Driver] GET_DRIVERS_FAIL';

export interface IGetDriversAction {
  type: typeof GET_DRIVERS;
  config: { useSeparateHeapFlag: boolean };
}
export interface IGetDriversActionSuccess {
  type: typeof GET_DRIVERS_SUCCESS;
  payload: {
    drivers: IDriver[];
    pagination: IPagination;
    statistic: IDriversStatistic | null;
    useSeparateHeapFlag: boolean;
  };
}
export interface IGetDriversActionFail {
  type: typeof GET_DRIVERS_FAIL;
  payload: AxiosError;
}

// GET DRIVER
export const GET_DRIVER = '[@@Driver] GET_DRIVER';
export const GET_DRIVER_SUCCESS = '[@@Driver] GET_DRIVER_SUCCESS';
export const GET_DRIVER_FAIL = '[@@Driver] GET_DRIVER_FAIL';

export interface IGetDriverAction {
  type: typeof GET_DRIVER;
}
export interface IGetDriverActionSuccess {
  type: typeof GET_DRIVER_SUCCESS;
  payload: IDriver;
}
export interface IGetDriverActionFail {
  type: typeof GET_DRIVER_FAIL;
  payload: AxiosError;
}

// GET DRIVER RATES LIST
export const GET_DRIVER_RATES_LIST = '[@@Driver] GET_DRIVER_RATES_LIST';
export const GET_DRIVER_RATES_LIST_SUCCESS =
  '[@@Driver] GET_DRIVER_RATES_LIST_SUCCESS';
export const GET_DRIVER_RATES_LIST_FAIL =
  '[@@Driver] GET_DRIVER_RATES_LIST_FAIL';

export interface IGetDriverRatesListAction {
  type: typeof GET_DRIVER_RATES_LIST;
  payload: {
    data: { id: string | number };
    config?: { sort: DefaultRateSortKey };
  };
}
export interface IGetDriverRatesListActionSuccess {
  type: typeof GET_DRIVER_RATES_LIST_SUCCESS;
  payload: {
    rates: IDriverRates[];
    pagination: IPagination;
  };
}
export interface IGetDriverRatesListActionFail {
  type: typeof GET_DRIVER_RATES_LIST_FAIL;
  payload: AxiosError;
}

// CREATE DRIVER
export const CREATE_DRIVER = '[@@Driver] CREATE_DRIVER';
export const CREATE_DRIVER_SUCCESS = '[@@Driver] CREATE_DRIVER_SUCCESS';
export const CREATE_DRIVER_FAIL = '[@@Driver] CREATE_DRIVER_FAIL';

export interface ICreateDriverAction {
  type: typeof CREATE_DRIVER;
}
export interface ICreateDriverActionSuccess {
  type: typeof CREATE_DRIVER_SUCCESS;
  payload: IDriver;
}
export interface ICreateDriverActionFail {
  type: typeof CREATE_DRIVER_FAIL;
  payload: AxiosError;
}

// UPDATE DRIVER
export const UPDATE_DRIVER = '[@@Driver] UPDATE_DRIVER';
export const UPDATE_DRIVER_SUCCESS = '[@@Driver] UPDATE_DRIVER_SUCCESS';
export const UPDATE_DRIVER_FAIL = '[@@Driver] UPDATE_DRIVER_FAIL';

export interface IUpdateDriverAction {
  type: typeof UPDATE_DRIVER;
}
export interface IUpdateDriverActionSuccess {
  type: typeof UPDATE_DRIVER_SUCCESS;
  payload: IDriver;
}
export interface IUpdateDriverActionFail {
  type: typeof UPDATE_DRIVER_FAIL;
  payload: AxiosError;
}

// ASSIGN DRIVER
export const ASSIGN_DRIVER = '[@@Driver] ASSIGN_DRIVER';
export const ASSIGN_DRIVER_SUCCESS = '[@@Driver] ASSIGN_DRIVER_SUCCESS';
export const ASSIGN_DRIVER_FAIL = '[@@Driver] ASSIGN_DRIVER_FAIL';

export interface IAssignDriverAction {
  type: typeof ASSIGN_DRIVER;
}
export interface IAssignDriverActionSuccess {
  type: typeof ASSIGN_DRIVER_SUCCESS;
  payload: IDriver;
}
export interface IAssignDriverActionFail {
  type: typeof ASSIGN_DRIVER_FAIL;
  payload: AxiosError;
}

// DELETE DRIVER
export const DELETE_DRIVER = '[@@Driver] DELETE_DRIVER';
export const DELETE_DRIVER_SUCCESS = '[@@Driver] DELETE_DRIVER_SUCCESS';
export const DELETE_DRIVER_FAIL = '[@@Driver] DELETE_DRIVER_FAIL';

export interface IDeleteDriverAction {
  type: typeof DELETE_DRIVER;
}
export interface IDeleteDriverActionSuccess {
  type: typeof DELETE_DRIVER_SUCCESS;
  payload: number;
}
export interface IDeleteDriverActionFail {
  type: typeof DELETE_DRIVER_FAIL;
  payload: AxiosError;
}

// UPLOAD DRIVER PHOTO
export const UPLOAD_DRIVER_PHOTO = '[@@Driver] UPLOAD_DRIVER_PHOTO';
export const UPLOAD_DRIVER_PHOTO_SUCCESS =
  '[@@Driver] UPLOAD_DRIVER_PHOTO_SUCCESS';
export const UPLOAD_DRIVER_PHOTO_FAIL = '[@@Driver] UPLOAD_DRIVER_FILES_FAIL';

export interface IUploadDriverPhotoAction {
  type: typeof UPLOAD_DRIVER_PHOTO;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}
export interface IUploadDriverPhotoActionSuccess {
  type: typeof UPLOAD_DRIVER_PHOTO_SUCCESS;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}
export interface IUploadDriverPhotoActionFail {
  type: typeof UPLOAD_DRIVER_PHOTO_FAIL;
  payload: AxiosError;
}

// DELETE DRIVER PHOTO
export const DELETE_DRIVER_PHOTO = '[@@Driver] DELETE_DRIVER_PHOTO';
export const DELETE_DRIVER_PHOTO_SUCCESS =
  '[@@Driver] DELETE_DRIVER_PHOTO_SUCCESS';
export const DELETE_DRIVER_PHOTO_FAIL = '[@@Driver] DELETE_DRIVER_PHOTO_FAIL';

export interface IDeleteDriverPhotoAction {
  type: typeof DELETE_DRIVER_PHOTO;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}
export interface IDeleteDriverPhotoActionSuccess {
  type: typeof DELETE_DRIVER_PHOTO_SUCCESS;
  payload: string;
}
export interface IDeleteDriverPhotoActionFail {
  type: typeof DELETE_DRIVER_PHOTO_FAIL;
  payload: AxiosError;
}

// UPLOAD DRIVER FILES
export const UPLOAD_DRIVER_FILES = '[@@Driver] UPLOAD_DRIVER_FILES';
export const UPLOAD_DRIVER_FILES_SUCCESS =
  '[@@Driver] UPLOAD_DRIVER_FILES_SUCCESS';
export const UPLOAD_DRIVER_FILES_FAIL = '[@@Driver] UPLOAD_DRIVER_FILES_FAIL';

export interface IUploadDriverFilesAction extends IUploadFilesAction {
  type: typeof UPLOAD_DRIVER_FILES;
}
export interface IUploadDriverFilesActionSuccess
  extends IUploadFilesActionSuccess {
  type: typeof UPLOAD_DRIVER_FILES_SUCCESS;
}
export interface IUploadDriverFilesActionFail extends IUploadFilesActionFail {
  type: typeof UPLOAD_DRIVER_FILES_FAIL;
}

// DELETE DRIVER FILES
export const DELETE_DRIVER_FILE = '[@@Driver] DELETE_DRIVER_FILE';
export const DELETE_DRIVER_FILE_SUCCESS =
  '[@@Driver] DELETE_DRIVER_FILE_SUCCESS';
export const DELETE_DRIVER_FILE_FAIL = '[@@Driver] DELETE_DRIVER_FILE_FAIL';

export interface IDeleteDriverFileAction extends IDeleteFileAction {
  type: typeof DELETE_DRIVER_FILE;
}
export interface IDeleteDriverFileActionSuccess
  extends IDeleteFileActionSuccess {
  type: typeof DELETE_DRIVER_FILE_SUCCESS;
}
export interface IDeleteDriverFileActionFail extends IDeleteFileActionFail {
  type: typeof DELETE_DRIVER_FILE_FAIL;
}

// CHANGE DRIVER FILE CATEGORY
export const CHANGE_DRIVER_FILE_CATEGORY =
  '[@@Driver] CHANGE_DRIVER_FILE_CATEGORY';
export const CHANGE_DRIVER_FILE_CATEGORY_SUCCESS =
  '[@@Driver] CHANGE_DRIVER_FILE_CATEGORY_SUCCESS';
export const CHANGE_DRIVER_FILE_CATEGORY_FAIL =
  '[@@Driver] CHANGE_DRIVER_FILE_CATEGORY_FAIL';

export interface IChangeDriverFileCategoryAction {
  type: typeof CHANGE_DRIVER_FILE_CATEGORY;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}
export interface IChangeDriverFileCategoryActionSuccess {
  type: typeof CHANGE_DRIVER_FILE_CATEGORY_SUCCESS;
  payload: IDriverUploadedFile;
}
export interface IChangeDriverFileCategoryActionFail {
  type: typeof CHANGE_DRIVER_FILE_CATEGORY_FAIL;
  payload: AxiosError;
}

// ARCHIVE DRIVER
export const ARCHIVE_DRIVER = '[@@Driver] ARCHIVE_DRIVER';
export const ARCHIVE_DRIVER_SUCCESS = '[@@Driver] ARCHIVE_DRIVER_SUCCESS';
export const ARCHIVE_DRIVER_FAIL = '[@@Driver] ARCHIVE_DRIVER_FAIL';

export interface IArchiveDriverAction {
  type: typeof ARCHIVE_DRIVER;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}
export interface IArchiveDriverActionSuccess {
  type: typeof ARCHIVE_DRIVER_SUCCESS;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}
export interface IArchiveDriverActionFail {
  type: typeof ARCHIVE_DRIVER_FAIL;
  payload: AxiosError;
}

// SET DRIVER RATE
export const SET_DRIVER_RATE = '[@@Driver] SET_DRIVER_RATE';
export const SET_DRIVER_RATE_SUCCESS = '[@@Driver] SET_DRIVER_RATE_SUCCESS';
export const SET_DRIVER_RATE_FAIL = '[@@Driver] SET_DRIVER_RATE_FAIL';

export interface ISetDriverRateAction {
  type: typeof SET_DRIVER_RATE;
  payload: IBrokerRate;
}
export interface ISetDriverRateActionSuccess {
  type: typeof SET_DRIVER_RATE_SUCCESS;
}
export interface ISetDriverRateActionFail {
  type: typeof SET_DRIVER_RATE_FAIL;
  payload: AxiosError;
}

// FUEL_COMPANIES
export type IFuelCompany = {
  id: number;
  name: string;
};
export const GET_FUEL_COMPANIES = '[@@Driver] GET_FUEL_COMPANIES';
export const GET_FUEL_COMPANIES_SUCCESS =
  '[@@Driver] GET_FUEL_COMPANIES_SUCCESS';
export const GET_FUEL_COMPANIES_FAIL = '[@@Driver] GET_FUEL_COMPANIES_FAIL';

export interface IGetFuelCompanies {
  type: typeof GET_FUEL_COMPANIES;
}

export interface IGetFuelCompaniesSuccess {
  type: typeof GET_FUEL_COMPANIES_SUCCESS;
  payload: IFuelCompany[];
}

export interface IGetFuelCompaniesFail {
  type: typeof GET_FUEL_COMPANIES_FAIL;
  payload: AxiosError;
}

// SET_DRIVER_COMMENT_VISIBILITY
export const SET_DRIVER_COMMENT_VISIBILITY =
  '[@@Driver] SET_DRIVER_COMMENT_VISIBILITY';

export interface ISetDriverRateCommentVisibility {
  type: typeof SET_DRIVER_COMMENT_VISIBILITY;
  payload: {
    data: { id: number };
  };
}

export const UNMOUNT_DRIVER_FORM = '[@@Driver] UNMOUNT_DRIVER_FORM';

export interface IUnmountDriverFormAction {
  type: typeof UNMOUNT_DRIVER_FORM;
}

export type driversActionsType =
  | IArchiveDriverAction
  | IArchiveDriverActionSuccess
  | IArchiveDriverActionFail
  | IAssignDriverAction
  | IAssignDriverActionSuccess
  | IAssignDriverActionFail
  | IGetDriversAction
  | IGetDriversActionSuccess
  | IGetDriversActionFail
  | IGetDriverAction
  | IGetDriverActionSuccess
  | IGetDriverActionFail
  | ICreateDriverAction
  | ICreateDriverActionSuccess
  | ICreateDriverActionFail
  | IUpdateDriverAction
  | IUpdateDriverActionSuccess
  | IUpdateDriverActionFail
  | IDeleteDriverAction
  | IDeleteDriverActionSuccess
  | IDeleteDriverActionFail
  | IUploadDriverFilesAction
  | IUploadDriverFilesActionSuccess
  | IUploadDriverFilesActionFail
  | IDeleteDriverFileAction
  | IDeleteDriverFileActionSuccess
  | IDeleteDriverFileActionFail
  | IChangeDriverFileCategoryAction
  | IChangeDriverFileCategoryActionSuccess
  | IChangeDriverFileCategoryActionFail
  | IUploadDriverPhotoAction
  | IUploadDriverPhotoActionSuccess
  | IUploadDriverPhotoActionFail
  | IDeleteDriverPhotoAction
  | IDeleteDriverPhotoActionSuccess
  | IDeleteDriverPhotoActionFail
  | ISetDriverRateAction
  | ISetDriverRateActionSuccess
  | ISetDriverRateActionFail
  | IUnmountDriverFormAction
  | IGetDriverRatesListAction
  | IGetDriverRatesListActionSuccess
  | IGetDriverRatesListActionFail
  | ISetDriverRateCommentVisibility
  | IGetFuelCompanies
  | IGetFuelCompaniesSuccess
  | IGetFuelCompaniesFail
  | ILogout;
