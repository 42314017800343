import { FC } from 'react';

import { useSelector } from 'react-redux';

import Modal from 'components/_common/Modal/Modal';
import api from 'utils/requests';

import { authSelector } from '../../../Auth/_redux/authSelectors';
import SupportModal from './SupportForm';

import styles from './SupportCenterModal.module.scss';

type Props = {
  isOpen: boolean;
  close: () => void;
};

const SupportCenterModal: FC<Props> = ({ isOpen, close }) => {
  const { token } = useSelector(authSelector);
  const handleSubmitSupport = (values: { themes: string; message: string }) => {
    const data = {
      email: 'support@w1selogistics.com',
      employee_support_request: {
        theme: values.themes,
        message: values.message,
      },
    };
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      api.post('/v1/employee/support', data, config).then(() => close());
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      className={styles.supportCenterModal}
      onClose={close}
    >
      <h2>Contact Support</h2>
      <p>
        Need assistance? Submit a request below and we will get to work! If you
        think the problem is a bug raise it here and we will collect full
        details to report it to development.
      </p>
      <SupportModal submitQuestion={handleSubmitSupport} />
    </Modal>
  );
};

export default SupportCenterModal;
