import { change } from 'redux-form';
import { call, put, takeLatest } from 'redux-saga/effects';

import { fileToFormData } from '../../../../utils/converters';
import api from '../../../../utils/requests';
import {
  createInternalCompanyAction,
  deleteInternalCompanyAction,
  deleteInternalCompanyFilesAction,
  editInternalCompanyAction,
  editInternalCompanyFilesAction,
  uploadInternalCompanyFilesAction,
} from './internalCompanyActions';
import {
  DELETE_COMPANY_PHOTO_SUCCESS,
  UPLOAD_COMPANY_PHOTO,
  UPLOAD_COMPANY_PHOTO_FAIL,
  UPLOAD_COMPANY_PHOTO_SUCCESS,
  DELETE_COMPANY_PHOTO_FAIL,
  DELETE_COMPANY_PHOTO,
  CREATE_INTERNAL_COMPANY_SUCCESS,
  CREATE_INTERNAL_COMPANY_FAIL,
  CREATE_INTERNAL_COMPANY,
  GET_INTERNAL_COMPANIES,
  GET_INTERNAL_COMPANIES_SUCCESS,
  GET_INTERNAL_COMPANIES_FAIL,
  DELETE_INTERNAL_COMPANY_SUCCESS,
  DELETE_INTERNAL_COMPANY_FAIL,
  DELETE_INTERNAL_COMPANY,
  EDIT_INTERNAL_COMPANY_SUCCESS,
  EDIT_INTERNAL_COMPANY_FAIL,
  EDIT_INTERNAL_COMPANY,
  EDIT_COMPANY_PHOTO_SUCCESS,
  EDIT_COMPANY_PHOTO_FAIL,
  EDIT_COMPANY_PHOTO,
} from './internalCompanyTypes';

function* uploadCompanyPhotoSaga({
  payload: { photo, callback },
}: ReturnType<typeof uploadInternalCompanyFilesAction>) {
  try {
    const { data } = yield call(() =>
      api.post(
        '/v1/internal_companies/photo',
        fileToFormData(photo, 'internal_companies_photos[file]'),
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      ),
    );
    const uploadedPhoto = { id: data.id, url: data.photo.thumb };
    yield put(
      change('createAndUpdateInternalCompanyForm', 'photo', uploadedPhoto),
    );
    yield put({ type: UPLOAD_COMPANY_PHOTO_SUCCESS });
    if (callback) yield put(callback);
  } catch (e) {
    yield put({ type: UPLOAD_COMPANY_PHOTO_FAIL, payload: e });
  }
}

function* editCompanyPhotoSaga({
  payload: { photo, companyId, callback },
}: ReturnType<typeof editInternalCompanyFilesAction>) {
  try {
    const { data } = yield call(() =>
      api.patch(
        `/v1/internal_companies/${companyId}/photo`,
        fileToFormData(photo, 'internal_companies_photos[file]'),
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      ),
    );
    const uploadedPhoto = { id: data.id, url: data.photo.thumb };
    yield put(
      change('createAndUpdateInternalCompanyForm', 'photo', uploadedPhoto),
    );
    yield put({ type: EDIT_COMPANY_PHOTO_SUCCESS });
    if (callback) yield put(callback);
  } catch (e) {
    yield put({ type: EDIT_COMPANY_PHOTO_FAIL, payload: e });
  }
}

function* deleteCompanyPhotoSaga({
  payload: { photoId, isCompanyDelete, callback },
}: ReturnType<typeof deleteInternalCompanyFilesAction>) {
  try {
    if (!isCompanyDelete)
      yield call(() => api.delete(`/v1/internal_companies/${photoId}/photo`));
    if (isCompanyDelete)
      yield call(() => api.delete(`/v1/internal_companies/photos/${photoId}`));
    yield put({ type: DELETE_COMPANY_PHOTO_SUCCESS, payload: photoId });
    if (callback) callback();
  } catch (e) {
    yield put({ type: DELETE_COMPANY_PHOTO_FAIL, payload: e });
  }
}

function* createInternalCompany({
  payload: { name, photoId, callback },
}: ReturnType<typeof createInternalCompanyAction>) {
  try {
    const { data } = yield call(() =>
      api.post('v1/internal_companies', {
        internal_company: {
          name,
          ...(photoId && { existing_photo_id: photoId }),
        },
      }),
    );
    if (data) yield put({ type: CREATE_INTERNAL_COMPANY_SUCCESS });
    if (callback) callback();
  } catch (e) {
    yield put({ type: CREATE_INTERNAL_COMPANY_FAIL, payload: e });
  }
}

function* getInternalCompaniesSaga() {
  try {
    const { data } = yield call(() => api.get('v1/internal_companies'));
    if (data)
      yield put({
        type: GET_INTERNAL_COMPANIES_SUCCESS,
        payload: data.internal_companies,
      });
  } catch (e) {
    yield put({ type: GET_INTERNAL_COMPANIES_FAIL, payload: e });
  }
}

function* deleteInternalCompanySaga({
  payload: { companyId },
}: ReturnType<typeof deleteInternalCompanyAction>) {
  try {
    yield call(() => api.delete(`v1/internal_companies/${companyId}`));
    yield put({
      type: DELETE_INTERNAL_COMPANY_SUCCESS,
      payload: { companyId },
    });
  } catch (e) {
    yield put({ type: DELETE_INTERNAL_COMPANY_FAIL, payload: e });
  }
}

function* editInternalCompany({
  payload: { companyId, name, callback },
}: ReturnType<typeof editInternalCompanyAction>) {
  try {
    const { data } = yield call(() =>
      api.patch(`v1/internal_companies/${companyId}`, {
        internal_company: {
          name,
        },
      }),
    );
    if (data) yield put({ type: EDIT_INTERNAL_COMPANY_SUCCESS });
    if (callback) callback();
  } catch (e) {
    yield put({ type: EDIT_INTERNAL_COMPANY_FAIL, payload: e });
  }
}

export default function* internalCompaniesSaga() {
  yield takeLatest(UPLOAD_COMPANY_PHOTO, uploadCompanyPhotoSaga);
  yield takeLatest(DELETE_COMPANY_PHOTO, deleteCompanyPhotoSaga);
  yield takeLatest(CREATE_INTERNAL_COMPANY, createInternalCompany);
  yield takeLatest(GET_INTERNAL_COMPANIES, getInternalCompaniesSaga);
  yield takeLatest(DELETE_INTERNAL_COMPANY, deleteInternalCompanySaga);
  yield takeLatest(EDIT_INTERNAL_COMPANY, editInternalCompany);
  yield takeLatest(EDIT_COMPANY_PHOTO, editCompanyPhotoSaga);
}
