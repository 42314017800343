import { call, put, takeLatest } from 'redux-saga/effects';

import api from '../../../../utils/requests';
import {
  deleteLeadFileAction,
  uploadLeadFilesAction,
  uploadLeadIdFilesAction,
} from './leadsActions';
import {
  DELETE_LEAD_FILE,
  DELETE_LEAD_FILE_FAIL,
  DELETE_LEAD_FILE_SUCCESS,
  UPLOAD_LEAD_FILES,
  UPLOAD_LEAD_FILES_FAIL,
  UPLOAD_LEAD_FILES_SUCCESS,
  UPLOAD_LEAD_ID_FILES_SUCCESS,
  UPLOAD_LEAD_ID_FILES_FAIL,
  UPLOAD_LEAD_ID_FILES,
} from './leadsTypes';

function* uploadLeadFileSaga({
  payload: { files },
}: ReturnType<typeof uploadLeadFilesAction>) {
  const url = `/v1/fleets/leads/files`;

  try {
    const formData = new FormData();

    formData.append(`fleets_leads_file[file]`, files);
    const { data } = yield call(() =>
      api.post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    );
    if (data) {
      yield put({
        type: UPLOAD_LEAD_FILES_SUCCESS,
        payload: { file: data.fleets_leads_file },
      });
    }
  } catch (e) {
    yield put({ type: UPLOAD_LEAD_FILES_FAIL, payload: e });
  }
}

function* uploadLeadIdFileSaga({
  payload: { files, leadId },
}: ReturnType<typeof uploadLeadIdFilesAction>) {
  const url = `/v1/fleets/leads/${leadId}/files`;
  for (const fileObj of files) {
    const { file, category_id } = fileObj;
    yield call(function* foo() {
      try {
        const formData = new FormData();

        formData.append(`fleets_leads_file[file]`, file);
        formData.append(`fleets_leads_file[category_type]`, `${category_id}`);
        const { data } = yield call(() =>
          api.post(url, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          }),
        );
        if (data) {
          yield put({
            type: UPLOAD_LEAD_ID_FILES_SUCCESS,
            payload: { file: data.fleets_leads_file },
          });
        }
      } catch (e) {
        yield put({ type: UPLOAD_LEAD_ID_FILES_FAIL, payload: e });
      }
    });
  }
}

function* deleteLeadFileSaga({
  payload: { fileId },
}: ReturnType<typeof deleteLeadFileAction>) {
  const url = `/v1//fleets/leads/files/${fileId}`;
  try {
    yield call(() => api.delete(url));
    yield put({ type: DELETE_LEAD_FILE_SUCCESS, payload: fileId });
  } catch (e) {
    yield put({ type: DELETE_LEAD_FILE_FAIL, payload: e });
  }
}

export default function* leadSaga() {
  yield takeLatest(UPLOAD_LEAD_FILES, uploadLeadFileSaga);
  yield takeLatest(UPLOAD_LEAD_ID_FILES, uploadLeadIdFileSaga);
  yield takeLatest(DELETE_LEAD_FILE, deleteLeadFileSaga);
}
