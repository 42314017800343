import { createSelector } from 'reselect';

import { AppState } from 'redux/store';

import { CompanySettingsReduces } from './companySettingsTypes';

export const companySettingsSelector = (
  state: AppState,
): CompanySettingsReduces => state.companySettings;

export const companyFilesSelector = createSelector(
  companySettingsSelector,
  (state: CompanySettingsReduces) => state.files,
);
