import {
  CREATE_CHATROOM,
  GET_CHAT_PEOPLE,
  GET_CHATROOM,
  GET_CHATROOM_MESSAGES,
  GET_CHATROOMS,
  IForwardMessage,
  ISendMessage,
  ISetReactionMessage,
  ISetReplyMessage,
  MARK_READ_MESSAGES,
  SEND_MESSAGE,
  FORWARD_MESSAGE,
  SET_FORWARD_MESSAGE_DATA,
  SET_REACTION_MESSAGE,
  ISetForwardMessage,
  SET_REPLY_MESSAGE,
  UPDATE_FROM_SOCKET,
  UPLOAD_CHAT_FILES,
  DELETE_CHAT_FILES,
  SET_PIN_MESSAGE,
  ISetPinMessage,
  IDeletePinMessage,
  DELETE_PIN_MESSAGE,
  GET_MESSAGES_WITH_PIN,
  IGetMessagesWithPin,
  GET_CHAT_USER_PROFILE,
  IGetChatUserProfile,
  IChangeGroupChatName,
  CHANGE_GROUP_CHAT_NAME,
  DELETE_USER_FROM_GROUP_CHAT,
  IDeleteUserFromGroupChat,
  LEAVE_GROUP_CHAT,
  ILeaveGroupChat,
  ADD_USERS_FOR_CHAT_GROUP,
  IAddUsersForChatGroup,
  CHANGE_MAIN_CHAT_VIEW,
  IChangeMainChatView,
  IGetChatroom,
} from './chatTypes';

// CREATE CHATROOM
export const createChatRoomAction = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any,
  callback?: (id: number) => void,
) => ({
  type: CREATE_CHATROOM,
  params,
  callback,
});

// GET CHATROOM
export const getChatRoomAction = (payload: IGetChatroom['payload']) => ({
  type: GET_CHATROOM,
  payload,
});

// GET CHATROOM MESSAGES
export const getChatRoomMessagesAction = (
  id: number,
  page?: number,
  callback?: () => void,
) => ({
  type: GET_CHATROOM_MESSAGES,
  id,
  page,
  callback,
});

// SEND MESSAGE TO CHATROOM
export const sendMessageAction = (
  payload: ISendMessage['payload'],
): ISendMessage => ({
  payload,
  type: SEND_MESSAGE,
});

// GET CHATROOMS
export const getChatRoomsAction = (
  page?: number,
  query?: string,
  callback?: () => void,
) => ({
  type: GET_CHATROOMS,
  page,
  query,
  callback,
});

// TRACK READ MESSAGES
export const markReadMessagesAction = (
  ids: number[],
  chatRoomId: number,
  callback?: () => void,
) => ({
  type: MARK_READ_MESSAGES,
  ids,
  chatRoomId,
  callback,
});

// GET CHAT PEOPLE
export const getChatPeopleAction = (query?: string, callback?: () => void) => ({
  type: GET_CHAT_PEOPLE,
  query,
  callback,
});

// UPDATE SOCKET DATA
export const updateFromSocketAction = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any,
  callback?: () => void,
) => ({
  type: UPDATE_FROM_SOCKET,
  payload,
  callback,
});

// UPLOAD CHAT FILES
export const uploadChatFilesAction = (
  files: File[],
  callback?: () => void,
) => ({
  type: UPLOAD_CHAT_FILES,
  files,
  callback,
});

// UPLOAD CHAT FILES
export const deleteChatFilesAction = (
  ids: number[],
  callback?: () => void,
) => ({
  type: DELETE_CHAT_FILES,
  ids,
  callback,
});

// SET REPLY MESSAGE
export const setReplyMessageAction = (
  payload: ISetReplyMessage['payload'],
): ISetReplyMessage => ({ type: SET_REPLY_MESSAGE, payload });

// FORWARD MESSAGE
export const forwardMessageAction = (
  payload: IForwardMessage['payload'],
): IForwardMessage => ({ type: FORWARD_MESSAGE, payload });

export const setForwardMessageDataAction = (
  payload: ISetForwardMessage['payload'],
): ISetForwardMessage => ({ type: SET_FORWARD_MESSAGE_DATA, payload });

// SET REACTION
export const setReactionMessageAction = (
  payload: ISetReactionMessage['payload'],
): ISetReactionMessage => ({ type: SET_REACTION_MESSAGE, payload });

// SET PIN MESSAGE
export const setPinMessageAction = (payload: ISetPinMessage['payload']) => ({
  type: SET_PIN_MESSAGE,
  payload,
});

// DELETE PIN MESSAGE
export const deletePinMessageAction = (
  payload: IDeletePinMessage['payload'],
) => ({ type: DELETE_PIN_MESSAGE, payload });

//  GET MESSAGES WITH PIN MESSAGE
export const getMessagesWithPinAction = (
  payload: IGetMessagesWithPin['payload'],
) => ({ type: GET_MESSAGES_WITH_PIN, payload });

//  GET USER DETAILS
export const getChatUserProfileAction = (
  payload: IGetChatUserProfile['payload'],
) => ({ type: GET_CHAT_USER_PROFILE, payload });

export const addUsersForChatGroupAction = (
  payload: IAddUsersForChatGroup['payload'],
) => ({ type: ADD_USERS_FOR_CHAT_GROUP, payload });

//  DELETE USER FROM CHAT GROUP
export const deleteUserFromChatGroupAction = (
  payload: IDeleteUserFromGroupChat['payload'],
) => ({ type: DELETE_USER_FROM_GROUP_CHAT, payload });

//  GET USER DETAILS
export const changeChatGroupNameAction = (
  payload: IChangeGroupChatName['payload'],
) => ({ type: CHANGE_GROUP_CHAT_NAME, payload });

//  LEAVE_GROUP_CHAT
export const leaveGroupChatAction = (payload: ILeaveGroupChat['payload']) => ({
  type: LEAVE_GROUP_CHAT,
  payload,
});

//  CHANGE_MAIN_CHAT_VIEW
export const changeMainChatViewAction = (
  payload: IChangeMainChatView['payload'],
) => ({ type: CHANGE_MAIN_CHAT_VIEW, payload });
