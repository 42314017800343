import { FC } from 'react';

import { useSelector } from 'react-redux';

import { userThemeSelector } from 'redux/userSettings/userSettingsSelectors';

interface IOwnProps {
  className?: string;
}

const IconSettingsLarge: FC<IOwnProps> = ({ className }) => {
  const theme = useSelector(userThemeSelector);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.5 6L22.5 6M13.5 6C13.5 7.65685 12.1569 9 10.5 9C8.84315 9 7.5 7.65685 7.5 6M13.5 6C13.5 4.34315 12.1569 3 10.5 3C8.84315 3 7.5 4.34315 7.5 6M1.5 6H7.5M1.5 18H16.5M16.5 18C16.5 19.6569 17.8431 21 19.5 21C21.1569 21 22.5 19.6569 22.5 18C22.5 16.3431 21.1569 15 19.5 15C17.8431 15 16.5 16.3431 16.5 18Z"
        stroke={theme === 'dark' ? '#fff' : '#2D2F36'}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconSettingsLarge;
