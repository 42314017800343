import { stopSubmit, setSubmitSucceeded } from 'redux-form';
import { takeLatest, takeLeading, call, put } from 'redux-saga/effects';

import { GET_RESOURCES_AUTOCOMPLETE_DATA } from 'components/_common/FormElements/ResourcesAutocomplete/_redux/resourcesTypes';
import {
  changePasswordAction,
  forgotPasswordAction,
  loginAction,
  postRegisterCompanyFinish,
  postRegisterCompanyStart,
  refreshTokenAction,
  resetPasswordAction,
} from 'components/Auth/_redux/authActions';
import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  LOGIN,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  REFRESH_USER,
  REFRESH_USER_FAIL,
  REFRESH_USER_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  SET_ONLINE_SUCCESS,
  SET_ONLINE_FAIL,
  SET_ONLINE,
  SET_OFFLINE_SUCCESS,
  SET_OFFLINE_FAIL,
  SET_OFFLINE,
  REFRESH_TOKEN,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAIL,
  REG_COMPANY,
  REG_COMPANY_SUCCESS,
  REG_COMPANY_FAIL,
  FULL_REG_COMPANY,
  NEED_REGISTRATION,
  IRegCompanyFail,
} from 'components/Auth/_redux/authTypes';
import { LOAD_SETTINGS } from 'redux/userSettings/userSettingsTypes';
import api from 'utils/requests';

type ErrorResponse = {
  error?: string;
  message?: string;
  errors?: string;
};

function* loginSaga({ payload }: ReturnType<typeof loginAction>) {
  try {
    const { data } = yield call(() =>
      api.post('/v1/employee/session', payload),
    );

    yield put({
      type: LOGIN_SUCCESS,
      payload: {
        token: data.data.token,
        email: data.data.employee.email,
        valid_at: data.data.valid_at,
      },
    });
    yield put({ type: LOAD_SETTINGS });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put(
        stopSubmit('loginForm', {
          _error: (e.response?.data as ErrorResponse)?.error,
        }),
      );
    yield put({ type: LOGIN_FAIL });
  }
}

function* refreshTokenSaga({ payload }: ReturnType<typeof refreshTokenAction>) {
  try {
    const { data } = yield call(() =>
      api.post('/v1/device_authentication/refresh_token', payload),
    );
    yield put({
      type: REFRESH_TOKEN_SUCCESS,
      payload: {
        token: data.token,
        valid_at: data.valid_at,
      },
    });
  } catch (e) {
    yield put({ type: REFRESH_TOKEN_FAIL });
  }
}

function* refreshUserSaga() {
  try {
    const { data } = yield call(() => api.get('/v1/employee/profile'));
    if (data.employee.company_registration_done) {
      yield put({
        type: REFRESH_USER_SUCCESS,
        payload: data.employee,
      });
      yield put({ type: GET_RESOURCES_AUTOCOMPLETE_DATA });
    } else {
      yield put({ type: NEED_REGISTRATION });
    }
  } catch (e) {
    if (api.isAxiosError(e)) {
      if (
        (e.message as ErrorResponse) !== 'Request failed with status code 403'
      )
        yield put({ type: REFRESH_USER_FAIL });
      else yield put({ type: NEED_REGISTRATION });
    }
  }
}

function* forgotPasswordSaga({
  payload,
}: ReturnType<typeof forgotPasswordAction>) {
  try {
    yield call(() => api.post('/v1/employee/password', { employee: payload }));
    yield put({ type: FORGOT_PASSWORD_SUCCESS });
    yield put(setSubmitSucceeded('forgotPasswordForm'));
  } catch (e) {
    yield put({ type: FORGOT_PASSWORD_FAIL });
    if (api.isAxiosError(e))
      yield put(
        stopSubmit('forgotPasswordForm', {
          email: (e.response?.data as ErrorResponse)?.message,
        }),
      );
  }
}

function* resetPasswordSaga({
  payload,
}: ReturnType<typeof resetPasswordAction>) {
  try {
    yield call(() => api.patch('/v1/employee/password', { employee: payload }));
    yield put({ type: RESET_PASSWORD_SUCCESS });
    yield put(setSubmitSucceeded('resetPasswordForm'));
  } catch (e) {
    yield put({ type: RESET_PASSWORD_FAIL });
    if (api.isAxiosError(e))
      yield put(
        stopSubmit('resetPasswordForm', {
          _error: (e.response?.data as ErrorResponse)?.error,
        }),
      );
  }
}

function* changePasswordSaga({
  payload,
}: ReturnType<typeof changePasswordAction>) {
  try {
    yield call(() =>
      api.patch('/v1/employee/profile/change_password', { ...payload }),
    );
    yield put({ type: CHANGE_PASSWORD_SUCCESS });
    yield put(setSubmitSucceeded('changePasswordForm'));
  } catch (e) {
    yield put({ type: CHANGE_PASSWORD_FAIL });
    if (api.isAxiosError(e))
      yield put(
        stopSubmit('changePasswordForm', {
          current_password: (e.response?.data as ErrorResponse)?.errors,
        }),
      );
  }
}

function* setOnlineSaga() {
  try {
    yield call(() => api.put('/v1/employee/online'));
    yield put({ type: SET_ONLINE_SUCCESS });
  } catch (error) {
    yield put({ type: SET_ONLINE_FAIL });
  }
}

function* setOfflineSaga() {
  try {
    yield call(() => api.delete('/v1/employee/online'));
    yield put({ type: SET_OFFLINE_SUCCESS });
  } catch (error) {
    yield put({ type: SET_OFFLINE_FAIL });
  }
}

function* regCompanySaga({
  payload,
}: ReturnType<typeof postRegisterCompanyStart>) {
  try {
    yield put({
      type: REG_COMPANY,
    });
    const { data } = yield call(() =>
      api.post('/v1/employees/registrations', { registration: payload }),
    );
    yield put({
      type: REG_COMPANY_SUCCESS,
      payload: data,
    });
  } catch (e) {
    if (api.isAxiosError(e)) {
      yield put<IRegCompanyFail>({
        type: REG_COMPANY_FAIL,
        payload: e,
      });
    }
  }
}

function* fullCompanyReg({
  payload,
}: ReturnType<typeof postRegisterCompanyFinish>) {
  try {
    yield call(() =>
      api.patch('/v1/employees/registrations', { company: payload }),
    );
    yield put({ type: REFRESH_USER });
    yield put({ type: LOAD_SETTINGS });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put(
        stopSubmit('signUpFullCompanyForm', {
          _error: (e.response?.data as ErrorResponse)?.error,
        }),
      );
  }
}

export default function* authSaga() {
  yield takeLeading(LOGIN, loginSaga);
  yield takeLatest(REFRESH_USER, refreshUserSaga);
  yield takeLeading(FORGOT_PASSWORD, forgotPasswordSaga);
  yield takeLeading(RESET_PASSWORD, resetPasswordSaga);
  yield takeLeading(CHANGE_PASSWORD, changePasswordSaga);
  yield takeLeading(SET_ONLINE, setOnlineSaga);
  yield takeLeading(SET_OFFLINE, setOfflineSaga);
  yield takeLeading(REFRESH_TOKEN, refreshTokenSaga);
  yield takeLeading(REG_COMPANY, regCompanySaga);
  yield takeLeading(FULL_REG_COMPANY, fullCompanyReg);
}
