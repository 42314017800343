import { lazy } from 'react';

import PrivateRoutes from '../PrivateRoutes/PrivateRoutes';

const Chat = lazy(() => import('components/Chat/Chat'));

/* Chat */
export const chatRoute = (
  <PrivateRoutes exact path="/chat" component={Chat} withPermission={false} />
);
