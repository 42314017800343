import { FC, KeyboardEvent, useCallback, useEffect, useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import clsx from 'clsx';
import { WrappedFieldMetaProps, WrappedFieldInputProps } from 'redux-form';

import useTestAttrRef, { getAttrName } from 'hooks/useTestAttrRef';
import useToggle from 'hooks/useToggle';

import styles from './Input.module.scss';

type OwnProps = {
  id: string;
  type: string;
  placeholder: string;
  className: string;
  readOnly: boolean;
  icon: string | JSX.Element;
  iconRight: string | JSX.Element;
  focusOnRender?: boolean;
  // TODO: mb rename 'checkbox' prop to some more common name like 'element' or etc
  checkbox: JSX.Element;
  disabled: boolean;
  dollarSign?: boolean;
  hideReadOnlyHint?: boolean;
  labelText?: string;
  dataTestPrefix?: string;
};

type Props = OwnProps & WrappedFieldInputProps & WrappedFieldMetaProps;

const Input: FC<Partial<Props>> = ({
  type = 'text',
  id,
  placeholder,
  className,
  readOnly,
  icon,
  iconRight,
  checkbox,
  disabled,
  error,
  touched,
  value,
  name,
  dollarSign,
  focusOnRender,
  hideReadOnlyHint,
  labelText,
  dataTestPrefix = '',
  onChange,
  onBlur,
}) => {
  const inputRef = useTestAttrRef<HTMLInputElement>(
    null,
    labelText &&
      getAttrName((dataTestPrefix ? `${dataTestPrefix}_` : '') + labelText),
  );
  const [disabledHint, setDisabledHint] = useState<boolean>(false);

  // clear read only label after 3s.
  useEffect(() => {
    if (disabledHint && hideReadOnlyHint === undefined) {
      setTimeout(() => setDisabledHint(false), 3000);
    }
  }, [disabledHint]);

  const { isOpen: showValue, toggleVisibility } = useToggle(
    type !== 'password',
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Enter':
        case 'Space':
          event.preventDefault();
          toggleVisibility();
          inputRef.current?.focus();
          break;
        default:
          break;
      }
    },
    [showValue, toggleVisibility],
  );

  const handleClickField = () => {
    if (disabled) {
      setDisabledHint(true);
    }
  };

  useEffect(
    () =>
      process.nextTick(() => {
        if (focusOnRender && inputRef?.current) {
          inputRef.current.focus();
        }
      }),
    [focusOnRender],
  );

  const PasswordIcon = showValue ? VisibilityOff : Visibility;

  return (
    <div
      className={clsx(styles.root, {
        [styles.rootDisabled]: disabled,
      })}
      onClick={handleClickField}
    >
      {icon && <i className={styles.icon}>{icon}</i>}
      {iconRight && <i className={styles.iconRight}>{iconRight}</i>}
      <input
        id={id || name}
        value={value}
        type={showValue ? 'text' : 'password'}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        className={clsx(styles.input, className, {
          [styles.hasError]: touched && !!error,
          [styles.withIcon]: icon,
          [styles.withIconRight]: iconRight,
          [styles.disabled]: disabled,
        })}
        readOnly={readOnly}
        disabled={disabled}
        autoComplete="none"
        ref={inputRef}
      />
      {dollarSign && (
        <span
          className={clsx(styles.sign, {
            [styles.signVisible]: value,
          })}
        >
          $
        </span>
      )}
      {type === 'password' && (
        <PasswordIcon
          fontSize="small"
          color="inherit"
          className={styles.passwordIcon}
          onClick={toggleVisibility}
          tabIndex={0}
          onKeyDown={handleKeyDown}
        />
      )}
      {checkbox && <div className={styles.checkbox}>{checkbox}</div>}
      {disabledHint && !hideReadOnlyHint && (
        <span className={styles.disabledHint}>Read only field</span>
      )}
    </div>
  );
};

export default Input;
