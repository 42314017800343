import { AxiosError, AxiosRequestConfig } from 'axios';

import { IPagination } from 'components/_common/Navigation/Pagination/_redux/paginationTypes';
import { ILogout } from 'redux/_common/models';
import { IWithPending, IWithLoaded, IWithError } from 'utils/models';

import { IBillingItem, IPatchBillingItem } from '../_models/billingModels';
import { IBrokerDeduction } from '../_models/brokerDeductionModels';
import { IExtraCharge } from '../_models/extraChargeModels';
import { ProofOfDeliveryPhoto } from '../_models/podModels';

interface IBillingItems {
  list: IBillingItem[];
  statistics?: {
    [key: string]: number;
  };
  pagination?: IPagination;
}

interface IGetOrderZip {
  id: number | null;
  status: string;
  file: string | null;
}

export interface IBillingItemsReducer
  extends IBillingItems,
    IWithPending,
    IWithLoaded,
    IWithError {}

export interface IAccountingBillingReducer {
  billingItems: IBillingItemsReducer;
}

// GET BILLING ITEMS
export const GET_BILLING_ITEMS = '[@@Accounting_Billing] GET_BILLING_ITEMS';
export const GET_BILLING_ITEMS_SUCCESS =
  '[@@Accounting_Billing] GET_BILLING_ITEMS_SUCCESS';
export const GET_BILLING_ITEMS_FAIL =
  '[@@Accounting_Billing] GET_BILLING_ITEMS_FAIL';

export interface IGetBillingItemsAction {
  type: typeof GET_BILLING_ITEMS;
  params?: AxiosRequestConfig['params'];
  query?: string;
}

export interface IGetBillingItemsActionSuccess {
  type: typeof GET_BILLING_ITEMS_SUCCESS;
  payload: IBillingItems;
}

export interface IGetBillingItemsActionFail {
  type: typeof GET_BILLING_ITEMS_FAIL;
  payload: AxiosError;
}

// PATCH BILLING ITEMS
export const PATCH_BILLING_ITEMS = '[@@Accounting_Billing] PATCH_BILLING_ITEMS';
export const PATCH_BILLING_ITEMS_SUCCESS =
  '[@@Accounting_Billing] PATCH_BILLING_ITEMS_SUCCESS';
export const PATCH_BILLING_ITEMS_FAIL =
  '[@@Accounting_Billing] PATCH_BILLING_ITEMS_FAIL';

export interface IPatchBillingItemsAction {
  type: typeof PATCH_BILLING_ITEMS;
  payload: {
    items: IPatchBillingItem[];
    callback?(items: IPatchBillingItem[]): void;
  };
}

export interface IPatchBillingItemsActionSuccess {
  type: typeof PATCH_BILLING_ITEMS_SUCCESS;
  items: IPatchBillingItem[];
}

export interface IPatchBillingItemsActionFail {
  type: typeof PATCH_BILLING_ITEMS_FAIL;
  payload: AxiosError;
}

// ADD EXTRA CHARGES
export const ADD_EXTRA_CHARGES = '[@@Accounting_Billing] ADD_EXTRA_CHARGES';
export const ADD_EXTRA_CHARGES_SUCCESS =
  '[@@Accounting_Billing] ADD_EXTRA_CHARGES_SUCCESS';
export const ADD_EXTRA_CHARGES_FAIL =
  '[@@Accounting_Billing] ADD_EXTRA_CHARGES_FAIL';

export interface IAddExtraChargesAction {
  type: typeof ADD_EXTRA_CHARGES;
  payload: {
    billingId: number;
    items: IExtraCharge[];
    callback?: (items: IExtraCharge[]) => void;
  };
}

export interface IAddExtraChargesActionSuccess {
  type: typeof ADD_EXTRA_CHARGES_SUCCESS;
  items: IExtraCharge[];
}

export interface IAddExtraChargesActionFail {
  type: typeof ADD_EXTRA_CHARGES_FAIL;
  payload: AxiosError;
}

// UPDATE EXTRA CHARGE
export const UPDATE_EXTRA_CHARGE = '[@@Accounting_Billing] UPDATE_EXTRA_CHARGE';
export const UPDATE_EXTRA_CHARGE_SUCCESS =
  '[@@Accounting_Billing] UPDATE_EXTRA_CHARGE_SUCCESS';
export const UPDATE_EXTRA_CHARGE_FAIL =
  '[@@Accounting_Billing] UPDATE_EXTRA_CHARGE_FAIL';

export interface IUpdateExtraChargeAction {
  type: typeof UPDATE_EXTRA_CHARGE;
  payload: {
    item: IExtraCharge;
    order_id: number;
    callback?: (item: IExtraCharge) => void;
  };
}

export interface IUpdateExtraChargeActionSuccess {
  type: typeof UPDATE_EXTRA_CHARGE_SUCCESS;
  payload: IExtraCharge;
}

export interface IUpdateExtraChargeActionFail {
  type: typeof UPDATE_EXTRA_CHARGE_FAIL;
  payload: AxiosError;
}

// DELETE EXTRA CHARGE
export const DELETE_EXTRA_CHARGE = '[@@Accounting_Billing] DELETE_EXTRA_CHARGE';
export const DELETE_EXTRA_CHARGE_SUCCESS =
  '[@@Accounting_Billing] DELETE_EXTRA_CHARGE_SUCCESS';
export const DELETE_EXTRA_CHARGE_FAIL =
  '[@@Accounting_Billing] DELETE_EXTRA_CHARGE_FAIL';

export interface IDeleteExtraChargeAction {
  type: typeof DELETE_EXTRA_CHARGE;
  payload: {
    item: IExtraCharge;
    order_id: number;
    callback?: (item: IExtraCharge) => void;
  };
}

export interface IDeleteExtraChargeActionSuccess {
  type: typeof DELETE_EXTRA_CHARGE_SUCCESS;
  payload: IExtraCharge;
}

export interface IDeleteExtraChargeActionFail {
  type: typeof DELETE_EXTRA_CHARGE_FAIL;
  payload: AxiosError;
}

// ADD PROOF OF DELIVERIES
export const ADD_PROOF_OF_DELIVERIES =
  '[@@Accounting_Billing] ADD_PROOF_OF_DELIVERIES';
export const ADD_PROOF_OF_DELIVERIES_SUCCESS =
  '[@@Accounting_Billing] ADD_PROOF_OF_DELIVERIES_SUCCESS';
export const ADD_PROOF_OF_DELIVERIES_FAIL =
  '[@@Accounting_Billing] ADD_PROOF_OF_DELIVERIES_FAIL';

export interface IAddProofOfDeliveriesAction {
  type: typeof ADD_PROOF_OF_DELIVERIES;
  payload: {
    photos: ProofOfDeliveryPhoto[];
    callback?: (photos: ProofOfDeliveryPhoto[]) => void;
  };
}

export interface IAddProofOfDeliveriesActionSuccess {
  type: typeof ADD_PROOF_OF_DELIVERIES_SUCCESS;
  photos: ProofOfDeliveryPhoto[];
}

export interface IAddProofOfDeliveriesActionFail {
  type: typeof ADD_PROOF_OF_DELIVERIES_FAIL;
  payload: AxiosError;
}

// DELETE PROOF OF DELIVERY
export const DELETE_PROOF_OF_DELIVERY =
  '[@@Accounting_Billing] DELETE_PROOF_OF_DELIVERY';
export const DELETE_PROOF_OF_DELIVERY_SUCCESS =
  '[@@Accounting_Billing] DELETE_PROOF_OF_DELIVERY_SUCCESS';
export const DELETE_PROOF_OF_DELIVERY_FAIL =
  '[@@Accounting_Billing] DELETE_PROOF_OF_DELIVERY_FAIL';

export interface IDeleteProofOfDeliveryAction {
  type: typeof DELETE_PROOF_OF_DELIVERY;
  payload: {
    photo: ProofOfDeliveryPhoto;
    callback?: (photo: ProofOfDeliveryPhoto) => void;
  };
}

export interface IDeleteProofOfDeliveryActionSuccess {
  type: typeof DELETE_PROOF_OF_DELIVERY_SUCCESS;
  payload: ProofOfDeliveryPhoto;
}

export interface IDeleteProofOfDeliveryActionFail {
  type: typeof DELETE_PROOF_OF_DELIVERY_FAIL;
  payload: AxiosError;
}

// CREATE NEW ORDER
export const CREATE_ORDER_ZIP = '[@@Accounting_Billing] CREATE_ORDER_ZIP';
export const CREATE_ORDER_ZIP_SUCCESS =
  '[@@Accounting_Billing] CREATE_ORDER_ZIP_SUCCESS';
export const CREATE_ORDER_ZIP_FAIL =
  '[@@Accounting_Billing] CREATE_ORDER_ZIP_FAIL';

export interface ICreateOrderZipAction {
  type: typeof CREATE_ORDER_ZIP;
  ids: number[];
  callback?: (id: number) => void;
}

export interface ICreateOrderZipActionSuccess {
  type: typeof CREATE_ORDER_ZIP_SUCCESS;
  id: number;
}

export interface ICreateOrderZipActionFail {
  type: typeof CREATE_ORDER_ZIP_FAIL;
  payload: AxiosError;
}

// GET ORDER ZIP
export const GET_ORDER_ZIP = '[@@Accounting_Billing] GET_ORDER_ZIP';
export const GET_ORDER_ZIP_SUCCESS =
  '[@@Accounting_Billing] GET_ORDER_ZIP_SUCCESS';
export const GET_ORDER_ZIP_FAIL = '[@@Accounting_Billing] GET_ORDER_ZIP_FAIL';

export interface IGetOrderZipAction {
  type: typeof GET_ORDER_ZIP;
  order_id: number;
  callback?: (data?: { file?: string }) => void;
}

export interface IGetOrderZipActionSuccess {
  type: typeof GET_ORDER_ZIP_SUCCESS;
  payload: IGetOrderZip;
}

export interface IGetOrderZipActionFail {
  type: typeof GET_ORDER_ZIP_FAIL;
  payload: AxiosError;
}

// regenerate bill
export const REGENERATE_BILL = '[@@Accounting_Billing] REGENERATE_BILL';
export const REGENERATE_BILL_SUCCESS =
  '[@@Accounting_Billing] REGENERATE_BILL_SUCCESS';
export const REGENERATE_BILL_FAIL =
  '[@@Accounting_Billing] REGENERATE_BILL_FAIL';

export interface IRegenerateBillAction {
  type: typeof REGENERATE_BILL;
  payload: {
    order_id: number;
    callback?: (payload: IRegenerateBillActionSuccess['payload']) => void;
  };
}

export interface IRegenerateBillActionSuccess {
  type: typeof REGENERATE_BILL_SUCCESS;
  payload: {
    order_id: number;
    invoice: string;
    invoice_date?: string;
    merge_pdf_link?: string;
  };
}

export interface IRegenerateBillActionFail {
  type: typeof REGENERATE_BILL_FAIL;
  payload: AxiosError;
}

// CREATE-UPDATE-DELETE BROKER DEDUCTION
export const BROKER_DEDUCTION_INVOKE =
  '[@@Accounting_Billing] BROKER_DEDUCTION_INVOKE';
export const CREATE_BROKER_DEDUCTION_SUCCESS =
  '[@@Accounting_Billing] CREATE_BROKER_DEDUCTION_SUCCESS';
export const UPDATE_BROKER_DEDUCTION_SUCCESS =
  '[@@Accounting_Billing] UPDATE_BROKER_DEDUCTION_SUCCESS';
export const DELETE_BROKER_DEDUCTION_SUCCESS =
  '[@@Accounting_Billing] DELETE_BROKER_DEDUCTION_SUCCESS';
export const BROKER_DEDUCTION_FAIL =
  '[@@Accounting_Billing] BROKER_DEDUCTION_FAIL';

export interface IBrokerDeductionAction {
  type: typeof BROKER_DEDUCTION_INVOKE;
  successType: IBrokerDeductionActionSuccess['type'];
  item: IBrokerDeduction;
  callback?: (item: IBrokerDeduction) => void;
}

export interface IBrokerDeductionActionSuccess {
  type:
    | typeof CREATE_BROKER_DEDUCTION_SUCCESS
    | typeof UPDATE_BROKER_DEDUCTION_SUCCESS
    | typeof DELETE_BROKER_DEDUCTION_SUCCESS;
  item: IBrokerDeduction;
}

export interface IBrokerDeductionActionFail {
  type: typeof BROKER_DEDUCTION_FAIL;
  payload: AxiosError;
}

// GET BILLING ITEMS AS EXCEL DOCUMENT
export const GET_BILLING_ITEMS_AS_EXCEL =
  '[@@Accounting_Billing] GET_BILLING_ITEMS_AS_EXCEL';
export const GET_BILLING_ITEMS_AS_EXCEL_SUCCESS =
  '[@@Accounting_Billing] GET_BILLING_ITEMS_AS_EXCEL_SUCCESS';
export const GET_BILLING_ITEMS_AS_EXCEL_FAIL =
  '[@@Accounting_Billing] GET_BILLING_ITEMS_AS_EXCEL_FAIL';

export interface IGetBillingsAsExcelAction {
  type: typeof GET_BILLING_ITEMS_AS_EXCEL;
  items: IBillingItem[];
  callback?: (file: File) => void;
}

export interface IGetBillingsAsExcelActionSuccess {
  type: typeof GET_BILLING_ITEMS_AS_EXCEL_SUCCESS;
  file: File;
}

export interface IGetBillingsAsExcelActionFail {
  type: typeof GET_BILLING_ITEMS_AS_EXCEL_FAIL;
  payload: AxiosError;
}

export type billingActionTypes =
  | IGetBillingItemsAction
  | IGetBillingItemsActionSuccess
  | IGetBillingItemsActionFail
  | ICreateOrderZipAction
  | ICreateOrderZipActionSuccess
  | ICreateOrderZipActionFail
  | IGetOrderZipAction
  | IGetOrderZipActionSuccess
  | IGetOrderZipActionFail
  | IAddExtraChargesAction
  | IAddExtraChargesActionSuccess
  | IAddExtraChargesActionFail
  | IUpdateExtraChargeAction
  | IUpdateExtraChargeActionSuccess
  | IUpdateExtraChargeActionFail
  | IDeleteExtraChargeAction
  | IDeleteExtraChargeActionSuccess
  | IDeleteExtraChargeActionFail
  | IAddProofOfDeliveriesAction
  | IAddProofOfDeliveriesActionSuccess
  | IAddProofOfDeliveriesActionFail
  | IDeleteProofOfDeliveryAction
  | IDeleteProofOfDeliveryActionSuccess
  | IDeleteProofOfDeliveryActionFail
  | IRegenerateBillAction
  | IRegenerateBillActionSuccess
  | IRegenerateBillActionFail
  | IBrokerDeductionAction
  | IBrokerDeductionActionSuccess
  | IBrokerDeductionActionFail
  | IGetBillingsAsExcelAction
  | IGetBillingsAsExcelActionSuccess
  | IGetBillingsAsExcelActionFail
  | IPatchBillingItemsActionSuccess
  | ILogout;
