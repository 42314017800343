import { createSelector } from 'reselect';

import { AppState } from 'redux/store';

import { IChatState } from './chatReducers';

export const chatSelector = (state: AppState): IChatState => state.chat;

export const unreadMessagesCountSelector = createSelector(
  chatSelector,
  (chat: IChatState): number => {
    const { rooms } = chat;
    const count = rooms?.reduce((acc, room) => acc + room.unread_number, 0);
    return count || 0;
  },
);
