/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';

import { ILogout } from 'redux/_common/models';

import {
  IChatFile,
  IChatMessage,
  IChatForwardMessage,
  IChatUser,
  IChatUserProfile,
  MainChatView,
  IChatRoom,
} from '../_models/chatMainEntities';

// CREATE CHATROOM
export const CREATE_CHATROOM = '[@@Chat] CREATE_CHATROOM';
export const CREATE_CHATROOM_SUCCESS = '[@@Chat] CREATE_CHATROOM_SUCCESS';
export const CREATE_CHATROOM_FAIL = '[@@Chat] CREATE_CHATROOM_FAIL';

export interface ICreateChatroom {
  type: typeof CREATE_CHATROOM;
  id: number;
}
export interface ICreateChatroomSuccess {
  type: typeof CREATE_CHATROOM_SUCCESS;
  payload: any;
}
export interface ICreateChatroomFail {
  type: typeof CREATE_CHATROOM_FAIL;
  payload: any;
}

// GET CHATROOMS
export const GET_CHATROOMS = '[@@Chat] GET_CHATROOMS';
export const GET_CHATROOMS_SUCCESS = '[@@Chat] GET_CHATROOMS_SUCCESS';
export const GET_CHATROOMS_FAIL = '[@@Chat] GET_CHATROOMS_FAIL';

export interface IGetChatrooms {
  type: typeof GET_CHATROOMS;
}

export interface IGetChatroomsSuccess {
  type: typeof GET_CHATROOMS_SUCCESS;
  payload: any;
}

export interface IGetChatroomsFail {
  type: typeof GET_CHATROOMS_FAIL;
  payload: any;
}

// GET CHATROOM
export const GET_CHATROOM = '[@@Chat] GET_CHATROOM';
export const GET_CHATROOM_SUCCESS = '[@@Chat] GET_CHATROOM_SUCCESS';
export const GET_CHATROOM_FAIL = '[@@Chat] GET_CHATROOM_FAIL';

export interface IGetChatroom {
  type: typeof GET_CHATROOM;
  payload: {
    data: { chatroomId: number; mainChatView?: MainChatView };
    callback?: () => void;
  };
}
export interface IGetChatroomSuccess {
  type: typeof GET_CHATROOM_SUCCESS;
  payload: { chatRoom: IChatRoom; mainChatView?: MainChatView };
}
export interface IGetChatroomFail {
  type: typeof GET_CHATROOM_FAIL;
  payload: AxiosError;
}

// GET CHATROOM MESSAGES
export const GET_CHATROOM_MESSAGES = '[@@Chat] GET_CHATROOM_MESSAGES';
export const GET_CHATROOM_MESSAGES_SUCCESS =
  '[@@Chat] GET_CHATROOM_MESSAGES_SUCCESS';
export const GET_CHATROOM_MESSAGES_FAIL = '[@@Chat] GET_CHATROOM_MESSAGES_FAIL';

export interface IGetChatroomMessages {
  type: typeof GET_CHATROOM_MESSAGES;
  id: number;
}

export interface IGetChatroomMessagesSuccess {
  type: typeof GET_CHATROOM_MESSAGES_SUCCESS;
  payload: any;
}

export interface IGetChatroomMessagesFail {
  type: typeof GET_CHATROOM_MESSAGES_FAIL;
  payload: any;
}

// SEND MESSAGE TO CHATROOM
export const SEND_MESSAGE = '[@@Chat] SEND_MESSAGE';
export const SEND_MESSAGE_SUCCESS = '[@@Chat] SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAIL = '[@@Chat] SEND_MESSAGE_FAIL';

export interface ISendMessage {
  type: typeof SEND_MESSAGE;
  payload: {
    id: number;
    text: string;
    parent_message_id?: string | number;
    parent_message_relation?: string;
    files?: IChatFile[];
    callback?: () => void;
  };
}

export interface ISendMessageSuccess {
  type: typeof SEND_MESSAGE_SUCCESS;
  payload: any;
}

export interface ISendMessageFail {
  type: typeof SEND_MESSAGE_FAIL;
  payload: any;
}

// MARK READ MESSAGES
export const MARK_READ_MESSAGES = '[@@Chat] MARK_READ_MESSAGES';
export const MARK_READ_MESSAGES_SUCCESS = '[@@Chat] MARK_READ_MESSAGES_SUCCESS';
export const MARK_READ_MESSAGES_FAIL = '[@@Chat] MARK_READ_MESSAGES_FAIL';

export interface IMarkReadMessages {
  type: typeof MARK_READ_MESSAGES;
  ids: number[];
}

export interface IMarkReadMessagesSuccess {
  type: typeof MARK_READ_MESSAGES_SUCCESS;
  payload: any;
}

export interface IMarkReadMessagesFail {
  type: typeof MARK_READ_MESSAGES_FAIL;
  payload: any;
}

// GET CHAT PEOPLE
export const GET_CHAT_PEOPLE = '[@@Chat] GET_CHAT_PEOPLE';
export const GET_CHAT_PEOPLE_SUCCESS = '[@@Chat] GET_CHAT_PEOPLE_SUCCESS';
export const GET_CHAT_PEOPLE_FAIL = '[@@Chat] GET_CHAT_PEOPLE_FAIL';

export interface IGetChatPeople {
  type: typeof GET_CHAT_PEOPLE;
  query: string;
}

export interface IGetChatPeopleSuccess {
  type: typeof GET_CHAT_PEOPLE_SUCCESS;
  payload: any;
}

export interface IGetChatPeopleFail {
  type: typeof GET_CHAT_PEOPLE_FAIL;
  payload: any;
}

// UPDATE FROM SOCKET
export const UPDATE_FROM_SOCKET = '[@@Chat] UPDATE_FROM_SOCKET';

export interface IUpdateFromSocket {
  type: typeof UPDATE_FROM_SOCKET;
  payload: any;
}

// UPLOAD CHAT FILES
export const UPLOAD_CHAT_FILES = '[@@Chat] UPLOAD_CHAT_FILES';
export const UPLOAD_CHAT_FILES_SUCCESS = '[@@Chat] UPLOAD_CHAT_FILES_SUCCESS';
export const UPLOAD_CHAT_FILES_FAIL = '[@@Chat] UPLOAD_CHAT_FILES_FAIL';

export interface IUploadChatFiles {
  type: typeof UPLOAD_CHAT_FILES;
  files: File[];
}

export interface IUploadChatFilesSuccess {
  type: typeof UPLOAD_CHAT_FILES_SUCCESS;
  payload: any;
}

export interface IUploadChatFilesFail {
  type: typeof UPLOAD_CHAT_FILES_FAIL;
  payload: any;
}

// DELETE CHAT FILES
export const DELETE_CHAT_FILES = '[@@Chat] DELETE_CHAT_FILES';
export const DELETE_CHAT_FILES_SUCCESS = '[@@Chat] DELETE_CHAT_FILES_SUCCESS';
export const DELETE_CHAT_FILES_FAIL = '[@@Chat] DELETE_CHAT_FILES_FAIL';

export interface IDeleteChatFiles {
  type: typeof DELETE_CHAT_FILES;
  ids: number[];
}

export interface IDeleteChatFilesSuccess {
  type: typeof DELETE_CHAT_FILES_SUCCESS;
  payload: any;
}

export interface IDeleteChatFilesFail {
  type: typeof DELETE_CHAT_FILES_FAIL;
  payload: any;
}

// FORWARD MESSAGE
export const FORWARD_MESSAGE = '[@@Chat] FORWARD_MESSAGE';
export interface IForwardMessage {
  type: typeof FORWARD_MESSAGE;
  payload: {
    forwardTo: number;
    messageId: number;
    callback?: () => void;
  };
}

// SET FORWARD MESSAGE
export const SET_FORWARD_MESSAGE_DATA = '[@@Chat] SET_FORWARD_MESSAGE_DATA';
export interface ISetForwardMessage {
  type: typeof SET_FORWARD_MESSAGE_DATA;
  payload: {
    data: IChatForwardMessage | null;
    config?: {};
    callback?: () => void;
  };
}

// SET REPLY MESSAGE
export const SET_REPLY_MESSAGE = '[@@Chat] SET_REPLY_MESSAGE';
export interface ISetReplyMessage {
  type: typeof SET_REPLY_MESSAGE;
  payload: {
    messageId: number | string | null;
  };
}

// SET REACTION MESSAGE
export const SET_REACTION_MESSAGE = '[@@Chat] SET_REACTION_MESSAGE';
export const SET_REACTION_MESSAGE_SUCCESS =
  '[@@Chat] SET_REACTION_MESSAGE_SUCCESS';
export const SET_REACTION_MESSAGE_FAIL = '[@@Chat] SET_REACTION_MESSAGE_FAIL';

export interface ISetReactionMessage {
  type: typeof SET_REACTION_MESSAGE;
  payload: {
    data: { id: number; chatroomId: number | string; type: string };
    callback?: () => void;
  };
}
export interface ISetReactionMessageSuccess {
  type: typeof SET_REACTION_MESSAGE_SUCCESS;
  payload: any;
}
export interface ISetReactionMessageFail {
  type: typeof SET_REACTION_MESSAGE_FAIL;
  payload: AxiosError;
}

// SET PIN MESSAGE
export const SET_PIN_MESSAGE = '[@@Chat] SET_PIN_MESSAGE';
export const SET_PIN_MESSAGE_SUCCESS = '[@@Chat] SET_PIN_MESSAGE_SUCCESS';
export const SET_PIN_MESSAGE_FAIL = '[@@Chat] SET_PIN_MESSAGE_FAIL';

export interface ISetPinMessage {
  type: typeof SET_PIN_MESSAGE;
  payload: {
    data: { id: number; chatroomId: number | string };
    callback?: () => void;
  };
}
export interface ISetPinMessageSuccess {
  type: typeof SET_PIN_MESSAGE_SUCCESS;
  payload: number;
}
export interface ISetPinMessageFail {
  type: typeof SET_PIN_MESSAGE_FAIL;
  payload: AxiosError;
}

// DELETE PIN MESSAGE
export const DELETE_PIN_MESSAGE = '[@@Chat] DELETE_PIN_MESSAGE';
export const DELETE_PIN_MESSAGE_SUCCESS = '[@@Chat] DELETE_PIN_MESSAGE_SUCCESS';
export const DELETE_PIN_MESSAGE_FAIL = '[@@Chat] DELETE_PIN_MESSAGE_FAIL';

export interface IDeletePinMessage {
  type: typeof DELETE_PIN_MESSAGE;
  payload: {
    data: { id: number; chatroomId: number | string };
    callback?: () => void;
  };
}
export interface IDeletePinMessageSuccess {
  type: typeof DELETE_PIN_MESSAGE_SUCCESS;
}
export interface IDeletePinMessageFail {
  type: typeof DELETE_PIN_MESSAGE_FAIL;
  payload: AxiosError;
}

// GET MESSAGES WITH PIN
export const GET_MESSAGES_WITH_PIN = '[@@Chat] GET_MESSAGES_WITH_PIN';
export const GET_MESSAGES_WITH_PIN_SUCCESS =
  '[@@Chat] GET_MESSAGES_WITH_PIN_SUCCESS';
export const GET_MESSAGES_WITH_PIN_FAIL = '[@@Chat] GET_MESSAGES_WITH_PIN_FAIL';

export interface IGetMessagesWithPin {
  type: typeof GET_MESSAGES_WITH_PIN;
  payload: {
    data: { id: number; chatroomId: number | string };
    callback?: () => void;
  };
}
export interface IGetMessagesWithPinSuccess {
  type: typeof GET_MESSAGES_WITH_PIN_SUCCESS;
  payload: IChatMessage[];
}
export interface IGetMessagesWithPinFail {
  type: typeof GET_MESSAGES_WITH_PIN_FAIL;
  payload: AxiosError;
}

// GET USER PROFILE
export const GET_CHAT_USER_PROFILE = '[@@Chat] GET_CHAT_USER_PROFILE';
export const GET_CHAT_USER_PROFILE_SUCCESS =
  '[@@Chat] GET_CHAT_USER_PROFILE_SUCCESS';
export const GET_CHAT_USER_PROFILE_FAIL = '[@@Chat] GET_CHAT_USER_PROFILE_FAIL';

export interface IGetChatUserProfile {
  type: typeof GET_CHAT_USER_PROFILE;
  payload: {
    data: { userId: number | string; chatroomId: number | string };
    callback?: () => void;
  };
}
export interface IGetChatUserProfileSuccess {
  type: typeof GET_CHAT_USER_PROFILE_SUCCESS;
  payload: IChatUserProfile;
}
export interface IGetChatUserProfileFail {
  type: typeof GET_CHAT_USER_PROFILE_FAIL;
  payload: AxiosError;
}

// ADD_USERS_FOR_CHAT_GROUP
export const ADD_USERS_FOR_CHAT_GROUP = '[@@Chat] ADD_USERS_FOR_CHAT_GROUP';
export const ADD_USERS_FOR_CHAT_GROUP_SUCCESS =
  '[@@Chat] ADD_USERS_FOR_CHAT_GROUP_SUCCESS';
export const ADD_USERS_FOR_CHAT_GROUP_FAIL =
  '[@@Chat] ADD_USERS_FOR_CHAT_GROUP_FAIL';

export interface IAddUsersForChatGroup {
  type: typeof ADD_USERS_FOR_CHAT_GROUP;
  payload: {
    data: { chatroomId: number; usersIds: string[] };
    callback?: () => void;
  };
}
export interface IAddUsersForChatGroupSuccess {
  type: typeof ADD_USERS_FOR_CHAT_GROUP_SUCCESS;
  payload: IChatUser;
}
export interface IAddUsersForChatGroupFail {
  type: typeof ADD_USERS_FOR_CHAT_GROUP_FAIL;
  payload: AxiosError;
}

// DELETE_USER_FROM_CHAT_GROUP
export const DELETE_USER_FROM_GROUP_CHAT =
  '[@@Chat] DELETE_USER_FROM_GROUP_CHAT';
export const DELETE_USER_FROM_GROUP_CHAT_SUCCESS =
  '[@@Chat] DELETE_USER_FROM_GROUP_CHAT_SUCCESS';
export const DELETE_USER_FROM_GROUP_CHAT_FAIL =
  '[@@Chat] DELETE_USER_FROM_GROUP_CHAT_FAIL';

export interface IDeleteUserFromGroupChat {
  type: typeof DELETE_USER_FROM_GROUP_CHAT;
  payload: {
    data: { chatUserId: number | string; chatroomId: number | string };
    callback?: () => void;
  };
}
export interface IDeleteUserFromGroupChatSuccess {
  type: typeof DELETE_USER_FROM_GROUP_CHAT_SUCCESS;
  payload: { chatroomId: string | number; chatUserId: string | number };
}
export interface IDeleteUserFromGroupChatFail {
  type: typeof DELETE_USER_FROM_GROUP_CHAT_FAIL;
  payload: AxiosError;
}

// CHANGE GROUP CHAT NAME
export const CHANGE_GROUP_CHAT_NAME = '[@@Chat] CHANGE_GROUP_CHAT_NAME';
export const CHANGE_GROUP_CHAT_NAME_SUCCESS =
  '[@@Chat] CHANGE_GROUP_CHAT_NAME_SUCCESS';
export const CHANGE_GROUP_CHAT_NAME_FAIL =
  '[@@Chat] CHANGE_GROUP_CHAT_NAME_FAIL';

export interface IChangeGroupChatName {
  type: typeof CHANGE_GROUP_CHAT_NAME;
  payload: {
    data: { groupName: string; chatroomId: number | string };
    callback?: () => void;
  };
}
export interface IChangeGroupChatNameSuccess {
  type: typeof CHANGE_GROUP_CHAT_NAME_SUCCESS;
  payload: { groupName: string; chatroomId: string | number };
}
export interface IChangeGroupChatNameFail {
  type: typeof CHANGE_GROUP_CHAT_NAME_FAIL;
  payload: AxiosError;
}

// LEAVE_GROUP_CHAT
export const LEAVE_GROUP_CHAT = '[@@Chat] LEAVE_GROUP_CHAT';
export const LEAVE_GROUP_CHAT_SUCCESS = '[@@Chat] LEAVE_GROUP_CHAT_SUCCESS';
export const LEAVE_GROUP_CHAT_FAIL = '[@@Chat] LEAVE_GROUP_CHAT_FAIL';

export interface ILeaveGroupChat {
  type: typeof LEAVE_GROUP_CHAT;
  payload: {
    data: { chatroomId: number };
    callback?: () => void;
  };
}
export interface ILeaveGroupChatSuccess {
  type: typeof LEAVE_GROUP_CHAT_SUCCESS;
  payload: { chatroomId: number };
}
export interface ILeaveGroupChatFail {
  type: typeof LEAVE_GROUP_CHAT_FAIL;
  payload: AxiosError;
}

// CHANGE_MAIN_CHAT_VIEW
export const CHANGE_MAIN_CHAT_VIEW = '[@@Chat] CHANGE_MAIN_CHAT_VIEW';
export interface IChangeMainChatView {
  type: typeof CHANGE_MAIN_CHAT_VIEW;
  payload: { data: { mainChatView: MainChatView }; callback?: () => void };
}

export type chatActionsType =
  | IForwardMessage
  | ISetForwardMessage
  | ISetReplyMessage
  | ISetReactionMessage
  | ISetReactionMessageSuccess
  | ISetReactionMessageFail
  | IDeleteChatFiles
  | IDeleteChatFilesSuccess
  | IDeleteChatFilesFail
  | IUploadChatFiles
  | IUploadChatFilesSuccess
  | IUploadChatFilesFail
  | IUpdateFromSocket
  | IGetChatPeople
  | IGetChatPeopleSuccess
  | IGetChatPeopleFail
  | IMarkReadMessages
  | IMarkReadMessagesSuccess
  | IMarkReadMessagesFail
  | IGetChatroomMessages
  | IGetChatroomMessagesSuccess
  | IGetChatroomMessagesFail
  | IGetChatrooms
  | IGetChatroomsSuccess
  | IGetChatroomsFail
  | IGetChatroom
  | IGetChatroomSuccess
  | IGetChatroomFail
  | ISendMessage
  | ISendMessageSuccess
  | ISendMessageFail
  | ICreateChatroom
  | ICreateChatroomSuccess
  | ICreateChatroomFail
  | ISetPinMessage
  | ISetPinMessageSuccess
  | ISetPinMessageFail
  | IDeletePinMessage
  | IDeletePinMessageSuccess
  | IDeletePinMessageFail
  | IGetMessagesWithPin
  | IGetMessagesWithPinSuccess
  | IGetMessagesWithPinFail
  | IGetChatUserProfile
  | IGetChatUserProfileSuccess
  | IGetChatUserProfileFail
  | IChangeGroupChatName
  | IChangeGroupChatNameSuccess
  | IChangeGroupChatNameFail
  | IDeleteUserFromGroupChat
  | IDeleteUserFromGroupChatSuccess
  | IDeleteUserFromGroupChatFail
  | ILeaveGroupChat
  | ILeaveGroupChatSuccess
  | ILeaveGroupChatFail
  | IAddUsersForChatGroup
  | IAddUsersForChatGroupSuccess
  | IAddUsersForChatGroupFail
  | IChangeMainChatView
  | ILogout;
