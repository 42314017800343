import { FC } from 'react';

import { useSelector } from 'react-redux';

import { userThemeSelector } from 'redux/userSettings/userSettingsSelectors';

interface IOwnProps {
  className?: string;
  fillColor?: string;
  onClick?: () => void;
}

const IconPencil: FC<IOwnProps> = ({ className, fillColor, onClick }) => {
  const theme = useSelector(userThemeSelector);

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M2 14H1C1 14.5523 1.44772 15 2 15V14ZM5 14V15C5.26522 15 5.51957 14.8946 5.70711 14.7071L5 14ZM2 11L1.29289 10.2929C1.10536 10.4804 1 10.7348 1 11H2ZM11 2L11.7071 1.29289C11.3166 0.902369 10.6834 0.902369 10.2929 1.29289L11 2ZM14 5L14.7071 5.70711C15.0976 5.31658 15.0976 4.68342 14.7071 4.29289L14 5ZM2 15H5V13H2V15ZM3 14V11H1V14H3ZM2.70711 11.7071L11.7071 2.70711L10.2929 1.29289L1.29289 10.2929L2.70711 11.7071ZM10.2929 2.70711L13.2929 5.70711L14.7071 4.29289L11.7071 1.29289L10.2929 2.70711ZM13.2929 4.29289L4.29289 13.2929L5.70711 14.7071L14.7071 5.70711L13.2929 4.29289Z"
        fill={fillColor || (theme === 'dark' ? '#fff' : 'black')}
      />
    </svg>
  );
};

export default IconPencil;
