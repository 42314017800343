/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';

import { ISelectOption } from 'components/_common/FormElements/FormField/Select/_models/selectModels';
import { IPagination } from 'components/_common/Navigation/Pagination/_redux/paginationTypes';
import {
  IDeleteFileAction,
  IDeleteFileActionFail,
  IDeleteFileActionSuccess,
  IUploadFilesAction,
  IUploadFilesActionFail,
  IUploadFilesActionSuccess,
} from 'redux/_common/commonTypes';
import { ILogout } from 'redux/_common/models';
import { IWithPending, IWithLoaded, IWithError } from 'utils/models';

import { IEmployee } from '../_models/employeeModel';

export interface IEmployeesReducer
  extends IWithPending,
    IWithLoaded,
    IWithError {
  list: IEmployee[];
  recruiters: ISelectOption[];
  currentEmployee: IEmployee | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  files: any[];
  pagination: IPagination | null;
}

// GET EMPLOYEES
export const GET_EMPLOYEES = '[@@Employee] GET_EMPLOYEES';
export const GET_EMPLOYEES_SUCCESS = '[@@Employee] GET_EMPLOYEES_SUCCESS';
export const GET_EMPLOYEES_FAIL = '[@@Employee] GET_EMPLOYEES_FAIL';

export interface IGetEmployeesAction {
  type: typeof GET_EMPLOYEES;
  payload: {
    data: {
      query?: string;
      usePaginationFlag?: boolean;
      preventUpdatePagination?: boolean;
    };
  };
}
export interface IGetEmployeesActionSuccess {
  type: typeof GET_EMPLOYEES_SUCCESS;
  payload: {
    employees: IEmployee[];
    pagination: IPagination;
    preventUpdatePagination?: boolean;
  };
}

export interface IGetEmployeesActionFail {
  type: typeof GET_EMPLOYEES_FAIL;
  payload: AxiosError;
}

export const GET_RECRUITERS = '[@@Employee] GET_RECRUITERS';
export const GET_RECRUITERS_SUCCESS = '[@@Employee] GET_RECRUITERS_SUCCESS';
export const GET_RECRUITERS_FAIL = '[@@Employee] GET_RECRUITERS_FAIL';

export interface IGetRecruitersAction {
  type: typeof GET_RECRUITERS;
  payload: any[];
}

export interface IGetRecruitersActionSuccess {
  type: typeof GET_RECRUITERS_SUCCESS;
  payload: ISelectOption[];
}

export interface IGetRecruitersActionFail {
  type: typeof GET_RECRUITERS_FAIL;
  payload: AxiosError;
}

// GET EMPLOYEE
export const GET_EMPLOYEE = '[@@Employee] GET_EMPLOYEE';
export const GET_EMPLOYEE_SUCCESS = '[@@Employee] GET_EMPLOYEE_SUCCESS';
export const GET_EMPLOYEE_FAIL = '[@@Employee] GET_EMPLOYEE_FAIL';

export interface IGetEmployeeAction {
  type: typeof GET_EMPLOYEE;
}

export interface IGetEmployeeActionSuccess {
  type: typeof GET_EMPLOYEE_SUCCESS;
  payload: IEmployee;
}
export interface IGetEmployeeActionFail {
  type: typeof GET_EMPLOYEE_FAIL;
  payload: AxiosError;
}

// CREATE EMPLOYEE
export const CREATE_EMPLOYEE = '[@@Employee] CREATE_EMPLOYEE';
export const CREATE_EMPLOYEE_SUCCESS = '[@@Employee] CREATE_EMPLOYEE_SUCCESS';
export const CREATE_EMPLOYEE_FAIL = '[@@Employee] CREATE_EMPLOYEE_FAIL';

export interface ICreateEmployeeAction {
  type: typeof CREATE_EMPLOYEE;
}
export interface ICreateEmployeeActionSuccess {
  type: typeof CREATE_EMPLOYEE_SUCCESS;
  payload: IEmployee;
}
export interface ICreateEmployeeActionFail {
  type: typeof CREATE_EMPLOYEE_FAIL;
  payload: AxiosError;
}

// UPDATE EMPLOYEE
export const UPDATE_EMPLOYEE = '[@@Employee] UPDATE_EMPLOYEE';
export const UPDATE_EMPLOYEE_SUCCESS = '[@@Employee] UPDATE_EMPLOYEE_SUCCESS';
export const UPDATE_EMPLOYEE_FAIL = '[@@Employee] UPDATE_EMPLOYEE_FAIL';

export interface IUpdateEmployeeAction {
  type: typeof UPDATE_EMPLOYEE;
}
export interface IUpdateEmployeeActionSuccess {
  type: typeof UPDATE_EMPLOYEE_SUCCESS;
  payload: IEmployee;
}
export interface IUpdateEmployeeActionFail {
  type: typeof UPDATE_EMPLOYEE_FAIL;
  payload: AxiosError;
}

// DELETE EMPLOYEE
export const DELETE_EMPLOYEE = '[@@Employee] DELETE_EMPLOYEE';
export const DELETE_EMPLOYEE_SUCCESS = '[@@Employee] DELETE_EMPLOYEE_SUCCESS';
export const DELETE_EMPLOYEE_FAIL = '[@@Employee] DELETE_EMPLOYEE_FAIL';

export interface IDeleteEmployeeAction {
  type: typeof DELETE_EMPLOYEE;
}
export interface IDeleteEmployeeActionSuccess {
  type: typeof DELETE_EMPLOYEE_SUCCESS;
  payload: number;
}
export interface IDeleteEmployeeActionFail {
  type: typeof DELETE_EMPLOYEE_FAIL;
  payload: AxiosError;
}

// UPLOAD EMPLOYEE PHOTO
export const UPLOAD_EMPLOYEE_PHOTO = '[@@Employee] UPLOAD_EMPLOYEE_PHOTO';
export const UPLOAD_EMPLOYEE_PHOTO_SUCCESS =
  '[@@Employee] UPLOAD_EMPLOYEE_PHOTO_SUCCESS';
export const UPLOAD_EMPLOYEE_PHOTO_FAIL =
  '[@@Employee] UPLOAD_EMPLOYEE_FILES_FAIL';

export interface IUploadEmployeePhotoAction {
  type: typeof UPLOAD_EMPLOYEE_PHOTO;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}
export interface IUploadEmployeePhotoActionSuccess {
  type: typeof UPLOAD_EMPLOYEE_PHOTO_SUCCESS;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}
export interface IUploadEmployeePhotoActionFail {
  type: typeof UPLOAD_EMPLOYEE_PHOTO_FAIL;
  payload: AxiosError;
}

// DELETE EMPLOYEE PHOTO
export const DELETE_EMPLOYEE_PHOTO = '[@@Employee] DELETE_EMPLOYEE_PHOTO';
export const DELETE_EMPLOYEE_PHOTO_SUCCESS =
  '[@@Employee] DELETE_EMPLOYEE_PHOTO_SUCCESS';
export const DELETE_EMPLOYEE_PHOTO_FAIL =
  '[@@Employee] DELETE_EMPLOYEE_PHOTO_FAIL';

export interface IDeleteEmployeePhotoAction {
  type: typeof DELETE_EMPLOYEE_PHOTO;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}
export interface IDeleteEmployeePhotoActionSuccess {
  type: typeof DELETE_EMPLOYEE_PHOTO_SUCCESS;
  payload: string;
}
export interface IDeleteEmployeePhotoActionFail {
  type: typeof DELETE_EMPLOYEE_PHOTO_FAIL;
  payload: AxiosError;
}

// UPLOAD EMPLOYEE FILES
export const UPLOAD_EMPLOYEE_FILES = '[@@Employee] UPLOAD_EMPLOYEE_FILES';
export const UPLOAD_EMPLOYEE_FILES_SUCCESS =
  '[@@Employee] UPLOAD_EMPLOYEE_FILES_SUCCESS';
export const UPLOAD_EMPLOYEE_FILES_FAIL =
  '[@@Employee] UPLOAD_EMPLOYEE_FILES_FAIL';

export interface IUploadEmployeeFilesAction extends IUploadFilesAction {
  type: typeof UPLOAD_EMPLOYEE_FILES;
}
export interface IUploadEmployeeFilesActionSuccess
  extends IUploadFilesActionSuccess {
  type: typeof UPLOAD_EMPLOYEE_FILES_SUCCESS;
}
export interface IUploadEmployeeFilesActionFail extends IUploadFilesActionFail {
  type: typeof UPLOAD_EMPLOYEE_FILES_FAIL;
}

// DELETE EMPLOYEE FILE
export const DELETE_EMPLOYEE_FILE = '[@@Employee] DELETE_EMPLOYEE_FILE';
export const DELETE_EMPLOYEE_FILE_SUCCESS =
  '[@@Employee] DELETE_EMPLOYEE_FILE_SUCCESS';
export const DELETE_EMPLOYEE_FILE_FAIL =
  '[@@Employee] DELETE_EMPLOYEE_FILE_FAIL';

export interface IDeleteEmployeeFileAction extends IDeleteFileAction {
  type: typeof DELETE_EMPLOYEE_FILE;
}
export interface IDeleteEmployeeFileActionSuccess
  extends IDeleteFileActionSuccess {
  type: typeof DELETE_EMPLOYEE_FILE_SUCCESS;
}
export interface IDeleteEmployeeFileActionFail extends IDeleteFileActionFail {
  type: typeof DELETE_EMPLOYEE_FILE_FAIL;
}

// OTHER
export const UNMOUNT_EMPLOYEE_FORM = '[@@Employee] UNMOUNT_EMPLOYEE_FORM';

export interface IUnmountEmployeeFormAction {
  type: typeof UNMOUNT_EMPLOYEE_FORM;
}

export type employeesActionsType =
  | IGetEmployeesAction
  | IGetEmployeesActionSuccess
  | IGetEmployeesActionFail
  | IGetRecruitersAction
  | IGetRecruitersActionSuccess
  | IGetRecruitersActionFail
  | IGetEmployeeAction
  | IGetEmployeeActionSuccess
  | IGetEmployeeActionFail
  | ICreateEmployeeAction
  | ICreateEmployeeActionSuccess
  | ICreateEmployeeActionFail
  | IUpdateEmployeeAction
  | IUpdateEmployeeActionSuccess
  | IUpdateEmployeeActionFail
  | IDeleteEmployeeAction
  | IDeleteEmployeeActionSuccess
  | IDeleteEmployeeActionFail
  | IUploadEmployeeFilesAction
  | IUploadEmployeeFilesActionSuccess
  | IUploadEmployeeFilesActionFail
  | IDeleteEmployeeFileAction
  | IDeleteEmployeeFileActionSuccess
  | IDeleteEmployeeFileActionFail
  | IUploadEmployeePhotoAction
  | IUploadEmployeePhotoActionSuccess
  | IUploadEmployeePhotoActionFail
  | IDeleteEmployeePhotoAction
  | IDeleteEmployeePhotoActionSuccess
  | IDeleteEmployeePhotoActionFail
  | IUnmountEmployeeFormAction
  | ILogout;
