import { FC } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
  strokeColor?: string;
}

const IconCommentPin: FC<IOwnProps> = ({
  className,
  fillColor = '#2C6AF6',
  strokeColor = 'transparent',
}) => {
  return (
    <svg
      width="28"
      height="32"
      viewBox="0 0 28 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M28 13.7143C28 21.9509 19.0225 28.7218 15.4455 31.0927C14.5601 31.6795 13.4399 31.6795 12.5545 31.0927C8.9775 28.7218 0 21.9509 0 13.7143C0 9.92719 1.567 6.49862 4.1005 4.01682C6.63401 1.53502 10.134 0 14 0C17.866 0 21.366 1.53502 23.8995 4.01682C26.433 6.49862 28 9.92719 28 13.7143Z"
        fill={fillColor}
        stroke={strokeColor}
      />
    </svg>
  );
};

export default IconCommentPin;
