type Props = {
  className?: string;
};

const IconSuccessMark = ({ className }: Props) => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      opacity="0.1"
      d="M36 72C16.1489 72 0 55.8503 0 36C0 16.1497 16.1489 0 36 0C55.8511 0 72 16.1497 72 36C72 55.8503 55.8511 72 36 72Z"
      fill="#71C85A"
    />
    <path
      d="M32.6839 49L21 37L24.0516 33.8654L32.6839 42.7308L49.9484 25L53 28.1346L32.6839 49Z"
      fill="#71C85A"
    />
  </svg>
);

export default IconSuccessMark;
