import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { ClickAwayListener, Popper } from '@mui/material';
import clsx from 'clsx';
import { omit } from 'ramda';
import { useDispatch } from 'react-redux';
import {
  touch,
  WrappedFieldInputProps,
  WrappedFieldMetaProps,
} from 'redux-form';

import { IconArrowDown } from 'assets/icons/components';
import {
  ISelectOptions,
  ISelectOption,
} from 'components/_common/FormElements/FormField/Select/_models/selectModels';
import useTestAttrRef, { getAttrName } from 'hooks/useTestAttrRef';
import useToggle from 'hooks/useToggle';

import Option from './Option/Option';

import styles from './Select.module.scss';

type OwnProps = {
  options: ISelectOptions;
  placeholder: string;
  labelText: string;
  dataTestPrefix?: string;
  extraControl: JSX.Element;
  className: string;
  required: boolean;
  focusOnRender?: boolean;
  // sometimes other object property needs to be used to show it in select & options
  propertyToUse?: string;
  disabled?: boolean;
  autocomplete?: boolean;
  isPortalDisabled?: boolean;
  disableLabel?: boolean;
};

type Props = OwnProps & WrappedFieldInputProps & WrappedFieldMetaProps;

const Select: FC<Partial<Props>> = ({
  options = {},
  labelText,
  extraControl,
  className,
  placeholder = '',
  required,
  value,
  error,
  touched,
  focusOnRender,
  disabled = false,
  propertyToUse = 'name',
  onChange,
  form = '',
  name = '',
  dataTestPrefix,
  autocomplete = false,
  isPortalDisabled = true,
  disableLabel = false,
}) => {
  const dispatch = useDispatch();
  const [localValue, setLocalValue] = useState('');
  const [cursor, setCursor] = useState(0);
  const [currentOption, setCurrentOption] = useState({});
  const [focused, setFocused] = useState(false);

  const refNameString =
    (typeof labelText === 'string' &&
      getAttrName((dataTestPrefix ? `${dataTestPrefix}_` : '') + labelText)) ||
    (typeof name === 'string' && name) ||
    '';

  const { isOpen, open, toggleVisibility, close } = useToggle();
  const selectRef = useTestAttrRef<HTMLDivElement>(null, refNameString);

  const focusSelect = () => {
    if (selectRef?.current) {
      selectRef.current.focus();
    }
  };

  useEffect(() => {
    if (focusOnRender) {
      focusSelect();
    }
  }, [focusOnRender, selectRef?.current]);

  const [disabledHint, setDisabledHint] = useState<boolean>(false);

  // clear read only label after 3s.
  useEffect(() => {
    if (disabledHint) {
      setTimeout(() => setDisabledHint(false), 3000);
    }
  }, [disabledHint]);

  const handleFocus = () => setFocused(true);
  const handleBlur = () => {
    if (focused) {
      setFocused(false);
      dispatch(touch(form, name));
    }
  };

  const handleOnChange = (option: ISelectOption): void => {
    if (onChange) onChange(option.id || option.id === 0 ? option.id : null);
    setLocalValue(option.id || option.id === 0 ? option[propertyToUse] : '');
  };

  const handleClickField = () => {
    if (disabled) {
      setDisabledHint(true);
    } else if (!isOpen) {
      open();
    }
  };

  useEffect(() => {
    if (options) {
      setCurrentOption(options[Object.keys(options)[cursor]]);
    }
  }, [cursor]);

  useEffect(() => {
    if (options) {
      const selectedIndex = Object.values(options).findIndex(
        o => o[propertyToUse] === localValue,
      );
      setCursor(Math.max(0, selectedIndex));
    }
  }, [isOpen]);

  useEffect(() => {
    if (options) {
      const foundOption =
        value != null
          ? options[value] ||
            Object.values(options).find(({ id }) => id === value)
          : undefined;
      setLocalValue(foundOption?.[propertyToUse] || '');
    }
  }, [value, options]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const { key } = event;

    // if tab - don`t preventDefault
    if (key === 'Tab') {
      return;
    }

    event.preventDefault();

    if ((key === 'ArrowDown' || key === 'ArrowUp') && !isOpen) {
      open();
    }

    switch (key) {
      case 'ArrowUp':
        setCursor(cursor <= 0 ? Object.keys(options).length - 1 : cursor - 1);
        break;
      case 'ArrowDown':
        setCursor(cursor === Object.keys(options).length - 1 ? 0 : cursor + 1);
        break;
      case 'Enter':
        if (isOpen) {
          const rest = omit(
            ['optionComponent'],
            currentOption,
          ) as ISelectOption;
          handleOnChange(rest);
          close();
          focusSelect();
        }
        break;
      case 'Escape':
        toggleVisibility();
        focusSelect();
        break;
      default:
        if (autocomplete) {
          const optsArr = Object.values(options);
          const index = optsArr.findIndex(o =>
            o[propertyToUse].toLowerCase().startsWith(key.toLowerCase()),
          );
          if (index > -1) {
            const option = optsArr[index];
            setLocalValue(
              option.id || option.id === 0 ? option[propertyToUse] : '',
            );
            setCursor(index);
          }
          if (!isOpen) open();
        }
        break;
    }
  };

  const getChildren = useCallback(
    (): ReactNode =>
      options &&
      Object.entries(options).map(([key, option], i) => (
        <Option
          className={cursor === i ? styles.activeTitle : undefined}
          option={option}
          key={key}
          isSelected={option[propertyToUse] === localValue}
          isVisible={key !== 'undefined' || !!localValue}
          onSelectOption={handleOnChange}
          toggleVisibility={close}
          focus={cursor === i}
        />
      )),
    [options, close, cursor],
  );

  const CustomTag = useMemo(
    () => (disableLabel ? 'div' : 'label'),
    [disableLabel],
  );

  return (
    <CustomTag
      className={
        disableLabel
          ? styles.disabledLabel
          : clsx(styles.label, {
              [styles.activeTitle]: isOpen,
            })
      }
    >
      <span>
        {labelText} {required && <span className={styles.required}>*</span>}
      </span>
      {extraControl}
      <div
        role="listbox"
        tabIndex={disabled ? -1 : 0}
        className={clsx(styles.root, className, {
          [styles.activeBorder]: isOpen,
          [styles.hasError]: touched && !!error,
          [styles.disabled]: disabled,
        })}
        ref={selectRef}
        onClick={handleClickField}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onBlur={handleBlur}
        id={name}
      >
        {placeholder && !localValue && (
          <span className={styles.placeholder}>{placeholder}</span>
        )}
        {localValue && <span>{localValue}</span>}
        {disabledHint && (
          <span className={styles.disabledHint}>Read only field</span>
        )}
        {!disabled && (
          <>
            <IconArrowDown className={clsx({ [styles.rotate]: isOpen })} />
            <Popper
              anchorEl={selectRef.current}
              open={isOpen}
              style={{ zIndex: 100 }}
              disablePortal={isPortalDisabled}
            >
              <ClickAwayListener mouseEvent="onClick" onClickAway={close}>
                <div
                  className={styles.popperBody}
                  style={{ width: selectRef.current?.clientWidth }}
                >
                  <div className={styles.popperContent}>{getChildren()}</div>
                </div>
              </ClickAwayListener>
            </Popper>
          </>
        )}
      </div>
    </CustomTag>
  );
};

export default Select;

// TODO: implement!
const CreatableSelect: FC<Partial<Props>> = props => <Select {...props} />;
export { CreatableSelect };
