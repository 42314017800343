import { FC } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const IconSpeedometer: FC<IOwnProps> = ({
  className,
  fillColor = '#2D2F36',
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.5 12L6 7"
        stroke={fillColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8591 12C14.9505 11.515 15 11.014 15 10.5C15 6.358 11.8659 3 8 3C4.13413 3 1 6.358 1 10.5C1 11.014 1.04853 11.515 1.14093 12H14.8591Z"
        stroke={fillColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconSpeedometer;
