import { AxiosError } from 'axios';

import { ILogout } from 'redux/_common/models';

import {
  ForceCheckType,
  IInspection,
} from '../../_models/vehicleInspectionModels';

// DELETE_VEHICLE_INSPECTION
export const DELETE_VEHICLE_INSPECTION =
  '[@@VehicleInspection] DELETE_VEHICLE_INSPECTION';
export const DELETE_VEHICLE_INSPECTION_SUCCESS =
  '[@@VehicleInspection] DELETE_VEHICLE_INSPECTION_SUCCESS';
export const DELETE_VEHICLE_INSPECTION_FAIL =
  '[@@VehicleInspection] DELETE_VEHICLE_INSPECTION_FAIL';

export interface IDeleteVehicleInspectionAction {
  type: typeof DELETE_VEHICLE_INSPECTION;
  inspectionId: number;
  vehicleType: 'truck' | 'trailer';
  callback?: (inspectionId: number, vehicleType: 'truck' | 'trailer') => void;
  isTandem?: boolean;
}

export interface IDeleteVehicleInspectionActionSuccess {
  type: typeof DELETE_VEHICLE_INSPECTION_SUCCESS;
  inspectionId: number;
  vehicleType: 'truck' | 'trailer';
}

export interface IDeleteVehicleInspectionActionFail {
  type: typeof DELETE_VEHICLE_INSPECTION_FAIL;
  payload: AxiosError;
}

// UNLOCK_VEHICLE_INSPECTION
export const UNLOCK_VEHICLE_INSPECTION =
  '[@@VehicleInspection] UNLOCK_VEHICLE_INSPECTION';
export const UNLOCK_VEHICLE_INSPECTION_SUCCESS =
  '[@@VehicleInspection] UNLOCK_VEHICLE_INSPECTION_SUCCESS';
export const UNLOCK_VEHICLE_INSPECTION_FAIL =
  '[@@VehicleInspection] UNLOCK_VEHICLE_INSPECTION_FAIL';

export interface IUnlockVehicleInspectionAction {
  type: typeof UNLOCK_VEHICLE_INSPECTION;
  payload: {
    id: number;
    force_check_type?: ForceCheckType;
  };
  callback?: (inspection: IInspection) => void;
}

export interface IUnlockVehicleInspectionActionSuccess {
  type: typeof UNLOCK_VEHICLE_INSPECTION_SUCCESS;
  payload: IInspection;
}

export interface IUnlockVehicleInspectionActionFail {
  type: typeof UNLOCK_VEHICLE_INSPECTION_FAIL;
  payload: AxiosError;
}

// DELETE INSPECTION
export const DELETE_INSPECTION = '[@@VehicleInspection] DELETE_INSPECTION';

export interface IDeleteInspectionAction {
  type: typeof DELETE_INSPECTION;
  inspectionId: number;
  callback: () => void;
}

export const SET_IS_JUST_CREATED = '[@@VehicleInspection] SET_IS_JUST_CREATED';
export interface ISetIsJustCreatedInspection {
  type: typeof SET_IS_JUST_CREATED;
  payload: boolean;
}

export const CLEAR_INSPECTIONS_ERROR =
  '[@@VehicleInspection] CLEAR_INSPECTIONS_ERROR';
export interface IClearInspectionsError {
  type: typeof CLEAR_INSPECTIONS_ERROR;
}

export const HANDLE_SAVE_DRAFT = '[@@VehicleInspection] HANDLE_SAVE_DRAFT';
export const HANDLE_SAVE_DRAFT_SUCCESS =
  '[@@VehicleInspection] HANDLE_SAVE_DRAFT_SUCCESS';

export interface IHandleSaveInspectionDraft {
  type: typeof HANDLE_SAVE_DRAFT;
  payload?: number;
}

export interface IHandleSaveInspectionDraftSuccess {
  type: typeof HANDLE_SAVE_DRAFT_SUCCESS;
}

export const HANDLE_SET_INSPECTION_DRIVER_CHANGED =
  '[@@VehicleInspection] HANDLE_SET_INSPECTION_DRIVER_CHANGED';

export interface IHandleSetInspectionDriverChanged {
  type: typeof HANDLE_SET_INSPECTION_DRIVER_CHANGED;
  payload?: boolean;
}

export const SET_INSPECTION_CLAIMS_COUNT =
  '[@@VehicleInspection] SET_INSPECTION_CLAIMS_COUNT';

export interface ISetInspectionClaimsCount {
  type: typeof SET_INSPECTION_CLAIMS_COUNT;
  payload: number;
}

export type allTypes =
  | IDeleteVehicleInspectionAction
  | IDeleteVehicleInspectionActionSuccess
  | IDeleteVehicleInspectionActionFail
  | IUnlockVehicleInspectionAction
  | IUnlockVehicleInspectionActionSuccess
  | IUnlockVehicleInspectionActionFail
  | IDeleteInspectionAction
  | ISetIsJustCreatedInspection
  | IClearInspectionsError
  | IHandleSaveInspectionDraft
  | IHandleSaveInspectionDraftSuccess
  | IHandleSetInspectionDriverChanged
  | ISetInspectionClaimsCount
  | ILogout;
