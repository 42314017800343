import { FC } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const IconMessageSent: FC<IOwnProps> = ({
  className,
  fillColor = '#2C6AF6',
}) => {
  return (
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1 5L5 9L13 1"
        stroke={fillColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconMessageSent;
