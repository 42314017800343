import { FC } from 'react';

type OwnProps = {
  className?: string;
  fillColor?: string;
};

const IconDanger: FC<OwnProps> = ({ className, fillColor = '#fff' }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M20.7009 22H3.29953C1.79762 22 0.831603 20.4063 1.52641 19.0748L10.2282 2.39848C10.9761 0.965362 13.0269 0.965472 13.7746 2.39867L22.4741 19.075C23.1687 20.4065 22.2027 22 20.7009 22Z"
          stroke="white"
          strokeWidth="2"
        />
        <path
          d="M12 8V13"
          stroke={fillColor}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <g filter="url(#filter0_d)">
          <circle cx="12" cy="17" r="0.5" stroke={fillColor} />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="7"
          y="16"
          width="10"
          height="10"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <clipPath id="clip0">
          <rect width="24" height="24" fill={fillColor} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconDanger;
