// external
import { AxiosError } from 'axios';

// internal
import { IPagination } from 'components/_common/Navigation/Pagination/_redux/paginationTypes';
import { ILogout } from 'redux/_common/models';
import { IWithError, IWithPending } from 'utils/models';

import { ICategory, ICreateCategory } from '../_models/categoryModels';

export interface ICategoriesReducer extends IWithError, IWithPending {
  pagination: IPagination | null;
  list: ICategory[];
  fullList: ICategory[];
  currentCategory: ICategory | null;
  total: number;
}

// CREATE_CATEGORY
export const CREATE_CATEGORY = '[@@Categories] CREATE_CATEGORY';
export const CREATE_CATEGORY_SUCCESS = '[@@Categories] CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAIL = '[@@Categories] CREATE_CATEGORY_FAIL';
export interface ICreateCategoryAction {
  type: typeof CREATE_CATEGORY;
  payload: {
    data: { inventories_category: ICreateCategory };
    callback?: (res?: ICategory) => void;
  };
}
export interface ICreateCategoryActionSuccess {
  type: typeof CREATE_CATEGORY_SUCCESS;
  payload: ICategory;
}
export interface ICreateCategoryActionFail {
  type: typeof CREATE_CATEGORY_FAIL;
  payload: AxiosError;
}

// REMOVE CATEGORY
export const REMOVE_CATEGORY = '[@@Categories] REMOVE_CATEGORY';
export const REMOVE_CATEGORY_SUCCESS = '[@@Categories] REMOVE_CATEGORY_SUCCESS';
export const REMOVE_CATEGORY_FAIL = '[@@Categories] REMOVE_CATEGORY_FAIL';
export interface IRemoveCategoryAction {
  type: typeof REMOVE_CATEGORY;
  payload: {
    id: number;
    callback?: () => void;
  };
}
export interface IRemoveCategorySuccessAction {
  type: typeof REMOVE_CATEGORY_SUCCESS;
  payload: {
    id: number;
  };
}
export interface IRemoveCategoryFailAction {
  type: typeof REMOVE_CATEGORY_FAIL;
  payload: AxiosError;
}

// UPDATE_CATEGORY
export const UPDATE_CATEGORY = '[@@Categories] UPDATE_CATEGORY';
export const UPDATE_CATEGORY_SUCCESS = '[@@Categories] UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAIL = '[@@Categories] UPDATE_CATEGORY_FAIL';
export interface IUpdateCategoryAction {
  type: typeof UPDATE_CATEGORY;
  payload: {
    data: Partial<ICategory>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config?: any;
    callback?: () => void;
  };
}
export interface IUpdateCategoryActionSuccess {
  type: typeof UPDATE_CATEGORY_SUCCESS;
  payload: ICategory;
}
export interface IUpdateCategoryActionFail {
  type: typeof UPDATE_CATEGORY_FAIL;
  payload: AxiosError;
}

// GET_CATEGORIES
export const GET_CATEGORIES = '[@@Categories] GET_CATEGORIES';
export const GET_ALL_CATEGORIES = '[@@Categories] GET_ALL_CATEGORIES';
export const GET_ALL_CATEGORIES_SUCCESS =
  '[@@Categories] GET_ALL_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_SUCCESS = '[@@Categories] GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAIL = '[@@Categories] GET_CATEGORIES_FAIL';
export interface IGetCategoriesAction {
  type: typeof GET_CATEGORIES;
  payload: {
    data: { query?: string };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config?: any;
    callback?: () => void;
  };
}

export interface IGetAllCategoriesAction {
  type: typeof GET_ALL_CATEGORIES;
}
export interface IGetLCategoriesActionSuccess {
  type: typeof GET_CATEGORIES_SUCCESS;
  payload: { categories: ICategory[]; pagination: IPagination | null };
}

export interface IGetAllCategoriesActionSuccess {
  type: typeof GET_ALL_CATEGORIES_SUCCESS;
  payload: { categories: ICategory[] };
}

export interface IGetLCategoriesActionFail {
  type: typeof GET_CATEGORIES_FAIL;
  payload: AxiosError;
}

// GET_CATEGORY
export const GET_CATEGORY = '[@@Categories] GET_CATEGORY';
export const GET_CATEGORY_SUCCESS = '[@@Categories] GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAIL = '[@@Categories] GET_CATEGORY_FAIL';
export interface IGetCategoryAction {
  type: typeof GET_CATEGORY;
  payload: {
    data: { id: number };
    callback?: () => void;
  };
}
export interface IGetCategoryActionSuccess {
  type: typeof GET_CATEGORY_SUCCESS;
  payload: ICategory;
}
export interface IGetCategoryActionFail {
  type: typeof GET_CATEGORY_FAIL;
  payload: AxiosError;
}

// SESRCH CATEGORY
export const SEARCH_CATEGORY = '[@@Categories] SEARCH_CATEGORY';
export const SEARCH_CATEGORY_SUCCESS = '[@@Categories] SEARCH_CATEGORY_SUCCESS';
export const SEARCH_CATEGORY_FAIL = '[@@Categories] SEARCH_CATEGORY_FAIL';
export interface ISearchCategoriesAction {
  type: typeof SEARCH_CATEGORY;
}
export interface ISearchCategoriesSuccessAction {
  type: typeof SEARCH_CATEGORY_SUCCESS;
  payload: ICategory[];
}
export interface ISearchCategoriesFailAction {
  type: typeof SEARCH_CATEGORY_FAIL;
  payload: AxiosError;
}

// CLEAR CURRENT CATEGORY
export const CLEAR_CURRENT_CATEGORY = '[@@Categories] CLEAR_CURRENT_CATEGORY';
export interface IClearCurrentCategoryAction {
  type: typeof CLEAR_CURRENT_CATEGORY;
}
export type CategoriesTypes =
  | ICreateCategoryAction
  | ICreateCategoryActionSuccess
  | ICreateCategoryActionFail
  | IUpdateCategoryAction
  | IUpdateCategoryActionSuccess
  | IUpdateCategoryActionFail
  | IGetCategoriesAction
  | IGetAllCategoriesAction
  | IGetAllCategoriesActionSuccess
  | IGetLCategoriesActionSuccess
  | IGetLCategoriesActionFail
  | IGetCategoryAction
  | IGetCategoryActionSuccess
  | IGetCategoryActionFail
  | ISearchCategoriesAction
  | ISearchCategoriesSuccessAction
  | ISearchCategoriesFailAction
  | IClearCurrentCategoryAction
  | ILogout
  | IRemoveCategorySuccessAction
  | IRemoveCategoryAction
  | IRemoveCategoryFailAction;
