import en from './en.json';
import uk from './uk.json';

interface ITranslate {
  [key: string]: { [id: string]: string };
}

const languages: ITranslate = { en, uk };

export default languages;
