import { ISearchResult } from 'components/_common/SearchPanel/models';

import { ICategory } from '../_models/categoryModels';

// eslint-disable-next-line import/prefer-default-export
export const transformCategoriesSearchResult = (
  payload: ICategory[],
): ISearchResult[] => {
  return payload.map((item: ICategory) => ({
    id: item.id,
    title: item.name,
    additional: item.group + (item.tags && ` / ${item.tags}`),
    url: `/fleet/inventory/categories/${item.id}`,
    icon: (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="7" cy="7" r="7" fill={`var(${item.color})`} />
      </svg>
    ),
  }));
};
