import { takeEvery, call, put, takeLatest } from 'redux-saga/effects';

import api from 'utils/requests';

import { fileToFormData } from '../../../../utils/converters';
import {
  upcomingDriverFilesToFormData,
  upcomingDriverTestFilesToFormData,
} from '../_helpers/filesTransform';
import {
  deleteUpcomingDriverFilesAction,
  deleteUpcomingDriverFileSuccessAction,
  deleteUpcomingDriverTestFilesAction,
  deleteUpcomingDriverTestFileSuccessAction,
  uploadUpcomingDriverFilesAction,
  uploadUpcomingDriverFilesSuccessAction,
  uploadUpcomingDriverTestFilesAction,
  uploadUpcomingDriverTestFileSuccessAction,
  completeUpcomingDriverTestSuccessAction,
  completeUpcomingDriverTestAction,
  completeUpcomingDriverStepAction,
  completeUpcomingDriverStepSuccessAction,
  getUpcomingDriverAction,
  uploadProcessFileAction,
  uploadProcessFileSuccessAction,
  deleteUpcomingDriverFileAction,
} from './upcomingDriverActions';
import {
  DELETE_UPCOMING_DRIVER_STEP_FILE,
  DELETE_UPCOMING_DRIVER_TEST_FILE,
  DELETE_UPCOMING_DRIVER_TEST_FILE_FAIL,
  UPLOAD_UPCOMING_DRIVER_STEP_FILES,
  UPLOAD_UPCOMING_DRIVER_TEST_FILE_FAIL,
  UPLOAD_UPCOMING_DRIVER_TEST_FILES,
  COMPLETE_UPCOMING_DRIVER_TEST,
  COMPLETE_UPCOMING_DRIVER_TEST_FAIL,
  COMPLETE_UPCOMING_DRIVER_STEP,
  COMPLETE_UPCOMING_DRIVER_STEP_FAIL,
  GET_UPCOMING_DRIVER,
  GET_UPCOMING_DRIVER_SUCCESS,
  GET_UPCOMING_DRIVER_FAIL,
  UPLOAD_PROCESS_FILE_FAIL,
  UPLOAD_PROCESS_FILE,
  DELETE_PROCESS_FILE,
  DELETE_PROCESS_FILE_SUCCESS,
  DELETE_PROCESS_FILE_FAIL,
} from './upcomingDriverTypes';

function* uploadUpcomingDriverFilesSaga({
  payload,
}: ReturnType<typeof uploadUpcomingDriverFilesAction>) {
  const { files, stepId, driverId, callback } = payload;
  //   const { addressId, fileCategory: categoryType } = actionConfig || {};

  const url = `/v1/fleets/upcoming_drivers/${driverId}/onboardings/steps/${stepId}/files`;
  for (const fileObj of files) {
    try {
      // console.log('🚀 formValues', formValues);
      const { data } = yield call(() =>
        api.post(url, upcomingDriverFilesToFormData(fileObj), {
          headers: { 'Content-Type': 'multipart/form-data' },
        }),
      );

      yield put(
        uploadUpcomingDriverFilesSuccessAction(
          data.upcoming_drivers_onboardings_steps_file,
        ),
      );
      if (callback) callback(data.upcoming_drivers_onboardings_steps_file);
    } catch (e) {
      // if (api.isAxiosError(e))
      //   yield put({ type: UPLOAD_ORDER_ADDRESS_FILES_FAIL, payload: e });
    }
  }
}

function* uploadUpcomingDriverTestFilesSaga({
  payload,
}: ReturnType<typeof uploadUpcomingDriverTestFilesAction>) {
  const { files, actionConfig, callback } = payload;
  const url = `/v1/fleets/upcoming_drivers/onboardings/steps/${actionConfig.stepId}/tests/${actionConfig.testId}/files`;
  for (const fileObj of files) {
    try {
      const { data } = yield call(() =>
        api.post(url, upcomingDriverTestFilesToFormData(fileObj), {
          headers: { 'Content-Type': 'multipart/form-data' },
        }),
      );

      yield put(
        uploadUpcomingDriverTestFileSuccessAction(
          data.upcoming_drivers_onboardings_steps_tests_file,
          actionConfig.testId,
        ),
      );
      if (callback) callback();
    } catch (e) {
      if (api.isAxiosError(e))
        yield put({ type: UPLOAD_UPCOMING_DRIVER_TEST_FILE_FAIL, payload: e });
    }
  }
}

function* deleteUpcomingDriverTestFileSaga({
  payload,
}: ReturnType<typeof deleteUpcomingDriverTestFilesAction>) {
  const { actionConfig, fileId, callback } = payload;

  const url = `/v1/fleets/upcoming_drivers/onboardings/steps/tests/${actionConfig.testId}/files/${fileId}`;
  try {
    const { data } = yield call(() => api.delete(url));

    yield put(
      deleteUpcomingDriverTestFileSuccessAction({
        fileId,
        testId: actionConfig.testId,
      }),
    );
    if (callback) callback(data);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({ type: DELETE_UPCOMING_DRIVER_TEST_FILE_FAIL, payload: e });
  }
}

function* deleteUpcomingDriverFileSaga({
  payload,
}: ReturnType<typeof deleteUpcomingDriverFilesAction>) {
  const { fileId, itemId: stepId, actionConfig, callback } = payload;

  const url = `/v1/fleets/upcoming_drivers/onboardings/steps/${stepId}/files/${fileId}`;
  try {
    const { data } = yield call(() => api.delete(url));

    yield put(
      deleteUpcomingDriverFileSuccessAction({
        fileId: +fileId,
        fileType: actionConfig.fileType,
      }),
    );
    if (callback) callback(data);
  } catch (e) {
    // if (api.isAxiosError(e))
    //   yield put({ type: UPLOAD_ORDER_ADDRESS_FILES_FAIL, payload: e });
  }
}

function* completeUpcomingDriverTestSaga({
  payload,
}: ReturnType<typeof completeUpcomingDriverTestAction>) {
  const { actionType, testId, stepId } = payload;
  try {
    const { data } = yield call(() =>
      api.post(
        `/v1/fleets/upcoming_drivers/onboardings/steps/${stepId}/tests/${testId}/${actionType}`,
      ),
    );
    yield put(
      completeUpcomingDriverTestSuccessAction({
        completedTest: data.upcoming_drivers_onboardings_steps_test,
      }),
    );
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({ type: COMPLETE_UPCOMING_DRIVER_TEST_FAIL, payload: e });
  }
}

function* completeUpcomingDriverStepSaga({
  payload,
}: ReturnType<typeof completeUpcomingDriverStepAction>) {
  const { driverId, stepId, dataToSend, callback } = payload;
  try {
    yield call(() =>
      api.patch(
        `/v1/fleets/upcoming_drivers/${driverId}/onboardings/steps/${stepId}`,
        {
          upcoming_drivers_onboardings_step: dataToSend,
        },
      ),
    );
    yield put(completeUpcomingDriverStepSuccessAction());
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({ type: COMPLETE_UPCOMING_DRIVER_STEP_FAIL, payload: e });
  }
}

function* getUpcomingDriverSaga({
  id,
  dispatchToStore,
  callback,
}: ReturnType<typeof getUpcomingDriverAction>) {
  try {
    const { data } = yield call(() =>
      api.get(`/v1/fleets/leads/upcoming_drivers/${id}`),
    );
    if (dispatchToStore)
      yield put({
        type: GET_UPCOMING_DRIVER_SUCCESS,
        payload: data.upcoming_driver,
      });

    if (callback) callback(data.upcoming_driver);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({ type: GET_UPCOMING_DRIVER_FAIL, payload: e });
  }
}

function* uploadUpcomingProcessFileSaga({
  payload: { files, itemId },
}: ReturnType<typeof uploadProcessFileAction>) {
  try {
    const { data } = yield call(() =>
      api.patch(
        `v1/job_orientations/fields/${itemId}/`,
        fileToFormData(files[0].file, 'job_orientations_field[file]'),
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      ),
    );
    if (data && itemId) {
      yield put(
        uploadProcessFileSuccessAction({
          file: data.file,
          field_id: itemId as number,
        }),
      );
    }
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({ type: UPLOAD_PROCESS_FILE_FAIL, payload: e });
  }
}

function* deleteUpcomingProcessFileSaga({
  payload: { fileId, itemId },
}: ReturnType<typeof deleteUpcomingDriverFileAction>) {
  try {
    yield call(() => api.delete(`v1/job_orientations/fields/${itemId}/file`));
    yield put({ type: DELETE_PROCESS_FILE_SUCCESS, payload: fileId });
  } catch (e) {
    yield put({ type: DELETE_PROCESS_FILE_FAIL, payload: e });
  }
}

export default function* upcomingDriverSaga() {
  yield takeEvery(
    UPLOAD_UPCOMING_DRIVER_STEP_FILES,
    uploadUpcomingDriverFilesSaga,
  );
  yield takeEvery(
    DELETE_UPCOMING_DRIVER_STEP_FILE,
    deleteUpcomingDriverFileSaga,
  );
  yield takeEvery(
    UPLOAD_UPCOMING_DRIVER_TEST_FILES,
    uploadUpcomingDriverTestFilesSaga,
  );
  yield takeEvery(
    DELETE_UPCOMING_DRIVER_TEST_FILE,
    deleteUpcomingDriverTestFileSaga,
  );
  yield takeEvery(
    COMPLETE_UPCOMING_DRIVER_TEST,
    completeUpcomingDriverTestSaga,
  );
  yield takeEvery(
    COMPLETE_UPCOMING_DRIVER_STEP,
    completeUpcomingDriverStepSaga,
  );
  yield takeEvery(DELETE_PROCESS_FILE, deleteUpcomingProcessFileSaga);
  yield takeEvery(UPLOAD_PROCESS_FILE, uploadUpcomingProcessFileSaga);
  yield takeLatest(GET_UPCOMING_DRIVER, getUpcomingDriverSaga);
}
