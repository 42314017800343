export const DEFAULT_DATE_FORMAT_FOR_VIEW = 'MM/DD/YYYY';
export const SECONDARY_FORMAT_FOR_VIEW = 'DD MMM, YYYY';
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const SERVER_DATE_AND_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const DATE_FORMAT_FOR_VIEW = 'MMM DD, YYYY';
export const DATE_AND_TIME_FORMAT_FOR_VIEW = 'MMM DD, YYYY hh:mm a';
export const DEFAULT_TIME_FORMAT_LOWER_TIME = 'h:mm a';
export const DEFAULT_TIME_FORMAT_UPPER_TIME = 'h:mm A';
export const DEFAULT_TIME_FORMAT_24H = 'HH:mm';
export const DEFAULT_DATE_AND_TIME_FORMAT_LOWER_TIME = 'MMM DD, YYYY h:mm a';
export const DEFAULT_DATE_AND_TIME_FORMAT_UPPER_TIME = 'MMM DD, YYYY h:mm A';
export const SECONDARY_DATE_AND_TIME_FORMAT_LOWER_TIME = 'MM/DD/YYYY, hh:mm a';
export const SECONDARY_TIME_AND_DATE_FORMAT_LOWER_TIME = 'hh:mm a, MM/DD/YYYY';
export const SECONDARY_TIME_AND_DATE_FORMAT_LOWER_TIME_SMALL =
  'hh:mm a, MM/DD/YY';
export const SECONDARY_DATE_AND_TIME_FORMAT_UPPER_TIME = 'MM/DD/YYYY - hh:mm a';
export const DATE_AND_TIME_FORMAT_FOR_SEARCH = 'YYYY-MM-DD-HH-mm';
export const DATE_FORMAT_ITEMS = 'DD MMM, YYYY, h:mm a';
export const DATE_FORMAT_MONTH_DD = 'MMM DD';
export const DATE_FORMAT_WITH_DOTS = 'MM.DD.YYYY';
