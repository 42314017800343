import { AxiosError } from 'axios';

import { ProperList } from '../RolesSettings/PermissionTab/PermissionTab';
import { IRoleAndPermission } from './RolesAndPermissionsModels';

export const GET_ROLES_LIST = '[@@ROLES] GET_ROLES_LIST';
export const GET_ROLES_LIST_SUCCESS = '[@@ROLES] GET_ROLES_LIST_SUCCESS';
export const GET_ROLES_LIST_FAIL = '[@@ROLES] GET_ROLES_LIST_FAIL';

export interface IGetRolesList {
  type: typeof GET_ROLES_LIST;
}

export interface IGetRolesSuccess {
  type: typeof GET_ROLES_LIST_SUCCESS;
  payload: IRoleAndPermission[];
}
export interface IGetRolesFail {
  type: typeof GET_ROLES_LIST_FAIL;
  payload: AxiosError;
}

export const CREATE_ROLE = '[@@ROLES] CREATE_ROLE';
export const CREATE_ROLE_SUCCESS = '[@@ROLES] CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_FAIL = '[@@ROLES] CREATE_ROLE_FAIL';

export interface ICreateRole {
  type: typeof CREATE_ROLE;
  payload: {
    name: string;
    description: string;
    callback?: Function;
  };
}

export interface ICreateRoleSuccess {
  type: typeof CREATE_ROLE_SUCCESS;
  payload: IRoleAndPermission;
}
export interface ICreateRoleFail {
  type: typeof CREATE_ROLE_FAIL;
  payload: AxiosError;
}

export const EDIT_ROLE = '[@@ROLES] EDIT_ROLE';
export const EDIT_ROLE_SUCCESS = '[@@ROLES] EDIT_ROLE_SUCCESS';
export const EDIT_ROLE_FAIL = '[@@ROLES] EDIT_ROLE_FAIL';

export interface IEditRole {
  type: typeof EDIT_ROLE;
  payload: {
    id: string;
    name?: string;
    description?: string;
    companyId?: string;
    permissionType?: 'global' | 'oriented';
    permissions?: {} | null;
  };
  callback?: Function;
}

export interface IEditRoleSuccess {
  type: typeof EDIT_ROLE_SUCCESS;
  payload: IRoleAndPermission;
}
export interface IEditRoleFail {
  type: typeof EDIT_ROLE_FAIL;
  payload: AxiosError;
}

export const DELETE_ROLE = '[@@ROLES] DELETE_ROLE';
export const DELETE_ROLE_SUCCESS = '[@@ROLES] DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAIL = '[@@ROLES] DELETE_ROLE_FAIL';

export interface IDeleteRole {
  type: typeof DELETE_ROLE;
  payload: {
    id: number;
  };
}

export interface IDeleteRoleSuccess {
  type: typeof DELETE_ROLE_SUCCESS;
  payload: {
    id: number;
  };
}
export interface IDeleteRoleFail {
  type: typeof DELETE_ROLE_FAIL;
  payload: AxiosError;
}

export const GET_ROLE = '[@@ROLES] GET_ROLE';
export const GET_ROLE_SUCCESS = '[@@ROLES] GET_ROLE_SUCCESS';
export const GET_ROLE_FAIL = '[@@ROLES] GET_ROLE_FAIL';

export interface IGetRole {
  type: typeof GET_ROLE;
  payload: {
    id: number;
  };
}

export interface IGetRoleSuccess {
  type: typeof GET_ROLE_SUCCESS;
  payload: IRoleAndPermission;
}
export interface IGetRoleFail {
  type: typeof GET_ROLE_FAIL;
  payload: AxiosError;
}

export const SET_ACTIVE_PERMISSIONS = '[@@ROLES] SET_ACTIVE_PERMISSIONS';
export const SET_ACTIVE_PERMISSIONS_SUCCESS =
  '[@@ROLES] SET_ACTIVE_PERMISSIONS_SUCCESS';

export interface ISetActivePermissions {
  type: typeof SET_ACTIVE_PERMISSIONS;
  payload: {
    permissions: ProperList[];
  };
}
export interface ISetActivePermissionsSuccess {
  type: typeof SET_ACTIVE_PERMISSIONS_SUCCESS;
  payload: {
    permissions: ProperList[];
  };
}

export const SET_COMPANIES_TO_SET = '[@@ROLES] SET_COMPANIES_TO_SET';

export interface ISetCompaniesToSet {
  type: typeof SET_COMPANIES_TO_SET;
  payload: number[];
}

export type rolesAndPermissionsActionsType =
  | IGetRolesList
  | IGetRolesSuccess
  | IGetRolesFail
  | ICreateRole
  | ICreateRoleSuccess
  | ICreateRoleFail
  | IEditRole
  | IEditRoleSuccess
  | IEditRoleFail
  | IDeleteRole
  | IDeleteRoleSuccess
  | IDeleteRoleFail
  | IGetRole
  | IGetRoleSuccess
  | IGetRoleFail
  | ISetActivePermissions
  | ISetActivePermissionsSuccess
  | ISetCompaniesToSet;
