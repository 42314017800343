/* eslint-disable @typescript-eslint/no-unused-vars */
import { SocketMessage } from 'redux/socket/socketTypes';

export default class Socket {
  private onConnectionChanged: (isConnected: boolean) => void;

  private onIncomingMessage: (message: SocketMessage) => void;

  private onIncomingError: (event: Event) => void;

  private onConnectedCallbacks: Array<() => void>;

  private socket: WebSocket | undefined;

  private host: string;

  constructor(
    onConnectionChanged: (isConnected: boolean) => void,
    onIncomingMessage: (message: SocketMessage) => void,
    onIncomingError: (event: Event) => void,
  ) {
    this.onConnectionChanged = onConnectionChanged;
    this.onIncomingMessage = onIncomingMessage;
    this.onIncomingError = onIncomingError;
    this.onConnectedCallbacks = [];
    this.socket = undefined;
    this.host = '';
  }

  public connect = (
    token: string,
    onConnectedCallback: () => void,
  ): boolean => {
    const host = `${process.env.REACT_APP_WS_URL}?token=${token}`;
    if (this.host === host && this.socket) {
      // socket is already opened, just call callback
      onConnectedCallback();
      return false;
    }

    // socket is being opened. push callback to queue
    this.onConnectedCallbacks.push(onConnectedCallback);

    if (this.host === host) return false;

    this.disconnect();

    this.host = host; // mark that socket is being opened
    const socket = new WebSocket(host);

    // add listeners
    socket.onopen = () => {
      this.socket = socket;
      this.onConnectionChanged(true);

      // call all queued callbacks
      this.onConnectedCallbacks.forEach(callback => callback());

      // clear callbacks queue
      this.onConnectedCallbacks = [];
    };

    socket.onclose = () => {
      this.onConnectionChanged(false);
    };

    socket.onmessage = e => {
      this.onIncomingMessage(JSON.parse(e.data));
    };

    socket.onerror = e => {
      this.onIncomingError(e);
    };

    return true;
  };

  public sendMessage = (message: SocketMessage): boolean => {
    if (this.socket) this.socket.send(JSON.stringify(message));
    return !!this.socket;
  };

  public disconnect = (): boolean => {
    const result = !!this.socket;
    if (this.socket) this.socket.close();
    this.host = '';
    this.socket = undefined;
    return result;
  };
}
