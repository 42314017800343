import { MouseEvent } from 'react';

type Props = {
  className?: string;
  fillColor?: string;
  onClick?: (e: MouseEvent) => void;
  width?: number;
  height?: number;
  viewBox?: string;
  d?: string;
};

const IconClose = ({
  className,
  onClick,
  fillColor = '#86898E',
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  d = 'M18 6L6 18M17.9896 17.9896L6.01036 6.01039',
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
    data-test="close_icon"
  >
    <path d={d} stroke={fillColor} strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export default IconClose;
