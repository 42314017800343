// Fleet
export const PERMISSION_TRUCK = 'trucks#list';
export const PERMISSION_CREATE_TRUCK = 'trucks#create';
export const PERMISSION_EDIT_TRUCK = 'trucks#update';
export const PERMISSION_DELETE_TRUCK = 'trucks#delete';

export const PERMISSION_TRAILER = 'trailers#create';
export const PERMISSION_CREATE_TRAILER = 'trailers#create';
export const PERMISSION_EDIT_TRAILER = 'trailers#update';
export const PERMISSION_DELETE_TRAILER = 'trailers#delete';

export const PERMISSION_DASHBOARD = 'dashboard#view';

export const PERMISSION_VEHICLE = 'vehicles#list';
export const PERMISSION_CREATE_VEHICLE = 'vehicles#create';
export const PERMISSION_EDIT_VEHICLE = 'vehicles#update';

export const PERMISSION_GEOFENCE = 'geofences#list';
export const PERMISSION_CREATE_GEOFENCE = 'geofences#create';
export const PERMISSION_EDIT_GEOFENCE = 'geofences#update';
export const PERMISSION_DELETE_GEOFENCE = 'geofences#delete';

export const PERMISSION_TOLLS = 'toll#list';
export const PERMISSION_CREATE_TOLL = 'toll#create';
export const PERMISSION_EDIT_TOLL = 'toll#update';
export const PERMISSION_DELETE_TOLL = 'toll#delete';

export const PERMISSION_INSPECTION = 'inspections#list';
export const PERMISSION_CREATE_INSPECTION = 'inspections#create';
export const PERMISSION_EDIT_INSPECTION = 'inspections#update';
export const PERMISSION_DELETE_INSPECTION = 'inspections#delete';

export const PERMISSION_CLAIMS = 'claims#list';
export const PERMISSION_CREATE_CLAIM = 'claims#create';
export const PERMISSION_EDIT_CLAIM = 'claims#update';
export const PERMISSION_DELETE_CLAIM = 'claims#delete';

export const PERMISSION_ITEMS = 'items#list';
export const PERMISSION_CREATE_ITEM = 'items#create';
export const PERMISSION_EDIT_ITEM = 'items#update';
export const PERMISSION_DELETE_ITEM = 'items#delete';

export const PERMISSION_CATEGORY = 'category#list';
export const PERMISSION_CREATE_CATEGORY = 'category#create';
export const PERMISSION_EDIT_CATEGORY = 'category#update';
export const PERMISSION_DELETE_CATEGORY = 'category#delete';

export const PERMISSION_LOCATION = 'location#list';
export const PERMISSION_CREATE_LOCATION = 'location#create';
export const PERMISSION_EDIT_LOCATION = 'location#update';
export const PERMISSION_DELETE_LOCATION = 'location#delete';

export const PERMISSION_VENDOR = 'vendors#list';
export const PERMISSION_CREATE_VENDOR = 'vendors#create';
export const PERMISSION_EDIT_VENDOR = 'vendors#update';
export const PERMISSION_DELETE_VENDOR = 'vendors#delete';

export const PERMISSION_WORK_ORDER_LIST = 'work_orders#list';
export const PERMISSION_CREATE_WORK_ORDER = 'work_orders#create';
export const PERMISSION_EDIT_WORK_ORDER = 'work_orders#update';
export const PERMISSION_DELETE_WORK_ORDER = 'work_orders#delete';

// Safety
export const PERMISSION_ACCIDENT = 'accidents#list';
export const PERMISSION_CREATE_ACCIDENT = 'accidents#create';
export const PERMISSION_EDIT_ACCIDENT = 'accidents#update';
export const PERMISSION_DELETE_ACCIDENT = 'accidents#delete';

// Chat
export const PERMISSION_CHAT = 'chat#list';

// Dispatch
export const PERMISSION_FACILITIES = 'facility#list';
export const PERMISSION_CREATE_FACILITY = 'facility#create';
export const PERMISSION_EDIT_FACILITY = 'facility#update';
export const PERMISSION_DELETE_FACILITY = 'facility#delete';

export const PERMISSION_ORDER = 'orders#list';
export const PERMISSION_CREATE_ORDER = 'orders#create';
export const PERMISSION_EDIT_ORDER = 'orders#update';
export const PERMISSION_DELETE_ORDER = 'orders#delete';

export const PERMISSION_BROKERS = 'brokers#list';
export const PERMISSION_CREATE_BROKER = 'brokers#create';
export const PERMISSION_EDIT_BROKER = 'brokers#update';
export const PERMISSION_DELETE_BROKER = 'brokers#delete';

export const PERMISSION_CALENDAR = 'calendar#list';

export const PERMISSION_OVERVIEW = 'dispatch_overview#list';

// Accounting
export const PERMISSION_PAYROLL_WEEKLY = 'weekly_payrolls#list';
export const PERMISSION_CREATE_PAYROLL_WEEKLY = 'weekly_payrolls#create';
export const PERMISSION_EDIT_PAYROLL_WEEKLY = 'weekly_payrolls#update';
export const PERMISSION_DELETE_PAYROLL_WEEKLY = 'weekly_payrolls#delete';

export const PERMISSION_DRIVER_PAYROLL = 'drivers_payrolls#list';
export const PERMISSION_CREATE_DRIVER_PAYROLL = 'drivers_payrolls#create';
export const PERMISSION_EDIT_DRIVER_PAYROLL = 'drivers_payrolls#update';
export const PERMISSION_DELETE_DRIVER_PAYROLL = 'drivers_payrolls#delete';

export const PERMISSION_DEDUCTION = 'payrollItems_deductions#list';
export const PERMISSION_CREATE_DEDUCTION = 'payrollItems_deductions#create';
export const PERMISSION_EDIT_DEDUCTION = 'payrollItems_deductions#update';
export const PERMISSION_DELETE_DEDUCTION = 'payrollItems_deductions#delete';

export const PERMISSION_FUEL = 'payrollItems_fuel#list';

export const PERMISSION_CREDIT = 'payrollItems_credits#list';
export const PERMISSION_CREATE_CREDIT = 'payrollItems_credits#create';
export const PERMISSION_EDIT_CREDIT = 'payrollItems_credits#update';
export const PERMISSION_DELETE_CREDIT = 'payrollItems_credits#delete';

export const PERMISSION_BILLING = 'billing#list';

export const PERMISSION_REPORTS = 'reports_company#list';

// Settings
export const PERMISSION_FACTORY_COMPANIES = 'factory_companies#list';

export const PERMISSION_PAYROLL = 'payrolls#list';
export const PERMISSION_CREATE_PAYROLL = 'payrolls#create';
export const PERMISSION_EDIT_PAYROLL = 'payrolls#update';
export const PERMISSION_DELETE_PAYROLL = 'payrolls#delete';

export const PERMISSION_GPS_TRACKING = 'gps_tracking#list';
export const PERMISSION_CREATE_GPS_TRACKING = 'gps_tracking#create';
export const PERMISSION_EDIT_GPS_TRACKING = 'gps_tracking#update';
export const PERMISSION_DELETE_GPS_TRACKING = 'gps_tracking#delete';

export const PERMISSION_ORIENTATION_PROCESS = 'orientation_process#list';
export const PERMISSION_CREATE_ORIENTATION_PROCESS =
  'orientation_process#create';
export const PERMISSION_EDIT_ORIENTATION_PROCESS = 'orientation_process#update';
export const PERMISSION_DELETE_ORIENTATION_PROCESS =
  'orientation_process#delete';

export const PERMISSION_DOCUMENT_MAPPING = 'document_mapping#list';
export const PERMISSION_CREATE_DOCUMENT_MAPPING = 'document_mapping#create';
export const PERMISSION_EDIT_DOCUMENT_MAPPING = 'document_mapping#update';
export const PERMISSION_DELETE_DOCUMENT_MAPPING = 'document_mapping#delete';

export const PERMISSION_CONTRACT_TEMPLATES = 'contract_templates#list';
export const PERMISSION_CREATE_CONTRACT_TEMPLATE = 'contract_templates#create';
export const PERMISSION_EDIT_CONTRACT_TEMPLATE = 'contract_templates#update';
export const PERMISSION_DELETE_CONTRACT_TEMPLATE = 'contract_templates#delete';

export const PERMISSION_SCRIPT = 'scripts#list';
export const PERMISSION_CREATE_SCRIPT = 'scripts#create';
export const PERMISSION_EDIT_SCRIPT = 'scripts#update';
export const PERMISSION_DELETE_SCRIPT = 'scripts#delete';

export const PERMISSION_GARAGES_LIST = 'garages#list';
export const PERMISSION_CREATE_GARAGE = 'garages#create';
export const PERMISSION_EDIT_GARAGE = 'garages#update';
export const PERMISSION_DELETE_GARAGE = 'garages#delete';

export const PERMISSION_TECHNICIANS_LIST = 'technicians#list';
export const PERMISSION_CREATE_TECHNICIAN = 'technicians#create';
export const PERMISSION_EDIT_TECHNICIAN = 'technicians#update';
export const PERMISSION_DELETE_TECHNICIAN = 'technicians#delete';

// HR
export const PERMISSION_EMPLOYEE_LIST = 'employees#list';
export const PERMISSION_EMPLOYEE_CREATE = 'employees#create';
export const PERMISSION_EMPLOYEE_UPDATE = 'employees#update';
export const PERMISSION_EMPLOYEE_DELETE = 'employees#delete';

export const PERMISSION_DRIVER_LIST = 'drivers#list';
export const PERMISSION_DRIVER_CREATE = 'drivers#create';
export const PERMISSION_DRIVER_UPDATE = 'drivers#update';
export const PERMISSION_DRIVER_DELETE = 'drivers#delete';
export const PERMISSION_DRIVER_ARCHIVE = 'drivers#archive';

export const PERMISSION_LEAD_LIST = 'fleets_leads#list';
export const PERMISSION_LEAD_CREATE = 'fleets_leads#create';
export const PERMISSION_LEAD_UPDATE = 'fleets_leads#update';
export const PERMISSION_LEAD_REJECT = 'fleets_leads#reject';

export const PERMISSION_UPCOMING_DRIVERS_LIST = 'upcoming_drivers#list';

export const PERMISSION_ARCHIVE_DRIVER_UPDATE = 'drivers_archive#update';
export const PERMISSION_ARCHIVE_DRIVER_LIST = 'drivers_archive#list';
export const PERMISSION_HIRE_DRIVER = 'drivers_archive#hire';

// ADMIN PANEL
export const PERMISSION_ROLES_LIST = 'roles#list';
export const PERMISSION_ROLES_CREATE = 'roles#create';
export const PERMISSION_ROLES_UPDATE = 'roles#update';
export const PERMISSION_ROLES_DELETE = 'roles#delete';

export const PERMISSION_COMPANY_LIST = 'internal_companies#list';
export const PERMISSION_COMPANY_CREATE = 'internal_companies#create';
export const PERMISSION_COMPANY_UPDATE = 'internal_companies#update';
export const PERMISSION_COMPANY_DELETE = 'internal_companies#delete';

// TASK MANAGEMENT
export const PERMISSION_TASK_LIST = 'tasks#list';
export const PERMISSION_TASK_CREATE = 'tasks#create';
export const PERMISSION_TASK_UPDATE = 'tasks#update';
export const PERMISSION_TASK_DELETE = 'tasks#delete';
