import { FC } from 'react';

import { useSelector } from 'react-redux';

import { userThemeSelector } from 'redux/userSettings/userSettingsSelectors';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const IconRoute: FC<IOwnProps> = ({ className, fillColor = '#2C6AF6' }) => {
  const theme = useSelector(userThemeSelector);

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle
        cx="13"
        cy="15"
        r="2"
        transform="rotate(-180 13 15)"
        stroke={theme === 'dark' ? '#3179df' : fillColor}
        strokeWidth="2"
      />
      <circle
        cx="5"
        cy="3"
        r="2"
        transform="rotate(-180 5 3)"
        stroke={theme === 'dark' ? '#3179df' : fillColor}
        strokeWidth="2"
      />
      <path
        d="M11 2C10.4477 2 10 2.44772 10 3C10 3.55228 10.4477 4 11 4V2ZM7 16C7.55228 16 8 15.5523 8 15C8 14.4477 7.55228 14 7 14V16ZM11 4H13V2H11V4ZM13 8H5V10H13V8ZM5 16H7V14H5V16ZM1 12C1 14.2091 2.79086 16 5 16V14C3.89543 14 3 13.1046 3 12H1ZM5 8C2.79086 8 1 9.79086 1 12H3C3 10.8954 3.89543 10 5 10V8ZM15 6C15 7.10457 14.1046 8 13 8V10C15.2091 10 17 8.20914 17 6H15ZM13 4C14.1046 4 15 4.89543 15 6H17C17 3.79086 15.2091 2 13 2V4Z"
        fill={theme === 'dark' ? '#3179df' : fillColor}
      />
    </svg>
  );
};

export default IconRoute;
