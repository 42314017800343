import { TRUCKS_API_PREFIX } from 'constants/api';

export const getFileDownloadPath = (itemId: number, fileId: string) =>
  `${TRUCKS_API_PREFIX}/${itemId}/files/${fileId}`;

export const truckServiceFileToFormData = ({ file }: { file: File }) => {
  if (!file) return null;
  const formData = new FormData();
  formData.append(`planning_reviews_services_file[file]`, file);

  return formData;
};
