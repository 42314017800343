import { call, takeLatest } from 'redux-saga/effects';

import { FLEET_INSPECTIONS_API_PREFIX } from 'constants/api';
import api from 'utils/requests';

import { changeInspectionApproveStatus } from './tabInspectionsActions';
import { CHANGE_INSPECTION_APPROVE_STATUS } from './tabInspectionsTypes';

function* changeInspectionApproveSaga({
  payload: { approval_status, inspectionId, callback },
}: ReturnType<typeof changeInspectionApproveStatus>) {
  try {
    yield call(() =>
      api.patch(`${FLEET_INSPECTIONS_API_PREFIX}/${inspectionId}/set_status`, {
        fleets_inspection: { approval_status },
      }),
    );

    if (callback) callback();
  } catch (e) {
    console.error(e);
  }
}

export default function* tabInspectionsSaga() {
  yield takeLatest(
    CHANGE_INSPECTION_APPROVE_STATUS,
    changeInspectionApproveSaga,
  );
}
