import { FC } from 'react';

import { LocalizationProvider, DateTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import clsx from 'clsx';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import { IconCalendar } from 'assets/icons/components';

import { checkValidDate } from '../../../../../utils/validation';

import styles from '../CustomDatePicker/CustomDatePicker.module.scss';

type OwnProps = {
  className?: string;
  placeholder?: string;
  formatDate?: string;
  maxDate?: Date;
  minDate?: Date;
  disabled?: boolean;
  focusOnRender?: boolean;
  onClose?: () => void;
  onSelectValue?: (value: Date | null) => void;
};

type Props = WrappedFieldInputProps & WrappedFieldMetaProps & OwnProps;

const CustomDateAndTimePicker: FC<Partial<Props>> = ({
  placeholder,
  className,
  maxDate,
  minDate,
  name,
  onChange,
  value,
  onClose,
  onSelectValue,
  disabled,
  focusOnRender,
}) => {
  const handleDateChange = (date: Date | null) => {
    const realValue = checkValidDate(date) ? null : date;
    if (onChange) onChange(realValue);
    if (onSelectValue) onSelectValue(realValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        value={value}
        onChange={handleDateChange}
        components={{ OpenPickerIcon: IconCalendar }}
        OpenPickerButtonProps={{
          tabIndex: -1,
        }}
        minDate={minDate}
        maxDate={maxDate}
        onClose={onClose}
        disabled={disabled}
        InputProps={{
          className: clsx(styles.root, className),
          autoFocus: focusOnRender,
          name,
          disabled,
          placeholder,
        }}
        renderInput={inputProps => <TextField {...inputProps} fullWidth />}
      />
    </LocalizationProvider>
  );
};

export default CustomDateAndTimePicker;
