import { takeLatest, call, put, select, takeLeading } from 'redux-saga/effects';

import { setPaginationAction } from 'components/_common/Navigation/Pagination/_redux/paginationActions';
import { paginationSelector } from 'components/_common/Navigation/Pagination/_redux/paginationSelectors';
import { ErrorsUnitType } from 'components/Fleet/TabInspections/_common/InspectionInventory/_models/inspectionInventoryModels';
import { fileToFormData } from 'utils/converters';
import api from 'utils/requests';

import {
  addItemsAction,
  addItemsFailAction,
  addItemsSuccessAction,
  uploadItemPhotoAction,
  uploadItemPhotoSuccessAction,
  uploadItemPhotoFailAction,
  deleteItemPhotoAction,
  deleteItemPhotoSuccessAction,
  deleteItemPhotoFailAction,
  uploadItemInvoiceFileAction,
  uploadItemInvoiceFileSuccessAction,
  uploadItemInvoiceFileFailAction,
  getItemInvoicesAction,
  getItemInvoicesSuccessAction,
  getItemInvoicesFailAction,
  deleteItemInvoiceFileAction,
  deleteItemInvoiceFileSuccessAction,
  deleteItemInvoiceFileFailAction,
  getItemAction,
  getItemSuccessAction,
  getItemFailAction,
  addUnitsAction,
  addUnitsSuccessAction,
  addUnitsFailAction,
  changeUnitStatusAction,
  changeUnitStatusSuccessAction,
  changeUnitStatusFailAction,
  assignInventoryUnitAction,
  assignDriverUnitSuccessAction,
  assignDriverUnitFailAction,
  unassignUnitAction,
  unassignUnitSuccessAction,
  unassignUnitFailAction,
  updateItemsAction,
  updateItemsSuccessAction,
  updateItemsFailAction,
  getItemActivityAction,
  getItemActivitySuccessAction,
  getItemActivityFailAction,
  assignEmployeeUnitAction,
  assignEmployeeUnitSuccessAction,
  assignEmployeeUnitFailAction,
  removeEmployeeUnitAction,
  removeEmployeeUnitSuccessAction,
  removeEmployeeUnitFailAction,
  getItemWriteOffEmployees,
  getItemWriteOffEmployeesSuccess,
  undoUnitStatusAction,
  getAllInvoicesActionSuccess,
  assignSellToDriverAction,
  assignLeaseToDriverAction,
  leaseSellInventoryUnitAction,
  leaseSellUnitSuccessAction,
  leaseSellUnitFailAction,
  deleteItemsAction,
  deleteItemsSuccessAction,
  deleteItemsFailAction,
} from './itemsActions';
import {
  CREATE_ITEM,
  UPLOAD_ITEM_PHOTO,
  DELETE_ITEM_PHOTO,
  UPLOAD_ITEM_INVOICE_FILE,
  DELETE_ITEM_INVOICE_FILE,
  GET_ITEM_INVOICES,
  GET_ITEM,
  ADD_UNITS,
  CHANGE_STATUS_UNIT,
  ASSIGN_INVENTORY_UNIT,
  ASSIGN_SELL,
  ASSIGN_LEASE,
  UNASSIGN_UNIT,
  UPDATE_ITEM,
  GET_ITEM_ACTIVITY,
  ASSIGN_EMPLOYEE_UNIT,
  REMOVE_EMPLOYEE_UNIT,
  GET_ITEM_WRITE_OFF_EMPLOYEES,
  UNDO_CHANGE_STATUS_UNIT,
  GET_ALL_INVOICES,
  LEASE_SELL_INVENTORY_UNIT,
  DELETE_ITEM,
} from './itemsTypes';

// CREATE_ITEM
function* addItemsSaga({
  payload: { data, callback },
}: ReturnType<typeof addItemsAction>) {
  const url = '/v1/inventories/items';
  try {
    const {
      data: { items },
    } = yield call(() => api.post(url, data));

    yield put(addItemsSuccessAction(items));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(addItemsFailAction(e));
  }
}

// UPDATE_ITEM
function* updateItemsSaga({
  payload: { id, data, callback },
}: ReturnType<typeof updateItemsAction>) {
  const url = `/v1/inventories/items/${id}`;
  try {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      data: { items },
    } = yield call(() => api.patch(url, data));

    yield put(updateItemsSuccessAction());
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(updateItemsFailAction(e));
  }
}
// DELETE_ITEM
function* deleteItemSaga({
  payload: { itemId },
}: ReturnType<typeof deleteItemsAction>) {
  const url = `/v1/inventories/items/${itemId}`;
  try {
    const { data } = yield call(() => api.delete(url));
    if (data) yield put(deleteItemsSuccessAction());
  } catch (e) {
    if (api.isAxiosError(e)) yield put(deleteItemsFailAction(e));
  }
}
// ADD_UNITS
function* addUnitsSaga({
  payload: { itemId, data, callback },
}: ReturnType<typeof addUnitsAction>) {
  const url = `/v1/inventories/items/${itemId}/units`;
  try {
    const {
      data: { items },
    } = yield call(() => api.post(url, data));

    yield put(addUnitsSuccessAction(items));
    yield put(getItemAction(itemId));
    // yield put(getItemUnitsRepairAction({ itemId: +itemId }));
    yield put(getItemActivityAction({ itemId }));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(addUnitsFailAction(e));
  }
}

// GET_ITEMS
function* getItemActivitySaga({
  payload: { itemId, callback },
}: ReturnType<typeof getItemActivityAction>) {
  const { page, per_page } = yield select(paginationSelector);
  const url = `/v1/inventories/items/${itemId}/activity?page=${page}&per_page=${per_page}$`;
  try {
    const { data } = yield call(() => api.get(url));
    yield put(getItemActivitySuccessAction(data));
    yield put(setPaginationAction(data.pagination));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(getItemActivityFailAction(e));
  }
}

// LEASE UNIT
function* leaseSellInventoryUnitSaga({
  payload: {
    formData,
    operation,
    leaseToId,
    callback,
    snackbarCallback,
    errorCallback,
  },
}: ReturnType<typeof leaseSellInventoryUnitAction>) {
  try {
    const { data } = yield call(() =>
      api.post(
        `/v1/inventories/items/driver/${leaseToId}/${operation}`,
        formData,
      ),
    );
    yield put(leaseSellUnitSuccessAction());
    if (callback) callback(data.units);
    if (snackbarCallback) {
      snackbarCallback(operation === 'lease' ? 'lease' : 'sell')(data);
    }
  } catch (e) {
    if (api.isAxiosError(e)) {
      yield put(leaseSellUnitFailAction(e));
      const { errors } = (e.response?.data || {}) as {
        errors: { errors_units: ErrorsUnitType[] };
      };
      if (errors?.errors_units && errorCallback)
        errorCallback(errors.errors_units);
    }
  }
}

// ASSIGN DRIVER UNIT
function* assignInventoryUnitSaga({
  payload: { formData, assignTo, assignToId, callback, errorCallback },
}: ReturnType<typeof assignInventoryUnitAction>) {
  try {
    const { data } = yield call(() =>
      api.post(
        `/v1/inventories/items/${assignTo}/${assignToId}/assign`,
        formData,
      ),
    );
    yield put(assignDriverUnitSuccessAction());
    if (callback) callback(data.units);
  } catch (e) {
    if (api.isAxiosError(e)) {
      yield put(assignDriverUnitFailAction(e));
      const { errors } = (e.response?.data || {}) as {
        errors: { errors_units: ErrorsUnitType[] };
      };
      if (errors?.errors_units && errorCallback)
        errorCallback(errors.errors_units);
    }
  }
}

function* assignSellSaga({
  payload: { formData, assignToId, callback },
}: ReturnType<typeof assignSellToDriverAction>) {
  try {
    const { data } = yield call(() =>
      api.post(`/v1/inventories/items/driver/${assignToId}/sell`, formData),
    );
    yield put(assignDriverUnitSuccessAction());
    if (callback) callback(data.units);
  } catch (e) {
    if (api.isAxiosError(e)) {
      yield put(assignDriverUnitFailAction(e));
    }
  }
}

function* assignLeaseSaga({
  payload: { formData, assignToId, callback },
}: ReturnType<typeof assignLeaseToDriverAction>) {
  try {
    const { data } = yield call(() =>
      api.post(`/v1/inventories/items/driver/${assignToId}/lease`, formData),
    );
    yield put(assignDriverUnitSuccessAction());
    if (callback) callback(data.units);
  } catch (e) {
    if (api.isAxiosError(e)) {
      yield put(assignDriverUnitFailAction(e));
    }
  }
}

// ASSIGN EMPLOYEE UNIT
function* assignEmployeeUnitSaga({
  payload: { itemId, unitId, employeeId, callback },
}: ReturnType<typeof assignEmployeeUnitAction>) {
  try {
    yield call(() =>
      api.post(
        `/v1/inventories/items/${itemId}/units/${unitId}/attach_to_employee/${employeeId}`,
      ),
    );
    yield put(assignEmployeeUnitSuccessAction());
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(assignEmployeeUnitFailAction(e));
  }
}

// REMOVE DRIVER UNIT
function* unassignUnitSaga({
  payload: { itemId, unitIds, callback },
}: ReturnType<typeof unassignUnitAction>) {
  try {
    const { data } = yield call(() =>
      api.post(`/v1/inventories/items/${itemId}/units/unassign`, {
        units_ids: unitIds,
      }),
    );
    yield put(unassignUnitSuccessAction());
    if (callback) callback(data);
  } catch (e) {
    if (api.isAxiosError(e)) yield put(unassignUnitFailAction(e));
  }
}

// REMOVE DRIVER UNIT
function* removeEmployeeUnitSaga({
  payload: { itemId, unitId, employeeId, callback },
}: ReturnType<typeof removeEmployeeUnitAction>) {
  try {
    yield call(() =>
      api.delete(
        `/v1/inventories/items/${itemId}/units/${unitId}/attach_to_employee/${employeeId}`,
      ),
    );
    yield put(removeEmployeeUnitSuccessAction());
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(removeEmployeeUnitFailAction(e));
  }
}

// CHANGE UNIT CONDITION BY ID
function* changeUnitStatusSaga({
  payload: { itemId, newStatus, dataToSend, callback },
}: ReturnType<typeof changeUnitStatusAction>) {
  try {
    const { data } = yield call(() =>
      api.post(
        `/v1/inventories/items/${itemId}/units/${newStatus}`,
        dataToSend,
      ),
    );
    yield put(changeUnitStatusSuccessAction());
    if (callback) callback(data);
  } catch (e) {
    if (api.isAxiosError(e)) yield put(changeUnitStatusFailAction(e));
  }
}

function* undoChangeUnitStatusSaga({
  payload: { itemId, dataToSend, callback },
}: ReturnType<typeof undoUnitStatusAction>) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data } = yield call(() =>
      api.post(`/v1/inventories/items/${itemId}/units/undo`, dataToSend),
    );
    // yield put(changeUnitStatusSuccessAction());
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(changeUnitStatusFailAction(e));
  }
}

// GET_ITEM
function* getItemSaga({ id }: ReturnType<typeof getItemAction>) {
  try {
    const { data } = yield call(() => api.get(`v1/inventories/items/${id}`));
    yield put(getItemSuccessAction(data));
  } catch (e) {
    if (api.isAxiosError(e)) yield put(getItemFailAction(e));
  }
}

// UPLOAD PHOTO
function* uploadItemPhotoSaga({
  payload: { files, itemId },
}: ReturnType<typeof uploadItemPhotoAction>) {
  const url = itemId
    ? `/v1/inventories/items/${itemId}/photos`
    : '/v1/inventories/items/photos';
  for (const { file } of files) {
    yield call(function* upload() {
      try {
        const { data } = yield call(() =>
          api.post(url, fileToFormData(file, 'inventories_items_photo[file]'), {
            headers: { 'Content-Type': 'multipart/form-data' },
          }),
        );
        yield put(
          uploadItemPhotoSuccessAction({
            file: data.file,
            itemId: itemId ? Number(itemId) : undefined,
          }),
        );
      } catch (e) {
        if (api.isAxiosError(e)) yield put(uploadItemPhotoFailAction(e));
      }
    });
  }
}

// DELETE PHOTO
function* deleteItemPhotoSaga({
  payload: { fileId, itemId },
}: ReturnType<typeof deleteItemPhotoAction>) {
  try {
    const url = itemId
      ? `/v1/inventories/items/${itemId}/photos/${fileId}`
      : `/v1/inventories/items/photos/${fileId}`;
    yield call(() => api.delete(url));
    yield put(
      deleteItemPhotoSuccessAction({
        fileId,
        itemId: itemId ? Number(itemId) : undefined,
      }),
    );
  } catch (e) {
    if (api.isAxiosError(e)) yield put(deleteItemPhotoFailAction(e));
  }
}

// UPLOAD INVOICE FILE
function* uploadItemInvoiceFileSaga({
  payload: { files, actionConfig },
}: ReturnType<typeof uploadItemInvoiceFileAction>) {
  try {
    const url = '/v1/inventories/items/invoice_files';
    const [{ file }] = files;
    const formData = new FormData();
    formData.append('inventories_items_invoice_file[file]', file);
    if (actionConfig) {
      formData.append(
        'inventories_items_invoice_file[invoice_number]',
        actionConfig.name,
      );
    }

    const { data } = yield call(() =>
      api.post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    );

    yield put(uploadItemInvoiceFileSuccessAction(data.file));
  } catch (e) {
    if (api.isAxiosError(e)) yield put(uploadItemInvoiceFileFailAction(e));
  }
}

// DELETE INVOICE FILE
function* deleteItemInvoiceFileSaga({
  payload: { fileId },
}: ReturnType<typeof deleteItemInvoiceFileAction>) {
  try {
    const url = `/v1/inventories/items/invoice_files/${fileId}`;
    yield call(() => api.delete(url));
    yield put(deleteItemInvoiceFileSuccessAction(fileId));
  } catch (e) {
    if (api.isAxiosError(e)) yield put(deleteItemInvoiceFileFailAction(e));
  }
}

// GET INVOICES
function* getItemInvoicesSaga({
  payload,
}: ReturnType<typeof getItemInvoicesAction>) {
  const { query = '', callback } = payload || {};
  try {
    const url = `/v1/inventories/items/invoice_files?${query}`;
    const { data } = yield call(() => api.get(url));
    yield put(getItemInvoicesSuccessAction(data.invoice_files));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(getItemInvoicesFailAction(e));
  }
}

function* getAllInvoicesSaga() {
  try {
    const url = '/v1/inventories/items/invoice_files';
    const { data } = yield call(() => api.get(url));
    yield put(getAllInvoicesActionSuccess(data.invoice_files));
  } catch (e) {
    if (api.isAxiosError(e)) yield put(getItemInvoicesFailAction(e));
  }
}

// GET WRITE OF BY LIST
function* getItemWriteOffEmployeesSaga({
  payload,
}: ReturnType<typeof getItemWriteOffEmployees>) {
  const { callback } = payload || {};
  try {
    const url = `/v1/inventories/items/written_off_by`;
    const { data } = yield call(() => api.get(url));
    yield put(getItemWriteOffEmployeesSuccess(data.employees));
    if (callback) callback();
  } catch (e) {
    console.warn(`Error: `, e);
  }
}

export default function* itemsSaga() {
  yield takeLeading(CREATE_ITEM, addItemsSaga);
  yield takeLeading(UPDATE_ITEM, updateItemsSaga);
  yield takeLeading(DELETE_ITEM, deleteItemSaga);
  yield takeLeading(ADD_UNITS, addUnitsSaga);
  yield takeLeading(LEASE_SELL_INVENTORY_UNIT, leaseSellInventoryUnitSaga);
  yield takeLeading(ASSIGN_INVENTORY_UNIT, assignInventoryUnitSaga);
  yield takeLeading(ASSIGN_SELL, assignSellSaga);
  yield takeLeading(ASSIGN_LEASE, assignLeaseSaga);
  yield takeLeading(ASSIGN_EMPLOYEE_UNIT, assignEmployeeUnitSaga);
  yield takeLeading(UNASSIGN_UNIT, unassignUnitSaga);
  yield takeLeading(REMOVE_EMPLOYEE_UNIT, removeEmployeeUnitSaga);
  yield takeLeading(CHANGE_STATUS_UNIT, changeUnitStatusSaga);
  yield takeLeading(UNDO_CHANGE_STATUS_UNIT, undoChangeUnitStatusSaga);
  yield takeLatest(GET_ITEM_ACTIVITY, getItemActivitySaga);
  yield takeLatest(GET_ITEM, getItemSaga);
  yield takeLeading(UPLOAD_ITEM_PHOTO, uploadItemPhotoSaga);
  yield takeLeading(DELETE_ITEM_PHOTO, deleteItemPhotoSaga);
  yield takeLeading(UPLOAD_ITEM_INVOICE_FILE, uploadItemInvoiceFileSaga);
  yield takeLeading(DELETE_ITEM_INVOICE_FILE, deleteItemInvoiceFileSaga);
  yield takeLatest(GET_ITEM_INVOICES, getItemInvoicesSaga);
  yield takeLatest(GET_ALL_INVOICES, getAllInvoicesSaga);
  yield takeLatest(GET_ITEM_WRITE_OFF_EMPLOYEES, getItemWriteOffEmployeesSaga);
}
