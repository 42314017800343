import { call, put, takeLatest, select } from 'redux-saga/effects';

import { setPagesAction } from 'components/_common/Navigation/Pagination/_redux/paginationActions';
import { paginationSelector } from 'components/_common/Navigation/Pagination/_redux/paginationSelectors';
import api from 'utils/requests';

import { getApplicantsArchiveListAction } from './applicantsArchiveActions';
import {
  GET_APPLICANTS_ARCHIVE_LIST,
  GET_APPLICANTS_ARCHIVE_LIST_SUCCESS,
  GET_APPLICANTS_ARCHIVE_LIST_FAIL,
} from './applicantsArchiveTypes';

function* getAplicantsArchiveListSaga({
  payload: { query = '', usePaginationFlag, callback },
}: ReturnType<typeof getApplicantsArchiveListAction>) {
  try {
    const { per_page, page } = yield select(paginationSelector);

    const { data } = yield call(() =>
      api.get(
        `/v1/fleets/applicants/archived?page=${page}&per_page=${per_page}${query}`,
      ),
    );

    const { pagination } = data;

    yield put({ type: GET_APPLICANTS_ARCHIVE_LIST_SUCCESS, payload: data });

    // Pagination set
    if (usePaginationFlag)
      yield put(setPagesAction(pagination && pagination.pages));

    if (callback) callback();
  } catch (error) {
    yield put({ type: GET_APPLICANTS_ARCHIVE_LIST_FAIL, payload: error });
  }
}

export default function* applicantsArchiveSaga() {
  yield takeLatest(GET_APPLICANTS_ARCHIVE_LIST, getAplicantsArchiveListSaga);
}
