import {
  ISetCollapsedFlag,
  SET_COLLAPSED_FLAG,
  SET_OVER_FLAG,
  ISetOverFlag,
  ISetSidebarConfig,
  SET_SIDEBAR_CONFIG,
} from './mainSidebarTypes';

export const setCollapsedFlagAction = (
  payload: ISetCollapsedFlag['payload'],
) => ({
  type: SET_COLLAPSED_FLAG,
  payload,
});

export const setOverFlagAction = (payload: ISetOverFlag['payload']) => ({
  type: SET_OVER_FLAG,
  payload,
});

export const setSidebarConfigAction = (
  payload: ISetSidebarConfig['payload'],
) => ({
  type: SET_SIDEBAR_CONFIG,
  payload,
});
