import {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import { getResourcesAutocompleteAction } from 'components/_common/FormElements/ResourcesAutocomplete/_redux/resourcesAction';
import { GET_RESOURCES_AUTOCOMPLETE_DATA_SUCCESS } from 'components/_common/FormElements/ResourcesAutocomplete/_redux/resourcesTypes';
// import Message from 'components/_common/Message/Message';
import {
  refreshTokenAction,
  refreshUserAction,
} from 'components/Auth/_redux/authActions';
import { authSelector } from 'components/Auth/_redux/authSelectors';
import { updateFromSocketAction } from 'components/Chat/_redux/chatActions';
import DOTPopUp from 'components/Fleet/_common/DOTPopUp/DOTPopUp';
import useGoogleMapsLoad from 'hooks/googleAPIs/useGoogleMapsLoad';
import useQuery from 'hooks/useQuery';
import languages from 'i18n';
import IndexedDb from 'indexedDB';
import {
  subscribeChannelAction,
  unsubscribeChannelAction,
} from 'redux/socket/socketActions';
import { socketChannelSelector } from 'redux/socket/socketSelectors';
import { userSettingsSelector } from 'redux/userSettings/userSettingsSelectors';
import MainRoutes from 'routes';
import {
  CHAT_CHANNEL_ID,
  NOTIFICATIONS_CHANNEL_ID,
  NotificationsChannelItem,
} from 'socket/channels';
import { emptyFn, replaceFavIcons } from 'utils/helpers';

import { getActivePlan } from '../WiseCheck/TabSettings/_redux/companySettingsActions';
import { companySettingsSelector } from '../WiseCheck/TabSettings/_redux/companySettingsSelectors';

import styles from './App.module.scss';

const TOKEN_REFRESH_RATE = 1000 * 60 * 60; // 1 hour

const wisePagesUrls = [
  '/inspections/show',
  '/fleet/dashboard',
  '/fleet/dashboard/?guide=true',
  '/fleet/trucks',
  '/fleet/trucks-maintenances',
  '/fleet/trailers',
  '/fleet/inventory/items',
  '/fleet/inventory/item',
  '/fleet/inventory/categories',
  '/fleet/inventory/vendors',
  '/fleet/inspections',
  '/fleet/inspection',
  '/fleet/requests',
  '/fleet/claims',
  '/hr/drivers',
  '/hr/employees',
  '/hr/archive',
  '/fleet/settings',
  '/settings/integrations/gps_tracking',
  '/fleet/inventory/locations',
  '/chat',
  '/terms',
  '/management/tasks',
];

const App: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const location = useLocation();

  const { messages } = useSelector(
    socketChannelSelector<NotificationsChannelItem>(NOTIFICATIONS_CHANNEL_ID),
  );

  const redirectUrlFromQuery = query.get('redirectUrl');
  const [redirectUrl, setRedirectUrl] = useState<string>();

  // const [showRedirectError, setShowRedirectError] = useState(false);

  const { language, theme, company } = useSelector(userSettingsSelector);
  const { token, valid_at, position, planRedirect } = useSelector(authSelector);
  const { activePlan } = useSelector(companySettingsSelector);

  const prevToken = useRef(token);
  const timeout = useRef<NodeJS.Timeout>();

  const isWiseCheck = useMemo(
    () => company?.product_menu_type === 'wisecheck',
    [company],
  );

  // Load Google Maps on app init
  useGoogleMapsLoad();

  // TODO: DimaM Commented due to Nazar Yurystovskyy task FT-5822 - further all functionality will be deleted
  // const {
  //   isOpen: showGoogleSheet,
  //   open: openGoogleSheet,
  //   close: closeGoogleSheet,
  // } = useToggle();

  // creating tab title on user location (for example: trucks - fleet - TMS Platform)
  useEffect(() => {
    const pathNames = window.location.pathname
      .split('/')
      .filter(item => item)
      .reverse()
      .map(word => word[0].toUpperCase() + word.substring(1));
    const mainPath = document.title.split('-');
    let defaultPath = mainPath[mainPath.length - 1];
    if (isWiseCheck) {
      defaultPath = 'W1se Check';
    }
    const titleString = pathNames.join(' - ');
    document.title = `${titleString} - ${defaultPath}`;
  }, [window.location.pathname, isWiseCheck]);

  useEffect(() => {
    // get data from indexed DB
    // need for hash some values for correct work of AG-grid
    const runIndexDb = async () => {
      const indexedDb = new IndexedDb('test');
      await indexedDb.createObjectStore(['drivers']);
      const initDrivers = await indexedDb.getAllValue('drivers');
      dispatch({
        type: GET_RESOURCES_AUTOCOMPLETE_DATA_SUCCESS,
        payload: { drivers: initDrivers },
      });
      dispatch(getResourcesAutocompleteAction(emptyFn, true));
    };
    if (token) runIndexDb();
    if (!token) document.title = 'TMS Platform';
  }, [token]);

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  useEffect(() => {
    // save in state => because auto redirect clears it from query
    if (redirectUrlFromQuery) setRedirectUrl(redirectUrlFromQuery);
  }, [redirectUrlFromQuery]);

  const wisecheckRedirect = useCallback(() => {
    if (
      activePlan?.status === 'suspended' ||
      activePlan?.status === 'pending' ||
      activePlan?.status === 'pending_trial' ||
      activePlan?.expired
    ) {
      history.push('/fleet/settings#subscription');
    } else if (
      redirectUrl &&
      wisePagesUrls.some(v => v.includes(redirectUrl))
    ) {
      history.replace(redirectUrl);
    } else if (wisePagesUrls.some(v => location.pathname.includes(v))) {
      history.replace(location.pathname + location.search);
    } else history.push('/fleet/dashboard');
  }, [redirectUrl, location, activePlan]);

  useEffect(() => {
    // setShowRedirectError(false);
    if (position && isWiseCheck && window.location.search !== '?guide=true') {
      if (
        (activePlan?.status === 'suspended' || activePlan?.expired) &&
        window.location.href.indexOf('/fleet/settings/plans') === -1
      ) {
        history.push('/fleet/settings#subscription');
      } else if (
        // activePlan?.status === 'suspended' ||
        activePlan?.status === 'pending' ||
        activePlan?.status === 'pending_trial'
      ) {
        history.push('/fleet/settings#subscription');
      } else if (!activePlan) {
        // setShowRedirectError(true);
        if (planRedirect) {
          history.push(`/fleet/settings/plans?plan=${planRedirect}`);
        } else {
          history.push('/fleet/settings/plans');
        }
      }
    }
  }, [isWiseCheck, activePlan, location.pathname, window.location.search]);

  useEffect(() => {
    if (position) {
      // for wisecheck
      if (isWiseCheck) {
        wisecheckRedirect();
      } // for TMS
      else if (redirectUrl && position !== 'need_registration')
        history.replace(redirectUrl);
      else if (!location.pathname || location.pathname === '/') {
        // redirect by position
        let redirectByPosition = '';
        switch (position) {
          case 'CSO (Chief Strategic Officer)':
          case 'CEO (Chief Executive Officer)':
            redirectByPosition = '/accounting/reports/company';
            break;

          case 'Fleet Manager':
          case 'Fleet Specialist':
          case 'Main Mechanik':
          case 'Mechanik':
          case 'Forklift Agent':
          case 'Technician':
            redirectByPosition = '/fleet/trucks';
            break;

          case 'Shop Manager':
          case 'Warehouse Manager':
            redirectByPosition = '/fleet/inventory/items';
            break;

          case 'Dispatcher':
            redirectByPosition = '/dispatch/orders';
            break;

          case 'Recruiter':
          case 'Drivers Relation specialist':
          case 'HR Manager':
          case 'Operation Manager':
          case 'HR Specialist':
            redirectByPosition = '/hr/employees';
            break;

          case 'Chief accountant':
            redirectByPosition = '/accounting/billing';
            break;

          case 'Accountant':
          case 'Accounting Administrative Manager':
            redirectByPosition = '/accounting/payrolls/weeks';
            break;

          case 'Safety Manager':
          case 'Safety Administrative Manager':
          case 'Safety Specialist':
          case 'Claims Specialist':
            redirectByPosition = '/safety';
            break;
          case 'need_registration':
            if (planRedirect) {
              redirectByPosition = `/reg?plan=${planRedirect}`;
            } else {
              redirectByPosition = '/reg';
            }
            break;
          /* For:
            • Receptionist
            • Marketing Manager
            • Marketing Specialist
            • Account Manager
            • System Administrator
            redirect to chat
          */
          default:
            redirectByPosition = '/chat';
            break;
        }
        history.replace(redirectByPosition);
      }
    }
  }, [position, isWiseCheck]);

  useEffect(() => {
    replaceFavIcons(isWiseCheck);
  }, [isWiseCheck]);

  useEffect(() => {
    if (window.location.hostname === 'my.w1secheck.com') replaceFavIcons(true);
    if (window.location.hostname === 'mydvlexpress.com') replaceFavIcons(false);
  }, []);

  useEffect(() => {
    const isLogin = !prevToken.current && !!token;
    const isLogout = !!prevToken.current && !token;
    const isRefresh =
      !!prevToken.current && !!token && prevToken.current !== token;

    // if (!isLogin) {
    //   console.log('here2?', isLogin);
    //   replaceFavIcons(true);
    // }
    // TODO: DimaM Commented due to Nazar Yurystovskyy task FT-5822 - further all functionality will be deleted
    // if (isLogin) openGoogleSheet();

    if (isLogout && timeout.current) clearTimeout(timeout.current);

    if (isLogin || isRefresh) {
      const currentDate = moment().valueOf();
      const validDate = moment(valid_at).valueOf();
      const timeForRefresh = validDate - currentDate;

      timeout.current = setTimeout(
        () => {
          dispatch(refreshTokenAction({ token }));
        },
        timeForRefresh < TOKEN_REFRESH_RATE ? 0 : TOKEN_REFRESH_RATE,
      );
    }

    dispatch(refreshUserAction());
    prevToken.current = token;

    dispatch(subscribeChannelAction(NOTIFICATIONS_CHANNEL_ID));
    dispatch(subscribeChannelAction(CHAT_CHANNEL_ID));
    return () => {
      dispatch(unsubscribeChannelAction(NOTIFICATIONS_CHANNEL_ID));
      dispatch(unsubscribeChannelAction(CHAT_CHANNEL_ID));
    };
  }, [token]);

  // Chat stuff
  const { lastMessage } = useSelector(socketChannelSelector(CHAT_CHANNEL_ID));

  useEffect(() => {
    if (lastMessage) dispatch(updateFromSocketAction(lastMessage));
  }, [lastMessage]);

  useEffect(() => {
    messages.map(message => {
      if (message.type === 'confirm_subscription') {
        dispatch(getActivePlan());
      }
      return null;
    });
  }, [messages]);

  return (
    <>
      <div className={styles.root}>
        <LocalizationProvider
          locale={language}
          messages={languages[language]}
          textComponent={Fragment}
          dateAdapter={AdapterDateFns}
        >
          <MainRoutes />
          {token && position && <DOTPopUp />}
        </LocalizationProvider>
      </div>

      {/* {showRedirectError && (
        <Message message="You need to subscribe to W1seCheck to access this page" />
      )} */}
      {/* TODO: DimaM Commented due to Nazar Yurystovskyy task FT-5822 - further all functionality will be deleted */}
      {/* {showGoogleSheet && ( */}
      {/*  <Modal */}
      {/*    className={styles.modal} */}
      {/*    isOpen={showGoogleSheet} */}
      {/*    onClose={closeGoogleSheet} */}
      {/*    dataTestAttr="feedbacks" */}
      {/*  > */}
      {/*    <iframe */}
      {/*      className={styles.googleSheet} */}
      {/*      title="FleetTec Feedbacks" */}
      {/*      src="https://docs.google.com/spreadsheets/d/1tvD-GIyGOAegVYUAo1o730qscdn0SmcDoGSO6KKrY6g/edit#gid=511054325&range=A1" */}
      {/*      frameBorder="0" */}
      {/*    /> */}
      {/*  </Modal> */}
      {/* )} */}
    </>
  );
};

export default App;
