export const DEFAULT_COLOR_PALETTE = '--colorsPaletteDefault';

export const COLORS_PALETTE_LIST = [
  DEFAULT_COLOR_PALETTE,
  '--colorsPaletteRed',
  '--colorsPaletteOrange',
  '--colorsPaletteYellow',
  '--colorsPaletteGreen',
  '--colorsPaletteBlue',
  '--colorsPalettePurple',
];
