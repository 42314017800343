import {
  FC,
  ReactNode,
  CSSProperties,
  useRef,
  useState,
  useEffect,
} from 'react';

import { Fade } from '@mui/material';

import { ISelectOptions } from '../_models/selectModels';

import styles from './DropDown.module.scss';

type Props = {
  options?: ISelectOptions;
  isOpen: boolean;
  children: ReactNode;
  direction?: 'top' | 'bottom';
};

const DropDown: FC<Props> = ({ options, isOpen, children, direction }) => {
  const optionsRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<CSSProperties>();

  useEffect(() => {
    let newPosition;

    if (isOpen && optionsRef.current) {
      const form =
        optionsRef.current.closest('form') ||
        optionsRef.current.closest('aside');
      if (form) {
        const { top, bottom, height } =
          optionsRef.current.getBoundingClientRect();
        const formRect = form.getBoundingClientRect();
        if (height > formRect.height - 24)
          newPosition = {
            height: `${formRect.height - 24}px`,
            transform: `translateY(${formRect.top - top + 12}px)`,
          };
        else if (formRect.bottom < bottom)
          newPosition = {
            transform: `translateY(${top - bottom - 45}px)`, // todo consider if there is a better way
          };

        if (direction && direction === 'bottom')
          newPosition = {
            transform: `translateY(0px)`,
          };
      }
    }

    setPosition(newPosition);
  }, [isOpen, options, optionsRef.current]);

  return (
    <div className={styles.root}>
      <Fade in={isOpen}>
        <div className={styles.dropdown} ref={optionsRef} style={position}>
          <div className={styles.dropdownContent}>{children}</div>
        </div>
      </Fade>
    </div>
  );
};

export default DropDown;
