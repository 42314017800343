import { FC, LazyExoticComponent } from 'react';

import { useSelector } from 'react-redux';
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from 'react-router-dom';

import { authSelector } from 'components/Auth/_redux/authSelectors';

type Props = RouteProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: LazyExoticComponent<any>;
  redirectUrl?: string;
  withPermission?: boolean;
};

const PublicRoutes: FC<Props> = ({
  component: Component,
  redirectUrl = '/',
  withPermission = true,
  ...rest
}) => {
  const { token } = useSelector(authSelector);

  const makeComponent = (props: RouteComponentProps<{}>) =>
    !token || !withPermission ? (
      <Component {...props} />
    ) : (
      <Redirect to={redirectUrl} />
    );

  return <Route {...rest} render={makeComponent} />;
};

export default PublicRoutes;
