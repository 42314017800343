// Global
import { startSubmit, stopSubmit } from 'redux-form';
import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';

// Utils
// Common components
// Constants
// Selectors
import { setPaginationAction } from 'components/_common/Navigation/Pagination/_redux/paginationActions';
import { paginationSelector } from 'components/_common/Navigation/Pagination/_redux/paginationSelectors';
// Actions
import {
  setSearchResultAction,
  setSearchResultFailAction,
  setSearchResultSuccessAction,
} from 'components/_common/SearchPanel/_redux/searchActions';
import { searchSelector } from 'components/_common/SearchPanel/_redux/searchSelectors';
import { MAX_SEARCH_RESULT } from 'components/_common/SearchPanel/constants';
import {
  createBrokerAction,
  deleteBrokerAction,
  deleteBrokerFileAction,
  getBrokerAction,
  getBrokersAction,
  setBrokerRateAction,
  updateBrokerAction,
  uploadBrokerFilesAction,
  getBrokerRatesListAction,
} from 'components/Dispatch/TabBrokers/_redux/brokersActions';
// Types
import {
  CREATE_BROKER,
  CREATE_BROKER_FAIL,
  CREATE_BROKER_SUCCESS,
  DELETE_BROKER,
  DELETE_BROKER_FAIL,
  DELETE_BROKER_FILE,
  DELETE_BROKER_FILE_FAIL,
  DELETE_BROKER_FILE_SUCCESS,
  DELETE_BROKER_SUCCESS,
  GET_BROKER,
  GET_BROKER_FAIL,
  GET_BROKER_SUCCESS,
  GET_BROKERS,
  GET_BROKERS_FAIL,
  GET_BROKERS_SUCCESS,
  ISetBrokerRateActionFail,
  ISetBrokerRateActionSuccess,
  SEARCH_BROKERS,
  SET_BROKER_RATE,
  SET_BROKER_RATE_FAIL,
  SET_BROKER_RATE_SUCCESS,
  UPDATE_BROKER,
  UPDATE_BROKER_FAIL,
  UPDATE_BROKER_SUCCESS,
  UPLOAD_BROKER_FILES,
  UPLOAD_BROKER_FILES_FAIL,
  UPLOAD_BROKER_FILES_SUCCESS,
  GET_BROKER_RATES_LIST,
  GET_BROKER_RATES_LIST_SUCCESS,
  GET_BROKER_RATES_LIST_FAIL,
} from 'components/Dispatch/TabBrokers/_redux/brokersTypes';
// Private
import { transformBrokersSearchResult } from 'components/Dispatch/TabBrokers/_redux/helpers';
import { BROKER_API_PREFIX as brokersUrl } from 'constants/api';
import { getErrors, scrollToFirstError } from 'utils/helpers';
import api from 'utils/requests';

import { userSettingsSelector } from '../../../../redux/userSettings/userSettingsSelectors';
import { BROKER_FORM_NAME } from '../_config/formConfig';
import {
  createBrokerEmployeeAction,
  deleteBrokerEmployeeAction,
  updateBrokerEmployeeAction,
} from './brokerEmployeesActions';
import {
  CREATE_BROKER_EMPLOYEE,
  CREATE_BROKER_EMPLOYEE_FAIL,
  CREATE_BROKER_EMPLOYEE_SUCCESS,
  DELETE_BROKER_EMPLOYEE,
  DELETE_BROKER_EMPLOYEE_FAIL,
  DELETE_BROKER_EMPLOYEE_SUCCESS,
  UPDATE_BROKER_EMPLOYEE,
  UPDATE_BROKER_EMPLOYEE_FAIL,
  UPDATE_BROKER_EMPLOYEE_SUCCESS,
} from './brokerEmployeeTypes';

function* getBrokersSaga({
  payload = {},
}: ReturnType<typeof getBrokersAction>) {
  const { query, callback, config } = payload;

  try {
    const { per_page, page } = yield select(paginationSelector);
    const { data } = yield call(() =>
      api.get(`${brokersUrl}?page=${page}&per_page=${per_page}${query || ''}`),
    );
    yield put({ type: GET_BROKERS_SUCCESS, payload: data });
    const { pagination } = data;
    // Pagination set
    if (config && config.usePaginationFlag && pagination)
      yield put(setPaginationAction(pagination));
    if (callback) callback();
  } catch (e) {
    yield put({ type: GET_BROKERS_FAIL, payload: e });
  }
}

function* searchBrokersSaga() {
  const { searchValue } = yield select(searchSelector);
  const { states } = yield select(userSettingsSelector);

  if (searchValue)
    try {
      yield put(setSearchResultAction([]));
      const { data } = yield call(() =>
        api.get(
          `${brokersUrl}?page=1&per_page=${MAX_SEARCH_RESULT}&search=${searchValue}`,
        ),
      );
      const { brokers } = data;

      const resBrokers = transformBrokersSearchResult(brokers, states);
      yield put(setSearchResultSuccessAction(resBrokers));
    } catch (e) {
      if (api.isAxiosError(e)) yield put(setSearchResultFailAction(e));
    }
}

function* getBrokerSaga({ id }: ReturnType<typeof getBrokerAction>) {
  try {
    const { data } = yield call(() => api.get(`${brokersUrl}/${id}`));
    yield put({
      type: GET_BROKER_SUCCESS,
      payload: data.broker,
    });
  } catch (e) {
    if (api.isAxiosError(e)) yield put({ type: GET_BROKER_FAIL, payload: e });
  }
}

function* getBrokerRatesListSaga({
  payload: {
    data: { id },
    callback,
    config,
  },
}: ReturnType<typeof getBrokerRatesListAction>) {
  try {
    const sortKey = (config && config.sort) || 'all_rates';
    const { data } = yield call(() =>
      api.get(`${brokersUrl}/${id}/orders_rates?rate_sort=${sortKey}`),
    );
    yield put({
      type: GET_BROKER_RATES_LIST_SUCCESS,
      payload: data.rates,
    });
    if (data && data.rates && callback) {
      callback();
    }
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({ type: GET_BROKER_RATES_LIST_FAIL, payload: e });
  }
}

// ToDo MOVE TO HELPERS
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const brokerFileToFormData = (fileObj: any) => {
  if (!fileObj || !fileObj.file) return null;
  const formData = new FormData();
  const { category_type, file, date_of_sign, signed_employee_id } = fileObj;
  formData.append(`brokers_file[category_type]`, category_type);
  formData.append(`brokers_file[file]`, file);
  formData.append(`brokers_file[date_of_sign]`, date_of_sign);
  if (signed_employee_id) {
    formData.append(`brokers_file[signed_employee_id]`, signed_employee_id.id);
  }
  return formData;
};

function* createBrokerSaga({
  payload: { values, callback },
}: ReturnType<typeof createBrokerAction>) {
  try {
    yield put(startSubmit(BROKER_FORM_NAME));
    const { data } = yield call(() => api.post(brokersUrl, { broker: values }));
    yield put({ type: CREATE_BROKER_SUCCESS });
    yield put(stopSubmit(BROKER_FORM_NAME));
    if (callback) callback(data.broker);
  } catch (e) {
    if (api.isAxiosError(e)) {
      const errors = getErrors(e);
      const firstErrorKey = Object.keys(errors)[0];
      if (firstErrorKey) {
        scrollToFirstError({ [firstErrorKey]: 'error' });
      }
      yield put({ type: CREATE_BROKER_FAIL, payload: e });
      yield put(stopSubmit(BROKER_FORM_NAME, errors));
    }
  }
}

function* updateBrokerSaga({
  payload: { callback, values },
}: ReturnType<typeof updateBrokerAction>) {
  try {
    yield put(startSubmit(BROKER_FORM_NAME));
    yield call(() =>
      api.patch(`${brokersUrl}/${values.id}`, {
        broker: values,
      }),
    );
    yield put({ type: UPDATE_BROKER_SUCCESS });
    yield put(stopSubmit(BROKER_FORM_NAME));
    callback();
  } catch (e) {
    if (api.isAxiosError(e)) {
      const errors = getErrors(e);
      const firstErrorKey = Object.keys(errors)[0];
      if (firstErrorKey) {
        scrollToFirstError({ [firstErrorKey]: 'error' });
      }
      yield put({ type: UPDATE_BROKER_FAIL, payload: e });
      yield put(stopSubmit(BROKER_FORM_NAME, errors));
    }
  }
}

function* deleteBrokerSaga({
  payload: { id, callback },
}: ReturnType<typeof deleteBrokerAction>) {
  try {
    yield call(() => api.delete(`/v1/fleets/brokers/${id}`));
    // yield call(() => api.delete(`${brokersUrl}/${id}`));
    yield put({ type: DELETE_BROKER_SUCCESS });
    yield put(getBrokersAction());
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({ type: DELETE_BROKER_FAIL, payload: e });
  }
}

// BROKER FILES START
function* uploadBrokerFilesSaga({
  payload: { files, itemId: brokerId },
}: ReturnType<typeof uploadBrokerFilesAction>) {
  const url = brokerId
    ? `${brokersUrl}/${brokerId}/files`
    : `${brokersUrl}/files`;

  for (const fileObj of files) {
    yield call(function* foo() {
      try {
        const { data } = yield call(() =>
          api.post(url, brokerFileToFormData(fileObj), {
            headers: { 'Content-Type': 'multipart/form-data' },
          }),
        );
        yield put({
          type: UPLOAD_BROKER_FILES_SUCCESS,
          payload: data.file,
        });
      } catch (e) {
        if (api.isAxiosError(e))
          yield put({ type: UPLOAD_BROKER_FILES_FAIL, payload: e });
      }
    });
  }
}

function* deleteBrokerFileSaga({
  payload: { fileId, itemId: brokerId },
}: ReturnType<typeof deleteBrokerFileAction>) {
  const url = brokerId
    ? `${brokersUrl}/${brokerId}/files/${fileId}`
    : `${brokersUrl}/files/${fileId}`;

  try {
    yield call(() => api.delete(url));
    yield put({ type: DELETE_BROKER_FILE_SUCCESS, payload: fileId });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({ type: DELETE_BROKER_FILE_FAIL, payload: e });
  }
}
// BROKER FILES END

// SET BROKER RATE
function* setBrokerRateSaga({
  payload: { orderId, brokerId, ...brokerRate },
}: ReturnType<typeof setBrokerRateAction>) {
  try {
    yield call(() =>
      api.post(`/v1/orders/${orderId}/rate_brokers/${brokerId}`, brokerRate),
    );
    yield put<ISetBrokerRateActionSuccess>({ type: SET_BROKER_RATE_SUCCESS });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<ISetBrokerRateActionFail>({
        type: SET_BROKER_RATE_FAIL,
        payload: e,
      });
  }
}

// BROKER EMPLOYEES
function* createBrokerEmployeeSaga({
  payload: { values, brokerId, callback },
}: ReturnType<typeof createBrokerEmployeeAction>) {
  try {
    const { data } = yield call(() =>
      api.post(`${brokersUrl}/${brokerId}/contacts`, {
        brokers_contact: values,
      }),
    );
    yield put({
      type: CREATE_BROKER_EMPLOYEE_SUCCESS,
      payload: data.brokers_contact,
    });
    if (callback) callback(data.brokers_contact);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({ type: CREATE_BROKER_EMPLOYEE_FAIL, payload: e });
  }
}

function* updateBrokerEmployeeSaga({
  payload: { values, callback },
}: ReturnType<typeof updateBrokerEmployeeAction>) {
  try {
    const { data } = yield call(() =>
      api.patch(`${brokersUrl}/${values.broker_id}/contacts/${values.id}`, {
        brokers_contact: values,
      }),
    );
    yield put({
      type: UPDATE_BROKER_EMPLOYEE_SUCCESS,
      payload: data.brokers_contact,
    });
    if (callback) callback(data.brokers_contact);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({ type: UPDATE_BROKER_EMPLOYEE_FAIL, payload: e });
  }
}

function* deleteBrokerEmployeeSaga({
  payload: { employeeId, brokerId, callback },
}: ReturnType<typeof deleteBrokerEmployeeAction>) {
  try {
    yield call(() =>
      api.delete(`${brokersUrl}/${brokerId}/contacts/${employeeId}`),
    );
    yield put({ type: DELETE_BROKER_EMPLOYEE_SUCCESS, payload: employeeId });
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({ type: DELETE_BROKER_EMPLOYEE_FAIL, payload: e });
  }
}
// END BROKER EMPLOYEES

export default function* brokersSaga() {
  yield takeLatest(GET_BROKERS, getBrokersSaga);
  yield takeLatest(SEARCH_BROKERS, searchBrokersSaga);
  yield takeLatest(GET_BROKER, getBrokerSaga);
  yield takeLeading(CREATE_BROKER, createBrokerSaga);
  yield takeLatest(UPDATE_BROKER, updateBrokerSaga);
  yield takeLatest(DELETE_BROKER, deleteBrokerSaga);
  yield takeLatest(UPLOAD_BROKER_FILES, uploadBrokerFilesSaga);
  yield takeLeading(DELETE_BROKER_FILE, deleteBrokerFileSaga);
  yield takeLatest(SET_BROKER_RATE, setBrokerRateSaga);
  yield takeLatest(GET_BROKER_RATES_LIST, getBrokerRatesListSaga);
  yield takeLeading(CREATE_BROKER_EMPLOYEE, createBrokerEmployeeSaga);
  yield takeLatest(UPDATE_BROKER_EMPLOYEE, updateBrokerEmployeeSaga);
  yield takeLatest(DELETE_BROKER_EMPLOYEE, deleteBrokerEmployeeSaga);
}
