import { FC } from 'react';

import { useSelector } from 'react-redux';

import { userThemeSelector } from 'redux/userSettings/userSettingsSelectors';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const IconEmail: FC<IOwnProps> = ({ className, fillColor }) => {
  const theme = useSelector(userThemeSelector);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      className={className}
    >
      <rect
        x="1"
        y="1"
        width="14"
        height="12"
        rx="2"
        stroke={fillColor || (theme === 'dark' ? 'white' : '#2C6AF6')}
        strokeWidth="2"
      />
      <path
        d="M1.49614 3.13176C1.01662 2.85775 0.405767 3.02434 0.131757 3.50386C-0.142253 3.98338 0.0243436 4.59423 0.503861 4.86824L1.49614 3.13176ZM15.4961 4.86824C15.9757 4.59423 16.1423 3.98338 15.8682 3.50386C15.5942 3.02434 14.9834 2.85775 14.5039 3.13176L15.4961 4.86824ZM7.00772 7.43298L6.51158 8.30123L7.00772 7.43298ZM0.503861 4.86824L6.51158 8.30123L7.50386 6.56474L1.49614 3.13176L0.503861 4.86824ZM9.48842 8.30123L15.4961 4.86824L14.5039 3.13176L8.49614 6.56474L9.48842 8.30123ZM6.51158 8.30123C7.43388 8.82825 8.56612 8.82825 9.48842 8.30123L8.49614 6.56474C8.18871 6.74042 7.81129 6.74042 7.50386 6.56474L6.51158 8.30123Z"
        fill={fillColor || (theme === 'dark' ? 'white' : '#2C6AF6')}
      />
    </svg>
  );
};

export default IconEmail;
