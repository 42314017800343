/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';

import {
  IDeleteFileAction,
  IDeleteFileActionFail,
  IDeleteFileActionSuccess,
  IUploadFilesAction,
  IUploadFilesActionFail,
  IUploadFilesActionSuccess,
} from 'redux/_common/commonTypes';
import { IFile, ILogout } from 'redux/_common/models';

import { ITrailerTrackingData } from '../_models/trailerMapModels';
import { ITrailer } from '../_models/trailerModel';

// CREATE TRAILER
export const CREATE_TRAILER = '[@@Trailers] CREATE_TRAILER';
export const CREATE_TRAILER_SUCCESS = '[@@Trailers] CREATE_TRAILER_SUCCESS';
export const CREATE_TRAILER_FAIL = '[@@Trailers] CREATE_TRAILER_FAIL';

export interface ICreateTrailer {
  type: typeof CREATE_TRAILER;
  payload: any;
}

export interface ICreateTrailerSuccess {
  type: typeof CREATE_TRAILER_SUCCESS;
  payload: ITrailer;
}

export interface ICreateTrailerFail {
  type: typeof CREATE_TRAILER_FAIL;
  payload: AxiosError;
}

// GET TRAILERS
export const GET_TRAILERS = '[@@Trailers] GET_TRAILERS';
export const GET_TRAILERS_SUCCESS = '[@@Trailers] GET_TRAILERS_SUCCESS';
export const GET_TRAILERS_FAIL = '[@@Trailers] GET_TRAILERS_FAIL';

export interface IGetTrailers {
  type: typeof GET_TRAILERS;
  payload: {
    data: { query?: string };
    config?: { usePaginationFlag?: boolean };
    callback?: () => void;
  };
}

export interface IGetTrailersSuccess {
  type: typeof GET_TRAILERS_SUCCESS;
  payload: any;
}

export interface IGetTrailersFail {
  type: typeof GET_TRAILERS_FAIL;
  payload: AxiosError;
}

// GET TRAILER BY ID
export const GET_TRAILER = '[@@Trailers] GET_TRAILER';
export const GET_TRAILER_SUCCESS = '[@@Trailers] GET_TRAILER_SUCCESS';
export const GET_TRAILER_FAIL = '[@@Trailers] GET_TRAILER_FAIL';

export interface IGetTrailer {
  type: typeof GET_TRAILER;
  payload: any;
}

export interface IGetTrailerSuccess {
  type: typeof GET_TRAILER_SUCCESS;
  payload: any;
}

export interface IGetTrailerFail {
  type: typeof GET_TRAILER_FAIL;
  payload: AxiosError;
}

export const CLEAR_TRAILER = '[@@Trucks] CLEAR_TRAILER';
export interface IClearTrailerAction {
  type: typeof CLEAR_TRAILER;
}

// UPDATE TRAILER BY ID
export const UPDATE_TRAILER = '[@@Trailers] UPDATE_TRAILER';
export const UPDATE_TRAILER_SUCCESS = '[@@Trailers] UPDATE_TRAILER_SUCCESS';
export const UPDATE_TRAILER_FAIL = '[@@Trailers] UPDATE_TRAILER_FAIL';

export interface IUpdateTrailer {
  type: typeof UPDATE_TRAILER;
  payload: any;
}

export interface IUpdateTrailerSuccess {
  type: typeof UPDATE_TRAILER_SUCCESS;
  payload: any;
}

export interface IUpdateTrailerFail {
  type: typeof UPDATE_TRAILER_FAIL;
  payload: AxiosError;
}

// UPDATE TRAILER BY ID
export const DELETE_TRAILER = '[@@Trailers] DELETE_TRAILER';
export const DELETE_TRAILER_SUCCESS = '[@@Trailers] DELETE_TRAILER_SUCCESS';
export const DELETE_TRAILER_FAIL = '[@@Trailers] DELETE_TRAILER_FAIL';

export interface IDeleteTrailer {
  type: typeof DELETE_TRAILER;
}

export interface IDeleteTrailerSuccess {
  type: typeof DELETE_TRAILER_SUCCESS;
  payload: number;
}

export interface IDeleteTrailerFail {
  type: typeof DELETE_TRAILER_FAIL;
  payload: AxiosError;
}

// UPLOAD TRAILER FILES
export const UPLOAD_TRAILER_FILES = '[@@Trailer] UPLOAD_TRAILER_FILES';
export const UPLOAD_TRAILER_FILES_SUCCESS =
  '[@@Trailer] UPLOAD_TRAILER_FILES_SUCCESS';
export const UPLOAD_TRAILER_FILES_FAIL =
  '[@@Trailer] UPLOAD_TRAILER_FILES_FAIL';

export interface IUploadTrailerFilesAction extends IUploadFilesAction {
  type: typeof UPLOAD_TRAILER_FILES;
}
export interface IUploadTrailerFilesActionSuccess
  extends IUploadFilesActionSuccess {
  type: typeof UPLOAD_TRAILER_FILES_SUCCESS;
}
export interface IUploadTrailerFilesActionFail extends IUploadFilesActionFail {
  type: typeof UPLOAD_TRAILER_FILES_FAIL;
}

// DELETE TRAILER FILE
export const DELETE_TRAILER_FILE = '[@@Trailer] DELETE_TRAILER_FILE';
export const DELETE_TRAILER_FILE_SUCCESS =
  '[@@Trailer] DELETE_TRAILER_FILE_SUCCESS';
export const DELETE_TRAILER_FILE_FAIL = '[@@Trailer] DELETE_TRAILER_FILE_FAIL';

export interface IDeleteTrailerFileAction extends IDeleteFileAction {
  type: typeof DELETE_TRAILER_FILE;
}
export interface IDeleteTrailerFileActionSuccess
  extends IDeleteFileActionSuccess {
  type: typeof DELETE_TRAILER_FILE_SUCCESS;
}
export interface IDeleteTrailerFileActionFail extends IDeleteFileActionFail {
  type: typeof DELETE_TRAILER_FILE_FAIL;
}

// TOGGLE_TRAILER_PIN
export const TOGGLE_TRAILER_PIN = '[@@Trailer] TOGGLE_TRAILER_PIN';
export const TOGGLE_TRAILER_PIN_SUCCESS =
  '[@@Trailer] TOGGLE_TRAILER_PIN_SUCCESS';
export const TOGGLE_TRAILER_PIN_FAIL = '[@@Trailer] TOGGLE_TRAILER_PIN_FAIL';

export interface IToggleTrailerPin {
  type: typeof TOGGLE_TRAILER_PIN;
  payload: {
    data: { trailerId: number; isPinned: boolean };
    callback?: () => void;
  };
}
export interface IToggleTrailerPinSuccess {
  type: typeof TOGGLE_TRAILER_PIN_SUCCESS;
  payload: { trailerId: number; isPinned: boolean };
}
export interface IToggleTrailerPinFail {
  type: typeof TOGGLE_TRAILER_PIN_FAIL;
  payload: AxiosError;
}

// GET_TRAILERS_TRACKING_DATA
export const GET_TRAILERS_TRACKING_DATA =
  '[@@Trailer] GET_TRAILERS_TRACKING_DATA';
export const GET_TRAILERS_TRACKING_DATA_SUCCESS =
  '[@@Trailer] GET_TRAILERS_TRACKING_DATA_SUCCESS';
export const GET_TRAILERS_TRACKING_DATA_FAIL =
  '[@@Trailer] GET_TRAILERS_TRACKING_DATA_FAIL';

export interface IGetTrailersTrackingData {
  type: typeof GET_TRAILERS_TRACKING_DATA;
  payload: {
    data: { deviceIds: string | null };
    callback?: () => void;
  };
}
export interface IGetTrailersTrackingDataSuccess {
  type: typeof GET_TRAILERS_TRACKING_DATA_SUCCESS;
  payload: ITrailerTrackingData[];
}
export interface IGetTrailersTrackingDataFail {
  type: typeof GET_TRAILERS_TRACKING_DATA_FAIL;
  payload: AxiosError;
}

// CLEAR_TRAILERS_TRACKING_DATA
export const CLEAR_TRAILERS_TRACKING_DATA =
  '[@@Trailer]  CLEAR_TRAILERS_TRACKING_DATA';
export interface IClearTrailersTrackingData {
  type: typeof CLEAR_TRAILERS_TRACKING_DATA;
}

// UNLOCK_TRAILERS_INSPECTION
export const UNLOCK_TRAILER_INSPECTION =
  '[@@Trailer] UNLOCK_TRAILER_INSPECTION';
export const UNLOCK_TRAILER_INSPECTION_FAIL =
  '[@@Trailer] UNLOCK_TRAILER_INSPECTION_FAIL';

export interface IUnlockTrailerInspectionAction {
  type: typeof UNLOCK_TRAILER_INSPECTION;
  payload: {
    trailerId: number;
    callback?: () => void;
  };
}

export interface IUnlockTrailerInspectionActionFail {
  type: typeof UNLOCK_TRAILER_INSPECTION_FAIL;
  payload: AxiosError;
}

// UPLOAD TRAILER CLAIM_PHOTO
export const UPLOAD_TRAILER_CLAIM_PHOTO =
  '[@@Trailer] UPLOAD_TRAILER_CLAIM_PHOTO';
export const UPLOAD_TRAILER_CLAIM_PHOTO_SUCCESS =
  '[@@Trailer] UPLOAD_TRAILER_CLAIM_PHOTO_SUCCESS';
export const UPLOAD_TRAILER_CLAIM_PHOTO_FAIL =
  '[@@Trailer] UPLOAD_TRAILER_CLAIM_PHOTO_FAIL';

export interface IUploadTrailerClaimPhotoAction extends IUploadFilesAction {
  type: typeof UPLOAD_TRAILER_CLAIM_PHOTO;
}
export interface IUploadTrailerClaimPhotoActionSuccess {
  type: typeof UPLOAD_TRAILER_CLAIM_PHOTO_SUCCESS;
  payload: { file: IFile };
}
export interface IUploadTrailerClaimPhotoActionFail {
  type: typeof UPLOAD_TRAILER_CLAIM_PHOTO_FAIL;
  payload: AxiosError;
}

// DELETE TRUCK CLAIM PHOTO
export const DELETE_TRAILER_CLAIM_PHOTO =
  '[@@Items] DELETE_TRAILER_CLAIM_PHOTO';
export const DELETE_TRAILER_CLAIM_PHOTO_SUCCESS =
  '[@@Items] DELETE_TRAILER_CLAIM_PHOTO_SUCCESS';
export const DELETE_TRAILER_CLAIM_PHOTO_FAIL =
  '[@@Items] DELETE_TRAILER_CLAIM_PHOTO_FAIL';
export interface IDeleteTrailerClaimPhotoAction extends IDeleteFileAction {
  type: typeof DELETE_TRAILER_CLAIM_PHOTO;
}
export interface IDeleteTrailerClaimPhotoSuccessAction {
  type: typeof DELETE_TRAILER_CLAIM_PHOTO_SUCCESS;
  payload: { fileId: string | number; itemId?: number };
}
export interface IDeleteTrailerClaimPhotoFailAction {
  type: typeof DELETE_TRAILER_CLAIM_PHOTO_FAIL;
  payload: AxiosError;
}
// DELETE LOCAL CLAIM PHOTOS
export const DELETE_LOCAL_CLAIM_PHOTO = '[@@Trailer] DELETE_LOCAL_CLAIM_PHOTO';
export interface IDeleteLocalClaimPhotoAction {
  type: typeof DELETE_LOCAL_CLAIM_PHOTO;
}

export type trailersTypes =
  | IDeleteTrailerFileAction
  | IDeleteTrailerFileActionFail
  | IDeleteTrailerFileActionSuccess
  | IUploadTrailerFilesAction
  | IUploadTrailerFilesActionFail
  | IUploadTrailerFilesActionSuccess
  | IDeleteTrailer
  | IDeleteTrailerFail
  | IDeleteTrailerSuccess
  | IUpdateTrailer
  | IUpdateTrailerFail
  | IUpdateTrailerSuccess
  | IGetTrailer
  | IGetTrailerFail
  | IGetTrailerSuccess
  | IClearTrailerAction
  | ICreateTrailer
  | ICreateTrailerFail
  | ICreateTrailerSuccess
  | IGetTrailers
  | IGetTrailersFail
  | IGetTrailersSuccess
  | IToggleTrailerPin
  | IToggleTrailerPinSuccess
  | IToggleTrailerPinFail
  | IGetTrailersTrackingData
  | IGetTrailersTrackingDataSuccess
  | IGetTrailersTrackingDataFail
  | IClearTrailersTrackingData
  | ILogout
  | IUnlockTrailerInspectionAction
  | IUnlockTrailerInspectionActionFail
  | IUploadTrailerClaimPhotoAction
  | IUploadTrailerClaimPhotoActionSuccess
  | IUploadTrailerClaimPhotoActionFail
  | IDeleteTrailerClaimPhotoAction
  | IDeleteTrailerClaimPhotoSuccessAction
  | IDeleteTrailerClaimPhotoFailAction
  | IDeleteLocalClaimPhotoAction;
