import { IClaimsReducer } from 'components/Fleet/TabClaims/_models/claimsModels';
import {
  ADD_CLAIM_ISSUE,
  claimsActionsType,
  CREATE_CLAIM,
  CREATE_CLAIM_FAIL,
  CREATE_CLAIM_SUCCESS,
  DELETE_CLAIM_FILE,
  DELETE_CLAIM_FILE_FAIL,
  DELETE_CLAIM_FILE_SUCCESS,
  DELETE_CLAIM_ISSUE,
  DELETE_CLAIM_ISSUE_FILE,
  DELETE_CLAIM_ISSUE_FILE_FAIL,
  DELETE_CLAIM_ISSUE_FILE_SUCCESS,
  EDIT_CLAIM_ISSUE,
  MOUNT_CLAIM_FORM,
  UNMOUNT_CLAIM_FORM,
  UPLOAD_CLAIM_FILES,
  UPLOAD_CLAIM_FILES_FAIL,
  UPLOAD_CLAIM_FILES_SUCCESS,
  UPLOAD_CLAIM_ISSUE_FILES,
  UPLOAD_CLAIM_ISSUE_FILES_FAIL,
  UPLOAD_CLAIM_ISSUE_FILES_SUCCESS,
} from 'components/Fleet/TabClaims/_redux/claimsTypes';
import { ILocalFile } from 'redux/_common/models';

const initialState: IClaimsReducer = {
  files: [],
  issueFiles: [],
  isFilesLoadStatusTrue: true,
  claimIssues: [],
  _pending: false,
  _loaded: false,
  _error: null,
};

const claimsReducers = (
  state = initialState,
  action: claimsActionsType,
): IClaimsReducer => {
  switch (action.type) {
    // CREATE CLAIM
    case CREATE_CLAIM:
      return { ...state };
    case CREATE_CLAIM_SUCCESS:
      return { ...state, files: [] };
    case CREATE_CLAIM_FAIL:
      return { ...state, _error: action.payload };

    // CLAIM ISSUE
    case ADD_CLAIM_ISSUE:
      return { ...state, claimIssues: [...state.claimIssues, action.payload] };
    case EDIT_CLAIM_ISSUE:
      return {
        ...state,
        claimIssues: [...state.claimIssues].map(issue => {
          return issue.id === action.payload.id ? action.payload : issue;
        }),
      };
    case DELETE_CLAIM_ISSUE:
      return {
        ...state,
        claimIssues: [...state.claimIssues].filter(
          el => el.id !== action.payload,
        ),
      };

    // UPLOAD CLAIM FILES
    case UPLOAD_CLAIM_FILES:
      return {
        ...state,
        isFilesLoadStatusTrue: false,
        files: [
          ...state.files,
          ...action.payload.files.map(file => ({
            ...file,
            loading: true,
          })),
        ],
      };
    case UPLOAD_CLAIM_FILES_SUCCESS:
      return {
        ...state,
        files: state.files.map((file: ILocalFile, index: number) =>
          index === state.files.findIndex(({ loading }) => loading)
            ? { ...action.payload, loading: false }
            : file,
        ) as ILocalFile[],
        isFilesLoadStatusTrue: true,
      };
    case UPLOAD_CLAIM_FILES_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // DELETE CLAIM FILE
    case DELETE_CLAIM_FILE:
      return {
        ...state,
      };
    case DELETE_CLAIM_FILE_SUCCESS:
      return {
        ...state,
        files: state.files.filter(file => file.id !== action.payload),
      };
    case DELETE_CLAIM_FILE_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    case UPLOAD_CLAIM_ISSUE_FILES:
      return {
        ...state,
        isFilesLoadStatusTrue: false,
      };

    case UPLOAD_CLAIM_ISSUE_FILES_SUCCESS:
      return {
        ...state,
        issueFiles: [
          ...state.issueFiles,
          {
            issue_id: action.payload.issue_id,
            ...action.payload.file,
          },
        ],
        isFilesLoadStatusTrue: true,
      };

    case UPLOAD_CLAIM_ISSUE_FILES_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // DELETE CLAIM ISSUE FILE
    case DELETE_CLAIM_ISSUE_FILE:
      return {
        ...state,
      };
    case DELETE_CLAIM_ISSUE_FILE_SUCCESS:
      return {
        ...state,
        issueFiles: [...state.issueFiles].filter(
          file => file.id !== action.payload.fileId,
        ),
      };
    case DELETE_CLAIM_ISSUE_FILE_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    //  OTHER

    case MOUNT_CLAIM_FORM:
      return {
        ...state,
        files: action.payload.files,
        claimIssues: action.payload.issues,
        issueFiles: action.payload.issueFiles,
      };

    case UNMOUNT_CLAIM_FORM:
      return {
        ...state,
        files: [],
        issueFiles: [],
        claimIssues: [],
      };
    default:
      return { ...state };
  }
};

export default claimsReducers;
