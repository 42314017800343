// TODO: ADD LOGOUT case
// import { LOGOUT } from 'components/Auth/_redux/authTypes';
import {
  accidentsStatisticsTypes,
  IAccidentsStatisticsState,
  GET_ACCIDENTS_STATISTICS,
  GET_ACCIDENTS_STATISTICS_SUCCESS,
  GET_ACCIDENTS_STATISTICS_FAIL,
} from './accidentsStatisticsTypes';

const initialState: IAccidentsStatisticsState = {
  pagination: null,
  list: [],
  statistics: null,
  _pending: false,
  _loaded: false,
  _error: null,
};

const accidentsStatisticsReducers = (
  state = initialState,
  action: accidentsStatisticsTypes,
): IAccidentsStatisticsState => {
  switch (action.type) {
    // GET_ACIDENTS_STATISTICS
    case GET_ACCIDENTS_STATISTICS:
      return {
        ...state,
        _error: null,
        _pending: true,
        _loaded: false,
      };
    case GET_ACCIDENTS_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: action.payload,
        _pending: false,
        _loaded: true,
      };
    case GET_ACCIDENTS_STATISTICS_FAIL:
      return {
        ...state,
        _error: action.payload,
        _loaded: false,
        _pending: false,
      };

    // LOGOUT
    // TODO: ADD LOGOUT case
    // case LOGOUT:
    //   return initialState;

    default:
      return state;
  }
};

export default accidentsStatisticsReducers;
