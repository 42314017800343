import {
  GET_RESOURCES_AUTOCOMPLETE_DATA,
  IGetResourcesAutocompleteAction,
} from './resourcesTypes';

export const getResourcesAutocompleteAction = (
  callback?: IGetResourcesAutocompleteAction['callback'],
  force?: boolean,
): IGetResourcesAutocompleteAction => ({
  type: GET_RESOURCES_AUTOCOMPLETE_DATA,
  callback,
  force,
});
