/* eslint-disable @typescript-eslint/no-explicit-any */
import { LOGOUT } from 'components/Auth/_redux/authTypes';
import {
  DELETE_EMPLOYEE_PHOTO,
  employeesActionsType,
  UNMOUNT_EMPLOYEE_FORM,
  DELETE_EMPLOYEE_PHOTO_SUCCESS,
  DELETE_EMPLOYEE_PHOTO_FAIL,
  UPLOAD_EMPLOYEE_PHOTO_FAIL,
  UPLOAD_EMPLOYEE_PHOTO_SUCCESS,
  UPLOAD_EMPLOYEE_PHOTO,
  DELETE_EMPLOYEE_FILE_FAIL,
  DELETE_EMPLOYEE_FILE_SUCCESS,
  DELETE_EMPLOYEE_FILE,
  UPLOAD_EMPLOYEE_FILES_FAIL,
  UPLOAD_EMPLOYEE_FILES_SUCCESS,
  UPLOAD_EMPLOYEE_FILES,
  DELETE_EMPLOYEE_FAIL,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE,
  UPDATE_EMPLOYEE_FAIL,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE,
  CREATE_EMPLOYEE_FAIL,
  CREATE_EMPLOYEE_SUCCESS,
  CREATE_EMPLOYEE,
  GET_EMPLOYEE_FAIL,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE,
  GET_EMPLOYEES_FAIL,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES,
  GET_RECRUITERS,
  IEmployeesReducer,
  GET_RECRUITERS_SUCCESS,
  GET_RECRUITERS_FAIL,
} from 'components/HumanResources/TabEmployees/_redux/employeesTypes';
import { parseFloatString } from 'utils/helpers';

const initialState: IEmployeesReducer = {
  pagination: null,
  list: [],
  files: [],
  recruiters: [],
  currentEmployee: null,
  _pending: false,
  _loaded: false,
  _error: null,
};

const employeesReducers = (
  state = initialState,
  action: employeesActionsType,
): IEmployeesReducer => {
  switch (action.type) {
    // GET EMPLOYEES
    case GET_EMPLOYEES:
      return {
        ...state,
        files: [],
        currentEmployee: null,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        list: action.payload.employees,
        pagination: action.payload.preventUpdatePagination
          ? state.pagination
          : action.payload.pagination,
        _loaded: true,
        _pending: false,
      };
    case GET_EMPLOYEES_FAIL:
      return {
        ...initialState,
        _error: action.payload,
        _loaded: false,
        _pending: false,
      };

    // GET EMPLOYEE
    case GET_EMPLOYEE:
      return { ...state, _loaded: false, _pending: true, _error: null };
    case GET_EMPLOYEE_SUCCESS:
      return {
        ...state,
        currentEmployee: {
          ...action.payload,
          salary: parseFloatString(action.payload.salary),
        },
        files: action.payload.files,
        _loaded: true,
        _pending: false,
      };
    case GET_EMPLOYEE_FAIL:
      return {
        ...state,
        _error: action.payload,
        _loaded: false,
        _pending: false,
      };

    // CREATE EMPLOYEE
    case CREATE_EMPLOYEE:
      return { ...state };
    case CREATE_EMPLOYEE_SUCCESS:
      return { ...state, files: [], currentEmployee: null };
    case CREATE_EMPLOYEE_FAIL:
      return { ...state, _error: action.payload };

    // UPDATE EMPLOYEE
    case UPDATE_EMPLOYEE:
      return { ...state };
    case UPDATE_EMPLOYEE_SUCCESS:
      return { ...state, files: [], currentEmployee: null };
    case UPDATE_EMPLOYEE_FAIL:
      return { ...state, _error: action.payload };

    // DELETE EMPLOYEE
    case DELETE_EMPLOYEE:
      return { ...state };
    case DELETE_EMPLOYEE_SUCCESS:
      return { ...state, files: [], currentEmployee: null };
    case DELETE_EMPLOYEE_FAIL:
      return { ...state, _error: action.payload };

    // UPLOAD EMPLOYEE FILES
    case UPLOAD_EMPLOYEE_FILES:
      return {
        ...state,
        files: [
          ...state.files,
          ...action.payload.files.map(file => ({
            ...file,
            loading: true,
          })),
        ],
      };
    case UPLOAD_EMPLOYEE_FILES_SUCCESS:
      return {
        ...state,
        files: state.files.map((file, index: number) =>
          index === state.files.findIndex(({ loading }) => loading)
            ? { ...action.payload, loading: false }
            : file,
        ),
      };
    case UPLOAD_EMPLOYEE_FILES_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // DELETE EMPLOYEE FILE
    case DELETE_EMPLOYEE_FILE:
      return {
        ...state,
      };
    case DELETE_EMPLOYEE_FILE_SUCCESS:
      return {
        ...state,
        files: state.files.filter(file => file.id !== action.payload),
      };
    case DELETE_EMPLOYEE_FILE_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // UPLOAD EMPLOYEE PHOTO
    case UPLOAD_EMPLOYEE_PHOTO:
      return {
        ...state,
        _error: null,
      };
    case UPLOAD_EMPLOYEE_PHOTO_SUCCESS:
      return {
        ...state,
        currentEmployee: state.currentEmployee
          ? {
              ...state.currentEmployee,
              photo_url_thumb: action.payload,
            }
          : null,
      };
    case UPLOAD_EMPLOYEE_PHOTO_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // DELETE EMPLOYEE PHOTO
    case DELETE_EMPLOYEE_PHOTO:
      return {
        ...state,
        _error: null,
      };
    case DELETE_EMPLOYEE_PHOTO_SUCCESS:
      return {
        ...state,
        currentEmployee: state.currentEmployee
          ? {
              ...state.currentEmployee,
              photo_url_thumb: '',
            }
          : null,
      };
    case DELETE_EMPLOYEE_PHOTO_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };

    case GET_RECRUITERS:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case GET_RECRUITERS_SUCCESS:
      return {
        ...state,
        recruiters: action.payload,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case GET_RECRUITERS_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: action.payload,
      };

    //  OTHER
    case UNMOUNT_EMPLOYEE_FORM:
      return {
        ...state,
        files: [],
        list: [],
        currentEmployee: null,
      };

    case LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
};

export default employeesReducers;
