import { AxiosError } from 'axios';

import {
  IDeleteFileAction,
  IDeleteFileActionFail,
  IDeleteFileActionSuccess,
  IUploadFilesAction,
  IUploadFilesActionFail,
  IUploadFilesActionSuccess,
} from '../../../../redux/_common/commonTypes';
import {
  IWithError,
  IWithLoaded,
  IWithPending,
} from '../../../../utils/models';
import {
  SubscriptionPlans,
  CurrentSubscriptionPlan,
  FormattedSubscriptionPlans,
} from '../SettingsProfile/_models/models';

export type ICompanyFile = {
  id: number;
  file_name: string;
  file_size: number;
  content_type: string;
  file_url: string;
  loading?: boolean;
};

export type CompanySettingsReduces = IWithLoaded &
  IWithError &
  IWithPending & {
    monthlyPlans: FormattedSubscriptionPlans;
    yearlyPlans: FormattedSubscriptionPlans;
    customPlans: FormattedSubscriptionPlans;
    activePlan: CurrentSubscriptionPlan | null;
    files: ICompanyFile[];
  };

export const GET_SUBSCRIPTION_PLANS =
  '[@@CompanySettings] GET_SUBSCRIPTION_PLANS';
export const GET_SUBSCRIPTION_PLANS_SUCCESS =
  '[@@CompanySettings] GET_SUBSCRIPTION_PLANS_SUCCESS';

export interface IGetSubscriptionPlansSuccess {
  type: typeof GET_SUBSCRIPTION_PLANS_SUCCESS;
  payload: SubscriptionPlans;
}

export interface IGetSubscriptionPlans {
  type: typeof GET_SUBSCRIPTION_PLANS;
  payload: {
    isPublic: boolean;
  };
}

export const GET_COMPANY_FILES = '[@@CompanySettings] GET_COMPANY_FILES';
export const GET_COMPANY_FILES_SUCCESS =
  '[@@CompanySettings] GET_COMPANY_FILES_SUCCESS';

export const GET_COMPANY_FILES_FAIL =
  '[@@CompanySettings] GET_COMPANY_FILES_FAIL';
export interface IGetCompanyFilesSuccess {
  type: typeof GET_COMPANY_FILES_SUCCESS;
  payload: ICompanyFile[];
}

export interface IGetCompanyFiles {
  type: typeof GET_COMPANY_FILES;
}

export interface IGetCompanyFilesFail {
  type: typeof GET_COMPANY_FILES_FAIL;
  payload: AxiosError;
}

export const GET_ACTIVE_PLAN = '[@@CompanySettings] GET_ACTIVE_PLAN';
export const GET_ACTIVE_PLAN_SUCCESS =
  '[@@CompanySettings] GET_ACTIVE_PLAN_SUCCESS';

export interface IGetActivePlanSuccess {
  type: typeof GET_ACTIVE_PLAN_SUCCESS;
  payload: CurrentSubscriptionPlan;
}

export interface IGetActivePlanPlans {
  type: typeof GET_ACTIVE_PLAN;
}

export const UPLOAD_COMPANY_FILES = '[@@Truck] UPLOAD_COMPANY_FILES';
export const UPLOAD_COMPANY_FILES_SUCCESS =
  '[@@CompanySettings] UPLOAD_COMPANY_FILES_SUCCESS';
export const UPLOAD_COMPANY_FILES_FAIL = '[@@Truck] UPLOAD_COMPANY_FILES_FAIL';

export interface IUploadCompanyFilesAction extends IUploadFilesAction {
  type: typeof UPLOAD_COMPANY_FILES;
}
export interface IUploadCompanyFilesActionSuccess
  extends IUploadFilesActionSuccess {
  type: typeof UPLOAD_COMPANY_FILES_SUCCESS;
}
export interface IUploadCompanyFilesActionFail extends IUploadFilesActionFail {
  type: typeof UPLOAD_COMPANY_FILES_FAIL;
}

export const DELETE_COMPANY_FILE = '[@@Truck] DELETE_COMPANY_FILE';
export const DELETE_COMPANY_FILE_SUCCESS =
  '[@@CompanySettings] DELETE_COMPANY_FILE_SUCCESS';
export const DELETE_COMPANY_FILE_FAIL = '[@@Truck] DELETE_COMPANY_FILE_FAIL';

export interface IDeleteCompanyFileAction extends IDeleteFileAction {
  type: typeof DELETE_COMPANY_FILE;
}
export interface IDeleteCompanyFileActionSuccess
  extends IDeleteFileActionSuccess {
  type: typeof DELETE_COMPANY_FILE_SUCCESS;
}
export interface IDeleteCompanyFileActionFail extends IDeleteFileActionFail {
  type: typeof DELETE_COMPANY_FILE_FAIL;
}

export type companySettingsTypes =
  | IGetSubscriptionPlans
  | IGetSubscriptionPlansSuccess
  | IGetActivePlanPlans
  | IGetActivePlanSuccess
  | IUploadCompanyFilesAction
  | IUploadCompanyFilesActionSuccess
  | IUploadCompanyFilesActionFail
  | IDeleteCompanyFileAction
  | IDeleteCompanyFileActionSuccess
  | IDeleteCompanyFileActionFail
  | IGetCompanyFiles
  | IGetCompanyFilesSuccess
  | IGetCompanyFilesFail;
