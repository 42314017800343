import {
  geoFencesTypes,
  GET_GEOFENCES,
  GET_GEOFENCES_SUCCESS,
  GET_GEOFENCES_FAIL,
  GET_GEOFENCE,
  GET_GEOFENCE_SUCCESS,
  GET_GEOFENCE_FAIL,
  CREATE_GEOFENCE,
  CREATE_GEOFENCE_SUCCESS,
  CREATE_GEOFENCE_FAIL,
  UPDATE_GEOFENCE,
  UPDATE_GEOFENCE_SUCCESS,
  UPDATE_GEOFENCE_FAIL,
  DELETE_GEOFENCE,
  DELETE_GEOFENCE_SUCCESS,
  DELETE_GEOFENCE_FAIL,
  IGeoFencesState,
  CLEAR_STATE,
  GET_GEOFENCE_ALERTS,
  GET_GEOFENCE_ALERTS_SUCCESS,
  GET_GEOFENCE_ALERTS_FAIL,
} from './geoFencesTypes';

const initialState: IGeoFencesState = {
  pagination: null,
  list: [],
  currentGeoFence: null,
  currentGeoFenceAlerts: [],
  _pending: false,
  _loaded: false,
  _error: null,
};

const geoFencesReducers = (state = initialState, action: geoFencesTypes) => {
  switch (action.type) {
    // GET_GEOFENCES
    case GET_GEOFENCES:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case GET_GEOFENCES_SUCCESS:
      return {
        ...state,
        list: action.payload.geofences,
        pagination: action.payload.pagination,
        _loaded: true,
        _pending: false,
      };
    case GET_GEOFENCES_FAIL:
      return {
        ...state,
        _error: action.payload,
        _loaded: false,
        _pending: false,
      };

    // GET_GEOFENCE
    case GET_GEOFENCE:
      return {
        ...state,
        _error: null,
      };
    case GET_GEOFENCE_SUCCESS:
      return {
        ...state,
        currentGeoFence: action.payload.geofence,
      };
    case GET_GEOFENCE_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // CREATE_GEOFENCE
    case CREATE_GEOFENCE:
      return { ...state, _error: null };
    case CREATE_GEOFENCE_SUCCESS:
      return {
        ...state,
        currentGeoFence: action.payload.geofence,
        list: [action.payload.geofence, ...state.list],
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        pagination: { ...state.pagination, total: state.pagination.total + 1 },
      };

    case CREATE_GEOFENCE_FAIL:
      return { ...state, _error: action.payload };

    // UPDATE_GEOFENCE
    case UPDATE_GEOFENCE:
      return { ...state, _error: null };
    case UPDATE_GEOFENCE_SUCCESS:
      return {
        ...state,
        currentGeoFence: action.payload.geofence,
        list: state.list.map(item =>
          item.id === action.payload.geofence.id
            ? action.payload.geofence
            : item,
        ),
      };
    case UPDATE_GEOFENCE_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // GET_GEOFENCE_ALERTS
    case GET_GEOFENCE_ALERTS:
      return { ...state, _error: null };
    case GET_GEOFENCE_ALERTS_SUCCESS:
      return {
        ...state,
        currentGeoFenceAlerts: action.payload.geofences_alerts,
      };
    case GET_GEOFENCE_ALERTS_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // DELETE_GEOFENCE
    case DELETE_GEOFENCE:
      return { ...state, _error: null };
    case DELETE_GEOFENCE_SUCCESS:
      return {
        ...state,
        currentGeoFence: null,
        list: state.list.filter(item => item.id !== action.payload),
        pagination: { ...state.pagination, total: state.pagination.total - 1 },
      };
    case DELETE_GEOFENCE_FAIL:
      return { ...state, _error: action.payload };

    case CLEAR_STATE: {
      return { ...state, ...action.payload };
    }

    default:
      return state;
  }
};

export default geoFencesReducers;
