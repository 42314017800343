import { stopSubmit, startSubmit } from 'redux-form';
import {
  takeLatest,
  call,
  put,
  select,
  takeLeading,
  takeEvery,
} from 'redux-saga/effects';

import { setPaginationAction } from 'components/_common/Navigation/Pagination/_redux/paginationActions';
import { paginationSelector } from 'components/_common/Navigation/Pagination/_redux/paginationSelectors';
import { IOrder } from 'components/Dispatch/TabOrders/_models/orderModels';
import {
  orderFileToFormData,
  orderAddressFileToFormData,
  orderFileCategoryToFormData,
  orderAddressReceiptToFormData,
} from 'components/Dispatch/TabOrders/_redux/helpers';
import {
  createOrderAction,
  deleteOrderAction,
  deleteOrderFileAction,
  getOrderAction,
  updateOrderAction,
  uploadOrderFilesAction,
  getOrdersAction,
  deleteOrderAddressAction,
  changeOrderFileCategoryAction,
  updateOrderAddressAction,
  uploadOrderAddressFilesAction,
  deleteOrderAddressFileAction,
  changeOrderAddressFileCategoryAction,
  updateOrderAddressReceiptPhotosAction,
  updateOrderAddressStatusAction,
  updateOrderStatusAction,
  getTripAndSingleOrdersAction,
  connectOrdersToTripAction,
  moveTripAddressesAction,
  removeOrderConnectionAction,
  reassignAction,
} from 'components/Dispatch/TabOrders/_redux/ordersActions';
import {
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAIL,
  GET_ORDER,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAIL,
  CREATE_ORDER,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAIL,
  UPDATE_ORDER,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  DELETE_ORDER,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAIL,
  UPLOAD_ORDER_FILES,
  UPLOAD_ORDER_FILES_SUCCESS,
  UPLOAD_ORDER_FILES_FAIL,
  DELETE_ORDER_FILE,
  DELETE_ORDER_FILE_SUCCESS,
  DELETE_ORDER_FILE_FAIL,
  DELETE_ORDER_ADDRESS,
  DELETE_ORDER_ADDRESS_SUCCESS,
  DELETE_ORDER_ADDRESS_FAIL,
  CHANGE_ORDER_FILE_CATEGORY,
  CHANGE_ORDER_FILE_CATEGORY_SUCCESS,
  CHANGE_ORDER_FILE_CATEGORY_FAIL,
  UPDATE_ORDER_ADDRESS,
  UPDATE_ORDER_ADDRESS_SUCCESS,
  UPDATE_ORDER_ADDRESS_FAIL,
  DELETE_ORDER_ADDRESS_FILE,
  UPLOAD_ORDER_ADDRESS_FILES,
  UPLOAD_ORDER_ADDRESS_FILES_SUCCESS,
  UPLOAD_ORDER_ADDRESS_FILES_FAIL,
  DELETE_ORDER_ADDRESS_FILE_SUCCESS,
  DELETE_ORDER_ADDRESS_FILE_FAIL,
  CHANGE_ORDER_ADDRESS_FILE_CATEGORY,
  CHANGE_ORDER_ADDRESS_FILE_CATEGORY_SUCCESS,
  CHANGE_ORDER_ADDRESS_FILE_CATEGORY_FAIL,
  UPDATE_ORDER_ADDRESS_RECEIPT_PHOTOS,
  UPDATE_ORDER_ADDRESS_RECEIPT_PHOTOS_SUCCESS,
  UPDATE_ORDER_ADDRESS_RECEIPT_PHOTOS_FAIL,
  UPDATE_ORDER_ADDRESS_STATUS,
  UPDATE_ORDER_ADDRESS_STATUS_SUCCESS,
  UPDATE_ORDER_ADDRESS_STATUS_FAIL,
  UPDATE_ORDER_STATUS,
  UPDATE_ORDER_STATUS_FAIL,
  UPDATE_ORDER_STATUS_SUCCESS,
  GET_TRIP_AND_SINGLE_ORDERS,
  GET_TRIP_AND_SINGLE_ORDERS_SUCCESS,
  GET_TRIP_AND_SINGLE_ORDERS_FAIL,
  CONNECT_ORDERS_TO_TRIP,
  CONNECT_ORDERS_TO_TRIP_SUCCESS,
  CONNECT_ORDERS_TO_TRIP_FAIL,
  IGetTripAndSingleOrdersSuccessAction,
  IGetTripAndSingleOrdersFailAction,
  ICreateOrderActionSuccess,
  ICreateOrderActionFail,
  IConnectOrdersToTripSuccessAction,
  IConnectOrdersToTripFailAction,
  MOVE_TRIP_ADDRESSES,
  MOVE_TRIP_ADDRESSES_SUCCESS,
  MOVE_TRIP_ADDRESSES_FAIL,
  REMOVE_ORDER_CONNECTION,
  REMOVE_ORDER_CONNECTION_SUCCESS,
  REMOVE_ORDER_CONNECTION_FAIL,
  IRemoveOrderConnectionActionFail,
  IRemoveOrderConnectionActionSuccess,
  UPDATE_DRIVER,
  UPDATE_DRIVER_SUCCESS,
  UPDATE_DRIVER_FAIL,
} from 'components/Dispatch/TabOrders/_redux/ordersTypes';
import { DISPATCH_APIS } from 'constants/api';
import { getErrorsForOrderForm } from 'utils/helpers';
import api from 'utils/requests';

function* getOrdersSaga({ payload }: ReturnType<typeof getOrdersAction>) {
  const { query, callback } = payload;
  try {
    const { per_page, page } = yield select(paginationSelector);
    const { data } = yield call(() =>
      api.get(DISPATCH_APIS.ORDERS, {
        params: {
          page,
          per_page,
          ...(query?.dispatcher && {
            'filter[dispatcher]': query.dispatcher,
          }),
        },
      }),
    );
    yield put({ type: GET_ORDERS_SUCCESS, payload: data });

    const { pagination } = data;

    // Pagination set
    yield put(setPaginationAction(pagination));

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put({ type: GET_ORDERS_FAIL, payload: e });
  }
}

function* getOrderSaga({ id }: ReturnType<typeof getOrderAction>) {
  try {
    const { data } = yield call(() => api.get(`/v1/orders/${id}`));
    yield put({ type: GET_ORDER_SUCCESS, payload: data.order });
  } catch (e) {
    if (api.isAxiosError(e)) yield put({ type: GET_ORDER_FAIL, payload: e });
  }
}

function* getTripAndSingleOrdersSaga({
  payload: { createdOrderId, driverId, callback },
}: ReturnType<typeof getTripAndSingleOrdersAction>) {
  try {
    const { data } = yield call(() =>
      api.get(`/v1/orders/drivers/${driverId}/connection_available`),
    );
    yield put<IGetTripAndSingleOrdersSuccessAction>({
      type: GET_TRIP_AND_SINGLE_ORDERS_SUCCESS,
      payload: {
        data,
        orderIdToExclude: createdOrderId,
      },
    });
    const ordersForCallback =
      data.orders &&
      data.orders.filter(
        (order: IOrder) => order && +order.id !== +createdOrderId,
      );
    if (callback) callback(ordersForCallback);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetTripAndSingleOrdersFailAction>({
        type: GET_TRIP_AND_SINGLE_ORDERS_FAIL,
        payload: e,
      });
  }
}

function* connectOrdersToTripSaga({
  payload: {
    data: { orderId, driverId, orderConnectId },
    callback,
  },
}: ReturnType<typeof connectOrdersToTripAction>) {
  try {
    const { data } = yield call(() =>
      // orderConnectId = order id we connecting TO, orderId - order id we are connecting to another one
      api.post(
        `/v1/orders/${orderId}/drivers/${driverId}/connect/${orderConnectId}`,
      ),
    );
    yield put<IConnectOrdersToTripSuccessAction>({
      type: CONNECT_ORDERS_TO_TRIP_SUCCESS,
      payload: { data },
    });
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IConnectOrdersToTripFailAction>({
        type: CONNECT_ORDERS_TO_TRIP_FAIL,
        payload: e,
      });
  }
}

function* removeOrderConnectionSaga({
  payload: {
    data: { order },
    callback,
  },
}: ReturnType<typeof removeOrderConnectionAction>) {
  try {
    const { parentOrderId, id, drivers } = order;
    const driverId = drivers && drivers[0] && drivers[0].id;
    yield call(() =>
      api.delete(
        `/v1/orders/${parentOrderId}/drivers/${driverId}/connect/${id}`,
      ),
    );
    yield put<IRemoveOrderConnectionActionSuccess>({
      type: REMOVE_ORDER_CONNECTION_SUCCESS,
    });
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IRemoveOrderConnectionActionFail>({
        type: REMOVE_ORDER_CONNECTION_FAIL,
        payload: e,
      });
  }
}

function* createOrderSaga({
  payload: { callback, values },
}: ReturnType<typeof createOrderAction>) {
  try {
    yield put(startSubmit('createAndUpdateOrderForm'));
    const { data } = yield call(() =>
      api.post('/v1/orders', { order: values }),
    );
    yield put<ICreateOrderActionSuccess>({
      type: CREATE_ORDER_SUCCESS,
      payload: data.order,
    });
    yield put(stopSubmit('createAndUpdateOrderForm'));
    if (callback) callback(data.order);
  } catch (e) {
    if (api.isAxiosError(e)) {
      yield put<ICreateOrderActionFail>({
        type: CREATE_ORDER_FAIL,
        payload: e,
      });
      yield put(
        stopSubmit('createAndUpdateOrderForm', getErrorsForOrderForm(e)),
      ); // ToDo rename fields to display errors
    }
  }
}

function* updateOrderSaga({
  payload: { callback, values },
}: ReturnType<typeof updateOrderAction>) {
  try {
    yield put(startSubmit('createAndUpdateOrderForm'));
    const { data } = yield call(() =>
      api.patch(`/v1/orders/${values.id}`, { order: values }),
    );
    yield put({ type: UPDATE_ORDER_SUCCESS, payload: data.order });
    yield put(stopSubmit('createAndUpdateOrderForm'));
    if (callback) callback(data.order);
  } catch (e) {
    if (api.isAxiosError(e)) {
      yield put({ type: UPDATE_ORDER_FAIL, payload: e });
      yield put(
        stopSubmit('createAndUpdateOrderForm', getErrorsForOrderForm(e)),
      );
    }
  }
}

function* deleteOrderSaga({
  payload: { id, callback },
}: ReturnType<typeof deleteOrderAction>) {
  try {
    yield call(() => api.delete(`/v1/orders/${id}`));
    yield put({ type: DELETE_ORDER_SUCCESS });
    if (callback) callback(id);
  } catch (e) {
    if (api.isAxiosError(e)) yield put({ type: DELETE_ORDER_FAIL, payload: e });
  }
}

// UPDATE ORDER STATUS
function* updateOrderStatusSaga({
  payload: { orderId, action },
}: ReturnType<typeof updateOrderStatusAction>) {
  try {
    yield call(() => api.put(`/v1/orders/${orderId}/${action}`));
    yield put({
      type: UPDATE_ORDER_STATUS_SUCCESS,
      payload: action === 'cancel' ? 'tonu' : 'pending', // ToDo add more status if need
    });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({ type: UPDATE_ORDER_STATUS_FAIL, payload: e });
  }
}

// ORDER FILES START
function* uploadOrderFilesSaga({
  payload: { files, itemId, callback },
}: ReturnType<typeof uploadOrderFilesAction>) {
  const url = itemId ? `/v1/orders/${itemId}/files` : `/v1/orders/files`;

  for (const fileObj of files) {
    const { file, visible_for_driver, category_id } = fileObj;
    yield call(function* foo() {
      try {
        const { data } = yield call(() =>
          api.post(
            url,
            orderFileToFormData({
              file,
              visible_for_driver: visible_for_driver || false,
              category_id: category_id || 0,
            }),
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            },
          ),
        );
        yield put({ type: UPLOAD_ORDER_FILES_SUCCESS, payload: data.file });
        if (callback) callback(data.file.id);
      } catch (e) {
        if (api.isAxiosError(e))
          yield put({ type: UPLOAD_ORDER_FILES_FAIL, payload: e });
      }
    });
  }
}

function* changeOrderFileCategorySaga({
  payload: { fileId, category_id, visible_for_driver, orderId },
}: ReturnType<typeof changeOrderFileCategoryAction>) {
  const url = orderId
    ? `/v1/orders/${orderId}/files/${fileId}`
    : `/v1/orders/files/${fileId}`;

  try {
    const { data } = yield call(() =>
      api.patch(
        url,
        orderFileCategoryToFormData({ category_id, visible_for_driver }),
        { headers: { 'Content-Type': 'multipart/form-data' } },
      ),
    );

    yield put({
      type: CHANGE_ORDER_FILE_CATEGORY_SUCCESS,
      payload: data.file,
    });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({ type: CHANGE_ORDER_FILE_CATEGORY_FAIL, payload: e });
  }
}

function* deleteOrderFileSaga({
  payload: { fileId, itemId: orderId, callback },
}: ReturnType<typeof deleteOrderFileAction>) {
  const url = orderId
    ? `/v1/orders/${orderId}/files/${fileId}`
    : `/v1/orders/files/${fileId}`;

  try {
    yield call(() => api.delete(url));
    yield put({ type: DELETE_ORDER_FILE_SUCCESS, payload: fileId });
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({ type: DELETE_ORDER_FILE_FAIL, payload: e });
  }
}
// ORDER FILES END

// ORDER ADDRESS START
function* updateOrderAddressSaga({
  payload: { orderId, addressId, ...rest },
}: ReturnType<typeof updateOrderAddressAction>) {
  try {
    const { data } = yield call(() =>
      api.patch(`/v1/orders/${orderId}/addresses/${addressId}`, {
        orders_address: rest,
      }),
    );
    yield put({
      type: UPDATE_ORDER_ADDRESS_SUCCESS,
      payload: { addressId, address: data.address },
    });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({ type: UPDATE_ORDER_ADDRESS_FAIL, payload: e });
  }
}

function* deleteOrderAddressSaga({
  payload: { addressId, orderId },
}: ReturnType<typeof deleteOrderAddressAction>) {
  try {
    yield call(() =>
      api.delete(`/v1/orders/${orderId}/addresses/${addressId}`),
    );
    yield put({ type: DELETE_ORDER_ADDRESS_SUCCESS });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({ type: DELETE_ORDER_ADDRESS_FAIL, payload: e });
  }
}

// UPDATE ORDER ADDRESS STATUS
function* updateOrderAddressStatusSaga({
  payload: { orderId, addressId, action },
}: ReturnType<typeof updateOrderAddressStatusAction>) {
  try {
    yield call(() =>
      api.put(`/v1/orders/${orderId}/addresses/${addressId}/${action}`),
    );
    yield put({ type: UPDATE_ORDER_ADDRESS_STATUS_SUCCESS, payload: action });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({ type: UPDATE_ORDER_ADDRESS_STATUS_FAIL, payload: e });
  }
}

// ORDER ADDRESS END

// ORDER ADDRESS FILES START
function* uploadOrderAddressFilesSaga({
  payload: { files, itemId: orderId, actionConfig, callback },
}: ReturnType<typeof uploadOrderAddressFilesAction>) {
  const { addressId, fileCategory: categoryType } = actionConfig || {};
  for (const fileObj of files) {
    try {
      const { data } = yield call(() =>
        api.post(
          `/v1/orders/${orderId}/addresses/${addressId}/${categoryType}`,
          orderAddressFileToFormData(categoryType, fileObj),
        ),
      );

      yield put({
        type: UPLOAD_ORDER_ADDRESS_FILES_SUCCESS,
        payload: {
          addressId,
          categoryType,
          file:
            categoryType === 'receipt_files' ? data.receipt_file : data.file,
        },
      });

      if (callback) callback(data.file.id);
    } catch (e) {
      if (api.isAxiosError(e))
        yield put({ type: UPLOAD_ORDER_ADDRESS_FILES_FAIL, payload: e });
    }
  }
}

function* deleteOrderAddressFileSaga({
  payload: { orderId, fileCategory, addressId, filesIds },
}: ReturnType<typeof deleteOrderAddressFileAction>) {
  for (const id of filesIds) {
    try {
      yield call(() =>
        api.delete(
          `/v1/orders/${orderId}/addresses/${addressId}/${fileCategory}/${id}`,
        ),
      );
      yield put({
        type: DELETE_ORDER_ADDRESS_FILE_SUCCESS,
        payload: {
          orderId,
          address_id: addressId,
          categoryType: fileCategory,
          id,
        },
      });
    } catch (e) {
      if (api.isAxiosError(e))
        yield put({ type: DELETE_ORDER_ADDRESS_FILE_FAIL, payload: e });
    }
  }
}

function* changeOrderAddressFileCategorySaga({
  payload: { filesIds, categoryType, order_id, address_id },
}: ReturnType<typeof changeOrderAddressFileCategoryAction>) {
  for (const fileId of filesIds) {
    const url =
      categoryType === 'cargo_photos'
        ? `/v1/orders/${order_id}/addresses/${address_id}/cargo_photos/${fileId}/move_to_proof_photos`
        : `/v1/orders/${order_id}/addresses/${address_id}/proof_photos/${fileId}/move_to_cargo_photos`;

    try {
      yield call(() => api.put(url));
      yield put({
        type: CHANGE_ORDER_ADDRESS_FILE_CATEGORY_SUCCESS,
        payload: { fileId, categoryType, order_id, address_id },
      });
    } catch (e) {
      if (api.isAxiosError(e))
        yield put({
          type: CHANGE_ORDER_ADDRESS_FILE_CATEGORY_FAIL,
          payload: e,
        });
    }
  }
}

function* updateOrderAddressReceiptPhotosSaga({
  payload: { order_id, address_id, photos },
}: ReturnType<typeof updateOrderAddressReceiptPhotosAction>) {
  for (const photo of photos) {
    const url = `/v1/orders/${order_id}/addresses/${address_id}/receipt_files/${photo.id}`;

    try {
      const { data } = yield call(() =>
        api.patch(url, orderAddressReceiptToFormData(photo)),
      );

      yield put({
        type: UPDATE_ORDER_ADDRESS_RECEIPT_PHOTOS_SUCCESS,
        payload: { order_id, address_id, photo: data.receipt_file },
      });
    } catch (e) {
      if (api.isAxiosError(e))
        yield put({
          type: UPDATE_ORDER_ADDRESS_RECEIPT_PHOTOS_FAIL,
          payload: e,
        });
    }
  }
}
// ORDER ADDRESS FILES END

// MOVE_TRIP_ADDRESSES
function* moveTripAddressesSaga({
  payload: {
    data: { orderId, tripAddresses },
    callback,
  },
}: ReturnType<typeof moveTripAddressesAction>) {
  const url = `/v1/orders/${orderId}/trip_addresses/move`;

  try {
    yield call(() => api.post(url, tripAddresses));
    yield put({
      type: MOVE_TRIP_ADDRESSES_SUCCESS,
    });
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({ type: MOVE_TRIP_ADDRESSES_FAIL, payload: e });
  }
}

function* reassignTruckSaga({
  payload: { callback, values },
}: ReturnType<typeof reassignAction>) {
  try {
    const { data } = yield call(() =>
      api.post('/v1/fleets/connections', { ...values }),
    );
    yield put({ type: UPDATE_DRIVER_SUCCESS, payload: data.data });
    yield put(stopSubmit('createAndUpdateDriverForm'));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({ type: UPDATE_DRIVER_FAIL, payload: e });
  }
}

export default function* ordersSaga() {
  yield takeLatest(GET_ORDERS, getOrdersSaga);
  yield takeLatest(GET_ORDER, getOrderSaga);
  yield takeLeading(CREATE_ORDER, createOrderSaga);
  yield takeLeading(UPDATE_ORDER, updateOrderSaga);
  yield takeLatest(DELETE_ORDER, deleteOrderSaga);
  yield takeEvery(UPDATE_ORDER_STATUS, updateOrderStatusSaga);
  yield takeLatest(UPLOAD_ORDER_FILES, uploadOrderFilesSaga);
  yield takeEvery(CHANGE_ORDER_FILE_CATEGORY, changeOrderFileCategorySaga);
  yield takeEvery(DELETE_ORDER_FILE, deleteOrderFileSaga);
  yield takeLeading(UPDATE_ORDER_ADDRESS, updateOrderAddressSaga);
  yield takeEvery(UPDATE_ORDER_ADDRESS_STATUS, updateOrderAddressStatusSaga);
  yield takeLeading(DELETE_ORDER_ADDRESS, deleteOrderAddressSaga);
  yield takeEvery(UPLOAD_ORDER_ADDRESS_FILES, uploadOrderAddressFilesSaga);
  yield takeEvery(DELETE_ORDER_ADDRESS_FILE, deleteOrderAddressFileSaga);
  yield takeEvery(
    CHANGE_ORDER_ADDRESS_FILE_CATEGORY,
    changeOrderAddressFileCategorySaga,
  );
  yield takeLeading(
    UPDATE_ORDER_ADDRESS_RECEIPT_PHOTOS,
    updateOrderAddressReceiptPhotosSaga,
  );
  yield takeLatest(GET_TRIP_AND_SINGLE_ORDERS, getTripAndSingleOrdersSaga);
  yield takeLeading(CONNECT_ORDERS_TO_TRIP, connectOrdersToTripSaga);
  yield takeLeading(MOVE_TRIP_ADDRESSES, moveTripAddressesSaga);
  yield takeLatest(REMOVE_ORDER_CONNECTION, removeOrderConnectionSaga);
  yield takeLatest(UPDATE_DRIVER, reassignTruckSaga);
}
