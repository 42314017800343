/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { clone, dissoc, isEmpty, sort } from 'ramda';

import {
  IChatMessage,
  IChatRoom,
  IChatUser,
  IChatFile,
} from '../_models/chatMainEntities';

export const sortByPropLastActivity = (arr: Array<any>) =>
  sort((a: IChatRoom, b: IChatRoom): any => {
    if (a && b && a.last_activity_at && b.last_activity_at)
      return moment(a.last_activity_at).isAfter(b.last_activity_at) ? -1 : 1;
    return 0;
  }, arr);

export const sortByType = (arr: Array<any>) =>
  sort((a: File, b: File): any => {
    if (a.type && b.type) return `${a.type}` < `${b.type}` ? -1 : 1;
    return 0;
  }, arr);

export const sortByContentType = (arr: Array<any>) =>
  sort((a: IChatFile, b: IChatFile): any => {
    if (a.content_type && b.content_type)
      return `${a.content_type}` < `${b.content_type}` ? -1 : 1;
    return 0;
  }, arr);

export const sortByTimestamp = (arr: Array<IChatMessage>) =>
  sort((a: IChatMessage, b: IChatMessage): any => {
    if (a.sent_at_timestamp && b.sent_at_timestamp)
      return a.sent_at_timestamp > b.sent_at_timestamp ? -1 : 1;
    return 0;
  }, arr);

export const processDataOnSendMessage = (
  rooms: IChatRoom[] | null,
  message: any,
) => {
  if (rooms) {
    let clonedRooms = clone(rooms);
    clonedRooms = clonedRooms.map((room: IChatRoom) => {
      const clonedRoom = clone(room);
      if (room.id === message.chatroom_id) {
        clonedRoom.last_activity_at = message.sent_at;
        clonedRoom.last_message = message;
      }
      return clonedRoom;
    });
    return sortByPropLastActivity([...clonedRooms]);
  }
  return rooms;
};

export const processDataOnReadMessages = (
  rooms: IChatRoom[] | null,
  room: IChatRoom | null,
  unread_number: number,
) => {
  if (rooms && room) {
    let clonedRooms = clone(rooms);
    clonedRooms = clonedRooms.map((mappedRoom: IChatRoom) => {
      const clonedRoom = clone(mappedRoom);
      if (mappedRoom.id === room.id) {
        clonedRoom.unread_number = unread_number;
        clonedRoom.last_message.read = true;
      }
      return clonedRoom;
    });
    return clonedRooms;
  }
  return rooms;
};

// Date label related functions
const defineDateView = (date: any): any => {
  return moment(date).isSame(moment(), 'day') ? 'Today' : date;
};

export const defineDateLabelHandler = (
  firstMessage: IChatMessage,
  secondMessageDate: IChatMessage,
) => {
  let date_label: any = '';
  if (firstMessage && secondMessageDate) {
    const { sent_at_timestamp: firstDate } = firstMessage;
    const { sent_at_timestamp: secondDate } = secondMessageDate;
    if (firstDate && secondDate) {
      if (moment(firstDate).isAfter(moment(secondDate), 'day')) {
        date_label = defineDateView(firstDate);
      }
    }
  }
  return date_label || '';
};

export const defineShowInfoHandler = (
  firstMessage: IChatMessage,
  secondMessageDate: IChatMessage,
) => {
  if (firstMessage && secondMessageDate) {
    const { sent_at_timestamp: firstDate, self: firstSelf } = firstMessage;
    const { sent_at_timestamp: secondDate, self: secondSelf } =
      secondMessageDate;
    if (firstSelf !== secondSelf) return true;
    if (firstDate && secondDate) {
      return moment(firstDate).isAfter(moment(secondDate), 'minute');
    }
  }
  return false;
};

const markFirstMessage = (
  currentMessage: IChatMessage,
  nextMessage: IChatMessage,
) => {
  if (currentMessage && nextMessage) {
    const { sent_at_timestamp: firstDate, self: currentSelf } = currentMessage;
    const { sent_at_timestamp: secondDate, self: nextSelf } = nextMessage;

    if (currentSelf !== nextSelf) return true;
    if (firstDate && secondDate) {
      return moment(firstDate).isAfter(moment(secondDate), 'minute');
    }
  }

  return true;
};

export const processMessagesForDateLabels = (messages: IChatMessage[]) => {
  let clonedMessages = clone(messages);

  // Clear date and time labels to assign new ones later in the function
  const deleteDateLabelField = (x: IChatMessage) => dissoc('date_label', x);
  const deleteShowInfoField = (x: IChatMessage) => dissoc('isLastMessage', x);
  clonedMessages = clonedMessages.map(deleteDateLabelField) as IChatMessage[];
  clonedMessages = clonedMessages.map(deleteShowInfoField) as IChatMessage[];

  // Sort by timestamp on updates
  clonedMessages = sortByTimestamp(clonedMessages);

  // Process new labels for messages
  const preparedMessages = clonedMessages.map(
    (mappedMessage: IChatMessage, index: number) => {
      const clonedMapped = clone(mappedMessage);

      // Sort files
      if (clonedMapped.files && !isEmpty(clonedMapped.files))
        clonedMapped.files = sortByContentType(clonedMapped.files);

      // Date labels handlers
      const dateLabelResult = defineDateLabelHandler(
        mappedMessage,
        clonedMessages[index + 1],
      );
      if (dateLabelResult) clonedMapped.date_label = dateLabelResult;

      // Time to show on messages handlers
      const showInfoResult = defineShowInfoHandler(
        mappedMessage,
        clonedMessages[index + 1],
      );
      if (showInfoResult) {
        clonedMessages[index + 1].isLastMessage = true;
      }

      // Mark first message
      clonedMapped.isFirstMessage = markFirstMessage(
        mappedMessage,
        clonedMessages[index + 1],
      );

      // First message handler
      if (index === 0) {
        clonedMapped.isLastMessage = true;
      }
      // Last message handler
      if (index === clonedMessages.length - 1) {
        clonedMapped.date_label = defineDateView(
          clonedMapped.sent_at_timestamp,
        );
      }

      return clonedMapped;
    },
  );

  return preparedMessages;
};

// Socket data handler
export const processSocketData = (state: any, payload: any) => {
  const { messages, rooms, room } = state;
  let clonedState = clone(state);
  const { type, ...data } = payload;

  switch (type) {
    case 'message_read': {
      const { ids, chatroom_id } = data;
      if (messages && room && room.id === chatroom_id) {
        let clonedMessages = clone(messages);
        clonedMessages = clonedMessages.map((message: IChatMessage) => {
          const clonedMessage = clone(message);
          if (message.chatroom_id === chatroom_id) {
            ids.forEach((id: number) => {
              if (message.id === id) clonedMessage.read = true;
            });
          }
          return clonedMessage;
        });
        clonedState = {
          ...clonedState,
          messages: processMessagesForDateLabels(clonedMessages),
        };
      }

      if (rooms) {
        let clonedRooms = clone(rooms);
        clonedRooms = clonedRooms.map((mappedRoom: IChatRoom) => {
          const clonedRoom = clone(mappedRoom);
          if (mappedRoom.id === chatroom_id) {
            ids.forEach((id: number) => {
              if (clonedRoom.last_message.id === id)
                clonedRoom.last_message.read = true;
            });
          }
          return clonedRoom;
        });
        clonedState = {
          ...clonedState,
          rooms: sortByPropLastActivity([...clonedRooms]),
        };
      }

      return clonedState;
    }

    case 'new_message': {
      const audio = new Audio('/sounds/chat-message.mov');
      audio.play();
      const {
        id,
        chatroom_id,
        text,
        self,
        sent_at,
        unread_number,
        files,
        sent_at_timestamp,
      } = data;

      if (messages && room && room.id === chatroom_id) {
        let clonedMessages = clone(messages);
        clonedMessages = [
          {
            id,
            chatroom_id,
            text,
            self,
            sent_at,
            files,
            sent: true,
            read: false,
            sent_at_timestamp,
          },
          ...clonedMessages,
        ];

        clonedState = {
          ...clonedState,
          messages: processMessagesForDateLabels(clonedMessages),
        };
      }

      if (rooms) {
        let clonedRooms = clone(rooms);
        clonedRooms = clonedRooms.map((mappedRoom: IChatRoom) => {
          const clonedRoom = clone(mappedRoom);
          if (mappedRoom.id === chatroom_id) {
            clonedRoom.unread_number = unread_number;
            clonedRoom.last_activity_at = sent_at;
            clonedRoom.last_message.id = id;
            clonedRoom.last_message.text = text;
            clonedRoom.last_message.datetime = sent_at;
            clonedRoom.last_message.sent = true;
            clonedRoom.last_message.read = false;
          }
          return clonedRoom;
        });
        clonedState = {
          ...clonedState,
          rooms: sortByPropLastActivity([...clonedRooms]),
        };
      }

      return clonedState;
    }

    case 'changed_online_status': {
      const { online, chatroom_id } = data;
      if (room && room.id === chatroom_id) {
        let clonedRoom = clone(room);
        let clonedUsers = clone(room.chat_users);
        clonedUsers = clonedUsers.map(
          (mappedUser: IChatUser, index: number) => {
            if (index === 0) return { ...mappedUser, online };
            return mappedUser;
          },
        );
        clonedRoom = {
          ...clonedRoom,
          chat_users: clonedUsers,
        };
        clonedState = {
          ...clonedState,
          room: clonedRoom,
        };
      }

      if (rooms) {
        let clonedRooms = clone(rooms);
        clonedRooms = clonedRooms.map((mappedRoom: IChatRoom) => {
          let clonedRoom = clone(mappedRoom);
          if (mappedRoom.id === chatroom_id) {
            let clonedUsers = clonedRoom.chat_users;
            clonedUsers = clonedUsers.map(
              (mappedUser: IChatUser, index: number) => {
                if (index === 0) return { ...mappedUser, online };
                return mappedUser;
              },
            );
            clonedRoom = {
              ...clonedRoom,
              chat_users: clonedUsers,
            };
          }
          return clonedRoom;
        });
        clonedState = {
          ...clonedState,
          rooms: sortByPropLastActivity([...clonedRooms]),
        };
      }

      return clonedState;
    }

    default:
      return clonedState;
  }
};
