type Props = {
  className?: string;
  fillColor?: string;
  onClick?: () => void;
};

const IconArchive = ({ className, fillColor = 'white', onClick }: Props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <path
      fill={fillColor}
      d="M11.5858 1.58579L12.2929 0.878679L11.5858 1.58579ZM14 5.82843V13H16V5.82843H14ZM13 14H3V16H13V14ZM2 13V5.82843H0V13H2ZM15.1213 3.70711L12.2929 0.878679L10.8787 2.29289L13.7071 5.12132L15.1213 3.70711ZM10.1716 0H5.82843V2H10.1716V0ZM3.70711 0.87868L0.878679 3.70711L2.29289 5.12132L5.12132 2.29289L3.70711 0.87868ZM5.82843 0C5.03278 0 4.26972 0.31607 3.70711 0.87868L5.12132 2.29289C5.30886 2.10536 5.56321 2 5.82843 2V0ZM12.2929 0.878679C11.7303 0.31607 10.9672 0 10.1716 0V2C10.4368 2 10.6911 2.10536 10.8787 2.29289L12.2929 0.878679ZM3 14C2.44772 14 2 13.5523 2 13H0C0 14.6569 1.34315 16 3 16V14ZM14 13C14 13.5523 13.5523 14 13 14V16C14.6569 16 16 14.6569 16 13H14ZM16 5.82843C16 5.03278 15.6839 4.26972 15.1213 3.70711L13.7071 5.12132C13.8946 5.30886 14 5.56321 14 5.82843H16ZM2 5.82843C2 5.56321 2.10536 5.30886 2.29289 5.12132L0.878679 3.70711C0.31607 4.26972 0 5.03278 0 5.82843H2Z"
    />
    <path d="M15 5H1" stroke={fillColor} strokeWidth="2" />
  </svg>
);

export default IconArchive;
