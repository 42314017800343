import { RefObject, ForwardedRef } from 'react';

import { AxiosError } from 'axios';
import moment, { Moment } from 'moment';
import { equals, isEmpty, isNil } from 'ramda';
import { change, FormAction, FormErrors } from 'redux-form';

import { IDriver } from 'components/HumanResources/TabDrivers/_models/driverModel';
import {
  SERVER_DATE_FORMAT,
  DEFAULT_DATE_FORMAT_FOR_VIEW,
  SECONDARY_DATE_AND_TIME_FORMAT_LOWER_TIME,
  SECONDARY_TIME_AND_DATE_FORMAT_LOWER_TIME,
  DEFAULT_DATE_AND_TIME_FORMAT_LOWER_TIME,
  DEFAULT_TIME_FORMAT_LOWER_TIME,
  DEFAULT_TIME_FORMAT_24H,
  DEFAULT_TIME_FORMAT_UPPER_TIME,
  SECONDARY_TIME_AND_DATE_FORMAT_LOWER_TIME_SMALL,
} from 'constants/dateFormats';
import {
  mimeTypesAsString,
  IMAGE_MIME_TYPES,
  PDF_DOC_MIME_TYPES,
  VIDEO_MIME_TYPES,
} from 'constants/mimeTypes';
import { IFile, IFileCommonFields } from 'redux/_common/models';
import api from 'utils/requests';

import { IListableItem, IWithError } from './models';

// generates an unique surrogate identifier
let anId = -1;
export const genId = () => {
  anId -= 1;
  return anId;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const emptyFn = () => {};
export const emptyObj = Object.freeze({});
export const emptyArr = Object.freeze([]);

export const areEqual = (oldProps: Object, newProps?: Object): boolean =>
  equals(oldProps, newProps);

/**
 * find the first element with the error and scroll to it
 * @param errors = object of errors
 * @returns null
 */
export const scrollToFirstError = (errors?: FormErrors) => {
  if (errors) {
    for (const [key, value] of Object.entries(errors)) {
      const el =
        document.querySelector(
          typeof value === 'string'
            ? `[id="${key}"]`
            : `[id="${key}.${Object.keys(value as {})[0]}"]`,
        ) ||
        document.querySelector(
          typeof value === 'string'
            ? `[name="${key}"]`
            : `[name="${key}.${Object.keys(value as {})[0]}"]`,
        );
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
        break;
      }
    }
  }
  return null;
};

/**
 * clear some fields in form
 * don`t use it in case to clear all fields, use destroy instead
 */
export const clearFormFields = (
  formName: string,
  dispatch: (arg0: FormAction) => void,
  fieldsNames: string[],
) => {
  fieldsNames.forEach(field => dispatch(change(formName, field, '')));
};

export const onlyNumber = (value: string): string | undefined => {
  if (!value) return value;
  return value.replace(/[^\d]/g, '');
};
export const onlyNumberWithMaxLength = (
  value: string,
  maxLength: number,
): string | undefined => {
  if (!value) return value;
  return value.replace(/[^\d]/g, '').slice(0, maxLength);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const monthValidation = (value: any): string | undefined => {
  if (!value) return value;
  let result = value.replace(/[^\d]/g, '');
  if (+result > 12) result = 12;
  if (+result === 0) result = 0;
  return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const yearsValidation = (value: any): string | undefined => {
  if (!value) return value;
  let result = value.replace(/[^\d]/g, '');
  if (+result > 100) result = 100;
  if (+result === 0) result = 0;
  return result;
};

export const onlyNumberAndDot = (value: string): string | undefined => {
  if (!value) return value;
  return value
    .replace(/,/g, '.')
    .replace(/[^0-9.,]/g, '')
    .replace(/([^.]*[.]{1}[^.]*)([.])/g, '$1'); // remove second dot
};

/**
 * Round to one symbol after dot
 * */
export const roundOnlyNumberAndDot = (value: string): string | undefined => {
  const val = onlyNumberAndDot(value);
  return !val ? val : `${Math.round(+val * 10) / 10}`;
};

/**
 * Round two symbol after dot
 * */
export const roundNumberWithTwoDecimals = (
  value: string,
): string | undefined => {
  const val = onlyNumberAndDot(value);
  return !val ? val : `${Math.round(+val * 100) / 100}`;
};

export const onlyLetters = (value: string): string | undefined => {
  if (!value) return value;
  return value.replace(/[^a-zA-Z ,]/g, '');
};

export const onlyNumbersAndLettersWithoutSpace = (
  value: string,
): string | undefined => {
  if (!value) return value;
  return value.replace(/[^a-zA-Z0-9]/g, '');
};
export const onlyNumbersAndLettersWithoutSpaceWithMaxLength = (
  value: string,
  maxLength: number,
): string | undefined => {
  if (!value) return value;
  return value.replace(/[^a-zA-Z0-9]/g, '').slice(0, maxLength);
};

export const IFTAnormalize = (value: string): string | undefined => {
  if (!value) return value;
  return value.replace(/[^a-zA-Z0-9\-\\]/g, '').slice(0, 100);
};

export const onlyNumbersAndLetters = (value: string): string | undefined => {
  if (!value) return value;
  return value.replace(/[^a-zA-Z0-9 ]/g, '');
};
export const onlyNumbersSpacesLettersWithMaxLength = (
  value: string,
  maxLength: number,
): string | undefined => {
  if (!value) return value;
  return value.replace(/[^a-zA-Z0-9 ]/g, '').slice(0, maxLength);
};

export const onlyNumbersAndLettersOnlyOneSpace = (
  value: string,
): string | undefined => {
  if (!value) return value;
  return value.replace(/[^a-zA-Z0-9 -]/g, '').replace(/\s\s+/g, ' ');
};

export const onlyNumbersLettersSpaceComma = (
  value: string,
): string | undefined => {
  if (!value) return value;
  return value.replace(/[^a-zA-Z0-9 ,]/g, '');
};

export const onlyNumbersLettersSpaceSemi = (
  value: string,
): string | undefined => {
  if (!value) return value;
  return value.replace(/[^a-zA-Z0-9 ;]/g, '');
};

export const onlyYear = (value?: Moment): string => {
  const momentDate = moment(value);
  return momentDate ? momentDate.format('YYYY') : '';
};
export const checkValidPickerDate = (value?: Moment): string => {
  const momentDate = moment(value);
  return momentDate.format('YYYY') === 'Invalid date'
    ? ''
    : momentDate.format('YYYY');
};

export const padNumber = (value: number, digits = 2): string =>
  String(value).padStart(digits, '0');

export const formatNumber = (value?: number, digits?: number): string => {
  if (isNil(value)) return '—';
  let options: Intl.NumberFormatOptions = { style: 'decimal' };
  if (digits !== undefined) {
    options = {
      ...options,
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    };
  }
  return Intl.NumberFormat('en-US', options).format(value);
};

export const formatCurrency = (
  amount?: number | string,
  digits = 2,
): string => {
  if (isNil(amount)) return '—';
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(+amount);
};

export const roundToCurrency = (amount: number) =>
  Math.round(amount * 100) * 0.01;

export const randomBetween = (min: number, max: number) =>
  Math.floor(min + Math.random() * (1 + max - min));

export const randomTo = (max: number) => randomBetween(0, max);

export const formatDate = (
  value?: Moment | Date,
  format = SERVER_DATE_FORMAT,
): string => (value ? moment(value).format(format) : '');

export const parseDate = (
  value: string | null | undefined,
  format = SERVER_DATE_FORMAT,
): Date | undefined => (!value ? undefined : moment(value, format).toDate());

export const formatDateForView = (value?: Moment | string): string => {
  if (value) {
    const momentDate = moment(value);
    return momentDate.format(DEFAULT_DATE_FORMAT_FOR_VIEW);
  }
  return '—';
};

export const formatTimeForView = (
  value?: Moment | string,
  upperTime?: boolean,
): string => {
  if (value) {
    const momentTime = moment(value);
    return momentTime.format(
      upperTime
        ? DEFAULT_TIME_FORMAT_UPPER_TIME
        : DEFAULT_TIME_FORMAT_LOWER_TIME,
    );
  }
  return '—';
};

export const formatDateForViewLowerTime = (
  value?: Moment | Date | string,
): string => {
  return value
    ? moment(value).format(DEFAULT_DATE_AND_TIME_FORMAT_LOWER_TIME)
    : '';
};

export const formatDateAndTime = (
  value?: Moment | Date | string,
  timeFirst?: boolean,
): string => {
  return value
    ? moment(value).format(
        timeFirst
          ? SECONDARY_TIME_AND_DATE_FORMAT_LOWER_TIME
          : SECONDARY_DATE_AND_TIME_FORMAT_LOWER_TIME,
      )
    : '';
};

export const formatDateAndTimeSmall = (
  value?: Moment | Date | string,
  timeFirst?: boolean,
): string => {
  return value
    ? moment(value).format(
        timeFirst
          ? SECONDARY_TIME_AND_DATE_FORMAT_LOWER_TIME_SMALL
          : SECONDARY_TIME_AND_DATE_FORMAT_LOWER_TIME_SMALL,
      )
    : '';
};

export const formatTime = (
  value?: Moment | string,
  format = DEFAULT_TIME_FORMAT_24H,
): string => {
  let time = moment(value, 'hh:mm a', true);
  // if time is not in AM/PM format
  if (!time.isValid()) time = moment(value);

  return time ? time.format(format) : '';
};

export const format24HTimeTo12H = (
  value: Moment | string,
  format24H = DEFAULT_TIME_FORMAT_24H,
  format12H = DEFAULT_TIME_FORMAT_UPPER_TIME,
): string => {
  return moment(value, format24H).format(format12H);
};

export const formatTimeWithAPM = (value?: Moment | string): string => {
  const momentDate = moment(value);
  return momentDate ? momentDate.format('hh:mm a') : '';
};

export const upperFirstLetter = (value: string): string => {
  if (!value) return '';

  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const upperAllLetters = (value: string): string => {
  if (!value) return '';

  return value.toUpperCase();
};

export const takeFirstLetters = (value: string): string =>
  value
    .split(' ')
    .map(item => item[0])
    .join('');

export const getErrors = (
  e: AxiosError,
): { [key: string]: string[] | string } | {} => {
  const { errors = {} } = e.response?.data as {
    errors?: { [key: string]: string[] };
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Object.keys(errors).reduce((acc: any, curr) => {
    acc[curr] = errors[curr].map((x: string) =>
      typeof x === 'string' ? upperFirstLetter(x) : '',
    );
    return acc;
  }, {});
};

export const getErrorsForOrderForm = (
  e: AxiosError,
): { [key: string]: string[] | string } | {} => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { errors = {} } = e.response?.data as any;
  const nerErrors = { ...errors };
  if (errors.trucks) nerErrors.truck_ids = errors.trucks;
  if (errors.trailers) nerErrors.trailer_ids = errors.trailers;
  if (errors.drivers) nerErrors.driver_ids = errors.drivers;
  return nerErrors;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFilesIds = (files: any[]): number[] =>
  files.reduce((acc: number[], file: { id: number }) => {
    acc.push(file.id);
    return acc;
  }, []);

export const cutString = (string: string | undefined, cutNumber: number) => {
  if (!string || string.length <= cutNumber) return string;
  return string.slice(0, cutNumber);
};

export const cutStringWithEllipsis = (
  string: string | undefined,
  cutNumber: number,
) => {
  if (!string || string.length <= cutNumber) return string;
  return `${string.slice(0, cutNumber)}...`;
};

export const humanFileSize = (bytes: number, si: boolean) => {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }
  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  let clonedBytes = bytes;
  do {
    clonedBytes /= thresh;
    u += 1;
  } while (Math.abs(clonedBytes) >= thresh && u < units.length - 1);
  return `${clonedBytes.toFixed(1)} ${units[u]}`;
};

export const hasLettersInString = (value: string): string => {
  const lettersRegex = /[^\s]/;
  if (value && lettersRegex.exec(value)) return value;
  return '';
};

export const urlify = (text: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url: string) => {
    return `<a href="${url}" target="_blank">${url}</a>`;
  });
};

export const downloadUrl = (fileURL: string, fileName?: string) => {
  const filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
  const link = document.createElement('a');
  link.href = fileURL;
  link.download = fileName || filename;
  link.rel = 'noopener noreferrer';
  link.target = '_blank';
  link.click();
  URL.revokeObjectURL(fileURL);
};

export const validateUrlToHttpHttps = (str: string) => {
  let url;
  try {
    url = new URL(str);
  } catch (_) {
    return `https://${str}`;
  }
  const isValid = url.protocol === 'http:' || url.protocol === 'https:';
  if (!isValid) return `${url.protocol}//${str}`;
  return str;
};

export const saveFile = (file: File) => {
  const url = URL.createObjectURL(file);
  downloadUrl(url, file.name);
};

export const downloadBlob = (
  data: BlobPart,
  type: string,
  fileName?: string,
) => {
  const blob = data instanceof Blob ? data : new Blob([data], { type });
  const url = URL.createObjectURL(blob);
  downloadUrl(url, fileName);
};

export const downloadFile = (path: string, fileName = 'download_file') => {
  api.post(`${path}/token`).then(({ data: { token } }) => {
    if (!token) return; // ToDo error message

    api
      .get(`${path}/download?token=${token}`, {
        responseType: 'blob',
      })
      .then(({ data, headers }) =>
        downloadBlob(data, headers['content-type'], fileName),
      );
  });
};

export const refFilesChecker = (ref: RefObject<HTMLInputElement>) =>
  ref && ref.current && ref.current.files && ref.current.files.length
    ? Array.from(ref.current.files)
    : null;

export const fileToBase64 = (
  convertedFile: File,
  callback: (url: string | ArrayBuffer | null) => void,
) => {
  const reader = new FileReader();
  reader.readAsDataURL(convertedFile);
  reader.onload = () => callback(reader.result);
};

export const parseFloatString = (value: string): string =>
  value ? `${parseFloat(value)}` : value;

export const appendLoadScript = (url: string, callback?: () => void) => {
  const script = document.createElement('script');
  script.src = url;
  script.async = true;
  script.defer = true;
  script.type = 'text/javascript';
  document.body.appendChild(script);
  if (callback) script.onload = callback;
  return script;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ITransformItemByConfigFunc {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (item: any): { [key: string]: any };
}

export interface ITransformItemByConfig {
  [key: string]: ITransformItemByConfigFunc;
}

export const getDataFromItemByConfig = (
  item: IListableItem,
  config: ITransformItemByConfig,
  excludeUnlistedKeys?: boolean,
) => {
  const dataObjects = Object.keys(item || {}).map((key: string) => {
    const defaultValue = !excludeUnlistedKeys ? { [key]: item[key] } : {};
    return config[key] && item[key] ? config[key](item) : defaultValue;
  });
  return !item ? {} : Object.assign.apply(null, [{}, ...dataObjects]);
};

export const getDataFromItemByConfigs = (
  item: IListableItem,
  configs: ITransformItemByConfig[],
  includeUnlistedKeys?: boolean,
) => {
  const dataObjects = configs.map(config => {
    return getDataFromItemByConfig(item, config, includeUnlistedKeys);
  });
  return !item ? {} : Object.assign.apply(null, [{}, ...dataObjects]);
};

/**
 * compare initArray and newArray
 * @param initArray
 * @param newArray
 * @param exceptIds
 * @returns array with new and mark deleted objects as _destroy: true
 */
export const markDeleteObject = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initArray: any[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  newArray: any[],
  exceptIds?: number[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any[] => {
  if (!initArray.length) return newArray;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return initArray.reduce((acc: any[], current: any) => {
    const deletedObj = { id: current.id, _destroy: true };
    const consistInNewArray = newArray.find(({ id }) => current.id === id);
    const consistInExceptionIds =
      (exceptIds && exceptIds.includes(current.id)) || false;

    if (consistInExceptionIds) return [...acc];

    return [...acc, consistInNewArray || deletedObj];
  }, []);
};

/**
 * compare oldArray and newArray, return array with new or changed objects and mark deleted objects as _destroy: true,
 */
export const convertToAttributes = <T extends { id: number }>(
  oldArray: T[],
  newArray: T[],
) => {
  const result: (T | { id: number; _destroy: true })[] = [];

  newArray.forEach(newObject => {
    const oldObject = oldArray.find(anOld => anOld.id === newObject.id);
    if (!oldObject || !equals(newObject, oldObject)) result.push(newObject);
  });

  oldArray.forEach(oldObject => {
    const newObject = newArray.find(anNew => anNew.id === oldObject.id);
    if (!newObject) result.push({ id: oldObject.id, _destroy: true });
  });

  return result;
};

// get minutes and hours from minutes
export const getHoursAndMinutesFromMinutes = (
  value: number | null,
): { hours: number; minutes: number } | null => {
  if (!value || Number.isNaN(value)) return null;

  const hours = Math.floor(value / 60);
  const minutes = value % 60;

  return { hours, minutes };
};

// helper for getFullNameFromContact
export const getNameString = (
  contact?: { first_name?: string | null; last_name?: string | null },
  emptyState = '—',
) => {
  const nameStr = [contact?.first_name, contact?.last_name]
    .filter(name => !!name)
    .join(' ');
  return !nameStr ? emptyState : nameStr;
};

// get full name from object with full_name or first_name,last_name fields
export const getFullNameFromContact = (
  contact?: {
    full_name?: string | null;
    first_name?: string | null;
    last_name?: string | null;
  },
  emptyState = '—',
): string => {
  return !contact
    ? emptyState
    : contact?.full_name || getNameString(contact, emptyState);
};

// Drop repeats in array of object with a certain key
export const dropRepeatsByKey = <T>(key: keyof T, data: T[]) => [
  ...new Map(data.map(item => item && [item[key], item])).values(),
];

// Get number  with comma from string, return 0 or number
export const parseNumbersWithComma = (value: string): number =>
  value ? parseInt(`${value}`.replace(/,/g, ''), 10) : 0;

export const calculateEntityRating = (rateTotal = '', rateCount = 0) => {
  const totalAsNumber = parseFloat(rateTotal);
  const dataIsValid = !Number.isNaN(totalAsNumber) && rateCount;
  return !dataIsValid ? 0 : (totalAsNumber / rateCount).toFixed(1);
};

// Get all first letters from string
export const getFirstLettersFromString = (value: string): string => {
  if (!value) return value;
  return value.split(' ').reduce((acc, current) => {
    const letter = current.charAt(0).toUpperCase();
    return `${acc}${letter}`;
  }, '');
};

// Converts meters into miles
export const convertMetersToMiles = (value: number, precision = 1) => {
  return (value * 0.000621371192).toFixed(precision);
};

export const convertMilesToMeters = (value: number, precision = 1) => {
  return (value * 1609.344).toFixed(precision);
};

// Mask string values from begin as *
export const maskStringValue = (value: string, maskLetters = 4) => {
  if (value.length <= maskLetters) return value;

  const maskedPart = value.slice(0, -maskLetters).replace(/./g, '*');
  const visiblePart = value.slice(-maskLetters);
  return maskedPart + visiblePart;
};

export const isClipboardSupported = () =>
  (window.isSecureContext && navigator.clipboard) ||
  document.queryCommandSupported('copy');

// Copy text to Clipboard
export const copyToClipboard = (value: string) => {
  if (window.isSecureContext && navigator.clipboard)
    navigator.clipboard.writeText(value);
  else {
    const dummyInput = document.createElement('input');
    document.body.appendChild(dummyInput);
    dummyInput.setAttribute('value', value);
    dummyInput.select();
    document.execCommand('copy');
    document.body.removeChild(dummyInput);
  }
};

// Pluralize values/string
export const pluralizeString = (count: number, noun: string, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

// Get Percent From Number
export const getPercentFromNumber = (value: number, from: number) => {
  return (value * 100) / from;
};

// Get Number From Percent
export const getNumberFromPercent = (percent: number, from: number) => {
  return (percent * from) / 100;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const concatArrayStrings = (array: any[], fieldName: string) => {
  if (!array.length) return '-';
  const string = array
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .reduce((prev: any, curr: any) => [...prev, curr[fieldName]], [])
    .join(', ');
  return string;
};

// Check if object has properties with null or empty strings
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isObjectPropertiesNotEmpty = (object: any) => {
  if (!object) return false;
  return !Object.values(object).every(
    property => property === null || property === '',
  );
};

// Get name from settings
export const getNameFromSettings = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setting: any,
  itemId?: string | number,
): string => {
  return (itemId && setting && setting[itemId] && setting[itemId].name) || '';
};

// Return formatted date
export const getFormattedDate = (
  value?: Moment | null | string | Date,
  dateFormat = DEFAULT_DATE_FORMAT_FOR_VIEW,
): string => {
  if (!value || !moment(value).isValid()) return '';

  return moment(value).format(dateFormat);
};

export const getLngAndLatFromObject = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object?: any,
): google.maps.LatLngLiteral | undefined => {
  if (
    object &&
    typeof object.lat === 'number' &&
    typeof object.lng === 'number'
  ) {
    return { lng: object.lng, lat: object.lat };
  }
  if (
    object &&
    typeof object.lat === 'string' &&
    !Number.isNaN(object.lat) &&
    typeof object.lng === 'string' &&
    !Number.isNaN(object.lng)
  ) {
    return { lng: +object.lng, lat: +object.lat };
  }

  return undefined;
};

export const getThumbnailFromFile = (file: IFile | null) =>
  (file && file.urls && (file.urls.thumb || file.urls.original)) || null;

// Adds 0 to digits (1-9)
// Returns string value
export const setZeroBeforeDigit = (digit: number): string => {
  return `${(digit < 10 && digit > 0 && '0') || ''}${digit}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getMainDriverOrTheOnly = (drivers: any) => {
  if (!isEmpty(drivers)) {
    if (drivers.length === 1) return drivers[0];
    return drivers.find(
      (driver: Partial<IDriver>) => driver && !driver.extra_driver,
    );
  }
  return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTagsStringFromFormData = (formData: any) =>
  (formData.tags &&
    formData.tags.list &&
    formData.tags.list.length > 0 &&
    formData.tags.list.join(',')) ||
  '';

export const capitalizeText = (value: string, splitBy = ' ') =>
  value
    .split(splitBy)
    .map(span => span[0].toUpperCase() + span.substr(1))
    .join(' ');
export function isImage(file?: IFileCommonFields | null): boolean {
  const content_type = file && file.content_type;
  return !!content_type && IMAGE_MIME_TYPES.includes(content_type);
}

export function isPdf(file?: IFileCommonFields | null): boolean {
  const content_type = file && file.content_type;
  return !!content_type && PDF_DOC_MIME_TYPES.includes(content_type);
}

export function isVideo(file?: IFileCommonFields | null): boolean {
  const content_type = file && file.content_type;
  return (
    !!content_type && mimeTypesAsString(VIDEO_MIME_TYPES).includes(content_type)
  );
}

export const getErrorMessage = (error: IWithError['_error']): string => {
  if (!error) return '';

  const e = error as AxiosError;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { errors, error: resError } = (e.response?.data || {}) as any;

  if (
    resError?.message === 'Employee save failed' ||
    resError?.message === 'Company save failed' ||
    resError?.message === 'Employee not valid'
  ) {
    let detailMessage = '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resError.errors.forEach((element: any) => {
      const info = Object.entries(element);
      detailMessage = `${info[0][0].replace('_', ' ')} has already been taken`;
    });
    return `Employee save failed: ${detailMessage}`;
  }
  const message =
    isNil(errors) || isEmpty(errors)
      ? error.message
      : errors[0] || errors.error;
  return `${error.name}: ${resError?.message ?? message ?? error?.message}`;
};

/**
 * compare two objects and return just changed fields
 */
export const getChanged = <T extends {}>(
  initial: T,
  current: T,
): Partial<T> => {
  const result: Partial<T> = {};
  Object.entries(current).forEach(([key, value]) => {
    if (value !== initial[key as keyof T])
      result[key as keyof T] = value as T[keyof T];
  });
  return { ...result };
};

export const getHumanFileFormat = (format: string) => {
  const formatWithoutSpaces = format.replace(/\s/g, '');
  switch (formatWithoutSpaces) {
    case 'audio/aac':
      return 'aac';
    case 'application/x-abiword':
      return 'abw';
    case 'application/x-freearc':
      return 'arc';
    case 'video/x-msvideo':
      return 'avi';
    case 'application/vnd.amazon.ebook':
      return 'azw';
    case 'application/octet-stream':
      return 'bin';
    case 'image/bmp':
      return 'bmp';
    case 'application/x-bzip':
      return 'bz';
    case 'application/x-bzip2':
      return 'bz2';
    case 'application/x-csh':
      return 'csh';
    case 'text/css':
      return 'css';
    case 'text/csv':
      return 'csv';
    case 'application/msword':
      return 'doc';
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'docx';
    case 'application/vnd.ms-fontobject':
      return 'eot';
    case 'application/epub+zip':
      return 'epub';
    case 'application/gzip':
      return 'gz';
    case 'image/gif':
      return 'gif';
    case 'text/html':
      return 'htm, html';
    case 'image/vnd.microsoft.icon':
      return 'ico';
    case 'text/calendar':
      return 'ics';
    case 'application/java-archive':
      return 'jar';
    case 'image/jpeg':
    case 'image/x-citrix-jpeg':
    case 'image/x-jpeg':
      return 'jpeg, jpg';
    case 'text/javascript':
      return 'js, mjs';
    case 'application/json':
      return 'json';
    case 'application/ld+json':
      return 'jsonld';
    case 'audio/midi audio/x-midi':
      return 'mid, midi';
    case 'audio/mpeg':
      return 'mp3';
    case 'application/x-cdf':
      return 'cda';
    case 'video/mp4':
      return 'mp4';
    case 'video/mpeg':
      return 'mpeg';
    case 'application/vnd.apple.installer+xml':
      return 'mpkg';
    case 'application/vnd.oasis.opendocument.presentation':
      return 'odp';
    case 'application/vnd.oasis.opendocument.spreadsheet':
      return 'ods';
    case 'application/vnd.oasis.opendocument.text':
      return 'odt';
    case 'audio/ogg':
      return 'oga';
    case 'video/ogg':
      return 'ogv';
    case 'application/ogg':
      return 'ogx';
    case 'audio/opus':
      return 'opus';
    case 'font/otf':
      return 'otf';
    case 'image/png':
    case 'image/x-citrix-png':
    case 'image/x-png':
      return 'png';
    case 'application/pdf':
      return 'pdf';
    case 'application/x-httpd-php':
      return 'php';
    case 'application/vnd.ms-powerpoint':
      return 'ppt';
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'pptx';
    case 'application/vnd.rar':
      return 'rar';
    case 'application/rtf':
      return 'rtf';
    case 'application/x-sh':
      return 'sh';
    case 'image/svg+xml':
      return 'svg';
    case 'application/x-shockwave-flash':
      return 'swf';
    case 'image/tiff':
      return 'tar, tiff';
    case 'video/mp2t':
      return 'ts';
    case 'font/ttf':
      return 'ttf';
    case 'text/plain':
      return 'txt';
    case 'application/vnd.visio':
      return 'vsd';
    case 'audio/wav':
      return 'wav';
    case 'audio/webm':
    case 'video/webm':
      return 'weba';
    case 'image/webp':
      return 'webp';
    case 'font/woff':
      return 'woff';
    case 'font/woff2':
      return 'woff2';
    case 'application/xhtml+xml':
      return 'xhtml';
    case 'application/vnd.ms-excel':
      return 'xls';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xlsx';
    case 'application/xml':
    case 'text/xml':
      return 'xml';
    case 'application/vnd.mozilla.xul+xml':
      return 'xul';
    case 'archive	application/zip':
      return 'zip';
    case 'video/3gpp':
    case 'audio/3gpp':
      return '3gp';
    case 'video/3gpp2':
    case 'audio/3gpp2':
      return '3g2';
    case 'application/x-7z-compressed':
      return '7z';
    case 'video/mov':
    case 'video/quicktime':
      return 'mov';
    default:
      return '';
  }
};

export const getOS = () => {
  const { userAgent } = window.navigator;
  if (userAgent.indexOf('Win') !== -1) return 'Windows';
  if (userAgent.indexOf('Mac') !== -1) return 'MacOS';
  if (userAgent.indexOf('Linux') !== -1 || userAgent.indexOf('X11') !== -1)
    return 'Linux';
  return 'unknown';
};

export function toSnakeCase(str: string): string {
  const m = str.match(
    /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g,
  );
  return m ? m.map(x => x.toLowerCase()).join('_') : '';
}

export function isRefObject<T>(ref: ForwardedRef<T>): ref is RefObject<T> {
  return !!ref && 'current' in ref;
}

export const checkSymbolsOverflow = (str: string, max: number) => {
  return str.length > max;
};

const TMS_ICONS = [
  {
    rel: 'apple-touch-icon-precomposed',
    sizes: '60x60',
    href: '/favicon/apple-touch-icon-60x60.png',
  },
  {
    rel: 'apple-touch-icon-precomposed',
    sizes: '57x57',
    href: '/favicon/apple-touch-icon-57x57.png',
  },
  {
    rel: 'apple-touch-icon-precomposed',
    sizes: '76x76',
    href: '/favicon/apple-touch-icon-76x76.png',
  },
  {
    rel: 'apple-touch-icon-precomposed',
    sizes: '114x114',
    href: '/favicon/apple-touch-icon-114x114.png',
  },
  {
    rel: 'apple-touch-icon-precomposed',
    sizes: '120x120',
    href: '/favicon/apple-touch-icon-120x120.png',
  },
  {
    rel: 'apple-touch-icon-precomposed',
    sizes: '144x144',
    href: '/favicon/apple-touch-icon-144x144.png',
  },
  {
    rel: 'apple-touch-icon-precomposed',
    sizes: '152x152',
    href: '/favicon/apple-touch-icon-152x152.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: '/favicon/favicon-16x16.png',
    sizes: '16x16',
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: '/favicon/favicon-32x32.png',
    sizes: '32x32',
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: '/favicon/favicon-96x96.png',
    sizes: '96x96',
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: '/favicon/favicon-128x128.png',
    sizes: '128x128',
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: '/favicon/favicon-196x196.png',
    sizes: '196x196',
  },
  { rel: 'manifest', href: '/favicon/manifest.json' },
  {
    rel: 'apple-touch-icon-precomposed',
    sizes: '72x72',
    href: '/favicon/apple-touch-icon-72x72.png',
  },
];
const WISE_ICONS = [
  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: '/favicon/wise-icons/apple-touch-icon.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: '/favicon/wise-icons/favicon-32x32.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: '/favicon/wise-icons/favicon-16x16.png',
  },
  { rel: 'manifest', href: '/favicon/wise-icons/site.webmanifest' },
  {
    rel: 'mask-icon',
    href: '/favicon/wise-icons/safari-pinned-tab.svg',
    color: '#5bbad5',
  },
];

export const replaceFavIcons = (isWiseCheck?: boolean) => {
  const links = document.head.querySelectorAll(
    "link[rel*='icon'][href^='/favicon'], link[rel='manifest'][href^='/favicon']",
  );

  // remove old icons
  links.forEach(link => link.remove());

  // add new icons
  (isWiseCheck ? WISE_ICONS : TMS_ICONS).forEach(icon => {
    const link = document.createElement('link');
    link.rel = icon.rel;
    link.href = icon.href;
    if (icon.sizes) (link.sizes as unknown as string) = icon.sizes;
    if (icon.type) link.type = icon.type;

    document.head.appendChild(link);
  });
};
