import { LOGOUT } from 'components/Auth/_redux/authTypes';
import {
  facilitiesActionsType,
  GET_FACILITIES,
  GET_FACILITIES_FAIL,
  GET_FACILITIES_SUCCESS,
  GET_FACILITY,
  GET_FACILITY_FAIL,
  GET_FACILITY_SUCCESS,
  CREATE_FACILITY,
  CREATE_FACILITY_FAIL,
  CREATE_FACILITY_SUCCESS,
  UPDATE_FACILITY,
  UPDATE_FACILITY_SUCCESS,
  UPDATE_FACILITY_FAIL,
  IFacilitiesReducer,
  CLEAR_CURRENT_FACILITY,
} from 'components/Dispatch/TabFacilities/_redux/facilitiesTypes';

const initialState: IFacilitiesReducer = {
  pagination: null,
  list: [],
  files: [],
  currentFacility: null,
  _pending: false,
  _loaded: false,
  _error: null,
};

const facilitiesReducer = (
  state = initialState,
  action: facilitiesActionsType,
): IFacilitiesReducer => {
  switch (action.type) {
    // GET BROKERS
    case GET_FACILITIES:
      return {
        ...state,
        files: [],
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case GET_FACILITIES_SUCCESS:
      return {
        ...state,
        list: action.payload.facilities,
        pagination: action.payload.pagination,
        _loaded: true,
        _pending: false,
      };
    case GET_FACILITIES_FAIL:
      return {
        ...initialState,
        _error: action.payload,
        _loaded: false,
        _pending: false,
      };

    // GET FACILITY
    case GET_FACILITY:
      return { ...state, _loaded: false, _pending: true, _error: null };
    case GET_FACILITY_SUCCESS:
      return {
        ...state,
        currentFacility: action.payload,
        _loaded: true,
        _pending: false,
      };
    case GET_FACILITY_FAIL:
      return {
        ...state,
        _error: action.payload,
        _loaded: false,
        _pending: false,
      };

    // CREATE FACILITY
    case CREATE_FACILITY:
      return { ...state };
    case CREATE_FACILITY_SUCCESS:
      return { ...state, currentFacility: null };
    case CREATE_FACILITY_FAIL:
      return { ...state, _error: action.payload };

    // UPDATE FACILITY
    case UPDATE_FACILITY:
      return { ...state };
    case UPDATE_FACILITY_SUCCESS:
      return { ...state, files: [], currentFacility: null };
    case UPDATE_FACILITY_FAIL:
      return { ...state, _error: action.payload };

    // OTHERS
    case CLEAR_CURRENT_FACILITY:
      return { ...state, currentFacility: null };

    case LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
};

export default facilitiesReducer;
