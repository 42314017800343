import { FC } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const IconPhoto: FC<IOwnProps> = ({
  className,
  fillColor = 'var(--secondaryText)',
}) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3 10C3 8.89543 3.89543 8 5 8H9.04623C9.33459 8 9.60892 7.87552 9.79881 7.6585L12.7012 4.3415C12.8911 4.12448 13.1654 4 13.4538 4H19C19.3148 4 19.6111 4.14819 19.8 4.4L22.2 7.6C22.3889 7.85181 22.6852 8 23 8H27C28.1046 8 29 8.89543 29 10V26C29 27.1046 28.1046 28 27 28H5C3.89543 28 3 27.1046 3 26V10Z"
        stroke={fillColor}
        strokeWidth="2"
      />
      <circle cx="16" cy="18" r="6" stroke={fillColor} strokeWidth="2" />
    </svg>
  );
};

export default IconPhoto;
