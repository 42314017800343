import { AppState } from 'redux/store';

import {
  IAccountEntriesReducer,
  IAccountFuelDriverReducer,
  IAccountFuelReducer,
  IAccountingPayrollItemsReducer,
  IDeductionsReducer,
  IDriversReducer,
} from './payrollItemsTypes';

const entrySelector = (state: AppState): IAccountingPayrollItemsReducer =>
  state.payrollItems;

export const schedDeductionDriversSelector = (
  state: AppState,
): IDriversReducer => entrySelector(state).schedDeductionDrivers;

export const deductionsSelector = (state: AppState): IDeductionsReducer =>
  entrySelector(state).deductions;

export const deductionPaymentsSelector = (
  state: AppState,
): IDeductionsReducer['deductionPayments'] =>
  entrySelector(state).deductions.deductionPayments;

export const creditsSelector = (state: AppState): IAccountEntriesReducer =>
  entrySelector(state).credits;

export const fuelSelector = (state: AppState): IAccountFuelReducer =>
  entrySelector(state).fuel;

export const fuelDriverSelector = (
  state: AppState,
): IAccountFuelDriverReducer => entrySelector(state).fuelDriver;
