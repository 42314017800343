import { AxiosError } from 'axios';

import { IPagination } from 'components/_common/Navigation/Pagination/_redux/paginationTypes';
import { ILogout } from 'redux/_common/models';
import { IWithError, IWithPending, IWithLoaded } from 'utils/models';

import { IVendorLocations } from '../_models/tableModels';
import {
  ICreateVendor,
  IVendor,
  IVendorOption,
  IVendorProfileOrdersHistory,
} from '../_models/vendorModels';

export interface IVendorsReducer extends IWithError, IWithPending, IWithLoaded {
  list: IVendor[];
  fullList: IVendorOption[];
  vendorLocations: IVendorLocations[];
  currentVendor: IVendor | null;
  total: number | null;
  items: IVendorProfileOrdersHistory[];
}

// CREATE_VENDOR
export const CREATE_VENDOR = '[@@Vendors] CREATE_VENDOR';
export const CREATE_VENDOR_SUCCESS = '[@@Vendors] CREATE_VENDOR_SUCCESS';
export const CREATE_VENDOR_FAIL = '[@@Vendors] CREATE_VENDOR_FAIL';
export interface ICreateVendorAction {
  type: typeof CREATE_VENDOR;
  payload: {
    data: { inventories_vendor: ICreateVendor };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // config?: any;
    callback?: (id: number) => void;
  };
}
export interface ICreateVendorSuccessAction {
  type: typeof CREATE_VENDOR_SUCCESS;
  payload: {
    vendor: IVendor;
  };
}
export interface ICreateVendorFailAction {
  type: typeof CREATE_VENDOR_FAIL;
  payload: AxiosError;
}

// REMOVE VENDOR
export const REMOVE_VENDOR = '[@@Vendors] REMOVE_VENDOR';
export const REMOVE_VENDOR_SUCCESS = '[@@Vendors] REMOVE_VENDOR_SUCCESS';
export const REMOVE_VENDOR_FAIL = '[@@Vendors] REMOVE_VENDOR_FAIL';
export interface IRemoveVendorAction {
  type: typeof REMOVE_VENDOR;
  payload: {
    id: number;
    callback?: () => void;
  };
}
export interface IRemoveVendorSuccessAction {
  type: typeof REMOVE_VENDOR_SUCCESS;
  payload: {
    id: number;
  };
}
export interface IRemoveVendorFailAction {
  type: typeof REMOVE_VENDOR_FAIL;
  payload: AxiosError;
}

// UPDATE_VENDOR
export const UPDATE_VENDOR = '[@@Vendors] UPDATE_VENDOR';
export const UPDATE_VENDOR_SUCCESS = '[@@Vendors] UPDATE_VENDOR_SUCCESS';
export const UPDATE_VENDOR_FAIL = '[@@Vendors] UPDATE_VENDOR_FAIL';
export interface IUpdateVendorAction {
  type: typeof UPDATE_VENDOR;
  payload: {
    data: {
      id: number;
      inventories_vendor: Partial<ICreateVendor[keyof ICreateVendor]>;
    };
    callback?: () => void;
  };
}
export interface IUpdateVendorSuccessAction {
  type: typeof UPDATE_VENDOR_SUCCESS;
  payload: IVendor;
}
export interface IUpdateVendorFailAction {
  type: typeof UPDATE_VENDOR_FAIL;
  payload: AxiosError;
}

// SEARCH VENDOR
export const SEARCH_VENDOR = '[@@Vendors] SEARCH_VENDOR';
export interface ISearchVendorAction {
  type: typeof SEARCH_VENDOR;
}

// UNMOUNT MAIN FORM
export const UNMOUNT_MAIN_FORM = '[@@Vendors] UNMOUNT_MAIN_FORM';
export interface IClearMainVendorsFormAction {
  type: typeof UNMOUNT_MAIN_FORM;
}

// GET VENDORS
export const GET_VENDORS = '[@@Vendors] GET_VENDORS';
export const GET_ALL_VENDORS = '[@@Vendors] GET_ALL_VENDORS';
export const GET_VENDORS_SUCCESS = '[@@Vendors] GET_VENDORS_SUCCESS';
export const GET_ALL_VENDORS_SUCCESS = '[@@Vendors] GET_ALL_VENDORS_SUCCESS';
export const GET_VENDORS_FAIL = '[@@Vendors] GET_VENDORS_FAIL';
export interface IGetVendorsAction {
  type: typeof GET_VENDORS;
  payload: {
    data: { query?: string };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config?: any;
    callback?: () => void;
  };
}
export interface IGetVendorsSuccessAction {
  type: typeof GET_VENDORS_SUCCESS;
  payload: {
    vendors: IVendor[];
    pagination: IPagination | null;
  };
}
export interface IGetAllVendorsSuccessAction {
  type: typeof GET_ALL_VENDORS_SUCCESS;
  payload: {
    vendors: IVendor[];
  };
}
export interface IGetVendorsFailAction {
  type: typeof GET_VENDORS_FAIL;
  payload: AxiosError;
}

// GET VENDOR LOCATIONS
export const GET_VENDOR_LOCATIONS = '[@@Vendors] GET_VENDOR_LOCATIONS';
export const GET_VENDOR_LOCATIONS_SUCCESS =
  '[@@Vendors] GET_VENDOR_LOCATIONS_SUCCESS';
export const GET_VENDOR_LOCATIONS_FAIL =
  '[@@Vendors] GET_VENDOR_LOCATIONS_FAIL';

export interface IGetVendorLocationsAction {
  type: typeof GET_VENDOR_LOCATIONS;
}
export interface IGetVendorLocationsSuccessAction {
  type: typeof GET_VENDOR_LOCATIONS_SUCCESS;
  payload: IVendorLocations[];
}
export interface IGetVendorLocationsFailAction {
  type: typeof GET_VENDOR_LOCATIONS_FAIL;
  payload: AxiosError;
}

// GET VENDOR PROFILE ORDERS HISTORY
export const GET_VENDOR_PROFILE_ORDERS_HISTORY =
  '[@@Vendors] GET_VENDOR_ORDERS_HISTORY';
export const GET_VENDOR_PROFILE_ORDERS_HISTORY_SUCCESS =
  '[@@Vendors] GET_VENDOR_ORDERS_HISTORY_SUCCESS';
export const GET_VENDOR_PROFILE_ORDERS_HISTORY_FAIL =
  '[@@Vendors] GET_VENDOR_ORDERS_HISTORY_FAIL';
export interface IGetVendorProfileOrdersHistoryAction {
  type: typeof GET_VENDOR_PROFILE_ORDERS_HISTORY;
  payload: {
    data: { id: string; query?: string };
    callback?: () => void;
  };
}

export interface IGetVendorProfileOrdersHistorySuccessAction {
  type: typeof GET_VENDOR_PROFILE_ORDERS_HISTORY_SUCCESS;
  payload: {
    items: IVendorProfileOrdersHistory[];
    pagination: IPagination | null;
  };
}
export interface IGetVendorProfileOrdersHistoryFailAction {
  type: typeof GET_VENDOR_PROFILE_ORDERS_HISTORY_FAIL;
  payload: AxiosError;
}

// GET VENDORS BY ID
export const GET_VENDOR = '[@@Vendors] GET_VENDOR';
export const GET_VENDOR_SUCCESS = '[@@Vendors] GET_VENDOR_SUCCESS';
export const GET_VENDOR_FAIL = '[@@Vendors] GET_VENDOR_FAIL';
export interface IGetVendorByIdAction {
  type: typeof GET_VENDOR;
  payload: {
    data: { id: string };
    callback?: () => void;
  };
}
export interface IGetVendorByIdSuccessAction {
  type: typeof GET_VENDOR_SUCCESS;
  payload: IVendor;
}
export interface IGetVendorByIdFailAction {
  type: typeof GET_VENDOR_FAIL;
  payload: AxiosError;
}

// CLEAR CURRENT VENDOR
export const CLEAR_CURRENT_VENDOR = '[@@Categories] CLEAR_CURRENT_VENDOR';
export interface IClearCurrentVendorAction {
  type: typeof CLEAR_CURRENT_VENDOR;
}

export type VendorsTypes =
  | IClearMainVendorsFormAction
  | ICreateVendorAction
  | ICreateVendorSuccessAction
  | ICreateVendorFailAction
  | IUpdateVendorAction
  | IUpdateVendorSuccessAction
  | IUpdateVendorFailAction
  | IRemoveVendorAction
  | IRemoveVendorSuccessAction
  | IRemoveVendorFailAction
  | IGetVendorsAction
  | IGetVendorsSuccessAction
  | IGetAllVendorsSuccessAction
  | IGetVendorsFailAction
  | IGetVendorByIdAction
  | IGetVendorByIdSuccessAction
  | IGetVendorByIdFailAction
  | IGetVendorsSuccessAction
  | IGetVendorsFailAction
  | ISearchVendorAction
  | IGetVendorLocationsAction
  | IGetVendorLocationsSuccessAction
  | IGetVendorLocationsFailAction
  | IGetVendorProfileOrdersHistoryAction
  | IGetVendorProfileOrdersHistorySuccessAction
  | IGetVendorProfileOrdersHistoryFailAction
  | IClearCurrentVendorAction
  | ILogout;
