import { useEffect } from 'react';

export default function useAttachEventListener(
  handler: keyof GlobalEventHandlersEventMap,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (e?: any) => void,
) {
  useEffect(() => {
    window.addEventListener(handler, callback);
    return () => {
      window.removeEventListener(handler, callback);
    };
  }, [handler, callback]);
}
