import { lazy } from 'react';

import {
  PERMISSION_BILLING,
  PERMISSION_CREDIT,
  PERMISSION_DEDUCTION,
  PERMISSION_DRIVER_PAYROLL,
  PERMISSION_FUEL,
  PERMISSION_PAYROLL_WEEKLY,
  PERMISSION_REPORTS,
} from 'constants/permissions';

import { IPermissionObject } from '../index';
import PrivateRoutes, { redirectRoute } from '../PrivateRoutes/PrivateRoutes';

const TabWeekly = lazy(
  () => import('components/Accounting/TabPayrolls/TabWeekly'),
);
const TabWeekPayrolls = lazy(
  () =>
    import('components/Accounting/TabPayrolls/TabWeekPayrolls/TabWeekPayrolls'),
);
const TabDrivers = lazy(
  () => import('components/Accounting/TabPayrolls/TabDrivers'),
);
const TabDriverPayrolls = lazy(
  () =>
    import(
      'components/Accounting/TabPayrolls/TabDriverPayrolls/TabDriverPayrolls'
    ),
);
const TabBilling = lazy(
  () => import('components/Accounting/TabBilling/TabBilling'),
);
const CreateAndUpdatePayroll = lazy(
  () =>
    import(
      'components/Accounting/TabPayrolls/CreateAndUpdatePayroll/CreateAndUpdatePayroll'
    ),
);
const DeductionProfile = lazy(
  () =>
    import(
      'components/Accounting/TabPayrollItems/Deductions/DeductionProfile/DeductionProfile'
    ),
);
const Deductions = lazy(
  () => import('components/Accounting/TabPayrollItems/Deductions/Deductions'),
);
const DeductionsCalendar = lazy(
  () =>
    import(
      'components/Accounting/TabPayrollItems/Deductions/DeductionsCalendar/DeductionsCalendar'
    ),
);
const Credits = lazy(
  () => import('components/Accounting/TabPayrollItems/Credits/Credits'),
);
const ReportDrivers = lazy(
  () => import('components/Accounting/TabReports/Drivers/Drivers'),
);
const ReportDriverItems = lazy(
  () => import('components/Accounting/TabReports/Driver/Driver'),
);
const ReportCompany = lazy(
  () => import('components/Accounting/TabReports/Company/Dashboard'),
);
const ReportCompanyGrid = lazy(
  () => import('components/Accounting/TabReports/Company/Grid'),
);
const Fuel = lazy(
  () => import('components/Accounting/TabPayrollItems/Fuel/Fuel'),
);
const FuelDriver = lazy(
  () => import('components/Accounting/TabPayrollItems/FuelDriver/FuelDriver'),
);

const getRedirectURL = (permissions?: string[]) => {
  if (permissions?.includes(PERMISSION_PAYROLL_WEEKLY))
    return '/accounting/billing';

  if (permissions?.includes(PERMISSION_DRIVER_PAYROLL))
    return '/accounting/reports/drivers';

  return '/';
};

/* Accounting Main Page (redirect into) */
export const mainAccountingRoute = (permissions?: IPermissionObject[]) => {
  const targetName = 'Accounting';
  const targetObject = permissions?.find(obj => obj.name === targetName);
  return redirectRoute(
    '/accounting',
    getRedirectURL(targetObject ? targetObject.permissions : []),
  );
};

/* Accounting Weekly Payrolls */
export const weeklyPayrollsMainRoute = (
  <PrivateRoutes
    exact
    path="/accounting/payrolls/weeks"
    component={TabWeekly}
    permissionType={PERMISSION_PAYROLL_WEEKLY}
  />
);

/* Accounting Payrolls for certain Week */
export const accountingPayrollsRoute = (
  <PrivateRoutes
    exact
    path="/accounting/payrolls/week/:weekId"
    component={TabWeekPayrolls}
    permissionType={PERMISSION_PAYROLL_WEEKLY}
  />
);

/* Accounting Drivers Payrolls */
export const driversPayrollsMainRoute = (
  <PrivateRoutes
    exact
    path="/accounting/payrolls/drivers"
    component={TabDrivers}
    permissionType={PERMISSION_DRIVER_PAYROLL}
  />
);

/* Accounting Payrolls for certain Driver */
export const driversPayrollsRoute = (
  <PrivateRoutes
    exact
    path="/accounting/payrolls/drivers/:driverId"
    component={TabDriverPayrolls}
    permissionType={PERMISSION_DRIVER_PAYROLL}
  />
);

/* Accounting Payroll (create or update) */
export const accountingPayrollRoute = (
  <PrivateRoutes
    exact
    path={[
      '/accounting/payrolls/weeks/payroll/:driverId',
      '/accounting/payrolls/week/:weekId/payroll/:driverId',
      '/accounting/payrolls/drivers/payroll/:driverId',
      '/accounting/payrolls/driver/:driverId/payroll/:payrollId',
      '/accounting/payrolls/drivers/:weekId/payroll/:driverId',
    ]}
    component={CreateAndUpdatePayroll}
    permissionType={PERMISSION_DRIVER_PAYROLL}
  />
);

/* Accounting Deduction Profile */
export const accountingDeductionProfileRoute = (
  <PrivateRoutes
    exact
    path="/accounting/payrollItems/deductions/:deductionId"
    component={DeductionProfile}
    permissionType={PERMISSION_DEDUCTION}
  />
);

/* Accounting Deductions */
export const accountingDeductionsRoute = (
  <PrivateRoutes
    exact
    path="/accounting/payrollItems/deductions"
    component={Deductions}
    permissionType={PERMISSION_DEDUCTION}
  />
);

/* Accounting Deductions Calendar */
export const accountingDeductionsCalendarRoute = (
  <PrivateRoutes
    exact
    path="/accounting/payrollItems/deductions/calendar/driver"
    component={DeductionsCalendar}
    permissionType={PERMISSION_DRIVER_PAYROLL}
  />
);

/* Accounting Credits */
export const accountingCreditsRoute = (
  <PrivateRoutes
    exact
    path="/accounting/payrollItems/credits"
    component={Credits}
    permissionType={PERMISSION_CREDIT}
  />
);

/* Accounting Fuel */
export const accountingFuelRoute = (
  <PrivateRoutes
    exact
    path="/accounting/payrollItems/fuel"
    component={Fuel}
    permissionType={PERMISSION_FUEL}
  />
);

/* Accounting Fuel Driver */
export const accountingFuelDriverRoute = (
  <PrivateRoutes
    exact
    path="/accounting/payrollItems/fuel/:driverId"
    component={FuelDriver}
    permissionType={PERMISSION_FUEL}
  />
);

/* Accounting Billing */
export const accountingBillingRoute = (
  <PrivateRoutes
    exact
    path="/accounting/billing"
    component={TabBilling}
    permissionType={PERMISSION_BILLING}
  />
);

/* Accounting Reports Drivers */
export const accountingReportDriversRoute = (
  <PrivateRoutes
    exact
    path="/accounting/reports/drivers"
    component={ReportDrivers}
    permissionType={PERMISSION_REPORTS}
  />
);

/* Accounting Reports Driver */
export const accountingReportDriverItemsRoute = (
  <PrivateRoutes
    exact
    path="/accounting/reports/driver/:driverId"
    component={ReportDriverItems}
    permissionType={PERMISSION_REPORTS}
  />
);

/* Accounting Reports Company (Chart) */
export const accountingReportCompanyRoute = (
  <PrivateRoutes
    exact
    path="/accounting/reports/company"
    component={ReportCompany}
    permissionType={PERMISSION_REPORTS}
  />
);

/* Accounting Reports Company (Table) */
export const accountingReportCompanyGridRoute = (
  <PrivateRoutes
    exact
    path="/accounting/reports/company/:type/:subType"
    component={ReportCompanyGrid}
    permissionType={PERMISSION_REPORTS}
  />
);
