import { lazy } from 'react';

import {
  PERMISSION_FACILITIES,
  PERMISSION_BROKERS,
  PERMISSION_ORDER,
  PERMISSION_CREATE_BROKER,
  PERMISSION_EDIT_BROKER,
  PERMISSION_CREATE_FACILITY,
  PERMISSION_EDIT_FACILITY,
  PERMISSION_CALENDAR,
} from 'constants/permissions';

import { IPermissionObject } from '../index';
import PrivateRoutes, { redirectRoute } from '../PrivateRoutes/PrivateRoutes';
import PublicRoutes from '../PublicRoutes/PublicRoutes';

const CreateOrder = lazy(
  () => import('components/Dispatch/TabOrders/CreateOrder/CreateOrder'),
);
const OrderProfile = lazy(
  () => import('components/Dispatch/TabOrders/OrderProfile/OrderProfile'),
);
const UpdateOrder = lazy(
  () => import('components/Dispatch/TabOrders/UpdateOrder/UpdateOrder'),
);
const BrokerCreate = lazy(
  () => import('components/Dispatch/TabBrokers/BrokerCreate/BrokerCreate'),
);
const BrokerProfile = lazy(
  () => import('components/Dispatch/TabBrokers/BrokerProfile/BrokerProfile'),
);
const BrokerEdit = lazy(
  () => import('components/Dispatch/TabBrokers/BrokerEdit/BrokerEdit'),
);
const FacilityCreate = lazy(
  () =>
    import('components/Dispatch/TabFacilities/FacilityCreate/FacilityCreate'),
);
const FacilityProfile = lazy(
  () =>
    import('components/Dispatch/TabFacilities/FacilityProfile/FacilityProfile'),
);
const FacilityEdit = lazy(
  () => import('components/Dispatch/TabFacilities/FacilityEdit/FacilityEdit'),
);
const Orders = lazy(() => import('components/Dispatch/TabOrders/Orders'));
const TabBrokers = lazy(
  () => import('components/Dispatch/TabBrokers/TabBrokers'),
);
const TabFacility = lazy(
  () => import('components/Dispatch/TabFacilities/TabFacility'),
);
const TabCalendar = lazy(
  () => import('components/Dispatch/TabCalendar/TabCalendar'),
);
const Overview = lazy(
  () => import('components/Dispatch/TabReports/Overview/Overview'),
);
const LiveSharingMap = lazy(
  () => import('components/Dispatch/TabOrders/LiveSharing/LiveSharingMap'),
);

const getRedirectURL = (permissions?: string[]) => {
  if (permissions?.includes(PERMISSION_ORDER)) return '/dispatch/orders';

  if (permissions?.includes(PERMISSION_BROKERS)) return '/dispatch/brokers';

  if (permissions?.includes(PERMISSION_FACILITIES))
    return '/dispatch/facilities';

  return '/';
};

/* Dispatch Main Page (redirect into) */
export const mainDispatchRoute = (permissions?: IPermissionObject[]) => {
  const targetName = 'Dispatch';
  const targetObject = permissions?.find(obj => obj.name === targetName);
  return redirectRoute(
    '/dispatch',
    getRedirectURL(targetObject ? targetObject.permissions : []),
  );
};

export const mainOrdersRoute = (
  <PrivateRoutes
    exact
    path="/dispatch/orders"
    component={Orders}
    permissionType={PERMISSION_BROKERS}
  />
);
export const orderCreateRoute = (
  <PrivateRoutes
    exact
    path="/dispatch/orders/create"
    component={CreateOrder}
    permissionType={PERMISSION_ORDER}
  />
);
export const orderProfileRoute = (
  <PrivateRoutes
    exact
    path="/dispatch/orders/:id"
    component={OrderProfile}
    permissionType={PERMISSION_ORDER}
  />
);
export const editOrderRoute = (
  <PrivateRoutes
    exact
    path="/dispatch/orders/:id/edit"
    component={UpdateOrder}
    permissionType={PERMISSION_EDIT_BROKER}
  />
);

/* Brokers */
export const mainBrokersRoute = (
  <PrivateRoutes
    exact
    path="/dispatch/brokers"
    component={TabBrokers}
    permissionType={PERMISSION_BROKERS}
  />
);
export const brokerCreateRoute = (
  <PrivateRoutes
    exact
    path="/dispatch/brokers/create"
    component={BrokerCreate}
    permissionType={PERMISSION_CREATE_BROKER}
  />
);
export const brokerProfileRoute = (
  <PrivateRoutes
    exact
    path="/dispatch/brokers/:id"
    component={BrokerProfile}
    permissionType={PERMISSION_BROKERS}
  />
);
export const editBrokerRoute = (
  <PrivateRoutes
    exact
    path="/dispatch/brokers/:id/edit"
    component={BrokerEdit}
    permissionType={PERMISSION_EDIT_BROKER}
  />
);

/* Facility */
export const mainFacilitiesRoute = (
  <PrivateRoutes
    exact
    path="/dispatch/facilities"
    component={TabFacility}
    permissionType={PERMISSION_FACILITIES}
  />
);
export const facilityCreateRoute = (
  <PrivateRoutes
    exact
    path="/dispatch/facilities/create"
    component={FacilityCreate}
    permissionType={PERMISSION_CREATE_FACILITY}
  />
);
export const facilityProfileRoute = (
  <PrivateRoutes
    exact
    path="/dispatch/facilities/:id"
    component={FacilityProfile}
    permissionType={PERMISSION_FACILITIES}
  />
);
export const editFacilityRoute = (
  <PrivateRoutes
    exact
    path="/dispatch/facilities/:id/edit"
    component={FacilityEdit}
    permissionType={PERMISSION_EDIT_FACILITY}
  />
);

/* Calendar */
export const mainCalendarRoute = (
  <PrivateRoutes
    exact
    path="/dispatch/calendar"
    component={TabCalendar}
    permissionType={PERMISSION_CALENDAR}
  />
);

/* Overview */
export const mainOverviewRoute = (
  <PrivateRoutes
    path="/dispatch/reports/overview/:tab"
    component={Overview}
    permissionType={PERMISSION_ORDER}
  />
);
export const tabOverviewRoute = (
  <PrivateRoutes
    path="/dispatch/reports/overview"
    component={Overview}
    permissionType={PERMISSION_ORDER}
    redirectUrl="/dispatch/reports/overview/offices"
  />
);

/* Live Sharing */
export const publicLiveSharingRoute = (
  <PublicRoutes
    exact
    path="/liveSharing/:link"
    component={LiveSharingMap}
    withPermission={false}
  />
);
