import { AxiosError } from 'axios';

import {
  ICreateTrailerSuccess,
  IDeleteTrailerSuccess,
  IUpdateTrailerSuccess,
} from 'components/Fleet/TabTrailers/_redux/trailersTypes';
import {
  ICreateTruckSuccess,
  IDeleteTruckSuccess,
  IUpdateTruckSuccess,
} from 'components/Fleet/TabTrucks/_redux/trucksTypes';
import {
  ICreateDriverActionSuccess,
  IUpdateDriverActionSuccess,
  IDeleteDriverActionSuccess,
} from 'components/HumanResources/TabDrivers/_redux/driversTypes';
import { IWithPending } from 'utils/models';

export const GET_RESOURCES_AUTOCOMPLETE_DATA =
  '[@@ResourcesAutocomplete] GET_RESOURCES_AUTOCOMPLETE_DATA';

export const GET_RESOURCES_AUTOCOMPLETE_DATA_INIT =
  '[@@ResourcesAutocomplete] GET_RESOURCES_AUTOCOMPLETE_DATA_INIT';

export const GET_RESOURCES_AUTOCOMPLETE_DATA_SUCCESS =
  '[@@ResourcesAutocomplete] GET_RESOURCES_AUTOCOMPLETE_DATA_SUCCESS';

export const GET_RESOURCES_AUTOCOMPLETE_DATA_FAILED =
  '[@@ResourcesAutocomplete] GET_RESOURCES_AUTOCOMPLETE_DATA_FAILED';

export type Vehicle = {
  id: number;
  unit_number: string;
};

export type Employee = {
  id: number;
  full_name: string;
  photo?: string;
  name?: string;
};

type Inspector = {
  id: string;
  full_name: string;
  type: 'employee' | 'driver';
};

export type Driver = Employee & {
  dispatcher?: Employee;
  extra_driver?: Employee;
  trailer?: Vehicle;
  truck?: Vehicle;
};

export type Truck = Vehicle & {
  status: 'available' | 'inactive' | 'assigned'; // see 'truck_statuses' in the user settings
  vin: string;
  make_id: number; // means manufacturer_id, see 'truck_manufacturers' in the user settings
  dispatcher?: Employee;
  driver?: Employee;
  extra_driver?: Employee;
  trailer?: Vehicle;
};

export type Trailer = Vehicle & {
  status: 'available' | 'inactive' | 'assigned'; // see 'trailer_statuses' in the user settings
  vin: string;
  make_id: number; // means manufacturer_id, see 'trailer_manufacturers' in the user settings
  dispatcher?: Employee;
  driver?: Employee;
  extra_driver?: Employee;
  truck?: Vehicle;
  trailer_type?: string;
};

type AbstractVehicle = {
  id: string;
  unit_number: string;
  type: 'truck' | 'trailer';
};

type Company = {
  id: number;
  name: string;
};

export type Resources = {
  trucks?: Truck[];
  trailers?: Trailer[];
  drivers?: Driver[];
  dispatchers?: Employee[];
  inspectors?: Inspector[];
  vehicles?: AbstractVehicle[];
  employees?: Employee[];
  internal_companies?: Company[];
};

export interface IResourcesAutocompleteReducer extends Resources, IWithPending {
  _updatedTime: number; // last updated time in milliseconds
}

export interface IGetResourcesAutocompleteAction {
  type: typeof GET_RESOURCES_AUTOCOMPLETE_DATA;
  callback?: (data: Resources) => void;
  force?: boolean;
}

export interface IGetResourcesAutocompleteActionInit {
  type: typeof GET_RESOURCES_AUTOCOMPLETE_DATA_INIT;
}

export interface IGetResourcesAutocompleteActionSuccess {
  type: typeof GET_RESOURCES_AUTOCOMPLETE_DATA_SUCCESS;
  payload: Resources;
}

export interface IGetResourcesAutocompleteActionFailed {
  type: typeof GET_RESOURCES_AUTOCOMPLETE_DATA_FAILED;
  payload: AxiosError;
}

export type resourcesActionTypes =
  | ICreateDriverActionSuccess
  | IUpdateDriverActionSuccess
  | IDeleteDriverActionSuccess
  | ICreateTruckSuccess
  | IUpdateTruckSuccess
  | IDeleteTruckSuccess
  | ICreateTrailerSuccess
  | IUpdateTrailerSuccess
  | IDeleteTrailerSuccess
  | IGetResourcesAutocompleteAction
  | IGetResourcesAutocompleteActionInit
  | IGetResourcesAutocompleteActionSuccess
  | IGetResourcesAutocompleteActionFailed;
