import { FC, useEffect, FormEvent, FocusEvent } from 'react';

import { MenuItem, ListItemText } from '@mui/material';

import useTestAttrRef from '../../../../../../hooks/useTestAttrRef';
import { BlueCheckbox } from '../../../../CustomMaterialComponents';

type Props = {
  id?: string | number;
  name: string;
  focus?: boolean;
  checked: boolean;
  isNone: boolean;
  handleOnChange: (e: FormEvent<HTMLLIElement>) => void;
  onFocus?: (v: FocusEvent<HTMLLIElement>) => void;
};

const MultiOption: FC<Props> = ({
  id,
  name,
  focus,
  checked,
  isNone,
  handleOnChange,
  onFocus,
}) => {
  const selectedLiRef = useTestAttrRef<HTMLLIElement>(null);

  useEffect(() => {
    if (focus && selectedLiRef.current) {
      selectedLiRef.current.focus();
    }
  }, [focus]);

  const optionStyles = {
    border: '1px solid transparent',
    borderRadius: '4px',
    padding: '2px 16px',
    ':focus': {
      borderColor: 'var(--activeBorder)',
    },
  };

  return (
    <MenuItem
      sx={optionStyles}
      id={name}
      value={id}
      data-checked={checked}
      disabled={isNone && id !== 1}
      onClick={handleOnChange}
      ref={selectedLiRef}
      onFocus={onFocus}
    >
      <BlueCheckbox checked={checked} />
      <ListItemText primary={name} />
    </MenuItem>
  );
};

export default MultiOption;
