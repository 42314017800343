import { RefObject, useEffect } from 'react';

type Props<T> = {
  ref: RefObject<T>;
  callback: (event: MouseEvent | TouchEvent) => void;
  listen?: boolean;
};

export default function useOnClickOutside<T extends HTMLElement = HTMLElement>({
  ref,
  callback,
  listen = true,
}: Props<T>) {
  useEffect(() => {
    const handleClick = (e: MouseEvent | TouchEvent) => {
      if (listen && ref?.current && !ref.current.contains(e.target as Node))
        callback(e);
    };

    document.addEventListener('mousedown', handleClick, true);
    document.addEventListener('touchstart', handleClick, true);

    return () => {
      document.removeEventListener('mousedown', handleClick, true);
      document.removeEventListener('touchstart', handleClick, true);
    };
  }, [ref?.current, callback, listen]);
}
