import moment from 'moment';

export const MONTHS = Object.freeze(
  Array.from(Array(12).keys()).map(m =>
    new Date(0, m + 1, 0).toLocaleString('en', { month: 'long' }),
  ),
);

const daysOfWeek = Object.freeze(
  Array.from(Array(7).keys()).map(wd =>
    new Date(0, 0, wd).toLocaleString('en', { weekday: 'long' }),
  ),
);

export const DAYS_OF_WEEK = {
  0: { id: 7, name: daysOfWeek[0] },
  1: { id: 1, name: daysOfWeek[1] },
  2: { id: 2, name: daysOfWeek[2] },
  3: { id: 3, name: daysOfWeek[3] },
  4: { id: 4, name: daysOfWeek[4] },
  5: { id: 5, name: daysOfWeek[5] },
  6: { id: 6, name: daysOfWeek[6] },
} as const;

export default class CalendarGroup {
  private static calendarNodes: Array<[number, string]> = [];

  private static today: Date | undefined;

  constructor() {
    const today = moment().startOf('day').toDate();
    if (
      !CalendarGroup.today ||
      CalendarGroup.today.getTime() !== today.getTime()
    ) {
      CalendarGroup.reset();
      CalendarGroup.today = today;
    }
  }

  static reset() {
    this.calendarNodes = [];

    const day = moment().startOf('day');
    const tomorrow = moment(day).add(1, 'day');
    this.calendarNodes.push([tomorrow.toDate().getTime(), 'Tomorrow']);
    this.calendarNodes.push([day.toDate().getTime(), 'Today']);
    this.calendarNodes.push([
      day.add(-1, 'day').toDate().getTime(),
      'Yesterday',
    ]);

    const week = moment().startOf('week');
    while (day.toDate().getTime() > week.toDate().getTime()) {
      const dayOfWeek = daysOfWeek[day.add(-1, 'day').day()];
      this.calendarNodes.push([day.toDate().getTime(), dayOfWeek]);
    }

    this.calendarNodes.push([
      week.add(-1, 'week').toDate().getTime(),
      'Last Week',
    ]);

    this.calendarNodes.push([
      week.add(-1, 'week').toDate().getTime(),
      'Two Weeks Ago',
    ]);

    this.calendarNodes.push([
      week.add(-1, 'week').toDate().getTime(),
      'Three Weeks Ago', // just a tail
    ]);

    // future part
    let head = tomorrow.add(1, 'day');
    const nextWeek = moment().startOf('week').add(1, 'week');
    if (nextWeek.toDate().getTime() > head.toDate().getTime()) {
      this.calendarNodes.unshift([head.toDate().getTime(), 'This Week']);
      head = nextWeek.clone();
    }

    nextWeek.add(1, 'week');
    if (nextWeek.toDate().getTime() > head.toDate().getTime()) {
      this.calendarNodes.unshift([head.toDate().getTime(), 'Next Week']);
      head = nextWeek;
    }

    const nextMonth = moment().startOf('month').add(1, 'month');
    if (nextMonth.toDate().getTime() > head.toDate().getTime()) {
      this.calendarNodes.unshift([head.toDate().getTime(), 'This Month']);
      head = nextMonth;
    }

    this.calendarNodes.unshift([head.toDate().getTime(), 'Future']);
  }

  // eslint-disable-next-line class-methods-use-this
  getGroupTitle(date: moment.MomentInput): string {
    const arg = moment(date);
    const timestamp = arg.toDate().getTime();
    const index = CalendarGroup.calendarNodes.lowerBound(n => timestamp < n[0]);

    return index > 0 && index < CalendarGroup.calendarNodes.length - 1
      ? CalendarGroup.calendarNodes[index][1]
      : `${arg.year()}, ${MONTHS[arg.month()]}`;
  }
}
