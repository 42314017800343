import {
  mainSidebarTypes,
  IMainSidebarReducer,
  SET_COLLAPSED_FLAG,
  SET_OVER_FLAG,
  SET_SIDEBAR_CONFIG,
} from './mainSidebarTypes';

const initialState: IMainSidebarReducer = {
  collapsedFlag: false,
  overFlag: false,
  sidebarConfig: [],
};

const mainSidebarReducer = (
  state = initialState,
  action: mainSidebarTypes,
): IMainSidebarReducer => {
  switch (action.type) {
    case SET_COLLAPSED_FLAG: {
      if (state.collapsedFlag === action.payload) break;
      return {
        ...state,
        collapsedFlag: action.payload,
      };
    }

    case SET_OVER_FLAG: {
      if (state.overFlag === action.payload) break;
      return {
        ...state,
        overFlag: action.payload,
      };
    }

    case SET_SIDEBAR_CONFIG:
      return {
        ...state,
        sidebarConfig: action.payload,
      };

    default:
  }
  return state;
};

export default mainSidebarReducer;
