import { isEmpty } from 'ramda';
import { startSubmit, stopSubmit } from 'redux-form';
import { call, put, takeLatest, select, takeLeading } from 'redux-saga/effects';

import {
  setPagesAction,
  setPaginationAction,
} from 'components/_common/Navigation/Pagination/_redux/paginationActions';
import { paginationSelector } from 'components/_common/Navigation/Pagination/_redux/paginationSelectors';
import {
  setSearchResultAction,
  setSearchResultSuccessAction,
  setSearchResultFailAction,
} from 'components/_common/SearchPanel/_redux/searchActions';
import { searchSelector } from 'components/_common/SearchPanel/_redux/searchSelectors';
import { MAX_SEARCH_RESULT } from 'components/_common/SearchPanel/constants';
import { clearAllMapsAction } from 'services/google/_redux/googleActions';
import { getErrors } from 'utils/helpers';
import api from 'utils/requests';

import { transformTollsSearchResult, getFromToDateRange } from './helpers';
import {
  createTollAction,
  getTollAction,
  updateTollAction,
  deleteTollAction,
  getTollsAction,
  setTollPinAction,
  setTollUnPinAction,
  searchTollsAction,
  getTollAlertsAction,
  getTollsStatisticsAction,
  getTollsRoadsStatisticsAction,
  getTollsTrucksStatisticsAction,
} from './tollsActions';
import {
  CREATE_TOLL,
  GET_TOLLS,
  GET_TOLL,
  UPDATE_TOLL,
  DELETE_TOLL,
  GET_TOLLS_SUCCESS,
  GET_TOLLS_FAIL,
  GET_TOLL_SUCCESS,
  GET_TOLL_FAIL,
  CREATE_TOLL_SUCCESS,
  CREATE_TOLL_FAIL,
  UPDATE_TOLL_SUCCESS,
  UPDATE_TOLL_FAIL,
  DELETE_TOLL_SUCCESS,
  DELETE_TOLL_FAIL,
  IGetTollActionSuccess,
  IGetTollsActionSuccess,
  IGetTollsActionFail,
  IGetTollActionFail,
  ICreateTollActionSuccess,
  ICreateTollActionFail,
  IUpdateTollActionSuccess,
  IUpdateTollActionFail,
  IDeleteTollActionSuccess,
  IDeleteTollActionFail,
  PIN_TOLL_SUCCESS,
  PIN_TOLL_FAIL,
  PIN_TOLL,
  UNPIN_TOLL,
  UNPIN_TOLL_SUCCESS,
  UNPIN_TOLL_FAIL,
  SEARCH_TOLLS,
  GET_TOLL_ALERTS,
  GET_TOLL_ALERTS_SUCCESS,
  GET_TOLL_ALERTS_FAIL,
  IGetTollAlertsActionSuccess,
  IGetTollAlertsActionFail,
  GET_TOLLS_STATISTICS_SUCCESS,
  GET_TOLLS_STATISTICS,
  GET_TOLLS_STATISTICS_FAIL,
  IGetTollsStatisticsActionSuccess,
  IGetTollsStatisticsActionFail,
  GET_TOLLS_ROADS_STATISTICS_SUCCESS,
  GET_TOLLS_ROADS_STATISTICS_FAIL,
  IGetTollsRoadsStatisticsActionSuccess,
  IGetTollsRoadsStatisticsActionFail,
  GET_TOLLS_ROADS_STATISTICS,
  IGetTollsTrucksStatisticsActionSuccess,
  GET_TOLLS_TRUCKS_STATISTICS_SUCCESS,
  IGetTollsTrucksStatisticsActionFail,
  GET_TOLLS_TRUCKS_STATISTICS_FAIL,
  GET_TOLLS_TRUCKS_STATISTICS,
} from './tollsTypes';

function* searchTollsSaga({
  payload: { viewKey },
}: ReturnType<typeof searchTollsAction>) {
  const { searchValue } = yield select(searchSelector);
  if (searchValue) {
    try {
      yield put(setSearchResultAction([]));
      const { data } = yield call(() =>
        api.get(
          `/v1/fleets/geofences/tolls?page=1&per_page=${MAX_SEARCH_RESULT}&search=${
            searchValue || ''
          }`,
        ),
      );
      const { geofences, pagination } = data;
      yield put(
        setSearchResultSuccessAction(transformTollsSearchResult(geofences)),
      );
      if (viewKey === 'map' && !isEmpty(geofences)) {
        yield put(clearAllMapsAction());
        yield put<IGetTollsActionSuccess>({
          type: GET_TOLLS_SUCCESS,
          payload: data,
        });
        yield put(setPagesAction(pagination && pagination.pages));
      } else if (viewKey === 'map' && isEmpty(geofences)) {
        yield put(
          getTollsAction({ data: { query: '', usePaginationFlag: true } }),
        );
      }
    } catch (e) {
      if (api.isAxiosError(e)) yield put(setSearchResultFailAction(e));
    }
  } else {
    yield put(getTollsAction({ data: { query: '', usePaginationFlag: true } }));
  }
}

function* getTollsSaga({
  payload: {
    data: { query, usePaginationFlag },
    callback,
  },
}: ReturnType<typeof getTollsAction>) {
  try {
    const { per_page, page } = yield select(paginationSelector);
    const { data } = yield call(() =>
      api.get(
        `/v1/fleets/geofences/tolls?page=${page}&per_page=${per_page}${
          query || ''
        }`,
      ),
    );
    const { pagination } = data;
    yield put<IGetTollsActionSuccess>({
      type: GET_TOLLS_SUCCESS,
      payload: data,
    });

    // Pagination set
    if (usePaginationFlag) yield put(setPaginationAction(pagination));

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetTollsActionFail>({ type: GET_TOLLS_FAIL, payload: e });
  }
}

function* getTollSaga({
  payload: {
    data: { id },
    callback,
  },
}: ReturnType<typeof getTollAction>) {
  try {
    const { data } = yield call(() => api.get(`/v1/fleets/geofences/${id}`));

    yield put<IGetTollActionSuccess>({ type: GET_TOLL_SUCCESS, payload: data });
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetTollActionFail>({ type: GET_TOLL_FAIL, payload: e });
  }
}

function* createTollSaga({
  payload: {
    data: { values },
    callback,
  },
}: ReturnType<typeof createTollAction>) {
  try {
    yield put(startSubmit('createAndUpdateTollForm'));
    const { data } = yield call(() => api.post('/v1/fleets/geofences', values));
    yield put<ICreateTollActionSuccess>({
      type: CREATE_TOLL_SUCCESS,
      payload: data,
    });
    yield put(stopSubmit('createAndUpdateTollForm'));
    if (callback) callback(data);
  } catch (e) {
    if (api.isAxiosError(e)) {
      yield put<ICreateTollActionFail>({
        type: CREATE_TOLL_FAIL,
        payload: e,
      });
      yield put(stopSubmit('createAndUpdateTollForm', getErrors(e)));
    }
  }
}

function* updateTollSaga({
  payload: {
    data: { id, values },
    callback,
  },
}: ReturnType<typeof updateTollAction>) {
  try {
    yield put(startSubmit('createAndUpdateTollForm'));
    const { data } = yield call(() =>
      api.patch(`/v1/fleets/geofences/${id}`, values),
    );
    yield put<IUpdateTollActionSuccess>({
      type: UPDATE_TOLL_SUCCESS,
      payload: data,
    });
    yield put(stopSubmit('createAndUpdateTollForm'));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) {
      yield put<IUpdateTollActionFail>({
        type: UPDATE_TOLL_FAIL,
        payload: e,
      });
      yield put(stopSubmit('createAndUpdateTollForm', getErrors(e)));
    }
  }
}

function* deleteTollSaga({
  payload: {
    data: { id },
    callback,
  },
}: ReturnType<typeof deleteTollAction>) {
  try {
    yield call(() => api.delete(`/v1/fleets/geofences/${id}`));
    yield put<IDeleteTollActionSuccess>({
      type: DELETE_TOLL_SUCCESS,
      payload: {
        id: +id,
      },
    });
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IDeleteTollActionFail>({ type: DELETE_TOLL_FAIL, payload: e });
  }
}

function* setTollPinSaga({
  payload: {
    data: { id },
    callback,
  },
}: ReturnType<typeof setTollPinAction>) {
  try {
    yield call(() => api.post(`/v1/fleets/geofences/${id}/pin`));
    if (callback) callback();
    yield put({ type: PIN_TOLL_SUCCESS, payload: id });
  } catch (error) {
    yield put({ type: PIN_TOLL_FAIL, payload: error });
  }
}

function* setTollUnPinSaga({
  payload: {
    data: { id },
    callback,
  },
}: ReturnType<typeof setTollUnPinAction>) {
  try {
    yield call(() => api.delete(`/v1/fleets/geofences/${id}/pin`));
    yield put({ type: UNPIN_TOLL_SUCCESS, payload: id });
    if (callback) callback();
  } catch (error) {
    yield put({ type: UNPIN_TOLL_FAIL, payload: error });
  }
}

function* getTollAlertsSaga({
  payload: {
    data: { id },
    callback,
  },
}: ReturnType<typeof getTollAlertsAction>) {
  try {
    const { per_page, page } = yield select(paginationSelector);

    const { data } = yield call(() =>
      api.get(
        `/v1/fleets/geofences/${id}/alerts?page=${page}&per_page=${per_page}`,
      ),
    );
    yield put<IGetTollAlertsActionSuccess>({
      type: GET_TOLL_ALERTS_SUCCESS,
      payload: data,
    });

    yield put(setPagesAction(data.pagination && data.pagination.pages));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetTollAlertsActionFail>({
        type: GET_TOLL_ALERTS_FAIL,
        payload: e,
      });
  }
}

function* getTollsStatisticsSaga({
  payload: {
    data: { dateRange },
    callback,
  },
}: ReturnType<typeof getTollsStatisticsAction>) {
  try {
    const { dateFrom, dateTo } = getFromToDateRange(dateRange);
    const { data } = yield call(() =>
      api.get(
        `/v1/fleets/toll_roads_statistics/total?from=${dateFrom}&to=${dateTo}`,
      ),
    );
    yield put<IGetTollsStatisticsActionSuccess>({
      type: GET_TOLLS_STATISTICS_SUCCESS,
      payload: data,
    });
    if (data.pagination && data.pagination.pages) {
      yield put(setPagesAction(data.pagination && data.pagination.pages));
    }
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetTollsStatisticsActionFail>({
        type: GET_TOLLS_STATISTICS_FAIL,
        payload: e,
      });
  }
}

function* getTollsRoadsStatisticsSaga({
  payload: {
    data: { dateRange, page, perPage },
    callback,
  },
}: ReturnType<typeof getTollsRoadsStatisticsAction>) {
  try {
    const { dateFrom, dateTo } = getFromToDateRange(dateRange);
    const { data } = yield call(() =>
      api.get(
        `/v1/fleets/toll_roads_statistics/toll_roads?from=${dateFrom}&to=${dateTo}&page=${page}&per_page=${perPage}`,
      ),
    );
    yield put<IGetTollsRoadsStatisticsActionSuccess>({
      type: GET_TOLLS_ROADS_STATISTICS_SUCCESS,
      payload: data,
    });

    yield put(setPagesAction(data.pagination && data.pagination.pages));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetTollsRoadsStatisticsActionFail>({
        type: GET_TOLLS_ROADS_STATISTICS_FAIL,
        payload: e,
      });
  }
}

function* getTollsTrucksStatisticsSaga({
  payload: {
    data: { dateRange, page, perPage },
    callback,
  },
}: ReturnType<typeof getTollsTrucksStatisticsAction>) {
  try {
    const { dateFrom, dateTo } = getFromToDateRange(dateRange);
    const { data } = yield call(() =>
      api.get(
        `/v1/fleets/toll_roads_statistics/trucks?from=${dateFrom}&to=${dateTo}&page=${page}&per_page=${perPage}`,
      ),
    );
    yield put<IGetTollsTrucksStatisticsActionSuccess>({
      type: GET_TOLLS_TRUCKS_STATISTICS_SUCCESS,
      payload: data,
    });
    if (data.pagination && data.pagination.pages) {
      yield put(setPagesAction(data.pagination && data.pagination.pages));
    }
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetTollsTrucksStatisticsActionFail>({
        type: GET_TOLLS_TRUCKS_STATISTICS_FAIL,
        payload: e,
      });
  }
}

export default function* tollsSaga() {
  yield takeLatest(SEARCH_TOLLS, searchTollsSaga);
  yield takeLeading(GET_TOLLS, getTollsSaga);
  yield takeLatest(GET_TOLL, getTollSaga);
  yield takeLatest(UPDATE_TOLL, updateTollSaga);
  yield takeLatest(DELETE_TOLL, deleteTollSaga);
  yield takeLeading(CREATE_TOLL, createTollSaga);
  yield takeLeading(PIN_TOLL, setTollPinSaga);
  yield takeLeading(UNPIN_TOLL, setTollUnPinSaga);
  yield takeLatest(GET_TOLL_ALERTS, getTollAlertsSaga);
  yield takeLatest(GET_TOLLS_STATISTICS, getTollsStatisticsSaga);
  yield takeLatest(GET_TOLLS_ROADS_STATISTICS, getTollsRoadsStatisticsSaga);
  yield takeLatest(GET_TOLLS_TRUCKS_STATISTICS, getTollsTrucksStatisticsSaga);
}
