import {
  FC,
  memo,
  useCallback,
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useState,
  useEffect,
} from 'react';

import clsx from 'clsx';

import { IconSearch, IconClose } from 'assets/icons/components';
import useDebounceCallback from 'hooks/useDebounceCallback';

import styles from './SearchInput.module.scss';

type Props = {
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  changeDelay?: number; // milliseconds, 0 means update immediatelly, negative value (-1 e.g.) means update by Enter or focus lost
  visible?: boolean;
  searchIcon?: React.FC | null;
  className?: string;
};

const SearchInput: FC<Props> = ({
  value: propValue = '',
  onChange,
  placeholder = 'Search',
  changeDelay = 500,
  visible = true,
  searchIcon = <IconSearch />,
  className,
}) => {
  const [currValue, setCurrValue] = useState(propValue);
  useEffect(() => setCurrValue(propValue), [propValue]);

  const onChangeDebounced = useDebounceCallback(onChange, changeDelay);

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setCurrValue(event.target.value);
      if (changeDelay > 0) onChangeDebounced(event.target.value);
      else if (changeDelay === 0) onChange(event.target.value);
    },
    [changeDelay, setCurrValue, onChange, onChangeDebounced],
  );

  const onClearHandler = useCallback(() => {
    setCurrValue('');
    onChange('');
    onChangeDebounced('');
  }, [setCurrValue, onChange, onChangeDebounced]);

  const onKeyUpHanlder = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        if (changeDelay < 0) onChange(currValue);
      } else if (event.key === 'Escape') {
        onClearHandler();
      }
    },
    [currValue, changeDelay, onChange, onClearHandler],
  );

  const onBlurHandler = useCallback(
    (event: FocusEvent<HTMLInputElement>) => onChange(event.target.value),
    [onChange],
  );

  return (
    <div
      attr-type="search_edit"
      className={clsx(styles.root, !visible && styles.hidden, className)}
    >
      {!!searchIcon && searchIcon}
      <input
        type="text"
        placeholder={placeholder}
        value={currValue}
        onChange={onChangeHandler}
        onKeyUp={onKeyUpHanlder}
        onBlur={changeDelay < 0 ? onBlurHandler : undefined}
      />
      {currValue && (
        <button onClick={onClearHandler}>
          <IconClose />
        </button>
      )}
    </div>
  );
};

export default memo(SearchInput);
