import { FC } from 'react';

type OwnProps = {
  className?: string;
  color?: string;
};

const IconPreset: FC<OwnProps> = ({
  className,
  color = 'var(--primaryText)',
}) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="10" r="6" stroke={color} strokeWidth="2" />
      <circle cx="15" cy="14" r="6" stroke={color} strokeWidth="2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 17C12.866 17 16 13.866 16 10C16 8.92941 15.7597 7.91495 15.33 7.00764C15.2206 7.00257 15.1106 7 15 7C11.134 7 8 10.134 8 14C8 15.0706 8.24034 16.085 8.67001 16.9924C8.77937 16.9974 8.88939 17 9 17Z"
        fill={color}
      />
    </svg>
  );
};

export default IconPreset;
