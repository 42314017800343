import { FC, ChangeEvent, useEffect } from 'react';

import { ListItem, ListItemText, List } from '@mui/material';
import clsx from 'clsx';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import {
  BlackTooltip,
  BlueCheckbox,
} from 'components/_common/CustomMaterialComponents';
import {
  ISelectOption,
  ISelectOptions,
} from 'components/_common/FormElements/FormField/Select/_models/selectModels';
import useTestAttrRef, { getAttrName } from 'hooks/useTestAttrRef';

import styles from './MultiCheckbox.module.scss';

type CheckboxSelectOption = ISelectOption & {
  disabled?: boolean;
  tooltipText?: string;
};

type OwnProps = {
  id: string;
  initialTextTransform?: boolean;
  options: ISelectOptions<CheckboxSelectOption>;
  focusOnRender?: boolean;
};

type Props = OwnProps & WrappedFieldInputProps & WrappedFieldMetaProps;

const MultiCheckbox: FC<Partial<Props>> = ({
  id,
  name: checkboxesName,
  options,
  onChange,
  value,
  initialTextTransform,
  focusOnRender,
}) => {
  const firstItemRef = useTestAttrRef<HTMLInputElement>(null);

  const valuesForCheckboxes: string[] = (value && value.split(',')) || [];

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    // Setting
    const { checked, value: localValue } = e.target;
    let newInput = `${value}${
      checked ? `${(value && ',') || ''}${localValue}` : ''
    }`;

    // Deleting
    const filteredValue = value
      .split(',')
      .filter((v: string) => v === localValue)
      .join('');
    if (filteredValue)
      newInput = newInput
        .split(',')
        .filter((v: string) => v !== filteredValue)
        .join(',');

    // Replacing all commas
    newInput = newInput.replace(/,,/g, ',').replace(/,\s*$/, '');

    // Change in form
    if (onChange) onChange(newInput);
  };

  useEffect(() => {
    // focus after 0.75s ( magic number )
    // if faster - material incorrectly show focus styles
    if (focusOnRender && firstItemRef?.current) {
      setTimeout(() => {
        firstItemRef?.current?.focus();
      }, 750);
    }
  }, [focusOnRender]);

  return (
    <List
      id={id}
      className={styles.root}
      data-test={`${checkboxesName}_checkboxes`.toLowerCase()}
    >
      {options &&
        Object.keys(options).map((key, index) => {
          const { id: itemId, name, disabled, tooltipText = '' } = options[key];
          const isChecked = valuesForCheckboxes.some(
            (valueForCheckboxes: string) =>
              valueForCheckboxes === itemId.toString(),
          );
          return (
            <BlackTooltip
              key={itemId}
              title={<div className={styles.tooltipContent}>{tooltipText}</div>}
              maxWidth={205}
              placement="top"
              arrow
              disableHoverListener={!tooltipText}
              disableFocusListener={!tooltipText}
            >
              <ListItem
                key={itemId}
                value={itemId}
                className={clsx(styles.item, {
                  [styles.itemInitialText]: initialTextTransform,
                })}
              >
                <BlueCheckbox
                  inputRef={index === 0 ? firstItemRef : null}
                  data-test={`${getAttrName(name)}_checkbox`}
                  data-value={isChecked ? 'checked' : 'unchecked'}
                  className={styles.checkbox}
                  color="primary"
                  onChange={handleChange}
                  value={itemId}
                  checked={isChecked}
                  disabled={disabled}
                />
                <ListItemText
                  {...(tooltipText ? { secondary: name } : { primary: name })}
                />
              </ListItem>
            </BlackTooltip>
          );
        })}
    </List>
  );
};

export default MultiCheckbox;
