import { IWithPending, IWithLoaded, IWithError } from 'utils/models';

import { IFeedbackUploadedFile } from '../_models/feedbackModels';
import {
  DELETE_FEEDBACK_FILES,
  DELETE_FEEDBACK_FILES_FAIL,
  DELETE_FEEDBACK_FILES_SUCCESS,
  UPLOAD_FEEDBACK_FILES,
  UPLOAD_FEEDBACK_FILES_FAIL,
  UPLOAD_FEEDBACK_FILES_SUCCESS,
  feedbackTypes,
  UNMOUNT_FEEDBACK_FORM,
} from './feedbackTypes';

export interface IFeedbackState extends IWithPending, IWithLoaded, IWithError {
  files: IFeedbackUploadedFile[];
}

const initialState: IFeedbackState = {
  files: [],
  _pending: false,
  _loaded: false,
  _error: null,
};

const feedbackReducers = (
  state = initialState,
  action: feedbackTypes,
): IFeedbackState => {
  switch (action.type) {
    // UPLOAD FEEDBACK FILES
    case UPLOAD_FEEDBACK_FILES:
      return {
        ...state,
        files: [
          ...state.files,
          ...action.payload.files.map(
            file =>
              ({
                ...file,
                name: file.name,
                loading: true,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } as any),
          ),
        ],
        _pending: true,
        _loaded: false,
        _error: null,
      };
    case UPLOAD_FEEDBACK_FILES_SUCCESS:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        files: state.files.map((file: any, index: number) =>
          index === state.files.findIndex(({ loading }) => loading)
            ? { ...action.payload, loading: false }
            : file,
        ),
        _pending: false,
        _loaded: true,
      };
    case UPLOAD_FEEDBACK_FILES_FAIL:
      return {
        ...state,
        _pending: false,
        _error: action.payload,
      };

    // DELETE FEEDBACK FILES
    case DELETE_FEEDBACK_FILES:
      return { ...state, _pending: true, _loaded: false, _error: null };
    case DELETE_FEEDBACK_FILES_SUCCESS:
      return {
        ...state,
        files: state.files.filter(file => !action.payload.includes(file.id)),
        _pending: false,
        _loaded: true,
      };
    case DELETE_FEEDBACK_FILES_FAIL:
      return {
        ...state,
        _pending: false,
        _error: action.payload,
      };

    // UNMOUNT FEEDBACK FORM
    case UNMOUNT_FEEDBACK_FORM:
      return { ...state, files: [] };

    default:
      return state;
  }
};

export default feedbackReducers;
