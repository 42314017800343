import { FC } from 'react';

type Props = {
  className?: string;
  fillColor?: string;
};

const IconNoImage: FC<Props> = ({
  className,
  fillColor = 'var(--secondaryText)',
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g stroke={fillColor}>
        <path
          d="M2 6C2 4.93913 2.42143 3.92172 3.17157 3.17157C3.92172 2.42143 4.93913 2 6 2H18C19.0609 2 20.0783 2.42143 20.8284 3.17157C21.5786 3.92172 22 4.93913 22 6V18C22 19.0609 21.5786 20.0783 20.8284 20.8284C20.0783 21.5786 19.0609 22 18 22H6C4.93913 22 3.92172 21.5786 3.17157 20.8284C2.42143 20.0783 2 19.0609 2 18V6Z"
          stroke="#D3D3D3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 11C9.88071 11 11 9.88071 11 8.5C11 7.11929 9.88071 6 8.5 6C7.11929 6 6 7.11929 6 8.5C6 9.88071 7.11929 11 8.5 11Z"
          stroke="#D3D3D3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.526 12.6193L6 21.9983H18.133C19.1586 21.9983 20.1422 21.5909 20.8674 20.8657C21.5926 20.1405 22 19.1569 22 18.1313V17.9983C22 17.5323 21.825 17.3533 21.51 17.0083L17.48 12.6133C17.2922 12.4085 17.0637 12.245 16.8092 12.1333C16.5546 12.0217 16.2796 11.9643 16.0017 11.9648C15.7237 11.9654 15.449 12.0239 15.1949 12.1366C14.9408 12.2493 14.713 12.4137 14.526 12.6193V12.6193Z"
          stroke="#D3D3D3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default IconNoImage;
