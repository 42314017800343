import { AxiosError } from 'axios';

import {
  IInspectionFile,
  InspectionFileView,
  InspectionPhotoCommentLocal,
  InspectionPhotoCommentServer,
} from 'components/Fleet/_models/commonModels';
import {
  IVehicleDraftStart,
  IInspection,
  IDraftInspection,
} from 'components/Fleet/TabInspections/_models/vehicleInspectionModels';
import { IClearInspectionErrorAction } from 'components/Fleet/TabInspections/_redux/tabInspectionsTypes';
import { ITrailerInspection } from 'components/Fleet/TabTrailers/TrailerProfile/TrailerInspections/_models/trailerInspection';
import { ILogout } from 'redux/_common/models';

// DRAFT_TRAILER_INSPECTION
export const DRAFT_TRAILER_INSPECTION =
  '[@@TrailerInspection] DRAFT_TRAILER_INSPECTION';
export const DRAFT_TRAILER_INSPECTION_SUCCESS =
  '[@@TrailerInspection] DRAFT_TRAILER_INSPECTION_SUCCESS';
export const DRAFT_TRAILER_INSPECTION_FAIL =
  '[@@TrailerInspection] DRAFT_TRAILER_INSPECTION_FAIL';

export interface IDraftTrailerInspectionAction {
  type: typeof DRAFT_TRAILER_INSPECTION;
  payload: {
    data: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      formData: any;
      inspectionId: number;
    };
    callback?: () => void;
  };
}
export interface IDraftTrailerInspectionActionSuccess {
  type: typeof DRAFT_TRAILER_INSPECTION_SUCCESS;
  payload: IInspection;
}
export interface IDraftTrailerInspectionActionFail {
  type: typeof DRAFT_TRAILER_INSPECTION_FAIL;
  payload: AxiosError;
}

export const INITIALIZE_DRAFT_TRAILER_INSPECTION =
  '[@@TrailerInspection] INITIALIZE_DRAFT_TRAILER_INSPECTION';
export const INITIALIZE_DRAFT_TRAILER_INSPECTION_SUCCESS =
  '[@@TrailerInspection] INITIALIZE_DRAFT_TRAILER_INSPECTION_SUCCESS';
export const INITIALIZE_DRAFT_TRAILER_INSPECTION_FAIL =
  '[@@TrailerInspection] INITIALIZE_DRAFT_TRAILER_INSPECTION_FAIL';

export interface IInitializeDraftTrailerInspectionAction {
  type: typeof INITIALIZE_DRAFT_TRAILER_INSPECTION;
  payload: {
    data: {
      formData: IVehicleDraftStart;
    };
    callback?: (data?: { inspection: ITrailerInspection }) => void;
  };
}
export interface IInitialiseDraftTrailerInspectionActionSuccess {
  type: typeof INITIALIZE_DRAFT_TRAILER_INSPECTION_SUCCESS;
  payload: {
    inspection: Omit<IDraftInspection, 'vehicle_inspections'> & {
      vehicle_inspections: [ITrailerInspection];
    };
  };
}
export interface IInitialiseDraftTrailerInspectionActionFail {
  type: typeof INITIALIZE_DRAFT_TRAILER_INSPECTION_FAIL;
  payload: AxiosError;
}

// DELETE_TRAILER_INSPECTION
export const DELETE_TRAILER_INSPECTION =
  '[@@TrailerInspection] DELETE_TRAILER_INSPECTION';
export const DELETE_TRAILER_INSPECTION_SUCCESS =
  '[@@TrailerInspection] DELETE_TRAILER_INSPECTION_SUCCESS';
export const DELETE_TRAILER_INSPECTION_FAIL =
  '[@@TrailerInspection] DELETE_TRAILER_INSPECTION_FAIL';

export interface IDeleteTrailerInspectionAction {
  type: typeof DELETE_TRAILER_INSPECTION;
  payload: {
    data: {
      inspectionId: number;
      trailerId: number;
    };
    callback?: () => void;
  };
}
export interface IDeleteTrailerInspectionActionSuccess {
  type: typeof DELETE_TRAILER_INSPECTION_SUCCESS;
}
export interface IDeleteTrailerInspectionActionFail {
  type: typeof DELETE_TRAILER_INSPECTION_FAIL;
  payload: AxiosError;
}

// GET_TRAILER_INSPECTION_DRAFT
export const GET_TRAILER_INSPECTION_DRAFT =
  '[@@TrailerInspection] GET_TRAILER_INSPECTION_DRAFT';
export const GET_TRAILER_INSPECTION_DRAFT_SUCCESS =
  '[@@TrailerInspection] GET_TRAILER_INSPECTION_DRAFT_SUCCESS';
export const GET_TRAILER_INSPECTION_DRAFT_FAIL =
  '[@@TrailerInspection] GET_TRAILER_INSPECTION_DRAFT_FAIL';

export interface IGetTrailerInspectionDraftAction {
  type: typeof GET_TRAILER_INSPECTION_DRAFT;
  payload: {
    data: {
      inspectionId: number;
    };
    callback?: () => void;
  };
}
export interface IGetTrailerInspectionDraftActionSuccess {
  type: typeof GET_TRAILER_INSPECTION_DRAFT_SUCCESS;
  payload: {
    inspection: Omit<IDraftInspection, 'vehicle_inspections'> & {
      vehicle_inspections: [ITrailerInspection];
    };
  };
}
export interface IGetTrailerInspectionDraftActionFail {
  type: typeof GET_TRAILER_INSPECTION_DRAFT_FAIL;
  payload: AxiosError;
}

// GET_TRAILER_INSPECTION
export const GET_TRAILER_INSPECTION =
  '[@@TrailerInspection] GET_TRAILER_INSPECTION';
export const GET_TRAILER_INSPECTION_SUCCESS =
  '[@@TrailerInspection] GET_TRAILER_INSPECTION_SUCCESS';
export const GET_TRAILER_INSPECTION_FAIL =
  '[@@TrailerInspection] GET_TRAILER_INSPECTION_FAIL';

export interface IGetTrailerInspectionAction {
  type: typeof GET_TRAILER_INSPECTION;
  payload: {
    data: {
      inspectionId: number;
    };
    callback?: () => void;
  };
}
export interface IGetTrailerInspectionActionSuccess {
  type: typeof GET_TRAILER_INSPECTION_SUCCESS;
  payload: {
    inspection: IInspection;
  };
}
export interface IGetTrailerInspectionActionFail {
  type: typeof GET_TRAILER_INSPECTION_FAIL;
  payload: AxiosError;
}

// CLEAR_CURRENT_TRAILER_INSPECTION
export const CLEAR_CURRENT_TRAILER_INSPECTION =
  '[@@TrailerInspection]  CLEAR_TRAILER_INSPECTION';
export interface IClearCurrentTrailerInspectionAction {
  type: typeof CLEAR_CURRENT_TRAILER_INSPECTION;
}

// UPLOAD_TRAILER_INSPECTION_PHOTO
export const UPLOAD_TRAILER_INSPECTION_PHOTO =
  '[@@TrailerInspection] UPLOAD_TRAILER_INSPECTION_PHOTO';
export const UPLOAD_TRAILER_INSPECTION_PHOTO_SUCCESS =
  '[@@TrailerInspection] UPLOAD_TRAILER_INSPECTION_PHOTO_SUCCESS';
export const UPLOAD_TRAILER_INSPECTION_PHOTO_FAIL =
  '[@@TrailerInspection] UPLOAD_TRAILER_INSPECTION_PHOTO_FAIL';

export interface IUploadTrailerInspectionPhotoAction {
  type: typeof UPLOAD_TRAILER_INSPECTION_PHOTO;
  payload: {
    data: {
      file: File;
      trailerId?: number;
      view: InspectionFileView;
      fieldName: string;
      inspectionId: number;
      uploadIndex: number;
    };
    callback?: () => void;
  };
}
export interface IUploadTrailerInspectionPhotoActionSuccess {
  type: typeof UPLOAD_TRAILER_INSPECTION_PHOTO_SUCCESS;
  payload: IInspectionFile;
  uploadIndex: number;
}
export interface IUploadTrailerInspectionPhotoActionFail {
  type: typeof UPLOAD_TRAILER_INSPECTION_PHOTO_FAIL;
  payload: AxiosError;
}

// DELETE_TRAILER_INSPECTION_PHOTO
export const DELETE_TRAILER_INSPECTION_PHOTO =
  '[@@TrailerInspection] DELETE_TRAILER_INSPECTION_PHOTO';
export const DELETE_TRAILER_INSPECTION_PHOTO_SUCCESS =
  '[@@TrailerInspection] DELETE_TRAILER_INSPECTION_PHOTO_SUCCESS';
export const DELETE_TRAILER_INSPECTION_PHOTO_FAIL =
  '[@@TrailerInspection] DELETE_TRAILER_INSPECTION_PHOTO_FAIL';

export interface IDeleteTrailerInspectionPhotoAction {
  type: typeof DELETE_TRAILER_INSPECTION_PHOTO;
  payload: {
    data: {
      id: number;
      trailerId: number;
    };
    callback?: () => void;
  };
}
export interface IDeleteTrailerInspectionPhotoActionSuccess {
  type: typeof DELETE_TRAILER_INSPECTION_PHOTO_SUCCESS;
  payload: number;
}
export interface IDeleteTrailerInspectionPhotoActionFail {
  type: typeof DELETE_TRAILER_INSPECTION_PHOTO_FAIL;
  payload: AxiosError;
}

// ADD_TRAILER_INSPECTION_PHOTO_COMMENT
export const ADD_TRAILER_INSPECTION_PHOTO_COMMENT =
  '[@@TrailerInspection] ADD_TRAILER_INSPECTION_PHOTO_COMMENT';
export const ADD_TRAILER_INSPECTION_PHOTO_COMMENT_SUCCESS =
  '[@@TrailerInspection] ADD_TRAILER_INSPECTION_PHOTO_COMMENT_SUCCESS';
export const ADD_TRAILER_INSPECTION_PHOTO_COMMENT_FAIL =
  '[@@TrailerInspection] ADD_TRAILER_INSPECTION_PHOTO_COMMENT_FAIL';

export interface IAddTrailerInspectionPhotoCommentAction {
  type: typeof ADD_TRAILER_INSPECTION_PHOTO_COMMENT;
  payload: {
    data: {
      fileId: number;
      trailerId?: number;
      commentData: InspectionPhotoCommentLocal;
    };
    callback?: () => void;
  };
}
export interface IAddTrailerInspectionPhotoCommentActionSuccess {
  type: typeof ADD_TRAILER_INSPECTION_PHOTO_COMMENT_SUCCESS;
  payload: { fileId: number; comment: InspectionPhotoCommentServer };
}
export interface IAddTrailerInspectionPhotoCommentActionFail {
  type: typeof ADD_TRAILER_INSPECTION_PHOTO_COMMENT_FAIL;
  payload: AxiosError;
}
// UPDATE_TRAILER_INSPECTION_PHOTO_COMMENT
export const UPDATE_TRAILER_INSPECTION_PHOTO_COMMENT =
  '[@@TrailerInspection] UPDATE_TRAILER_INSPECTION_PHOTO_COMMENT';
export const UPDATE_TRAILER_INSPECTION_PHOTO_COMMENT_SUCCESS =
  '[@@TrailerInspection] UPDATE_TRAILER_INSPECTION_PHOTO_COMMENT_SUCCESS';
export const UPDATE_TRAILER_INSPECTION_PHOTO_COMMENT_FAIL =
  '[@@TrailerInspection] UPDATE_TRAILER_INSPECTION_PHOTO_COMMENT_FAIL';

export interface IUpdateTrailerInspectionPhotoCommentAction {
  type: typeof UPDATE_TRAILER_INSPECTION_PHOTO_COMMENT;
  payload: {
    data: {
      fileId: number;
      trailerId?: number;
      commentData: InspectionPhotoCommentServer;
    };
    callback?: () => void;
  };
}
export interface IUpdateTrailerInspectionPhotoCommentActionSuccess {
  type: typeof UPDATE_TRAILER_INSPECTION_PHOTO_COMMENT_SUCCESS;
  payload: { fileId: number; comment: InspectionPhotoCommentServer };
}
export interface IUpdateTrailerInspectionPhotoCommentActionFail {
  type: typeof UPDATE_TRAILER_INSPECTION_PHOTO_COMMENT_FAIL;
  payload: AxiosError;
}
// DELETE_TRAILER_INSPECTION_PHOTO_COMMENT
export const DELETE_TRAILER_INSPECTION_PHOTO_COMMENT =
  '[@@TrailerInspection] DELETE_TRAILER_INSPECTION_PHOTO_COMMENT';
export const DELETE_TRAILER_INSPECTION_PHOTO_COMMENT_SUCCESS =
  '[@@TrailerInspection] DELETE_TRAILER_INSPECTION_PHOTO_COMMENT_SUCCESS';
export const DELETE_TRAILER_INSPECTION_PHOTO_COMMENT_FAIL =
  '[@@TrailerInspection] DELETE_TRAILER_INSPECTION_PHOTO_COMMENT_FAIL';

export interface IDeleteTrailerInspectionPhotoCommentAction {
  type: typeof DELETE_TRAILER_INSPECTION_PHOTO_COMMENT;
  payload: {
    data: {
      fileId: number;
      trailerId?: number;
      commentId: number;
    };
    callback?: () => void;
  };
}
export interface IDeleteTrailerInspectionPhotoCommentActionSuccess {
  type: typeof DELETE_TRAILER_INSPECTION_PHOTO_COMMENT_SUCCESS;
  payload: { fileId: number; commentId: number };
}
export interface IDeleteTrailerInspectionPhotoCommentActionFail {
  type: typeof DELETE_TRAILER_INSPECTION_PHOTO_COMMENT_FAIL;
  payload: AxiosError;
}

// OTHER
export const UNMOUNT_TRAILER_INSPECTION_FORM =
  '[@@TrailerInspection] UNMOUNT_TRAILER_INSPECTION_FORM';
export interface IUnmountTrailerInspectionFormAction {
  type: typeof UNMOUNT_TRAILER_INSPECTION_FORM;
}

export type TrailerInspectionActions =
  | IUploadTrailerInspectionPhotoAction
  | IUploadTrailerInspectionPhotoActionSuccess
  | IUploadTrailerInspectionPhotoActionFail
  | IDeleteTrailerInspectionPhotoAction
  | IDeleteTrailerInspectionPhotoActionSuccess
  | IDeleteTrailerInspectionPhotoActionFail
  | IAddTrailerInspectionPhotoCommentAction
  | IAddTrailerInspectionPhotoCommentActionSuccess
  | IAddTrailerInspectionPhotoCommentActionFail
  | IUpdateTrailerInspectionPhotoCommentAction
  | IUpdateTrailerInspectionPhotoCommentActionSuccess
  | IUpdateTrailerInspectionPhotoCommentActionFail
  | IDeleteTrailerInspectionPhotoCommentAction
  | IDeleteTrailerInspectionPhotoCommentActionSuccess
  | IDeleteTrailerInspectionPhotoCommentActionFail
  | IGetTrailerInspectionAction
  | IGetTrailerInspectionActionSuccess
  | IGetTrailerInspectionActionFail
  | IDeleteTrailerInspectionAction
  | IDeleteTrailerInspectionActionSuccess
  | IDeleteTrailerInspectionActionFail
  | IUnmountTrailerInspectionFormAction
  | IClearCurrentTrailerInspectionAction
  | IGetTrailerInspectionDraftAction
  | IGetTrailerInspectionDraftActionSuccess
  | IGetTrailerInspectionDraftActionFail
  | IDraftTrailerInspectionAction
  | IDraftTrailerInspectionActionSuccess
  | IDraftTrailerInspectionActionFail
  | IInitializeDraftTrailerInspectionAction
  | IInitialiseDraftTrailerInspectionActionSuccess
  | IInitialiseDraftTrailerInspectionActionFail
  | IClearInspectionErrorAction
  | ILogout;
