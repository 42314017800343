import {
  IBrokerRate,
  IBroker,
} from 'components/Dispatch/TabBrokers/_models/brokerModel';
import {
  GET_BROKER,
  GET_BROKERS,
  SEARCH_BROKERS,
  CREATE_BROKER,
  UPDATE_BROKER,
  DELETE_BROKER,
  UPLOAD_BROKER_FILES,
  DELETE_BROKER_FILE,
  UNMOUNT_BROKERS_FORM,
  SET_BROKER_RATE,
  GET_BROKER_RATES_LIST,
  IDeleteBrokerFileAction,
  IUploadBrokerFilesAction,
  IGetBrokerRatesListAction,
  ISetBrokerRateCommentVisibility,
  SET_BROKER_COMMENT_VISIBILITY,
} from 'components/Dispatch/TabBrokers/_redux/brokersTypes';
import { IDeleteFileAction } from 'redux/_common/commonTypes';
import { ILocalFile } from 'redux/_common/models';

export const getBrokersAction = (payload?: {
  query?: string;
  callback?: () => void;
  config?: { useSeparateHeapFlag?: boolean; usePaginationFlag?: boolean };
}) => ({
  type: GET_BROKERS,
  payload,
});

export const getBrokerAction = (id: string | number) => ({
  type: GET_BROKER,
  id,
});

export const setBrokerRateCommentVisibilityAction = (
  payload: ISetBrokerRateCommentVisibility['payload'],
): ISetBrokerRateCommentVisibility => ({
  type: SET_BROKER_COMMENT_VISIBILITY,
  payload,
});

export const getBrokerRatesListAction = (
  payload: IGetBrokerRatesListAction['payload'],
) => ({
  type: GET_BROKER_RATES_LIST,
  payload,
});

export const searchBrokersAction = () => ({
  type: SEARCH_BROKERS,
});

export const createBrokerAction = (
  values: Partial<IBroker>,
  callback?: (broker: IBroker) => void,
) => ({
  type: CREATE_BROKER,
  payload: { values, callback },
});

export const updateBrokerAction = (
  values: Partial<IBroker>,
  callback: () => void,
) => ({
  type: UPDATE_BROKER,
  payload: { values, callback },
});

export const deleteBrokerAction = (id: number, callback?: () => void) => ({
  type: DELETE_BROKER,
  payload: { id, callback },
});

export const uploadBrokerFilesAction = ({
  files,
  itemId,
}: {
  files: ILocalFile[];
  itemId?: number;
}): IUploadBrokerFilesAction => ({
  type: UPLOAD_BROKER_FILES,
  payload: { files, itemId },
});

export const deleteBrokerFileAction = ({
  fileId,
  itemId,
}: IDeleteFileAction['payload']): IDeleteBrokerFileAction => ({
  type: DELETE_BROKER_FILE,
  payload: { fileId, itemId },
});

export const unmountBrokerFormAction = () => ({
  type: UNMOUNT_BROKERS_FORM,
});

export const setBrokerRateAction = (payload: {
  rate_data: Partial<IBrokerRate>;
  orderId: number;
  brokerId: number;
}) => ({
  type: SET_BROKER_RATE,
  payload,
});
