import { AxiosError } from 'axios';

import { ISearchResult } from '../models';

export const SET_SEARCH_STRING = '[@@Search] SET_SEARCH_STRING';
export const CLEAR_SEARCH = '[@@Search] CLEAR_SEARCH';

export const SET_SEARCH_RESULT = '[@@Search] SET_SEARCH_RESULT';
export const SET_SEARCH_RESULT_SUCCESS = '[@@Search] SET_SEARCH_RESULT_SUCCESS';
export const SET_SEARCH_RESULT_FAIL = '[@@Search] SET_SEARCH_RESULT_FAIL';

export interface ISetSearchStringAction {
  type: typeof SET_SEARCH_STRING;
  payload: string;
}

export interface IClearSearchAction {
  type: typeof CLEAR_SEARCH;
  payload: string;
}

export interface ISetSearchResultAction {
  type: typeof SET_SEARCH_RESULT;
  payload: ISearchResult[];
}

export interface ISetSearchResultSuccessAction {
  type: typeof SET_SEARCH_RESULT_SUCCESS;
  payload: ISearchResult[];
}

export interface ISetSearchResultFailAction {
  type: typeof SET_SEARCH_RESULT_FAIL;
  payload: AxiosError;
}

export type initSearchActionsType =
  | IClearSearchAction
  | ISetSearchResultFailAction
  | ISetSearchResultSuccessAction
  | ISetSearchStringAction
  | ISetSearchResultAction;
