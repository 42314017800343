import { FC, useEffect, useMemo } from 'react';

import { LocalizationProvider, DesktopDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { SxProps, TextField } from '@mui/material';
import clsx from 'clsx';
import moment from 'moment';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import { IconCalendar } from 'assets/icons/components';
import useTestAttrRef from 'hooks/useTestAttrRef';

import styles from './CustomDatePicker.module.scss';

type OwnProps = {
  className?: string;
  id?: string;
  yearOnly?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  maxDate?: Date;
  minDate?: Date;
  focusOnRender?: boolean;
  disabled?: boolean;
  smallYears?: boolean;
};

const popperSx: SxProps = {
  '& .MuiCalendarPicker-root': {
    backgroundColor: 'var(--primaryInputBg)',
  },
  '& .PrivatePickersFadeTransitionGroup-root, & .MuiTypography-caption': {
    color: 'var(--primaryText)',
  },
  '& .MuiPickersDay-dayWithMargin:not(.Mui-selected):hover': {
    backgroundColor: 'var(--dropdownBg)',
    color: 'var(--primaryText)',
  },
};

type Props = OwnProps & WrappedFieldInputProps & WrappedFieldMetaProps;

const CustomDatePicker: FC<Partial<Props>> = ({
  className,
  yearOnly,
  placeholder = yearOnly ? 'YYYY' : 'MM/DD/YYYY',
  readOnly,
  maxDate,
  minDate: initMinDate,
  value,
  name,
  onChange,
  onBlur,
  invalid,
  touched,
  focusOnRender,
  disabled,
  smallYears,
}) => {
  const inputRef = useTestAttrRef<HTMLInputElement>(null);

  const minDate = useMemo(() => {
    if (initMinDate && moment(initMinDate).isValid()) {
      return moment(initMinDate).toDate();
    }
    return undefined;
  }, [initMinDate]);

  const dateValue =
    (yearOnly && typeof value === 'number' && value && new Date(value, 1, 1)) ||
    (value ? new Date(value) : null);

  useEffect(() => {
    if (inputRef?.current) {
      const parentName = inputRef.current.getAttribute('data-test');
      const calendarButton =
        inputRef.current.parentElement?.querySelector('button');
      calendarButton?.setAttribute('data-test', `${parentName}_button`);
    }
  }, [inputRef?.current]);

  const handleDateChange = (date: Date | null) => {
    if (onChange) onChange(date);
  };

  const handleDateBlur = () => {
    if (onBlur) onBlur(dateValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        inputRef={inputRef}
        value={dateValue}
        inputFormat={yearOnly ? 'yyyy' : smallYears ? 'MM/dd/yy' : 'MM/dd/yyyy'}
        views={yearOnly ? ['year'] : ['year', 'month', 'day']}
        maxDate={maxDate}
        minDate={minDate}
        components={{ OpenPickerIcon: IconCalendar }}
        OpenPickerButtonProps={{
          tabIndex: -1,
        }}
        onChange={handleDateChange}
        readOnly={readOnly}
        disabled={disabled}
        className={styles.datePicker}
        PopperProps={{
          sx: popperSx,
        }}
        InputProps={{
          className: clsx(
            styles.root,
            { [styles.error]: invalid && touched },
            className,
          ),
          autoFocus: focusOnRender,
          readOnly,
          name,
          disabled,
          onBlur: handleDateBlur,
          placeholder,
        }}
        renderInput={inputProps => <TextField {...inputProps} fullWidth />}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
