/* eslint-disable @typescript-eslint/no-explicit-any */
// UPLOAD SCRIPT FILES
import {
  IDeleteFileActionFail,
  IDeleteFileActionSuccess,
} from '../../../../../redux/_common/commonTypes';

export const UPLOAD_SCRIPT_FILES = '[@@Script] UPLOAD_SCRIPT_FILES';
export const UPLOAD_SCRIPT_FILES_SUCCESS =
  '[@@Script] UPLOAD_SCRIPT_FILES_SUCCESS';
export const UPLOAD_SCRIPT_FILES_FAIL = '[@@Script] UPLOAD_SCRIPT_FILES_FAIL';

export const UPLOAD_EXISTING_SCRIPT_FILES_SUCCESS =
  '[@@Script] UPLOAD_SCRIPT_FILES_SUCCESS';

export interface IUploadScriptFilesAction {
  type: typeof UPLOAD_SCRIPT_FILES;
  payload: any;
}

export interface IUploadScriptFilesActionSuccess {
  type: typeof UPLOAD_SCRIPT_FILES_SUCCESS;
  payload: { file: any; segmentId: number };
}

export interface IUploadScriptFilesActionFail {
  type: typeof UPLOAD_SCRIPT_FILES_FAIL;
}

export interface IUploadExistingScriptFilesActionSuccess {
  type: typeof UPLOAD_EXISTING_SCRIPT_FILES_SUCCESS;
  payload: { file: any; segmentId: number };
}
// DELETE SCRIPT FILES
export const DELETE_SCRIPT_FILE = '[@@Script] DELETE_SCRIPT_FILE';
export const DELETE_SCRIPT_FILE_SUCCESS =
  '[@@Script] DELETE_SCRIPT_FILE_SUCCESS';
export const DELETE_SCRIPT_FILE_FAIL = '[@@Script] DELETE_SCRIPT_FILE_FAIL';

export interface IDeleteScriptFileAction {
  type: typeof DELETE_SCRIPT_FILE;
  payload: { fileId: number };
}
export interface IDeleteScriptFileActionSuccess
  extends IDeleteFileActionSuccess {
  type: typeof DELETE_SCRIPT_FILE_SUCCESS;
}
export interface IDeleteScriptFileActionFail extends IDeleteFileActionFail {
  type: typeof DELETE_SCRIPT_FILE_FAIL;
}

export const CLEAR_SCRIPT_STATE = '[@@Script] CLEAR_SCRIPT_STATE';

export interface IClearStateAction {
  type: typeof CLEAR_SCRIPT_STATE;
}

export type scriptActionsType =
  | IUploadScriptFilesAction
  | IUploadScriptFilesActionSuccess
  | IUploadScriptFilesActionFail
  | IDeleteScriptFileAction
  | IDeleteScriptFileActionSuccess
  | IDeleteScriptFileActionFail
  | IClearStateAction;
