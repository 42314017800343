import { DRIVERS_API_PREFIX } from 'constants/api';

export const getFileDownloadPath = (itemId: number, fileId: string) => {
  return `${DRIVERS_API_PREFIX}/${itemId}/files/${fileId}`;
};

export const driverFileToFormData = ({
  file,
  fileCategory,
}: {
  file: File;
  fileCategory: number;
}) => {
  if (!file) return null;

  const formData = new FormData();

  formData.append(`fleets_drivers_file[file]`, file);
  formData.append(`fleets_drivers_file[category_id]`, `${fileCategory}`);

  return formData;
};

export const driverFileCategoryToFormData = (fileCategory: number) => {
  const formData = new FormData();

  formData.append(`fleets_drivers_file[category_id]`, `${fileCategory}`);

  return formData;
};
