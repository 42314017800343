import { useEffect, useState } from 'react';

import { isEmpty } from 'ramda';
import { useSelector } from 'react-redux';

import { authSelector } from 'components/Auth/_redux/authSelectors';

/**
 * Checks user permissions by comparing string or array of string with existing
 * permissions in the profile state.
 * @param atLeastOneMatchFlag uses to check if at least one item in the array
 * permissions are available for edit and view or vice versa.
 * @returns hasViewPermission: boolean, hasEditPermission: boolean
 */
export default function useCheckUserPermission(
  checkPermission: string | string[],
  atLeastOneMatchFlag = true,
) {
  const { permissions } = useSelector(authSelector);
  const [hasViewPermission, setHasViewPermission] = useState(false);
  const [hasEditPermission, setHasEditPermission] = useState(false);

  useEffect(() => {
    let readPermissions = 0;
    let editPermissions = 0;

    const checkPermissions =
      typeof checkPermission === 'string' ? [checkPermission] : checkPermission;

    if (!isEmpty(permissions))
      permissions.forEach(({ permit, resource_name }) => {
        if (checkPermissions.includes(resource_name)) {
          if (permit.startsWith('read_')) readPermissions += 1;
          if (permit.startsWith('manage_')) editPermissions += 1;
        }
      });

    const viewPermission = atLeastOneMatchFlag
      ? readPermissions > 0
      : readPermissions >= checkPermissions.length;
    if (viewPermission !== hasViewPermission)
      setHasViewPermission(viewPermission);

    const editPermission = atLeastOneMatchFlag
      ? editPermissions > 0
      : editPermissions >= checkPermissions.length;
    if (editPermission !== hasEditPermission)
      setHasEditPermission(editPermission);
  }, [permissions, checkPermission, atLeastOneMatchFlag]);

  return { hasViewPermission, hasEditPermission };
}
