import { takeLatest, call, put } from 'redux-saga/effects';

import { uploadCroppedImgAction } from 'redux/_common/commonActions';
import {
  UPLOAD_CROPPED_IMAGE,
  UPLOAD_CROPPED_IMAGE_SUCCESS,
  UPLOAD_CROPPED_IMAGE_FAIL,
} from 'redux/_common/commonTypes';
import { fileToFormData } from 'utils/converters';
import api from 'utils/requests';

function* uploadCroppedImgSage({
  payload,
}: ReturnType<typeof uploadCroppedImgAction>) {
  try {
    const { data } = yield call(() =>
      api.patch(
        '/v1/employee/photo',
        fileToFormData(payload, 'employees_photo[file]'),
      ),
    );
    yield put({ type: UPLOAD_CROPPED_IMAGE_SUCCESS, payload: data.photo.urls });
  } catch ({ response: { data } }) {
    yield put({ type: UPLOAD_CROPPED_IMAGE_FAIL });
  }
}

export default function* commonSaga() {
  yield takeLatest(UPLOAD_CROPPED_IMAGE, uploadCroppedImgSage);
}
