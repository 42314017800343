import { memo } from 'react';

type OwnProps = {
  className?: string;
  fillColor?: string;
};

const IconRoles = ({
  className,
  fillColor = 'var(--sideBarIcon)',
}: OwnProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_107_15881)">
        <circle cx="5" cy="11" r="4" stroke={fillColor} strokeWidth="2" />
        <path
          d="M8 8L15 1"
          stroke={fillColor}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M13 7L15 5"
          stroke={fillColor}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_107_15881">
          <rect width="16" height="16" stroke={fillColor} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(IconRoles);
