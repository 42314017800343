type Props = {
  className?: string;
  fillColor?: string;
};

const IconCheck = ({ className, fillColor = '#2C6AF6' }: Props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.70711 8.29289C3.31658 7.90237 2.68342 7.90237 2.29289 8.29289C1.90237 8.68342 1.90237 9.31658 2.29289 9.70711L3.70711 8.29289ZM6 12L5.29289 12.7071C5.68342 13.0976 6.31658 13.0976 6.70711 12.7071L6 12ZM13.7071 5.70711C14.0976 5.31658 14.0976 4.68342 13.7071 4.29289C13.3166 3.90237 12.6834 3.90237 12.2929 4.29289L13.7071 5.70711ZM2.29289 9.70711L5.29289 12.7071L6.70711 11.2929L3.70711 8.29289L2.29289 9.70711ZM6.70711 12.7071L13.7071 5.70711L12.2929 4.29289L5.29289 11.2929L6.70711 12.7071Z"
      fill={fillColor}
    />
  </svg>
);

export default IconCheck;
