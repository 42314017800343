import { AppState } from 'redux/store';

import { IDefaultSettingsNumberKey, IUserSettingsReducer } from './models';

export const userSettingsSelector = (state: AppState): IUserSettingsReducer =>
  state.settings;

export const userLanguageSelector = (state: AppState): string =>
  state.settings.language;

export const userInternalCompaniesSelector = (
  state: AppState,
): IDefaultSettingsNumberKey | null => state.settings.internal_companies;

export const userThemeSelector = (state: AppState) => state.settings.theme;
export const userIsDesktopSelector = (state: AppState) =>
  state.settings.isDesktop;
