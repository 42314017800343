import { Store, Dispatch } from 'redux';

import { GET_CHATROOMS_SUCCESS } from 'components/Chat/_redux/chatTypes';
import { AppState } from 'redux/store';
import { CHAT_CHANNEL_ID, NOTIFICATIONS_CHANNEL_ID } from 'socket/channels';
import Socket from 'socket/Socket';
import api from 'utils/requests';

import {
  appendChannelMessagesAction,
  onConnectionChangedAction,
  onChannelSubscribedAction,
  onChannelMessageReceivedAction,
  onIncomingErrorAction,
} from '../socket/socketActions';
import {
  SocketMessage,
  SUBSCRIBE_CHANNEL,
  UNSUBSCRIBE_CHANNEL,
  ISubscribeChannelAction,
  IUnsubscribeChannelAction,
} from '../socket/socketTypes';

const socketMiddleware = (store: Store<AppState>) => {
  const onConnectionChanged = (isConnected: boolean) => {
    store.dispatch(onConnectionChangedAction(isConnected));
  };

  const onIncomingMessage = (message: SocketMessage) => {
    // send message related to channels
    const { identifier, type, message: channelMessage } = message;
    if (identifier) {
      if (type === 'confirm_subscription')
        store.dispatch(onChannelSubscribedAction(identifier));
      else if (channelMessage?.data)
        store.dispatch(
          onChannelMessageReceivedAction(
            identifier,
            channelMessage.data,
            channelMessage.type,
          ),
        );
    }
  };

  const onIncomingError = (event: Event) => {
    store.dispatch(onIncomingErrorAction(event));
  };

  const webSocket = new Socket(
    onConnectionChanged,
    onIncomingMessage,
    onIncomingError,
  );

  return (next: Dispatch) =>
    (action: ISubscribeChannelAction | IUnsubscribeChannelAction) => {
      const { profile, socket } = store.getState();

      switch (action.type) {
        case SUBSCRIBE_CHANNEL: {
          const { channelIdentifier: identifier } = action;

          const connectToSocket = () =>
            webSocket.connect(profile?.token, () => {
              webSocket.sendMessage({
                command: 'subscribe',
                identifier,
              });
            });

          if (identifier === CHAT_CHANNEL_ID) {
            // get chat messages history via RestAPI. Yes, it's a strange solution...
            api
              .get('/v1/chatrooms?page=1&per_page=20')
              .then(res =>
                store.dispatch({
                  type: GET_CHATROOMS_SUCCESS,
                  payload: res.data,
                }),
              )
              .then(() => connectToSocket())
              .catch(err => console.error(err));
          } else if (identifier === NOTIFICATIONS_CHANNEL_ID) {
            // get notifications and tasks histories via RestAPI. Yes, it's a strange solution...
            api
              .get('/v1/notifications?page=1&per_page=100')
              .then(res =>
                store.dispatch(
                  appendChannelMessagesAction(
                    identifier,
                    res.data.notifications,
                    'employee_notification',
                  ),
                ),
              )
              .then(() =>
                api.get('/v1/tasks?page=1&per_page=100&filter[is_done]=false'),
              )
              .then(res =>
                store.dispatch(
                  appendChannelMessagesAction(
                    identifier,
                    res.data.tasks,
                    'task',
                  ),
                ),
              )
              .then(() => connectToSocket())
              .catch(err => console.error(err));
          } else connectToSocket();

          break;
        }

        case UNSUBSCRIBE_CHANNEL: {
          webSocket.sendMessage({
            command: 'unsubscribe',
            identifier: action.channelIdentifier,
          });

          const channelsCount = Object.keys(socket.channels).length;
          if (
            channelsCount === 0 ||
            (channelsCount === 1 && socket.channels[action.channelIdentifier])
          )
            webSocket.disconnect();

          break;
        }

        default:
          break;
      }

      return next(action);
    };
};

export default socketMiddleware;
