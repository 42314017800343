import { cond, equals, merge, T, concat } from 'ramda';
import { combineReducers } from 'redux';

import { IPagination } from 'components/_common/Navigation/Pagination/_redux/paginationTypes';
import { dropRepeatsByKey } from 'utils/helpers';
import { IWithError, IWithLoaded, IWithPending } from 'utils/models';

import { ITruckMaintenance } from '../../AllTrucksMaintenance/_models/truckMaintenanceModels';
import {
  ITruckReview,
  ITruckReviewHeading,
} from '../../AllTrucksMaintenance/_models/truckReviewModel';
import {
  ITruckTrackingData,
  ITruckTrafficHistory,
} from '../_models/truckMapModel';
import {
  ITruck,
  ITruckLog,
  ITruckServiceUploadedFile,
  ITruckUploadedFile,
} from '../_models/truckModel';
import {
  CLEAR_TRUCK_MARKERS,
  CLEAR_TRUCK_TOLL_LOCATION,
  CLEAR_TRUCK_TRAFFIC_HISTORY,
  CREATE_TRUCK,
  CREATE_TRUCK_FAIL,
  CREATE_TRUCK_PLANNING_REVIEW,
  CREATE_TRUCK_PLANNING_REVIEW_FAIL,
  CREATE_TRUCK_PLANNING_REVIEW_SUCCESS,
  CREATE_TRUCK_SUCCESS,
  DELETE_CLAIM_PHOTO_FAIL,
  DELETE_CLAIM_PHOTO_SUCCESS,
  DELETE_LOCAL_CLAIM_PHOTO,
  DELETE_TRUCK,
  DELETE_TRUCK_FAIL,
  DELETE_TRUCK_FILE,
  DELETE_TRUCK_FILE_FAIL,
  DELETE_TRUCK_FILE_SUCCESS,
  DELETE_TRUCK_SERVICE_FILE,
  DELETE_TRUCK_SERVICE_FILE_FAIL,
  DELETE_TRUCK_SERVICE_FILE_SUCCESS,
  DELETE_TRUCK_SUCCESS,
  GET_ENGINES,
  GET_ENGINES_FAIL,
  GET_ENGINES_SUCCESS,
  GET_TRACKING_TRUCKS,
  GET_TRACKING_TRUCKS_FAIL,
  GET_TRACKING_TRUCKS_SUCCESS,
  GET_TRUCK,
  GET_TRUCK_FAIL,
  GET_TRUCK_LOCATION,
  GET_TRUCK_LOCATION_FAIL,
  GET_TRUCK_LOCATION_SUCCESS,
  GET_TRUCK_LOGS,
  GET_TRUCK_LOGS_FAIL,
  GET_TRUCK_LOGS_SUCCESS,
  GET_TRUCK_MAINTENANCE_LIST,
  GET_TRUCK_MAINTENANCE_LIST_FAIL,
  GET_TRUCK_MAINTENANCE_LIST_SUCCESS,
  GET_TRUCK_PLANNING_REVIEW_SERVICE,
  GET_TRUCK_PLANNING_REVIEW_SERVICE_FAIL,
  GET_TRUCK_PLANNING_REVIEW_SERVICE_SUCCESS,
  GET_TRUCK_PLANNING_REVIEW_SERVICES,
  GET_TRUCK_PLANNING_REVIEW_SERVICES_FAIL,
  GET_TRUCK_PLANNING_REVIEW_SERVICES_SUCCESS,
  GET_TRUCK_SUCCESS,
  GET_TRUCK_TRAFFIC_HISTORY,
  GET_TRUCK_TRAFFIC_HISTORY_BY_DATE,
  GET_TRUCK_TRAFFIC_HISTORY_BY_DATE_FAIL,
  GET_TRUCK_TRAFFIC_HISTORY_BY_DATE_SUCCESS,
  GET_TRUCK_TRAFFIC_HISTORY_FAIL,
  GET_TRUCK_TRAFFIC_HISTORY_SUCCESS,
  GET_TRUCKS,
  GET_TRUCKS_FAIL,
  GET_TRUCKS_SUCCESS,
  SET_PIN_TRUCK_MAP_VIEW,
  SET_PIN_TRUCK_MAP_VIEW_FAIL,
  SET_PIN_TRUCK_MAP_VIEW_SUCCESS,
  SET_TRUCK_REVIEW_COMMENT_VISIBILITY,
  SET_UNPIN_TRUCK_MAP_VIEW,
  SET_UNPIN_TRUCK_MAP_VIEW_FAIL,
  SET_UNPIN_TRUCK_MAP_VIEW_SUCCESS,
  trucksEnginesTypes,
  trucksTypes,
  UPDATE_TRUCK,
  UPDATE_TRUCK_FAIL,
  UPDATE_TRUCK_REVIEW,
  UPDATE_TRUCK_REVIEW_FAIL,
  UPDATE_TRUCK_REVIEW_SUCCESS,
  UPDATE_TRUCK_SUCCESS,
  UPLOAD_TRUCK_CLAIM_PHOTO,
  UPLOAD_TRUCK_CLAIM_PHOTO_FAIL,
  UPLOAD_TRUCK_CLAIM_PHOTO_SUCCESS,
  UPLOAD_TRUCK_FILES,
  UPLOAD_TRUCK_FILES_FAIL,
  UPLOAD_TRUCK_FILES_SUCCESS,
  UPLOAD_TRUCK_SERVICE_FILE,
  UPLOAD_TRUCK_SERVICE_FILE_FAIL,
  UPLOAD_TRUCK_SERVICE_FILE_SUCCESS,
  CLEAR_TRUCK,
  CLEAR_TRUCK_ERRORS,
} from './trucksTypes';

export interface ITrucksEnginesState
  extends IWithPending,
    IWithLoaded,
    IWithError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entities: Array<any>;
}

export interface ITrucksState extends IWithPending, IWithLoaded, IWithError {
  pagination: IPagination | null;
  list: ITruck[];
  files: ITruckUploadedFile[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  claimPhotos: any;
  currentTruck: ITruck | null;
  statistic: { [key: string]: number } | null;
  trackingData: ITruckTrackingData[];
  logs: ITruckLog[] | null;
  truckMaintenance: ITruckMaintenance[];
  currentTruckService: ITruckReview | null;
  truckServiceFiles: ITruckServiceUploadedFile[];
  truckReviews: {
    reviewsList: ITruckReview[];
    reviewsHeading: Partial<ITruckReviewHeading>;
    lastComment: string | null;
  };
  trafficHistory: ITruckTrafficHistory;
  _reviewsPending: boolean;
  currentTruckLocation: ITruck | null;
}

const initialTrucksState: ITrucksState = {
  pagination: null,
  list: [],
  files: [],
  claimPhotos: [],
  currentTruck: null,
  statistic: null,
  trackingData: [],
  truckMaintenance: [],
  currentTruckService: null,
  truckServiceFiles: [],
  truckReviews: {
    reviewsList: [],
    reviewsHeading: {},
    lastComment: null,
  },
  trafficHistory: { route: [], positions: [], distance: 0 },
  logs: null,
  _pending: false,
  _loaded: false,
  _error: null,
  _reviewsPending: false,
  currentTruckLocation: null,
};

const initialTrucksEnginesState: ITrucksEnginesState = {
  _pending: false,
  _loaded: false,
  _error: null,
  entities: [],
};

const trucksEngineReducers = (
  state = initialTrucksEnginesState,
  action: trucksEnginesTypes,
): ITrucksEnginesState => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return cond<any, any>([
    [
      equals(GET_ENGINES),
      () =>
        merge(state, {
          _loaded: false,
          _pending: true,
          _error: null,
        }),
    ],
    [
      equals(GET_ENGINES_SUCCESS),
      () =>
        merge(state, {
          _loaded: true,
          _pending: false,
          entities: action.payload,
        }),
    ],
    [
      equals(GET_ENGINES_FAIL),
      () =>
        merge(state, {
          _loaded: false,
          _pending: false,
          entities: action.payload,
        }),
    ],
    [
      T,
      () => {
        return state;
      },
    ],
  ])(action.type);
};

const trucksReducers = (
  state = initialTrucksState,
  action: trucksTypes,
): ITrucksState => {
  switch (action.type) {
    // GET TRUCKS
    case GET_TRUCKS:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case GET_TRUCKS_SUCCESS:
      return {
        ...state,
        list: action.payload.trucks,
        pagination: action.payload.preventUpdatePagination
          ? state.pagination
          : action.payload.pagination,
        statistic: action.payload.statistic,
        files: [],
        claimPhotos: [],
        truckServiceFiles: [],
        _loaded: true,
        _pending: false,
      };
    case GET_TRUCKS_FAIL:
      return {
        ...state,
        _error: action.payload,
        _loaded: false,
        _pending: false,
      };

    case CLEAR_TRUCK:
      return {
        ...state,
        files: [],
        claimPhotos: [],
        currentTruck: null,
      };

    // GET TRUCK BY ID
    case GET_TRUCK:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case GET_TRUCK_SUCCESS:
      return {
        ...state,
        currentTruck: action.payload.truck,
        claimPhotos: [],
        files: action.payload.truck.files,
        _loaded: true,
        _pending: false,
      };
    case GET_TRUCK_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };

    // UPLOAD CLAIM PHOTO
    case UPLOAD_TRUCK_CLAIM_PHOTO:
      return {
        ...state,
        claimPhotos: [
          ...state.claimPhotos,
          ...action.payload.files.map(item => ({
            ...item,
            loading: true,
          })),
        ],
      };
    case UPLOAD_TRUCK_CLAIM_PHOTO_SUCCESS:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        claimPhotos: state.claimPhotos.map((file: any, index: number) =>
          index ===
          state.claimPhotos.findIndex(
            ({ loading }: { loading: boolean }) => loading,
          )
            ? { ...action.payload.file, loading: false }
            : file,
        ),
      };
    case UPLOAD_TRUCK_CLAIM_PHOTO_FAIL:
      return {
        ...state,
        _error: action.payload,
      };
    // Delete claim photo
    case DELETE_CLAIM_PHOTO_SUCCESS: {
      let photos = state.claimPhotos ? { ...state.claimPhotos } : null;
      if (
        photos &&
        action.payload.itemId &&
        action.payload.itemId === photos.id
      ) {
        photos = photos.filter(
          (photo: { id: string | number }) =>
            photo.id !== action.payload.fileId,
        );
      }
      return {
        ...state,
        claimPhotos: state.claimPhotos.filter(
          (file: { id: string | number }) => file.id !== action.payload.fileId,
        ),
      };
    }
    case DELETE_CLAIM_PHOTO_FAIL:
      return {
        ...state,
        _error: action.payload,
      };
    // DELETE LOCAL CLAIM PHOTO
    case DELETE_LOCAL_CLAIM_PHOTO:
      return {
        ...state,
        claimPhotos: [],
      };

    // GET TRUCK MAINTENANCE LIST
    case GET_TRUCK_MAINTENANCE_LIST:
      return {
        ...state,
        truckMaintenance: [],
        _error: null,
        _reviewsPending: true,
      };
    case GET_TRUCK_MAINTENANCE_LIST_SUCCESS:
      return {
        ...state,
        truckMaintenance: action.payload.data,
        pagination: action.payload.pagination,
        _reviewsPending: false,
      };
    case GET_TRUCK_MAINTENANCE_LIST_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // GET TRUCK REVIEW SERVICES LIST
    case GET_TRUCK_PLANNING_REVIEW_SERVICES:
      return {
        ...state,
        truckReviews: { ...initialTrucksState.truckReviews },
        _error: null,
      };
    case GET_TRUCK_PLANNING_REVIEW_SERVICES_SUCCESS:
      return {
        ...state,
        truckServiceFiles: [],
        truckReviews: {
          reviewsList: action.payload.data.map(review => {
            return {
              ...review,
              commentVisibility: false,
            };
          }),
          reviewsHeading: action.payload.planning_review,
          lastComment: action.payload.last_comment,
        },
        pagination: action.payload.pagination,
        _loaded: true,
        _pending: false,
      };
    case GET_TRUCK_PLANNING_REVIEW_SERVICES_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // CREATE TRUCK
    case CREATE_TRUCK:
      return { ...state };
    case CREATE_TRUCK_SUCCESS:
      return { ...state, list: [], currentTruck: null, files: [] };
    case CREATE_TRUCK_FAIL:
      return { ...state, _error: action.payload };

    // CREATE TRUCK PLANNING REVIEW
    case CREATE_TRUCK_PLANNING_REVIEW:
      return { ...state };
    case CREATE_TRUCK_PLANNING_REVIEW_SUCCESS:
      return { ...state, list: [] };
    case CREATE_TRUCK_PLANNING_REVIEW_FAIL:
      return { ...state, _error: action.payload };

    // UPDATE TRUCK REVIEW STATUS
    case UPDATE_TRUCK_REVIEW:
      return { ...state };
    case UPDATE_TRUCK_REVIEW_SUCCESS:
      return {
        ...state,
        truckReviews: {
          ...state.truckReviews,
          reviewsList: state.truckReviews.reviewsList.map(review =>
            review.id === action.payload.data.id
              ? { ...review, status: action.payload.data.status }
              : review,
          ),
        },
      };
    case UPDATE_TRUCK_REVIEW_FAIL:
      return { ...state, _error: action.payload };

    // UPDATE TRUCK BY ID
    case UPDATE_TRUCK:
      return { ...state, _error: null };
    case UPDATE_TRUCK_SUCCESS:
      return {
        ...state,
        currentTruck: action.payload.truck,
        _pending: false,
        _loaded: true,
      };
    case UPDATE_TRUCK_FAIL:
      return {
        ...state,
        _error: action.payload,
        _pending: false,
        _loaded: false,
      };

    // DELETE TRUCK BY ID
    case DELETE_TRUCK:
      return { ...state };
    case DELETE_TRUCK_SUCCESS:
      return { ...state, currentTruck: null };
    case DELETE_TRUCK_FAIL:
      return { ...state, _error: action.payload };

    // UPLOAD TRUCK FILES
    case UPLOAD_TRUCK_FILES:
      return {
        ...state,
        files: [
          ...state.files,
          ...action.payload.files.map(
            file =>
              ({
                ...file,
                loading: true,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } as any),
          ),
        ],
      };
    case UPLOAD_TRUCK_FILES_SUCCESS:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        files: state.files.map((file: any, index: number) =>
          index === state.files.findIndex(({ loading }) => loading)
            ? { ...action.payload, loading: false }
            : file,
        ),
      };
    case UPLOAD_TRUCK_FILES_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // DELETE TRUCK FILE
    case DELETE_TRUCK_FILE:
      return state;
    case DELETE_TRUCK_FILE_SUCCESS:
      return {
        ...state,
        files: state.files.filter(file => file.id !== action.payload),
      };
    case DELETE_TRUCK_FILE_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // GET TRUCKS LOGS
    case GET_TRUCK_LOGS:
      return {
        ...state,
        _error: null,
        logs: null,
      };
    case GET_TRUCK_LOGS_SUCCESS:
      return {
        ...state,
        logs: action.payload.trucks,
        pagination: action.payload.pagination,
      };

    case GET_TRUCK_LOGS_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // GET TRACKING TRUCKS
    case GET_TRACKING_TRUCKS:
      return { ...state, _error: null };
    case GET_TRACKING_TRUCKS_SUCCESS: {
      const concatData = concat(state.trackingData, action.payload);
      // Drop repeats
      const trackingData = dropRepeatsByKey('vin', concatData);
      return { ...state, trackingData };
    }
    case GET_TRACKING_TRUCKS_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // CLEAR TRUCK MARKER
    case CLEAR_TRUCK_MARKERS: {
      return {
        ...state,
        trackingData: [],
      };
    }

    // SET PIN TRUCK
    case SET_PIN_TRUCK_MAP_VIEW:
      return { ...state, _error: null };
    case SET_PIN_TRUCK_MAP_VIEW_FAIL:
      return { ...state, _error: action.payload };
    case SET_PIN_TRUCK_MAP_VIEW_SUCCESS:
      return {
        ...state,
        list: state.list.map(truck => ({
          ...truck,
          pinned: truck.id === action.payload ? true : truck.pinned,
        })),
      };
    // SET UNPIN TRUCK
    case SET_UNPIN_TRUCK_MAP_VIEW:
      return { ...state, _error: null };
    case SET_UNPIN_TRUCK_MAP_VIEW_SUCCESS:
      return {
        ...state,
        list: state.list.map(truck => ({
          ...truck,
          pinned: truck.id === action.payload ? false : truck.pinned,
        })),
      };
    case SET_UNPIN_TRUCK_MAP_VIEW_FAIL:
      return { ...state, _error: action.payload };

    // GET_TRUCK_TRAFFIC_HISTORY
    case GET_TRUCK_TRAFFIC_HISTORY:
      return { ...state, _error: null };
    case GET_TRUCK_TRAFFIC_HISTORY_SUCCESS:
      return {
        ...state,
        trafficHistory: {
          ...action.payload,
          // cut markers for Google if get more then 25
          route: action.payload.route.filter(
            (item, index) => item && index < 25,
          ),
        },
      };
    case GET_TRUCK_TRAFFIC_HISTORY_FAIL:
      return { ...state, _error: action.payload };

    // CLEAR_TRUCK_TRAFFIC_HISTORY
    case CLEAR_TRUCK_TRAFFIC_HISTORY:
      return {
        ...state,
        trafficHistory: initialTrucksState.trafficHistory,
      };

    // CLEAR_TRUCK_TRAFFIC_HISTORY
    case CLEAR_TRUCK_TOLL_LOCATION:
      return {
        ...state,
        currentTruckLocation: initialTrucksState.currentTruckLocation,
      };

    // GET_TRUCK_TRAFFIC_HISTORY_BY_DATE
    case GET_TRUCK_TRAFFIC_HISTORY_BY_DATE:
      return { ...state, _error: null };
    case GET_TRUCK_TRAFFIC_HISTORY_BY_DATE_SUCCESS: // ToDo need to realize
      return state;
    case GET_TRUCK_TRAFFIC_HISTORY_BY_DATE_FAIL:
      return { ...state, _error: action.payload };

    // UPLOAD TRUCK SERVICE FILE
    case UPLOAD_TRUCK_SERVICE_FILE:
      return {
        ...state,
        truckServiceFiles: [
          ...state.truckServiceFiles,
          ...action.payload.files.map(
            file =>
              ({
                ...file,
                loading: true,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } as any),
          ),
        ],
      };
    case UPLOAD_TRUCK_SERVICE_FILE_SUCCESS:
      return {
        ...state,
        truckServiceFiles: state.truckServiceFiles.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (file: any, index: number) =>
            index ===
            state.truckServiceFiles.findIndex(({ loading }) => loading)
              ? { ...action.payload, loading: false }
              : file,
        ),
      };
    case UPLOAD_TRUCK_SERVICE_FILE_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // GET TRUCK REVIEW SERVICE

    case GET_TRUCK_PLANNING_REVIEW_SERVICE:
      return {
        ...state,
        currentTruckService: null,
        _pending: true,
        truckServiceFiles: [],
      };
    case GET_TRUCK_PLANNING_REVIEW_SERVICE_SUCCESS:
      return {
        ...state,
        currentTruckService: action.payload.data,
        truckServiceFiles: action.payload.data.files,
        _pending: false,
      };
    case GET_TRUCK_PLANNING_REVIEW_SERVICE_FAIL:
      return {
        ...state,
        _error: action.payload,
        _pending: false,
      };

    // DELETE TRUCK SERVICE FILE
    case DELETE_TRUCK_SERVICE_FILE:
      return state;
    case DELETE_TRUCK_SERVICE_FILE_SUCCESS:
      return {
        ...state,
        // TODO: improve types
        truckServiceFiles: state.truckServiceFiles.filter(
          file => file.id !== action.payload,
        ),
      };
    case DELETE_TRUCK_SERVICE_FILE_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    case SET_TRUCK_REVIEW_COMMENT_VISIBILITY:
      return {
        ...state,
        truckReviews: {
          ...state.truckReviews,
          reviewsList: state.truckReviews.reviewsList.map(review =>
            review.id === action.payload.data.id
              ? { ...review, commentVisibility: !review.commentVisibility }
              : review,
          ),
        },
      };

    case GET_TRUCK_LOCATION:
      return {
        ...state,
        currentTruckLocation: null,
      };

    case GET_TRUCK_LOCATION_SUCCESS:
      return {
        ...state,
        currentTruckLocation: action.payload.truck,
      };

    case GET_TRUCK_LOCATION_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    case CLEAR_TRUCK_ERRORS:
      return { ...state, _error: null };

    default:
      return state;
  }
};

export default combineReducers({
  self: trucksReducers,
  engines: trucksEngineReducers,
});
