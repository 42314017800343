import { isNil } from 'ramda';

import { ISearchResult } from 'components/_common/SearchPanel/models';
import { IBroker } from 'components/Dispatch/TabBrokers/_models/brokerModel';

import { ISettingsState } from '../../../../redux/userSettings/models';

// eslint-disable-next-line import/prefer-default-export
export const transformBrokersSearchResult = (
  payload: IBroker[],
  states: ISettingsState[],
): ISearchResult[] =>
  payload.map((item: IBroker) => ({
    id: item.id,
    title: item.company_name,
    additional:
      [item.address, item.city, states[item.state_id]?.iso_code, item.zip_code]
        .filter(v => !isNil(v))
        .join(', ') || '-',
    url: `/dispatch/brokers/${item.id}`,
  }));
