import { ChangeEvent, FC, KeyboardEvent, MouseEvent } from 'react';

import clsx from 'clsx';
import { WrappedFieldMetaProps, WrappedFieldInputProps } from 'redux-form';

import { IconClose } from 'assets/icons/components';
import {
  KEY_COMMA,
  KEY_DELETE,
  KEY_BACKSPACE,
  KEY_ENTER,
} from 'constants/keyCodes';
import useTestAttrRef from 'hooks/useTestAttrRef';
import useToggle from 'hooks/useToggle';
import { hasLettersInString } from 'utils/helpers';

import styles from './TagsInput.module.scss';

type OwnProps = {
  id: string;
  type: string;
  placeholder: string;
  className: string;
  readOnly: boolean;
  disabled: boolean;
  suggestedTags?: string[];
  suggestedTagsCnt?: number;
};
type Props = Partial<OwnProps & WrappedFieldInputProps & WrappedFieldMetaProps>;

const TagsInput: FC<Props> = ({
  id,
  placeholder,
  className,
  readOnly,
  disabled,
  error,
  value = { list: [], currentValue: '' },
  suggestedTags,
  suggestedTagsCnt = 0,
  name,
  onChange,
  onBlur,
}) => {
  const { list = [] as string[], currentValue = '' } = value;
  const filteredSuggestedTags = (suggestedTags || [])
    .filter((tag: string) => !list.includes(tag))
    .slice(0, suggestedTagsCnt);

  // Refs
  const containerRef = useTestAttrRef<HTMLDivElement>(null, name);
  // End

  // Hooks
  const {
    isOpen: isActiveInput,
    close: onBlurInput,
    open: onFocusInput,
  } = useToggle();
  // End

  // Handle key up
  const handleKeyUp = (e: KeyboardEvent) => {
    if (
      onChange &&
      currentValue === '' &&
      list.length > 0 &&
      (e.key === KEY_BACKSPACE || e.key === KEY_DELETE)
    ) {
      onChange({ ...value, list: list.slice(0, -1) });
    }

    if (
      (e.key === KEY_ENTER || e.key === KEY_COMMA) &&
      isActiveInput &&
      !error &&
      currentValue
    ) {
      if (onChange) {
        const newTag =
          e.key === KEY_COMMA ? currentValue.slice(0, -1) : currentValue;
        const hasSameTag = list.some((tag: string) => tag === newTag);

        onChange({
          currentValue: '',
          list: hasSameTag ? list : [...list, newTag],
        });
      }

      if (containerRef && containerRef.current) {
        containerRef.current.scrollTo({
          left: 10000,
          top: 0,
          behavior: 'smooth',
        });
      }
    }
  };

  // End

  // Handlers
  const addSuggestedTag = (e: MouseEvent) => {
    const tag = e.currentTarget.getAttribute('data-tag');
    if (tag && onChange) {
      onChange({ currentValue, list: [...list, tag] });
    }
  };

  const handleOnBlur = () => {
    onBlurInput();
    if (onBlur) onBlur(value);
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    if (
      onChange &&
      inputValue !== ',' &&
      (inputValue === '' || hasLettersInString(inputValue))
    ) {
      onChange({ ...value, currentValue: inputValue });
    }
  };

  const handleRemoveTag = (e: MouseEvent) => {
    const tagValue = e.currentTarget.getAttribute('data-tag') || '';
    if (onChange)
      onChange({
        ...value,
        list: list.filter((tag: string) => tag !== tagValue),
      });
  };

  const handleOneTagRemove = () => {
    if (onChange) {
      onChange('');
    }
  };
  // End
  return (
    <div
      ref={containerRef}
      className={clsx(styles.root, suggestedTagsCnt && styles.withSuggested)}
    >
      <div className={styles.wrap}>
        {list.length > 0 &&
          list.map((tag: string, index: number) => (
            <div
              key={index}
              className={styles.tagBlock}
              data-test={`tag_block_${index}`}
            >
              <span className={styles.tagText} data-test={`tag_${index}`}>
                {tag}
              </span>
              <span
                onClick={handleRemoveTag}
                data-tag={tag}
                data-test={`delete_tag_${index}`}
              >
                <IconClose />
              </span>
            </div>
          ))}
        {typeof value === 'string' && value !== '' && list.length === 0 && (
          <div className={styles.tagBlock} data-test="tag_block_">
            <span className={styles.tagText} data-test="tag">
              {value}
            </span>
            <span onClick={handleOneTagRemove}>
              <IconClose />
            </span>
          </div>
        )}
        <input
          id={id}
          value={currentValue}
          type="text"
          placeholder={placeholder}
          name={name}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onFocus={onFocusInput}
          onKeyUp={handleKeyUp}
          className={clsx(styles.input, className)}
          readOnly={readOnly}
          disabled={disabled}
          autoComplete="off"
          data-test={`${name}_input`}
        />
      </div>
      {!!filteredSuggestedTags.length && (
        <div className={styles.suggestedTags} data-test="suggested_tags">
          <span className={styles.suggestedTagsLabel}>Suggested tags:</span>
          {filteredSuggestedTags.map((tag: string, i) => (
            <span
              className={styles.suggestedTagsItem}
              key={i}
              data-tag={tag}
              onClick={addSuggestedTag}
            >
              {tag}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default TagsInput;
