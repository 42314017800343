import { AxiosError } from 'axios';

import { IDeleteFileAction } from 'redux/_common/commonTypes';
import { ILocalFile } from 'redux/_common/models';

import { ITruck } from '../_models/truckModel';
import {
  CLEAR_TRUCK_MARKERS,
  CLEAR_TRUCK_TOLL_LOCATION,
  CLEAR_TRUCK_TRAFFIC_HISTORY,
  CREATE_TRUCK,
  CREATE_TRUCK_FAIL,
  CREATE_TRUCK_PLANNING_REVIEW,
  CREATE_TRUCK_PLANNING_REVIEW_FAIL,
  CREATE_TRUCK_PLANNING_REVIEW_SUCCESS,
  CREATE_TRUCK_SUCCESS,
  DELETE_LOCAL_CLAIM_PHOTO,
  DELETE_TRUCK,
  DELETE_TRUCK_FAIL,
  DELETE_TRUCK_FILE,
  DELETE_TRUCK_SERVICE_FILE,
  DELETE_TRUCK_SUCCESS,
  GET_ENGINES,
  GET_ENGINES_FAIL,
  GET_ENGINES_SUCCESS,
  GET_TRACKING_TRUCKS,
  GET_TRUCK,
  GET_TRUCK_FAIL,
  GET_TRUCK_LOCATION,
  GET_TRUCK_LOGS,
  GET_TRUCK_MAINTENANCE_LIST,
  GET_TRUCK_MAINTENANCE_LIST_FAIL,
  GET_TRUCK_MAINTENANCE_LIST_SUCCESS,
  GET_TRUCK_PLANNING_REVIEW_SERVICE,
  GET_TRUCK_PLANNING_REVIEW_SERVICE_FAIL,
  GET_TRUCK_PLANNING_REVIEW_SERVICE_SUCCESS,
  GET_TRUCK_PLANNING_REVIEW_SERVICES,
  GET_TRUCK_PLANNING_REVIEW_SERVICES_FAIL,
  GET_TRUCK_PLANNING_REVIEW_SERVICES_SUCCESS,
  GET_TRUCK_SUCCESS,
  GET_TRUCK_TRAFFIC_HISTORY,
  GET_TRUCK_TRAFFIC_HISTORY_BY_DATE,
  GET_TRUCKS,
  GET_TRUCKS_FAIL,
  GET_TRUCKS_SUCCESS,
  ICreateTruckReview,
  IDeleteLocalClaimPhotoAction,
  IDeleteTruckFileAction,
  IGetEnginesSuccess,
  IGetTruckLocation,
  IGetTruckMaintenanceList,
  IGetTruckMaintenanceListSuccess,
  IGetTruckReviewService,
  IGetTruckReviewServices,
  IGetTruckReviewServicesSuccess,
  IGetTruckReviewServiceSuccess,
  IGetTruckTrafficHistory,
  IGetTruckTrafficHistoryByDate,
  ISetPinTruckMapView,
  ISetTruckReviewCommentVisibility,
  ISetUnPinTruckMapView,
  IUpdateTruckReview,
  IUpdateTruckReviewSuccess,
  IUploadTruckClaimPhotoAction,
  IUploadTruckClaimPhotoActionFail,
  IUploadTruckClaimPhotoActionSuccess,
  IUploadTruckFilesAction,
  IUploadTruckServiceFileAction,
  SET_PIN_TRUCK_MAP_VIEW,
  SET_PIN_TRUCK_MAP_VIEW_FAIL,
  SET_PIN_TRUCK_MAP_VIEW_SUCCESS,
  SET_TRUCK_REVIEW_COMMENT_VISIBILITY,
  SET_UNPIN_TRUCK_MAP_VIEW,
  SET_UNPIN_TRUCK_MAP_VIEW_FAIL,
  SET_UNPIN_TRUCK_MAP_VIEW_SUCCESS,
  UPDATE_TRUCK,
  UPDATE_TRUCK_FAIL,
  UPDATE_TRUCK_REVIEW,
  UPDATE_TRUCK_REVIEW_FAIL,
  UPDATE_TRUCK_REVIEW_SUCCESS,
  UPDATE_TRUCK_SUCCESS,
  UPLOAD_TRUCK_CLAIM_PHOTO,
  UPLOAD_TRUCK_CLAIM_PHOTO_FAIL,
  UPLOAD_TRUCK_CLAIM_PHOTO_SUCCESS,
  UPLOAD_TRUCK_FILES,
  UPLOAD_TRUCK_SERVICE_FILE,
  UNLOCK_TRUCK_INSPECTION,
  IUnlockTruckInspectionAction,
  DELETE_CLAIM_PHOTO,
  DELETE_CLAIM_PHOTO_SUCCESS,
  DELETE_CLAIM_PHOTO_FAIL,
  IDeleteClaimPhotoAction,
  IDeleteClaimPhotoSuccessAction,
  IDeleteClaimPhotoFailAction,
  CLEAR_TRUCK,
  CLEAR_TRUCK_ERRORS,
} from './trucksTypes';

// GET ENGINES
export const getEnginesAction = (
  search: { query: string },
  callback?: () => void,
) => ({
  type: GET_ENGINES,
  payload: { search, callback },
});
export const getEnginesSuccessAction = (
  payload: IGetEnginesSuccess['payload'],
) => ({
  type: GET_ENGINES_SUCCESS,
  payload,
});
export const getEnginesFailAction = (error: AxiosError) => ({
  type: GET_ENGINES_FAIL,
  payload: error,
});

// CREATE TRUCK
export const createTruckAction = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
  callback: (id: number) => void,
) => ({
  type: CREATE_TRUCK,
  payload: { values, callback },
});

export const createTruckSuccessAction = () => ({
  type: CREATE_TRUCK_SUCCESS,
});

export const createTruckFailAction = (error: AxiosError) => ({
  type: CREATE_TRUCK_FAIL,
  payload: error,
});

// CREATE TRUCK PLANNING REVIEW

export const createTruckReviewAction = (
  payload: ICreateTruckReview['payload'],
): ICreateTruckReview => ({ type: CREATE_TRUCK_PLANNING_REVIEW, payload });

export const createTruckReviewSuccessAction = () => ({
  type: CREATE_TRUCK_PLANNING_REVIEW_SUCCESS,
});

export const createTruckReviewFailAction = (error: AxiosError) => ({
  type: CREATE_TRUCK_PLANNING_REVIEW_FAIL,
  payload: error,
});

// UPLOAD CLAIM PHOTO
export const uploadTruckClaimPhotoAction = (
  payload: IUploadTruckClaimPhotoAction['payload'],
): IUploadTruckClaimPhotoAction => ({
  type: UPLOAD_TRUCK_CLAIM_PHOTO,
  payload,
});

export const uploadTruckClaimPhotoSuccessAction = (
  payload: IUploadTruckClaimPhotoActionSuccess['payload'],
): IUploadTruckClaimPhotoActionSuccess => ({
  type: UPLOAD_TRUCK_CLAIM_PHOTO_SUCCESS,
  payload,
});

export const uploadTruckClaimPhotoFailAction = (
  payload: IUploadTruckClaimPhotoActionFail['payload'],
): IUploadTruckClaimPhotoActionFail => ({
  type: UPLOAD_TRUCK_CLAIM_PHOTO_FAIL,
  payload,
});

// DELETE CLAIM PHOTO
export const deleteClaimPhotoAction = (
  payload: IDeleteClaimPhotoAction['payload'],
): IDeleteClaimPhotoAction => ({
  type: DELETE_CLAIM_PHOTO,
  payload,
});

export const deleteClaimPhotoSuccessAction = (
  payload: IDeleteClaimPhotoSuccessAction['payload'],
): IDeleteClaimPhotoSuccessAction => ({
  type: DELETE_CLAIM_PHOTO_SUCCESS,
  payload,
});

export const deleteClaimPhotoFailAction = (
  payload: IDeleteClaimPhotoFailAction['payload'],
): IDeleteClaimPhotoFailAction => ({
  type: DELETE_CLAIM_PHOTO_FAIL,
  payload,
});

// DELETE LOCAL CLAIM PHOTO
export const deleteLocalClaimPhotoAction =
  (): IDeleteLocalClaimPhotoAction => ({
    type: DELETE_LOCAL_CLAIM_PHOTO,
  });
// CREATE TRUCK PLANNING REVIEW

export const updateTruckReviewAction = (
  payload: IUpdateTruckReview['payload'],
): IUpdateTruckReview => ({ type: UPDATE_TRUCK_REVIEW, payload });

export const updateTruckReviewSuccessAction = (
  payload: IUpdateTruckReviewSuccess['payload'],
) => ({
  type: UPDATE_TRUCK_REVIEW_SUCCESS,
  payload,
});

export const updateTruckReviewFailAction = (error: AxiosError) => ({
  type: UPDATE_TRUCK_REVIEW_FAIL,
  payload: error,
});

// GET TRUCKS
export const getTrucksAction = (
  payload: {
    query?: string;
    usePaginationFlag?: boolean;
    preventUpdatePagination?: boolean;
  },
  callback?: () => void,
) => ({
  type: GET_TRUCKS,
  payload,
  callback,
});

export const getTrucksSuccessAction = (payload: ITruck) => ({
  type: GET_TRUCKS_SUCCESS,
  payload,
});

export const getTrucksFailAction = (error: AxiosError) => ({
  type: GET_TRUCKS_FAIL,
  payload: error,
});

// GET TRUCK BY ID
export const getTruckAction = (
  id: string | number,
  callback?: (data: ITruck) => void,
  dispatchToStore = true,
) => ({
  type: GET_TRUCK,
  id,
  dispatchToStore,
  callback,
});

export const getTruckSuccessAction = (data: ITruck) => ({
  type: GET_TRUCK_SUCCESS,
  payload: data,
});

export const getTruckFailAction = (error: AxiosError) => ({
  type: GET_TRUCK_FAIL,
  payload: error,
});

export const clearTruckAction = () => ({
  type: CLEAR_TRUCK,
});

// UPDATE TRUCK BY ID
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateTruckAction = (values: any, callback?: () => void) => ({
  type: UPDATE_TRUCK,
  payload: { values, callback },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateTruckSuccessAction = (payload: any) => ({
  type: UPDATE_TRUCK_SUCCESS,
  payload,
});

export const updateTruckFailAction = (error: AxiosError) => ({
  type: UPDATE_TRUCK_FAIL,
  payload: error,
});

// DELETE TRUCK BY ID
export const deleteTruckAction = (id: number, callback?: () => void) => ({
  type: DELETE_TRUCK,
  payload: { id, callback },
});

export const deleteTruckSuccessAction = () => ({
  type: DELETE_TRUCK_SUCCESS,
});

export const deleteTruckFailAction = (error: AxiosError) => ({
  type: DELETE_TRUCK_FAIL,
  payload: error,
});

// FILES
export const uploadTruckFilesAction = ({
  files,
  itemId,
}: {
  files: ILocalFile[];
  itemId?: number;
}): IUploadTruckFilesAction => ({
  type: UPLOAD_TRUCK_FILES,
  payload: { files, itemId },
});

export const deleteTruckFileAction = ({
  fileId,
  itemId,
}: IDeleteFileAction['payload']): IDeleteTruckFileAction => ({
  type: DELETE_TRUCK_FILE,
  payload: { fileId, itemId },
});

// TRUCK SERVICE FILE
export const uploadTruckServiceFileAction = (
  payload: IUploadTruckServiceFileAction['payload'],
): IUploadTruckServiceFileAction => ({
  type: UPLOAD_TRUCK_SERVICE_FILE,
  payload,
});

export const deleteTruckServiceFileAction = (
  payload: IDeleteFileAction['payload'],
) => ({ type: DELETE_TRUCK_SERVICE_FILE, payload });

// TRACKING TRUCKS
export const getTrackingTrucksAction = (
  vinNumbers: string[],
  callback?: () => void,
) => ({
  type: GET_TRACKING_TRUCKS,
  payload: { vinNumbers, callback },
});

export const clearTruckMarkersAction = () => ({
  type: CLEAR_TRUCK_MARKERS,
});

// LOGS
export const getTruckLogsAction = (
  id: string | number,
  callback?: () => void,
) => ({
  type: GET_TRUCK_LOGS,
  payload: { id, callback },
});

//  GET TRUCK MAINTENANCE LIST
export const getTruckMaintenanceListAction = (
  payload: IGetTruckMaintenanceList['payload'],
): IGetTruckMaintenanceList => ({
  type: GET_TRUCK_MAINTENANCE_LIST,
  payload,
});

export const getTruckMaintenanceListSuccessAction = (
  payload: IGetTruckMaintenanceListSuccess['payload'],
): IGetTruckMaintenanceListSuccess => ({
  type: GET_TRUCK_MAINTENANCE_LIST_SUCCESS,
  payload,
});

export const getTruckMaintenanceListFailAction = (error: AxiosError) => ({
  type: GET_TRUCK_MAINTENANCE_LIST_FAIL,
  payload: error,
});

//  SET TRUCK PIN
export const setTruckPinAction = (
  payload: ISetPinTruckMapView['payload'],
): ISetPinTruckMapView => ({
  type: SET_PIN_TRUCK_MAP_VIEW,
  payload,
});

export const setTruckPinFailAction = (error: AxiosError) => ({
  type: SET_PIN_TRUCK_MAP_VIEW_FAIL,
  payload: error,
});

export const setTruckPinSuccessAction = (payload: number) => ({
  type: SET_PIN_TRUCK_MAP_VIEW_SUCCESS,
  payload,
});

//  SET TRUCK UNPIN
export const setTruckUnPinAction = (
  payload: ISetUnPinTruckMapView['payload'],
): ISetUnPinTruckMapView => ({
  type: SET_UNPIN_TRUCK_MAP_VIEW,
  payload,
});

export const setTruckUnPinFailAction = (error: AxiosError) => ({
  type: SET_UNPIN_TRUCK_MAP_VIEW_FAIL,
  payload: error,
});

export const setTruckUnPinSuccessAction = (payload: number) => ({
  type: SET_UNPIN_TRUCK_MAP_VIEW_SUCCESS,
  payload,
});

// GET_TRUCK_PLANNING_REVIEW_SERVICES
export const getTruckReviewServicesAction = (
  payload: IGetTruckReviewServices['payload'],
) => ({
  type: GET_TRUCK_PLANNING_REVIEW_SERVICES,
  payload,
});
export const getTruckReviewServicesSuccessAction = (
  payload: IGetTruckReviewServicesSuccess['payload'],
) => ({
  type: GET_TRUCK_PLANNING_REVIEW_SERVICES_SUCCESS,
  payload,
});
export const getTruckReviewServicesFailAction = (error: AxiosError) => ({
  type: GET_TRUCK_PLANNING_REVIEW_SERVICES_FAIL,
  payload: error,
});

// GET_TRUCK_TRAFFIC_HISTORY
export const getTruckTrafficHistoryAction = (
  payload: IGetTruckTrafficHistory['payload'],
) => ({
  type: GET_TRUCK_TRAFFIC_HISTORY,
  payload,
});

// CLEAR_TRUCK_TRAFFIC_HISTORY
export const clearTruckTrafficHistoryAction = () => ({
  type: CLEAR_TRUCK_TRAFFIC_HISTORY,
});

// CLEAR_TRUCK_TOLL_LOCATION
export const clearTollTruckLocationAction = () => ({
  type: CLEAR_TRUCK_TOLL_LOCATION,
});

// GET_TRUCK_TRAFFIC_HISTORY_BY_DATE
export const getTruckTrafficHistoryByDateAction = (
  payload: IGetTruckTrafficHistoryByDate['payload'],
) => ({
  type: GET_TRUCK_TRAFFIC_HISTORY_BY_DATE,
  payload,
});

export const getTruckReviewServiceAction = (
  payload: IGetTruckReviewService['payload'],
) => ({
  type: GET_TRUCK_PLANNING_REVIEW_SERVICE,
  payload,
});

export const getTruckReviewServiceSuccessAction = (
  payload: IGetTruckReviewServiceSuccess['payload'],
) => ({
  type: GET_TRUCK_PLANNING_REVIEW_SERVICE_SUCCESS,
  payload,
});

export const getTruckReviewServiceFailAction = (error: AxiosError) => ({
  type: GET_TRUCK_PLANNING_REVIEW_SERVICE_FAIL,
  payload: error,
});

export const setTruckReviewCommentVisibilityAction = (
  payload: ISetTruckReviewCommentVisibility['payload'],
): ISetTruckReviewCommentVisibility => ({
  type: SET_TRUCK_REVIEW_COMMENT_VISIBILITY,
  payload,
});

export const getTruckLocationAction = (
  payload: IGetTruckLocation['payload'],
): IGetTruckLocation => ({
  type: GET_TRUCK_LOCATION,
  payload,
});

export const unlockTruckInspectionAction = (
  payload: IUnlockTruckInspectionAction['payload'],
): IUnlockTruckInspectionAction => ({
  type: UNLOCK_TRUCK_INSPECTION,
  payload,
});

export const clearTruckErrorsAction = () => ({
  type: CLEAR_TRUCK_ERRORS,
});
