import {
  FC,
  useState,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import { emptyFn } from 'utils/helpers';

interface ISearchContextProps {
  value?: string;
  placeholder?: string;
  visible?: boolean;
  searchIcon?: FC | null;
  changesImmediatelly?: boolean;
}

interface ISearchContextActions {
  setValue: (value: string) => void;
  setPlaceholder: (placeholder: string) => void;
  setVisible: (visible: boolean) => void;
  setSearchIcon: (icon?: FC | null) => void;
  setChangesImmediatelly: (changesImmediatelly: boolean) => void;
}

interface ISearchContext extends ISearchContextProps, ISearchContextActions {}

const initialSearchContext: ISearchContext = {
  value: '',
  setValue: emptyFn,
  setPlaceholder: emptyFn,
  setVisible: emptyFn,
  setSearchIcon: emptyFn,
  setChangesImmediatelly: emptyFn,
};

const SearchContext = createContext(initialSearchContext);

export function useSearchPanel(props?: ISearchContextProps | null) {
  const context = useContext(SearchContext);

  useEffect(() => {
    if (props !== null) {
      const {
        value,
        placeholder = 'Search...',
        visible = true,
        searchIcon,
        changesImmediatelly,
      } = props || {};
      if (value !== undefined && context.value !== value)
        context.setValue(value);
      if (visible !== undefined && context.visible !== visible)
        context.setVisible(visible);
      if (placeholder !== undefined && context.placeholder !== placeholder)
        context.setPlaceholder(placeholder);
      if (context.searchIcon !== searchIcon) context.setSearchIcon(searchIcon);
      if (
        changesImmediatelly !== undefined &&
        context.changesImmediatelly !== changesImmediatelly
      )
        context.setChangesImmediatelly(changesImmediatelly);
    }
  }, [props, context]);

  return context;
}

const NewSearchPanelProvider: FC = ({ children }) => {
  const [value, setValue] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const [visible, setVisible] = useState(false);
  const [searchIcon, setSearchIcon] = useState<React.FC | null>();
  const [changesImmediatelly, setChangesImmediatelly] = useState(false);

  const props = useMemo(
    () => ({
      value,
      setValue,
      placeholder,
      setPlaceholder,
      visible,
      setVisible,
      searchIcon,
      setSearchIcon,
      changesImmediatelly,
      setChangesImmediatelly,
    }),
    [
      value,
      setValue,
      placeholder,
      setPlaceholder,
      visible,
      setVisible,
      searchIcon,
      setSearchIcon,
      changesImmediatelly,
      setChangesImmediatelly,
    ],
  );

  return (
    <SearchContext.Provider value={props}>{children}</SearchContext.Provider>
  );
};

export default NewSearchPanelProvider;
