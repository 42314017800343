import {
  DELETE_VEHICLE_INSPECTION,
  UNLOCK_VEHICLE_INSPECTION,
  IDeleteVehicleInspectionAction,
  IUnlockVehicleInspectionAction,
  IDeleteInspectionAction,
  DELETE_INSPECTION,
  ISetIsJustCreatedInspection,
  SET_IS_JUST_CREATED,
  IClearInspectionsError,
  CLEAR_INSPECTIONS_ERROR,
  IHandleSaveInspectionDraft,
  HANDLE_SAVE_DRAFT,
  HANDLE_SAVE_DRAFT_SUCCESS,
  IHandleSaveInspectionDraftSuccess,
  IHandleSetInspectionDriverChanged,
  HANDLE_SET_INSPECTION_DRIVER_CHANGED,
  SET_INSPECTION_CLAIMS_COUNT,
  ISetInspectionClaimsCount,
} from './allInspectionsTypes';

export const deleteVehicleInspectionAction = (
  inspectionId: IDeleteVehicleInspectionAction['inspectionId'],
  vehicleType: IDeleteVehicleInspectionAction['vehicleType'],
  isTandem?: IDeleteVehicleInspectionAction['isTandem'],
  callback?: IDeleteVehicleInspectionAction['callback'],
): IDeleteVehicleInspectionAction => ({
  type: DELETE_VEHICLE_INSPECTION,
  inspectionId,
  vehicleType,
  isTandem,
  callback,
});

export const deleteInspectionAction = (
  inspectionId: IDeleteInspectionAction['inspectionId'],
  callback: IDeleteInspectionAction['callback'],
): IDeleteInspectionAction => ({
  type: DELETE_INSPECTION,
  inspectionId,
  callback,
});

export const unlockVehicleInspectionAction = (
  payload: IUnlockVehicleInspectionAction['payload'],
  callback?: IUnlockVehicleInspectionAction['callback'],
): IUnlockVehicleInspectionAction => ({
  type: UNLOCK_VEHICLE_INSPECTION,
  payload,
  callback,
});

export const setIsJustCreatedInspectionAction = (
  payload: ISetIsJustCreatedInspection['payload'],
): ISetIsJustCreatedInspection => ({
  type: SET_IS_JUST_CREATED,
  payload,
});

export const handleSaveInspectionDraft = (
  payload?: IHandleSaveInspectionDraft['payload'],
): IHandleSaveInspectionDraft => ({
  type: HANDLE_SAVE_DRAFT,
  payload,
});

export const handleSaveInspectionDraftSuccess =
  (): IHandleSaveInspectionDraftSuccess => ({
    type: HANDLE_SAVE_DRAFT_SUCCESS,
  });

export const clearInspectionsErrorAction = (): IClearInspectionsError => ({
  type: CLEAR_INSPECTIONS_ERROR,
});

export const handleSetInspectionDriverChanged = (
  payload?: IHandleSetInspectionDriverChanged['payload'],
): IHandleSetInspectionDriverChanged => ({
  type: HANDLE_SET_INSPECTION_DRIVER_CHANGED,
  payload,
});

export const setInspectionClaimsCount = (
  payload: ISetInspectionClaimsCount['payload'],
): ISetInspectionClaimsCount => ({
  type: SET_INSPECTION_CLAIMS_COUNT,
  payload,
});
