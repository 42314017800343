import { LOGOUT } from 'components/Auth/_redux/authTypes';
import { IOrdersReducer } from 'components/Dispatch/TabOrders/_models/orderModels';
import {
  CHANGE_ORDER_ADDRESS_FILE_CATEGORY,
  CHANGE_ORDER_ADDRESS_FILE_CATEGORY_FAIL,
  CHANGE_ORDER_ADDRESS_FILE_CATEGORY_SUCCESS,
  CHANGE_ORDER_FILE_CATEGORY,
  CHANGE_ORDER_FILE_CATEGORY_FAIL,
  CHANGE_ORDER_FILE_CATEGORY_SUCCESS,
  CREATE_ORDER,
  CREATE_ORDER_FAIL,
  CREATE_ORDER_SUCCESS,
  DELETE_ORDER,
  DELETE_ORDER_ADDRESS_FILE,
  DELETE_ORDER_ADDRESS_FILE_FAIL,
  DELETE_ORDER_ADDRESS_FILE_SUCCESS,
  DELETE_ORDER_FAIL,
  DELETE_ORDER_FILE,
  DELETE_ORDER_FILE_FAIL,
  DELETE_ORDER_FILE_SUCCESS,
  DELETE_ORDER_SUCCESS,
  GET_ORDER,
  GET_ORDER_FAIL,
  GET_ORDER_SUCCESS,
  GET_ORDERS,
  GET_ORDERS_FAIL,
  GET_ORDERS_SUCCESS,
  ordersActionsType,
  SET_DELETE_FILES_PENDING,
  UNMOUNT_ORDER_FORM,
  UPDATE_ORDER,
  UPDATE_ORDER_ADDRESS,
  UPDATE_ORDER_ADDRESS_FAIL,
  UPDATE_ORDER_ADDRESS_SUCCESS,
  UPDATE_ORDER_FAIL,
  UPDATE_ORDER_SUCCESS,
  UPLOAD_ORDER_ADDRESS_FILES,
  UPLOAD_ORDER_ADDRESS_FILES_FAIL,
  UPLOAD_ORDER_ADDRESS_FILES_SUCCESS,
  UPLOAD_ORDER_FILES,
  UPLOAD_ORDER_FILES_FAIL,
  UPLOAD_ORDER_FILES_SUCCESS,
  UPDATE_ORDER_ADDRESS_RECEIPT_PHOTOS,
  UPDATE_ORDER_ADDRESS_RECEIPT_PHOTOS_SUCCESS,
  UPDATE_ORDER_ADDRESS_RECEIPT_PHOTOS_FAIL,
  UPDATE_ORDER_ADDRESS_STATUS,
  UPDATE_ORDER_ADDRESS_STATUS_SUCCESS,
  UPDATE_ORDER_ADDRESS_STATUS_FAIL,
  UPDATE_ORDER_STATUS,
  UPDATE_ORDER_STATUS_FAIL,
  UPDATE_ORDER_STATUS_SUCCESS,
  GET_TRIP_AND_SINGLE_ORDERS,
  GET_TRIP_AND_SINGLE_ORDERS_SUCCESS,
  GET_TRIP_AND_SINGLE_ORDERS_FAIL,
  CONNECT_ORDERS_TO_TRIP_FAIL,
} from 'components/Dispatch/TabOrders/_redux/ordersTypes';

const initialState: IOrdersReducer = {
  pagination: {
    page: 0,
    per_page: 0,
    total: 0,
    pages: 0,
  },
  list: [],
  files: [],
  currentOrder: null,
  statistic: null,
  _pending: false,
  _loaded: false,
  _error: null,
  combineOrders: {
    separateOrders: null,
    connectedOrders: null,
  },
};

const ordersReducers = (
  state = initialState,
  action: ordersActionsType,
): IOrdersReducer => {
  switch (action.type) {
    // GET ORDERS
    case GET_ORDERS:
      return {
        ...state,
        files: [],
        currentOrder: null,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        pagination: action.payload.pagination,
        list: action.payload.orders,
        statistic: action.payload.statistic,
        _loaded: true,
        _pending: false,
      };
    case GET_ORDERS_FAIL:
      return {
        ...initialState,
        _error: action.payload,
        _loaded: false,
        _pending: false,
      };

    // GET ORDER
    case GET_ORDER:
      return { ...state, _loaded: false, _pending: true, _error: null };
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        currentOrder: action.payload,
        files: action.payload.files,
        _loaded: true,
        _pending: false,
      };
    case GET_ORDER_FAIL:
      return {
        ...state,
        _error: action.payload,
        _loaded: false,
        _pending: false,
      };

    // CREATE ORDER
    case CREATE_ORDER:
      return state;
    case CREATE_ORDER_SUCCESS:
      return { ...state, files: [], currentOrder: action.payload };
    case CREATE_ORDER_FAIL:
      return { ...state, _error: action.payload };

    // UPDATE ORDER
    case UPDATE_ORDER:
      return state;
    case UPDATE_ORDER_SUCCESS:
      return { ...state, files: [], currentOrder: action.payload };
    case UPDATE_ORDER_FAIL:
      return { ...state, _error: action.payload };

    // DELETE ORDER
    case DELETE_ORDER:
      return state;
    case DELETE_ORDER_SUCCESS:
      return { ...state, files: [], currentOrder: null };
    case DELETE_ORDER_FAIL:
      return { ...state, _error: action.payload };

    // UPDATE ORDER STATUS
    case UPDATE_ORDER_STATUS:
      return state;
    case UPDATE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        currentOrder: state.currentOrder
          ? { ...state.currentOrder, status: action.payload }
          : null,
      };
    case UPDATE_ORDER_STATUS_FAIL:
      return { ...state, _error: action.payload };

    // UPLOAD ORDER FILES
    case UPLOAD_ORDER_FILES:
      return {
        ...state,
        files: [
          ...state.files,
          ...action.payload.files.map(fileObj => ({
            ...fileObj,
            loading: true,
          })),
        ],
      };

    case UPLOAD_ORDER_FILES_SUCCESS: {
      let { currentOrder } = state;
      if (currentOrder?.files) {
        currentOrder = { ...currentOrder };
        currentOrder.files = [...currentOrder.files];
        const index = currentOrder.files.findIndex(
          ({ id }) => id === action.payload.id,
        );
        if (index >= 0)
          currentOrder.files[index] = { ...action.payload, loading: false };
        else currentOrder.files.push({ ...action.payload, loading: false });
      }
      return {
        ...state,
        currentOrder,
        files: state.files.map((file, index) =>
          index === state.files.findIndex(({ loading }) => loading)
            ? { ...action.payload, loading: false }
            : file,
        ),
      };
    }

    case UPLOAD_ORDER_FILES_FAIL:
      return { ...state, _error: action.payload };

    // CHANGE ORDER FILE CATEGORY
    case CHANGE_ORDER_FILE_CATEGORY:
      return { ...state };
    case CHANGE_ORDER_FILE_CATEGORY_SUCCESS:
      return {
        ...state,
        files: state.files.map(file =>
          file.id === action.payload.id ? action.payload : file,
        ),
      };
    case CHANGE_ORDER_FILE_CATEGORY_FAIL:
      return { ...state, _error: action.payload };

    // DELETE ORDER FILE
    case DELETE_ORDER_FILE:
      return state;

    case DELETE_ORDER_FILE_SUCCESS: {
      let { currentOrder } = state;
      if (currentOrder?.files) {
        currentOrder = { ...currentOrder };
        currentOrder.files = [...currentOrder.files];
        const index = currentOrder.files.findIndex(
          ({ id }) => id === action.payload,
        );
        if (index >= 0) currentOrder.files.splice(index, 1);
      }

      return {
        ...state,
        currentOrder,
        files: state.files.filter(file => file.id !== action.payload),
      };
    }

    case DELETE_ORDER_FILE_FAIL:
      return { ...state, _error: action.payload };

    // UPDATE_ORDER_ADDRESS
    case UPDATE_ORDER_ADDRESS:
      return state;
    case UPDATE_ORDER_ADDRESS_SUCCESS:
      return {
        ...state,
        currentOrder: state.currentOrder
          ? {
              ...state.currentOrder,
              pickup_addresses: state.currentOrder.pickup_addresses.map(
                orderAddress =>
                  orderAddress.id === action.payload.addressId
                    ? action.payload.address
                    : orderAddress,
              ),
              dropoff_addresses: state.currentOrder.dropoff_addresses.map(
                orderAddress =>
                  orderAddress.id === action.payload.addressId
                    ? action.payload.address
                    : orderAddress,
              ),
              swap_addresses: state.currentOrder.swap_addresses.map(
                orderAddress =>
                  orderAddress.id === action.payload.addressId
                    ? action.payload.address
                    : orderAddress,
              ),
            }
          : null,
      };
    case UPDATE_ORDER_ADDRESS_FAIL:
      return { ...state, _error: action.payload };

    // UPDATE ORDER ADDRESS STATUS
    case UPDATE_ORDER_ADDRESS_STATUS:
      return state;
    case UPDATE_ORDER_ADDRESS_STATUS_SUCCESS:
      return state;
    case UPDATE_ORDER_ADDRESS_STATUS_FAIL:
      return { ...state, _error: action.payload };

    // UPLOAD ORDER ADDRESS FILE
    case UPLOAD_ORDER_ADDRESS_FILES:
      return state;
    case UPLOAD_ORDER_ADDRESS_FILES_SUCCESS:
      if (!state.currentOrder) return { ...state };

      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          status: action.payload.file.order_status,
          pickup_addresses: state.currentOrder.pickup_addresses.map(address =>
            address.id !== action.payload.addressId
              ? address
              : {
                  ...address,
                  driver_check_in: true,
                  dispatcher_check_in: true,
                  driver_check_out: !address.dispatcher_check_out
                    ? action.payload.file.order_status === 'delivered' ||
                      action.payload.categoryType === 'proof_photos'
                    : true,
                  dispatcher_check_out: !address.dispatcher_check_out
                    ? action.payload.file.order_status === 'delivered' ||
                      action.payload.categoryType === 'proof_photos'
                    : true,
                  [action.payload.categoryType]: [
                    ...address[action.payload.categoryType],
                    action.payload.file,
                  ],
                },
          ),

          dropoff_addresses: state.currentOrder.dropoff_addresses.map(address =>
            address.id !== action.payload.addressId
              ? address
              : {
                  ...address,
                  driver_check_in: true,
                  dispatcher_check_in: true,
                  driver_check_out: !address.dispatcher_check_out
                    ? action.payload.file.order_status === 'delivered' ||
                      action.payload.categoryType === 'proof_photos'
                    : true,
                  dispatcher_check_out: !address.dispatcher_check_out
                    ? action.payload.file.order_status === 'delivered' ||
                      action.payload.categoryType === 'proof_photos'
                    : true,
                  [action.payload.categoryType]: [
                    ...address[action.payload.categoryType],
                    action.payload.file,
                  ],
                },
          ),
        },
      };
    case UPLOAD_ORDER_ADDRESS_FILES_FAIL:
      return { ...state, _error: action.payload };

    // DELETE ORDER ADDRESS FILE
    case DELETE_ORDER_ADDRESS_FILE:
      return state;

    case DELETE_ORDER_ADDRESS_FILE_SUCCESS:
      if (!state.currentOrder) return { ...state };

      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          pickup_addresses: state.currentOrder.pickup_addresses.map(address =>
            address.id !== action.payload.address_id
              ? address
              : {
                  ...address,
                  [action.payload.categoryType]: address[
                    action.payload.categoryType
                  ].filter(photo => photo.id !== action.payload.id),
                },
          ),
          dropoff_addresses: state.currentOrder.dropoff_addresses.map(address =>
            address.id !== action.payload.address_id
              ? address
              : {
                  ...address,
                  [action.payload.categoryType]: address[
                    action.payload.categoryType
                  ].filter(photo => photo.id !== action.payload.id),
                },
          ),
        },
      };
    case DELETE_ORDER_ADDRESS_FILE_FAIL:
      return { ...state, _error: action.payload };

    // CHANGE ORDER ADDRESS FILE CATEGORY
    case CHANGE_ORDER_ADDRESS_FILE_CATEGORY:
      return state;
    case CHANGE_ORDER_ADDRESS_FILE_CATEGORY_SUCCESS:
      if (!state.currentOrder) return { ...state };

      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          pickup_addresses: state.currentOrder.pickup_addresses.map(address => {
            if (address.id !== action.payload.address_id) return address;

            const movedFile = address[action.payload.categoryType].find(
              photo => photo.id === action.payload.fileId,
            );
            if (!movedFile) return address;

            return {
              ...address,
              proof_photos:
                action.payload.categoryType === 'proof_photos'
                  ? address.proof_photos.filter(
                      photo => photo.id !== action.payload.fileId,
                    )
                  : [...address.proof_photos, movedFile],
              cargo_photos:
                action.payload.categoryType === 'cargo_photos'
                  ? address.cargo_photos.filter(
                      photo => photo.id !== action.payload.fileId,
                    )
                  : [...address.cargo_photos, movedFile],
            };
          }),
          dropoff_addresses: state.currentOrder.dropoff_addresses.map(
            address => {
              if (address.id !== action.payload.address_id) return address;

              const movedFile = address[action.payload.categoryType].find(
                photo => photo.id === action.payload.fileId,
              );
              if (!movedFile) return address;

              return {
                ...address,
                proof_photos:
                  action.payload.categoryType === 'proof_photos'
                    ? address.proof_photos.filter(
                        photo => photo.id !== action.payload.fileId,
                      )
                    : [...address.proof_photos, movedFile],
                cargo_photos:
                  action.payload.categoryType === 'cargo_photos'
                    ? address.cargo_photos.filter(
                        photo => photo.id !== action.payload.fileId,
                      )
                    : [...address.cargo_photos, movedFile],
              };
            },
          ),
        },
      };
    case CHANGE_ORDER_ADDRESS_FILE_CATEGORY_FAIL:
      return { ...state, _error: action.payload };

    // UPDATE ORDER ADDRESS RECEIPT PHOTO
    case UPDATE_ORDER_ADDRESS_RECEIPT_PHOTOS:
      return state;
    case UPDATE_ORDER_ADDRESS_RECEIPT_PHOTOS_SUCCESS:
      if (!state.currentOrder) return { ...state };

      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          pickup_addresses: state.currentOrder.pickup_addresses.map(address => {
            if (address.id !== action.payload.address_id) return address;

            return {
              ...address,
              receipt_files: address.receipt_files.map(photo =>
                photo.id === action.payload.photo.id
                  ? action.payload.photo
                  : photo,
              ),
            };
          }),
          dropoff_addresses: state.currentOrder.dropoff_addresses.map(
            address => {
              if (address.id !== action.payload.address_id) return address;

              return {
                ...address,
                receipt_files: address.receipt_files.map(photo =>
                  photo.id === action.payload.photo.id
                    ? action.payload.photo
                    : photo,
                ),
              };
            },
          ),
        },
      };
    case UPDATE_ORDER_ADDRESS_RECEIPT_PHOTOS_FAIL:
      return { ...state, _error: action.payload };

    //  OTHER
    case SET_DELETE_FILES_PENDING: {
      return {
        ...state,
        currentOrder: state.currentOrder
          ? {
              ...state.currentOrder,
              deleteFilesPending: state.currentOrder.deleteFilesPending
                ? [...state.currentOrder.deleteFilesPending, action.payload]
                : [action.payload],
            }
          : null,
      };
    }

    case UNMOUNT_ORDER_FORM:
      return { ...state };
    case LOGOUT:
      return { ...initialState };
    // GET_TRIP_AND_SINGLE_ORDERS
    case GET_TRIP_AND_SINGLE_ORDERS:
      return state;
    case GET_TRIP_AND_SINGLE_ORDERS_SUCCESS:
      return {
        ...state,
        combineOrders: {
          separateOrders: action.payload.data.orders.filter(
            order =>
              !order.is_trip_order &&
              order.id !== action.payload.orderIdToExclude &&
              order,
          ),
          connectedOrders: action.payload.data.orders.filter(
            order =>
              order.id !== action.payload.orderIdToExclude &&
              order.is_trip_order &&
              order,
          ),
        },
      };
    case GET_TRIP_AND_SINGLE_ORDERS_FAIL:
      return { ...state, _error: action.payload };
    // CONNECT_ORDERS_TO_TRIP_FAIL
    case CONNECT_ORDERS_TO_TRIP_FAIL:
      return { ...state, _error: action.payload };
    default:
      return state;
  }
};

export default ordersReducers;
