import { LOGOUT } from 'components/Auth/_redux/authTypes';

import {
  IVendorsReducer,
  CREATE_VENDOR,
  CREATE_VENDOR_SUCCESS,
  CREATE_VENDOR_FAIL,
  UPDATE_VENDOR,
  UPDATE_VENDOR_SUCCESS,
  UPDATE_VENDOR_FAIL,
  VendorsTypes,
  GET_VENDORS,
  GET_VENDORS_SUCCESS,
  GET_VENDORS_FAIL,
  GET_VENDOR,
  GET_VENDOR_SUCCESS,
  GET_VENDOR_FAIL,
  GET_VENDOR_LOCATIONS,
  GET_VENDOR_LOCATIONS_SUCCESS,
  GET_VENDOR_LOCATIONS_FAIL,
  GET_VENDOR_PROFILE_ORDERS_HISTORY,
  GET_VENDOR_PROFILE_ORDERS_HISTORY_SUCCESS,
  GET_VENDOR_PROFILE_ORDERS_HISTORY_FAIL,
  CLEAR_CURRENT_VENDOR,
  REMOVE_VENDOR,
  REMOVE_VENDOR_SUCCESS,
  REMOVE_VENDOR_FAIL,
  GET_ALL_VENDORS_SUCCESS,
} from './vendorsTypes';

const initialState: IVendorsReducer = {
  total: null,
  list: [],
  fullList: [],
  vendorLocations: [],
  currentVendor: null,
  items: [],
  _pending: false,
  _loaded: false,
  _error: null,
};

const inventoryGroupReducer = (
  state = initialState,
  action: VendorsTypes,
): IVendorsReducer => {
  switch (action.type) {
    // CREATE_VENDOR
    case CREATE_VENDOR:
      return { ...state, _pending: true, _error: null };
    case CREATE_VENDOR_SUCCESS:
      return {
        ...state,
        currentVendor: action.payload.vendor,
        _pending: false,
      };
    case CREATE_VENDOR_FAIL:
      return { ...state, _pending: false, _error: action.payload };

    // REMOVE_VENDOR
    case REMOVE_VENDOR:
      return { ...state };
    case REMOVE_VENDOR_SUCCESS:
      return {
        ...state,
        currentVendor: null,
        list: state.list.filter(vendor => vendor.id !== action.payload.id),
      };
    case REMOVE_VENDOR_FAIL:
      return { ...state, _error: action.payload };

    // UPDATE_VENDOR
    case UPDATE_VENDOR:
      return { ...state };
    case UPDATE_VENDOR_SUCCESS:
      return {
        ...state,
        currentVendor: null,
        list: state.list.map(vendor =>
          vendor.id === action.payload.id ? action.payload : vendor,
        ),
      };
    case UPDATE_VENDOR_FAIL:
      return { ...state, _error: action.payload };

    // GET VENDORS
    case GET_VENDOR:
      return { ...state, _pending: true, _error: null };
    case GET_VENDOR_SUCCESS:
      return {
        ...state,
        _pending: false,
        currentVendor: action.payload,
        _loaded: true,
      };
    case GET_VENDOR_FAIL:
      return { ...state, _pending: false, _error: action.payload };

    // GET_VENDORS
    case GET_VENDORS:
      return {
        ...state,
        list: [],
        _pending: true,
        _loaded: false,
        _error: null,
      };
    case GET_VENDORS_SUCCESS:
      return {
        ...state,
        list: action.payload.vendors,
        total: action.payload.pagination
          ? action.payload.pagination.total
          : state.total,
        _pending: false,
        _loaded: true,
        _error: null,
      };
    case GET_ALL_VENDORS_SUCCESS:
      return {
        ...state,
        fullList: action.payload.vendors,
      };
    case GET_VENDORS_FAIL:
      return {
        ...state,
        _error: action.payload,
        _loaded: false,
        _pending: false,
      };

    // GET_VENDOR_LOCATIONS
    case GET_VENDOR_LOCATIONS:
      return {
        ...state,
        vendorLocations: [],
        _error: null,
      };
    case GET_VENDOR_LOCATIONS_SUCCESS:
      return {
        ...state,
        vendorLocations: action.payload,
        _error: null,
      };
    case GET_VENDOR_LOCATIONS_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // GET_VENDOR PROFILE ORDERS HISTORY
    case GET_VENDOR_PROFILE_ORDERS_HISTORY:
      return {
        ...state,
        items: [],
        _error: null,
      };
    case GET_VENDOR_PROFILE_ORDERS_HISTORY_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        total: action.payload.pagination
          ? action.payload.pagination.total
          : state.total,
        _error: null,
      };
    case GET_VENDOR_PROFILE_ORDERS_HISTORY_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // CLEAR CURRENT VENDOR
    case CLEAR_CURRENT_VENDOR:
      return { ...state, currentVendor: null };

    // LOGOUT
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};

export default inventoryGroupReducer;
