import { FC } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
  strokeColor?: string;
}

const IconStarRounded: FC<IOwnProps> = ({
  className,
  fillColor = 'white',
  strokeColor = 'var(--primaryText)',
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.2503 5.62997L10.4742 5.05515L8.78468 1.46962C8.48896 0.84346 7.5108 0.84346 7.21508 1.46962L5.52648 5.05515L1.74944 5.62997C1.03463 5.73906 0.745035 6.66318 1.26474 7.19491L3.99799 9.98559L3.35317 13.9268C3.23156 14.6731 3.98049 15.2461 4.62268 14.8931L7.99988 13.033L11.3779 14.894C12.0149 15.2433 12.7699 14.6795 12.6475 13.9277L12.0026 9.98651L14.7359 7.19583C15.2547 6.66318 14.9651 5.73906 14.2503 5.62997Z"
        stroke={strokeColor}
        fill={fillColor}
        strokeWidth="2"
      />
    </svg>
  );
};

export default IconStarRounded;
