import {
  CREATE_TOLL,
  GET_TOLLS,
  GET_TOLL,
  UPDATE_TOLL,
  DELETE_TOLL,
  SEARCH_TOLLS,
  ITollsState,
  CLEAR_STATE,
  ICreateTollAction,
  IGetTollsAction,
  IGetTollAction,
  IUpdateTollAction,
  IDeleteTollAction,
  PIN_TOLL,
  ISetTollPinAction,
  UNPIN_TOLL,
  ISetTollUnPinAction,
  ISearchTolls,
  IGetTollAlertsAction,
  GET_TOLL_ALERTS,
  ISetDateRangeAction,
  SET_DATE_RANGE,
  IGetTollsStatisticsAction,
  GET_TOLLS_STATISTICS,
  IGetTollsRoadsStatisticsAction,
  GET_TOLLS_ROADS_STATISTICS,
  IGetTollsTrucksStatisticsAction,
  GET_TOLLS_TRUCKS_STATISTICS,
} from './tollsTypes';

// CREATE_TOLL
export const createTollAction = (payload: ICreateTollAction['payload']) => ({
  type: CREATE_TOLL,
  payload,
});

// GET_TOLLS
export const getTollsAction = (payload: IGetTollsAction['payload']) => ({
  type: GET_TOLLS,
  payload,
});

// SEARCH_TOLLS
export const searchTollsAction = (payload: ISearchTolls['payload']) => ({
  type: SEARCH_TOLLS,
  payload,
});

// GET_TOLL
export const getTollAction = (payload: IGetTollAction['payload']) => ({
  type: GET_TOLL,
  payload,
});

// UPDATE_TOLL
export const updateTollAction = (payload: IUpdateTollAction['payload']) => ({
  type: UPDATE_TOLL,
  payload,
});

// DELETE_TOLL
export const deleteTollAction = (payload: IDeleteTollAction['payload']) => ({
  type: DELETE_TOLL,
  payload,
});

// CLEAR_STATE
export const clearTollStateAction = (data: Partial<ITollsState>) => ({
  type: CLEAR_STATE,
  data,
});

// PIN_TOLL
export const setTollPinAction = (payload: ISetTollPinAction['payload']) => ({
  type: PIN_TOLL,
  payload,
});

// UNPIN_TOLL
export const setTollUnPinAction = (
  payload: ISetTollUnPinAction['payload'],
) => ({
  type: UNPIN_TOLL,
  payload,
});

// GET_TOLL_ALERTS
export const getTollAlertsAction = (
  payload: IGetTollAlertsAction['payload'],
) => ({ type: GET_TOLL_ALERTS, payload });

// SET_DATE_RANGE
export const setDateRangeAction = (
  payload: ISetDateRangeAction['payload'],
) => ({ type: SET_DATE_RANGE, payload });

// GET_TOLLS_STATISTICS
export const getTollsStatisticsAction = (
  payload: IGetTollsStatisticsAction['payload'],
) => ({ type: GET_TOLLS_STATISTICS, payload });

// GET_TOLLS_ROADS_STATISTICS
export const getTollsRoadsStatisticsAction = (
  payload: IGetTollsRoadsStatisticsAction['payload'],
) => ({ type: GET_TOLLS_ROADS_STATISTICS, payload });

// GET_TOLLS_TRUCKS_STATISTICS
export const getTollsTrucksStatisticsAction = (
  payload: IGetTollsTrucksStatisticsAction['payload'],
) => ({ type: GET_TOLLS_TRUCKS_STATISTICS, payload });
