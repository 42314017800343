/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';

import { IPagination } from 'components/_common/Navigation/Pagination/_redux/paginationTypes';
import {
  IOrderAddressPhoto,
  IOrder,
  IOrderUploadedFile,
  IOrderAddressReceiptPhoto,
  IOrderStatusStatistic,
} from 'components/Dispatch/TabOrders/_models/orderModels';
import {
  IDeleteFileAction,
  IDeleteFileActionFail,
  IDeleteFileActionSuccess,
  IUploadFilesAction,
  IUploadFilesActionFail,
  IUploadFilesActionSuccess,
} from 'redux/_common/commonTypes';
import { ILogout } from 'redux/_common/models';

// GET ORDERS
export const GET_ORDERS = '[@@Order] GET_ORDERS';
export const GET_ORDERS_SUCCESS = '[@@Order] GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAIL = '[@@Order] GET_ORDERS_FAIL';

export interface IGetOrdersAction {
  type: typeof GET_ORDERS;
}
export interface IGetOrdersActionSuccess {
  type: typeof GET_ORDERS_SUCCESS;
  payload: {
    orders: IOrder[];
    pagination: IPagination;
    statistic: IOrderStatusStatistic;
  };
}
export interface IGetOrdersActionFail {
  type: typeof GET_ORDERS_FAIL;
  payload: AxiosError;
}

// GET ORDER
export const GET_ORDER = '[@@Order] GET_ORDER';
export const GET_ORDER_SUCCESS = '[@@Order] GET_ORDER_SUCCESS';
export const GET_ORDER_FAIL = '[@@Order] GET_ORDER_FAIL';

export interface IGetOrderAction {
  type: typeof GET_ORDER;
}
export interface IGetOrderActionSuccess {
  type: typeof GET_ORDER_SUCCESS;
  payload: IOrder;
}
export interface IGetOrderActionFail {
  type: typeof GET_ORDER_FAIL;
  payload: AxiosError;
}

// CREATE ORDER
export const CREATE_ORDER = '[@@Order] CREATE_ORDER';
export const CREATE_ORDER_SUCCESS = '[@@Order] CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAIL = '[@@Order] CREATE_ORDER_FAIL';

export interface ICreateOrderAction {
  type: typeof CREATE_ORDER;
}
export interface ICreateOrderActionSuccess {
  type: typeof CREATE_ORDER_SUCCESS;
  payload: IOrder;
}
export interface ICreateOrderActionFail {
  type: typeof CREATE_ORDER_FAIL;
  payload: AxiosError;
}

// UPDATE ORDER
export const UPDATE_ORDER = '[@@Order] UPDATE_ORDER';
export const UPDATE_ORDER_SUCCESS = '[@@Order] UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAIL = '[@@Order] UPDATE_ORDER_FAIL';

export interface IUpdateOrderAction {
  type: typeof UPDATE_ORDER;
}
export interface IUpdateOrderActionSuccess {
  type: typeof UPDATE_ORDER_SUCCESS;
  payload: IOrder;
}
export interface IUpdateOrderActionFail {
  type: typeof UPDATE_ORDER_FAIL;
  payload: AxiosError;
}

// DELETE ORDER
export const DELETE_ORDER = '[@@Order] DELETE_ORDER';
export const DELETE_ORDER_SUCCESS = '[@@Order] DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAIL = '[@@Order] DELETE_ORDER_FAIL';

export interface IDeleteOrderAction {
  type: typeof DELETE_ORDER;
}
export interface IDeleteOrderActionSuccess {
  type: typeof DELETE_ORDER_SUCCESS;
  payload: number;
}
export interface IDeleteOrderActionFail {
  type: typeof DELETE_ORDER_FAIL;
  payload: AxiosError;
}

// UPLOAD ORDER FILE
export const UPLOAD_ORDER_FILES = '[@@Order] UPLOAD_ORDER_FILES';
export const UPLOAD_ORDER_FILES_SUCCESS =
  '[@@Order] UPLOAD_ORDER_FILES_SUCCESS';
export const UPLOAD_ORDER_FILES_FAIL = '[@@Order] UPLOAD_ORDER_FILES_FAIL';

export interface IUploadOrderFilesAction extends IUploadFilesAction {
  type: typeof UPLOAD_ORDER_FILES;
}

export interface IUploadOrderFilesActionSuccess
  extends IUploadFilesActionSuccess {
  type: typeof UPLOAD_ORDER_FILES_SUCCESS;
  payload: IOrderUploadedFile;
}
export interface IUploadOrderFilesActionFail extends IUploadFilesActionFail {
  type: typeof UPLOAD_ORDER_FILES_FAIL;
  payload: AxiosError;
}

// CHANGE ORDER CATEGORY FILE
export const CHANGE_ORDER_FILE_CATEGORY =
  '[@@Order] CHANGE_ORDER_FILE_CATEGORY';
export const CHANGE_ORDER_FILE_CATEGORY_SUCCESS =
  '[@@Order] CHANGE_ORDER_FILE_CATEGORY_SUCCESS';
export const CHANGE_ORDER_FILE_CATEGORY_FAIL =
  '[@@Order] CHANGE_ORDER_FILE_CATEGORY_FAIL';

export interface IChangeOrderFileCategoryAction {
  type: typeof CHANGE_ORDER_FILE_CATEGORY;
  payload: any;
}
export interface IChangeOrderFileCategoryActionSuccess {
  type: typeof CHANGE_ORDER_FILE_CATEGORY_SUCCESS;
  payload: IOrderUploadedFile;
}
export interface IChangeOrderFileCategoryActionFail {
  type: typeof CHANGE_ORDER_FILE_CATEGORY_FAIL;
  payload: AxiosError;
}

// DELETE ORDER FILE
export const DELETE_ORDER_FILE = '[@@Order] DELETE_ORDER_FILE';
export const DELETE_ORDER_FILE_SUCCESS = '[@@Order] DELETE_ORDER_FILE_SUCCESS';
export const DELETE_ORDER_FILE_FAIL = '[@@Order] DELETE_ORDER_FILE_FAIL';

export interface IDeleteOrderFileAction extends IDeleteFileAction {
  type: typeof DELETE_ORDER_FILE;
}
export interface IDeleteOrderFileActionSuccess
  extends IDeleteFileActionSuccess {
  type: typeof DELETE_ORDER_FILE_SUCCESS;
}

export interface IDeleteOrderFileActionFail extends IDeleteFileActionFail {
  type: typeof DELETE_ORDER_FILE_FAIL;
}

// UPDATE ORDER STATUS
export const UPDATE_ORDER_STATUS = '[@@Order] UPDATE_ORDER_STATUS';
export const UPDATE_ORDER_STATUS_SUCCESS =
  '[@@Order] UPDATE_ORDER_STATUS_SUCCESS';
export const UPDATE_ORDER_STATUS_FAIL = '[@@Order] UPDATE_ORDER_STATUS_FAIL';

export interface IUpdateOrderStatusAction {
  type: typeof UPDATE_ORDER_STATUS;
  payload: any;
}
export interface IUpdateOrderStatusActionSuccess {
  type: typeof UPDATE_ORDER_STATUS_SUCCESS;
  payload: any;
}
export interface IUpdateOrderStatusActionFail {
  type: typeof UPDATE_ORDER_STATUS_FAIL;
  payload: AxiosError;
}

// UPDATE ORDER ADDRESS
export const UPDATE_ORDER_ADDRESS = '[@@Order] UPDATE_ORDER_ADDRESS';
export const UPDATE_ORDER_ADDRESS_SUCCESS =
  '[@@Order] UPDATE_ORDER_ADDRESS_SUCCESS';
export const UPDATE_ORDER_ADDRESS_FAIL = '[@@Order] UPDATE_ORDER_ADDRESS_FAIL';

export interface IUpdateOrderAddressAction {
  type: typeof UPDATE_ORDER_ADDRESS;
  payload: any;
}
export interface IUpdateOrderAddressActionSuccess {
  type: typeof UPDATE_ORDER_ADDRESS_SUCCESS;
  payload: any;
}
export interface IUpdateOrderAddressActionFail {
  type: typeof UPDATE_ORDER_ADDRESS_FAIL;
  payload: AxiosError;
}

// UPDATE ORDER ADDRESS STATUS
export const UPDATE_ORDER_ADDRESS_STATUS =
  '[@@Order] UPDATE_ORDER_ADDRESS_STATUS';
export const UPDATE_ORDER_ADDRESS_STATUS_SUCCESS =
  '[@@Order] UPDATE_ORDER_ADDRESS_STATUS_SUCCESS';
export const UPDATE_ORDER_ADDRESS_STATUS_FAIL =
  '[@@Order] UPDATE_ORDER_ADDRESS_STATUS_FAIL';

export interface IUpdateOrderAddressStatusAction {
  type: typeof UPDATE_ORDER_ADDRESS_STATUS;
  payload: any;
}
export interface IUpdateOrderAddressStatusActionSuccess {
  type: typeof UPDATE_ORDER_ADDRESS_STATUS_SUCCESS;
  payload: any;
}
export interface IUpdateOrderAddressStatusActionFail {
  type: typeof UPDATE_ORDER_ADDRESS_STATUS_FAIL;
  payload: AxiosError;
}

// DELETE ORDER ADDRESS
export const DELETE_ORDER_ADDRESS = '[@@Order] DELETE_ORDER_ADDRESS';
export const DELETE_ORDER_ADDRESS_SUCCESS =
  '[@@Order] DELETE_ORDER_ADDRESS_SUCCESS';
export const DELETE_ORDER_ADDRESS_FAIL = '[@@Order] DELETE_ORDER_ADDRESS_FAIL';

export interface IDeleteOrderAddressAction {
  type: typeof DELETE_ORDER_ADDRESS;
  payload: { orderId: number; addressId: number };
}
export interface IDeleteOrderAddressActionSuccess {
  type: typeof DELETE_ORDER_ADDRESS_SUCCESS;
  payload: any;
}
export interface IDeleteOrderAddressActionFail {
  type: typeof DELETE_ORDER_ADDRESS_FAIL;
  payload: AxiosError;
}

// UPLOAD ORDER ADDRESS FILE
export const UPLOAD_ORDER_ADDRESS_FILES =
  '[@@Order] UPLOAD_ORDER_ADDRESS_FILES';
export const UPLOAD_ORDER_ADDRESS_FILES_SUCCESS =
  '[@@Order] UPLOAD_ORDER_ADDRESS_FILES_SUCCESS';
export const UPLOAD_ORDER_ADDRESS_FILES_FAIL =
  '[@@Order] UPLOAD_ORDER_ADDRESS_FILES_FAIL';

export interface IUploadOrderAddressFilesAction extends IUploadFilesAction {
  type: typeof UPLOAD_ORDER_ADDRESS_FILES;
  payload: any; // @todo rework as ILocalFile
}
export interface IUploadOrderAddressFilesActionSuccess {
  type: typeof UPLOAD_ORDER_ADDRESS_FILES_SUCCESS;
  payload: {
    file: IOrderAddressPhoto;
    categoryType: 'cargo_photos' | 'proof_photos';
    addressId: number;
  };
}
export interface IUploadOrderAddressFilesActionFail {
  type: typeof UPLOAD_ORDER_ADDRESS_FILES_FAIL;
  payload: AxiosError;
}

// DELETE ORDER ADDRESS FILE
export const DELETE_ORDER_ADDRESS_FILE = '[@@Order] DELETE_ORDER_ADDRESS_FILE';
export const DELETE_ORDER_ADDRESS_FILE_SUCCESS =
  '[@@Order] DELETE_ORDER_ADDRESS_FILE_SUCCESS';
export const DELETE_ORDER_ADDRESS_FILE_FAIL =
  '[@@Order] DELETE_ORDER_ADDRESS_FILE_FAIL';

export interface IDeleteOrderAddressFileAction {
  type: typeof DELETE_ORDER_ADDRESS_FILE;
  payload: any;
}
export interface IDeleteOrderAddressFileActionSuccess {
  type: typeof DELETE_ORDER_ADDRESS_FILE_SUCCESS;
  payload: {
    order_id: number;
    address_id: number;
    categoryType: 'cargo_photos' | 'proof_photos';
    id: number;
  };
}
export interface IDeleteOrderAddressFileActionFail {
  type: typeof DELETE_ORDER_ADDRESS_FILE_FAIL;
  payload: AxiosError;
}

// CHANGE ORDER CATEGORY FILE
export const CHANGE_ORDER_ADDRESS_FILE_CATEGORY =
  '[@@Order] CHANGE_ORDER_ADDRESS_FILE_CATEGORY';
export const CHANGE_ORDER_ADDRESS_FILE_CATEGORY_SUCCESS =
  '[@@Order] CHANGE_ORDER_ADDRESS_FILE_CATEGORY_SUCCESS';
export const CHANGE_ORDER_ADDRESS_FILE_CATEGORY_FAIL =
  '[@@Order] CHANGE_ORDER_ADDRESS_FILE_CATEGORY_FAIL';

export interface IChangeOrderAddressFileCategoryAction {
  type: typeof CHANGE_ORDER_ADDRESS_FILE_CATEGORY;
  payload: any;
}
export interface IChangeOrderAddressFileCategoryActionSuccess {
  type: typeof CHANGE_ORDER_ADDRESS_FILE_CATEGORY_SUCCESS;
  payload: {
    fileId: number;
    order_id: number;
    address_id: number;
    categoryType: 'cargo_photos' | 'proof_photos';
  };
}
export interface IChangeOrderAddressFileCategoryActionFail {
  type: typeof CHANGE_ORDER_ADDRESS_FILE_CATEGORY_FAIL;
  payload: AxiosError;
}

// UPDATE ORDER ADDRESS RECEIPT PHOTO
export const UPDATE_ORDER_ADDRESS_RECEIPT_PHOTOS =
  '[@@Order] UPDATE_ORDER_ADDRESS_RECEIPT_PHOTOS';
export const UPDATE_ORDER_ADDRESS_RECEIPT_PHOTOS_SUCCESS =
  '[@@Order] UPDATE_ORDER_ADDRESS_RECEIPT_PHOTOS_SUCCESS';
export const UPDATE_ORDER_ADDRESS_RECEIPT_PHOTOS_FAIL =
  '[@@Order] UPDATE_ORDER_ADDRESS_RECEIPT_PHOTOS_FAIL';

export interface IUpdateOrderAddressReceiptPhotoAction {
  type: typeof UPDATE_ORDER_ADDRESS_RECEIPT_PHOTOS;
  payload: IOrderAddressReceiptPhoto[];
}

export interface IUpdateOrderAddressReceiptPhotoActionSuccess {
  type: typeof UPDATE_ORDER_ADDRESS_RECEIPT_PHOTOS_SUCCESS;
  payload: {
    order_id: number;
    address_id: number;
    photo: IOrderAddressReceiptPhoto;
  };
}

export interface IUpdateOrderAddressReceiptPhotoActionFail {
  type: typeof UPDATE_ORDER_ADDRESS_RECEIPT_PHOTOS_FAIL;
  payload: AxiosError;
}

// OTHER
export const SET_DELETE_FILES_PENDING = '[@@Order] SET_DELETE_FILES_PENDING';
export interface ISetDeleteOrderFilesPendingAction {
  type: typeof SET_DELETE_FILES_PENDING;
  payload: number;
}

export const GET_TRIP_AND_SINGLE_ORDERS =
  '[@@Order] GET_TRIP_AND_SINGLE_ORDERS';
export const GET_TRIP_AND_SINGLE_ORDERS_SUCCESS =
  '[@@Order] GET_TRIP_AND_SINGLE_ORDERS_SUCCESS';
export const GET_TRIP_AND_SINGLE_ORDERS_FAIL =
  '[@@Order] GET_TRIP_AND_SINGLE_ORDERS_FAIL';
export interface IGetTripAndSingleOrdersAction {
  type: typeof GET_TRIP_AND_SINGLE_ORDERS;
  payload: {
    createdOrderId: number;
    driverId: number;
    callback?: (orders: IOrder[]) => void;
  };
}
export interface IGetTripAndSingleOrdersSuccessAction {
  type: typeof GET_TRIP_AND_SINGLE_ORDERS_SUCCESS;
  payload: {
    data: {
      orders: IOrder[];
    };
    orderIdToExclude: number;
  };
}
export interface IGetTripAndSingleOrdersFailAction {
  type: typeof GET_TRIP_AND_SINGLE_ORDERS_FAIL;
  payload: AxiosError;
}

export const CONNECT_ORDERS_TO_TRIP = '[@@Order] CONNECT_ORDERS_TO_TRIP';
export const CONNECT_ORDERS_TO_TRIP_SUCCESS =
  '[@@Order] CONNECT_ORDERS_TO_TRIP_SUCCESS';
export const CONNECT_ORDERS_TO_TRIP_FAIL =
  '[@@Order] CONNECT_ORDERS_TO_TRIP_FAIL';
export interface IConnectOrdersToTripAction {
  type: typeof CONNECT_ORDERS_TO_TRIP;
  payload: {
    data: {
      orderId: number;
      driverId: number;
      orderConnectId: number;
    };
    callback?: () => void;
  };
}
export interface IConnectOrdersToTripSuccessAction {
  type: typeof CONNECT_ORDERS_TO_TRIP_SUCCESS;
  payload: any;
}
export interface IConnectOrdersToTripFailAction {
  type: typeof CONNECT_ORDERS_TO_TRIP_FAIL;
  payload: AxiosError;
}

export const MOVE_TRIP_ADDRESSES = '[@@Order] MOVE_TRIP_ADDRESSES';
export const MOVE_TRIP_ADDRESSES_SUCCESS =
  '[@@Order] MOVE_TRIP_ADDRESSES_SUCCESS';
export const MOVE_TRIP_ADDRESSES_FAIL = '[@@Order] MOVE_TRIP_ADDRESSES_FAIL';
export interface IMoveTripAddressesAction {
  type: typeof MOVE_TRIP_ADDRESSES;
  payload: {
    data: {
      orderId: number;
      tripAddresses: {
        positions: Array<{ id: number; position: number }>;
      };
    };
    callback?: () => void;
  };
}
export interface IMoveTripAddressesActionSuccess {
  type: typeof MOVE_TRIP_ADDRESSES_SUCCESS;
}
export interface IMoveTripAddressesActionFail {
  type: typeof MOVE_TRIP_ADDRESSES_FAIL;
  payload: AxiosError;
}

// REMOVE_ORDER_CONNECTION
export const REMOVE_ORDER_CONNECTION = '[@@Order] REMOVE_ORDER_CONNECTION';
export const REMOVE_ORDER_CONNECTION_SUCCESS =
  '[@@Order] REMOVE_ORDER_CONNECTION_SUCCESS';
export const REMOVE_ORDER_CONNECTION_FAIL =
  '[@@Order] REMOVE_ORDER_CONNECTION_FAIL';

export interface IRemoveOrderConnectionAction {
  type: typeof REMOVE_ORDER_CONNECTION;
  payload: {
    data: any;
    callback?: () => void;
  };
}
export interface IRemoveOrderConnectionActionSuccess {
  type: typeof REMOVE_ORDER_CONNECTION_SUCCESS;
}
export interface IRemoveOrderConnectionActionFail {
  type: typeof REMOVE_ORDER_CONNECTION_FAIL;
  payload: AxiosError;
}

export const UNMOUNT_ORDER_FORM = '[@@Order] UNMOUNT_ORDER_FORM';
export interface IUnmountOrderFormAction {
  type: typeof UNMOUNT_ORDER_FORM;
}

// UPDATE DRIVER
export const UPDATE_DRIVER = '[@@Order] UPDATE_DRIVER';
export const UPDATE_DRIVER_SUCCESS = '[@@Order] UPDATE_DRIVER_SUCCESS';
export const UPDATE_DRIVER_FAIL = '[@@Order] UPDATE_DRIVER_FAIL';

export interface IUpdateDriverAction {
  type: typeof UPDATE_DRIVER;
}
export interface IUpdateDriverActionSuccess {
  type: typeof UPDATE_DRIVER_SUCCESS;
}
export interface IUpdateDriverActionFail {
  type: typeof UPDATE_DRIVER_FAIL;
  payload: AxiosError;
}

export type ordersActionsType =
  | IMoveTripAddressesAction
  | IMoveTripAddressesActionSuccess
  | IMoveTripAddressesActionFail
  | IGetOrdersAction
  | IGetOrdersActionSuccess
  | IGetOrdersActionFail
  | IGetOrderAction
  | IGetOrderActionSuccess
  | IGetOrderActionFail
  | ICreateOrderAction
  | ICreateOrderActionSuccess
  | ICreateOrderActionFail
  | IUpdateOrderAction
  | IUpdateOrderActionSuccess
  | IUpdateOrderActionFail
  | IUpdateOrderStatusAction
  | IUpdateOrderStatusActionSuccess
  | IUpdateOrderStatusActionFail
  | IDeleteOrderAction
  | IDeleteOrderActionSuccess
  | IDeleteOrderActionFail
  | IUploadOrderFilesAction
  | IUploadOrderFilesActionSuccess
  | IUploadOrderFilesActionFail
  | IChangeOrderFileCategoryAction
  | IChangeOrderFileCategoryActionSuccess
  | IChangeOrderFileCategoryActionFail
  | IDeleteOrderFileAction
  | IDeleteOrderFileActionSuccess
  | IDeleteOrderFileActionFail
  | IDeleteOrderAddressAction
  | IDeleteOrderAddressActionSuccess
  | IDeleteOrderAddressActionFail
  | IUpdateOrderAddressAction
  | IUpdateOrderAddressActionSuccess
  | IUpdateOrderAddressActionFail
  | IUpdateOrderAddressStatusAction
  | IUpdateOrderAddressStatusActionSuccess
  | IUpdateOrderAddressStatusActionFail
  | IUploadOrderAddressFilesAction
  | IUploadOrderAddressFilesActionSuccess
  | IUploadOrderAddressFilesActionFail
  | IDeleteOrderAddressFileAction
  | IDeleteOrderAddressFileActionSuccess
  | IDeleteOrderAddressFileActionFail
  | IChangeOrderAddressFileCategoryAction
  | IChangeOrderAddressFileCategoryActionSuccess
  | IChangeOrderAddressFileCategoryActionFail
  | IUpdateOrderAddressReceiptPhotoAction
  | IUpdateOrderAddressReceiptPhotoActionSuccess
  | IUpdateOrderAddressReceiptPhotoActionFail
  | IUnmountOrderFormAction
  | ISetDeleteOrderFilesPendingAction
  | IGetTripAndSingleOrdersAction
  | IGetTripAndSingleOrdersSuccessAction
  | IGetTripAndSingleOrdersFailAction
  | IConnectOrdersToTripAction
  | IConnectOrdersToTripSuccessAction
  | IConnectOrdersToTripFailAction
  | IRemoveOrderConnectionAction
  | IRemoveOrderConnectionActionSuccess
  | IRemoveOrderConnectionActionFail
  | ILogout
  | IUpdateDriverAction
  | IUpdateDriverActionSuccess
  | IUpdateDriverActionFail;
