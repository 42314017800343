import { AxiosError, AxiosRequestConfig } from 'axios';

import { IPagination } from 'components/_common/Navigation/Pagination/_redux/paginationTypes';
import { ILogout } from 'redux/_common/models';
import { IWithPending, IWithLoaded, IWithError } from 'utils/models';

import {
  IPayroll,
  IPayrollItem,
  IUpdatedPayrollItem,
  IDriver,
  IDriversPayrollItem,
  IOutOfRangePayrollItem,
} from '../_models/payrollsModels';

interface IDriversPayrollsItems {
  list: IDriversPayrollItem[];
  statistics?: {
    amount_min: number;
    amount_max: number;
    miles_min: number;
    miles_max: number;
  };
  pagination?: IPagination;
}

export interface IDriversPayrollsItemsReducer
  extends IDriversPayrollsItems,
    IWithPending,
    IWithLoaded,
    IWithError {}

interface IPayrolls {
  list: IPayroll[];
  statistics?: {
    [key: string]: number;
  };
  pagination?: IPagination;
}

export interface IPayrollsReducer
  extends IPayrolls,
    IWithPending,
    IWithLoaded,
    IWithError {}

interface IPayrollItems {
  driverId?: number;
  status?: string;
  list: IPayrollItem[];
  totalAmount: number;
  hasComments: boolean;
  commentsCount: number;
  pdfId: number;
}

interface IPayrollItemComments {
  comments: [];
}

export interface IPayrollItemCommentsReducer
  extends IPayrollItemComments,
    IWithPending,
    IWithLoaded,
    IWithError {}

export interface IPayrollItemsReducer
  extends IPayrollItems,
    IWithPending,
    IWithLoaded,
    IWithError {}

export interface IDriversReducer extends IWithPending, IWithLoaded, IWithError {
  list: IDriver[];
  pagination?: IPagination;
}

export interface IAccountingPayrollsReducer {
  driversPayrollsItems: IDriversPayrollsItemsReducer; // list of payrolls drivers items
  payrolls: IPayrollsReducer; // list of retrieved payrolls for certain period or driver
  payrollItems: IPayrollItemsReducer; // payroll items for certain driver payroll
  drivers: IDriversReducer; // common list of drivers who have payrolls
  payrollItemComments: IPayrollItemCommentsReducer; // list of comments
}

// GET DRIVERS PAYROLLS ITEMS
export const GET_DRIVERS_PAYROLLS_ITEMS =
  '[@@Accounting_Payrolls] GET_DRIVERS_PAYROLLS_ITEMS';
export const GET_DRIVERS_PAYROLLS_ITEMS_SUCCESS =
  '[@@Accounting_Payrolls] GET_DRIVERS_PAYROLLS_ITEMS_SUCCESS';
export const GET_DRIVERS_PAYROLLS_ITEMS_FAIL =
  '[@@Accounting_Payrolls] GET_DRIVERS_PAYROLLS_ITEMS_FAIL';

export interface IGetDriversPayrollsItemsAction {
  type: typeof GET_DRIVERS_PAYROLLS_ITEMS;
  driverId: number;
  params?: AxiosRequestConfig['params'];
}

export interface IGetDriversPayrollsItemsActionSuccess {
  type: typeof GET_DRIVERS_PAYROLLS_ITEMS_SUCCESS;
  payload: IDriversPayrollsItems;
}

export interface IGetDriversPayrollsItemsActionFail {
  type: typeof GET_DRIVERS_PAYROLLS_ITEMS_FAIL;
  payload: AxiosError;
}

// GET WEEK OR DRIVER PAYROLLS
export const GET_PAYROLLS = '[@@Accounting_Payrolls] GET_PAYROLLS';
export const GET_PAYROLLS_SUCCESS =
  '[@@Accounting_Payrolls] GET_PAYROLLS_SUCCESS';
export const GET_PAYROLLS_FAIL = '[@@Accounting_Payrolls] GET_PAYROLLS_FAIL';

export interface IGetPayrollsAction {
  type: typeof GET_PAYROLLS;
  week_id?: string;
  params?: AxiosRequestConfig['params'];
}

export interface IGetPayrollsActionSuccess {
  type: typeof GET_PAYROLLS_SUCCESS;
  payload: IPayrolls;
}

export interface IGetPayrollsActionFail {
  type: typeof GET_PAYROLLS_FAIL;
  payload: AxiosError;
}

// GET DRIVER PAYROLLS
export const GET_DRIVER_PAYROLLS =
  '[@@Accounting_Payrolls] GET_DRIVER_PAYROLLS';
export const GET_DRIVER_PAYROLLS_SUCCESS =
  '[@@Accounting_Payrolls] GET_DRIVER_PAYROLLS_SUCCESS';
export const GET_DRIVER_PAYROLLS_FAIL =
  '[@@Accounting_Payrolls] GET_DRIVER_PAYROLLS_FAIL';

export interface IGetDriverPayrollsAction {
  type: typeof GET_DRIVER_PAYROLLS;
  payload: {
    driverId: number;
    payrollDate?: string;
  };
}

export interface IGetDriverPayrollsActionSuccess {
  type: typeof GET_DRIVER_PAYROLLS_SUCCESS;
  payload: IPayrollItems;
}

export interface IGetDriverPayrollsActionFail {
  type: typeof GET_DRIVER_PAYROLLS_FAIL;
  payload: AxiosError;
}

// UPDATE DRIVER PAYROLL STATUS
export const UPDATE_DRIVER_PAYROLL_STATUS =
  '[@@Accounting_Payrolls] UPDATE_DRIVER_PAYROLL_STATUS';
export const UPDATE_DRIVER_PAYROLL_STATUS_SUCCESS =
  '[@@Accounting_Payrolls] UPDATE_DRIVER_PAYROLL_STATUS_SUCCESS';
export const UPDATE_DRIVER_PAYROLL_STATUS_FAIL =
  '[@@Accounting_Payrolls] UPDATE_DRIVER_PAYROLL_STATUS_FAIL';

export interface IUpdateDriverPayrollStatusAction {
  type: typeof UPDATE_DRIVER_PAYROLL_STATUS;
  payload: {
    driverId: number | number[];
    status: 'sent' | 'paid';
    payroll_number: number;
  };
}

export interface IUpdateDriverPayrollStatusSuccess {
  type: typeof UPDATE_DRIVER_PAYROLL_STATUS_SUCCESS;
  payload: {
    status: 'sent' | 'paid';
    payroll_number: number;
  };
}

export interface IUpdateDriverPayrollStatusFail {
  type: typeof UPDATE_DRIVER_PAYROLL_STATUS_FAIL;
  payload: AxiosError;
}

// UPDATE PAYROLL ITEM
export const UPDATE_PAYROLL_ITEM =
  '[@@Accounting_Payrolls] UPDATE_PAYROLL_ITEM';

export interface IUpdatePayrollItemAction {
  type: typeof UPDATE_PAYROLL_ITEM;
  payload: IUpdatedPayrollItem;
}

// PATCH DRIVER PAYROLLS
export const PATCH_DRIVER_PAYROLLS =
  '[@@Accounting_Payrolls] PATCH_DRIVER_PAYROLLS';
export const PATCH_DRIVER_PAYROLLS_SUCCESS =
  '[@@Accounting_Payrolls] PATCH_DRIVER_PAYROLLS_SUCCESS';
export const PATCH_DRIVER_PAYROLLS_FAIL =
  '[@@Accounting_Payrolls] PATCH_DRIVER_PAYROLLS_FAIL';

export interface IPatchDriverPayrollsAction {
  type: typeof PATCH_DRIVER_PAYROLLS;
  payload: IPatchDriverPayrollsActionSuccess['payload'] & {
    payrollDate?: string;
    payrollId?: number;
    callback?(payload: IPatchDriverPayrollsActionSuccess['payload']): void;
  };
}

export interface IPatchDriverPayrollsActionSuccess {
  type: typeof PATCH_DRIVER_PAYROLLS_SUCCESS;
  payload: {
    driverId: number | number[];
    payrollStatus: string;
    items: IUpdatedPayrollItem[];
  };
}

export interface IPatchDriverPayrollsActionFail {
  type: typeof PATCH_DRIVER_PAYROLLS_FAIL;
  payload: AxiosError;
}

// PATCH DRIVER PAYROLL ITEM
export const PATCH_DRIVER_PAYROLL_ITEM =
  '[@@Accounting_Payrolls] PATCH_DRIVER_PAYROLL_ITEM';
export const PATCH_DRIVER_PAYROLL_ITEM_SUCCESS =
  '[@@Accounting_Payrolls] PATCH_DRIVER_PAYROLL_ITEM_SUCCESS';
export const PATCH_DRIVER_PAYROLL_ITEM_FAIL =
  '[@@Accounting_Payrolls] PATCH_DRIVER_PAYROLL_ITEM_FAIL';

export interface IPatchDriverPayrollItemAction {
  type: typeof PATCH_DRIVER_PAYROLL_ITEM;
  item: IPayrollItem;
  callback?: (item: IPayrollItem) => void;
}

export interface IPatchDriverPayrollItemActionSuccess {
  type: typeof PATCH_DRIVER_PAYROLL_ITEM_SUCCESS;
  payload: IPayrollItem;
}

export interface IPatchDriverPayrollItemActionFail {
  type: typeof PATCH_DRIVER_PAYROLL_ITEM_FAIL;
  payload: AxiosError;
}

// GET DRIVER PAYROLLS OUT OF RANGE ITEMS (ORDER | FUEL | DEDUCTION)
export const GET_PAYROLL_OUT_OF_RANGE_ITEMS =
  '[@@Accounting_Payrolls] GET_PAYROLL_OUT_OF_RANGE_ITEMS';
export const GET_PAYROLL_OUT_OF_RANGE_ITEMS_SUCCESS =
  '[@@Accounting_Payrolls] GET_PAYROLL_OUT_OF_RANGE_ITEMS_SUCCESS';
export const GET_PAYROLL_OUT_OF_RANGE_ITEMS_FAIL =
  '[@@Accounting_Payrolls] GET_PAYROLL_OUT_OF_RANGE_ITEMS_FAIL';

export interface IGetPayrollOutOfRangeItemsAction {
  type: typeof GET_PAYROLL_OUT_OF_RANGE_ITEMS;
  payload: {
    driverId: number;
    weekId: string;
    itemsType?: IOutOfRangePayrollItem['type'];
    callback?(
      payload: IGetPayrollOutOfRangeItemsActionSuccess['payload'],
    ): void;
  };
}

export interface IGetPayrollOutOfRangeItemsActionSuccess {
  type: typeof GET_PAYROLL_OUT_OF_RANGE_ITEMS_SUCCESS;
  payload: {
    driverId: number;
    weekId: string;
    items: IOutOfRangePayrollItem[];
  };
}

export interface IGetPayrollOutOfRangeItemsActionFail {
  type: typeof GET_PAYROLL_OUT_OF_RANGE_ITEMS_FAIL;
  payload: AxiosError;
}

// ADD OUT OF RANGE ITEMS INTO DRIVER PAYROLL (ORDER | FUEL | DEDUCTION)
export const ADD_PAYROLL_OUT_OF_RANGE_ITEMS =
  '[@@Accounting_Payrolls] ADD_PAYROLL_OUT_OF_RANGE_ITEMS';
export const ADD_PAYROLL_OUT_OF_RANGE_ITEMS_SUCCESS =
  '[@@Accounting_Payrolls] ADD_PAYROLL_OUT_OF_RANGE_ITEMS_SUCCESS';
export const ADD_PAYROLL_OUT_OF_RANGE_ITEMS_FAIL =
  '[@@Accounting_Payrolls] ADD_PAYROLL_OUT_OF_RANGE_ITEMS_FAIL';

export interface IAddPayrollOutOfRangeItemsAction {
  type: typeof ADD_PAYROLL_OUT_OF_RANGE_ITEMS;
  payload: IGetPayrollOutOfRangeItemsActionSuccess['payload'] & {
    callback?(
      payload: IGetPayrollOutOfRangeItemsActionSuccess['payload'],
    ): void;
  };
}

export interface IAddPayrollOutOfRangeItemsActionSuccess {
  type: typeof ADD_PAYROLL_OUT_OF_RANGE_ITEMS_SUCCESS;
  payload: {
    driverId: number;
    weekId: string;
    items: IOutOfRangePayrollItem[];
  };
}

export interface IAddPayrollOutOfRangeItemsActionFail {
  type: typeof ADD_PAYROLL_OUT_OF_RANGE_ITEMS_FAIL;
  payload: AxiosError;
}

// GET DRIVERS LIST
export const GET_DRIVERS = '[@@Accounting_Payrolls] GET_DRIVERS';
export const GET_DRIVERS_SUCCESS =
  '[@@Accounting_Payrolls] GET_DRIVERS_SUCCESS';
export const GET_DRIVERS_FAIL = '[@@Accounting_Payrolls] GET_DRIVERS_FAIL';

export interface IGetDriversAction {
  type: typeof GET_DRIVERS;
  payload?: string[];
}
export interface IGetDriversActionSuccess {
  type: typeof GET_DRIVERS_SUCCESS;
  payload: IDriver[];
}
export interface IGetDriversActionFail {
  type: typeof GET_DRIVERS_FAIL;
  payload: AxiosError;
}

// GET PAYROLL ITEM COMMENTS
export const GET_PAYROLL_ITEM_COMMENTS =
  '[@@Accounting_Payrolls] GET_PAYROLL_ITEM_COMMENTS';
export const GET_PAYROLL_ITEM_COMMENTS_SUCCESS =
  '[@@Accounting_Payrolls] GET_PAYROLL_ITEM_COMMENTS_SUCCESS';
export const GET_PAYROLL_ITEM_COMMENTS_FAIL =
  '[@@Accounting_Payrolls] GET_PAYROLL_ITEM_COMMENTS_FAIL';

export interface IGetPayrollItemCommentsAction {
  type: typeof GET_PAYROLL_ITEM_COMMENTS;
  payload: {
    driverId: number;
    id: number;
  };
}

export interface IGetPayrollItemCommentsActionSuccess {
  type: typeof GET_PAYROLL_ITEM_COMMENTS_SUCCESS;
  payload: IPayrollItemComments;
}

export interface IGetPayrollItemCommentsActionFail {
  type: typeof GET_PAYROLL_ITEM_COMMENTS_FAIL;
  payload: AxiosError;
}

export type payrollsActionTypes =
  | IGetPayrollsAction
  | IGetPayrollsActionSuccess
  | IGetPayrollsActionFail
  | IGetDriverPayrollsAction
  | IGetDriverPayrollsActionSuccess
  | IGetDriverPayrollsActionFail
  | IUpdatePayrollItemAction
  | IPatchDriverPayrollsAction
  | IPatchDriverPayrollsActionSuccess
  | IPatchDriverPayrollsActionFail
  | IPatchDriverPayrollItemAction
  | IPatchDriverPayrollItemActionSuccess
  | IPatchDriverPayrollItemActionFail
  | IGetDriversAction
  | IGetDriversActionSuccess
  | IGetDriversActionFail
  | IGetPayrollItemCommentsAction
  | IGetPayrollItemCommentsActionSuccess
  | IGetPayrollItemCommentsActionFail
  | IGetDriversPayrollsItemsAction
  | IGetDriversPayrollsItemsActionSuccess
  | IGetDriversPayrollsItemsActionFail
  | IGetPayrollOutOfRangeItemsAction
  | IGetPayrollOutOfRangeItemsActionSuccess
  | IGetPayrollOutOfRangeItemsActionFail
  | IUpdateDriverPayrollStatusAction
  | IUpdateDriverPayrollStatusSuccess
  | IUpdateDriverPayrollStatusFail
  | ILogout;
