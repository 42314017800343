import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';

import {
  PAYROLL_SETTINGS_API_PREFIX,
  GPS_INTEGRATIONS_API,
} from 'constants/api';
import api from 'utils/requests';

import { IGpsTrackingIntegration } from '../_models/settingsModels';
import {
  IGetPayrollSettingsActionSuccess,
  IGetPayrollSettingsActionFail,
  GET_PAYROLL_SETTINGS,
  GET_PAYROLL_SETTINGS_SUCCESS,
  GET_PAYROLL_SETTINGS_FAIL,
  IUpdatePayrollSettingsAction,
  IUpdatePayrollSettingsActionSuccess,
  IUpdatePayrollSettingsActionFail,
  UPDATE_PAYROLL_SETTINGS,
  UPDATE_PAYROLL_SETTINGS_SUCCESS,
  UPDATE_PAYROLL_SETTINGS_FAIL,
  IGetGpsIntegrationsActionSuccess,
  IGetGpsIntegrationsActionFail,
  GET_GPS_INTEGRATIONS,
  GET_GPS_INTEGRATIONS_SUCCESS,
  GET_GPS_INTEGRATIONS_FAIL,
  IUpdateGpsIntegrationAction,
  IUpdateGpsIntegrationActionSuccess,
  IUpdateGpsIntegrationActionFail,
  UPDATE_GPS_INTEGRATION,
  UPDATE_GPS_INTEGRATION_SUCCESS,
  UPDATE_GPS_INTEGRATION_FAIL,
  IDeleteGpsIntegrationAction,
  IDeleteGpsIntegrationActionSuccess,
  IDeleteGpsIntegrationActionFail,
  DELETE_GPS_INTEGRATION,
  DELETE_GPS_INTEGRATION_SUCCESS,
  DELETE_GPS_INTEGRATION_FAIL,
} from './settingsTypes';

function* getPayrollSettingsSaga() {
  try {
    const { data } = yield call(() => api.get(PAYROLL_SETTINGS_API_PREFIX));
    yield put<IGetPayrollSettingsActionSuccess>({
      type: GET_PAYROLL_SETTINGS_SUCCESS,
      payload: data.company_payroll_settings,
    });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetPayrollSettingsActionFail>({
        type: GET_PAYROLL_SETTINGS_FAIL,
        payload: e,
      });
  }
}

function* updatePayrollSettingsSaga({
  payload: payrollSettings,
}: IUpdatePayrollSettingsAction) {
  try {
    yield call(() =>
      api.patch(PAYROLL_SETTINGS_API_PREFIX, {
        company_payroll_settings: payrollSettings,
      }),
    );
    yield put<IUpdatePayrollSettingsActionSuccess>({
      type: UPDATE_PAYROLL_SETTINGS_SUCCESS,
      payload: payrollSettings,
    });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IUpdatePayrollSettingsActionFail>({
        type: UPDATE_PAYROLL_SETTINGS_FAIL,
        payload: e,
      });
  }
}

function* getGpsIntegrationsSaga() {
  try {
    const { data } = yield call(() => api.get(GPS_INTEGRATIONS_API));
    yield put<IGetGpsIntegrationsActionSuccess>({
      type: GET_GPS_INTEGRATIONS_SUCCESS,
      payload: data.gps_tracking_integrations,
    });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetGpsIntegrationsActionFail>({
        type: GET_GPS_INTEGRATIONS_FAIL,
        payload: e,
      });
  }
}

function* updateGpsIntegrationSaga({
  payload,
  callback,
}: IUpdateGpsIntegrationAction) {
  const items = Array.isArray(payload) ? payload : [payload];
  try {
    const addItems = items.filter(({ id }) => !id);
    const updItems = items.filter(({ id }) => !!id);
    const result: IGpsTrackingIntegration[] = [];
    // create items
    for (const item of addItems) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, updated_at, ...rest } = item;
      const { data } = yield call(() =>
        api.post(GPS_INTEGRATIONS_API, {
          gps_tracking_integration: rest,
        }),
      );
      result.push(data.gps_tracking_integration);
    }
    // update items
    for (const item of updItems) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, updated_at, ...rest } = item;
      const { data } = yield call(() =>
        api.patch(`${GPS_INTEGRATIONS_API}/${id}`, {
          gps_tracking_integration: rest,
        }),
      );
      result.push(data.gps_tracking_integration);
    }

    if (result.length)
      yield put<IUpdateGpsIntegrationActionSuccess>({
        type: UPDATE_GPS_INTEGRATION_SUCCESS,
        payload: result,
      });
    if (callback) callback(result);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IUpdateGpsIntegrationActionFail>({
        type: UPDATE_GPS_INTEGRATION_FAIL,
        payload: e,
      });
  }
}

function* deleteGpsIntegrationSaga({
  payload,
  callback,
}: IDeleteGpsIntegrationAction) {
  const ids = Array.isArray(payload) ? payload : [payload];
  try {
    for (const id of ids) {
      yield call(() => api.delete(`${GPS_INTEGRATIONS_API}/${id}`));
    }
    if (ids.length)
      yield put<IDeleteGpsIntegrationActionSuccess>({
        type: DELETE_GPS_INTEGRATION_SUCCESS,
        payload,
      });
    if (callback) callback(payload);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IDeleteGpsIntegrationActionFail>({
        type: DELETE_GPS_INTEGRATION_FAIL,
        payload: e,
      });
  }
}

export default function* settingsSagas() {
  yield takeLatest(GET_PAYROLL_SETTINGS, getPayrollSettingsSaga);
  yield takeEvery(UPDATE_PAYROLL_SETTINGS, updatePayrollSettingsSaga);
  yield takeLatest(GET_GPS_INTEGRATIONS, getGpsIntegrationsSaga);
  yield takeEvery(UPDATE_GPS_INTEGRATION, updateGpsIntegrationSaga);
  yield takeEvery(DELETE_GPS_INTEGRATION, deleteGpsIntegrationSaga);
}
