import {
  CHANGE_LANGUAGE,
  COLOR_THEME,
  SET_DESKTOP_VERSION,
  LOAD_SETTINGS,
  SET_CURRENT_PERMISSIONS,
} from 'redux/userSettings/userSettingsTypes';

import { IPermissionObject } from '../../routes';

export const changeLanguageAction = (language: string) => ({
  type: CHANGE_LANGUAGE,
  payload: language,
});

export const changeColorThemeAction = (theme: string) => ({
  type: COLOR_THEME,
  payload: theme,
});

export const setDesktopVersionAction = () => ({ type: SET_DESKTOP_VERSION });

export const getUserSettings = () => ({ type: LOAD_SETTINGS });

export const setCurrentPermissionsAction = (
  permissions: IPermissionObject[],
) => ({
  type: SET_CURRENT_PERMISSIONS,
  payload: permissions,
});
