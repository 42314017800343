import { FC } from 'react';

import { useSelector } from 'react-redux';

import { userThemeSelector } from 'redux/userSettings/userSettingsSelectors';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const IconChain: FC<IOwnProps> = ({ className, fillColor = '#2C6AF6' }) => {
  const theme = useSelector(userThemeSelector);

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.58694 9.41161C7.13304 9.95766 7.94299 10.3337 8.71526 10.3337C9.48752 10.3337 10.2282 10.027 10.7743 9.48093L13.2193 7.03584C13.7568 6.48798 14.0562 5.75009 14.0525 4.98264C14.0488 4.21518 13.673 3.41089 13.1303 2.86821C12.5877 2.32553 11.7975 1.96368 11.03 1.95999C10.4764 1.95734 9.93818 2.1124 9.47558 2.40075M6.54452 13.6118C6.0815 13.9058 5.54086 14.0654 4.98376 14.0654C4.21149 14.0654 3.40144 13.6893 2.85536 13.1432C2.30928 12.5971 1.94714 11.8013 1.94714 11.029C1.94714 10.2567 2.25393 9.51608 2.80001 8.97L5.24269 6.52798C5.78876 5.98214 6.52925 5.67551 7.30135 5.67551C7.74364 5.67551 8.19373 5.79424 8.60018 5.99828M8.60018 5.99828C8.90328 6.15044 9.18211 6.35002 9.41537 6.58319L8.60018 5.99828Z"
        stroke={theme === 'dark' ? 'white' : fillColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconChain;
