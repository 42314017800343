import { isEmpty, reject, isNil } from 'ramda';
import { takeLatest, call, put, delay } from 'redux-saga/effects';

import {
  ACCOUNTING_BILLINGS_API,
  ACCOUNTING_BILLING_STATUS_API,
  ACCOUNTING_BILLINGS_CREATE_ORDER_API,
  ACCOUNTING_BILLINGS_GET_ORDER_API,
  ACCOUNTING_BILLINGS_REGENERATE_BILL_API,
  ACCOUNTING_BILLINGS_MERGE_PDF_LINK_API,
  ACCOUNTING_EXTRA_CHARGE_API,
  ACCOUNTING_EXTRA_CHARGE_FILE_API,
  ACCOUNTING_PROOF_OF_DELIVERY_API,
  ACCOUNTING_BILLINGS_BROKER_DEDUCTION_API,
  ACCOUNTING_BILLINGS_GET_EXCEL_FILE_API,
} from 'constants/api';
import { fileToFormData } from 'utils/converters';
import api from 'utils/requests';

import { IOrderExtraCharges } from '../../../Dispatch/TabOrders/_models/orderModels';
import { IExtraCharge } from '../_models/extraChargeModels';
import { ProofOfDeliveryPhoto } from '../_models/podModels';
import {
  createOrderZipAction,
  getBillingItemsAction,
  getOrderZipAction,
  patchBillingItemsAction,
  addExtraChargesAction,
  updateExtraChargeAction,
  deleteExtraChargeAction,
  addProofOfDeliveriesAction,
  deleteProofOfDeliveryAction,
  regenerateBillAction,
  createBrokerDeductionAction,
  getBillingsAsExcelDocAction,
} from './billingActions';
import {
  GET_BILLING_ITEMS,
  GET_BILLING_ITEMS_SUCCESS,
  GET_BILLING_ITEMS_FAIL,
  IGetBillingItemsActionSuccess,
  IGetBillingItemsActionFail,
  PATCH_BILLING_ITEMS_SUCCESS,
  PATCH_BILLING_ITEMS_FAIL,
  PATCH_BILLING_ITEMS,
  IPatchBillingItemsActionSuccess,
  IPatchBillingItemsActionFail,
  CREATE_ORDER_ZIP_SUCCESS,
  CREATE_ORDER_ZIP_FAIL,
  CREATE_ORDER_ZIP,
  ICreateOrderZipActionSuccess,
  ICreateOrderZipActionFail,
  GET_ORDER_ZIP_SUCCESS,
  GET_ORDER_ZIP_FAIL,
  GET_ORDER_ZIP,
  IGetOrderZipActionSuccess,
  IGetOrderZipActionFail,
  ADD_EXTRA_CHARGES_SUCCESS,
  ADD_EXTRA_CHARGES_FAIL,
  ADD_EXTRA_CHARGES,
  IAddExtraChargesActionSuccess,
  IAddExtraChargesActionFail,
  UPDATE_EXTRA_CHARGE_SUCCESS,
  UPDATE_EXTRA_CHARGE_FAIL,
  UPDATE_EXTRA_CHARGE,
  IUpdateExtraChargeActionSuccess,
  IUpdateExtraChargeActionFail,
  DELETE_EXTRA_CHARGE_SUCCESS,
  DELETE_EXTRA_CHARGE_FAIL,
  DELETE_EXTRA_CHARGE,
  IDeleteExtraChargeActionSuccess,
  IDeleteExtraChargeActionFail,
  ADD_PROOF_OF_DELIVERIES_SUCCESS,
  ADD_PROOF_OF_DELIVERIES_FAIL,
  ADD_PROOF_OF_DELIVERIES,
  IAddProofOfDeliveriesActionSuccess,
  IAddProofOfDeliveriesActionFail,
  DELETE_PROOF_OF_DELIVERY_SUCCESS,
  DELETE_PROOF_OF_DELIVERY_FAIL,
  DELETE_PROOF_OF_DELIVERY,
  IDeleteProofOfDeliveryActionSuccess,
  IDeleteProofOfDeliveryActionFail,
  REGENERATE_BILL_SUCCESS,
  REGENERATE_BILL_FAIL,
  REGENERATE_BILL,
  IRegenerateBillActionSuccess,
  IRegenerateBillActionFail,
  CREATE_BROKER_DEDUCTION_SUCCESS,
  UPDATE_BROKER_DEDUCTION_SUCCESS,
  DELETE_BROKER_DEDUCTION_SUCCESS,
  BROKER_DEDUCTION_FAIL,
  BROKER_DEDUCTION_INVOKE,
  IBrokerDeductionActionSuccess,
  IBrokerDeductionActionFail,
  GET_BILLING_ITEMS_AS_EXCEL_SUCCESS,
  GET_BILLING_ITEMS_AS_EXCEL_FAIL,
  GET_BILLING_ITEMS_AS_EXCEL,
  IGetBillingsAsExcelActionSuccess,
  IGetBillingsAsExcelActionFail,
} from './billingTypes';

function* getBillingItemsSaga({
  params,
  query,
}: ReturnType<typeof getBillingItemsAction>) {
  try {
    const {
      data: { items: list, pagination, statistics = {} },
    } = yield call(() =>
      api.post(`${ACCOUNTING_BILLINGS_API}${query}`, { filter: params }),
    );

    if (!isEmpty(list))
      for (const billing of list) {
        if (!isEmpty(billing.proof_of_deliveries))
          for (let i = 0; i < billing.proof_of_deliveries.length; i += 1) {
            const pod = billing.proof_of_deliveries[i];
            for (let j = 0; j < pod.photos.length; j += 1)
              pod.photos[j] = {
                ...pod.photos[j],
                order_id: billing.id,
                address_id: pod.id,
              };
          }
      }

    yield put<IGetBillingItemsActionSuccess>({
      type: GET_BILLING_ITEMS_SUCCESS,
      payload: { list, pagination, statistics },
    });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetBillingItemsActionFail>({
        type: GET_BILLING_ITEMS_FAIL,
        payload: e,
      });
  }
}

function* patchBillingItemsSaga({
  payload: { items, callback },
}: ReturnType<typeof patchBillingItemsAction>) {
  try {
    yield call(() =>
      api.patch(ACCOUNTING_BILLING_STATUS_API, {
        orders: items,
      }),
    );
    yield put<IPatchBillingItemsActionSuccess>({
      type: PATCH_BILLING_ITEMS_SUCCESS,
      items,
    });
    if (callback) callback(items);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IPatchBillingItemsActionFail>({
        type: PATCH_BILLING_ITEMS_FAIL,
        payload: e,
      });
  }
}

function* createOrderZipSaga({
  ids,
  callback,
}: ReturnType<typeof createOrderZipAction>) {
  try {
    const postData = {
      orders_zip_file_download_task: {
        order_ids: ids.join(','),
      },
    };
    const { data } = yield call(() =>
      api.post(ACCOUNTING_BILLINGS_CREATE_ORDER_API, postData),
    );
    yield put<ICreateOrderZipActionSuccess>({
      type: CREATE_ORDER_ZIP_SUCCESS,
      id: data.id,
    });
    if (callback) callback(data.id);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<ICreateOrderZipActionFail>({
        type: CREATE_ORDER_ZIP_FAIL,
        payload: e,
      });
  }
}

function* getOrderZipSaga({
  order_id,
  callback,
}: ReturnType<typeof getOrderZipAction>) {
  try {
    const {
      data: { zip_file_download_task },
    } = yield call(() => api.get(ACCOUNTING_BILLINGS_GET_ORDER_API(order_id)));
    yield put<IGetOrderZipActionSuccess>({
      type: GET_ORDER_ZIP_SUCCESS,
      payload: zip_file_download_task,
    });
    if (callback) callback(zip_file_download_task);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetOrderZipActionFail>({
        type: GET_ORDER_ZIP_FAIL,
        payload: e,
      });
  }
}

function* addExtraChargesSaga({
  payload: { billingId, items, callback },
}: ReturnType<typeof addExtraChargesAction>) {
  try {
    const payloads: IExtraCharge[] = [];
    for (const item of items) {
      let receipt_file = item.receipt_file || undefined;
      if (receipt_file?.isLocal) {
        // upload new receipt file
        const formData = fileToFormData(
          receipt_file.file,
          'orders_receipt_file[file]',
        );
        if (formData)
          formData.append(
            `orders_receipt_file[amount]`,
            item.amount.toString(),
          );
        const { data } = yield call(() =>
          api.post(ACCOUNTING_EXTRA_CHARGE_FILE_API(billingId), formData),
        );
        receipt_file = data.receipt_file;
      }

      // create extra charge
      const {
        amount,
        payer,
        charger = 'broker_charger',
        charge_type,
        payment_code,
        payer_driver_id,
        date,
      } = item;
      const orders_extra_charge = reject(isNil)({
        amount,
        payer,
        charger,
        charge_type,
        payment_code,
        payer_driver_id,
        date,
        receipt_file_id: receipt_file?.id ? receipt_file?.id : null,
      });
      const { data } = yield call(() =>
        api.post(ACCOUNTING_EXTRA_CHARGE_API(billingId), {
          orders_extra_charge,
        }),
      );

      // story created instance
      const payload = {
        ...data.orders_extra_charge,
        amount: Number(data.orders_extra_charge.amount),
        receipt_file,
      };
      payloads.push(payload);
    }

    // notify with added instances
    yield put<IAddExtraChargesActionSuccess>({
      type: ADD_EXTRA_CHARGES_SUCCESS,
      items: payloads,
    });
    if (callback) callback(payloads);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IAddExtraChargesActionFail>({
        type: ADD_EXTRA_CHARGES_FAIL,
        payload: e,
      });
  }
}

function* updateExtraChargeSaga({
  payload: { item, order_id, callback },
}: ReturnType<typeof updateExtraChargeAction>) {
  try {
    let receipt_file = item.receipt_file || undefined;
    if (receipt_file?.isLocal) {
      // upload new receipt file
      const formData = fileToFormData(
        receipt_file.file,
        'orders_receipt_file[file]',
      );
      if (formData) {
        formData.append(`orders_receipt_file[amount]`, item.amount.toString());
        const { data } = yield call(() =>
          api.post(ACCOUNTING_EXTRA_CHARGE_FILE_API(order_id), formData),
        );
        receipt_file = data.receipt_file;
      }
    }

    // update extra charge
    const {
      id,
      amount,
      payer,
      charger,
      charge_type,
      payment_code,
      payer_driver_id,
      date,
    } = item;

    const orders_extra_charge: Partial<IOrderExtraCharges> = reject(isNil)({
      id,
      order_id,
      amount,
      payer,
      charger,
      charge_type,
      payment_code,
      payer_driver_id,
      date,
      receipt_file_id: receipt_file?.id ? receipt_file?.id : null,
    });
    const { data } = yield call(() =>
      api.patch(ACCOUNTING_EXTRA_CHARGE_API(order_id, item.id), {
        orders_extra_charge,
      }),
    );

    // delete previous receipt file
    if (item.receipt_file_id && item.receipt_file_id !== receipt_file?.id) {
      yield call(() =>
        api.delete(
          ACCOUNTING_EXTRA_CHARGE_FILE_API(order_id, item.receipt_file_id),
        ),
      );
    }

    // notify with updated instance
    const payload = {
      ...data.orders_extra_charge,
      amount: Number(data.orders_extra_charge.amount),
      receipt_file: receipt_file || [],
    };
    yield put<IUpdateExtraChargeActionSuccess>({
      type: UPDATE_EXTRA_CHARGE_SUCCESS,
      payload,
    });
    if (callback) callback(payload);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IUpdateExtraChargeActionFail>({
        type: UPDATE_EXTRA_CHARGE_FAIL,
        payload: e,
      });
  }
}

function* deleteExtraChargeSaga({
  payload: { item, order_id, callback },
}: ReturnType<typeof deleteExtraChargeAction>) {
  try {
    // delete extra charge
    yield call(() =>
      api.delete(ACCOUNTING_EXTRA_CHARGE_API(order_id, item.id)),
    );

    // delete receipt file
    if (item.receipt_file_id) {
      yield call(() =>
        api.delete(
          ACCOUNTING_EXTRA_CHARGE_FILE_API(order_id, item.receipt_file_id),
        ),
      );
    }

    // notify with deleted instance
    yield put<IDeleteExtraChargeActionSuccess>({
      type: DELETE_EXTRA_CHARGE_SUCCESS,
      payload: { ...item, order_id },
    });
    if (callback) callback(item);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IDeleteExtraChargeActionFail>({
        type: DELETE_EXTRA_CHARGE_FAIL,
        payload: e,
      });
  }
}

function* addProofOfDeliveriesSaga({
  payload: { photos, callback },
}: ReturnType<typeof addProofOfDeliveriesAction>) {
  try {
    const payloads: ProofOfDeliveryPhoto[] = [];
    for (const photo of photos)
      if (photo?.isLocal) {
        const { file, order_id, address_id } = photo;
        // upload new proof file
        const formData = fileToFormData(
          file,
          'orders_addresses_files_proof_photo[file]',
        );

        const { data } = yield call(() =>
          api.post(
            ACCOUNTING_PROOF_OF_DELIVERY_API(order_id, address_id),
            formData,
          ),
        );
        payloads.push({ ...data.file, order_id, address_id });
      }

    // notify with added instances
    yield put<IAddProofOfDeliveriesActionSuccess>({
      type: ADD_PROOF_OF_DELIVERIES_SUCCESS,
      photos: payloads,
    });
    if (callback) callback(payloads);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IAddProofOfDeliveriesActionFail>({
        type: ADD_PROOF_OF_DELIVERIES_FAIL,
        payload: e,
      });
  }
}

function* deleteProofOfDeliverySaga({
  payload: { photo, callback },
}: ReturnType<typeof deleteProofOfDeliveryAction>) {
  try {
    const { isLocal, id, order_id, address_id } = photo;
    if (!isLocal && id) {
      yield call(() =>
        api.delete(ACCOUNTING_PROOF_OF_DELIVERY_API(order_id, address_id, id)),
      );
    }

    // notify with deleted instance
    yield put<IDeleteProofOfDeliveryActionSuccess>({
      type: DELETE_PROOF_OF_DELIVERY_SUCCESS,
      payload: photo,
    });
    if (callback) callback(photo);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IDeleteProofOfDeliveryActionFail>({
        type: DELETE_PROOF_OF_DELIVERY_FAIL,
        payload: e,
      });
  }
}

function* regenerateBill({
  payload: { order_id, callback },
}: ReturnType<typeof regenerateBillAction>) {
  try {
    const {
      data: { invoice },
    } = yield call(() =>
      api.post(ACCOUNTING_BILLINGS_REGENERATE_BILL_API(order_id)),
    );

    const payload: IRegenerateBillActionSuccess['payload'] = {
      order_id,
      invoice,
    };

    // wait 10 attempts for PDF file generating
    let waitCounter = 10;
    while (!payload.merge_pdf_link && waitCounter) {
      yield delay(500);
      const { data } = yield call(() =>
        api.get(ACCOUNTING_BILLINGS_MERGE_PDF_LINK_API(order_id)),
      );
      const { file_task_status, merge_pdf_link, order } = data;
      payload.merge_pdf_link = merge_pdf_link;
      payload.invoice_date = order?.invoice_date;
      waitCounter -= 1;
      if (file_task_status === 'completed' || file_task_status === 'fail')
        break;
    }

    yield put<IRegenerateBillActionSuccess>({
      type: REGENERATE_BILL_SUCCESS,
      payload,
    });
    if (callback) callback(payload);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IRegenerateBillActionFail>({
        type: REGENERATE_BILL_FAIL,
        payload: e,
      });
  }
}

function* brokerDeductionSaga({
  item,
  successType,
  callback,
}: ReturnType<typeof createBrokerDeductionAction>) {
  try {
    let result = item;
    if (successType === CREATE_BROKER_DEDUCTION_SUCCESS) {
      const { data } = yield call(() =>
        api.post(ACCOUNTING_BILLINGS_BROKER_DEDUCTION_API(), item),
      );
      result = data;
    }
    if (successType === UPDATE_BROKER_DEDUCTION_SUCCESS) {
      const { data } = yield call(() =>
        api.patch(ACCOUNTING_BILLINGS_BROKER_DEDUCTION_API(item.id), item),
      );
      result = data;
    } else if (successType === DELETE_BROKER_DEDUCTION_SUCCESS) {
      yield call(() =>
        api.delete(ACCOUNTING_BILLINGS_BROKER_DEDUCTION_API(item.id)),
      );
    }
    yield put<IBrokerDeductionActionSuccess>({
      type: successType,
      item: result,
    });
    if (callback) callback(result);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IBrokerDeductionActionFail>({
        type: BROKER_DEDUCTION_FAIL,
        payload: e,
      });
  }
}

function* getBillingsAsExcelDocSaga({
  items,
  callback,
}: ReturnType<typeof getBillingsAsExcelDocAction>) {
  try {
    const ids = items.map(({ id }) => id);
    const { headers, data } = yield call(() =>
      api.get(ACCOUNTING_BILLINGS_GET_EXCEL_FILE_API, {
        responseType: 'blob',
        params: { ids },
      }),
    );

    const contentDisposition: string =
      headers && headers['content-disposition'];
    const fileName = contentDisposition
      ? contentDisposition
          .split('filename=')[1]
          .split(';')[0]
          .replace(/['"]/g, '')
      : 'billings.xlsx';
    const file = new File([data], fileName);

    yield put<IGetBillingsAsExcelActionSuccess>({
      type: GET_BILLING_ITEMS_AS_EXCEL_SUCCESS,
      file,
    });
    if (callback) callback(file);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetBillingsAsExcelActionFail>({
        type: GET_BILLING_ITEMS_AS_EXCEL_FAIL,
        payload: e,
      });
  }
}

export default function* billingSagas() {
  yield takeLatest(GET_BILLING_ITEMS, getBillingItemsSaga);
  yield takeLatest(PATCH_BILLING_ITEMS, patchBillingItemsSaga);
  yield takeLatest(CREATE_ORDER_ZIP, createOrderZipSaga);
  yield takeLatest(GET_ORDER_ZIP, getOrderZipSaga);
  yield takeLatest(ADD_EXTRA_CHARGES, addExtraChargesSaga);
  yield takeLatest(UPDATE_EXTRA_CHARGE, updateExtraChargeSaga);
  yield takeLatest(DELETE_EXTRA_CHARGE, deleteExtraChargeSaga);
  yield takeLatest(ADD_PROOF_OF_DELIVERIES, addProofOfDeliveriesSaga);
  yield takeLatest(DELETE_PROOF_OF_DELIVERY, deleteProofOfDeliverySaga);
  yield takeLatest(REGENERATE_BILL, regenerateBill);
  yield takeLatest(BROKER_DEDUCTION_INVOKE, brokerDeductionSaga);
  yield takeLatest(GET_BILLING_ITEMS_AS_EXCEL, getBillingsAsExcelDocSaga);
}
