import { AxiosError } from 'axios';

import { IPagination } from 'components/_common/Navigation/Pagination/_redux/paginationTypes';
import { IFacility } from 'components/Dispatch/TabFacilities/_models/facilityModel';
import { IFile, ILogout } from 'redux/_common/models';
import { IDefaultReducer } from 'utils/models';

export interface IFacilitiesReducer extends IDefaultReducer {
  list: IFacility[];
  files: IFile[];
  currentFacility: IFacility | null;
}

// GET FACILITY
export const GET_FACILITY = '[@@Facilities] GET_FACILITY';
export const GET_FACILITY_SUCCESS = '[@@Facilities] GET_FACILITY_SUCCESS';
export const GET_FACILITY_FAIL = '[@@Facilities] GET_FACILITY_FAIL';

export interface IGetFacilityAction {
  type: typeof GET_FACILITY;
}
export interface IGetFacilityActionSuccess {
  type: typeof GET_FACILITY_SUCCESS;
  payload: IFacility;
}
export interface IGetFacilityActionFail {
  type: typeof GET_FACILITY_FAIL;
  payload: AxiosError;
}

// GET FACILITIES
export const GET_FACILITIES = '[@@Facilities] GET_FACILITIES';
export const GET_FACILITIES_SUCCESS = '[@@Facilities] GET_FACILITIES_SUCCESS';
export const GET_FACILITIES_FAIL = '[@@Facilities] GET_FACILITIES_FAIL';

export interface IGetFacilitiesAction {
  type: typeof GET_FACILITIES;
}
export interface IGetFacilitiesActionSuccess {
  type: typeof GET_FACILITIES_SUCCESS;
  payload: { facilities: IFacility[]; pagination: IPagination };
}
export interface IGetFacilitiesActionFail {
  type: typeof GET_FACILITIES_FAIL;
  payload: AxiosError;
}

// SEARCH FACILITIES
export const SEARCH_FACILITIES = '[@@Facilities] SEARCH_FACILITIES';

export interface ISearchFacilitiesAction {
  type: typeof SEARCH_FACILITIES;
}
export interface ISearchFacilitiesActionFail {
  type: typeof GET_FACILITIES_FAIL;
  payload: AxiosError;
}

// CREATE FACILITY
export const CREATE_FACILITY = '[@@Facilities] CREATE_FACILITY';
export const CREATE_FACILITY_SUCCESS = '[@@Facilities] CREATE_FACILITY_SUCCESS';
export const CREATE_FACILITY_FAIL = '[@@Facilities] CREATE_FACILITY_FAIL';

export interface ICreateFacilityAction {
  type: typeof CREATE_FACILITY;
}
export interface ICreateFacilityActionSuccess {
  type: typeof CREATE_FACILITY_SUCCESS;
}
export interface ICreateFacilityActionFail {
  type: typeof CREATE_FACILITY_FAIL;
  payload: AxiosError;
}

// UPDATE FACILITY
export const UPDATE_FACILITY = '[@@Facilities] UPDATE_FACILITY';
export const UPDATE_FACILITY_SUCCESS = '[@@Facilities] UPDATE_FACILITY_SUCCESS';
export const UPDATE_FACILITY_FAIL = '[@@Facilities] UPDATE_FACILITY_FAIL';

export interface IUpdateFacilityAction {
  type: typeof UPDATE_FACILITY;
}
export interface IUpdateFacilityActionSuccess {
  type: typeof UPDATE_FACILITY_SUCCESS;
  payload: IFacility;
}
export interface IUpdateFacilityActionFail {
  type: typeof UPDATE_FACILITY_FAIL;
  payload: AxiosError;
}

// DELETE FACILITY
export const DELETE_FACILITY = '[@@Facilities] DELETE_FACILITY';
export const DELETE_FACILITY_SUCCESS = '[@@Facilities] DELETE_FACILITY_SUCCESS';
export const DELETE_FACILITY_FAIL = '[@@Facilities] DELETE_FACILITY_FAIL';

export interface IDeleteFacilityAction {
  type: typeof DELETE_FACILITY;
}
export interface IDeleteFacilityActionSuccess {
  type: typeof DELETE_FACILITY_SUCCESS;
  payload: number;
}
export interface IDeleteFacilityActionFail {
  type: typeof DELETE_FACILITY_FAIL;
  payload: AxiosError;
}

// CLEAR CURRENT FACILITY
export const CLEAR_CURRENT_FACILITY = '[@@Facilities] CLEAR_CURRENT_FACILITY';
export interface IClearCurrentFacility {
  type: typeof CLEAR_CURRENT_FACILITY;
}

// UNMOUNT BROKER FORM
export const UNMOUNT_FACILITIES_FORM = '[@@Facilities] UNMOUNT_FACILITIES_FORM';

export type facilitiesActionsType =
  | IGetFacilityAction
  | IGetFacilityActionSuccess
  | IGetFacilityActionFail
  | IGetFacilitiesAction
  | IGetFacilitiesActionSuccess
  | IGetFacilitiesActionFail
  | ICreateFacilityAction
  | ICreateFacilityActionSuccess
  | ICreateFacilityActionFail
  | IUpdateFacilityAction
  | IUpdateFacilityActionSuccess
  | IUpdateFacilityActionFail
  | IDeleteFacilityAction
  | IDeleteFacilityActionSuccess
  | IDeleteFacilityActionFail
  | IClearCurrentFacility
  | ISearchFacilitiesAction
  | ISearchFacilitiesActionFail
  | ILogout;
