import { FC } from 'react';

import { useSelector } from 'react-redux';

import { userThemeSelector } from 'redux/userSettings/userSettingsSelectors';

interface IOwnProps {
  className?: string;
}

const IconFilter: FC<IOwnProps> = ({ className }) => {
  const theme = useSelector(userThemeSelector);

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g stroke={theme === 'dark' ? '#fff' : '#2D2F36'}>
        <path d="M2 4H14" strokeWidth="2" strokeLinecap="round" />
        <path d="M4 8H12" strokeWidth="2" strokeLinecap="round" />
        <path d="M6 12H10" strokeWidth="2" strokeLinecap="round" />
      </g>
    </svg>
  );
};

export default IconFilter;
