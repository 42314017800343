import { FC } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const IconReply: FC<IOwnProps> = ({ className, fillColor = '#86898E' }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 12V8C15 6.89543 14.1046 6 13 6H3"
        stroke={fillColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M6 2L2 6L6 10"
        stroke={fillColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconReply;
