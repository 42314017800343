import { FC } from 'react';

interface IOwnProps {
  className?: string;
}

const IconMessageSentAndRead: FC<IOwnProps> = ({ className }) => {
  return (
    <svg
      width="20"
      height="11"
      viewBox="0 0 20 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1 5L5 9L13 1"
        stroke="#2C6AF6"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10 8L11 9L19 1"
        stroke="#2C6AF6"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconMessageSentAndRead;
