import { call, put, takeLeading, takeLatest } from 'redux-saga/effects';

import { FEEDBACK_API_PREFIX } from 'constants/api';
import { fileToFormData } from 'utils/converters';
import api from 'utils/requests';

import {
  sendFeedbackAction,
  uploadFeedbackFilesAction,
  deleteFeedbackFilesAction,
} from './feedbackActions';
import {
  SEND_FEEDBACK,
  SEND_FEEDBACK_SUCCESS,
  SEND_FEEDBACK_FAIL,
  UPLOAD_FEEDBACK_FILES,
  UPLOAD_FEEDBACK_FILES_FAIL,
  UPLOAD_FEEDBACK_FILES_SUCCESS,
  DELETE_FEEDBACK_FILES,
  DELETE_FEEDBACK_FILES_SUCCESS,
  DELETE_FEEDBACK_FILES_FAIL,
  ISendFeedbackActionSuccess,
  ISendFeedbackActionFail,
} from './feedbackTypes';

function* sendFeedbackSaga({
  payload: { data, callback },
}: ReturnType<typeof sendFeedbackAction>) {
  try {
    yield call(() =>
      api.post(FEEDBACK_API_PREFIX, { employee_feedback: data }),
    );
    yield put<ISendFeedbackActionSuccess>({
      type: SEND_FEEDBACK_SUCCESS,
    });
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<ISendFeedbackActionFail>({
        type: SEND_FEEDBACK_FAIL,
        payload: e,
      });
  }
}

function* uploadFeedbackFilesSaga({
  payload: { files, itemId: feedbackId },
}: ReturnType<typeof uploadFeedbackFilesAction>) {
  const url = feedbackId
    ? `${FEEDBACK_API_PREFIX}/${feedbackId}/files`
    : `${FEEDBACK_API_PREFIX}/files`;
  for (const fileObj of files) {
    yield call(function* processFiles() {
      try {
        const { data } = yield call(() =>
          api.post(url, fileToFormData(fileObj.file, `feedbacks_file[file]`), {
            headers: { 'Content-Type': 'multipart/form-data' },
          }),
        );
        yield put({ type: UPLOAD_FEEDBACK_FILES_SUCCESS, payload: data.file });
      } catch (e) {
        if (api.isAxiosError(e))
          yield put({ type: UPLOAD_FEEDBACK_FILES_FAIL, payload: e });
      }
    });
  }
}

function* deleteFeedbackFileSaga({
  payload: { fileIds },
}: ReturnType<typeof deleteFeedbackFilesAction>) {
  const url = `${FEEDBACK_API_PREFIX}/files/${fileIds}`;
  try {
    yield call(() => api.delete(url));
    yield put({ type: DELETE_FEEDBACK_FILES_SUCCESS, payload: fileIds });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({ type: DELETE_FEEDBACK_FILES_FAIL, payload: e });
  }
}

export default function* feedbackSaga() {
  yield takeLeading(SEND_FEEDBACK, sendFeedbackSaga);
  yield takeLatest(UPLOAD_FEEDBACK_FILES, uploadFeedbackFilesSaga);
  yield takeLatest(DELETE_FEEDBACK_FILES, deleteFeedbackFileSaga);
}
