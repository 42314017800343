import { FC, memo } from 'react';

import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { required } from '../../../../utils/validation';
import Button from '../../../_common/FormElements/Button/Button';
import FormField from '../../../_common/FormElements/FormField/FormField';

import styles from './SupportCenterModal.module.scss';

const themeOptions = {
  'Technical  issues and bugs': {
    id: 'Technical  issues and bugs',
    name: 'Technical  issues and bugs',
  },
  'Billing, payments, and pricing': {
    id: 'Billing, payments, and pricing',
    name: 'Billing, payments, and pricing',
  },
  'Import or export support': {
    id: 'Import or export support',
    name: 'Import or export support',
  },
  'Personal data': { id: 'Personal data', name: 'Personal data' },
  Other: { id: 'Other', name: 'Other' },
};

type OwnProps = {
  submitQuestion: (values: { themes: string; message: string }) => void;
};

type Props = OwnProps &
  InjectedFormProps<{ themes: string; message: string }, OwnProps>;

const SupportForm: FC<Props> = ({ handleSubmit, submitQuestion }) => {
  return (
    <form
      className={styles.form}
      autoComplete="off"
      onSubmit={handleSubmit(submitQuestion)}
    >
      <div className={styles.fields}>
        <Field
          id="themes_id"
          name="themes"
          component={FormField}
          placeholder="Themes"
          labelText="Themes"
          fieldType="select"
          options={themeOptions}
          validate={[required]}
          required
          fullWidth
        />
        <Field
          id="message_id"
          name="message"
          component={FormField}
          placeholder="Message"
          labelText="Message"
          fieldType="textarea"
          fullWidth
        />
      </div>
      <Button type="submit">Submit theme</Button>
    </form>
  );
};

export default reduxForm<{ themes: string; message: string }, OwnProps>({
  form: 'supportForm',
  enableReinitialize: true,
})(memo(SupportForm));
