import {
  CircularProgress,
  LinearProgress,
  Radio as MUIRadio,
  RadioProps,
  FormControlLabel,
  Tooltip,
  Checkbox as MUICheckbox,
  CheckboxProps,
  Slider,
  Switch,
  Popover as MUIPopover,
  PopoverProps,
  Dialog as MUIDialog,
  DialogProps as MUIDialogProps,
  ToggleButton as MUIToggleButton,
  lighten,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import {
  IconChecked,
  IconUnchecked,
  IconIndeterminate,
} from 'assets/icons/components/IconCheckboxes';
import {
  IconRadioChecked,
  IconRadioUnchecked,
} from 'assets/icons/components/IconRadioStates';

export const WhiteCircularProgress = withStyles({
  root: {
    color: 'white',
  },
})(CircularProgress);

export const BlueCircularProgress = withStyles({
  root: {
    color: 'var(--activeText)',
  },
})(CircularProgress);

export const BlueLinearProgress = withStyles({
  root: {
    borderRadius: 2,
    height: 8,
    width: '100%',
  },
  colorPrimary: {
    backgroundColor: lighten('#2c6af6', 0.9),
  },
  barColorPrimary: {
    backgroundColor: 'var(--primaryActiveBg)',
  },
})(LinearProgress);

const CustomRadio = (props: RadioProps) => (
  <MUIRadio
    icon={<IconRadioUnchecked />}
    checkedIcon={<IconRadioChecked />}
    {...props}
  />
);

export const BlueRadioButton = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    color: 'var(--dashedBorder)',
    '&$checked': {
      color: 'var(--activeText)',
    },
  },
  checked: {
    color: 'var(--activeText)',
  },
  disabled: {
    color: 'var(--secondaryText)!important',
  },
})(CustomRadio);

const CustomCheckbox = (props: CheckboxProps) => (
  <MUICheckbox
    {...props}
    icon={<IconUnchecked />}
    checkedIcon={<IconChecked />}
    indeterminateIcon={<IconIndeterminate />}
  />
);

export const BlueCheckbox = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    color: 'var(--secondaryText)',
    '&$checked': {
      color: 'var(--activeText)',
    },
    '&$disabled': {
      color: 'var(--secondaryText)',
    },
  },
  checked: {},
  disabled: {},
})(CustomCheckbox);

export const Checkbox = withStyles({
  root: {
    color: 'var(--secondaryText)',
    padding: 0,
  },
  checked: { color: 'var(--activeText)' },
  disabled: { color: 'var(--secondaryText)' },
})(CustomCheckbox);

export const BlueFormControlLabel = withStyles({
  label: {
    color: 'var(--primaryText)',
    fontWeight: 500,
  },
  disabled: {
    color: 'var(--secondaryText)!important',
  },
})(FormControlLabel);

export const BlackTooltip = withStyles({
  tooltip: (props: {
    maxWidth?: number | string;
    textColor?: string;
    backgroundColor?: string;
  }) => ({
    color: props.textColor,
    backgroundColor: props.backgroundColor || 'var(--primaryTooltipBg)',
    maxwidth: props.maxWidth || 180,
    fontSize: 12,
    textAlign: 'left',
    fontWeight: 'normal',
  }),
  arrow: (props: { backgroundColor?: string }) => ({
    color: props.backgroundColor || 'var(--primaryTooltipBg)',
  }),
})(Tooltip);

export const RateSlider = withStyles({
  root: {},
  thumb: {
    display: 'block',
    position: 'relative',
    height: 48,
    width: 66,
    borderRadius: 49,
    backgroundColor: 'var(--primaryActiveBg)',
    color: 'white',
    top: -25,
    marginLeft: -33,
    zIndex: 2,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  track: {
    paddingLeft: 30,
    height: 30,
    borderRadius: 49,
    backgroundColor: 'var(--primaryInputBg)',
  },
  rail: {
    paddingRight: 30,
    height: 30,
    borderRadius: 49,
    backgroundColor: 'var(--primaryInputBg)',
    opacity: 1,
  },
  active: {},
})(Slider);

export const RangeSlider = withStyles({
  root: {
    color: 'var(--activeBorder)',
    height: 2,
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: 'var(--secondaryBg)',
    border: '2px solid var(--activeBorder)',
    marginTop: -6,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
})(Slider);

export const BlueSwitch = withStyles({
  root: {
    width: 40,
    height: 24,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 4,
    color: 'var(--primarySwitcherBg)',
    '&$checked': {
      transform: 'translateX(16px)',
      color: 'var(--secondarySwitcherBg)',
      '& + $track': {
        opacity: 1,
        backgroundColor: 'var(--secondarySwitcherTrackBg)',
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: 'none',
  },
  track: {
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: 'var(--primarySwitcherTrackBg)',
  },
  checked: {},
})(Switch);

export const BlueSwitchInverted = withStyles({
  root: {
    width: 40,
    height: 24,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 4,
    color: 'var(--secondarySwitcherTrackBg)',
    '&$checked': {
      transform: 'translateX(16px)',
      color: 'var(--secondarySwitcherTrackBg)',
      '& + $track': {
        opacity: 1,
        backgroundColor: 'var(--secondarySwitcherBg)',
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: 'none',
  },
  track: {
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: 'var(--secondarySwitcherBg)',
  },
  checked: {},
})(Switch);

const CustomPopover = (props: PopoverProps) => (
  <MUIPopover
    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    {...props}
  />
);
export const Popover = withStyles({
  paper: {
    color: 'var(--primaryText)',
    backgroundColor: 'var(--secondaryBg)',
    boxShadow: '-8px 8px 12px 6px var(--shadow)',
    borderRadius: 0,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
})(CustomPopover);

export type DialogProps = Omit<MUIDialogProps, 'open'> & {
  closeOnOutsideClick?: boolean;
  open?: boolean;
};

const CustomDialog = (props: DialogProps) => {
  const { open = true, closeOnOutsideClick, onClose } = props;
  return (
    <MUIDialog
      {...props}
      open={open}
      maxWidth={false}
      BackdropProps={{
        style: { backgroundColor: 'var(--primaryTooltipBg)', opacity: 0.7 },
      }}
      onClose={(event, reason) => {
        if (closeOnOutsideClick || reason !== 'backdropClick') {
          if (onClose) onClose(event, reason);
        }
      }}
    />
  );
};
export const Dialog = withStyles({
  root: {
    zIndex: 99,
  },
  paper: {
    boxShadow: '-8px 8px 12px 6px var(--shadow)',
    backgroundColor: 'var(--secondaryBg)',
    fontWeight: 500,
    fontSize: '16px',
    color: 'var(--primaryText)',
    margin: 0,
    overflow: 'hidden',
  },
})(CustomDialog);

export const ToggleButton = withStyles({
  root: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    color: 'var(--secondaryText)',
    letterSpacing: 'normal',
    textTransform: 'none',
    padding: 0,
    margin: 2,
    flexGrow: 1,
    border: 'none',
    '&$selected': {
      color: 'var(--primaryText)',
      backgroundColor: 'var(--secondaryBg)',
    },
  },
  selected: {},
})(MUIToggleButton);
