export const CHAT_CHANNEL_ID = '{"channel":"ChatroomsChannel"}';

export const INSPECTION_UPLOAD_CHANNEL_ID =
  '{"channel":"InspectionUploadingFilesChannel"}';

export type InspectionUploadChannelMessage = {
  inspection_id: number;
  progress: number;
};

export const NOTIFICATIONS_CHANNEL_ID = '{"channel":"NotificationsChannel"}';

export type MessageTextParam = {
  value: string;
  color?: string;
  is_bold?: boolean;
  is_underline?: boolean;
  url?: string;
};

export type NotificationsChannelItem = {
  id: number;
  type: 'employee_notification' | 'task' | 'confirm_subscription';
  text: string;
  code: string;
  text_params?: {
    [param: string]: string | MessageTextParam;
  };
  created_at: string; // timestamp
};

export type NotificationsChannelMessage = NotificationsChannelItem & {
  type: 'employee_notification';
  is_read?: boolean;
};

export function isMessage(
  item: NotificationsChannelItem,
): item is NotificationsChannelMessage {
  return item?.type === 'employee_notification';
}

export type NotificationsChannelTask = NotificationsChannelItem & {
  type: 'task';
  expired_at: string;
  owner_type: 'system' | 'personal';
  is_done?: boolean;
};

export function isTask(
  item: NotificationsChannelItem,
): item is NotificationsChannelTask {
  return item?.type === 'task';
}
