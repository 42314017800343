import moment, { unitOfTime } from 'moment';
import { isNil } from 'ramda';

import { padNumber } from 'utils/helpers';

export const UTC_TIME_OFFSETS = Object.freeze([
  '-12:00',
  '-11:00',
  '-10:00',
  '-09:30',
  '-09:00',
  '-08:00',
  '-07:00',
  '-06:00',
  '-05:00',
  '-04:00',
  '-03:30',
  '-03:00',
  '-02:00',
  '-01:00',
  '+00:00',
  '+01:00',
  '+02:00',
  '+03:00',
  '+03:30',
  '+04:00',
  '+04:30',
  '+05:00',
  '+05:30',
  '+05:45',
  '+06:00',
  '+06:30',
  '+07:00',
  '+08:00',
  '+08:45',
  '+09:00',
  '+09:30',
  '+10:00',
  '+10:30',
  '+11:00',
  '+12:00',
  '+12:45',
  '+13:00',
  '+14:00',
]);

export const getCurrentBias = (): string => {
  const currOffset = new Date().getTimezoneOffset(); // minutes
  const hours = Math.floor(Math.abs(currOffset) / 60);
  const minutes = currOffset % 60;
  return `${currOffset < 0 ? '+' : '-'}${padNumber(hours, 2)}:${padNumber(
    minutes,
    2,
  )}`;
};

export default class TimeRange {
  readonly from: moment.Moment;

  readonly to: moment.Moment;

  static readonly MIN: moment.Moment = moment(0);

  static readonly MAX: moment.Moment = moment({ year: 2100 });

  constructor(from: moment.MomentInput, to?: moment.MomentInput) {
    this.from = moment(from);
    this.to = isNil(to) ? this.from : moment(to);
  }

  private static getNextPoint(
    point: moment.Moment,
    step: unitOfTime.Diff,
  ): moment.Moment {
    return moment(point).add(1, step);
  }

  private containsPoint(point: moment.Moment): boolean {
    return point.isSameOrAfter(this.from) && point.isSameOrBefore(this.to);
  }

  duration(unit: unitOfTime.Diff = 'm'): number | undefined {
    if (!this.isNull()) return this.to.diff(this.from, unit);
    return undefined;
  }

  isNull(): boolean {
    return this.to.isBefore(this.from);
  }

  isEqual(other: TimeRange): boolean {
    if (this.isNull() && other.isNull()) return true;
    if (this.isNull() || other.isNull()) return false;
    return this.from.isSame(other.from) && this.to.isSame(other.to);
  }

  isLess(other: TimeRange): boolean {
    if (this.isNull() || other.isNull()) return false;
    return this.from.isSame(other.from)
      ? this.to.isBefore(other.to)
      : this.from.isBefore(other.from);
  }

  isGreater(other: TimeRange): boolean {
    if (this.isNull() || other.isNull()) return false;
    return this.to.isSame(other.to)
      ? this.from.isAfter(other.from)
      : this.to.isAfter(other.to);
  }

  compare(other: TimeRange): -1 | 0 | 1 | undefined {
    if (this.isLess(other)) return -1;
    if (this.isGreater(other)) return 1;
    if (this.isEqual(other)) return 0;
    return undefined;
  }

  isBefore(other: TimeRange): boolean {
    if (this.isNull() || other.isNull()) return false;
    return this.to.isBefore(other.from);
  }

  isAfter(other: TimeRange): boolean {
    if (this.isNull() || other.isNull()) return false;
    return this.from.isAfter(other.to);
  }

  isAdjacent(other: TimeRange, step: unitOfTime.Diff = 'm'): boolean {
    if (this.isNull() || other.isNull()) return false;
    return (
      (this.to.isBefore(other.from) &&
        TimeRange.getNextPoint(this.to, step).isSameOrAfter(other.from)) ||
      (other.to.isBefore(this.from) &&
        TimeRange.getNextPoint(other.to, step).isSameOrAfter(this.from))
    );
  }

  contains(other: TimeRange): boolean {
    if (this.isNull() || other.isNull()) return false;
    return (
      this.from.isSameOrBefore(other.from) && this.to.isSameOrAfter(other.to)
    );
  }

  intersects(other: TimeRange): boolean {
    if (this.isNull() || other.isNull()) return false;
    return (
      this.containsPoint(other.from) ||
      this.containsPoint(other.to) ||
      other.containsPoint(this.from)
    );
  }
}

export const getPeriodAgo = (timestamp: moment.MomentInput): string => {
  const now = moment();
  const datetime = moment(timestamp);
  const minutesAgo = Math.abs(datetime.diff(now, 'minutes'));
  if (minutesAgo < 1) return 'Now';
  if (minutesAgo < 60) return `${minutesAgo} min`;
  if (minutesAgo < 1440) return `${Math.floor(minutesAgo / 60)} hrs`;

  let monthsAgo = Math.abs(datetime.diff(now, 'months'));
  if (monthsAgo < 1) return `${Math.floor(minutesAgo / 1440)} d`;
  const yearsAgo = Math.floor(monthsAgo / 12);
  monthsAgo %= 12;
  if (yearsAgo < 1) return `${monthsAgo} m`;
  return `${yearsAgo} yr ${monthsAgo} m`;
};
