type Props = {
  strokeColor?: string;
};

const IconRefresh = ({ strokeColor = 'var(--primaryText)' }: Props) => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke={strokeColor} strokeLinecap="round" strokeWidth="2">
      <path d="m14.3436 10.9596c-.6546 1.403-1.7559 2.5495-3.1315 3.2599-1.37559.7105-2.94791.9448-4.47089.6664-1.52299-.2784-2.91071-1.0539-3.946-2.2051-1.03528-1.1511-1.65971-2.6131-1.77558-4.15694-.115866-1.54388.28338-3.08262 1.13525-4.37542s2.1083-2.26671 3.57267-2.76931c1.46438-.502614 3.05406-.505566 4.52025-.0084 1.4663.49717 2.7263 1.4664 3.583 2.75603" />
      <path d="m15 1v4h-4" />
    </g>
  </svg>
);

export default IconRefresh;
