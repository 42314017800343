import { call, put, select, takeLatest } from 'redux-saga/effects';

import { setPaginationAction } from 'components/_common/Navigation/Pagination/_redux/paginationActions';
import { paginationSelector } from 'components/_common/Navigation/Pagination/_redux/paginationSelectors';
import {
  ACCOUNTING_CREDITS_API,
  ACCOUNTING_DEDUCTIONS_API,
  ACCOUNTING_DRIVER_FUEL_API,
  ACCOUNTING_DRIVER_FUEL_STATISTICS_API,
  ACCOUNTING_FUEL_API,
  DEDUCTIONS_API,
  DRIVER_CREDIT_API,
  DRIVER_DEDUCTION_API,
  SCHED_DEDUCTION_DRIVERS_API,
} from 'constants/api';
import api from 'utils/requests';

import { IStoredAccountEntry } from '../_models/payrollItemsModels';
import {
  addDeductionAction,
  cancelDeductionAction,
  createAccountEntryAction,
  deleteAccountEntryAction,
  deleteDeductionAction,
  editDeductionAction,
  getAccountEntriesAction,
  getAccountFuelAction,
  getDeductionDetailsAction,
  getDeductionPaymentsAction,
  getDeductionPaymentsActionFail,
  getDeductionPaymentsActionSuccess,
  getDeductionsAction,
  getDriverFuelAction,
  getGetSchedDeductionDriversAction,
  setDeductionDetailsAction,
  updateAccountEntryAction,
} from './payrollItemsActions';
import { fuelDriverSelector, fuelSelector } from './payrollItemsSelectors';
import {
  ADD_DEDUCTION,
  ADD_DEDUCTION_FAIL,
  CANCEL_DEDUCTION,
  CANCEL_DEDUCTION_FAIL,
  CREATE_ACCOUNT_ENTRY,
  DELETE_ACCOUNT_ENTRY,
  DELETE_ACCOUNT_ENTRY_FAIL,
  DELETE_ACCOUNT_ENTRY_SUCCESS,
  DELETE_DEDUCTION,
  DELETE_DEDUCTION_FAIL,
  EDIT_DEDUCTION,
  GET_ACCOUNT_ENTRIES,
  GET_ACCOUNT_ENTRIES_FAIL,
  GET_ACCOUNT_ENTRIES_SUCCESS,
  GET_ACCOUNT_FUEL,
  GET_ACCOUNT_FUEL_FAIL,
  GET_ACCOUNT_FUEL_SUCCESS,
  GET_DEDUCTION_DETAILS,
  GET_DEDUCTION_DETAILS_FAIL,
  GET_DEDUCTION_PAYMENTS,
  GET_DEDUCTIONS,
  GET_DEDUCTIONS_FAIL,
  GET_DEDUCTIONS_SUCCESS,
  GET_DRIVER_FUEL,
  GET_DRIVER_FUEL_FAIL,
  GET_DRIVER_FUEL_SUCCESS,
  GET_SCHED_DEDUCTION_DRIVERS,
  GET_SCHED_DEDUCTION_DRIVERS_FAIL,
  GET_SCHED_DEDUCTION_DRIVERS_SUCCESS,
  IAddDeductionActionFail,
  ICancelDeductionActionFail,
  IDeleteAccountEntryActionFail,
  IDeleteAccountEntryActionSuccess,
  IDeleteDeductionActionFail,
  IGetAccountEntriesActionFail,
  IGetAccountEntriesActionSuccess,
  IGetAccountFuelActionFail,
  IGetAccountFuelActionSuccess,
  IGetDeductionDetailsActionFail,
  IGetDeductionDetailsActionSuccess,
  IGetDeductionPaymentsActionSuccess,
  IGetDeductionsActionFail,
  IGetDeductionsActionSuccess,
  IGetDriverFuelActionFail,
  IGetDriverFuelActionSuccess,
  IGetSchedDeductionDriversActionFail,
  IGetSchedDeductionDriversActionSuccess,
  IStoreAccountEntryActionFail,
  IStoreAccountEntryActionSuccess,
  STORE_ACCOUNT_ENTRY_FAIL,
  STORE_ACCOUNT_ENTRY_SUCCESS,
  UPDATE_ACCOUNT_ENTRY,
} from './payrollItemsTypes';

function* getSchedDeductionDriversSaga({
  payload = {},
}: ReturnType<typeof getGetSchedDeductionDriversAction>) {
  try {
    const { usePagination = true, driverName, driverId } = payload;
    const { page, per_page } = yield select(paginationSelector);
    const {
      data: { drivers: list, pagination },
    } = yield call(() =>
      api.get(
        SCHED_DEDUCTION_DRIVERS_API(
          usePagination ? page : undefined,
          usePagination ? per_page : undefined,
          driverName,
          driverId,
        ),
      ),
    );
    yield put<IGetSchedDeductionDriversActionSuccess>({
      type: GET_SCHED_DEDUCTION_DRIVERS_SUCCESS,
      payload: { list, pagination },
    });
    yield put(setPaginationAction(pagination));
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetSchedDeductionDriversActionFail>({
        type: GET_SCHED_DEDUCTION_DRIVERS_FAIL,
        payload: e,
      });
  }
}

function* getDeductionsSaga({
  params,
  query,
}: ReturnType<typeof getDeductionsAction>) {
  try {
    const url = `${ACCOUNTING_DEDUCTIONS_API}${query}`;
    const {
      data: { deductions, pagination },
    } = yield call(() => api.post(url, { filter: params }));
    yield put<IGetDeductionsActionSuccess>({
      type: GET_DEDUCTIONS_SUCCESS,
      payload: {
        list: deductions,
        pagination,
      },
    });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetDeductionsActionFail>({
        type: GET_DEDUCTIONS_FAIL,
        payload: { error: e },
      });
  }
}

function* getDeductionDetailsSaga({
  payload,
}: ReturnType<typeof getDeductionDetailsAction>) {
  try {
    const url = `${DEDUCTIONS_API}/${payload.id}`;
    const {
      data: { deduction },
    } = yield call(() => api.get(url));
    yield put<IGetDeductionDetailsActionSuccess>(
      setDeductionDetailsAction(deduction),
    );
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetDeductionDetailsActionFail>({
        type: GET_DEDUCTION_DETAILS_FAIL,
        payload: { error: e },
      });
  }
}

function* getDeductionPaymentsSaga({
  deductionId,
  params,
}: ReturnType<typeof getDeductionPaymentsAction>) {
  try {
    const url = `v1/accountings/drivers/deductions/${deductionId}/payroll_items`;
    const {
      data: { payroll_items, pagination },
    }: {
      data: IGetDeductionPaymentsActionSuccess['payload'];
    } = yield call(() =>
      api.get(url, {
        params,
      }),
    );
    yield put(getDeductionPaymentsActionSuccess({ payroll_items, pagination }));
  } catch (e) {
    if (api.isAxiosError(e))
      yield put(getDeductionPaymentsActionFail({ error: e }));
  }
}

function* addDeductionsSaga({ params }: ReturnType<typeof addDeductionAction>) {
  try {
    const url = `v1/accountings/drivers/${params.driver_id}/deductions`;
    yield call(() =>
      api.post(url, { accountings_drivers_deduction: { ...params } }),
    );
    params.callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IAddDeductionActionFail>({
        type: ADD_DEDUCTION_FAIL,
        payload: { error: e },
      });
  }
}

function* patchDeductionsSaga({
  params,
}: ReturnType<typeof editDeductionAction>) {
  try {
    const { callback, id, ...rest } = params;

    const url = `v1/accountings/drivers/${rest.driver_id}/deductions/${id}`;
    yield call(() =>
      api.patch(url, { accountings_drivers_deduction: { ...rest } }),
    );
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IAddDeductionActionFail>({
        type: ADD_DEDUCTION_FAIL,
        payload: { error: e },
      });
  }
}

function* deleteDeductionSaga({
  payload: { deductionId, callback },
}: ReturnType<typeof deleteDeductionAction>) {
  try {
    const url = `v1/accountings/drivers/deductions/${deductionId}`;
    yield call(() => api.delete(url));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IDeleteDeductionActionFail>({
        type: DELETE_DEDUCTION_FAIL,
        payload: { error: e },
      });
  }
}

function* cancelDeductionSaga({
  payload: { deductionId, callback },
}: ReturnType<typeof cancelDeductionAction>) {
  try {
    const url = `v1/accountings/drivers/deductions/${deductionId}/cancel`;
    yield call(() => api.patch(url));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errorObj = e?.response?.data as any;
      yield put<ICancelDeductionActionFail>({
        type: CANCEL_DEDUCTION_FAIL,
        payload: {
          error: { ...e, message: errorObj.error.message || 'Error' },
        },
      });
    }
  }
}

function* getAccountEntriesSaga({
  entryType,
  params,
  query,
}: ReturnType<typeof getAccountEntriesAction>) {
  try {
    // TODO: Viktor - check this, entryType === 'deduction' not transferred here, need to clear code
    const url =
      entryType === 'deduction'
        ? `${ACCOUNTING_DEDUCTIONS_API}${query}`
        : `${ACCOUNTING_CREDITS_API}${query}`;
    const {
      data: { deductions, credits, pagination },
    } = yield call(() => api.post(url, { filter: params }));
    yield put<IGetAccountEntriesActionSuccess>({
      type: GET_ACCOUNT_ENTRIES_SUCCESS,
      payload: {
        entryType,
        list: entryType === 'deduction' ? deductions : credits,
        pagination,
      },
    });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetAccountEntriesActionFail>({
        type: GET_ACCOUNT_ENTRIES_FAIL,
        payload: { entryType, error: e },
      });
  }
}

function* createAccountEntrySaga({
  payload: { driverId, entry, entryType, callback },
}: ReturnType<typeof createAccountEntryAction>) {
  try {
    let result: IStoredAccountEntry | null = null;

    if (entryType === 'credit') {
      const accountings_drivers_credit = { ...entry };
      const { data } = yield call(() =>
        api.post(DRIVER_CREDIT_API(driverId), { accountings_drivers_credit }),
      );
      result = data.accountings_drivers_credit;
    } else if (entryType === 'deduction') {
      const accountings_drivers_deduction = { ...entry };
      const { data } = yield call(() =>
        api.post(DRIVER_DEDUCTION_API(driverId), {
          accountings_drivers_deduction,
        }),
      );
      result = data.accountings_drivers_deduction;
    }

    if (result) {
      yield put<IStoreAccountEntryActionSuccess>({
        type: STORE_ACCOUNT_ENTRY_SUCCESS,
        payload: { entry: result, entryType },
      });
      if (callback) callback({ entry: result, entryType });
    }
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IStoreAccountEntryActionFail>({
        type: STORE_ACCOUNT_ENTRY_FAIL,
        payload: { entryType, error: e },
      });
  }
}

function* updateAccountEntrySaga({
  payload: { entry, entryType, callback },
}: ReturnType<typeof updateAccountEntryAction>) {
  try {
    if (entryType === 'credit') {
      const { id, driver_id, ...accountings_drivers_credit } = entry;
      yield call(() =>
        api.patch(`${DRIVER_CREDIT_API(driver_id)}/${id}`, {
          accountings_drivers_credit,
        }),
      );
    } else if (entryType === 'deduction') {
      const { id, driver_id, ...accountings_drivers_deduction } = entry;
      yield call(() =>
        api.patch(`${DRIVER_DEDUCTION_API(driver_id)}/${id}`, {
          accountings_drivers_deduction,
        }),
      );
    }

    yield put<IStoreAccountEntryActionSuccess>({
      type: STORE_ACCOUNT_ENTRY_SUCCESS,
      payload: { entry, entryType },
    });
    if (callback) callback({ entry, entryType });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IStoreAccountEntryActionFail>({
        type: STORE_ACCOUNT_ENTRY_FAIL,
        payload: { entryType, error: e },
      });
  }
}

function* deleteAccountEntrySaga({
  payload: { driverId, entryId, entryType, callback },
}: ReturnType<typeof deleteAccountEntryAction>) {
  try {
    const url =
      entryType === 'credit'
        ? DRIVER_CREDIT_API(driverId)
        : DRIVER_DEDUCTION_API(driverId);
    yield call(() => api.delete(`${url}/${entryId}`));

    const result = { driverId, entryId, entryType };
    yield put<IDeleteAccountEntryActionSuccess>({
      type: DELETE_ACCOUNT_ENTRY_SUCCESS,
      payload: result,
    });
    if (callback) callback(result);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IDeleteAccountEntryActionFail>({
        type: DELETE_ACCOUNT_ENTRY_FAIL,
        payload: { entryType, error: e },
      });
  }
}

function* getAccountFuelSaga({
  params,
}: ReturnType<typeof getAccountFuelAction>) {
  try {
    const {
      data: { items: list, pagination },
    } = yield call(() => api.get(ACCOUNTING_FUEL_API, { params }));

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { page, per_page, order_field, order_type, ...filterParams } = params;
    const { statistics: currentStatistics, isFiltered } = yield select(
      fuelSelector,
    );
    let statistics: IGetAccountFuelActionSuccess['payload']['statistics'];
    const hasFilterParams = Object.keys(filterParams).length > 0;
    if (!currentStatistics || hasFilterParams || isFiltered) {
      const { data } = yield call(() =>
        api.get(`${ACCOUNTING_FUEL_API}/statistic`, { params: filterParams }),
      );
      statistics = data.statistics;
      if (statistics)
        for (const statEntry of Object.values(statistics))
          statEntry.total = Object.values(statEntry).reduce(
            (sum: number, value) => sum + Number(value),
            0,
          );
    }

    yield put<IGetAccountFuelActionSuccess>({
      type: GET_ACCOUNT_FUEL_SUCCESS,
      payload: {
        list,
        pagination,
        isFiltered: hasFilterParams,
        ...(statistics && { statistics }),
      },
    });
    yield put(setPaginationAction(pagination));
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetAccountFuelActionFail>({
        type: GET_ACCOUNT_FUEL_FAIL,
        payload: e,
      });
  }
}

function* getDriverFuelSaga({
  driverId,
  params,
}: ReturnType<typeof getDriverFuelAction>) {
  try {
    const {
      data: { items: list, pagination },
    } = yield call(() =>
      api.get(ACCOUNTING_DRIVER_FUEL_API(driverId), { params }),
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { page, per_page, order_field, order_type, ...filterParams } = params;
    const { statistics: currentStatistics, isFiltered } = yield select(
      fuelDriverSelector,
    );
    let statistics;
    const hasFilterParams = Object.keys(filterParams).length > 0;
    if (
      !currentStatistics ||
      currentStatistics.id !== driverId ||
      hasFilterParams ||
      isFiltered
    ) {
      const { data } = yield call(() =>
        api.get(ACCOUNTING_DRIVER_FUEL_STATISTICS_API(driverId), {
          params: filterParams,
        }),
      );
      statistics = data.statistics;
    }

    yield put<IGetDriverFuelActionSuccess>({
      type: GET_DRIVER_FUEL_SUCCESS,
      payload: {
        list,
        pagination,
        isFiltered: hasFilterParams,
        ...(statistics && { statistics }),
      },
    });
    yield put(setPaginationAction(pagination));
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetDriverFuelActionFail>({
        type: GET_DRIVER_FUEL_FAIL,
        payload: e,
      });
  }
}
export default function* payrollItemsSagas() {
  yield takeLatest(GET_SCHED_DEDUCTION_DRIVERS, getSchedDeductionDriversSaga);
  yield takeLatest(GET_ACCOUNT_ENTRIES, getAccountEntriesSaga);
  yield takeLatest(GET_DEDUCTIONS, getDeductionsSaga);
  yield takeLatest(GET_DEDUCTION_DETAILS, getDeductionDetailsSaga);
  yield takeLatest(GET_DEDUCTION_PAYMENTS, getDeductionPaymentsSaga);
  yield takeLatest(ADD_DEDUCTION, addDeductionsSaga);
  yield takeLatest(EDIT_DEDUCTION, patchDeductionsSaga);
  yield takeLatest(DELETE_DEDUCTION, deleteDeductionSaga);
  yield takeLatest(CANCEL_DEDUCTION, cancelDeductionSaga);
  yield takeLatest(CREATE_ACCOUNT_ENTRY, createAccountEntrySaga);
  yield takeLatest(UPDATE_ACCOUNT_ENTRY, updateAccountEntrySaga);
  yield takeLatest(DELETE_ACCOUNT_ENTRY, deleteAccountEntrySaga);
  yield takeLatest(GET_ACCOUNT_FUEL, getAccountFuelSaga);
  yield takeLatest(GET_DRIVER_FUEL, getDriverFuelSaga);
}
