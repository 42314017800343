type Props = {
  className?: string;
};

const IconMail = ({ className }: Props) => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      opacity="0.1"
      d="M36 72C16.1489 72 0 55.8503 0 36C0 16.1497 16.1489 0 36 0C55.8511 0 72 16.1497 72 36C72 55.8503 55.8511 72 36 72Z"
      fill="#2C6AF6"
    />
    <rect
      x="25.5"
      y="27"
      width="21"
      height="18"
      rx="3"
      stroke="#2C6AF6"
      strokeWidth="3"
    />
    <path
      d="M26.2442 30.1976C25.5249 29.7866 24.6087 30.0365 24.1976 30.7558C23.7866 31.4751 24.0365 32.3913 24.7558 32.8024L26.2442 30.1976ZM47.2442 32.8024C47.9635 32.3913 48.2134 31.4751 47.8024 30.7558C47.3913 30.0365 46.4751 29.7866 45.7558 30.1976L47.2442 32.8024ZM37.4884 36.6495L36.7442 35.3471L37.4884 36.6495ZM24.7558 32.8024L33.7674 37.9518L35.2558 35.3471L26.2442 30.1976L24.7558 32.8024ZM38.2326 37.9518L47.2442 32.8024L45.7558 30.1976L36.7442 35.3471L38.2326 37.9518ZM33.7674 37.9518C35.1508 38.7424 36.8492 38.7424 38.2326 37.9518L36.7442 35.3471C36.2831 35.6106 35.7169 35.6106 35.2558 35.3471L33.7674 37.9518Z"
      fill="#2C6AF6"
    />
  </svg>
);

export default IconMail;
