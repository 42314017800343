import { omit } from 'ramda';
import { startSubmit, stopSubmit } from 'redux-form';
import {
  call,
  put,
  takeLatest,
  select,
  takeLeading,
  takeEvery,
} from 'redux-saga/effects';

import { setPaginationAction } from 'components/_common/Navigation/Pagination/_redux/paginationActions';
import { paginationSelector } from 'components/_common/Navigation/Pagination/_redux/paginationSelectors';
import { fileToFormData } from 'utils/converters';
import { getErrors, scrollToFirstError } from 'utils/helpers';
import api, { apiTrailersTracking } from 'utils/requests';

import {
  createTrailerAction,
  createTrailerFailAction,
  createTrailerSuccessAction,
  getTrailersSuccessAction,
  getTrailersFailAction,
  getTrailerAction,
  getTrailerSuccessAction,
  getTrailerFailAction,
  updateTrailerAction,
  deleteTrailerAction,
  deleteTrailerSuccessAction,
  deleteTrailerFailAction,
  getTrailersAction,
  uploadTrailerFilesAction,
  deleteTrailerFileAction,
  toggleTrailerPinAction,
  getTrackingTrailersAction,
  unlockTrailerInspectionAction,
  uploadTrailerClaimPhotoSuccessAction,
  uploadTrailerClaimPhotoFailAction,
  uploadTrailerClaimPhotoAction,
  deleteTrailerClaimPhotoAction,
  deleteTrailerClaimPhotoSuccessAction,
  deleteTrailerClaimPhotoFailAction,
} from './trailersActions';
import {
  CREATE_TRAILER,
  GET_TRAILERS,
  GET_TRAILER,
  UPDATE_TRAILER,
  DELETE_TRAILER,
  UPLOAD_TRAILER_FILES_FAIL,
  UPLOAD_TRAILER_FILES_SUCCESS,
  UPLOAD_TRAILER_FILES,
  DELETE_TRAILER_FILE,
  DELETE_TRAILER_FILE_SUCCESS,
  DELETE_TRAILER_FILE_FAIL,
  UPDATE_TRAILER_SUCCESS,
  UPDATE_TRAILER_FAIL,
  TOGGLE_TRAILER_PIN,
  TOGGLE_TRAILER_PIN_SUCCESS,
  TOGGLE_TRAILER_PIN_FAIL,
  GET_TRAILERS_TRACKING_DATA,
  GET_TRAILERS_TRACKING_DATA_SUCCESS,
  GET_TRAILERS_TRACKING_DATA_FAIL,
  IGetTrailersTrackingDataSuccess,
  IGetTrailersTrackingDataFail,
  UNLOCK_TRAILER_INSPECTION_FAIL,
  UNLOCK_TRAILER_INSPECTION,
  UPLOAD_TRAILER_CLAIM_PHOTO,
  DELETE_TRAILER_CLAIM_PHOTO,
} from './trailersTypes';

function* createTrailerSaga({
  payload: { values, callback },
}: ReturnType<typeof createTrailerAction>) {
  try {
    yield put(startSubmit('createAndUpdateTrailerForm'));
    const { data } = yield call(() =>
      api.post('/v1/fleets/trailers', { ...values }),
    );

    yield put(createTrailerSuccessAction());
    yield put(stopSubmit('createAndUpdateTrailerForm'));
    callback(data.trailer.id);
  } catch (e) {
    if (api.isAxiosError(e)) {
      const errors = getErrors(e);
      const firstErrorKey = Object.keys(errors)[0];
      if (firstErrorKey) {
        scrollToFirstError({ [firstErrorKey]: 'error' });
      }
      yield put(createTrailerFailAction(e));
      yield put(stopSubmit('createAndUpdateTrailerForm', errors));
    }
  }
}

function* getTrailersSaga({
  payload: {
    callback,
    config = {},
    data: { query },
  },
}: ReturnType<typeof getTrailersAction>) {
  const { usePaginationFlag } = config;

  try {
    const { per_page, page } = yield select(paginationSelector);

    const { data } = yield call(() =>
      api.get(
        `/v1/fleets/trailers?page=${page}&per_page=${per_page}&stats=1${
          query || ''
        }`,
      ),
    );

    const { pagination } = data;

    yield put(getTrailersSuccessAction(data));

    // Pagination set
    if (usePaginationFlag) {
      yield put(setPaginationAction(pagination));
    }

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(getTrailersFailAction(e));
  }
}

function* getTrailerSaga({
  id,
  dispatchToStore,
  callback,
}: ReturnType<typeof getTrailerAction>) {
  try {
    const { data } = yield call(() => api.get(`/v1/fleets/trailers/${id}`));

    if (dispatchToStore) yield put(getTrailerSuccessAction(data));
    if (callback) callback(data);
  } catch (e) {
    if (api.isAxiosError(e)) yield put(getTrailerFailAction(e));
  }
}

function* deleteTrailerSaga({
  payload: { id, callback },
}: ReturnType<typeof deleteTrailerAction>) {
  try {
    yield call(() => api.delete(`/v1/fleets/trailers/${id}`));
    yield put(deleteTrailerSuccessAction());
    yield put(getTrailersAction({ data: {} }));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(deleteTrailerFailAction(e));
  }
}

function* updateTrailerSaga({
  payload: { values, callback },
}: ReturnType<typeof updateTrailerAction>) {
  try {
    const { data } = yield call(() =>
      api.patch(`/v1/fleets/trailers/${values.trailer.id}`, {
        trailer: omit(['id', 'driver', 'trailer', 'order'], values.trailer),
        tracking_device_company: { name: values.tracking_device_company?.name },
      }),
    );

    yield put({ type: UPDATE_TRAILER_SUCCESS, payload: data });
    yield put(stopSubmit('createAndUpdateTrailerForm'));
    if (callback) callback();
  } catch (e) {
    yield put({ type: UPDATE_TRAILER_FAIL, payload: e });
    if (api.isAxiosError(e))
      yield put(stopSubmit('createAndUpdateTrailerForm', getErrors(e)));
  }
}

// TRAILER FILES
function* uploadTrailerFilesSaga({
  payload: { files, itemId: trailerId },
}: ReturnType<typeof uploadTrailerFilesAction>) {
  const url = trailerId
    ? `/v1/fleets/trailers/${trailerId}/files`
    : `/v1/fleets/trailers/files`;

  for (const fileObj of files) {
    yield call(function* processFiles() {
      try {
        const { data } = yield call(() =>
          api.post(
            url,
            fileToFormData(fileObj.file, `fleets_trailers_file[file]`),
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            },
          ),
        );
        yield put({
          type: UPLOAD_TRAILER_FILES_SUCCESS,
          payload: { ...data.file, justCreated: true },
        });
      } catch (e) {
        yield put({ type: UPLOAD_TRAILER_FILES_FAIL, payload: e });
      }
    });
  }
}

function* deleteTrailerFileSaga({
  payload: { fileId, itemId: trailerId },
}: ReturnType<typeof deleteTrailerFileAction>) {
  const url = trailerId
    ? `/v1/fleets/trailers/${trailerId}/files/${fileId}`
    : `/v1/fleets/trailers/files/${fileId}`;

  try {
    yield call(() => api.delete(url));
    yield put({ type: DELETE_TRAILER_FILE_SUCCESS, payload: fileId });
  } catch (e) {
    yield put({ type: DELETE_TRAILER_FILE_FAIL, payload: e });
  }
}

function* toggleTrailerPinSaga({
  payload: {
    callback,
    data: { isPinned, trailerId },
  },
}: ReturnType<typeof toggleTrailerPinAction>) {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const pinMethod = isPinned ? api.delete : api.post;

  try {
    yield call(() => pinMethod(`/v1/fleets/trailers/${trailerId}/pin`));
    yield put({
      type: TOGGLE_TRAILER_PIN_SUCCESS,
      payload: { isPinned, trailerId },
    });

    if (callback) callback();
  } catch (e) {
    yield put({ type: TOGGLE_TRAILER_PIN_FAIL, payload: e });
  }
}

function* getTrackingTrailersSaga({
  payload: {
    callback,
    data: { deviceIds },
  },
}: ReturnType<typeof getTrackingTrailersAction>) {
  try {
    const { data } = yield call(() =>
      deviceIds
        ? apiTrailersTracking.post(`/v1/vehicles?devices_ids=${deviceIds}`)
        : apiTrailersTracking.get('/v1/vehicles'),
    );
    yield put<IGetTrailersTrackingDataSuccess>({
      type: GET_TRAILERS_TRACKING_DATA_SUCCESS,
      payload: data,
    });
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetTrailersTrackingDataFail>({
        type: GET_TRAILERS_TRACKING_DATA_FAIL,
        payload: e,
      });
  }
}

function* unlockTrailerInspectionSaga({
  payload: { trailerId, callback },
}: ReturnType<typeof unlockTrailerInspectionAction>) {
  const url = `/v1/fleets/trailers/${trailerId}/inspections/drafts/unlock`;
  try {
    yield call(() => api.post(url));
    if (callback) callback();
  } catch (e) {
    yield put({ type: UNLOCK_TRAILER_INSPECTION_FAIL, payload: e });
  }
}

// UPLOAD TRAILER CLAIM PHOTO
function* uploadTrailerClaimPhotoSaga({
  payload: { files },
}: ReturnType<typeof uploadTrailerClaimPhotoAction>) {
  const url = `v1/fleets/drivers/claims/issues/files`;
  for (const { file } of files) {
    yield call(function* upload() {
      try {
        const { data } = yield call(() =>
          api.post(
            url,
            fileToFormData(file, 'fleets_drivers_claims_issues_file[file]'),
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            },
          ),
        );
        yield put(uploadTrailerClaimPhotoSuccessAction({ file: data.file }));
      } catch (e) {
        if (api.isAxiosError(e))
          yield put(uploadTrailerClaimPhotoFailAction(e));
      }
    });
  }
}

// DELETE TRUCK CLAIM PHOTO
function* deleteTrailerClaimPhotoSaga({
  payload: { fileId, itemId },
}: ReturnType<typeof deleteTrailerClaimPhotoAction>) {
  try {
    const url = itemId
      ? `/v1/fleets/drivers/claims/${itemId}/issues/${itemId}/files/${fileId}`
      : `/v1/fleets/drivers/claims/issues/files/${fileId}`;
    yield call(() => api.delete(url));
    yield put(
      deleteTrailerClaimPhotoSuccessAction({
        fileId,
        itemId: itemId ? Number(itemId) : undefined,
      }),
    );
  } catch (e) {
    if (api.isAxiosError(e)) yield put(deleteTrailerClaimPhotoFailAction(e));
  }
}

export default function* trailersSaga() {
  yield takeLatest(GET_TRAILERS, getTrailersSaga);
  yield takeEvery(GET_TRAILER, getTrailerSaga);
  yield takeLatest(UPDATE_TRAILER, updateTrailerSaga);
  yield takeLatest(DELETE_TRAILER, deleteTrailerSaga);
  yield takeLeading(CREATE_TRAILER, createTrailerSaga);
  yield takeLeading(UPLOAD_TRAILER_FILES, uploadTrailerFilesSaga);
  yield takeEvery(DELETE_TRAILER_FILE, deleteTrailerFileSaga);
  yield takeLeading(TOGGLE_TRAILER_PIN, toggleTrailerPinSaga);
  yield takeLeading(GET_TRAILERS_TRACKING_DATA, getTrackingTrailersSaga);
  yield takeLeading(UNLOCK_TRAILER_INSPECTION, unlockTrailerInspectionSaga);
  yield takeLeading(UPLOAD_TRAILER_CLAIM_PHOTO, uploadTrailerClaimPhotoSaga);
  yield takeLeading(DELETE_TRAILER_CLAIM_PHOTO, deleteTrailerClaimPhotoSaga);
}
