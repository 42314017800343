import { AxiosError } from 'axios';

import { Employee } from 'components/_common/FormElements/ResourcesAutocomplete/_redux/resourcesTypes';
import { IPagination } from 'components/_common/Navigation/Pagination/_redux/paginationTypes';
import {
  IUploadFilesAction,
  IDeleteFileAction,
} from 'redux/_common/commonTypes';
import { ILogout, IFile, ILocalFile } from 'redux/_common/models';
import { IWithError, IWithPending } from 'utils/models';

import {
  IItem,
  IAddItems,
  IAddUnits,
  IUpdateItem,
  IItemActivity,
} from '../_models/itemsModels';

export interface IItemsReducer extends IWithError, IWithPending {
  currentItem: IItem | null;
  currentItemActivity: IItemActivity[];
  photoFiles: Array<IFile | ILocalFile>;
  invoiceFile: Array<IFile | ILocalFile>;
  updateUnitsGrid: number;
  invoicesList: IFile[];
  allInvoicesList: IFile[];

  writtenOffByList: Employee[];
}

// CREATE_ITEM
export const CREATE_ITEM = '[@@Items] CREATE_ITEM';
export const CREATE_ITEM_SUCCESS = '[@@Items] CREATE_ITEM_SUCCESS';
export const CREATE_ITEM_FAIL = '[@@Items] CREATE_ITEM_FAIL';
export interface IAddItemsAction {
  type: typeof CREATE_ITEM;
  payload: {
    data: { inventories_item: IAddItems };
    callback?: () => void;
  };
}
export interface IAddItemsSuccessAction {
  type: typeof CREATE_ITEM_SUCCESS;
  payload: IItem;
}
export interface IAddItemsFailAction {
  type: typeof CREATE_ITEM_FAIL;
  payload: AxiosError;
}

// UPDATE_ITEM
export const UPDATE_ITEM = '[@@Items] UPDATE_ITEM';
export const UPDATE_ITEM_SUCCESS = '[@@Items] UPDATE_ITEM_SUCCESS';
export const UPDATE_ITEM_FAIL = '[@@Items] UPDATE_ITEM_FAIL';
export interface IUpdateItemAction {
  type: typeof UPDATE_ITEM;
  payload: {
    id: number;
    data: { inventories_item: IAddItems | IUpdateItem };
    callback?: () => void;
  };
}
export interface IUpdateItemSuccessAction {
  type: typeof UPDATE_ITEM_SUCCESS;
}
export interface IUpdateItemFailAction {
  type: typeof UPDATE_ITEM_FAIL;
  payload: AxiosError;
}

export const DELETE_ITEM = '[@@Items] DELETE_ITEM';
export const DELETE_ITEM_SUCCESS = '[@@Items] DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_FAIL = '[@@Items] DELETE_ITEM_FAIL';

export interface IDeleteItem {
  type: typeof DELETE_ITEM;
  payload: {
    itemId: number;
  };
}

export interface IDeleteItemSuccessAction {
  type: typeof DELETE_ITEM_SUCCESS;
}
export interface IDeleteItemFailAction {
  type: typeof DELETE_ITEM_FAIL;
  payload: AxiosError;
}

export const ASSIGN_SELL = '[@@Items] ASSIGN_SELL';
export interface IAssignSellAction {
  type: typeof ASSIGN_SELL;
  payload: {
    id: number;
    data: { inventories_item: IUpdateItem };
    callback?: () => void;
  };
}

export const ASSIGN_LEASE = '[@@Items] ASSIGN_LEASE';
export interface IAssignLeaseAction {
  type: typeof ASSIGN_LEASE;
  payload: {
    id: number;
    data: { inventories_item: IUpdateItem };
    callback?: () => void;
  };
}
// ADD_UNITS
export const ADD_UNITS = '[@@Items] ADD_UNITS';
export const ADD_UNITS_SUCCESS = '[@@Items] ADD_UNITS_SUCCESS';
export const ADD_UNITS_FAIL = '[@@Items] ADD_UNITS_FAIL';
export interface IAddUnitsAction {
  type: typeof ADD_UNITS;
  payload: {
    itemId: number;
    data: { inventories_item: IAddUnits };
    callback?: () => void;
  };
}
export interface IAddUnitsSuccessAction {
  type: typeof ADD_UNITS_SUCCESS;
  payload: {};
}
export interface IAddUnitsFailAction {
  type: typeof ADD_UNITS_FAIL;
  payload: AxiosError;
}

// UPLOAD PHOTO
export const UPLOAD_ITEM_PHOTO = '[@@Items] UPLOAD_ITEM_PHOTO';
export const UPLOAD_ITEM_PHOTO_SUCCESS = '[@@Items] UPLOAD_ITEM_PHOTO_SUCCESS';
export const UPLOAD_ITEM_PHOTO_FAIL = '[@@Items] UPLOAD_ITEM_PHOTO_FAIL';

export interface IUploadItemPhotoAction extends IUploadFilesAction {
  type: typeof UPLOAD_ITEM_PHOTO;
}

export interface IUploadItemPhotoSuccessAction {
  type: typeof UPLOAD_ITEM_PHOTO_SUCCESS;
  payload: { file: IFile; itemId?: number };
}
export interface IUploadItemPhotoFailAction {
  type: typeof UPLOAD_ITEM_PHOTO_FAIL;
  payload: AxiosError;
}

// DELETE PHOTO
export const DELETE_ITEM_PHOTO = '[@@Items] DELETE_ITEM_PHOTO';
export const DELETE_ITEM_PHOTO_SUCCESS = '[@@Items] DELETE_ITEM_PHOTO_SUCCESS';
export const DELETE_ITEM_PHOTO_FAIL = '[@@Items] DELETE_ITEM_PHOTO_FAIL';
export interface IDeleteItemPhotoAction extends IDeleteFileAction {
  type: typeof DELETE_ITEM_PHOTO;
}
export interface IDeleteItemPhotoSuccessAction {
  type: typeof DELETE_ITEM_PHOTO_SUCCESS;
  payload: { fileId: string | number; itemId?: number };
}
export interface IDeleteItemPhotoFailAction {
  type: typeof DELETE_ITEM_PHOTO_FAIL;
  payload: AxiosError;
}

// UPLOAD INVOICE FILE
export const UPLOAD_ITEM_INVOICE_FILE = '[@@Items] UPLOAD_ITEM_INVOICE_FILE';
export const UPLOAD_ITEM_INVOICE_FILE_SUCCESS =
  '[@@Items] UPLOAD_ITEM_INVOICE_FILE_SUCCESS';
export const UPLOAD_ITEM_INVOICE_FILE_FAIL =
  '[@@Items] UPLOAD_ITEM_INVOICE_FILE_FAIL';

export interface IUploadItemInvoiceFileAction extends IUploadFilesAction {
  type: typeof UPLOAD_ITEM_INVOICE_FILE;
}
export interface IUploadItemInvoiceFileSuccessAction {
  type: typeof UPLOAD_ITEM_INVOICE_FILE_SUCCESS;
  payload: IFile;
}
export interface IUploadItemInvoiceFileFailAction {
  type: typeof UPLOAD_ITEM_INVOICE_FILE_FAIL;
  payload: AxiosError;
}

// DELETE INVOICE FILE
export const DELETE_ITEM_INVOICE_FILE = '[@@Items] DELETE_ITEM_INVOICE_FILE';
export const DELETE_ITEM_INVOICE_FILE_SUCCESS =
  '[@@Items] DELETE_ITEM_INVOICE_FILE_SUCCESS';
export const DELETE_ITEM_INVOICE_FILE_FAIL =
  '[@@Items] DELETE_ITEM_INVOICE_FILE_FAIL';

export interface IDeleteItemInvoiceFileAction extends IDeleteFileAction {
  type: typeof DELETE_ITEM_INVOICE_FILE;
}
export interface IDeleteItemInvoiceFileSuccessAction {
  type: typeof DELETE_ITEM_INVOICE_FILE_SUCCESS;
  payload: string | number;
}
export interface IDeleteItemInvoiceFileFailAction {
  type: typeof DELETE_ITEM_INVOICE_FILE_FAIL;
  payload: AxiosError;
}

// GET INVOICES
export const GET_ITEM_INVOICES = '[@@Items] GET_ITEM_INVOICES';
export const GET_ITEM_INVOICES_SUCCESS = '[@@Items] GET_ITEM_INVOICES_SUCCESS';
export const GET_ITEM_INVOICES_FAIL = '[@@Items] GET_ITEM_INVOICES_FAIL';

export interface IGetItemInvoicesAction {
  type: typeof GET_ITEM_INVOICES;
  payload?: {
    query?: string;
    callback?: () => void;
  };
}
export interface IGetItemInvoicesSuccessAction {
  type: typeof GET_ITEM_INVOICES_SUCCESS;
  payload: IFile[];
}
export interface IGetItemInvoicesFailAction {
  type: typeof GET_ITEM_INVOICES_FAIL;
  payload: AxiosError;
}

export const GET_ALL_INVOICES = '[@@Items] GET_ALL_INVOICES';
export const GET_ALL_INVOICES_SUCCESS = '[@@Items] GET_ALL_INVOICES_SUCCESS';

export interface IGetAllInvoicesAction {
  type: typeof GET_ALL_INVOICES;
}

export interface IGetAllInvoicesActionSuccess {
  type: typeof GET_ALL_INVOICES_SUCCESS;
  payload: IFile[];
}

// SET_ITEM_INVOICE_FILE
export const SET_ITEM_INVOICE_FILE = '[@@Items] SET_ITEM_INVOICE_FILE';
export interface ISetItemsInvoiceFileAction {
  type: typeof SET_ITEM_INVOICE_FILE;
  payload: IFile;
}

export const SET_ITEM_FILES = '[@@Items] SET_ITEM_FILES';
export interface ISetItemFiles {
  type: typeof SET_ITEM_FILES;
  payload: {
    photoFiles: IFile[];
  };
}

// RESET MAIN FORM
export const RESET_ITEMS_CREATE_FORM = '[@@Items] RESET_ITEMS_CREATE_FORM';
export interface IResetItemsCreateFormsAction {
  type: typeof RESET_ITEMS_CREATE_FORM;
}

// GET ITEM BY ID
export const GET_ITEM = '[@@Items] GET_ITEM';
export const GET_ITEM_SUCCESS = '[@@Items] GET_ITEM_SUCCESS';
export const GET_ITEM_FAIL = '[@@Items] GET_ITEM_FAIL';

export interface IGetItem {
  type: typeof GET_ITEM;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}

export interface IGetItemSuccess {
  type: typeof GET_ITEM_SUCCESS;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}

export interface IGetItemFail {
  type: typeof GET_ITEM_FAIL;
  payload: AxiosError;
}

// GET ITEM ACTIVITY BY ID
export const GET_ITEM_ACTIVITY = '[@@Items] GET_ITEM_ACTIVITY';
export const GET_ITEM_ACTIVITY_SUCCESS = '[@@Items] GET_ITEM_ACTIVITY_SUCCESS';
export const GET_ITEM_ACTIVITY_FAIL = '[@@Items] GET_ITEM_ACTIVITY_FAIL';

export interface IGetItemActivity {
  type: typeof GET_ITEM_ACTIVITY;
  payload: {
    itemId: number;
    callback?: () => void;
  };
}

export interface IGetItemActivitySuccess {
  type: typeof GET_ITEM_ACTIVITY_SUCCESS;
  payload: {
    pagination: IPagination;
    activities: IItemActivity[];
  };
}

export interface IGetItemActivityFail {
  type: typeof GET_ITEM_ACTIVITY_FAIL;
  payload: AxiosError;
}

// CHANGE UNIT STATUS BY ID
export const CHANGE_STATUS_UNIT = '[@@Items] CHANGE_STATUS_UNIT';
export const CHANGE_STATUS_UNIT_SUCCESS =
  '[@@Items] CHANGE_STATUS_UNIT_SUCCESS';
export const CHANGE_STATUS_UNIT_FAIL = '[@@Items] CHANGE_STATUS_UNIT_FAIL';

export interface IChangeStatusUnit {
  type: typeof CHANGE_STATUS_UNIT;
  payload: {
    itemId: number;
    unitId: number;
    reason?: string;
    callback?: () => void;
  };
}

export interface IChangeStatusUnitSuccess {
  type: typeof CHANGE_STATUS_UNIT_SUCCESS;
}

export interface IChangeStatusUnitFail {
  type: typeof CHANGE_STATUS_UNIT_FAIL;
  payload: AxiosError;
}

export const UNDO_CHANGE_STATUS_UNIT = '[@@Items] UNDO_CHANGE_STATUS_UNIT';

// LEASE UNIT
export const LEASE_SELL_INVENTORY_UNIT = '[@@Items] LEASE_SELL_INVENTORY_UNIT';
export const LEASE_SELL_INVENTORY_UNIT_SUCCESS =
  '[@@Items] LEASE_SELL_INVENTORY_UNIT_SUCCESS';
export const LEASE_SELL_INVENTORY_UNIT_FAIL =
  '[@@Items] LEASE_SELL_INVENTORY_UNIT_FAIL';

// ASSIGN DRIVER UNIT
export const ASSIGN_INVENTORY_UNIT = '[@@Items] ASSIGN_INVENTORY_UNIT';
export const ASSIGN_INVENTORY_UNIT_SUCCESS =
  '[@@Items] ASSIGN_INVENTORY_UNIT_SUCCESS';
export const ASSIGN_INVENTORY_UNIT_FAIL =
  '[@@Items] ASSIGN_INVENTORY_UNIT_FAIL';

export interface IAssignInventoryUnit {
  type: typeof ASSIGN_INVENTORY_UNIT;
  payload: {
    assignTo: 'truck' | 'trailer' | 'driver';
    assignToId: number;
    formData: {
      fleets_connection: {
        units_ids?: number[];
        critical_checks: boolean;
        use_on?: string;
        item_id?: number;
        autosequence?: boolean;
        autosequence_quantity?: number;
        autosequence_item_condition?: string;
        inspection_id?: number;
        sell_price?: number;
        lease_price?: number;
      };
    };
    callback?: () => void;
  };
}

export interface IAssignInventoryUnitSuccess {
  type: typeof ASSIGN_INVENTORY_UNIT_SUCCESS;
}

export interface IAssignInventoryUnitFail {
  type: typeof ASSIGN_INVENTORY_UNIT_FAIL;
  payload: AxiosError;
}

// ASSIGN DRIVER UNIT
export const ASSIGN_EMPLOYEE_UNIT = '[@@Items] ASSIGN_EMPLOYEE_UNIT';
export const ASSIGN_EMPLOYEE_UNIT_SUCCESS =
  '[@@Items] ASSIGN_EMPLOYEE_UNIT_SUCCESS';
export const ASSIGN_EMPLOYEE_UNIT_FAIL = '[@@Items] ASSIGN_EMPLOYEE_UNIT_FAIL';

export interface IAssignEmployeeUnit {
  type: typeof ASSIGN_EMPLOYEE_UNIT;
  payload: {
    itemId: number;
    unitId: number;
    employeeId: number;
    callback?: () => void;
  };
}

export interface IAssignEmployeeUnitSuccess {
  type: typeof ASSIGN_EMPLOYEE_UNIT_SUCCESS;
}

export interface IAssignEmployeeUnitFail {
  type: typeof ASSIGN_EMPLOYEE_UNIT_FAIL;
  payload: AxiosError;
}

// UNASSIGN UNIT
export const UNASSIGN_UNIT = '[@@Items] UNASSIGN_UNIT';
export const UNASSIGN_UNIT_SUCCESS = '[@@Items] UNASSIGN_UNIT_SUCCESS';
export const UNASSIGN_UNIT_FAIL = '[@@Items] UNASSIGN_UNIT_FAIL';

export interface IUnassignUnit {
  type: typeof UNASSIGN_UNIT;
  payload: {
    itemId: number;
    unitId: number;
    callback?: () => void;
  };
}

export interface IUnassignUnitSuccess {
  type: typeof UNASSIGN_UNIT_SUCCESS;
}

export interface IUnassignUnitFail {
  type: typeof UNASSIGN_UNIT_FAIL;
  payload: AxiosError;
}

// REMOVE FROM EMPLOYEE UNIT
export const REMOVE_EMPLOYEE_UNIT = '[@@Items] REMOVE_EMPLOYEE_UNIT';
export const REMOVE_EMPLOYEE_UNIT_SUCCESS =
  '[@@Items] REMOVE_EMPLOYEE_UNIT_SUCCESS';
export const REMOVE_EMPLOYEE_UNIT_FAIL = '[@@Items] REMOVE_DRIVER_UNIT_FAIL';

export interface IRemoveEmployeeUnit {
  type: typeof REMOVE_EMPLOYEE_UNIT;
  payload: {
    itemId: number;
    unitId: number;
    employeeId: number;
    callback?: () => void;
  };
}

export interface IRemoveEmployeeUnitSuccess {
  type: typeof REMOVE_EMPLOYEE_UNIT_SUCCESS;
}

export interface IRemoveEmployeeUnitFail {
  type: typeof REMOVE_EMPLOYEE_UNIT_FAIL;
  payload: AxiosError;
}

export const GET_ITEM_WRITE_OFF_EMPLOYEES =
  '[@@Items] GET_ITEM_WRITE_OFF_EMPLOYEES';
export const GET_ITEM_WRITE_OFF_EMPLOYEES_SUCCESS =
  '[@@Items] GET_ITEM_WRITE_OFF_EMPLOYEE_SUCCESS';
export interface IGetItemWriteOffEmployees {
  type: typeof GET_ITEM_WRITE_OFF_EMPLOYEES;
  payload?: {
    callback?: () => void;
  };
}

export interface IGetItemWriteOffEmployeesSuccess {
  type: typeof GET_ITEM_WRITE_OFF_EMPLOYEES_SUCCESS;
  payload: Employee[];
}

export type ItemsTypes =
  | IAddItemsAction
  | IAddItemsSuccessAction
  | IAddItemsFailAction
  | IUpdateItemAction
  | IUpdateItemSuccessAction
  | IUpdateItemFailAction
  | IAddUnitsAction
  | IAssignLeaseAction
  | IAssignSellAction
  | IAddUnitsSuccessAction
  | IAddUnitsFailAction
  | IAssignInventoryUnit
  | IAssignInventoryUnitSuccess
  | IAssignInventoryUnitFail
  | IAssignEmployeeUnit
  | IAssignEmployeeUnitSuccess
  | IAssignEmployeeUnitFail
  | IUnassignUnit
  | IUnassignUnitSuccess
  | IUnassignUnitFail
  | IRemoveEmployeeUnit
  | IRemoveEmployeeUnitSuccess
  | IRemoveEmployeeUnitFail
  | IChangeStatusUnit
  | IChangeStatusUnitSuccess
  | IChangeStatusUnitFail
  | IUploadItemPhotoAction
  | IUploadItemPhotoSuccessAction
  | IUploadItemPhotoFailAction
  | IDeleteItemPhotoAction
  | IDeleteItemPhotoSuccessAction
  | IDeleteItemPhotoFailAction
  | IUploadItemInvoiceFileAction
  | IUploadItemInvoiceFileSuccessAction
  | IUploadItemInvoiceFileFailAction
  | IDeleteItemInvoiceFileAction
  | IDeleteItemInvoiceFileSuccessAction
  | IDeleteItemInvoiceFileFailAction
  | IGetItemInvoicesAction
  | IGetAllInvoicesAction
  | IGetAllInvoicesActionSuccess
  | IGetItemInvoicesSuccessAction
  | IGetItemInvoicesFailAction
  | ISetItemsInvoiceFileAction
  | IResetItemsCreateFormsAction
  | ISetItemFiles
  | IGetItemActivity
  | IGetItemActivitySuccess
  | IGetItemActivityFail
  | IGetItem
  | IGetItemSuccess
  | IGetItemFail
  | IGetItemWriteOffEmployees
  | IGetItemWriteOffEmployeesSuccess
  | ILogout
  | IDeleteItem
  | IDeleteItemSuccessAction
  | IDeleteItemFailAction;
