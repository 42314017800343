import { AxiosError } from 'axios';

import { SwitcherKeys, IWithPending } from 'utils/models';

import {
  IDeleteFileAction,
  IDeleteFileActionFail,
  IDeleteFileActionSuccess,
  IUploadFilesAction,
  IUploadFilesActionFail,
  IUploadFilesActionSuccess,
} from '../../../redux/_common/commonTypes';

export interface IFleetGroupReducer extends IWithPending {
  viewKey: SwitcherKeys;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  workOrderFiles: any[];
}

// SET_VIEW_KEY
export const SET_FLEET_GROUP_VIEW_KEY =
  '[@@FleetGroup] SET_FLEET_GROUP_VIEW_KEY';

export interface ISetFleetGroupViewKeyAction {
  type: typeof SET_FLEET_GROUP_VIEW_KEY;
  payload: SwitcherKeys;
}

// SET_LABEL
export const SET_FLEET_LABEL = '[@@FleetGroup] SET_FLEET_LABEL';
export const SET_FLEET_LABEL_SUCCESS = '[@@FleetGroup] SET_FLEET_LABEL_SUCCESS';
export const SET_FLEET_LABEL_FAIL = '[@@FleetGroup] SET_FLEET_LABEL_FAIL';

export interface ISetLabelAction {
  type: typeof SET_FLEET_LABEL;
  payload: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: { id: number; endPoint: string; labelKey: string; values: any };
    callback?: () => void;
  };
}

export interface ISetLabelActionSuccess {
  type: typeof SET_FLEET_LABEL_SUCCESS;
}

export interface ISetLabelActionFail {
  type: typeof SET_FLEET_LABEL_FAIL;
  payload: AxiosError;
}

// DELETE_LABEL
export const DELETE_FLEET_LABEL = '[@@FleetGroup] DELETE_FLEET_LABEL';
export const DELETE_FLEET_LABEL_SUCCESS =
  '[@@FleetGroup] DELETE_FLEET_LABEL_SUCCESS';
export const DELETE_FLEET_LABEL_FAIL = '[@@FleetGroup] DELETE_FLEET_LABEL_FAIL';

export interface IDeleteLabelAction {
  type: typeof DELETE_FLEET_LABEL;
  payload: {
    data: { id: number; endPoint: string; labelKey: string };
    callback?: () => void;
  };
}

export interface IDeleteLabelActionSuccess {
  type: typeof DELETE_FLEET_LABEL_SUCCESS;
}

export interface IDeleteLabelActionFail {
  type: typeof DELETE_FLEET_LABEL_FAIL;
  payload: AxiosError;
}

// ASSIGN VEHICLES
export const ASSIGN_VEHICLES = '[@@FleetGroup] ASSIGN_VEHICLES';
export const ASSIGN_VEHICLES_SUCCESS = '[@@FleetGroup] ASSIGN_VEHICLES_SUCCESS';
export const ASSIGN_VEHICLES_FAIL = '[@@FleetGroup] ASSIGN_VEHICLES_FAIL';

export type AssignVehicles = {
  driver_id: number | null;
  extra_driver_id: number | null;
  truck_id: number | null;
  trailer_id: number | null;
  main_item?: 'truck' | 'trailer' | 'driver';
};
export interface IAssignVehiclesAction {
  type: typeof ASSIGN_VEHICLES;
  data: AssignVehicles;
  callback?: () => void;
}

export interface IAssignVehiclesActionSuccess {
  type: typeof ASSIGN_VEHICLES_SUCCESS;
}

export interface IAssignVehiclesActionFail {
  type: typeof ASSIGN_VEHICLES_FAIL;
  payload: AxiosError;
}

// UPLOAD WORK ORDER FILES
export const UPLOAD_WORK_ORDER_FILES = '[@@Fleet] UPLOAD_WORK_ORDER_FILES';
export const UPLOAD_WORK_ORDER_FILES_SUCCESS =
  '[@@Fleet] UPLOAD_WORK_ORDER_FILES_SUCCESS';
export const UPLOAD_WORK_ORDER_FILES_FAIL =
  '[@@Fleet] UPLOAD_WORK_ORDER_FILES_FAIL';

export interface IUploadWorkOrderFilesAction extends IUploadFilesAction {
  type: typeof UPLOAD_WORK_ORDER_FILES;
}
export interface IUploadWorkOrderFilesActionSuccess
  extends IUploadFilesActionSuccess {
  type: typeof UPLOAD_WORK_ORDER_FILES_SUCCESS;
}
export interface IUploadWorkOrderFilesActionFail
  extends IUploadFilesActionFail {
  type: typeof UPLOAD_WORK_ORDER_FILES_FAIL;
}

// DELETE EMPLOYEE FILE
export const DELETE_WORK_ORDER_FILE = '[@@Fleet] DELETE_WORK_ORDER_FILE';
export const DELETE_WORK_ORDER_FILE_SUCCESS =
  '[@@Fleet] DELETE_WORK_ORDER_FILE_SUCCESS';
export const DELETE_WORK_ORDER_FILE_FAIL =
  '[@@Fleet] DELETE_WORK_ORDER_FILE_FAIL';

export interface IDeleteWorkOrderFileAction extends IDeleteFileAction {
  type: typeof DELETE_WORK_ORDER_FILE;
}
export interface IDeleteWorkOrderFileActionSuccess
  extends IDeleteFileActionSuccess {
  type: typeof DELETE_WORK_ORDER_FILE_SUCCESS;
}
export interface IDeleteWorkOrderFileActionFail extends IDeleteFileActionFail {
  type: typeof DELETE_WORK_ORDER_FILE_FAIL;
}

export type fleetTypes =
  | ISetFleetGroupViewKeyAction
  | IDeleteLabelAction
  | IDeleteLabelActionSuccess
  | IDeleteLabelActionFail
  | ISetLabelAction
  | ISetLabelActionSuccess
  | ISetLabelActionFail
  | IAssignVehiclesAction
  | IAssignVehiclesActionSuccess
  | IAssignVehiclesActionFail
  | IUploadWorkOrderFilesAction
  | IUploadWorkOrderFilesActionSuccess
  | IUploadWorkOrderFilesActionFail
  | IDeleteWorkOrderFileAction
  | IDeleteWorkOrderFileActionSuccess
  | IDeleteWorkOrderFileActionFail;
