import { FC } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const IconWarningSquare: FC<IOwnProps> = ({
  className,
  fillColor = 'var(--sideBarIcon)',
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.5822 5.4932L8 8L8.4178 5.4932C8.46083 5.23502 8.26174 5 8 5C7.73826 5 7.53917 5.23502 7.5822 5.4932Z"
        fill={fillColor}
        stroke={fillColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="8" cy="11" r="1" fill={fillColor} />
      <rect
        x="1"
        y="1"
        width="14"
        height="14"
        rx="2"
        stroke={fillColor}
        strokeWidth="2"
      />
    </svg>
  );
};

export default IconWarningSquare;
