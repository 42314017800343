import { ReactText } from 'react';

import { AxiosError } from 'axios';

import {
  IClaimsIssue,
  IClaimsItem,
  IIssueFile,
} from 'components/Fleet/TabClaims/_models/claimsModels';
import {
  IDeleteFileAction,
  IDeleteFileActionFail,
  IDeleteFileActionSuccess,
  IUploadFilesAction,
  IUploadFilesActionFail,
  IUploadFilesActionSuccess,
} from 'redux/_common/commonTypes';
import { ILocalFile, ILogout } from 'redux/_common/models';

// CLAIM
export const CREATE_CLAIM = '[@@Claim] CREATE_CLAIM';
export const CREATE_CLAIM_SUCCESS = '[@@Claim] CREATE_CLAIM_SUCCESS';
export const CREATE_CLAIM_FAIL = '[@@Claim] CREATE_CLAIM_FAIL';
export const DELETE_CLAIM = '[@@Claim] DELETE_CLAIM';
export const DELETE_CLAIM_FAIL = '[@@Claim] DELETE_CLAIM_FAIL';
export const ADD_EDIT_CLAIM_NOTE = '[@@Claim] ADD_EDIT_CLAIM_NOTE';
export const ADD_EDIT_CLAIM_NOTE_FAIL = '[@@Claim] ADD_EDIT_CLAIM_NOTE_FAIL';

export interface ICreateClaimAction {
  type: typeof CREATE_CLAIM;
}
export interface ICreateClaimActionSuccess {
  type: typeof CREATE_CLAIM_SUCCESS;
  payload: IClaimsItem;
}
export interface ICreateClaimActionFail {
  type: typeof CREATE_CLAIM_FAIL;
  payload: AxiosError;
}
export interface IDeleteClaimAction {
  type: typeof DELETE_CLAIM;
  payload: { id: ReactText; callback: () => void };
}
export interface IDeleteClaimFailAction {
  type: typeof DELETE_CLAIM_FAIL;
  payload: AxiosError;
}
export interface IAddEditClaimNoteAction {
  type: typeof ADD_EDIT_CLAIM_NOTE;
  payload: { id: ReactText; comment: string; callback: () => void };
}
export interface IAddEditClaimNoteFailAction {
  type: typeof ADD_EDIT_CLAIM_NOTE_FAIL;
  payload: AxiosError;
}
// CLAIM ISSUE
export const ADD_CLAIM_ISSUE = '[@@Claim] ADD_CLAIM_ISSUE';
export const EDIT_CLAIM_ISSUE = '[@@Claim] EDIT_CLAIM_ISSUE';
export const DELETE_CLAIM_ISSUE = '[@@Claim] DELETE_CLAIM_ISSUE';

export interface IAddClaimIssueAction {
  type: typeof ADD_CLAIM_ISSUE;
  payload: IClaimsIssue;
}
export interface IEditClaimIssueAction {
  type: typeof EDIT_CLAIM_ISSUE;
  payload: IClaimsIssue;
}
export interface IDeleteClaimIssueAction {
  type: typeof DELETE_CLAIM_ISSUE;
  payload: ReactText;
}
// UPLOAD CLAIM FILES
export const UPLOAD_CLAIM_FILES = '[@@Claim] UPLOAD_CLAIM_FILES';
export const UPLOAD_CLAIM_FILES_SUCCESS =
  '[@@Claim] UPLOAD_CLAIM_FILES_SUCCESS';
export const UPLOAD_CLAIM_FILES_FAIL = '[@@Claim] UPLOAD_CLAIM_FILES_FAIL';

export interface IUploadClaimFilesAction extends IUploadFilesAction {
  type: typeof UPLOAD_CLAIM_FILES;
}
export interface IUploadClaimFilesActionSuccess
  extends IUploadFilesActionSuccess {
  type: typeof UPLOAD_CLAIM_FILES_SUCCESS;
}
export interface IUploadClaimFilesActionFail extends IUploadFilesActionFail {
  type: typeof UPLOAD_CLAIM_FILES_FAIL;
}

// DELETE CLAIM FILES
export const DELETE_CLAIM_FILE = '[@@Claim] DELETE_CLAIM_FILE';
export const DELETE_CLAIM_FILE_SUCCESS = '[@@Claim] DELETE_CLAIM_FILE_SUCCESS';
export const DELETE_CLAIM_FILE_FAIL = '[@@Claim] DELETE_CLAIM_FILE_FAIL';

export interface IDeleteClaimFileAction extends IDeleteFileAction {
  type: typeof DELETE_CLAIM_FILE;
}
export interface IDeleteClaimFileActionSuccess
  extends IDeleteFileActionSuccess {
  type: typeof DELETE_CLAIM_FILE_SUCCESS;
}
export interface IDeleteClaimFileActionFail extends IDeleteFileActionFail {
  type: typeof DELETE_CLAIM_FILE_FAIL;
}

// UPLOAD CLAIM ISSUE FILES
export const UPLOAD_CLAIM_ISSUE_FILES = '[@@Claim] UPLOAD_CLAIM_ISSUE_FILES';
export const UPLOAD_CLAIM_ISSUE_FILES_SUCCESS =
  '[@@Claim] UPLOAD_CLAIM_ISSUE_FILES_SUCCESS';
export const UPLOAD_CLAIM_ISSUE_FILES_FAIL =
  '[@@Claim] UPLOAD_CLAIM_ISSUE_FILES_FAIL';

export interface IUploadClaimIssueFilesAction {
  type: typeof UPLOAD_CLAIM_ISSUE_FILES;
  payload: IUploadFilesAction['payload'] & {
    issue_id: ReactText;
    claim_id?: ReactText;
  };
}
export interface IUploadClaimIssueFilesActionSuccess {
  type: typeof UPLOAD_CLAIM_ISSUE_FILES_SUCCESS;
  payload: IUploadFilesActionSuccess['payload'] & {
    file: ILocalFile;
    issue_id: ReactText;
  };
}
export interface IUploadClaimIssueFilesActionFail
  extends IUploadFilesActionFail {
  type: typeof UPLOAD_CLAIM_ISSUE_FILES_FAIL;
}

// DELETE CLAIM ISSUE FILES
export const DELETE_CLAIM_ISSUE_FILE = '[@@Claim] DELETE_CLAIM_ISSUE_FILE';
export const DELETE_CLAIM_ISSUE_FILE_SUCCESS =
  '[@@Claim] DELETE_CLAIM_ISSUE_FILE_SUCCESS';
export const DELETE_CLAIM_ISSUE_FILE_FAIL =
  '[@@Claim] DELETE_CLAIM_ISSUE_FILE_FAIL';

export interface IDeleteClaimIssueFileAction {
  type: typeof DELETE_CLAIM_ISSUE_FILE;
  payload: {
    issue_id: ReactText;
    claim_id: ReactText;
    fileId: number | string;
  };
}
export interface IDeleteClaimIssueFileActionSuccess
  extends IDeleteFileActionSuccess {
  type: typeof DELETE_CLAIM_ISSUE_FILE_SUCCESS;
}
export interface IDeleteClaimIssueFileActionFail extends IDeleteFileActionFail {
  type: typeof DELETE_CLAIM_ISSUE_FILE_FAIL;
}

// OTHER

export const MOUNT_CLAIM_FORM = '[@@Claim] MOUNT_CLAIM_FORM';

export interface IMountClaimClaimForm {
  type: typeof MOUNT_CLAIM_FORM;
  payload: {
    files: IIssueFile[];
    issues: IClaimsIssue[];
    issueFiles: IIssueFile[];
  };
}

export const UNMOUNT_CLAIM_FORM = '[@@Claim] UNMOUNT_CLAIM_FORM';

export interface IUnmountClaimClaimForm {
  type: typeof UNMOUNT_CLAIM_FORM;
}

export type claimsActionsType =
  | ICreateClaimAction
  | ICreateClaimActionSuccess
  | ICreateClaimActionFail
  | IDeleteClaimAction
  | IDeleteClaimFailAction
  | IAddEditClaimNoteAction
  | IAddEditClaimNoteFailAction
  | IAddClaimIssueAction
  | IEditClaimIssueAction
  | IDeleteClaimIssueAction
  | ILogout
  | IUploadClaimFilesAction
  | IUploadClaimFilesActionSuccess
  | IUploadClaimFilesActionFail
  | IDeleteClaimFileAction
  | IDeleteClaimFileActionSuccess
  | IDeleteClaimFileActionFail
  | IUnmountClaimClaimForm
  | IUploadClaimIssueFilesAction
  | IUploadClaimIssueFilesActionSuccess
  | IUploadClaimIssueFilesActionFail
  | IDeleteClaimIssueFileAction
  | IDeleteClaimIssueFileActionSuccess
  | IDeleteClaimIssueFileActionFail
  | IMountClaimClaimForm;
