import { ReactText } from 'react';

import { AxiosError } from 'axios';

import {
  IField,
  IStep,
  ITest,
  IUpcomingDriver,
  StepFile,
} from 'components/HumanResources/TabDrivers/_models/driverModel';
import {
  IDeleteFileAction,
  IDeleteFileActionFail,
  IDeleteFileActionSuccess,
  IUploadFilesAction,
} from 'redux/_common/commonTypes';
import {
  IFile,
  IFileCommonFields,
  ILocalFile,
  ILogout,
} from 'redux/_common/models';
import { IWithPending, IWithLoaded, IWithError } from 'utils/models';

export interface IUpcomingProcessFile {
  fileId: number;
  field_id: number;
  created_at: string;
  urls: { thumb: string; original: string };
  file: IFileCommonFields;
  loading: boolean;
}

export interface IUpcomingDriverReducer
  extends IWithPending,
    IWithLoaded,
    IWithError {
  currentStep?: IStep;
  upcomingDriver: IUpcomingDriver;
  files: IUpcomingProcessFile[];
}

export const SET_UPCOMING_DRIVER_CURRENT_STEP =
  '[@@UpcomingDriver] SET_UPCOMING_DRIVER_CURRENT_STEP';

export interface ISetUpcomingDriverCurrentStepAction {
  type: typeof SET_UPCOMING_DRIVER_CURRENT_STEP;
  payload: {
    step?: IStep;
  };
}

export const UPLOAD_UPCOMING_DRIVER_STEP_FILES =
  '[@@UpcomingDriver] UPLOAD_UPCOMING_DRIVER_STEP_FILES';
export const UPLOAD_UPCOMING_DRIVER_TEST_FILES =
  '[@@UpcomingDriver] UPLOAD_UPCOMING_DRIVER_TEST_FILES';
export const UPLOAD_UPCOMING_DRIVER_FILE_SUCCESS =
  '[@@UpcomingDriver] UPLOAD_UPCOMING_DRIVER_FILE_SUCCESS';
export const UPLOAD_UPCOMING_DRIVER_TEST_FILE_SUCCESS =
  '[@@UpcomingDriver] UPLOAD_UPCOMING_DRIVER_TEST_FILE_SUCCESS';

export interface IUploadUpcomingDriverStepFilesAction
  extends IUploadFilesAction {
  type: typeof UPLOAD_UPCOMING_DRIVER_STEP_FILES;
  payload: IUploadFilesAction['payload'] & {
    driverId: ReactText;
    stepId: ReactText;
  };
}

export interface IUploadUpcomingDriverTestFilesAction
  extends IUploadFilesAction {
  type: typeof UPLOAD_UPCOMING_DRIVER_TEST_FILES;
  payload: IUploadFilesAction['payload'];
}

export const DELETE_UPCOMING_DRIVER_STEP_FILE =
  '[@@UpcomingDriver] DELETE_UPCOMING_DRIVER_STEP_FILE';

export const DELETE_UPCOMING_DRIVER_STEP_FILE_SUCCESS =
  '[@@UpcomingDriver] DELETE_UPCOMING_DRIVER_STEP_FILE_SUCCESS';

export const DELETE_UPCOMING_DRIVER_TEST_FILE =
  '[@@UpcomingDriver] DELETE_UPCOMING_DRIVER_TEST_FILE';

export const DELETE_UPCOMING_DRIVER_TEST_FILE_SUCCESS =
  '[@@UpcomingDriver] DELETE_UPCOMING_DRIVER_TEST_FILE_SUCCESS';

export const UPLOAD_UPCOMING_DRIVER_TEST_FILE_FAIL =
  '[@@UpcomingDriver] UPLOAD_UPCOMING_DRIVER_TEST_FILE_FAIL';

export const DELETE_UPCOMING_DRIVER_TEST_FILE_FAIL =
  '[@@UpcomingDriver] DELETE_UPCOMING_DRIVER_TEST_FILE_FAIL';

export const COMPLETE_UPCOMING_DRIVER_TEST =
  '[@@UpcomingDriver] COMPLETE_UPCOMING_DRIVER_TEST';

export const COMPLETE_UPCOMING_DRIVER_TEST_SUCCESS =
  '[@@UpcomingDriver] COMPLETE_UPCOMING_DRIVER_TEST_SUCCESS';

export const COMPLETE_UPCOMING_DRIVER_TEST_FAIL =
  '[@@UpcomingDriver] COMPLETE_UPCOMING_DRIVER_TEST_FAIL';

export const COMPLETE_UPCOMING_DRIVER_STEP =
  '[@@UpcomingDriver] COMPLETE_UPCOMING_DRIVER_STEP';

export const COMPLETE_UPCOMING_DRIVER_STEP_FAIL =
  '[@@UpcomingDriver] COMPLETE_UPCOMING_DRIVER_STEP_FAIL';

export const COMPLETE_UPCOMING_DRIVER_STEP_SUCCESS =
  '[@@UpcomingDriver] COMPLETE_UPCOMING_DRIVER_STEP_SUCCESS';

export const UPDATE_UPCOMING_DRIVER_TEST =
  '[@@UpcomingDriver] UPDATE_UPCOMING_DRIVER_TEST';

export interface IDeleteUpcomingDriverFileAction {
  type: typeof DELETE_UPCOMING_DRIVER_STEP_FILE;
  payload: IDeleteFileAction['payload'];
}

// GET UPCOMING DRIVERS
export const GET_UPCOMING_DRIVER =
  '[@@Accounting_Payrolls] GET_UPCOMING_DRIVER';
export const GET_UPCOMING_DRIVER_SUCCESS =
  '[@@Accounting_Payrolls] GET_DRIVER_SUCCESS';
export const GET_UPCOMING_DRIVER_FAIL =
  '[@@Accounting_Payrolls] GET_DRIVER_FAIL';
export const CLEAR_UPCOMING_DRIVER =
  '[@@Accounting_Payrolls] CLEAR_UPCOMING_DRIVER';
export const START_UPCOMING_DRIVER_STEP =
  '[@@Accounting_Payrolls] START_UPCOMING_DRIVER_STEP';

export interface IGetUpcomingDriverAction {
  type: typeof GET_UPCOMING_DRIVER;
  id?: string;
  dispatchToStore: boolean;
  callback?: () => void;
}
export interface IGetUpcomingDriverActionSuccess {
  type: typeof GET_UPCOMING_DRIVER_SUCCESS;
  payload: IUpcomingDriver;
}
export interface IGetUpcomingDriverActionFail {
  type: typeof GET_UPCOMING_DRIVER_FAIL;
  payload: AxiosError;
}
export interface IClearUpcomingDriver {
  type: typeof CLEAR_UPCOMING_DRIVER;
}
export interface IStartUpcomingDriverStep {
  type: typeof START_UPCOMING_DRIVER_STEP;
  payload: number;
}

export interface IUploadUpcomingDriverStepFilesSuccessAction {
  type: typeof UPLOAD_UPCOMING_DRIVER_FILE_SUCCESS;
  payload: {
    file: StepFile;
  };
}

export interface IDeleteUpcomingDriverStepFileSuccessAction {
  type: typeof DELETE_UPCOMING_DRIVER_STEP_FILE_SUCCESS;
  payload: {
    fileId: number;
    fileType: StepFile['file_type'];
  };
}

export interface IUploadUpcomingDriverTestFileSuccessAction {
  type: typeof UPLOAD_UPCOMING_DRIVER_TEST_FILE_SUCCESS;
  payload: {
    file: ILocalFile;
    testId: number | string;
  };
}

export interface IDeleteUpcomingDriverTestFilesAction {
  type: typeof DELETE_UPCOMING_DRIVER_TEST_FILE;
  payload: IDeleteFileAction['payload'] & {
    fileId: number | string;
  };
}

export interface IDeleteUpcomingDriverTestFileSuccessAction {
  type: typeof DELETE_UPCOMING_DRIVER_TEST_FILE_SUCCESS;
  payload: {
    fileId: number | string;
    testId: number | string;
  };
}

export interface IUploadUpcomingDriverTestFileFailAction {
  type: typeof UPLOAD_UPCOMING_DRIVER_TEST_FILE_FAIL;
  payload: AxiosError;
}

export interface IDeleteUpcomingDriverTestFileFailAction {
  type: typeof DELETE_UPCOMING_DRIVER_TEST_FILE_FAIL;
  payload: AxiosError;
}

export interface ICompleteUpcomingDriverTestAction {
  type: typeof COMPLETE_UPCOMING_DRIVER_TEST;
  payload: {
    actionType: 'failed' | 'passed';
    testId: number | string;
    stepId: number | string;
  };
}

export interface ICompleteUpcomingDriverTestSuccessAction {
  type: typeof COMPLETE_UPCOMING_DRIVER_TEST_SUCCESS;
  payload: {
    completedTest: ITest;
  };
}

export interface ICompleteUpcomingDriverTestFailAction {
  type: typeof COMPLETE_UPCOMING_DRIVER_TEST_FAIL;
  payload: AxiosError;
}

export interface ICompleteUpcomingDriverStepAction {
  type: typeof COMPLETE_UPCOMING_DRIVER_STEP;
  payload: {
    driverId: string;
    stepId: number;
    dataToSend?: { status?: string; fieldsToUpdate: IField[] };
    callback?: () => void;
  };
}

export interface ICompleteUpcomingDriverStepSuccessAction {
  type: typeof COMPLETE_UPCOMING_DRIVER_STEP_SUCCESS;
}

export interface IUpdateUpcomingDriverTest {
  type: typeof UPDATE_UPCOMING_DRIVER_TEST;
  payload: { id: number; comment: string };
}

export interface ICompleteUpcomingDriverStepFailAction {
  type: typeof COMPLETE_UPCOMING_DRIVER_STEP_FAIL;
  payload: AxiosError;
}

export const UPLOAD_PROCESS_FILE = '[@@UPCOMING_PROCESS] UPLOAD_PROCESS_FILE';
export const UPLOAD_PROCESS_FILE_SUCCESS =
  '[@@UPCOMING_PROCESS] UPLOAD_PROCESS_FILE_SUCCESS';
export const UPLOAD_PROCESS_FILE_FAIL =
  '[@@UPCOMING_PROCESS] UPLOAD_PROCESS_FILE_FAIL';
export const UPLOAD_PROCESS_FILE_FROM_PROFILE =
  '[@@UPCOMING_PROCESS] UPLOAD_PROCESS_FILE_FROM_PROFILE';

export interface IUploadProcessFileAction extends IUploadFilesAction {
  type: typeof UPLOAD_PROCESS_FILE;
}

export interface IUploadProcessFileSuccessAction {
  type: typeof UPLOAD_PROCESS_FILE_SUCCESS;
  payload: { file: IFile; field_id: number };
}

export interface IUploadProcessFileFromProfileAction {
  type: typeof UPLOAD_PROCESS_FILE_FROM_PROFILE;
  payload: { file: IFile; field_id: number };
}

export interface IUploadProcessFileFailAction {
  type: typeof UPLOAD_PROCESS_FILE_FAIL;
  payload: AxiosError;
}

export const DELETE_PROCESS_FILE = '[@@UPCOMING_PROCESS] DELETE_PROCESS_FILE';
export const DELETE_PROCESS_FILE_SUCCESS =
  '[@@UPCOMING_PROCESS] DELETE_PROCESS_FILE_SUCCESS';
export const DELETE_PROCESS_FILE_FAIL =
  '[@@UPCOMING_PROCESS] DELETE_PROCESS_FILE_FAIL';

export interface IDeleteProcessFileAction extends IDeleteFileAction {
  type: typeof DELETE_PROCESS_FILE;
}

export interface IDeleteProcessFileSuccessAction
  extends IDeleteFileActionSuccess {
  type: typeof DELETE_PROCESS_FILE_SUCCESS;
}

export interface IDeleteProcessFileFailAction extends IDeleteFileActionFail {
  type: typeof DELETE_PROCESS_FILE_FAIL;
}

export type upcomingDriverActionsType =
  | IUploadUpcomingDriverStepFilesAction
  | ISetUpcomingDriverCurrentStepAction
  | IUploadUpcomingDriverTestFilesAction
  | IUploadUpcomingDriverStepFilesSuccessAction
  | IUploadUpcomingDriverTestFileSuccessAction
  | IDeleteUpcomingDriverTestFileSuccessAction
  | IDeleteUpcomingDriverTestFilesAction
  | IDeleteUpcomingDriverFileAction
  | IDeleteUpcomingDriverStepFileSuccessAction
  | IUploadUpcomingDriverTestFileFailAction
  | IDeleteUpcomingDriverTestFileFailAction
  | ICompleteUpcomingDriverTestAction
  | ICompleteUpcomingDriverTestSuccessAction
  | ICompleteUpcomingDriverTestFailAction
  | ICompleteUpcomingDriverStepAction
  | ICompleteUpcomingDriverStepFailAction
  | ICompleteUpcomingDriverStepSuccessAction
  | IUpdateUpcomingDriverTest
  | IGetUpcomingDriverAction
  | IGetUpcomingDriverActionSuccess
  | IGetUpcomingDriverActionFail
  | IClearUpcomingDriver
  | IStartUpcomingDriverStep
  | ILogout
  | IUploadProcessFileAction
  | IUploadProcessFileSuccessAction
  | IUploadProcessFileFailAction
  | IDeleteProcessFileAction
  | IDeleteProcessFileSuccessAction
  | IDeleteProcessFileFailAction
  | IUploadProcessFileFromProfileAction;
