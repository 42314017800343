import { FC } from 'react';

type Props = {
  className?: string;
  fill?: string;
};

const IconSimpleNotification: FC<Props> = ({
  className,
  fill = 'var(--primaryText)',
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M13 11V12H14V11H13ZM3 11H2V12H3V11ZM1 10C0.447715 10 0 10.4477 0 11C0 11.5523 0.447715 12 1 12V10ZM15 12C15.5523 12 16 11.5523 16 11C16 10.4477 15.5523 10 15 10V12ZM6.5 14C5.94772 14 5.5 14.4477 5.5 15C5.5 15.5523 5.94772 16 6.5 16V14ZM9.5 16C10.0523 16 10.5 15.5523 10.5 15C10.5 14.4477 10.0523 14 9.5 14V16ZM12 6V11H14V6H12ZM13 10H3V12H13V10ZM4 11V6H2V11H4ZM8 2C10.2091 2 12 3.79086 12 6H14C14 2.68629 11.3137 0 8 0V2ZM8 0C4.68629 0 2 2.68629 2 6H4C4 3.79086 5.79086 2 8 2V0ZM1 12H15V10H1V12ZM6.5 16H8V14H6.5V16ZM8 16H9.5V14H8V16Z"
      fill={fill}
    />
  </svg>
);

export default IconSimpleNotification;
