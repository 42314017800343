import { FC, memo } from 'react';

type Props = {
  className?: string;
  fillColor?: string;
};

const IconAlert16: FC<Props> = ({
  className,
  fillColor = 'var(--colorsPaletteRed)',
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_14698_530280)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.31488 1.64432C6.41941 -0.571029 9.58035 -0.570854 10.6846 1.64461L15.6777 11.6618C16.6718 13.6563 15.2212 16.0001 12.9927 16.0001H3.00532C0.776665 16.0001 -0.673899 13.656 0.320518 11.6615L5.31488 1.64432ZM8.89467 2.53681C8.52657 1.79832 7.47293 1.79827 7.10475 2.53671L2.11039 12.5539C1.77891 13.2187 2.26244 14.0001 3.00532 14.0001H12.9927C13.7356 14.0001 14.2191 13.2188 13.8877 12.554L8.89467 2.53681ZM7.99993 5.0001C8.55221 5.0001 8.99993 5.44781 8.99993 6.0001V9.0001C8.99993 9.55238 8.55221 10.0001 7.99993 10.0001C7.44764 10.0001 6.99993 9.55238 6.99993 9.0001V6.0001C6.99993 5.44781 7.44764 5.0001 7.99993 5.0001ZM8.99993 12.0001C8.99993 12.5524 8.55221 13.0001 7.99993 13.0001C7.44764 13.0001 6.99993 12.5524 6.99993 12.0001C6.99993 11.4478 7.44764 11.0001 7.99993 11.0001C8.55221 11.0001 8.99993 11.4478 8.99993 12.0001Z"
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath id="clip0_14698_530280">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default memo(IconAlert16);
