import { IChangePassword } from 'components/Auth/_models/changePasswordModels';
import { IForgotPassword } from 'components/Auth/_models/forgotPasswordModels';
import {
  ILoginFormData,
  IPatchFullRegCompany,
  IPostRegCompany,
} from 'components/Auth/_models/loginModels';
import { IResetPassword } from 'components/Auth/_models/resetPassword';
import {
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  LOGIN,
  LOGOUT,
  REFRESH_USER,
  RESET_PASSWORD,
  SET_ONLINE,
  SET_OFFLINE,
  REFRESH_TOKEN,
  SET_PREV_USER_EMAIL,
  REG_COMPANY,
  FULL_REG_COMPANY,
  PLAN_REDIRECT,
} from 'components/Auth/_redux/authTypes';
import { clearAuthHeaderToken } from 'utils/requests';

export const loginAction = (values: ILoginFormData) => ({
  type: LOGIN,
  payload: values,
});

export const refreshTokenAction = (values: { token: string }) => ({
  type: REFRESH_TOKEN,
  payload: values,
});

export const forgotPasswordAction = (values: IForgotPassword) => ({
  type: FORGOT_PASSWORD,
  payload: values,
});

export const resetPasswordAction = (
  values: IResetPassword & { reset_password_token: string },
) => ({
  type: RESET_PASSWORD,
  payload: values,
});

export const changePasswordAction = (values: IChangePassword) => ({
  type: CHANGE_PASSWORD,
  payload: values,
});

export const refreshUserAction = () => ({ type: REFRESH_USER });

export const logoutAction = () => {
  clearAuthHeaderToken();
  return { type: LOGOUT };
};

export const setOnlineAction = () => ({ type: SET_ONLINE });
export const setOfflineAction = () => ({ type: SET_OFFLINE });

export const setPrevUserEmailAction = (email: string) => ({
  type: SET_PREV_USER_EMAIL,
  payload: email,
});

export const postRegisterCompanyStart = (values: IPostRegCompany) => ({
  type: REG_COMPANY,
  payload: values,
});

export const postRegisterCompanyFinish = (values: IPatchFullRegCompany) => ({
  type: FULL_REG_COMPANY,
  payload: values,
});

export const planRedirect = (plan: string) => ({
  type: PLAN_REDIRECT,
  payload: plan,
});
