import { LOGOUT } from 'components/Auth/_redux/authTypes';
import { IWithError } from 'utils/models';

import {
  allTypes,
  CLEAR_INSPECTIONS_ERROR,
  DELETE_VEHICLE_INSPECTION_FAIL,
  SET_IS_JUST_CREATED,
  UNLOCK_VEHICLE_INSPECTION_FAIL,
  HANDLE_SAVE_DRAFT,
  HANDLE_SAVE_DRAFT_SUCCESS,
  HANDLE_SET_INSPECTION_DRIVER_CHANGED,
  SET_INSPECTION_CLAIMS_COUNT,
} from './allInspectionsTypes';

export interface IAllInspectionsState extends IWithError {
  isJustCreated: boolean;
  isInspectionDriverChanged: boolean;
  saveDraftHandler: number;
  successSaveDraftHandler: number;
  claimsCount: number;
}

const initialState: IAllInspectionsState = {
  isJustCreated: false,
  isInspectionDriverChanged: false,
  saveDraftHandler: 0,
  successSaveDraftHandler: 0,
  claimsCount: 0,
  _error: null,
};

const allInspectionsReducer = (
  state = initialState,
  action: allTypes,
): IAllInspectionsState => {
  switch (action.type) {
    case DELETE_VEHICLE_INSPECTION_FAIL:
    case UNLOCK_VEHICLE_INSPECTION_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    case SET_IS_JUST_CREATED:
      return {
        ...state,
        isJustCreated: action.payload,
      };

    case CLEAR_INSPECTIONS_ERROR:
      return {
        ...state,
        _error: null,
      };

    case HANDLE_SAVE_DRAFT:
      return {
        ...state,
        saveDraftHandler:
          action.payload !== undefined
            ? action.payload
            : state.saveDraftHandler + 1,
      };

    case HANDLE_SAVE_DRAFT_SUCCESS:
      return {
        ...state,
        successSaveDraftHandler: state.successSaveDraftHandler + 1,
      };

    case HANDLE_SET_INSPECTION_DRIVER_CHANGED:
      return {
        ...state,
        isInspectionDriverChanged: !!action.payload,
      };

    case SET_INSPECTION_CLAIMS_COUNT:
      return {
        ...state,
        claimsCount: action.payload,
      };

    case LOGOUT:
      return { ...initialState, isJustCreated: state.isJustCreated };

    default:
      return state;
  }
};

export default allInspectionsReducer;
