import {
  FC,
  ChangeEvent,
  FocusEvent,
  useEffect,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { LocalizationProvider, DesktopTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import clsx from 'clsx';
import moment from 'moment';
import ReactInputMask from 'react-input-mask';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import { IconClock } from 'assets/icons/components';
import useTestAttrRef from 'hooks/useTestAttrRef';
import { padNumber } from 'utils/helpers';

import { checkValidDate } from '../../../../../utils/validation';

import styles from '../CustomDatePicker/CustomDatePicker.module.scss';

const mask24 = [/[0-9]/, /[0-9]/, ':', /[0-9]/, /[0-9]/];
const mask12 = [...mask24, ' ', /a|A|p|P/, 'm'];

type OwnProps = {
  className?: string;
  name?: string;
  placeholder?: string;
  readOnly?: boolean;
  ampm?: boolean;
  step?: number;
  childComponent?: JSX.Element;
  focusOnRender?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  touched?: boolean;
  value?: Date | string | null;
  onChange?: (val?: string | null) => void;
};

const MaskedTimePicker: FC<OwnProps> = ({
  className,
  name,
  onChange,
  value,
  invalid,
  touched,
  readOnly,
  ampm = true,
  step,
  childComponent,
  focusOnRender,
  disabled,
  placeholder = ampm ? 'hh:mm am' : 'hh:mm',
}) => {
  const inputRef = useTestAttrRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef?.current) {
      const parentName = inputRef.current.getAttribute('data-test');
      const timePickerButton =
        inputRef.current.parentElement?.querySelector('button');
      timePickerButton?.setAttribute('data-test', `${parentName}_button`);
    }
  }, [inputRef?.current]);

  const [localValue, setLocalValue] = useState('');

  const valueDate = useMemo(() => {
    let vDate: Date | null = null;
    let vStr = '';
    let time = moment(value, 'HH:mm', true);

    if (!time.isValid()) time = moment(value, 'hh:mm a', true);

    // if (!time.isValid()) time = moment(value); // TODO uncomment if it breaks something:)

    if (time.isValid()) {
      vDate = time.toDate();
      vStr = time.format(ampm ? 'hh:mm a' : 'HH:mm');
    }
    setLocalValue(vStr);
    return vDate;
  }, [value, ampm]);

  const handleTimeChange1 = useCallback(
    (date?: Date | null) => {
      const realValue = checkValidDate(date) ? null : date;
      if (onChange)
        onChange(realValue ? moment(realValue).format('HH:mm') : null);
    },
    [onChange, ampm],
  );

  const handleTimeChange2 = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value || '';
    setLocalValue(inputValue);
  }, []);

  const handleTimeBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (onChange) {
        let newValue: string | null = null;

        let blurValue = e.currentTarget.value || '';
        if (blurValue.length) {
          if (ampm && (blurValue.length === 3 || blurValue.length === 6)) {
            let hours = Number(blurValue.slice(0, 2));
            if (hours > 12) {
              hours -= 12;
              if (blurValue.length === 3)
                blurValue = `${padNumber(hours, 2)}:00 pm`;
              else blurValue = `${padNumber(hours, 2)}${blurValue.slice(2)}pm`;
            }
          }

          if (blurValue.length < 5) {
            const arr = blurValue.split('').filter(s => s !== ':');
            arr.unshift('0');
            arr.splice(2, 0, ':');
            blurValue = arr.join('');
          }
          const completeVal = ampm ? '00:00 am' : '00:00';
          if (blurValue.length < completeVal.length)
            blurValue = `${blurValue}${completeVal.substring(
              blurValue.length,
            )}`;

          if (ampm && blurValue[0] === '0' && blurValue[1] === '0')
            blurValue = `12${blurValue.slice(2)}`;

          const time = moment(blurValue, ampm ? 'hh:mm a' : 'HH:mm', true);
          if (time.isValid()) {
            setLocalValue(blurValue);
            newValue = time.format('HH:mm');
          }
        }

        if (!newValue) setLocalValue('');
        onChange(newValue);
      }
    },
    [onChange, ampm],
  );

  return (
    <>
      <DesktopTimePicker
        inputRef={inputRef}
        value={valueDate}
        onChange={handleTimeChange1}
        components={{ OpenPickerIcon: IconClock }}
        OpenPickerButtonProps={{
          tabIndex: -1,
        }}
        ampm={ampm}
        ampmInClock={ampm}
        minutesStep={step}
        readOnly={readOnly}
        disabled={disabled}
        InputProps={{
          className: clsx(
            styles.root,
            { [styles.error]: invalid && touched },
            className,
          ),
          autoFocus: focusOnRender,
          readOnly,
          name,
        }}
        renderInput={props => {
          const { inputProps, ...restProps } = props;
          return (
            <ReactInputMask
              mask={ampm ? mask12 : mask24}
              alwaysShowMask={false}
              maskPlaceholder={null}
              value={localValue}
              onChange={handleTimeChange2}
              onBlur={handleTimeBlur}
              disabled={disabled}
            >
              <TextField {...restProps} placeholder={placeholder} fullWidth />
            </ReactInputMask>
          );
        }}
      />
      {childComponent && (
        <span className={styles.childComponent}>{childComponent}</span>
      )}
    </>
  );
};

export { MaskedTimePicker };

type Props = OwnProps & WrappedFieldInputProps & WrappedFieldMetaProps;

const CustomTimePicker: FC<Partial<Props>> = props => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MaskedTimePicker {...props} />
    </LocalizationProvider>
  );
};

export default CustomTimePicker;
