import { FC, FocusEvent, useEffect, useState } from 'react';

import clsx from 'clsx';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { WrappedFieldMetaProps, WrappedFieldInputProps } from 'redux-form';

import useTestAttrRef, { getAttrName } from 'hooks/useTestAttrRef';

import styles from './Input.module.scss';

type OwnProps = {
  id: string;
  placeholder: string;
  className: string;
  readOnly: boolean;
  icon: string | JSX.Element;
  disabled: boolean;
  dollarSign?: boolean;
  allowNegative?: boolean;
  thousandSeparator?: boolean;
  fixedDecimalScale?: boolean;
  decimalScale?: number;
  isAllowed?: (values: NumberFormatValues) => boolean;
  checkbox?: JSX.Element;
  index?: number;
  dataTestAttr?: string;
  focusOnRender?: boolean;
  labelText?: string;
  checkForDisabled?: boolean;
};

type Props = OwnProps & WrappedFieldInputProps & WrappedFieldMetaProps;

const NumberInput: FC<Partial<Props>> = ({
  id,
  placeholder,
  className,
  readOnly,
  icon,
  disabled,
  error,
  touched,
  value,
  name,
  dollarSign,
  allowNegative,
  thousandSeparator = true,
  fixedDecimalScale = true,
  decimalScale,
  labelText,
  onChange,
  onBlur,
  isAllowed,
  checkbox,
  index,
  dataTestAttr,
  focusOnRender,
  checkForDisabled,
}) => {
  const ref = useTestAttrRef<HTMLInputElement>(
    null,
    dataTestAttr ||
      (typeof labelText === 'string' && getAttrName(labelText)) ||
      '',
  );

  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (focusOnRender && ref.current) {
      ref.current.focus();
      setFocused(true);
    }
  }, [focusOnRender]);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e);
    }
    setFocused(false);
  };

  return (
    <div className={styles.root}>
      {icon && <i className={styles.icon}>{icon}</i>}
      <NumberFormat
        id={id}
        getInputRef={ref}
        value={value}
        thousandSeparator={!focused && thousandSeparator}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        isAllowed={isAllowed}
        className={clsx(styles.input, className, {
          [styles.hasError]:
            (touched && !!error) || (checkForDisabled && !!error),
          [styles.withIcon]: icon,
          [styles.disabled]: disabled && !checkForDisabled,
        })}
        readOnly={readOnly}
        disabled={disabled}
        autoComplete="off"
        allowNegative={allowNegative}
        decimalScale={decimalScale}
        fixedDecimalScale={!focused && fixedDecimalScale}
        attr-index={index}
      />
      {checkbox && <div className={styles.checkbox}>{checkbox}</div>}
      {dollarSign && (
        <span
          className={clsx(styles.sign, {
            [styles.signVisible]: value,
          })}
        >
          $
        </span>
      )}
    </div>
  );
};

export default NumberInput;
