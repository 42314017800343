import { AxiosError } from 'axios';

import { TimeRange } from 'components/_common/TimeRangeSelector/TimeRangeInput';
import { IDefaultReducer } from 'utils/models';

// TODO: ADD LOGOUT
// import { ILogout } from 'redux/_common/models';
import { AccidentsStatistics } from '../_models/accidentsStatistics';

export interface IAccidentsStatisticsState extends IDefaultReducer {
  statistics: AccidentsStatistics | null;
}

// GET_ACCIDENTS_STATISTICS
export const GET_ACCIDENTS_STATISTICS =
  '[@@AccidentsStatistics] GET_ACCIDENTS_STATISTICS';
export const GET_ACCIDENTS_STATISTICS_SUCCESS =
  '[@@AccidentsStatistics] GET_ACCIDENTS_STATISTICS_SUCCESS';
export const GET_ACCIDENTS_STATISTICS_FAIL =
  '[@@AccidentsStatistics] GET_ACCIDENTS_STATISTICS_FAIL';

export interface IGetAccidentsStatisticsAction {
  type: typeof GET_ACCIDENTS_STATISTICS;
  payload: {
    timeRange: TimeRange;
    callback?: () => void;
  };
}

export interface IGetAccidentsStatisticsActionSuccess {
  type: typeof GET_ACCIDENTS_STATISTICS_SUCCESS;
  payload: AccidentsStatistics;
}

export interface IGetAccidentsStatisticsActionFail {
  type: typeof GET_ACCIDENTS_STATISTICS_FAIL;
  payload: AxiosError;
}

export type accidentsStatisticsTypes =
  | IGetAccidentsStatisticsAction
  | IGetAccidentsStatisticsActionFail
  | IGetAccidentsStatisticsActionSuccess;
