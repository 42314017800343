import { createSelector } from 'reselect';

import { AppState } from 'redux/store';
import { emptyArr } from 'utils/helpers';

import { ISocketReducer } from './socketReducers';

const socketSelector = (state: AppState): ISocketReducer => state.socket;

export const socketChannelSelector =
  <MessageType>(channelIdentifier: string) =>
  (state: AppState) =>
    createSelector(
      [socketSelector, (_, channelId: string) => channelId],
      (socket, channelId) => {
        const { _opened, _error, channels } = socket;
        const {
          messages: channelMessages,
          _pending,
          _connected,
        } = channels[channelId] || {};
        const messages = (channelMessages || emptyArr) as MessageType[];
        return {
          _opened, // socket is opened
          _connected, // channel is connected
          _error,
          _pending,
          messages,
          lastMessage: messages.first(), // due to messages are descending sorted
        };
      },
    )(state, channelIdentifier);
