import { FC } from 'react';

import { useSelector } from 'react-redux';

import { userThemeSelector } from 'redux/userSettings/userSettingsSelectors';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const LittleFuel: FC<IOwnProps> = ({ className, fillColor = '#2D2F36' }) => {
  const theme = useSelector(userThemeSelector);

  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15 14H3V6L7.61538 1H15V14Z"
        stroke={theme === 'dark' ? 'white' : fillColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 5H11"
        stroke={theme === 'dark' ? 'white' : fillColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 1C2 1 2.96398 1.14265 3.5 1.5C4.03602 1.85735 4.76271 2.401 5 2.99993"
        stroke={theme === 'dark' ? 'white' : fillColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LittleFuel;
