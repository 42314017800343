import { takeLeading, call, put } from 'redux-saga/effects';

import api from 'utils/requests';

import {
  setHumanResourcesLabelAction,
  deleteHumanResourcesLabelAction,
} from './humanResourcesGroupActions';
import {
  SET_HR_LABEL,
  DELETE_HR_LABEL,
  SET_HR_LABEL_SUCCESS,
  SET_HR_LABEL_FAIL,
  DELETE_HR_LABEL_SUCCESS,
  DELETE_HR_LABEL_FAIL,
  ISetLabelActionSuccess,
  ISetLabelActionFail,
  IDeleteLabelActionFail,
  IDeleteLabelActionSuccess,
} from './humanResourcesGroupTypes';

// LABELS
function* setHumanResourcesLabelActionSaga({
  payload: { data, callback },
}: ReturnType<typeof setHumanResourcesLabelAction>) {
  const { id, endPoint, labelKey, values } = data;
  const url = `/v1/humanResourcess/${endPoint}/${id}/labels/${labelKey}`;
  try {
    yield call(() =>
      api.post(url, {
        [`humanResourcess_${endPoint}_label`]: values,
      }),
    );
    yield put<ISetLabelActionSuccess>({ type: SET_HR_LABEL_SUCCESS });
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<ISetLabelActionFail>({ type: SET_HR_LABEL_FAIL, payload: e });
  }
}

function* deleteHumanResourcesLabelActionSaga({
  payload: { data, callback },
}: ReturnType<typeof deleteHumanResourcesLabelAction>) {
  const { id, endPoint, labelKey } = data;
  const url = `/v1/humanResourcess/${endPoint}/${id}/labels/${labelKey}`;

  try {
    yield call(() => api.delete(url));
    yield put<IDeleteLabelActionSuccess>({ type: DELETE_HR_LABEL_SUCCESS });
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IDeleteLabelActionFail>({
        type: DELETE_HR_LABEL_FAIL,
        payload: e,
      });
  }
}

export default function* humanResourcesSagas() {
  yield takeLeading(SET_HR_LABEL, setHumanResourcesLabelActionSaga);
  yield takeLeading(DELETE_HR_LABEL, deleteHumanResourcesLabelActionSaga);
}
