import { FC } from 'react';

type OwnProps = {
  className?: string;
  color?: string;
};

const IconRepeat: FC<OwnProps> = ({
  className,
  color = 'var(--primaryText)',
}) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.65643 10.9596C2.31102 12.3626 3.41228 13.5091 4.78788 14.2195C6.16349 14.93 7.73581 15.1643 9.25879 14.8859C10.7818 14.6075 12.1695 13.832 13.2048 12.6808C14.2401 11.5297 14.8645 10.0677 14.9804 8.52386C15.0962 6.97998 14.697 5.44124 13.8451 4.14844C12.9933 2.85564 11.7368 1.88173 10.2724 1.37913C8.80807 0.876516 7.21839 0.873564 5.75216 1.37073C4.28592 1.8679 3.02589 2.83713 2.16922 4.12676"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M1 1V5H5" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default IconRepeat;
