import { change, startSubmit, stopSubmit } from 'redux-form';
import { takeLatest, call, put, select, takeLeading } from 'redux-saga/effects';

import { setPaginationAction } from 'components/_common/Navigation/Pagination/_redux/paginationActions';
import { paginationSelector } from 'components/_common/Navigation/Pagination/_redux/paginationSelectors';
import {
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_FAIL,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_FAIL,
  GET_RECRUITERS_SUCCESS,
  GET_RECRUITERS_FAIL,
  CREATE_EMPLOYEE_SUCCESS,
  CREATE_EMPLOYEE_FAIL,
  DELETE_EMPLOYEE_FILE_SUCCESS,
  DELETE_EMPLOYEE_FILE_FAIL,
  UPLOAD_EMPLOYEE_FILES_SUCCESS,
  UPLOAD_EMPLOYEE_FILES_FAIL,
  DELETE_EMPLOYEE_PHOTO_SUCCESS,
  DELETE_EMPLOYEE_PHOTO_FAIL,
  UPLOAD_EMPLOYEE_PHOTO_SUCCESS,
  UPLOAD_EMPLOYEE_PHOTO_FAIL,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_FAIL,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_FAIL,
  GET_EMPLOYEES,
  GET_EMPLOYEE,
  CREATE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  DELETE_EMPLOYEE,
  UPLOAD_EMPLOYEE_PHOTO,
  UPLOAD_EMPLOYEE_FILES,
  DELETE_EMPLOYEE_PHOTO,
  DELETE_EMPLOYEE_FILE,
  GET_RECRUITERS,
} from 'components/HumanResources/TabEmployees/_redux/employeesTypes';
import { getErrors } from 'utils/helpers';
import api from 'utils/requests';

import { fileToFormData } from '../../../../utils/converters';
import {
  createEmployeeAction,
  deleteEmployeeAction,
  deleteEmployeeFileAction,
  deleteEmployeePhotoAction,
  getEmployeeAction,
  updateEmployeeAction,
  uploadEmployeeFilesAction,
  uploadEmployeePhotoAction,
  getEmployeesAction,
} from './employeesActions';

function* getEmployeesSaga({
  payload: {
    data: { query, usePaginationFlag, preventUpdatePagination },
  },
}: ReturnType<typeof getEmployeesAction>) {
  try {
    const { per_page, page } = yield select(paginationSelector);
    const { data } = yield call(() =>
      api.get(
        `/v1/hr/employees?page=${page}&per_page=${per_page}${query || ''}`,
      ),
    );
    yield put({
      type: GET_EMPLOYEES_SUCCESS,
      payload: { ...data, preventUpdatePagination },
    });
    const { pagination } = data;
    // Pagination set
    if (usePaginationFlag) yield put(setPaginationAction(pagination));
  } catch (e) {
    yield put({ type: GET_EMPLOYEES_FAIL, payload: e });
  }
}

function* getEmployeeSaga({ id }: ReturnType<typeof getEmployeeAction>) {
  try {
    const { data } = yield call(() => api.get(`/v1/hr/employees/${id}`));
    yield put({ type: GET_EMPLOYEE_SUCCESS, payload: data.employee });
  } catch (e) {
    yield put({ type: GET_EMPLOYEE_FAIL, payload: e });
  }
}

function* getRecruitersSaga() {
  try {
    const { data } = yield call(() => api.get(`/v1/hr/employees/recruiters`));
    yield put({
      type: GET_RECRUITERS_SUCCESS,
      payload: Object.entries(data).map(r => ({ id: r[0], name: r[1] })),
    });
  } catch (e) {
    yield put({ type: GET_RECRUITERS_FAIL, payload: e });
  }
}

function* createEmployeeSaga({
  payload: { values, callback },
}: ReturnType<typeof createEmployeeAction>) {
  try {
    yield put(startSubmit('createAndUpdateEmployeeForm'));
    const { data } = yield call(() =>
      api.post('/v1/hr/employees', { employee: values }),
    );
    yield put({ type: CREATE_EMPLOYEE_SUCCESS });
    yield put(stopSubmit('createAndUpdateEmployeeForm'));
    callback(data.employee.id);
  } catch (e) {
    yield put({ type: CREATE_EMPLOYEE_FAIL, payload: e });
    if (api.isAxiosError(e))
      yield put(stopSubmit('createAndUpdateEmployeeForm', getErrors(e)));
  }
}

function* updateEmployeeSaga({
  payload: { callback, values },
}: ReturnType<typeof updateEmployeeAction>) {
  try {
    yield call(() =>
      api.patch(`/v1/hr/employees/${values.id}`, { employee: values }),
    );
    yield put({ type: UPDATE_EMPLOYEE_SUCCESS });
    callback();
  } catch (e) {
    yield put({ type: UPDATE_EMPLOYEE_FAIL, payload: e });
  }
}

function* deleteEmployeeSaga({
  payload: { id, callback },
}: ReturnType<typeof deleteEmployeeAction>) {
  try {
    yield call(() => api.delete(`/v1/hr/employees/${id}`));
    yield put({ type: DELETE_EMPLOYEE_SUCCESS });
    if (callback) callback();
  } catch (e) {
    yield put({ type: DELETE_EMPLOYEE_FAIL, payload: e });
  }
}

// EMPLOYEE PHOTO START
function* uploadEmployeePhotoSaga({
  payload: { employeeId, photo },
}: ReturnType<typeof uploadEmployeePhotoAction>) {
  const url = employeeId
    ? `/v1/hr/employees/${employeeId}/photo`
    : `/v1/hr/employees/photos`;

  try {
    const { data } = yield call(() =>
      api.post(url, fileToFormData(photo, `hr_employees_photo[file]`), {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    );
    const uploadedPhoto = { id: data.file.id, url: data.file.file_url_thumb };
    yield put(change('createAndUpdateEmployeeForm', 'photo', uploadedPhoto));
    yield put({
      type: UPLOAD_EMPLOYEE_PHOTO_SUCCESS,
      payload: data.file.file_url_thumb,
    });
  } catch (e) {
    yield put({ type: UPLOAD_EMPLOYEE_PHOTO_FAIL, payload: e });
  }
}

function* deleteEmployeePhotoSaga({
  payload: { employeeId, photoId },
}: ReturnType<typeof deleteEmployeePhotoAction>) {
  const url = employeeId
    ? `/v1/hr/employees/${employeeId}/photo`
    : `/v1/hr/employees/photos/${photoId}`;

  try {
    yield call(() => api.delete(url));
    yield put(change('createAndUpdateEmployeeForm', 'photo', ''));
    yield put({ type: DELETE_EMPLOYEE_PHOTO_SUCCESS });
  } catch (e) {
    yield put({ type: DELETE_EMPLOYEE_PHOTO_FAIL, payload: e });
  }
}
// EMPLOYEE PHOTO END

// EMPLOYEE FILES START
function* uploadEmployeeFilesSaga({
  payload: { files, itemId: employeeId },
}: ReturnType<typeof uploadEmployeeFilesAction>) {
  const url = employeeId
    ? `/v1/hr/employees/${employeeId}/files`
    : `/v1/hr/employees/files`;

  for (const fileObj of files) {
    yield call(function* foo() {
      try {
        const { data } = yield call(() =>
          api.post(
            url,
            fileToFormData(fileObj.file, `hr_employees_file[file]`),
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            },
          ),
        );
        yield put({ type: UPLOAD_EMPLOYEE_FILES_SUCCESS, payload: data.file });
      } catch (e) {
        yield put({ type: UPLOAD_EMPLOYEE_FILES_FAIL, payload: e });
      }
    });
  }
}

function* deleteEmployeeFileSaga({
  payload: { fileId, itemId: employeeId },
}: ReturnType<typeof deleteEmployeeFileAction>) {
  const url = employeeId
    ? `/v1/hr/employees/${employeeId}/files/${fileId}`
    : `/v1/hr/employees/files/${fileId}`;

  try {
    yield call(() => api.delete(url));
    yield put({ type: DELETE_EMPLOYEE_FILE_SUCCESS, payload: fileId });
  } catch (e) {
    yield put({ type: DELETE_EMPLOYEE_FILE_FAIL, payload: e });
  }
}
// EMPLOYEE FILES END

export default function* employeesSaga() {
  yield takeLatest(GET_EMPLOYEES, getEmployeesSaga);
  yield takeLatest(GET_RECRUITERS, getRecruitersSaga);
  yield takeLatest(GET_EMPLOYEE, getEmployeeSaga);
  yield takeLeading(CREATE_EMPLOYEE, createEmployeeSaga);
  yield takeLatest(UPDATE_EMPLOYEE, updateEmployeeSaga);
  yield takeLatest(DELETE_EMPLOYEE, deleteEmployeeSaga);
  yield takeLatest(UPLOAD_EMPLOYEE_PHOTO, uploadEmployeePhotoSaga);
  yield takeLatest(UPLOAD_EMPLOYEE_FILES, uploadEmployeeFilesSaga);
  yield takeLeading(DELETE_EMPLOYEE_PHOTO, deleteEmployeePhotoSaga);
  yield takeLeading(DELETE_EMPLOYEE_FILE, deleteEmployeeFileSaga);
}
