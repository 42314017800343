import { lazy } from 'react';

import {
  PERMISSION_CATEGORY,
  PERMISSION_CREATE_LOCATION,
  PERMISSION_CREATE_VENDOR,
  PERMISSION_EDIT_LOCATION,
  PERMISSION_EDIT_VENDOR,
  PERMISSION_ITEMS,
  PERMISSION_LOCATION,
  PERMISSION_VENDOR,
} from 'constants/permissions';

import PrivateRoutes from '../PrivateRoutes/PrivateRoutes';

const CreateAndEditVendor = lazy(
  () =>
    import(
      'components/Inventory/TabVendors/CreateAndEditVendor/CreateAndEditVendor'
    ),
);
const ItemProfile = lazy(
  () => import('components/Inventory/TabItems/ItemProfile/ItemProfile'),
);
const VendorProfile = lazy(
  () => import('components/Inventory/TabVendors/VendorProfile/VendorProfile'),
);
const TabLocations = lazy(
  () => import('components/Inventory/TabLocations/TabLocations'),
);
const TabCategories = lazy(
  () => import('components/Inventory/TabCategories/TabCategories'),
);
const InventoryItems = lazy(
  () => import('components/Inventory/TabItems/InventoryItems/InventoryItems'),
);
const TabVendors = lazy(
  () => import('components/Inventory/TabVendors/TabVendors'),
);
const CreateEditLocation = lazy(
  () =>
    import(
      'components/Inventory/TabLocations/CreateEditLocation/CreateEditLocation'
    ),
);
const LocationProfile = lazy(
  () =>
    import('components/Inventory/TabLocations/LocationProfile/LocationProfile'),
);

/* Locations */
export const mainLocationsRoute = (
  <PrivateRoutes
    exact
    path="/fleet/inventory/locations"
    component={TabLocations}
    permissionType={PERMISSION_LOCATION}
  />
);
export const locationCreateRoute = (
  <PrivateRoutes
    exact
    path="/fleet/inventory/locations/create"
    component={CreateEditLocation}
    permissionType={PERMISSION_CREATE_LOCATION}
  />
);
export const locationEditRoute = (
  <PrivateRoutes
    exact
    path="/fleet/inventory/locations/:locationId/edit"
    component={CreateEditLocation}
    permissionType={PERMISSION_EDIT_LOCATION}
  />
);
export const locationProfileRoute = (
  <PrivateRoutes
    exact
    path="/fleet/inventory/locations/:locationId"
    component={LocationProfile}
    permissionType={PERMISSION_LOCATION}
  />
);

/* Categories */
export const mainCategoriesRoute = (
  <PrivateRoutes
    exact
    path="/fleet/inventory/categories"
    component={TabCategories}
    permissionType={PERMISSION_CATEGORY}
  />
);

/* Items */
export const mainItemsRoute = (
  <PrivateRoutes
    exact
    path="/fleet/inventory/items"
    component={InventoryItems}
    permissionType={PERMISSION_ITEMS}
  />
);
export const itemProfileRoute = (
  <PrivateRoutes
    exact
    path="/fleet/inventory/items/:id"
    component={ItemProfile}
    permissionType={PERMISSION_ITEMS}
  />
);

/* Vendors */
export const mainVendorsRoute = (
  <PrivateRoutes
    exact
    path="/fleet/inventory/vendors"
    component={TabVendors}
    permissionType={PERMISSION_VENDOR}
  />
);
export const vendorCreateRoute = (
  <PrivateRoutes
    exact
    path="/fleet/inventory/vendors/create"
    component={CreateAndEditVendor}
    permissionType={PERMISSION_CREATE_VENDOR}
  />
);
export const vendorEditRoute = (
  <PrivateRoutes
    exact
    path="/fleet/inventory/vendors/:vendorId/edit"
    component={CreateAndEditVendor}
    permissionType={PERMISSION_EDIT_VENDOR}
  />
);
export const vendorProfileRoute = (
  <PrivateRoutes
    exact
    path="/fleet/inventory/vendors/:id"
    component={VendorProfile}
    permissionType={PERMISSION_VENDOR}
  />
);
