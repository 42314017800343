import { FC, memo, CSSProperties } from 'react';

import clsx from 'clsx';

import {
  IconUserPhoto,
  LittleTruck,
  LittleTrailer,
  IconPhoto,
} from 'assets/icons/components';
import { getFirstLettersFromString } from 'utils/helpers';

import styles from './Avatar.module.scss';

type Props = {
  square?: boolean;
  online?: boolean;
  avatar?: string | null;
  placeholder?: JSX.Element;
  defaultUserPhoto?: boolean;
  withLittleTruck?: boolean;
  withLittleTrailer?: boolean;
  defaultItemPhoto?: boolean;
  name?: string | null;
  size?: number;
  className?: string;
  style?: CSSProperties;
  filledBackground?: boolean;
};

const Avatar: FC<Props> = ({
  online,
  avatar,
  placeholder,
  defaultUserPhoto,
  name,
  size = 48,
  className,
  style,
  withLittleTruck,
  withLittleTrailer,
  defaultItemPhoto,
  square,
  filledBackground,
}) => (
  <div
    className={clsx(styles.root, className, {
      [styles.abbreviationName]: name,
      [styles.rootSquare]: square,
    })}
    style={{ height: size, width: size, minWidth: size, ...style }}
  >
    {online && <span className={styles.online} />}

    {avatar && (
      <div
        className={styles.avatar}
        style={{ backgroundImage: `url(${avatar})` }}
      />
    )}
    {!avatar && placeholder}

    {defaultUserPhoto && <IconUserPhoto className={styles.defaultUserPhoto} />}

    {withLittleTruck && (
      <LittleTruck
        className={styles.withLittleTruck}
        fillColor={filledBackground ? 'var(--primaryBg)' : 'var(--primaryText)'}
      />
    )}
    {withLittleTrailer && (
      <LittleTrailer
        className={styles.withLittleTruck}
        fillColor={filledBackground ? 'var(--primaryBg)' : 'var(--primaryText)'}
      />
    )}
    {defaultItemPhoto && <IconPhoto className={styles.withLittleTruck} />}

    {name && <>{getFirstLettersFromString(name).substring(0, 2)}</>}
  </div>
);

export default memo(Avatar);
