import { memo, useMemo, useRef } from 'react';

import { useSelector } from 'react-redux';

import useOnClickOutside from 'hooks/useOnClickOutside';
import { userSettingsSelector } from 'redux/userSettings/userSettingsSelectors';

import { SIDEBAR_DROP_MENU_CONFIG } from '../_config';
import SidebarDropMenuLink from './SidebarDropMenuLink';

import styles from './SidebarDropMenu.module.scss';

type Props = {
  activePath: string;
  onClickOutside: (e: MouseEvent | TouchEvent) => void;
};

const SidebarDropMenu = ({ activePath, onClickOutside }: Props) => {
  const dropMenuRef = useRef(null);

  useOnClickOutside({ ref: dropMenuRef, callback: onClickOutside });

  const { company, currentPermissions } = useSelector(userSettingsSelector);

  const menuConfig = useMemo(() => {
    const isWiseCheck = company?.product_menu_type === 'wisecheck';
    let configMenu = SIDEBAR_DROP_MENU_CONFIG;

    if (!isWiseCheck && currentPermissions) {
      const allowedMenuNames = currentPermissions.map(
        permission => permission.name,
      );
      configMenu = SIDEBAR_DROP_MENU_CONFIG.filter(
        item => allowedMenuNames.includes(item.title) && item.id !== 'wise',
      );
    }

    return configMenu;
  }, [company?.product_menu_type, currentPermissions]);

  return (
    <section className={styles.root} ref={dropMenuRef}>
      {menuConfig.map(item => (
        <SidebarDropMenuLink
          key={item.id}
          item={item}
          selected={activePath.startsWith(item.path)}
        />
      ))}
    </section>
  );
};

export default memo(SidebarDropMenu);
