import moment from 'moment';
import { NumberFormatValues } from 'react-number-format';

import TimeRange from 'components/_common/TimeRange';
import {
  IOrderAddress,
  OrderAddressField,
} from 'components/Dispatch/TabOrders/_models/orderModels';
import {
  DEFAULT_MIN_DOB,
  DEFAULT_MAX_DOB,
  DEFAULT_MAX_HIRE_EMPLOYEE_DATE,
  DEFAULT_MIN_HIRE_EMPLOYEE_DATE,
} from 'constants/date';

import { ISignUpFormData } from '../components/Auth/_models/loginModels';
import { DEFAULT_DATE_FORMAT_FOR_VIEW } from '../constants/dateFormats';
import { hasLettersInString } from './helpers';
import { ITimeFrame } from './models';

export class ValidationError extends Error {
  constructor(error: Record<string, string>) {
    super();
    Object.assign(this, error);
  }
}

export const required = <T>(value?: T) =>
  value ? undefined : 'Field is required';

export const requiredForNumber = <T>(value?: T) =>
  value || typeof value === 'number' ? undefined : 'Field is required'; // ToDo need test on other form

export const requiredId = (value?: { id: number | string }) => {
  return value && value.id ? undefined : 'Field is required';
};

export const requiredArray = (value?: { id: number | string }) => {
  return value && Array.isArray(value) && value.length
    ? undefined
    : 'Field is required';
};

export const requiredName = (value?: { name: string }) => {
  return value && value.name && value.name.trim()
    ? undefined
    : 'Field is required';
};

export const requiredTimeFrame = (value?: {
  from?: number | string;
  to?: number | string;
}) => {
  return value && value.from && value.to ? undefined : 'Field is required';
};

export type InvalidOrderItem = {
  _error: string;
  address?: {
    index: number;
    field: OrderAddressField;
  };
};

export const validateOrderRoute = (
  route?: IOrderAddress[],
): InvalidOrderItem[] | undefined => {
  const result: InvalidOrderItem[] = [];
  if (!route || !route.length) {
    result.push({ _error: 'Please add addresses' });
  } else {
    if (route.length < 2)
      result.push({ _error: 'Route has to contain at least two adresses' });

    if (route.first()?.address_type !== 'pickup')
      result.push({
        _error: 'Route has to start from PU adress',
        address: { field: 'type', index: 0 },
      });

    if (route.last()?.address_type !== 'dropoff')
      result.push({
        _error: 'Route has to end with DO adress',
        address: { field: 'type', index: route.length - 1 },
      });

    let index = route.findIndex(({ facility_id }) => !(facility_id?.id || 0));
    if (index !== -1)
      result.push({
        _error: 'Route has address with unknown facility',
        address: { field: 'facility', index },
      });

    index = route.findIndex(({ driver_id }) => !(driver_id?.id || 0));
    if (index !== -1)
      result.push({
        _error: 'Route has address with unknown driver',
        address: { field: 'driver', index },
      });

    index = route.findIndex(({ date }) => !date);
    if (index !== -1)
      result.push({
        _error: 'Route address date is empty',
        address: { field: 'date', index },
      });
    else {
      let timeRange = new TimeRange(TimeRange.MIN);
      index = route.findIndex(address => {
        const addressRange = new TimeRange(address.date, address.date_2);
        const before =
          addressRange.isNull() || addressRange.isBefore(timeRange);
        timeRange = addressRange;
        return before;
      });
      if (index !== -1) {
        result.push({
          _error: timeRange.isNull()
            ? 'Route address has invalid date range value'
            : 'Route addresses dates must be in chronological order',
          address: { field: 'date', index },
        });
      }
    }
  }
  return result.length ? result : undefined;
};

const maxLength = (max: number) => (value: string) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

const maxSalaryLength = (max: number) => (value: string) =>
  value && value.length > max
    ? `You can’t add more than ${max} symbols to this field`
    : undefined;

export const maxSalaryLength6 = maxSalaryLength(6);

export const maxLength2 = maxLength(2);
export const maxLength3 = maxLength(3);
export const maxLength6 = maxLength(6);
export const maxLength7 = maxLength(7);
export const maxLength9 = maxLength(9);
export const maxLength10 = maxLength(10);
export const maxLength12 = maxLength(12);
export const maxLength15 = maxLength(15);
export const maxLength16 = maxLength(16);
export const maxLength20 = maxLength(20);
export const maxLength25 = maxLength(25);
export const maxLength30 = maxLength(30);
export const maxLength32 = maxLength(32);
export const maxLength64 = maxLength(64);
export const maxLength100 = maxLength(100);

export const maxLength120 = maxLength(120);
export const maxLength300 = maxLength(300);
export const maxLength500 = maxLength(500);
export const maxLength1000 = maxLength(1000);
export const maxLength10000 = maxLength(10000);

const checkMaxLengthMinusNumber =
  (max: number, minus: number) => (value: string) =>
    value && value.length > max
      ? `Must be ${max - minus} characters or less`
      : undefined;
export const maxLength5Minus1 = checkMaxLengthMinusNumber(5, 1);

const minLength = (min: number) => (value: string) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;
export const minLength1 = minLength(1);
export const minLength2 = minLength(2);
export const minLength3 = minLength(3);
export const minLength5 = minLength(5);
export const minLength8 = minLength(8);
export const minLength14 = minLength(14);
export const minLength15 = minLength(15);

const checkLength = (length: number) => (value: string) =>
  value && value.length !== length ? `Must be ${length} characters` : undefined;
export const checkLength5 = checkLength(5);

const checkLengthBetween = (start: number, end: number) => (value: string) =>
  value && (value.length > end || value.length < start)
    ? `Must be ${start} or ${end} characters`
    : undefined;
export const checkLengthBetween5And6 = checkLengthBetween(5, 6);

const checkLengthMinusNumber =
  (length: number, minus: number) => (value: string) =>
    value && value.length !== length
      ? `Must be ${length - minus} characters`
      : undefined;

export const checkLength11Minus2 = checkLengthMinusNumber(11, 2);

export const email = (value: string) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.trim())
    ? 'Invalid email address'
    : undefined;

export const sixDigitsThenLetter = (value: string) =>
  value && !/^[0-9]{6}[A-Z]{1}$/i.test(value) // i replace with g for only UPPERCASE letters at the end
    ? 'Invalid unit number'
    : undefined;

export const notMoreNumber = (num: number) => (value: number | string) =>
  value && Number(value) && Number(value) > num
    ? `Equal or less than ${num}`
    : undefined;

export const notMore5 = notMoreNumber(5);
export const notMore20 = notMoreNumber(20);
export const notMore100 = notMoreNumber(100);
export const notMore1000 = notMoreNumber(1000);
export const notMore9999 = notMoreNumber(9999);
export const notMore10000 = notMoreNumber(10000);
export const notMore100000 = notMoreNumber(100000);
export const notMore1000000 = notMoreNumber(1000000);

const notMoreMinusNumber = (num: number) => (value: number | string) =>
  value && Number(value) && Number(value) < num
    ? `Must be ${num} or more`
    : undefined;
export const notMoreMinus1000 = notMoreMinusNumber(-1000);

const greaterThanNumber = (num: number) => (value: number | string) =>
  Number(value) <= num ? `Must be greater than ${num}` : undefined;

export const greaterThan0 = greaterThanNumber(0);

export const notLessNumber = (num: number) => (value: number | string) =>
  Number(value) < num ? `Must be no less than ${num}` : undefined;

const isLessValue = (num: number) => (value: NumberFormatValues) =>
  value.floatValue === undefined || value.floatValue < num;

const isNotGreaterValue = (num: number) => (value: NumberFormatValues) =>
  value.floatValue === undefined || value.floatValue <= num;

export const notLess0 = notLessNumber(0);
export const notLess1 = notLessNumber(1);

export const isLessOrEqual100000 = isNotGreaterValue(100000);
export const isLessMillion = isLessValue(1000000);
export const isPercent = isNotGreaterValue(100);

export const passwordsMatch = (
  value: string,
  allValues: { password: string },
) => (value !== allValues.password ? "Passwords don't match" : undefined);

export const checkVinNumber = (value: string) =>
  value && !/^[A-HJ-NPR-Z0-9]{2,20}$/i.test(value)
    ? "Must be from 2 till 20 characters and can't include the letters Q, I, and O. "
    : undefined;

export const hasNoSpacesInString = (value: string) => {
  const regexp = /\s/g;
  return value && value.match(regexp) ? 'No spaces accepted' : undefined;
};

export const checkNoSpecialCharacters = (
  value: string | { currentValue: string },
) => {
  const regexp = /[^a-zA-Z0-9 ]/g;

  // eslint-disable-next-line no-underscore-dangle
  const _value = typeof value === 'string' ? value : value?.currentValue;

  return _value && _value.match(regexp)
    ? 'No special characters accepted'
    : undefined;
};

// Checks if number is 13 or 16 characters
export const checkFuelCardNumber = (value: string) =>
  value && !/^(\d{16})$/i.test(value)
    ? `Fuel card must have 16 digits`
    : undefined;

// Checks if hasLettersInString
export const checkHasLettersInString = (value: string) =>
  value && !hasLettersInString(value) ? 'Must contain letters' : undefined;

// Checks if valid Time Frame
export const checkTimeFrame = (
  value: ITimeFrame = { from: null, to: null },
) => {
  const { from, to } = value;

  if (!value) return undefined;
  if (
    (from && !moment(from, 'hh:mm a').isValid()) ||
    (to && !moment(to, 'hh:mm a').isValid())
  ) {
    return 'Invalid time format';
  }

  return undefined;
};

// Checks if valid Time
export const checkTime = (value: string) => {
  if (!value) return undefined;
  if (!moment(value, 'HH:mm').isValid()) {
    return 'Invalid time format';
  }

  return undefined;
};

// Checks Max And Min Date
const checkMaxAndMinDate =
  (min: string, max: string, errorText?: string) =>
  <T>(value?: T) => {
    if (value)
      if (!moment(value).isValid()) return 'Invalid date';
      else if (moment(value).isAfter(max) || moment(value).isBefore(min))
        return errorText || `Date must be between ${min} and ${max}`;

    return undefined;
  };

export const checkMaxAndMinHireEmployeeDate = checkMaxAndMinDate(
  DEFAULT_MIN_HIRE_EMPLOYEE_DATE,
  DEFAULT_MAX_HIRE_EMPLOYEE_DATE,
);
export const checkMaxAndMinDOB = checkMaxAndMinDate(
  DEFAULT_MIN_DOB,
  DEFAULT_MAX_DOB,
  'Age must be between 18 and 100 years old',
);

export const checkValidDate = <T>(value?: T) => {
  if (value && !moment(value).isValid()) return 'Invalid date';
  return undefined;
};

/**
 * * Use only in useCallback
 * @param min - min available date
 * @param unit - determines the precision
 * @param msg - returned error message
 * @param format - format for date
 */
export const isDateSameOrAfterThan =
  <M>(
    min: M,
    unit: moment.unitOfTime.StartOf = 'day',
    msg?: string,
    format = DEFAULT_DATE_FORMAT_FOR_VIEW,
  ) =>
  <T>(value?: T) => {
    const isInvalidMin = checkValidDate(min);
    if (isInvalidMin) return undefined;

    const isInvalid = checkValidDate(value);
    if (!isInvalid) {
      const minDate = moment(min);
      if (moment(value).isSameOrAfter(minDate, unit)) return undefined;
      return msg || `Must be more or equal than ${minDate.format(format)}`;
    }
    return checkValidDate(value);
  };

/**
 * Check date is same or before the max date
 * * Use only in useCallback
 * @param max - max available date
 * @param unit - determines the precision
 * @param msg - returned error message
 * @param format - format for date
 */
export const isDateSameOrBeforeThan =
  <M>(
    max: M,
    unit: moment.unitOfTime.StartOf = 'day',
    msg?: string,
    format = DEFAULT_DATE_FORMAT_FOR_VIEW,
  ) =>
  <T>(value?: T) => {
    const isInvalidMax = checkValidDate(max);
    if (isInvalidMax) return undefined;

    const isInvalid = checkValidDate(value);
    if (!isInvalid) {
      const maxDate = moment(max);
      if (moment(value).isSameOrBefore(maxDate, unit)) return undefined;
      return msg || `Must be less or equal than ${maxDate.format(format)}`;
    }
    return checkValidDate(value);
  };

export const hasNumericValue = (value?: string) => {
  return !value || parseFloat(value) === +value
    ? undefined
    : 'Must be a number';
};

export const hasintegerNumericValue = (value?: string) => {
  return !value || parseInt(value, 10) === +value
    ? undefined
    : 'Must be an integer number';
};

export const passwordsMustMatch = (value: string, allValues: ISignUpFormData) =>
  value !== allValues.user_password ? 'Passwords do not match' : undefined;

export const totalEqual = (total: number) => (value?: number) => {
  if (value === undefined || total === undefined) return undefined;
  return value === total ? undefined : 'Total must be equal';
};
