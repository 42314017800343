import { FC, KeyboardEvent, MouseEvent, useEffect, memo } from 'react';

import clsx from 'clsx';
import { omit } from 'ramda';

import Avatar from 'components/_common/Avatar/Avatar';
import { ISelectOption } from 'components/_common/FormElements/FormField/Select/_models/selectModels';
import { highlightSearchValue } from 'components/_common/SearchPanel/helpers';
import useTestAttrRef from 'hooks/useTestAttrRef';

import styles from './Options.module.scss';

type Props = {
  option: ISelectOption;
  onSelectOption: (option: ISelectOption) => void;
  toggleVisibility: (v?: boolean) => void;
  inputValue?: string;
  className?: string;
  isSelected?: boolean;
  isVisible?: boolean;
  focus?: boolean;
  highlight?: boolean;
};

const Option: FC<Props> = ({
  inputValue,
  option,
  onSelectOption,
  toggleVisibility,
  className,
  isSelected,
  isVisible = true,
  focus,
  highlight,
}) => {
  const {
    versionKey = 'other',
    photo_url_thumb,
    name,
    id,
    optionComponent,
  } = option;

  const selectRef = useTestAttrRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectRef.current) {
      if (focus) selectRef.current.focus();
      else if (highlight)
        selectRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
    }
  }, [selectRef.current, focus, highlight]);

  const selectOption = (e: MouseEvent) => {
    e.stopPropagation();
    const rest = omit(['optionComponent'], option) as ISelectOption;
    onSelectOption(rest);
    toggleVisibility();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement | HTMLLIElement>) => {
    if (e.key === 'Enter') {
      const rest = omit(['optionComponent'], option) as ISelectOption;
      onSelectOption(rest);
      toggleVisibility();
      return;
    }

    // !keyCode is Deprecated
    if (e.keyCode === 9 || e.key === 'Tab') {
      if (e.shiftKey) {
        // if with shift, just close dropdown and focus on input
        toggleVisibility(true);
      } else {
        toggleVisibility();
        selectOption(e as unknown as MouseEvent);
      }
    }
  };

  const commonProperties = {
    ref: selectRef,
    role: 'option',
    'aria-selected': isSelected,
    tabIndex: -1,
    onMouseDown: selectOption,
    onKeyDown: handleKeyDown,
    name,
  };

  // Display only option component
  if (option.onlyOptionComponent && optionComponent) {
    return (
      <div
        {...commonProperties}
        className={clsx(styles.root, styles.noPadding, {
          [styles.highlight]: highlight,
        })}
      >
        {optionComponent}
      </div>
    );
  }

  return (
    <>
      {versionKey === 'other' && isVisible && (
        <div
          {...commonProperties}
          key={id}
          className={clsx(
            styles.root,
            styles.otherRoot,
            { [styles.highlight]: highlight },
            isSelected ? styles.selected : undefined,
            className,
          )}
        >
          {option.name}
        </div>
      )}

      {(versionKey === 'driver' ||
        versionKey === 'dispatcher' ||
        versionKey === 'employee') &&
        isVisible && (
          <div
            {...commonProperties}
            key={id}
            className={clsx(styles.root, styles.otherRoot, {
              [styles.highlight]: highlight,
            })}
          >
            <Avatar
              avatar={photo_url_thumb}
              defaultUserPhoto={!photo_url_thumb}
              size={24}
            />

            <span className={styles.dispatcherAndDriverName}>
              {inputValue
                ? highlightSearchValue(name, inputValue, styles.highlighted)
                : name}
            </span>
          </div>
        )}

      {optionComponent && (
        <div
          {...commonProperties}
          className={clsx(styles.optionComponent, {
            [styles.highlight]: highlight,
          })}
        >
          {optionComponent}
        </div>
      )}
    </>
  );
};

export default memo(Option);
