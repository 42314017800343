import { useCallback } from 'react';

import moment from 'moment';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import Button from 'components/_common/FormElements/Button/Button';
import FormField from 'components/_common/FormElements/FormField/FormField';
import Modal from 'components/_common/Modal/Modal';
import { CURRENT_DATE_PLUS_TREE_YEAR } from 'constants/date';
import useGetFormValues from 'hooks/useGetFormValues';
import { mergeDateAndTime } from 'utils/converters';
import { normalizeDate } from 'utils/normalizers';
import {
  checkValidDate,
  isDateSameOrAfterThan,
  required,
} from 'utils/validation';

import styles from './DOTUpdateModal.module.scss';

type OwnProps = {
  isOpen: boolean;
  onClose: () => void;
  type?: 'update' | 'remind';
};

type FormData = { date: string; time?: string };

type Props = OwnProps & InjectedFormProps<FormData, OwnProps>;

const DOTUpdateModal = ({
  isOpen,
  type = 'update',
  onClose,
  handleSubmit,
}: Props) => {
  const {
    formValues: { date, time },
  } = useGetFormValues('DOTUpdateModal', ['date', 'time']);

  const checkMinDate = useCallback(
    isDateSameOrAfterThan(
      type === 'update'
        ? moment().add(1, 'years').subtract(1, 'months').format()
        : moment().format(),
    ),
    [type],
  );

  const checkMinTime = useCallback(() => {
    const currValue = mergeDateAndTime(date, time);
    return moment(currValue).isAfter(moment(), 'minutes')
      ? undefined
      : 'There must be a future date';
  }, [date, time]);

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOutsideClick={false}
      overlayClassName={styles.overlay}
    >
      <h3>
        {type === 'update' ? 'DOT Inspections Update Date' : 'Set a Reminder'}
      </h3>
      <form onSubmit={handleSubmit}>
        <Field
          id="date"
          name="date"
          component={FormField}
          fieldType="datePicker"
          labelText="Date"
          maxDate={CURRENT_DATE_PLUS_TREE_YEAR}
          normalize={normalizeDate}
          validate={[checkValidDate, required, checkMinDate]}
          // for update - previous month, but next year
          // for remind - current date
          minDate={
            type === 'update'
              ? moment().add(1, 'years').subtract(1, 'months')
              : moment()
          }
          focusOnRender
          fullWidth
          required
        />

        {type === 'remind' && (
          <Field
            id="time"
            name="time"
            component={FormField}
            fieldType="timePicker"
            placeholder="hh:mm"
            labelText="Time"
            validate={[required, checkMinTime]}
            fullWidth
            required
            ampm={false}
          />
        )}
        <footer>
          <Button text="Update" theme="blue" type="submit" />
          <Button text="Cancel" theme="white" onClick={onClose} />
        </footer>
      </form>
    </Modal>
  );
};

export default reduxForm<FormData, OwnProps>({
  form: 'DOTUpdateModal',
})(DOTUpdateModal);
