import { FC, memo } from 'react';

import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';

import Button from 'components/_common/FormElements/Button/Button';
import { authSelector } from 'components/Auth/_redux/authSelectors';

import styles from './NotFound.module.scss';

type Props = {};

const NotFound: FC<Props> = () => {
  const { _pending } = useSelector(authSelector);

  if (_pending)
    return (
      <div className={styles.loader}>
        <CircularProgress size={25} />
      </div>
    );

  return (
    <div className={styles.root}>
      <h2>404</h2>
      <h3>Oops, sorry we can&apos;t find that page!</h3>
      <p>The page is either under construction or no longer exists</p>
      <Button text="Back To Home" link={{ to: '/chat' }} />
    </div>
  );
};

export default memo(NotFound);
