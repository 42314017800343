import { FC } from 'react';

type OwnProps = {
  className?: string;
  fillColor?: string;
};

const IconGeofence: FC<OwnProps> = ({
  className,
  fillColor = 'var(--sideBarIcon)',
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="8" cy="8" r="3" stroke={fillColor} strokeWidth="2" />
      <circle cx="8" cy="8" r="7" stroke={fillColor} strokeWidth="2" />
    </svg>
  );
};

export default IconGeofence;
