import { DEFAULT_MAP_CONFIG } from 'components/_common/Map/_config';
import { LOGOUT } from 'components/Auth/_redux/authTypes';
import {
  INIT_GOOGLE_SCRIPT,
  initGoogleActionsType,
  SET_TRUCK_MAP,
  SET_GEOFENCE_MAP,
  SET_TOLL_MAP,
  CLEAR_ALL_MAPS,
  SET_TRAILER_MAP,
  IGoogleReducer,
  SET_MAP_TYPE_ID,
} from 'services/google/_redux/googleTypes';

const initialState: IGoogleReducer = {
  isInitGoogleAPIs: false,
  mapTypeId: 'roadmap',
  truckMap: {
    activeMarkerId: '',
    truckMarkers: [],
    mapCenter: DEFAULT_MAP_CONFIG.mapCenterPosition,
  },
  geoFenceMap: {
    geoFences: [],
    mapCenter: DEFAULT_MAP_CONFIG.mapCenterPosition,
  },
  tollMap: {
    tolls: [],
    mapCenter: DEFAULT_MAP_CONFIG.mapCenterPosition,
  },
  trailerMap: {
    trailerMarkers: [],
    mapCenter: DEFAULT_MAP_CONFIG.mapCenterPosition,
  },
};

const googleReducers = (
  state = initialState,
  action: initGoogleActionsType,
): IGoogleReducer => {
  switch (action.type) {
    // INIT_GOOGLE_SCRIPT
    case INIT_GOOGLE_SCRIPT:
      return { ...state, isInitGoogleAPIs: true };

    // SET_MAP_TYPE_ID
    case SET_MAP_TYPE_ID:
      return { ...state, mapTypeId: action.payload };

    // SET_TRUCK_MAP
    case SET_TRUCK_MAP:
      return { ...state, truckMap: action.payload };

    // SET_GEOFENCE_MAP
    case SET_GEOFENCE_MAP:
      return { ...state, geoFenceMap: action.payload };

    // SET_TOLL_MAP
    case SET_TOLL_MAP:
      return { ...state, tollMap: action.payload };

    // SET_TRAILER_MAP
    case SET_TRAILER_MAP:
      return { ...state, trailerMap: action.payload };

    // CLEAR_ALL_MAPS
    case CLEAR_ALL_MAPS:
      return {
        ...initialState,
        mapTypeId: state.mapTypeId,
        isInitGoogleAPIs: state.isInitGoogleAPIs,
      };

    case LOGOUT:
      return {
        ...initialState,
        mapTypeId: state.mapTypeId,
        isInitGoogleAPIs: state.isInitGoogleAPIs,
      };

    default:
      return state;
  }
};

export default googleReducers;
