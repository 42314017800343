export const mimeTypesAsString = (
  mimeTypes: Array<string | string[]>,
): string => {
  return mimeTypes.flat().join(',');
};

export const IMAGE_MIME_TYPES = [
  'image/png',
  'image/x-citrix-png',
  'image/x-png',
  'image/jpeg',
  'image/jpg',
  'image/x-citrix-jpeg',
  // 'image/webp',
  // 'image/gif',
  // 'image/bmp',
];

export const VIDEO_MIME_TYPES = [
  'video/mpeg',
  'video/mp4',
  'video/ogg',
  'video/mov',
  'video/quicktime',
  '.mov',
  'video/webm',
  'video/x-ms-wmv',
  'video/x-flv',
  'video/x-msvideo',
  'video/3gpp',
  'video/3gpp2',
];

export const PDF_DOC_MIME_TYPES = ['application/pdf'];
export const RTF_DOC_MIME_TYPES = ['application/rtf'];
export const DOC_MIME_TYPES = [
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',

  'text/plain',
  'application/vnd.oasis.opendocument.text',

  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const DEFAULT_ACCEPT_STRING = mimeTypesAsString([
  IMAGE_MIME_TYPES,
  PDF_DOC_MIME_TYPES,
  DOC_MIME_TYPES,
]);

export const IMG_STRING = mimeTypesAsString([IMAGE_MIME_TYPES]);

export const IMG_PDF_ACCEPT_STRING = mimeTypesAsString([
  IMAGE_MIME_TYPES,
  PDF_DOC_MIME_TYPES,
]);

export const ALL_FILES_ACCEPT_STRING = mimeTypesAsString([
  IMAGE_MIME_TYPES,
  PDF_DOC_MIME_TYPES,
  DOC_MIME_TYPES,
  RTF_DOC_MIME_TYPES,
  VIDEO_MIME_TYPES,
]);
