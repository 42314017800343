import { FC } from 'react';

type OwnProps = {
  className?: string;
  fillColor?: string;
};

const IconSupportCenter: FC<OwnProps> = ({
  className,
  fillColor = 'var(--primaryText)',
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.1426 9.21098L19.3172 5.03637M9.34967 15.0039L5.17906 19.1745M9.34967 9.21099L5.0001 4.86198M19.1426 19.0039L15.1426 15.0039M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z"
        stroke={fillColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconSupportCenter;
