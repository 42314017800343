import { LOGOUT } from 'components/Auth/_redux/authTypes';
import {
  IDriver,
  IDriverRates,
  IDriversReducer,
  IDriverUploadedFile,
} from 'components/HumanResources/TabDrivers/_models/driverModel';
import { parseFloatString } from 'utils/helpers';

import {
  CREATE_DRIVER,
  CREATE_DRIVER_FAIL,
  CREATE_DRIVER_SUCCESS,
  DELETE_DRIVER,
  DELETE_DRIVER_FAIL,
  DELETE_DRIVER_FILE,
  DELETE_DRIVER_FILE_FAIL,
  DELETE_DRIVER_FILE_SUCCESS,
  DELETE_DRIVER_PHOTO,
  DELETE_DRIVER_PHOTO_FAIL,
  DELETE_DRIVER_PHOTO_SUCCESS,
  DELETE_DRIVER_SUCCESS,
  driversActionsType,
  GET_DRIVER,
  GET_DRIVER_FAIL,
  GET_DRIVER_SUCCESS,
  GET_DRIVERS,
  GET_DRIVERS_FAIL,
  GET_DRIVERS_SUCCESS,
  UNMOUNT_DRIVER_FORM,
  UPDATE_DRIVER,
  UPDATE_DRIVER_FAIL,
  UPDATE_DRIVER_SUCCESS,
  UPLOAD_DRIVER_FILES,
  UPLOAD_DRIVER_FILES_FAIL,
  UPLOAD_DRIVER_FILES_SUCCESS,
  UPLOAD_DRIVER_PHOTO,
  UPLOAD_DRIVER_PHOTO_FAIL,
  UPLOAD_DRIVER_PHOTO_SUCCESS,
  CHANGE_DRIVER_FILE_CATEGORY,
  CHANGE_DRIVER_FILE_CATEGORY_SUCCESS,
  CHANGE_DRIVER_FILE_CATEGORY_FAIL,
  ASSIGN_DRIVER,
  ASSIGN_DRIVER_SUCCESS,
  ASSIGN_DRIVER_FAIL,
  ARCHIVE_DRIVER,
  ARCHIVE_DRIVER_SUCCESS,
  ARCHIVE_DRIVER_FAIL,
  GET_DRIVER_RATES_LIST,
  GET_DRIVER_RATES_LIST_SUCCESS,
  GET_DRIVER_RATES_LIST_FAIL,
  SET_DRIVER_COMMENT_VISIBILITY,
  GET_FUEL_COMPANIES_SUCCESS,
  GET_FUEL_COMPANIES_FAIL,
} from './driversTypes';

const initialState: IDriversReducer = {
  pagination: null,
  statistic: null,
  list: [],
  heapList: [],
  files: [],
  ratesData: [],
  fuelCompanies: [],
  currentDriver: null,
  _pending: false,
  _loaded: false,
  _error: null,
};

const driversReducers = (
  state = initialState,
  action: driversActionsType,
): IDriversReducer => {
  switch (action.type) {
    // GET DRIVERS
    case GET_DRIVERS: {
      if (action.config && action.config.useSeparateHeapFlag) {
        return {
          ...state,
          files: [],
          _error: null,
        };
      }

      return {
        ...state,
        files: [],
        currentDriver: null,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    }

    case GET_DRIVERS_SUCCESS: {
      if (action.payload.useSeparateHeapFlag) {
        return {
          ...state,
          list: state.list,
          heapList: action.payload.drivers,
        };
      }

      return {
        ...state,
        pagination: action.payload.pagination,
        statistic: action.payload.statistic,
        list: action.payload.drivers,
        heapList: [],
        _loaded: true,
        _pending: false,
      };
    }

    case GET_DRIVERS_FAIL:
      return {
        ...initialState,
        _error: action.payload,
        _loaded: false,
        _pending: false,
      };

    // GET DRIVER
    case GET_DRIVER:
      return { ...state, _loaded: false, _pending: true, _error: null };
    case GET_DRIVER_SUCCESS:
      return {
        ...state,
        currentDriver: {
          ...action.payload.driver,
          experience: parseFloatString(action.payload.driver.experience),
        },
        files: action.payload.driver.files as IDriverUploadedFile[],
        truck: action.payload.truck,
        trailer: action.payload.trailer,
        _loaded: true,
        _pending: false,
      };
    case GET_DRIVER_FAIL:
      return {
        ...state,
        _error: action.payload,
        _loaded: false,
        _pending: false,
      };

    // GET DRIVER RATES LIST
    case GET_DRIVER_RATES_LIST:
      return { ...state, _error: null };
    case GET_DRIVER_RATES_LIST_SUCCESS:
      return {
        ...state,
        ratesData:
          action.payload.rates.map(rate => {
            return {
              ...rate,
              commentVisibility: false,
            };
          }) || [],
        pagination: action.payload.pagination,
      };
    case GET_DRIVER_RATES_LIST_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    case SET_DRIVER_COMMENT_VISIBILITY:
      return {
        ...state,
        ratesData: state.ratesData.map((rate: IDriverRates) => {
          const shouldToggle = rate.id === action.payload.data.id;
          const isVisibile = !rate.commentVisibility;
          return {
            ...rate,
            commentVisibility: shouldToggle && isVisibile,
          };
        }),
      };

    // CREATE DRIVER
    case CREATE_DRIVER:
      return { ...state };
    case CREATE_DRIVER_SUCCESS:
      return { ...state, files: [], currentDriver: null };
    case CREATE_DRIVER_FAIL:
      return { ...state, _error: action.payload };

    // UPDATE DRIVER
    case UPDATE_DRIVER:
      return { ...state };
    case UPDATE_DRIVER_SUCCESS:
      return { ...state, files: [] };
    case UPDATE_DRIVER_FAIL:
      return { ...state, _error: action.payload };

    // ASSIGN DRIVER
    case ASSIGN_DRIVER:
      return { ...state, _error: null };
    case ASSIGN_DRIVER_SUCCESS:
      return {
        ...state,
        // TODO - remove this after backend fix
        // list: state.list.map((driver: { id: number }) =>
        //   driver.id === action.payload.driver?.id
        //     ? action.payload.driver
        //     : driver,
        // ),
      };
    case ASSIGN_DRIVER_FAIL:
      return { ...state, _error: action.payload };

    // DELETE DRIVER
    case DELETE_DRIVER:
      return { ...state };
    case DELETE_DRIVER_SUCCESS:
      return { ...state, files: [], currentDriver: null };
    case DELETE_DRIVER_FAIL:
      return { ...state, _error: action.payload };

    // UPLOAD DRIVER FILES
    case UPLOAD_DRIVER_FILES:
      return {
        ...state,
        files: [
          ...state.files,
          ...action.payload.files.map(file => ({
            ...file,
            name: file.name,
            loading: true,
          })),
        ] as IDriverUploadedFile[],
      };
    case UPLOAD_DRIVER_FILES_SUCCESS:
      return {
        ...state,
        files: state.files.map((file, index: number) =>
          index === state.files.findIndex(({ loading }) => loading)
            ? { ...action.payload, loading: false }
            : file,
        ) as IDriverUploadedFile[],
      };
    case UPLOAD_DRIVER_FILES_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // CHANGE DRIVER FILE CATEGORY
    case CHANGE_DRIVER_FILE_CATEGORY:
      return { ...state };
    case CHANGE_DRIVER_FILE_CATEGORY_SUCCESS:
      return {
        ...state,
        files: state.files.map(file =>
          file.id === action.payload.id ? action.payload : file,
        ),
      };
    case CHANGE_DRIVER_FILE_CATEGORY_FAIL:
      return { ...state, _error: action.payload };

    // DELETE DRIVER FILE
    case DELETE_DRIVER_FILE:
      return { ...state };
    case DELETE_DRIVER_FILE_SUCCESS:
      return {
        ...state,
        files: state.files.filter(file => file.id !== action.payload),
      };
    case DELETE_DRIVER_FILE_FAIL:
      return { ...state, _error: action.payload };

    // UPLOAD DRIVER PHOTO
    case UPLOAD_DRIVER_PHOTO:
      return { ...state };
    case UPLOAD_DRIVER_PHOTO_SUCCESS:
      return { ...state };
    case UPLOAD_DRIVER_PHOTO_FAIL:
      return { ...state, _error: action.payload };

    // DELETE DRIVER PHOTO
    case DELETE_DRIVER_PHOTO:
      return { ...state };
    case DELETE_DRIVER_PHOTO_SUCCESS:
      return { ...state };
    case DELETE_DRIVER_PHOTO_FAIL:
      return { ...state, _error: action.payload };

    // ARCHIVE DRIVER
    case ARCHIVE_DRIVER:
      return { ...state, _error: null };
    case ARCHIVE_DRIVER_SUCCESS: {
      const pagination =
        (state.pagination && {
          ...state.pagination,
          total: state.pagination.total - 1,
        }) ||
        state.pagination;
      return {
        ...state,
        list:
          state.list &&
          state.list.filter((item: IDriver) => item.id !== action.payload.id),
        pagination,
      };
    }
    case ARCHIVE_DRIVER_FAIL:
      return { ...state, _error: action.payload };

    case UNMOUNT_DRIVER_FORM:
      return {
        ...state,
        files: [],
        list: [],
        currentDriver: null,
      };

    // GET FUEL COMPANIES
    case GET_FUEL_COMPANIES_SUCCESS:
      return { ...state, fuelCompanies: action.payload };
    case GET_FUEL_COMPANIES_FAIL:
      return { ...state, _error: action.payload };

    case LOGOUT:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default driversReducers;
