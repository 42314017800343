import { FC, MouseEvent } from 'react';

type Props = {
  className?: string;
  color?: string;
  onClick?: (event: MouseEvent) => void;
};

const IconLock: FC<Props> = ({ className, color = '#86898E', onClick }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <rect
        x="2"
        y="7"
        width="12"
        height="8"
        rx="2"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M8 10.5L8 11.3704"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M11 6V3C11 1.89543 10.1046 1 9 1H7C5.89543 1 5 1.89543 5 3V3"
        stroke={color}
        strokeWidth="2"
      />
      <circle cx="5" cy="3" r="1" fill={color} />
    </svg>
  );
};

export default IconLock;
