import { FC } from 'react';

type OwnProps = {
  className?: string;
  color?: string;
};

const IconCrop: FC<OwnProps> = ({
  className,
  color = 'var(--primaryText)',
}) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 2V6" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path
        d="M6 10V16C6 17.1046 6.89543 18 8 18H14"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M2 6H16C17.1046 6 18 6.89543 18 8V22"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M22 18H18"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconCrop;
