import { IWithError, IWithLoaded, IWithPending } from 'utils/models';

import { ProperList } from '../RolesSettings/PermissionTab/PermissionTab';
import { IRoleAndPermission } from './RolesAndPermissionsModels';
import {
  CREATE_ROLE,
  CREATE_ROLE_FAIL,
  CREATE_ROLE_SUCCESS,
  DELETE_ROLE,
  DELETE_ROLE_FAIL,
  DELETE_ROLE_SUCCESS,
  GET_ROLE,
  GET_ROLE_FAIL,
  GET_ROLE_SUCCESS,
  GET_ROLES_LIST,
  GET_ROLES_LIST_FAIL,
  GET_ROLES_LIST_SUCCESS,
  rolesAndPermissionsActionsType,
  SET_ACTIVE_PERMISSIONS_SUCCESS,
  SET_COMPANIES_TO_SET,
} from './RolesAndPermissionsTypes';

interface IRoleAndPermissionReducer
  extends IWithError,
    IWithPending,
    IWithLoaded {
  roleAndPermission: IRoleAndPermission[];
  currentRole: IRoleAndPermission | null;
  activePermissionsList: ProperList[] | null;
  companiesToSet: number[] | null;
}

const initialState: IRoleAndPermissionReducer = {
  roleAndPermission: [],
  currentRole: null,
  activePermissionsList: null,
  companiesToSet: null,
  _pending: false,
  _loaded: false,
  _error: null,
};

const rolesAndPermissionReducer = (
  state = initialState,
  action: rolesAndPermissionsActionsType,
) => {
  switch (action.type) {
    case GET_ROLES_LIST: {
      return {
        ...state,
        _pending: true,
        _loaded: false,
        _error: null,
      };
    }
    case GET_ROLES_LIST_SUCCESS: {
      return {
        ...state,
        roleAndPermission: action.payload,
        _pending: false,
        _loaded: true,
        _error: null,
      };
    }
    case GET_ROLES_LIST_FAIL: {
      return {
        ...state,
        _pending: false,
        _loaded: true,
        _error: action.payload,
      };
    }
    case GET_ROLE: {
      return {
        ...state,
        _pending: true,
        _loaded: false,
        _error: null,
      };
    }
    case GET_ROLE_SUCCESS: {
      return {
        ...state,
        currentRole: action.payload,
        _pending: false,
        _loaded: true,
        _error: null,
      };
    }
    case GET_ROLE_FAIL: {
      return {
        ...state,
        _pending: false,
        _loaded: true,
        _error: action.payload,
      };
    }
    case CREATE_ROLE: {
      return {
        ...state,
        _pending: true,
        _loaded: false,
        _error: null,
      };
    }
    case CREATE_ROLE_SUCCESS: {
      return {
        ...state,
        roleAndPermission: [...state.roleAndPermission, action.payload],
        _pending: true,
        _loaded: false,
        _error: null,
      };
    }
    case CREATE_ROLE_FAIL: {
      return {
        ...state,
        _pending: false,
        _loaded: true,
        _error: action.payload,
      };
    }
    case DELETE_ROLE: {
      return {
        ...state,
        _pending: true,
        _loaded: false,
        _error: null,
      };
    }
    case DELETE_ROLE_SUCCESS: {
      return {
        ...state,
        roleAndPermission: state.roleAndPermission.filter(
          role => role.id !== action.payload.id,
        ),
        _loaded: true,
        _pending: false,
        _error: null,
      };
    }
    case DELETE_ROLE_FAIL: {
      return {
        ...state,
        _loaded: true,
        _pending: false,
        _error: action.payload,
      };
    }
    case SET_ACTIVE_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        activePermissionsList: action.payload.permissions,
        _pending: false,
        _loaded: true,
        _error: null,
      };
    }
    case SET_COMPANIES_TO_SET: {
      return {
        ...state,
        companiesToSet: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default rolesAndPermissionReducer;
