import { FC } from 'react';

type OwnProps = {};
type Props = OwnProps;

const IconFlagUSA: FC<Props> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7241 13.2425H0.275874C0.123531 13.2425 0 13.119 0 12.9667V3.03564C0 2.8833 0.123531 2.75977 0.275874 2.75977H15.7241C15.8765 2.75977 16 2.8833 16 3.03564V12.9667C16 13.119 15.8764 13.2425 15.7241 13.2425Z"
        fill="#F5F5F5"
      />
      <path
        d="M16 3.56617H0V3.03564C0 2.8833 0.123531 2.75977 0.275874 2.75977H15.7241C15.8765 2.75977 16 2.8833 16 3.03564L16 3.56617Z"
        fill="#FF4B55"
      />
      <path d="M16 5.98633H0V6.7927H16V5.98633Z" fill="#FF4B55" />
      <path d="M16 4.37305H0V5.17942H16V4.37305Z" fill="#FF4B55" />
      <path
        d="M16 8.40405H0.275874C0.123531 8.40405 0 8.28052 0 8.12818V7.59766H16V8.40405Z"
        fill="#FF4B55"
      />
      <path d="M16 10.8242H0V11.6306H16V10.8242Z" fill="#FF4B55" />
      <path
        d="M15.7241 13.2419H0.275874C0.123531 13.2419 0 13.1184 0 12.966V12.4355H16V12.9661C16 13.1184 15.8764 13.2419 15.7241 13.2419Z"
        fill="#FF4B55"
      />
      <path d="M16 9.21094H0V10.0173H16V9.21094Z" fill="#FF4B55" />
      <path
        d="M7.17239 2.75781H0.275874C0.123531 2.75781 0 2.88131 0 3.03366V8.12646C0 8.2788 0.123531 8.40233 0.275874 8.40233H7.17242C7.32477 8.40233 7.4483 8.2788 7.4483 8.12646V3.03366C7.44827 2.88131 7.32474 2.75781 7.17239 2.75781Z"
        fill="#41479B"
      />
      <path
        d="M0.775549 3.39279L0.836611 3.57591L1.02964 3.57738C1.05464 3.5776 1.06499 3.60947 1.04489 3.62432L0.889611 3.739L0.947861 3.92303C0.955423 3.94684 0.928267 3.96659 0.907923 3.95203L0.750893 3.83981L0.593862 3.95206C0.573519 3.96659 0.546425 3.94688 0.553925 3.92306L0.612175 3.73903L0.456894 3.62435C0.4368 3.60947 0.447144 3.57763 0.472144 3.57741L0.665174 3.57594L0.726237 3.39282C0.734112 3.36907 0.767612 3.36907 0.775549 3.39279Z"
        fill="#F5F5F5"
      />
      <path
        d="M0.775549 4.35175L0.836611 4.53487L1.02964 4.53634C1.05464 4.53656 1.06499 4.56843 1.04489 4.58328L0.889611 4.69796L0.947861 4.88199C0.955423 4.90581 0.928267 4.92556 0.907923 4.91099L0.750893 4.79878L0.593862 4.91103C0.573519 4.92556 0.546425 4.90584 0.553925 4.88203L0.612175 4.69799L0.456894 4.58331C0.4368 4.56843 0.447144 4.53659 0.472144 4.53637L0.665174 4.5349L0.726237 4.35178C0.734112 4.32806 0.767612 4.32806 0.775549 4.35175Z"
        fill="#F5F5F5"
      />
      <path
        d="M0.775549 5.30878L0.836611 5.4919L1.02964 5.49337C1.05464 5.49359 1.06499 5.52547 1.04489 5.54031L0.889611 5.655L0.947861 5.83903C0.955423 5.86284 0.928267 5.88259 0.907923 5.86803L0.750893 5.75581L0.593862 5.86806C0.573519 5.88259 0.546425 5.86288 0.553925 5.83906L0.612175 5.65503L0.456894 5.54034C0.4368 5.52547 0.447144 5.49362 0.472144 5.4934L0.665174 5.49194L0.726237 5.30881C0.734112 5.28509 0.767612 5.28509 0.775549 5.30878Z"
        fill="#F5F5F5"
      />
      <path
        d="M0.775549 6.26779L0.836611 6.45091L1.02964 6.45238C1.05464 6.4526 1.06499 6.48447 1.04489 6.49932L0.889611 6.614L0.947861 6.79803C0.955423 6.82185 0.928267 6.8416 0.907923 6.82704L0.750893 6.71482L0.593862 6.82707C0.573519 6.8416 0.546425 6.82188 0.553925 6.79807L0.612175 6.61403L0.456894 6.49935C0.4368 6.48447 0.447144 6.45263 0.472144 6.45241L0.665174 6.45094L0.726237 6.26782C0.734112 6.24407 0.767612 6.24407 0.775549 6.26779Z"
        fill="#F5F5F5"
      />
      <path
        d="M0.775549 7.22484L0.836611 7.40797L1.02964 7.40944C1.05464 7.40965 1.06499 7.44153 1.04489 7.45637L0.889611 7.57106L0.947861 7.75509C0.955423 7.77891 0.928267 7.79866 0.907923 7.78409L0.750893 7.67188L0.593862 7.78413C0.573519 7.79866 0.546425 7.77894 0.553925 7.75513L0.612175 7.57109L0.456894 7.4564C0.4368 7.44153 0.447144 7.40969 0.472144 7.40947L0.665174 7.408L0.726237 7.22487C0.734112 7.20109 0.767612 7.20109 0.775549 7.22484Z"
        fill="#F5F5F5"
      />
      <path
        d="M1.51871 3.86347L1.57978 4.04659L1.77281 4.04806C1.79781 4.04828 1.80815 4.08016 1.78806 4.095L1.63277 4.20969L1.69102 4.39372C1.69859 4.41753 1.67143 4.43728 1.65109 4.42272L1.49406 4.31047L1.33703 4.42272C1.31668 4.43725 1.28959 4.41753 1.29709 4.39372L1.35534 4.20969L1.20006 4.095C1.17996 4.08012 1.19031 4.04828 1.21531 4.04806L1.40834 4.04659L1.4694 3.86347C1.47724 3.83978 1.51081 3.83978 1.51871 3.86347Z"
        fill="#F5F5F5"
      />
      <path
        d="M1.51871 4.82052L1.57978 5.00365L1.77281 5.00511C1.79781 5.00533 1.80815 5.03721 1.78806 5.05205L1.63277 5.16674L1.69102 5.35076C1.69859 5.37458 1.67143 5.39433 1.65109 5.37976L1.49406 5.26752L1.33703 5.37976C1.31668 5.3943 1.28959 5.37461 1.29709 5.35076L1.35534 5.16674L1.20006 5.05205C1.17996 5.03718 1.19031 5.00533 1.21531 5.00511L1.40834 5.00365L1.4694 4.82052C1.47724 4.7968 1.51081 4.7968 1.51871 4.82052Z"
        fill="#F5F5F5"
      />
      <path
        d="M1.51871 5.77951L1.57978 5.96263L1.77281 5.9641C1.79781 5.96432 1.80815 5.99619 1.78806 6.01104L1.63277 6.12573L1.69102 6.30976C1.69859 6.33357 1.67143 6.35332 1.65109 6.33876L1.49406 6.22651L1.33703 6.33876C1.31668 6.35329 1.28959 6.33357 1.29709 6.30976L1.35534 6.12573L1.20006 6.01104C1.17996 5.99616 1.19031 5.96432 1.21531 5.9641L1.40834 5.96263L1.4694 5.77951C1.47724 5.75579 1.51081 5.75579 1.51871 5.77951Z"
        fill="#F5F5F5"
      />
      <path
        d="M1.51871 6.73652L1.57978 6.91964L1.77281 6.92111C1.79781 6.92133 1.80815 6.9532 1.78806 6.96805L1.63277 7.08273L1.69102 7.26676C1.69859 7.29058 1.67143 7.31033 1.65109 7.29576L1.49406 7.18351L1.33703 7.29576C1.31668 7.3103 1.28959 7.29058 1.29709 7.26676L1.35534 7.08273L1.20006 6.96805C1.17996 6.95317 1.19031 6.92133 1.21531 6.92111L1.40834 6.91964L1.4694 6.73652C1.47724 6.71283 1.51081 6.71283 1.51871 6.73652Z"
        fill="#F5F5F5"
      />
      <path
        d="M2.26286 3.39279L2.32392 3.57591L2.51695 3.57738C2.54195 3.5776 2.55229 3.60947 2.5322 3.62432L2.37692 3.739L2.43517 3.92303C2.44273 3.94685 2.41558 3.9666 2.39523 3.95203L2.2382 3.83979L2.08117 3.95203C2.06082 3.96657 2.03373 3.94685 2.04123 3.92303L2.09948 3.739L1.9442 3.62432C1.9241 3.60944 1.93445 3.5776 1.95945 3.57738L2.15248 3.57591L2.21354 3.39279C2.22142 3.36907 2.25495 3.36907 2.26286 3.39279Z"
        fill="#F5F5F5"
      />
      <path
        d="M2.26286 4.35175L2.32392 4.53487L2.51695 4.53634C2.54195 4.53656 2.55229 4.56844 2.5322 4.58328L2.37692 4.69797L2.43517 4.882C2.44273 4.90581 2.41558 4.92556 2.39523 4.911L2.2382 4.79875L2.08117 4.911C2.06082 4.92553 2.03373 4.90581 2.04123 4.882L2.09948 4.69797L1.9442 4.58328C1.9241 4.56841 1.93445 4.53656 1.95945 4.53634L2.15248 4.53487L2.21354 4.35175C2.22142 4.32806 2.25495 4.32806 2.26286 4.35175Z"
        fill="#F5F5F5"
      />
      <path
        d="M2.26286 5.30878L2.32392 5.49191L2.51695 5.49338C2.54195 5.4936 2.55229 5.52547 2.5322 5.54032L2.37692 5.65501L2.43517 5.83904C2.44273 5.86285 2.41558 5.8826 2.39523 5.86804L2.2382 5.75579L2.08117 5.86804C2.06082 5.88257 2.03373 5.86285 2.04123 5.83904L2.09948 5.65501L1.9442 5.54032C1.9241 5.52544 1.93445 5.4936 1.95945 5.49338L2.15248 5.49191L2.21354 5.30878C2.22142 5.28509 2.25495 5.28509 2.26286 5.30878Z"
        fill="#F5F5F5"
      />
      <path
        d="M2.26286 6.26779L2.32392 6.45091L2.51695 6.45238C2.54195 6.4526 2.55229 6.48448 2.5322 6.49932L2.37692 6.61401L2.43517 6.79804C2.44273 6.82185 2.41558 6.8416 2.39523 6.82704L2.2382 6.71479L2.08117 6.82704C2.06082 6.84157 2.03373 6.82185 2.04123 6.79804L2.09948 6.61401L1.9442 6.49932C1.9241 6.48444 1.93445 6.4526 1.95945 6.45238L2.15248 6.45091L2.21354 6.26779C2.22142 6.24407 2.25495 6.24407 2.26286 6.26779Z"
        fill="#F5F5F5"
      />
      <path
        d="M2.26286 7.22484L2.32392 7.40797L2.51695 7.40944C2.54195 7.40966 2.55229 7.44153 2.5322 7.45638L2.37692 7.57106L2.43517 7.7551C2.44273 7.77891 2.41558 7.79866 2.39523 7.7841L2.2382 7.67185L2.08117 7.7841C2.06082 7.79863 2.03373 7.77891 2.04123 7.7551L2.09948 7.57106L1.9442 7.45638C1.9241 7.4415 1.93445 7.40966 1.95945 7.40944L2.15248 7.40797L2.21354 7.22484C2.22142 7.20109 2.25495 7.20109 2.26286 7.22484Z"
        fill="#F5F5F5"
      />
      <path
        d="M3.00504 3.86347L3.0661 4.04659L3.25914 4.04806C3.28414 4.04828 3.29448 4.08016 3.27439 4.095L3.1191 4.20969L3.17735 4.39372C3.18492 4.41753 3.15776 4.43728 3.13742 4.42272L2.98039 4.31047L2.82335 4.42272C2.80301 4.43725 2.77592 4.41753 2.78342 4.39372L2.84167 4.20969L2.68639 4.095C2.66629 4.08012 2.67664 4.04828 2.70164 4.04806L2.89467 4.04659L2.95573 3.86347C2.96357 3.83978 2.99714 3.83978 3.00504 3.86347Z"
        fill="#F5F5F5"
      />
      <path
        d="M3.00504 4.82052L3.0661 5.00365L3.25914 5.00511C3.28414 5.00533 3.29448 5.03721 3.27439 5.05205L3.1191 5.16674L3.17735 5.35076C3.18492 5.37458 3.15776 5.39433 3.13742 5.37976L2.98039 5.26752L2.82335 5.37976C2.80301 5.3943 2.77592 5.37461 2.78342 5.35076L2.84167 5.16674L2.68639 5.05205C2.66629 5.03718 2.67664 5.00533 2.70164 5.00511L2.89467 5.00365L2.95573 4.82052C2.96357 4.7968 2.99714 4.7968 3.00504 4.82052Z"
        fill="#F5F5F5"
      />
      <path
        d="M3.00504 5.77951L3.0661 5.96263L3.25914 5.9641C3.28414 5.96432 3.29448 5.99619 3.27439 6.01104L3.1191 6.12573L3.17735 6.30976C3.18492 6.33357 3.15776 6.35332 3.13742 6.33876L2.98039 6.22651L2.82335 6.33876C2.80301 6.35329 2.77592 6.33357 2.78342 6.30976L2.84167 6.12573L2.68639 6.01104C2.66629 5.99616 2.67664 5.96432 2.70164 5.9641L2.89467 5.96263L2.95573 5.77951C2.96357 5.75579 2.99714 5.75579 3.00504 5.77951Z"
        fill="#F5F5F5"
      />
      <path
        d="M3.00504 6.73652L3.0661 6.91964L3.25914 6.92111C3.28414 6.92133 3.29448 6.9532 3.27439 6.96805L3.1191 7.08273L3.17735 7.26676C3.18492 7.29058 3.15776 7.31033 3.13742 7.29576L2.98039 7.18351L2.82335 7.29576C2.80301 7.3103 2.77592 7.29058 2.78342 7.26676L2.84167 7.08273L2.68639 6.96805C2.66629 6.95317 2.67664 6.92133 2.70164 6.92111L2.89467 6.91964L2.95573 6.73652C2.96357 6.71283 2.99714 6.71283 3.00504 6.73652Z"
        fill="#F5F5F5"
      />
      <path
        d="M3.75019 3.39279L3.81125 3.57591L4.00428 3.57738C4.02928 3.5776 4.03963 3.60947 4.01953 3.62432L3.86425 3.739L3.9225 3.92303C3.93007 3.94685 3.90291 3.9666 3.88257 3.95203L3.7255 3.83979L3.56847 3.95203C3.54813 3.96657 3.52103 3.94685 3.52853 3.92303L3.58678 3.739L3.4315 3.62432C3.41141 3.60944 3.42175 3.5776 3.44675 3.57738L3.63978 3.57591L3.70085 3.39279C3.70875 3.36907 3.74228 3.36907 3.75019 3.39279Z"
        fill="#F5F5F5"
      />
      <path
        d="M3.75019 4.35175L3.81125 4.53487L4.00428 4.53634C4.02928 4.53656 4.03963 4.56844 4.01953 4.58328L3.86425 4.69797L3.9225 4.882C3.93007 4.90581 3.90291 4.92556 3.88257 4.911L3.7255 4.79875L3.56847 4.911C3.54813 4.92553 3.52103 4.90581 3.52853 4.882L3.58678 4.69797L3.4315 4.58328C3.41141 4.56841 3.42175 4.53656 3.44675 4.53634L3.63978 4.53487L3.70085 4.35175C3.70875 4.32806 3.74228 4.32806 3.75019 4.35175Z"
        fill="#F5F5F5"
      />
      <path
        d="M3.75019 5.30878L3.81125 5.49191L4.00428 5.49338C4.02928 5.4936 4.03963 5.52547 4.01953 5.54032L3.86425 5.65501L3.9225 5.83904C3.93007 5.86285 3.90291 5.8826 3.88257 5.86804L3.7255 5.75579L3.56847 5.86804C3.54813 5.88257 3.52103 5.86285 3.52853 5.83904L3.58678 5.65501L3.4315 5.54032C3.41141 5.52544 3.42175 5.4936 3.44675 5.49338L3.63978 5.49191L3.70085 5.30878C3.70875 5.28509 3.74228 5.28509 3.75019 5.30878Z"
        fill="#F5F5F5"
      />
      <path
        d="M3.75019 6.26779L3.81125 6.45091L4.00428 6.45238C4.02928 6.4526 4.03963 6.48448 4.01953 6.49932L3.86425 6.61401L3.9225 6.79804C3.93007 6.82185 3.90291 6.8416 3.88257 6.82704L3.7255 6.71479L3.56847 6.82704C3.54813 6.84157 3.52103 6.82185 3.52853 6.79804L3.58678 6.61401L3.4315 6.49932C3.41141 6.48444 3.42175 6.4526 3.44675 6.45238L3.63978 6.45091L3.70085 6.26779C3.70875 6.24407 3.74228 6.24407 3.75019 6.26779Z"
        fill="#F5F5F5"
      />
      <path
        d="M3.75019 7.22484L3.81125 7.40797L4.00428 7.40944C4.02928 7.40966 4.03963 7.44153 4.01953 7.45638L3.86425 7.57106L3.9225 7.7551C3.93007 7.77891 3.90291 7.79866 3.88257 7.7841L3.7255 7.67185L3.56847 7.7841C3.54813 7.79863 3.52103 7.77891 3.52853 7.7551L3.58678 7.57106L3.4315 7.45638C3.41141 7.4415 3.42175 7.40966 3.44675 7.40944L3.63978 7.40797L3.70085 7.22484C3.70875 7.20109 3.74228 7.20109 3.75019 7.22484Z"
        fill="#F5F5F5"
      />
      <path
        d="M4.49235 3.86347L4.55341 4.04659L4.74645 4.04806C4.77145 4.04828 4.78179 4.08016 4.7617 4.095L4.60641 4.20969L4.66466 4.39372C4.67223 4.41753 4.64507 4.43728 4.62473 4.42272L4.46769 4.31047L4.31066 4.42272C4.29032 4.43725 4.26322 4.41753 4.27072 4.39372L4.32897 4.20969L4.17369 4.095C4.1536 4.08012 4.16394 4.04828 4.18894 4.04806L4.38197 4.04659L4.44304 3.86347C4.45094 3.83978 4.48444 3.83978 4.49235 3.86347Z"
        fill="#F5F5F5"
      />
      <path
        d="M4.49235 4.82052L4.55341 5.00365L4.74645 5.00511C4.77145 5.00533 4.78179 5.03721 4.7617 5.05205L4.60641 5.16674L4.66466 5.35076C4.67223 5.37458 4.64507 5.39433 4.62473 5.37976L4.46769 5.26752L4.31066 5.37976C4.29032 5.3943 4.26322 5.37461 4.27072 5.35076L4.32897 5.16674L4.17369 5.05205C4.1536 5.03718 4.16394 5.00533 4.18894 5.00511L4.38197 5.00365L4.44304 4.82052C4.45094 4.7968 4.48444 4.7968 4.49235 4.82052Z"
        fill="#F5F5F5"
      />
      <path
        d="M4.49235 5.77951L4.55341 5.96263L4.74645 5.9641C4.77145 5.96432 4.78179 5.99619 4.7617 6.01104L4.60641 6.12573L4.66466 6.30976C4.67223 6.33357 4.64507 6.35332 4.62473 6.33876L4.46769 6.22651L4.31066 6.33876C4.29032 6.35329 4.26322 6.33357 4.27072 6.30976L4.32897 6.12573L4.17369 6.01104C4.1536 5.99616 4.16394 5.96432 4.18894 5.9641L4.38197 5.96263L4.44304 5.77951C4.45094 5.75579 4.48444 5.75579 4.49235 5.77951Z"
        fill="#F5F5F5"
      />
      <path
        d="M4.49235 6.73652L4.55341 6.91964L4.74645 6.92111C4.77145 6.92133 4.78179 6.9532 4.7617 6.96805L4.60641 7.08273L4.66466 7.26676C4.67223 7.29058 4.64507 7.31033 4.62473 7.29576L4.46769 7.18351L4.31066 7.29576C4.29032 7.3103 4.26322 7.29058 4.27072 7.26676L4.32897 7.08273L4.17369 6.96805C4.1536 6.95317 4.16394 6.92133 4.18894 6.92111L4.38197 6.91964L4.44304 6.73652C4.45094 6.71283 4.48444 6.71283 4.49235 6.73652Z"
        fill="#F5F5F5"
      />
      <path
        d="M5.23747 3.39279L5.29853 3.57591L5.49156 3.57738C5.51656 3.5776 5.52691 3.60947 5.50681 3.62432L5.35153 3.739L5.40978 3.92303C5.41734 3.94685 5.39019 3.9666 5.36984 3.95203L5.21281 3.83979L5.05578 3.95203C5.03543 3.96657 5.00834 3.94685 5.01584 3.92303L5.07409 3.739L4.91881 3.62432C4.89871 3.60944 4.90906 3.5776 4.93406 3.57738L5.12709 3.57591L5.18815 3.39279C5.196 3.36907 5.22956 3.36907 5.23747 3.39279Z"
        fill="#F5F5F5"
      />
      <path
        d="M5.23747 4.35175L5.29853 4.53487L5.49156 4.53634C5.51656 4.53656 5.52691 4.56844 5.50681 4.58328L5.35153 4.69797L5.40978 4.882C5.41734 4.90581 5.39019 4.92556 5.36984 4.911L5.21281 4.79875L5.05578 4.911C5.03543 4.92553 5.00834 4.90581 5.01584 4.882L5.07409 4.69797L4.91881 4.58328C4.89871 4.56841 4.90906 4.53656 4.93406 4.53634L5.12709 4.53487L5.18815 4.35175C5.196 4.32806 5.22956 4.32806 5.23747 4.35175Z"
        fill="#F5F5F5"
      />
      <path
        d="M5.23747 5.30878L5.29853 5.49191L5.49156 5.49338C5.51656 5.4936 5.52691 5.52547 5.50681 5.54032L5.35153 5.65501L5.40978 5.83904C5.41734 5.86285 5.39019 5.8826 5.36984 5.86804L5.21281 5.75579L5.05578 5.86804C5.03543 5.88257 5.00834 5.86285 5.01584 5.83904L5.07409 5.65501L4.91881 5.54032C4.89871 5.52544 4.90906 5.4936 4.93406 5.49338L5.12709 5.49191L5.18815 5.30878C5.196 5.28509 5.22956 5.28509 5.23747 5.30878Z"
        fill="#F5F5F5"
      />
      <path
        d="M5.23747 6.26779L5.29853 6.45091L5.49156 6.45238C5.51656 6.4526 5.52691 6.48448 5.50681 6.49932L5.35153 6.61401L5.40978 6.79804C5.41734 6.82185 5.39019 6.8416 5.36984 6.82704L5.21281 6.71479L5.05578 6.82704C5.03543 6.84157 5.00834 6.82185 5.01584 6.79804L5.07409 6.61401L4.91881 6.49932C4.89871 6.48444 4.90906 6.4526 4.93406 6.45238L5.12709 6.45091L5.18815 6.26779C5.196 6.24407 5.22956 6.24407 5.23747 6.26779Z"
        fill="#F5F5F5"
      />
      <path
        d="M5.23747 7.22484L5.29853 7.40797L5.49156 7.40944C5.51656 7.40966 5.52691 7.44153 5.50681 7.45638L5.35153 7.57106L5.40978 7.7551C5.41734 7.77891 5.39019 7.79866 5.36984 7.7841L5.21281 7.67185L5.05578 7.7841C5.03543 7.79863 5.00834 7.77891 5.01584 7.7551L5.07409 7.57106L4.91881 7.45638C4.89871 7.4415 4.90906 7.40966 4.93406 7.40944L5.12709 7.40797L5.18815 7.22484C5.196 7.20109 5.22956 7.20109 5.23747 7.22484Z"
        fill="#F5F5F5"
      />
      <path
        d="M5.98063 3.86347L6.04169 4.04659L6.23472 4.04806C6.25972 4.04828 6.27006 4.08016 6.24997 4.095L6.09469 4.20969L6.15294 4.39372C6.1605 4.41753 6.13334 4.43728 6.113 4.42272L5.95597 4.31047L5.79894 4.42272C5.7786 4.43725 5.7515 4.41753 5.759 4.39372L5.81725 4.20969L5.66197 4.095C5.64188 4.08012 5.65222 4.04828 5.67722 4.04806L5.87025 4.04659L5.93131 3.86347C5.93919 3.83978 5.97275 3.83978 5.98063 3.86347Z"
        fill="#F5F5F5"
      />
      <path
        d="M5.98063 4.82052L6.04169 5.00365L6.23472 5.00511C6.25972 5.00533 6.27006 5.03721 6.24997 5.05205L6.09469 5.16674L6.15294 5.35076C6.1605 5.37458 6.13334 5.39433 6.113 5.37976L5.95597 5.26752L5.79894 5.37976C5.7786 5.3943 5.7515 5.37461 5.759 5.35076L5.81725 5.16674L5.66197 5.05205C5.64188 5.03718 5.65222 5.00533 5.67722 5.00511L5.87025 5.00365L5.93131 4.82052C5.93919 4.7968 5.97275 4.7968 5.98063 4.82052Z"
        fill="#F5F5F5"
      />
      <path
        d="M5.98063 5.77951L6.04169 5.96263L6.23472 5.9641C6.25972 5.96432 6.27006 5.99619 6.24997 6.01104L6.09469 6.12573L6.15294 6.30976C6.1605 6.33357 6.13334 6.35332 6.113 6.33876L5.95597 6.22651L5.79894 6.33876C5.7786 6.35329 5.7515 6.33357 5.759 6.30976L5.81725 6.12573L5.66197 6.01104C5.64188 5.99616 5.65222 5.96432 5.67722 5.9641L5.87025 5.96263L5.93131 5.77951C5.93919 5.75579 5.97275 5.75579 5.98063 5.77951Z"
        fill="#F5F5F5"
      />
      <path
        d="M5.98063 6.73652L6.04169 6.91964L6.23472 6.92111C6.25972 6.92133 6.27006 6.9532 6.24997 6.96805L6.09469 7.08273L6.15294 7.26676C6.1605 7.29058 6.13334 7.31033 6.113 7.29576L5.95597 7.18351L5.79894 7.29576C5.7786 7.3103 5.7515 7.29058 5.759 7.26676L5.81725 7.08273L5.66197 6.96805C5.64188 6.95317 5.65222 6.92133 5.67722 6.92111L5.87025 6.91964L5.93131 6.73652C5.93919 6.71283 5.97275 6.71283 5.98063 6.73652Z"
        fill="#F5F5F5"
      />
      <path
        d="M6.72379 3.39279L6.78486 3.57591L6.97789 3.57738C7.00289 3.5776 7.01323 3.60947 6.99314 3.62432L6.83786 3.739L6.89611 3.92303C6.90367 3.94685 6.87651 3.9666 6.85617 3.95203L6.69914 3.83979L6.54211 3.95203C6.52176 3.96657 6.49467 3.94685 6.50217 3.92303L6.56042 3.739L6.40514 3.62432C6.38504 3.60944 6.39539 3.5776 6.42039 3.57738L6.61342 3.57591L6.67448 3.39279C6.68236 3.36907 6.71586 3.36907 6.72379 3.39279Z"
        fill="#F5F5F5"
      />
      <path
        d="M6.72379 4.35175L6.78486 4.53487L6.97789 4.53634C7.00289 4.53656 7.01323 4.56844 6.99314 4.58328L6.83786 4.69797L6.89611 4.882C6.90367 4.90581 6.87651 4.92556 6.85617 4.911L6.69914 4.79875L6.54211 4.911C6.52176 4.92553 6.49467 4.90581 6.50217 4.882L6.56042 4.69797L6.40514 4.58328C6.38504 4.56841 6.39539 4.53656 6.42039 4.53634L6.61342 4.53487L6.67448 4.35175C6.68236 4.32806 6.71586 4.32806 6.72379 4.35175Z"
        fill="#F5F5F5"
      />
      <path
        d="M6.72379 5.30878L6.78486 5.49191L6.97789 5.49338C7.00289 5.4936 7.01323 5.52547 6.99314 5.54032L6.83786 5.65501L6.89611 5.83904C6.90367 5.86285 6.87651 5.8826 6.85617 5.86804L6.69914 5.75579L6.54211 5.86804C6.52176 5.88257 6.49467 5.86285 6.50217 5.83904L6.56042 5.65501L6.40514 5.54032C6.38504 5.52544 6.39539 5.4936 6.42039 5.49338L6.61342 5.49191L6.67448 5.30878C6.68236 5.28509 6.71586 5.28509 6.72379 5.30878Z"
        fill="#F5F5F5"
      />
      <path
        d="M6.72379 6.26779L6.78486 6.45091L6.97789 6.45238C7.00289 6.4526 7.01323 6.48448 6.99314 6.49932L6.83786 6.61401L6.89611 6.79804C6.90367 6.82185 6.87651 6.8416 6.85617 6.82704L6.69914 6.71479L6.54211 6.82704C6.52176 6.84157 6.49467 6.82185 6.50217 6.79804L6.56042 6.61401L6.40514 6.49932C6.38504 6.48444 6.39539 6.4526 6.42039 6.45238L6.61342 6.45091L6.67448 6.26779C6.68236 6.24407 6.71586 6.24407 6.72379 6.26779Z"
        fill="#F5F5F5"
      />
      <path
        d="M6.72379 7.22484L6.78486 7.40797L6.97789 7.40944C7.00289 7.40966 7.01323 7.44153 6.99314 7.45638L6.83786 7.57106L6.89611 7.7551C6.90367 7.77891 6.87651 7.79866 6.85617 7.7841L6.69914 7.67185L6.54211 7.7841C6.52176 7.79863 6.49467 7.77891 6.50217 7.7551L6.56042 7.57106L6.40514 7.45638C6.38504 7.4415 6.39539 7.40966 6.42039 7.40944L6.61342 7.40797L6.67448 7.22484C6.68236 7.20109 6.71586 7.20109 6.72379 7.22484Z"
        fill="#F5F5F5"
      />
    </svg>
  );
};

export default IconFlagUSA;
