import { AxiosError } from 'axios';

import {
  IUploadFilesAction,
  IUploadFilesActionFail,
  IUploadFilesActionSuccess,
  IDeleteFilesAction,
  IDeleteFileActionSuccess,
  IDeleteFilesActionFail,
} from 'redux/_common/commonTypes';

import { IFeedback } from '../_models/feedbackModels';

// SEND FEEDBACK
export const SEND_FEEDBACK = 'SEND_FEEDBACK';
export const SEND_FEEDBACK_SUCCESS = 'SEND_FEEDBACK_SUCCESS';
export const SEND_FEEDBACK_FAIL = 'SEND_FEEDBACK_FAIL';

export interface ISendFeedbackAction {
  type: typeof SEND_FEEDBACK;
  payload: {
    data: IFeedback;
    callback?: () => void;
  };
}
export interface ISendFeedbackActionSuccess {
  type: typeof SEND_FEEDBACK_SUCCESS;
}
export interface ISendFeedbackActionFail {
  type: typeof SEND_FEEDBACK_FAIL;
  payload: AxiosError;
}

// UPLOAD FEEDBACK FILES
export const UPLOAD_FEEDBACK_FILES = '[@@Feedback] UPLOAD_FEEDBACK_FILES';
export const UPLOAD_FEEDBACK_FILES_SUCCESS =
  '[@@Feedback] UPLOAD_FEEDBACK_FILES_SUCCESS';
export const UPLOAD_FEEDBACK_FILES_FAIL =
  '[@@Feedback] UPLOAD_FEEDBACK_FILES_FAIL';

export interface IUploadFeedbackFilesAction extends IUploadFilesAction {
  type: typeof UPLOAD_FEEDBACK_FILES;
}
export interface IUploadFeedbackFilesActionSuccess
  extends IUploadFilesActionSuccess {
  type: typeof UPLOAD_FEEDBACK_FILES_SUCCESS;
}
export interface IUploadFeedbackFilesActionFail extends IUploadFilesActionFail {
  type: typeof UPLOAD_FEEDBACK_FILES_FAIL;
}

// DELETE FEEDBACK FILE
export const DELETE_FEEDBACK_FILES = '[@@Feedback] DELETE_FEEDBACK_FILES';
export const DELETE_FEEDBACK_FILES_SUCCESS =
  '[@@Feedback] DELETE_FEEDBACK_FILES_SUCCESS';
export const DELETE_FEEDBACK_FILES_FAIL =
  '[@@Feedback] DELETE_FEEDBACK_FILES_FAIL';

export interface IDeleteFeedbackFilesAction extends IDeleteFilesAction {
  type: typeof DELETE_FEEDBACK_FILES;
}
export interface IDeleteFeedbackFileActionSuccess
  extends IDeleteFileActionSuccess {
  type: typeof DELETE_FEEDBACK_FILES_SUCCESS;
}
export interface IDeleteFeedbackFilesActionFail extends IDeleteFilesActionFail {
  type: typeof DELETE_FEEDBACK_FILES_FAIL;
}

// UNMOUNT FEEDBACK FORM
export const UNMOUNT_FEEDBACK_FORM = '[@@Feedback] UNMOUNT_FEEDBACK_FORM';

export interface IUnmountFeedbackFormAction {
  type: typeof UNMOUNT_FEEDBACK_FORM;
}

export type feedbackTypes =
  | ISendFeedbackAction
  | ISendFeedbackActionSuccess
  | ISendFeedbackActionFail
  | IUploadFeedbackFilesAction
  | IUploadFeedbackFilesActionSuccess
  | IUploadFeedbackFilesActionFail
  | IDeleteFeedbackFilesAction
  | IDeleteFeedbackFileActionSuccess
  | IDeleteFeedbackFilesActionFail
  | IUnmountFeedbackFormAction;
