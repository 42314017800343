import { AxiosError } from 'axios';

import { LOGOUT } from 'components/Auth/_redux/authTypes';

import { ITruckMaintenance } from '../_models/truckMaintenanceModels';
import {
  GET_ALL_TRUCKS_MAINTENANCE_LIST,
  GET_ALL_TRUCKS_MAINTENANCE_LIST_FAIL,
  GET_ALL_TRUCKS_MAINTENANCE_LIST_SUCCESS,
  allTrucksMaintenanceTypes,
} from './allTrucksMaintenanceTypes';

export interface ITrucksMaintenanceState {
  list: ITruckMaintenance[];
  _listPending: boolean;
  _listError: AxiosError | null;
}

const initialState: ITrucksMaintenanceState = {
  list: [],
  _listError: null,
  _listPending: false,
};

const allTrucksMaintenanceReducers = (
  state = initialState,
  action: allTrucksMaintenanceTypes,
): ITrucksMaintenanceState => {
  switch (action.type) {
    // GET MAINTENANCE LIST
    case GET_ALL_TRUCKS_MAINTENANCE_LIST:
      return {
        ...state,
        list: [],
        _listError: null,
        _listPending: true,
      };
    case GET_ALL_TRUCKS_MAINTENANCE_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.data,
        _listPending: false,
      };
    case GET_ALL_TRUCKS_MAINTENANCE_LIST_FAIL:
      return {
        ...state,
        _listError: action.payload,
        _listPending: false,
      };
    // LOGOUT
    case LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
};

export default allTrucksMaintenanceReducers;
