import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { userSettingsSelector } from '../redux/userSettings/userSettingsSelectors';

// TODO: Deprecate / use useCheckUserPermission instead
export default function useCheckPermission(
  path: string,
  permissionType?: string,
) {
  const [hasPermission, setHasPermission] = useState(false);
  const { currentPermissions } = useSelector(userSettingsSelector);

  useEffect(() => {
    if (currentPermissions && currentPermissions.length > 0) {
      // eslint-disable-next-line array-callback-return
      currentPermissions.map(permission => {
        const hasPerm = permission.permissions.some(
          permiss => permissionType === permiss,
        );
        if (!hasPermission && hasPerm) setHasPermission(hasPerm);
      });
    }
  }, [path, currentPermissions, hasPermission]);

  return { hasPermission };
}
