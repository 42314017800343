import { FC } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const IconInventory: FC<IOwnProps> = ({
  className,
  fillColor = 'var(--sideBarIcon)',
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15 13H5M1 1H3V11"
        stroke={fillColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="3" cy="13" r="2" stroke={fillColor} strokeWidth="2" />
      <rect
        x="7"
        y="2"
        width="7"
        height="7"
        rx="1"
        stroke={fillColor}
        strokeWidth="2"
      />
    </svg>
  );
};

export default IconInventory;
