import { IWithError, IWithLoaded, IWithPending } from 'utils/models';

import { IInternalCompany } from './InternalCompanyModels';
import {
  CREATE_INTERNAL_COMPANY,
  CREATE_INTERNAL_COMPANY_FAIL,
  CREATE_INTERNAL_COMPANY_SUCCESS,
  DELETE_COMPANY_PHOTO,
  DELETE_COMPANY_PHOTO_FAIL,
  DELETE_COMPANY_PHOTO_SUCCESS,
  DELETE_INTERNAL_COMPANY,
  DELETE_INTERNAL_COMPANY_FAIL,
  DELETE_INTERNAL_COMPANY_SUCCESS,
  GET_INTERNAL_COMPANIES,
  GET_INTERNAL_COMPANIES_FAIL,
  GET_INTERNAL_COMPANIES_SUCCESS,
  internalCompanyActionsType,
  UPLOAD_COMPANY_PHOTO,
  UPLOAD_COMPANY_PHOTO_FAIL,
  UPLOAD_COMPANY_PHOTO_SUCCESS,
} from './internalCompanyTypes';

interface IInternalCompanyReducer
  extends IWithError,
    IWithPending,
    IWithLoaded {
  internal_companies: IInternalCompany[];
}

const initialState: IInternalCompanyReducer = {
  internal_companies: [],
  _pending: false,
  _loaded: false,
  _error: null,
};

const internalCompanyReducer = (
  state = initialState,
  action: internalCompanyActionsType,
) => {
  switch (action.type) {
    case UPLOAD_COMPANY_PHOTO: {
      return {
        ...state,
        _error: null,
      };
    }
    case UPLOAD_COMPANY_PHOTO_SUCCESS: {
      return {
        ...state,
        _error: null,
      };
    }
    case UPLOAD_COMPANY_PHOTO_FAIL: {
      return {
        ...state,
        _error: action.payload,
      };
    }
    case DELETE_COMPANY_PHOTO: {
      return {
        ...state,
        _error: null,
      };
    }
    case DELETE_COMPANY_PHOTO_SUCCESS: {
      return {
        ...state,
        _error: null,
      };
    }
    case DELETE_COMPANY_PHOTO_FAIL: {
      return {
        ...state,
        _error: action.payload,
      };
    }
    case CREATE_INTERNAL_COMPANY: {
      return {
        ...state,
        _error: null,
      };
    }
    case CREATE_INTERNAL_COMPANY_SUCCESS: {
      return {
        ...state,
        _error: null,
      };
    }
    case CREATE_INTERNAL_COMPANY_FAIL: {
      return {
        ...state,
        _error: action.payload,
      };
    }
    case GET_INTERNAL_COMPANIES: {
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    }
    case GET_INTERNAL_COMPANIES_SUCCESS: {
      return {
        ...state,
        internal_companies: action.payload,
        _loaded: true,
        _pending: false,
      };
    }
    case GET_INTERNAL_COMPANIES_FAIL: {
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };
    }
    case DELETE_INTERNAL_COMPANY: {
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    }
    case DELETE_INTERNAL_COMPANY_SUCCESS: {
      return {
        ...state,
        internal_companies: state.internal_companies.filter(
          company => company.id !== action.payload.companyId,
        ),
        _loaded: true,
        _pending: false,
        _error: null,
      };
    }
    case DELETE_INTERNAL_COMPANY_FAIL: {
      return {
        ...state,
        _loaded: true,
        _pending: false,
        _error: action.payload,
      };
    }
    default:
      return { ...state };
  }
};

export default internalCompanyReducer;
