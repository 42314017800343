import { call, put, takeLatest } from 'redux-saga/effects';

import {
  WISE_CHECK_SUBSCRIPTION_APIS,
  WISE_CHECK_SUBSCRIPTION_API_PREFIX,
} from 'constants/api';
import api from 'utils/requests';

import { fileToFormData } from '../../../../utils/converters';
import {
  deleteCompanyFileAction,
  getActivePlan,
  getActivePlanSuccess,
  getSubscriptionPlans,
  getSubscriptionPlansSuccess,
  uploadCompanyFilesAction,
} from './companySettingsActions';
import {
  GET_SUBSCRIPTION_PLANS,
  GET_ACTIVE_PLAN,
  UPLOAD_COMPANY_FILES_SUCCESS,
  UPLOAD_COMPANY_FILES_FAIL,
  UPLOAD_COMPANY_FILES,
  DELETE_COMPANY_FILE,
  DELETE_COMPANY_FILE_SUCCESS,
  DELETE_COMPANY_FILE_FAIL,
  GET_COMPANY_FILES_SUCCESS,
  GET_COMPANY_FILES_FAIL,
  GET_COMPANY_FILES,
} from './companySettingsTypes';

// LABELS
function* getSubscriptionPlanSaga({
  payload,
}: ReturnType<typeof getSubscriptionPlans>) {
  if (!payload) {
    try {
      const { data } = yield call(() =>
        api.get(WISE_CHECK_SUBSCRIPTION_APIS.PLANS),
      );
      yield put(
        getSubscriptionPlansSuccess(data.companies_subscriptions_plans),
      );
    } catch (e) {
      if (api.isAxiosError(e)) console.warn('e', e);
    }
  } else {
    try {
      const { data } = yield call(() =>
        api.get('v1/company_subscriptions/plans/basic'),
      );
      yield put(
        getSubscriptionPlansSuccess(data.companies_subscriptions_plans),
      );
    } catch (e) {
      if (api.isAxiosError(e)) console.warn('e', e);
    }
  }
}

function* getActivePlanSaga({ callback }: ReturnType<typeof getActivePlan>) {
  try {
    const { data } = yield call(() =>
      api.get(WISE_CHECK_SUBSCRIPTION_API_PREFIX),
    );
    yield put(getActivePlanSuccess(data));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) console.warn('e', e);
  }
}

function* uploadCompanyFilesSaga({
  payload: { files },
}: ReturnType<typeof uploadCompanyFilesAction>) {
  const url = '/v1/employee/company_settings/files';

  for (const fileObj of files) {
    yield call(function* processFiles() {
      try {
        const { data } = yield call(() =>
          api.post(url, fileToFormData(fileObj.file, `companies_file[file]`), {
            headers: { 'Content-Type': 'multipart/form-data' },
          }),
        );
        yield put({
          type: UPLOAD_COMPANY_FILES_SUCCESS,
          payload: { ...data, justCreated: true },
        });
      } catch (e) {
        yield put({ type: UPLOAD_COMPANY_FILES_FAIL, payload: e });
      }
    });
  }
}

function* deleteCompanyFileSaga({
  payload: { fileId },
}: ReturnType<typeof deleteCompanyFileAction>) {
  const url = `/v1/employee/company_settings/files/${fileId}`;

  try {
    yield call(() => api.delete(url));
    yield put({ type: DELETE_COMPANY_FILE_SUCCESS, payload: fileId });
  } catch (e) {
    yield put({ type: DELETE_COMPANY_FILE_FAIL, payload: e });
  }
}

function* getCompanyFilesSaga() {
  const url = `/v1/employee/company_settings/files`;

  try {
    const { data } = yield call(() => api.get(url));
    yield put({ type: GET_COMPANY_FILES_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_COMPANY_FILES_FAIL, payload: e });
  }
}

export default function* companySettingsSaga() {
  yield takeLatest(GET_SUBSCRIPTION_PLANS, getSubscriptionPlanSaga);
  yield takeLatest(GET_ACTIVE_PLAN, getActivePlanSaga);
  yield takeLatest(UPLOAD_COMPANY_FILES, uploadCompanyFilesSaga);
  yield takeLatest(DELETE_COMPANY_FILE, deleteCompanyFileSaga);
  yield takeLatest(GET_COMPANY_FILES, getCompanyFilesSaga);
}
