import { AxiosError } from 'axios';

import { IBrokerContact } from '../_models/brokerModel';

// // CREATE BROKER EMPLOYEE
export const CREATE_BROKER_EMPLOYEE = '[@@Brokers] CREATE_BROKER_EMPLOYEE';
export const CREATE_BROKER_EMPLOYEE_SUCCESS =
  '[@@Brokers] CREATE_BROKER_EMPLOYEE_SUCCESS';
export const CREATE_BROKER_EMPLOYEE_FAIL =
  '[@@Brokers] CREATE_BROKER_EMPLOYEE_FAIL';

export interface ICreateBrokerEmployeeAction {
  type: typeof CREATE_BROKER_EMPLOYEE;
}
export interface ICreateBrokerEmployeeActionSuccess {
  type: typeof CREATE_BROKER_EMPLOYEE_SUCCESS;
  payload: IBrokerContact;
}
export interface ICreateBrokerEmployeeActionFail {
  type: typeof CREATE_BROKER_EMPLOYEE_FAIL;
  payload: AxiosError;
}

// // UPDATE BROKER EMPLOYEE
export const UPDATE_BROKER_EMPLOYEE = '[@@Brokers] UPDATE_BROKER_EMPLOYEE';
export const UPDATE_BROKER_EMPLOYEE_SUCCESS =
  '[@@Brokers] UPDATE_BROKER_EMPLOYEE_SUCCESS';
export const UPDATE_BROKER_EMPLOYEE_FAIL =
  '[@@Brokers] UPDATE_BROKER_EMPLOYEE_FAIL';

export interface IUpdateBrokerEmployeeAction {
  type: typeof UPDATE_BROKER_EMPLOYEE;
}
export interface IUpdateBrokerEmployeeActionSuccess {
  type: typeof UPDATE_BROKER_EMPLOYEE_SUCCESS;
  payload: IBrokerContact;
}
export interface IUpdateBrokerEmployeeActionFail {
  type: typeof UPDATE_BROKER_EMPLOYEE_FAIL;
  payload: AxiosError;
}

// // DELETE BROKER EMPLOYEE
export const DELETE_BROKER_EMPLOYEE = '[@@Brokers] DELETE_BROKER_EMPLOYEE';
export const DELETE_BROKER_EMPLOYEE_SUCCESS =
  '[@@Brokers] DELETE_BROKER_EMPLOYEE_SUCCESS';
export const DELETE_BROKER_EMPLOYEE_FAIL =
  '[@@Brokers] DELETE_BROKER_EMPLOYEE_FAIL';

export interface IDeleteBrokerEmployeeAction {
  type: typeof DELETE_BROKER_EMPLOYEE;
}
export interface IDeleteBrokerEmployeeActionSuccess {
  type: typeof DELETE_BROKER_EMPLOYEE_SUCCESS;
  payload: number;
}
export interface IDeleteBrokerEmployeeActionFail {
  type: typeof DELETE_BROKER_EMPLOYEE_FAIL;
  payload: AxiosError;
}

export type brokerEmployeeActionsType =
  | ICreateBrokerEmployeeAction
  | ICreateBrokerEmployeeActionSuccess
  | ICreateBrokerEmployeeActionFail
  | IUpdateBrokerEmployeeAction
  | IUpdateBrokerEmployeeActionSuccess
  | IUpdateBrokerEmployeeActionFail
  | IDeleteBrokerEmployeeAction
  | IDeleteBrokerEmployeeActionSuccess
  | IDeleteBrokerEmployeeActionFail;
