import {
  ASSIGN_VEHICLES,
  ASSIGN_VEHICLES_FAIL,
  ASSIGN_VEHICLES_SUCCESS,
  DELETE_WORK_ORDER_FILE,
  DELETE_WORK_ORDER_FILE_FAIL,
  DELETE_WORK_ORDER_FILE_SUCCESS,
  fleetTypes,
  IFleetGroupReducer,
  SET_FLEET_GROUP_VIEW_KEY,
  UPLOAD_WORK_ORDER_FILES,
  UPLOAD_WORK_ORDER_FILES_FAIL,
  UPLOAD_WORK_ORDER_FILES_SUCCESS,
} from './fleetGroupTypes';

const initialState: IFleetGroupReducer = {
  viewKey: 'list',
  workOrderFiles: [],
  _pending: false,
};

const fleetGroupReducer = (
  state = initialState,
  action: fleetTypes,
): IFleetGroupReducer => {
  switch (action.type) {
    case SET_FLEET_GROUP_VIEW_KEY:
      return {
        ...state,
        viewKey: action.payload,
      };

    case ASSIGN_VEHICLES:
      return {
        ...state,
        _pending: true,
      };

    case ASSIGN_VEHICLES_SUCCESS:
    case ASSIGN_VEHICLES_FAIL:
      return {
        ...state,
        _pending: false,
      };

    case UPLOAD_WORK_ORDER_FILES:
      return {
        ...state,
        workOrderFiles: [
          ...state.workOrderFiles,
          ...action.payload.files.map(file => ({
            ...file,
            loading: true,
          })),
        ],
      };

    case UPLOAD_WORK_ORDER_FILES_SUCCESS:
      return {
        ...state,
        workOrderFiles: state.workOrderFiles.map((file, index: number) =>
          index === state.workOrderFiles.findIndex(({ loading }) => loading)
            ? { ...action.payload, loading: false }
            : file,
        ),
        _pending: false,
      };
    case UPLOAD_WORK_ORDER_FILES_FAIL:
      return {
        ...state,
        _pending: false,
      };
    case DELETE_WORK_ORDER_FILE:
      return {
        ...state,
      };
    case DELETE_WORK_ORDER_FILE_SUCCESS:
      return {
        ...state,
        workOrderFiles: state.workOrderFiles.filter(
          file => file.id !== action.payload.fileId,
        ),
      };
    case DELETE_WORK_ORDER_FILE_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default fleetGroupReducer;
