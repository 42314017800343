import { FC } from 'react';

type OwnProps = {
  className?: string;
  color?: string;
};

const IconAdjust: FC<OwnProps> = ({
  className,
  color = 'var(--primaryText)',
}) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 6L20 6"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M4 12H20" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M4 18H20" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path
        d="M16 4L16 8"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M8 10L8 14"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M16 16L16 20"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconAdjust;
