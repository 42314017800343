import { FC, useRef, useEffect } from 'react';

import clsx from 'clsx';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import { BlueCheckbox } from 'components/_common/CustomMaterialComponents';
import { ISelectOptions } from 'components/_common/FormElements/FormField/Select/_models/selectModels';
import Select from 'components/_common/FormElements/FormField/Select/Select';
import { getAttrName } from 'hooks/useTestAttrRef';

import styles from './CustomCheckboxes.module.scss';

type OwnProps = {
  id: string;
  label: string;
  value: string;
  className: string;
  options: ISelectOptions;
  description?: string;
  checkOut?: number | null;
  formType?: string;
  focusOnRender: boolean;
};

type Props = OwnProps & WrappedFieldInputProps & WrappedFieldMetaProps;

const CustomCheckboxWithSelect: FC<Partial<Props>> = ({
  value,
  name,
  label,
  className,
  options,
  onChange,
  checkOut,
  formType,
  focusOnRender,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  let checkInLocal = !value ? 0 : value;
  if (value === true) checkInLocal = 1;
  const checkOutLocal = checkOut === null ? 0 : checkOut;

  useEffect(() => {
    // focus after 0.75s ( magic number )
    // if faster - material incorrectly show focus styles
    if (focusOnRender && inputRef?.current) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 750);
    }
  }, [focusOnRender]);

  return (
    <div className={styles.checkboxWithSelect}>
      <section className={styles.checkbox}>
        <BlueCheckbox
          inputRef={inputRef}
          name={name}
          color="primary"
          onChange={onChange}
          checked={!!value}
          classes={{
            root: className,
          }}
          inputProps={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            'data-test': `${getAttrName(name)}_checkbox`,
            'data-value': value ? 'checked' : 'unchecked',
          }}
        />
        <span>
          {label}
          {formType === 'check_in' && (
            <span
              className={clsx(
                styles.comparing,
                checkOutLocal !== undefined &&
                  checkInLocal !== undefined &&
                  +checkInLocal !== +checkOutLocal &&
                  styles.warning,
              )}
            >
              {`(${checkInLocal === undefined ? 0 : checkInLocal} / ${
                checkOutLocal === undefined ? 0 : checkOutLocal
              })`}
            </span>
          )}
        </span>
      </section>
      {!!value && (
        <section className={styles.select}>
          <Select
            options={options}
            disabled={!value}
            onChange={onChange}
            value={`${value}` === 'true' ? 1 : value}
            name={`input_${name}`}
            className={styles.select}
          />
        </section>
      )}
    </div>
  );
};

export default CustomCheckboxWithSelect;
