import { IGeoFenceMap } from 'components/Fleet/TabGeoFences/_models/geoFenceMap';
import { ITollMap } from 'components/Fleet/TabTolls/_models/tollMap';
import { ITrailerMap } from 'components/Fleet/TabTrailers/_models/trailerMapModels';
import { ITruckMap } from 'components/Fleet/TabTrucks/_models/truckMapModel';
import { ILogout } from 'redux/_common/models';

export type MapTypeId = 'roadmap' | 'hybrid';

export interface IGoogleReducer {
  isInitGoogleAPIs: boolean;
  mapTypeId: MapTypeId;
  truckMap: ITruckMap;
  geoFenceMap: IGeoFenceMap;
  tollMap: ITollMap;
  trailerMap: ITrailerMap;
}

// INIT_GOOGLE_SCRIPT
export const INIT_GOOGLE_SCRIPT = '[@@Service/Google] INIT_GOOGLE_SCRIPT';

export interface IInitGoogleScriptAction {
  type: typeof INIT_GOOGLE_SCRIPT;
  payload: boolean;
}

// SET_MAP_TYPE_ID
export const SET_MAP_TYPE_ID = '[@@Service/Google] SET_MAP_TYPE_ID';

export interface ISetMapTypeIdAction {
  type: typeof SET_MAP_TYPE_ID;
  payload: MapTypeId;
}

// SET_TRUCK_MAP
export const SET_TRUCK_MAP = '[@@Service/Google] SET_TRUCK_MAP';

export interface ISetTruckMapAction {
  type: typeof SET_TRUCK_MAP;
  payload: ITruckMap;
}

// SET_GEOFENCE_MAP
export const SET_GEOFENCE_MAP = '[@@Service/Google] SET_GEOFENCE_MAP';

export interface ISetGeoFenceMapAction {
  type: typeof SET_GEOFENCE_MAP;
  payload: IGeoFenceMap;
}

// SET_TOLL_MAP
export const SET_TOLL_MAP = '[@@Service/Google] SET_TOLL_MAP';

export interface ISetTollMapAction {
  type: typeof SET_TOLL_MAP;
  payload: ITollMap;
}

// SET_TRAILER_MAP
export const SET_TRAILER_MAP = '[@@Service/Google] SET_TRAILER_MAP';
export interface ISetTrailerMapAction {
  type: typeof SET_TRAILER_MAP;
  payload: ITrailerMap;
}

// CLEAR_ALL_MAPS
export const CLEAR_ALL_MAPS = '[@@Service/Google] CLEAR_ALL_MAPS';

export interface IClearAllMapsAction {
  type: typeof CLEAR_ALL_MAPS;
}

export type initGoogleActionsType =
  | IInitGoogleScriptAction
  | ISetMapTypeIdAction
  | IClearAllMapsAction
  | ISetTruckMapAction
  | ISetGeoFenceMapAction
  | ISetTollMapAction
  | ISetTrailerMapAction
  | ILogout;
