import { FC } from 'react';

import { useSelector } from 'react-redux';

import { userThemeSelector } from 'redux/userSettings/userSettingsSelectors';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const LittleOdometer: FC<IOwnProps> = ({
  className,
  fillColor = '#86898E',
}) => {
  const theme = useSelector(userThemeSelector);

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M8.39449 12L5.92554 7"
          stroke={theme === 'dark' ? 'white' : fillColor}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.6745 12C14.7649 11.515 14.8137 11.014 14.8137 10.5C14.8137 6.358 11.7185 3 7.90063 3C4.08277 3 0.987549 6.358 0.987549 10.5C0.987549 11.014 1.03548 11.515 1.12673 12H14.6745Z"
          stroke={theme === 'dark' ? 'white' : fillColor}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0H15.8013V16H0V0Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LittleOdometer;
