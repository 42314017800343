import { AxiosError } from 'axios';

import { IPagination } from 'components/_common/Navigation/Pagination/_redux/paginationTypes';

import { IApplicantArchived } from '../_models/mainEntities';

export const GET_APPLICANTS_ARCHIVE_LIST =
  '[@@ApplicantsArchive] GET_APPLICANTS_ARCHIVE_LIST';
export const GET_APPLICANTS_ARCHIVE_LIST_SUCCESS =
  '[@@ApplicantsArchive] GET_APPLICANTS_ARCHIVE_LIST_SUCCESS';
export const GET_APPLICANTS_ARCHIVE_LIST_FAIL =
  '[@@ApplicantsArchive] GET_APPLICANTS_FAIL';

export interface IGetApplicantsArchiveList {
  type: typeof GET_APPLICANTS_ARCHIVE_LIST;
  query?: string;
}

export interface IGetApplicantsArchiveListSuccess {
  type: typeof GET_APPLICANTS_ARCHIVE_LIST_SUCCESS;
  payload: {
    applicants: IApplicantArchived[];
    pagination: IPagination;
  };
}

export interface IGetApplicantsArchiveListFail {
  type: typeof GET_APPLICANTS_ARCHIVE_LIST_FAIL;
  payload: AxiosError;
}

export type applicantsArchiveTypes =
  | IGetApplicantsArchiveList
  | IGetApplicantsArchiveListSuccess
  | IGetApplicantsArchiveListFail;
