import { AxiosError } from 'axios';

import {
  IInspectionFile,
  InspectionFileView,
  InspectionPhotoCommentLocal,
  InspectionPhotoCommentServer,
} from 'components/Fleet/_models/commonModels';
import {
  IInspection,
  IDraftInspection,
  IVehicleDraftStart,
} from 'components/Fleet/TabInspections/_models/vehicleInspectionModels';
import { ILogout } from 'redux/_common/models';

import { TrailerInspectionFormType } from '../../../TabTrailers/TrailerProfile/TrailerInspections/_models/trailerInspection';
import { TruckInspectionFormType } from '../../../TabTrucks/TruckProfile/TruckInspections/_models/truckInspection';
import { IClearInspectionErrorAction } from '../../_redux/tabInspectionsTypes';
import {
  ITandemInspection,
  TandemInspectionFormType,
} from '../_models/tandemInspection';

// CREATE_TANDEM_INSPECTION
export const CREATE_TANDEM_INSPECTION =
  '[@@TandemInspection] CREATE_TANDEM_INSPECTION';
export const CREATE_TANDEM_INSPECTION_SUCCESS =
  '[@@TandemInspection] CREATE_TANDEM_INSPECTION_SUCCESS';
export const CREATE_TANDEM_INSPECTION_FAIL =
  '[@@TandemInspection] CREATE_TANDEM_INSPECTION_FAIL';

export interface ICreateTandemInspectionAction {
  type: typeof CREATE_TANDEM_INSPECTION;
  payload: {
    data: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      formData: any;
      tandemId?: number;
    };
    callback?: () => void;
  };
}
export interface ICreateTandemInspectionActionSuccess {
  type: typeof CREATE_TANDEM_INSPECTION_SUCCESS;
  payload: {
    inspection: IDraftInspection;
  };
}
export interface ICreateTandemInspectionActionFail {
  type: typeof CREATE_TANDEM_INSPECTION_FAIL;
  payload: AxiosError;
}

// DRAFT_TANDEM_INSPECTION
export const DRAFT_TANDEM_INSPECTION =
  '[@@TandemInspection] DRAFT_TANDEM_INSPECTION';
export const DRAFT_TANDEM_INSPECTION_WITH_ID =
  '[@@TandemInspection] DRAFT_TANDEM_INSPECTION_WITH_ID';
export const DRAFT_TANDEM_INSPECTION_SUCCESS =
  '[@@TandemInspection] DRAFT_TANDEM_INSPECTION_SUCCESS';
export const DRAFT_TANDEM_INSPECTION_FAIL =
  '[@@TandemInspection] DRAFT_TANDEM_INSPECTION_FAIL';

export interface IDraftTandemInspectionAction {
  type: typeof DRAFT_TANDEM_INSPECTION;
  payload: {
    data: {
      formData: IVehicleDraftStart;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback?: (data?: any) => void;
  };
}
export interface IDraftTandemInspectionWithIdAction {
  type: typeof DRAFT_TANDEM_INSPECTION_WITH_ID;
  payload: {
    data: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      formData: any;
      tandemId?: number;
    };
    callback?: () => void;
  };
}
export interface IDraftTandemInspectionActionSuccess {
  type: typeof DRAFT_TANDEM_INSPECTION_SUCCESS;
  payload: IInspection;
}
export interface IDraftTandemInspectionActionFail {
  type: typeof DRAFT_TANDEM_INSPECTION_FAIL;
  payload: AxiosError;
}

export interface IDraftTandemInspectionWithIDActionSuccess {
  type: typeof DRAFT_TANDEM_INSPECTION_WITH_ID;
  payload: ITandemInspection;
}
export interface IDraftTandemInspectionWithIdActionFail {
  type: typeof DRAFT_TANDEM_INSPECTION_WITH_ID;
  payload: AxiosError;
}

// GET_TANDEM_INSPECTION_DRAFT
export const GET_TANDEM_INSPECTION_DRAFT =
  '[@@TandemInspection] GET_TANDEM_INSPECTION_DRAFT';
export const GET_TANDEM_INSPECTION_DRAFT_SUCCESS =
  '[@@TandemInspection] GET_TANDEM_INSPECTION_DRAFT_SUCCESS';
export const GET_TANDEM_INSPECTION_DRAFT_FAIL =
  '[@@TandemInspection] GET_TANDEM_INSPECTION_DRAFT_FAIL';

export interface IGetTandemInspectionDraftAction {
  type: typeof GET_TANDEM_INSPECTION_DRAFT;
  payload: {
    data: {
      inspectionId: number;
    };
    callback?: () => void;
  };
}
export interface IGetTandemInspectionDraftActionSuccess {
  type: typeof GET_TANDEM_INSPECTION_DRAFT_SUCCESS;
  payload: {
    inspection: IDraftInspection;
  };
}
export interface IGetTandemInspectionDraftActionFail {
  type: typeof GET_TANDEM_INSPECTION_DRAFT_FAIL;
  payload: AxiosError;
}

// CLEAR_CURRENT_TANDEM_INSPECTION
export const CLEAR_CURRENT_TANDEM_INSPECTION =
  '[@@TandemInspection]  CLEAR_CURRENT_TANDEM_INSPECTION';
export interface IClearCurrentTandemInspectionAction {
  type: typeof CLEAR_CURRENT_TANDEM_INSPECTION;
}

// UPLOAD_TANDEM_INSPECTION_PHOTO
export const UPLOAD_TANDEM_INSPECTION_PHOTO =
  '[@@TandemInspection] UPLOAD_TANDEM_INSPECTION_PHOTO';
export const UPLOAD_TANDEM_INSPECTION_PHOTO_SUCCESS =
  '[@@TandemInspection] UPLOAD_TANDEM_INSPECTION_PHOTO_SUCCESS';
export const UPLOAD_TANDEM_INSPECTION_PHOTO_FAIL =
  '[@@TandemInspection] UPLOAD_TANDEM_INSPECTION_PHOTO_FAIL';

export interface IUploadTandemInspectionPhotoAction {
  type: typeof UPLOAD_TANDEM_INSPECTION_PHOTO;
  payload: {
    data: {
      file: File;
      trailerId: number;
      truckId: number;
      view: InspectionFileView;
      fieldName: string;
      formType: TandemInspectionFormType;
      truckInspectionId: number;
      trailerInspectionId: number;
      uploadIndex: number;
    };
    callback?: () => void;
  };
}
export interface IUploadTandemInspectionPhotoActionSuccess {
  type: typeof UPLOAD_TANDEM_INSPECTION_PHOTO_SUCCESS;
  payload: {
    truckFile: IInspectionFile;
    trailerFile: IInspectionFile;
    uploadIndex: number;
  };
}
export interface IUploadTandemInspectionPhotoActionFail {
  type: typeof UPLOAD_TANDEM_INSPECTION_PHOTO_FAIL;
  payload: AxiosError;
}

// DELETE_TANDEM_INSPECTION_PHOTO
export const DELETE_TANDEM_INSPECTION_PHOTO =
  '[@@TandemInspection] DELETE_TANDEM_INSPECTION_PHOTO';
export const DELETE_TANDEM_INSPECTION_PHOTO_SUCCESS =
  '[@@TandemInspection] DELETE_TANDEM_INSPECTION_PHOTO_SUCCESS';
export const DELETE_TANDEM_INSPECTION_PHOTO_FAIL =
  '[@@TandemInspection] DELETE_TANDEM_INSPECTION_PHOTO_FAIL';

export interface IDeleteTandemInspectionPhotoAction {
  type: typeof DELETE_TANDEM_INSPECTION_PHOTO;
  payload: {
    data: {
      formType: TandemInspectionFormType;
      fieldName: TruckInspectionFormType | TrailerInspectionFormType;
      truckFileId: number;
      trailerFileId: number;
      trailerId: number;
      truckId: number;
    };
    callback?: () => void;
  };
}
export interface IDeleteTandemInspectionPhotoActionSuccess {
  type: typeof DELETE_TANDEM_INSPECTION_PHOTO_SUCCESS;
  payload: number;
}
export interface IDeleteTandemInspectionPhotoActionFail {
  type: typeof DELETE_TANDEM_INSPECTION_PHOTO_FAIL;
  payload: AxiosError;
}

// ADD_TANDEM_INSPECTION_PHOTO_COMMENT
export const ADD_TANDEM_INSPECTION_PHOTO_COMMENT =
  '[@@TandemInspection] ADD_TANDEM_INSPECTION_PHOTO_COMMENT';
export const ADD_TANDEM_INSPECTION_PHOTO_COMMENT_SUCCESS =
  '[@@TandemInspection] ADD_TANDEM_INSPECTION_PHOTO_COMMENT_SUCCESS';
export const ADD_TANDEM_INSPECTION_PHOTO_COMMENT_FAIL =
  '[@@TandemInspection] ADD_TANDEM_INSPECTION_PHOTO_COMMENT_FAIL';

export interface IAddTandemInspectionPhotoCommentAction {
  type: typeof ADD_TANDEM_INSPECTION_PHOTO_COMMENT;
  payload: {
    data: {
      fileId: number;
      commentData: InspectionPhotoCommentLocal;
    };
    callback?: () => void;
  };
}
export interface IAddTandemInspectionPhotoCommentActionSuccess {
  type: typeof ADD_TANDEM_INSPECTION_PHOTO_COMMENT_SUCCESS;
  payload: { fileId: number; comment: InspectionPhotoCommentServer };
}
export interface IAddTandemInspectionPhotoCommentActionFail {
  type: typeof ADD_TANDEM_INSPECTION_PHOTO_COMMENT_FAIL;
  payload: AxiosError;
}
// UPDATE_TANDEM_INSPECTION_PHOTO_COMMENT
export const UPDATE_TANDEM_INSPECTION_PHOTO_COMMENT =
  '[@@TandemInspection] UPDATE_TANDEM_INSPECTION_PHOTO_COMMENT';
export const UPDATE_TANDEM_INSPECTION_PHOTO_COMMENT_SUCCESS =
  '[@@TandemInspection] UPDATE_TANDEM_INSPECTION_PHOTO_COMMENT_SUCCESS';
export const UPDATE_TANDEM_INSPECTION_PHOTO_COMMENT_FAIL =
  '[@@TandemInspection] UPDATE_TANDEM_INSPECTION_PHOTO_COMMENT_FAIL';

export interface IUpdateTandemInspectionPhotoCommentAction {
  type: typeof UPDATE_TANDEM_INSPECTION_PHOTO_COMMENT;
  payload: {
    data: {
      fileId: number;
      commentData: InspectionPhotoCommentServer;
    };
    callback?: () => void;
  };
}
export interface IUpdateTandemInspectionPhotoCommentActionSuccess {
  type: typeof UPDATE_TANDEM_INSPECTION_PHOTO_COMMENT_SUCCESS;
  payload: { fileId: number; comment: InspectionPhotoCommentServer };
}
export interface IUpdateTandemInspectionPhotoCommentActionFail {
  type: typeof UPDATE_TANDEM_INSPECTION_PHOTO_COMMENT_FAIL;
  payload: AxiosError;
}
// DELETE_TANDEM_INSPECTION_PHOTO_COMMENT
export const DELETE_TANDEM_INSPECTION_PHOTO_COMMENT =
  '[@@TandemInspection] DELETE_TANDEM_INSPECTION_PHOTO_COMMENT';
export const DELETE_TANDEM_INSPECTION_PHOTO_COMMENT_SUCCESS =
  '[@@TandemInspection] DELETE_TANDEM_INSPECTION_PHOTO_COMMENT_SUCCESS';
export const DELETE_TANDEM_INSPECTION_PHOTO_COMMENT_FAIL =
  '[@@TandemInspection] DELETE_TANDEM_INSPECTION_PHOTO_COMMENT_FAIL';

export interface IDeleteTandemInspectionPhotoCommentAction {
  type: typeof DELETE_TANDEM_INSPECTION_PHOTO_COMMENT;
  payload: {
    data: {
      fileId: number;
      commentId: number;
    };
    callback?: () => void;
  };
}
export interface IDeleteTandemInspectionPhotoCommentActionSuccess {
  type: typeof DELETE_TANDEM_INSPECTION_PHOTO_COMMENT_SUCCESS;
  payload: { fileId: number; commentId: number };
}
export interface IDeleteTandemInspectionPhotoCommentActionFail {
  type: typeof DELETE_TANDEM_INSPECTION_PHOTO_COMMENT_FAIL;
  payload: AxiosError;
}

export const SET_TANDEM_INSPECTION_BACK_STEP =
  '[@@TandemInspection] SET_TANDEM_INSPECTION_BACK_STEP';
export interface ISetTandemInspectionBackStepAction {
  type: typeof SET_TANDEM_INSPECTION_BACK_STEP;
  payload: { backStep: number };
}

export const SET_TANDEM_DRAFT_STARTED =
  '[@@TandemInspection] SET_TANDEM_DRAFT_STARTED';
export interface ISetTandemDraftStartedAction {
  type: typeof SET_TANDEM_DRAFT_STARTED;
  payload: { isDraftStarted: boolean };
}

// OTHER
export const UNMOUNT_TANDEM_INSPECTION_FORM =
  '[@@TandemInspection] UNMOUNT_TANDEM_INSPECTION_FORM';
export interface IUnmountTandemInspectionFormAction {
  type: typeof UNMOUNT_TANDEM_INSPECTION_FORM;
}

export type TandemInspectionActions =
  | IUploadTandemInspectionPhotoAction
  | IUploadTandemInspectionPhotoActionSuccess
  | IUploadTandemInspectionPhotoActionFail
  | IDeleteTandemInspectionPhotoAction
  | IDeleteTandemInspectionPhotoActionSuccess
  | IDeleteTandemInspectionPhotoActionFail
  | IAddTandemInspectionPhotoCommentAction
  | IAddTandemInspectionPhotoCommentActionSuccess
  | IAddTandemInspectionPhotoCommentActionFail
  | IUpdateTandemInspectionPhotoCommentAction
  | IUpdateTandemInspectionPhotoCommentActionSuccess
  | IUpdateTandemInspectionPhotoCommentActionFail
  | IDeleteTandemInspectionPhotoCommentAction
  | IDeleteTandemInspectionPhotoCommentActionSuccess
  | IDeleteTandemInspectionPhotoCommentActionFail
  | ICreateTandemInspectionAction
  | ICreateTandemInspectionActionSuccess
  | ICreateTandemInspectionActionFail
  | IUnmountTandemInspectionFormAction
  | IClearCurrentTandemInspectionAction
  | IGetTandemInspectionDraftAction
  | IGetTandemInspectionDraftActionSuccess
  | IGetTandemInspectionDraftActionFail
  | IDraftTandemInspectionAction
  | IDraftTandemInspectionActionSuccess
  | IDraftTandemInspectionActionFail
  | IDraftTandemInspectionWithIdAction
  | IDraftTandemInspectionWithIDActionSuccess
  | IDraftTandemInspectionWithIdActionFail
  | ISetTandemInspectionBackStepAction
  | ISetTandemDraftStartedAction
  | IClearInspectionErrorAction
  | ILogout;
