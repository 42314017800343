import { Action } from 'redux';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ChannelMessage = any;

export type SocketMessage = {
  identifier: string;
  command?: 'subscribe' | 'unsubscribe';
  type?: string;
  message?: {
    type?: string;
    data: ChannelMessage;
  };
};

// commands
export const SUBSCRIBE_CHANNEL = '[@@Socket] SUBSCRIBE_CHANNEL';
export const UNSUBSCRIBE_CHANNEL = '[@@Socket] UNSUBSCRIBE_CHANNEL';
export const APPEND_CHANNEL_MESSAGES = '[@@Socket] APPEND_CHANNEL_MESSAGES';
export const UPDATE_CHANNEL_MESSAGES = '[@@Socket] UPDATE_CHANNEL_MESSAGES';

// responses
export const ON_CONNECTION_CHANGED = '[@@Socket] ON_CONNECTION_CHANGED';
export const ON_CHANNEL_SUBSCRIBED = '[@@Socket] ON_CHANNEL_SUBSCRIBED';
export const ON_INCOMING_MESSAGE = '[@@Socket] ON_INCOMING_MESSAGE';
export const ON_INCOMING_ERROR = '[@@Socket] ON_INCOMING_ERROR';

// actions intefaces
export interface ISubscribeChannelAction extends Action {
  type: typeof SUBSCRIBE_CHANNEL;
  channelIdentifier: string;
  messagesHistoryDepth: number;
}

export interface IUnsubscribeChannelAction extends Action {
  type: typeof UNSUBSCRIBE_CHANNEL;
  channelIdentifier: string;
}

export interface IAppendChannelMessagesAction extends Action {
  type: typeof APPEND_CHANNEL_MESSAGES;
  channelIdentifier: string;
  messages: ChannelMessage[];
  messagesType?: string;
}

export interface IUpdateChannelMessagesAction extends Action {
  type: typeof UPDATE_CHANNEL_MESSAGES;
  channelIdentifier: string;
  messages: ChannelMessage[];
  messagesType?: string;
}

export interface IOnConnectionChangedAction extends Action {
  type: typeof ON_CONNECTION_CHANGED;
  connected: boolean;
}

export interface IOnChannelSubscribedAction extends Action {
  type: typeof ON_CHANNEL_SUBSCRIBED;
  channelIdentifier: string;
}

export interface IOnIncomingMessageAction extends Action {
  type: typeof ON_INCOMING_MESSAGE;
  channelIdentifier: string;
  message: ChannelMessage;
  messageType?: string;
}

export interface IOnIncomingErrorAction extends Action {
  type: typeof ON_INCOMING_ERROR;
  event: Event;
}

export type socketActionsType =
  | ISubscribeChannelAction
  | IUnsubscribeChannelAction
  | IAppendChannelMessagesAction
  | IUpdateChannelMessagesAction
  | IOnConnectionChangedAction
  | IOnChannelSubscribedAction
  | IOnIncomingMessageAction
  | IOnIncomingErrorAction;
