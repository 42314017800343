import { call, put, takeLatest } from 'redux-saga/effects';

import { formatDate } from 'utils/helpers';
import api from 'utils/requests';

import { getAccidentsStatisticsAction } from './accidentsStatisticsActions';
import {
  GET_ACCIDENTS_STATISTICS_SUCCESS,
  GET_ACCIDENTS_STATISTICS,
  GET_ACCIDENTS_STATISTICS_FAIL,
  IGetAccidentsStatisticsActionSuccess,
  IGetAccidentsStatisticsActionFail,
} from './accidentsStatisticsTypes';

function* getAccidentsStatisticsSaga({
  payload: { timeRange, callback },
}: ReturnType<typeof getAccidentsStatisticsAction>) {
  try {
    const { data } = yield call(() =>
      api.get('/v1/fleets/accidents/statistics', {
        params: {
          ...(timeRange?.from && {
            from: formatDate(timeRange.from),
          }),
          ...(timeRange?.to && {
            to: formatDate(timeRange.to),
          }),
        },
      }),
    );
    yield put<IGetAccidentsStatisticsActionSuccess>({
      type: GET_ACCIDENTS_STATISTICS_SUCCESS,
      payload: data,
    });
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetAccidentsStatisticsActionFail>({
        type: GET_ACCIDENTS_STATISTICS_FAIL,
        payload: e,
      });
  }
}

export default function* accidentsSaga() {
  yield takeLatest(GET_ACCIDENTS_STATISTICS, getAccidentsStatisticsSaga);
}
