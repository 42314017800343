export const MIN_EMPLOYEE_AGE = 18;
export const MAX_EMPLOYEE_AGE = 100;
export const CURRENT_DATE = new Date();
export const CURRENT_DATE_PLUS_TWO_YEAR = new Date(
  new Date().setFullYear(new Date().getFullYear() + 2),
);
export const CURRENT_DATE_PLUS_TREE_YEAR = new Date(
  new Date().setFullYear(new Date().getFullYear() + 3),
);
export const CURRENT_YEAR = CURRENT_DATE.getFullYear();
export const CURRENT_MONTH = CURRENT_DATE.getMonth() + 1;
export const CURRENT_DAY = CURRENT_DATE.getDate();
export const START_DATE_TRUCK_HISTORY_FILTER = new Date(2020, 5, 5);

export const DEFAULT_MIN_DOB = `${
  CURRENT_YEAR - MAX_EMPLOYEE_AGE
}-${CURRENT_MONTH}-${CURRENT_DAY}`;
export const DEFAULT_MAX_DOB = `${
  CURRENT_YEAR - MIN_EMPLOYEE_AGE
}-${CURRENT_MONTH}-${CURRENT_DAY}`;

export const DEFAULT_MIN_HIRE_EMPLOYEE_DATE = `${
  CURRENT_YEAR - 50
}-${CURRENT_MONTH}-${CURRENT_DAY}`;
export const DEFAULT_MAX_HIRE_EMPLOYEE_DATE = `${CURRENT_YEAR}-${CURRENT_MONTH}-${CURRENT_DAY}`;

export const MAX_DRIVER_LICENSE_DATE = new Date(
  new Date().setFullYear(new Date().getFullYear() + 15),
);
