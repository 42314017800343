import { FC, MouseEvent } from 'react';

type Props = {
  className?: string;
  color?: string;
  onClick?: (event: MouseEvent) => void;
};

const IconSendEmail: FC<Props> = ({
  className,
  color = '#86898E',
  onClick,
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M21.5 14C21.5 14.5523 21.9477 15 22.5 15C23.0523 15 23.5 14.5523 23.5 14H21.5ZM10 20C10.5523 20 11 19.5523 11 19C11 18.4477 10.5523 18 10 18V20ZM4.5 2H19.5V0H4.5V2ZM2.5 16V4H0.5V16H2.5ZM21.5 4V14H23.5V4H21.5ZM10 18H4.5V20H10V18ZM0.5 16C0.5 18.2091 2.29086 20 4.5 20V18C3.39543 18 2.5 17.1046 2.5 16H0.5ZM19.5 2C20.6046 2 21.5 2.89543 21.5 4H23.5C23.5 1.79086 21.7091 0 19.5 0V2ZM4.5 0C2.29086 0 0.5 1.79086 0.5 4H2.5C2.5 2.89543 3.39543 2 4.5 2V0Z"
          fill={color}
        />
        <path
          d="M1.99614 4.63176C1.51662 4.35775 0.905767 4.52434 0.631757 5.00386C0.357747 5.48338 0.524344 6.09423 1.00386 6.36824L1.99614 4.63176ZM22.9961 6.36824C23.4757 6.09423 23.6423 5.48338 23.3682 5.00386C23.0942 4.52434 22.4834 4.35775 22.0039 4.63176L22.9961 6.36824ZM13.4884 10.6495L12.9923 9.78123L13.4884 10.6495ZM1.00386 6.36824L10.0154 11.5177L11.0077 9.78123L1.99614 4.63176L1.00386 6.36824ZM13.9846 11.5177L22.9961 6.36824L22.0039 4.63176L12.9923 9.78123L13.9846 11.5177ZM10.0154 11.5177C11.2452 12.2204 12.7548 12.2204 13.9846 11.5177L12.9923 9.78123C12.3774 10.1326 11.6226 10.1326 11.0077 9.78123L10.0154 11.5177Z"
          fill={color}
        />
        <path
          d="M19.3321 14.5429C18.9416 14.1524 18.3084 14.1524 17.9179 14.5429C17.5274 14.9334 17.5274 15.5666 17.9179 15.9571L19.3321 14.5429ZM22.375 19L23.0821 19.7071L23.7892 19L23.0821 18.2929L22.375 19ZM14.625 18C14.0727 18 13.625 18.4477 13.625 19C13.625 19.5523 14.0727 20 14.625 20L14.625 18ZM17.9179 22.0429C17.5274 22.4334 17.5274 23.0666 17.9179 23.4571C18.3084 23.8476 18.9416 23.8476 19.3321 23.4571L17.9179 22.0429ZM17.9179 15.9571L21.6679 19.7071L23.0821 18.2929L19.3321 14.5429L17.9179 15.9571ZM22.375 18L14.625 18L14.625 20L22.375 20L22.375 18ZM21.6679 18.2929L17.9179 22.0429L19.3321 23.4571L23.0821 19.7071L21.6679 18.2929Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconSendEmail;
