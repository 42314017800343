import { lazy } from 'react';

import { PERMISSION_ACCIDENT } from '../../constants/permissions';
import { IPermissionObject } from '../index';
import PrivateRoutes, { redirectRoute } from '../PrivateRoutes/PrivateRoutes';

const CreateAccident = lazy(
  () =>
    import(
      'components/Safety/TabAccidents/AccidentsMainForm/CreateAccident/CreateAccident'
    ),
);
const UpdateAccident = lazy(
  () =>
    import(
      'components/Safety/TabAccidents/AccidentsMainForm/UpdateAccident/UpdateAccident'
    ),
);
const AccidentProfile = lazy(
  () =>
    import('components/Safety/TabAccidents/AccidentProfile/AccidentProfile'),
);
const Accidents = lazy(
  () => import('components/Safety/TabAccidents/Accidents'),
);

const getRedirectURL = (permissions?: string[]): string => {
  if (permissions?.includes(PERMISSION_ACCIDENT)) return '/safety/accidents';
  return '/';
};

/* Safety Main Page (redirect into) */
export const mainSafetyRoute = (permissions?: IPermissionObject[]) => {
  const targetName = 'Safety';
  const targetObject = permissions?.find(obj => obj.name === targetName);
  return redirectRoute(
    '/safety',
    getRedirectURL(targetObject ? targetObject.permissions : []),
  );
};

/* Accidents */
export const accidentsRoute = (
  <PrivateRoutes
    exact
    path="/safety/accidents"
    component={Accidents}
    permissionType={PERMISSION_ACCIDENT}
  />
);
export const accidentCreateRoute = (
  <PrivateRoutes
    exact
    path="/safety/accident/create"
    component={CreateAccident}
    permissionType={PERMISSION_ACCIDENT}
  />
);
export const accidentUpdateRoute = (
  <PrivateRoutes
    exact
    path="/safety/accidents/:id/edit"
    component={UpdateAccident}
    permissionType={PERMISSION_ACCIDENT}
  />
);
export const accidentProfileRoute = (
  <PrivateRoutes
    exact
    path="/safety/accidents/:id"
    component={AccidentProfile}
    permissionType={PERMISSION_ACCIDENT}
  />
);
