import { FC, useMemo, useCallback, KeyboardEvent, FocusEvent } from 'react';

import clsx from 'clsx';
import NumberFormat from 'react-number-format';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import { RangeSlider } from 'components/_common/CustomMaterialComponents';

import styles from './Range.module.scss';

type OwnProps = {
  id: string;
  min?: number;
  max?: number;
  step?: number;
  className?: string;
};

type Props = Partial<OwnProps & WrappedFieldInputProps & WrappedFieldMetaProps>;

const Range: FC<Props> = ({
  id,
  onChange,
  value,
  min = 0,
  max = 100,
  step,
  className,
}) => {
  const handleChange = useCallback(
    (event: Event, newValue: number | number[]) => {
      event.preventDefault();
      if (onChange) onChange(newValue);
    },
    [onChange],
  );

  const range: number[] = useMemo(() => {
    if (!value) return [min, max];
    if (typeof value === 'string') return value.split(',').map(el => +el);
    if (Array.isArray(value) && value.length > 1)
      return [
        typeof value[0] === 'number' ? value[0] : min,
        typeof value[1] === 'number' ? value[1] : max,
      ];
    return value;
  }, [value, min, max]);

  const isFloating = useMemo(
    () => !!(min % 1) || !!(max % 1) || max - min < 10,
    [min, max],
  );

  const getRange = (newValue: number, type: string | null): number[] =>
    type === 'min'
      ? [Math.min(range[1], Math.max(newValue, min)), range[1]]
      : [range[0], Math.max(range[0], Math.min(newValue, max))];

  const onKeyUpHanlder = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (onChange) {
        if (event.key === 'Enter')
          onChange(
            getRange(
              +event.currentTarget.value,
              event.currentTarget.getAttribute('attr-type'),
            ),
          );
        if (event.key === 'Escape') onChange(range);
      }
    },
    [range, onChange],
  );

  const onBlurHandler = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (onChange)
        onChange(
          getRange(
            +event.target.value,
            event.currentTarget.getAttribute('attr-type'),
          ),
        );
    },
    [range, onChange],
  );

  return (
    <div className={clsx(styles.root, className)}>
      <RangeSlider
        id={id}
        name={id}
        step={step}
        min={min}
        max={max}
        value={range}
        onChange={handleChange}
        valueLabelDisplay="off"
        aria-labelledby="range-slider"
      />
      <NumberFormat
        key={`${id}_min_${range[0]}`}
        className={styles.markLeft}
        autoComplete="off"
        attr-type="min"
        defaultValue={range[0]}
        onKeyUp={onKeyUpHanlder}
        onBlur={onBlurHandler}
        allowNegative={min < 0}
        decimalScale={isFloating ? undefined : 0}
      />
      <NumberFormat
        key={`${id}_max_${range[1]}`}
        attr-type="max"
        className={styles.markRight}
        autoComplete="off"
        defaultValue={range[1]}
        onKeyUp={onKeyUpHanlder}
        onBlur={onBlurHandler}
        allowNegative={min < 0}
        decimalScale={isFloating ? undefined : 0}
      />
    </div>
  );
};

export default Range;
