import { AxiosError } from 'axios';

import { IPagination } from 'components/_common/Navigation/Pagination/_redux/paginationTypes';
import { ILogout } from 'redux/_common/models';
import { IDefaultReducer, SwitcherKeys } from 'utils/models';

import { IToll, ITollAlerts } from '../_models/tollModel';
import {
  TollsStatistics,
  TollsTrucksStatistics,
  TollsRoadsStatistics,
} from '../_models/tollsStatistics';

// REDUCER
export interface ITollsState extends IDefaultReducer {
  list: IToll[];
  currentToll: IToll | null;
  currentTollAlerts: ITollAlerts[];
  dateRange: string;
  statistics: TollsStatistics | null;
  statisticsRoads: TollsRoadsStatistics[]; // TODO: Define later
  statisticsTrucks: TollsTrucksStatistics[]; // TODO: Define later
  pagination: IPagination | null;
}

// CREATE_TOLL
export const CREATE_TOLL = '[@@Tolls] CREATE_TOLL';
export const CREATE_TOLL_SUCCESS = '[@@Tolls] CREATE_TOLL_SUCCESS';
export const CREATE_TOLL_FAIL = '[@@Tolls] CREATE_TOLL_FAIL';

export interface ICreateTollAction {
  type: typeof CREATE_TOLL;
  payload: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: { values: any };
    callback?: (id: number) => void;
  };
}

export interface ICreateTollActionSuccess {
  type: typeof CREATE_TOLL_SUCCESS;
  payload: {
    geofence: IToll;
  };
}

export interface ICreateTollActionFail {
  type: typeof CREATE_TOLL_FAIL;
  payload: AxiosError;
}

// GET_TOLLS
export const GET_TOLLS = '[@@Tolls] GET_TOLLS';
export const GET_TOLLS_SUCCESS = '[@@Tolls] GET_TOLLS_SUCCESS';
export const GET_TOLLS_FAIL = '[@@Tolls] GET_TOLLS_FAIL';

export interface IGetTollsAction {
  type: typeof GET_TOLLS;
  payload: {
    data: {
      query?: string;
      usePaginationFlag?: boolean;
    };
    callback?: () => void;
  };
}

export interface IGetTollsActionSuccess {
  type: typeof GET_TOLLS_SUCCESS;
  payload: {
    geofences: IToll[];
    pagination: IPagination;
  };
}

export interface IGetTollsActionFail {
  type: typeof GET_TOLLS_FAIL;
  payload: AxiosError;
}

// SEARCH TOLLS
export const SEARCH_TOLLS = '[@@Tolls] SEARCH_TOLLS';

export interface ISearchTolls {
  type: typeof SEARCH_TOLLS;
  payload: { viewKey: SwitcherKeys };
}

// GET_TOLL
export const GET_TOLL = '[@@Tolls] GET_TOLL';
export const GET_TOLL_SUCCESS = '[@@Tolls] GET_TOLL_SUCCESS';
export const GET_TOLL_FAIL = '[@@Tolls] GET_TOLL_FAIL';

export interface IGetTollAction {
  type: typeof GET_TOLL;
  payload: {
    data: { id: string | number };
    callback?: () => void;
  };
}

export interface IGetTollActionSuccess {
  type: typeof GET_TOLL_SUCCESS;
  payload: {
    geofence: IToll;
  };
}

export interface IGetTollActionFail {
  type: typeof GET_TOLL_FAIL;
  payload: AxiosError;
}

// UPDATE_TOLL
export const UPDATE_TOLL = '[@@Tolls] UPDATE_TOLL';
export const UPDATE_TOLL_SUCCESS = '[@@Tolls] UPDATE_TOLL_SUCCESS';
export const UPDATE_TOLL_FAIL = '[@@Tolls] UPDATE_TOLL_FAIL';

export interface IUpdateTollAction {
  type: typeof UPDATE_TOLL;
  payload: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: { id: number; values: any };
    callback?: () => void;
  };
}

export interface IUpdateTollActionSuccess {
  type: typeof UPDATE_TOLL_SUCCESS;
  payload: {
    geofence: IToll;
  };
}

export interface IUpdateTollActionFail {
  type: typeof UPDATE_TOLL_FAIL;
  payload: AxiosError;
}

// DELETE_TOLL
export const DELETE_TOLL = '[@@Tolls] DELETE_TOLL';
export const DELETE_TOLL_SUCCESS = '[@@Tolls] DELETE_TOLL_SUCCESS';
export const DELETE_TOLL_FAIL = '[@@Tolls] DELETE_TOLL_FAIL';

export interface IDeleteTollAction {
  type: typeof DELETE_TOLL;
  payload: {
    data: { id: number | string };
    callback?: () => void;
  };
}

export interface IDeleteTollActionSuccess {
  type: typeof DELETE_TOLL_SUCCESS;
  payload: {
    id: number;
  };
}

export interface IDeleteTollActionFail {
  type: typeof DELETE_TOLL_FAIL;
  payload: AxiosError;
}

// PIN_TOLL
export const PIN_TOLL = '[@@Tolls] PIN_TOLL';
export const PIN_TOLL_SUCCESS = '[@@Tolls] PIN_TOLL_SUCCESS';
export const PIN_TOLL_FAIL = '[@@Tolls] PIN_TOLL_FAIL';

export interface ISetTollPinAction {
  type: typeof PIN_TOLL;
  payload: {
    data: { id: number };
    callback?: () => void;
  };
}

export interface ISetTollPinActionSuccess {
  type: typeof PIN_TOLL_SUCCESS;
  payload: {
    id: number;
  };
}

export interface ISetTollPinActionFail {
  type: typeof PIN_TOLL_FAIL;
  payload: AxiosError;
}

// UNPIN_TOLL
export const UNPIN_TOLL = '[@@Tolls] UNPIN_TOLL';
export const UNPIN_TOLL_SUCCESS = '[@@Tolls] UNPIN_TOLL_SUCCESS';
export const UNPIN_TOLL_FAIL = '[@@Tolls] UNPIN_TOLL_FAIL';

export interface ISetTollUnPinAction {
  type: typeof UNPIN_TOLL;
  payload: {
    data: { id: number };
    callback?: () => void;
  };
}

export interface ISetTollUnPinActionSuccess {
  type: typeof UNPIN_TOLL_SUCCESS;
  payload: {
    id: number;
  };
}

export interface ISetTollUnPinActionFail {
  type: typeof UNPIN_TOLL_FAIL;
  payload: AxiosError;
}

// CLEAR_STATE
export const CLEAR_STATE = '[@@Tolls] CLEAR_STATE';

export interface IClearStateFail {
  type: typeof CLEAR_STATE;
  payload: Partial<ITollsState>;
}

// GET_TOLL_ALERTS
export const GET_TOLL_ALERTS = '[@@Tolls] GET_TOLL_ALERTS';
export const GET_TOLL_ALERTS_SUCCESS = '[@@Tolls] GET_TOLL_ALERTS_SUCCESS';
export const GET_TOLL_ALERTS_FAIL = '[@@Tolls] GET_TOLL_ALERTS_FAIL';

export interface IGetTollAlertsAction {
  type: typeof GET_TOLL_ALERTS;
  payload: {
    data: { id: number };
    callback?: () => void;
  };
}

export interface IGetTollAlertsActionSuccess {
  type: typeof GET_TOLL_ALERTS_SUCCESS;
  payload: {
    geofences_alerts: ITollAlerts[];
    pagination: IPagination;
  };
}

export interface IGetTollAlertsActionFail {
  type: typeof GET_TOLL_ALERTS_FAIL;
  payload: AxiosError;
}

// GET_TOLLS_STATISTICS
export const GET_TOLLS_STATISTICS = '[@@Tolls] GET_TOLLS_STATISTICS';
export const GET_TOLLS_STATISTICS_SUCCESS =
  '[@@Tolls] GET_TOLLS_STATISTICS_SUCCESS';
export const GET_TOLLS_STATISTICS_FAIL = '[@@Tolls] GET_TOLLS_STATISTICS_FAIL';

export interface IGetTollsStatisticsAction {
  type: typeof GET_TOLLS_STATISTICS;
  payload: {
    data: { dateRange: string };
    callback?: () => void;
  };
}

export interface IGetTollsStatisticsActionSuccess {
  type: typeof GET_TOLLS_STATISTICS_SUCCESS;
  payload: TollsStatistics;
}

export interface IGetTollsStatisticsActionFail {
  type: typeof GET_TOLLS_STATISTICS_FAIL;
  payload: AxiosError;
}

// GET_TOLLS_ROADS_STATISTICS
export const GET_TOLLS_ROADS_STATISTICS =
  '[@@Tolls] GET_TOLLS_ROADS_STATISTICS';
export const GET_TOLLS_ROADS_STATISTICS_SUCCESS =
  '[@@Tolls] GET_TOLLS_ROADS_STATISTICS_SUCCESS';
export const GET_TOLLS_ROADS_STATISTICS_FAIL =
  '[@@Tolls] GET_TOLLS_ROADS_STATISTICS_FAIL';

export interface IGetTollsRoadsStatisticsAction {
  type: typeof GET_TOLLS_ROADS_STATISTICS;
  payload: {
    data: {
      dateRange: string;
      page: number;
      perPage: number;
    };
    callback?: () => void;
  };
}

export interface IGetTollsRoadsStatisticsActionSuccess {
  type: typeof GET_TOLLS_ROADS_STATISTICS_SUCCESS;
  payload: TollsRoadsStatistics[];
}

export interface IGetTollsRoadsStatisticsActionFail {
  type: typeof GET_TOLLS_ROADS_STATISTICS_FAIL;
  payload: AxiosError;
}

// GET_TOLLS_TRUCKS_STATISTICS
export const GET_TOLLS_TRUCKS_STATISTICS =
  '[@@Tolls] GET_TOLLS_TRUCKS_STATISTICS';
export const GET_TOLLS_TRUCKS_STATISTICS_SUCCESS =
  '[@@Tolls] GET_TOLLS_TRUCKS_STATISTICS_SUCCESS';
export const GET_TOLLS_TRUCKS_STATISTICS_FAIL =
  '[@@Tolls] GET_TOLLS_TRUCKS_STATISTICS_FAIL';

export interface IGetTollsTrucksStatisticsAction {
  type: typeof GET_TOLLS_TRUCKS_STATISTICS;
  payload: {
    data: {
      dateRange: string;
      page: number;
      perPage: number;
    };
    callback?: () => void;
  };
}

export interface IGetTollsTrucksStatisticsActionSuccess {
  type: typeof GET_TOLLS_TRUCKS_STATISTICS_SUCCESS;
  payload: TollsTrucksStatistics[];
}

export interface IGetTollsTrucksStatisticsActionFail {
  type: typeof GET_TOLLS_TRUCKS_STATISTICS_FAIL;
  payload: AxiosError;
}

// SET_DATE_RANGE
export const SET_DATE_RANGE = '[@@Tolls] SET_DATE_RANGE';

export interface ISetDateRangeAction {
  type: typeof SET_DATE_RANGE;
  payload: string;
}

export type tollsTypes =
  | ISetDateRangeAction
  | ILogout
  | IClearStateFail
  | ISearchTolls
  | IGetTollsTrucksStatisticsAction
  | IGetTollsTrucksStatisticsActionFail
  | IGetTollsTrucksStatisticsActionSuccess
  | IGetTollsRoadsStatisticsAction
  | IGetTollsRoadsStatisticsActionFail
  | IGetTollsRoadsStatisticsActionSuccess
  | IGetTollsStatisticsAction
  | IGetTollsStatisticsActionFail
  | IGetTollsStatisticsActionSuccess
  | ISetTollUnPinAction
  | ISetTollUnPinActionFail
  | ISetTollUnPinActionSuccess
  | ISetTollPinAction
  | ISetTollPinActionFail
  | ISetTollPinActionSuccess
  | IDeleteTollAction
  | IDeleteTollActionFail
  | IDeleteTollActionSuccess
  | IUpdateTollAction
  | IUpdateTollActionFail
  | IUpdateTollActionSuccess
  | IGetTollAction
  | IGetTollActionFail
  | IGetTollActionSuccess
  | ICreateTollAction
  | ICreateTollActionFail
  | ICreateTollActionSuccess
  | IGetTollsAction
  | IGetTollsActionFail
  | IGetTollsActionSuccess
  | IGetTollAlertsAction
  | IGetTollAlertsActionSuccess
  | IGetTollAlertsActionFail;
