import { FC, memo, useMemo } from 'react';

import clsx from 'clsx';
import { Link, useHistory } from 'react-router-dom';

import { IconArrowLeft } from 'assets/icons/components';
import { getShortPath } from 'hooks/useTestAttrRef';

import styles from './GoBackButton.module.scss';

type Props = {
  link?: string;
  text?: string;
  className?: string;
  action?: () => void;
};

const GoBackButton: FC<Props> = props => {
  const { link, text, className = '', action } = props;

  const dataTest = useMemo(
    () => `${getShortPath(window.location.pathname)}_go_back_button`,
    [window.location.pathname],
  );

  const history = useHistory();

  const resultText = useMemo(() => text || 'Back', [text]);
  const resultStyles = useMemo(
    () => clsx(styles.goBackLink, className),
    [styles, className],
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const Content = memo(() => (
    <>
      <IconArrowLeft />
      {resultText}
    </>
  ));

  if (link) {
    return (
      <Link to={link} className={resultStyles} data-test={dataTest}>
        <Content />
      </Link>
    );
  }

  return (
    <div
      className={resultStyles}
      onClick={() => (action ? action() : history.goBack())}
      tabIndex={0}
      role="button"
      data-test={dataTest}
    >
      <Content />
    </div>
  );
};

export default memo(GoBackButton);
