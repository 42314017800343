type Props = {
  className?: string;
  fillColor?: string;
  transformToSmall?: boolean;
  onClick?: () => void;
};

const IconChat = ({
  className,
  fillColor = 'var(--primaryText)',
  transformToSmall,
  onClick,
}: Props) => (
  <svg
    width={transformToSmall ? '16' : '24'}
    height={transformToSmall ? '16' : '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <rect width="24" height="24" />
    <path
      d="M3 12C3 16.9706 7.02944 21 12 21C13.4876 21 14.891 20.6391 16.1272 20L21 21L20 16.1272C20.6391 14.891 21 13.4876 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12Z"
      stroke={fillColor}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M9 10H15"
      stroke={fillColor}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M9 14H12"
      stroke={fillColor}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default IconChat;
