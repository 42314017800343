import {
  IconVehicle,
  IconGeofence,
  IconInspection,
  IconToll,
  IconWarningSquare,
  IconRepair,
  IconInventory,
  IconClock,
  IconDashboard,
  IconFacility,
  IconArchive,
  IconWheel,
  LittleSuitcase,
  LittleDefaultUserPhoto,
  IconCalendar,
  IconChat,
  IconSettings,
  IconSettingsWise,
  IconClaim,
  IconTasks,
  IconCompany,
  IconRoles,
} from 'assets/icons/components';
import IconStatistics from 'assets/icons/components/IconStatistics';
import {
  PERMISSION_TRUCK,
  PERMISSION_EDIT_TRUCK,
  PERMISSION_DELETE_TRUCK,
  PERMISSION_TRAILER,
  PERMISSION_EDIT_TRAILER,
  PERMISSION_DELETE_TRAILER,
  PERMISSION_VEHICLE,
  PERMISSION_GEOFENCE,
  PERMISSION_TOLLS,
  PERMISSION_ACCIDENT,
  PERMISSION_BROKERS,
  PERMISSION_FACILITIES,
  PERMISSION_ORDER,
  PERMISSION_INSPECTION,
  PERMISSION_CLAIMS,
  PERMISSION_CHAT,
  PERMISSION_GPS_TRACKING,
  PERMISSION_EMPLOYEE_LIST,
  PERMISSION_DRIVER_LIST,
  PERMISSION_LEAD_LIST,
  PERMISSION_UPCOMING_DRIVERS_LIST,
  PERMISSION_ARCHIVE_DRIVER_LIST,
  PERMISSION_PAYROLL_WEEKLY,
  PERMISSION_DRIVER_PAYROLL,
  PERMISSION_DEDUCTION,
  PERMISSION_FUEL,
  PERMISSION_CREATE_TRUCK,
  PERMISSION_CREATE_TRAILER,
  PERMISSION_DASHBOARD,
  PERMISSION_CREATE_VEHICLE,
  PERMISSION_EDIT_VEHICLE,
  PERMISSION_CREATE_TOLL,
  PERMISSION_EDIT_TOLL,
  PERMISSION_DELETE_TOLL,
  PERMISSION_CREATE_INSPECTION,
  PERMISSION_DELETE_CLAIM,
  PERMISSION_ITEMS,
  PERMISSION_CREATE_ITEM,
  PERMISSION_EDIT_ITEM,
  PERMISSION_DELETE_ITEM,
  PERMISSION_CATEGORY,
  PERMISSION_CREATE_CATEGORY,
  PERMISSION_EDIT_CATEGORY,
  PERMISSION_DELETE_CATEGORY,
  PERMISSION_LOCATION,
  PERMISSION_CREATE_LOCATION,
  PERMISSION_EDIT_LOCATION,
  PERMISSION_DELETE_LOCATION,
  PERMISSION_VENDOR,
  PERMISSION_CREATE_VENDOR,
  PERMISSION_EDIT_VENDOR,
  PERMISSION_DELETE_VENDOR,
  PERMISSION_CREATE_FACILITY,
  PERMISSION_EDIT_FACILITY,
  PERMISSION_DELETE_FACILITY,
  PERMISSION_CREATE_GEOFENCE,
  PERMISSION_EDIT_GEOFENCE,
  PERMISSION_DELETE_GEOFENCE,
  PERMISSION_EDIT_INSPECTION,
  PERMISSION_DELETE_INSPECTION,
  PERMISSION_CREATE_CLAIM,
  PERMISSION_EDIT_CLAIM,
  PERMISSION_CREATE_ORDER,
  PERMISSION_EDIT_ORDER,
  PERMISSION_DELETE_ORDER,
  PERMISSION_CREATE_BROKER,
  PERMISSION_EDIT_BROKER,
  PERMISSION_DELETE_BROKER,
  PERMISSION_CALENDAR,
  PERMISSION_OVERVIEW,
  PERMISSION_EMPLOYEE_DELETE,
  PERMISSION_DRIVER_DELETE,
  PERMISSION_DRIVER_ARCHIVE,
  PERMISSION_LEAD_REJECT,
  PERMISSION_ARCHIVE_DRIVER_UPDATE,
  PERMISSION_HIRE_DRIVER,
  PERMISSION_CREATE_PAYROLL_WEEKLY,
  PERMISSION_EDIT_PAYROLL_WEEKLY,
  PERMISSION_DELETE_PAYROLL_WEEKLY,
  PERMISSION_CREATE_DRIVER_PAYROLL,
  PERMISSION_EDIT_DRIVER_PAYROLL,
  PERMISSION_DELETE_DRIVER_PAYROLL,
  PERMISSION_CREATE_DEDUCTION,
  PERMISSION_EDIT_DEDUCTION,
  PERMISSION_DELETE_DEDUCTION,
  PERMISSION_CREDIT,
  PERMISSION_CREATE_CREDIT,
  PERMISSION_EDIT_CREDIT,
  PERMISSION_DELETE_CREDIT,
  PERMISSION_BILLING,
  PERMISSION_REPORTS,
  PERMISSION_CREATE_ACCIDENT,
  PERMISSION_EDIT_ACCIDENT,
  PERMISSION_DELETE_ACCIDENT,
  PERMISSION_FACTORY_COMPANIES,
  PERMISSION_PAYROLL,
  PERMISSION_CREATE_PAYROLL,
  PERMISSION_EDIT_PAYROLL,
  PERMISSION_DELETE_PAYROLL,
  PERMISSION_CREATE_GPS_TRACKING,
  PERMISSION_EDIT_GPS_TRACKING,
  PERMISSION_DELETE_GPS_TRACKING,
  PERMISSION_ORIENTATION_PROCESS,
  PERMISSION_CREATE_ORIENTATION_PROCESS,
  PERMISSION_EDIT_ORIENTATION_PROCESS,
  PERMISSION_DELETE_ORIENTATION_PROCESS,
  PERMISSION_DOCUMENT_MAPPING,
  PERMISSION_CREATE_DOCUMENT_MAPPING,
  PERMISSION_EDIT_DOCUMENT_MAPPING,
  PERMISSION_DELETE_DOCUMENT_MAPPING,
  PERMISSION_CONTRACT_TEMPLATES,
  PERMISSION_CREATE_CONTRACT_TEMPLATE,
  PERMISSION_EDIT_CONTRACT_TEMPLATE,
  PERMISSION_DELETE_CONTRACT_TEMPLATE,
  PERMISSION_SCRIPT,
  PERMISSION_CREATE_SCRIPT,
  PERMISSION_EDIT_SCRIPT,
  PERMISSION_DELETE_SCRIPT,
  PERMISSION_TASK_LIST,
  PERMISSION_TASK_CREATE,
  PERMISSION_TASK_UPDATE,
  PERMISSION_TASK_DELETE,
  PERMISSION_ROLES_LIST,
  PERMISSION_ROLES_CREATE,
  PERMISSION_ROLES_UPDATE,
  PERMISSION_ROLES_DELETE,
  PERMISSION_COMPANY_LIST,
  PERMISSION_COMPANY_CREATE,
  PERMISSION_COMPANY_UPDATE,
  PERMISSION_COMPANY_DELETE,
  PERMISSION_WORK_ORDER_LIST,
  PERMISSION_GARAGES_LIST,
  PERMISSION_TECHNICIANS_LIST,
} from 'constants/permissions';

import { MainSidebarAccordion, SideBarDropMenuItem } from './_models';

// Set of routes that are changed in the header of the main sidebar
export const SIDEBAR_DROP_MENU_CONFIG: SideBarDropMenuItem[] = [
  {
    id: 'fleet',
    title: 'Fleet Management',
    path: '/fleet',
    permissions: [
      PERMISSION_TRUCK,
      PERMISSION_CREATE_TRUCK,
      PERMISSION_EDIT_TRUCK,
      PERMISSION_DELETE_TRUCK,
      PERMISSION_TRAILER,
      PERMISSION_CREATE_TRAILER,
      PERMISSION_EDIT_TRAILER,
      PERMISSION_DELETE_TRAILER,
      PERMISSION_DASHBOARD,
      PERMISSION_VEHICLE,
      PERMISSION_CREATE_VEHICLE,
      PERMISSION_EDIT_VEHICLE,
      PERMISSION_GEOFENCE,
      PERMISSION_CREATE_GEOFENCE,
      PERMISSION_EDIT_GEOFENCE,
      PERMISSION_DELETE_GEOFENCE,
      PERMISSION_TOLLS,
      PERMISSION_CREATE_TOLL,
      PERMISSION_EDIT_TOLL,
      PERMISSION_DELETE_TOLL,
      PERMISSION_INSPECTION,
      PERMISSION_CREATE_INSPECTION,
      PERMISSION_EDIT_INSPECTION,
      PERMISSION_DELETE_INSPECTION,
      PERMISSION_CLAIMS,
      PERMISSION_CREATE_CLAIM,
      PERMISSION_EDIT_CLAIM,
      PERMISSION_DELETE_CLAIM,
      PERMISSION_ITEMS,
      PERMISSION_CREATE_ITEM,
      PERMISSION_EDIT_ITEM,
      PERMISSION_DELETE_ITEM,
      PERMISSION_CATEGORY,
      PERMISSION_CREATE_CATEGORY,
      PERMISSION_EDIT_CATEGORY,
      PERMISSION_DELETE_CATEGORY,
      PERMISSION_LOCATION,
      PERMISSION_CREATE_LOCATION,
      PERMISSION_EDIT_LOCATION,
      PERMISSION_DELETE_LOCATION,
      PERMISSION_VENDOR,
      PERMISSION_CREATE_VENDOR,
      PERMISSION_EDIT_VENDOR,
      PERMISSION_DELETE_VENDOR,
    ],
  },
  {
    id: 'dispatch',
    title: 'Dispatch',
    path: '/dispatch',
    permissions: [
      PERMISSION_ORDER,
      PERMISSION_BROKERS,
      PERMISSION_FACILITIES,
      PERMISSION_CREATE_FACILITY,
      PERMISSION_EDIT_FACILITY,
      PERMISSION_DELETE_FACILITY,
      PERMISSION_CREATE_ORDER,
      PERMISSION_EDIT_ORDER,
      PERMISSION_DELETE_ORDER,
      PERMISSION_CREATE_BROKER,
      PERMISSION_EDIT_BROKER,
      PERMISSION_DELETE_BROKER,
      PERMISSION_CALENDAR,
      PERMISSION_OVERVIEW,
    ],
  },
  {
    id: 'hr',
    title: 'HR',
    path: '/hr',
    permissions: [
      PERMISSION_EMPLOYEE_LIST,
      PERMISSION_DRIVER_LIST,
      PERMISSION_LEAD_LIST,
      PERMISSION_UPCOMING_DRIVERS_LIST,
      PERMISSION_ARCHIVE_DRIVER_LIST,
      PERMISSION_EMPLOYEE_DELETE,
      PERMISSION_DRIVER_DELETE,
      PERMISSION_DRIVER_ARCHIVE,
      PERMISSION_LEAD_REJECT,
      PERMISSION_ARCHIVE_DRIVER_UPDATE,
      PERMISSION_HIRE_DRIVER,
    ],
  },
  {
    id: 'accounting',
    title: 'Accounting',
    path: '/accounting',
    permissions: [
      PERMISSION_PAYROLL_WEEKLY,
      PERMISSION_DRIVER_PAYROLL,
      PERMISSION_DEDUCTION,
      PERMISSION_FUEL,
      PERMISSION_CREATE_PAYROLL_WEEKLY,
      PERMISSION_EDIT_PAYROLL_WEEKLY,
      PERMISSION_DELETE_PAYROLL_WEEKLY,
      PERMISSION_CREATE_DRIVER_PAYROLL,
      PERMISSION_EDIT_DRIVER_PAYROLL,
      PERMISSION_DELETE_DRIVER_PAYROLL,
      PERMISSION_CREATE_DEDUCTION,
      PERMISSION_EDIT_DEDUCTION,
      PERMISSION_DELETE_DEDUCTION,
      PERMISSION_CREDIT,
      PERMISSION_CREATE_CREDIT,
      PERMISSION_EDIT_CREDIT,
      PERMISSION_DELETE_CREDIT,
      PERMISSION_BILLING,
      PERMISSION_REPORTS,
    ],
  },
  {
    id: 'safety',
    title: 'Safety',
    path: '/safety',
    permissions: [
      PERMISSION_ACCIDENT,
      PERMISSION_CREATE_ACCIDENT,
      PERMISSION_EDIT_ACCIDENT,
      PERMISSION_DELETE_ACCIDENT,
    ],
  },
  {
    id: 'wise',
    title: 'W1SE Check',
    path: '/wise',
    permissions: [
      PERMISSION_TRUCK,
      PERMISSION_TRAILER,
      PERMISSION_GEOFENCE,
      PERMISSION_TOLLS,
    ],
  },
  {
    id: 'settings',
    title: 'Settings',
    path: '/settings',
    permissions: [
      PERMISSION_FACTORY_COMPANIES,
      PERMISSION_PAYROLL,
      PERMISSION_CREATE_PAYROLL,
      PERMISSION_EDIT_PAYROLL,
      PERMISSION_DELETE_PAYROLL,
      PERMISSION_GPS_TRACKING,
      PERMISSION_CREATE_GPS_TRACKING,
      PERMISSION_EDIT_GPS_TRACKING,
      PERMISSION_DELETE_GPS_TRACKING,
      PERMISSION_ORIENTATION_PROCESS,
      PERMISSION_CREATE_ORIENTATION_PROCESS,
      PERMISSION_EDIT_ORIENTATION_PROCESS,
      PERMISSION_DELETE_ORIENTATION_PROCESS,
      PERMISSION_DOCUMENT_MAPPING,
      PERMISSION_CREATE_DOCUMENT_MAPPING,
      PERMISSION_EDIT_DOCUMENT_MAPPING,
      PERMISSION_DELETE_DOCUMENT_MAPPING,
      PERMISSION_CONTRACT_TEMPLATES,
      PERMISSION_CREATE_CONTRACT_TEMPLATE,
      PERMISSION_EDIT_CONTRACT_TEMPLATE,
      PERMISSION_DELETE_CONTRACT_TEMPLATE,
      PERMISSION_SCRIPT,
      PERMISSION_CREATE_SCRIPT,
      PERMISSION_EDIT_SCRIPT,
      PERMISSION_DELETE_SCRIPT,
    ],
  },
  {
    id: 'chat',
    title: 'Chat',
    path: '/chat',
    permissions: [PERMISSION_CHAT],
  },
  {
    id: 'task_management',
    title: 'Task Management',
    path: '/management',
    permissions: [
      PERMISSION_TASK_LIST,
      PERMISSION_TASK_CREATE,
      PERMISSION_TASK_UPDATE,
      PERMISSION_TASK_DELETE,
    ],
  },
  {
    id: 'admin_panel',
    title: 'Admin Panel',
    path: '/admin_panel',
    permissions: [
      PERMISSION_ROLES_LIST,
      PERMISSION_ROLES_CREATE,
      PERMISSION_ROLES_UPDATE,
      PERMISSION_ROLES_DELETE,
      PERMISSION_COMPANY_LIST,
      PERMISSION_COMPANY_CREATE,
      PERMISSION_COMPANY_UPDATE,
      PERMISSION_COMPANY_DELETE,
    ],
  },
];

// Module config of main sidebar routes
export const FLEET_MAIN_SIDEBAR_CONFIG: {
  [key: string]: MainSidebarAccordion[];
} = {
  admin_panel: [
    {
      id: 'internal_companies',
      title: 'Internal Companies',
      path: '/admin_panel/internal_companies',
      icon: <IconCompany />,
      activeIcon: <IconCompany fillColor="white" />,
      permissions: [PERMISSION_COMPANY_LIST],
    },
    {
      id: 'roles_and_permissions',
      title: 'Roles and Permissions',
      path: '/admin_panel/roles_and_permissions',
      icon: <IconRoles />,
      activeIcon: <IconRoles fillColor="white" />,
      permissions: [PERMISSION_ROLES_LIST],
    },
  ],
  fleet: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      path: '/fleet/dashboard',
      icon: <IconDashboard />,
      activeIcon: <IconDashboard fillColor="white" />,
      permissions: [PERMISSION_DASHBOARD],
    },
    {
      id: 'vehicles',
      title: 'Vehicles',
      icon: <IconVehicle />,
      activeIcon: <IconVehicle fillColor="white" />,
      paths: [
        '/fleet/trucks',
        '/fleet/trucks-maintenances',
        '/fleet/trailers',
        '/fleet/vehicles',
      ],
      permissions: [PERMISSION_TRUCK, PERMISSION_TRAILER, PERMISSION_VEHICLE],
      children: [
        {
          id: 'trucks',
          title: 'Trucks',
          path: '/fleet/trucks',
          permissions: [PERMISSION_TRUCK],
        },
        {
          id: 'trailers',
          title: 'Trailers',
          path: '/fleet/trailers',
          permissions: [PERMISSION_TRAILER],
        },
        {
          id: 'child_vehicles',
          title: 'Vehicles',
          path: '/fleet/vehicles',
          permissions: [PERMISSION_VEHICLE],
        },
      ],
    },
    {
      id: 'inventory',
      title: 'Inventory',
      icon: <IconInventory />,
      activeIcon: <IconInventory fillColor="white" />,
      paths: [
        '/fleet/inventory/items',
        '/fleet/inventory/item',
        '/fleet/inventory/categories',
        '/fleet/inventory/locations',
        '/fleet/inventory/vendors',
      ],
      permissions: [
        PERMISSION_ITEMS,
        PERMISSION_CATEGORY,
        PERMISSION_LOCATION,
        PERMISSION_VENDOR,
      ],
      children: [
        {
          id: 'items',
          title: 'Items',
          path: '/fleet/inventory/items',
          permissions: [PERMISSION_ITEMS],
        },
        {
          id: 'category',
          title: 'Category',
          path: '/fleet/inventory/categories',
          permissions: [PERMISSION_CATEGORY],
        },
        {
          id: 'location',
          title: 'Location',
          path: '/fleet/inventory/locations',
          permissions: [PERMISSION_LOCATION],
        },
        {
          id: 'vendors',
          title: 'Vendors',
          path: '/fleet/inventory/vendors',
          permissions: [PERMISSION_VENDOR],
        },
      ],
    },
    {
      id: 'inspections',
      title: 'Inspections',
      icon: <IconInspection />,
      activeIcon: <IconInspection fillColor="white" />,
      paths: [
        '/fleet/inspections/all',
        '/fleet/inspections/trucks',
        '/fleet/inspections/trailers',
        '/fleet/inspections/drivers',
        '/fleet/inspections/alerts',
        '/fleet/inspections/edit',
        '/fleet/requests',
      ],
      permissions: [PERMISSION_INSPECTION],
      children: [
        {
          id: 'child_inspections',
          title: 'All Inspections',
          path: '/fleet/inspections/all',
          paths: [
            '/fleet/inspections/all',
            '/fleet/inspections/trucks',
            '/fleet/inspections/trailers',
            '/fleet/inspections/drivers',
            '/fleet/inspections/alerts',
          ],
          permissions: [PERMISSION_INSPECTION],
        },
      ],
    },
    {
      id: 'claims',
      title: 'Claims',
      path: '/fleet/claims',
      icon: <IconClaim />,
      activeIcon: <IconClaim color="white" />,
      permissions: [PERMISSION_CLAIMS],
    },
    {
      id: 'geofences',
      title: 'Geofences',
      path: '/fleet/geofences',
      icon: <IconGeofence />,
      activeIcon: <IconGeofence fillColor="white" />,
      permissions: [PERMISSION_GEOFENCE],
    },
    {
      id: 'tolls',
      title: 'Tolls',
      path: '/fleet/tolls',
      icon: <IconToll />,
      activeIcon: <IconToll fillColor="white" />,
      permissions: [PERMISSION_TOLLS],
    },
    {
      id: 'workorders',
      title: 'Work Orders',
      path: '/fleet/work-orders',
      icon: <IconClaim />,
      activeIcon: <IconClaim color="white" />,
      permissions: [PERMISSION_WORK_ORDER_LIST],
    },
  ],
  accounting: [
    {
      id: 'payrolls',
      title: 'Payrolls',
      paths: ['/accounting/payrolls/week', '/accounting/payrolls/driver'],
      icon: <IconClock />,
      activeIcon: <IconClock fillColor="white" />,
      permissions: [PERMISSION_PAYROLL_WEEKLY, PERMISSION_DRIVER_PAYROLL],
      children: [
        {
          id: 'weeklyPayrolls',
          title: 'Weekly',
          path: '/accounting/payrolls/weeks',
          paths: ['/accounting/payrolls/week'],
          permissions: [PERMISSION_PAYROLL_WEEKLY],
        },
        {
          id: 'driversPayrolls',
          title: 'Drivers',
          path: '/accounting/payrolls/drivers',
          paths: ['/accounting/payrolls/driver'],
          permissions: [PERMISSION_DRIVER_PAYROLL],
        },
      ],
    },
    {
      id: 'payrollItems',
      title: 'Payroll Items',
      paths: [
        '/accounting/payrollItems/all',
        '/accounting/payrollItems/scheduledDeduction',
        '/accounting/payrollItems/deduction',
        '/accounting/payrollItems/fuel',
        '/accounting/payrollItems/credit',
      ],
      icon: <IconClock />,
      activeIcon: <IconClock fillColor="white" />,
      permissions: [PERMISSION_DEDUCTION, PERMISSION_FUEL, PERMISSION_CREDIT],
      children: [
        /* NOTE: temporarily hidden for release due to is not implemented
        {
          id: 'payrollItems_all',
          title: 'All',
          path: '/accounting/payrollItems/all',
          permissions: [PERMISSION_ACCOUNTING_PAYROLL_ITEMS],
        }, */
        {
          id: 'payrollItems_deductions',
          title: 'Deductions',
          path: '/accounting/payrollItems/deductions',
          paths: ['/accounting/payrollItems/deduction'],
          permissions: [PERMISSION_DEDUCTION],
        },
        {
          id: 'payrollItems_fuel',
          title: 'Fuel',
          path: '/accounting/payrollItems/fuel',
          permissions: [PERMISSION_FUEL],
        },
        {
          id: 'payrollItems_credits',
          title: 'Credits',
          path: '/accounting/payrollItems/credits',
          permissions: [PERMISSION_CREDIT],
        },
      ],
    },
    {
      id: 'billing',
      title: 'Billing',
      path: '/accounting/billing',
      icon: <IconToll />,
      activeIcon: <IconToll fillColor="white" />,
      permissions: [PERMISSION_BILLING],
    },
    {
      id: 'reports',
      title: 'Reports',
      paths: [
        '/accounting/reports/driver',
        '/accounting/reports/trailer',
        '/accounting/reports/company',
      ],
      icon: <IconToll />,
      activeIcon: <IconToll fillColor="white" />,
      permissions: [PERMISSION_REPORTS],
      children: [
        /* NOTE: temporarily hidden for release due to is not implemented
        {
          id: 'reports_drivers',
          title: 'Drivers',
          path: '/accounting/reports/drivers',
          paths: ['/accounting/reports/driver'],
          permissions: [PERMISSION_ACCOUNTING_REPORTS],
        },
        {
          id: 'reports_trailers',
          title: 'Trailers',
          path: '/accounting/reports/trailers',
          paths: ['/accounting/reports/trailer'],
          permissions: [PERMISSION_ACCOUNTING_REPORTS],
        },
        */
        {
          id: 'reports_company',
          title: 'Company',
          path: '/accounting/reports/company',
          paths: ['/accounting/reports/company'],
          permissions: [PERMISSION_REPORTS],
        },
      ],
    },
  ],
  dispatch: [
    {
      id: 'orders',
      path: '/dispatch/orders',
      icon: <IconVehicle />,
      activeIcon: <IconVehicle fillColor="white" />,
      title: 'Orders',
      permissions: [PERMISSION_ORDER],
    },
    {
      id: 'brokers',
      path: '/dispatch/brokers',
      icon: <LittleSuitcase />,
      activeIcon: <LittleSuitcase fillColor="white" />,
      title: 'Brokers',
      permissions: [PERMISSION_BROKERS],
    },
    {
      id: 'facility',
      path: '/dispatch/facilities',
      title: 'Facility',
      icon: <IconFacility />,
      activeIcon: <IconFacility fillColor="white" />,
      permissions: [PERMISSION_FACILITIES],
    },
    {
      id: 'calendar',
      path: '/dispatch/calendar',
      title: 'Calendar',
      icon: <IconCalendar />,
      activeIcon: <IconCalendar fillColor="white" />,
      permissions: [PERMISSION_ORDER],
    },
    {
      id: 'reports',
      paths: [
        '/dispatch/reports/overview/offices',
        '/dispatch/reports/overview/divisions',
        '/dispatch/reports/overview/dispatchers',
      ],
      title: 'Reports',
      icon: <IconStatistics fillColor="var(--sideBarIcon)" />,
      activeIcon: <IconStatistics fillColor="white" />,
      permissions: [PERMISSION_ORDER],
      children: [
        {
          id: 'dispatch_overview',
          path: '/dispatch/reports/overview',
          title: 'Overview',
          permissions: [PERMISSION_ORDER],
        },
      ],
    },
  ],
  hr: [
    {
      id: 'drivers',
      title: 'Drivers',
      paths: ['/hr/drivers', '/hr/leads', '/hr/upcoming_drivers'],
      icon: <IconWheel fillColor="var(--sideBarIcon)" />,
      activeIcon: <IconWheel fillColor="white" />,
      permissions: [
        PERMISSION_EMPLOYEE_LIST,
        PERMISSION_DRIVER_LIST,
        PERMISSION_LEAD_LIST,
        PERMISSION_UPCOMING_DRIVERS_LIST,
        PERMISSION_ARCHIVE_DRIVER_LIST,
      ], // TODO update if needed with upcoming driver permissions
      children: [
        {
          id: 'hr_drivers',
          path: '/hr/drivers',
          title: 'Drivers',
          permissions: [PERMISSION_DRIVER_LIST],
        },
        {
          id: 'hr_upcoming_drivers',
          path: '/hr/upcoming_drivers',
          title: 'Upcoming Drivers',
          permissions: [PERMISSION_UPCOMING_DRIVERS_LIST],
        },
        {
          id: 'hr_leads',
          path: '/hr/leads',
          title: 'Leads',
          permissions: [PERMISSION_LEAD_LIST],
        },
        {
          id: 'hr_analytics',
          path: '/hr/analytics',
          title: 'Analytics',
          permissions: [PERMISSION_LEAD_LIST],
        },
      ],
    },
    {
      id: 'employees',
      path: '/hr/employees',
      icon: <LittleDefaultUserPhoto fillColor="var(--sideBarIcon)" />,
      activeIcon: <LittleDefaultUserPhoto fillColor="white" />,
      title: 'Employees',
      permissions: [PERMISSION_EMPLOYEE_LIST],
    },
    {
      id: 'archive',
      path: '/hr/archive',
      title: 'Archive',
      icon: <IconArchive fillColor="var(--sideBarIcon)" />,
      activeIcon: <IconArchive fillColor="white" />,
      permissions: [PERMISSION_ARCHIVE_DRIVER_LIST],
    },
  ],
  wise: [
    {
      id: 'wiseDashboard',
      title: 'Dashboard',
      path: '/fleet/dashboard',
      icon: <IconDashboard />,
      activeIcon: <IconDashboard fillColor="white" />,
      permissions: [
        PERMISSION_TRUCK,
        PERMISSION_TRAILER,
        PERMISSION_TOLLS,
        PERMISSION_VEHICLE,
        PERMISSION_GEOFENCE,
      ],
    },
    {
      id: 'vehicles',
      title: 'Vehicles',
      icon: <IconVehicle />,
      activeIcon: <IconVehicle fillColor="white" />,
      paths: ['/fleet/trucks', '/fleet/trucks-maintenances', '/fleet/trailers'],
      permissions: [PERMISSION_TRUCK, PERMISSION_TRAILER, PERMISSION_VEHICLE],
      children: [
        {
          id: 'trucks',
          title: 'Trucks',
          path: '/fleet/trucks',
          permissions: [PERMISSION_TRUCK],
        },
        {
          id: 'trailers',
          title: 'Trailers',
          path: '/fleet/trailers',
          permissions: [PERMISSION_TRAILER],
        },
      ],
    },
    {
      id: 'inventory',
      title: 'Inventory',
      icon: <IconInventory />,
      activeIcon: <IconInventory fillColor="white" />,
      paths: [
        '/fleet/inventory/items',
        '/fleet/inventory/item',
        '/fleet/inventory/categories',
        '/fleet/inventory/vendors',
      ],
      permissions: [PERMISSION_ITEMS, PERMISSION_CATEGORY, PERMISSION_VENDOR],
      children: [
        {
          id: 'items',
          title: 'Items',
          path: '/fleet/inventory/items',
          permissions: [PERMISSION_ITEMS],
        },
        {
          id: 'category',
          title: 'Category',
          path: '/fleet/inventory/categories',
          permissions: [PERMISSION_CATEGORY],
        },
        {
          id: 'vendors',
          title: 'Vendors',
          path: '/fleet/inventory/vendors',
          permissions: [PERMISSION_VENDOR],
        },
      ],
    },
    {
      id: 'inspections',
      title: 'Inspections',
      paths: ['/fleet/inspections/all' /* '/fleet/requests' */],
      icon: <IconInspection />,
      activeIcon: <IconInspection fillColor="white" />,
      permissions: [PERMISSION_INSPECTION],
      children: [
        {
          id: 'child_inspections',
          title: 'All Inspections',
          path: '/fleet/inspections/all',
          permissions: [PERMISSION_INSPECTION],
        },
        // {
        //   id: 'requests',
        //   title: 'Requests',
        //   path: '/fleet/requests',
        //   permissions: [
        //     PERMISSION_TRUCK,
        //     PERMISSION_TRAILER,
        //     PERMISSION_VEHICLE,
        //   ],
        // },
      ],
    },
    {
      id: 'wiseClaims',
      title: 'Claims',
      path: '/fleet/claims',
      icon: <IconWarningSquare />,
      activeIcon: <IconWarningSquare fillColor="white" />,
      permissions: [PERMISSION_CLAIMS],
    },
    {
      id: 'wise_hr',
      title: 'HR',
      icon: <LittleDefaultUserPhoto fillColor="var(--sideBarIcon)" />,
      activeIcon: <LittleDefaultUserPhoto fillColor="white" />,
      paths: ['/hr/drivers', '/hr/employees', '/hr/archive'],
      permissions: [
        PERMISSION_DRIVER_LIST,
        PERMISSION_EMPLOYEE_LIST,
        PERMISSION_ARCHIVE_DRIVER_LIST,
      ],
      children: [
        {
          id: 'drivers',
          title: 'Drivers',
          path: '/hr/drivers',
          permissions: [PERMISSION_DRIVER_LIST],
        },
        {
          id: 'users',
          title: 'Users',
          path: '/hr/employees',
          permissions: [PERMISSION_EMPLOYEE_LIST],
        },
        {
          id: 'archive',
          title: 'Archive',
          path: '/hr/archive',
          permissions: [PERMISSION_ARCHIVE_DRIVER_LIST],
        },
      ],
    },
    {
      id: 'wiseSettings',
      title: 'Settings',
      paths: [
        '/fleet/settings',
        '/settings/integrations/gps_tracking',
        '/fleet/inventory/locations',
      ],
      icon: <IconSettingsWise fillColor="var(--sideBarIcon)" />,
      activeIcon: <IconSettingsWise fillColor="white" />,
      permissions: [
        PERMISSION_GPS_TRACKING, // TODO update when wise permissions are added
      ],
      children: [
        {
          id: 'company_settings',
          title: 'Company Settings',
          path: '/fleet/settings',
          permissions: [PERMISSION_GPS_TRACKING],
        },
        {
          id: 'gps_tracking',
          title: 'GPS tracking',
          path: '/settings/integrations/gps_tracking',
          paths: ['/settings/integrations/gps_tracking'],
          permissions: [PERMISSION_GPS_TRACKING],
        },
        {
          id: 'location',
          title: 'Location',
          path: '/fleet/inventory/locations',
          permissions: [PERMISSION_GPS_TRACKING],
        },
      ],
    },
    {
      id: 'chats',
      title: 'Chats',
      path: '/chat',
      icon: <IconChat fillColor="var(--sideBarIcon)" transformToSmall />,
      activeIcon: <IconChat fillColor="white" transformToSmall />,
      permissions: [PERMISSION_CHAT],
    },
    {
      id: 'wise_tasks',
      title: 'Tasks',
      path: '/management/tasks',
      icon: <IconTasks color="var(--sideBarIcon)" transformToSmall />,
      activeIcon: <IconTasks color="white" transformToSmall />,
      permissions: [PERMISSION_TRUCK],
    },
  ],
  settings: [
    {
      id: 'accounting',
      title: 'Accounting',
      paths: [
        '/settings/accounting/factoryCompanies',
        '/settings/accounting/payrolls',
      ],
      icon: <IconRepair fillColor="var(--sideBarIcon)" />,
      activeIcon: <IconRepair fillColor="white" />,
      permissions: [
        PERMISSION_FACTORY_COMPANIES,
        PERMISSION_PAYROLL,
        PERMISSION_GPS_TRACKING,
      ],
      children: [
        {
          id: 'factoryCompanies',
          title: 'Factory Companies',
          path: '/settings/accounting/factoryCompanies',
          paths: ['/settings/accounting/factoryCompanies'],
          permissions: [PERMISSION_FACTORY_COMPANIES],
        },
        {
          id: 'payrolls',
          title: 'Payrolls',
          path: '/settings/accounting/payrolls',
          paths: ['/settings/accounting/payrolls'],
          permissions: [PERMISSION_PAYROLL],
        },
      ],
    },
    {
      id: 'integrations',
      title: 'Integrations',
      paths: ['/settings/integrations/gps_tracking'],
      icon: <IconSettings fillColor="var(--sideBarIcon)" />,
      activeIcon: <IconSettings fillColor="white" />,
      permissions: [PERMISSION_GPS_TRACKING], // TODO
      children: [
        {
          id: 'gps_tracking',
          title: 'GPS tracking',
          path: '/settings/integrations/gps_tracking',
          paths: ['/settings/integrations/gps_tracking'],
          permissions: [PERMISSION_GPS_TRACKING], // TODO
        },
      ],
    },
    {
      id: 'hr',
      title: 'HR',
      paths: [
        '/settings/hr/orientation_process',
        '/settings/hr/document_mapping',
        '/settings/hr/contract_templates',
        '/settings/hr/scripts',
      ],
      icon: <IconInventory fillColor="var(--sideBarIcon)" />,
      activeIcon: <IconInventory fillColor="white" />,
      permissions: [
        PERMISSION_ORIENTATION_PROCESS,
        PERMISSION_DOCUMENT_MAPPING,
        PERMISSION_CONTRACT_TEMPLATES,
        PERMISSION_SCRIPT,
      ],
      children: [
        {
          id: 'orientation_process',
          title: 'Orientation Process',
          path: '/settings/hr/orientation_process',
          paths: ['/settings/hr/orientation_process'],
          permissions: [PERMISSION_ORIENTATION_PROCESS],
        },
        {
          id: 'document_mapping',
          title: 'Document Mapping',
          path: '/settings/hr/document_mapping',
          paths: ['/settings/hr/document_mapping'],
          permissions: [PERMISSION_DOCUMENT_MAPPING],
        },
        {
          id: 'contract_templates',
          title: 'Contract Templates',
          path: '/settings/hr/contract_templates',
          paths: ['/settings/hr/contract_templates'],
          permissions: [PERMISSION_CONTRACT_TEMPLATES],
        },
        {
          id: 'scripts',
          title: 'Scripts',
          path: '/settings/hr/scripts',
          paths: ['/settings/hr/scripts'],
          permissions: [PERMISSION_SCRIPT],
        },
      ],
    },
    {
      id: 'workshop',
      title: 'Workshop',
      path: '/settings/workshop',
      icon: <IconDashboard />,
      activeIcon: <IconDashboard fillColor="white" />,
      permissions: [PERMISSION_GARAGES_LIST, PERMISSION_TECHNICIANS_LIST],
    },
  ],
  chat: [
    {
      id: 'chats',
      title: 'Chats',
      path: '/chat',
      icon: <IconChat />,
      activeIcon: <IconChat fillColor="white" />,
      permissions: [PERMISSION_CHAT],
    },
  ],
  safety: [
    {
      id: 'claims',
      title: 'Claims',
      paths: ['/safety/accidents'],
      icon: <IconWarningSquare />,
      activeIcon: <IconWarningSquare fillColor="white" />,
      permissions: [PERMISSION_ACCIDENT],
      children: [
        {
          id: 'accidents',
          title: 'Accidents',
          path: '/safety/accidents',
          permissions: [PERMISSION_ACCIDENT],
        },
      ],
    },
  ],
  task_management: [
    // TODO updated permissions later
    {
      id: 'tasks',
      title: 'Tasks',
      icon: <IconTasks color="var(--sideBarIcon)" />,
      activeIcon: <IconTasks color="white" />,
      paths: ['/management/tasks'],
      permissions: [PERMISSION_TASK_LIST],
    },
  ],
};
