type Props = {
  className?: string;
  stroke?: string;
};

const IconSheet = ({ className, stroke = '#2D2F36' }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.5 1H3C2.44772 1 2 1.44772 2 2V14C2 14.5523 2.44772 15 3 15H13C13.5523 15 14 14.5523 14 14V7M8.5 1V6C8.5 6.55228 8.94772 7 9.5 7H14M8.5 1L14 7"
        stroke={stroke}
        strokeWidth="2"
      />
    </svg>
  );
};

export default IconSheet;
