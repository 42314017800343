import { RefObject, useRef, useEffect } from 'react';

import { toSnakeCase } from 'utils/helpers';

const IS_DEV_MODE = process.env.REACT_APP_DEV_MODE === 'true';

/**
 * Convert string to corect form for data-test attribute
 * @param name - string for covert
 * @returns string
 */
export function getAttrName(name: string): string {
  return toSnakeCase(
    name
      .replaceAll(' ', '_')
      .replace(/[^\w\s]/gi, '') // remove all special characters
      .toLowerCase(),
  );
}

export function getShortPath(fullPath?: string | null, depth = 3) {
  return fullPath
    ? getAttrName(
        fullPath
          .split('/')
          .filter(e => !!e && !Number(e))
          .slice(0, depth)
          .join('_'),
      )
    : '';
}
/**
 * Append data-test atribute to element
 * @param extRef - exsisted ref for extend
 * @param name  - custom name for datatest
 * @param checkParent - check parents for datatest
 * @returns RefObject
 */
export default function useTestAttrRef<T extends Element>(
  extRef: RefObject<T> | null,
  name?: string,
  checkParent = true,
): RefObject<T> {
  const intRef = useRef<T>(null);

  useEffect(() => {
    const element = extRef?.current || intRef.current;
    if (IS_DEV_MODE && element) {
      let result = getAttrName(
        name ||
          element.getAttribute('name') ||
          (element instanceof HTMLAnchorElement &&
            getShortPath(element.getAttribute('href'))) ||
          (element instanceof HTMLButtonElement &&
            getShortPath(element.innerText)) ||
          element.getAttribute('id') ||
          '',
      );
      if (checkParent) {
        let parentName = '';
        let parent = element.parentElement;
        while (parent && !parentName) {
          parentName = parent.getAttribute('data-test') || '';
          parent = parent.parentElement;
        }
        if (!parentName) {
          parentName = getShortPath(window.location.pathname);
          result = `${parentName}_${result}`;
        }
      }
      if (result) {
        if (element instanceof HTMLFormElement) result = `${result}_form`;
        else if (element instanceof HTMLButtonElement)
          result = `${result}_button`;
        else if (element instanceof HTMLInputElement)
          result = `${result}_input`;
        else if (element instanceof HTMLAnchorElement)
          result = `${result}_link`;
        else if (element instanceof HTMLTextAreaElement)
          result = `${result}_text`;
        else if (element.getAttribute('role') === 'listbox')
          result = `${result}_select`;
        else if (element.getAttribute('role') === 'option')
          result = `${result}_option`;
        else if (element.getAttribute('role') === 'menuitem')
          result = `${result}_menuitem`;

        element.setAttribute('data-test', getAttrName(result));
      }
    }
  }, [extRef, extRef?.current, intRef, intRef.current]);

  return extRef || intRef;
}
