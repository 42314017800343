import { lazy } from 'react';

import { PERMISSION_TASK_LIST } from 'constants/permissions';

import { IPermissionObject } from '../index';
import PrivateRoutes, { redirectRoute } from '../PrivateRoutes/PrivateRoutes';

const Tasks = lazy(() => import('components/TaskManagement/TabTasks/TabTasks'));

const getRedirectURL = (permissions?: string[]) => {
  if (permissions?.includes(PERMISSION_TASK_LIST)) return '/management/tasks';

  return '/';
};

export const mainTaskManagementRoute = (permissions?: IPermissionObject[]) => {
  const targetName = 'Task Management';
  const targetObject = permissions?.find(obj => obj.name === targetName);
  return redirectRoute(
    '/management',
    getRedirectURL(targetObject ? targetObject.permissions : []),
  );
};

export const tasksRoute = (
  <PrivateRoutes
    exact
    path="/management/tasks"
    component={Tasks}
    permissionType={PERMISSION_TASK_LIST}
  />
);
