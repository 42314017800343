import { IPagination } from 'components/_common/Navigation/Pagination/_redux/paginationTypes';
import { IWithPending, IWithLoaded, IWithError } from 'utils/models';

import { IApplicantArchived } from '../_models/mainEntities';
import {
  applicantsArchiveTypes,
  GET_APPLICANTS_ARCHIVE_LIST,
  GET_APPLICANTS_ARCHIVE_LIST_SUCCESS,
  GET_APPLICANTS_ARCHIVE_LIST_FAIL,
} from './applicantsArchiveTypes';

export interface IApplicantsArchiveState
  extends IWithPending,
    IWithLoaded,
    IWithError {
  list: IApplicantArchived[];
  pagination: IPagination | null;
}

const initialState: IApplicantsArchiveState = {
  list: [],
  pagination: null,
  _pending: false,
  _loaded: false,
  _error: null,
};

const applicantsArchiveReducers = (
  state: IApplicantsArchiveState = initialState,
  action: applicantsArchiveTypes,
) => {
  switch (action.type) {
    case GET_APPLICANTS_ARCHIVE_LIST:
      return {
        ...state,
        _pending: true,
        _loaded: false,
        _error: null,
      };

    case GET_APPLICANTS_ARCHIVE_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.applicants,
        pagination: action.payload.pagination,
        _pending: false,
        _loaded: true,
      };

    case GET_APPLICANTS_ARCHIVE_LIST_FAIL:
      return {
        ...state,
        _error: action.payload,
        _pending: false,
        _loaded: false,
      };

    default:
      return state;
  }
};

export default applicantsArchiveReducers;
