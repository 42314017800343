import { isEmpty } from 'ramda';
import { startSubmit, stopSubmit } from 'redux-form';
import { call, put, takeLatest, select, takeLeading } from 'redux-saga/effects';

import { setPaginationAction } from 'components/_common/Navigation/Pagination/_redux/paginationActions';
import { paginationSelector } from 'components/_common/Navigation/Pagination/_redux/paginationSelectors';
import { getErrors } from 'utils/helpers';
import api from 'utils/requests';

import {
  createGeoFenceAction,
  getGeoFenceAction,
  updateGeoFenceAction,
  deleteGeoFenceAction,
  getGeoFencesAction,
  getGeoFenceAlerts,
} from './geoFencesActions';
import { geoFencesSelector } from './geoFencesSelectors';
import {
  CREATE_GEOFENCE,
  GET_GEOFENCES,
  GET_GEOFENCE,
  UPDATE_GEOFENCE,
  DELETE_GEOFENCE,
  GET_GEOFENCES_SUCCESS,
  GET_GEOFENCES_FAIL,
  GET_GEOFENCE_SUCCESS,
  GET_GEOFENCE_FAIL,
  CREATE_GEOFENCE_SUCCESS,
  CREATE_GEOFENCE_FAIL,
  UPDATE_GEOFENCE_SUCCESS,
  UPDATE_GEOFENCE_FAIL,
  DELETE_GEOFENCE_SUCCESS,
  DELETE_GEOFENCE_FAIL,
  GET_GEOFENCE_ALERTS,
  GET_GEOFENCE_ALERTS_SUCCESS,
  GET_GEOFENCE_ALERTS_FAIL,
} from './geoFencesTypes';

function* getGeoFencesSaga({
  payload: {
    data: { query },
    callback,
    config: { usePaginationFlag },
  },
}: ReturnType<typeof getGeoFencesAction>) {
  try {
    const { per_page, page } = yield select(paginationSelector);
    const { data } = yield call(() =>
      api.get(
        `/v1/fleets/geofences?page=${page}&per_page=${per_page}${query || ''}`,
      ),
    );
    yield put({ type: GET_GEOFENCES_SUCCESS, payload: data });

    // Pagination set
    if (usePaginationFlag) yield put(setPaginationAction(data.pagination));
    if (callback) callback();
  } catch (error) {
    yield put({ type: GET_GEOFENCES_FAIL, payload: error });
  }
}

function* getGeoFenceSaga({
  payload: {
    data: { id },
    callback,
  },
}: ReturnType<typeof getGeoFenceAction>) {
  try {
    const { data } = yield call(() => api.get(`/v1/fleets/geofences/${id}`));
    yield put({ type: GET_GEOFENCE_SUCCESS, payload: data });
    if (callback) callback();
  } catch (error) {
    yield put({ type: GET_GEOFENCE_FAIL, payload: error });
  }
}

function* createGeoFenceSaga({
  payload: {
    data: { values },
    callback,
  },
}: ReturnType<typeof createGeoFenceAction>) {
  try {
    yield put(startSubmit('createAndUpdateGeoFenceForm'));
    const { data } = yield call(() => api.post('/v1/fleets/geofences', values));
    yield put({ type: CREATE_GEOFENCE_SUCCESS, payload: data });
    yield put(stopSubmit('createAndUpdateGeoFenceForm'));

    if (callback) callback(data);

    const { pages, per_page } = yield select(paginationSelector);
    const { list } = yield select(geoFencesSelector);
    if (per_page === list.length / 2) {
      yield put(
        setPaginationAction({
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          pages: pages + 1,
        }),
      );
      yield put({ type: GET_GEOFENCES, payload: { data: {}, config: {} } });
    }
  } catch (e) {
    yield put({ type: CREATE_GEOFENCE_FAIL, payload: e });
    if (api.isAxiosError(e))
      yield put(stopSubmit('createAndUpdateGeoFenceForm', getErrors(e)));
  }
}

function* updateGeoFenceSaga({
  payload: {
    data: { id, values },
    callback,
  },
}: ReturnType<typeof updateGeoFenceAction>) {
  try {
    yield put(startSubmit('createAndUpdateGeoFenceForm'));
    const { data } = yield call(() =>
      api.patch(`/v1/fleets/geofences/${id}`, values),
    );
    yield put({ type: UPDATE_GEOFENCE_SUCCESS, payload: data });
    yield put(stopSubmit('createAndUpdateGeoFenceForm'));
    if (callback) callback();
  } catch (e) {
    yield put({ type: UPDATE_GEOFENCE_FAIL, payload: e });
    if (api.isAxiosError(e))
      yield put(stopSubmit('createAndUpdateGeoFenceForm', getErrors(e)));
  }
}

function* getGeoFenceAlertsSaga({
  payload: {
    data: { id, page, per_page, usePagination },
    callback,
  },
}: ReturnType<typeof getGeoFenceAlerts>) {
  try {
    const { data } = yield call(() =>
      api.get(
        `/v1/fleets/geofences/${id}/alerts?page=${page}&per_page=${per_page}`,
      ),
    );
    if (usePagination) {
      yield put(setPaginationAction(data.pagination));
    }
    yield put({ type: GET_GEOFENCE_ALERTS_SUCCESS, payload: data });
    if (callback) callback();
  } catch (error) {
    yield put({ type: GET_GEOFENCE_ALERTS_FAIL, payload: error });
  }
}

function* deleteGeoFenceSaga({
  payload: {
    data: { id },
    callback,
  },
}: ReturnType<typeof deleteGeoFenceAction>) {
  try {
    const { pages } = yield select(paginationSelector);

    yield call(() => api.delete(`/v1/fleets/geofences/${id}`));
    yield put({ type: DELETE_GEOFENCE_SUCCESS, payload: id });

    if (callback) callback();

    const { list } = yield select(geoFencesSelector);
    if (pages && isEmpty(list)) {
      yield put(
        setPaginationAction({
          page: pages - 1,
        }),
      );
      yield put({ type: GET_GEOFENCES, payload: { data: {}, config: {} } });
    }
  } catch (error) {
    yield put({ type: DELETE_GEOFENCE_FAIL, payload: error });
  }
}

export default function* geoFencesSaga() {
  yield takeLatest(GET_GEOFENCES, getGeoFencesSaga);
  yield takeLatest(GET_GEOFENCE, getGeoFenceSaga);
  yield takeLatest(UPDATE_GEOFENCE, updateGeoFenceSaga);
  yield takeLatest(DELETE_GEOFENCE, deleteGeoFenceSaga);
  yield takeLeading(CREATE_GEOFENCE, createGeoFenceSaga);
  yield takeLeading(GET_GEOFENCE_ALERTS, getGeoFenceAlertsSaga);
}
