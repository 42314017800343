import { memo, useEffect, useMemo } from 'react';

import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { IconArrowRight, IconArrowDown } from 'assets/icons/components';
import FleetTecLogo from 'assets/img/FleetTecLogo';
import WiseLogo from 'assets/img/WiseLogo';
import useSidebarSelector from 'components/Fleet/_hooks/useSidebarSelector';
import useToggle from 'hooks/useToggle';
import { userSettingsSelector } from 'redux/userSettings/userSettingsSelectors';

import { SIDEBAR_DROP_MENU_CONFIG } from './_config';
import { SideBarDropMenuItem } from './_models';
import {
  setCollapsedFlagAction,
  setOverFlagAction,
} from './_redux/mainSidebarActions';
import SidebarDropMenu from './SidebarDropMenu/SidebarDropMenu';

import styles from './MainSidebar.module.scss';

const SidebarHeader = () => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const {
    isOpen: isDropMenuOpen,
    close: closeDropMenu,
    toggleVisibility: toggleDropMenu,
  } = useToggle();

  const { collapsedFlag, realCollapsedFlag } = useSidebarSelector();
  const settings = useSelector(userSettingsSelector);
  const isWiseCheck = settings?.company?.product_menu_type === 'wisecheck';

  function onMouseEnterHandler() {
    dispatch(setOverFlagAction(true));
  }

  const toggleMenuFlagHandler = () => {
    dispatch(setCollapsedFlagAction(!realCollapsedFlag));
  };

  useEffect(() => closeDropMenu(), [pathname]);

  const currentItem: SideBarDropMenuItem | undefined = useMemo(
    () =>
      SIDEBAR_DROP_MENU_CONFIG.find(item =>
        !isWiseCheck ? pathname.startsWith(item.path) : item.id === 'wise',
      ),
    [pathname, isWiseCheck],
  );

  return (
    <header>
      <div
        className={clsx(styles.hoverContainer, {
          [styles.active]: isDropMenuOpen,
        })}
        onMouseEnter={onMouseEnterHandler}
        onMouseDown={toggleDropMenu}
        data-test="main-sidebar-menu"
      >
        {isWiseCheck ? (
          <WiseLogo className={styles.wiseLogo} />
        ) : (
          <FleetTecLogo withoutTextFlag />
        )}
        {!collapsedFlag && (
          <section className={styles.selectBranchContainer}>
            <span className={styles.branchName}>
              {currentItem ? currentItem.title : ''}
            </span>
            {!isWiseCheck && (
              <IconArrowDown
                strokeColor="var(--primaryBorder)"
                className={clsx({
                  [styles.rotatedArrow]: isDropMenuOpen,
                })}
              />
            )}
          </section>
        )}
      </div>

      <span
        className={clsx(styles.toggleIcon, {
          [styles.collapsed]: realCollapsedFlag,
        })}
        onClick={toggleMenuFlagHandler}
      >
        <IconArrowRight />
      </span>
      {isDropMenuOpen && !isWiseCheck && (
        <>
          <SidebarDropMenu
            activePath={pathname}
            onClickOutside={(e: MouseEvent | TouchEvent) => {
              e.stopPropagation();
              closeDropMenu();
            }}
          />
          <div className={styles.dropMenuOverlay} />
        </>
      )}
    </header>
  );
};

export default memo(SidebarHeader);
