import { ISearchResult } from 'components/_common/SearchPanel/models';
import { APPLICANT_API_PREFIX } from 'constants/api';
import { IDefaultSettingsStringKey } from 'redux/userSettings/models';
import { getNameString } from 'utils/helpers';

import { IApplicant } from '../_models/mainEntities';

export const getFileDownloadPath = (itemId: number, fileId: string) => {
  return `${APPLICANT_API_PREFIX}/${itemId}/files/${fileId}`;
};

export const applicantFileToFormData = ({
  file,
  fileCategory,
}: {
  file: File;
  fileCategory: number;
}) => {
  if (!file) return null;

  const formData = new FormData();

  formData.append(`fleets_applicants_file[file]`, file);
  formData.append(`fleets_applicants_file[category_id]`, `${fileCategory}`);

  return formData;
};

export const applicantFileCategoryToFormData = (fileCategory: number) => {
  const formData = new FormData();

  formData.append(`fleets_applicants_file[category_id]`, `${fileCategory}`);

  return formData;
};

export const transformApplicantsSearchResult = (
  payload: IApplicant[],
  driver_types: IDefaultSettingsStringKey | null,
): ISearchResult[] => {
  return payload.map((item: IApplicant) => {
    return {
      id: item.id,
      title: getNameString(item),
      additional: [
        item.phone_number,
        item.email,
        driver_types?.[item?.driver_type]?.name || '—',
      ],
      url: `/applicants/applicants/${item.id}`,
    };
  });
};
