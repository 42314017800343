import { ISearchResult } from 'components/_common/SearchPanel/models';

import { IVendor } from '../_models/vendorModels';

// eslint-disable-next-line import/prefer-default-export
export const transformVendorsSearchResult = (
  payload: IVendor[],
): ISearchResult[] => {
  return payload.map((item: IVendor) => {
    return {
      id: item.id,
      title: item.company_name,
      additional: `${item.first_name} ${item.last_name}, ${item.billing_address_city}`,
      url: `/fleet/inventory/vendors/${item.id}`,
    };
  });
};
