// external
import { stopSubmit, startSubmit } from 'redux-form';
import { takeLatest, select, call, put } from 'redux-saga/effects';

// internal
import { setPaginationAction } from 'components/_common/Navigation/Pagination/_redux/paginationActions';
import { initialState as paginationInitialState } from 'components/_common/Navigation/Pagination/_redux/paginationReducers';
import { paginationSelector } from 'components/_common/Navigation/Pagination/_redux/paginationSelectors';
import {
  setSearchResultSuccessAction,
  setSearchResultFailAction,
  setSearchResultAction,
} from 'components/_common/SearchPanel/_redux/searchActions';
import { searchSelector } from 'components/_common/SearchPanel/_redux/searchSelectors';
import { MAX_SEARCH_RESULT } from 'components/_common/SearchPanel/constants';
import { fileToFormData } from 'utils/converters';
import { getErrors } from 'utils/helpers';
import api from 'utils/requests';

import { transformLocationsSearchResult } from './helpers';
import {
  getLocationsAction,
  getLocationsSuccessAction,
  getLocationsFailAction,
  createLocationAction,
  createLocationSuccessAction,
  createLocationFailAction,
  updateLocationAction,
  updateLocationSuccessAction,
  updateLocationFailAction,
  deleteLocationAction,
  deleteLocationSuccessAction,
  deleteLocationFailAction,
  uploadLocationFileAction,
  deleteLocationFileAction,
  uploadLocationFileSuccessAction,
  uploadLocationFileFailAction,
  deleteLocationFileSuccessAction,
  deleteLocationFileFailAction,
  getLocationByIdAction,
  getLocationByIdSuccessAction,
  getLocationByIdFailAction,
  getLocationInvitedEmployeesAction,
  getLocationInvitedEmployeesSuccessAction,
  getLocationInvitedEmployeesFailAction,
  addEmployeeToLocationAction,
  addEmployeeToLocationSuccessAction,
  addEmployeeToLocationFailAction,
  getLocationAvailableEmployeesAction,
  getLocationAvailableEmployeesSuccessAction,
  getLocationAvailableEmployeesFailAction,
} from './locationsActions';
import {
  GET_LOCATIONS,
  CREATE_LOCATION,
  UPDATE_LOCATION,
  DELETE_LOCATION,
  UPLOAD_LOCATION_FILE,
  DELETE_LOCATION_FILE,
  GET_LOCATION_BY_ID,
  SEARCH_LOCATION,
  GET_LOCATION_INVITED_EMPLOYEES,
  ADD_EMPLOYEE_TO_LOCATION,
  GET_LOCATION_AVAILABLE_EMPLOYEES,
} from './locationsTypes';

function* getLocationsSaga({ payload }: ReturnType<typeof getLocationsAction>) {
  let url = '';
  const {
    data: payloadData,
    per_page: customPerPage,
    callback,
  } = payload || {};

  const { per_page, page } = yield select(paginationSelector);

  if (payload?.data) {
    url = `/v1/inventories/locations?page=${page}&per_page=${per_page}${
      payloadData?.query || ''
    }`;
  } else {
    url = `/v1/inventories/locations${
      customPerPage ? `?per_page=${customPerPage}` : ''
    }`;
  }

  try {
    const { data } = yield call(() => api.get(url));
    yield put(
      getLocationsSuccessAction({
        ...data,
        pagination: {
          ...data.pagination,
          per_page: customPerPage ? per_page : data.pagination.per_page,
        },
      }),
    );
    if (data)
      yield put(
        // prevent set customPerPage in pagination
        setPaginationAction({
          ...data.pagination,
          per_page: customPerPage ? per_page : data.pagination.per_page,
        }),
      );
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(getLocationsFailAction(e));
  }
}

function* getLocationEmployeesSaga({
  payload: {
    id,
    callback,
    per_page = paginationInitialState.per_page,
    page = paginationInitialState.page,
  },
}: ReturnType<typeof getLocationInvitedEmployeesAction>) {
  const url = `/v1/inventories/locations/${id}/employees?page=${page}&per_page=${per_page}`;
  try {
    const { data } = yield call(() => api.get(url));
    yield put(getLocationInvitedEmployeesSuccessAction(data));

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put(getLocationInvitedEmployeesFailAction(e));
  }
}

function* getLocationAvailableEmployeesSaga({
  payload: {
    data: { locationId, usePaginationFlag },
    callback,
  },
}: ReturnType<typeof getLocationAvailableEmployeesAction>) {
  const { per_page, page } = yield select(paginationSelector);
  let { searchValue = '' } = yield select(searchSelector);
  searchValue = searchValue.trim();

  const url = `/v1/inventories/locations/${locationId}/invite_employees?page=${page}&per_page=${per_page}&search=${searchValue}`;
  try {
    const { data } = yield call(() => api.get(url));
    yield put(getLocationAvailableEmployeesSuccessAction(data));

    if (usePaginationFlag && data.pagination) {
      yield put(setPaginationAction(data.pagination));
    }
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put(getLocationAvailableEmployeesFailAction(e));
  }
}

function* searchLocationSaga() {
  yield put(setSearchResultAction([]));
  let { searchValue = '' } = yield select(searchSelector);
  searchValue = searchValue.trim();

  if (searchValue) {
    try {
      const url = `/v1/inventories/locations?page=1&per_page=${MAX_SEARCH_RESULT}&search=${searchValue}`;
      const { data } = yield call(() => api.get(url));
      yield put(
        setSearchResultSuccessAction(
          transformLocationsSearchResult(data.locations),
        ),
      );
    } catch (e) {
      if (api.isAxiosError(e)) yield put(setSearchResultFailAction(e));
    }
  }
}

function* getLocationByIdSaga({
  payload: {
    data: { id },
    callback,
  },
}: ReturnType<typeof getLocationByIdAction>) {
  const url = `/v1/inventories/locations/${id}`;
  try {
    const { data } = yield call(() => api.get(url));
    yield put(getLocationByIdSuccessAction(data.location));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(getLocationByIdFailAction(e));
  }
}

function* createLocationSaga({
  payload: { data, callback },
}: ReturnType<typeof createLocationAction>) {
  const url = '/v1/inventories/locations';
  try {
    yield put(startSubmit('createAndUpdateLocationForm'));
    const {
      data: { location },
    } = yield call(() => api.post(url, data));
    yield put(createLocationSuccessAction(location));
    yield put(stopSubmit('createAndUpdateLocationForm'));
    if (callback) callback(location.id);
  } catch (e) {
    if (api.isAxiosError(e)) {
      yield put(createLocationFailAction(e));
      yield put(stopSubmit('createAndUpdateLocationForm', getErrors(e)));
    }
  }
}

function* updateLocationSaga({
  payload: {
    data: { id, ...location },
    callback,
  },
}: ReturnType<typeof updateLocationAction>) {
  const url = `/v1/inventories/locations/${id}`;
  try {
    yield put(startSubmit('createAndUpdateLocationForm'));
    const { data } = yield call(() =>
      api.patch(url, { inventories_location: location }),
    );
    yield put(updateLocationSuccessAction(data.location));
    yield put(stopSubmit('createAndUpdateLocationForm'));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) {
      yield put(updateLocationFailAction(e));
      yield put(stopSubmit('createAndUpdateLocationForm', getErrors(e)));
    }
  }
}

function* deleteLocationSaga({
  payload: {
    data: { id },
    callback,
  },
}: ReturnType<typeof deleteLocationAction>) {
  const url = `/v1/inventories/locations/${id}`;
  try {
    yield call(() => api.delete(url));
    yield put(deleteLocationSuccessAction(id));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(deleteLocationFailAction(e));
  }
}

function* addEmployeeToLocationSaga({
  payload: {
    data: { locationId, employeeIds },
    callback,
  },
}: ReturnType<typeof addEmployeeToLocationAction>) {
  const url = `/v1/inventories/locations/${locationId}/employees`;
  try {
    yield call(() =>
      api.post(url, {
        employee_ids: employeeIds,
      }),
    );
    yield put(addEmployeeToLocationSuccessAction());
    yield put(
      getLocationInvitedEmployeesAction({
        id: `${locationId}`,
      }),
    );
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(addEmployeeToLocationFailAction(e));
  }
}

function* uploadLocationFileSaga({
  payload: { files, itemId: locationId },
}: ReturnType<typeof uploadLocationFileAction>) {
  try {
    const url = locationId
      ? `/v1/inventories/locations/${locationId}/photo`
      : '/v1/inventories/locations/photos';
    const { data } = yield call(() =>
      api.post(
        url,
        fileToFormData(files[0].file, 'inventories_locations_photo[file]'),
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      ),
    );
    yield put(uploadLocationFileSuccessAction(data.photo));
  } catch (e) {
    if (api.isAxiosError(e)) yield put(uploadLocationFileFailAction(e));
  }
}

function* deleteLocationFileSaga({
  payload: { fileId, itemId: locationId },
}: ReturnType<typeof deleteLocationFileAction>) {
  try {
    const url = locationId
      ? `/v1/inventories/locations/${locationId}/photo`
      : `/v1/inventories/locations/photos/${fileId}`;

    yield call(() => api.delete(url));
    yield put(deleteLocationFileSuccessAction(fileId));
  } catch (e) {
    if (api.isAxiosError(e)) yield put(deleteLocationFileFailAction(e));
  }
}

export default function* locationsSaga() {
  yield takeLatest(GET_LOCATIONS, getLocationsSaga);
  yield takeLatest(GET_LOCATION_INVITED_EMPLOYEES, getLocationEmployeesSaga);
  yield takeLatest(
    GET_LOCATION_AVAILABLE_EMPLOYEES,
    getLocationAvailableEmployeesSaga,
  );
  yield takeLatest(ADD_EMPLOYEE_TO_LOCATION, addEmployeeToLocationSaga);
  yield takeLatest(SEARCH_LOCATION, searchLocationSaga);
  yield takeLatest(GET_LOCATION_BY_ID, getLocationByIdSaga);
  yield takeLatest(CREATE_LOCATION, createLocationSaga);
  yield takeLatest(UPDATE_LOCATION, updateLocationSaga);
  yield takeLatest(DELETE_LOCATION, deleteLocationSaga);
  yield takeLatest(UPLOAD_LOCATION_FILE, uploadLocationFileSaga);
  yield takeLatest(DELETE_LOCATION_FILE, deleteLocationFileSaga);
}
