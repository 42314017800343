import { FC, MouseEvent } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
  size?: string;
  onClick?: (e: MouseEvent) => void;
}

const IconEye: FC<IOwnProps> = ({
  className,
  fillColor,
  size = '16',
  onClick,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.2949 8.47483C1.1035 8.18429 1.1035 7.81571 1.2949 7.52517C2.11708 6.27714 4.59974 3 8 3C11.4003 3 13.8829 6.27714 14.7051 7.52517C14.8965 7.81571 14.8965 8.18429 14.7051 8.47483C13.8829 9.72286 11.4003 13 8 13C4.59974 13 2.11708 9.72286 1.2949 8.47483Z"
        stroke={fillColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M8 9C8.55228 9 9 8.55228 9 8C9 7.44772 8.55228 7 8 7C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9Z"
        stroke={fillColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default IconEye;
