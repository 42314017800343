import { startSubmit, stopSubmit } from 'redux-form';
import {
  call,
  put,
  takeLatest,
  select,
  takeLeading,
  takeEvery,
} from 'redux-saga/effects';

import {
  setPagesAction,
  setPaginationAction,
} from 'components/_common/Navigation/Pagination/_redux/paginationActions';
import { paginationSelector } from 'components/_common/Navigation/Pagination/_redux/paginationSelectors';
import { fileToFormData } from 'utils/converters';
import { getErrors, scrollToFirstError } from 'utils/helpers';
import api, { apiTrucksTracking } from 'utils/requests';

import { truckServiceFileToFormData } from './helpers';
import {
  createTruckAction,
  createTruckFailAction,
  createTruckSuccessAction,
  getTrucksSuccessAction,
  getTrucksFailAction,
  getTruckAction,
  getTruckSuccessAction,
  getTruckFailAction,
  updateTruckSuccessAction,
  updateTruckFailAction,
  updateTruckAction,
  deleteTruckAction,
  deleteTruckSuccessAction,
  deleteTruckFailAction,
  getTrucksAction,
  uploadTruckFilesAction,
  deleteTruckFileAction,
  getTrackingTrucksAction,
  getTruckLogsAction,
  getTruckMaintenanceListAction,
  getTruckMaintenanceListSuccessAction,
  getTruckMaintenanceListFailAction,
  createTruckReviewAction,
  createTruckReviewSuccessAction,
  createTruckReviewFailAction,
  setTruckPinAction,
  setTruckPinFailAction,
  setTruckPinSuccessAction,
  setTruckUnPinAction,
  setTruckUnPinFailAction,
  setTruckUnPinSuccessAction,
  getTruckTrafficHistoryAction,
  getTruckReviewServicesAction,
  getTruckReviewServicesFailAction,
  getTruckReviewServicesSuccessAction,
  updateTruckReviewAction,
  updateTruckReviewSuccessAction,
  updateTruckReviewFailAction,
  getTruckTrafficHistoryByDateAction,
  uploadTruckServiceFileAction,
  getTruckReviewServiceAction,
  getTruckReviewServiceFailAction,
  getTruckReviewServiceSuccessAction,
  deleteTruckServiceFileAction,
  getTruckLocationAction,
  uploadTruckClaimPhotoAction,
  uploadTruckClaimPhotoSuccessAction,
  uploadTruckClaimPhotoFailAction,
  getEnginesAction,
  getEnginesSuccessAction,
  getEnginesFailAction,
  unlockTruckInspectionAction,
  deleteClaimPhotoAction,
  deleteClaimPhotoSuccessAction,
  deleteClaimPhotoFailAction,
} from './trucksActions';
import {
  CREATE_TRUCK,
  GET_TRUCKS,
  GET_TRUCK,
  UPDATE_TRUCK,
  DELETE_TRUCK,
  UPLOAD_TRUCK_FILES,
  DELETE_TRUCK_FILE,
  DELETE_TRUCK_FILE_SUCCESS,
  DELETE_TRUCK_FILE_FAIL,
  UPLOAD_TRUCK_FILES_FAIL,
  UPLOAD_TRUCK_FILES_SUCCESS,
  GET_TRACKING_TRUCKS,
  GET_TRACKING_TRUCKS_SUCCESS,
  GET_TRACKING_TRUCKS_FAIL,
  GET_TRUCK_LOGS,
  GET_TRUCK_LOGS_SUCCESS,
  GET_TRUCK_LOGS_FAIL,
  GET_TRUCK_MAINTENANCE_LIST,
  CREATE_TRUCK_PLANNING_REVIEW,
  SET_PIN_TRUCK_MAP_VIEW,
  SET_UNPIN_TRUCK_MAP_VIEW,
  GET_TRUCK_TRAFFIC_HISTORY,
  GET_TRUCK_TRAFFIC_HISTORY_SUCCESS,
  GET_TRUCK_TRAFFIC_HISTORY_FAIL,
  IGetTruckTrafficHistoryFail,
  IGetTruckTrafficHistorySuccess,
  GET_TRUCK_PLANNING_REVIEW_SERVICES,
  UPDATE_TRUCK_REVIEW,
  GET_TRUCK_TRAFFIC_HISTORY_BY_DATE,
  IGetTruckTrafficHistoryByDateSuccess,
  IGetTruckTrafficHistoryByDateFail,
  GET_TRUCK_TRAFFIC_HISTORY_BY_DATE_SUCCESS,
  GET_TRUCK_TRAFFIC_HISTORY_BY_DATE_FAIL,
  UPLOAD_TRUCK_SERVICE_FILE,
  UPLOAD_TRUCK_SERVICE_FILE_SUCCESS,
  UPLOAD_TRUCK_SERVICE_FILE_FAIL,
  GET_TRUCK_PLANNING_REVIEW_SERVICE,
  DELETE_TRUCK_SERVICE_FILE,
  DELETE_TRUCK_SERVICE_FILE_SUCCESS,
  DELETE_TRUCK_SERVICE_FILE_FAIL,
  GET_TRUCK_LOCATION,
  GET_TRUCK_LOCATION_SUCCESS,
  GET_TRUCK_LOCATION_FAIL,
  IGetTruckLocationActionSuccess,
  IGetTruckLocationActionFail,
  UPLOAD_TRUCK_CLAIM_PHOTO,
  GET_ENGINES,
  UNLOCK_TRUCK_INSPECTION,
  UNLOCK_TRUCK_INSPECTION_FAIL,
  DELETE_CLAIM_PHOTO,
} from './trucksTypes';

function* getEnginesSaga({
  payload: {
    search: { query },
    callback,
  },
}: ReturnType<typeof getEnginesAction>) {
  try {
    const { data } = yield call(() => api.get(`/v1/vehicle_engines?${query}`));

    yield put(getEnginesSuccessAction(data.engines));

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(getEnginesFailAction(e));
  }
}

function* getTrucksSaga({
  payload: { query, usePaginationFlag, preventUpdatePagination },
  callback,
}: ReturnType<typeof getTrucksAction>) {
  try {
    const { per_page, page } = yield select(paginationSelector);
    const { data } = yield call(() =>
      api.get(
        `/v1/fleets/trucks?page=${page}&per_page=${per_page}&stats=1${
          query || ''
        }`,
      ),
    );
    const { pagination } = data;
    yield put(getTrucksSuccessAction({ ...data, preventUpdatePagination }));

    // Pagination set
    if (usePaginationFlag) yield put(setPaginationAction(pagination));

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(getTrucksFailAction(e));
  }
}

function* getTruckSaga({
  id,
  dispatchToStore,
  callback,
}: ReturnType<typeof getTruckAction>) {
  try {
    const { data } = yield call(() => api.get(`/v1/fleets/trucks/${id}`));

    if (dispatchToStore) yield put(getTruckSuccessAction(data));
    if (callback) callback(data);
  } catch (e) {
    if (api.isAxiosError(e)) yield put(getTruckFailAction(e));
  }
}

function* getTruckMaintenanceListSaga({
  payload: {
    data: { id },
    callback,
  },
}: ReturnType<typeof getTruckMaintenanceListAction>) {
  try {
    const { per_page, page } = yield select(paginationSelector);
    const { data } = yield call(() =>
      api.get(
        `/v1/fleets/maintenance/planning_reviews/truck/${id}?page=${page}&per_page=${per_page}`,
      ),
    );
    yield put(getTruckMaintenanceListSuccessAction(data));
    yield put(setPaginationAction(data.pagination));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(getTruckMaintenanceListFailAction(e));
  }
}

function* createTruckSaga({
  payload: { values, callback },
}: ReturnType<typeof createTruckAction>) {
  try {
    yield put(startSubmit('createAndUpdateTruckForm'));
    const { data } = yield call(() =>
      api.post('/v1/fleets/trucks', { ...values }),
    );
    yield put(createTruckSuccessAction());
    yield put(stopSubmit('createAndUpdateTruckForm'));
    callback(data.truck.id);
  } catch (e) {
    if (api.isAxiosError(e)) {
      const errors = getErrors(e);
      const firstErrorKey = Object.keys(errors)[0];
      if (firstErrorKey) {
        scrollToFirstError({ [firstErrorKey]: 'error' });
      }
      yield put(createTruckFailAction(e));
      yield put(stopSubmit('createAndUpdateTruckForm', errors));
    }
  }
}

function* createTruckReviewSaga({
  payload: {
    data: { id, values },
    callback,
  },
}: ReturnType<typeof createTruckReviewAction>) {
  try {
    yield put(startSubmit('createPlanningReviewForm'));
    yield call(() =>
      api.post(`/v1/fleets/maintenance/planning_reviews/truck/${id}`, {
        data: values,
      }),
    );
    yield put(createTruckReviewSuccessAction());
    yield put(stopSubmit('createPlanningReviewForm'));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) {
      yield put(createTruckReviewFailAction(e));
      yield put(stopSubmit('createPlanningReviewForm', getErrors(e)));
    }
  }
}

function* updateTruckReviewSaga({
  payload: {
    data: { id, values },
    callback,
  },
}: ReturnType<typeof updateTruckReviewAction>) {
  try {
    const { data } = yield call(() =>
      api.patch(`/v1/fleets/maintenance/planning_reviews/services/${id}`, {
        data: values,
      }),
    );
    yield put(
      updateTruckReviewSuccessAction({
        data: data.data,
      }),
    );
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(updateTruckReviewFailAction(e));
  }
}

function* updateTruckSaga({
  payload: { callback, values },
}: ReturnType<typeof updateTruckAction>) {
  try {
    yield put(startSubmit('createAndUpdateTruckForm'));
    const { data } = values.truck
      ? yield call(() =>
          api.patch(`/v1/fleets/trucks/${values.truck.id}`, { ...values }),
        )
      : yield call(() =>
          api.patch(`/v1/fleets/trucks/${values.id}`, { truck: values }),
        );
    yield put(updateTruckSuccessAction(data));
    yield put(stopSubmit('createAndUpdateTruckForm'));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) {
      yield put(updateTruckFailAction(e));
      yield put(stopSubmit('createAndUpdateTruckForm', getErrors(e)));
    }
  }
}

function* deleteTruckSaga({
  payload: { id, callback },
}: ReturnType<typeof deleteTruckAction>) {
  try {
    yield call(() => api.delete(`/v1/fleets/trucks/${id}`));
    yield put(deleteTruckSuccessAction());
    yield put(getTrucksAction({}));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(deleteTruckFailAction(e));
  }
}

// UPLOAD TRUCK CLAIM PHOTO
function* uploadTruckClaimPhotoSaga({
  payload: { files },
}: ReturnType<typeof uploadTruckClaimPhotoAction>) {
  const url = `v1/fleets/drivers/claims/issues/files`;
  for (const { file } of files) {
    yield call(function* upload() {
      try {
        const { data } = yield call(() =>
          api.post(
            url,
            fileToFormData(file, 'fleets_drivers_claims_issues_file[file]'),
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            },
          ),
        );
        yield put(uploadTruckClaimPhotoSuccessAction({ file: data.file }));
      } catch (e) {
        if (api.isAxiosError(e)) yield put(uploadTruckClaimPhotoFailAction(e));
      }
    });
  }
}

// DELETE TRUCK CLAIM PHOTO
function* deleteClaimPhotoSaga({
  payload: { fileId, itemId },
}: ReturnType<typeof deleteClaimPhotoAction>) {
  try {
    const url = itemId
      ? `/v1/fleets/drivers/claims/${itemId}/issues/${itemId}/files/${fileId}`
      : `/v1/fleets/drivers/claims/issues/files/${fileId}`;
    yield call(() => api.delete(url));
    yield put(
      deleteClaimPhotoSuccessAction({
        fileId,
        itemId: itemId ? Number(itemId) : undefined,
      }),
    );
  } catch (e) {
    if (api.isAxiosError(e)) yield put(deleteClaimPhotoFailAction(e));
  }
}

// TRUCK FILES
function* uploadTruckFilesSaga({
  payload: { files, itemId: truckId },
}: ReturnType<typeof uploadTruckFilesAction>) {
  const url = truckId
    ? `/v1/fleets/trucks/${truckId}/files`
    : `/v1/fleets/trucks/files`;
  for (const fileObj of files) {
    yield call(function* processFiles() {
      try {
        const { data } = yield call(() =>
          api.post(
            url,
            fileToFormData(fileObj.file, `fleets_trucks_file[file]`),
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            },
          ),
        );
        yield put({
          type: UPLOAD_TRUCK_FILES_SUCCESS,
          payload: { ...data.file, justCreated: true },
        });
      } catch (e) {
        yield put({ type: UPLOAD_TRUCK_FILES_FAIL, payload: e });
      }
    });
  }
}

function* deleteTruckFileSaga({
  payload: { fileId, itemId: truckId },
}: ReturnType<typeof deleteTruckFileAction>) {
  const url = truckId
    ? `/v1/fleets/trucks/${truckId}/files/${fileId}`
    : `/v1/fleets/trucks/files/${fileId}`;

  try {
    yield call(() => api.delete(url));
    yield put({ type: DELETE_TRUCK_FILE_SUCCESS, payload: fileId });
  } catch (e) {
    yield put({ type: DELETE_TRUCK_FILE_FAIL, payload: e });
  }
}

function* getTrackingTrucksActionSaga({
  payload: { vinNumbers, callback },
}: ReturnType<typeof getTrackingTrucksAction>) {
  try {
    const { data } = yield call(() =>
      apiTrucksTracking.post('/v1/trucks', { vins: vinNumbers.join(',') }),
    );
    yield put({ type: GET_TRACKING_TRUCKS_SUCCESS, payload: data });
    if (callback) callback();
  } catch (e) {
    yield put({ type: GET_TRACKING_TRUCKS_FAIL, payload: e });
  }
}

function* getTrackingTruckLocationSaga({
  payload: {
    data: { truckVin },
    callback,
  },
}: ReturnType<typeof getTruckLocationAction>) {
  try {
    const { data } = yield call(() =>
      apiTrucksTracking.get(`/v1/trucks/${truckVin}`),
    );
    yield put<IGetTruckLocationActionSuccess>({
      type: GET_TRUCK_LOCATION_SUCCESS,
      payload: data,
    });
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetTruckLocationActionFail>({
        type: GET_TRUCK_LOCATION_FAIL,
        payload: e,
      });
  }
}

function* getTruckLogsActionSaga({
  payload: { id, callback },
}: ReturnType<typeof getTruckLogsAction>) {
  try {
    const { per_page, page } = yield select(paginationSelector);

    const { data } = yield call(() =>
      api.get(`/v1/fleets/trucks/${id}/logs?page=${page}&per_page=${per_page}`),
    );

    yield put({ type: GET_TRUCK_LOGS_SUCCESS, payload: data });

    // Pagination set
    const { pagination } = data;
    yield put(setPagesAction(pagination && pagination.pages));

    if (callback) callback();
  } catch (e) {
    yield put({ type: GET_TRUCK_LOGS_FAIL, payload: e });
  }
}

function* setTruckPinSaga({
  payload: {
    data: { id },
    callback,
  },
}: ReturnType<typeof setTruckPinAction>) {
  try {
    yield call(() => api.post(`/v1/fleets/trucks/${id}/pin`));
    if (callback) callback();
    yield put(setTruckPinSuccessAction(id));
    // yield put(getTrucksAction({}));
  } catch (e) {
    if (api.isAxiosError(e)) yield put(setTruckPinFailAction(e));
  }
}

function* setTruckUnPinSaga({
  payload: {
    data: { id },
    callback,
  },
}: ReturnType<typeof setTruckUnPinAction>) {
  try {
    yield call(() => api.delete(`/v1/fleets/trucks/${id}/pin`));
    // yield put(getTrucksAction({}));
    yield put(setTruckUnPinSuccessAction(id));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(setTruckUnPinFailAction(e));
  }
}

function* getTruckTrafficHistorySaga({
  payload: {
    callback,
    data: { from, truckVin, period },
  },
}: ReturnType<typeof getTruckTrafficHistoryAction>) {
  try {
    // Get current user timezone
    const tz = new Date().getTimezoneOffset() / 60;
    const url = period
      ? `/v1/trucks/${truckVin}/route?date=${from}&period=${period}&tz=${tz}`
      : `/v1/trucks/${truckVin}/route?date=${from}&tz=${tz}`;
    const { data } = yield call(() => apiTrucksTracking.get(url));
    yield put<IGetTruckTrafficHistorySuccess>({
      type: GET_TRUCK_TRAFFIC_HISTORY_SUCCESS,
      payload: data,
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetTruckTrafficHistoryFail>({
        type: GET_TRUCK_TRAFFIC_HISTORY_FAIL,
        payload: e,
      });
  }
}

// ToDo realize if you need this
function* getTruckTrafficHistoryByDateSaga({
  payload: {
    callback,
    data: { date, truckVin },
  },
}: ReturnType<typeof getTruckTrafficHistoryByDateAction>) {
  try {
    const { data } = yield call(() =>
      apiTrucksTracking.get(`/v1/trucks/${truckVin}/route`, {
        params: { date },
      }),
    );

    const preparedTruckingData = data.filter(
      (_item: never, index: number) => index % 20 === 0,
    );

    yield put<IGetTruckTrafficHistoryByDateSuccess>({
      type: GET_TRUCK_TRAFFIC_HISTORY_BY_DATE_SUCCESS,
      payload: preparedTruckingData,
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetTruckTrafficHistoryByDateFail>({
        type: GET_TRUCK_TRAFFIC_HISTORY_BY_DATE_FAIL,
        payload: e,
      });
  }
}

function* getTruckReviewServicesSaga({
  payload: {
    data: { id },
    callback,
  },
}: ReturnType<typeof getTruckReviewServicesAction>) {
  try {
    const { data } = yield call(() =>
      api.get(`/v1/fleets/maintenance/planning_reviews/${id}/services`),
    );
    yield put(getTruckReviewServicesSuccessAction(data));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(getTruckReviewServicesFailAction(e));
  }
}

function* getTruckReviewServiceSaga({
  payload: {
    data: { id },
    callback,
  },
}: ReturnType<typeof getTruckReviewServiceAction>) {
  try {
    const { data } = yield call(() =>
      api.get(`/v1/fleets/maintenance/planning_reviews/services/${id}`),
    );
    yield put(getTruckReviewServiceSuccessAction(data));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(getTruckReviewServiceFailAction(e));
  }
}

function* uploadTruckServiceFilesSaga({
  payload: { itemId: serviceId, files },
}: ReturnType<typeof uploadTruckServiceFileAction>) {
  const url = `/v1/fleets/maintenance/planning_reviews/services/${serviceId}/files`;
  for (const fileObj of files) {
    yield call(function* foo() {
      try {
        const { file } = fileObj;
        const { data } = yield call(() =>
          api.post(url, truckServiceFileToFormData({ file }), {
            headers: { 'Content-Type': 'multipart/form-data' },
          }),
        );
        yield put({
          type: UPLOAD_TRUCK_SERVICE_FILE_SUCCESS,
          payload: data.file,
        });
      } catch (e) {
        yield put({ type: UPLOAD_TRUCK_SERVICE_FILE_FAIL, payload: e });
      }
    });
  }
}

function* deleteTruckServiceFileSaga({
  payload: { fileId, itemId: reviewId },
}: ReturnType<typeof deleteTruckServiceFileAction>) {
  const url = `/v1/fleets/maintenance/planning_reviews/services/${reviewId}/files/${fileId}`;
  try {
    yield call(() => api.delete(url));
    yield put({ type: DELETE_TRUCK_SERVICE_FILE_SUCCESS, payload: fileId });
  } catch (e) {
    yield put({ type: DELETE_TRUCK_SERVICE_FILE_FAIL, payload: e });
  }
}

function* unlockTruckInspectionSaga({
  payload: { truckId, callback },
}: ReturnType<typeof unlockTruckInspectionAction>) {
  const url = `/v1/fleets/trucks/${truckId}/inspections/drafts/unlock`;
  try {
    yield call(() => api.post(url));
    if (callback) callback();
  } catch (e) {
    yield put({ type: UNLOCK_TRUCK_INSPECTION_FAIL, payload: e });
  }
}

export default function* trucksSaga() {
  yield takeLatest(GET_TRUCKS, getTrucksSaga);
  yield takeLeading(GET_ENGINES, getEnginesSaga);
  yield takeEvery(GET_TRUCK, getTruckSaga);
  yield takeLatest(GET_TRUCK_MAINTENANCE_LIST, getTruckMaintenanceListSaga);
  yield takeLatest(UPDATE_TRUCK, updateTruckSaga);
  yield takeLatest(DELETE_TRUCK, deleteTruckSaga);
  yield takeLeading(CREATE_TRUCK, createTruckSaga);
  yield takeLeading(UPLOAD_TRUCK_FILES, uploadTruckFilesSaga);
  yield takeLeading(UPLOAD_TRUCK_SERVICE_FILE, uploadTruckServiceFilesSaga);
  yield takeEvery(DELETE_TRUCK_FILE, deleteTruckFileSaga);
  yield takeLeading(GET_TRACKING_TRUCKS, getTrackingTrucksActionSaga);
  yield takeLeading(GET_TRUCK_LOGS, getTruckLogsActionSaga);
  yield takeLeading(UPLOAD_TRUCK_CLAIM_PHOTO, uploadTruckClaimPhotoSaga);
  yield takeLeading(DELETE_CLAIM_PHOTO, deleteClaimPhotoSaga);
  yield takeLeading(CREATE_TRUCK_PLANNING_REVIEW, createTruckReviewSaga);
  yield takeLeading(UPDATE_TRUCK_REVIEW, updateTruckReviewSaga);
  yield takeLeading(SET_PIN_TRUCK_MAP_VIEW, setTruckPinSaga);
  yield takeLeading(SET_UNPIN_TRUCK_MAP_VIEW, setTruckUnPinSaga);
  yield takeLeading(GET_TRUCK_TRAFFIC_HISTORY, getTruckTrafficHistorySaga);
  yield takeLeading(
    GET_TRUCK_TRAFFIC_HISTORY_BY_DATE,
    getTruckTrafficHistoryByDateSaga,
  );
  yield takeLeading(
    GET_TRUCK_PLANNING_REVIEW_SERVICES,
    getTruckReviewServicesSaga,
  );
  yield takeLeading(
    GET_TRUCK_PLANNING_REVIEW_SERVICE,
    getTruckReviewServiceSaga,
  );
  yield takeEvery(DELETE_TRUCK_SERVICE_FILE, deleteTruckServiceFileSaga);
  yield takeLatest(GET_TRUCK_LOCATION, getTrackingTruckLocationSaga);
  yield takeLatest(UNLOCK_TRUCK_INSPECTION, unlockTruckInspectionSaga);
}
