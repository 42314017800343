import { AxiosError } from 'axios';

import { ErrorsUnitType } from 'components/Fleet/TabInspections/_common/InspectionInventory/_models/inspectionInventoryModels';

import {
  ChangedUnitResponse,
  IItem,
  SnackBarReasons,
} from '../_models/itemsModels';
import {
  ADD_UNITS,
  ADD_UNITS_FAIL,
  ADD_UNITS_SUCCESS,
  ASSIGN_EMPLOYEE_UNIT,
  ASSIGN_EMPLOYEE_UNIT_FAIL,
  ASSIGN_EMPLOYEE_UNIT_SUCCESS,
  ASSIGN_INVENTORY_UNIT,
  ASSIGN_INVENTORY_UNIT_FAIL,
  ASSIGN_INVENTORY_UNIT_SUCCESS,
  ASSIGN_LEASE,
  ASSIGN_SELL,
  CHANGE_STATUS_UNIT,
  CHANGE_STATUS_UNIT_FAIL,
  CHANGE_STATUS_UNIT_SUCCESS,
  CREATE_ITEM,
  CREATE_ITEM_FAIL,
  CREATE_ITEM_SUCCESS,
  DELETE_ITEM,
  DELETE_ITEM_FAIL,
  DELETE_ITEM_INVOICE_FILE,
  DELETE_ITEM_INVOICE_FILE_FAIL,
  DELETE_ITEM_INVOICE_FILE_SUCCESS,
  DELETE_ITEM_PHOTO,
  DELETE_ITEM_PHOTO_FAIL,
  DELETE_ITEM_PHOTO_SUCCESS,
  DELETE_ITEM_SUCCESS,
  GET_ALL_INVOICES,
  GET_ALL_INVOICES_SUCCESS,
  GET_ITEM,
  GET_ITEM_ACTIVITY,
  GET_ITEM_ACTIVITY_SUCCESS,
  GET_ITEM_FAIL,
  GET_ITEM_INVOICES,
  GET_ITEM_INVOICES_FAIL,
  GET_ITEM_INVOICES_SUCCESS,
  GET_ITEM_SUCCESS,
  GET_ITEM_WRITE_OFF_EMPLOYEES,
  GET_ITEM_WRITE_OFF_EMPLOYEES_SUCCESS,
  IAddItemsAction,
  IAddItemsFailAction,
  IAddItemsSuccessAction,
  IAddUnitsAction,
  IAddUnitsFailAction,
  IAddUnitsSuccessAction,
  IAssignInventoryUnit,
  IDeleteItem,
  IDeleteItemFailAction,
  IDeleteItemInvoiceFileAction,
  IDeleteItemInvoiceFileFailAction,
  IDeleteItemInvoiceFileSuccessAction,
  IDeleteItemPhotoAction,
  IDeleteItemPhotoFailAction,
  IDeleteItemPhotoSuccessAction,
  IDeleteItemSuccessAction,
  IGetAllInvoicesAction,
  IGetAllInvoicesActionSuccess,
  IGetItemActivity,
  IGetItemActivityFail,
  IGetItemActivitySuccess,
  IGetItemInvoicesAction,
  IGetItemInvoicesFailAction,
  IGetItemInvoicesSuccessAction,
  IGetItemWriteOffEmployees,
  IGetItemWriteOffEmployeesSuccess,
  IResetItemsCreateFormsAction,
  ISetItemFiles,
  ISetItemsInvoiceFileAction,
  IUpdateItemAction,
  IUpdateItemFailAction,
  IUpdateItemSuccessAction,
  IUploadItemInvoiceFileAction,
  IUploadItemInvoiceFileFailAction,
  IUploadItemInvoiceFileSuccessAction,
  IUploadItemPhotoAction,
  IUploadItemPhotoFailAction,
  IUploadItemPhotoSuccessAction,
  LEASE_SELL_INVENTORY_UNIT,
  LEASE_SELL_INVENTORY_UNIT_FAIL,
  LEASE_SELL_INVENTORY_UNIT_SUCCESS,
  REMOVE_EMPLOYEE_UNIT,
  REMOVE_EMPLOYEE_UNIT_FAIL,
  REMOVE_EMPLOYEE_UNIT_SUCCESS,
  RESET_ITEMS_CREATE_FORM,
  SET_ITEM_FILES,
  SET_ITEM_INVOICE_FILE,
  UNASSIGN_UNIT,
  UNASSIGN_UNIT_FAIL,
  UNASSIGN_UNIT_SUCCESS,
  UNDO_CHANGE_STATUS_UNIT,
  UPDATE_ITEM,
  UPDATE_ITEM_FAIL,
  UPDATE_ITEM_SUCCESS,
  UPLOAD_ITEM_INVOICE_FILE,
  UPLOAD_ITEM_INVOICE_FILE_FAIL,
  UPLOAD_ITEM_INVOICE_FILE_SUCCESS,
  UPLOAD_ITEM_PHOTO,
  UPLOAD_ITEM_PHOTO_FAIL,
  UPLOAD_ITEM_PHOTO_SUCCESS,
} from './itemsTypes';

// CREATE_ITEM
export const addItemsAction = (
  payload: IAddItemsAction['payload'],
): IAddItemsAction => ({
  type: CREATE_ITEM,
  payload,
});
export const addItemsSuccessAction = (
  payload: IAddItemsSuccessAction['payload'],
): IAddItemsSuccessAction => ({
  type: CREATE_ITEM_SUCCESS,
  payload,
});
export const addItemsFailAction = (
  payload: IAddItemsFailAction['payload'],
): IAddItemsFailAction => ({
  type: CREATE_ITEM_FAIL,
  payload,
});

// UPDATE_ITEM
export const updateItemsAction = (
  payload: IUpdateItemAction['payload'],
): IUpdateItemAction => ({
  type: UPDATE_ITEM,
  payload,
});
export const updateItemsSuccessAction = (): IUpdateItemSuccessAction => ({
  type: UPDATE_ITEM_SUCCESS,
});
export const updateItemsFailAction = (
  payload: IUpdateItemFailAction['payload'],
): IUpdateItemFailAction => ({
  type: UPDATE_ITEM_FAIL,
  payload,
});
// DELETE_ITEM
export const deleteItemsAction = (
  payload: IDeleteItem['payload'],
): IDeleteItem => ({
  type: DELETE_ITEM,
  payload,
});
export const deleteItemsSuccessAction = (): IDeleteItemSuccessAction => ({
  type: DELETE_ITEM_SUCCESS,
});
export const deleteItemsFailAction = (
  payload: IDeleteItemFailAction['payload'],
): IDeleteItemFailAction => ({
  type: DELETE_ITEM_FAIL,
  payload,
});
// ADD_UNITS
export const addUnitsAction = (
  payload: IAddUnitsAction['payload'],
): IAddUnitsAction => ({
  type: ADD_UNITS,
  payload,
});
export const addUnitsSuccessAction = (
  payload: IAddUnitsSuccessAction['payload'],
): IAddUnitsSuccessAction => ({
  type: ADD_UNITS_SUCCESS,
  payload,
});
export const addUnitsFailAction = (
  payload: IAddUnitsFailAction['payload'],
): IAddUnitsFailAction => ({
  type: ADD_UNITS_FAIL,
  payload,
});

// LEASE UNIT ITEMS
export const leaseSellInventoryUnitAction = (
  operation: 'lease' | 'sell',
  leaseToId: number,
  formData: IAssignInventoryUnit['payload']['formData'],
  callback?: (data?: ChangedUnitResponse) => void,
  snackbarCallback?: (
    key: SnackBarReasons,
  ) => (data?: ChangedUnitResponse) => void,
  errorCallback?: (errorUnits?: ErrorsUnitType[]) => void,
) => ({
  type: LEASE_SELL_INVENTORY_UNIT,
  payload: {
    leaseToId,
    operation,
    formData,
    callback,
    snackbarCallback,
    errorCallback,
  },
});

export const leaseSellUnitSuccessAction = () => ({
  type: LEASE_SELL_INVENTORY_UNIT_SUCCESS,
});

export const leaseSellUnitFailAction = (error: AxiosError) => ({
  type: LEASE_SELL_INVENTORY_UNIT_FAIL,
  payload: error,
});

// ASSIGN UNIT ITEMS
export const assignInventoryUnitAction = (
  assignTo: IAssignInventoryUnit['payload']['assignTo'],
  assignToId: number,
  formData: IAssignInventoryUnit['payload']['formData'],
  callback?: (data?: ChangedUnitResponse) => void,
  errorCallback?: (errorUnits?: ErrorsUnitType[]) => void,
) => ({
  type: ASSIGN_INVENTORY_UNIT,
  payload: { assignTo, assignToId, formData, callback, errorCallback },
});

export const assignSellToDriverAction = (
  assignToId: number,
  formData: IAssignInventoryUnit['payload']['formData'],
  callback?: (data?: ChangedUnitResponse) => void,
  errorCallback?: (errorUnits?: ErrorsUnitType[]) => void,
) => ({
  type: ASSIGN_SELL,
  payload: { assignToId, formData, callback, errorCallback },
});

export const assignLeaseToDriverAction = (
  assignToId: number,
  formData: IAssignInventoryUnit['payload']['formData'],
  callback?: (data?: ChangedUnitResponse) => void,
  errorCallback?: (errorUnits?: ErrorsUnitType[]) => void,
) => ({
  type: ASSIGN_LEASE,
  payload: { assignToId, formData, callback, errorCallback },
});

export const assignDriverUnitSuccessAction = () => ({
  type: ASSIGN_INVENTORY_UNIT_SUCCESS,
});

export const assignDriverUnitFailAction = (error: AxiosError) => ({
  type: ASSIGN_INVENTORY_UNIT_FAIL,
  payload: error,
});

// ASSIGN DRIVER UNIT
export const assignEmployeeUnitAction = (
  itemId: number,
  unitId: number,
  employeeId: number,
  callback?: () => void,
) => ({
  type: ASSIGN_EMPLOYEE_UNIT,
  payload: { itemId, unitId, employeeId, callback },
});

export const assignEmployeeUnitSuccessAction = () => ({
  type: ASSIGN_EMPLOYEE_UNIT_SUCCESS,
});

export const assignEmployeeUnitFailAction = (error: AxiosError) => ({
  type: ASSIGN_EMPLOYEE_UNIT_FAIL,
  payload: error,
});

// REMOVE DRIVER UNIT
export const unassignUnitAction = (
  itemId: number,
  unitIds: number[],
  callback?: (data?: ChangedUnitResponse) => void,
) => ({
  type: UNASSIGN_UNIT,
  payload: {
    itemId,
    unitIds,
    callback,
  },
});

export const unassignUnitSuccessAction = () => ({
  type: UNASSIGN_UNIT_SUCCESS,
});

export const unassignUnitFailAction = (error: AxiosError) => ({
  type: UNASSIGN_UNIT_FAIL,
  payload: error,
});

// REMOVE EMPLOYEE UNIT
export const removeEmployeeUnitAction = (
  itemId: number,
  unitId: number,
  employeeId: number,
  callback?: () => void,
) => ({
  type: REMOVE_EMPLOYEE_UNIT,
  payload: { itemId, unitId, employeeId, callback },
});

export const removeEmployeeUnitSuccessAction = () => ({
  type: REMOVE_EMPLOYEE_UNIT_SUCCESS,
});

export const removeEmployeeUnitFailAction = (error: AxiosError) => ({
  type: REMOVE_EMPLOYEE_UNIT_FAIL,
  payload: error,
});

// CHANGE UNIT STATUS BY ID
export const changeUnitStatusAction = (
  itemId: number,
  dataToSend: {
    units_ids: number[];
    unavailable_reason?: string;
    reason?: string;
    comment?: string;
  },
  newStatus: string,
  callback?: (data: ChangedUnitResponse) => void,
) => ({
  type: CHANGE_STATUS_UNIT,
  payload: { itemId, dataToSend, newStatus, callback },
});

export const changeUnitStatusSuccessAction = () => ({
  type: CHANGE_STATUS_UNIT_SUCCESS,
});

export const changeUnitStatusFailAction = (error: AxiosError | null) => ({
  type: CHANGE_STATUS_UNIT_FAIL,
  payload: error,
});

export const undoUnitStatusAction = (
  itemId: number,
  dataToSend: { transaction_uid: string },
  callback?: () => void,
) => ({
  type: UNDO_CHANGE_STATUS_UNIT,
  payload: { itemId, dataToSend, callback },
});

// GET_ITEM by ID
export const getItemAction = (id: string | number) => ({
  type: GET_ITEM,
  id,
});

export const getItemSuccessAction = (item: IItem) => ({
  type: GET_ITEM_SUCCESS,
  payload: item,
});

export const getItemFailAction = (error: AxiosError) => ({
  type: GET_ITEM_FAIL,
  payload: error,
});

// GET ITEM ACTIVITY by ID
export const getItemActivityAction = (
  payload: IGetItemActivity['payload'],
) => ({
  type: GET_ITEM_ACTIVITY,
  payload,
});

export const getItemActivitySuccessAction = (
  payload: IGetItemActivitySuccess['payload'],
) => ({
  type: GET_ITEM_ACTIVITY_SUCCESS,
  payload,
});

export const getItemActivityFailAction = (
  payload: IGetItemActivityFail['payload'],
) => ({
  type: GET_ITEM_FAIL,
  payload,
});

// UPLOAD PHOTO
export const uploadItemPhotoAction = (
  payload: IUploadItemPhotoAction['payload'],
): IUploadItemPhotoAction => ({
  type: UPLOAD_ITEM_PHOTO,
  payload,
});

export const uploadItemPhotoSuccessAction = (
  payload: IUploadItemPhotoSuccessAction['payload'],
): IUploadItemPhotoSuccessAction => ({
  type: UPLOAD_ITEM_PHOTO_SUCCESS,
  payload,
});

export const uploadItemPhotoFailAction = (
  payload: IUploadItemPhotoFailAction['payload'],
): IUploadItemPhotoFailAction => ({
  type: UPLOAD_ITEM_PHOTO_FAIL,
  payload,
});

// DELETE PHOTO
export const deleteItemPhotoAction = (
  payload: IDeleteItemPhotoAction['payload'],
): IDeleteItemPhotoAction => ({
  type: DELETE_ITEM_PHOTO,
  payload,
});

export const deleteItemPhotoSuccessAction = (
  payload: IDeleteItemPhotoSuccessAction['payload'],
): IDeleteItemPhotoSuccessAction => ({
  type: DELETE_ITEM_PHOTO_SUCCESS,
  payload,
});

export const deleteItemPhotoFailAction = (
  payload: IDeleteItemPhotoFailAction['payload'],
): IDeleteItemPhotoFailAction => ({
  type: DELETE_ITEM_PHOTO_FAIL,
  payload,
});

// UPLOAD INVOICE FILE
export const uploadItemInvoiceFileAction = (
  payload: IUploadItemInvoiceFileAction['payload'],
): IUploadItemInvoiceFileAction => ({
  type: UPLOAD_ITEM_INVOICE_FILE,
  payload,
});

export const uploadItemInvoiceFileSuccessAction = (
  payload: IUploadItemInvoiceFileSuccessAction['payload'],
): IUploadItemInvoiceFileSuccessAction => ({
  type: UPLOAD_ITEM_INVOICE_FILE_SUCCESS,
  payload,
});

export const uploadItemInvoiceFileFailAction = (
  payload: IUploadItemInvoiceFileFailAction['payload'],
): IUploadItemInvoiceFileFailAction => ({
  type: UPLOAD_ITEM_INVOICE_FILE_FAIL,
  payload,
});

// DELETE INVOICE FILE
export const deleteItemInvoiceFileAction = (
  payload: IDeleteItemInvoiceFileAction['payload'],
): IDeleteItemInvoiceFileAction => ({
  type: DELETE_ITEM_INVOICE_FILE,
  payload,
});

export const deleteItemInvoiceFileSuccessAction = (
  payload: IDeleteItemInvoiceFileSuccessAction['payload'],
): IDeleteItemInvoiceFileSuccessAction => ({
  type: DELETE_ITEM_INVOICE_FILE_SUCCESS,
  payload,
});

export const deleteItemInvoiceFileFailAction = (
  payload: IDeleteItemInvoiceFileFailAction['payload'],
): IDeleteItemInvoiceFileFailAction => ({
  type: DELETE_ITEM_INVOICE_FILE_FAIL,
  payload,
});

// GET INVOICES
export const getItemInvoicesAction = (
  payload?: IGetItemInvoicesAction['payload'],
): IGetItemInvoicesAction => ({
  type: GET_ITEM_INVOICES,
  payload,
});

export const getItemInvoicesSuccessAction = (
  payload: IGetItemInvoicesSuccessAction['payload'],
): IGetItemInvoicesSuccessAction => ({
  type: GET_ITEM_INVOICES_SUCCESS,
  payload,
});

export const getItemInvoicesFailAction = (
  payload: IGetItemInvoicesFailAction['payload'],
): IGetItemInvoicesFailAction => ({
  type: GET_ITEM_INVOICES_FAIL,
  payload,
});

export const getAllInvoicesAction = (): IGetAllInvoicesAction => ({
  type: GET_ALL_INVOICES,
});

export const getAllInvoicesActionSuccess = (
  payload: IGetAllInvoicesActionSuccess['payload'],
): IGetAllInvoicesActionSuccess => ({
  type: GET_ALL_INVOICES_SUCCESS,
  payload,
});

// GET INVOICE FILE BY ID
export const setItemsCategoriesInvoiceFileAction = (
  payload: ISetItemsInvoiceFileAction['payload'],
): ISetItemsInvoiceFileAction => ({
  type: SET_ITEM_INVOICE_FILE,
  payload,
});

export const getItemWriteOffEmployees = (
  payload?: IGetItemWriteOffEmployees['payload'],
): IGetItemWriteOffEmployees => ({
  type: GET_ITEM_WRITE_OFF_EMPLOYEES,
  payload,
});

export const getItemWriteOffEmployeesSuccess = (
  payload: IGetItemWriteOffEmployeesSuccess['payload'],
): IGetItemWriteOffEmployeesSuccess => ({
  type: GET_ITEM_WRITE_OFF_EMPLOYEES_SUCCESS,
  payload,
});

// RESET MAIN FORM
export const resetItemsCreateFormAction = (): IResetItemsCreateFormsAction => ({
  type: RESET_ITEMS_CREATE_FORM,
});

// RESET MAIN FORM
export const setItemFilesAction = (
  payload: ISetItemFiles['payload'],
): ISetItemFiles => ({
  type: SET_ITEM_FILES,
  payload,
});
