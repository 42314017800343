import {
  takeLeading,
  takeEvery,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import api from 'utils/requests';

import { fileToFormData } from '../../../utils/converters';
import {
  setFleetLabelAction,
  deleteFleetLabelAction,
  assignVehiclesAction,
  uploadWorkOrderFilesAction,
} from './fleetGroupActions';
import {
  SET_FLEET_LABEL,
  DELETE_FLEET_LABEL,
  SET_FLEET_LABEL_SUCCESS,
  SET_FLEET_LABEL_FAIL,
  DELETE_FLEET_LABEL_SUCCESS,
  DELETE_FLEET_LABEL_FAIL,
  ASSIGN_VEHICLES,
  ASSIGN_VEHICLES_SUCCESS,
  ASSIGN_VEHICLES_FAIL,
  ISetLabelActionSuccess,
  ISetLabelActionFail,
  IDeleteLabelActionSuccess,
  IDeleteLabelActionFail,
  IAssignVehiclesActionSuccess,
  IAssignVehiclesActionFail,
  IUploadWorkOrderFilesActionSuccess,
  UPLOAD_WORK_ORDER_FILES_SUCCESS,
  UPLOAD_WORK_ORDER_FILES_FAIL,
  UPLOAD_WORK_ORDER_FILES,
} from './fleetGroupTypes';

// LABELS
function* setLabelActionSaga({
  payload: { data, callback },
}: ReturnType<typeof setFleetLabelAction>) {
  const { id, endPoint, labelKey, values } = data;
  const url = `/v1/fleets/${endPoint}/${id}/labels/${labelKey}`;
  try {
    yield call(() =>
      api.post(url, {
        [`fleets_${endPoint}_label`]: values,
      }),
    );
    yield put<ISetLabelActionSuccess>({ type: SET_FLEET_LABEL_SUCCESS });
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<ISetLabelActionFail>({
        type: SET_FLEET_LABEL_FAIL,
        payload: e,
      });
  }
}

function* deleteLabelActionSaga({
  payload: { data, callback },
}: ReturnType<typeof deleteFleetLabelAction>) {
  const { id, endPoint, labelKey } = data;
  const url = `/v1/fleets/${endPoint}/${id}/labels/${labelKey}`;

  try {
    yield call(() => api.delete(url));
    yield put<IDeleteLabelActionSuccess>({ type: DELETE_FLEET_LABEL_SUCCESS });
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IDeleteLabelActionFail>({
        type: DELETE_FLEET_LABEL_FAIL,
        payload: e,
      });
  }
}

function* assignVehiclesSaga({
  data,
  callback,
}: ReturnType<typeof assignVehiclesAction>) {
  try {
    yield call(() => api.post('/v1/fleets/connections', data));
    yield put<IAssignVehiclesActionSuccess>({ type: ASSIGN_VEHICLES_SUCCESS });
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IAssignVehiclesActionFail>({
        type: ASSIGN_VEHICLES_FAIL,
        payload: e,
      });
  }
}

interface IWorkOrderFile {
  id: number;
  file_name: string;
  file_size: number;
  content_type: string;
  urls: {
    original: string;
    thumb: string | null;
  };
  manager: {
    id: number;
    full_name: string;
  };
  created_at: string;
  file_url: string;
}

interface IApiResponse {
  fleets_workshops_work_orders_invoices_file: IWorkOrderFile;
}

function* uploadWorkOrderFilesSaga({
  payload: { files, itemId },
}: ReturnType<typeof uploadWorkOrderFilesAction>) {
  const url = itemId
    ? `/v1/fleets/workshops/work_orders/invoices/files/${itemId}`
    : '/v1/fleets/workshops/work_orders/invoices/files';
  for (const fileObj of files) {
    // eslint-disable-next-line func-names
    yield call(function* () {
      try {
        const { data }: { data: IApiResponse } = yield call(() =>
          api.post(
            url,
            fileToFormData(
              fileObj.file,
              `fleets_workshops_work_orders_invoice_file[file]`,
            ),
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          ),
        );
        yield put<IUploadWorkOrderFilesActionSuccess>({
          type: UPLOAD_WORK_ORDER_FILES_SUCCESS,
          payload: data.fleets_workshops_work_orders_invoices_file,
        });
      } catch (e) {
        yield put({ type: UPLOAD_WORK_ORDER_FILES_FAIL, payload: e });
      }
    });
  }
}

export default function* fleetsSagas() {
  yield takeLeading(SET_FLEET_LABEL, setLabelActionSaga);
  yield takeLeading(DELETE_FLEET_LABEL, deleteLabelActionSaga);
  yield takeEvery(ASSIGN_VEHICLES, assignVehiclesSaga);
  yield takeLatest(UPLOAD_WORK_ORDER_FILES, uploadWorkOrderFilesSaga);
}
