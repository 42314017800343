import { isEmpty } from 'ramda';
import { takeLatest, call, put } from 'redux-saga/effects';

import { setPaginationAction } from 'components/_common/Navigation/Pagination/_redux/paginationActions';
import {
  WEEK_PAYROLLS_API,
  GET_DRIVER_PAYROLLS_API,
  PATCH_DRIVER_PAYROLLS_API,
  PAYROLL_DRIVERS_API_PREFIX,
  DRIVER_PAYROLL_ITEMS_API,
  DRIVER_PAYROLL_ITEM_COMMENTS_API,
  DRIVERS_PAYROLLS_ITEMS_API,
  DRIVER_PAYROLLS_OUT_OF_RANGE_ORDERS_API,
} from 'constants/api';
import api from 'utils/requests';

import { IOutOfRangePayrollItem } from '../_models/payrollsModels';
import {
  getPayrollsAction,
  getDriverPayrollsAction,
  patchDriverPayrollsAction,
  patchDriverPayrollItemAction,
  getDriversAction,
  getPayrollItemCommentsAction,
  getDriversPayrollsItemsAction,
  getPayrollOutOfRangeItemsAction,
  addPayrollOutOfRangeItemsAction,
  updateDriverPayrollStatusAction,
} from './payrollsActions';
import {
  GET_PAYROLLS,
  GET_PAYROLLS_SUCCESS,
  GET_PAYROLLS_FAIL,
  IGetPayrollsActionSuccess,
  IGetPayrollsActionFail,
  GET_DRIVER_PAYROLLS,
  GET_DRIVER_PAYROLLS_SUCCESS,
  GET_DRIVER_PAYROLLS_FAIL,
  IGetDriverPayrollsActionSuccess,
  IGetDriverPayrollsActionFail,
  PATCH_DRIVER_PAYROLLS,
  PATCH_DRIVER_PAYROLLS_SUCCESS,
  PATCH_DRIVER_PAYROLLS_FAIL,
  IPatchDriverPayrollsActionSuccess,
  IPatchDriverPayrollsActionFail,
  PATCH_DRIVER_PAYROLL_ITEM,
  PATCH_DRIVER_PAYROLL_ITEM_SUCCESS,
  PATCH_DRIVER_PAYROLL_ITEM_FAIL,
  IPatchDriverPayrollItemActionSuccess,
  IPatchDriverPayrollItemActionFail,
  GET_DRIVERS,
  GET_DRIVERS_SUCCESS,
  GET_DRIVERS_FAIL,
  IGetDriversActionSuccess,
  IGetDriversActionFail,
  GET_PAYROLL_ITEM_COMMENTS,
  GET_PAYROLL_ITEM_COMMENTS_FAIL,
  GET_PAYROLL_ITEM_COMMENTS_SUCCESS,
  IGetPayrollItemCommentsActionSuccess,
  IGetPayrollItemCommentsActionFail,
  GET_DRIVERS_PAYROLLS_ITEMS,
  GET_DRIVERS_PAYROLLS_ITEMS_SUCCESS,
  GET_DRIVERS_PAYROLLS_ITEMS_FAIL,
  IGetDriversPayrollsItemsActionSuccess,
  IGetDriversPayrollsItemsActionFail,
  GET_PAYROLL_OUT_OF_RANGE_ITEMS,
  GET_PAYROLL_OUT_OF_RANGE_ITEMS_SUCCESS,
  GET_PAYROLL_OUT_OF_RANGE_ITEMS_FAIL,
  IGetPayrollOutOfRangeItemsActionSuccess,
  IGetPayrollOutOfRangeItemsActionFail,
  ADD_PAYROLL_OUT_OF_RANGE_ITEMS,
  ADD_PAYROLL_OUT_OF_RANGE_ITEMS_SUCCESS,
  ADD_PAYROLL_OUT_OF_RANGE_ITEMS_FAIL,
  IAddPayrollOutOfRangeItemsActionSuccess,
  IAddPayrollOutOfRangeItemsActionFail,
  UPDATE_DRIVER_PAYROLL_STATUS,
  IUpdateDriverPayrollStatusSuccess,
  UPDATE_DRIVER_PAYROLL_STATUS_SUCCESS,
  IUpdateDriverPayrollStatusFail,
  UPDATE_DRIVER_PAYROLL_STATUS_FAIL,
} from './payrollsTypes';

function* getDriversPayrollsItemsSaga({
  driverId,
  params,
}: ReturnType<typeof getDriversPayrollsItemsAction>) {
  try {
    const {
      data: { payrolls: list, pagination, statistics },
    } = yield call(() =>
      api.get(DRIVERS_PAYROLLS_ITEMS_API(driverId), { params }),
    );
    yield put<IGetDriversPayrollsItemsActionSuccess>({
      type: GET_DRIVERS_PAYROLLS_ITEMS_SUCCESS,
      payload: { list, pagination, statistics },
    });
    yield put(setPaginationAction(pagination));
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetDriversPayrollsItemsActionFail>({
        type: GET_DRIVERS_PAYROLLS_ITEMS_FAIL,
        payload: e,
      });
  }
}

function* getPayrollsSaga({
  week_id = 'current',
  params,
}: ReturnType<typeof getPayrollsAction>) {
  try {
    const {
      data: { week_payrolls: list, statistics, pagination },
    } = yield call(() => api.get(WEEK_PAYROLLS_API(week_id), { params }));
    yield put<IGetPayrollsActionSuccess>({
      type: GET_PAYROLLS_SUCCESS,
      payload: { list, statistics, pagination },
    });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetPayrollsActionFail>({
        type: GET_PAYROLLS_FAIL,
        payload: e,
      });
  }
}

function* getDriverPayrollsSaga({
  payload: { driverId, payrollDate = 'current' },
}: ReturnType<typeof getDriverPayrollsAction>) {
  try {
    const {
      data: {
        status,
        items: list,
        total: totalAmount,
        has_comments: hasComments,
        comments_count: commentsCount,
        pdf_id: pdfId,
      },
    } = yield call(() =>
      api.get(GET_DRIVER_PAYROLLS_API(driverId, payrollDate)),
    );
    yield put<IGetDriverPayrollsActionSuccess>({
      type: GET_DRIVER_PAYROLLS_SUCCESS,
      payload: {
        driverId,
        status,
        list,
        totalAmount,
        hasComments,
        commentsCount,
        pdfId,
      },
    });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetDriverPayrollsActionFail>({
        type: GET_DRIVER_PAYROLLS_FAIL,
        payload: e,
      });
  }
}

function* updateDriverPayrollStatusSaga({
  payload: { driverId, status, payroll_number },
}: ReturnType<typeof updateDriverPayrollStatusAction>) {
  try {
    const driver_ids = Array.isArray(driverId) ? driverId : [driverId];
    const data = {
      accountings_drivers_payroll: {
        status,
      },
      driver_ids,
      payroll_id: payroll_number,
    };
    yield call(() => api.patch(PATCH_DRIVER_PAYROLLS_API, data));
    const payload = { status, payroll_number };
    yield put<IUpdateDriverPayrollStatusSuccess>({
      type: UPDATE_DRIVER_PAYROLL_STATUS_SUCCESS,
      payload,
    });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IUpdateDriverPayrollStatusFail>({
        type: UPDATE_DRIVER_PAYROLL_STATUS_FAIL,
        payload: e,
      });
  }
}

function* patchDriverPayrollsSaga({
  payload: {
    driverId,
    payrollDate = 'current',
    payrollStatus,
    items,
    payrollId,
    callback,
  },
}: ReturnType<typeof patchDriverPayrollsAction>) {
  try {
    const driver_ids = Array.isArray(driverId) ? driverId : [driverId];
    const data = {
      accountings_drivers_payroll: {
        status: payrollStatus,
        items_attributes: items,
      },
      driver_ids,
      payroll_id: payrollId || payrollDate,
    };
    yield call(() => api.patch(PATCH_DRIVER_PAYROLLS_API, data));
    const payload = { driverId, payrollStatus, items };
    yield put<IPatchDriverPayrollsActionSuccess>({
      type: PATCH_DRIVER_PAYROLLS_SUCCESS,
      payload,
    });
    if (callback) callback(payload);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IPatchDriverPayrollsActionFail>({
        type: PATCH_DRIVER_PAYROLLS_FAIL,
        payload: e,
      });
  }
}

function* patchDriverPayrollItemSaga({
  item,
  callback,
}: ReturnType<typeof patchDriverPayrollItemAction>) {
  try {
    const { id, driver_id, ...accountings_drivers_payroll_item } = item;
    yield call(() =>
      api.patch(DRIVER_PAYROLL_ITEMS_API(driver_id, id), {
        accountings_drivers_payroll_item,
      }),
    );
    yield put<IPatchDriverPayrollItemActionSuccess>({
      type: PATCH_DRIVER_PAYROLL_ITEM_SUCCESS,
      payload: item,
    });
    if (callback) callback(item);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IPatchDriverPayrollItemActionFail>({
        type: PATCH_DRIVER_PAYROLL_ITEM_FAIL,
        payload: e,
      });
  }
}

function* getOutOfRangeItemsSaga({
  payload: { driverId, weekId, itemsType, callback },
}: ReturnType<typeof getPayrollOutOfRangeItemsAction>) {
  try {
    const items: IOutOfRangePayrollItem[] = [];

    const appendItems = (
      retrievedItems: IOutOfRangePayrollItem[],
      type: IOutOfRangePayrollItem['type'],
    ) => {
      for (const item of retrievedItems) {
        item.type = type;
        items.push(item);
      }
    };

    if (!itemsType || itemsType === 'order') {
      const { data } = yield call(() =>
        api.get(DRIVER_PAYROLLS_OUT_OF_RANGE_ORDERS_API(driverId, weekId)),
      );
      appendItems(data.items, 'order');
    }
    if (!itemsType || itemsType === 'fuel') {
      const { data } = yield call(
        () =>
          api.get(DRIVER_PAYROLLS_OUT_OF_RANGE_ORDERS_API(driverId, weekId)), // TODO: change URL
      );
      appendItems(data.items, 'fuel');
    }
    if (!itemsType || itemsType === 'deduction') {
      const { data } = yield call(
        () =>
          api.get(DRIVER_PAYROLLS_OUT_OF_RANGE_ORDERS_API(driverId, weekId)), // TODO: change URL
      );
      appendItems(data.items, 'deduction');
    }

    const response: IGetPayrollOutOfRangeItemsActionSuccess['payload'] = {
      driverId,
      weekId,
      items,
    };
    if (callback) callback(response);
    yield put<IGetPayrollOutOfRangeItemsActionSuccess>({
      type: GET_PAYROLL_OUT_OF_RANGE_ITEMS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetPayrollOutOfRangeItemsActionFail>({
        type: GET_PAYROLL_OUT_OF_RANGE_ITEMS_FAIL,
        payload: e,
      });
  }
}

function* addOutOfRangeItemsSaga({
  payload: { driverId, weekId, items, callback },
}: ReturnType<typeof addPayrollOutOfRangeItemsAction>) {
  try {
    // group items by type
    const groupedItems = new Map<
      IOutOfRangePayrollItem['type'],
      IOutOfRangePayrollItem[]
    >();
    for (const item of items) {
      if (!groupedItems.has(item.type)) groupedItems.set(item.type, []);
      const values = groupedItems.get(item.type);
      if (values) values.push(item);
    }

    for (const [type, itemsToAdd] of groupedItems) {
      const postData = {
        order_ids: itemsToAdd.map(({ id }) => id),
      };

      if (type === 'order')
        yield call(() =>
          api.post(
            DRIVER_PAYROLLS_OUT_OF_RANGE_ORDERS_API(driverId, weekId),
            postData,
          ),
        );
      else if (type === 'fuel')
        yield call(() =>
          api.post(
            DRIVER_PAYROLLS_OUT_OF_RANGE_ORDERS_API(driverId, weekId), // TODO: change URL
            postData,
          ),
        );
      else if (type === 'deduction')
        yield call(() =>
          api.post(
            DRIVER_PAYROLLS_OUT_OF_RANGE_ORDERS_API(driverId, weekId), // TODO: change URL
            postData,
          ),
        );
    }

    const response: IAddPayrollOutOfRangeItemsActionSuccess['payload'] = {
      driverId,
      weekId,
      items,
    };
    if (callback) callback(response);
    yield put<IAddPayrollOutOfRangeItemsActionSuccess>({
      type: ADD_PAYROLL_OUT_OF_RANGE_ITEMS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IAddPayrollOutOfRangeItemsActionFail>({
        type: ADD_PAYROLL_OUT_OF_RANGE_ITEMS_FAIL,
        payload: e,
      });
  }
}

function* getDriversSaga({ payload }: ReturnType<typeof getDriversAction>) {
  try {
    const { data: response } = yield call(() => {
      let url = PAYROLL_DRIVERS_API_PREFIX;
      if (payload && !isEmpty(payload)) url += `?${payload.join('&')}`;
      return api.get(url);
    });
    yield put<IGetDriversActionSuccess>({
      type: GET_DRIVERS_SUCCESS,
      payload: response.drivers,
    });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetDriversActionFail>({
        type: GET_DRIVERS_FAIL,
        payload: e,
      });
  }
}

function* getCommentsSaga({
  payload: { driverId, id },
}: ReturnType<typeof getPayrollItemCommentsAction>) {
  try {
    const { data: comments } = yield call(() => {
      return api.get(DRIVER_PAYROLL_ITEM_COMMENTS_API(driverId, id));
    });
    yield put<IGetPayrollItemCommentsActionSuccess>({
      type: GET_PAYROLL_ITEM_COMMENTS_SUCCESS,
      payload: comments,
    });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetPayrollItemCommentsActionFail>({
        type: GET_PAYROLL_ITEM_COMMENTS_FAIL,
        payload: e,
      });
  }
}

export default function* payrollsSagas() {
  yield takeLatest(GET_PAYROLLS, getPayrollsSaga);
  yield takeLatest(GET_DRIVER_PAYROLLS, getDriverPayrollsSaga);
  yield takeLatest(PATCH_DRIVER_PAYROLLS, patchDriverPayrollsSaga);
  yield takeLatest(PATCH_DRIVER_PAYROLL_ITEM, patchDriverPayrollItemSaga);
  yield takeLatest(GET_PAYROLL_OUT_OF_RANGE_ITEMS, getOutOfRangeItemsSaga);
  yield takeLatest(ADD_PAYROLL_OUT_OF_RANGE_ITEMS, addOutOfRangeItemsSaga);
  yield takeLatest(GET_DRIVERS, getDriversSaga);
  yield takeLatest(GET_PAYROLL_ITEM_COMMENTS, getCommentsSaga);
  yield takeLatest(GET_DRIVERS_PAYROLLS_ITEMS, getDriversPayrollsItemsSaga);
  yield takeLatest(UPDATE_DRIVER_PAYROLL_STATUS, updateDriverPayrollStatusSaga);
}
