import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';

import api from 'utils/requests';

import {
  deleteVehicleInspectionAction,
  unlockVehicleInspectionAction,
  deleteInspectionAction,
} from './allInspectionsActions';
import {
  DELETE_VEHICLE_INSPECTION,
  DELETE_VEHICLE_INSPECTION_SUCCESS,
  DELETE_VEHICLE_INSPECTION_FAIL,
  IDeleteVehicleInspectionActionSuccess,
  IDeleteVehicleInspectionActionFail,
  UNLOCK_VEHICLE_INSPECTION,
  UNLOCK_VEHICLE_INSPECTION_SUCCESS,
  UNLOCK_VEHICLE_INSPECTION_FAIL,
  IUnlockVehicleInspectionActionSuccess,
  IUnlockVehicleInspectionActionFail,
  DELETE_INSPECTION,
} from './allInspectionsTypes';

function* deleteVehicleInspectionSaga({
  inspectionId,
  vehicleType,
  callback,
  isTandem,
}: ReturnType<typeof deleteVehicleInspectionAction>) {
  try {
    yield call(() => api.delete(`/v1/fleets/inspections/${inspectionId}`));

    yield put<IDeleteVehicleInspectionActionSuccess>({
      type: DELETE_VEHICLE_INSPECTION_SUCCESS,
      inspectionId,
      vehicleType,
    });
    if (isTandem)
      yield put<IDeleteVehicleInspectionActionSuccess>({
        type: DELETE_VEHICLE_INSPECTION_SUCCESS,
        inspectionId,
        vehicleType: vehicleType === 'truck' ? 'trailer' : 'truck',
      });
    if (callback) callback(inspectionId, vehicleType);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IDeleteVehicleInspectionActionFail>({
        type: DELETE_VEHICLE_INSPECTION_FAIL,
        payload: e,
      });
  }
}

function* deleteInspectionSaga({
  inspectionId,
  callback,
}: ReturnType<typeof deleteInspectionAction>) {
  try {
    yield call(() => api.delete(`/v1/fleets/inspections/${inspectionId}`));
    if (callback) callback();
  } catch (error) {
    // TODO
  }
}

function* unlockVehicleInspectionSaga({
  payload,
  callback,
}: ReturnType<typeof unlockVehicleInspectionAction>) {
  const { id, ...rest } = payload;

  try {
    const { data } = yield call(() =>
      api.patch(`/v1/fleets/inspections/${id}`, {
        fleets_inspection: {
          unlock_inspection: true,
          ...rest,
        },
      }),
    );

    yield put<IUnlockVehicleInspectionActionSuccess>({
      type: UNLOCK_VEHICLE_INSPECTION_SUCCESS,
      payload: data.inspection,
    });

    if (callback) callback(data.inspection);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IUnlockVehicleInspectionActionFail>({
        type: UNLOCK_VEHICLE_INSPECTION_FAIL,
        payload: e,
      });
  }
}

export default function* allInspectionsSaga() {
  yield takeEvery(DELETE_VEHICLE_INSPECTION, deleteVehicleInspectionSaga);
  yield takeEvery(DELETE_INSPECTION, deleteInspectionSaga);
  yield takeLeading(UNLOCK_VEHICLE_INSPECTION, unlockVehicleInspectionSaga);
}
