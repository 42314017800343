import { FC } from 'react';

import { useSelector } from 'react-redux';

import { userThemeSelector } from '../../../redux/userSettings/userSettingsSelectors';

interface IOwnProps {
  className?: string;
  fillColor?: string;
  size?: string;
}

const IconCub: FC<IOwnProps> = ({ className, fillColor, size = '22' }) => {
  const theme = useSelector(userThemeSelector);

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10 1L10.3104 0.317225C10.0939 0.218837 9.84361 0.228913 9.63577 0.344382L10 1ZM1 6L0.635768 5.34438C0.397669 5.47666 0.25 5.72762 0.25 6H1ZM1 16H0.25C0.25 16.2941 0.421902 16.5611 0.689648 16.6828L1 16ZM12 21L11.6896 21.6828C11.9061 21.7812 12.1564 21.7711 12.3642 21.6556L12 21ZM21 16L21.3642 16.6556C21.6023 16.5233 21.75 16.2724 21.75 16H21ZM21 6H21.75C21.75 5.70589 21.5781 5.43893 21.3104 5.31723L21 6ZM11.5412 10.7914L11.2308 11.4742L11.2308 11.4742L11.5412 10.7914ZM12.4406 10.7552L12.0764 10.0996L12.0764 10.0996L12.4406 10.7552ZM9.63577 0.344382L0.635768 5.34438L1.36423 6.65562L10.3642 1.65562L9.63577 0.344382ZM0.25 6V16H1.75V6H0.25ZM0.689648 16.6828L11.6896 21.6828L12.3104 20.3172L1.31035 15.3172L0.689648 16.6828ZM12.3642 21.6556L21.3642 16.6556L20.6358 15.3444L11.6358 20.3444L12.3642 21.6556ZM21.75 16V6H20.25V16H21.75ZM21.3104 5.31723L10.3104 0.317225L9.68965 1.68277L20.6896 6.68277L21.3104 5.31723ZM11.25 11V21H12.75V11H11.25ZM0.689648 6.68277L11.2308 11.4742L11.8515 10.1087L1.31035 5.31723L0.689648 6.68277ZM12.8048 11.4108L21.3642 6.65562L20.6358 5.34438L12.0764 10.0996L12.8048 11.4108ZM11.2308 11.4742C11.7359 11.7038 12.3199 11.6803 12.8048 11.4108L12.0764 10.0996C12.0071 10.1381 11.9237 10.1415 11.8515 10.1087L11.2308 11.4742Z"
        fill={theme === 'dark' ? 'white' : fillColor}
      />
    </svg>
  );
};

export default IconCub;
