import { AxiosError } from 'axios';

import { IWithPending, IWithLoaded, IWithError } from 'utils/models';

import {
  IPayrollSettings,
  IGpsTrackingIntegration,
} from '../_models/settingsModels';

interface IPayrollSettingsReducer
  extends IWithPending,
    IWithLoaded,
    IWithError {
  settings: IPayrollSettings | null;
}

interface IGpsTrackingIntegrationReducer
  extends IWithPending,
    IWithLoaded,
    IWithError {
  items: IGpsTrackingIntegration[] | null;
}

export interface ISettingsReducer {
  payrollSettings: IPayrollSettingsReducer;
  gpsIntegrations: IGpsTrackingIntegrationReducer;
  currentContractTemplate: {
    id: number | null;
    name: string;
    content: string;
    status: string;
    contract_type: string;
    internal_company_id: number;
  };
}

// GET PAYROLL SETTINGS
export const GET_PAYROLL_SETTINGS = '[@@System_Settings] GET_PAYROLL_SETTINGS';
export const GET_PAYROLL_SETTINGS_SUCCESS =
  '[@@System_Settings] GET_PAYROLL_SETTINGS_SUCCESS';
export const GET_PAYROLL_SETTINGS_FAIL =
  '[@@System_Settings] GET_PAYROLL_SETTINGS_FAIL';

export interface IGetPayrollSettingsAction {
  type: typeof GET_PAYROLL_SETTINGS;
}
export interface IGetPayrollSettingsActionSuccess {
  type: typeof GET_PAYROLL_SETTINGS_SUCCESS;
  payload: IPayrollSettings;
}
export interface IGetPayrollSettingsActionFail {
  type: typeof GET_PAYROLL_SETTINGS_FAIL;
  payload: AxiosError;
}

// UPDATE PAYROLL SETTINGS
export const UPDATE_PAYROLL_SETTINGS =
  '[@@System_Settings] UPDATE_PAYROLL_SETTINGS';
export const UPDATE_PAYROLL_SETTINGS_SUCCESS =
  '[@@System_Settings] UPDATE_PAYROLL_SETTINGS_SUCCESS';
export const UPDATE_PAYROLL_SETTINGS_FAIL =
  '[@@System_Settings] UPDATE_PAYROLL_SETTINGS_FAIL';

export interface IUpdatePayrollSettingsAction {
  type: typeof UPDATE_PAYROLL_SETTINGS;
  payload: IPayrollSettings;
}
export interface IUpdatePayrollSettingsActionSuccess {
  type: typeof UPDATE_PAYROLL_SETTINGS_SUCCESS;
  payload: IPayrollSettings;
}
export interface IUpdatePayrollSettingsActionFail {
  type: typeof UPDATE_PAYROLL_SETTINGS_FAIL;
  payload: AxiosError;
}

// GET GPS INTEGRATIONS
export const GET_GPS_INTEGRATIONS = '[@@System_Settings] GET_GPS_INTEGRATIONS';
export const GET_GPS_INTEGRATIONS_SUCCESS =
  '[@@System_Settings] GET_GPS_INTEGRATIONS_SUCCESS';
export const GET_GPS_INTEGRATIONS_FAIL =
  '[@@System_Settings] GET_GPS_INTEGRATIONS_FAIL';

export interface IGetGpsIntegrationsAction {
  type: typeof GET_GPS_INTEGRATIONS;
}
export interface IGetGpsIntegrationsActionSuccess {
  type: typeof GET_GPS_INTEGRATIONS_SUCCESS;
  payload: IGpsTrackingIntegration[];
}
export interface IGetGpsIntegrationsActionFail {
  type: typeof GET_GPS_INTEGRATIONS_FAIL;
  payload: AxiosError;
}

// UPDATE GPS INTEGRATION
export const UPDATE_GPS_INTEGRATION =
  '[@@System_Settings] UPDATE_GPS_INTEGRATION';
export const UPDATE_GPS_INTEGRATION_SUCCESS =
  '[@@System_Settings] UPDATE_GPS_INTEGRATION_SUCCESS';
export const UPDATE_GPS_INTEGRATION_FAIL =
  '[@@System_Settings] UPDATE_GPS_INTEGRATION_FAIL';

export interface IUpdateGpsIntegrationAction {
  type: typeof UPDATE_GPS_INTEGRATION;
  payload: IGpsTrackingIntegration | IGpsTrackingIntegration[];
  callback?: (
    item: IGpsTrackingIntegration | IGpsTrackingIntegration[],
  ) => void;
}
export interface IUpdateGpsIntegrationActionSuccess {
  type: typeof UPDATE_GPS_INTEGRATION_SUCCESS;
  payload: IGpsTrackingIntegration | IGpsTrackingIntegration[];
}
export interface IUpdateGpsIntegrationActionFail {
  type: typeof UPDATE_GPS_INTEGRATION_FAIL;
  payload: AxiosError;
}

// DELETE GPS INTEGRATION
export const DELETE_GPS_INTEGRATION =
  '[@@System_Settings] DELETE_GPS_INTEGRATION';
export const DELETE_GPS_INTEGRATION_SUCCESS =
  '[@@System_Settings] DELETE_GPS_INTEGRATION_SUCCESS';
export const DELETE_GPS_INTEGRATION_FAIL =
  '[@@System_Settings] DELETE_GPS_INTEGRATION_FAIL';

export interface IDeleteGpsIntegrationAction {
  type: typeof DELETE_GPS_INTEGRATION;
  payload: number | number[];
  callback?: (id: number | number[]) => void;
}
export interface IDeleteGpsIntegrationActionSuccess {
  type: typeof DELETE_GPS_INTEGRATION_SUCCESS;
  payload: number | number[];
}
export interface IDeleteGpsIntegrationActionFail {
  type: typeof DELETE_GPS_INTEGRATION_FAIL;
  payload: AxiosError;
}

export const SET_CURRENT_CONTRACT_TEMPLATE =
  '[@@System_Settings] SET_CURRENT_CONTRACT_TEMPLATE';

export interface ISetCurrentContractTemplate {
  type: typeof SET_CURRENT_CONTRACT_TEMPLATE;
  payload: {
    id: number | null;
    name: string;
    content: string;
    status: string;
    contract_type: string;
    internal_company_id: number;
  };
}

export type settingsActionTypes =
  | IGetPayrollSettingsAction
  | IGetPayrollSettingsActionSuccess
  | IGetPayrollSettingsActionFail
  | IUpdatePayrollSettingsAction
  | IUpdatePayrollSettingsActionSuccess
  | IUpdatePayrollSettingsActionFail
  | IGetGpsIntegrationsAction
  | IGetGpsIntegrationsActionSuccess
  | IGetGpsIntegrationsActionFail
  | IUpdateGpsIntegrationAction
  | IUpdateGpsIntegrationActionSuccess
  | IUpdateGpsIntegrationActionFail
  | IDeleteGpsIntegrationAction
  | IDeleteGpsIntegrationActionSuccess
  | IDeleteGpsIntegrationActionFail
  | ISetCurrentContractTemplate;
