type Props = {
  withText?: boolean;
  className?: string;
};

const WiseLogo = ({ withText, className }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 300 300"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="73.79"
        y1="141.3"
        x2="147.51"
        y2="13.62"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#2fcaf5" />
        <stop offset="1" stopColor="#322978" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="134.01"
        y1="130.73"
        x2="205.77"
        y2="6.45"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#da58f7" />
        <stop offset="1" stopColor="#1e069a" />
      </linearGradient>
    </defs>
    <g id="Слой_1" data-name="Слой 1">
      <path
        fill="url(#linear-gradient)"
        d="M78.63,52.54l59.07,88-35.3-1.91,92.68-95.18.09-.1a2.5,2.5,0,0,1,3.92,3l-68.45,112.9a13.17,13.17,0,0,1-22.64-.2l-.61-1.06L54.56,66.43A13.87,13.87,0,0,1,78.07,51.72Z"
      />
      <polygon
        opacity={0.2}
        points="151.43 88.23 125.56 114.8 140.61 142.8 163.13 105.65 151.43 88.23"
      />
      <path
        fill="url(#linear-gradient-2)"
        d="M134.34,52.54l59.08,88-35.92-1.05,85.35-95.94.09-.1A2.49,2.49,0,0,1,247,46.24L186.75,158.68a13.18,13.18,0,0,1-23,.4l-.63-1.09L110.28,66.43a13.88,13.88,0,0,1,23.51-14.72Z"
      />
    </g>
    {withText && (
      <g id="Layer_6" data-name="Layer 6">
        <text
          fontSize="27.09px"
          fontFamily="Nexa-Heavy, Nexa"
          fontWeight={800}
          letterSpacing="0.7em"
          color="white"
          transform="translate(65.26 257.44)"
        >
          CH
          <tspan letterSpacing="0.69em" x="77.89" y="0">
            E
          </tspan>
          <tspan x="114.44" y="0">
            C
          </tspan>
          <tspan letterSpacing="0.69em" x="152.26" y="0">
            K
          </tspan>
        </text>
        <path d="M110.38,226.53h-9.83L93,206.2l-7.24,20.33H76.08l-15.86-42.4h13.1L78.83,199c1,3.27,1.56,6.72,2.25,9.82l7.75-24.65H97.1l8.11,24.65c.68-3.27,1.37-6.55,2.41-10.17L112.79,184h13.1Z" />
        <path d="M183.59,196c-2.93-2.24-6.2-2.93-10-2.93-2.93,0-5.68.86-5.68,3.1,0,2.93,2.93,3.27,7.06,3.79,8.45.86,17.24,4.31,17.24,14.13,0,9.66-9.14,13.79-18.62,13.79-7.58,0-13.1-1.2-19-7.41l6.55-7.58a16.64,16.64,0,0,0,12.41,4.82c4,0,6.73-1.37,6.73-3.79,0-2.07-2.42-3.44-7.07-3.79-9.31-.86-17.24-3.27-17.24-13.79,0-9,8.79-12.93,17.76-12.93,6.37,0,11.37,1.38,15.85,5Z" />
        <path d="M233.75,226.71h-34v-42.4h33.09v10h-21.2v6h20v9.82h-20v6.38h22.07Z" />
        <polygon points="142.07 183.17 125.6 200.12 125.6 201.59 131.47 201.59 131.47 226.53 142.07 226.53 142.07 183.17" />
        <polygon points="143.97 183.17 148.61 190.12 148.61 226.53 143.97 226.53 143.97 183.17" />
      </g>
    )}
  </svg>
);

export default WiseLogo;
