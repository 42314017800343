import { LOGOUT } from 'components/Auth/_redux/authTypes';
import {
  formatCurrency,
  formatNumber,
  randomBetween,
  randomTo,
} from 'utils/helpers';

import { IAccountingReportsReducer, reportsActionTypes } from './reportsTypes';

// TODO: remove after getting data from back-end
function generateDrivers() {
  const arr = [];
  for (let i = 0; i < 10; i += 1) {
    const obj = {
      id: i,
      driver_avatar: '',
      driver_full_name: 'Nathan Etenborough',
      driver_cooperation_type: 'Company Driver',
      net_income: '1200',
      gross_income: '1080',
      revenue: '2800',
      revenue_per_mile: '3.84',
      billed_revenue: '3010',
      total_fuel: '3010',
      claims: '3',
      trailer_type: 'Fleep Axel',
    };
    arr.push(obj);
  }
  return arr;
}

const randomDate = () =>
  `${randomBetween(2010, 2020)}-${randomBetween(1, 12)}-${randomBetween(
    1,
    28,
  )}`;

function generateSubItems(type: string, parentId: number) {
  const arr = [];
  const count = randomTo(5);
  for (let i = 0; i < count; i += 1) {
    const obj = {
      id: parentId + i,
      type: `FH8948732${i}`,
      description: `Los Angeles, CA ― Philadelphia, PA`,
      extra_description:
        type === 'orders' ? '220 loaded miles | 10 empty miles' : undefined,
      date: randomDate(),
      amount: randomBetween(200, 400),
      earnings: type === 'orders' ? randomBetween(20, 40) : undefined,
    };
    arr.push(obj);
  }
  return arr;
}

function generateItems() {
  const arr = [];
  for (let i = 0; i < 20; i += 1) {
    const obj = {
      id: i,
      payroll_number: `${112244 + i}`,
      payroll_date: randomDate(),
      claims_count: randomTo(4),
      gross_amount: randomBetween(2000, 4000),
      net_amount: randomBetween(2000, 4000),
      orders: generateSubItems('orders', 100 * i),
      deductions: generateSubItems('deductions', 200 * i),
      credits: generateSubItems('creadits', 300 * i),
    };
    arr.push(obj);
  }
  return arr;
}

function generateCards() {
  const arr = [];
  arr.push({ name: 'Revenue', value: formatCurrency(randomBetween(200, 400)) });
  arr.push({
    name: 'Billed revenue',
    value: formatCurrency(randomBetween(200, 400)),
  });
  arr.push({
    name: 'Gross income',
    value: formatCurrency(randomBetween(200, 400)),
  });
  arr.push({
    name: 'Net income',
    value: formatCurrency(randomBetween(200, 400)),
  });
  arr.push({
    name: 'Revenue per mile',
    value: formatCurrency(randomBetween(200, 400)),
  });
  arr.push({
    name: 'Orders QTY',
    value: formatNumber(randomBetween(200, 400)),
  });
  arr.push({
    name: 'Revenue XXX',
    value: formatCurrency(randomBetween(200, 400)),
  });
  arr.push({
    name: 'Revenue YYY',
    value: formatCurrency(randomBetween(200, 400)),
  });
  arr.push({
    name: 'Revenue ZZZ',
    value: formatCurrency(randomBetween(200, 400)),
  });
  return arr;
}

const initialState: IAccountingReportsReducer = {
  drivers: {
    list: generateDrivers(),
    pagination: undefined,
    _pending: false,
    _loaded: true, // TODO: set to false when BE ready
    _error: null,
  },

  driverItems: {
    list: generateItems(),
    cards: generateCards(),
    pagination: undefined,
    _pending: false,
    _loaded: true, // TODO: set to false when BE ready
    _error: null,
  },
};

// TODO: VZ - delete this
const reportsReducers = (
  state = initialState,
  action: reportsActionTypes,
): IAccountingReportsReducer => {
  switch (action.type) {
    case LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
};

export default reportsReducers;
