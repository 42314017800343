import { memo } from 'react';

type Props = {
  className?: string;
  color?: string;
  transformToSmall?: boolean;
};

const IconTasks = ({
  className,
  transformToSmall,
  color = 'var(--primaryText)',
}: Props) => (
  <svg
    width={transformToSmall ? '16' : '24'}
    height={transformToSmall ? '16' : '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3 7.42857L5.4 10L11 4"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 16.4286L5.4 19L11 13"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="14"
      y1="7"
      x2="22"
      y2="7"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="14"
      y1="16"
      x2="22"
      y2="16"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default memo(IconTasks);
