import { call, put, takeLatest } from 'redux-saga/effects';

import { getOverviewAction } from 'components/Dispatch/TabReports/Overview/_redux/overviewActions';
import {
  GET_OVERVIEW,
  GET_OVERVIEW_SUCCESS,
  GET_OVERVIEW_FAIL,
  IGetOverviewActionSuccess,
  IGetOverviewActionFail,
} from 'components/Dispatch/TabReports/Overview/_redux/overviewTypes';
import api from 'utils/requests';

function* getOverviewSaga({ payload }: ReturnType<typeof getOverviewAction>) {
  let url = `/v1/reports/dispatches`;
  const query = Object.entries(payload).reduce((acc, [key, value]) => {
    return value.length > 0 ? `${acc}&${key}=${value}` : acc;
  }, ''); // joining payload's params into one string
  if (query.length > 0) {
    url += `?${query.replace('&', '')}`;
  }
  try {
    const {
      data: { reports },
    } = yield call(() => api.get(url));
    yield put<IGetOverviewActionSuccess>({
      type: GET_OVERVIEW_SUCCESS,
      payload: reports,
    });
    if (payload && payload.callback) payload.callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetOverviewActionFail>({
        type: GET_OVERVIEW_FAIL,
        payload: e,
      });
  }
}

export default function* overviewSaga() {
  yield takeLatest(GET_OVERVIEW, getOverviewSaga);
}
