/* eslint-disable @typescript-eslint/no-explicit-any */
import { IWithError, IWithLoaded, IWithPending } from 'utils/models';

import { LOGOUT } from '../../Auth/_redux/authTypes';
import { MAX_INPUT_FILES } from '../_constants/attachFile';
import {
  IChatDraft,
  IChatFile,
  IChatMessage,
  IChatRoom,
  IChatUserProfile,
  MainChatView,
  IChatForwardMessage,
} from '../_models/chatMainEntities';
import {
  ADD_USERS_FOR_CHAT_GROUP,
  ADD_USERS_FOR_CHAT_GROUP_FAIL,
  ADD_USERS_FOR_CHAT_GROUP_SUCCESS,
  CHANGE_GROUP_CHAT_NAME,
  CHANGE_GROUP_CHAT_NAME_FAIL,
  CHANGE_GROUP_CHAT_NAME_SUCCESS,
  chatActionsType,
  CREATE_CHATROOM,
  CREATE_CHATROOM_FAIL,
  CREATE_CHATROOM_SUCCESS,
  DELETE_CHAT_FILES,
  DELETE_CHAT_FILES_FAIL,
  DELETE_CHAT_FILES_SUCCESS,
  DELETE_PIN_MESSAGE,
  DELETE_PIN_MESSAGE_FAIL,
  DELETE_PIN_MESSAGE_SUCCESS,
  DELETE_USER_FROM_GROUP_CHAT,
  DELETE_USER_FROM_GROUP_CHAT_FAIL,
  DELETE_USER_FROM_GROUP_CHAT_SUCCESS,
  GET_CHAT_PEOPLE,
  GET_CHAT_PEOPLE_FAIL,
  GET_CHAT_PEOPLE_SUCCESS,
  GET_CHAT_USER_PROFILE,
  GET_CHAT_USER_PROFILE_FAIL,
  GET_CHAT_USER_PROFILE_SUCCESS,
  GET_CHATROOM,
  GET_CHATROOM_FAIL,
  GET_CHATROOM_MESSAGES,
  GET_CHATROOM_MESSAGES_FAIL,
  GET_CHATROOM_MESSAGES_SUCCESS,
  GET_CHATROOM_SUCCESS,
  GET_CHATROOMS,
  GET_CHATROOMS_FAIL,
  GET_CHATROOMS_SUCCESS,
  GET_MESSAGES_WITH_PIN,
  GET_MESSAGES_WITH_PIN_FAIL,
  GET_MESSAGES_WITH_PIN_SUCCESS,
  LEAVE_GROUP_CHAT,
  LEAVE_GROUP_CHAT_FAIL,
  LEAVE_GROUP_CHAT_SUCCESS,
  MARK_READ_MESSAGES,
  MARK_READ_MESSAGES_FAIL,
  MARK_READ_MESSAGES_SUCCESS,
  SET_FORWARD_MESSAGE_DATA,
  SEND_MESSAGE,
  SEND_MESSAGE_FAIL,
  SEND_MESSAGE_SUCCESS,
  SET_PIN_MESSAGE,
  SET_PIN_MESSAGE_FAIL,
  SET_PIN_MESSAGE_SUCCESS,
  SET_REPLY_MESSAGE,
  UPDATE_FROM_SOCKET,
  UPLOAD_CHAT_FILES,
  UPLOAD_CHAT_FILES_FAIL,
  UPLOAD_CHAT_FILES_SUCCESS,
  CHANGE_MAIN_CHAT_VIEW,
} from './chatTypes';
import {
  processDataOnReadMessages,
  processDataOnSendMessage,
  processMessagesForDateLabels,
  processSocketData,
} from './helpers';

export interface IChatState extends IWithPending, IWithLoaded, IWithError {
  // Rooms
  rooms: IChatRoom[] | null;
  roomsPagination: any;
  // Current Room
  room: IChatRoom | null;
  // Current Room Messages
  messages: IChatMessage[] | null;
  messagesPagination: { page: number; pages: number } | null;
  // People to chat with
  people: any[] | null;
  // Files
  files: IChatFile[];
  // Draft
  draft: IChatDraft[];
  forwardMessageData: null | IChatForwardMessage;
  // User  profile details to chat with
  userProfile: null | IChatUserProfile;
  mainChatView: MainChatView;
}

const initialState: IChatState = {
  rooms: null,
  roomsPagination: null,
  room: null,
  messages: null,
  messagesPagination: null,
  people: null,
  userProfile: null,
  mainChatView: 'messages',
  files: [],
  draft: [],
  forwardMessageData: null,
  _pending: false,
  _loaded: false,
  _error: null,
};

function chatReducer(
  state = initialState,
  action: chatActionsType,
): IChatState {
  switch (action.type) {
    case UPDATE_FROM_SOCKET: {
      return processSocketData(state, action.payload);
    }

    case CREATE_CHATROOM:
      return { ...state, _error: null };
    case CREATE_CHATROOM_SUCCESS:
      return {
        ...state,
      };
    case CREATE_CHATROOM_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    case GET_CHATROOMS:
      return { ...state, _error: null };
    case GET_CHATROOMS_SUCCESS: {
      const { chatrooms, pagination } = action.payload;
      return {
        ...state,
        rooms:
          pagination.page === 1
            ? chatrooms
            : [...(state.rooms || []), ...chatrooms],
        roomsPagination: pagination,
      };
    }
    case GET_CHATROOMS_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // GET_CHATROOM
    case GET_CHATROOM:
      return { ...state, _error: null };
    case GET_CHATROOM_SUCCESS: {
      return {
        ...state,
        room: action.payload.chatRoom,
        messages: null,
        messagesPagination: null,
        userProfile: null,
        mainChatView: action.payload.mainChatView || 'messages',
      };
    }
    case GET_CHATROOM_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    case GET_CHATROOM_MESSAGES:
      return { ...state, _pending: true, _loaded: false, _error: null };
    case GET_CHATROOM_MESSAGES_SUCCESS: {
      const { messages, pagination } = action.payload;
      return {
        ...state,
        _pending: false,
        _loaded: true,
        messagesPagination: pagination,
        mainChatView: 'messages',
        messages:
          pagination.page === 1
            ? processMessagesForDateLabels(messages)
            : processMessagesForDateLabels([
                ...(state.messages || []),
                ...messages,
              ]),
      };
    }
    case GET_CHATROOM_MESSAGES_FAIL:
      return {
        ...state,
        _pending: false,
        _loaded: false,
        _error: action.payload,
      };

    case SET_FORWARD_MESSAGE_DATA: {
      return {
        ...state,
        forwardMessageData: action.payload.data,
      };
    }

    case SEND_MESSAGE: {
      return {
        ...state,
        _error: null,
        draft: state.draft.filter(
          (item: IChatDraft) =>
            item.text !== action.payload.text &&
            item.files.every(
              (file: IChatFile, index: number) =>
                file.id !== state.files[index].id,
            ),
        ),
      };
    }
    case SEND_MESSAGE_SUCCESS: {
      const { messages, rooms } = state;
      const { message } = action.payload;

      return {
        ...state,
        rooms: processDataOnSendMessage(rooms, message),
        messages: processMessagesForDateLabels([message, ...(messages || [])]),
        files: [],
      };
    }
    case SEND_MESSAGE_FAIL: {
      const { chatroom_id, text, files, error } = action.payload;
      return {
        ...state,
        _error: action.payload.error,
        draft: [...state.draft, { text, error, chatroom_id, files }],
        files: [],
      };
    }

    case MARK_READ_MESSAGES:
      return { ...state, _error: null };
    case MARK_READ_MESSAGES_SUCCESS: {
      const { unread_number } = action.payload;
      const { rooms, room } = state;

      return {
        ...state,
        rooms: processDataOnReadMessages(rooms, room, unread_number),
      };
    }
    case MARK_READ_MESSAGES_FAIL:
      return { ...state, _error: action.payload };

    case GET_CHAT_PEOPLE:
      return { ...state, _pending: true, _loaded: false, _error: null };
    case GET_CHAT_PEOPLE_SUCCESS:
      return {
        ...state,
        _pending: false,
        _loaded: true,
        people: action.payload.employees,
      };
    case GET_CHAT_PEOPLE_FAIL:
      return {
        ...state,
        _pending: false,
        _loaded: false,
        _error: action.payload,
      };

    // UPLOAD_CHAT_FILES
    case UPLOAD_CHAT_FILES:
      return {
        ...state,
        _error: null,
        files: [
          ...state.files,
          ...action.files.map(
            file =>
              ({
                preview: file,
                loading: true,
              } as IChatFile),
          ),
        ].slice(0, MAX_INPUT_FILES),
      };
    case UPLOAD_CHAT_FILES_SUCCESS:
      return {
        ...state,
        files: state.files.map((file: IChatFile, index: number) =>
          index === state.files.findIndex(({ loading }: any) => loading)
            ? { ...action.payload, loading: false }
            : file,
        ),
      };
    case UPLOAD_CHAT_FILES_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    case DELETE_CHAT_FILES:
      return {
        ...state,
        _error: null,
      };
    case DELETE_CHAT_FILES_SUCCESS:
      return {
        ...state,
        files: state.files.filter(
          (file: any) => file.file.id !== action.payload && file,
        ),
      };
    case DELETE_CHAT_FILES_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // SET_REPLY_MESSAGE
    case SET_REPLY_MESSAGE: {
      const { messageId } = action.payload;
      const replyMessage =
        (state.messages && state.messages.find(({ id }) => id === messageId)) ||
        null;

      return {
        ...state,
        room: state.room ? { ...state.room, replyMessage } : null,
      };
    }

    // SET_PIN_MESSAGE
    case SET_PIN_MESSAGE:
      return { ...state, _error: null };
    case SET_PIN_MESSAGE_SUCCESS: {
      const pinMessage =
        (state.messages &&
          state.messages.find(({ id }) => id === action.payload)) ||
        null;

      return {
        ...state,
        room: state.room ? { ...state.room, pin_message: pinMessage } : null,
      };
    }
    case SET_PIN_MESSAGE_FAIL:
      return { ...state, _error: action.payload };

    // DELETE_PIN_MESSAGE
    case DELETE_PIN_MESSAGE:
      return { ...state, _error: null };
    case DELETE_PIN_MESSAGE_SUCCESS:
      return {
        ...state,
        room: state.room ? { ...state.room, pin_message: null } : null,
      };
    case DELETE_PIN_MESSAGE_FAIL:
      return { ...state, _error: action.payload };

    // GET_MESSAGES_WITH_PIN
    case GET_MESSAGES_WITH_PIN:
      return { ...state, _error: null };
    case GET_MESSAGES_WITH_PIN_SUCCESS:
      return {
        ...state,
        messages: action.payload.length
          ? processMessagesForDateLabels(action.payload)
          : processMessagesForDateLabels(state.messages || []),
      };
    case GET_MESSAGES_WITH_PIN_FAIL:
      return { ...state, _error: action.payload };

    // GET_CHAT_USER_PROFILE
    case GET_CHAT_USER_PROFILE:
      return { ...state, _error: null };
    case GET_CHAT_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
        mainChatView: 'userProfile',
      };
    case GET_CHAT_USER_PROFILE_FAIL:
      return { ...state, _error: action.payload };

    // CHANGE_GROUP_CHAT_NAME
    case CHANGE_GROUP_CHAT_NAME:
      return { ...state, _error: null };
    case CHANGE_GROUP_CHAT_NAME_SUCCESS:
      return {
        ...state,
        rooms: !state.rooms
          ? null
          : state.rooms.map(room =>
              room.id !== action.payload.chatroomId
                ? room
                : { ...room, name: action.payload.groupName },
            ),
      };
    case CHANGE_GROUP_CHAT_NAME_FAIL:
      return { ...state, _error: action.payload };

    // ADD_USERS_FOR_CHAT_GROUP
    case ADD_USERS_FOR_CHAT_GROUP:
      return { ...state, _error: null };
    case ADD_USERS_FOR_CHAT_GROUP_SUCCESS:
      return {
        ...state,
        room: state.room
          ? {
              ...state.room,
              chat_users: [action.payload, ...state.room.chat_users],
            }
          : null,
      };
    case ADD_USERS_FOR_CHAT_GROUP_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // DELETE_USER_FROM_GROUP_CHAT
    case DELETE_USER_FROM_GROUP_CHAT:
      return { ...state, _error: null };
    case DELETE_USER_FROM_GROUP_CHAT_SUCCESS:
      return {
        ...state,
        room: !state.room
          ? null
          : {
              ...state.room,
              chat_users: state.room.chat_users.filter(
                user => user.chat_user_id !== action.payload.chatUserId,
              ),
            },
      };
    case DELETE_USER_FROM_GROUP_CHAT_FAIL:
      return { ...state, _error: action.payload };

    // LEAVE_GROUP_CHAT
    case LEAVE_GROUP_CHAT:
      return { ...state, _error: null };
    case LEAVE_GROUP_CHAT_SUCCESS:
      return {
        ...state,
        mainChatView: 'messages',
        messages: null,
        room: null,
        userProfile: null,
        rooms: !state.rooms
          ? null
          : state.rooms.filter(room => room.id !== action.payload.chatroomId),
      };
    case LEAVE_GROUP_CHAT_FAIL:
      return { ...state, _error: action.payload };

    // CHANGE_MAIN_CHAT_VIEW
    case CHANGE_MAIN_CHAT_VIEW:
      return { ...state, mainChatView: action.payload.data.mainChatView };

    // OTHER
    case LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
}

export default chatReducer;
