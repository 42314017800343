import { FC } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const IconVehicle: FC<IOwnProps> = ({
  className,
  fillColor = 'var(--sideBarIcon)',
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3 13V14C3 14.5523 3.44772 15 4 15V15C4.55228 15 5 14.5523 5 14V13"
        stroke={fillColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M11 13V14C11 14.5523 11.4477 15 12 15V15C12.5523 15 13 14.5523 13 14V13"
        stroke={fillColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M13 13H3C1.89543 13 1 12.1046 1 11V8C1 6.89543 1.89543 6 3 6H13C14.1046 6 15 6.89543 15 8V11C15 12.1046 14.1046 13 13 13Z"
        stroke={fillColor}
        strokeWidth="2"
      />
      <circle cx="4" cy="9" r="1" fill={fillColor} />
      <circle cx="12" cy="9" r="1" fill={fillColor} />
      <path
        d="M13 6H3L3.67845 2.60777C3.86542 1.67292 4.68625 1 5.63961 1H10.3604C11.3138 1 12.1346 1.67292 12.3216 2.60777L13 6Z"
        stroke={fillColor}
        strokeWidth="2"
      />
    </svg>
  );
};

export default IconVehicle;
