import {
  ChannelMessage,
  SUBSCRIBE_CHANNEL,
  UNSUBSCRIBE_CHANNEL,
  APPEND_CHANNEL_MESSAGES,
  UPDATE_CHANNEL_MESSAGES,
  ON_CONNECTION_CHANGED,
  ON_CHANNEL_SUBSCRIBED,
  ON_INCOMING_MESSAGE,
  ON_INCOMING_ERROR,
  ISubscribeChannelAction,
  IUnsubscribeChannelAction,
  IAppendChannelMessagesAction,
  IUpdateChannelMessagesAction,
  IOnConnectionChangedAction,
  IOnChannelSubscribedAction,
  IOnIncomingMessageAction,
  IOnIncomingErrorAction,
} from './socketTypes';

export const subscribeChannelAction = (
  channelIdentifier: string,
  messagesHistoryDepth = 0, // 0 means infinite
): ISubscribeChannelAction => ({
  type: SUBSCRIBE_CHANNEL,
  channelIdentifier,
  messagesHistoryDepth,
});

export const unsubscribeChannelAction = (
  channelIdentifier: string,
): IUnsubscribeChannelAction => ({
  type: UNSUBSCRIBE_CHANNEL,
  channelIdentifier,
});

export const appendChannelMessagesAction = (
  channelIdentifier: string,
  messages: ChannelMessage[],
  messagesType?: string,
): IAppendChannelMessagesAction => ({
  type: APPEND_CHANNEL_MESSAGES,
  channelIdentifier,
  messages,
  messagesType,
});

export const updateChannelMessagesAction = <Message>(
  channelIdentifier: string,
  messages: Message | Message[],
  messagesType?: string,
): IUpdateChannelMessagesAction => ({
  type: UPDATE_CHANNEL_MESSAGES,
  channelIdentifier,
  messages: Array.isArray(messages) ? messages : [messages],
  messagesType,
});

export const onConnectionChangedAction = (
  connected: boolean,
): IOnConnectionChangedAction => ({ type: ON_CONNECTION_CHANGED, connected });

export const onChannelSubscribedAction = (
  channelIdentifier: string,
): IOnChannelSubscribedAction => ({
  type: ON_CHANNEL_SUBSCRIBED,
  channelIdentifier,
});

export const onChannelMessageReceivedAction = (
  channelIdentifier: string,
  message: ChannelMessage,
  messageType?: string,
): IOnIncomingMessageAction => ({
  type: ON_INCOMING_MESSAGE,
  channelIdentifier,
  message,
  messageType,
});

export const onIncomingErrorAction = (
  event: Event,
): IOnIncomingErrorAction => ({ type: ON_INCOMING_ERROR, event });
