import { memo } from 'react';

import { useHistory } from 'react-router';

import GoBackButton from 'components/_common/GoBackButton/GoBackButton';
import { ModalDialog } from 'components/_common/Modal/Modal';

import styles from './PrivacyPolicyPopUp.module.scss';

type Props = {
  isOpen: boolean;
  close: () => void;
  companyName?: 'W1se Check' | 'FleetTec';
};

export const PrivacyPolicy = ({
  companyName = 'W1se Check',
}: Partial<Props>) => {
  const history = useHistory();

  return (
    <div className={styles.wrapper}>
      {history.location.pathname === '/terms' && (
        <GoBackButton className={styles.goBack} />
      )}
      <div className={styles.header}>
        <h2>Privacy Policy</h2>
        <p>last updated Jan 1, 2020</p>
      </div>

      <div className={styles.main}>
        <p>
          This Privacy Notice describes how {companyName} and its subsidiaries
          and affiliates (together referred to as {companyName}, “we”, “us” or
          “our”) collect, use, store, and share your information.
        </p>
        <br />
        <p>
          This Privacy Notice applies to information we receive from or about
          you: (1) when you access or use our mobile applications
          (“Applications”), websites (“Websites”), or products and/or services
          (“Products”); (2) when you communicate with us in any manner,
          including by email, telephone, and in person; and (3) from third-party
          sources.
        </p>

        <h3>Acknowledgment</h3>
        <p>
          Please read this Privacy Notice carefully. The practices described in
          this Privacy Notice are subject to applicable laws in the places in
          which we operate.
        </p>

        <p className={styles.declaration}>
          BY ACCESSING AND USING OUR APPLICATIONS, WEBSITES, AND/OR PRODUCTS, OR
          BY COMMUNICATING WITH US IN ANY MANNER, YOU REPRESENT THAT YOU HAVE
          READ AND UNDERSTOOD THIS PRIVACY NOTICE.
        </p>

        <h3>{companyName} Fair Information Values</h3>
        <p>
          We collect, use, and share your personal information consistent with{' '}
          {companyName}’s Fair Information Values:
        </p>

        <h4>Fairness</h4>
        <p>
          We consider your expectations of privacy in all of our business
          operations, and strive to offer our Applications, Websites, and
          Products in a way that ensures a favorable balance between the
          benefits we provide and your privacy.
        </p>

        <h4>Use</h4>
        <p>
          We believe we should behave responsibly when we use your personal
          information. We will obey the applicable laws governing the
          collection, use, storage, and sharing of your personal information.
        </p>

        <h4>Security</h4>
        <p>
          We will maintain commercially reasonable technical, organizational,
          and physical safeguards to protect your personal information.
        </p>

        <h3>What Type of Information Do We Collect About You?</h3>
        <p>
          Depending upon the Applications, Websites, or Products you access or
          use, the nature of your communications with us, and the applicable
          laws, we may collect both personal and non- personal information about
          and from you. Personal information is non-public information that can
          be used to directly or indirectly identify or contact you. Examples of
          categories of personal information include, but may not be limited to:
        </p>

        <ul>
          <li>
            <span>Name</span>
          </li>
          <li>
            <span>Telephone Number</span>
          </li>
          <li>
            <span>Application’s User Name</span>
          </li>
          <li>
            <span>Email Address</span>
          </li>
          <li>
            <span>GPS Cordinates</span>
          </li>
        </ul>

        <p>
          Non-personal information is any other type of information. Examples of
          non-personal information include, but may not be limited to:
        </p>

        <ul>
          <li>
            <span>Publicly available information </span>
          </li>
          <li>
            <span>Browser type and version </span>
          </li>
          <li>
            <span>Browser settings </span>
          </li>
          <li>
            <span> Browser plug-in types and versions</span>
          </li>
          <li>
            <span> Operating systems and platform</span>
          </li>
          <li>
            <span>Device type and operating information </span>
          </li>
          <li>
            <span>Mobile carrier </span>
          </li>
          <li>
            <span>Page response time and download errors </span>
          </li>
          <li>
            <span>Time zone </span>
          </li>
        </ul>

        <h3>How Do We Collect Your Information?</h3>
        <p>
          We collect your personal and non-personal information in three ways:
        </p>
        <ul>
          <li>
            <span>
              Directly from you when you access or use our Applications,
              Websites, or Products, or when you communicate with us in any
              manner
            </span>
          </li>
          <li>
            <span>
              Automatically when you access or use our Applications, Websites,
              or Products, or when you communicate with us
            </span>
          </li>
        </ul>

        <h3>How Do We Use Your Information?</h3>
        <p>
          We may use your personal information in a variety of ways, including
          but not limited to:
        </p>
        <ul>
          <li>
            <span> Provide the Applications and Websites to you </span>
          </li>
          <li>
            <span>Fulfill a transaction you requested </span>
          </li>
          <li>
            <span> Create and manage your account</span>
          </li>
          <li>
            <span>
              Operate the Applications and Websites, including access
              management, Application and/or Website administration, internal
              operations, troubleshooting, data analysis, testing, research, and
              for statistical purposes
            </span>
          </li>
          <li>
            <span>Respond to your requests, feedback, or inquiries </span>
          </li>
          <li>
            <span>
              {' '}
              Comply with laws, regulations, and other legal requirements
            </span>
          </li>
          <li>
            <span>
              Protect and enforce your rights and the rights of other users
              against unlawful activity, including identity theft and fraud
            </span>
          </li>
          <li>
            <span>
              Protect and enforce our collective rights arising under any
              agreements entered into between {companyName} and you or any other
              third party
            </span>
          </li>
          <li>
            <span>
              Protect the integrity and maintain the security of our
              Applications, Websites, and Products
            </span>
          </li>
          <li>
            <span>Operate, evaluate, and improve our business </span>
          </li>
        </ul>

        <p>
          We may use your personal information in a variety of ways, including
          but not limited to:
        </p>

        <ul>
          <li>
            <span>
              Deliver content tailored to your interests and how you use the
              Applications and/or Websites
            </span>
          </li>
          <li>
            <span>
              Present content in a manner that is optimized for your device
            </span>
          </li>
          <li>
            <span>
              Measure and analyze the effectiveness of the Applications,
              Websites, and Products we provide you
            </span>
          </li>
        </ul>

        <h3>How Do We Share Your Personal Information?</h3>
        <p>
          We do not sell your personal information to nonaffiliated third
          parties. We will only share your personal information, including
          subsidiaries, affiliates, and unaffiliated third parties in the
          following situations:
        </p>

        <h4>With your consent</h4>
        <p>
          We may disclose your personal information to an unaffiliated
          third-party with your consent. We may obtain your consent in writing,
          online, through “click-through” agreements; when you accept the terms
          of use on our Applications or Websites; orally, either in person or on
          the phone; or by other means.
        </p>

        <h4>In a business transfer</h4>
        <p>
          We may disclose your personal information as part of a corporate
          business transaction, such as a merger or acquisition, joint venture,
          corporate reorganization, financing, or sale of company assets, or in
          the unlikely event of insolvency, bankruptcy, or receivership, in
          which such information could be transferred to third parties as a
          business asset in the transaction.
        </p>

        <h4>To service providers</h4>
        <p>
          We may disclose your personal information to third-party vendors and
          partners who complete transactions or perform services on our behalf.
        </p>

        <h4>To subsidiaries and affiliates</h4>
        <p>
          We may disclose your personal information to subsidiaries and
          affiliates to further facilitate your use of our Applications,
          Websites, and Products, and to ensure the smooth and consistent
          operations of
          {companyName}.
        </p>

        <h4>For legal process and protection</h4>
        <p>
          We may disclose your personal information to satisfy any law,
          regulation, legal process, governmental request, or where we have a
          good faith belief that access, use, preservation or disclosure of such
          information is reasonably necessary to:
        </p>
        <ul>
          <li>
            <span>
              Enforce or apply agreements, or initiate, render, bill, and
              collect for Products
            </span>
          </li>
          <li>
            <span>
              Protect our rights or interests, property or safety or that of
              others
            </span>
          </li>
          <li>
            <span>
              In connection with claims, disputes, or litigation - in court or
              elsewhere
            </span>
          </li>
          <li>
            <span>
              Protect users of our Applications, Websites, and Products and
              other carriers or providers from fraudulent, abusive, or unlawful
              use of, or subscription to, such services
            </span>
          </li>
          <li>
            <span>
              Facilitate or verify the appropriate calculation of taxes, fees,
              or other obligations due to local, state, or federal government
            </span>
          </li>
          <li>
            <span>In case of an emergency</span>
          </li>
        </ul>

        <h3>How Do We Store and Protect Your Personal Information?</h3>
        <p>
          The security of your personal information is important to us. That is
          why we take commercially reasonable steps to make sure your personal
          information is protected. Although no system can guarantee the
          complete security of your personal information, we will use our best
          efforts to maintain commercially reasonable technical, organizational,
          and physical safeguards, consistent with applicable law, to protect
          your personal information.
        </p>

        <h3>How Long Do We Keep Your Personal Information?</h3>
        <p>
          We keep your personal information for as long as necessary in
          accordance with the purpose for which it was collected, our business
          needs, and our legal and regulatory obligations. We may store some
          personal information indefinitely, including the period after which
          your relationship with us has ended. If we dispose of your personal
          information, we will do so in a way that is secure and appropriate to
          the nature of the data subject to disposal.
        </p>

        <h3>Cookie Statement</h3>
        <p>
          We may use cookies (browser and flash), beacons, pixel tags, scripts,
          and other similar technologies from time to time to support the
          functionality of our Applications, Websites, and Products. This
          echnology provides a better experience when you use our Applications,
          Websites, and Products, and allows us to improve our services. We may
          also use analytic software, such as Google Analytics and others, to
          help better understand how our Applications and Websites function on
          your device(s) and for other analytical purposes.
        </p>

        <h3>International Jurisdictions</h3>
        <p>
          Our Applications, Websites, and Products are hosted and offered in the
          United States of America (US) and are subject to US federal, state,
          and local law. If you are accessing the Applications, Websites, or
          Products from another country, please be advised that you may be
          transferring your personal information to us in the US, and you
          consent to that transfer and use of your personal information in
          accordance with this Privacy Notice. You also agree to abide by the
          applicable laws of applicable US federal, state, and local laws
          concerning your use of the Applications, Websites, and Products, and
          your agreements with us. Any persons accessing our Applications,
          Websites, or Products from any jurisdiction with laws or regulations
          governing the use of the Internet, including the collection, use, or
          disclosure of personal information, different from those of the
          jurisdictions mentioned above may only use the Applications, Websites,
          or Products in a manner lawful in their jurisdiction. If your use of
          the Applications, Websites, or Products would be unlawful in your
          jurisdiction, you may not use the Applications, Websites, or Products.
        </p>

        <h3>Changes to Our Privacy Policy</h3>
        <p>
          We may change this Privacy Notice from time to time. Any and all
          changes to this Privacy Notice will be reflected on this page, and
          where appropriate provided in person or by another electronic method.
          The effective date will be stated at the top of this Privacy Notice.
          Unless stated otherwise, our current Privacy Notice applies to all
          information that we have about you. You should regularly check this
          page for any changes to this Privacy Notice.
        </p>

        <p className={styles.declaration}>
          YOUR CONTINUED USE, ACCESS, OR INTERACTION WITH THE APPLICATIONS,
          WEBSITES, OR PRODUCTS, OR YOUR CONTINUED COMMUNICATIONS WITH US AFTER
          THE UPDATED PRIVACY NOTICE HAS BEEN POSTED WILL REPRESENT THAT YOU
          HAVE READ AND UNDERSTOOD THIS PRIVACY NOTICE.
        </p>
      </div>
    </div>
  );
};

const PrivacyPolicyPopUp = ({
  isOpen,
  companyName = 'W1se Check',
  close,
}: Props) => (
  <ModalDialog
    open={isOpen}
    onClose={close}
    className={styles.root}
    dataTestAttr="privacy_policy"
  >
    <PrivacyPolicy companyName={companyName} />
  </ModalDialog>
);

export default memo(PrivacyPolicyPopUp);
