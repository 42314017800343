import { FC } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
  plusFlag?: boolean;
}

const IconComment: FC<IOwnProps> = ({
  className,
  fillColor = '#2D2F36',
  plusFlag = false,
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M1 3V14C1 14.824 1.94076 15.2944 2.6 14.8L4.46667 13.4C4.81286 13.1404 5.23393 13 5.66667 13H13C14.1046 13 15 12.1046 15 11V3C15 1.89543 14.1046 1 13 1H3C1.89543 1 1 1.89543 1 3Z"
          stroke={fillColor}
          strokeWidth="2"
        />
        {plusFlag && (
          <>
            <path
              d="M8 5V9"
              stroke={fillColor}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M10 7H6"
              stroke={fillColor}
              strokeWidth="2"
              strokeLinecap="round"
            />
          </>
        )}
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0H16V16H0V0Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconComment;
