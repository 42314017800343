import { FC } from 'react';

import { useSelector } from 'react-redux';

import { userThemeSelector } from 'redux/userSettings/userSettingsSelectors';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const LittleVoltage: FC<IOwnProps> = ({ className, fillColor = '#86898E' }) => {
  const theme = useSelector(userThemeSelector);

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M8.39448 4.5L6.41931 8H9.38206L7.40689 11.5"
          stroke={theme === 'dark' ? 'white' : fillColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.7502 1H2.0511C1.46372 1 0.987549 1.41787 0.987549 1.93333V14.0667C0.987549 14.5821 1.46372 15 2.0511 15H13.7502C14.3375 15 14.8137 14.5821 14.8137 14.0667V1.93333C14.8137 1.41787 14.3375 1 13.7502 1Z"
          stroke={theme === 'dark' ? 'white' : fillColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0H15.8013V16H0V0Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LittleVoltage;
