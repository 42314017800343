import { IMapConfig } from './_models';

import styles from './Map.module.scss';

export const DEFAULT_MAP_MARKER_TRANSPARENT_ICON_PATH =
  '/icons/IconMapMarkerTransparent.svg';
export const DEFAULT_MAP_MARKER_ICON_PATH = '/icons/IconMapMarker.svg';
export const DEFAULT_ACTIVE_MAP_MARKER_ICON_PATH =
  '/icons/IconActiveMapMarker.svg';
export const DEFAULT_MAP_POSITION_ICON_PATH = '/icons/IconMapPosition.svg';
export const ROUNDED_MAP_POSITION_ICON_PATH =
  '/icons/IconMapPositionRounded.svg';
export const DEFAULT_MAP_CLUSTER_ICONS_PATH = '/icons/mapClusterIcons';
export const DEFAULT_MAP_URL = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${process.env.REACT_APP_GOOGLE_KEY}&libraries=geometry,drawing,places&type=(regions)&language=en`;

export const DEFAULT_ZOOM = 6;

export const DEFAULT_MAP_CONFIG: IMapConfig = {
  containerElement: (
    <div className={styles.mapContainer} data-test="map_container" />
  ),
  mapElement: <div className={styles.mapElement} data-test="map_element" />,
  mapCenterPosition: { lat: 47.1164, lng: -101.2996 }, // Center of North America
} as const;

export const DEFAULT_MAP_CLUSTER_STYLES = Array.from(Array(5).keys()).map(
  (key: number) => {
    const sideValue = 32;
    return {
      url: `${DEFAULT_MAP_CLUSTER_ICONS_PATH}/m${key + 1}.png`,
      height: sideValue,
      width: sideValue,
      textColor: '#fff',
    };
  },
);

export const SHOP_GEOFENCE_OPTIONS = {
  zIndex: 1,
  fillColor: '#2c6af6', // Blue
  fillOpacity: 0.2,
  strokeColor: '#2c6af6', // Blue
  strokeOpacity: 1,
  strokeWeight: 0.5,
  radius: 160000,
} as const;

export const DEFAULT_MAX_CIRCLE_RADIUS = 160934; // Meters / 100 miles
export const EARTH_RADIUS_KILOMETERS = 6378.137; // Radius of the earth in kilometer
