import { FC } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const LittleMap: FC<IOwnProps> = ({
  className,
  fillColor = 'var(--secondaryText)',
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g stroke={fillColor}>
        <circle cx="8" cy="5" r="4" strokeWidth="2" />
        <path d="M8 9V12" strokeWidth="2" strokeLinecap="round" />
        <path d="M4 15H12" strokeWidth="2" strokeLinecap="round" />
      </g>
    </svg>
  );
};

export default LittleMap;
