import { memo, useEffect, useState, useCallback, useMemo } from 'react';

import clsx from 'clsx';
import { useLocation, Link } from 'react-router-dom';

import { IconArrowDown } from 'assets/icons/components';
import useSidebarSelector from 'components/Fleet/_hooks/useSidebarSelector';
import useTestAttrRef from 'hooks/useTestAttrRef';

import { Accordion, AccordionSummary, AccordionDetails } from './_elements';
import { MainSidebarAccordion } from './_models';

import styles from './MainSidebar.module.scss';

type Props = {
  item: MainSidebarAccordion;
  onCollapseHandler?: (isExpanded: boolean, id: string) => void;
  expanded?: string | false;
};

const SidebarAccordion = ({ item, onCollapseHandler, expanded }: Props) => {
  // Hooks
  const location = useLocation();
  const { pathname } = location;
  const linkMenuRef = useTestAttrRef<HTMLAnchorElement>(null, '', false);
  // Selectors
  const { collapsedFlag } = useSidebarSelector();

  // States
  const [activeFlag, setActiveFlag] = useState(false);

  // Local variables
  const { id, title, icon, activeIcon, children, path, paths, action } = item;

  const linkTo = useMemo(() => {
    if (path) return path;
    if (collapsedFlag && children && children[0] && children[0].path)
      // If menu is not collapsed and accordion has children push to first path
      return children[0].path;
    return '#';
  }, [path, collapsedFlag]);

  const activeParentAccordionFlag = useMemo(
    () => paths && paths.some(x => pathname.startsWith(x)),
    [paths, pathname],
  );

  const handleAccordionChange = useCallback(() => {
    if (onCollapseHandler && path && !pathname.startsWith(path))
      onCollapseHandler(false, '');
  }, [path, pathname, onCollapseHandler]);

  const arrowDownStrokeColor = activeParentAccordionFlag
    ? 'var(--primaryBorder)'
    : 'var(--sideBarIcon)';

  const accordionProps = useMemo(() => {
    if (!children) {
      return {
        expanded: false,
        onChange: handleAccordionChange,
      };
    }
    return {
      expanded: expanded === id,
      onChange: (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        handleAccordionChange();
        if (onCollapseHandler) {
          onCollapseHandler(isExpanded, id);
        }
      },
    };
  }, [handleAccordionChange, onCollapseHandler, children, id, expanded]);

  // Effects
  useEffect(() => {
    setActiveFlag(!!path && pathname.startsWith(path));
  }, [path, pathname]);

  // Permissions check
  // const { hasViewPermission } = useCheckUserPermission(permissions);
  //
  // if (!hasViewPermission) return null;

  const body = (
    <Accordion
      {...accordionProps}
      color="green !important"
      data-test={`${item.id}_menu_option`}
      id={`${item.id}_menu_option`}
    >
      <AccordionSummary
        id={id}
        className={clsx(styles.accordionSummaryItem, {
          [styles.active]:
            (activeFlag && activeIcon) || activeParentAccordionFlag,
        })}
        onClick={() => action && action(id)}
        expandIcon={
          (children && !collapsedFlag && (
            <IconArrowDown strokeColor={arrowDownStrokeColor} />
          )) ||
          null
        }
      >
        {icon && !activeFlag && !activeParentAccordionFlag && (
          <span
            className={clsx(styles.icon, {
              [styles.collapsed]: collapsedFlag,
            })}
          >
            {icon}
          </span>
        )}
        {activeIcon && (activeFlag || activeParentAccordionFlag) && (
          <span
            className={clsx(styles.icon, styles.activeIcon, {
              [styles.collapsed]: collapsedFlag,
            })}
          >
            {activeIcon}
          </span>
        )}
        {!activeIcon && activeFlag && <span className={styles.activeBullet} />}
        {!collapsedFlag && (
          <span
            className={clsx(styles.title, {
              [styles.active]: activeFlag || activeParentAccordionFlag,
            })}
          >
            {title}
          </span>
        )}
      </AccordionSummary>
      {children && !collapsedFlag && (
        <AccordionDetails
          className={clsx(styles.submenu, {
            [styles.active]: activeParentAccordionFlag,
          })}
        >
          {children.map(child => (
            <SidebarAccordion key={child.id} item={child} />
          ))}
        </AccordionDetails>
      )}
    </Accordion>
  );

  return linkTo === '#' ? (
    body
  ) : (
    <Link to={linkTo} ref={linkMenuRef}>
      {body}
    </Link>
  );
};

export default memo(SidebarAccordion);
