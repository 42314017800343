import { FC } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const IconRouteStop: FC<IOwnProps> = ({ className, fillColor = '#86898E' }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle
        cx="8"
        cy="8"
        r="4"
        transform="rotate(90 8 8)"
        stroke={fillColor}
        strokeWidth="2"
      />
      <path
        d="M4 8L1 8"
        stroke={fillColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M15 8L12 8"
        stroke={fillColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconRouteStop;
