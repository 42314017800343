import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { handleSaveInspectionDraftSuccess } from 'components/Fleet/TabInspections/AllInspectionsTab/_redux/allInspectionsActions';
import { trailerInspectionsFileToFormData } from 'components/Fleet/TabTrailers/TrailerProfile/TrailerInspections/_redux/helpers';
import {
  addTrailerInspectionPhotoCommentAction,
  deleteTrailerInspectionAction,
  deleteTrailerInspectionPhotoAction,
  deleteTrailerInspectionPhotoCommentAction,
  draftTrailerInspectionAction,
  getTrailerInspectionAction,
  getTrailerInspectionDraftAction,
  initializeDraftTrailerInspectionAction,
  updateTrailerInspectionPhotoCommentAction,
  uploadTrailerInspectionPhotoAction,
} from 'components/Fleet/TabTrailers/TrailerProfile/TrailerInspections/_redux/trailerInspectionsActions';
import {
  ADD_TRAILER_INSPECTION_PHOTO_COMMENT,
  ADD_TRAILER_INSPECTION_PHOTO_COMMENT_FAIL,
  ADD_TRAILER_INSPECTION_PHOTO_COMMENT_SUCCESS,
  DELETE_TRAILER_INSPECTION,
  DELETE_TRAILER_INSPECTION_FAIL,
  DELETE_TRAILER_INSPECTION_PHOTO,
  DELETE_TRAILER_INSPECTION_PHOTO_COMMENT,
  DELETE_TRAILER_INSPECTION_PHOTO_COMMENT_FAIL,
  DELETE_TRAILER_INSPECTION_PHOTO_COMMENT_SUCCESS,
  DELETE_TRAILER_INSPECTION_PHOTO_FAIL,
  DELETE_TRAILER_INSPECTION_PHOTO_SUCCESS,
  DELETE_TRAILER_INSPECTION_SUCCESS,
  DRAFT_TRAILER_INSPECTION,
  DRAFT_TRAILER_INSPECTION_FAIL,
  DRAFT_TRAILER_INSPECTION_SUCCESS,
  GET_TRAILER_INSPECTION,
  GET_TRAILER_INSPECTION_DRAFT,
  GET_TRAILER_INSPECTION_DRAFT_FAIL,
  GET_TRAILER_INSPECTION_DRAFT_SUCCESS,
  GET_TRAILER_INSPECTION_FAIL,
  GET_TRAILER_INSPECTION_SUCCESS,
  IAddTrailerInspectionPhotoCommentActionFail,
  IAddTrailerInspectionPhotoCommentActionSuccess,
  IDeleteTrailerInspectionActionFail,
  IDeleteTrailerInspectionActionSuccess,
  IDeleteTrailerInspectionPhotoActionFail,
  IDeleteTrailerInspectionPhotoActionSuccess,
  IDeleteTrailerInspectionPhotoCommentActionFail,
  IDeleteTrailerInspectionPhotoCommentActionSuccess,
  IDraftTrailerInspectionActionFail,
  IDraftTrailerInspectionActionSuccess,
  IGetTrailerInspectionActionFail,
  IGetTrailerInspectionActionSuccess,
  IGetTrailerInspectionDraftActionFail,
  IGetTrailerInspectionDraftActionSuccess,
  IInitialiseDraftTrailerInspectionActionFail,
  IInitialiseDraftTrailerInspectionActionSuccess,
  INITIALIZE_DRAFT_TRAILER_INSPECTION,
  INITIALIZE_DRAFT_TRAILER_INSPECTION_FAIL,
  INITIALIZE_DRAFT_TRAILER_INSPECTION_SUCCESS,
  IUpdateTrailerInspectionPhotoCommentActionFail,
  IUpdateTrailerInspectionPhotoCommentActionSuccess,
  IUploadTrailerInspectionPhotoActionFail,
  IUploadTrailerInspectionPhotoActionSuccess,
  UPDATE_TRAILER_INSPECTION_PHOTO_COMMENT,
  UPDATE_TRAILER_INSPECTION_PHOTO_COMMENT_FAIL,
  UPDATE_TRAILER_INSPECTION_PHOTO_COMMENT_SUCCESS,
  UPLOAD_TRAILER_INSPECTION_PHOTO,
  UPLOAD_TRAILER_INSPECTION_PHOTO_FAIL,
  UPLOAD_TRAILER_INSPECTION_PHOTO_SUCCESS,
} from 'components/Fleet/TabTrailers/TrailerProfile/TrailerInspections/_redux/trailerInspectionsTypes';
import api from 'utils/requests';

// GET_TRAILER_INSPECTION_DRAFT
function* getTrailerInspectionDraftSaga({
  payload: {
    data: { inspectionId },
    callback,
  },
}: ReturnType<typeof getTrailerInspectionDraftAction>) {
  try {
    const { data } = yield call(() =>
      api.get(`/v1/fleets/inspections/${inspectionId}`),
    );

    yield put<IGetTrailerInspectionDraftActionSuccess>({
      type: GET_TRAILER_INSPECTION_DRAFT_SUCCESS,
      payload: data,
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetTrailerInspectionDraftActionFail>({
        type: GET_TRAILER_INSPECTION_DRAFT_FAIL,
        payload: e,
      });
  }
}

// UPDATE_TRAILER_INSPECTION
function* draftTrailerInspectionSaga({
  payload: {
    data: { formData, inspectionId },
    callback,
  },
}: ReturnType<typeof draftTrailerInspectionAction>) {
  try {
    const { data } = yield call(() =>
      api.patch(`/v1/fleets/inspections/${inspectionId}`, formData),
    );

    yield put<IDraftTrailerInspectionActionSuccess>({
      type: DRAFT_TRAILER_INSPECTION_SUCCESS,
      payload: data.inspection,
    });

    yield put(handleSaveInspectionDraftSuccess());

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IDraftTrailerInspectionActionFail>({
        type: DRAFT_TRAILER_INSPECTION_FAIL,
        payload: e,
      });
  }
}

function* initializeDraftTrailerInspectionSaga({
  payload: {
    data: { formData },
    callback,
  },
}: ReturnType<typeof initializeDraftTrailerInspectionAction>) {
  try {
    const { data } = yield call(() =>
      api.post(`/v1/fleets/inspections`, formData),
    );

    yield put<IInitialiseDraftTrailerInspectionActionSuccess>({
      type: INITIALIZE_DRAFT_TRAILER_INSPECTION_SUCCESS,
      payload: data,
    });

    if (callback) callback(data);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IInitialiseDraftTrailerInspectionActionFail>({
        type: INITIALIZE_DRAFT_TRAILER_INSPECTION_FAIL,
        payload: e,
      });
  }
}

// DELETE_TRAILER_INSPECTION
function* deleteTrailerInspectionSaga({
  payload: {
    data: { inspectionId, trailerId },
    callback,
  },
}: ReturnType<typeof deleteTrailerInspectionAction>) {
  try {
    yield call(() =>
      api.delete(
        `/v1/fleets/trailers/${trailerId}/inspections/${inspectionId}`,
      ),
    );

    yield put<IDeleteTrailerInspectionActionSuccess>({
      type: DELETE_TRAILER_INSPECTION_SUCCESS,
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IDeleteTrailerInspectionActionFail>({
        type: DELETE_TRAILER_INSPECTION_FAIL,
        payload: e,
      });
  }
}

// GET_TRAILER_INSPECTION
function* getTrailerInspectionSaga({
  payload: {
    data: { inspectionId },
    callback,
  },
}: ReturnType<typeof getTrailerInspectionAction>) {
  try {
    const { data } = yield call(() =>
      api.get(`/v1/fleets/inspections/${inspectionId}`),
    );

    yield put<IGetTrailerInspectionActionSuccess>({
      type: GET_TRAILER_INSPECTION_SUCCESS,
      payload: { ...data },
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetTrailerInspectionActionFail>({
        type: GET_TRAILER_INSPECTION_FAIL,
        payload: e,
      });
  }
}

// UPLOAD_TRAILER_INSPECTION_PHOTO
function* uploadTrailerInspectionPhotoSaga({
  payload: {
    data: { file, view, fieldName, inspectionId, uploadIndex },
    callback,
  },
}: ReturnType<typeof uploadTrailerInspectionPhotoAction>) {
  const photoType = fieldName === 'photos' ? '/short_inspection' : '';
  try {
    const { data } = yield call(() =>
      api.post(
        `/v1/fleets/inspections/vehicles/${inspectionId}/files/${fieldName}${photoType}`,
        trailerInspectionsFileToFormData(file),
        { headers: { 'Content-Type': 'multipart/form-data' } },
      ),
    );

    yield put<IUploadTrailerInspectionPhotoActionSuccess>({
      type: UPLOAD_TRAILER_INSPECTION_PHOTO_SUCCESS,
      payload: { ...data.file, view, fieldName },
      uploadIndex,
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IUploadTrailerInspectionPhotoActionFail>({
        type: UPLOAD_TRAILER_INSPECTION_PHOTO_FAIL,
        payload: e,
      });
  }
}

// DELETE_TRAILER_INSPECTION_PHOTO
function* deleteTrailerInspectionPhotoSaga({
  payload: {
    data: { trailerId, id },
    callback,
  },
}: ReturnType<typeof deleteTrailerInspectionPhotoAction>) {
  try {
    yield call(() =>
      api.delete(`/v1/fleets/inspections/vehicles/${trailerId}/files/${id}`),
    );

    yield put<IDeleteTrailerInspectionPhotoActionSuccess>({
      type: DELETE_TRAILER_INSPECTION_PHOTO_SUCCESS,
      payload: id,
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IDeleteTrailerInspectionPhotoActionFail>({
        type: DELETE_TRAILER_INSPECTION_PHOTO_FAIL,
        payload: e,
      });
  }
}

// ADD_TRAILER_INSPECTION_PHOTO_COMMENT
function* addTrailerInspectionPhotoCommentSaga({
  payload: {
    data: { fileId, commentData },
    callback,
  },
}: ReturnType<typeof addTrailerInspectionPhotoCommentAction>) {
  try {
    const { data } = yield call(() =>
      api.post(`/v1/fleets/inspections/vehicles/files/${fileId}/comments`, {
        fleets_inspections_files_comment: commentData,
      }),
    );

    yield put<IAddTrailerInspectionPhotoCommentActionSuccess>({
      type: ADD_TRAILER_INSPECTION_PHOTO_COMMENT_SUCCESS,
      payload: { ...data, fileId },
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IAddTrailerInspectionPhotoCommentActionFail>({
        type: ADD_TRAILER_INSPECTION_PHOTO_COMMENT_FAIL,
        payload: e,
      });
  }
}

// UPDATE_TRAILER_INSPECTION_PHOTO_COMMENT
function* updateTrailerInspectionPhotoCommentSaga({
  payload: {
    data: { fileId, commentData },
    callback,
  },
}: ReturnType<typeof updateTrailerInspectionPhotoCommentAction>) {
  try {
    const { data } = yield call(() =>
      api.patch(
        `/v1/fleets/inspections/vehicles/files/${fileId}/comments/${commentData.id}`,
        {
          fleets_inspections_files_comment: commentData,
        },
      ),
    );

    yield put<IUpdateTrailerInspectionPhotoCommentActionSuccess>({
      type: UPDATE_TRAILER_INSPECTION_PHOTO_COMMENT_SUCCESS,
      payload: { ...data, fileId },
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IUpdateTrailerInspectionPhotoCommentActionFail>({
        type: UPDATE_TRAILER_INSPECTION_PHOTO_COMMENT_FAIL,
        payload: e,
      });
  }
}

// DELETE_TRAILER_INSPECTION_PHOTO_COMMENT
function* deleteTrailerInspectionPhotoCommentSaga({
  payload: {
    data: { fileId, commentId },
    callback,
  },
}: ReturnType<typeof deleteTrailerInspectionPhotoCommentAction>) {
  try {
    yield call(() =>
      api.delete(
        `/v1/fleets/inspections/vehicles/files/${fileId}/comments/${commentId}`,
      ),
    );

    yield put<IDeleteTrailerInspectionPhotoCommentActionSuccess>({
      type: DELETE_TRAILER_INSPECTION_PHOTO_COMMENT_SUCCESS,
      payload: { fileId, commentId },
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IDeleteTrailerInspectionPhotoCommentActionFail>({
        type: DELETE_TRAILER_INSPECTION_PHOTO_COMMENT_FAIL,
        payload: e,
      });
  }
}

export default function* trailersInspectionSaga() {
  yield takeLatest(GET_TRAILER_INSPECTION_DRAFT, getTrailerInspectionDraftSaga);
  yield takeLatest(DRAFT_TRAILER_INSPECTION, draftTrailerInspectionSaga);
  yield takeLatest(
    INITIALIZE_DRAFT_TRAILER_INSPECTION,
    initializeDraftTrailerInspectionSaga,
  );
  yield takeLatest(DELETE_TRAILER_INSPECTION, deleteTrailerInspectionSaga);
  yield takeEvery(GET_TRAILER_INSPECTION, getTrailerInspectionSaga);
  yield takeEvery(
    UPLOAD_TRAILER_INSPECTION_PHOTO,
    uploadTrailerInspectionPhotoSaga,
  );
  yield takeLatest(
    DELETE_TRAILER_INSPECTION_PHOTO,
    deleteTrailerInspectionPhotoSaga,
  );
  yield takeLatest(
    ADD_TRAILER_INSPECTION_PHOTO_COMMENT,
    addTrailerInspectionPhotoCommentSaga,
  );
  yield takeLatest(
    UPDATE_TRAILER_INSPECTION_PHOTO_COMMENT,
    updateTrailerInspectionPhotoCommentSaga,
  );
  yield takeLatest(
    DELETE_TRAILER_INSPECTION_PHOTO_COMMENT,
    deleteTrailerInspectionPhotoCommentSaga,
  );
}
