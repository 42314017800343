//  CREATE_CATEGORY
import {
  CREATE_CATEGORY,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAIL,
  ICreateCategoryActionSuccess,
  ICreateCategoryActionFail,
  ICreateCategoryAction,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  IUpdateCategoryAction,
  IUpdateCategoryActionSuccess,
  IUpdateCategoryActionFail,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAIL,
  GET_CATEGORY,
  IGetCategoryActionSuccess,
  IGetCategoryActionFail,
  IGetCategoryAction,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  IGetCategoriesAction,
  IGetLCategoriesActionFail,
  IGetLCategoriesActionSuccess,
  ISearchCategoriesAction,
  IClearCurrentCategoryAction,
  SEARCH_CATEGORY,
  CLEAR_CURRENT_CATEGORY,
  IRemoveCategoryAction,
  REMOVE_CATEGORY,
  IRemoveCategorySuccessAction,
  REMOVE_CATEGORY_SUCCESS,
  IRemoveCategoryFailAction,
  REMOVE_CATEGORY_FAIL,
  GET_ALL_CATEGORIES,
  IGetAllCategoriesActionSuccess,
  GET_ALL_CATEGORIES_SUCCESS,
} from 'components/Inventory/TabCategories/_redux/categoriesTypes';

// CREATE_CATEGORY
export const createCategoryAction = (
  payload: ICreateCategoryAction['payload'],
): ICreateCategoryAction => ({
  type: CREATE_CATEGORY,
  payload,
});
export const createCategoryActionSuccess = (
  payload: ICreateCategoryActionSuccess['payload'],
): ICreateCategoryActionSuccess => ({
  type: CREATE_CATEGORY_SUCCESS,
  payload,
});
export const createCategoryActionFail = (
  payload: ICreateCategoryActionFail['payload'],
): ICreateCategoryActionFail => ({
  type: CREATE_CATEGORY_FAIL,
  payload,
});

// REMOVE_CATEGORY
export const removeCategoryAction = (
  payload: IRemoveCategoryAction['payload'],
): IRemoveCategoryAction => ({
  type: REMOVE_CATEGORY,
  payload,
});
export const removeCategorySuccessAction = (
  payload: IRemoveCategorySuccessAction['payload'],
): IRemoveCategorySuccessAction => ({
  type: REMOVE_CATEGORY_SUCCESS,
  payload,
});
export const removeCategoryFailAction = (
  payload: IRemoveCategoryFailAction['payload'],
): IRemoveCategoryFailAction => ({
  type: REMOVE_CATEGORY_FAIL,
  payload,
});

// UPDATE_CATEGORY
export const updateCategoryAction = (
  payload: IUpdateCategoryAction['payload'],
): IUpdateCategoryAction => ({
  type: UPDATE_CATEGORY,
  payload,
});
export const updateCategoryActionSuccess = (
  payload: IUpdateCategoryActionSuccess['payload'],
): IUpdateCategoryActionSuccess => ({
  type: UPDATE_CATEGORY_SUCCESS,
  payload,
});
export const updateCategoryActionFail = (
  payload: IUpdateCategoryActionFail['payload'],
): IUpdateCategoryActionFail => ({
  type: UPDATE_CATEGORY_FAIL,
  payload,
});

// GET_CATEGORY
export const getCategoryAction = (payload: IGetCategoryAction['payload']) => ({
  type: GET_CATEGORY,
  payload,
});
export const getCategoryActionSuccess = (
  payload: IGetCategoryActionSuccess['payload'],
) => ({
  type: GET_CATEGORY_SUCCESS,
  payload,
});
export const getCategoryActionFail = (
  payload: IGetCategoryActionFail['payload'],
) => ({
  type: GET_CATEGORY_FAIL,
  payload,
});

// GET_CATEGORIES
export const getCategoriesAction = (
  payload: IGetCategoriesAction['payload'],
) => ({
  type: GET_CATEGORIES,
  payload,
});

export const getAllCategoriesAction = () => ({
  type: GET_ALL_CATEGORIES,
});

export const getCategoriesActionSuccess = (
  payload: IGetLCategoriesActionSuccess['payload'],
) => ({
  type: GET_CATEGORIES_SUCCESS,
  payload,
});

export const getAllCategoriesActionSuccess = (
  payload: IGetAllCategoriesActionSuccess['payload'],
) => ({
  type: GET_ALL_CATEGORIES_SUCCESS,
  payload,
});

export const getCategoriesActionFail = (
  payload: IGetLCategoriesActionFail['payload'],
) => ({
  type: GET_CATEGORIES_FAIL,
  payload,
});

// SESRCH CATEGORY
export const searchCategoriesAction = (): ISearchCategoriesAction => ({
  type: SEARCH_CATEGORY,
});

// CLEAR CURRENT CATEGORY
export const clearCurrentCategoryAction = (): IClearCurrentCategoryAction => ({
  type: CLEAR_CURRENT_CATEGORY,
});
