import { FC } from 'react';

import { useSelector } from 'react-redux';

import { userThemeSelector } from 'redux/userSettings/userSettingsSelectors';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const IconStatistics: FC<IOwnProps> = ({
  className,
  fillColor = '#2D2F36',
}) => {
  const theme = useSelector(userThemeSelector);

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3 14L3 10"
        stroke={theme === 'dark' ? 'white' : fillColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M8 14L8 6"
        stroke={theme === 'dark' ? 'white' : fillColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M13 14V2"
        stroke={theme === 'dark' ? 'white' : fillColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconStatistics;
