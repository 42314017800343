import { IDeleteFileAction } from '../../../../redux/_common/commonTypes';
import { ILocalFile } from '../../../../redux/_common/models';
import {
  CurrentSubscriptionPlan,
  SubscriptionPlans,
} from '../SettingsProfile/_models/models';
import {
  DELETE_COMPANY_FILE,
  GET_ACTIVE_PLAN,
  GET_ACTIVE_PLAN_SUCCESS,
  GET_COMPANY_FILES,
  GET_SUBSCRIPTION_PLANS,
  GET_SUBSCRIPTION_PLANS_SUCCESS,
  IDeleteCompanyFileAction,
  IGetCompanyFiles,
  IUploadCompanyFilesAction,
  UPLOAD_COMPANY_FILES,
} from './companySettingsTypes';

export const getSubscriptionPlans = (payload: boolean) => ({
  type: GET_SUBSCRIPTION_PLANS,
  payload,
});

export const getSubscriptionPlansSuccess = (payload: SubscriptionPlans) => ({
  type: GET_SUBSCRIPTION_PLANS_SUCCESS,
  payload,
});

export const getActivePlan = (callback?: () => void) => ({
  type: GET_ACTIVE_PLAN,
  callback,
});

export const getActivePlanSuccess = (payload: CurrentSubscriptionPlan) => ({
  type: GET_ACTIVE_PLAN_SUCCESS,
  payload,
});

export const uploadCompanyFilesAction = ({
  files,
}: {
  files: ILocalFile[];
}): IUploadCompanyFilesAction => ({
  type: UPLOAD_COMPANY_FILES,
  payload: { files },
});

export const deleteCompanyFileAction = ({
  fileId,
}: IDeleteFileAction['payload']): IDeleteCompanyFileAction => ({
  type: DELETE_COMPANY_FILE,
  payload: { fileId },
});

export const gerCompanyFilesAction = (): IGetCompanyFiles => ({
  type: GET_COMPANY_FILES,
});
