import { stopSubmit, startSubmit } from 'redux-form';
import { takeLatest, call, put, select } from 'redux-saga/effects';

import { setPaginationAction } from 'components/_common/Navigation/Pagination/_redux/paginationActions';
import { paginationSelector } from 'components/_common/Navigation/Pagination/_redux/paginationSelectors';
import {
  setSearchResultAction,
  setSearchResultSuccessAction,
  setSearchResultFailAction,
} from 'components/_common/SearchPanel/_redux/searchActions';
import { searchSelector } from 'components/_common/SearchPanel/_redux/searchSelectors';
import { MAX_SEARCH_RESULT } from 'components/_common/SearchPanel/constants';
import { getErrors } from 'utils/helpers';
import api from 'utils/requests';

import { transformVendorsSearchResult } from './helpers';
import {
  createVendorAction,
  createVendorSuccessAction,
  createVendorFailAction,
  updateVendorAction,
  updateVendorSuccessAction,
  updateVendorFailAction,
  getVendorsAction,
  getVendorsSuccessAction,
  getVendorsFailAction,
  getVendorByIdAction,
  getVendorByIdSuccessAction,
  getVendorByIdFailAction,
  getVendorLocationsSuccessAction,
  getVendorLocationsFailAction,
  getVendorProfileOrdersHistoryAction,
  getVendorProfileOrdersHistorySuccessAction,
  getVendorProfileOrdersHistoryFailAction,
  removeVendorAction,
  removeVendorSuccessAction,
  removeVendorFailAction,
  getAllVendorsSuccessAction,
} from './vendorsActions';
import {
  CREATE_VENDOR,
  UPDATE_VENDOR,
  GET_VENDORS,
  GET_VENDOR,
  SEARCH_VENDOR,
  GET_VENDOR_LOCATIONS,
  GET_VENDOR_PROFILE_ORDERS_HISTORY,
  REMOVE_VENDOR,
  GET_ALL_VENDORS,
} from './vendorsTypes';

function* createVendorSaga({
  payload: { data, callback },
}: ReturnType<typeof createVendorAction>) {
  const url = '/v1/inventories/vendors';
  try {
    yield put(startSubmit('createAndUpdateVendorForm'));
    const {
      data: { vendor },
    } = yield call(() => api.post(url, data));
    yield put(createVendorSuccessAction({ vendor }));
    yield put(stopSubmit('createAndUpdateVendorForm'));
    if (callback) callback(vendor.id);
  } catch (e) {
    if (api.isAxiosError(e)) {
      yield put(createVendorFailAction(e));
      yield put(stopSubmit('createAndUpdateVendorForm', getErrors(e)));
    }
  }
}

function* removeVendorSaga({
  payload: { id, callback },
}: ReturnType<typeof removeVendorAction>) {
  const url = `/v1/inventories/vendors/${id}`;
  try {
    yield call(() => api.delete(url, {}));
    yield put(removeVendorSuccessAction({ id }));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(removeVendorFailAction(e));
  }
}

function* updateVendorSaga({
  payload: {
    data: { id, ...rest },
    callback,
  },
}: ReturnType<typeof updateVendorAction>) {
  const url = `/v1/inventories/vendors/${id}`;
  try {
    yield put(startSubmit('createAndEditVendorForm'));
    const {
      data: { vendor },
    } = yield call(() => api.patch(url, rest));
    yield put(updateVendorSuccessAction(vendor));
    yield put(stopSubmit('createAndEditVendorForm'));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) {
      yield put(updateVendorFailAction(e));
      yield put(stopSubmit('createAndEditVendorForm', getErrors(e)));
    }
  }
}

function* getVendorsSaga({
  payload: {
    data: { query = '' },
    config,
    callback,
  },
}: ReturnType<typeof getVendorsAction>) {
  const { per_page, page } = yield select(paginationSelector);
  const url = `/v1/inventories/vendors?page=${page}&per_page=${per_page}${query}`;
  const usePaginationFlag =
    config && config.usePaginationFlag ? config.usePaginationFlag : true;

  try {
    const {
      data: { vendors, pagination },
    } = yield call(() => api.get(url));
    yield put(
      getVendorsSuccessAction({
        vendors,
        pagination: usePaginationFlag ? pagination : null,
      }),
    );

    // Pagination set
    if (usePaginationFlag) yield put(setPaginationAction(pagination));

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(getVendorsFailAction(e));
  }
}

function* getAllVendorsSaga() {
  const url = `/v1/inventories/vendors/all`;

  try {
    const {
      data: { vendors },
    } = yield call(() => api.get(url));
    yield put(
      getAllVendorsSuccessAction({
        vendors,
      }),
    );
  } catch (e) {
    if (api.isAxiosError(e)) yield put(getVendorsFailAction(e));
  }
}

function* getVendorByIdSaga({
  payload: {
    data: { id },
    callback,
  },
}: ReturnType<typeof getVendorByIdAction>) {
  const url = `/v1/inventories/vendors/${id}`;

  try {
    const {
      data: { vendor },
    } = yield call(() => api.get(url));

    yield put(getVendorByIdSuccessAction(vendor));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e)) yield put(getVendorByIdFailAction(e));
  }
}

// SEARCH_VENDORS
function* searchVendorSaga() {
  yield put(setSearchResultAction([]));
  let { searchValue = '' } = yield select(searchSelector);
  searchValue = searchValue.trim();
  if (searchValue) {
    try {
      const url = `/v1/inventories/vendors?page=1&per_page=${MAX_SEARCH_RESULT}&search=${searchValue}`;
      const { data } = yield call(() => api.get(url));
      yield put(
        setSearchResultSuccessAction(
          transformVendorsSearchResult(data.vendors),
        ),
      );
    } catch (e) {
      if (api.isAxiosError(e)) yield put(setSearchResultFailAction(e));
    }
  }
}

// GET VENDOR LOCATIONS
function* getVendorLocationsSaga() {
  const url = `/v1/inventories/vendors/locations`;
  try {
    const { data: vendorLocations } = yield call(() => api.get(url));

    yield put(getVendorLocationsSuccessAction(vendorLocations));
  } catch (e) {
    if (api.isAxiosError(e)) yield put(getVendorLocationsFailAction(e));
  }
}

// GET VENDOR PROFILE ORDERS HISTORY

function* getVendorProfileOrdersHistorySaga({
  payload: {
    data: { id, query = '' },
    callback,
  },
}: ReturnType<typeof getVendorProfileOrdersHistoryAction>) {
  const { per_page, page } = yield select(paginationSelector);
  const url = `/v1/inventories/vendors/${id}/items?page=${page}&per_page=${per_page}${query}`;

  try {
    const {
      data: { items, pagination },
    } = yield call(() => api.get(url));
    yield put(
      getVendorProfileOrdersHistorySuccessAction({
        items,
        pagination,
      }),
    );

    // Pagination set
    yield put(setPaginationAction(pagination));

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put(getVendorProfileOrdersHistoryFailAction(e));
  }
}

export default function* vendorsSaga() {
  yield takeLatest(CREATE_VENDOR, createVendorSaga);
  yield takeLatest(UPDATE_VENDOR, updateVendorSaga);
  yield takeLatest(REMOVE_VENDOR, removeVendorSaga);
  yield takeLatest(GET_VENDORS, getVendorsSaga);
  yield takeLatest(GET_ALL_VENDORS, getAllVendorsSaga);
  yield takeLatest(GET_VENDOR, getVendorByIdSaga);
  yield takeLatest(SEARCH_VENDOR, searchVendorSaga);
  yield takeLatest(GET_VENDOR_LOCATIONS, getVendorLocationsSaga);
  yield takeLatest(
    GET_VENDOR_PROFILE_ORDERS_HISTORY,
    getVendorProfileOrdersHistorySaga,
  );
}
