import {
  SET_SEARCH_STRING,
  SET_SEARCH_RESULT,
  SET_SEARCH_RESULT_SUCCESS,
  CLEAR_SEARCH,
  SET_SEARCH_RESULT_FAIL,
  ISetSearchResultAction,
  ISetSearchResultSuccessAction,
  ISetSearchStringAction,
  ISetSearchResultFailAction,
} from 'components/_common/SearchPanel/_redux/searchTypes';

export const setSearchStringAction = (
  payload: ISetSearchStringAction['payload'],
) => ({
  type: SET_SEARCH_STRING,
  payload,
});

export const clearSearchAction = () => ({
  type: CLEAR_SEARCH,
});

export const setSearchResultAction = (
  payload: ISetSearchResultAction['payload'],
) => ({
  type: SET_SEARCH_RESULT,
  payload,
});

export const setSearchResultSuccessAction = (
  payload: ISetSearchResultSuccessAction['payload'],
) => ({
  type: SET_SEARCH_RESULT_SUCCESS,
  payload,
});

export const setSearchResultFailAction = (
  payload: ISetSearchResultFailAction['payload'],
) => ({
  type: SET_SEARCH_RESULT_FAIL,
  payload,
});
