import { LOGOUT } from 'components/Auth/_redux/authTypes';

import {
  tollsTypes,
  GET_TOLLS,
  GET_TOLLS_SUCCESS,
  GET_TOLLS_FAIL,
  GET_TOLL,
  GET_TOLL_SUCCESS,
  GET_TOLL_FAIL,
  CREATE_TOLL,
  CREATE_TOLL_SUCCESS,
  CREATE_TOLL_FAIL,
  UPDATE_TOLL,
  UPDATE_TOLL_SUCCESS,
  UPDATE_TOLL_FAIL,
  DELETE_TOLL,
  DELETE_TOLL_SUCCESS,
  DELETE_TOLL_FAIL,
  ITollsState,
  CLEAR_STATE,
  PIN_TOLL,
  PIN_TOLL_FAIL,
  PIN_TOLL_SUCCESS,
  UNPIN_TOLL,
  UNPIN_TOLL_SUCCESS,
  UNPIN_TOLL_FAIL,
  GET_TOLL_ALERTS,
  GET_TOLL_ALERTS_SUCCESS,
  GET_TOLL_ALERTS_FAIL,
  SET_DATE_RANGE,
  GET_TOLLS_STATISTICS,
  GET_TOLLS_STATISTICS_SUCCESS,
  GET_TOLLS_STATISTICS_FAIL,
  GET_TOLLS_ROADS_STATISTICS,
  GET_TOLLS_ROADS_STATISTICS_SUCCESS,
  GET_TOLLS_ROADS_STATISTICS_FAIL,
  GET_TOLLS_TRUCKS_STATISTICS,
  GET_TOLLS_TRUCKS_STATISTICS_SUCCESS,
  GET_TOLLS_TRUCKS_STATISTICS_FAIL,
} from './tollsTypes';

const initialState: ITollsState = {
  pagination: null,
  list: [],
  currentTollAlerts: [],
  currentToll: null,
  dateRange: 'lastWeek',
  statistics: null,
  statisticsRoads: [],
  statisticsTrucks: [],
  _pending: false,
  _loaded: false,
  _error: null,
};

const tollsReducers = (
  state = initialState,
  action: tollsTypes,
): ITollsState => {
  switch (action.type) {
    // GET_TOLLS
    case GET_TOLLS:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case GET_TOLLS_SUCCESS:
      return {
        ...state,
        list: action.payload.geofences,
        pagination: action.payload.pagination,
        _loaded: true,
        _pending: false,
      };
    case GET_TOLLS_FAIL:
      return {
        ...state,
        _error: action.payload,
        _loaded: false,
        _pending: false,
      };

    // GET_TOLL
    case GET_TOLL:
      return {
        ...state,
        _error: null,
      };
    case GET_TOLL_SUCCESS:
      return {
        ...state,
        currentToll: action.payload.geofence,
      };
    case GET_TOLL_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // CREATE_TOLL
    case CREATE_TOLL:
      return { ...state, _error: null };
    case CREATE_TOLL_SUCCESS:
      return {
        ...state,
        currentToll: action.payload.geofence,
        list: [action.payload.geofence, ...state.list],
        pagination: state.pagination
          ? { ...state.pagination, total: state.pagination.total + 1 }
          : null,
      };

    case CREATE_TOLL_FAIL:
      return { ...state, _error: action.payload };

    // UPDATE_TOLL
    case UPDATE_TOLL:
      return { ...state, _error: null };
    case UPDATE_TOLL_SUCCESS:
      return {
        ...state,
        currentToll: action.payload.geofence,
        list: state.list.map(item =>
          item.id === action.payload.geofence.id
            ? action.payload.geofence
            : item,
        ),
      };
    case UPDATE_TOLL_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // DELETE_TOLL
    case DELETE_TOLL:
      return { ...state, _error: null };
    case DELETE_TOLL_SUCCESS:
      return {
        ...state,
        currentToll: null,
        list: state.list.filter(item => item.id !== action.payload.id),
        pagination: state.pagination
          ? { ...state.pagination, total: state.pagination.total - 1 }
          : null,
      };
    case DELETE_TOLL_FAIL:
      return { ...state, _error: action.payload };

    case CLEAR_STATE: {
      return { ...state, ...action.payload };
    }

    // PIN_TOLL
    case PIN_TOLL:
      return { ...state, _error: null };
    case PIN_TOLL_FAIL:
      return { ...state, _error: action.payload };
    case PIN_TOLL_SUCCESS:
      return {
        ...state,
        list: state.list.map(toll => ({
          ...toll,
          pinned: toll.id === +action.payload ? true : toll.pinned,
        })),
      };
    // UNPIN_TOLL
    case UNPIN_TOLL:
      return { ...state, _error: null };
    case UNPIN_TOLL_SUCCESS:
      return {
        ...state,
        list: state.list.map(toll => ({
          ...toll,
          pinned: toll.id === +action.payload ? false : toll.pinned,
        })),
      };
    case UNPIN_TOLL_FAIL:
      return { ...state, _error: action.payload };

    // GET_TOLL_ALERTS
    case GET_TOLL_ALERTS:
      return {
        ...state,
        _error: null,
        currentTollAlerts: [],
        currentToll: null,
        _pending: true,
        _loaded: false,
      };
    case GET_TOLL_ALERTS_SUCCESS:
      return {
        ...state,
        currentTollAlerts: action.payload.geofences_alerts,
        pagination: action.payload.pagination,
        _pending: false,
        _loaded: true,
      };
    case GET_TOLL_ALERTS_FAIL:
      return {
        ...state,
        _error: action.payload,
        _loaded: false,
        _pending: false,
      };

    // GET_TOLLS_STATISTICS
    case GET_TOLLS_STATISTICS:
      return {
        ...state,
        _error: null,
        _pending: true,
        _loaded: false,
      };
    case GET_TOLLS_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: action.payload,
        _pending: false,
        _loaded: true,
      };
    case GET_TOLLS_STATISTICS_FAIL:
      return {
        ...state,
        _error: action.payload,
        _loaded: false,
        _pending: false,
      };

    // GET_TOLLS_ROADS_STATISTICS
    case GET_TOLLS_ROADS_STATISTICS:
      return {
        ...state,
        _error: null,
        _pending: true,
        _loaded: false,
      };
    case GET_TOLLS_ROADS_STATISTICS_SUCCESS:
      return {
        ...state,
        statisticsRoads: action.payload,
        _pending: false,
        _loaded: true,
      };
    case GET_TOLLS_ROADS_STATISTICS_FAIL:
      return {
        ...state,
        _error: action.payload,
        _loaded: false,
        _pending: false,
      };

    // GET_TOLLS_TRUCKS_STATISTICS
    case GET_TOLLS_TRUCKS_STATISTICS:
      return {
        ...state,
        _error: null,
        _pending: true,
        _loaded: false,
      };
    case GET_TOLLS_TRUCKS_STATISTICS_SUCCESS:
      return {
        ...state,
        statisticsTrucks: action.payload,
        _pending: false,
        _loaded: true,
      };
    case GET_TOLLS_TRUCKS_STATISTICS_FAIL:
      return {
        ...state,
        _error: action.payload,
        _loaded: false,
        _pending: false,
      };

    // SET_DATE_RANGE
    case SET_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload,
      };

    // LOGOUT
    case LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default tollsReducers;
