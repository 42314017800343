import {
  FC,
  useMemo,
  FormEvent,
  useState,
  ReactNode,
  useCallback,
} from 'react';

import { ClickAwayListener, Popper } from '@mui/material';
import clsx from 'clsx';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import { IconArrowDown } from 'assets/icons/components';
import { ISelectOptions } from 'components/_common/FormElements/FormField/Select/_models/selectModels';
import useTestAttrRef from 'hooks/useTestAttrRef';
import useToggle from 'hooks/useToggle';

import MultiOption from './Option/MultiOption';

import styles from './Select.module.scss';

export type Props = {
  options?: ISelectOptions;
  placeholder?: string;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  touched?: any;
  disabled?: boolean;
  chip?: JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: any) => void;
  name?: string;
  isPortalDisabled?: boolean;
};

const MultiSelectImpl: FC<Props> = ({
  options = {},
  className,
  placeholder = '',
  value,
  chip,
  error,
  touched,
  disabled = false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = () => {},
  name,
  isPortalDisabled = true,
}) => {
  const { isOpen, toggleVisibility, open, close } = useToggle();
  const [cursor, setCursor] = useState(0);
  const checkedValues: number[] = value || [];
  const selectRef = useTestAttrRef<HTMLDivElement>(null);
  const useNonOption = useMemo(() => {
    return options && options[1] && options[1].name === 'NONE';
  }, [options]);
  const isNone = useNonOption && checkedValues.includes(1);

  const focusSelect = () => {
    if (selectRef?.current) {
      selectRef.current.focus();
    }
  };

  const renderValues = useMemo(() => {
    if (chip) {
      return chip;
    }
    if (checkedValues.length === 0) {
      return <span className={styles.placeholder}>{placeholder}</span>;
    }
    return checkedValues
      .map((key: number) => options && options[key]?.name)
      .join(', ');
  }, [checkedValues, placeholder, chip]);

  const handleOnChange = (e: FormEvent<HTMLLIElement>): void => {
    e.stopPropagation();
    e.preventDefault();
    const id = +e.currentTarget.value;
    const checked = e.currentTarget.getAttribute('data-checked');

    const currentOptionIndex = Object.values(options).findIndex(
      option => option.id === id,
    );
    setCursor(currentOptionIndex);

    if (checked === 'true') {
      onChange(checkedValues.filter((item: number) => item !== id));
    } else {
      const values = useNonOption && id === 1 ? [1] : [...checkedValues, id];
      onChange(values);
    }
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const { key } = event;

    if (
      (key === 'ArrowDown' || key === 'ArrowUp' || key === 'Enter') &&
      !isOpen
    ) {
      open();
    }
  };

  const updateCursor = (down: boolean) => {
    let newValue;
    const optionsLength = Object.keys(options).length;

    if (!down) {
      newValue = cursor === 0 ? optionsLength : cursor - 1;
    } else {
      newValue = cursor === optionsLength ? 0 : cursor + 1;
    }

    setCursor(newValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    /**
     * To avoid browser scroll triggering
     */
    event.preventDefault();

    const { key } = event;

    const noneOptionSelected = useNonOption && checkedValues.includes(1);
    if (!noneOptionSelected) {
      if (key === 'ArrowUp') {
        updateCursor(false);
      }
      if (key === 'ArrowDown') {
        updateCursor(true);
      }
    }

    if (key === 'Tab' || key === 'Escape') {
      close();
      focusSelect();
    }

    if (key === 'Enter') {
      const currentOption = Object.values(options)[cursor];
      const currentOptionId = currentOption.id as number;

      const isChecked = checkedValues.includes(currentOptionId);
      if (isChecked) {
        onChange(
          checkedValues.filter((item: number) => item !== currentOptionId),
        );
      } else {
        const values =
          useNonOption && currentOptionId === 1
            ? [1]
            : [...checkedValues, currentOptionId];
        onChange(values);
      }
    }
  };

  const getChildren = useCallback(
    (): ReactNode =>
      options &&
      Object.keys(options).map((key, index) => {
        const { id, name: optionName } = options[key];
        const checked = checkedValues.some(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (item: any) => item === id,
        );

        return (
          <MultiOption
            key={key}
            id={id}
            name={optionName}
            checked={checked}
            isNone={isNone}
            handleOnChange={handleOnChange}
            focus={cursor === index}
          />
        );
      }),
    [options, checkedValues, cursor],
  );

  return (
    <>
      <div
        id={name}
        className={clsx(styles.root, chip && styles.rootChip, className, {
          [styles.activeBorder]: isOpen,
          [styles.hasError]: touched && !!error,
          [styles.disabled]: disabled,
        })}
        ref={selectRef}
        role="listbox"
        tabIndex={0}
        onClick={toggleVisibility}
        onMouseDown={e => {
          if (isOpen) e.stopPropagation();
        }}
        onKeyDown={handleInputKeyDown}
      >
        {renderValues}

        {!disabled && (
          <IconArrowDown className={clsx({ [styles.rotate]: isOpen })} />
        )}
      </div>
      <Popper
        anchorEl={selectRef.current}
        open={isOpen}
        style={{ zIndex: 100 }}
        disablePortal={isPortalDisabled}
      >
        <ClickAwayListener mouseEvent="onMouseDown" onClickAway={close}>
          <div
            onKeyDown={handleKeyDown}
            className={styles.popperBody}
            // style={{ width: selectRef.current?.clientWidth }}
          >
            <div className={styles.popperContent}>{getChildren()}</div>
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export { MultiSelectImpl };

type MultiSelectProps = Props & WrappedFieldInputProps & WrappedFieldMetaProps;

const MultiSelect: FC<Partial<MultiSelectProps>> = props => {
  return <MultiSelectImpl {...props} />;
};

export default MultiSelect;
