import { AxiosError } from 'axios';

import { IImportFileReducer } from './importFileModel';

export const IMPORT_FILE = '[@@Import] IMPORT_FILE';
export const IMPORT_FILE_SUCCESS = '[@@Import] IMPORT_FILE_SUCCESS';
export const IMPORT_FILE_FAIL = '[@@Import] IMPORT_FILE_FAIL';

export interface IImportFileAction {
  type: typeof IMPORT_FILE;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}
export interface IImportFileActionSuccess {
  type: typeof IMPORT_FILE_SUCCESS;
  payload: IImportFileReducer;
}
export interface IImportFileActionFail {
  type: typeof IMPORT_FILE_FAIL;
  payload: AxiosError;
}

export const IMPORT_FILE_MATCH_FIELDS = '[@@Import] IMPORT_FILE_MATCH_FIELDS';
export const IMPORT_FILE_MATCH_FIELDS_SUCCESS =
  '[@@Import] IMPORT_FILE_MATCH_FIELDS_SUCCESS';
export const IMPORT_FILE_MATCH_FIELDS_FAIL =
  '[@@Import] IMPORT_FILE_MATCH_FIELDS_FAIL';

export interface IImportFileMatchFieldsAction {
  type: typeof IMPORT_FILE_MATCH_FIELDS;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}
export interface IImportFileMatchFieldsActionSuccess {
  type: typeof IMPORT_FILE_MATCH_FIELDS_SUCCESS;
  payload: IImportFileReducer;
}
export interface IImportFileMatchFieldsActionFail {
  type: typeof IMPORT_FILE_MATCH_FIELDS_FAIL;
  payload: AxiosError;
}

export const IMPORT_FILE_START_CREATE = '[@@Import] IMPORT_FILE_START_CREATE';
export const IMPORT_FILE_START_CREATE_SUCCESS =
  '[@@Import] IMPORT_FILE_START_CREATE_SUCCESS';
export const IMPORT_FILE_START_CREATE_FAIL =
  '[@@Import] IMPORT_FILE_START_CREATE_FAIL';

export interface IImportFileStartCreateAction {
  type: typeof IMPORT_FILE_START_CREATE;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}
export interface IImportFileStartCreateActionSuccess {
  type: typeof IMPORT_FILE_START_CREATE_SUCCESS;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}
export interface IImportFileStartCreateActionFail {
  type: typeof IMPORT_FILE_START_CREATE_FAIL;
  payload: AxiosError;
}

export type importFilesActionsType =
  | IImportFileAction
  | IImportFileActionSuccess
  | IImportFileActionFail
  | IImportFileMatchFieldsAction
  | IImportFileMatchFieldsActionSuccess
  | IImportFileMatchFieldsActionFail
  | IImportFileStartCreateAction
  | IImportFileStartCreateActionSuccess
  | IImportFileStartCreateActionFail;
