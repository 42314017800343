import { takeLatest, call, put } from 'redux-saga/effects';

import api from 'utils/requests';

import { getSchedulerAction } from './calendarActions';
import {
  GET_SCHEDULER,
  GET_SCHEDULER_SUCCESS,
  GET_SCHEDULER_FAIL,
  IGetSchedulerActionSuccess,
  IGetSchedulerActionFail,
} from './calendarTypes';

function* getSchedulerSaga({
  payload: { date_from, date_to, callback },
}: ReturnType<typeof getSchedulerAction>) {
  try {
    const {
      data: { drivers, data, ...rest },
    } = yield call(() =>
      api.get(
        date_from && date_to
          ? `/v1/orders/scheduler?date_from=${date_from}&date_to=${date_to}`
          : '/v1/orders/scheduler',
      ),
    );
    yield put<IGetSchedulerActionSuccess>({
      type: GET_SCHEDULER_SUCCESS,
      payload: {
        drivers,
        data,
        date_to: rest.date_to,
        date_from: rest.date_from,
      },
    });
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetSchedulerActionFail>({
        type: GET_SCHEDULER_FAIL,
        payload: e,
      });
  }
}

export default function* calendarSaga() {
  yield takeLatest(GET_SCHEDULER, getSchedulerSaga);
}
