import { FC } from 'react';

type OwnProps = {};
type Props = OwnProps;

const IconFlagCanada: FC<Props> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7241 13.2406H0.275874C0.123531 13.2406 0 13.117 0 12.9647V3.03369C0 2.88134 0.123531 2.75781 0.275874 2.75781H15.7241C15.8765 2.75781 16 2.88134 16 3.03369V12.9647C16 13.1171 15.8764 13.2406 15.7241 13.2406Z"
        fill="#F5F5F5"
      />
      <path
        d="M12.4144 2.75781H3.58691V13.2406H12.4144V2.75781Z"
        fill="#F5F5F5"
      />
      <path
        d="M0.275874 2.75977C0.123531 2.75977 0 2.88327 0 3.03561V12.9666C0 13.119 0.123531 13.2425 0.275874 13.2425H3.58621V2.75977H0.275874Z"
        fill="#FF4B55"
      />
      <path
        d="M15.7235 2.75977H12.4131V13.2425H15.7235C15.8758 13.2425 15.9993 13.119 15.9993 12.9666V3.03561C15.9993 2.88327 15.8758 2.75977 15.7235 2.75977Z"
        fill="#FF4B55"
      />
      <path
        d="M9.67374 9.21324L11.3107 8.27786L10.9351 8.09005C10.8297 8.03736 10.7694 7.92361 10.785 7.80683L10.9072 6.89052L10.2142 7.09214C10.0611 7.1367 9.90224 7.04252 9.86781 6.88686L9.80143 6.58686L9.15072 7.34842C9.0605 7.45399 8.88865 7.37336 8.91219 7.23649L9.19009 5.62061L8.76462 5.73698C8.64034 5.77099 8.50909 5.71442 8.4485 5.60073L8.00047 4.76102V4.75977L8.00016 4.76039L7.99981 4.75977V4.76102L7.55178 5.60073C7.49113 5.71439 7.35988 5.77095 7.2356 5.73698L6.8101 5.62061L7.08801 7.23649C7.11154 7.3733 6.93966 7.45395 6.84944 7.34842L6.19873 6.58686L6.13235 6.88686C6.09792 7.04255 5.93904 7.13667 5.78595 7.09214L5.09292 6.89052L5.21514 7.80683C5.23073 7.92358 5.17042 8.03736 5.06508 8.09005L4.68945 8.27786L6.32641 9.21327C6.49598 9.31017 6.57029 9.51567 6.50188 9.69858L6.36195 10.0729L7.7646 9.95311C7.84222 9.94649 7.90828 10.009 7.906 10.0868L7.86181 11.5882H8.13769L8.09347 10.0868C8.09119 10.0089 8.15725 9.94642 8.23488 9.95305L9.63818 10.0729L9.49825 9.69858C9.4299 9.51564 9.50418 9.31011 9.67374 9.21324Z"
        fill="#FF4B55"
      />
    </svg>
  );
};

export default IconFlagCanada;
