import { useRef, useEffect } from 'react';

const usePrevious = <T>(value: T, currentValue?: T): T | undefined => {
  const ref = useRef(currentValue);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export default usePrevious;
