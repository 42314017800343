import { FC } from 'react';

interface IOwnProps {
  className?: string;
}

const IconSend: FC<IOwnProps> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.7068 0.292637C15.4338 0.0206365 15.0268 -0.0723635 14.6638 0.0586365L0.663812 5.05864C0.286812 5.19264 0.0258123 5.53964 0.00181231 5.93864C-0.0221877 6.33864 0.193812 6.71364 0.552812 6.89364L5.13881 9.18564L10.9998 4.99964L6.81281 10.8616L9.10481 15.4476C9.27581 15.7866 9.62281 15.9996 9.99981 15.9996C10.0208 15.9996 10.0408 15.9986 10.0608 15.9976C10.4608 15.9736 10.8078 15.7136 10.9428 15.3356L15.9428 1.33564C16.0718 0.972636 15.9798 0.565637 15.7068 0.292637Z"
        fill="white"
      />
    </svg>
  );
};

export default IconSend;
