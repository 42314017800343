import { FC } from 'react';

import { useSelector } from 'react-redux';

import { userThemeSelector } from 'redux/userSettings/userSettingsSelectors';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const IconSave: FC<IOwnProps> = ({ className, fillColor }) => {
  const theme = useSelector(userThemeSelector);

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 3C1 1.89543 1.89543 1 3 1H10.6972C11.0921 1 11.4781 1.11688 11.8066 1.3359L14.1094 2.87108C14.6658 3.24201 15 3.86648 15 4.53518V13C15 14.1046 14.1046 15 13 15H3C1.89543 15 1 14.1046 1 13V3ZM7 3H9V4H7V3ZM3 3L5 3V5C5 5.55228 5.44772 6 6 6H10C10.5523 6 11 5.55228 11 5V3.20185L13 4.53518V13H12V9C12 8.44772 11.5523 8 11 8L5 8C4.44771 8 4 8.44772 4 9L4 13L3 13V3ZM6 13H10V10H6L6 13Z"
        fill={fillColor || (theme === 'dark' ? 'white' : '#AF49FF')}
      />
    </svg>
  );
};

export default IconSave;
