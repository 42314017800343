import { formatPlansToMonthlyAndYearly } from '../_helpers/_helper';
import {
  CompanySettingsReduces,
  companySettingsTypes,
  DELETE_COMPANY_FILE,
  DELETE_COMPANY_FILE_FAIL,
  DELETE_COMPANY_FILE_SUCCESS,
  GET_ACTIVE_PLAN,
  GET_ACTIVE_PLAN_SUCCESS,
  GET_COMPANY_FILES,
  GET_COMPANY_FILES_FAIL,
  GET_COMPANY_FILES_SUCCESS,
  GET_SUBSCRIPTION_PLANS_SUCCESS,
  UPLOAD_COMPANY_FILES,
  UPLOAD_COMPANY_FILES_FAIL,
  UPLOAD_COMPANY_FILES_SUCCESS,
} from './companySettingsTypes';

const initialState: CompanySettingsReduces = {
  monthlyPlans: [],
  yearlyPlans: [],
  customPlans: [],
  activePlan: null,
  files: [],
  _error: null,
  _loaded: false,
  _pending: false,
};

const companySettingsReduces = (
  state = initialState,
  action: companySettingsTypes,
): CompanySettingsReduces => {
  switch (action.type) {
    case GET_SUBSCRIPTION_PLANS_SUCCESS: {
      const { yearlyPlans, monthlyPlans, customPlans } =
        formatPlansToMonthlyAndYearly(action.payload);
      return {
        ...state,
        monthlyPlans,
        yearlyPlans,
        customPlans,
      };
    }

    case GET_ACTIVE_PLAN:
      return {
        ...state,
        _loaded: false,
      };

    case GET_ACTIVE_PLAN_SUCCESS:
      return {
        ...state,
        activePlan: action.payload,
        _loaded: true,
      };

    case UPLOAD_COMPANY_FILES:
      return {
        ...state,
        files: [
          ...state.files,
          ...action.payload.files.map(
            file =>
              ({
                ...file,
                loading: true,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } as any),
          ),
        ],
      };
    case UPLOAD_COMPANY_FILES_SUCCESS:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        files: state.files.map((file: any, index: number) =>
          index === state.files.findIndex(({ loading }) => loading)
            ? { ...action.payload, loading: false }
            : file,
        ),
      };
    case UPLOAD_COMPANY_FILES_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    case DELETE_COMPANY_FILE:
      return state;
    case DELETE_COMPANY_FILE_SUCCESS:
      return {
        ...state,
        files: state.files.filter(file => file.id !== action.payload),
      };
    case DELETE_COMPANY_FILE_FAIL:
      return {
        ...state,
        _error: action.payload,
      };
    case GET_COMPANY_FILES:
      return {
        ...state,
        _loaded: true,
      };
    case GET_COMPANY_FILES_SUCCESS:
      return {
        ...state,
        files: action.payload,
      };
    case GET_COMPANY_FILES_FAIL:
      return {
        ...state,
        _error: action.payload,
      };
    default:
      return { ...state };
  }
};

export default companySettingsReduces;
