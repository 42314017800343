import { RadioBlockConfig } from 'components/_common/FormElements/RadioBlock/RadioBlock';
import { BROKER_API_PREFIX } from 'constants/api';

export const BROKER_FORM_NAME = 'BrokerForm';
export const getFileDownloadPath = (itemId: number, fileId: string) =>
  `${BROKER_API_PREFIX}/${itemId}/files/${fileId}`;

export const OVERNIGHT_ADDRESS_OPTIONS: RadioBlockConfig = [
  {
    value: 'overnight_address_is_same',
    label: 'Overnight Address is the same as Billing Address',
  },
  {
    value: 'overnight_address_is_different',
    label: 'Overnight Address is different as Billing Address',
  },
];

export const BROKER_ADDRESS_FIELDS = [
  'address',
  'city',
  'country_id',
  'state_id',
  'zip_code',
  'ext_phone_number',
  'email',
  'first_name',
  'last_name',
  'phone_number',
];
