import { AxiosError, AxiosRequestConfig } from 'axios';

import { IPagination } from 'components/_common/Navigation/Pagination/_redux/paginationTypes';
import { ILogout } from 'redux/_common/models';
import { IWithError, IWithLoaded, IWithPending } from 'utils/models';

import {
  AccountEntryType,
  AddDeduction,
  DeductionPayment,
  EditDeduction,
  IAccountEntry,
  IAccountEntryItem,
  IAccountFuelDriverItem,
  IAccountFuelItem,
  IAccountFuelStatistics,
  IDeductionsTableItem,
  IDriver,
  IStoredAccountEntry,
} from '../_models/payrollItemsModels';

interface IDrivers {
  list: IDriver[];
  pagination?: IPagination;
}

interface IAccountFuelList {
  list: IAccountFuelItem[];
  isFiltered: boolean;
  statistics?: {
    discounted_amount: IAccountFuelStatistics;
    drivers: IAccountFuelStatistics;
    gallons: IAccountFuelStatistics;
    total_amount: IAccountFuelStatistics;
  };
  pagination?: IPagination;
}

interface IAccountFuelDriverList {
  list: IAccountFuelDriverItem[];
  isFiltered: boolean;
  statistics?: {
    id: number; // driver_id
    cooperation_type: string;
    driver_name: string;
    truck_number?: string;
    fuel_card: string;
    fuel_amount: number;
    other_amount: number;
    total_amount: number;
    ppg: number;
  };
  pagination?: IPagination;
}

export interface IDriversReducer
  extends IDrivers,
    IWithPending,
    IWithLoaded,
    IWithError {}

export interface IDeductionPayments extends IWithLoaded, IWithError {
  list: DeductionPayment[];
  pagination?: IPagination;
}

export interface IDeductionsReducer extends IWithLoaded, IWithError {
  list: IDeductionsTableItem[];
  deductionDetails: IDeductionsTableItem | null;
  deductionPayments: IDeductionPayments;
  pagination?: IPagination;
}

export interface IAccountEntriesReducer extends IWithLoaded, IWithError {
  list: IAccountEntryItem[];
  pagination?: IPagination;
}

export interface IAccountFuelReducer
  extends IAccountFuelList,
    IWithPending,
    IWithLoaded,
    IWithError {}

export interface IAccountFuelDriverReducer
  extends IAccountFuelDriverList,
    IWithPending,
    IWithLoaded,
    IWithError {}

export interface IAccountingPayrollItemsReducer {
  schedDeductionDrivers: IDriversReducer; // list of drivers who have scheduled deduction payroll items
  deductions: IDeductionsReducer;
  credits: IAccountEntriesReducer;
  fuel: IAccountFuelReducer;
  fuelDriver: IAccountFuelDriverReducer;
}

// GET SCHEDULED DEDUCTION DRIVERS
export const GET_SCHED_DEDUCTION_DRIVERS =
  '[@@Accounting_Payroll_Items] GET_SCHED_DEDUCTION_DRIVERS';
export const GET_SCHED_DEDUCTION_DRIVERS_SUCCESS =
  '[@@Accounting_Payroll_Items] GET_SCHED_DEDUCTION_DRIVERS_SUCCESS';
export const GET_SCHED_DEDUCTION_DRIVERS_FAIL =
  '[@@Accounting_Payroll_Items] GET_SCHED_DEDUCTION_DRIVERS_FAIL';

export interface IGetSchedDeductionDriversAction {
  type: typeof GET_SCHED_DEDUCTION_DRIVERS;
  payload?: { driverName?: string; usePagination?: boolean; driverId?: number };
}

export interface IGetSchedDeductionDriversActionSuccess {
  type: typeof GET_SCHED_DEDUCTION_DRIVERS_SUCCESS;
  payload: IDrivers;
}

export interface IGetSchedDeductionDriversActionFail {
  type: typeof GET_SCHED_DEDUCTION_DRIVERS_FAIL;
  payload: AxiosError;
}

// GET ACCOUNT ENTRIES (CREDITS)
export const GET_ACCOUNT_ENTRIES =
  '[@@Accounting_Payroll_Items] GET_ACCOUNT_ENTRIES';
export const GET_ACCOUNT_ENTRIES_SUCCESS =
  '[@@Accounting_Payroll_Items] GET_ACCOUNT_ENTRIES_SUCCESS';
export const GET_ACCOUNT_ENTRIES_FAIL =
  '[@@Accounting_Payroll_Items] GET_ACCOUNT_ENTRIES_FAIL';

export interface IGetAccountEntriesAction {
  type: typeof GET_ACCOUNT_ENTRIES;
  entryType: AccountEntryType;
  params?: AxiosRequestConfig['params'];
  query?: string;
}

export interface IGetAccountEntriesActionSuccess {
  type: typeof GET_ACCOUNT_ENTRIES_SUCCESS;
  payload: {
    entryType: AccountEntryType;
    list: IAccountEntryItem[];
    pagination: IPagination;
  };
}

export interface IGetAccountEntriesActionFail {
  type: typeof GET_ACCOUNT_ENTRIES_FAIL;
  payload: { entryType: AccountEntryType; error: AxiosError };
}

// GET ACCOUNT FUEL
export const GET_ACCOUNT_FUEL = '[@@Accounting_Payroll_Items] GET_ACCOUNT_FUEL';
export const GET_ACCOUNT_FUEL_SUCCESS =
  '[@@Accounting_Payroll_Items] GET_ACCOUNT_FUEL_SUCCESS';
export const GET_ACCOUNT_FUEL_FAIL =
  '[@@Accounting_Payroll_Items] GET_ACCOUNT_FUEL_FAIL';

export interface IGetAccountFuelAction {
  type: typeof GET_ACCOUNT_FUEL;
  params?: AxiosRequestConfig['params'];
}

export interface IGetAccountFuelActionSuccess {
  type: typeof GET_ACCOUNT_FUEL_SUCCESS;
  payload: IAccountFuelList;
}

export interface IGetAccountFuelActionFail {
  type: typeof GET_ACCOUNT_FUEL_FAIL;
  payload: AxiosError;
}

// GET DRIVER FUEL
export const GET_DRIVER_FUEL = '[@@Accounting_Payroll_Items] GET_DRIVER_FUEL';
export const GET_DRIVER_FUEL_SUCCESS =
  '[@@Accounting_Payroll_Items] GET_DRIVER_FUEL_SUCCESS';
export const GET_DRIVER_FUEL_FAIL =
  '[@@Accounting_Payroll_Items] GET_DRIVER_FUEL_FAIL';

export interface IGetDriverFuelAction {
  type: typeof GET_DRIVER_FUEL;
  driverId: number;
  params?: AxiosRequestConfig['params'];
}

export interface IGetDriverFuelActionSuccess {
  type: typeof GET_DRIVER_FUEL_SUCCESS;
  payload: IAccountFuelDriverList;
}

export interface IGetDriverFuelActionFail {
  type: typeof GET_DRIVER_FUEL_FAIL;
  payload: AxiosError;
}

// CREATE / UPDATE ACCOUNT ENTRY (CREDIT)
export const CREATE_ACCOUNT_ENTRY =
  '[@@Accounting_Payroll_Items] CREATE_ACCOUNT_ENTRY';
export const UPDATE_ACCOUNT_ENTRY =
  '[@@Accounting_Payroll_Items] UPDATE_ACCOUNT_ENTRY';
export const STORE_ACCOUNT_ENTRY_SUCCESS =
  '[@@Accounting_Payroll_Items] STORE_ACCOUNT_ENTRY_SUCCESS';
export const STORE_ACCOUNT_ENTRY_FAIL =
  '[@@Accounting_Payroll_Items] STORE_ACCOUNT_ENTRY_FAIL';

export interface ICreateAccountEntryAction {
  type: typeof CREATE_ACCOUNT_ENTRY;
  payload: {
    driverId: number;
    entryType: AccountEntryType;
    entry: IAccountEntry;
    callback?: (payload: IStoreAccountEntryActionSuccess['payload']) => void;
  };
}

export interface IUpdateAccountEntryAction {
  type: typeof UPDATE_ACCOUNT_ENTRY;
  payload: {
    entryType: AccountEntryType;
    entry: IStoredAccountEntry;
    callback?: (payload: IStoreAccountEntryActionSuccess['payload']) => void;
  };
}

export interface IStoreAccountEntryActionSuccess {
  type: typeof STORE_ACCOUNT_ENTRY_SUCCESS;
  payload: { entryType: AccountEntryType; entry: IStoredAccountEntry };
}

export interface IStoreAccountEntryActionFail {
  type: typeof STORE_ACCOUNT_ENTRY_FAIL;
  payload: { entryType: AccountEntryType; error: AxiosError };
}

// DELETE ACCOUNT ENTRY (CREDIT)
export const DELETE_ACCOUNT_ENTRY =
  '[@@Accounting_Payroll_Items] DELETE_ACCOUNT_ENTRY';
export const DELETE_ACCOUNT_ENTRY_SUCCESS =
  '[@@Accounting_Payroll_Items] DELETE_ACCOUNT_ENTRY_SUCCESS';
export const DELETE_ACCOUNT_ENTRY_FAIL =
  '[@@Accounting_Payroll_Items] DELETE_ACCOUNT_ENTRY_FAIL';

export interface IDeleteAccountEntryAction {
  type: typeof DELETE_ACCOUNT_ENTRY;
  payload: {
    driverId: number;
    entryId: number;
    entryType: AccountEntryType;
    callback?: (payload: IDeleteAccountEntryActionSuccess['payload']) => void;
  };
}

export interface IDeleteAccountEntryActionSuccess {
  type: typeof DELETE_ACCOUNT_ENTRY_SUCCESS;
  payload: {
    driverId: number;
    entryId: number;
    entryType: AccountEntryType;
  };
}

export interface IDeleteAccountEntryActionFail {
  type: typeof DELETE_ACCOUNT_ENTRY_FAIL;
  payload: { entryType: AccountEntryType; error: AxiosError };
}

// GET DEDUCTIONS
export const GET_DEDUCTIONS = '[@@Accounting_Payroll_Items] GET_DEDUCTIONS';
export const GET_DEDUCTIONS_SUCCESS =
  '[@@Accounting_Payroll_Items] GET_DEDUCTIONS_SUCCESS';
export const GET_DEDUCTIONS_FAIL =
  '[@@Accounting_Payroll_Items] GET_DEDUCTIONS_FAIL';

export interface IGetDeductionsAction {
  type: typeof GET_DEDUCTIONS;
  params?: AxiosRequestConfig['params'];
  query?: string;
}

export interface IGetDeductionsActionSuccess {
  type: typeof GET_DEDUCTIONS_SUCCESS;
  payload: {
    list: IDeductionsTableItem[];
    pagination: IPagination;
  };
}

export interface IGetDeductionsActionFail {
  type: typeof GET_DEDUCTIONS_FAIL;
  payload: { error: AxiosError };
}

// GET DEDUCTION DETAILS
export const GET_DEDUCTION_DETAILS =
  '[@@Accounting_Payroll_Items] GET_DEDUCTION_DETAILS';
export const GET_DEDUCTION_DETAILS_SUCCESS =
  '[@@Accounting_Payroll_Items] GET_DEDUCTION_DETAILS_SUCCESS';
export const GET_DEDUCTION_DETAILS_FAIL =
  '[@@Accounting_Payroll_Items] GET_DEDUCTION_DETAILS_FAIL';

export interface IGetDeductionDetailsRequestAction {
  type: typeof GET_DEDUCTION_DETAILS;
  payload: { id: string };
}

export interface IGetDeductionDetailsActionSuccess {
  type: typeof GET_DEDUCTION_DETAILS_SUCCESS;
  payload: {
    deductionDetails: IDeductionsTableItem | null;
  };
}

export interface IGetDeductionDetailsActionFail {
  type: typeof GET_DEDUCTION_DETAILS_FAIL;
  payload: { error: AxiosError };
}

// GET DEDUCTION PAYMENTS
export const GET_DEDUCTION_PAYMENTS =
  '[@@Accounting_Payroll_Items] GET_DEDUCTION_PAYMENTS';
export const GET_DEDUCTION_PAYMENTS_SUCCESS =
  '[@@Accounting_Payroll_Items] GET_DEDUCTION_PAYMENTS_SUCCESS';
export const GET_DEDUCTION_PAYMENTS_FAIL =
  '[@@Accounting_Payroll_Items] GET_DEDUCTION_PAYMENTS_FAIL';

export interface IGetDeductionPaymentsAction {
  type: typeof GET_DEDUCTION_PAYMENTS;
  deductionId: number;
  params?: AxiosRequestConfig['params'];
}

export interface IGetDeductionPaymentsActionSuccess {
  type: typeof GET_DEDUCTION_PAYMENTS_SUCCESS;
  payload: {
    payroll_items: DeductionPayment[];
    pagination: IPagination;
  };
}

export interface IGetDeductionPaymentsActionFail {
  type: typeof GET_DEDUCTION_PAYMENTS_FAIL;
  payload: { error: AxiosError };
}

// ADD DEDUCTION
export const ADD_DEDUCTION = '[@@Accounting_Payroll_Items] ADD_DEDUCTION';
export const ADD_DEDUCTION_FAIL =
  '[@@Accounting_Payroll_Items] ADD_DEDUCTION_FAIL';

export const EDIT_DEDUCTION = '[@@Accounting_Payroll_Items] EDIT_DEDUCTION';
export const EDIT_DEDUCTION_FAIL =
  '[@@Accounting_Payroll_Items] EDIT_DEDUCTION_FAIL';

export interface IAddDeductionAction {
  type: typeof ADD_DEDUCTION;
  params: AddDeduction;
}

export interface IEditDeductionAction {
  type: typeof EDIT_DEDUCTION;
  params: EditDeduction;
}

export interface IAddDeductionActionFail {
  type: typeof ADD_DEDUCTION_FAIL;
  payload: { error: AxiosError };
}

// DELETE DEDUCTION
export const DELETE_DEDUCTION = '[@@Accounting_Payroll_Items] DELETE_DEDUCTION';
export const DELETE_DEDUCTION_FAIL =
  '[@@Accounting_Payroll_Items] DELETE_DEDUCTION_FAIL';

export interface IDeleteDeductionAction {
  type: typeof DELETE_DEDUCTION;
  payload: {
    deductionId: number;
    callback?: () => void;
  };
}

export interface IDeleteDeductionActionFail {
  type: typeof DELETE_DEDUCTION_FAIL;
  payload: { error: AxiosError };
}

// CANCEL DEDUCTION
export const CANCEL_DEDUCTION = '[@@Accounting_Payroll_Items] CANCEL_DEDUCTION';
export const CANCEL_DEDUCTION_FAIL =
  '[@@Accounting_Payroll_Items] CANCEL_DEDUCTION_FAIL';

export interface ICancelDeductionAction {
  type: typeof CANCEL_DEDUCTION;
  payload: {
    deductionId: number;
    callback?: () => void;
  };
}

export interface ICancelDeductionActionFail {
  type: typeof CANCEL_DEDUCTION_FAIL;
  payload: { error: AxiosError };
}

export type payrollItemsActionTypes =
  | IGetSchedDeductionDriversAction
  | IGetSchedDeductionDriversActionSuccess
  | IGetSchedDeductionDriversActionFail
  | IGetAccountEntriesAction
  | IGetAccountEntriesActionSuccess
  | IGetAccountEntriesActionFail
  | ICreateAccountEntryAction
  | IUpdateAccountEntryAction
  | IStoreAccountEntryActionSuccess
  | IStoreAccountEntryActionFail
  | IDeleteAccountEntryAction
  | IDeleteAccountEntryActionSuccess
  | IDeleteAccountEntryActionFail
  | IGetAccountFuelAction
  | IGetAccountFuelActionSuccess
  | IGetAccountFuelActionFail
  | IGetDriverFuelAction
  | IGetDriverFuelActionSuccess
  | IGetDriverFuelActionFail
  | IGetDeductionsAction
  | IGetDeductionsActionSuccess
  | IGetDeductionsActionFail
  | IGetDeductionDetailsRequestAction
  | IGetDeductionDetailsActionSuccess
  | IGetDeductionDetailsActionFail
  | IGetDeductionPaymentsAction
  | IGetDeductionPaymentsActionSuccess
  | IGetDeductionPaymentsActionFail
  | IAddDeductionAction
  | IAddDeductionActionFail
  | IEditDeductionAction
  | IDeleteDeductionAction
  | IDeleteDeductionActionFail
  | ICancelDeductionAction
  | ICancelDeductionActionFail
  | ILogout;
