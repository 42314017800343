import { startSubmit, stopSubmit } from 'redux-form';
import { takeLatest, call, put, select, takeLeading } from 'redux-saga/effects';

import { setPaginationAction } from 'components/_common/Navigation/Pagination/_redux/paginationActions';
import { paginationSelector } from 'components/_common/Navigation/Pagination/_redux/paginationSelectors';
import {
  setSearchResultAction,
  setSearchResultFailAction,
  setSearchResultSuccessAction,
} from 'components/_common/SearchPanel/_redux/searchActions';
import { searchSelector } from 'components/_common/SearchPanel/_redux/searchSelectors';
import { MAX_SEARCH_RESULT } from 'components/_common/SearchPanel/constants';
import {
  getFacilitiesAction,
  getFacilityAction,
  createFacilityAction,
  updateFacilityAction,
  deleteFacilityAction,
} from 'components/Dispatch/TabFacilities/_redux/facilitiesActions';
import {
  GET_FACILITY,
  GET_FACILITY_SUCCESS,
  GET_FACILITY_FAIL,
  GET_FACILITIES,
  GET_FACILITIES_SUCCESS,
  GET_FACILITIES_FAIL,
  CREATE_FACILITY,
  CREATE_FACILITY_SUCCESS,
  CREATE_FACILITY_FAIL,
  DELETE_FACILITY,
  DELETE_FACILITY_SUCCESS,
  DELETE_FACILITY_FAIL,
  UPDATE_FACILITY,
  UPDATE_FACILITY_SUCCESS,
  UPDATE_FACILITY_FAIL,
  SEARCH_FACILITIES,
} from 'components/Dispatch/TabFacilities/_redux/facilitiesTypes';
import { transformFacilitiesSearchResult } from 'components/Dispatch/TabFacilities/_redux/helpers';
import { FACILITIES_API_PREFIX as facilitiesUrl } from 'constants/api';
import { getErrors } from 'utils/helpers';
import api from 'utils/requests';

function* getFacilitiesSaga({
  payload = {},
}: ReturnType<typeof getFacilitiesAction>) {
  const { config, query, callback } = payload;

  try {
    const { per_page, page } = yield select(paginationSelector);
    const { data } = yield call(() =>
      api.get(
        `${facilitiesUrl}?page=${page}&per_page=${per_page}${query || ''}`,
      ),
    );
    yield put({ type: GET_FACILITIES_SUCCESS, payload: data });
    const { pagination } = data;
    // Pagination set
    if (config && config.usePaginationFlag && pagination)
      yield put(setPaginationAction(pagination));

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({ type: GET_FACILITIES_FAIL, payload: e });
  }
}

function* searchFacilitiesSaga() {
  const { searchValue } = yield select(searchSelector);
  if (searchValue)
    try {
      yield put(setSearchResultAction([]));
      const { data } = yield call(() =>
        api.get(
          `${facilitiesUrl}?page=1&per_page=${MAX_SEARCH_RESULT}&search=${searchValue}`,
        ),
      );
      const { facilities } = data;

      yield put(
        setSearchResultSuccessAction(
          transformFacilitiesSearchResult(facilities),
        ),
      );
    } catch (e) {
      if (api.isAxiosError(e)) yield put(setSearchResultFailAction(e));
    }
}

function* getFacilitySaga({ id }: ReturnType<typeof getFacilityAction>) {
  try {
    const { data } = yield call(() => api.get(`${facilitiesUrl}/${id}`));
    yield put({
      type: GET_FACILITY_SUCCESS,
      payload: data.facility,
    });
  } catch (e) {
    if (api.isAxiosError(e)) yield put({ type: GET_FACILITY_FAIL, payload: e });
  }
}

function* createFacilitySaga({
  payload: { values, callback },
}: ReturnType<typeof createFacilityAction>) {
  try {
    yield put(startSubmit('createAndUpdateFacilityForm'));
    const { data } = yield call(() =>
      api.post(facilitiesUrl, { facility: values }),
    );
    yield put({ type: CREATE_FACILITY_SUCCESS });
    yield put(stopSubmit('createAndUpdateFacilityForm'));
    if (callback) callback(data.facility.id);
  } catch (e) {
    if (api.isAxiosError(e)) {
      yield put({ type: CREATE_FACILITY_FAIL, payload: e });
      yield put(stopSubmit('createAndUpdateFacilityForm', getErrors(e)));
    }
  }
}

function* updateFacilitySaga({
  payload: { callback, values },
}: ReturnType<typeof updateFacilityAction>) {
  try {
    yield put(startSubmit('createAndUpdateFacilityForm'));
    yield call(() =>
      api.patch(`${facilitiesUrl}/${values.id}`, {
        facility: values,
      }),
    );
    yield put({ type: UPDATE_FACILITY_SUCCESS });
    yield put(stopSubmit('createAndUpdateFacilityForm'));
    callback();
  } catch (e) {
    if (api.isAxiosError(e)) {
      yield put({ type: UPDATE_FACILITY_FAIL, payload: e });
      yield put(stopSubmit('createAndUpdateFacilityForm', getErrors(e)));
    }
  }
}

function* deleteFacilitySaga({
  payload: { id, callback },
}: ReturnType<typeof deleteFacilityAction>) {
  try {
    yield call(() => api.delete(`${facilitiesUrl}/${id}`));
    yield put({ type: DELETE_FACILITY_SUCCESS });
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({ type: DELETE_FACILITY_FAIL, payload: e });
  }
}

export default function* facilitiesSaga() {
  yield takeLeading(CREATE_FACILITY, createFacilitySaga);
  yield takeLatest(GET_FACILITIES, getFacilitiesSaga);
  yield takeLatest(SEARCH_FACILITIES, searchFacilitiesSaga);
  yield takeLatest(GET_FACILITY, getFacilitySaga);
  yield takeLatest(DELETE_FACILITY, deleteFacilitySaga);
  yield takeLatest(UPDATE_FACILITY, updateFacilitySaga);
}
