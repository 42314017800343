import { call, put, takeLatest, select } from 'redux-saga/effects';

import { setPagesAction } from 'components/_common/Navigation/Pagination/_redux/paginationActions';
import { paginationSelector } from 'components/_common/Navigation/Pagination/_redux/paginationSelectors';
import api from 'utils/requests';

import {
  getAllTrucksMaintenanceListAction,
  getAllTrucksMaintenanceListSuccessAction,
  getAllTrucksMaintenanceListFailAction,
} from './allTrucksMaintenanceActions';
import { GET_ALL_TRUCKS_MAINTENANCE_LIST } from './allTrucksMaintenanceTypes';

function* getTruckMaintenanceListSaga({
  payload: {
    data: { query = '' },
    callback,
  },
}: ReturnType<typeof getAllTrucksMaintenanceListAction>) {
  try {
    const { per_page, page } = yield select(paginationSelector);
    const { data } = yield call(() =>
      api.get(`/v1/fleets/maintenance/planning_reviews?${query}`, {
        params: { per_page, page },
      }),
    );
    yield put(getAllTrucksMaintenanceListSuccessAction(data));
    const { pagination } = data;
    yield put(setPagesAction(pagination && pagination.pages));
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put(getAllTrucksMaintenanceListFailAction(e));
  }
}

export default function* maintenanceSaga() {
  yield takeLatest(
    GET_ALL_TRUCKS_MAINTENANCE_LIST,
    getTruckMaintenanceListSaga,
  );
}
