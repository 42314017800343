import moment from 'moment';

import { isTask } from 'socket/channels';
import { IWithPending } from 'utils/models';

import {
  ChannelMessage,
  SUBSCRIBE_CHANNEL,
  UNSUBSCRIBE_CHANNEL,
  APPEND_CHANNEL_MESSAGES,
  UPDATE_CHANNEL_MESSAGES,
  ON_CONNECTION_CHANGED,
  ON_CHANNEL_SUBSCRIBED,
  ON_INCOMING_MESSAGE,
  ON_INCOMING_ERROR,
  socketActionsType,
} from './socketTypes';

interface ISocketChannel extends IWithPending {
  messages: ChannelMessage[];
  historyDepth: number;
  _connected: boolean;
}

export interface ISocketReducer {
  channels: {
    [key: string]: ISocketChannel;
  };

  _opened: boolean;
  _error: Event | null;
}

const initialState: ISocketReducer = {
  channels: {},

  _opened: false,
  _error: null,
} as const;

function socketReducer(
  state = initialState,
  action: socketActionsType,
): ISocketReducer {
  switch (action.type) {
    case SUBSCRIBE_CHANNEL: {
      const channels = { ...state.channels };
      channels[action.channelIdentifier] = {
        messages: [],
        historyDepth: action.messagesHistoryDepth,
        _connected: false,
        _pending: true,
      };
      return {
        ...state,
        channels,
      };
    }

    case ON_CHANNEL_SUBSCRIBED: {
      const { channelIdentifier } = action;

      const socketChannel = channelIdentifier
        ? state.channels[channelIdentifier]
        : undefined;
      if (socketChannel == null) return state;

      return {
        ...state,
        channels: {
          ...state.channels,
          [channelIdentifier]: {
            ...socketChannel,
            _connected: true,
            _pending: false,
          },
        },
      };
    }

    case UNSUBSCRIBE_CHANNEL: {
      const channels = { ...state.channels };
      delete channels[action.channelIdentifier];
      return {
        ...state,
        channels,
      };
    }

    case APPEND_CHANNEL_MESSAGES: {
      const {
        channelIdentifier,
        messages: channelMessages,
        messagesType,
      } = action;

      const socketChannel = channelIdentifier
        ? state.channels[channelIdentifier]
        : undefined;
      if (
        socketChannel == null ||
        !Array.isArray(channelMessages) ||
        !channelMessages.length
      )
        return state;

      return {
        ...state,
        channels: {
          ...state.channels,
          [channelIdentifier]: {
            ...socketChannel,
            messages: [
              ...socketChannel.messages,
              ...channelMessages.map(m => ({ ...m, type: messagesType })),
            ].slice(-socketChannel.historyDepth),
          },
        },
      };
    }

    case UPDATE_CHANNEL_MESSAGES: {
      const {
        channelIdentifier,
        messages: incomingMessages,
        messagesType,
      } = action;

      const socketChannel = channelIdentifier
        ? state.channels[channelIdentifier]
        : undefined;
      if (
        socketChannel == null ||
        !Array.isArray(incomingMessages) ||
        !incomingMessages.length
      )
        return state;

      const messages = [...socketChannel.messages];
      incomingMessages.forEach(incomingMessage => {
        const index = messages.findIndex(
          ({ id, type }) =>
            id &&
            id === incomingMessage.id &&
            (!messagesType || messagesType === type),
        );

        if (index >= 0)
          messages[index] = { ...messages[index], ...incomingMessage };
        else if (messagesType === 'task') {
          const insertPosition = messages.lowerBound(
            m =>
              !m.expired_at ||
              moment(m.expired_at).isBefore(moment(incomingMessage.expired_at)),
          );
          messages.splice(insertPosition, 0, {
            ...incomingMessage,
            type: 'task',
          });
        }
      });

      return {
        ...state,
        channels: {
          ...state.channels,
          [channelIdentifier]: {
            ...socketChannel,
            messages,
          },
        },
      };
    }

    case ON_CONNECTION_CHANGED:
      return {
        ...state,
        _opened: action.connected,
      };

    case ON_INCOMING_ERROR:
      return {
        ...state,
        _error: action.event,
      };

    case ON_INCOMING_MESSAGE: {
      const {
        channelIdentifier,
        message: channelMessage,
        messageType,
      } = action;

      const socketChannel = channelIdentifier
        ? state.channels[channelIdentifier]
        : undefined;
      if (socketChannel == null) return state;

      const message = messageType
        ? { ...channelMessage, type: messageType }
        : channelMessage;

      const messages = [
        ...socketChannel.messages.slice(-socketChannel.historyDepth),
      ];

      if (isTask(message)) {
        const insertPosition = messages.lowerBound(
          m =>
            !m.expired_at ||
            moment(m.expired_at).isBefore(moment(message.expired_at)),
        );
        messages.splice(insertPosition, 0, message);
      } else messages.unshift(message);

      return {
        ...state,
        channels: {
          ...state.channels,
          [channelIdentifier]: {
            ...socketChannel,
            messages,
          },
        },
      };
    }

    default:
      return state;
  }
}

export default socketReducer;
