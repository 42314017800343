import { LOGOUT } from 'components/Auth/_redux/authTypes';
import { IFile, ILocalFile } from 'redux/_common/models';

import {
  ADD_UNITS,
  ADD_UNITS_FAIL,
  ASSIGN_INVENTORY_UNIT,
  ASSIGN_INVENTORY_UNIT_FAIL,
  ASSIGN_INVENTORY_UNIT_SUCCESS,
  ASSIGN_EMPLOYEE_UNIT,
  ASSIGN_EMPLOYEE_UNIT_FAIL,
  ASSIGN_EMPLOYEE_UNIT_SUCCESS,
  CHANGE_STATUS_UNIT,
  CHANGE_STATUS_UNIT_FAIL,
  CHANGE_STATUS_UNIT_SUCCESS,
  CREATE_ITEM,
  CREATE_ITEM_FAIL,
  DELETE_ITEM_INVOICE_FILE_FAIL,
  DELETE_ITEM_INVOICE_FILE_SUCCESS,
  DELETE_ITEM_PHOTO_FAIL,
  DELETE_ITEM_PHOTO_SUCCESS,
  GET_ITEM,
  GET_ITEM_ACTIVITY,
  GET_ITEM_ACTIVITY_FAIL,
  GET_ITEM_ACTIVITY_SUCCESS,
  GET_ITEM_FAIL,
  GET_ITEM_INVOICES,
  GET_ITEM_INVOICES_FAIL,
  GET_ITEM_INVOICES_SUCCESS,
  GET_ITEM_SUCCESS,
  IItemsReducer,
  ItemsTypes,
  ASSIGN_LEASE,
  ASSIGN_SELL,
  UNASSIGN_UNIT,
  UNASSIGN_UNIT_FAIL,
  UNASSIGN_UNIT_SUCCESS,
  REMOVE_EMPLOYEE_UNIT,
  REMOVE_EMPLOYEE_UNIT_FAIL,
  REMOVE_EMPLOYEE_UNIT_SUCCESS,
  RESET_ITEMS_CREATE_FORM,
  SET_ITEM_INVOICE_FILE,
  UPDATE_ITEM,
  UPDATE_ITEM_FAIL,
  UPLOAD_ITEM_INVOICE_FILE,
  UPLOAD_ITEM_INVOICE_FILE_FAIL,
  UPLOAD_ITEM_INVOICE_FILE_SUCCESS,
  UPLOAD_ITEM_PHOTO,
  UPLOAD_ITEM_PHOTO_FAIL,
  UPLOAD_ITEM_PHOTO_SUCCESS,
  CREATE_ITEM_SUCCESS,
  ADD_UNITS_SUCCESS,
  GET_ITEM_WRITE_OFF_EMPLOYEES_SUCCESS,
  GET_ALL_INVOICES_SUCCESS,
  SET_ITEM_FILES,
} from './itemsTypes';

const initialState: IItemsReducer = {
  currentItem: null,
  currentItemActivity: [],
  photoFiles: [],
  invoiceFile: [],
  invoicesList: [],
  allInvoicesList: [],
  updateUnitsGrid: 0,
  writtenOffByList: [],
  _pending: false,
  _error: null,
};

const itemsReducer = (
  state = initialState,
  action: ItemsTypes,
): IItemsReducer => {
  switch (action.type) {
    // CREATE_ITEM
    case CREATE_ITEM:
    case CREATE_ITEM_SUCCESS:
      return { ...state, _error: null };

    case CREATE_ITEM_FAIL:
      return { ...state, _error: action.payload };

    // UPDATE_ITEM
    case UPDATE_ITEM:
      return { ...state };
    case UPDATE_ITEM_FAIL:
      return { ...state, _error: action.payload };

    // ADD_UNITS
    case ADD_UNITS:
      return { ...state };
    case ADD_UNITS_SUCCESS:
      return { ...state, updateUnitsGrid: state.updateUnitsGrid + 1 };
    case ADD_UNITS_FAIL:
      return { ...state, _error: action.payload };

    // GET ITEM ACTIVITY BY ID
    case GET_ITEM_ACTIVITY:
      return {
        ...state,
        currentItemActivity: [],
        _pending: true,
        _error: null,
      };
    case GET_ITEM_ACTIVITY_SUCCESS:
      return {
        ...state,
        currentItemActivity: action.payload.activities,
        _pending: false,
        _error: null,
      };
    case GET_ITEM_ACTIVITY_FAIL:
      return { ...state, _pending: false, _error: action.payload };

    // ASSIGN DRIVER UNIT
    case ASSIGN_INVENTORY_UNIT:
      return {
        ...state,
        _pending: true,
        _error: null,
      };
    case ASSIGN_INVENTORY_UNIT_SUCCESS:
      return {
        ...state,
        _pending: false,
        _error: null,
      };
    case ASSIGN_INVENTORY_UNIT_FAIL:
      return {
        ...state,
        _pending: false,
        _error: action.payload,
      };

    // ASSIGN EMPLOYEE UNIT
    case ASSIGN_EMPLOYEE_UNIT:
      return {
        ...state,
        _pending: true,
        _error: null,
      };
    case ASSIGN_EMPLOYEE_UNIT_SUCCESS:
      return {
        ...state,
        _pending: false,
        _error: null,
      };
    case ASSIGN_EMPLOYEE_UNIT_FAIL:
      return {
        ...state,
        _pending: false,
        _error: action.payload,
      };

    // Assign lease and sell
    case ASSIGN_SELL:
      return { ...state, _error: null };
    case ASSIGN_LEASE:
      return { ...state, _error: null };

    // REMOVE DRIVER UNIT
    case UNASSIGN_UNIT:
      return {
        ...state,
        _pending: true,
        _error: null,
      };
    case UNASSIGN_UNIT_SUCCESS:
      return {
        ...state,
        _pending: false,
        _error: null,
      };
    case UNASSIGN_UNIT_FAIL:
      return {
        ...state,
        _pending: false,
        _error: action.payload,
      };

    // REMOVE EMPLOYEE UNIT
    case REMOVE_EMPLOYEE_UNIT:
      return {
        ...state,
        _pending: true,
        _error: null,
      };
    case REMOVE_EMPLOYEE_UNIT_SUCCESS:
      return {
        ...state,
        _pending: false,
        _error: null,
      };
    case REMOVE_EMPLOYEE_UNIT_FAIL:
      return {
        ...state,
        _pending: false,
        _error: action.payload,
      };

    // CHANGE UNIT STATUS BY ID
    case CHANGE_STATUS_UNIT:
      return {
        ...state,
        _pending: true,
        _error: null,
      };
    case CHANGE_STATUS_UNIT_SUCCESS:
      return {
        ...state,
        _pending: false,
        _error: null,
      };
    case CHANGE_STATUS_UNIT_FAIL:
      return {
        ...state,
        _pending: false,
        _error: action.payload,
      };

    // GET ITEM BY ID
    case GET_ITEM:
      return {
        ...state,
        _pending: true,
        _error: null,
        currentItem: null,
      };
    case GET_ITEM_SUCCESS:
      return {
        ...state,
        currentItem: action.payload.item,
        _pending: false,
      };
    case GET_ITEM_FAIL:
      return {
        ...state,
        _pending: false,
        _error: action.payload,
      };

    // UPLOAD PHOTO
    case UPLOAD_ITEM_PHOTO:
      return {
        ...state,
        photoFiles: [
          ...state.photoFiles,
          ...action.payload.files.map((item: ILocalFile) => ({
            ...item,
            loading: true,
          })),
        ],
      };
    case UPLOAD_ITEM_PHOTO_SUCCESS: {
      const currentItem = state.currentItem ? { ...state.currentItem } : null;
      if (
        currentItem &&
        action.payload.itemId &&
        action.payload.itemId === currentItem.id
      ) {
        currentItem.photos = [...currentItem.photos, action.payload.file];
      }
      return {
        ...state,
        currentItem,
        photoFiles: state.photoFiles.map((file, index) =>
          index === state.photoFiles.findIndex(({ loading }) => loading)
            ? { ...action.payload.file, loading: false }
            : file,
        ),
      };
    }
    case UPLOAD_ITEM_PHOTO_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // DELETE PHOTO
    case DELETE_ITEM_PHOTO_SUCCESS: {
      const currentItem = state.currentItem ? { ...state.currentItem } : null;
      if (
        currentItem &&
        action.payload.itemId &&
        action.payload.itemId === currentItem.id
      ) {
        currentItem.photos = currentItem.photos.filter(
          photo => photo.id !== action.payload.fileId,
        );
      }
      return {
        ...state,
        currentItem,
        photoFiles: state.photoFiles.filter(
          file => file.id !== action.payload.fileId,
        ),
      };
    }
    case DELETE_ITEM_PHOTO_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // UPLOAD INVOICE FILE
    case UPLOAD_ITEM_INVOICE_FILE:
      return {
        ...state,
        invoiceFile: [
          ...state.invoiceFile,
          ...action.payload.files.map((item: ILocalFile) => ({
            ...item,
            loading: true,
          })),
        ],
      };
    case UPLOAD_ITEM_INVOICE_FILE_SUCCESS:
      return {
        ...state,
        invoiceFile: state.invoiceFile.map(item =>
          item.loading ? action.payload : item,
        ),
      };
    case UPLOAD_ITEM_INVOICE_FILE_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // DELETE INVOICE FILE
    case DELETE_ITEM_INVOICE_FILE_SUCCESS:
      return {
        ...state,
        invoiceFile: state.invoiceFile.filter(
          (file: IFile | ILocalFile) => file.id !== action.payload,
        ),
      };
    case DELETE_ITEM_INVOICE_FILE_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // GET INVOICES
    case GET_ITEM_INVOICES:
      return {
        ...state,
        invoicesList: [],
        _pending: true,
        _error: null,
      };
    case GET_ITEM_INVOICES_SUCCESS:
      return {
        ...state,
        invoicesList: action.payload,
        _pending: false,
      };
    case GET_ITEM_INVOICES_FAIL:
      return {
        ...state,
        _pending: false,
        _error: action.payload,
      };

    case GET_ALL_INVOICES_SUCCESS:
      return {
        ...state,
        allInvoicesList: action.payload,
        _pending: false,
        _error: null,
      };

    // GET INVOICE FILE BY ID
    case SET_ITEM_INVOICE_FILE:
      return {
        ...state,
        invoiceFile: action.payload ? [action.payload] : [],
        _pending: false,
      };

    // SET ITEM FILES
    case SET_ITEM_FILES:
      return {
        ...state,
        photoFiles: action.payload.photoFiles || [],
        // invoiceFile: [],
        // invoicesList: [],
      };

    // RESET MAIN FORM
    case RESET_ITEMS_CREATE_FORM:
      return {
        ...state,
        photoFiles: [],
        invoiceFile: [],
        invoicesList: [],
      };

    case GET_ITEM_WRITE_OFF_EMPLOYEES_SUCCESS:
      return {
        ...state,
        writtenOffByList: action.payload,
      };

    // LOGOUT
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};

export default itemsReducer;
