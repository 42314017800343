type Props = {
  fillColor?: string;
};

const IconSync = ({ fillColor = 'var(--secondaryText)' }: Props) => (
  <svg fill="none" height="16" viewBox="0 0 16 16" width="16">
    <path
      d="m1 8.00557c-.265216 0-.51957-.10536-.707107-.29289-.187536-.18754-.292893-.4419-.292893-.70711v-4c0-.26522.105357-.51957.292893-.70711.187537-.18753.441891-.29289.707107-.29289.26522 0 .51957.10536.70711.29289.18753.18754.29289.44189.29289.70711v1.392c.56417-.94038 1.34151-1.73495 2.26928-2.31962.92777-.58466 1.98-.94303 3.07176-1.04619 1.09177-.103164 2.19249.05177 3.21336.45231 1.0209.40053 1.9333 1.03544 2.6636 1.8535.089.0976.1578.21187.2024.33621.0447.12434.0642.25629.0575.38822-.0067.13194-.0394.26124-.0963.38044-.057.11921-.137.22595-.2354.31407-.0984.08811-.2133.15586-.3381.19931-.1247.04346-.2568.06177-.3887.05387s-.2609-.04185-.3795-.09988c-.1187-.05803-.2247-.13901-.3119-.23824-.5586-.62508-1.2653-1.09969-2.05525-1.3802-.78995-.2805-1.63774-.3579-2.46543-.22506-.82768.13283-1.60868.47163-2.27118.98524-.6625.51362-1.18524 1.18555-1.52014 1.95402h1.584c.26522 0 .51957.10535.70711.29289.18753.18754.29289.44189.29289.70711 0 .26521-.10536.51957-.29289.70711-.18754.18753-.44189.29289-.70711.29289zm15 1v4.00003c0 .2652-.1054.5195-.2929.7071-.1875.1875-.4419.2929-.7071.2929s-.5196-.1054-.7071-.2929c-.1875-.1876-.2929-.4419-.2929-.7071v-1.392c-.5642.9403-1.3415 1.7349-2.2693 2.3196-.9278.5846-1.97998.943-3.07174 1.0462-1.09177.1031-2.19249-.0518-3.21336-.4523-1.02086-.4006-1.93327-1.0355-2.6636-1.8535-.17716-.1976-.26858-.4575-.25414-.7225.00715-.1312.04007-.2597.09689-.3782s.13642-.2246.23425-.3123c.09784-.0878.21199-.1553.33595-.199.12395-.0436.25529-.0623.3865-.0552.26499.0145.51338.1336.69055.3312.55867.6246 1.26533 1.0989 2.05508 1.3792.78975.2804 1.63728.3578 2.46476.2252.82747-.1326 1.60836-.471 2.27096-.9841s1.1857-1.1844 1.5212-1.9523h-1.584c-.2652 0-.5196-.10539-.7071-.29292-.1875-.18754-.2929-.44189-.2929-.70711s.1054-.51957.2929-.70711.4419-.29289.7071-.29289h4c.2652 0 .5196.10535.7071.29289s.2929.44189.2929.70711z"
      fill={fillColor}
    />
  </svg>
);

export default IconSync;
