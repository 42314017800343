import { FC } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
  fillBGColor?: string;
}

const IconMapMarker2: FC<IOwnProps> = ({
  className,
  fillBGColor = '#C43A37',
  fillColor = '#FF4A3C',
}) => {
  return (
    <svg
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 24C9 24 18 14.4361 18 8.66165C18 3.87795 13.9706 0 9 0C4.02944 0 0 3.87795 0 8.66165C0 14.4361 9 24 9 24Z"
        fill={fillColor}
      />
      <circle cx="9" cy="9" r="4" fill={fillBGColor} />
    </svg>
  );
};

export default IconMapMarker2;
