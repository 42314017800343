import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import api from '../../../../utils/requests';
import {
  createRoleAndPermissionAction,
  deleteRoleAction,
  getRoleAction,
  setActivePermissionsAction,
  updateRoleAction,
} from './RolesAndPermissionsActions';
import { IRoleAndPermission } from './RolesAndPermissionsModels';
import {
  CREATE_ROLE,
  CREATE_ROLE_FAIL,
  CREATE_ROLE_SUCCESS,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
  GET_ROLES_LIST,
  GET_ROLES_LIST_FAIL,
  GET_ROLES_LIST_SUCCESS,
  DELETE_ROLE,
  GET_ROLE_SUCCESS,
  GET_ROLE_FAIL,
  GET_ROLE,
  EDIT_ROLE_SUCCESS,
  EDIT_ROLE_FAIL,
  EDIT_ROLE,
  SET_ACTIVE_PERMISSIONS,
  SET_ACTIVE_PERMISSIONS_SUCCESS,
} from './RolesAndPermissionsTypes';

function* getRoleList() {
  try {
    const { data } = yield call(() => api.get('v1/hr/roles'));
    if (data) {
      yield put({
        type: GET_ROLES_LIST_SUCCESS,
        payload: data.hr_roles as IRoleAndPermission,
      });
    }
  } catch (e) {
    yield put({ type: GET_ROLES_LIST_FAIL, payload: e });
  }
}

function* createRoleSaga({
  payload: { name, description, callback },
}: ReturnType<typeof createRoleAndPermissionAction>) {
  try {
    const { data } = yield call(() =>
      api.post('v1/hr/roles', { hr_role: { name, description } }),
    );
    if (data) {
      yield put({ type: CREATE_ROLE_SUCCESS, payload: data.hr_role });
      if (callback) callback();
    }
  } catch (e) {
    yield put({ type: CREATE_ROLE_FAIL, payload: e });
  }
}

function* deleteRoleSaga({
  payload: { id },
}: ReturnType<typeof deleteRoleAction>) {
  try {
    yield call(() => api.delete(`v1/hr/roles/${id}`));
    yield put({
      type: DELETE_ROLE_SUCCESS,
      payload: { id },
    });
  } catch (e) {
    yield put({ type: DELETE_ROLE_FAIL, payload: e });
  }
}

function* getRoleSaga({ payload: { id } }: ReturnType<typeof getRoleAction>) {
  try {
    const { data } = yield call(() => api.get(`v1/hr/roles/${id}`));
    if (data) {
      yield put({
        type: GET_ROLE_SUCCESS,
        payload: data,
      });
    }
  } catch (e) {
    yield put({ type: GET_ROLE_FAIL, payload: e });
  }
}

function* editRoleSaga({
  payload: { id, name, description, permissionType, permissions },
  callback,
}: ReturnType<typeof updateRoleAction>) {
  const toSend = {
    ...(name && { name }),
    ...(description && { description }),
    ...(permissionType === 'global'
      ? {
          permissions: {
            global: {
              ...permissions,
            },
          },
        }
      : {
          permissions: {
            internal_companies: permissions,
          },
        }),
  };

  try {
    const { data } = yield call(() =>
      api.patch(`v1/hr/roles/${id}`, { hr_role: { ...toSend } }),
    );
    if (data) {
      yield put({
        type: EDIT_ROLE_SUCCESS,
      });
    }
    if (callback) callback();
  } catch (e) {
    yield put({
      type: EDIT_ROLE_FAIL,
      payload: e,
    });
  }
}

function* setActivePermissionsSaga({
  payload: { permissions },
}: ReturnType<typeof setActivePermissionsAction>) {
  yield put({ type: SET_ACTIVE_PERMISSIONS_SUCCESS, payload: { permissions } });
}
export default function* roleAndPermissionsSaga() {
  yield takeLatest(GET_ROLES_LIST, getRoleList);
  yield takeLatest(CREATE_ROLE, createRoleSaga);
  yield takeLatest(DELETE_ROLE, deleteRoleSaga);
  yield takeLatest(GET_ROLE, getRoleSaga);
  yield takeLatest(EDIT_ROLE, editRoleSaga);
  yield takeEvery(SET_ACTIVE_PERMISSIONS, setActivePermissionsSaga);
}
