import { FC, useRef, useState, useCallback, useEffect, memo } from 'react';

import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { IconClose, IconRedirect } from 'assets/icons/components';
import ErrorMessage from 'components/_common/Message/Message';
import useDebounceCallback from 'hooks/useDebounceCallback';
import { updateChannelMessagesAction } from 'redux/socket/socketActions';
import {
  NOTIFICATIONS_CHANNEL_ID,
  NotificationsChannelMessage,
  NotificationsChannelTask,
} from 'socket/channels';
import { getErrorMessage } from 'utils/helpers';
import api from 'utils/requests';

import HeaderFilter from './HeaderFilter';
import { Message, Task } from './Message';
import MessagesGroups from './MessagesGroups';

import styles from './NotificationMessages.module.scss';

type MessagesProps = {
  messages: NotificationsChannelMessage[];
  unreadCount: number;
  onClose: () => void;
};

const NotificationMessages: FC<MessagesProps> = memo(
  ({ messages, unreadCount, onClose }) => {
    const dispatch = useDispatch();

    const [error, setError] = useState<AxiosError>();
    const [filter, setFilter] = useState<'all' | 'unread'>('all');

    useEffect(() => {
      if (!unreadCount) setFilter('all');
    }, [unreadCount]);

    const markAsReadHandle = useCallback(
      (id: number) => {
        const message = id ? messages.find(m => m.id === id) : undefined;
        if (!id || message) {
          setError(undefined);
          api
            .patch(
              `/v1/notifications/${message?.is_read ? 'unread' : 'read'}`,
              {
                notification_ids: id || 'all',
              },
            )
            .then(() => {
              const updatedMessages: NotificationsChannelMessage[] = message
                ? [
                    {
                      ...message,
                      is_read: !message.is_read,
                    },
                  ]
                : messages
                    .filter(m => !m.is_read)
                    .map(m => ({
                      ...m,
                      is_read: true,
                    }));

              dispatch(
                updateChannelMessagesAction<NotificationsChannelMessage>(
                  NOTIFICATIONS_CHANNEL_ID,
                  updatedMessages,
                  'employee_notification',
                ),
              );
            })
            .catch(err => {
              if (api.isAxiosError(err)) setError(err);
              console.error(err);
            });
        }
      },
      [messages],
    );

    return (
      <>
        <div className={styles.root}>
          <header>
            <HeaderFilter
              value={filter}
              clickable={!!unreadCount}
              onChange={setFilter}
            />
            <div className={styles.headerContent}>
              {!!unreadCount && (
                <div
                  className={styles.markAsRead}
                  onClick={() => markAsReadHandle(0)}
                >
                  Mark All as Read
                </div>
              )}
              <button onClick={onClose} type="button">
                <IconClose />
              </button>
            </div>
          </header>

          <div className={styles.scrollableContent}>
            <div className={styles.wholeContent}>
              <MessagesGroups showAll={filter === 'all'} messages={messages}>
                {message => (
                  <Message
                    key={message.id}
                    message={message}
                    onMarkAsRead={markAsReadHandle}
                  />
                )}
              </MessagesGroups>
            </div>
          </div>
        </div>

        {error && (
          <ErrorMessage message={getErrorMessage(error)} type="error" />
        )}
      </>
    );
  },
);

export { NotificationMessages };

type TasksProps = {
  tasks: NotificationsChannelTask[];
  onClose: () => void;
};
const NotificationTasks: FC<TasksProps> = memo(({ tasks, onClose }) => {
  const dispatch = useDispatch();

  const checkedTasks = useRef(new Set<number>());

  const [error, setError] = useState<AxiosError>();

  const changeTasksStatusImpl = () => {
    const markToDoneTasks = tasks
      .filter(t => !t.is_done && checkedTasks.current.has(t.id))
      .map(t => ({ ...t, is_done: true }));

    if (markToDoneTasks.length) {
      setError(undefined);
      api
        .patch(`/v1/tasks/done`, {
          task_ids: markToDoneTasks.map(t => t.id).join(','),
        })
        .then(() => {
          dispatch(
            updateChannelMessagesAction<NotificationsChannelTask>(
              NOTIFICATIONS_CHANNEL_ID,
              markToDoneTasks,
              'task',
            ),
          );
        })
        .catch(err => {
          if (api.isAxiosError(err)) setError(err);
          console.error(err);
        });
    }
  };

  const changeTasksStatus = useDebounceCallback(changeTasksStatusImpl, 1000);

  const markAsDoneHandle = useCallback(
    (id: number, isDone: boolean) => {
      if (isDone) checkedTasks.current.add(id);
      else checkedTasks.current.delete(id);

      changeTasksStatus();
    },
    [changeTasksStatus],
  );

  useEffect(() => {
    if (!tasks.length) onClose();
  }, [tasks]);

  return (
    <>
      <div className={styles.root}>
        <header>
          <span>Tasks</span>
          <Link to="/management/tasks" onClick={onClose}>
            <IconRedirect />
          </Link>
          <div className={styles.headerContent}>
            <button onClick={onClose} type="button">
              <IconClose />
            </button>
          </div>
        </header>

        <div className={styles.scrollableContent}>
          <div className={styles.wholeContent}>
            <MessagesGroups messages={tasks}>
              {task => (
                <Task
                  key={task.id}
                  task={task}
                  onMarkAsDone={markAsDoneHandle}
                />
              )}
            </MessagesGroups>
          </div>
        </div>
      </div>

      {error && <ErrorMessage message={getErrorMessage(error)} type="error" />}
    </>
  );
});

export { NotificationTasks };
