import { FC } from 'react';

type OwnProps = {
  className?: string;
  strokeColor?: string;
  size?: string;
};

const IconWarehouse: FC<OwnProps> = ({
  className,
  strokeColor = 'white',
  size = '32',
}) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 31H3C1.89543 31 1 30.1046 1 29V20C1 18.8954 1.89543 18 3 18H6"
      stroke={strokeColor}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M11 22H14"
      stroke={strokeColor}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M11 26H14"
      stroke={strokeColor}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M11 18H14"
      stroke={strokeColor}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17 9V4.83801C17 3.45495 18.3702 2.48935 19.6727 2.95453L29.6727 6.52595C30.4687 6.81024 31 7.56421 31 8.40944V29C31 30.1046 30.1046 31 29 31H6V15C6 13.8954 6.89543 13 8 13H17C18.1046 13 19 13.8954 19 15V30.667"
      stroke={strokeColor}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M23 26V9"
      stroke={strokeColor}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M27 26V11"
      stroke={strokeColor}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default IconWarehouse;
