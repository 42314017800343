import { FC } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const IconCalendar: FC<IOwnProps> = ({ className, fillColor = '#86898E' }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5 1V5M11 1V5M3 15H13C14.1046 15 15 14.1046 15 13V5C15 3.89543 14.1046 3 13 3H3C1.89543 3 1 3.89543 1 5V13C1 14.1046 1.89543 15 3 15Z"
        stroke={fillColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconCalendar;
