import { FC } from 'react';

export const IconRadioUnchecked: FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="11.5" stroke="var(--secondaryText)" />
  </svg>
);

export const IconRadioChecked: FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="8" stroke="var(--activeText)" strokeWidth="8" />
  </svg>
);

export const CheckMarkError: FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#FF4A3C" />
    <path
      d="M7.70711 12.2929C7.31658 11.9024 6.68342 11.9024 6.29289 12.2929C5.90237 12.6834 5.90237 13.3166 6.29289 13.7071L7.70711 12.2929ZM10 16L9.29289 16.7071C9.68342 17.0976 10.3166 17.0976 10.7071 16.7071L10 16ZM17.7071 9.70711C18.0976 9.31658 18.0976 8.68342 17.7071 8.29289C17.3166 7.90237 16.6834 7.90237 16.2929 8.29289L17.7071 9.70711ZM6.29289 13.7071L9.29289 16.7071L10.7071 15.2929L7.70711 12.2929L6.29289 13.7071ZM10.7071 16.7071L17.7071 9.70711L16.2929 8.29289L9.29289 15.2929L10.7071 16.7071Z"
      fill="white"
    />
  </svg>
);
