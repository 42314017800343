import {
  SET_PER_PAGE,
  SET_PAGE,
  SET_PAGES,
  SET_PAGINATION,
  ISetPaginationAction,
} from './paginationTypes';

// TODO: Depricate
export const setPerPageAction = (per_page: number) => ({
  type: SET_PER_PAGE,
  payload: per_page,
});
// TODO: Depricate
export const setPageAction = (page: number) => ({
  type: SET_PAGE,
  payload: page,
});
// TODO: Depricate
export const setPagesAction = (pages: number) => ({
  type: SET_PAGES,
  payload: pages,
});

// Use this one instead of others
export const setPaginationAction = (
  payload: ISetPaginationAction['payload'],
) => ({
  type: SET_PAGINATION,
  payload,
});
