import { FC } from 'react';

type OwnProps = {
  className?: string;
  color?: string;
};

const IconFlip: FC<OwnProps> = ({
  className,
  color = 'var(--primaryText)',
}) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2H13C14.1046 2 15 2.89543 15 4V12C15 13.1046 14.1046 14 13 14H12"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4 2H3C1.89543 2 1 2.89543 1 4V12C1 13.1046 1.89543 14 3 14H4"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M8 1L8 15"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconFlip;
