import { match } from 'ramda';

import styles from './SearchPanel.module.scss';

const formatLabel = (label: string, val: string, highlightedClass: string) => {
  if (!val) {
    return label;
  }

  // Fix bug - Unterminated group in regex
  // remove this characters
  const invalid = /[°"§%()\\[\]{}=\\?´`'#<>|,;.:+_-]+/g;
  const value = val.replace(invalid, '');

  const regex = new RegExp(value, 'gi');
  const splitArray: string[] = label.split(regex);
  const matchedArray: string[] = match(regex, label);

  // Merge two arrays with alternating values
  const l = Math.min(splitArray.length, matchedArray.length);
  const newArray: string[] = [];

  for (let i = 0; i < l; i += 1) {
    newArray.push(splitArray[i], matchedArray[i]);
  }
  newArray.push(...splitArray.slice(l), ...matchedArray.slice(l));

  const elements = newArray.map((highlightString: string, i) =>
    regex.test(highlightString) ? (
      <span key={`${value}${highlightString}${i}`} className={highlightedClass}>
        {highlightString}
      </span>
    ) : (
      highlightString
    ),
  );
  return <p className={styles.elementsWrapper}>{elements}</p>;
};

const checkInclude = (payload: string, inputValue: string) =>
  payload &&
  inputValue &&
  payload.toLowerCase().includes(inputValue.toLowerCase());

const invokeFormatHighlightsHandler = (
  payload: string,
  inputValue: string,
  highlightedClass: string,
) => {
  if (checkInclude(payload, inputValue)) {
    return formatLabel(payload, inputValue, highlightedClass);
  }
  return payload;
};

// Highlighting values from search input. Indenting includes
// Receives string or array of strings
// eslint-disable-next-line import/prefer-default-export
export const highlightSearchValue = (
  payload: string[] | string,
  inputValue: string,
  highlightedClass: string,
) => {
  if (typeof payload === 'string') {
    return invokeFormatHighlightsHandler(payload, inputValue, highlightedClass);
  }
  if (Array.isArray(payload)) {
    let result;
    payload.forEach((item: string) => {
      if (checkInclude(item, inputValue)) result = item;
    });
    if (result)
      return invokeFormatHighlightsHandler(
        result,
        inputValue,
        highlightedClass,
      );
    return payload.length > 0 ? payload[payload.length - 1] : '';
  }
  return '';
};
