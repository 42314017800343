import {
  FormattedSubscriptionPlans,
  SubscriptionPlans,
  SubscriptionPrice,
} from '../SettingsProfile/_models/models';

export function formatPlansToMonthlyAndYearly(allPlans: SubscriptionPlans) {
  const monthlyPlans: FormattedSubscriptionPlans = [];
  const yearlyPlans: FormattedSubscriptionPlans = [];

  const customPlans: FormattedSubscriptionPlans = [];

  allPlans
    // .filter(({ prices, isFree }) => !!prices.length || isFree)
    // delete all plans without prices commented due to new Free plan
    .forEach(plan => {
      const { prices, company } = plan;

      const monthlyPrice = prices.find(price => price.interval === 'month');
      const yearlyPrice = prices.find(price => price.interval === 'year');

      // (!monthlyPrice && !yearlyPrice) is for Unlimited plan
      const isCustomPlan =
        !!company || (!monthlyPrice && !yearlyPrice) || plan.is_free;

      if (isCustomPlan) {
        const price = (monthlyPrice || yearlyPrice) as SubscriptionPrice;
        customPlans.push({ ...plan, price });
        return;
      }

      if (monthlyPrice) {
        monthlyPlans.push({
          ...plan,
          price: monthlyPrice,
        });
      }

      if (yearlyPrice) {
        yearlyPlans.push({
          ...plan,
          price: yearlyPrice,
        });
      }
    });

  return {
    monthlyPlans,
    yearlyPlans,
    customPlans,
  };
}
