import { FC } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const IconFacility: FC<IOwnProps> = ({
  className,
  fillColor = 'var(--sideBarIcon)',
}) => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1 2.99657C1 1.64935 2.30497 0.687478 3.59184 1.08615L11.5918 3.56452C12.4292 3.82393 13 4.59836 13 5.47495V13.0015C13 14.1061 12.1046 15.0015 11 15.0015H3C1.89543 15.0015 1 14.1061 1 13.0015V2.99657Z"
        stroke={fillColor}
        strokeWidth="2"
      />
      <rect x="4" y="6" width="2" height="2" fill={fillColor} />
      <rect x="8" y="6" width="2" height="2" fill={fillColor} />
      <rect x="8" y="10" width="2" height="2" fill={fillColor} />
      <rect x="4" y="10" width="2" height="2" fill={fillColor} />
    </svg>
  );
};

export default IconFacility;
