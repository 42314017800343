import { IGeoFenceMap } from 'components/Fleet/TabGeoFences/_models/geoFenceMap';
import { ITollMap } from 'components/Fleet/TabTolls/_models/tollMap';
import { ITrailerMap } from 'components/Fleet/TabTrailers/_models/trailerMapModels';
import { ITruckMap } from 'components/Fleet/TabTrucks/_models/truckMapModel';
import { AppState } from 'redux/store';
import { IGoogleReducer, MapTypeId } from 'services/google/_redux/googleTypes';

export const googleSelector = (state: AppState): IGoogleReducer => state.google;
export const mapTypeIdSelector = (state: AppState): MapTypeId =>
  state.google.mapTypeId;
export const googleTruckMapSelector = (state: AppState): ITruckMap =>
  state.google.truckMap;
export const googleGeoFenceMapSelector = (state: AppState): IGeoFenceMap =>
  state.google.geoFenceMap;
export const googleTollMapSelector = (state: AppState): ITollMap =>
  state.google.tollMap;
export const googleTrailerMapSelector = (state: AppState): ITrailerMap =>
  state.google.trailerMap;
