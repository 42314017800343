import { FC, useEffect } from 'react';

import { TextareaAutosize } from '@mui/material';
import clsx from 'clsx';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import usePrevious from 'hooks/usePreviousValue';
import useTestAttrRef from 'hooks/useTestAttrRef';
import useToggle from 'hooks/useToggle';

import styles from './Textarea.module.scss';

type OwnProps = {
  placeholder: string;
  className: string;
  id: string;
  checkbox: JSX.Element;
  toggleAreaSize?: boolean;
  disabled?: boolean;
  defaultRowsCount?: number;
  dataTestPrefix?: string;
};

type Props = OwnProps & WrappedFieldInputProps & WrappedFieldMetaProps;

const Textarea: FC<Partial<Props>> = ({
  placeholder,
  id,
  className,
  value,
  error,
  touched,
  onChange,
  onBlur,
  disabled = false,
  toggleAreaSize = false,
  defaultRowsCount = 4,
  checkbox,
  dataTestPrefix,
}) => {
  const inputRef = useTestAttrRef<HTMLTextAreaElement>(null, dataTestPrefix);
  const prevDisabled = usePrevious(disabled);

  const { isOpen: isExpanded, toggleVisibility: toggleTextareaSize } =
    useToggle(false);

  const shouldShowSizeToggler =
    toggleAreaSize &&
    ((disabled &&
      value &&
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      (value.match(/\n/g) || '').length + 1 > defaultRowsCount) ||
      value.length > 400);

  useEffect(() => {
    if (inputRef.current && !disabled && prevDisabled) inputRef.current.focus();
  }, [disabled]);

  return (
    <div
      id={`${id}-container`}
      className={clsx(styles.rootContainer, {
        [styles.rootContainerPadding]: shouldShowSizeToggler,
        [styles.rootContainerError]:
          shouldShowSizeToggler && touched && !!error,
      })}
    >
      <TextareaAutosize
        id={id}
        value={value || ''}
        className={clsx(styles.root, className, {
          [styles.hasError]: touched && !!error && !shouldShowSizeToggler,
          [styles.toggleSize]: shouldShowSizeToggler,
        })}
        placeholder={placeholder}
        onBlur={onBlur}
        minRows={defaultRowsCount}
        maxRows={isExpanded ? 100 : defaultRowsCount}
        onChange={onChange}
        disabled={disabled}
        ref={inputRef}
      />
      {checkbox && <div className={styles.checkbox}>{checkbox}</div>}
      {shouldShowSizeToggler && (
        <span
          className={clsx(styles.toggleSizeBtn, {
            [styles.isExpanded]: isExpanded,
          })}
          onClick={toggleTextareaSize}
        >
          {isExpanded ? 'Show less' : 'Show more'}
        </span>
      )}
    </div>
  );
};

export default Textarea;
