import { ISearchResult } from 'components/_common/SearchPanel/models';
import { IFacility } from 'components/Dispatch/TabFacilities/_models/facilityModel';

// eslint-disable-next-line import/prefer-default-export
export const transformFacilitiesSearchResult = (
  payload: IFacility[],
): ISearchResult[] => {
  return payload.map((item: IFacility) => {
    return {
      id: item.id,
      title: `${item.name}`,
      additional: '-',
      url: `/dispatch/facilities/${item.id}`,
    };
  });
};
