import {
  CREATE_TRAILER_SUCCESS,
  DELETE_TRAILER_SUCCESS,
  UPDATE_TRAILER_SUCCESS,
} from 'components/Fleet/TabTrailers/_redux/trailersTypes';
import {
  CREATE_TRUCK_SUCCESS,
  DELETE_TRUCK_SUCCESS,
  UPDATE_TRUCK_SUCCESS,
} from 'components/Fleet/TabTrucks/_redux/trucksTypes';
import {
  CREATE_DRIVER_SUCCESS,
  UPDATE_DRIVER_SUCCESS,
  DELETE_DRIVER_SUCCESS,
} from 'components/HumanResources/TabDrivers/_redux/driversTypes';

import {
  IResourcesAutocompleteReducer,
  GET_RESOURCES_AUTOCOMPLETE_DATA_INIT,
  GET_RESOURCES_AUTOCOMPLETE_DATA_SUCCESS,
  GET_RESOURCES_AUTOCOMPLETE_DATA_FAILED,
  resourcesActionTypes,
} from './resourcesTypes';

const initialState: IResourcesAutocompleteReducer = {
  trucks: [],
  trailers: [],
  drivers: [],
  dispatchers: [],
  inspectors: [],
  vehicles: [],
  internal_companies: [],
  _pending: false,
  _updatedTime: 0,
};

const resourcesReducer = (
  state = initialState,
  action: resourcesActionTypes,
): IResourcesAutocompleteReducer => {
  switch (action.type) {
    case GET_RESOURCES_AUTOCOMPLETE_DATA_INIT:
      return {
        ...state,
        _pending: true,
      };

    case GET_RESOURCES_AUTOCOMPLETE_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload,
        _pending: false,
        _updatedTime: Date.now(),
      };

    case GET_RESOURCES_AUTOCOMPLETE_DATA_FAILED:
      return {
        ...state,
        _pending: false,
      };

    case CREATE_DRIVER_SUCCESS:
    case UPDATE_DRIVER_SUCCESS:
    case DELETE_DRIVER_SUCCESS:
    case CREATE_TRUCK_SUCCESS:
    case UPDATE_TRUCK_SUCCESS:
    case DELETE_TRUCK_SUCCESS:
    case CREATE_TRAILER_SUCCESS:
    case UPDATE_TRAILER_SUCCESS:
    case DELETE_TRAILER_SUCCESS: {
      return {
        ...state,
        _updatedTime: 0,
      };
    }

    default:
      return state;
  }
};

export default resourcesReducer;
