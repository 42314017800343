import { FC } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const IconStrangeStar: FC<IOwnProps> = ({
  className,
  fillColor = '#86898E',
}) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16.7117 10.6498L12.4877 11.7238C12.3027 11.7753 12.1817 11.9527 12.2012 12.1438L12.5083 15.7321C12.5276 15.8783 12.4603 16.0224 12.3358 16.1013C12.2113 16.1802 12.0523 16.1795 11.9284 16.0996L9.05725 14.1466C8.91484 14.049 8.72344 14.0653 8.59953 14.1855L5.74874 16.9003C5.63278 17.0073 5.46246 17.0305 5.32209 16.9586C5.18172 16.8866 5.10109 16.7347 5.12012 16.5781L5.4359 12.0909C5.44975 11.9229 5.35622 11.7644 5.2025 11.6954L1.2344 9.9956C1.09153 9.92975 1 9.7867 1 9.62927C1 9.47183 1.09153 9.32881 1.2344 9.26293L5.19834 7.56489C5.35412 7.49488 5.44784 7.3332 5.43125 7.16313L5.12003 3.52641C5.10072 3.3802 5.16806 3.23612 5.29256 3.1572C5.41706 3.07828 5.57606 3.07897 5.6999 3.15892L8.55613 5.10182C8.70556 5.20407 8.90744 5.18068 9.02956 5.04698L12.7065 1.11471C12.8258 0.991799 13.0128 0.964502 13.1623 1.04817C13.3117 1.13185 13.3863 1.3056 13.3441 1.47167L12.2301 7.15841C12.1947 7.3306 12.2752 7.50589 12.4289 7.5911L16.7932 9.91237C16.9348 9.9911 17.0153 10.1472 16.9976 10.3082C16.9798 10.4693 16.8671 10.604 16.7117 10.6498Z"
        stroke={fillColor}
        strokeWidth="2"
      />
    </svg>
  );
};

export default IconStrangeStar;
