import { FC } from 'react';

import { useSelector } from 'react-redux';

import { userThemeSelector } from 'redux/userSettings/userSettingsSelectors';

interface IOwnProps {
  className?: string;
}

const IconFolder: FC<IOwnProps> = ({ className }) => {
  const theme = useSelector(userThemeSelector);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M0 0H24V24H0V0Z" fill="#F4F5F6" />
      <path
        d="M11.5938 4.89023L10.7617 5.4449L11.5938 4.89023ZM14.0703 6.99979L14.0703 7.99979L14.0703 6.99979ZM12.4063 6.10914L13.2384 5.55447L12.4063 6.10914ZM19.0001 7.00007L19.0001 8.00007L19.0001 7.00007ZM2 5.99985V18H4V5.99985H2ZM5 21H19V19H5V21ZM22 18V9.00007H20V18H22ZM19.0002 6.00007L14.0704 5.99979L14.0703 7.99979L19.0001 8.00007L19.0002 6.00007ZM13.2384 5.55447L12.4258 4.33556L10.7617 5.4449L11.5742 6.66381L13.2384 5.55447ZM9.92946 2.99957L4.99983 2.99985L4.99994 4.99985L9.92957 4.99957L9.92946 2.99957ZM12.4258 4.33556C11.8694 3.50086 10.9326 2.99952 9.92946 2.99957L9.92957 4.99957C10.264 4.99955 10.5762 5.16667 10.7617 5.4449L12.4258 4.33556ZM14.0704 5.99979C13.7361 5.99978 13.4238 5.83267 13.2384 5.55447L11.5742 6.66381C12.1306 7.49841 13.0672 7.99974 14.0703 7.99979L14.0704 5.99979ZM22 9.00007C22 7.34328 20.657 6.00016 19.0002 6.00007L19.0001 8.00007C19.5523 8.0001 20 8.44781 20 9.00007H22ZM19 21C20.6569 21 22 19.6568 22 18H20C20 18.5522 19.5523 19 19 19V21ZM2 18C2 19.6568 3.34315 21 5 21V19C4.44772 19 4 18.5522 4 18H2ZM4 5.99985C4 5.44758 4.44768 4.99988 4.99994 4.99985L4.99983 2.99985C3.34304 2.99994 2 4.34306 2 5.99985H4Z"
        fill={theme === 'dark' ? '#fff' : 'black'}
      />
    </svg>
  );
};

export default IconFolder;
