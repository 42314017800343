import { IPagination } from 'components/_common/Navigation/Pagination/_redux/paginationTypes';
import { LOGOUT } from 'components/Auth/_redux/authTypes';
import { IInspectionFile } from 'components/Fleet/_models/commonModels';
import { IWithError, IWithLoaded, IWithPending } from 'utils/models';

import { ITrailerInspection } from '../../../TabTrailers/TrailerProfile/TrailerInspections/_models/trailerInspection';
import { ITruckInspection } from '../../../TabTrucks/TruckProfile/TruckInspections/_models/truckInspection';
import { defaultInspectionData } from '../../_helpers/defaultInspectionData';
import { IInspection } from '../../_models/vehicleInspectionModels';
import { CLEAR_INSPECTION_ERROR } from '../../_redux/tabInspectionsTypes';
import { ITandemInspectionsListItem } from '../_models/tandemInspection';
import {
  ADD_TANDEM_INSPECTION_PHOTO_COMMENT,
  ADD_TANDEM_INSPECTION_PHOTO_COMMENT_FAIL,
  ADD_TANDEM_INSPECTION_PHOTO_COMMENT_SUCCESS,
  CLEAR_CURRENT_TANDEM_INSPECTION,
  CREATE_TANDEM_INSPECTION,
  CREATE_TANDEM_INSPECTION_FAIL,
  CREATE_TANDEM_INSPECTION_SUCCESS,
  DELETE_TANDEM_INSPECTION_PHOTO,
  DELETE_TANDEM_INSPECTION_PHOTO_COMMENT,
  DELETE_TANDEM_INSPECTION_PHOTO_COMMENT_FAIL,
  DELETE_TANDEM_INSPECTION_PHOTO_COMMENT_SUCCESS,
  DELETE_TANDEM_INSPECTION_PHOTO_FAIL,
  DELETE_TANDEM_INSPECTION_PHOTO_SUCCESS,
  DRAFT_TANDEM_INSPECTION_FAIL,
  DRAFT_TANDEM_INSPECTION_SUCCESS,
  GET_TANDEM_INSPECTION_DRAFT_FAIL,
  GET_TANDEM_INSPECTION_DRAFT_SUCCESS,
  SET_TANDEM_DRAFT_STARTED,
  SET_TANDEM_INSPECTION_BACK_STEP,
  TandemInspectionActions,
  UNMOUNT_TANDEM_INSPECTION_FORM,
  UPDATE_TANDEM_INSPECTION_PHOTO_COMMENT,
  UPDATE_TANDEM_INSPECTION_PHOTO_COMMENT_FAIL,
  UPDATE_TANDEM_INSPECTION_PHOTO_COMMENT_SUCCESS,
  UPLOAD_TANDEM_INSPECTION_PHOTO,
  UPLOAD_TANDEM_INSPECTION_PHOTO_FAIL,
  UPLOAD_TANDEM_INSPECTION_PHOTO_SUCCESS,
} from './tandemInspectionsTypes';

export interface ITandemInspectionState
  extends IWithPending,
    IWithLoaded,
    IWithError {
  pagination: IPagination | null;
  list: ITandemInspectionsListItem[];
  files: IInspectionFile[];
  tandemVehicleFiles: IInspectionFile[];
  backStep: number;
  isDraftStarted: boolean;
  currentTandemInspection: {
    inspection: IInspection;
    vehicle_inspections: [ITrailerInspection | ITruckInspection] | [];
  };
}

const initialState: ITandemInspectionState = {
  pagination: null,
  list: [],
  files: [],
  tandemVehicleFiles: [],
  backStep: 1,
  isDraftStarted: false,
  currentTandemInspection: {
    inspection: { ...defaultInspectionData },
    vehicle_inspections: [],
  },
  _pending: false,
  _loaded: false,
  _error: null,
};

const tandemInspectionReducers = (
  state = initialState,
  action: TandemInspectionActions,
): ITandemInspectionState => {
  switch (action.type) {
    // CREATE_TANDEM_INSPECTION
    case CREATE_TANDEM_INSPECTION:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case CREATE_TANDEM_INSPECTION_SUCCESS:
      return {
        ...state,
        currentTandemInspection: {
          inspection: action.payload.inspection,
          vehicle_inspections: action.payload.inspection.vehicle_inspections,
        },
        files: [],
        _loaded: true,
        _pending: false,
        _error: null,
      };
    case CREATE_TANDEM_INSPECTION_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };

    // GET_TANDEM_INSPECTION_DRAFT
    case GET_TANDEM_INSPECTION_DRAFT_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const truckInspectionIndex = action.payload.inspection.vehicle_inspections
        .map(inspection => inspection.vehicle_type)
        .indexOf('truck');
      // eslint-disable-next-line no-case-declarations
      const trailerInspectionIndex =
        action.payload.inspection.vehicle_inspections
          .map(inspection => inspection.vehicle_type)
          .indexOf('trailer');
      return {
        ...state,
        currentTandemInspection: {
          inspection: action.payload.inspection,
          vehicle_inspections: action.payload.inspection.vehicle_inspections,
        },
        files:
          action.payload.inspection.vehicle_inspections[truckInspectionIndex]
            ?.files || [],
        tandemVehicleFiles:
          action.payload.inspection.vehicle_inspections[trailerInspectionIndex]
            ?.files || [],
      };
    case GET_TANDEM_INSPECTION_DRAFT_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };

    // CLEAR_CURRENT_TANDEM_INSPECTION
    case CLEAR_CURRENT_TANDEM_INSPECTION:
      return {
        ...state,
        currentTandemInspection: {
          inspection: { ...defaultInspectionData },
          vehicle_inspections: [],
        },
        files: [],
        _error: null,
      };

    // UPLOAD_TANDEM_INSPECTION_PHOTO
    case UPLOAD_TANDEM_INSPECTION_PHOTO:
      return {
        ...state,
        files: [
          ...state.files,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          { ...action.payload.data, loading: true } as any,
        ],
        tandemVehicleFiles: [
          ...state.tandemVehicleFiles,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          { ...action.payload.data, loading: true } as any,
        ],
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case UPLOAD_TANDEM_INSPECTION_PHOTO_SUCCESS: {
      const {
        payload: { uploadIndex, truckFile, trailerFile },
      } = action;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const files = state.files.map((file: any) =>
        file.uploadIndex === uploadIndex
          ? {
              ...truckFile,
              comments: [],
              loading: false,
            }
          : file,
      );

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const tandemVehicleFiles = state.tandemVehicleFiles.map((file: any) =>
        file.uploadIndex === uploadIndex
          ? {
              ...trailerFile,
              comments: [],
              loading: false,
            }
          : file,
      );

      return {
        ...state,
        files,
        tandemVehicleFiles,
        _loaded: true,
        _pending: false,
        _error: null,
      };
    }
    case UPLOAD_TANDEM_INSPECTION_PHOTO_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };

    // DELETE_TRAILER_INSPECTION_PHOTO
    case DELETE_TANDEM_INSPECTION_PHOTO:
      return {
        ...state,
        files: state.files.filter(
          file => file.id !== action.payload.data.truckFileId,
        ),
        tandemVehicleFiles: state.tandemVehicleFiles.filter(
          file => file.id !== action.payload.data.trailerFileId,
        ),
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case DELETE_TANDEM_INSPECTION_PHOTO_SUCCESS:
      return {
        ...state,
        files: state.files.filter(file => file.id !== action.payload),
        _loaded: true,
        _pending: false,
        _error: null,
      };
    case DELETE_TANDEM_INSPECTION_PHOTO_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: null,
      };

    // ADD_TRAILER_INSPECTION_PHOTO_COMMENT
    case ADD_TANDEM_INSPECTION_PHOTO_COMMENT:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case ADD_TANDEM_INSPECTION_PHOTO_COMMENT_SUCCESS:
      return {
        ...state,
        files: state.files.map(file =>
          file.id === action.payload.fileId
            ? { ...file, comments: [...file.comments, action.payload.comment] }
            : file,
        ),
        _loaded: true,
        _pending: false,
        _error: null,
      };
    case ADD_TANDEM_INSPECTION_PHOTO_COMMENT_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };

    // UPDATE_TRAILER_INSPECTION_PHOTO_COMMENT
    case UPDATE_TANDEM_INSPECTION_PHOTO_COMMENT:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case UPDATE_TANDEM_INSPECTION_PHOTO_COMMENT_SUCCESS:
      return {
        ...state,
        files: state.files.map(file =>
          file.id === action.payload.fileId
            ? {
                ...file,
                comments: file.comments.map(comment =>
                  comment.id === action.payload.comment.id
                    ? action.payload.comment
                    : comment,
                ),
              }
            : file,
        ),
        _loaded: true,
        _pending: false,
        _error: null,
      };
    case UPDATE_TANDEM_INSPECTION_PHOTO_COMMENT_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };

    // DELETE_TRAILER_INSPECTION_PHOTO_COMMENT
    case DELETE_TANDEM_INSPECTION_PHOTO_COMMENT:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case DELETE_TANDEM_INSPECTION_PHOTO_COMMENT_SUCCESS:
      return {
        ...state,
        files: state.files.map(file =>
          file.id === action.payload.fileId
            ? {
                ...file,
                comments: file.comments.filter(
                  comment => comment.id !== action.payload.commentId,
                ),
              }
            : file,
        ),
        _loaded: true,
        _pending: false,
        _error: null,
      };
    case DELETE_TANDEM_INSPECTION_PHOTO_COMMENT_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };

    case DRAFT_TANDEM_INSPECTION_SUCCESS:
      return {
        ...state,
        currentTandemInspection: {
          ...state.currentTandemInspection,
          inspection: {
            ...state.currentTandemInspection.inspection,
            inventories_data: action.payload.inventories_data,
          },
        },
      };
    case DRAFT_TANDEM_INSPECTION_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };
    case SET_TANDEM_INSPECTION_BACK_STEP:
      return {
        ...state,
        backStep: action.payload.backStep,
      };
    case SET_TANDEM_DRAFT_STARTED:
      return {
        ...state,
        isDraftStarted: action.payload.isDraftStarted,
      };
    // OTHER
    case UNMOUNT_TANDEM_INSPECTION_FORM:
      return {
        ...state,
        files: [],
        backStep: 1,
        isDraftStarted: false,
        currentTandemInspection: {
          inspection: { ...defaultInspectionData },
          vehicle_inspections: [],
        },
        _error: null,
      };

    case CLEAR_INSPECTION_ERROR:
      return {
        ...state,
        _error: null,
      };

    case LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default tandemInspectionReducers;
