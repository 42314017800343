import { FC, memo } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const IconSplitter: FC<IOwnProps> = ({
  className,
  fillColor = 'var(--secondaryText)',
}) => {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M6 1L2 5H10L6 1Z" fill={fillColor} />
      <path d="M6 14L2 9H10L6 14Z" fill={fillColor} />
    </svg>
  );
};

export default memo(IconSplitter);
