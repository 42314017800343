import { FC } from 'react';

interface IOwnProps {
  className?: string;
  onClick?: () => void;
}

const IconPen: FC<IOwnProps> = ({ className, onClick }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M12.9494 8.70576C13.3399 9.09628 13.9731 9.09628 14.3636 8.70576C14.7541 8.31523 14.7541 7.68207 14.3636 7.29154L12.9494 8.70576ZM8.70673 1.63469C8.31621 1.24417 7.68304 1.24417 7.29252 1.63469C6.902 2.02521 6.902 2.65838 7.29252 3.0489L8.70673 1.63469ZM10.1209 12.9484L10.2115 13.9443L10.8628 13.8851L11.0696 13.2646L10.1209 12.9484ZM2.34277 13.6555L1.34688 13.565L1.23824 14.76L2.43331 14.6514L2.34277 13.6555ZM3.04988 5.87733L2.73365 4.92865L2.1132 5.13546L2.05399 5.78679L3.04988 5.87733ZM14.3636 7.29154L8.70673 1.63469L7.29252 3.0489L12.9494 8.70576L14.3636 7.29154ZM11.2936 6.26821L9.17226 12.6322L11.0696 13.2646L13.191 6.90067L11.2936 6.26821ZM10.0304 11.9525L2.25224 12.6596L2.43331 14.6514L10.2115 13.9443L10.0304 11.9525ZM3.33867 13.746L4.04577 5.96787L2.05399 5.78679L1.34688 13.565L3.33867 13.746ZM3.36611 6.82601L9.73007 4.70469L9.09761 2.80733L2.73365 4.92865L3.36611 6.82601Z"
        fill="#2C6AF6"
      />
      <path
        d="M8.41406 8.41406C8.41406 7.86178 7.96635 7.41406 7.41406 7.41406C6.86178 7.41406 6.41406 7.86178 6.41406 8.41406C6.41406 8.96635 6.86178 9.41406 7.41406 9.41406C7.96635 9.41406 8.41406 8.96635 8.41406 8.41406Z"
        fill="#2C6AF6"
      />
    </svg>
  );
};

export default IconPen;
