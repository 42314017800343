import { FC } from 'react';

import clsx from 'clsx';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import {
  BlueCheckbox,
  BlackTooltip,
} from 'components/_common/CustomMaterialComponents';
import { getAttrName } from 'hooks/useTestAttrRef';

import styles from './CustomCheckboxes.module.scss';

interface IOwnProps {
  id: string;
  description?: string;
  className: string;
  disabled?: boolean;
  checkboxText?: string;
}

type Props = IOwnProps & WrappedFieldInputProps & WrappedFieldMetaProps;

const CustomCheckbox: FC<Partial<Props>> = ({
  className,
  description = '',
  value,
  onChange,
  name,
  disabled = false,
  checkboxText,
}) => {
  // const inputRef = useTestAttrRef<HTMLInputElement>(null);

  return (
    <>
      <BlackTooltip
        title={<div className={styles.tooltipContent}>{checkboxText}</div>}
        maxWidth={150}
        placement="top"
        arrow
        disableHoverListener={!checkboxText}
        disableFocusListener={!checkboxText}
      >
        <div className={clsx({ [styles.forEmailToolTip]: checkboxText })}>
          <BlueCheckbox
            name={name}
            // inputRef={inputRef}
            color="primary"
            onChange={onChange}
            checked={!!value}
            disabled={disabled}
            classes={{
              root: className,
            }}
            inputProps={{
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              'data-test': `${getAttrName(name)}_checkbox`,
              'data-value': value ? 'checked' : 'unchecked',
            }}
          />
        </div>
      </BlackTooltip>
      <span>{description}</span>
    </>
  );
};

export default CustomCheckbox;
