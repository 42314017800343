import { memo, useEffect, useState } from 'react';

import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { MainSidebarAccordion } from './_models';
import { setOverFlagAction } from './_redux/mainSidebarActions';
import { mainSidebarSelector } from './_redux/mainSidebarSelectors';
import SidebarAccordion from './SidebarAccordion';
import SidebarFooter from './SidebarFooter';
import SidebarHeader from './SidebarHeader';

import styles from './MainSidebar.module.scss';

type Props = {
  className?: string;
  sidebarConfig: MainSidebarAccordion[];
};

const MainSidebar = ({ sidebarConfig, className }: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const checkActiveItemByPath = (paths?: Array<any>) =>
    paths && paths.some(x => pathname.startsWith(x));

  const { collapsedFlag, overFlag } = useSelector(mainSidebarSelector);

  const [expanded, setExpanded] = useState<string | false>(false);

  function onMouseEnterHandler() {
    dispatch(setOverFlagAction(true));
  }

  function onMouseLeaveHandler() {
    dispatch(setOverFlagAction(false));
  }

  const onCollapseHandler = (isExpanded: boolean, id: string) => {
    setExpanded(isExpanded ? id : false);
  };

  useEffect(() => {
    const activatedItem = sidebarConfig.find(item =>
      checkActiveItemByPath(item.paths),
    );

    if (activatedItem) {
      setExpanded(activatedItem.id);
    }
  }, []);
  return (
    <aside
      id="sidebar"
      className={clsx(
        className,
        styles.root,
        { [styles.collapsed]: collapsedFlag },
        { [styles.sticked]: collapsedFlag && !overFlag },
      )}
      onMouseLeave={onMouseLeaveHandler}
    >
      <SidebarHeader />
      <section onMouseEnter={onMouseEnterHandler}>
        {sidebarConfig.map(config => {
          return (
            <SidebarAccordion
              expanded={expanded}
              onCollapseHandler={onCollapseHandler}
              key={config.id}
              item={config}
            />
          );
        })}
      </section>
      <SidebarFooter />
    </aside>
  );
};

export default memo(MainSidebar);
