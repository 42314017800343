import { FC } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

type Props = IOwnProps;

const IconPause: FC<Props> = ({ className, fillColor = 'white' }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect x="3" y="2" width="3" height="12" rx="1" fill={fillColor} />
      <rect x="10" y="2" width="3" height="12" rx="1" fill={fillColor} />
    </svg>
  );
};

export default IconPause;
