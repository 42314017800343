import { AxiosError } from 'axios';

import { IDeleteFileAction } from 'redux/_common/commonTypes';
import { ILocalFile } from 'redux/_common/models';

import { ITrailer } from '../_models/trailerModel';
import {
  CLEAR_TRAILERS_TRACKING_DATA,
  CREATE_TRAILER,
  CREATE_TRAILER_FAIL,
  CREATE_TRAILER_SUCCESS,
  DELETE_LOCAL_CLAIM_PHOTO,
  DELETE_TRAILER,
  DELETE_TRAILER_CLAIM_PHOTO,
  DELETE_TRAILER_CLAIM_PHOTO_FAIL,
  DELETE_TRAILER_CLAIM_PHOTO_SUCCESS,
  DELETE_TRAILER_FAIL,
  DELETE_TRAILER_FILE,
  DELETE_TRAILER_SUCCESS,
  GET_TRAILER,
  GET_TRAILER_FAIL,
  GET_TRAILER_SUCCESS,
  GET_TRAILERS,
  GET_TRAILERS_FAIL,
  GET_TRAILERS_SUCCESS,
  GET_TRAILERS_TRACKING_DATA,
  IDeleteLocalClaimPhotoAction,
  IDeleteTrailerClaimPhotoAction,
  IDeleteTrailerClaimPhotoFailAction,
  IDeleteTrailerClaimPhotoSuccessAction,
  IDeleteTrailerFileAction,
  IGetTrailers,
  IGetTrailersTrackingData,
  IToggleTrailerPin,
  IUnlockTrailerInspectionAction,
  IUploadTrailerClaimPhotoAction,
  IUploadTrailerClaimPhotoActionFail,
  IUploadTrailerClaimPhotoActionSuccess,
  IUploadTrailerFilesAction,
  TOGGLE_TRAILER_PIN,
  UNLOCK_TRAILER_INSPECTION,
  UPDATE_TRAILER,
  UPLOAD_TRAILER_CLAIM_PHOTO,
  UPLOAD_TRAILER_CLAIM_PHOTO_FAIL,
  UPLOAD_TRAILER_CLAIM_PHOTO_SUCCESS,
  UPLOAD_TRAILER_FILES,
  CLEAR_TRAILER,
} from './trailersTypes';

// CREATE TRAILER
export const createTrailerAction = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
  callback: (id: number) => void,
) => ({
  type: CREATE_TRAILER,
  payload: { values, callback },
});

export const createTrailerSuccessAction = () => ({
  type: CREATE_TRAILER_SUCCESS,
});

export const createTrailerFailAction = (error: AxiosError) => ({
  type: CREATE_TRAILER_FAIL,
  payload: error,
});

// GET TRAILERS
export const getTrailersAction = (payload: IGetTrailers['payload']) => ({
  type: GET_TRAILERS,
  payload,
});
export const getTrailersSuccessAction = (payload: ITrailer[]) => ({
  type: GET_TRAILERS_SUCCESS,
  payload,
});
export const getTrailersFailAction = (error: AxiosError) => ({
  type: GET_TRAILERS_FAIL,
  payload: error,
});

// GET TRAILER BY ID
export const getTrailerAction = (
  id: number | string,
  callback?: (data: ITrailer) => void,
  dispatchToStore = true,
) => ({
  type: GET_TRAILER,
  id,
  dispatchToStore,
  callback,
});

export const clearTrailerAction = () => ({
  type: CLEAR_TRAILER,
});

export const getTrailerSuccessAction = (data: ITrailer) => ({
  type: GET_TRAILER_SUCCESS,
  payload: data,
});

export const getTrailerFailAction = (error: AxiosError) => ({
  type: GET_TRAILER_FAIL,
  payload: error,
});

// UPDATE TRAILER BY ID
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateTrailerAction = (values: any, callback?: () => void) => ({
  type: UPDATE_TRAILER,
  payload: { values, callback },
});

// DELETE TRAILER BY ID
export const deleteTrailerAction = (id: number, callback?: () => void) => ({
  type: DELETE_TRAILER,
  payload: { id, callback },
});

export const deleteTrailerSuccessAction = () => ({
  type: DELETE_TRAILER_SUCCESS,
});

export const deleteTrailerFailAction = (error: AxiosError) => ({
  type: DELETE_TRAILER_FAIL,
  payload: error,
});

// FILES
export const uploadTrailerFilesAction = ({
  files,
  itemId,
}: {
  files: ILocalFile[];
  itemId?: number;
}): IUploadTrailerFilesAction => ({
  type: UPLOAD_TRAILER_FILES,
  payload: { files, itemId },
});

export const deleteTrailerFileAction = ({
  fileId,
  itemId,
}: IDeleteFileAction['payload']): IDeleteTrailerFileAction => ({
  type: DELETE_TRAILER_FILE,
  payload: { fileId, itemId },
});

// TOGGLE_TRAILER_PIN
export const toggleTrailerPinAction = (
  payload: IToggleTrailerPin['payload'],
) => ({ type: TOGGLE_TRAILER_PIN, payload });

// MAP START
// GET_TRAILERS_TRACKING_DATA
export const getTrackingTrailersAction = (
  payload: IGetTrailersTrackingData['payload'],
) => ({ type: GET_TRAILERS_TRACKING_DATA, payload });

// CLEAR_TRAILERS_TRACKING_DATA
export const clearTrailersTrackingDataAction = () => ({
  type: CLEAR_TRAILERS_TRACKING_DATA,
});
// MAP END

export const unlockTrailerInspectionAction = (
  payload: IUnlockTrailerInspectionAction['payload'],
): IUnlockTrailerInspectionAction => ({
  type: UNLOCK_TRAILER_INSPECTION,
  payload,
});

// UPLOAD CLAIM PHOTO
export const uploadTrailerClaimPhotoAction = (
  payload: IUploadTrailerClaimPhotoAction['payload'],
): IUploadTrailerClaimPhotoAction => ({
  type: UPLOAD_TRAILER_CLAIM_PHOTO,
  payload,
});

export const uploadTrailerClaimPhotoSuccessAction = (
  payload: IUploadTrailerClaimPhotoActionSuccess['payload'],
): IUploadTrailerClaimPhotoActionSuccess => ({
  type: UPLOAD_TRAILER_CLAIM_PHOTO_SUCCESS,
  payload,
});

export const uploadTrailerClaimPhotoFailAction = (
  payload: IUploadTrailerClaimPhotoActionFail['payload'],
): IUploadTrailerClaimPhotoActionFail => ({
  type: UPLOAD_TRAILER_CLAIM_PHOTO_FAIL,
  payload,
});

// DELETE CLAIM PHOTO
export const deleteTrailerClaimPhotoAction = (
  payload: IDeleteTrailerClaimPhotoAction['payload'],
): IDeleteTrailerClaimPhotoAction => ({
  type: DELETE_TRAILER_CLAIM_PHOTO,
  payload,
});

export const deleteTrailerClaimPhotoSuccessAction = (
  payload: IDeleteTrailerClaimPhotoSuccessAction['payload'],
): IDeleteTrailerClaimPhotoSuccessAction => ({
  type: DELETE_TRAILER_CLAIM_PHOTO_SUCCESS,
  payload,
});

export const deleteTrailerClaimPhotoFailAction = (
  payload: IDeleteTrailerClaimPhotoFailAction['payload'],
): IDeleteTrailerClaimPhotoFailAction => ({
  type: DELETE_TRAILER_CLAIM_PHOTO_FAIL,
  payload,
});

// DELETE LOCAL CLAIM PHOTO
export const deleteLocalClaimPhotoAction =
  (): IDeleteLocalClaimPhotoAction => ({
    type: DELETE_LOCAL_CLAIM_PHOTO,
  });
