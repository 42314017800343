import { LOGOUT } from 'components/Auth/_redux/authTypes';

import {
  CategoriesTypes,
  CREATE_CATEGORY,
  CREATE_CATEGORY_FAIL,
  CREATE_CATEGORY_SUCCESS,
  GET_CATEGORIES,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORY,
  GET_CATEGORY_FAIL,
  GET_CATEGORY_SUCCESS,
  ICategoriesReducer,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  CLEAR_CURRENT_CATEGORY,
  REMOVE_CATEGORY,
  REMOVE_CATEGORY_SUCCESS,
  REMOVE_CATEGORY_FAIL,
  GET_ALL_CATEGORIES_SUCCESS,
} from './categoriesTypes';

const initialState: ICategoriesReducer = {
  pagination: null,
  list: [],
  fullList: [], // list of all categories
  currentCategory: null,
  total: 0,
  _pending: false,
  _error: null,
};

const categoriesReducer = (
  state = initialState,
  action: CategoriesTypes,
): ICategoriesReducer => {
  switch (action.type) {
    case CREATE_CATEGORY:
      return { ...state, _error: null };
    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        currentCategory: null,
        list: [...state.list, action.payload],
        _error: null,
      };
    case CREATE_CATEGORY_FAIL:
      return { ...state, _error: action.payload };

    // REMOVE_CATEGORY
    case REMOVE_CATEGORY:
      return { ...state };
    case REMOVE_CATEGORY_SUCCESS:
      return {
        ...state,
        currentCategory: null,
        list: state.list.filter(category => category.id !== action.payload.id),
      };
    case REMOVE_CATEGORY_FAIL:
      return { ...state, _error: action.payload };

    // UPDATE_CATEGORY
    case UPDATE_CATEGORY:
      return { ...state };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        currentCategory: null,
        list: state.list.map(category =>
          category.id === action.payload.id ? action.payload : category,
        ),
      };
    case UPDATE_CATEGORY_FAIL:
      return { ...state, _error: action.payload };

    // GET_CATEGORIES
    case GET_CATEGORIES:
      return { ...state, list: [], _pending: true, _error: null };
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        _pending: false,
        list: action.payload.categories,
        pagination: action.payload.pagination,
        total: action.payload.pagination
          ? action.payload.pagination.total
          : state.total,
      };

    case GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        fullList: action.payload.categories,
      };
    case GET_CATEGORIES_FAIL:
      return { ...state };

    // GET_CATEGORY
    case GET_CATEGORY:
      return {
        ...state,
        currentCategory: null,
        _pending: true,
        _error: null,
      };
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        currentCategory: action.payload,
        _pending: false,
        _error: null,
      };
    case GET_CATEGORY_FAIL:
      return { ...state, _pending: false, _error: action.payload };

    // CLEAR CURRENT CATEGORY
    case CLEAR_CURRENT_CATEGORY:
      return { ...state, currentCategory: null };

    // LOGOUT
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};

export default categoriesReducer;
