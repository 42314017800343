import { FC, useMemo, useCallback, memo } from 'react';

import clsx from 'clsx';
import PhoneInput, {
  Props as PhoneInputProps,
  formatPhoneNumber as formatPhoneNumberLocal,
  formatPhoneNumberIntl,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { useSelector } from 'react-redux';
import { WrappedFieldMetaProps, WrappedFieldInputProps } from 'redux-form';

import useTestAttrRef from 'hooks/useTestAttrRef';
import { userSettingsSelector } from 'redux/userSettings/userSettingsSelectors';
import { getOS } from 'utils/helpers';

import 'react-phone-number-input/style.css';
import styles from './Input.module.scss';

export const formatPhoneNumber = (
  value = '',
  format: 'local' | 'international' = 'international',
) => {
  let result = value.replaceAll(' ', '');
  if (result) {
    if (!result.startsWith('+')) result = `+${result}`;
    result =
      format === 'international'
        ? formatPhoneNumberIntl(result)
        : formatPhoneNumberLocal(result);
  }
  return result || value;
};

export const formatPhoneNumberExt = (
  phoneNumber?: string,
  extPhoneNumber?: string | number,
) => {
  const result = phoneNumber ? formatPhoneNumber(phoneNumber) : '—';
  return extPhoneNumber ? `${result}, ${extPhoneNumber}` : result;
};

export const normalizePhoneNumber = (value: string) =>
  value && `${Number(value.replaceAll(' ', ''))}`;

export const validatePhoneNumber = (value: string) =>
  value && !isPossiblePhoneNumber(formatPhoneNumber(value))
    ? 'Field has invalid format'
    : undefined;

export const checkPhoneNumber = (value: string) =>
  value && !isValidPhoneNumber(formatPhoneNumber(value))
    ? 'Entered phone number does not match country rules'
    : undefined;

type OwnProps = {
  id: string;
  readOnly: boolean;
  icon?: JSX.Element | string;
  iconRight: string | JSX.Element;
  checkbox?: JSX.Element;
};

type Props = PhoneInputProps<OwnProps> &
  WrappedFieldInputProps &
  WrappedFieldMetaProps;

const PhoneNumberInput: FC<Partial<Props>> = ({
  id,
  className,
  // placeholder,
  readOnly,
  icon,
  iconRight,
  disabled,
  warning,
  error,
  touched,
  value,
  name,
  onChange,
  onBlur,
  checkbox,
  defaultCountry,
}) => {
  const { countries } = useSelector(userSettingsSelector);

  const inputRef = useTestAttrRef<HTMLInputElement>(null);

  const countryOptionsOrder = useMemo(() => {
    type CountryOptions = PhoneInputProps<OwnProps>['countryOptionsOrder'];
    return (
      countries
        ? [
            ...Object.values(countries).map(({ iso_code_2 }) =>
              iso_code_2.toUpperCase(),
            ),
            '|',
            '...',
          ]
        : undefined
    ) as CountryOptions;
  }, [countries]);

  const countrySelectProps = useMemo(
    () => ({
      tabIndex: '-1',
      unicodeFlags: getOS() !== 'Windows',
      // 'data-test': `${getAttrName(name || 'phone_number')}_country_select`,
    }),
    [name],
  );

  const handleChange = useCallback(
    (v?: string) => {
      if (onChange) onChange(v);
    },
    [onChange],
  );

  return (
    <div className={styles.root}>
      {icon && <i className={styles.icon}>{icon}</i>}
      {iconRight && <i className={styles.iconRight}>{iconRight}</i>}
      <PhoneInput
        id={id}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ref={inputRef as any}
        value={formatPhoneNumber(value)}
        disabled={disabled}
        readOnly={readOnly}
        // placeholder={placeholder}
        name={name}
        onChange={handleChange}
        onBlur={onBlur}
        className={clsx(
          styles.input,
          styles.inputPhone,
          {
            [styles.hasWarning]: touched && !!warning,
            [styles.hasError]: touched && !!error,
            [styles.withIcon]: icon,
            [styles.disabled]: disabled,
          },
          className,
        )}
        autoComplete="off"
        defaultCountry={defaultCountry}
        countryOptionsOrder={countryOptionsOrder}
        limitMaxLength
        countrySelectProps={countrySelectProps}
      />
      {checkbox && <div className={styles.checkbox}>{checkbox}</div>}
    </div>
  );
};

export default memo(PhoneNumberInput);
