import {
  DELETE_GPS_INTEGRATION,
  DELETE_GPS_INTEGRATION_FAIL,
  DELETE_GPS_INTEGRATION_SUCCESS,
  GET_GPS_INTEGRATIONS,
  GET_GPS_INTEGRATIONS_FAIL,
  GET_GPS_INTEGRATIONS_SUCCESS,
  GET_PAYROLL_SETTINGS,
  GET_PAYROLL_SETTINGS_FAIL,
  GET_PAYROLL_SETTINGS_SUCCESS,
  ISettingsReducer,
  SET_CURRENT_CONTRACT_TEMPLATE,
  settingsActionTypes,
  UPDATE_GPS_INTEGRATION,
  UPDATE_GPS_INTEGRATION_FAIL,
  UPDATE_GPS_INTEGRATION_SUCCESS,
  UPDATE_PAYROLL_SETTINGS,
  UPDATE_PAYROLL_SETTINGS_FAIL,
  UPDATE_PAYROLL_SETTINGS_SUCCESS,
} from './settingsTypes';

const initialState: ISettingsReducer = {
  payrollSettings: {
    settings: null,
    _pending: false,
    _loaded: false,
    _error: null,
  },
  gpsIntegrations: {
    items: null,
    _pending: false,
    _loaded: false,
    _error: null,
  },
  currentContractTemplate: {
    id: null,
    name: '',
    content: '',
    status: '',
    contract_type: '',
    internal_company_id: 0,
  },
};

const settingsReducers = (
  state = initialState,
  action: settingsActionTypes,
): ISettingsReducer => {
  switch (action.type) {
    case GET_PAYROLL_SETTINGS:
    case UPDATE_PAYROLL_SETTINGS:
      return {
        ...state,
        payrollSettings: {
          ...state.payrollSettings,
          _loaded: false,
          _pending: true,
          _error: null,
        },
      };

    case GET_PAYROLL_SETTINGS_FAIL:
    case UPDATE_PAYROLL_SETTINGS_FAIL:
      return {
        ...state,
        payrollSettings: {
          ...state.payrollSettings,
          _error: action.payload,
          _loaded: false,
          _pending: false,
        },
      };

    case GET_PAYROLL_SETTINGS_SUCCESS:
    case UPDATE_PAYROLL_SETTINGS_SUCCESS:
      return {
        ...state,
        payrollSettings: {
          ...state.payrollSettings,
          settings: action.payload,
          _loaded: true,
          _pending: false,
        },
      };

    case GET_GPS_INTEGRATIONS:
    case UPDATE_GPS_INTEGRATION:
    case DELETE_GPS_INTEGRATION:
      return {
        ...state,
        gpsIntegrations: {
          ...state.gpsIntegrations,
          _loaded: false,
          _pending: true,
          _error: null,
        },
      };

    case GET_GPS_INTEGRATIONS_FAIL:
    case UPDATE_GPS_INTEGRATION_FAIL:
    case DELETE_GPS_INTEGRATION_FAIL:
      return {
        ...state,
        gpsIntegrations: {
          ...state.gpsIntegrations,
          _error: action.payload,
          _loaded: false,
          _pending: false,
        },
      };

    case GET_GPS_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        gpsIntegrations: {
          ...state.gpsIntegrations,
          items: action.payload,
          _loaded: true,
          _pending: false,
        },
      };

    case UPDATE_GPS_INTEGRATION_SUCCESS: {
      if (!state.gpsIntegrations.items) break;
      const { payload } = action;
      const updatedItems = Array.isArray(payload) ? payload : [payload];
      const items = [...state.gpsIntegrations.items];
      updatedItems.forEach(updatedItem => {
        const index = items.findIndex(rec => rec.id === updatedItem.id);
        if (index === -1) items.push(updatedItem);
        else items[index] = updatedItem;
      });
      return {
        ...state,
        gpsIntegrations: {
          ...state.gpsIntegrations,
          items,
          _loaded: true,
          _pending: false,
        },
      };
    }

    case DELETE_GPS_INTEGRATION_SUCCESS: {
      if (!state.gpsIntegrations.items) break;
      const { payload } = action;
      const ids = Array.isArray(payload) ? payload : [payload];
      const items = [...state.gpsIntegrations.items];
      ids.forEach(id => {
        const index = items.findIndex(rec => rec.id === id);
        if (index !== -1) items.splice(index, 1);
      });
      return {
        ...state,
        gpsIntegrations: {
          ...state.gpsIntegrations,
          items,
          _loaded: true,
          _pending: false,
        },
      };
    }
    case SET_CURRENT_CONTRACT_TEMPLATE: {
      return {
        ...state,
        currentContractTemplate: action.payload,
      };
    }

    default:
  }
  return state;
};

export default settingsReducers;
