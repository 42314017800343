import moment from 'moment';

import { ISearchResult } from 'components/_common/SearchPanel/models';
import { SERVER_DATE_FORMAT } from 'constants/dateFormats';

import { IToll } from '../_models/tollModel';

export const transformTollsSearchResult = (
  payload: IToll[],
): ISearchResult[] => {
  return payload.map((item: IToll) => {
    return {
      id: +item.id,
      title: item.name,
      additional: '',
      url: `/fleet/tolls/${item.id}`,
      // TODO: gonna add properties after back end finish search task
    };
  });
};

export const getDateRange = (
  days: number,
  type: 'd' | 'months',
): { dateFrom: string; dateTo: string } => {
  const dateTo = moment().format(SERVER_DATE_FORMAT);
  const dateFrom = moment().subtract(days, type).format(SERVER_DATE_FORMAT);
  return { dateFrom, dateTo };
};

export const getFromToDateRange = (
  dateRange: string,
): { dateFrom: string; dateTo: string } => {
  switch (dateRange) {
    case 'lastWeek':
      return getDateRange(7, 'd');
    case 'lastTwoWeeks':
      return getDateRange(14, 'd');
    case 'lastThreeWeeks':
      return getDateRange(21, 'd');
    case 'lastMonth':
      return getDateRange(1, 'months');

    default:
      return getDateRange(7, 'd');
  }
};
