export const CHANGE_INSPECTION_APPROVE_STATUS =
  '[@@FleetInspections] CHANGE_INSPECTION_APPROVE_STATUS';

export interface IChangeInspectionApproveStatus {
  type: typeof CHANGE_INSPECTION_APPROVE_STATUS;
  payload: {
    inspectionId: number;
    approval_status: {
      status: 'approved' | 'declined';
      comment?: string;
    };
    callback: () => void;
  };
}

// TODO: VZ - delete
export const CLEAR_INSPECTION_ERROR =
  '[@@FleetInspections] CLEAR_INSPECTION_ERROR';

export interface IClearInspectionErrorAction {
  type: typeof CLEAR_INSPECTION_ERROR;
}
