import { LOGOUT } from 'components/Auth/_redux/authTypes';
import { ILocalFile } from 'redux/_common/models';

import {
  ADD_EMPLOYEE_TO_LOCATION_FAIL,
  CREATE_LOCATION,
  CREATE_LOCATION_FAIL,
  CREATE_LOCATION_SUCCESS,
  DELETE_LOCATION,
  DELETE_LOCATION_FAIL,
  DELETE_LOCATION_FILE_FAIL,
  DELETE_LOCATION_FILE_SUCCESS,
  DELETE_LOCATION_SUCCESS,
  GET_LOCATION_AVAILABLE_EMPLOYEES,
  GET_LOCATION_AVAILABLE_EMPLOYEES_FAIL,
  GET_LOCATION_AVAILABLE_EMPLOYEES_SUCCESS,
  GET_LOCATION_BY_ID,
  GET_LOCATION_BY_ID_FAIL,
  GET_LOCATION_BY_ID_SUCCESS,
  GET_LOCATION_INVITED_EMPLOYEES,
  GET_LOCATION_INVITED_EMPLOYEES_FAIL,
  GET_LOCATION_INVITED_EMPLOYEES_SUCCESS,
  GET_LOCATIONS,
  GET_LOCATIONS_FAIL,
  GET_LOCATIONS_SUCCESS,
  ILocationsReducer,
  LocationsTypes,
  RESET_LOCATION_AVAILABLE_EMPLOYEES,
  UNMOUNT_MAIN_FORM,
  UPDATE_LOCATION,
  UPDATE_LOCATION_FAIL,
  UPDATE_LOCATION_SUCCESS,
  UPLOAD_LOCATION_FILE,
  UPLOAD_LOCATION_FILE_SUCCESS,
} from './locationsTypes';

const initialState: ILocationsReducer = {
  list: [],
  currentLocation: null,
  currentLocationEmployees: [],
  availableEmployees: [],
  files: [],
  total: null,
  availableEmployeesLoaded: false,
  pagination: null,
  availableEmployeesPending: false,
  _pending: false,
  _loaded: false,
  _error: null,
};

const inventoryGroupReducer = (
  state = initialState,
  action: LocationsTypes,
): ILocationsReducer => {
  switch (action.type) {
    // CREATE/UPDATE LOCATION
    case CREATE_LOCATION:
    case UPDATE_LOCATION:
      return { ...state, _pending: true, _error: null };
    case CREATE_LOCATION_SUCCESS:
    case UPDATE_LOCATION_SUCCESS:
      return { ...state, currentLocation: action.payload, _pending: false };
    case CREATE_LOCATION_FAIL:
    case UPDATE_LOCATION_FAIL:
      return { ...state, _pending: false, _error: action.payload };

    // DELETE LOCATION
    case DELETE_LOCATION:
      return { ...state, _pending: true, _error: null };
    case DELETE_LOCATION_SUCCESS:
      return { ...state, currentLocation: null, _pending: false };
    case DELETE_LOCATION_FAIL:
      return { ...state, _pending: false, _error: action.payload };

    // UPLOAD LOCATION FILE
    case UPLOAD_LOCATION_FILE:
      return {
        ...state,
        files: [
          ...state.files,
          ...action.payload.files.map((item: ILocalFile) => ({
            ...item,
            loading: true,
          })),
        ],
      };
    case UPLOAD_LOCATION_FILE_SUCCESS:
      return {
        ...state,
        files: state.files.map(item => (item.loading ? action.payload : item)),
      };

    // DELETE LOCATION
    case DELETE_LOCATION_FILE_SUCCESS:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        files: state.files.filter((file: any) => file.id !== action.payload),
      };
    case DELETE_LOCATION_FILE_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // UNMOUNT MAIN FORM
    case UNMOUNT_MAIN_FORM:
      return { ...state, files: [] };

    // GET LOCATIONS LIST
    case GET_LOCATIONS:
      return { ...state, list: [], _pending: true, _error: null };
    case GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        list: action.payload.locations,
        pagination: action.payload.pagination,
        total: action.payload.pagination.total,
        _pending: false,
      };
    case GET_LOCATIONS_FAIL:
      return { ...state };

    // GET LOCATIONS LIST
    case GET_LOCATION_INVITED_EMPLOYEES:
      return {
        ...state,
        currentLocationEmployees: [],
        _pending: true,
        _error: null,
      };
    case GET_LOCATION_INVITED_EMPLOYEES_SUCCESS:
      return {
        ...state,
        currentLocationEmployees: action.payload.items,
        pagination: action.payload.pagination,
        total: action.payload.pagination.total,
        _pending: false,
      };
    case GET_LOCATION_INVITED_EMPLOYEES_FAIL:
      return { ...state };

    // GET LOCATIONS AVAILABLE EMPLOYEES LIST
    case RESET_LOCATION_AVAILABLE_EMPLOYEES:
      return {
        ...state,
        availableEmployees: [],
      };
    case GET_LOCATION_AVAILABLE_EMPLOYEES:
      return {
        ...state,
        availableEmployeesLoaded: false,
        availableEmployeesPending: true,
        _error: null,
      };
    case GET_LOCATION_AVAILABLE_EMPLOYEES_SUCCESS:
      return {
        ...state,
        availableEmployees: [
          ...state.availableEmployees,
          ...action.payload.employees,
        ],
        total: action.payload.pagination.total,
        availableEmployeesLoaded: true,
        availableEmployeesPending: false,
      };
    case GET_LOCATION_AVAILABLE_EMPLOYEES_FAIL:
      return {
        ...initialState,
        _error: action.payload,
        availableEmployeesLoaded: false,
        availableEmployeesPending: false,
      };

    // FAIL TO ADD EMPLOYEE TO LOCATION DU TO THE DRAFTS
    case ADD_EMPLOYEE_TO_LOCATION_FAIL:
      return {
        ...state,
        _error: action.payload,
        _pending: false,
      };

    // GET LOCATION BY ID
    case GET_LOCATION_BY_ID:
      return {
        ...state,
        currentLocation: null,
        _pending: true,
        _error: null,
      };
    case GET_LOCATION_BY_ID_SUCCESS:
      return {
        ...state,
        currentLocation: action.payload,
        files: action.payload.photo ? [action.payload.photo] : [],
        _pending: false,
        _error: null,
      };
    case GET_LOCATION_BY_ID_FAIL:
      return { ...state, _pending: false, _error: action.payload };

    // LOGOUT
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};

export default inventoryGroupReducer;
