import { lazy } from 'react';

import {
  PERMISSION_CONTRACT_TEMPLATES,
  PERMISSION_CREATE_CONTRACT_TEMPLATE,
  PERMISSION_CREATE_ORIENTATION_PROCESS,
  PERMISSION_CREATE_SCRIPT,
  PERMISSION_EDIT_CONTRACT_TEMPLATE,
  PERMISSION_EDIT_GPS_TRACKING,
  PERMISSION_EDIT_ORIENTATION_PROCESS,
  PERMISSION_EDIT_SCRIPT,
  PERMISSION_GARAGES_LIST,
  PERMISSION_GPS_TRACKING,
  PERMISSION_ORIENTATION_PROCESS,
  PERMISSION_PAYROLL,
  PERMISSION_SCRIPT,
  PERMISSION_TECHNICIANS_LIST,
} from 'constants/permissions';

import { IPermissionObject } from '../index';
import PrivateRoutes, { redirectRoute } from '../PrivateRoutes/PrivateRoutes';

const TabPayrollSettings = lazy(
  () => import('components/Settings/TabPayrollSettings/TabPayrollSettings'),
);
const TabGpsIntegrationsSettings = lazy(
  () =>
    import(
      'components/Settings/TabGpsIntegrationsSettings/TabGpsIntegrationsSettings'
    ),
);
const EditGpsIntegrationsSettings = lazy(
  () =>
    import(
      'components/Settings/TabGpsIntegrationsSettings/EditGpsIntegrationsSettings/EditGpsIntegrationsSettings'
    ),
);

const TabChecklist = lazy(
  () =>
    import(
      'components/Settings/TabOrientationProcessSettings/TabChecklist/TabChecklist'
    ),
);

const TabOrientationProcessSettings = lazy(
  () =>
    import('components/Settings/TabOrientationProcessSettings/TabStep/TabStep'),
);
// const CreateOrientationProcessStep = lazy(
//   () =>
//     import(
//       'components/Settings/TabOrientationProcessSettings/CreateOrientationStep/CreateOrientationStep'
//     ),
// );

const TabOperation = lazy(
  () =>
    import(
      'components/Settings/TabOrientationProcessSettings/TabOperation/TabOperation'
    ),
);

const CreateAndUpdateStep = lazy(
  () =>
    import(
      'components/Settings/TabOrientationProcessSettings/CreateOrientationStep/CreateAndUpdateStepForm/CreateAndUpdateStepForm'
    ),
);

const CreateAndUpdateOperation = lazy(
  () =>
    import(
      'components/Settings/TabOrientationProcessSettings/CreateOrientationStep/CreateAndUpdateProcess/CreateAndUpdateProcess'
    ),
);

const ContractTemplatesList = lazy(
  () => import('components/Settings/TabContractTemplate/TabContractTemplate'),
);

const AddContractTemplate = lazy(
  () =>
    import(
      'components/Settings/TabContractTemplate/CreateAndUpdateTemplate/CreateAndUpdateTemplate'
    ),
);

const EditContractTemplate = lazy(
  () =>
    import(
      'components/Settings/TabContractTemplate/CreateAndUpdateTemplate/CreateAndUpdateTemplate'
    ),
);

const ScriptsList = lazy(
  () => import('components/Settings/TabScripts/TabScripts'),
);

const CreateAndUpdateScript = lazy(
  () =>
    import(
      'components/Settings/TabScripts/CreateAndUpdateScript/CreateAndUpdateScript'
    ),
);

const AddEmployeeContract = lazy(
  () =>
    import(
      'components/Settings/TabContractTemplate/CreateAndUpdateEmployeeContract/CreateAndUpdateEmployeeContract'
    ),
);

const WorkshopList = lazy(
  () => import('components/Settings/TabWorkshop/Workshop'),
);

const getRedirectURL = (permissions?: string[]): string => {
  // TODO: use appropriate permission
  if (permissions?.includes(PERMISSION_GPS_TRACKING))
    return '/settings/integrations/gps_tracking';

  if (permissions?.includes(PERMISSION_PAYROLL))
    return '/settings/accounting/payrolls';
  return '/';
};

/* Settings Main Page (redirect into) */
export const mainSettingsRoute = (permissions?: IPermissionObject[]) => {
  const targetName = 'Settings';
  const targetObject = permissions?.find(obj => obj.name === targetName);
  return redirectRoute(
    '/settings',
    getRedirectURL(targetObject ? targetObject.permissions : []),
  );
};

/* Accounting Settings */
export const accountingSettingsRoute = (
  <PrivateRoutes
    exact
    path="/settings/accounting/payrolls"
    component={TabPayrollSettings}
    permissionType={PERMISSION_PAYROLL}
  />
);

/* GPS integrations Settings */
export const gpsIntegrationsSettingsRoute = (
  <PrivateRoutes
    exact
    path="/settings/integrations/gps_tracking"
    component={TabGpsIntegrationsSettings}
    permissionType={PERMISSION_GPS_TRACKING}
  />
);

export const gpsIntegrationsSettingsEditRoute = (
  <PrivateRoutes
    exact
    path="/settings/integrations/gps_tracking/:id"
    component={EditGpsIntegrationsSettings}
    permissionType={PERMISSION_EDIT_GPS_TRACKING}
  />
);

/* Orientation Process Settings */
export const orientationProcessSettingsRoute = (
  <PrivateRoutes
    exact
    path="/settings/hr/orientation_process"
    component={TabChecklist}
    permissionType={PERMISSION_ORIENTATION_PROCESS}
  />
);

export const checklistSettingsRoute = (
  <PrivateRoutes
    exact
    path="/settings/hr/orientation_process/checklist/:id"
    component={TabOrientationProcessSettings}
    permissionType={PERMISSION_ORIENTATION_PROCESS}
  />
);

export const operationListSettingsRoute = (
  <PrivateRoutes
    exact
    path="/settings/hr/orientation_process/checklist/step_list/:checklistId/:stepId"
    component={TabOperation}
    permissionType={PERMISSION_ORIENTATION_PROCESS}
  />
);

export const createStepRoute = (
  <PrivateRoutes
    exact
    path="/settings/hr/orientation_process/checklist/:checkListId/:crud"
    component={CreateAndUpdateStep}
    permissionType={PERMISSION_CREATE_ORIENTATION_PROCESS}
  />
);

export const updateStepRoute = (
  <PrivateRoutes
    exact
    path="/settings/hr/orientation_process/checklist/:checkListId/:crud/:stepId"
    component={CreateAndUpdateStep}
    permissionType={PERMISSION_EDIT_ORIENTATION_PROCESS}
  />
);

export const createOperationRoute = (
  <PrivateRoutes
    exact
    path="/settings/hr/orientation_process/checklist/:checkListId/step_list/:stepId/operation/:crud"
    component={CreateAndUpdateOperation}
    permissionType={PERMISSION_CREATE_ORIENTATION_PROCESS}
  />
);

export const editOperationRoute = (
  <PrivateRoutes
    exact
    path="/settings/hr/orientation_process/checklist/:checkListId/step_list/:stepId/operation/:crud/:operationId"
    component={CreateAndUpdateOperation}
    permissionType={PERMISSION_EDIT_ORIENTATION_PROCESS}
  />
);

export const contractTemplateRoute = (
  <PrivateRoutes
    exact
    path="/settings/hr/contract_templates"
    component={ContractTemplatesList}
    permissionType={PERMISSION_CONTRACT_TEMPLATES}
  />
);

export const createContractTemplateRoute = (
  <PrivateRoutes
    exact
    path="/settings/hr/contract_templates/:crud"
    component={AddContractTemplate}
    permissionType={PERMISSION_CREATE_CONTRACT_TEMPLATE}
  />
);

export const editContractTemplateRoute = (
  <PrivateRoutes
    exact
    path="/settings/hr/contract_templates/:crud/:id"
    component={EditContractTemplate}
    permissionType={PERMISSION_EDIT_CONTRACT_TEMPLATE}
  />
);

export const scriptsRoute = (
  <PrivateRoutes
    exact
    path="/settings/hr/scripts"
    component={ScriptsList}
    permissionType={PERMISSION_SCRIPT}
  />
);

export const createScriptRoute = (
  <PrivateRoutes
    exact
    path="/settings/hr/:companyId/scripts/:crud"
    component={CreateAndUpdateScript}
    permissionType={PERMISSION_CREATE_SCRIPT}
  />
);

export const editScriptRoute = (
  <PrivateRoutes
    exact
    path="/settings/hr/:companyId/scripts/:crud/:id"
    component={CreateAndUpdateScript}
    permissionType={PERMISSION_EDIT_SCRIPT}
  />
);

export const createEmployeeContract = (
  <PrivateRoutes
    exact
    path="/settings/hr/contract_employee"
    component={AddEmployeeContract}
    permissionType={PERMISSION_CONTRACT_TEMPLATES}
  />
);

export const workshopRoute = (
  <PrivateRoutes
    exact
    path="/settings/workshop"
    component={WorkshopList}
    permissionType={PERMISSION_TECHNICIANS_LIST || PERMISSION_GARAGES_LIST}
  />
);
