import { FC } from 'react';

type OwnProps = {
  className?: string;
  fillColor?: string;
};

const IconDashboard: FC<OwnProps> = ({
  className,
  fillColor = 'var(--sideBarIcon)',
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1 7.87214V13C1 14.1046 1.89543 15 3 15H6H13C14.1046 15 15 14.1046 15 13V7.87214C15 7.316 14.7684 6.78498 14.3609 6.40655L9.3609 1.76369C8.59354 1.05114 7.40646 1.05114 6.6391 1.76369L1.6391 6.40655C1.23156 6.78498 1 7.316 1 7.87214Z"
        stroke={fillColor}
        strokeWidth="2"
      />
      <path
        d="M6 10V9C5.44772 9 5 9.44772 5 10H6ZM10 10H11C11 9.44772 10.5523 9 10 9V10ZM9 15C9 15.5523 9.44772 16 10 16C10.5523 16 11 15.5523 11 15H9ZM5 15C5 15.5523 5.44772 16 6 16C6.55228 16 7 15.5523 7 15H5ZM6 11H10V9H6V11ZM9 10V15H11V10H9ZM7 15V10H5V15H7Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconDashboard;
