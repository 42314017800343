import { AxiosError } from 'axios';

import {
  GET_ALL_TRUCKS_MAINTENANCE_LIST,
  GET_ALL_TRUCKS_MAINTENANCE_LIST_SUCCESS,
  GET_ALL_TRUCKS_MAINTENANCE_LIST_FAIL,
  IGetAllTrucksMaintenanceList,
  IGetAllTrucksMaintenanceListSuccess,
} from './allTrucksMaintenanceTypes';

//  GET MAINTENANCE LIST
export const getAllTrucksMaintenanceListAction = (
  payload: IGetAllTrucksMaintenanceList['payload'],
): IGetAllTrucksMaintenanceList => ({
  type: GET_ALL_TRUCKS_MAINTENANCE_LIST,
  payload,
});

export const getAllTrucksMaintenanceListSuccessAction = (
  payload: IGetAllTrucksMaintenanceListSuccess['payload'],
): IGetAllTrucksMaintenanceListSuccess => ({
  type: GET_ALL_TRUCKS_MAINTENANCE_LIST_SUCCESS,
  payload,
});

export const getAllTrucksMaintenanceListFailAction = (error: AxiosError) => ({
  type: GET_ALL_TRUCKS_MAINTENANCE_LIST_FAIL,
  payload: error,
});
