import { AxiosError } from 'axios';

import { IInternalCompany } from './InternalCompanyModels';

// Create internal company
export const CREATE_INTERNAL_COMPANY =
  '[@@InternalCompanies] CREATE_INTERNAL_COMPANY';
export const CREATE_INTERNAL_COMPANY_SUCCESS =
  '[@@InternalCompanies] CREATE_INTERNAL_COMPANY_SUCCESS';
export const CREATE_INTERNAL_COMPANY_FAIL =
  '[@@InternalCompanies] CREATE_INTERNAL_COMPANY_FAIL';

export interface ICreateInternalCompany {
  type: typeof CREATE_INTERNAL_COMPANY;
  payload: {
    name: string;
    photoId?: number;
    callback?: Function;
  };
}

export interface ICreateInternalCompanySuccess {
  type: typeof CREATE_INTERNAL_COMPANY_SUCCESS;
  payload: IInternalCompany;
}

export interface ICreateInternalCompanyFail {
  type: typeof CREATE_INTERNAL_COMPANY_FAIL;
  payload: AxiosError;
}

// UPLOAD COMPANY PHOTO
export const UPLOAD_COMPANY_PHOTO =
  '[@@InternalCompanies] UPLOAD_COMPANY_PHOTO';
export const UPLOAD_COMPANY_PHOTO_SUCCESS =
  '[@@InternalCompanies] UPLOAD_COMPANY_PHOTO_SUCCESS';
export const UPLOAD_COMPANY_PHOTO_FAIL =
  '[@@InternalCompanies] UPLOAD_COMPANY_PHOTO_FAIL';

export interface IUploadCompanyPhotoAction {
  type: typeof UPLOAD_COMPANY_PHOTO;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}
export interface IUploadCompanyPhotoActionSuccess {
  type: typeof UPLOAD_COMPANY_PHOTO_SUCCESS;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}
export interface IUploadCompanyPhotoActionFail {
  type: typeof UPLOAD_COMPANY_PHOTO_FAIL;
  payload: AxiosError;
}

export const EDIT_COMPANY_PHOTO = '[@@InternalCompanies] EDIT_COMPANY_PHOTO';
export const EDIT_COMPANY_PHOTO_SUCCESS =
  '[@@InternalCompanies] EDIT_COMPANY_PHOTO_SUCCESS';
export const EDIT_COMPANY_PHOTO_FAIL =
  '[@@InternalCompanies] EDIT_COMPANY_PHOTO_FAIL';

export interface IEditCompanyPhotoAction {
  type: typeof EDIT_COMPANY_PHOTO;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}
export interface IEditCompanyPhotoActionSuccess {
  type: typeof EDIT_COMPANY_PHOTO_SUCCESS;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}
export interface IEditCompanyPhotoActionFail {
  type: typeof EDIT_COMPANY_PHOTO_FAIL;
  payload: AxiosError;
}

// DELETE COMPANY PHOTO
export const DELETE_COMPANY_PHOTO =
  '[@@InternalCompanies] DELETE_COMPANY_PHOTO';
export const DELETE_COMPANY_PHOTO_SUCCESS =
  '[@@InternalCompanies] DELETE_COMPANY_PHOTO_SUCCESS';
export const DELETE_COMPANY_PHOTO_FAIL =
  '[@@InternalCompanies] UPLOAD_COMPANY_PHOTO_FAIL';

export interface IDeleteCompanyPhotoAction {
  type: typeof DELETE_COMPANY_PHOTO;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}
export interface IDeleteCompanyPhotoActionSuccess {
  type: typeof DELETE_COMPANY_PHOTO_SUCCESS;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}
export interface IDeleteCompanyPhotoActionFail {
  type: typeof DELETE_COMPANY_PHOTO_FAIL;
  payload: AxiosError;
}

// get internal companies
export const GET_INTERNAL_COMPANIES =
  '[@@InternalCompanies] GET_INTERNAL_COMPANIES';
export const GET_INTERNAL_COMPANIES_SUCCESS =
  '[@@InternalCompanies] GET_INTERNAL_COMPANIES_SUCCESS';
export const GET_INTERNAL_COMPANIES_FAIL =
  '[@@InternalCompanies] GET_INTERNAL_COMPANIES_FAIL';

export interface IGetInternalCompanies {
  type: typeof GET_INTERNAL_COMPANIES;
}

export interface IGetInternalCompaniesSuccess {
  type: typeof GET_INTERNAL_COMPANIES_SUCCESS;
  payload: IInternalCompany[];
}

export interface IGetInternalCompaniesFail {
  type: typeof GET_INTERNAL_COMPANIES_FAIL;
  payload: AxiosError;
}

// delete internal companies
export const DELETE_INTERNAL_COMPANY =
  '[@@InternalCompanies] DELETE_INTERNAL_COMPANY';
export const DELETE_INTERNAL_COMPANY_SUCCESS =
  '[@@InternalCompanies] DELETE_INTERNAL_COMPANY_SUCCESS';
export const DELETE_INTERNAL_COMPANY_FAIL =
  '[@@InternalCompanies] DELETE_INTERNAL_COMPANY_FAIL';

export interface IDeleteInternalCompany {
  type: typeof DELETE_INTERNAL_COMPANY;
  payload: { companyId: number };
}

export interface IDeleteInternalCompanySuccess {
  type: typeof DELETE_INTERNAL_COMPANY_SUCCESS;
  payload: { companyId: number };
}

export interface IDeleteInternalCompanyFail {
  type: typeof DELETE_INTERNAL_COMPANY_FAIL;
  payload: AxiosError;
}

// edit internal companies
export const EDIT_INTERNAL_COMPANY =
  '[@@InternalCompanies] EDIT_INTERNAL_COMPANY';
export const EDIT_INTERNAL_COMPANY_SUCCESS =
  '[@@InternalCompanies] EDIT_INTERNAL_COMPANY_SUCCESS';
export const EDIT_INTERNAL_COMPANY_FAIL =
  '[@@InternalCompanies] EDIT_INTERNAL_COMPANY_FAIL';

export interface IEditInternalCompany {
  type: typeof EDIT_INTERNAL_COMPANY;
  payload: {
    companyId: number;
    name: string;
    callback?: Function;
  };
}

export interface IEditInternalCompanySuccess {
  type: typeof EDIT_INTERNAL_COMPANY_SUCCESS;
  payload: IInternalCompany;
}

export interface IEditInternalCompanyFail {
  type: typeof EDIT_INTERNAL_COMPANY_FAIL;
  payload: AxiosError;
}

export type internalCompanyActionsType =
  | ICreateInternalCompany
  | ICreateInternalCompanySuccess
  | ICreateInternalCompanyFail
  | IUploadCompanyPhotoAction
  | IUploadCompanyPhotoActionSuccess
  | IUploadCompanyPhotoActionFail
  | IDeleteCompanyPhotoAction
  | IEditCompanyPhotoAction
  | IEditCompanyPhotoActionSuccess
  | IEditCompanyPhotoActionFail
  | IDeleteCompanyPhotoActionSuccess
  | IDeleteCompanyPhotoActionFail
  | IGetInternalCompanies
  | IGetInternalCompaniesSuccess
  | IGetInternalCompaniesFail
  | IDeleteInternalCompany
  | IDeleteInternalCompanySuccess
  | IDeleteInternalCompanyFail
  | IEditInternalCompany
  | IEditInternalCompanySuccess
  | IEditInternalCompanyFail;
