import { memo } from 'react';

type OwnProps = {
  className?: string;
  fillColor?: string;
};

const IconCompany = ({
  className,
  fillColor = 'var(--sideBarIcon)',
}: OwnProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_2294_11790)">
        <path
          stroke={fillColor}
          d="M11 5H8H5M11 5H13C14.1046 5 15 5.89543 15 7V9V13C15 14.1046 14.1046 15 13 15H3C1.89543 15 1 14.1046 1 13V7C1 5.89543 1.89543 5 3 5H5M11 5H5M11 5V3C11 1.89543 10.1046 1 9 1H7C5.89543 1 5 1.89543 5 3V5"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_2294_11790">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(IconCompany);
