/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';

import { IPhotoUrls } from 'components/Auth/_models/authModels';

import { IFile, ILocalFile, ILogout } from './models';

export interface IDeleteFileAction {
  payload: {
    fileId: number | string;
    itemId?: string | number | null;
    actionConfig?: any;
    filesConfig?: any;
    callback?: Function;
  };
}

export interface IDeleteFileActionSuccess {
  payload: any;
}

export interface IDeleteFileActionFail {
  payload: AxiosError;
}

export interface IDeleteFilesAction {
  payload: {
    fileIds: Array<number | string>;
    itemId?: string | number | null;
    actionConfig?: any;
    filesConfig?: any;
    callback?: Function;
  };
}

export interface IDeleteFilesActionSuccess {
  payload: any;
}

export interface IDeleteFilesActionFail {
  payload: any;
}

export interface IUploadFilesAction {
  payload: {
    files: Array<ILocalFile>;
    itemId?: string | number | null;
    actionConfig?: any;
    filesConfig?: any;
    callback?: Function;
  };
}
export interface IUploadFilesActionSuccess {
  payload: IFile;
}
export interface IUploadFilesActionFail {
  payload: AxiosError;
}

export interface IUpdateFilesAction {
  payload: {
    itemId: string | number | null;
    files: Array<ILocalFile>;
    actionConfig?: any;
    filesConfig?: any;
  };
}
export interface IUpdateFilesActionSuccess {
  payload: IFile;
}
export interface IUpdateFilesActionFail {
  payload: AxiosError;
}

// UPLOAD CROPPED IMAGE
export const UPLOAD_CROPPED_IMAGE = 'UPLOAD_CROPPED_IMAGE';
export const UPLOAD_CROPPED_IMAGE_SUCCESS = 'UPLOAD_CROPPED_IMAGE_SUCCESS';
export const UPLOAD_CROPPED_IMAGE_FAIL = 'UPLOAD_CROPPED_IMAGE_FAIL';

export interface IUploadCroppedImgAction {
  type: typeof UPLOAD_CROPPED_IMAGE;
  payload: string;
}
export interface IUploadCroppedImgActionSuccess {
  type: typeof UPLOAD_CROPPED_IMAGE_SUCCESS;
  payload: IPhotoUrls;
}
export interface IUploadCroppedImgActionFail {
  type: typeof UPLOAD_CROPPED_IMAGE_FAIL;
  payload: string;
}

export type initCommonActionsType =
  | IUploadCroppedImgAction
  | IUploadCroppedImgActionSuccess
  | IUploadCroppedImgActionFail
  | ILogout;
