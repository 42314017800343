import { FC, forwardRef, MouseEvent, Ref } from 'react';

import clsx from 'clsx';

import { getAttrName } from 'hooks/useTestAttrRef';

import { IOption } from '../optionModels';

import styles from './OptionItem.module.scss';

type Props = {
  id: number | string;
  type?: string;
  status?: string;
  inspection_id?: string | number;
  inspector_name?: string;
  item: IOption;
  className?: string;
  onAction?: (key: string, e: MouseEvent) => void;
  classNameDropdownItem?: string;
  ref?: Ref<HTMLDivElement>;
};

const OptionItem: FC<Props> = forwardRef(
  (
    {
      id,
      onAction,
      item,
      children,
      className,
      type,
      status,
      inspection_id,
      inspector_name,
      classNameDropdownItem,
    },
    ref,
  ) => {
    const { key, title, disabled, color, dotColor, tabIndex } = item;

    const onClickHandler = (e: MouseEvent) => {
      e.stopPropagation();
      if (key && onAction && !disabled) onAction(key, e);
    };

    return (
      <div
        ref={ref}
        className={clsx(className, classNameDropdownItem, {
          [styles.disabled]: disabled,
          [styles.withDot]: !!dotColor,
        })}
        onClick={onClickHandler}
        data-id={id}
        data-type={type}
        data-status={status}
        data-inspection_id={inspection_id}
        data-inspector_name={inspector_name}
        tabIndex={tabIndex === undefined ? -1 : tabIndex}
        data-test={getAttrName(`${title}_option`)}
      >
        {dotColor && (
          <span className={styles.dot} style={{ backgroundColor: dotColor }} />
        )}
        <span style={{ color }}>{title}</span>
        {children}
      </div>
    );
  },
);

export default OptionItem;
