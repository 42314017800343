import { FC } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const IconLostSignal: FC<IOwnProps> = ({
  className,
  fillColor = 'var(--primaryErrorBg)',
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="8" cy="8" r="3" stroke={fillColor} strokeWidth="2" />
      <path
        d="M12 13.7465C13.8135 12.4815 15 10.3799 15 8.00117C15 5.62242 13.8135 3.52081 12 2.25586M4 2.25586C2.18652 3.52081 1 5.62243 1 8.00117C1 10.3799 2.18652 12.4815 4 13.7465"
        stroke={fillColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconLostSignal;
