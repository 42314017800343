import { FC } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const IconPlay: FC<IOwnProps> = ({ className, fillColor = 'white' }) => {
  return (
    <svg
      width="9"
      height="12"
      viewBox="0 0 9 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0 1.04464C0 0.212491 0.957083 -0.255602 1.61394 0.255287L7.98512 5.21065C8.49987 5.61101 8.49986 6.38899 7.98512 6.78935L1.61394 11.7447C0.957081 12.2556 0 11.7875 0 10.9554V1.04464Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconPlay;
