import { ChangeEvent, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { IconUserPhoto, IconPencil } from 'assets/icons/components';
import { BlueSwitch } from 'components/_common/CustomMaterialComponents';
import Message from 'components/_common/Message/Message';
import CropImgModal from 'components/_common/Modal/CropImgModal/CropImgModal';
import Modal from 'components/_common/Modal/Modal';
import {
  logoutAction,
  setOfflineAction,
  setPrevUserEmailAction,
} from 'components/Auth/_redux/authActions';
import { authSelector } from 'components/Auth/_redux/authSelectors';
import useToggle from 'hooks/useToggle';
import { uploadCroppedImgAction } from 'redux/_common/commonActions';
import { changeColorThemeAction } from 'redux/userSettings/userSettingsActions';
import { userThemeSelector } from 'redux/userSettings/userSettingsSelectors';

import styles from './UserDropdown.module.scss';

type Props = {
  onClose?: () => void;
};

const UserDropdown = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [file, setFile] = useState<File | null>(null);
  const [notFile, setNotFile] = useState(false);

  const { id, full_name, position, photo_urls, email } =
    useSelector(authSelector);
  const theme = useSelector(userThemeSelector);

  const { isOpen, open, close } = useToggle();

  const handleLogout = () => {
    dispatch(setOfflineAction());
    dispatch(logoutAction());
    dispatch(setPrevUserEmailAction(email));
  };

  const redirectToChangePassword = () => {
    if (onClose) onClose();
    history.push('/change-password');
  };

  const onUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const currentFile = e.target.files && e.target.files[0];
    if (!currentFile || !/^image\/\w+/.test(currentFile.type)) {
      setNotFile(true);
      return;
    }

    setFile(currentFile);
    open();
  };

  const getBlobImg = (blob: Blob) => {
    dispatch(uploadCroppedImgAction(blob));
  };

  const onChangeColorTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    localStorage.setItem(`${email}_theme`, newTheme);
    dispatch(changeColorThemeAction(newTheme));
  };

  useEffect(() => {
    if (email) {
      const savedTheme = localStorage.getItem(`${email}_theme`);
      if (savedTheme) {
        dispatch(changeColorThemeAction(savedTheme));
      }
    }
  }, [email]);

  return (
    <>
      <div className={styles.root}>
        <div className={styles.logoWrapper}>
          <label
            className={styles.userLogo}
            style={{
              backgroundImage: photo_urls.thumb
                ? `url(${photo_urls.thumb})`
                : 'none',
            }}
          >
            {!photo_urls.thumb && (
              <IconUserPhoto className={styles.defaultAvatar} />
            )}
            <IconPencil className={styles.pencil} />
            <input
              type="file"
              onChange={onUploadFile}
              accept=".jpg,.jpeg,.png"
            />
          </label>
        </div>

        <Link to={`/hr/employees/${id}`} onClick={close}>
          {full_name}
        </Link>

        <h6>{position || '-'}</h6>

        <ul className={styles.options}>
          <li onClick={redirectToChangePassword}>Change password</li>
          <li className={styles.switchTheme}>
            {theme === 'light' ? 'Night' : 'Light'} mode
            <BlueSwitch
              checked={theme === 'dark'}
              onClick={onChangeColorTheme}
            />
          </li>
          <li onClick={handleLogout}>Log out</li>
        </ul>
      </div>

      {isOpen && file && (
        <Modal isOpen={isOpen} onClose={close} className={styles.modal}>
          <CropImgModal file={file} onClose={close} getBlobImg={getBlobImg} />
        </Modal>
      )}

      {notFile && <Message message="Please, choose image file" type="error" />}
    </>
  );
};

export default UserDropdown;
