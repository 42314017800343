import {
  IPagination,
  paginationTypes,
  SET_PAGE,
  SET_PER_PAGE,
  SET_PAGES,
  SET_PAGINATION,
} from './paginationTypes';

export const initialState: IPagination = {
  page: 1,
  pages: 1,
  per_page: 10,
  total: 0,
};

const paginationReducers = (state = initialState, action: paginationTypes) => {
  switch (action.type) {
    case SET_PER_PAGE:
      return {
        ...state,
        per_page: action.payload,
        page: 1,
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case SET_PAGES:
      return {
        ...state,
        pages: action.payload,
      };
    case SET_PAGINATION:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default paginationReducers;
