import { FC } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const IconQuestion16: FC<IOwnProps> = ({
  className,
  fillColor = '#FF4A3C',
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM8 14C4.7 14 2 11.3 2 8C2 4.7 4.7 2 8 2C11.3 2 14 4.7 14 8C14 11.3 11.3 14 8 14Z"
        fill={fillColor}
      />
      <path
        d="M8 13C8.55228 13 9 12.5523 9 12C9 11.4477 8.55228 11 8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13Z"
        fill={fillColor}
      />
      <path
        d="M7.10002 5.5C7.30002 5.2 7.60002 5 8.00002 5C8.60002 5 9.00002 5.4 9.00002 6C9.00002 6.3 8.90002 6.4 8.40002 6.7C7.80002 7.1 7.00002 7.7 7.00002 9C7.00002 9.55228 7.44774 10 8.00002 10C8.55231 10 9.00002 9.55228 9.00002 9C9.00002 8.8 9.00002 8.7 9.50002 8.4C10.1 8 11 7.4 11 6C11 4.3 9.70002 3 8.00002 3C6.90002 3 5.90002 3.6 5.40002 4.5L5.36715 4.55917C5.10732 5.02686 5.26795 5.61642 5.72909 5.88769C6.20659 6.16857 6.8217 6.00098 7.09074 5.51672L7.10002 5.5Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconQuestion16;
