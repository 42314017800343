import moment from 'moment';

import { SERVER_DATE_FORMAT } from 'constants/dateFormats';

export const temperatureNormalizer = (value?: string, prevValue?: string) => {
  if (!value) return value;

  if (value && value.length >= 2 && value.slice(-1) === '-') {
    return prevValue;
  }
  return value.replace(/[^-?\d]/g, '');
};

export const zipCodeNormalizer = (value: string): string | undefined => {
  if (!value) return value;
  return value.replace(/[^\d]/g, '').slice(0, 5);
};

export const zipCodeNormalizerForGoogle = (
  // ToDo for test, still in progress

  value: string,
): string | undefined => {
  if (!value) return value;
  return value.slice(0, 7);
};

export const normalizeSSN = (value: string, previousValue: string) => {
  if (!value) return value;

  const onlyNums = value.replace(/[^\d]/g, '');

  if (!previousValue || value.length > previousValue.length) {
    if (onlyNums.length === 3) return `${onlyNums}-`;
    if (onlyNums.length === 5)
      return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}-`;
  }

  if (onlyNums.length <= 3) return onlyNums;

  if (onlyNums.length <= 5) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}`;
  }

  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}-${onlyNums.slice(
    5,
    9,
  )}`;
};

export const trimString = (value = '') => value.trim();

export const normalizeDate = (
  value?: moment.MomentInput | null,
): string | null => {
  return value ? moment(value).format(SERVER_DATE_FORMAT) : null;
};

export const normalizeVIN = (value: string) => {
  return value
    .toUpperCase()
    .replace(/[^A-HJ-NPR-Z0-9]/g, '')
    .slice(0, 20);
};
