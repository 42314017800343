import { FC } from 'react';

import clsx from 'clsx';

import styles from './CommonTypography.module.scss';

// Defining the HTML tag that the component will support
// TODO: VZ - extend this
const variantsMapping = {
  h1: 'h1',
  h2: 'h2',
  modalTitle: 'h2',
  p: 'p',
  modalSubtitle: 'p',
};

type TypographyProps = React.HTMLAttributes<HTMLOrSVGElement> & {
  variant: keyof typeof variantsMapping;
  className?: string;
  color?: string;
  centered?: boolean;
};

/**
 * Component for typography in system
 * @param variant - string that represent HTML Attributes
 * @param centered - text-align center
 * @returns component
 */
const Typography: FC<TypographyProps> = ({
  variant,
  className,
  children,
  centered,
  ...props
}) => {
  // If the variant exists in variantsMapping, we use it.
  // Otherwise, use p tag instead.
  const tag = variant ? variantsMapping[variant] : 'p';
  const Component = tag as keyof JSX.IntrinsicElements;

  return (
    <Component
      className={clsx(className, styles[`typography--${tag}`], {
        [styles.centered]: !!centered,
      })}
      {...props}
    >
      {children}
    </Component>
  );
};

export default Typography;
