/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';

import { IPagination } from 'components/_common/Navigation/Pagination/_redux/paginationTypes';
import {
  IDeleteFileAction,
  IUploadFilesAction,
} from 'redux/_common/commonTypes';
import { ILocalFile, IFile, ILogout } from 'redux/_common/models';
import { IWithError, IWithLoaded, IWithPending } from 'utils/models';

import {
  IAvailableEmployees,
  ICreateLocation,
  ILocation,
  ILocationInvitedEmployee,
} from '../_models/locationModels';

export interface ILocationsReducer
  extends IWithError,
    IWithPending,
    IWithLoaded {
  list: ILocation[];
  files: Array<ILocalFile | IFile>;
  currentLocation: ILocation | null;
  total: number | null;
  currentLocationEmployees: ILocationInvitedEmployee[];
  pagination: IPagination | null;
  availableEmployeesPending: boolean;
  availableEmployeesLoaded: boolean;
  availableEmployees: IAvailableEmployees[];
}

// CREATE LOCATION
export const CREATE_LOCATION = '[@@Locations] CREATE_LOCATION';
export const CREATE_LOCATION_SUCCESS = '[@@Locations] CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_FAIL = '[@@Locations] CREATE_LOCATION_FAIL';
export interface ICreateLocationAction {
  type: typeof CREATE_LOCATION;
  payload: {
    data: { inventories_location: ICreateLocation };
    config?: any;
    callback?: (id: number) => void;
  };
}
export interface ICreateLocationSuccessAction {
  type: typeof CREATE_LOCATION_SUCCESS;
  payload: ILocation;
}
export interface ICreateLocationFailAction {
  type: typeof CREATE_LOCATION_FAIL;
  payload: AxiosError;
}

// DELETE LOCATION
export const DELETE_LOCATION = '[@@Locations] DELETE_LOCATION';
export const DELETE_LOCATION_SUCCESS = '[@@Locations] DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_FAIL = '[@@Locations] DELETE_LOCATION_FAIL';
export interface IDeleteLocationAction {
  type: typeof DELETE_LOCATION;
  payload: {
    data: { id: number | string };
    config?: any;
    callback?: () => void;
  };
}
export interface IDeleteLocationSuccessAction {
  type: typeof DELETE_LOCATION_SUCCESS;
  payload: number | string;
}
export interface IDeleteLocationFailAction {
  type: typeof DELETE_LOCATION_FAIL;
  payload: AxiosError;
}

// GET AVAILABLE EMPLOYEES LIST
export const RESET_LOCATION_AVAILABLE_EMPLOYEES =
  '[@@Locations] RESET_LOCATION_AVAILABLE_EMPLOYEES';
export const GET_LOCATION_AVAILABLE_EMPLOYEES =
  '[@@Locations] GET_LOCATION_AVAILABLE_EMPLOYEES';
export const GET_LOCATION_AVAILABLE_EMPLOYEES_SUCCESS =
  '[@@Locations] GET_LOCATION_AVAILABLE_EMPLOYEES_SUCCESS';
export const GET_LOCATION_AVAILABLE_EMPLOYEES_FAIL =
  '[@@Locations] GET_LOCATION_AVAILABLE_EMPLOYEES_FAIL';
export interface IResetLocationAvailableEmployeesAction {
  type: typeof RESET_LOCATION_AVAILABLE_EMPLOYEES;
}
export interface IGetLocationAvailableEmployeesAction {
  type: typeof GET_LOCATION_AVAILABLE_EMPLOYEES;
  payload: {
    data: {
      locationId: string;
      query?: string;
      usePaginationFlag?: boolean;
      preventUpdatePagination?: boolean;
    };
    callback?: () => void;
  };
}
export interface IGetLocationAvailableEmployeesSuccessAction {
  type: typeof GET_LOCATION_AVAILABLE_EMPLOYEES_SUCCESS;
  payload: { pagination: IPagination; employees: IAvailableEmployees[] };
}
export interface IGetLocationAvailableEmployeesFailAction {
  type: typeof GET_LOCATION_AVAILABLE_EMPLOYEES_FAIL;
  payload: AxiosError;
}

// ADD EMPLOYEE TO LOCATION
export const ADD_EMPLOYEE_TO_LOCATION =
  '[@@Locations] ADD_EMPLOYEE_TO_LOCATION';
export const ADD_EMPLOYEE_TO_LOCATION_SUCCESS =
  '[@@Locations] ADD_EMPLOYEE_TO_LOCATION_SUCCESS';
export const ADD_EMPLOYEE_TO_LOCATION_FAIL =
  '[@@Locations] ADD_EMPLOYEE_TO_LOCATION_FAIL';
export interface IAddEmployeeToLocationAction {
  type: typeof ADD_EMPLOYEE_TO_LOCATION;
  payload: {
    data: { locationId: number; employeeIds: Array<number> };
    config?: any;
    callback?: () => void;
  };
}
export interface IAddEmployeeToLocationSuccessAction {
  type: typeof ADD_EMPLOYEE_TO_LOCATION_SUCCESS;
}
export interface IAddEmployeeToLocationFailAction {
  type: typeof ADD_EMPLOYEE_TO_LOCATION_FAIL;
  payload: AxiosError;
}

// CREATE LOCATION
export const UPDATE_LOCATION = '[@@Locations] UPDATE_LOCATION';
export const UPDATE_LOCATION_SUCCESS = '[@@Locations] UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_FAIL = '[@@Locations] UPDATE_LOCATION_FAIL';
export interface IUpdateLocationAction {
  type: typeof UPDATE_LOCATION;
  payload: {
    data: Partial<ICreateLocation[keyof ICreateLocation]> & {
      id: string | number;
    };
    config?: any;
    callback?: () => void;
  };
}
export interface IUpdateLocationSuccessAction {
  type: typeof UPDATE_LOCATION_SUCCESS;
  payload: ILocation;
}
export interface IUpdateLocationFailAction {
  type: typeof UPDATE_LOCATION_FAIL;
  payload: AxiosError;
}

// UPLOAD LOCATION FILES
export const UPLOAD_LOCATION_FILE = '[@@Locations] UPLOAD_LOCATION_FILE';
export const UPLOAD_LOCATION_FILE_SUCCESS =
  '[@@Locations] UPLOAD_LOCATION_FILE_SUCCESS';
export const UPLOAD_LOCATION_FILE_FAIL =
  '[@@Locations] UPLOAD_LOCATION_FILE_FAIL';
export interface IUploadLocationFileAction extends IUploadFilesAction {
  type: typeof UPLOAD_LOCATION_FILE;
}
export interface IUploadLocationFileSuccessAction {
  type: typeof UPLOAD_LOCATION_FILE_SUCCESS;
  payload: IFile;
}
export interface IUploadLocationFileFailAction {
  type: typeof UPLOAD_LOCATION_FILE_FAIL;
  payload: AxiosError;
}

// DELETE LOCATION FILES
export const DELETE_LOCATION_FILE = '[@@Locations] DELETE_LOCATION_FILE';
export const DELETE_LOCATION_FILE_SUCCESS =
  '[@@Locations] DELETE_LOCATION_FILE_SUCCESS';
export const DELETE_LOCATION_FILE_FAIL =
  '[@@Locations] DELETE_LOCATION_FILE_FAIL';
export interface IDeleteLocationFileAction extends IDeleteFileAction {
  type: typeof DELETE_LOCATION_FILE;
}
export interface IDeleteLocationFileSuccessAction {
  type: typeof DELETE_LOCATION_FILE_SUCCESS;
  payload: string | number;
}
export interface IDeleteLocationFileFailAction {
  type: typeof DELETE_LOCATION_FILE_FAIL;
  payload: AxiosError;
}

// UNMOUNT MAIN FORM
export const UNMOUNT_MAIN_FORM = '[@@Locations] UNMOUNT_MAIN_FORM';
export interface IClearMainLocationsFormAction {
  type: typeof UNMOUNT_MAIN_FORM;
}

// GET LOCATIONS
export const GET_LOCATIONS = '[@@Locations] GET_LOCATIONS';
export const GET_LOCATIONS_SUCCESS = '[@@Locations] GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_FAIL = '[@@Locations] GET_LOCATIONS_FAIL';
export interface IGetLocationsAction {
  type: typeof GET_LOCATIONS;
  payload?: {
    data?: { query?: string };
    per_page?: number;
    callback?: () => void;
  };
}
export interface IGetLocationsSuccessAction {
  type: typeof GET_LOCATIONS_SUCCESS;
  payload: { pagination: IPagination; locations: ILocation[] };
}
export interface IGetLocationsFailAction {
  type: typeof GET_LOCATIONS_FAIL;
  payload: AxiosError;
}

// GET LOCATION EMPLOYEES
export const GET_LOCATION_INVITED_EMPLOYEES =
  '[@@Locations] GET_LOCATION_INVITED_EMPLOYEES';
export const GET_LOCATION_INVITED_EMPLOYEES_SUCCESS =
  '[@@Locations] GET_LOCATION_INVITED_EMPLOYEES_SUCCESS';
export const GET_LOCATION_INVITED_EMPLOYEES_FAIL =
  '[@@Locations] GET_LOCATION_INVITED_EMPLOYEES_FAIL';
export interface IGetLocationInvitedEmployeesAction {
  type: typeof GET_LOCATION_INVITED_EMPLOYEES;
  payload: {
    id: string;
    config?: any;
    callback?: (...args: any) => void;
  } & Partial<IPagination>;
}
export interface IGetLocationInvitedEmployeesSuccessAction {
  type: typeof GET_LOCATION_INVITED_EMPLOYEES_SUCCESS;
  payload: { pagination: IPagination; items: ILocationInvitedEmployee[] };
}
export interface IGetLocationInvitedEmployeesFailAction {
  type: typeof GET_LOCATION_INVITED_EMPLOYEES_FAIL;
  payload: AxiosError;
}

// SEARCH LOCATION
export const SEARCH_LOCATION = '[@@Locations] SEARCH_LOCATION';
export const SEARCH_LOCATION_SUCCESS = '[@@Locations] SEARCH_LOCATION_SUCCESS';
export const SEARCH_LOCATION_FAIL = '[@@Locations] SEARCH_LOCATION_FAIL';
export interface ISearchLocationAction {
  type: typeof SEARCH_LOCATION;
}
export interface ISearchLocationSuccessAction {
  type: typeof SEARCH_LOCATION_SUCCESS;
  payload: ILocation[];
}
export interface ISearchLocationFailAction {
  type: typeof SEARCH_LOCATION_FAIL;
  payload: AxiosError;
}

// GET LOCATION BY ID
export const GET_LOCATION_BY_ID = '[@@Locations] GET_LOCATION_BY_ID';
export const GET_LOCATION_BY_ID_SUCCESS =
  '[@@Locations] GET_LOCATION_BY_ID_SUCCESS';
export const GET_LOCATION_BY_ID_FAIL = '[@@Locations] GET_LOCATION_BY_ID_FAIL';
export interface IGetLocationByIdAction {
  type: typeof GET_LOCATION_BY_ID;
  payload: {
    data: { id: string | number };
    callback?: () => void;
  };
}
export interface IGetLocationByIdSuccessAction {
  type: typeof GET_LOCATION_BY_ID_SUCCESS;
  payload: ILocation;
}
export interface IGetLocationByIdFailAction {
  type: typeof GET_LOCATION_BY_ID_FAIL;
  payload: AxiosError;
}

export type LocationsTypes =
  | ICreateLocationAction
  | ICreateLocationSuccessAction
  | ICreateLocationFailAction
  | IGetLocationsAction
  | IGetLocationsSuccessAction
  | IGetLocationsFailAction
  | ISearchLocationAction
  | ISearchLocationSuccessAction
  | ISearchLocationFailAction
  | IUploadLocationFileAction
  | IUploadLocationFileSuccessAction
  | IUploadLocationFileFailAction
  | IDeleteLocationFileAction
  | IDeleteLocationFileSuccessAction
  | IDeleteLocationFileFailAction
  | IClearMainLocationsFormAction
  | IGetLocationByIdAction
  | IGetLocationByIdSuccessAction
  | IGetLocationByIdFailAction
  | IUpdateLocationAction
  | IUpdateLocationSuccessAction
  | IUpdateLocationFailAction
  | IDeleteLocationAction
  | IDeleteLocationSuccessAction
  | IDeleteLocationFailAction
  | IGetLocationInvitedEmployeesAction
  | IGetLocationInvitedEmployeesSuccessAction
  | IGetLocationInvitedEmployeesFailAction
  | IGetLocationAvailableEmployeesSuccessAction
  | IGetLocationAvailableEmployeesAction
  | IGetLocationAvailableEmployeesFailAction
  | IAddEmployeeToLocationSuccessAction
  | IAddEmployeeToLocationFailAction
  | IResetLocationAvailableEmployeesAction
  | ILogout;
