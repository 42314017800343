import { useSelector } from 'react-redux';

import { mainSidebarSelector } from 'components/_common/Navigation/MainSidebar/_redux/mainSidebarSelectors';
import useDebounce from 'hooks/useDebounce';

export default function useSidebarSelector() {
  const {
    collapsedFlag: realCollapsedFlag,
    overFlag,
    ...other
  } = useSelector(mainSidebarSelector);

  const debouncedCollapsedFlag = useDebounce(
    realCollapsedFlag && !overFlag,
    200, // 0.2s - MainSidebar transition timeout
  );
  const collapsedFlag =
    (!overFlag && realCollapsedFlag) || debouncedCollapsedFlag;

  return { collapsedFlag, realCollapsedFlag, ...other };
}
