import { UserReducer } from 'components/Auth/_models/authModels';
import {
  authActionsType,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  NEED_REGISTRATION,
  PLAN_REDIRECT,
  REFRESH_TOKEN_FAIL,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_USER,
  REFRESH_USER_FAIL,
  REFRESH_USER_SUCCESS,
  REG_COMPANY,
  REG_COMPANY_FAIL,
  REG_COMPANY_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  SET_OFFLINE,
  SET_OFFLINE_FAIL,
  SET_OFFLINE_SUCCESS,
  SET_ONLINE,
  SET_ONLINE_FAIL,
  SET_ONLINE_SUCCESS,
  SET_PREV_USER_EMAIL,
} from 'components/Auth/_redux/authTypes';
import { UPLOAD_CROPPED_IMAGE_SUCCESS } from 'redux/_common/commonTypes';

const initialState: UserReducer = {
  company: {
    created_at: '',
    email: '',
    id: 0,
    location: {
      address: '',
      city: '',
      country_name: '',
      name: '',
      state_name: '',
      zip_code: '',
      comment: '',
      contact_name: '',
      contact_phone: '',
    },
    name: '',
    phone_number: '',
    product_menu_type: '',
    root_employees: [],
  },
  id: null,
  token: '',
  valid_at: '',
  email: '',
  full_name: '',
  permissions: [],
  phone_number: '',
  position: '',
  prevUserEmail: '',
  photo_urls: { original: null, thumb: null },
  isForgotPasswordEmailSent: false,
  isResetPasswordEmailSent: false,
  planRedirect: '',
  company_registration_done: false,
  hr_role_permissions: {},
  online: false,
  _error: null,
  _pending: false,
};

const authReducer = (
  state = initialState,
  action: authActionsType,
): UserReducer => {
  switch (action.type) {
    case REFRESH_USER:
      return { ...state, _pending: true };

    case REFRESH_TOKEN_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isForgotPasswordEmailSent: false,
        isResetPasswordEmailSent: false,
        _pending: true,
      };
    case REFRESH_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isForgotPasswordEmailSent: false,
        isResetPasswordEmailSent: false,
        _pending: false,
      };

    case FORGOT_PASSWORD: {
      return {
        ...state,
        isForgotPasswordEmailSent: false,
      };
    }
    case FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        isForgotPasswordEmailSent: true,
      };
    }

    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        isResetPasswordEmailSent: true,
      };
    }

    case UPLOAD_CROPPED_IMAGE_SUCCESS: {
      return { ...state, photo_urls: action.payload };
    }

    case NEED_REGISTRATION: {
      return { ...state, _pending: false, position: 'need_registration' };
    }

    case LOGIN_FAIL:
    case REFRESH_USER_FAIL:
    case REFRESH_TOKEN_FAIL:
    case LOGOUT:
      return {
        ...initialState,
        prevUserEmail: state.prevUserEmail,
        planRedirect: state.planRedirect,
        _pending: false,
      };

    case SET_ONLINE:
    case SET_ONLINE_SUCCESS:
    case SET_ONLINE_FAIL:
    case SET_OFFLINE:
    case SET_OFFLINE_SUCCESS:
    case SET_OFFLINE_FAIL:
      return { ...state };

    case SET_PREV_USER_EMAIL:
      return { ...state, prevUserEmail: action.payload };

    case REG_COMPANY:
      return {
        ...state,
        _error: null,
      };

    case REG_COMPANY_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        company: action.payload.company,
      };
    case REG_COMPANY_FAIL: {
      return {
        ...state,
        _error: action.payload,
        _pending: false,
      };
    }
    case PLAN_REDIRECT: {
      return {
        ...state,
        planRedirect: action.payload,
      };
    }
    default:
      return state;
  }
};
export default authReducer;
