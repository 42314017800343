import {
  IDriverRate,
  IDriverDeletePhoto,
  IDriverUploadingPhoto,
} from 'components/HumanResources/TabDrivers/_models/driverModel';
import { IDeleteFileAction } from 'redux/_common/commonTypes';
import { ILocalFile } from 'redux/_common/models';

import {
  CREATE_DRIVER,
  DELETE_DRIVER,
  GET_DRIVER,
  GET_DRIVERS,
  UPDATE_DRIVER,
  UPLOAD_DRIVER_FILES,
  UPLOAD_DRIVER_PHOTO,
  DELETE_DRIVER_FILE,
  UNMOUNT_DRIVER_FORM,
  DELETE_DRIVER_PHOTO,
  ASSIGN_DRIVER,
  CHANGE_DRIVER_FILE_CATEGORY,
  ARCHIVE_DRIVER,
  SET_DRIVER_RATE,
  GET_DRIVER_RATES_LIST,
  IDeleteDriverFileAction,
  IUploadDriverFilesAction,
  IGetDriverRatesListAction,
  ISetDriverRateCommentVisibility,
  SET_DRIVER_COMMENT_VISIBILITY,
  GET_FUEL_COMPANIES,
} from './driversTypes';

export const getDriversAction = (
  query?: string,
  config?: { useSeparateHeapFlag?: boolean; usePaginationFlag?: boolean },
  callback?: () => void,
) => ({
  type: GET_DRIVERS,
  query,
  config,
  callback,
});

export const getDriverAction = (id: string | number) => ({
  type: GET_DRIVER,
  id,
});

export const createDriverAction = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
  callback: (id: number) => void,
) => ({
  type: CREATE_DRIVER,
  payload: { values, callback },
});

export const getDriverRatesListAction = (
  payload: IGetDriverRatesListAction['payload'],
) => ({
  type: GET_DRIVER_RATES_LIST,
  payload,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateDriverAction = (values: any, callback?: () => void) => ({
  type: UPDATE_DRIVER,
  payload: { values, callback },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const assignDriverAction = (values: any, callback: () => void) => ({
  type: ASSIGN_DRIVER,
  payload: { values, callback },
});

export const deleteDriverAction = (id: number, callback?: () => void) => ({
  type: DELETE_DRIVER,
  payload: { id, callback },
});

export const uploadDriverFilesAction = ({
  files,
  itemId,
  callback,
}: {
  files: ILocalFile[];
  itemId?: number;
  callback?: Function;
}): IUploadDriverFilesAction => ({
  type: UPLOAD_DRIVER_FILES,
  payload: { files, itemId, callback },
});

export const deleteDriverFileAction = ({
  fileId,
  itemId,
}: IDeleteFileAction['payload']): IDeleteDriverFileAction => ({
  type: DELETE_DRIVER_FILE,
  payload: { fileId, itemId },
});

export const changeDriverFileCategoryAction = (fileConfig: {
  fileCategory: number;
  fileId: number;
  driverId?: number;
}) => ({ type: CHANGE_DRIVER_FILE_CATEGORY, payload: fileConfig });

export const uploadDriverPhotoAction = (payload: IDriverUploadingPhoto) => ({
  type: UPLOAD_DRIVER_PHOTO,
  payload,
});

export const deleteDriverPhotoAction = (photoConfig: IDriverDeletePhoto) => ({
  type: DELETE_DRIVER_PHOTO,
  payload: photoConfig,
});

export const unmountDriverFormAction = () => ({ type: UNMOUNT_DRIVER_FORM });

// Archive
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const archiveDriverAction = (payload: any, callback?: () => void) => ({
  type: ARCHIVE_DRIVER,
  payload,
  callback,
});

export const setDriverRateAction = (payload: {
  rate_data: IDriverRate;
  orderId: number;
  driverId: number;
}) => ({
  type: SET_DRIVER_RATE,
  payload,
});

export const setDriverRateCommentVisibilityAction = (
  payload: ISetDriverRateCommentVisibility['payload'],
): ISetDriverRateCommentVisibility => ({
  type: SET_DRIVER_COMMENT_VISIBILITY,
  payload,
});

export const getFuelCompaniesAction = () => ({
  type: GET_FUEL_COMPANIES,
});
