import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';

import api from 'utils/requests';

import { truckInspectionsFileToFormData } from '../../../TabTrucks/TruckProfile/TruckInspections/_redux/helpers';
import { handleSaveInspectionDraftSuccess } from '../../AllInspectionsTab/_redux/allInspectionsActions';
import {
  addTandemInspectionPhotoCommentAction,
  deleteTandemInspectionPhotoAction,
  deleteTandemInspectionPhotoCommentAction,
  updateTandemInspectionPhotoCommentAction,
  uploadTandemInspectionPhotoAction,
  createTandemInspectionAction,
  getTandemInspectionDraftAction,
  draftTandemInspectionWithIdAction,
  draftTandemInspectionAction,
} from './tandemInspectionsActions';
import {
  IUploadTandemInspectionPhotoActionFail,
  IUploadTandemInspectionPhotoActionSuccess,
  UPLOAD_TANDEM_INSPECTION_PHOTO,
  UPLOAD_TANDEM_INSPECTION_PHOTO_FAIL,
  UPLOAD_TANDEM_INSPECTION_PHOTO_SUCCESS,
  DELETE_TANDEM_INSPECTION_PHOTO,
  DELETE_TANDEM_INSPECTION_PHOTO_FAIL,
  IDeleteTandemInspectionPhotoActionFail,
  ADD_TANDEM_INSPECTION_PHOTO_COMMENT,
  ADD_TANDEM_INSPECTION_PHOTO_COMMENT_SUCCESS,
  ADD_TANDEM_INSPECTION_PHOTO_COMMENT_FAIL,
  IAddTandemInspectionPhotoCommentActionFail,
  IAddTandemInspectionPhotoCommentActionSuccess,
  UPDATE_TANDEM_INSPECTION_PHOTO_COMMENT_SUCCESS,
  UPDATE_TANDEM_INSPECTION_PHOTO_COMMENT_FAIL,
  UPDATE_TANDEM_INSPECTION_PHOTO_COMMENT,
  IUpdateTandemInspectionPhotoCommentActionSuccess,
  IUpdateTandemInspectionPhotoCommentActionFail,
  DELETE_TANDEM_INSPECTION_PHOTO_COMMENT_SUCCESS,
  DELETE_TANDEM_INSPECTION_PHOTO_COMMENT_FAIL,
  DELETE_TANDEM_INSPECTION_PHOTO_COMMENT,
  IDeleteTandemInspectionPhotoCommentActionSuccess,
  IDeleteTandemInspectionPhotoCommentActionFail,
  CREATE_TANDEM_INSPECTION,
  CREATE_TANDEM_INSPECTION_SUCCESS,
  CREATE_TANDEM_INSPECTION_FAIL,
  ICreateTandemInspectionActionSuccess,
  ICreateTandemInspectionActionFail,
  GET_TANDEM_INSPECTION_DRAFT,
  GET_TANDEM_INSPECTION_DRAFT_SUCCESS,
  GET_TANDEM_INSPECTION_DRAFT_FAIL,
  IGetTandemInspectionDraftActionSuccess,
  IGetTandemInspectionDraftActionFail,
  DRAFT_TANDEM_INSPECTION,
  DRAFT_TANDEM_INSPECTION_WITH_ID,
  IDraftTandemInspectionActionSuccess,
  DRAFT_TANDEM_INSPECTION_SUCCESS,
  DRAFT_TANDEM_INSPECTION_FAIL,
  IDraftTandemInspectionActionFail,
  DELETE_TANDEM_INSPECTION_PHOTO_SUCCESS,
  IDeleteTandemInspectionPhotoActionSuccess,
} from './tandemInspectionsTypes';

// GET_TRAILER_INSPECTION_DRAFT
function* getTandemInspectionDraftSaga({
  payload: {
    data: { inspectionId },
    callback,
  },
}: ReturnType<typeof getTandemInspectionDraftAction>) {
  try {
    const { data } = yield call(() =>
      api.get(`/v1/fleets/inspections/${inspectionId}`),
    );
    yield put<IGetTandemInspectionDraftActionSuccess>({
      type: GET_TANDEM_INSPECTION_DRAFT_SUCCESS,
      payload: data,
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetTandemInspectionDraftActionFail>({
        type: GET_TANDEM_INSPECTION_DRAFT_FAIL,
        payload: e,
      });
  }
}

// CREATE_TRAILER_INSPECTION
function* createTandemInspectionSaga({
  payload: {
    data: { formData, tandemId },
    callback,
  },
}: ReturnType<typeof createTandemInspectionAction>) {
  try {
    const { data } = yield call(() =>
      api.patch(`/v1/fleets/inspections/${tandemId}`, formData),
    );

    yield put<ICreateTandemInspectionActionSuccess>({
      type: CREATE_TANDEM_INSPECTION_SUCCESS,
      payload: data,
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<ICreateTandemInspectionActionFail>({
        type: CREATE_TANDEM_INSPECTION_FAIL,
        payload: e,
      });
  }
}

// UPDATE_TRAILER_INSPECTION
function* draftTandemInspectionSaga({
  payload: {
    data: { formData },
    callback,
  },
}: ReturnType<typeof draftTandemInspectionAction>) {
  try {
    const { data } = yield call(() =>
      api.post(`/v1/fleets/inspections`, formData),
    );

    yield put<IDraftTandemInspectionActionSuccess>({
      type: DRAFT_TANDEM_INSPECTION_SUCCESS,
      payload: data.inspection,
    });

    if (callback) callback(data);
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IDraftTandemInspectionActionFail>({
        type: DRAFT_TANDEM_INSPECTION_FAIL,
        payload: e,
      });
  }
}

function* draftTandemInspectionWithIdSaga({
  payload: {
    data: { formData, tandemId },
    callback,
  },
}: ReturnType<typeof draftTandemInspectionWithIdAction>) {
  try {
    const { data } = yield call(() =>
      api.patch(`/v1/fleets/inspections/${tandemId}`, formData),
    );

    yield put<IDraftTandemInspectionActionSuccess>({
      type: DRAFT_TANDEM_INSPECTION_SUCCESS,
      payload: data.inspection,
    });

    yield put(handleSaveInspectionDraftSuccess());

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IDraftTandemInspectionActionFail>({
        type: DRAFT_TANDEM_INSPECTION_FAIL,
        payload: e,
      });
  }
}

// UPLOAD_TANDEM_INSPECTION_PHOTO
function* uploadTandemInspectionPhotoSaga({
  payload: {
    data: {
      file,
      view,
      fieldName,
      truckInspectionId,
      trailerInspectionId,
      uploadIndex,
    },
    callback,
  },
}: ReturnType<typeof uploadTandemInspectionPhotoAction>) {
  try {
    const photoType = fieldName === 'photos' ? '/short_inspection' : '';
    const options = { headers: { 'Content-Type': 'multipart/form-data' } };

    const { data } = yield call(() =>
      api.post(
        `/v1/fleets/inspections/vehicles/${truckInspectionId}/files/${fieldName}${photoType}`,
        truckInspectionsFileToFormData(file),
        options,
      ),
    );
    const { data: trailerData } = yield call(() =>
      api.post(
        `/v1/fleets/inspections/vehicles/${trailerInspectionId}/files/${fieldName}${photoType}`,
        truckInspectionsFileToFormData(file),
        options,
      ),
    );

    yield put<IUploadTandemInspectionPhotoActionSuccess>({
      type: UPLOAD_TANDEM_INSPECTION_PHOTO_SUCCESS,
      payload: {
        truckFile: { ...data.file, view, fieldName },
        trailerFile: { ...trailerData.file, view, fieldName },
        uploadIndex,
      },
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IUploadTandemInspectionPhotoActionFail>({
        type: UPLOAD_TANDEM_INSPECTION_PHOTO_FAIL,
        payload: e,
      });
  }
}

// DELETE_TRAILER_INSPECTION_PHOTO
function* deleteTandemInspectionPhotoSaga({
  payload: {
    data: { trailerId, truckId, truckFileId, trailerFileId },
    callback,
  },
}: ReturnType<typeof deleteTandemInspectionPhotoAction>) {
  try {
    yield call(() =>
      api.delete(
        `/v1/fleets/inspections/vehicles/${truckId}/files/${truckFileId}`,
      ),
    );
    yield call(() =>
      api.delete(
        `/v1/fleets/inspections/vehicles/${trailerId}/files/${trailerFileId}`,
      ),
    );
    // To implement further
    yield put<IDeleteTandemInspectionPhotoActionSuccess>({
      type: DELETE_TANDEM_INSPECTION_PHOTO_SUCCESS,
      payload: truckFileId,
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IDeleteTandemInspectionPhotoActionFail>({
        type: DELETE_TANDEM_INSPECTION_PHOTO_FAIL,
        payload: e,
      });
  }
}

// ADD_TANDEM_INSPECTION_PHOTO_COMMENT
function* addTandemInspectionPhotoCommentSaga({
  payload: {
    data: { fileId, commentData },
    callback,
  },
}: ReturnType<typeof addTandemInspectionPhotoCommentAction>) {
  try {
    const { data } = yield call(() =>
      api.post(`/v1/fleets/inspections/vehicles/files/${fileId}/comments`, {
        fleets_inspections_files_comment: commentData,
      }),
    );

    yield put<IAddTandemInspectionPhotoCommentActionSuccess>({
      type: ADD_TANDEM_INSPECTION_PHOTO_COMMENT_SUCCESS,
      payload: { ...data, fileId },
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IAddTandemInspectionPhotoCommentActionFail>({
        type: ADD_TANDEM_INSPECTION_PHOTO_COMMENT_FAIL,
        payload: e,
      });
  }
}

// UPDATE_TRAILER_INSPECTION_PHOTO_COMMENT
function* updateTandemInspectionPhotoCommentSaga({
  payload: {
    data: { fileId, commentData },
    callback,
  },
}: ReturnType<typeof updateTandemInspectionPhotoCommentAction>) {
  try {
    const { data } = yield call(() =>
      api.patch(
        `/v1/fleets/inspections/vehicles/files/${fileId}/comments/${commentData.id}`,
        {
          fleets_inspections_files_comment: commentData,
        },
      ),
    );

    yield put<IUpdateTandemInspectionPhotoCommentActionSuccess>({
      type: UPDATE_TANDEM_INSPECTION_PHOTO_COMMENT_SUCCESS,
      payload: { ...data, fileId },
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IUpdateTandemInspectionPhotoCommentActionFail>({
        type: UPDATE_TANDEM_INSPECTION_PHOTO_COMMENT_FAIL,
        payload: e,
      });
  }
}

// DELETE_TRAILER_INSPECTION_PHOTO_COMMENT
function* deleteTandemInspectionPhotoCommentSaga({
  payload: {
    data: { fileId, commentId },
    callback,
  },
}: ReturnType<typeof deleteTandemInspectionPhotoCommentAction>) {
  try {
    yield call(() =>
      api.delete(
        `/v1/fleets/inspections/vehicles/files/${fileId}/comments/${commentId}`,
      ),
    );

    yield put<IDeleteTandemInspectionPhotoCommentActionSuccess>({
      type: DELETE_TANDEM_INSPECTION_PHOTO_COMMENT_SUCCESS,
      payload: { fileId, commentId },
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IDeleteTandemInspectionPhotoCommentActionFail>({
        type: DELETE_TANDEM_INSPECTION_PHOTO_COMMENT_FAIL,
        payload: e,
      });
  }
}

export default function* tandemInspectionSaga() {
  yield takeLatest(GET_TANDEM_INSPECTION_DRAFT, getTandemInspectionDraftSaga);
  yield takeLatest(CREATE_TANDEM_INSPECTION, createTandemInspectionSaga);
  yield takeEvery(
    DRAFT_TANDEM_INSPECTION_WITH_ID,
    draftTandemInspectionWithIdSaga,
  );
  yield takeLatest(DRAFT_TANDEM_INSPECTION, draftTandemInspectionSaga);
  yield takeEvery(
    UPLOAD_TANDEM_INSPECTION_PHOTO,
    uploadTandemInspectionPhotoSaga,
  );
  yield takeLatest(
    DELETE_TANDEM_INSPECTION_PHOTO,
    deleteTandemInspectionPhotoSaga,
  );
  yield takeLatest(
    ADD_TANDEM_INSPECTION_PHOTO_COMMENT,
    addTandemInspectionPhotoCommentSaga,
  );
  yield takeLatest(
    UPDATE_TANDEM_INSPECTION_PHOTO_COMMENT,
    updateTandemInspectionPhotoCommentSaga,
  );
  yield takeLatest(
    DELETE_TANDEM_INSPECTION_PHOTO_COMMENT,
    deleteTandemInspectionPhotoCommentSaga,
  );
}
