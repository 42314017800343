/* eslint-disable @typescript-eslint/no-explicit-any */
// UPLOAD LEAD FILES

import {
  IDeleteFileActionFail,
  IDeleteFileActionSuccess,
} from '../../../../redux/_common/commonTypes';

export const UPLOAD_LEAD_FILES = '[@@Lead] UPLOAD_LEAD_FILES';
export const UPLOAD_LEAD_FILES_SUCCESS = '[@@LEAD] UPLOAD_LEAD_FILES_SUCCESS';
export const UPLOAD_LEAD_FILES_FAIL = '[@@LEAD] UPLOAD_LEAD_FILES_FAIL';

export interface IUploadLeadFilesAction {
  type: typeof UPLOAD_LEAD_FILES;
  payload: any;
}
export interface IUploadLeadFilesActionSuccess {
  type: typeof UPLOAD_LEAD_FILES_SUCCESS;
  payload: { file: any; segmentId: number };
}
export interface IUploadLeadFilesActionFail {
  type: typeof UPLOAD_LEAD_FILES_FAIL;
}

export const UPLOAD_LEAD_ID_FILES = '[@@Lead] UPLOAD_LEAD_ID_FILES';
export const UPLOAD_LEAD_ID_FILES_SUCCESS =
  '[@@LEAD] UPLOAD_LEAD_ID_FILES_SUCCESS';
export const UPLOAD_LEAD_ID_FILES_FAIL = '[@@LEAD] UPLOAD_LEAD_ID_FILES_FAIL';

export interface IUploadLeadIdFilesAction {
  type: typeof UPLOAD_LEAD_ID_FILES;
  payload: any;
}
export interface IUploadLeadIdFilesActionSuccess {
  type: typeof UPLOAD_LEAD_ID_FILES_SUCCESS;
  payload: { file: any; leadId: number };
}
export interface IUploadLeadIdFilesActionFail {
  type: typeof UPLOAD_LEAD_ID_FILES_FAIL;
}

// DELETE LEAD FILES
export const DELETE_LEAD_FILE = '[@@LEAD] DELETE_LEAD_FILE';
export const DELETE_LEAD_FILE_SUCCESS = '[@@LEAD] DELETE_LEAD_FILE_SUCCESS';
export const DELETE_LEAD_FILE_FAIL = '[@@LEAD] DELETE_LEAD_FILE_FAIL';

export interface IDeleteLeadFileAction {
  type: typeof DELETE_LEAD_FILE;
  payload: { fileId: number };
}
export interface IDeleteLeadFileActionSuccess extends IDeleteFileActionSuccess {
  type: typeof DELETE_LEAD_FILE_SUCCESS;
}
export interface IDeleteLeadFileActionFail extends IDeleteFileActionFail {
  type: typeof DELETE_LEAD_FILE_FAIL;
}

export const CLEAR_LEAD_STATE = '[@@LEAD] CLEAR_LEAD_STATE';

export interface IClearLeadStateAction {
  type: typeof CLEAR_LEAD_STATE;
}

export type leadActionsType =
  | IUploadLeadFilesAction
  | IUploadLeadFilesActionSuccess
  | IUploadLeadFilesActionFail
  | IDeleteLeadFileAction
  | IDeleteLeadFileActionSuccess
  | IDeleteLeadFileActionFail
  | IClearLeadStateAction
  | IUploadLeadIdFilesAction
  | IUploadLeadIdFilesActionSuccess
  | IUploadLeadIdFilesActionFail;
