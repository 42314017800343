import { AxiosError } from 'axios';

import { ILogout } from 'redux/_common/models';
import { IWithPending, IWithLoaded, IWithError } from 'utils/models';

import { SchedulerDriver, SchedulerData } from '../_models/calendarModels';

interface ISchedulerData {
  drivers: SchedulerDriver[];
  data: SchedulerData[];
  date_from?: string;
  date_to?: string;
}

export interface ISchedulerReducer
  extends ISchedulerData,
    IWithPending,
    IWithLoaded,
    IWithError {}

// GET SCHEDULER
export const GET_SCHEDULER = '[@@Scheduler] GET_SCHEDULER';
export const GET_SCHEDULER_SUCCESS = '[@@Scheduler] GET_SCHEDULER_SUCCESS';
export const GET_SCHEDULER_FAIL = '[@@Scheduler] GET_SCHEDULER_FAIL';

export interface IGetSchedulerAction {
  type: typeof GET_SCHEDULER;
  payload: {
    date_from?: string;
    date_to?: string;
    callback?: () => void;
  };
}
export interface IGetSchedulerActionSuccess {
  type: typeof GET_SCHEDULER_SUCCESS;
  payload: ISchedulerData;
}
export interface IGetSchedulerActionFail {
  type: typeof GET_SCHEDULER_FAIL;
  payload: AxiosError;
}

export type schedulerActionsType =
  | IGetSchedulerAction
  | IGetSchedulerActionSuccess
  | IGetSchedulerActionFail
  | ILogout;
