import { ReactText } from 'react';

import { AxiosError } from 'axios';

import {
  IClaimsIssue,
  ICreateClaim,
} from 'components/Fleet/TabClaims/_models/claimsModels';
import {
  ADD_CLAIM_ISSUE,
  ADD_EDIT_CLAIM_NOTE,
  ADD_EDIT_CLAIM_NOTE_FAIL,
  CREATE_CLAIM,
  CREATE_CLAIM_FAIL,
  DELETE_CLAIM,
  DELETE_CLAIM_FAIL,
  DELETE_CLAIM_FILE,
  DELETE_CLAIM_ISSUE,
  DELETE_CLAIM_ISSUE_FILE,
  EDIT_CLAIM_ISSUE,
  IAddClaimIssueAction,
  IAddEditClaimNoteAction,
  IAddEditClaimNoteFailAction,
  ICreateClaimActionFail,
  IDeleteClaimAction,
  IDeleteClaimFailAction,
  IDeleteClaimFileAction,
  IDeleteClaimIssueAction,
  IDeleteClaimIssueFileAction,
  IEditClaimIssueAction,
  IMountClaimClaimForm,
  IUnmountClaimClaimForm,
  IUploadClaimFilesAction,
  IUploadClaimIssueFilesAction,
  MOUNT_CLAIM_FORM,
  UNMOUNT_CLAIM_FORM,
  UPLOAD_CLAIM_FILES,
  UPLOAD_CLAIM_ISSUE_FILES,
} from 'components/Fleet/TabClaims/_redux/claimsTypes';
import { IDeleteFileAction } from 'redux/_common/commonTypes';
import { ILocalFile } from 'redux/_common/models';

// Claim Actions
export const createClaimAction = (
  values: ICreateClaim,
  callback: () => void,
) => ({
  type: CREATE_CLAIM,
  payload: { values, callback },
});
export const createClaimFailAction = (
  error: AxiosError,
): ICreateClaimActionFail => ({
  type: CREATE_CLAIM_FAIL,
  payload: error,
});
export const deleteClaimAction = (
  id: ReactText,
  callback: () => void,
): IDeleteClaimAction => ({
  type: DELETE_CLAIM,
  payload: { id, callback },
});
export const deleteClaimFailAction = (
  error: AxiosError,
): IDeleteClaimFailAction => ({
  type: DELETE_CLAIM_FAIL,
  payload: error,
});
export const addEditClaimNoteAction = (
  id: ReactText,
  comment: string,
  callback: () => void,
): IAddEditClaimNoteAction => ({
  type: ADD_EDIT_CLAIM_NOTE,
  payload: { id, comment, callback },
});
export const addEditClaimNoteFailAction = (
  error: AxiosError,
): IAddEditClaimNoteFailAction => ({
  type: ADD_EDIT_CLAIM_NOTE_FAIL,
  payload: error,
});
// Claim Issues Actions
export const addClaimIssueAction = (
  values: IClaimsIssue,
): IAddClaimIssueAction => ({
  type: ADD_CLAIM_ISSUE,
  payload: { ...values },
});
export const editClaimIssueAction = (
  values: IClaimsIssue,
): IEditClaimIssueAction => ({
  type: EDIT_CLAIM_ISSUE,
  payload: { ...values },
});
export const deleteClaimIssueAction = (
  id: ReactText,
): IDeleteClaimIssueAction => ({
  type: DELETE_CLAIM_ISSUE,
  payload: id,
});

// Claim Files Actions
export const uploadClaimFilesAction = ({
  files,
  itemId,
}: {
  files: ILocalFile[];
  itemId?: number;
}): IUploadClaimFilesAction => ({
  type: UPLOAD_CLAIM_FILES,
  payload: { files, itemId },
});

export const deleteClaimFileAction = ({
  fileId,
  itemId,
}: IDeleteFileAction['payload']): IDeleteClaimFileAction => ({
  type: DELETE_CLAIM_FILE,
  payload: { fileId, itemId },
});

// Claim Issue Files Actions
export const uploadClaimIssueFilesAction = ({
  files,
  issue_id,
  claim_id,
}: IUploadClaimIssueFilesAction['payload']): IUploadClaimIssueFilesAction => ({
  type: UPLOAD_CLAIM_ISSUE_FILES,
  payload: { files, issue_id, claim_id },
});

export const deleteClaimIssueFileAction = ({
  issue_id,
  claim_id,
  fileId,
}: IDeleteClaimIssueFileAction['payload']): IDeleteClaimIssueFileAction => ({
  type: DELETE_CLAIM_ISSUE_FILE,
  payload: { issue_id, claim_id, fileId },
});

// Unmount Form actions
export const unmountClaimFormAction = (): IUnmountClaimClaimForm => ({
  type: UNMOUNT_CLAIM_FORM,
});

export const mountClaimFormAction = (
  payload: IMountClaimClaimForm['payload'],
): IMountClaimClaimForm => ({
  type: MOUNT_CLAIM_FORM,
  payload,
});
