import { MainSidebarAccordion } from '../_models';

// Reducer
export interface IMainSidebarReducer {
  collapsedFlag: boolean;
  overFlag: boolean;
  sidebarConfig: MainSidebarAccordion[];
}

// SET_COLLAPSED_FLAG
export const SET_COLLAPSED_FLAG = '[@@MainSidebar] SET_COLLAPSED_FLAG';

export interface ISetCollapsedFlag {
  type: typeof SET_COLLAPSED_FLAG;
  payload: boolean;
}

// SET_OVER_FLAG
export const SET_OVER_FLAG = '[@@MainSidebar] SET_OVER_FLAG';

export interface ISetOverFlag {
  type: typeof SET_OVER_FLAG;
  payload: boolean;
}

// SET_SIDEBAR_CONFIG
export const SET_SIDEBAR_CONFIG = '[@@MainSidebar] SET_SIDEBAR_CONFIG';

export interface ISetSidebarConfig {
  type: typeof SET_SIDEBAR_CONFIG;
  payload: MainSidebarAccordion[];
}

export type mainSidebarTypes =
  | ISetSidebarConfig
  | ISetCollapsedFlag
  | ISetOverFlag;
