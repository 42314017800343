/* eslint-disable func-names */
/* eslint-disable no-extend-native */
export type FixedSizeArray<N extends number, T> = N extends 0
  ? never[]
  : {
      0: T;
      length: N;
    } & ReadonlyArray<T>;

export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Array<T> {
    first(): T | undefined;
    last(): T | undefined;
    swap(index1: number, index2: number): boolean;

    /**
     * Returns an index pointing to the first element in the range [first, last)
     * that is not less than (i.e. greater or equal to) value, or last if no such element is found.
     * The range [first, last) MUST be sorted in ascending order.
     * @param predicate is a binary predicate which returns True if the passed array element
     * is less than (i.e. is ordered before) the value.
     * @param first is an index of first element in the range [first, last).
     * default is 0.
     * @param last is an index of last element in the range [first, last).
     * default is array length.
     */
    lowerBound(
      predicate: (element: T, index: number, obj: T[]) => boolean,
      first?: number,
      last?: number,
    ): number;

    /**
     * Returns an index pointing to the first element in the range [first, last)
     * that is greater than value, or last if no such element is found.
     * The range [first, last) MUST be sorted in ascending order.
     * @param predicate is a binary predicate which returns True if the passed array element
     * is greater than value.
     * @param first is an index of first element in the range [first, last).
     * default is 0.
     * @param last is an index of last element in the range [first, last).
     * default is array length.
     */
    upperBound(
      predicate: (element: T, index: number, obj: T[]) => boolean,
      first?: number,
      last?: number,
    ): number;
  }
}

if (!Array.prototype.first) {
  Array.prototype.first = function () {
    return this.length > 0 ? this[0] : undefined;
  };
}

if (!Array.prototype.last) {
  Array.prototype.last = function () {
    return this.length > 0 ? this[this.length - 1] : undefined;
  };
}

if (!Array.prototype.swap) {
  Array.prototype.swap = function (index1: number, index2: number) {
    const result = this.length > index1 && this.length > index2;
    if (result) {
      const tmp = this[index1];
      this[index1] = this[index2];
      this[index2] = tmp;
    }
    return result;
  };
}

if (!Array.prototype.lowerBound) {
  Array.prototype.lowerBound = function <T>(
    predicate: (value: T, index: number, obj: T[]) => boolean,
    first?: number,
    last?: number,
  ): number {
    let f = Math.max(first || 0, 0);
    let l = Math.min(last || this.length - 1, this.length - 1);
    while (f < l) {
      // eslint-disable-next-line no-bitwise
      const middle = (f + l) >> 1;
      if (predicate(this[middle], middle, this)) f = middle + 1;
      else l = middle;
    }
    return f;
  };
}

if (!Array.prototype.upperBound) {
  Array.prototype.upperBound = function <T>(
    predicate: (value: T, index: number, obj: T[]) => boolean,
    first?: number,
    last?: number,
  ): number {
    let f = Math.max(first || 0, 0);
    let l = Math.min(last || this.length - 1, this.length - 1);
    while (f < l) {
      // eslint-disable-next-line no-bitwise
      const middle = (f + l) >> 1;
      if (!predicate(this[middle], middle, this)) f = middle + 1;
      else l = middle;
    }
    return f;
  };
}
