import {
  FC,
  ComponentType,
  FocusEvent,
  useRef,
  useMemo,
  useEffect,
} from 'react';

import clsx from 'clsx';
import {
  WrappedFieldInputProps,
  WrappedFieldMetaProps,
  WrappedFieldProps,
} from 'redux-form';

import { BlackTooltip } from 'components/_common/CustomMaterialComponents';
import GoogleAutocomplete from 'components/_common/FormElements/AddressBlock/GoogleAutocomplete/GoogleAutocomplete';
import CustomAutocomplete, {
  FilteredAutocomplete,
  MultiAutocomplete,
} from 'components/_common/FormElements/FormField/CustomAutocomplete/CustomAutocomplete';
import CustomCheckbox from 'components/_common/FormElements/FormField/CustomCheckbox/CustomCheckbox';
import CustomDatePicker from 'components/_common/FormElements/FormField/CustomDatePicker/CustomDatePicker';
import CustomTimePicker from 'components/_common/FormElements/FormField/CustomTimePicker/CustomTimePicker';
import CustomToggleButton from 'components/_common/FormElements/FormField/CustomToggleButton/CustomToggleButton';
import Input from 'components/_common/FormElements/FormField/Input/Input';
import NumberInput from 'components/_common/FormElements/FormField/Input/NumberInput';
import PhoneNumberInput from 'components/_common/FormElements/FormField/Input/PhoneNumberInput';
import { ISelectOptions } from 'components/_common/FormElements/FormField/Select/_models/selectModels';
import MultiChipSelect from 'components/_common/FormElements/FormField/Select/MultiChipSelect';
import MultiSelect from 'components/_common/FormElements/FormField/Select/MultiSelect';
import Select, {
  CreatableSelect,
} from 'components/_common/FormElements/FormField/Select/Select';
import Range from 'components/_common/FormElements/FormField/Slider/Range';
import Rate from 'components/_common/FormElements/FormField/Slider/Rate';
import TagsInput from 'components/_common/FormElements/FormField/TagsInput/TagsInput';
import Textarea from 'components/_common/FormElements/FormField/Textarea/Textarea';
import TimeRangePicker from 'components/_common/FormElements/FormField/TimeRangePicker/TimeRangePicker';

import ColorsPalette from './ColorsPalette/ColorsPalette';
import CustomCheckboxWithInput from './CustomCheckbox/CustomCheckboxWithInput';
import CustomCheckboxWithSelect from './CustomCheckbox/CustomCheckboxWithSelect';
import CustomDateAndTimePicker from './CustomDateAndTimePicker/CustomDateAndTimePicker';
import ImportFilesFields from './ImportFilesFields/ImportFilesFields';
import MultiCheckbox from './MultiCheckbox/MultiCheckbox';

import styles from './FormField.module.scss';

export interface IFormFieldComponentsMap {
  [key: string]: {
    component: ComponentType<Partial<ComponentToRenderProps>>;
    normalize?: Function;
  };
}

export const fieldTypesConfig: IFormFieldComponentsMap = {
  select: { component: Select },
  multiSelect: { component: MultiSelect },
  multiChipSelect: { component: MultiChipSelect },
  creatableSelect: { component: CreatableSelect },
  datePicker: { component: CustomDatePicker },
  timePicker: { component: CustomTimePicker },
  timePickerRange: { component: TimeRangePicker },
  dateAndTime: { component: CustomDateAndTimePicker },
  textarea: { component: Textarea },
  checkbox: { component: CustomCheckbox },
  toggle: { component: CustomToggleButton },
  checkboxWithInput: { component: CustomCheckboxWithInput },
  checkboxWithSelect: { component: CustomCheckboxWithSelect },
  multiCheckbox: { component: MultiCheckbox },
  autocomplete: { component: CustomAutocomplete },
  filteredAutocomplete: { component: FilteredAutocomplete },
  multiAutocomplete: { component: MultiAutocomplete },
  googleAutocomplete: { component: GoogleAutocomplete },
  range: { component: Range },
  rate: { component: Rate },
  input: { component: Input },
  tagsInput: { component: TagsInput },
  importFiles: { component: ImportFilesFields },
  colorsPalette: { component: ColorsPalette },
  numberInput: { component: NumberInput },
  phoneNumberInput: { component: PhoneNumberInput },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ValueType = any;

export interface IFormFieldProps {
  tooltipIcon: JSX.Element;
  tooltipText: string;
  tooltipMaxWidth?: string;
  fieldType: string;
  labelClassName: string;
  symbolsLeftClassName?: string;
  labelText: string;
  withLabel?: boolean;
  extraControl: JSX.Element;

  value: ValueType;
  id: string;
  className: string;
  placeholder: string;
  icon: string | JSX.Element;
  type: string;
  name: string;
  defaultValue: number | string;
  min: number;
  max: number;
  step: number;
  hideError?: boolean;
  readOnly: boolean;
  required: boolean;
  fullWidth: boolean;
  disabled?: boolean;
  dollarSign?: boolean;
  toggleAreaSize?: boolean;
  initialTextTransform?: boolean;
  focusOnRender?: boolean;
  onSelect: (value: ValueType) => void;
  onChange: (value: ValueType) => void;
  inputNormalizer: (value: string) => string;
  handleFocus: (value: string) => void;
  options: ISelectOptions;
  propertyToUse?: string;
  maxLength?: number; //* max length of symbols, usually for textarea
  dataTestPrefix?: string;
  checkForDisabled?: boolean;
}

type Props = IFormFieldProps & WrappedFieldProps;

type ComponentToRenderProps = IFormFieldProps &
  WrappedFieldInputProps &
  WrappedFieldMetaProps;

const FormField: FC<Partial<Props>> = ({ input, meta, ...otherProps }) => {
  const {
    fieldType = 'input',
    fullWidth = false,
    labelClassName,
    symbolsLeftClassName,
    labelText,
    withLabel = true,
    id,
    tooltipIcon,
    tooltipText,
    tooltipMaxWidth,
    required,
    children,
    hideError = false,
    extraControl,
    maxLength,
    checkForDisabled,
  } = otherProps;

  const divErrorRef = useRef<HTMLDivElement>(null);

  const fieldTypeConfig = fieldTypesConfig[fieldType] || fieldTypesConfig.input;
  const ComponentToRender = useMemo(
    () => fieldTypeConfig.component,
    [fieldType],
  );

  const handleLabelFocus = (e: FocusEvent<HTMLLabelElement>) => {
    const { top } = e.target.getBoundingClientRect();
    const isInView = top >= 0 && top <= window.innerHeight;
    if (!isInView)
      e.target.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
  };

  const showError =
    !hideError && meta?.touched && (meta?.invalid || meta?.warning);

  const showDisabledError = !hideError && checkForDisabled && meta?.invalid;

  const showMainError = showError || showDisabledError;

  useEffect(() => {
    if (divErrorRef.current && showMainError) {
      const componentTestAttr =
        divErrorRef.current?.previousElementSibling?.firstElementChild?.getAttribute(
          'data-test',
        );
      if (componentTestAttr)
        divErrorRef.current.setAttribute(
          'data-test',
          `${componentTestAttr}_${meta?.invalid ? 'error' : 'warning'}`,
        );
    }
  }, [divErrorRef?.current, showMainError]);

  const symbolsLeft = useMemo(() => {
    if (maxLength && input) {
      const leftSymbols = maxLength - (input.value?.length || 0);
      return leftSymbols >= 0 ? leftSymbols : 0;
    }
    return undefined;
  }, [maxLength, input?.value]);

  return (
    <label
      className={clsx(
        styles.root,
        labelClassName,
        fullWidth && styles.fullWidth,
      )}
      htmlFor={id}
      onFocus={handleLabelFocus}
    >
      <div className={styles.labelBlock} id={id}>
        {withLabel &&
          fieldType !== 'select' &&
          fieldType !== 'creatableSelect' && (
            <span className={styles.labelText}>{labelText}</span>
          )}

        {required &&
          withLabel &&
          fieldType !== 'select' &&
          fieldType !== 'creatableSelect' && (
            <span className={styles.required}>*</span>
          )}

        {tooltipIcon && tooltipText && (
          <BlackTooltip
            title={tooltipText}
            placement="top"
            className={styles.tooltip}
            maxWidth={tooltipMaxWidth}
            arrow
          >
            <span>{tooltipIcon}</span>
          </BlackTooltip>
        )}
        {fieldType !== 'select' && extraControl}
      </div>

      <ComponentToRender {...otherProps} {...input} {...meta} />
      {showMainError && (
        <div
          className={meta.invalid ? styles.error : styles.warning}
          ref={divErrorRef}
          data-role={meta.error ? 'error' : 'warning'}
        >
          {meta.error || meta.warning}
        </div>
      )}

      {children}

      {symbolsLeft !== undefined && (
        <span className={clsx(styles.symbolsLeft, symbolsLeftClassName)}>
          {symbolsLeft}
        </span>
      )}
    </label>
  );
};

export default FormField;
