/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';

import { IWidgetOption } from 'components/_common/WidgetSettingsMenu/IWidgetOption';
import {
  IBizOwnerInfo,
  ICompanyInfo,
  IDivisionInfo,
  IOfficeInfo,
} from 'components/Dispatch/TabReports/Overview/_models';
import { ILogout } from 'redux/_common/models';
import { IWithPending, IWithLoaded, IWithError } from 'utils/models';

export type FilterKeys = 'companies' | 'offices' | 'divisions' | 'dispatchers';
export type FilterPayload = {
  offices?: string[];
  divisions?: string[];
  driver_types?: string[];
  driver_cooperations?: string[];
  order_types?: string[];
  companies?: string[];
  from_date?: string;
  to_date?: string;
  range?: string;
  grouped_by?: string;
};

type BasicType = { [key: string]: number };

export type GeneralInfoSingle = {
  name: string;
  period: { type: string; duration: number };
  total: number;
  trend: number;
};

export type DoubleDataset = {
  name: string;
  value: BasicType;
  trend: BasicType;
};

export type SingleDataset = {
  name: string;
  value: number;
  trend: number;
};

export type DoublePeriod = {
  date_from: string;
  date_to: string;
  datasets: DoubleDataset[];
};

export type SinglePeriod = {
  date_from: string;
  date_to: string;
  datasets: SingleDataset[];
};

export type SingleChartData = {
  general: GeneralInfoSingle[];
  periods: SinglePeriod[];
};

export type TooltipPayload = { name: string; value: 0; trend: 0 };
export type OverviewCalendarRanges = 'weeks' | 'months' | 'quarters';

export interface IOverviewReducer
  extends IWithPending,
    IWithLoaded,
    IWithError {
  reports: {
    companies?: ICompanyInfo[];
    biz_owner?: IBizOwnerInfo;
    offices?: IOfficeInfo[];
    divisions?: IDivisionInfo[];
    charts: {
      [key: string]: any;
    };
  };
  widgets: IWidgetOption[];
  widgetView: string;
  filterKey?: FilterKeys;
  filterCount: number;
  filters?: FilterPayload;
}

// GET OVERVIEW
export const GET_OVERVIEW = '[@@Overview] GET_OVERVIEW';
export const GET_OVERVIEW_SUCCESS = '[@@Overview] GET_OVERVIEW_SUCCESS';
export const GET_OVERVIEW_FAIL = '[@@Overview] GET_OVERVIEW_FAIL';
export const SET_WIDGETS = '[@@Overview] SET_WIDGETS';
export const SET_WIDGET_VIEW = '[@@Widgets] SET_WIDGET_VIEW';
export const SET_FILTER = '[@@Overview] SET_FILTER';
export const SET_FILTER_KEY = '[@@Overview] SET_FILTER_KEY';
export const CLEAR_FILTERS = '[@@Overview] CLEAR_FILTERS';
export const UPDATE_FILTER = '[@@Overview] UPDATE_FILTER';
export const SET_FILTER_COUNT = '[@@Overview] SET_FILTER_COUNT';
export const SET_CALENDAR_RANGE = '[@@Overview] SET_CALENDAR_RANGE';

export interface IGetOverviewDataAction {
  type: typeof GET_OVERVIEW;
  payload: {
    from_date?: string;
    to_date?: string;
    offices?: string[];
    divisions?: string[];
    dispatchers?: string[];
    driver_types?: string[];
    driver_cooperations?: string[];
    order_types?: string[];
    companies?: string[];
    general_reports?: FilterKeys;
    callback?: () => void;
  };
}
export interface IGetOverviewActionSuccess {
  type: typeof GET_OVERVIEW_SUCCESS;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}
export interface IGetOverviewActionFail {
  type: typeof GET_OVERVIEW_FAIL;
  payload: AxiosError;
}
export interface ISetWidgetsAction {
  type: typeof SET_WIDGETS;
  payload: IWidgetOption[];
}
export interface ISetWidgetViewAction {
  type: typeof SET_WIDGET_VIEW;
  payload: string;
}

export interface ISetFilterAction {
  type: typeof SET_FILTER;
  payload: { key: string; filters: number[] | string[] | string };
}
export interface ISetFilterKeyAction {
  type: typeof SET_FILTER_KEY;
  payload?: FilterKeys;
}
export interface IClearFiltersAction {
  type: typeof CLEAR_FILTERS;
  payload?: string[];
}
export interface ISetFilterCountAction {
  type: typeof SET_FILTER_COUNT;
  payload: number;
}
export interface IUpdateFilterAction {
  type: typeof UPDATE_FILTER;
  payload: { key: string; id: string };
}
export type overviewActionsType =
  | IGetOverviewDataAction
  | IGetOverviewActionFail
  | IGetOverviewActionSuccess
  | ISetWidgetsAction
  | ISetWidgetViewAction
  | ISetFilterAction
  | ISetFilterKeyAction
  | ISetFilterCountAction
  | IClearFiltersAction
  | IUpdateFilterAction
  | ILogout;
