import {
  IUpcomingDriver,
  StepStatus,
} from 'components/HumanResources/TabDrivers/_models/driverModel';

import {
  CLEAR_UPCOMING_DRIVER,
  COMPLETE_UPCOMING_DRIVER_STEP_FAIL,
  COMPLETE_UPCOMING_DRIVER_STEP_SUCCESS,
  COMPLETE_UPCOMING_DRIVER_TEST_FAIL,
  COMPLETE_UPCOMING_DRIVER_TEST_SUCCESS,
  DELETE_PROCESS_FILE,
  DELETE_PROCESS_FILE_FAIL,
  DELETE_PROCESS_FILE_SUCCESS,
  DELETE_UPCOMING_DRIVER_STEP_FILE_SUCCESS,
  DELETE_UPCOMING_DRIVER_TEST_FILE_FAIL,
  DELETE_UPCOMING_DRIVER_TEST_FILE_SUCCESS,
  GET_UPCOMING_DRIVER,
  GET_UPCOMING_DRIVER_FAIL,
  GET_UPCOMING_DRIVER_SUCCESS,
  IUpcomingDriverReducer,
  IUpcomingProcessFile,
  SET_UPCOMING_DRIVER_CURRENT_STEP,
  START_UPCOMING_DRIVER_STEP,
  upcomingDriverActionsType,
  UPDATE_UPCOMING_DRIVER_TEST,
  UPLOAD_PROCESS_FILE,
  UPLOAD_PROCESS_FILE_FROM_PROFILE,
  UPLOAD_PROCESS_FILE_SUCCESS,
  UPLOAD_UPCOMING_DRIVER_FILE_SUCCESS,
  UPLOAD_UPCOMING_DRIVER_TEST_FILE_FAIL,
  UPLOAD_UPCOMING_DRIVER_TEST_FILE_SUCCESS,
} from './upcomingDriverTypes';

const initialState: IUpcomingDriverReducer = {
  _pending: false,
  _loaded: false,
  _error: null,
  upcomingDriver: {} as IUpcomingDriver,
  files: [],
};

const upcomingDriverReducer = (
  state = initialState,
  action: upcomingDriverActionsType,
): IUpcomingDriverReducer => {
  switch (action.type) {
    case SET_UPCOMING_DRIVER_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload?.step,
      };

    case UPLOAD_UPCOMING_DRIVER_FILE_SUCCESS: {
      if (state.currentStep) {
        const fileType = action.payload.file.file_type;
        const { files } = state.currentStep;

        const newFiles = {
          ...files,
          [fileType]: [...files[fileType], action.payload.file],
        };

        return {
          ...state,
          currentStep: {
            ...state.currentStep,
            files: newFiles,
          },
        };
      }
      return {
        ...state,
      };
    }

    case DELETE_UPCOMING_DRIVER_STEP_FILE_SUCCESS: {
      if (state.currentStep) {
        const { fileType, fileId } = action.payload;
        const { files } = state.currentStep;

        const newFiles = {
          ...files,
          [fileType]: files[fileType].filter(({ id }) => id !== fileId),
        };
        return {
          ...state,
          currentStep: {
            ...state.currentStep,
            files: newFiles,
          },
        };
      }
      return {
        ...state,
      };
    }

    case UPLOAD_UPCOMING_DRIVER_TEST_FILE_SUCCESS: {
      if (state.currentStep) {
        const { file, testId } = action.payload;
        const stepTests = [...state.currentStep.tests];
        const testToUpdate = stepTests.findIndex(t => t.id === testId);
        stepTests[testToUpdate].files.push(file);

        return {
          ...state,
          currentStep: {
            ...state.currentStep,
            tests: stepTests,
          },
        };
      }
      return {
        ...state,
      };
    }

    case UPLOAD_UPCOMING_DRIVER_TEST_FILE_FAIL: {
      return { ...state, _error: action.payload };
    }

    case DELETE_UPCOMING_DRIVER_TEST_FILE_SUCCESS: {
      if (state.currentStep) {
        const { fileId, testId } = action.payload;
        const stepTests = [...state.currentStep.tests];
        const testToUpdate = stepTests.find(t => t.id === testId);
        if (testToUpdate) {
          const fileToDeleteIndex = testToUpdate.files.findIndex(
            f => f.id === fileId,
          );
          testToUpdate.files.splice(fileToDeleteIndex, 1);
          const testToUpdateIndex = stepTests.findIndex(t => t.id === testId);
          stepTests.splice(testToUpdateIndex, 1, testToUpdate);
          return {
            ...state,
            currentStep: {
              ...state.currentStep,
              tests: stepTests,
            },
          };
        }
      }
      return {
        ...state,
      };
    }

    case DELETE_UPCOMING_DRIVER_TEST_FILE_FAIL: {
      return { ...state, _error: action.payload };
    }

    case COMPLETE_UPCOMING_DRIVER_TEST_SUCCESS: {
      if (state.currentStep) {
        const { completedTest } = action.payload;
        const stepTests = [...state.currentStep.tests];
        const testToUpdateIndex = stepTests.findIndex(
          t => t.name === completedTest.name,
        );
        if (testToUpdateIndex >= 0) {
          stepTests.splice(testToUpdateIndex, 1, completedTest);
        }
        return {
          ...state,
          currentStep: {
            ...state.currentStep,
            tests: [...stepTests],
          },
        };
      }
      return {
        ...state,
      };
    }

    case UPDATE_UPCOMING_DRIVER_TEST: {
      if (state.currentStep) {
        const { id, comment } = action.payload;
        const stepTests = [...state.currentStep.tests];
        const testToUpdateIndex = stepTests.findIndex(t => t.id === id);
        stepTests[testToUpdateIndex].comment = comment;
        return {
          ...state,
          currentStep: {
            ...state.currentStep,
            tests: [...stepTests],
          },
        };
      }
      return {
        ...state,
      };
    }

    case COMPLETE_UPCOMING_DRIVER_TEST_FAIL: {
      return { ...state, _error: action.payload };
    }

    case COMPLETE_UPCOMING_DRIVER_STEP_SUCCESS: {
      return { ...state };
    }

    case COMPLETE_UPCOMING_DRIVER_STEP_FAIL: {
      return { ...state, _error: action.payload };
    }

    case GET_UPCOMING_DRIVER:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        upcomingDriver: {
          ...state.upcomingDriver,
        },
      };

    case GET_UPCOMING_DRIVER_FAIL: {
      return {
        ...state,
        _loaded: true,
        _pending: false,
        upcomingDriver: {
          ...state.upcomingDriver,
        },
      };
    }

    case GET_UPCOMING_DRIVER_SUCCESS: {
      return {
        ...state,
        _loaded: true,
        _pending: false,
        upcomingDriver: action.payload,
      };
    }

    case START_UPCOMING_DRIVER_STEP: {
      const id = action.payload;
      const { steps } = state.upcomingDriver;
      const updatedSteps = steps.map(s =>
        s.id === id ? { ...s, status: 'active' as StepStatus } : s,
      );
      const activeStep = updatedSteps.find(s => s.id === id);
      return {
        ...state,
        upcomingDriver: {
          ...state.upcomingDriver,
          steps: updatedSteps,
        },
        ...(!!activeStep && {
          currentStep: { ...activeStep, status: 'in_progress', started: true },
        }),
      };
    }

    case CLEAR_UPCOMING_DRIVER: {
      return {
        ...state,
        _loaded: true,
        _pending: false,
        _error: null,
        upcomingDriver: {} as IUpcomingDriver,
      };
    }

    case UPLOAD_PROCESS_FILE: {
      return {
        ...state,
        files: [
          ...state.files,
          ...action.payload.files.map(
            file =>
              ({
                ...file,
                loading: true,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } as any),
          ),
        ],
        _loaded: false,
        _pending: true,
        _error: null,
      };
    }

    case UPLOAD_PROCESS_FILE_SUCCESS: {
      return {
        ...state,
        _loaded: true,
        _pending: false,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        files: state.files.map((file: any, index: number) =>
          index === state.files.findIndex(({ loading }) => loading)
            ? { ...action.payload, loading: false }
            : file,
        ),
      };
    }

    case UPLOAD_PROCESS_FILE_FROM_PROFILE: {
      return {
        ...state,
        /// eslint-disable-next-line @typescript-eslint/no-explicit-any
        files: [
          ...state.files,
          action.payload as unknown as IUpcomingProcessFile,
        ],
      };
    }

    case DELETE_PROCESS_FILE:
      return state;
    case DELETE_PROCESS_FILE_SUCCESS:
      return {
        ...state,
        files: state.files.filter(el => el.file.id !== action.payload),
      };
    case DELETE_PROCESS_FILE_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    default:
      return state;
  }
};

export default upcomingDriverReducer;
