import { FC } from 'react';

interface IOwnProps {
  className?: string;
}

const IconTag: FC<IOwnProps> = ({ className }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1 3C1 1.89543 1.89543 1 3 1H10.1382C10.6687 1 11.1774 1.21071 11.5525 1.58579L22.5858 12.6191C23.3668 13.4002 23.3668 14.6665 22.5858 15.4475L15.4475 22.5858C14.6665 23.3668 13.4002 23.3668 12.6191 22.5858L1.58578 11.5525C1.21071 11.1774 1 10.6687 1 10.1382V3Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.36732 7.90016C7.21415 7.90016 7.90065 7.21367 7.90065 6.36683C7.90065 5.51999 7.21415 4.8335 6.36732 4.8335C5.52048 4.8335 4.83398 5.51999 4.83398 6.36683C4.83398 7.21367 5.52048 7.90016 6.36732 7.90016Z"
        fill="white"
      />
    </svg>
  );
};

export default IconTag;
