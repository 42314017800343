import { FC, useEffect, useRef, useState } from 'react';

import Cropper from 'cropperjs';

import Button from 'components/_common/FormElements/Button/Button';

import styles from './CropImgModal.module.scss';

type Props = {
  file: File;
  onClose: () => void;
  getBlobImg: (blob: Blob) => void;
};

const CropImgModal: FC<Props> = ({ file, onClose, getBlobImg }) => {
  const [cropper, setCropper] = useState<Cropper | null>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  const onModalClose = () => {
    if (cropper) cropper.destroy();
    onClose();
  };

  useEffect(
    () => () => {
      if (cropper) cropper.destroy();
    },
    [cropper],
  );

  const onFileSave = () => {
    if (cropper) {
      cropper
        .getCroppedCanvas({
          width: 300,
          imageSmoothingEnabled: true,
          imageSmoothingQuality: 'high',
        })
        .toBlob((blob: Blob | null) => {
          if (blob) getBlobImg(blob);
        });
    }

    onModalClose();
  };

  useEffect(() => {
    const image = imageRef.current;

    if (file && image) {
      image.src = URL.createObjectURL(file);
      setCropper(new Cropper(image, { aspectRatio: 1, zoomable: false }));
    }

    return () => {
      if (image) URL.revokeObjectURL(image.src);
    };
  }, [file]);

  return (
    <div className={styles.root}>
      <h2>Change profile image</h2>
      <div className={styles.imageWrapper}>
        <img ref={imageRef} src="" alt="" width={600} />
      </div>
      <div className={styles.btnBlock}>
        <Button text="Save" theme="blue" onClick={onFileSave} focusOnRender />
        <Button text="Cancel" theme="grey" onClick={onModalClose} />
      </div>
    </div>
  );
};

export default CropImgModal;
