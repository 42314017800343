import { FC } from 'react';

interface IOwnProps {
  className?: string;
  onClick?: () => void;
}

const IconDocument: FC<IOwnProps> = ({ onClick, className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M2 13V6C2 4.89543 2.89543 4 4 4H12C13.1046 4 14 4.89543 14 6V13C14 14.1046 13.1046 15 12 15H4C2.89543 15 2 14.1046 2 13Z"
        stroke="#86898E"
        strokeWidth="2"
      />
      <path
        d="M5 1H11"
        stroke="#86898E"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconDocument;
