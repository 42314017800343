import { lazy } from 'react';

import {
  PERMISSION_EMPLOYEE_LIST,
  PERMISSION_EMPLOYEE_CREATE,
  PERMISSION_EMPLOYEE_UPDATE,
  PERMISSION_DRIVER_LIST,
  PERMISSION_DRIVER_CREATE,
  PERMISSION_DRIVER_UPDATE,
  PERMISSION_LEAD_LIST,
  PERMISSION_LEAD_CREATE,
  PERMISSION_LEAD_UPDATE,
  PERMISSION_UPCOMING_DRIVERS_LIST,
  PERMISSION_ARCHIVE_DRIVER_LIST,
} from 'constants/permissions';

import { IPermissionObject } from '../index';
import PrivateRoutes, { redirectRoute } from '../PrivateRoutes/PrivateRoutes';

const ApplicantProfile = lazy(
  () =>
    import(
      'components/HumanResources/TabApplicants/ApplicantProfile/ApplicantProfile'
    ),
);
const UpdateApplicant = lazy(
  () =>
    import(
      'components/HumanResources/TabApplicants/UpdateApplicant/UpdateApplicant'
    ),
);
const CreateDriver = lazy(
  () =>
    import('components/HumanResources/TabDrivers/CreateDriver/CreateDriver'),
);
const UpdateDriver = lazy(
  () =>
    import('components/HumanResources/TabDrivers/UpdateDriver/UpdateDriver'),
);
const DriverProfile = lazy(
  () =>
    import('components/HumanResources/TabDrivers/DriverProfile/DriverProfile'),
);
const CreateEmployee = lazy(
  () =>
    import(
      'components/HumanResources/TabEmployees/CreateEmployee/CreateEmployee'
    ),
);
const ImportEmployee = lazy(
  () =>
    import(
      'components/HumanResources/TabEmployees/ImportEmployee/ImportEmployee'
    ),
);
const ImportDriver = lazy(
  () =>
    import('components/HumanResources/TabDrivers/ImportDriver/ImportDriver'),
);
const UpdateEmployee = lazy(
  () =>
    import(
      'components/HumanResources/TabEmployees/UpdateEmployee/UpdateEmployee'
    ),
);
const EmployeeProfile = lazy(
  () =>
    import(
      'components/HumanResources/TabEmployees/EmployeeProfile/EmployeeProfile'
    ),
);
const Drivers = lazy(
  () => import('components/HumanResources/TabDrivers/Drivers'),
);
const Employees = lazy(
  () => import('components/HumanResources/TabEmployees/Employees'),
);
const ApplicantsArchive = lazy(
  () =>
    import('components/HumanResources/TabApplicantsArchive/ApplicantsArchive'),
);
const Leads = lazy(() => import('components/HumanResources/TabLeads/Leads'));
const LeadProfile = lazy(
  () => import('components/HumanResources/TabLeads/LeadProfile/LeadProfile'),
);

const UpcomingDrivers = lazy(
  () => import('components/HumanResources/TabUpcomingDrivers/UpcomingDrivers'),
);

const UpcomingDriverProfile = lazy(
  () =>
    import(
      'components/HumanResources/TabUpcomingDrivers/UpcomingDriverProfile/UpcomingDriverProfile'
    ),
);

const CreateLead = lazy(
  () => import('components/HumanResources/TabLeads/AddLead/AddLeadPage'),
);

const UpdateLead = lazy(
  () => import('components/HumanResources/TabLeads/AddLead/AddLeadPage'),
);

const UpcomingDriverContracts = lazy(
  () =>
    import(
      'components/HumanResources/TabUpcomingDrivers/components/Contract/CreateAndUpdateUpcomingContract'
    ),
);

const EditDriverContracts = lazy(
  () =>
    import(
      'components/HumanResources/TabUpcomingDrivers/components/Contract/CreateAndUpdateUpcomingContract'
    ),
);

const DriverContracts = lazy(
  () =>
    import(
      'components/HumanResources/TabDrivers/DriverProfile/TabContracts/AddContract/CreateAndUpdateDriverContract'
    ),
);

const Analytics = lazy(
  () => import('components/HumanResources/TabAnalytics/Analytics'),
);

const getRedirectURL = (permissions?: string[]): string => {
  if (permissions?.includes(PERMISSION_EMPLOYEE_LIST)) return '/hr/employees';
  if (permissions?.includes(PERMISSION_DRIVER_LIST)) return '/hr/drivers';
  if (permissions?.includes(PERMISSION_LEAD_LIST)) return '/hr/leads';
  if (permissions?.includes(PERMISSION_UPCOMING_DRIVERS_LIST))
    return '/hr/upcoming';
  if (permissions?.includes(PERMISSION_ARCHIVE_DRIVER_LIST))
    return '/hr/archive';
  return '/hr/archive';
};

/* HR Main Page (redirect into) */
export const mainHRRoute = (permissions?: IPermissionObject[]) => {
  const targetName = 'HR';
  const targetObject = permissions?.find(obj => obj.name === targetName);
  return redirectRoute(
    '/hr',
    getRedirectURL(targetObject ? targetObject.permissions : []),
  );
};

/* Drivers */
export const mainDriversRoute = (
  <PrivateRoutes
    exact
    path="/hr/drivers"
    component={Drivers}
    permissionType={PERMISSION_DRIVER_LIST}
  />
);
export const driverCreateRoute = (
  <PrivateRoutes
    exact
    path="/hr/drivers/create"
    component={CreateDriver}
    permissionType={PERMISSION_DRIVER_CREATE}
  />
);
export const driverEditRoute = (
  <PrivateRoutes
    exact
    path={['/hr/drivers/:id/edit', '/hr/drivers/:id/salary/edit']}
    component={UpdateDriver}
    permissionType={PERMISSION_DRIVER_UPDATE}
  />
);
export const driverProfileRoute = (
  <PrivateRoutes
    exact
    path="/hr/drivers/:id"
    component={DriverProfile}
    permissionType={PERMISSION_DRIVER_LIST}
  />
);
export const driverImportRoute = (
  <PrivateRoutes
    exact
    path="/hr/drivers/import"
    component={ImportDriver}
    permissionType={PERMISSION_DRIVER_LIST}
  />
);

/* Employees */
export const mainEmployeesRoute = (
  <PrivateRoutes
    exact
    path="/hr/employees"
    component={Employees}
    permissionType={PERMISSION_EMPLOYEE_LIST}
  />
);
export const employeeImportRoute = (
  <PrivateRoutes
    exact
    path="/hr/employees/import"
    component={ImportEmployee}
    permissionType={PERMISSION_EMPLOYEE_LIST}
  />
);
export const employeeCreateRoute = (
  <PrivateRoutes
    exact
    path="/hr/employees/create"
    component={CreateEmployee}
    permissionType={PERMISSION_EMPLOYEE_CREATE}
  />
);
export const employeeProfileRoute = (
  <PrivateRoutes
    exact
    path="/hr/employees/:id"
    component={EmployeeProfile}
    permissionType={PERMISSION_EMPLOYEE_LIST}
  />
);
export const employeeEditRoute = (
  <PrivateRoutes
    exact
    path="/hr/employees/:id/edit"
    component={UpdateEmployee}
    permissionType={PERMISSION_EMPLOYEE_UPDATE}
  />
);
/* Leads */
export const mainLeadsRoute = (
  <PrivateRoutes
    exact
    path="/hr/leads"
    component={Leads}
    permissionType={PERMISSION_LEAD_LIST}
  />
);
export const createLeadRoute = (
  <PrivateRoutes
    exact
    path="/hr/leads/createLead"
    component={CreateLead}
    permissionType={PERMISSION_LEAD_CREATE}
  />
);
export const editLeadRoute = (
  <PrivateRoutes
    exact
    path="/hr/leads/editLead/:id"
    component={UpdateLead}
    permissionType={PERMISSION_LEAD_UPDATE}
  />
);
export const leadProfileRoute = (
  <PrivateRoutes
    exact
    path="/hr/leads/:id"
    component={LeadProfile}
    permissionType={PERMISSION_LEAD_LIST}
  />
);
/* Upcoming Drivers */
export const mainUpcomingDriversRoute = (
  <PrivateRoutes
    exact
    path="/hr/upcoming_drivers"
    component={UpcomingDrivers}
    permissionType={PERMISSION_UPCOMING_DRIVERS_LIST}
  />
);

export const upcomingDriverRoute = (
  <PrivateRoutes
    exact
    path="/hr/upcoming_drivers/:driverId"
    component={UpcomingDriverProfile}
    permissionType={PERMISSION_UPCOMING_DRIVERS_LIST}
  />
);

/* Archive */
export const mainArchiveRoute = (
  <PrivateRoutes
    exact
    path="/hr/archive"
    component={ApplicantsArchive}
    permissionType={PERMISSION_ARCHIVE_DRIVER_LIST}
  />
);
export const applicantProfileRoute = (
  <PrivateRoutes
    exact
    path="/hr/archive/:id"
    component={ApplicantProfile}
    permissionType={PERMISSION_ARCHIVE_DRIVER_LIST}
  />
);
export const applicantEditRoute = (
  <PrivateRoutes
    exact
    path="/hr/archive/:id/edit"
    component={UpdateApplicant}
    permissionType={PERMISSION_ARCHIVE_DRIVER_LIST}
  />
);

export const upcomingDriverContractRoute = (
  <PrivateRoutes
    exact
    path="/hr/upcoming_drivers/:orientationId/contract/:fieldId"
    component={UpcomingDriverContracts}
    permissionType={PERMISSION_UPCOMING_DRIVERS_LIST}
  />
);

export const editUpcomingDriverContractRoute = (
  <PrivateRoutes
    exact
    path="/hr/upcoming_drivers/:orientationId/contract/:fieldId/edit/:contractId"
    component={EditDriverContracts}
    permissionType={PERMISSION_UPCOMING_DRIVERS_LIST}
  />
);

export const analyticsRoute = (
  <PrivateRoutes
    exact
    path="/hr/analytics"
    component={Analytics}
    permissionType={PERMISSION_DRIVER_LIST}
  />
);

export const driverContractRoute = (
  <PrivateRoutes
    exact
    path="/hr/drivers/:driverId/contract"
    component={DriverContracts}
    permissionType={PERMISSION_DRIVER_LIST}
  />
);

export const editDriverContractRoute = (
  <PrivateRoutes
    exact
    path="/hr/drivers/:driverId/contract/:contractId"
    component={DriverContracts}
    permissionType={PERMISSION_DRIVER_LIST}
  />
);
