import {
  CLEAR_SCRIPT_STATE,
  DELETE_SCRIPT_FILE_SUCCESS,
  scriptActionsType,
  UPLOAD_EXISTING_SCRIPT_FILES_SUCCESS,
  UPLOAD_SCRIPT_FILES,
  UPLOAD_SCRIPT_FILES_FAIL,
  UPLOAD_SCRIPT_FILES_SUCCESS,
} from './scriptsTypes';

interface IScriptsUploadedFile {
  id: number;
  file_name: string;
  file_size: number;
  content_type: string;
  segmentId: number;
  urls: { thumb: string; original: string };
}

interface IScriptsReducer {
  files: IScriptsUploadedFile[];
}

const initialState: IScriptsReducer = {
  files: [],
};

const scriptsReducer = (
  state = initialState,
  action: scriptActionsType,
): IScriptsReducer => {
  switch (action.type) {
    case UPLOAD_SCRIPT_FILES: {
      return {
        ...state,
      };
    }
    case UPLOAD_SCRIPT_FILES_SUCCESS:
    case UPLOAD_EXISTING_SCRIPT_FILES_SUCCESS:
      return {
        ...state,
        files: [
          ...state.files,
          { ...action.payload.file, segmentId: action.payload.segmentId },
        ],
      };
    case UPLOAD_SCRIPT_FILES_FAIL:
      return {
        ...state,
      };
    case DELETE_SCRIPT_FILE_SUCCESS:
      return {
        ...state,
        files: [...state.files.filter(file => file.id !== action.payload)],
      };
    case CLEAR_SCRIPT_STATE:
      return {
        ...state,
        files: [],
      };
    default:
      return { ...state };
  }
};

export default scriptsReducer;
