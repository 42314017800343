import { FC } from 'react';

import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import { BlueCheckbox } from 'components/_common/CustomMaterialComponents';

import styles from './CustomCheckboxes.module.scss';

interface IOwnProps {
  id: string;
  label: string;
  value: string;
  className: string;
  description?: string;
}

type Props = IOwnProps & WrappedFieldInputProps & WrappedFieldMetaProps;

const CustomCheckboxWithInput: FC<Partial<Props>> = ({
  value,
  name,
  label,
  className,
  onChange,
}) => {
  return (
    <div className={styles.checkboxWithInput}>
      <section className={styles.checkbox}>
        <BlueCheckbox
          name={`checkbox_${name}`}
          color="primary"
          onChange={onChange}
          checked={!!value}
          classes={{
            root: className,
          }}
        />
        <span>{label}</span>
      </section>

      <section className={styles.input}>
        <input
          value={`${value}` === 'false' || `${value}` === 'true' ? '' : value}
          placeholder="Enter value"
          name={`input_${name}`}
          disabled={!value}
          autoComplete="off"
          onChange={onChange}
        />
      </section>
    </div>
  );
};

export default CustomCheckboxWithInput;
