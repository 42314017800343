import { FC } from 'react';

interface IOwnProps {
  className?: string;
  onClick?: () => void;
  fillColor?: string;
}

type Props = IOwnProps;

const IconQuestionMark: FC<Props> = ({
  className,
  onClick,
  fillColor = 'var(--primaryText)',
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM8 14C4.7 14 2 11.3 2 8C2 4.7 4.7 2 8 2C11.3 2 14 4.7 14 8C14 11.3 11.3 14 8 14Z"
        fill={fillColor}
      />
      <path
        d="M8 13C8.55228 13 9 12.5523 9 12C9 11.4477 8.55228 11 8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13Z"
        fill={fillColor}
      />
      <path
        d="M7.10039 5.5C7.30039 5.2 7.60039 5 8.00039 5C8.60039 5 9.00039 5.4 9.00039 6C9.00039 6.3 8.90039 6.4 8.40039 6.7C7.80039 7.1 7.00039 7.7 7.00039 9V10H9.00039V9C9.00039 8.8 9.00039 8.7 9.50039 8.4C10.1004 8 11.0004 7.4 11.0004 6C11.0004 4.3 9.70039 3 8.00039 3C6.90039 3 5.90039 3.6 5.40039 4.5L4.90039 5.4L6.60039 6.4L7.10039 5.5Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconQuestionMark;
