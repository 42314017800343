import { Store, Dispatch, Action } from 'redux';

import {
  LOGIN,
  REFRESH_USER,
  SET_ONLINE,
  SET_OFFLINE,
  FULL_REG_COMPANY,
} from 'components/Auth/_redux/authTypes';
import { CREATE_CHATROOM } from 'components/Chat/_redux/chatTypes';
import {
  GET_BROKER,
  GET_BROKERS,
} from 'components/Dispatch/TabBrokers/_redux/brokersTypes';
import {
  GET_FACILITY,
  GET_FACILITIES,
} from 'components/Dispatch/TabFacilities/_redux/facilitiesTypes';
import {
  GET_ORDER,
  GET_ORDERS,
} from 'components/Dispatch/TabOrders/_redux/ordersTypes';
import {
  GET_GEOFENCES,
  GET_GEOFENCE,
  UPDATE_GEOFENCE,
} from 'components/Fleet/TabGeoFences/_redux/geoFencesTypes';
import {
  GET_TOLL,
  GET_TOLLS,
  UPDATE_TOLL,
} from 'components/Fleet/TabTolls/_redux/tollsTypes';
import {
  GET_TRAILERS,
  GET_TRAILER,
  UPDATE_TRAILER,
} from 'components/Fleet/TabTrailers/_redux/trailersTypes';
import {
  GET_TRUCKS,
  GET_TRUCK,
  UPDATE_TRUCK,
  GET_TRACKING_TRUCKS,
} from 'components/Fleet/TabTrucks/_redux/trucksTypes';
import {
  GET_APPLICANT,
  UPDATE_APPLICANT,
} from 'components/HumanResources/TabApplicants/_redux/applicantsTypes';
import { GET_APPLICANTS_ARCHIVE_LIST } from 'components/HumanResources/TabApplicantsArchive/_redux/applicantsArchiveTypes';
import {
  GET_DRIVER,
  GET_DRIVERS,
  UPDATE_DRIVER,
} from 'components/HumanResources/TabDrivers/_redux/driversTypes';
import {
  GET_EMPLOYEE,
  GET_EMPLOYEES,
} from 'components/HumanResources/TabEmployees/_redux/employeesTypes';
import {
  GET_LOCATIONS,
  GET_LOCATION_BY_ID,
} from 'components/Inventory/TabLocations/_redux/locationsTypes';
import { CREATE_VENDOR } from 'components/Inventory/TabVendors/_redux/vendorsTypes';
import {
  GET_ACCIDENT,
  GET_ACCIDENTS,
} from 'components/Safety/TabAccidents/_redux/accidentsTypes';
import { AppState } from 'redux/store';
import { setAuthHeaderToken } from 'utils/requests';

import { IMPORT_FILE } from '../importFiles/importFilesTypes';

const types = [
  LOGIN,
  GET_DRIVERS,
  GET_DRIVER,
  UPDATE_DRIVER,
  GET_APPLICANTS_ARCHIVE_LIST,
  GET_APPLICANT,
  UPDATE_APPLICANT,
  REFRESH_USER,
  GET_TRUCKS,
  GET_TRUCK,
  UPDATE_TRUCK,
  GET_EMPLOYEE,
  GET_EMPLOYEES,
  GET_ORDER,
  GET_ORDERS,
  GET_TRAILERS,
  GET_TRAILER,
  UPDATE_TRAILER,
  CREATE_CHATROOM,
  SET_ONLINE,
  SET_OFFLINE,
  GET_BROKER,
  GET_BROKERS,
  GET_TRACKING_TRUCKS,
  GET_FACILITY,
  GET_FACILITIES,
  GET_GEOFENCE,
  GET_GEOFENCES,
  UPDATE_GEOFENCE,
  GET_TOLL,
  GET_TOLLS,
  UPDATE_TOLL,
  GET_LOCATIONS,
  GET_LOCATION_BY_ID,
  CREATE_VENDOR,
  GET_ACCIDENT,
  GET_ACCIDENTS,
  FULL_REG_COMPANY,
  IMPORT_FILE,
];

const setHeaderMiddleware =
  (store: Store<AppState>) => (next: Dispatch) => (action: Action) => {
    const { token } = store.getState().profile;
    if (token && types.includes(action.type)) setAuthHeaderToken(token);

    next(action);
  };

export default setHeaderMiddleware;
