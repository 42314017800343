import {
  CREATE_VENDOR,
  CREATE_VENDOR_SUCCESS,
  CREATE_VENDOR_FAIL,
  GET_VENDORS,
  GET_VENDORS_SUCCESS,
  GET_VENDORS_FAIL,
  SEARCH_VENDOR,
  UPDATE_VENDOR,
  UPDATE_VENDOR_SUCCESS,
  UPDATE_VENDOR_FAIL,
  ICreateVendorAction,
  ICreateVendorSuccessAction,
  ICreateVendorFailAction,
  IUpdateVendorAction,
  IUpdateVendorSuccessAction,
  IUpdateVendorFailAction,
  IClearMainVendorsFormAction,
  ISearchVendorAction,
  UNMOUNT_MAIN_FORM,
  IGetVendorsAction,
  IGetVendorsSuccessAction,
  IGetVendorsFailAction,
  IGetVendorByIdAction,
  IGetVendorByIdSuccessAction,
  IGetVendorByIdFailAction,
  IGetVendorLocationsAction,
  IGetVendorLocationsSuccessAction,
  IGetVendorLocationsFailAction,
  IGetVendorProfileOrdersHistoryAction,
  IGetVendorProfileOrdersHistorySuccessAction,
  IGetVendorProfileOrdersHistoryFailAction,
  IClearCurrentVendorAction,
  GET_VENDOR,
  GET_VENDOR_SUCCESS,
  GET_VENDOR_FAIL,
  GET_VENDOR_LOCATIONS,
  GET_VENDOR_LOCATIONS_SUCCESS,
  GET_VENDOR_LOCATIONS_FAIL,
  GET_VENDOR_PROFILE_ORDERS_HISTORY,
  GET_VENDOR_PROFILE_ORDERS_HISTORY_SUCCESS,
  GET_VENDOR_PROFILE_ORDERS_HISTORY_FAIL,
  CLEAR_CURRENT_VENDOR,
  IRemoveVendorFailAction,
  REMOVE_VENDOR_FAIL,
  REMOVE_VENDOR_SUCCESS,
  IRemoveVendorSuccessAction,
  REMOVE_VENDOR,
  IRemoveVendorAction,
  GET_ALL_VENDORS,
  IGetAllVendorsSuccessAction,
  GET_ALL_VENDORS_SUCCESS,
} from './vendorsTypes';

// CREATE_VENDOR
export const createVendorAction = (
  payload: ICreateVendorAction['payload'],
): ICreateVendorAction => ({
  type: CREATE_VENDOR,
  payload,
});

export const createVendorSuccessAction = (
  payload: ICreateVendorSuccessAction['payload'],
): ICreateVendorSuccessAction => ({
  type: CREATE_VENDOR_SUCCESS,
  payload,
});

export const createVendorFailAction = (
  payload: ICreateVendorFailAction['payload'],
): ICreateVendorFailAction => ({
  type: CREATE_VENDOR_FAIL,
  payload,
});

// REMOVE_VENDOR
export const removeVendorAction = (
  payload: IRemoveVendorAction['payload'],
): IRemoveVendorAction => ({
  type: REMOVE_VENDOR,
  payload,
});
export const removeVendorSuccessAction = (
  payload: IRemoveVendorSuccessAction['payload'],
): IRemoveVendorSuccessAction => ({
  type: REMOVE_VENDOR_SUCCESS,
  payload,
});
export const removeVendorFailAction = (
  payload: IRemoveVendorFailAction['payload'],
): IRemoveVendorFailAction => ({
  type: REMOVE_VENDOR_FAIL,
  payload,
});

// UPDATE_VENDOR
export const updateVendorAction = (
  payload: IUpdateVendorAction['payload'],
): IUpdateVendorAction => ({
  type: UPDATE_VENDOR,
  payload,
});
export const updateVendorSuccessAction = (
  payload: IUpdateVendorSuccessAction['payload'],
): IUpdateVendorSuccessAction => ({
  type: UPDATE_VENDOR_SUCCESS,
  payload,
});
export const updateVendorFailAction = (
  payload: IUpdateVendorFailAction['payload'],
): IUpdateVendorFailAction => ({
  type: UPDATE_VENDOR_FAIL,
  payload,
});

// GET_VENDORS
export const getVendorsAction = (payload: IGetVendorsAction['payload']) => ({
  type: GET_VENDORS,
  payload,
});
export const getAllVendorsAction = () => ({
  type: GET_ALL_VENDORS,
});
export const getVendorsSuccessAction = (
  payload: IGetVendorsSuccessAction['payload'],
) => ({
  type: GET_VENDORS_SUCCESS,
  payload,
});
export const getAllVendorsSuccessAction = (
  payload: IGetAllVendorsSuccessAction['payload'],
) => ({
  type: GET_ALL_VENDORS_SUCCESS,
  payload,
});
export const getVendorsFailAction = (
  payload: IGetVendorsFailAction['payload'],
) => ({
  type: GET_VENDORS_FAIL,
  payload,
});

// SEARCH VENDOR
export const searchVendorAction = (): ISearchVendorAction => ({
  type: SEARCH_VENDOR,
});

// GET_VENDOR_LOCATIONS
export const getVendorLocationsAction = (): IGetVendorLocationsAction => ({
  type: GET_VENDOR_LOCATIONS,
});
export const getVendorLocationsSuccessAction = (
  payload: IGetVendorLocationsSuccessAction['payload'],
) => ({
  type: GET_VENDOR_LOCATIONS_SUCCESS,
  payload,
});
export const getVendorLocationsFailAction = (
  payload: IGetVendorLocationsFailAction['payload'],
) => ({
  type: GET_VENDOR_LOCATIONS_FAIL,
  payload,
});

// UNMOUNT_MAIN_FORM
export const clearMainVendorsFormAction = (): IClearMainVendorsFormAction => ({
  type: UNMOUNT_MAIN_FORM,
});

// GET VENDORS BY ID
export const getVendorByIdAction = (
  payload: IGetVendorByIdAction['payload'],
): IGetVendorByIdAction => ({
  type: GET_VENDOR,
  payload,
});

export const getVendorByIdSuccessAction = (
  payload: IGetVendorByIdSuccessAction['payload'],
): IGetVendorByIdSuccessAction => ({
  type: GET_VENDOR_SUCCESS,
  payload,
});

export const getVendorByIdFailAction = (
  payload: IGetVendorByIdFailAction['payload'],
): IGetVendorByIdFailAction => ({
  type: GET_VENDOR_FAIL,
  payload,
});

// GET VENDOR PROFILE ORDERS HISTORY
export const getVendorProfileOrdersHistoryAction = (
  payload: IGetVendorProfileOrdersHistoryAction['payload'],
): IGetVendorProfileOrdersHistoryAction => ({
  type: GET_VENDOR_PROFILE_ORDERS_HISTORY,
  payload,
});

export const getVendorProfileOrdersHistorySuccessAction = (
  payload: IGetVendorProfileOrdersHistorySuccessAction['payload'],
): IGetVendorProfileOrdersHistorySuccessAction => ({
  type: GET_VENDOR_PROFILE_ORDERS_HISTORY_SUCCESS,
  payload,
});

export const getVendorProfileOrdersHistoryFailAction = (
  payload: IGetVendorProfileOrdersHistoryFailAction['payload'],
): IGetVendorProfileOrdersHistoryFailAction => ({
  type: GET_VENDOR_PROFILE_ORDERS_HISTORY_FAIL,
  payload,
});
// CLEAR CURRENT VENDOR
export const clearCurrentVendorAction = (): IClearCurrentVendorAction => ({
  type: CLEAR_CURRENT_VENDOR,
});
