import { useCallback, useState } from 'react';

const useToggle = (initValue = false) => {
  const [isOpen, setVisibility] = useState(initValue);

  const toggleVisibility = () => {
    setVisibility(prevIsOpen => !prevIsOpen);
  };

  const close = useCallback(() => {
    setVisibility(false);
  }, []);

  const open = useCallback(() => {
    setVisibility(true);
  }, []);

  return {
    isOpen,
    toggleVisibility,
    close,
    open,
  };
};

export default useToggle;
