import {
  AddDeduction,
  EditDeduction,
  IDeductionsTableItem,
} from 'components/Accounting/TabPayrollItems/_models/payrollItemsModels';

import {
  ADD_DEDUCTION,
  CANCEL_DEDUCTION,
  CREATE_ACCOUNT_ENTRY,
  DELETE_ACCOUNT_ENTRY,
  DELETE_DEDUCTION,
  EDIT_DEDUCTION,
  GET_ACCOUNT_ENTRIES,
  GET_ACCOUNT_FUEL,
  GET_DEDUCTION_DETAILS,
  GET_DEDUCTION_DETAILS_SUCCESS,
  GET_DEDUCTION_PAYMENTS,
  GET_DEDUCTION_PAYMENTS_FAIL,
  GET_DEDUCTION_PAYMENTS_SUCCESS,
  GET_DEDUCTIONS,
  GET_DRIVER_FUEL,
  GET_SCHED_DEDUCTION_DRIVERS,
  IAddDeductionAction,
  ICancelDeductionAction,
  ICreateAccountEntryAction,
  IDeleteAccountEntryAction,
  IDeleteDeductionAction,
  IEditDeductionAction,
  IGetAccountEntriesAction,
  IGetAccountFuelAction,
  IGetDeductionDetailsActionSuccess,
  IGetDeductionDetailsRequestAction,
  IGetDeductionPaymentsAction,
  IGetDeductionPaymentsActionFail,
  IGetDeductionPaymentsActionSuccess,
  IGetDeductionsAction,
  IGetDriverFuelAction,
  IGetSchedDeductionDriversAction,
  IUpdateAccountEntryAction,
  UPDATE_ACCOUNT_ENTRY,
} from './payrollItemsTypes';

// Scheduled Deductions
export const getGetSchedDeductionDriversAction = (
  payload?: IGetSchedDeductionDriversAction['payload'],
): IGetSchedDeductionDriversAction => ({
  type: GET_SCHED_DEDUCTION_DRIVERS,
  payload,
});

export const getAccountEntriesAction = (
  entryType: IGetAccountEntriesAction['entryType'],
  params: IGetAccountEntriesAction['params'],
  query?: IGetAccountEntriesAction['query'],
): IGetAccountEntriesAction => ({
  type: GET_ACCOUNT_ENTRIES,
  entryType,
  params,
  query,
});

// Deductions
export const getDeductionsAction = (
  params: IGetDeductionsAction['params'],
  query?: IGetDeductionsAction['query'],
): IGetDeductionsAction => ({
  type: GET_DEDUCTIONS,
  params,
  query,
});

export const getDeductionDetailsAction = (
  id: string,
): IGetDeductionDetailsRequestAction => ({
  type: GET_DEDUCTION_DETAILS,
  payload: { id },
});

export const getDeductionPaymentsAction = (
  deductionId: IGetDeductionPaymentsAction['deductionId'],
  params: IGetDeductionPaymentsAction['params'],
): IGetDeductionPaymentsAction => ({
  type: GET_DEDUCTION_PAYMENTS,
  deductionId,
  params,
});

export const getDeductionPaymentsActionSuccess = (
  payload: IGetDeductionPaymentsActionSuccess['payload'],
): IGetDeductionPaymentsActionSuccess => ({
  type: GET_DEDUCTION_PAYMENTS_SUCCESS,
  payload,
});

export const getDeductionPaymentsActionFail = (
  payload: IGetDeductionPaymentsActionFail['payload'],
): IGetDeductionPaymentsActionFail => ({
  type: GET_DEDUCTION_PAYMENTS_FAIL,
  payload,
});

export const setDeductionDetailsAction = (
  deductionDetails: IDeductionsTableItem | null,
): IGetDeductionDetailsActionSuccess => ({
  type: GET_DEDUCTION_DETAILS_SUCCESS,
  payload: { deductionDetails },
});

export const addDeductionAction = (
  params: AddDeduction,
): IAddDeductionAction => ({
  type: ADD_DEDUCTION,
  params,
});

export const editDeductionAction = (
  params: EditDeduction,
): IEditDeductionAction => ({
  type: EDIT_DEDUCTION,
  params,
});

export const deleteDeductionAction = (
  payload: IDeleteDeductionAction['payload'],
): IDeleteDeductionAction => ({
  type: DELETE_DEDUCTION,
  payload,
});

export const cancelDeductionAction = (
  payload: ICancelDeductionAction['payload'],
): ICancelDeductionAction => ({
  type: CANCEL_DEDUCTION,
  payload,
});

// AccountEntry
export const createAccountEntryAction = (
  payload: ICreateAccountEntryAction['payload'],
): ICreateAccountEntryAction => ({
  type: CREATE_ACCOUNT_ENTRY,
  payload,
});

export const updateAccountEntryAction = (
  payload: IUpdateAccountEntryAction['payload'],
): IUpdateAccountEntryAction => ({
  type: UPDATE_ACCOUNT_ENTRY,
  payload,
});

export const deleteAccountEntryAction = (
  payload: IDeleteAccountEntryAction['payload'],
): IDeleteAccountEntryAction => ({
  type: DELETE_ACCOUNT_ENTRY,
  payload,
});

export const getAccountFuelAction = (
  params: IGetAccountFuelAction['params'],
): IGetAccountFuelAction => ({
  type: GET_ACCOUNT_FUEL,
  params,
});

export const getDriverFuelAction = (
  driverId: IGetDriverFuelAction['driverId'],
  params: IGetDriverFuelAction['params'],
): IGetDriverFuelAction => ({
  type: GET_DRIVER_FUEL,
  driverId,
  params,
});
