import { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
};

const IconGPS: FC<Props> = ({ className, color = 'var(--primaryText)' }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15 1L1.2755 5.84394C0.837648 5.99847 0.82854 6.61439 1.26163 6.7818L7 9L9.21819 14.7384C9.3856 15.1715 10.0015 15.1624 10.1561 14.7245L15 1Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconGPS;
