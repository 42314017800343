import { startSubmit, stopSubmit } from 'redux-form';
import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';

import {
  IChatMessage,
  IChatFile,
} from 'components/Chat/_models/chatMainEntities';
import { fileToFormData } from 'utils/converters';
import api from 'utils/requests';

import {
  changeChatGroupNameAction,
  createChatRoomAction,
  deleteUserFromChatGroupAction,
  getChatPeopleAction,
  getChatRoomAction,
  getChatRoomMessagesAction,
  getChatRoomsAction,
  leaveGroupChatAction,
  markReadMessagesAction,
  sendMessageAction,
  uploadChatFilesAction,
  deleteChatFilesAction,
  setPinMessageAction,
  getMessagesWithPinAction,
  getChatUserProfileAction,
  setReactionMessageAction,
  addUsersForChatGroupAction,
  setForwardMessageDataAction,
  forwardMessageAction,
} from './chatActions';
import { chatSelector } from './chatSelectors';
import {
  CHANGE_GROUP_CHAT_NAME,
  CHANGE_GROUP_CHAT_NAME_FAIL,
  CHANGE_GROUP_CHAT_NAME_SUCCESS,
  CREATE_CHATROOM,
  CREATE_CHATROOM_FAIL,
  CREATE_CHATROOM_SUCCESS,
  DELETE_CHAT_FILES,
  DELETE_CHAT_FILES_FAIL,
  DELETE_CHAT_FILES_SUCCESS,
  DELETE_USER_FROM_GROUP_CHAT,
  DELETE_USER_FROM_GROUP_CHAT_FAIL,
  DELETE_USER_FROM_GROUP_CHAT_SUCCESS,
  GET_CHAT_PEOPLE,
  GET_CHAT_PEOPLE_FAIL,
  GET_CHAT_PEOPLE_SUCCESS,
  GET_CHATROOM,
  GET_CHATROOM_FAIL,
  GET_CHATROOM_MESSAGES,
  GET_CHATROOM_MESSAGES_FAIL,
  GET_CHATROOM_MESSAGES_SUCCESS,
  GET_CHATROOM_SUCCESS,
  GET_CHATROOMS,
  GET_CHATROOMS_FAIL,
  GET_CHATROOMS_SUCCESS,
  IChangeGroupChatNameFail,
  IChangeGroupChatNameSuccess,
  IDeleteUserFromGroupChatFail,
  IDeleteUserFromGroupChatSuccess,
  LEAVE_GROUP_CHAT,
  LEAVE_GROUP_CHAT_FAIL,
  LEAVE_GROUP_CHAT_SUCCESS,
  MARK_READ_MESSAGES,
  MARK_READ_MESSAGES_FAIL,
  MARK_READ_MESSAGES_SUCCESS,
  FORWARD_MESSAGE,
  SEND_MESSAGE,
  SEND_MESSAGE_FAIL,
  SEND_MESSAGE_SUCCESS,
  UPLOAD_CHAT_FILES,
  UPLOAD_CHAT_FILES_FAIL,
  UPLOAD_CHAT_FILES_SUCCESS,
  SET_PIN_MESSAGE,
  SET_PIN_MESSAGE_SUCCESS,
  SET_PIN_MESSAGE_FAIL,
  ISetPinMessageSuccess,
  ISetPinMessageFail,
  DELETE_PIN_MESSAGE,
  DELETE_PIN_MESSAGE_SUCCESS,
  DELETE_PIN_MESSAGE_FAIL,
  IDeletePinMessageSuccess,
  IDeletePinMessageFail,
  GET_MESSAGES_WITH_PIN,
  GET_MESSAGES_WITH_PIN_SUCCESS,
  GET_MESSAGES_WITH_PIN_FAIL,
  IGetMessagesWithPinSuccess,
  IGetMessagesWithPinFail,
  GET_CHAT_USER_PROFILE,
  GET_CHAT_USER_PROFILE_SUCCESS,
  GET_CHAT_USER_PROFILE_FAIL,
  IGetChatUserProfileSuccess,
  IGetChatUserProfileFail,
  SET_REACTION_MESSAGE,
  ILeaveGroupChatSuccess,
  ILeaveGroupChatFail,
  ADD_USERS_FOR_CHAT_GROUP,
  ADD_USERS_FOR_CHAT_GROUP_SUCCESS,
  ADD_USERS_FOR_CHAT_GROUP_FAIL,
  IAddUsersForChatGroupSuccess,
  IAddUsersForChatGroupFail,
  IGetChatroomSuccess,
  IGetChatroomFail,
} from './chatTypes';

function* createChatroomSaga({
  params,
  callback,
}: ReturnType<typeof createChatRoomAction>) {
  try {
    yield put(startSubmit('createChat'));
    const { data } = yield call(() => api.post('/v1/chatrooms', params));
    yield put({ type: CREATE_CHATROOM_SUCCESS, payload: data });
    yield put(stopSubmit('createChat'));
    if (callback) callback(data.chatroom.id);
  } catch (error) {
    yield put({ type: CREATE_CHATROOM_FAIL, payload: error });
    yield put(stopSubmit('createChat'));
  }
}

function* addUsersForChatGroupSaga({
  payload: {
    callback,
    data: { usersIds, chatroomId },
  },
}: ReturnType<typeof addUsersForChatGroupAction>) {
  for (const userId of usersIds) {
    try {
      const { data } = yield call(() =>
        api.patch(`/v1/chatrooms/${chatroomId}/users/${userId}`),
      );
      yield put<IAddUsersForChatGroupSuccess>({
        type: ADD_USERS_FOR_CHAT_GROUP_SUCCESS,
        payload: data.chat_user,
      });

      if (callback) callback();
    } catch (e) {
      if (api.isAxiosError(e))
        yield put<IAddUsersForChatGroupFail>({
          type: ADD_USERS_FOR_CHAT_GROUP_FAIL,
          payload: e,
        });
    }
  }
}

function* getChatroomsSaga({
  page,
  query,
  callback,
}: ReturnType<typeof getChatRoomsAction>) {
  try {
    const { data } = yield call(() =>
      api.get(`/v1/chatrooms?page=${page || 1}&per_page=20${query || ''}`),
    );
    yield put({ type: GET_CHATROOMS_SUCCESS, payload: data });
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({ type: GET_CHATROOMS_FAIL, payload: e });
  }
}

function* getChatroomSaga({
  payload: {
    callback,
    data: { chatroomId, mainChatView },
  },
}: ReturnType<typeof getChatRoomAction>) {
  try {
    const { data } = yield call(() => api.get(`/v1/chatrooms/${chatroomId}`));
    const { forwardMessageData } = yield select(chatSelector);

    if (forwardMessageData && forwardMessageData.forwardTo !== chatroomId) {
      yield put(setForwardMessageDataAction({ data: null }));
    }

    yield put<IGetChatroomSuccess>({
      type: GET_CHATROOM_SUCCESS,
      payload: { chatRoom: data.chatroom, mainChatView },
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetChatroomFail>({ type: GET_CHATROOM_FAIL, payload: e });
  }
}

function* getChatroomMessagesSaga({
  id,
  page,
  callback,
}: ReturnType<typeof getChatRoomMessagesAction>) {
  try {
    const { data } = yield call(() =>
      api.get(`/v1/chatrooms/${id}/messages?page=${page || 1}&per_page=20`),
    );
    yield put({ type: GET_CHATROOM_MESSAGES_SUCCESS, payload: data });
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({ type: GET_CHATROOM_MESSAGES_FAIL, payload: e });
  }
}

function* sendMessageSaga({
  payload: {
    id,
    text,
    parent_message_id,
    parent_message_relation,
    files,
    callback,
  },
}: ReturnType<typeof sendMessageAction>) {
  const { files: filesFromState } = yield select(chatSelector);
  const filesToSend: IChatFile[] = files || filesFromState;

  try {
    const { data } = yield call(() =>
      api.post(`/v1/chatrooms/${id}/messages`, {
        chatroom_message: {
          text,
          parent_message_id,
          parent_message_relation,
          existing_files_ids: filesToSend.map(item => item?.file?.id),
        },
      }),
    );
    yield put({ type: SEND_MESSAGE_SUCCESS, payload: data });
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({
        type: SEND_MESSAGE_FAIL,
        payload: { error: e, text, chatroom_id: id, files: filesToSend },
      });
  }
}

function* markReadMessagesSaga({
  ids,
  chatRoomId,
  callback,
}: ReturnType<typeof markReadMessagesAction>) {
  try {
    const { data } = yield call(() =>
      api.put(`/v1/chatrooms/${chatRoomId}/messages/read`, {
        read_message_ids: ids,
      }),
    );
    yield put({ type: MARK_READ_MESSAGES_SUCCESS, payload: data });
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({ type: MARK_READ_MESSAGES_FAIL, payload: e });
  }
}

function* getChatPeopleSaga({
  query,
  callback,
}: ReturnType<typeof getChatPeopleAction>) {
  try {
    const { data } = yield call(() =>
      api.get(`/v1/chatrooms/employees${query || ''}`),
    );
    yield put({ type: GET_CHAT_PEOPLE_SUCCESS, payload: data });
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put({ type: GET_CHAT_PEOPLE_FAIL, payload: e });
  }
}

function* uploadChatFilesSaga({
  files,
  callback,
}: ReturnType<typeof uploadChatFilesAction>) {
  for (const file of files) {
    yield call(function* foo() {
      try {
        const { data } = yield call(() =>
          api.post(
            '/v1/chatrooms/messages/files',
            fileToFormData(file, 'chats_messages_file[file]'),
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            },
          ),
        );
        yield put({ type: UPLOAD_CHAT_FILES_SUCCESS, payload: data });
      } catch (e) {
        if (api.isAxiosError(e))
          yield put({ type: UPLOAD_CHAT_FILES_FAIL, payload: e });
      }
    });
    if (callback) callback();
  }
}

function* deleteChatFilesSaga({
  ids,
  callback,
}: ReturnType<typeof deleteChatFilesAction>) {
  for (const id of ids) {
    yield call(function* foo() {
      try {
        yield call(() => api.delete(`/v1/chatrooms/messages/files/${id}`));
        yield put({ type: DELETE_CHAT_FILES_SUCCESS, payload: id });
        if (callback) callback();
      } catch (e) {
        if (api.isAxiosError(e))
          yield put({ type: DELETE_CHAT_FILES_FAIL, payload: e });
      }
    });
  }
  if (callback) callback();
}

function* setReactionMessageSaga({
  payload: {
    data: { chatroomId, id },
    callback,
  },
}: ReturnType<typeof setReactionMessageAction>) {
  try {
    const { data } = yield call(() =>
      api.post(`/v1/chatrooms/${chatroomId}/messages/${id}/pin`),
    );

    yield put<ISetPinMessageSuccess>({
      type: SET_PIN_MESSAGE_SUCCESS,
      payload: data.message_id,
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<ISetPinMessageFail>({ type: SET_PIN_MESSAGE_FAIL, payload: e });
  }
}

// EDIT PIN MESSAGE
function* setPinMessageSaga({
  payload: {
    data: { chatroomId, id },
    callback,
  },
}: ReturnType<typeof setPinMessageAction>) {
  try {
    const { data } = yield call(() =>
      api.post(`/v1/chatrooms/${chatroomId}/messages/${id}/pin`),
    );

    yield put<ISetPinMessageSuccess>({
      type: SET_PIN_MESSAGE_SUCCESS,
      payload: data.message_id,
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<ISetPinMessageFail>({ type: SET_PIN_MESSAGE_FAIL, payload: e });
  }
}

// DELETE PIN MESSAGE
function* deletePinMessageSaga({
  payload: {
    data: { chatroomId, id },
    callback,
  },
}: ReturnType<typeof setPinMessageAction>) {
  try {
    yield call(() =>
      api.delete(`/v1/chatrooms/${chatroomId}/messages/${id}/pin`),
    );
    yield put<IDeletePinMessageSuccess>({ type: DELETE_PIN_MESSAGE_SUCCESS });
    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IDeletePinMessageFail>({
        type: DELETE_PIN_MESSAGE_FAIL,
        payload: e,
      });
  }
}

// GET PIN MESSAGE
function* getMessagesWithPinSaga({
  payload: {
    data: { chatroomId, id },
    callback,
  },
}: ReturnType<typeof getMessagesWithPinAction>) {
  try {
    const { data } = yield call(() =>
      api.get(`/v1/chatrooms/${chatroomId}/messages?per_page=5&from_id=${id}`),
    );
    yield put<IGetMessagesWithPinSuccess>({
      type: GET_MESSAGES_WITH_PIN_SUCCESS,
      payload: data.messages.length ? data.messages.reverse() : [],
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetMessagesWithPinFail>({
        type: GET_MESSAGES_WITH_PIN_FAIL,
        payload: e,
      });
  }
}

// GET_CHAT_USER_PROFILE
function* getChatUserProfileSaga({
  payload: {
    data: { chatroomId, userId },
    callback,
  },
}: ReturnType<typeof getChatUserProfileAction>) {
  try {
    const { data } = yield call(() =>
      api.get(`/v1/chatrooms/${chatroomId}/users/${userId}`),
    );
    yield put<IGetChatUserProfileSuccess>({
      type: GET_CHAT_USER_PROFILE_SUCCESS,
      payload: { ...data.chat_user, id: userId, chatroomId: +chatroomId },
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetChatUserProfileFail>({
        type: GET_CHAT_USER_PROFILE_FAIL,
        payload: e,
      });
  }
}

// DELETE_USER_FROM_GROUP_CHAT
function* deleteUserFromChatGroupSaga({
  payload: {
    data: { chatroomId, chatUserId },
    callback,
  },
}: ReturnType<typeof deleteUserFromChatGroupAction>) {
  try {
    yield call(() =>
      api.delete(`/v1/chatrooms/${chatroomId}/users/${chatUserId}`),
    );
    yield put<IDeleteUserFromGroupChatSuccess>({
      type: DELETE_USER_FROM_GROUP_CHAT_SUCCESS,
      payload: { chatroomId, chatUserId },
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IDeleteUserFromGroupChatFail>({
        type: DELETE_USER_FROM_GROUP_CHAT_FAIL,
        payload: e,
      });
  }
}

// CHANGE_GROUP_CHAT_NAME
function* changeChatGroupNameSaga({
  payload: {
    data: { chatroomId, groupName },
    callback,
  },
}: ReturnType<typeof changeChatGroupNameAction>) {
  try {
    yield call(() =>
      api.patch(`/v1/chatrooms/${chatroomId}`, {
        chatroom: { name: groupName },
      }),
    );

    yield put<IChangeGroupChatNameSuccess>({
      type: CHANGE_GROUP_CHAT_NAME_SUCCESS,
      payload: { groupName, chatroomId },
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IChangeGroupChatNameFail>({
        type: CHANGE_GROUP_CHAT_NAME_FAIL,
        payload: e,
      });
  }
}

// LEAVE_GROUP_CHAT
function* leaveGroupChatSaga({
  payload: {
    data: { chatroomId },
    callback,
  },
}: ReturnType<typeof leaveGroupChatAction>) {
  try {
    yield call(() => api.delete(`/v1/chatrooms/${chatroomId}/users/me`));

    yield put<ILeaveGroupChatSuccess>({
      type: LEAVE_GROUP_CHAT_SUCCESS,
      payload: { chatroomId },
    });

    if (callback) callback();
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<ILeaveGroupChatFail>({
        type: LEAVE_GROUP_CHAT_FAIL,
        payload: e,
      });
  }
}

function* forwardMessageSaga({
  payload: { forwardTo, messageId, callback },
}: ReturnType<typeof forwardMessageAction>) {
  const { messages, room } = yield select(chatSelector);
  const message = messages.find(({ id }: IChatMessage) => id === messageId);

  yield put(
    setForwardMessageDataAction({
      data: { forwardTo, message },
    }),
  );

  if (callback) callback();

  if (forwardTo !== room.id) {
    yield put(getChatRoomAction({ data: { chatroomId: forwardTo } }));
  }
}

export default function* chatSaga() {
  yield takeLatest(CREATE_CHATROOM, createChatroomSaga);
  yield takeLatest(ADD_USERS_FOR_CHAT_GROUP, addUsersForChatGroupSaga);
  yield takeLatest(GET_CHATROOMS, getChatroomsSaga);
  yield takeLatest(GET_CHATROOM, getChatroomSaga);
  yield takeLatest(SET_REACTION_MESSAGE, setReactionMessageSaga);
  yield takeLatest(GET_CHATROOM_MESSAGES, getChatroomMessagesSaga);
  yield takeLatest(FORWARD_MESSAGE, forwardMessageSaga);
  yield takeLatest(SEND_MESSAGE, sendMessageSaga);
  yield takeLatest(MARK_READ_MESSAGES, markReadMessagesSaga);
  yield takeLatest(GET_CHAT_PEOPLE, getChatPeopleSaga);
  yield takeLatest(UPLOAD_CHAT_FILES, uploadChatFilesSaga);
  yield takeLatest(DELETE_CHAT_FILES, deleteChatFilesSaga);
  yield takeLatest(SET_PIN_MESSAGE, setPinMessageSaga);
  yield takeLatest(DELETE_PIN_MESSAGE, deletePinMessageSaga);
  yield takeLatest(GET_MESSAGES_WITH_PIN, getMessagesWithPinSaga);
  yield takeLatest(GET_CHAT_USER_PROFILE, getChatUserProfileSaga);
  yield takeLeading(DELETE_USER_FROM_GROUP_CHAT, deleteUserFromChatGroupSaga);
  yield takeLatest(CHANGE_GROUP_CHAT_NAME, changeChatGroupNameSaga);
  yield takeLatest(LEAVE_GROUP_CHAT, leaveGroupChatSaga);
}
