import { AxiosError, AxiosRequestConfig } from 'axios';

import { IPagination } from 'components/_common/Navigation/Pagination/_redux/paginationTypes';
import { IAccident } from 'components/Safety/TabAccidents/_models/accidentModels';
import {
  IUploadFilesAction,
  IUploadFilesActionFail,
  IUploadFilesActionSuccess,
  IDeleteFileAction,
  IDeleteFileActionSuccess,
  IDeleteFileActionFail,
} from 'redux/_common/commonTypes';
import { ILogout } from 'redux/_common/models';

// CREATE ACCIDENT
export const CREATE_ACCIDENT = '[@@Accident] CREATE_ACCIDENT';
export const CREATE_ACCIDENT_SUCCESS = '[@@Accident] CREATE_ACCIDENT_SUCCESS';
export const CREATE_ACCIDENT_FAIL = '[@@Accident] CREATE_ACCIDENT_FAIL';

export interface ICreateAccidentAction {
  type: typeof CREATE_ACCIDENT;
  payload: {
    accident: IAccident;
    callback?: (accident: IAccident) => void;
  };
}
export interface ICreateAccidentActionSuccess {
  type: typeof CREATE_ACCIDENT_SUCCESS;
  payload: IAccident;
}
export interface ICreateAccidentActionFail {
  type: typeof CREATE_ACCIDENT_FAIL;
  payload: AxiosError;
}

// GET ACCIDENT
export const GET_ACCIDENT = '[@@Accident] GET_ACCIDENT';
export const GET_ACCIDENT_SUCCESS = '[@@Accident] GET_ACCIDENT_SUCCESS';
export const GET_ACCIDENT_FAIL = '[@@Accident] GET_ACCIDENT_FAIL';

export interface IGetAccidentAction {
  type: typeof GET_ACCIDENT;
  payload: {
    id: number;
    callback?: (accident: IAccident) => void;
  };
}
export interface IGetAccidentActionSuccess {
  type: typeof GET_ACCIDENT_SUCCESS;
  payload: {
    accident: IAccident;
  };
}
export interface IGetAccidentActionFail {
  type: typeof GET_ACCIDENT_FAIL;
  payload: AxiosError;
}

// GET ACCIDENTS
export const GET_ACCIDENTS = '[@@Accident] GET_ACCIDENTS';
export const GET_ACCIDENTS_SUCCESS = '[@@Accident] GET_ACCIDENTS_SUCCESS';
export const GET_ACCIDENTS_FAIL = '[@@Accident] GET_ACCIDENTS_FAIL';

export interface IGetAccidentsAction {
  type: typeof GET_ACCIDENTS;
  params?: AxiosRequestConfig['params'];
  query?: string;
  callback?: (accidents: IAccident[]) => void;
}
export interface IGetAccidentsActionSuccess {
  type: typeof GET_ACCIDENTS_SUCCESS;
  payload: {
    accidents: IAccident[];
    claim_total_max: string;
    pagination: IPagination;
  };
}
export interface IGetAccidentsActionFail {
  type: typeof GET_ACCIDENTS_FAIL;
  payload: AxiosError;
}

// UPDATE ACCIDENT
export const UPDATE_ACCIDENT = '[@@Accident] UPDATE_ACCIDENT';
export const UPDATE_ACCIDENT_SUCCESS = '[@@Accident] UPDATE_ACCIDENT_SUCCESS';
export const UPDATE_ACCIDENT_FAIL = '[@@Accident] UPDATE_OACCIDENT_FAIL';

export interface IUpdateAccidentAction {
  payload: {
    accident: Partial<IAccident>;
    callback?: (accident: IAccident) => void;
  };
  type: typeof UPDATE_ACCIDENT;
}
export interface IUpdateAccidentActionSuccess {
  type: typeof UPDATE_ACCIDENT_SUCCESS;
  payload: IAccident;
}
export interface IUpdateAccidentActionFail {
  type: typeof UPDATE_ACCIDENT_FAIL;
  payload: AxiosError;
}

// DELETE ACCIDENT
export const DELETE_ACCIDENT = '[@@Accident] DELETE_ACCIDENT';
export const DELETE_ACCIDENT_SUCCESS = '[@@Accident] DELETE_ACCIDENT_SUCCESS';
export const DELETE_ACCIDENT_FAIL = '[@@Accident] DELETE_ACCIDENT_FAIL';

export interface IDeleteAccidentAction {
  type: typeof DELETE_ACCIDENT;
  payload: {
    id: number;
    callback?: (id: number) => void;
  };
}
export interface IDeleteAccidentActionSuccess {
  type: typeof DELETE_ACCIDENT_SUCCESS;
  id: number;
}
export interface IDeleteAccidentActionFail {
  type: typeof DELETE_ACCIDENT_FAIL;
  payload: AxiosError;
}

// CREATE_ACCIDENT ACTIVITY ITEM
export const CREATE_ACCIDENT_ACTIVITY_ITEM =
  '[@@Accident] CREATE_ACCIDENT_ACTIVITY_ITEM';
export const CREATE_ACCIDENT_ACTIVITY_ITEM_SUCCESS =
  '[@@Accident] CREATE_ACCIDENT_ACTIVITY_ITEM_SUCCESS';
export const CREATE_ACCIDENT_ACTIVITY_ITEM_FAIL =
  '[@@Accident] CREATE_ACCIDENT_ACTIVITY_ITEM_FAIL';

export interface ICreateAccidentActivityItemAction {
  type: typeof CREATE_ACCIDENT_ACTIVITY_ITEM;
  payload: {
    data: {
      id: number | string;
      log_message: string;
    };
    callback?: () => void;
  };
}
export interface ICreateAccidentActivityItemActionSuccess {
  type: typeof CREATE_ACCIDENT_ACTIVITY_ITEM_SUCCESS;
}
export interface ICreateAccidentActivityItemActionFail {
  type: typeof CREATE_ACCIDENT_ACTIVITY_ITEM_FAIL;
  payload: AxiosError;
}

// GET ACCIDENT
export const GET_ACCIDENT_ACTIVITY = '[@@Accident] GET_ACCIDENT_ACTIVITY';
export const GET_ACCIDENT_ACTIVITY_SUCCESS =
  '[@@Accident] GET_ACCIDENT_ACTIVITY_SUCCESS';
export const GET_ACCIDENT_ACTIVITY_FAIL =
  '[@@Accident] GET_ACCIDENT_ACTIVITY_FAIL';

export interface IGetAccidentActivityAction {
  type: typeof GET_ACCIDENT_ACTIVITY;
  payload: {
    id: number;
  };
}
export interface IGetAccidentActivityActionSuccess {
  type: typeof GET_ACCIDENT_ACTIVITY_SUCCESS;
  payload: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    logs: any[];
  };
}
export interface IGetAccidentActivityActionFail {
  type: typeof GET_ACCIDENT_ACTIVITY_FAIL;
  payload: AxiosError;
}

// SEND ACCIDENT PAYMENT
export const SEND_ACCIDENT_PAYMENT = '[@@Accident] SEND_ACCIDENT_PAYMENT';
export const SEND_ACCIDENT_PAYMENT_SUCCESS =
  '[@@Accident] SEND_ACCIDENT_PAYMENT_SUCCESS';
export const SEND_ACCIDENT_PAYMENT_FAIL =
  '[@@Accident] SEND_ACCIDENT_PAYMENT_FAIL';

export interface ISendAccidentPaymentAction {
  type: typeof SEND_ACCIDENT_PAYMENT;
  payload: {
    id: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fleets_accidents_payment: any;
    callback?: () => void;
  };
}
export interface ISendAccidentPaymentActionSuccess {
  type: typeof SEND_ACCIDENT_PAYMENT_SUCCESS;
}
export interface ISendAccidentPaymentActionFail {
  type: typeof SEND_ACCIDENT_PAYMENT_FAIL;
  payload: AxiosError;
}

// UPLOAD ACCIDENT FILES
export const UPLOAD_ACCIDENT_FILES = '[@@Accident] UPLOAD_ACCIDENT_FILES';
export const UPLOAD_ACCIDENT_FILES_SUCCESS =
  '[@@Accident] UPLOAD_ACCIDENT_FILES_SUCCESS';
export const UPLOAD_ACCIDENT_FILES_FAIL =
  '[@@Accident] UPLOAD_ACCIDENT_FILES_FAIL';

export interface IUploadAccidentFilesAction extends IUploadFilesAction {
  type: typeof UPLOAD_ACCIDENT_FILES;
}
export interface IUploadAccidentFilesActionSuccess
  extends IUploadFilesActionSuccess {
  type: typeof UPLOAD_ACCIDENT_FILES_SUCCESS;
}
export interface IUploadAccidentFilesActionFail extends IUploadFilesActionFail {
  type: typeof UPLOAD_ACCIDENT_FILES_FAIL;
}

// UPLOAD ACCIDENT PAYMENT FILES
export const UPLOAD_ACCIDENT_PAYMENT_FILES =
  '[@@Accident] UPLOAD_ACCIDENT_PAYMENT_FILES';
export const UPLOAD_ACCIDENT_PAYMENT_FILES_SUCCESS =
  '[@@Accident] UPLOAD_ACCIDENT_PAYMENT_FILES_SUCCESS';
export const UPLOAD_ACCIDENT_PAYMENT_FILES_FAIL =
  '[@@Accident] UPLOAD_ACCIDENT_PAYMENT_FILES_FAIL';

export interface IUploadAccidentPaymentFilesAction extends IUploadFilesAction {
  type: typeof UPLOAD_ACCIDENT_PAYMENT_FILES;
}
export interface IUploadAccidentPaymentFilesActionSuccess
  extends IUploadFilesActionSuccess {
  type: typeof UPLOAD_ACCIDENT_PAYMENT_FILES_SUCCESS;
}
export interface IUploadAccidentPaymentFilesActionFail
  extends IUploadFilesActionFail {
  type: typeof UPLOAD_ACCIDENT_PAYMENT_FILES_FAIL;
}

// DELETE ACCIDENT FILES
export const DELETE_ACCIDENT_FILES = '[@@Accident] DELETE_ACCIDENT_FILES';
export const DELETE_ACCIDENT_FILES_SUCCESS =
  '[@@Accident] DELETE_ACCIDENT_FILES_SUCCESS';
export const DELETE_ACCIDENT_FILES_FAIL =
  '[@@Accident] DELETE_ACCIDENT_FILES_FAIL';

export interface IDeleteAccidentFilesAction extends IDeleteFileAction {
  type: typeof DELETE_ACCIDENT_FILES;
}
export interface IDeleteAccidentFilesActionSuccess
  extends IDeleteFileActionSuccess {
  type: typeof DELETE_ACCIDENT_FILES_SUCCESS;
}
export interface IDeleteAccidentFilesActionFail extends IDeleteFileActionFail {
  type: typeof DELETE_ACCIDENT_FILES_FAIL;
}

// UNMOUNT_ACCIDENT_PAYMENT_FORM
export const UNMOUNT_ACCIDENT_PAYMENT_FORM =
  '[@@Accident] UNMOUNT_ACCIDENT_PAYMENT_FORM';

export interface IUnmountAccidentPaymentFormAction {
  type: typeof UNMOUNT_ACCIDENT_PAYMENT_FORM;
}

// UNMOUNT_ACCIDENT_PAYMENT_FORM
export const RESET_ACCIDENT_FILES = '[@@Accident] RESET_ACCIDENT_FILES';

export interface IResetAccidentFilesAction {
  type: typeof RESET_ACCIDENT_FILES;
}

export type accidentActionsType =
  | ICreateAccidentAction
  | ICreateAccidentActionSuccess
  | ICreateAccidentActionFail
  | IGetAccidentsAction
  | IGetAccidentsActionSuccess
  | IGetAccidentsActionFail
  | IUpdateAccidentAction
  | IUpdateAccidentActionSuccess
  | IUpdateAccidentActionFail
  | IGetAccidentAction
  | IGetAccidentActionSuccess
  | IGetAccidentActionFail
  | ISendAccidentPaymentAction
  | ISendAccidentPaymentActionSuccess
  | ISendAccidentPaymentActionFail
  | IUploadAccidentFilesAction
  | IUploadAccidentFilesActionSuccess
  | IUploadAccidentFilesActionFail
  | IUploadAccidentPaymentFilesAction
  | IUploadAccidentPaymentFilesActionSuccess
  | IUploadAccidentPaymentFilesActionFail
  | IDeleteAccidentFilesAction
  | IDeleteAccidentFilesActionSuccess
  | IDeleteAccidentFilesActionFail
  | IGetAccidentActivityAction
  | IGetAccidentActivityActionSuccess
  | IGetAccidentActivityActionFail
  | ICreateAccidentActivityItemAction
  | ICreateAccidentActivityItemActionSuccess
  | ICreateAccidentActivityItemActionFail
  | IDeleteAccidentAction
  | IDeleteAccidentActionSuccess
  | IDeleteAccidentActionFail
  | IUnmountAccidentPaymentFormAction
  | IResetAccidentFilesAction
  | ILogout;
