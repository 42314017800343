import { IPagination } from 'components/_common/Navigation/Pagination/_redux/paginationTypes';
import { LOGOUT } from 'components/Auth/_redux/authTypes';
import { ITrailerTrackingData } from 'components/Fleet/TabTrailers/_models/trailerMapModels';
import { IWithError, IWithLoaded, IWithPending } from 'utils/models';

import { ITrailer, ITrailerUploadedFile } from '../_models/trailerModel';
import {
  CLEAR_TRAILERS_TRACKING_DATA,
  CREATE_TRAILER,
  CREATE_TRAILER_FAIL,
  CREATE_TRAILER_SUCCESS,
  DELETE_LOCAL_CLAIM_PHOTO,
  DELETE_TRAILER,
  DELETE_TRAILER_CLAIM_PHOTO_FAIL,
  DELETE_TRAILER_CLAIM_PHOTO_SUCCESS,
  DELETE_TRAILER_FAIL,
  DELETE_TRAILER_FILE,
  DELETE_TRAILER_FILE_FAIL,
  DELETE_TRAILER_FILE_SUCCESS,
  DELETE_TRAILER_SUCCESS,
  GET_TRAILER,
  GET_TRAILER_FAIL,
  GET_TRAILER_SUCCESS,
  GET_TRAILERS,
  GET_TRAILERS_FAIL,
  GET_TRAILERS_SUCCESS,
  GET_TRAILERS_TRACKING_DATA,
  GET_TRAILERS_TRACKING_DATA_FAIL,
  GET_TRAILERS_TRACKING_DATA_SUCCESS,
  TOGGLE_TRAILER_PIN,
  TOGGLE_TRAILER_PIN_FAIL,
  TOGGLE_TRAILER_PIN_SUCCESS,
  trailersTypes,
  UPDATE_TRAILER,
  UPDATE_TRAILER_FAIL,
  UPDATE_TRAILER_SUCCESS,
  UPLOAD_TRAILER_CLAIM_PHOTO,
  UPLOAD_TRAILER_CLAIM_PHOTO_FAIL,
  UPLOAD_TRAILER_CLAIM_PHOTO_SUCCESS,
  UPLOAD_TRAILER_FILES,
  UPLOAD_TRAILER_FILES_FAIL,
  UPLOAD_TRAILER_FILES_SUCCESS,
  CLEAR_TRAILER,
} from './trailersTypes';

export interface ITrailersState extends IWithPending, IWithLoaded, IWithError {
  list: ITrailer[];
  files: ITrailerUploadedFile[];
  trackingData: ITrailerTrackingData[];
  currentTrailer: ITrailer | null;
  pagination: IPagination | null;
  statistic: { [key: string]: number } | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  claimPhotos: any;
}

const initialState: ITrailersState = {
  list: [],
  files: [],
  trackingData: [],
  currentTrailer: null,
  pagination: null,
  statistic: null,
  _pending: false,
  _loaded: false,
  _error: null,
  claimPhotos: [],
};

const trailersReducers = (
  state = initialState,
  action: trailersTypes,
): ITrailersState => {
  switch (action.type) {
    // CREATE TRAILER
    case CREATE_TRAILER:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case CREATE_TRAILER_SUCCESS:
      return {
        ...state,
        list: [],
        pagination: null,
        currentTrailer: null,
        files: [],
        _loaded: true,
        _pending: false,
      };
    case CREATE_TRAILER_FAIL:
      return {
        ...state,
        _error: action.payload,
        _loaded: false,
        _pending: false,
      };

    // GET TRAILERS
    case GET_TRAILERS:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case GET_TRAILERS_SUCCESS:
      return {
        ...state,
        list: action.payload.trailers || [],
        pagination: action.payload.pagination,
        statistic: action.payload.statistic,
        files: [],
        claimPhotos: [],
        _loaded: true,
        _pending: false,
      };
    case GET_TRAILERS_FAIL:
      return {
        ...state,
        _error: action.payload,
        _loaded: false,
        _pending: false,
      };

    // GET TRAILER BY ID
    case GET_TRAILER:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case GET_TRAILER_SUCCESS:
      return {
        ...state,
        claimPhotos: [],
        currentTrailer: action.payload.trailer,
        files: action.payload.trailer.files,
        _loaded: true,
        _pending: false,
      };
    case GET_TRAILER_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };

    case CLEAR_TRAILER:
      return {
        ...state,
        files: [],
        currentTrailer: null,
      };

    // UPDATE TRAILER BY ID
    case UPDATE_TRAILER:
      return { ...state, _error: null, _pending: true, _loaded: false };
    case UPDATE_TRAILER_SUCCESS:
      return {
        ...state,
        currentTrailer: action.payload.trailer,
        _pending: false,
        _loaded: true,
      };
    case UPDATE_TRAILER_FAIL:
      return {
        ...state,
        _error: action.payload,
        _pending: false,
        _loaded: false,
      };

    // DELETE TRAILER BY ID
    case DELETE_TRAILER:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case DELETE_TRAILER_SUCCESS:
      return {
        ...state,
        currentTrailer: null,
        _loaded: true,
        _pending: false,
      };
    case DELETE_TRAILER_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };

    // UPLOAD CLAIM PHOTO
    case UPLOAD_TRAILER_CLAIM_PHOTO:
      return {
        ...state,
        claimPhotos: [
          ...state.claimPhotos,
          ...action.payload.files.map(item => ({
            ...item,
            loading: true,
          })),
        ],
      };
    case UPLOAD_TRAILER_CLAIM_PHOTO_SUCCESS:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        claimPhotos: state.claimPhotos.map((file: any, index: number) =>
          index ===
          state.claimPhotos.findIndex(
            ({ loading }: { loading: boolean }) => loading,
          )
            ? { ...action.payload.file, loading: false }
            : file,
        ),
      };
    case UPLOAD_TRAILER_CLAIM_PHOTO_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // Delete claim photo
    case DELETE_TRAILER_CLAIM_PHOTO_SUCCESS: {
      let photos = state.claimPhotos ? { ...state.claimPhotos } : null;
      if (
        photos &&
        action.payload.itemId &&
        action.payload.itemId === photos.id
      ) {
        photos = photos.filter(
          (photo: { id: string | number }) =>
            photo.id !== action.payload.fileId,
        );
      }
      return {
        ...state,
        claimPhotos: state.claimPhotos.filter(
          (file: { id: string | number }) => file.id !== action.payload.fileId,
        ),
      };
    }
    case DELETE_TRAILER_CLAIM_PHOTO_FAIL:
      return {
        ...state,
        _error: action.payload,
      };
    // DELETE LOCAL CLAIM PHOTO
    case DELETE_LOCAL_CLAIM_PHOTO:
      return {
        ...state,
        claimPhotos: [],
      };

    // UPLOAD TRAILER FILES
    case UPLOAD_TRAILER_FILES:
      return {
        ...state,
        files: [
          ...state.files,
          ...action.payload.files.map(
            file =>
              ({
                ...file,
                loading: true,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } as any),
          ),
        ],
      };
    case UPLOAD_TRAILER_FILES_SUCCESS:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        files: state.files.map((file: any, index: number) =>
          index === state.files.findIndex(({ loading }) => loading)
            ? { ...action.payload, loading: false }
            : file,
        ),
      };
    case UPLOAD_TRAILER_FILES_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // DELETE TRAILER FILE
    case DELETE_TRAILER_FILE:
      return { ...state };
    case DELETE_TRAILER_FILE_SUCCESS:
      return {
        ...state,
        files: state.files.filter(file => file.id !== action.payload),
      };
    case DELETE_TRAILER_FILE_FAIL:
      return { ...state, _error: action.payload };

    // TOGGLE_TRAILER_PIN
    case TOGGLE_TRAILER_PIN:
      return { ...state, _error: null };
    case TOGGLE_TRAILER_PIN_SUCCESS:
      return {
        ...state,
        list: state.list.map(trailer =>
          trailer.id !== action.payload.trailerId
            ? trailer
            : { ...trailer, pinned: !action.payload.isPinned },
        ),
      };
    case TOGGLE_TRAILER_PIN_FAIL:
      return { ...state, _error: action.payload };

    // GET_TRAILERS_TRACKING_DATA
    case GET_TRAILERS_TRACKING_DATA:
      return { ...state, _error: null };
    case GET_TRAILERS_TRACKING_DATA_SUCCESS:
      return {
        ...state,
        // ToDo remove template string for device_id after BE
        trackingData: action.payload.map(item => ({
          ...item,
          device_id: `${item.device_id}`,
        })),
      };
    case GET_TRAILERS_TRACKING_DATA_FAIL:
      return { ...state, _error: action.payload };

    // CLEAR_TRAILERS_TRACKING_DATA
    case CLEAR_TRAILERS_TRACKING_DATA: {
      return { ...state, trackingData: [] };
    }

    case LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default trailersReducers;
