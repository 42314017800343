import { FC, memo } from 'react';

type Props = {
  className?: string;
  color?: string;
  withAlert?: boolean;
};

const IconNotification: FC<Props> = ({
  className,
  color = 'var(--primaryText)',
  withAlert,
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M17 16V17H18V16H17ZM7 16H6V17H7V16ZM5 15C4.44772 15 4 15.4477 4 16C4 16.5523 4.44772 17 5 17V15ZM19 17C19.5523 17 20 16.5523 20 16C20 15.4477 19.5523 15 19 15V17ZM10.5 19C9.94772 19 9.5 19.4477 9.5 20C9.5 20.5523 9.94772 21 10.5 21V19ZM13.5 21C14.0523 21 14.5 20.5523 14.5 20C14.5 19.4477 14.0523 19 13.5 19V21ZM16 9V16H18V9H16ZM17 15H7V17H17V15ZM8 16V9H6V16H8ZM12 5C14.2091 5 16 6.79086 16 9H18C18 5.68629 15.3137 3 12 3V5ZM12 3C8.68629 3 6 5.68629 6 9H8C8 6.79086 9.79086 5 12 5V3ZM5 17H19V15H5V17ZM10.5 21H12V19H10.5V21ZM12 21H13.5V19H12V21Z"
        fill={color}
      />
      {withAlert && (
        <circle
          cx="22"
          cy="9"
          r="5"
          fill="var(--red)"
          stroke="white"
          strokeWidth="2"
        />
      )}
    </svg>
  );
};

export default memo(IconNotification);
