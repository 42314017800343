import {
  initSearchActionsType,
  SET_SEARCH_STRING,
  SET_SEARCH_RESULT,
  SET_SEARCH_RESULT_SUCCESS,
  CLEAR_SEARCH,
  SET_SEARCH_RESULT_FAIL,
} from 'components/_common/SearchPanel/_redux/searchTypes';
import { IWithPending, IWithLoaded, IWithError } from 'utils/models';

import { ISearchResult } from '../models';

export interface ISearchState extends IWithPending, IWithLoaded, IWithError {
  searchValue: string;
  searchResult: ISearchResult[];
}

const initialState: ISearchState = {
  searchValue: '',
  searchResult: [],
  _pending: false,
  _loaded: false,
  _error: null,
};

const searchReducers = (
  state = initialState,
  action: initSearchActionsType,
) => {
  switch (action.type) {
    case SET_SEARCH_STRING:
      return {
        ...state,
        searchValue: action.payload,
      };

    case CLEAR_SEARCH:
      return {
        ...state,
        ...initialState,
      };

    case SET_SEARCH_RESULT:
      return { ...state, _pending: true, _loaded: false };
    case SET_SEARCH_RESULT_SUCCESS:
      return {
        ...state,
        searchResult: action.payload,
        _pending: false,
        _loaded: true,
      };
    case SET_SEARCH_RESULT_FAIL:
      return {
        ...state,
        searchResult: [],
        _pending: false,
        _loaded: true,
        _error: action.payload,
      };

    default:
      return state;
  }
};

export default searchReducers;
