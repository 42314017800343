import { AxiosError } from 'axios';

import { IPagination } from 'components/_common/Navigation/Pagination/_redux/paginationTypes';
import { IDefaultReducer } from 'utils/models';

import { IGeoFence, IGeoFenceAlert } from '../_models/geoFenceModel';

// REDUCER
export interface IGeoFencesState extends IDefaultReducer {
  list: IGeoFence[];
  currentGeoFence: IGeoFence | null;
  currentGeoFenceAlerts: IGeoFenceAlert[];
}

// CREATE_GEOFENCE
export const CREATE_GEOFENCE = '[@@GeoFences] CREATE_GEOFENCE';
export const CREATE_GEOFENCE_SUCCESS = '[@@GeoFences] CREATE_GEOFENCE_SUCCESS';
export const CREATE_GEOFENCE_FAIL = '[@@GeoFences] CREATE_GEOFENCE_FAIL';

export interface ICreateGeoFence {
  type: typeof CREATE_GEOFENCE;
  payload: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: { values: any };
    callback?: (id: number) => void;
  };
}

export interface ICreateGeoFenceSuccess {
  type: typeof CREATE_GEOFENCE_SUCCESS;
  payload: {
    geofence: IGeoFence;
  };
}

export interface ICreateGeoFenceFail {
  type: typeof CREATE_GEOFENCE_FAIL;
  payload: AxiosError;
}

// GET_GEOFENCES
export const GET_GEOFENCES = '[@@GeoFences] GET_GEOFENCES';
export const GET_GEOFENCES_SUCCESS = '[@@GeoFences] GET_GEOFENCES_SUCCESS';
export const GET_GEOFENCES_FAIL = '[@@GeoFences] GET_GEOFENCES_FAIL';

export interface IGetGeoFences {
  type: typeof GET_GEOFENCES;
  payload: {
    data: {
      query?: string;
    };
    callback?: () => void;
    config: {
      usePaginationFlag?: boolean;
    };
  };
}

export interface IGetGeoFencesSuccess {
  type: typeof GET_GEOFENCES_SUCCESS;
  payload: {
    geofences: IGeoFence[];
    pagination: IPagination;
  };
}

export interface IGetGeoFencesFail {
  type: typeof GET_GEOFENCES_FAIL;
  payload: AxiosError;
}

// GET_GEOFENCE
export const GET_GEOFENCE = '[@@GeoFences] GET_GEOFENCE';
export const GET_GEOFENCE_SUCCESS = '[@@GeoFences] GET_GEOFENCE_SUCCESS';
export const GET_GEOFENCE_FAIL = '[@@GeoFences] GET_GEOFENCE_FAIL';

export interface IGetGeoFence {
  type: typeof GET_GEOFENCE;
  payload: {
    data: { id: string | number };
    callback?: () => void;
  };
}

export interface IGetGeoFenceSuccess {
  type: typeof GET_GEOFENCE_SUCCESS;
  payload: {
    geofence: IGeoFence;
  };
}

export interface IGetGeoFenceFail {
  type: typeof GET_GEOFENCE_FAIL;
  payload: AxiosError;
}

// UPDATE_GEOFENCE
export const UPDATE_GEOFENCE = '[@@GeoFences] UPDATE_GEOFENCE';
export const UPDATE_GEOFENCE_SUCCESS = '[@@GeoFences] UPDATE_GEOFENCE_SUCCESS';
export const UPDATE_GEOFENCE_FAIL = '[@@GeoFences] UPDATE_GEOFENCE_FAIL';

export interface IUpdateGeoFence {
  type: typeof UPDATE_GEOFENCE;
  payload: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: { id: number; values: any };
    callback?: () => void;
  };
}

export interface IUpdateGeoFenceSuccess {
  type: typeof UPDATE_GEOFENCE_SUCCESS;
  payload: {
    geofence: IGeoFence;
  };
}

export interface IUpdateGeoFenceFail {
  type: typeof UPDATE_GEOFENCE_FAIL;
  payload: AxiosError;
}

// GET_GEOFENCE_ALERTS
export const GET_GEOFENCE_ALERTS = '[@@GeoFences] GET_GEOFENCE_ALERTS';
export const GET_GEOFENCE_ALERTS_SUCCESS =
  '[@@GeoFences] GET_GEOFENCE_ALERTS_SUCCESS';
export const GET_GEOFENCE_ALERTS_FAIL =
  '[@@GeoFences] GET_GEOFENCE_ALERTS_FAIL';

export interface IGetGeoFenceAlerts {
  type: typeof GET_GEOFENCE_ALERTS;
  payload: {
    data: {
      id: number;
      page: number;
      per_page: number;
      usePagination?: boolean;
    };
    callback?: () => void;
  };
}

export interface IGetGeoFenceAlertsSuccess {
  type: typeof GET_GEOFENCE_ALERTS_SUCCESS;
  payload: {
    geofences_alerts: IGeoFenceAlert[];
  };
}

export interface IGetGeoFenceAlertsFail {
  type: typeof GET_GEOFENCE_ALERTS_FAIL;
  payload: AxiosError;
}

// DELETE_GEOFENCE
export const DELETE_GEOFENCE = '[@@GeoFences] DELETE_GEOFENCE';
export const DELETE_GEOFENCE_SUCCESS = '[@@GeoFences] DELETE_GEOFENCE_SUCCESS';
export const DELETE_GEOFENCE_FAIL = '[@@GeoFences] DELETE_GEOFENCE_FAIL';

export interface IDeleteGeoFence {
  type: typeof DELETE_GEOFENCE;
  payload: {
    data: { id: number | string };
    callback?: () => void;
  };
}

export interface IDeleteGeoFenceSuccess {
  type: typeof DELETE_GEOFENCE_SUCCESS;
  payload: number;
}

export interface IDeleteGeoFenceFail {
  type: typeof DELETE_GEOFENCE_FAIL;
  payload: AxiosError;
}

// CLEAR_STATE
export const CLEAR_STATE = '[@@GeoFences] CLEAR_STATE';

export interface IClearStateFail {
  type: typeof CLEAR_STATE;
  payload: Partial<IGeoFencesState>;
}

export type geoFencesTypes =
  | IClearStateFail
  | IGetGeoFenceAlerts
  | IGetGeoFenceAlertsFail
  | IGetGeoFenceAlertsSuccess
  | IDeleteGeoFence
  | IDeleteGeoFenceFail
  | IDeleteGeoFenceSuccess
  | IUpdateGeoFence
  | IUpdateGeoFenceFail
  | IUpdateGeoFenceSuccess
  | IGetGeoFence
  | IGetGeoFenceFail
  | IGetGeoFenceSuccess
  | ICreateGeoFence
  | ICreateGeoFenceFail
  | ICreateGeoFenceSuccess
  | IGetGeoFences
  | IGetGeoFencesFail
  | IGetGeoFencesSuccess;
