import { call, put, takeLatest } from 'redux-saga/effects';

import api from '../../../../../utils/requests';
import {
  deleteScriptFileAction,
  uploadScriptFilesAction,
} from './scriptsActions';
import {
  DELETE_SCRIPT_FILE,
  DELETE_SCRIPT_FILE_FAIL,
  DELETE_SCRIPT_FILE_SUCCESS,
  UPLOAD_SCRIPT_FILES,
  UPLOAD_SCRIPT_FILES_FAIL,
  UPLOAD_SCRIPT_FILES_SUCCESS,
} from './scriptsTypes';

function* uploadScriptFileSaga({
  payload: { files, itemId },
}: ReturnType<typeof uploadScriptFilesAction>) {
  const url = `/v1/lead_scripts/segments/files`;

  try {
    const formData = new FormData();

    formData.append(`lead_scripts_segments_file[file]`, files);
    const { data } = yield call(() =>
      api.post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    );
    if (data) {
      yield put({
        type: UPLOAD_SCRIPT_FILES_SUCCESS,
        payload: { file: data.fleets_leads_file, segmentId: itemId },
      });
    }
  } catch (e) {
    yield put({ type: UPLOAD_SCRIPT_FILES_FAIL, payload: e });
  }
}

function* deleteScriptFileSaga({
  payload: { fileId },
}: ReturnType<typeof deleteScriptFileAction>) {
  const url = `/v1/lead_scripts/segments/files/${fileId}`;
  try {
    yield call(() => api.delete(url));
    yield put({ type: DELETE_SCRIPT_FILE_SUCCESS, payload: fileId });
  } catch (e) {
    yield put({ type: DELETE_SCRIPT_FILE_FAIL, payload: e });
  }
}

export default function* scriptsSaga() {
  yield takeLatest(UPLOAD_SCRIPT_FILES, uploadScriptFileSaga);
  yield takeLatest(DELETE_SCRIPT_FILE, deleteScriptFileSaga);
}
