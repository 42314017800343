import { LicenseManager } from '@ag-grid-enterprise/core';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import App from 'components/App/App';
import { persistor, store } from 'redux/store';

import './assets/proto/array';
import './assets/styles/colors.scss';
import './assets/styles/themes.scss';
import './index.css';
import 'cropperjs/dist/cropper.css';

LicenseManager.setLicenseKey('test');

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter
        getUserConfirmation={(message, callback) => {
          // this is the default behavior
          // eslint-disable-next-line no-alert
          const allowTransition = window.confirm(message);
          callback(allowTransition);
        }}
      >
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
