import { FC } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
  onClick?: () => void;
}

const IconCheckboxUnchecked: FC<IOwnProps> = ({
  className,
  fillColor = '#86898E',
  onClick,
}) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
    >
      <circle cx="12" cy="12" r="11.5" stroke={fillColor} />
    </svg>
  );
};

export default IconCheckboxUnchecked;
