type Props = {
  className?: string;
  strokeColor?: string;
};

const IconChevrone = ({
  className,
  strokeColor = 'var(--primaryText)',
}: Props) => (
  <svg
    className={className}
    fill="none"
    height="9"
    viewBox="0 0 14 9"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m13 1-6 6-6-6"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeWidth="2"
    />
  </svg>
);

export default IconChevrone;
