type Props = {
  className?: string;
  fillColor?: string;
};

/**
 *
 * @param className
 * @param fillColor - default is #86898E
 */
const IconPhone = ({ className, fillColor = '#86898E' }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.845 9.43124C11.4545 9.04072 10.8213 9.04072 10.4308 9.43124L9.914 9.94807C9.52348 10.3386 8.89031 10.3386 8.49979 9.94807L6.05193 7.50021C5.66141 7.10969 5.66141 6.47652 6.05193 6.086L6.56876 5.56918C6.95928 5.17865 6.95928 4.54549 6.56876 4.15496L4.25661 1.84282C3.79113 1.37734 3.03645 1.37734 2.57098 1.84282V1.84282C1.5651 2.8487 0.9407 4.22602 1.21033 5.62276C2.10359 10.25 5.75002 13.8964 10.3772 14.7897C11.774 15.0593 13.1513 14.4349 14.1572 13.429V13.429C14.6227 12.9635 14.6227 12.2089 14.1572 11.7434L11.845 9.43124Z"
        stroke={fillColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default IconPhone;
