type Props = {
  strokeColor?: string;
  className?: string;
};

const IconCopy = ({
  strokeColor = 'var(--secondaryText)',
  className,
}: Props) => (
  <svg
    className={className}
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke={strokeColor} strokeWidth="2">
      <rect height="10" rx="2" width="10" x="1" y="5" />
      <path d="m5 1h6c2.2091 0 4 1.79086 4 4v6" strokeLinecap="round" />
    </g>
  </svg>
);

export default IconCopy;
