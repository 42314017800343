import { FC, MouseEvent, useCallback, useRef, memo } from 'react';

import clsx from 'clsx';

import { IconArrowDown } from 'assets/icons/components';
import OptionsPopover from 'components/_common/ThreeDots/OptionsPopover/OptionsPopover';
import useOnClickOutside from 'hooks/useOnClickOutside';
import useToggle from 'hooks/useToggle';

import { IOption } from '../ThreeDots/optionModels';

import styles from './NotificationMessages.module.scss';

const OPTIONS_CONFIG: readonly IOption[] = [
  {
    key: 'unread',
    title: 'Unread',
  },
  {
    key: 'all',
    title: 'All',
  },
];

const HeaderFilter: FC<{
  value: 'all' | 'unread';
  onChange: (value: 'all' | 'unread') => void;
  clickable?: boolean;
}> = ({ value, onChange, clickable = true }) => {
  const { isOpen, close, toggleVisibility: toggle } = useToggle();

  const cardRef = useRef<HTMLDivElement>(null);
  useOnClickOutside({ ref: cardRef, callback: close });

  const onClickHandler = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    toggle();
  }, []);

  const onActionHandler = useCallback(
    (key: string, e: MouseEvent) => {
      e.stopPropagation();
      if (key === 'all' || key === 'unread') onChange(key);
      close();
    },
    [onChange],
  );

  return (
    <>
      <div
        ref={cardRef}
        className={clsx(styles.filter, { [styles.clickable]: clickable })}
        onClick={clickable ? onClickHandler : undefined}
      >
        {OPTIONS_CONFIG.find(o => o.key === value)?.title || ''}

        {clickable && (
          <IconArrowDown className={isOpen ? styles.rotated : undefined} />
        )}
      </div>

      <OptionsPopover
        id={0}
        isOpen={isOpen}
        config={OPTIONS_CONFIG}
        position="bottom"
        onAction={onActionHandler}
        parentElement={cardRef.current}
      />
    </>
  );
};

export default memo(HeaderFilter);
