import { memo, useEffect, useState } from 'react';

import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { IconClose, IconMoon } from 'assets/icons/components';
import TruckImg from 'assets/img/Truck.png';
import Button from 'components/_common/FormElements/Button/Button';
import { authSelector } from 'components/Auth/_redux/authSelectors';
import useSidebarSelector from 'components/Fleet/_hooks/useSidebarSelector';
import { getActivePlan } from 'components/WiseCheck/TabSettings/_redux/companySettingsActions';
import { companySettingsSelector } from 'components/WiseCheck/TabSettings/_redux/companySettingsSelectors';
import { changeColorThemeAction } from 'redux/userSettings/userSettingsActions';
import {
  userSettingsSelector,
  userThemeSelector,
} from 'redux/userSettings/userSettingsSelectors';

import { BlueSwitch } from '../../CustomMaterialComponents';

import styles from './MainSidebar.module.scss';

const SidebarFooter = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showPlansBlock, setShowPlansBlock] = useState(true);

  // TODO: DimaM Commented due to Nazar Yurystovskyy task FT-5822 - further all functionality will be deleted
  // const {
  //   isOpen: feedbackModalIsOpen,
  //   close: closeFeedbackModal,
  //   open: openFeedbackModal,
  // } = useToggle();
  const { collapsedFlag } = useSidebarSelector();

  // Selectors
  const theme = useSelector(userThemeSelector);
  const { email } = useSelector(authSelector);

  const { company } = useSelector(userSettingsSelector);
  const { activePlan, _loaded: acivePlanLoaded } = useSelector(
    companySettingsSelector,
  );

  const isWiseCheck = company?.product_menu_type === 'wisecheck';

  // TODO: DimaM Commented due to Nazar Yurystovskyy task FT-5822 - further all functionality will be deleted
  // const { pathname } = useLocation();

  // Handlers
  const onChangeColorTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    localStorage.setItem(`${email}_theme`, newTheme);
    dispatch(changeColorThemeAction(newTheme));
  };
  // TODO: DimaM Commented due to Nazar Yurystovskyy task FT-5822 - further all functionality will be deleted
  // const section_type = useMemo(() => {
  //   const currentItem: SideBarDropMenuItem | undefined =
  //     SIDEBAR_DROP_MENU_CONFIG.find(item => pathname.startsWith(item.path));
  //   return currentItem?.id;
  // }, [pathname]);

  useEffect(() => {
    if (isWiseCheck) {
      dispatch(getActivePlan());
    }
  }, [isWiseCheck]);

  return (
    <footer>
      {isWiseCheck &&
        showPlansBlock &&
        (activePlan?.name === 'Trial' || (acivePlanLoaded && !activePlan)) && (
          <div className={styles.subscription}>
            <p>Get the most out of your company with Premium plans</p>
            <IconClose
              onClick={() => setShowPlansBlock(false)}
              fillColor="white"
              className={styles.closeButton}
            />
            <Button
              theme="white"
              onClick={() => history.push('/fleet/settings/plans')}
            >
              Upgrade
            </Button>
            <div
              className={styles.truckImage}
              style={{ backgroundImage: `url(${TruckImg})` }}
            />
          </div>
        )}
      <div className={styles.themeSwitcher}>
        <IconMoon
          className={clsx(styles.iconMoon, {
            [styles.collapsed]: collapsedFlag,
          })}
          fillColor={
            theme === 'dark'
              ? 'var(--colorsPaletteYellow)'
              : 'var(--sideBarIcon)'
          }
          onClick={collapsedFlag ? onChangeColorTheme : undefined}
        />
        {!collapsedFlag && (
          <>
            <span>Dark Mode</span>
            {/* TODO: Fit radio button to the new design */}
            <BlueSwitch
              className={styles.themeSwitcher}
              classes={{
                track: styles.track,
                switchBase: styles.switchBase,
              }}
              checked={theme === 'dark'}
              onClick={onChangeColorTheme}
              data-test="theme_switch_button"
            />
          </>
        )}
      </div>
      {/* TODO: DimaM Commented due to Nazar Yurystovskyy task FT-5822 - further all functionality will be deleted */}
      {/* <button className={styles.buttonSendFeedback} onClick={openFeedbackModal}> */}
      {/*  {collapsedFlag && <IconFeedback />} */}
      {/*  {!collapsedFlag && 'Send Feedback'} */}
      {/* </button> */}
      {/* <Modal isOpen={feedbackModalIsOpen} onClose={closeFeedbackModal}> */}
      {/*  <FeedbackModal */}
      {/*    path={pathname} */}
      {/*    initialValues={{ section_type }} */}
      {/*    onCancel={closeFeedbackModal} */}
      {/*  /> */}
      {/* </Modal> */}
    </footer>
  );
};

export default memo(SidebarFooter);
