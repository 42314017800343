import { IUpcomingDriver } from 'components/HumanResources/TabDrivers/_models/driverModel';

import {
  CLEAR_UPCOMING_DRIVER,
  COMPLETE_UPCOMING_DRIVER_STEP,
  COMPLETE_UPCOMING_DRIVER_STEP_SUCCESS,
  COMPLETE_UPCOMING_DRIVER_TEST,
  COMPLETE_UPCOMING_DRIVER_TEST_SUCCESS,
  DELETE_PROCESS_FILE,
  DELETE_UPCOMING_DRIVER_STEP_FILE,
  DELETE_UPCOMING_DRIVER_STEP_FILE_SUCCESS,
  DELETE_UPCOMING_DRIVER_TEST_FILE,
  DELETE_UPCOMING_DRIVER_TEST_FILE_SUCCESS,
  GET_UPCOMING_DRIVER,
  ICompleteUpcomingDriverStepAction,
  ICompleteUpcomingDriverTestAction,
  ICompleteUpcomingDriverTestSuccessAction,
  IDeleteUpcomingDriverFileAction,
  IDeleteUpcomingDriverStepFileSuccessAction,
  IDeleteUpcomingDriverTestFilesAction,
  IDeleteUpcomingDriverTestFileSuccessAction,
  IGetUpcomingDriverAction,
  ISetUpcomingDriverCurrentStepAction,
  IUpdateUpcomingDriverTest,
  IUploadProcessFileAction,
  IUploadProcessFileSuccessAction,
  IUploadUpcomingDriverStepFilesAction,
  IUploadUpcomingDriverStepFilesSuccessAction,
  IUploadUpcomingDriverTestFilesAction,
  IUploadUpcomingDriverTestFileSuccessAction,
  SET_UPCOMING_DRIVER_CURRENT_STEP,
  START_UPCOMING_DRIVER_STEP,
  UPDATE_UPCOMING_DRIVER_TEST,
  UPLOAD_PROCESS_FILE,
  UPLOAD_PROCESS_FILE_SUCCESS,
  UPLOAD_UPCOMING_DRIVER_FILE_SUCCESS,
  UPLOAD_UPCOMING_DRIVER_STEP_FILES,
  UPLOAD_UPCOMING_DRIVER_TEST_FILE_SUCCESS,
  UPLOAD_UPCOMING_DRIVER_TEST_FILES,
  UPLOAD_PROCESS_FILE_FROM_PROFILE,
  IUploadProcessFileFromProfileAction,
} from './upcomingDriverTypes';

export const uploadUpcomingDriverFilesAction = ({
  files,
  driverId,
  stepId,
  callback,
}: IUploadUpcomingDriverStepFilesAction['payload']): IUploadUpcomingDriverStepFilesAction => ({
  type: UPLOAD_UPCOMING_DRIVER_STEP_FILES,
  payload: { files, driverId, stepId, callback },
});

export const uploadUpcomingDriverTestFilesAction = ({
  files,
  actionConfig,
  callback,
}: IUploadUpcomingDriverTestFilesAction['payload']): IUploadUpcomingDriverTestFilesAction => ({
  type: UPLOAD_UPCOMING_DRIVER_TEST_FILES,
  payload: { files, actionConfig, callback },
});

export const deleteUpcomingDriverFilesAction = (
  payload: IDeleteUpcomingDriverFileAction['payload'],
): IDeleteUpcomingDriverFileAction => ({
  type: DELETE_UPCOMING_DRIVER_STEP_FILE,
  payload,
});

export const deleteUpcomingDriverTestFilesAction = ({
  fileId,
  actionConfig,
  callback,
}: IDeleteUpcomingDriverTestFilesAction['payload']): IDeleteUpcomingDriverTestFilesAction => ({
  type: DELETE_UPCOMING_DRIVER_TEST_FILE,
  payload: { fileId, actionConfig, callback },
});

export const uploadUpcomingDriverFilesSuccessAction = (
  file: IUploadUpcomingDriverStepFilesSuccessAction['payload']['file'],
): IUploadUpcomingDriverStepFilesSuccessAction => ({
  type: UPLOAD_UPCOMING_DRIVER_FILE_SUCCESS,
  payload: { file },
});

export const uploadUpcomingDriverTestFileSuccessAction = (
  file: IUploadUpcomingDriverTestFileSuccessAction['payload']['file'],
  testId: IUploadUpcomingDriverTestFileSuccessAction['payload']['testId'],
): IUploadUpcomingDriverTestFileSuccessAction => ({
  type: UPLOAD_UPCOMING_DRIVER_TEST_FILE_SUCCESS,
  payload: { file, testId },
});

export const deleteUpcomingDriverTestFileSuccessAction = (
  payload: IDeleteUpcomingDriverTestFileSuccessAction['payload'],
): IDeleteUpcomingDriverTestFileSuccessAction => ({
  type: DELETE_UPCOMING_DRIVER_TEST_FILE_SUCCESS,
  payload,
});

export const deleteUpcomingDriverFileSuccessAction = (
  payload: IDeleteUpcomingDriverStepFileSuccessAction['payload'],
): IDeleteUpcomingDriverStepFileSuccessAction => ({
  type: DELETE_UPCOMING_DRIVER_STEP_FILE_SUCCESS,
  payload,
});

export const setUpcomingDriverCurrentStepAction = (
  step?: ISetUpcomingDriverCurrentStepAction['payload']['step'],
): ISetUpcomingDriverCurrentStepAction => ({
  type: SET_UPCOMING_DRIVER_CURRENT_STEP,
  payload: { step },
});

export const completeUpcomingDriverTestAction = ({
  actionType,
  testId,
  stepId,
}: ICompleteUpcomingDriverTestAction['payload']) => ({
  type: COMPLETE_UPCOMING_DRIVER_TEST,
  payload: { actionType, testId, stepId },
});

export const completeUpcomingDriverTestSuccessAction = (
  payload: ICompleteUpcomingDriverTestSuccessAction['payload'],
) => ({
  type: COMPLETE_UPCOMING_DRIVER_TEST_SUCCESS,
  payload,
});

export const completeUpcomingDriverStepAction = ({
  driverId,
  stepId,
  dataToSend,
  callback,
}: ICompleteUpcomingDriverStepAction['payload']) => ({
  type: COMPLETE_UPCOMING_DRIVER_STEP,
  payload: { driverId, stepId, dataToSend, callback },
});

export const completeUpcomingDriverStepSuccessAction = () => ({
  type: COMPLETE_UPCOMING_DRIVER_STEP_SUCCESS,
});

export const updateUpcomingDriverTest = ({
  id,
  comment,
}: IUpdateUpcomingDriverTest['payload']) => ({
  type: UPDATE_UPCOMING_DRIVER_TEST,
  payload: { id, comment },
});

export const getUpcomingDriverAction = (
  id: IGetUpcomingDriverAction['id'],
  dispatchToStore = true,
  callback?: (data: IUpcomingDriver) => void,
): {
  dispatchToStore: boolean;
  id: string | undefined;
  callback: ((data: IUpcomingDriver) => void) | undefined;
  type: string;
} => ({
  type: GET_UPCOMING_DRIVER,
  id,
  dispatchToStore,
  callback,
});

export const clearUpcomingDriver = () => ({
  type: CLEAR_UPCOMING_DRIVER,
});

export const startUpcomingDriverStep = (id: number) => ({
  type: START_UPCOMING_DRIVER_STEP,
  payload: id,
});

export const uploadProcessFileAction = (
  payload: IUploadProcessFileAction['payload'],
) => ({
  type: UPLOAD_PROCESS_FILE,
  payload,
});

export const uploadProcessFileSuccessAction = (
  payload: IUploadProcessFileSuccessAction['payload'],
) => ({
  type: UPLOAD_PROCESS_FILE_SUCCESS,
  payload,
});

export const deleteUpcomingDriverFileAction = (
  payload: IDeleteUpcomingDriverFileAction['payload'],
) => ({
  type: DELETE_PROCESS_FILE,
  payload,
});

export const uploadProcessFileFromProfileAction = (
  payload: IUploadProcessFileFromProfileAction['payload'],
) => ({
  type: UPLOAD_PROCESS_FILE_FROM_PROFILE,
  payload,
});
