import { LOGOUT } from 'components/Auth/_redux/authTypes';
import { IAccidentReducer } from 'components/Safety/TabAccidents/_models/accidentModels';
import {
  accidentActionsType,
  CREATE_ACCIDENT_SUCCESS,
  CREATE_ACCIDENT_FAIL,
  GET_ACCIDENTS,
  GET_ACCIDENTS_SUCCESS,
  GET_ACCIDENTS_FAIL,
  GET_ACCIDENT,
  GET_ACCIDENT_SUCCESS,
  GET_ACCIDENT_FAIL,
  UPDATE_ACCIDENT,
  UPDATE_ACCIDENT_SUCCESS,
  UPDATE_ACCIDENT_FAIL,
  UPLOAD_ACCIDENT_FILES,
  UPLOAD_ACCIDENT_FILES_SUCCESS,
  UPLOAD_ACCIDENT_FILES_FAIL,
  UNMOUNT_ACCIDENT_PAYMENT_FORM,
  DELETE_ACCIDENT_FILES,
  DELETE_ACCIDENT_FILES_SUCCESS,
  DELETE_ACCIDENT_FILES_FAIL,
  GET_ACCIDENT_ACTIVITY,
  GET_ACCIDENT_ACTIVITY_SUCCESS,
  GET_ACCIDENT_ACTIVITY_FAIL,
  DELETE_ACCIDENT_SUCCESS,
  DELETE_ACCIDENT_FAIL,
  UPLOAD_ACCIDENT_PAYMENT_FILES,
  UPLOAD_ACCIDENT_PAYMENT_FILES_SUCCESS,
  UPLOAD_ACCIDENT_PAYMENT_FILES_FAIL,
  RESET_ACCIDENT_FILES,
  SEND_ACCIDENT_PAYMENT_FAIL,
} from 'components/Safety/TabAccidents/_redux/accidentsTypes';

const initialState: IAccidentReducer = {
  pagination: null,
  list: [],
  files: [],
  paymentFiles: [],
  logs: null,
  currentAccident: null,
  claimTotalMaxValue: null,
  _pending: false,
  _loaded: false,
  _error: null,
};

const accidentsReducers = (
  state = initialState,
  action: accidentActionsType,
): IAccidentReducer => {
  switch (action.type) {
    // CREATE_ACCIDENT
    case CREATE_ACCIDENT_SUCCESS:
      return { ...state, currentAccident: action.payload };
    case CREATE_ACCIDENT_FAIL:
      return { ...state, _error: action.payload };

    // GET_ACCIDENT
    case GET_ACCIDENT:
      return {
        ...state,
        currentAccident: null,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case GET_ACCIDENT_SUCCESS:
      return {
        ...state,
        currentAccident: action.payload.accident,
        files: action.payload.accident.files,
        _loaded: true,
        _pending: false,
      };
    case GET_ACCIDENT_FAIL:
      return {
        ...initialState,
        _error: action.payload,
        _loaded: false,
        _pending: false,
      };

    // DELETE ACCIDENT BY ID
    case DELETE_ACCIDENT_SUCCESS: {
      const { id } = action;
      let { pagination, list, currentAccident } = state;
      const index = list.findIndex(rec => rec.id === id);
      if (index !== -1) {
        if (pagination)
          pagination = { ...pagination, total: pagination.total - 1 };
        list = [...list];
        list.splice(index, 1);
        currentAccident = null;
      }
      return {
        ...state,
        pagination,
        list,
        currentAccident,
        _loaded: true,
        _pending: false,
      };
    }

    case DELETE_ACCIDENT_FAIL:
      return {
        ...state,
        _error: action.payload,
        _loaded: false,
        _pending: false,
      };

    // GET_ACCIDENTS
    case GET_ACCIDENTS:
      return {
        ...state,
        list: [],
        currentAccident: null,
        _loaded: false,
        _pending: true,
        _error: null,
      };

    case GET_ACCIDENTS_SUCCESS:
      return {
        ...state,
        pagination: action.payload.pagination,
        list: action.payload.accidents,
        claimTotalMaxValue: action.payload.claim_total_max,
        _loaded: true,
        _pending: false,
      };
    case GET_ACCIDENTS_FAIL:
      return {
        ...initialState,
        _error: action.payload,
        _loaded: false,
        _pending: false,
      };

    // UPDATE_ACCIDENT
    case UPDATE_ACCIDENT:
      return {
        ...state,
        currentAccident: {
          ...state.currentAccident,
          status:
            action.payload.accident && action.payload.accident.status
              ? action.payload.accident.status
              : state.currentAccident.status,
        },
        list: state.list.map(accident => {
          return {
            ...accident,
            status:
              accident.id === action.payload.accident.id &&
              action.payload.accident.status
                ? action.payload.accident.status
                : accident.status,
          };
        }),
      };
    case UPDATE_ACCIDENT_SUCCESS:
      return { ...state, currentAccident: action.payload };
    case UPDATE_ACCIDENT_FAIL:
      return { ...state, _error: action.payload };

    case SEND_ACCIDENT_PAYMENT_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // UPLOAD ACCIDENT FILES
    case UPLOAD_ACCIDENT_FILES:
      return {
        ...state,
        files: [
          ...state.files,
          ...action.payload.files.map(
            file =>
              ({
                ...file,
                name: file.name,
                loading: true,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } as any),
          ),
        ],
      };
    case UPLOAD_ACCIDENT_FILES_SUCCESS:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        files: state.files.map((file: any, index: number) =>
          index === state.files.findIndex(({ loading }) => loading)
            ? { ...action.payload, loading: false }
            : file,
        ),
      };
    case UPLOAD_ACCIDENT_FILES_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // UPLOAD_ACCIDENT_PAYMENT_FILES
    case UPLOAD_ACCIDENT_PAYMENT_FILES:
      return {
        ...state,
        paymentFiles: [
          ...state.paymentFiles,
          ...action.payload.files.map(
            file =>
              ({
                ...file,
                name: file.name,
                loading: true,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } as any),
          ),
        ],
      };
    case UPLOAD_ACCIDENT_PAYMENT_FILES_SUCCESS:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        paymentFiles: state.paymentFiles.map((file: any, index: number) =>
          index === state.paymentFiles.findIndex(({ loading }) => loading)
            ? { ...action.payload, loading: false }
            : file,
        ),
      };
    case UPLOAD_ACCIDENT_PAYMENT_FILES_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // DELETE ACCIDENT PAYMENT FILES
    case DELETE_ACCIDENT_FILES:
      return { ...state };
    case DELETE_ACCIDENT_FILES_SUCCESS:
      return {
        ...state,
        files: state.files.filter(file => file.id !== +action.payload),
        paymentFiles: state.paymentFiles.filter(
          file => file.id !== +action.payload,
        ),
      };
    case DELETE_ACCIDENT_FILES_FAIL:
      return {
        ...state,
        _error: action.payload,
      };

    // GET ACCIDENT ACTIVITY LOGS
    case GET_ACCIDENT_ACTIVITY:
      return {
        ...state,
        _pending: true,
        _error: null,
        logs: null,
      };
    case GET_ACCIDENT_ACTIVITY_SUCCESS:
      return {
        ...state,
        _pending: false,
        logs: action.payload.logs,
      };

    case GET_ACCIDENT_ACTIVITY_FAIL:
      return {
        ...state,
        _pending: false,
        _error: action.payload,
      };

    // UNMOUNT ACCIDENT PAYMENT FORM
    case UNMOUNT_ACCIDENT_PAYMENT_FORM:
      return { ...state, paymentFiles: [] };

    // RESET_ACCIDENT_FILES
    case RESET_ACCIDENT_FILES:
      return { ...state, files: [] };

    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};

export default accidentsReducers;
