import {
  INIT_GOOGLE_SCRIPT,
  SET_TRUCK_MAP,
  SET_GEOFENCE_MAP,
  SET_TOLL_MAP,
  ISetTruckMapAction,
  ISetGeoFenceMapAction,
  ISetTollMapAction,
  CLEAR_ALL_MAPS,
  ISetTrailerMapAction,
  SET_TRAILER_MAP,
  SET_MAP_TYPE_ID,
  ISetMapTypeIdAction,
  IInitGoogleScriptAction,
} from 'services/google/_redux/googleTypes';

export const initGoogleScriptAction = (
  payload: IInitGoogleScriptAction['payload'],
) => ({
  type: INIT_GOOGLE_SCRIPT,
  payload,
});

export const setMapTypeIdAction = (
  payload: ISetMapTypeIdAction['payload'],
) => ({
  type: SET_MAP_TYPE_ID,
  payload,
});

export const setTruckMapAction = (payload: ISetTruckMapAction['payload']) => ({
  type: SET_TRUCK_MAP,
  payload,
});

export const setGeoFenceMapAction = (
  payload: ISetGeoFenceMapAction['payload'],
) => ({
  type: SET_GEOFENCE_MAP,
  payload,
});

export const setTollMapAction = (payload: ISetTollMapAction['payload']) => ({
  type: SET_TOLL_MAP,
  payload,
});

export const setTrailerMapAction = (
  payload: ISetTrailerMapAction['payload'],
) => ({ type: SET_TRAILER_MAP, payload });

export const clearAllMapsAction = () => ({ type: CLEAR_ALL_MAPS });
