import { AxiosError } from 'axios';

import {
  IInspectionFile,
  InspectionFileView,
  InspectionPhotoCommentLocal,
  InspectionPhotoCommentServer,
} from 'components/Fleet/_models/commonModels';
import {
  ITruckInspection,
  TruckInspectionFormType,
} from 'components/Fleet/TabTrucks/TruckProfile/TruckInspections/_models/truckInspection';
import { ILogout } from 'redux/_common/models';

import {
  IDraftInspection,
  IInspection,
  IVehicleDraftStart,
} from '../../../../TabInspections/_models/vehicleInspectionModels';
import { IClearInspectionErrorAction } from '../../../../TabInspections/_redux/tabInspectionsTypes';

// DRAFT_TRUCK_INSPECTION
export const DRAFT_TRUCK_INSPECTION =
  '[@@TruckInspection] DRAFT_TRUCK_INSPECTION';
export const DRAFT_TRUCK_INSPECTION_SUCCESS =
  '[@@TruckInspection] DRAFT_TRUCK_INSPECTION_SUCCESS';
export const DRAFT_TRUCK_INSPECTION_FAIL =
  '[@@TruckInspection] DRAFT_TRUCK_INSPECTION_FAIL';

export interface IDraftTruckInspectionAction {
  type: typeof DRAFT_TRUCK_INSPECTION;
  payload: {
    data: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      formData: any;
      inspectionId: number;
    };
    callback?: () => void;
  };
}
export interface IDraftTruckInspectionActionSuccess {
  type: typeof DRAFT_TRUCK_INSPECTION_SUCCESS;
  payload: IInspection;
}
export interface IDraftTruckInspectionActionFail {
  type: typeof DRAFT_TRUCK_INSPECTION_FAIL;
  payload: AxiosError;
}

export const INITIALIZE_DRAFT_TRUCK_INSPECTION =
  '[@@TruckInspection] INITIALIZE_DRAFT_TRUCK_INSPECTION';
export const INITIALIZE_DRAFT_TRUCK_INSPECTION_SUCCESS =
  '[@@TruckInspection] INITIALIZE_DRAFT_TRUCK_INSPECTION_SUCCESS';
export const INITIALIZE_DRAFT_TRUCK_INSPECTION_FAIL =
  '[@@TruckInspection] INITIALIZE_DRAFT_TRUCK_INSPECTION_FAIL';

export interface IInitializeDraftTruckInspectionAction {
  type: typeof INITIALIZE_DRAFT_TRUCK_INSPECTION;
  payload: {
    data: {
      formData: IVehicleDraftStart;
    };
    callback?: (data?: { inspection: ITruckInspection }) => void;
  };
}
export interface IInitialiseDraftTruckInspectionActionSuccess {
  type: typeof INITIALIZE_DRAFT_TRUCK_INSPECTION_SUCCESS;
  payload: {
    inspection: Omit<IDraftInspection, 'vehicle_inspections'> & {
      vehicle_inspections: [ITruckInspection];
    };
  };
}
export interface IInitialiseDraftTruckInspectionActionFail {
  type: typeof INITIALIZE_DRAFT_TRUCK_INSPECTION_FAIL;
  payload: AxiosError;
}

// DELETE_TRUCK_INSPECTION
export const DELETE_TRUCK_INSPECTION =
  '[@@TruckInspection] DELETE_TRUCK_INSPECTION';
export const DELETE_TRUCK_INSPECTION_SUCCESS =
  '[@@TruckInspection] DELETE_TRUCK_INSPECTION_SUCCESS';
export const DELETE_TRUCK_INSPECTION_FAIL =
  '[@@TruckInspection] DELETE_TRUCK_INSPECTION_FAIL';

export interface IDeleteTruckInspectionAction {
  type: typeof DELETE_TRUCK_INSPECTION;
  payload: {
    data: {
      inspectionId: number;
      truckId: number;
    };
    callback?: () => void;
  };
}
export interface IDeleteTruckInspectionActionSuccess {
  type: typeof DELETE_TRUCK_INSPECTION_SUCCESS;
}
export interface IDeleteTruckInspectionActionFail {
  type: typeof DELETE_TRUCK_INSPECTION_FAIL;
  payload: AxiosError;
}

// GET_TRUCK_INSPECTION_DRAFT
export const GET_TRUCK_INSPECTION_DRAFT =
  '[@@TruckInspection] GET_TRUCK_INSPECTION_DRAFT';
export const GET_TRUCK_INSPECTION_DRAFT_SUCCESS =
  '[@@TruckInspection] GET_TRUCK_INSPECTION_DRAFT_SUCCESS';
export const GET_TRUCK_INSPECTION_DRAFT_FAIL =
  '[@@TruckInspection] GET_TRUCK_INSPECTION_DRAFT_FAIL';

export interface IGetTruckInspectionDraftAction {
  type: typeof GET_TRUCK_INSPECTION_DRAFT;
  payload: {
    data: {
      inspectionId: number;
    };
    callback?: () => void;
  };
}
export interface IGetTruckInspectionDraftActionSuccess {
  type: typeof GET_TRUCK_INSPECTION_DRAFT_SUCCESS;
  payload: {
    inspection: Omit<IDraftInspection, 'vehicle_inspections'> & {
      vehicle_inspections: [ITruckInspection];
    };
  };
}
export interface IGetTruckInspectionDraftActionFail {
  type: typeof GET_TRUCK_INSPECTION_DRAFT_FAIL;
  payload: AxiosError;
}

// GET_TRUCK_INSPECTION
export const GET_TRUCK_INSPECTION = '[@@TruckInspection] GET_TRUCK_INSPECTION';
export const GET_TRUCK_INSPECTION_SUCCESS =
  '[@@TruckInspection] GET_TRUCK_INSPECTION_SUCCESS';
export const GET_TRUCK_INSPECTION_FAIL =
  '[@@TruckInspection] GET_TRUCK_INSPECTION_FAIL';

export interface IGetTruckInspectionAction {
  type: typeof GET_TRUCK_INSPECTION;
  payload: {
    data: {
      inspectionId: number;
    };
    callback?: () => void;
  };
}
export interface IGetTruckInspectionActionSuccess {
  type: typeof GET_TRUCK_INSPECTION_SUCCESS;
  payload: {
    inspection: Omit<IDraftInspection, 'vehicle_inspections'> & {
      vehicle_inspections: [ITruckInspection];
    };
  };
}
export interface IGetTruckInspectionActionFail {
  type: typeof GET_TRUCK_INSPECTION_FAIL;
  payload: AxiosError;
}

// CLEAR_CURRENT_TRUCK_INSPECTION
export const CLEAR_CURRENT_TRUCK_INSPECTION =
  '[@@TruckInspection]  CLEAR_TRUCK_INSPECTION';
export interface IClearCurrentTruckInspectionAction {
  type: typeof CLEAR_CURRENT_TRUCK_INSPECTION;
}

// UPLOAD_TRUCK_INSPECTION_PHOTO
export const UPLOAD_TRUCK_INSPECTION_PHOTO =
  '[@@TruckInspection] UPLOAD_TRUCK_INSPECTION_PHOTO';
export const UPLOAD_TRUCK_INSPECTION_PHOTO_SUCCESS =
  '[@@TruckInspection] UPLOAD_TRUCK_INSPECTION_PHOTO_SUCCESS';
export const UPLOAD_TRUCK_INSPECTION_PHOTO_FAIL =
  '[@@TruckInspection] UPLOAD_TRUCK_INSPECTION_PHOTO_FAIL';

export interface IUploadTruckInspectionPhotoAction {
  type: typeof UPLOAD_TRUCK_INSPECTION_PHOTO;
  payload: {
    data: {
      file: File;
      truckId: number;
      view: InspectionFileView;
      formType: TruckInspectionFormType;
      inspectionId: number;
      fieldName: string;
      uploadIndex: number;
    };

    callback?: () => void;
  };
}
export interface IUploadTruckInspectionPhotoActionSuccess {
  type: typeof UPLOAD_TRUCK_INSPECTION_PHOTO_SUCCESS;
  payload: IInspectionFile;
  uploadIndex: number;
}
export interface IUploadTruckInspectionPhotoActionFail {
  type: typeof UPLOAD_TRUCK_INSPECTION_PHOTO_FAIL;
  payload: AxiosError;
}

// DELETE_TRUCK_INSPECTION_PHOTO
export const DELETE_TRUCK_INSPECTION_PHOTO =
  '[@@TruckInspection] DELETE_TRUCK_INSPECTION_PHOTO';
export const DELETE_TRUCK_INSPECTION_PHOTO_SUCCESS =
  '[@@TruckInspection] DELETE_TRUCK_INSPECTION_PHOTO_SUCCESS';
export const DELETE_TRUCK_INSPECTION_PHOTO_FAIL =
  '[@@TruckInspection] DELETE_TRUCK_INSPECTION_PHOTO_FAIL';

export interface IDeleteTruckInspectionPhotoAction {
  type: typeof DELETE_TRUCK_INSPECTION_PHOTO;
  payload: {
    data: {
      id: number;
      truckId: number;
    };
    callback?: () => void;
  };
}
export interface IDeleteTruckInspectionPhotoActionSuccess {
  type: typeof DELETE_TRUCK_INSPECTION_PHOTO_SUCCESS;
  payload: number;
}
export interface IDeleteTruckInspectionPhotoActionFail {
  type: typeof DELETE_TRUCK_INSPECTION_PHOTO_FAIL;
  payload: AxiosError;
}

// ADD_TRUCK_INSPECTION_PHOTO_COMMENT
export const ADD_TRUCK_INSPECTION_PHOTO_COMMENT =
  '[@@TruckInspection] ADD_TRUCK_INSPECTION_PHOTO_COMMENT';
export const ADD_TRUCK_INSPECTION_PHOTO_COMMENT_SUCCESS =
  '[@@TruckInspection] ADD_TRUCK_INSPECTION_PHOTO_COMMENT_SUCCESS';
export const ADD_TRUCK_INSPECTION_PHOTO_COMMENT_FAIL =
  '[@@TruckInspection] ADD_TRUCK_INSPECTION_PHOTO_COMMENT_FAIL';

export interface IAddTruckInspectionPhotoCommentAction {
  type: typeof ADD_TRUCK_INSPECTION_PHOTO_COMMENT;
  payload: {
    data: {
      fileId: number;
      commentData: InspectionPhotoCommentLocal;
    };
    callback?: () => void;
  };
}
export interface IAddTruckInspectionPhotoCommentActionSuccess {
  type: typeof ADD_TRUCK_INSPECTION_PHOTO_COMMENT_SUCCESS;
  payload: { fileId: number; comment: InspectionPhotoCommentServer };
}
export interface IAddTruckInspectionPhotoCommentActionFail {
  type: typeof ADD_TRUCK_INSPECTION_PHOTO_COMMENT_FAIL;
  payload: AxiosError;
}
// UPDATE_TRUCK_INSPECTION_PHOTO_COMMENT
export const UPDATE_TRUCK_INSPECTION_PHOTO_COMMENT =
  '[@@TruckInspection] UPDATE_TRUCK_INSPECTION_PHOTO_COMMENT';
export const UPDATE_TRUCK_INSPECTION_PHOTO_COMMENT_SUCCESS =
  '[@@TruckInspection] UPDATE_TRUCK_INSPECTION_PHOTO_COMMENT_SUCCESS';
export const UPDATE_TRUCK_INSPECTION_PHOTO_COMMENT_FAIL =
  '[@@TruckInspection] UPDATE_TRUCK_INSPECTION_PHOTO_COMMENT_FAIL';

export interface IUpdateTruckInspectionPhotoCommentAction {
  type: typeof UPDATE_TRUCK_INSPECTION_PHOTO_COMMENT;
  payload: {
    data: {
      fileId: number;
      commentData: InspectionPhotoCommentServer;
    };
    callback?: () => void;
  };
}
export interface IUpdateTruckInspectionPhotoCommentActionSuccess {
  type: typeof UPDATE_TRUCK_INSPECTION_PHOTO_COMMENT_SUCCESS;
  payload: { fileId: number; comment: InspectionPhotoCommentServer };
}
export interface IUpdateTruckInspectionPhotoCommentActionFail {
  type: typeof UPDATE_TRUCK_INSPECTION_PHOTO_COMMENT_FAIL;
  payload: AxiosError;
}
// DELETE_TRUCK_INSPECTION_PHOTO_COMMENT
export const DELETE_TRUCK_INSPECTION_PHOTO_COMMENT =
  '[@@TruckInspection] DELETE_TRUCK_INSPECTION_PHOTO_COMMENT';
export const DELETE_TRUCK_INSPECTION_PHOTO_COMMENT_SUCCESS =
  '[@@TruckInspection] DELETE_TRUCK_INSPECTION_PHOTO_COMMENT_SUCCESS';
export const DELETE_TRUCK_INSPECTION_PHOTO_COMMENT_FAIL =
  '[@@TruckInspection] DELETE_TRUCK_INSPECTION_PHOTO_COMMENT_FAIL';

export interface IDeleteTruckInspectionPhotoCommentAction {
  type: typeof DELETE_TRUCK_INSPECTION_PHOTO_COMMENT;
  payload: {
    data: {
      fileId: number;
      commentId: number;
    };
    callback?: () => void;
  };
}
export interface IDeleteTruckInspectionPhotoCommentActionSuccess {
  type: typeof DELETE_TRUCK_INSPECTION_PHOTO_COMMENT_SUCCESS;
  payload: { fileId: number; commentId: number };
}
export interface IDeleteTruckInspectionPhotoCommentActionFail {
  type: typeof DELETE_TRUCK_INSPECTION_PHOTO_COMMENT_FAIL;
  payload: AxiosError;
}

// OTHER
export const UNMOUNT_TRUCK_INSPECTION_FORM =
  '[@@TruckInspection] UNMOUNT_TRUCK_INSPECTION_FORM';
export interface IUnmountTruckInspectionFormAction {
  type: typeof UNMOUNT_TRUCK_INSPECTION_FORM;
}

export type TruckInspectionActions =
  | IUploadTruckInspectionPhotoAction
  | IUploadTruckInspectionPhotoActionSuccess
  | IUploadTruckInspectionPhotoActionFail
  | IDeleteTruckInspectionPhotoAction
  | IDeleteTruckInspectionPhotoActionSuccess
  | IDeleteTruckInspectionPhotoActionFail
  | IAddTruckInspectionPhotoCommentAction
  | IAddTruckInspectionPhotoCommentActionSuccess
  | IAddTruckInspectionPhotoCommentActionFail
  | IUpdateTruckInspectionPhotoCommentAction
  | IUpdateTruckInspectionPhotoCommentActionSuccess
  | IUpdateTruckInspectionPhotoCommentActionFail
  | IDeleteTruckInspectionPhotoCommentAction
  | IDeleteTruckInspectionPhotoCommentActionSuccess
  | IDeleteTruckInspectionPhotoCommentActionFail
  | IGetTruckInspectionAction
  | IGetTruckInspectionActionSuccess
  | IGetTruckInspectionActionFail
  | IDeleteTruckInspectionAction
  | IDeleteTruckInspectionActionSuccess
  | IDeleteTruckInspectionActionFail
  | IUnmountTruckInspectionFormAction
  | IClearCurrentTruckInspectionAction
  | IGetTruckInspectionDraftAction
  | IGetTruckInspectionDraftActionSuccess
  | IGetTruckInspectionDraftActionFail
  | IDraftTruckInspectionAction
  | IDraftTruckInspectionActionSuccess
  | IDraftTruckInspectionActionFail
  | IInitializeDraftTruckInspectionAction
  | IInitialiseDraftTruckInspectionActionSuccess
  | IInitialiseDraftTruckInspectionActionFail
  | IClearInspectionErrorAction
  | ILogout;
