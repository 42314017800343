import { FC, memo } from 'react';

type Props = {
  className?: string;
  fillColor?: string;
  onClick?: () => void;
};

const IconMoon: FC<Props> = ({
  className,
  onClick,
  fillColor = 'var(--sideBarIcon)',
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M1 7.99996C1 11.866 4.13401 15 8 15C11.4354 15 14.2928 12.5252 14.8867 9.26119C14.0302 9.73209 13.0464 9.99996 12 9.99996C8.68629 9.99996 6 7.31367 6 3.99996C6 2.95356 6.26787 1.96972 6.73877 1.11328C3.47475 1.70718 1 4.56457 1 7.99996Z"
        stroke={fillColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default memo(IconMoon);
