import { FC, LazyExoticComponent, memo, useEffect, useMemo } from 'react';

import { CircularProgress } from '@mui/material';
import clsx from 'clsx';
import { isEmpty } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from 'react-router-dom';

import {
  SIDEBAR_DROP_MENU_CONFIG,
  FLEET_MAIN_SIDEBAR_CONFIG,
} from 'components/_common/Navigation/MainSidebar/_config';
import { MainSidebarAccordion } from 'components/_common/Navigation/MainSidebar/_models';
import { setSidebarConfigAction } from 'components/_common/Navigation/MainSidebar/_redux/mainSidebarActions';
import { mainSidebarSelector } from 'components/_common/Navigation/MainSidebar/_redux/mainSidebarSelectors';
import MainSidebar from 'components/_common/Navigation/MainSidebar/MainSidebar';
import NewMainHeader from 'components/_common/Navigation/NewMainHeader/NewMainHeader';
import NewSearchPanelProvider from 'components/_common/SearchPanel/NewSearchPanel';
import { authSelector } from 'components/Auth/_redux/authSelectors';
import NotFound from 'components/NotFound/NotFound';
import useCheckPermission from 'hooks/useCheckPermission';
import { setDesktopVersionAction } from 'redux/userSettings/userSettingsActions';

import { userSettingsSelector } from '../../redux/userSettings/userSettingsSelectors';

import styles from '../routes.module.scss';

export const redirectRoute = (fromPath: string, toPath: string) =>
  toPath &&
  toPath !== '/' && (
    <Route exact path={fromPath}>
      <Redirect to={toPath} />
    </Route>
  );

type Props = RouteProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: LazyExoticComponent<any>;
  redirectUrl?: string;
  permissionType?: string;
  withPermission?: boolean;
};

const PrivateRoutes: FC<Props> = ({
  component: Component,
  redirectUrl = '',
  permissionType,
  withPermission = true,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { currentPermissions } = useSelector(userSettingsSelector);

  const pathname = rest?.location?.pathname || '';
  const { token, _pending, position } = useSelector(authSelector);
  const { collapsedFlag } = useSelector(mainSidebarSelector);
  const { hasPermission } = useCheckPermission(pathname, permissionType);
  const { hasPermission: hasPermissionForRedirectUrl } =
    useCheckPermission(redirectUrl);
  const settings = useSelector(userSettingsSelector);
  const isWiseCheck = settings?.company?.product_menu_type !== 'wisecheck';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const makeComponent = (props: RouteComponentProps<any>) => {
    if (
      !hasPermission &&
      !hasPermissionForRedirectUrl &&
      token &&
      withPermission
    ) {
      return <NotFound />;
    }

    if (redirectUrl) return <Redirect to={redirectUrl} />;
    return token ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={`/login${
          pathname && pathname !== '/'
            ? `?redirectUrl=${pathname}${rest?.location?.search || ''}`
            : ''
        }`}
      />
    );
  };

  const sidebarConfig: MainSidebarAccordion[] = useMemo(() => {
    const sidebarItem = SIDEBAR_DROP_MENU_CONFIG.find(item =>
      isWiseCheck ? pathname.startsWith(item.path) : item.id === 'wise',
    );

    const result = sidebarItem ? FLEET_MAIN_SIDEBAR_CONFIG[sidebarItem.id] : [];
    // Filter out items and their children based on permissions
    const filteredResult = result.reduce((acc, item) => {
      if (
        !item.permissions ||
        (currentPermissions &&
          item.permissions.some(permission =>
            currentPermissions.some(perm =>
              perm.permissions.includes(permission),
            ),
          ))
      ) {
        const filteredChildren = item.children
          ? item.children.filter(
              child =>
                !child.permissions ||
                child.permissions.some(permission =>
                  currentPermissions?.some(perm =>
                    perm.permissions.includes(permission),
                  ),
                ),
            )
          : undefined;

        acc.push({
          ...item,
          children: filteredChildren,
        });
      }

      return acc;
    }, [] as MainSidebarAccordion[]);

    return filteredResult || [];
  }, [pathname, isWiseCheck, currentPermissions]);

  // console.log('sidebarConfig', sidebarConfig);
  useEffect(() => {
    if (rest.location && rest.location.search === '?electron=1')
      dispatch(setDesktopVersionAction());
  }, [rest.location && rest.location.search]);

  useEffect(() => {
    dispatch(setSidebarConfigAction(sidebarConfig));
  }, [dispatch, sidebarConfig]);

  // show loader
  if (token && (_pending || !position)) {
    return (
      <div className={styles.loader}>
        <CircularProgress size={25} />
      </div>
    );
  }

  return position !== 'need_registration' ? (
    <>
      <MainSidebar
        className={styles.appSidebar}
        sidebarConfig={sidebarConfig}
      />
      <div
        className={clsx(styles.appContent, {
          [styles.collapsed]: collapsedFlag && !isEmpty(sidebarConfig),
        })}
      >
        <NewSearchPanelProvider>
          <NewMainHeader />
          <Route {...rest} render={makeComponent} exact />
        </NewSearchPanelProvider>
      </div>{' '}
    </>
  ) : (
    <Route {...rest} render={makeComponent} exact />
  );
};

export default memo(PrivateRoutes);
