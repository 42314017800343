import { PropsWithChildren, useState, memo } from 'react';

import moment from 'moment';

import {
  NotificationsChannelItem,
  NotificationsChannelMessage,
  NotificationsChannelTask,
  isMessage,
  isTask,
} from 'socket/channels';

import CalendarGroup from '../CalendarGroup';

type Props<T = NotificationsChannelTask | NotificationsChannelMessage> = {
  showAll?: boolean;
  messages: T[];
  children: (message: T) => JSX.Element;
};

const MessagesGroups = <T extends NotificationsChannelItem>(
  props: PropsWithChildren<Props<T>>,
): JSX.Element => {
  const { showAll, messages, children } = props;
  const [calendarGroup] = useState(() => new CalendarGroup());

  type GroupProps = { title: string; messages: T[] };
  const groups: GroupProps[] = [];

  const group: GroupProps = { title: '', messages: [] };
  for (const message of messages) {
    const title = calendarGroup.getGroupTitle(
      moment(isTask(message) ? message.expired_at : message.created_at),
    );
    if (group.title && group.title !== title && group.messages.length) {
      groups.push({ ...group });
      group.messages = [];
    }
    group.title = title;
    if (
      showAll ||
      (isMessage(message) && !message.is_read) ||
      (isTask(message) && !message.is_done)
    )
      group.messages.push(message);
  }
  if (group.messages.length) groups.push(group);

  return (
    <>
      {groups.map(g => (
        <section key={g.title}>
          <h1>{g.title}</h1>
          {g.messages.map(message => children(message))}
        </section>
      ))}
    </>
  );
};

export default memo(MessagesGroups) as typeof MessagesGroups;
