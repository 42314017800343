import { FC, CSSProperties } from 'react';

interface IOwnProps {
  style?: CSSProperties;
  className?: string;
  fillColor?: string;
  onClick?: () => void;
}

const IconIdleTruck: FC<IOwnProps> = ({
  style,
  className,
  fillColor = '#2C6AF6',
  onClick,
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
      onClick={onClick}
    >
      <circle cx="12" cy="10" r="8" fill="white" />
      <circle cx="12" cy="10" r="4" fill={fillColor} />
    </svg>
  );
};

export default IconIdleTruck;
