import { FC } from 'react';

interface IOwnProps {
  className?: string;
  fillColor?: string;
}

const IconWheel: FC<IOwnProps> = ({ className, fillColor = '#FFB21D' }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="8" cy="8" r="7" stroke={fillColor} strokeWidth="2" />
      <path
        d="M10 8C10 9.10457 9.10457 10 8 10M10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8M10 8H15M8 10C6.89543 10 6 9.10457 6 8M8 10V15M6 8H1"
        stroke={fillColor}
        strokeWidth="2"
      />
    </svg>
  );
};

export default IconWheel;
