import { FC, useEffect, KeyboardEvent } from 'react';

import clsx from 'clsx';
import { WrappedFieldMetaProps, WrappedFieldInputProps } from 'redux-form';

import { IconCheck } from 'assets/icons/components';
import { getAttrName } from 'hooks/useTestAttrRef';

import { COLORS_PALETTE_LIST, DEFAULT_COLOR_PALETTE } from './_config';

import styles from './ColorsPalette.module.scss';

type OwnProps = {
  id: string;
  className: string;
};
type Props = Partial<OwnProps & WrappedFieldInputProps & WrappedFieldMetaProps>;

const ColorsPalette: FC<Props> = ({ id, className, value, onChange }) => {
  const handleColorClick = (val: string) => {
    if (val && onChange && val !== value) onChange(val);
  };

  const handleKeyDown = (val: string) => (e: KeyboardEvent<HTMLDivElement>) => {
    const { key } = e;

    switch (key) {
      case 'Enter':
        handleColorClick(val);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!value && onChange) onChange(DEFAULT_COLOR_PALETTE);
  }, [value, DEFAULT_COLOR_PALETTE]);

  return (
    <div
      className={clsx(styles.root, className)}
      id={id}
      data-test="colors_palette"
    >
      {COLORS_PALETTE_LIST.map(color => {
        const isDefaultColor = color === DEFAULT_COLOR_PALETTE;

        return (
          <div
            key={color}
            data-name={color}
            className={clsx(styles.color, {
              [styles.defaultColor]: isDefaultColor,
            })}
            style={{ backgroundColor: `var(${color})` }}
            data-test={getAttrName(
              color.replaceAll(/([A-Z])/g, `_${'$1'.toLowerCase()}`),
            )}
            onClick={() => handleColorClick(color)}
            onKeyDown={handleKeyDown(color)}
            tabIndex={0}
            role="button"
          >
            {value === color && (
              <IconCheck
                fillColor={isDefaultColor ? 'var(--secondaryBg)' : 'white'}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ColorsPalette;
