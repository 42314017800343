import { IImportFileReducer } from './importFileModel';
import {
  IMPORT_FILE,
  IMPORT_FILE_SUCCESS,
  IMPORT_FILE_FAIL,
  importFilesActionsType,
  IMPORT_FILE_MATCH_FIELDS_FAIL,
  IMPORT_FILE_START_CREATE_FAIL,
} from './importFilesTypes';

const initialState: IImportFileReducer = {
  id: 0,
  company_id: 0,
  manager_id: 0,
  file: {
    url: '',
  },
  fields: [],
  matched_fields: {},
  status: '',
  created_at: '',
  updated_at: '',
  destination_fields: [],
  _pending: false,
  _loaded: false,
  _error: null,
};

const importFileReducers = (
  state = initialState,
  action: importFilesActionsType,
): IImportFileReducer => {
  switch (action.type) {
    case IMPORT_FILE: {
      return {
        ...state,
        _error: null,
      };
    }
    case IMPORT_FILE_SUCCESS: {
      return { ...state, ...action.payload };
    }
    case IMPORT_FILE_FAIL: {
      return { ...state, _error: action.payload };
    }
    case IMPORT_FILE_MATCH_FIELDS_FAIL: {
      return { ...state, _error: action.payload };
    }
    case IMPORT_FILE_START_CREATE_FAIL: {
      return { ...state, _error: action.payload };
    }
    default:
      return { ...state };
  }
};

export default importFileReducers;
