import { useState } from 'react';

import { Snackbar, IconButton } from '@mui/material';
import clsx from 'clsx';

import { IconClose } from 'assets/icons/components';

import styles from './Message.module.scss';

type Props = {
  message: string | null;
  type?: string;
};

/**
 * @param message - message to display
 * @param type - type of message: 'error'
 * @returns
 */
const Message = ({ message, type = 'error' }: Props) => {
  const [open, setOpen] = useState(true);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason !== 'clickaway') setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      className={clsx({ [styles[type]]: type })}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">{message}</span>}
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          className={styles.close}
          onClick={handleClose}
          size="large"
        >
          <IconClose />
        </IconButton>,
      ]}
    />
  );
};

export default Message;
