import { change, startSubmit, stopSubmit } from 'redux-form';
import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';

import { setPagesAction } from 'components/_common/Navigation/Pagination/_redux/paginationActions';
import { paginationSelector } from 'components/_common/Navigation/Pagination/_redux/paginationSelectors';
import {
  archiveDriverAction,
  changeDriverFileCategoryAction,
  createDriverAction,
  deleteDriverAction,
  deleteDriverFileAction,
  deleteDriverPhotoAction,
  getDriverAction,
  getDriversAction,
  setDriverRateAction,
  updateDriverAction,
  uploadDriverFilesAction,
  uploadDriverPhotoAction,
  getDriverRatesListAction,
} from 'components/HumanResources/TabDrivers/_redux/driversActions';
import {
  driverFileCategoryToFormData,
  driverFileToFormData,
} from 'components/HumanResources/TabDrivers/_redux/helpers';
import { API_VERSION } from 'constants/api';
import { fileToFormData } from 'utils/converters';
import { getErrors } from 'utils/helpers';
import api from 'utils/requests';

import {
  ARCHIVE_DRIVER,
  ARCHIVE_DRIVER_FAIL,
  ARCHIVE_DRIVER_SUCCESS,
  ASSIGN_DRIVER,
  ASSIGN_DRIVER_FAIL,
  ASSIGN_DRIVER_SUCCESS,
  CHANGE_DRIVER_FILE_CATEGORY,
  CHANGE_DRIVER_FILE_CATEGORY_FAIL,
  CHANGE_DRIVER_FILE_CATEGORY_SUCCESS,
  CREATE_DRIVER,
  CREATE_DRIVER_FAIL,
  CREATE_DRIVER_SUCCESS,
  DELETE_DRIVER,
  DELETE_DRIVER_FAIL,
  DELETE_DRIVER_FILE,
  DELETE_DRIVER_FILE_FAIL,
  DELETE_DRIVER_FILE_SUCCESS,
  DELETE_DRIVER_PHOTO,
  DELETE_DRIVER_PHOTO_FAIL,
  DELETE_DRIVER_PHOTO_SUCCESS,
  DELETE_DRIVER_SUCCESS,
  GET_DRIVER,
  GET_DRIVER_FAIL,
  GET_DRIVER_SUCCESS,
  GET_DRIVERS,
  GET_DRIVERS_FAIL,
  GET_DRIVERS_SUCCESS,
  ISetDriverRateActionFail,
  ISetDriverRateActionSuccess,
  SET_DRIVER_RATE,
  SET_DRIVER_RATE_FAIL,
  SET_DRIVER_RATE_SUCCESS,
  UPDATE_DRIVER,
  UPDATE_DRIVER_FAIL,
  UPDATE_DRIVER_SUCCESS,
  UPLOAD_DRIVER_FILES,
  UPLOAD_DRIVER_FILES_FAIL,
  UPLOAD_DRIVER_FILES_SUCCESS,
  UPLOAD_DRIVER_PHOTO,
  UPLOAD_DRIVER_PHOTO_FAIL,
  UPLOAD_DRIVER_PHOTO_SUCCESS,
  GET_DRIVER_RATES_LIST,
  GET_DRIVER_RATES_LIST_SUCCESS,
  GET_DRIVER_RATES_LIST_FAIL,
  IGetDriverRatesListActionSuccess,
  IGetDriverRatesListActionFail,
  IFuelCompany,
  GET_FUEL_COMPANIES,
  GET_FUEL_COMPANIES_SUCCESS,
  GET_FUEL_COMPANIES_FAIL,
} from './driversTypes';

function* getDriversSaga({
  query = '',
  config,
  callback,
}: ReturnType<typeof getDriversAction>) {
  try {
    const { per_page, page } = yield select(paginationSelector);

    const { data } = yield call(() =>
      api.get(
        `/v1/fleets/drivers?page=${page}&per_page=${per_page}&stats=1${
          query || ''
        }`,
      ),
    );
    const { pagination } = data;

    yield put({
      type: GET_DRIVERS_SUCCESS,
      payload: {
        ...data,
        useSeparateHeapFlag: config && config.useSeparateHeapFlag,
      },
    });

    // Pagination set
    if (config && config.usePaginationFlag)
      yield put(setPagesAction(pagination && pagination.pages));

    if (callback) callback();
  } catch (e) {
    yield put({ type: GET_DRIVERS_FAIL, payload: e });
  }
}

function* getDriverSaga({ id }: ReturnType<typeof getDriverAction>) {
  try {
    const { data } = yield call(() => api.get(`/v1/fleets/drivers/${id}`));
    yield put({ type: GET_DRIVER_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_DRIVER_FAIL, payload: e });
  }
}

function* getDriverRatesListSaga({
  payload: {
    data: { id },
    config,
  },
}: ReturnType<typeof getDriverRatesListAction>) {
  try {
    const sortKey = (config && config.sort) || 'all_rates';
    const { per_page, page } = yield select(paginationSelector);
    const {
      data: { rates, pagination },
    } = yield call(() =>
      api.get(
        `/v1/fleets/drivers/${id}/orders_rates?page=${page}&per_page=${per_page}&rate_sort=${sortKey}`,
      ),
    );
    // Pagination set
    yield put(setPagesAction(pagination && pagination.pages));
    yield put<IGetDriverRatesListActionSuccess>({
      type: GET_DRIVER_RATES_LIST_SUCCESS,
      payload: { rates, pagination },
    });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<IGetDriverRatesListActionFail>({
        type: GET_DRIVER_RATES_LIST_FAIL,
        payload: e,
      });
  }
}

function* createDriverSaga({
  payload: { callback, values },
}: ReturnType<typeof createDriverAction>) {
  try {
    yield put(startSubmit('createAndUpdateDriverForm'));
    const { data } = yield call(() =>
      api.post('/v1/fleets/drivers', { driver: values }),
    );
    yield put({ type: CREATE_DRIVER_SUCCESS });
    yield put(stopSubmit('createAndUpdateDriverForm'));
    callback(data.driver.id);
  } catch (e) {
    yield put({ type: CREATE_DRIVER_FAIL, payload: e });
    if (api.isAxiosError(e))
      yield put(stopSubmit('createAndUpdateDriverForm', getErrors(e)));
  }
}

function* updateDriverSaga({
  payload: { callback, values },
}: ReturnType<typeof updateDriverAction>) {
  try {
    yield put(startSubmit('createAndUpdateDriverForm'));
    const {
      data: { driver: updatedDriver },
    } = yield call(() =>
      api.patch(`/v1/fleets/drivers/${values.id}`, { driver: values }),
    );
    yield put({ type: UPDATE_DRIVER_SUCCESS, payload: updatedDriver });
    yield put(stopSubmit('createAndUpdateDriverForm'));
    if (callback) callback();
  } catch (e) {
    yield put({ type: UPDATE_DRIVER_FAIL, payload: e });
    if (api.isAxiosError(e))
      yield put(stopSubmit('createAndUpdateDriverForm', getErrors(e)));
  }
}

function* assignDriverSaga({
  payload: { callback, values },
}: ReturnType<typeof updateDriverAction>) {
  try {
    yield put(startSubmit('assignOnDriverForm'));
    const { data } = yield call(() =>
      api.post('/v1/fleets/connections', { ...values }),
    );
    yield put({ type: ASSIGN_DRIVER_SUCCESS, payload: data.data });
    yield put(stopSubmit('assignOnDriverForm'));
    if (callback) callback();
  } catch (e) {
    yield put({ type: ASSIGN_DRIVER_FAIL, payload: e });
    if (api.isAxiosError(e))
      yield put(stopSubmit('assignOnDriverForm', getErrors(e)));
  }
}

function* archiveDriverSaga({
  payload: { id, ...values },
  callback,
}: ReturnType<typeof archiveDriverAction>) {
  try {
    yield put(startSubmit('archiveForm'));
    const { data } = yield call(() =>
      api.post(`/v1/fleets/drivers/${id}/archive`, values),
    );
    yield put({ type: ARCHIVE_DRIVER_SUCCESS, payload: { data, id } });
    yield put(stopSubmit('archiveForm'));
    if (callback) callback();
  } catch (e) {
    yield put({ type: ARCHIVE_DRIVER_FAIL, payload: e });
    if (api.isAxiosError(e)) yield put(stopSubmit('archiveForm', getErrors(e)));
  }
}

function* deleteDriverSaga({
  payload: { id, callback },
}: ReturnType<typeof deleteDriverAction>) {
  try {
    yield call(() => api.delete(`/v1/fleets/drivers/${id}`));
    yield put({ type: DELETE_DRIVER_SUCCESS });
    yield put(getDriversAction());
    if (callback) callback();
  } catch (e) {
    yield put({ type: DELETE_DRIVER_FAIL, payload: e });
  }
}

// DRIVER PHOTO START
function* uploadDriverPhotoSaga({
  payload: { driverId, photo, callback },
}: ReturnType<typeof uploadDriverPhotoAction>) {
  const url = driverId
    ? `/v1/fleets/drivers/${driverId}/photo`
    : `/v1/fleets/drivers/photos`;

  try {
    const { data } = yield call(() =>
      api.post(url, fileToFormData(photo, 'fleets_drivers_photo[file]'), {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    );
    const uploadedPhoto = { id: data.file.id, url: data.file.file_url_thumb };
    yield put(change('createAndUpdateDriverForm', 'photo', uploadedPhoto));
    yield put({ type: UPLOAD_DRIVER_PHOTO_SUCCESS });
    if (callback) yield put(callback);
  } catch (e) {
    yield put({ type: UPLOAD_DRIVER_PHOTO_FAIL, payload: e });
  }
}

function* deleteDriverPhotoSaga({
  payload: { driverId, photoId, callback },
}: ReturnType<typeof deleteDriverPhotoAction>) {
  const url = driverId
    ? `/v1/fleets/drivers/${driverId}/photo`
    : `/v1/fleets/drivers/photos/${photoId}`;

  try {
    yield call(() => api.delete(url));
    yield put(change('createAndUpdateDriverForm', 'photo', ''));
    yield put({ type: DELETE_DRIVER_PHOTO_SUCCESS });
    if (callback) yield put(callback);
  } catch (e) {
    yield put({ type: DELETE_DRIVER_PHOTO_FAIL, payload: e });
  }
}
// DRIVER PHOTO END

// DRIVER FILES START
function* uploadDriverFilesSaga({
  payload: { itemId: driverId, files },
}: ReturnType<typeof uploadDriverFilesAction>) {
  const url = driverId
    ? `/v1/fleets/drivers/${driverId}/files`
    : `/v1/fleets/drivers/files`;

  for (const fileObj of files) {
    yield call(function* foo() {
      try {
        const { file, fileCategory } = fileObj;
        const { data } = yield call(() =>
          api.post(
            url,
            driverFileToFormData({ file, fileCategory: fileCategory || 0 }),
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            },
          ),
        );
        yield put({ type: UPLOAD_DRIVER_FILES_SUCCESS, payload: data.file });
      } catch (e) {
        yield put({ type: UPLOAD_DRIVER_FILES_FAIL, payload: e });
      }
    });
  }
}

function* changeDriverFileCategorySaga({
  payload: { fileId, fileCategory, driverId },
}: ReturnType<typeof changeDriverFileCategoryAction>) {
  const url = driverId
    ? `/v1/fleets/drivers/${driverId}/files/${fileId}`
    : `/v1/fleets/drivers/files/${fileId}`;

  try {
    const { data } = yield call(() =>
      api.patch(url, driverFileCategoryToFormData(fileCategory), {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    );

    yield put({
      type: CHANGE_DRIVER_FILE_CATEGORY_SUCCESS,
      payload: data.file,
    });
  } catch (e) {
    yield put({ type: CHANGE_DRIVER_FILE_CATEGORY_FAIL, payload: e });
  }
}

function* deleteDriverFileSaga({
  payload: { fileId, itemId: driverId },
}: ReturnType<typeof deleteDriverFileAction>) {
  const url = driverId
    ? `/v1/fleets/drivers/${driverId}/files/${fileId}`
    : `/v1/fleets/drivers/files/${fileId}`;
  try {
    yield call(() => api.delete(url));
    yield put({ type: DELETE_DRIVER_FILE_SUCCESS, payload: fileId });
  } catch (e) {
    yield put({ type: DELETE_DRIVER_FILE_FAIL, payload: e });
  }
}
// DRIVER FILES END

// SET DRIVER RATE
function* setDriverRateSaga({
  payload: { orderId, driverId, ...driverRate },
}: ReturnType<typeof setDriverRateAction>) {
  try {
    yield call(() =>
      api.post(`/v1/orders/${orderId}/rate_drivers/${driverId}`, driverRate),
    );
    yield put<ISetDriverRateActionSuccess>({ type: SET_DRIVER_RATE_SUCCESS });
  } catch (e) {
    if (api.isAxiosError(e))
      yield put<ISetDriverRateActionFail>({
        type: SET_DRIVER_RATE_FAIL,
        payload: e,
      });
  }
}

function* getFuelCompanies() {
  try {
    const { companies } = yield call(() =>
      api.get(`/${API_VERSION}/fuel_companies`).then(res => res.data),
    );

    const resCompanies = companies.reduce(
      (acc: IFuelCompany[], item: IFuelCompany) => ({
        ...acc,
        [item.id]: item,
      }),
      {},
    );

    yield put({
      type: GET_FUEL_COMPANIES_SUCCESS,
      payload: resCompanies,
    });
  } catch (e) {
    yield put({
      type: GET_FUEL_COMPANIES_FAIL,
      payload: e,
    });
  }
}

// DRIVER INSPECTIONS

export default function* driversSaga() {
  yield takeLatest(GET_DRIVERS, getDriversSaga);
  yield takeLatest(GET_DRIVER_RATES_LIST, getDriverRatesListSaga);
  yield takeLatest(GET_DRIVER, getDriverSaga);
  yield takeLeading(CREATE_DRIVER, createDriverSaga);
  yield takeLatest(UPDATE_DRIVER, updateDriverSaga);
  yield takeLatest(DELETE_DRIVER, deleteDriverSaga);
  yield takeLatest(UPLOAD_DRIVER_FILES, uploadDriverFilesSaga);
  yield takeEvery(DELETE_DRIVER_FILE, deleteDriverFileSaga);
  yield takeEvery(CHANGE_DRIVER_FILE_CATEGORY, changeDriverFileCategorySaga);
  yield takeLatest(UPLOAD_DRIVER_PHOTO, uploadDriverPhotoSaga);
  yield takeLeading(DELETE_DRIVER_PHOTO, deleteDriverPhotoSaga);
  yield takeLeading(ASSIGN_DRIVER, assignDriverSaga);
  yield takeLeading(ARCHIVE_DRIVER, archiveDriverSaga);
  yield takeLatest(SET_DRIVER_RATE, setDriverRateSaga);
  yield takeLatest(GET_FUEL_COMPANIES, getFuelCompanies);
}
