type Props = {
  className?: string;
  fillColor?: string;
};

/**
 *
 * @param className
 * @param fillColor - default is #2C6AF6
 */
const IconCreditCard = ({ className, fillColor = '#2C6AF6' }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      className={className}
    >
      <path
        d="M15 5H1"
        stroke={fillColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9231 12H2.07692C1.48246 12 1 11.552 1 11V2C1 1.448 1.48246 1 2.07692 1H13.9231C14.5175 1 15 1.448 15 2V11C15 11.552 14.5175 12 13.9231 12Z"
        stroke={fillColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconCreditCard;
