import { FC, useMemo, useCallback } from 'react';

import clsx from 'clsx';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import { IconClose } from 'assets/icons/components';

import { MultiSelectImpl, Props as MultiSelectProps } from './MultiSelect';

import styles from './Select.module.scss';

type Props = MultiSelectProps & WrappedFieldInputProps & WrappedFieldMetaProps;

const MAX_CHIPS = 3;

const MultiChipSelect: FC<Partial<Props>> = ({
  options = {},
  placeholder = '',
  value,
  onChange,
  ...restProps
}) => {
  const valuesForCheckbox: number[] =
    (value && value.split(',').map((item: string) => +item)) || [];

  const onChangeImpl = useCallback(
    (values: number[]) => {
      if (onChange) onChange(values.join(','));
    },
    [onChange],
  );

  const handleDeleteChip = (e: React.MouseEvent<HTMLElement>, key: number) => {
    e.stopPropagation();
    onChangeImpl(valuesForCheckbox.filter((item: number) => item !== key));
  };

  const renderValues = useMemo(() => {
    if (valuesForCheckbox.length === 0) {
      return (
        <div className={styles.selectedWrapper}>
          <span className={styles.placeholder}>{placeholder}</span>
        </div>
      );
    }

    const chips = valuesForCheckbox.map(
      (key: number, i: number) =>
        options && (
          <div
            key={key}
            className={clsx(styles.chip, i >= MAX_CHIPS && styles.hidden)}
          >
            <span className={styles.selectedChip}>{options[key].name}</span>
            <span
              className={styles.deleteChip}
              data-id={key}
              onClick={e => handleDeleteChip(e, key)}
            >
              <IconClose width={16} height={16} />
            </span>
          </div>
        ),
    );

    return (
      <div className={styles.selectedWrapper}>
        {chips}
        {valuesForCheckbox.length > MAX_CHIPS && (
          <span className={styles.chip}>{`+ ${
            valuesForCheckbox.length - MAX_CHIPS
          }`}</span>
        )}
      </div>
    );
  }, [valuesForCheckbox, placeholder]);

  return (
    <MultiSelectImpl
      options={options}
      placeholder={placeholder}
      chip={renderValues}
      value={valuesForCheckbox}
      onChange={onChangeImpl}
      {...restProps}
    />
  );
};

export default MultiChipSelect;
