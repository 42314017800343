import { FC } from 'react';

import { useSelector } from 'react-redux';

import { userThemeSelector } from 'redux/userSettings/userSettingsSelectors';

type Props = {
  className?: string;
  strokeColor?: string;
};

const ImportFile: FC<Props> = ({ className, strokeColor }) => {
  const theme = useSelector(userThemeSelector);

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_65_2668)">
        <path
          d="M1 4.5V3C1 1.89543 1.89543 1 3 1H13C14.1046 1 15 1.89543 15 3V13C15 14.1046 14.1046 15 13 15H3C1.89543 15 1 14.1046 1 13V11.5"
          stroke={strokeColor || (theme === 'dark' ? '#fff' : '#2D2F36')}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M1 8H10M10 8L7 5M10 8L7 11"
          stroke={strokeColor || (theme === 'dark' ? '#fff' : '#2D2F36')}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_65_2668">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ImportFile;
