import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as reduxFormReducer } from 'redux-form';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import resourcesReducer from 'components/_common/FormElements/ResourcesAutocomplete/_redux/resourcesReducer';
import feedbackReducers from 'components/_common/Modal/FeedbackModal/_redux/feedbackReducers';
import mainSidebarReducers from 'components/_common/Navigation/MainSidebar/_redux/mainSidebarReducers';
import paginationReducers from 'components/_common/Navigation/Pagination/_redux/paginationReducers';
import searchReducers from 'components/_common/SearchPanel/_redux/searchReducers';
import accountingBillingReducers from 'components/Accounting/TabBilling/_redux/billingReducers';
import payrollItemsReducers from 'components/Accounting/TabPayrollItems/_redux/payrollItemsReducers';
import payrollsReducers from 'components/Accounting/TabPayrolls/_redux/payrollsReducers';
import accountingReportsReducers from 'components/Accounting/TabReports/_redux/reportsReducers';
import authReducer from 'components/Auth/_redux/authReducers';
import brokersReducer from 'components/Dispatch/TabBrokers/_redux/brokersReducer';
import calendarReducers from 'components/Dispatch/TabCalendar/_redux/calendarReducers';
import facilitiesReducer from 'components/Dispatch/TabFacilities/_redux/facilitiesReducer';
import ordersReducers from 'components/Dispatch/TabOrders/_redux/ordersReducers';
import overviewReducer from 'components/Dispatch/TabReports/Overview/_redux/overviewReducer';
import fleetGroupReducer from 'components/Fleet/_redux/fleetGroupReducers';
import allTrucksMaintenanceReducers from 'components/Fleet/AllTrucksMaintenance/_redux/allTrucksMaintenanceReducers';
import claimsReducers from 'components/Fleet/TabClaims/_redux/claimsReducers';
import geoFencesReducers from 'components/Fleet/TabGeoFences/_redux/geoFencesReducers';
import allInspectionsReducer from 'components/Fleet/TabInspections/AllInspectionsTab/_redux/allInspectionsReducer';
import tollsReducers from 'components/Fleet/TabTolls/_redux/tollsReducers';
import trailersReducers from 'components/Fleet/TabTrailers/_redux/trailersReducers';
import trailersInspectionReducers from 'components/Fleet/TabTrailers/TrailerProfile/TrailerInspections/_redux/trailerInspectionsReducers';
import trucksReducers from 'components/Fleet/TabTrucks/_redux/trucksReducers';
import trucksInspectionReducers from 'components/Fleet/TabTrucks/TruckProfile/TruckInspections/_redux/truckInspectionsReducers';
import applicantsReducers from 'components/HumanResources/TabApplicants/_redux/applicantsReducers';
import applicantsArchiveReducers from 'components/HumanResources/TabApplicantsArchive/_redux/applicantsArchiveReducers';
import driversReducers from 'components/HumanResources/TabDrivers/_redux/driversReducers';
import employeesReducers from 'components/HumanResources/TabEmployees/_redux/employeesReducers';
import upcomingDriverReducer from 'components/HumanResources/TabUpcomingDrivers/_redux/upcomingDriverReducer';
import categoriesReducer from 'components/Inventory/TabCategories/_redux/categoriesReducers';
import itemsReducer from 'components/Inventory/TabItems/_redux/itemsReducers';
import locationsReducers from 'components/Inventory/TabLocations/_redux/locationsReducers';
import vendorsReducer from 'components/Inventory/TabVendors/_redux/vendorsReducers';
import accidentsReducers from 'components/Safety/TabAccidents/_redux/accidentsReducers';
import accidentsStatisticsReducers from 'components/Safety/TabAccidents/AccidentsStatistics/_redux/accidentsStatisticsReducers';
import settingsReducers from 'components/Settings/_redux/settingsReducers';
import { orientationReducers } from 'components/Settings/TabOrientationProcessSettings/_redux/orientationReducer';
import companySettingsReduces from 'components/WiseCheck/TabSettings/_redux/companySettingsReducers';
import setHeaderMiddleware from 'redux/middlewares/setHeaderMiddleware';
import rootSaga from 'redux/rootSaga';
import userSettingsReducers from 'redux/userSettings/userSettingsReducers';
import googleReducers from 'services/google/_redux/googleReducers';

import internalCompanyReducer from '../components/AdminPanel/InternalCompany/_redux/internalCompanyReducer';
import rolesAndPermissionReducer from '../components/AdminPanel/RolesAndPermissions/_redux/RolesAndPermissionsReducer';
import chatReducer from '../components/Chat/_redux/chatReducers';
import tandemInspectionReducers from '../components/Fleet/TabInspections/TandemInspectionTab/_redux/tandemInspectionsReducers';
import leadReducer from '../components/HumanResources/TabLeads/_redux/leadsReducer';
import scriptsReducer from '../components/Settings/TabScripts/_redux/scriptsReducer/scriptsReducer';
import importFileReducers from './importFiles/importFileReducers';
import socketMiddleware from './middlewares/socketMiddleware';
import socketReducer from './socket/socketReducers';

const userSettingsPersistConfig = {
  key: 'settings',
  storage,
};

const authPersistConfig = {
  key: 'profile',
  storage,
  whitelist: ['token', 'valid_at'],
};

const paginationPersistConfig = {
  key: 'pagination',
  storage,
  whitelist: ['per_page'],
};

const fleetGroupPersistConfig = {
  key: 'fleetGroup',
  storage,
  whitelist: ['viewKey'],
};

const tollsPersistConfig = {
  key: 'tolls',
  storage,
  whitelist: ['dateRange'],
};

const googlePersistConfig = {
  key: 'google',
  storage,
  whitelist: ['mapTypeId'],
};

const mainSidebarPersistConfig = {
  key: 'mainSidebar',
  storage,
  whitelist: ['collapsedFlag'],
};

const rootReducer = combineReducers({
  // Common
  form: reduxFormReducer,
  settings: persistReducer(userSettingsPersistConfig, userSettingsReducers),
  profile: persistReducer(authPersistConfig, authReducer),
  mainSidebar: persistReducer(mainSidebarPersistConfig, mainSidebarReducers),
  search: searchReducers,
  pagination: persistReducer(paginationPersistConfig, paginationReducers),
  socket: socketReducer,
  google: persistReducer(googlePersistConfig, googleReducers),
  feedback: feedbackReducers,
  resources: resourcesReducer,

  // Fleet
  fleetGroup: persistReducer(fleetGroupPersistConfig, fleetGroupReducer),
  trucks: trucksReducers,
  accidents: accidentsReducers,
  accidentsStatistics: accidentsStatisticsReducers,
  allTrucksMaintenance: allTrucksMaintenanceReducers,
  trucksInspection: trucksInspectionReducers,
  allInspections: allInspectionsReducer,
  trailers: trailersReducers,
  trailersInspection: trailersInspectionReducers,
  tandemInspection: tandemInspectionReducers,
  claims: claimsReducers,
  geoFences: geoFencesReducers,
  tolls: persistReducer(tollsPersistConfig, tollsReducers),

  // Dispatch
  orders: ordersReducers,
  calendar: calendarReducers,
  brokers: brokersReducer,
  facilities: facilitiesReducer,
  overview: overviewReducer,

  // HR
  employees: employeesReducers,
  upcomingDriver: upcomingDriverReducer,
  drivers: driversReducers,
  applicants: applicantsReducers,
  applicantsArchive: applicantsArchiveReducers,

  // Accounting
  payrolls: payrollsReducers,
  payrollItems: payrollItemsReducers,
  accountingReports: accountingReportsReducers,
  accountingBilling: accountingBillingReducers,

  // Inventory
  locations: locationsReducers,
  categories: categoriesReducer,
  items: itemsReducer,
  vendors: vendorsReducer,

  // Settings
  systemSettings: settingsReducers,

  // WiseCheck
  companySettings: companySettingsReduces,

  // Orientation
  orientation: orientationReducers,

  // Chat
  chat: chatReducer,

  // Import Files
  importFiles: importFileReducers,
  internalCompany: internalCompanyReducer,
  rolesAndPermission: rolesAndPermissionReducer,
  scripts: scriptsReducer,
  lead: leadReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

const sagaMiddleware = createSagaMiddleware();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const middleware: any[] = [
  setHeaderMiddleware,
  sagaMiddleware,
  socketMiddleware,
];
const enhancer = composeWithDevTools(applyMiddleware(...middleware));

export const store = createStore(rootReducer, enhancer);
export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
