import { FC, ChangeEvent, useRef, useEffect, useState, useMemo } from 'react';

import { Fade } from '@mui/material';
import clsx from 'clsx';
import { isEmpty } from 'ramda';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import { ISelectOption } from 'components/_common/FormElements/FormField/Select/_models/selectModels';
import useOnClickOutside from 'hooks/useOnClickOutside';
import useTestAttrRef, { getAttrName } from 'hooks/useTestAttrRef';
import useToggle from 'hooks/useToggle';

import DropDown from '../../FormField/Select/DropDown/DropDown';
import Option from '../../FormField/Select/Option/Option';

import styles from './GoogleAutocomplete.module.scss';

interface IOwnProps {
  placeholder: string;
  className: string;
  id: string;
  options: { [key: string]: ISelectOption };
  disabled: boolean;
  labelText?: string;
  dataTestPrefix?: string;
  onLocationSelect?: (placeId: string) => void;
}

type KeyboardEventType = React.KeyboardEvent<HTMLDivElement>;
type Props = IOwnProps & WrappedFieldInputProps & WrappedFieldMetaProps;

const GoogleAutocomplete: FC<Partial<Props>> = ({
  placeholder,
  className,
  options,
  disabled,
  onChange,
  value,
  touched,
  error,
  name,
  labelText,
  dataTestPrefix,
  onLocationSelect,
}) => {
  const { open, isOpen, close } = useToggle();
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useTestAttrRef<HTMLInputElement>(
    null,
    labelText &&
      getAttrName((dataTestPrefix ? `${dataTestPrefix}_` : '') + labelText),
  );

  const [firstOption, optionsLength] = useMemo(
    () => [
      options?.[Object.keys(options)[0]],
      options ? Object.keys(options).length : 0,
    ],
    [options],
  );

  const handleOnSelect = (option?: ISelectOption) => {
    if (onLocationSelect) onLocationSelect(option?.id ? `${option.id}` : '');
  };

  const handleOnBlur = () => {
    if (isOpen || (value && !firstOption)) handleOnSelect(firstOption);
    close();
  };

  useOnClickOutside({ ref: containerRef, callback: handleOnBlur });

  const [disabledHint, setDisabledHint] = useState<boolean>(false);
  const [cursor, setCursor] = useState<number>(-1);

  const handleClickField = () => {
    if (disabled) {
      setDisabledHint(true);
    }
  };

  const focusInput = () => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isOpen) open();
    if (onChange) onChange(e.target.value);
  };

  useEffect(() => {
    setCursor(-1);
    if (onChange) onChange(value);
  }, [onChange, value]);

  const handleKeyDown = (event: KeyboardEventType) => {
    event.preventDefault();
    if (options) {
      switch (event.key) {
        case 'ArrowUp':
          setCursor(cursor === 0 ? optionsLength - 1 : cursor - 1);
          break;
        case 'ArrowDown':
          setCursor(cursor === optionsLength - 1 ? 0 : cursor + 1);
          break;
        case 'Enter':
          setCursor(-1);
          focusInput();
          break;
        case 'Escape':
          close();
          focusInput();
          break;
        default:
          break;
      }
    }
  };

  const handleInputKeyDown = (event: KeyboardEventType) => {
    const { key } = event;

    if ((key === 'ArrowDown' || key === 'ArrowUp') && !isOpen) {
      open();
    }

    switch (key) {
      case 'Tab':
        handleOnBlur();
        break;
      case 'Enter':
        if (isOpen) {
          handleOnSelect(firstOption);
          close();
        }
        break;
      case 'ArrowDown':
        if (options) {
          if (!isOpen) {
            setCursor(-1);
          } else {
            // if autoSelectFirstItem => first item already selected, so we select second item
            setCursor(optionsLength > 1 ? 1 : 0);
          }
        }
        break;
      case 'ArrowUp':
        if (options) {
          if (isOpen) {
            setCursor(cursor <= 0 ? optionsLength - 1 : cursor - 1);
          } else {
            setCursor(-1);
          }
        }
        break;
      default:
        break;
    }
  };

  const handleClose = (withInputFocus?: boolean) => {
    close();
    if (withInputFocus) {
      focusInput();
    }
  };

  return (
    <div
      className={clsx(styles.root, {
        [styles.rootDisabled]: disabled,
      })}
      onClick={handleClickField}
      ref={containerRef}
    >
      <input
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        name={name}
        value={value || ''}
        disabled={disabled}
        autoComplete="off"
        onChange={handleOnChange}
        onKeyDown={handleInputKeyDown}
        className={clsx(styles.input, className, {
          [styles.hasError]: touched && !!error,
          [styles.disabled]: disabled,
        })}
      />

      {!isEmpty(options) && value && (
        <div onKeyDown={handleKeyDown}>
          <DropDown isOpen={isOpen} options={options}>
            {isOpen &&
              !!options &&
              Object.keys(options).map((key, index) => {
                return (
                  <Option
                    key={`${index}_${options[key].id}`}
                    onSelectOption={handleOnSelect}
                    option={options[key]}
                    toggleVisibility={handleClose}
                    inputValue={value.name || ''}
                    focus={cursor === index}
                    // highlight only first item
                    highlight={cursor === -1 && index === 0}
                  />
                );
              })}
          </DropDown>
        </div>
      )}

      <Fade in={isOpen && !options && !!value}>
        <ul className={styles.dropdown}>
          <li>{`No results found for "${value}"`}</li>
        </ul>
      </Fade>
      {disabledHint && (
        <span className={styles.disabledHint}>Read only field</span>
      )}
    </div>
  );
};

export default GoogleAutocomplete;
