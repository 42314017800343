import { IPagination } from 'components/_common/Navigation/Pagination/_redux/paginationTypes';
import { LOGOUT } from 'components/Auth/_redux/authTypes';
import { IInspectionFile } from 'components/Fleet/_models/commonModels';
import { defaultInspectionData } from 'components/Fleet/TabInspections/_helpers/defaultInspectionData';
import { CLEAR_INSPECTION_ERROR } from 'components/Fleet/TabInspections/_redux/tabInspectionsTypes';
import { ITruckInspection } from 'components/Fleet/TabTrucks/TruckProfile/TruckInspections/_models/truckInspection';
import {
  ADD_TRUCK_INSPECTION_PHOTO_COMMENT,
  ADD_TRUCK_INSPECTION_PHOTO_COMMENT_FAIL,
  ADD_TRUCK_INSPECTION_PHOTO_COMMENT_SUCCESS,
  CLEAR_CURRENT_TRUCK_INSPECTION,
  DELETE_TRUCK_INSPECTION,
  DELETE_TRUCK_INSPECTION_FAIL,
  DELETE_TRUCK_INSPECTION_PHOTO,
  DELETE_TRUCK_INSPECTION_PHOTO_COMMENT,
  DELETE_TRUCK_INSPECTION_PHOTO_COMMENT_FAIL,
  DELETE_TRUCK_INSPECTION_PHOTO_COMMENT_SUCCESS,
  DELETE_TRUCK_INSPECTION_PHOTO_FAIL,
  DELETE_TRUCK_INSPECTION_PHOTO_SUCCESS,
  DELETE_TRUCK_INSPECTION_SUCCESS,
  DRAFT_TRUCK_INSPECTION,
  DRAFT_TRUCK_INSPECTION_SUCCESS,
  DRAFT_TRUCK_INSPECTION_FAIL,
  GET_TRUCK_INSPECTION,
  GET_TRUCK_INSPECTION_DRAFT,
  GET_TRUCK_INSPECTION_DRAFT_FAIL,
  GET_TRUCK_INSPECTION_DRAFT_SUCCESS,
  GET_TRUCK_INSPECTION_FAIL,
  GET_TRUCK_INSPECTION_SUCCESS,
  INITIALIZE_DRAFT_TRUCK_INSPECTION,
  INITIALIZE_DRAFT_TRUCK_INSPECTION_FAIL,
  INITIALIZE_DRAFT_TRUCK_INSPECTION_SUCCESS,
  TruckInspectionActions,
  UNMOUNT_TRUCK_INSPECTION_FORM,
  UPDATE_TRUCK_INSPECTION_PHOTO_COMMENT,
  UPDATE_TRUCK_INSPECTION_PHOTO_COMMENT_FAIL,
  UPDATE_TRUCK_INSPECTION_PHOTO_COMMENT_SUCCESS,
  UPLOAD_TRUCK_INSPECTION_PHOTO,
  UPLOAD_TRUCK_INSPECTION_PHOTO_FAIL,
  UPLOAD_TRUCK_INSPECTION_PHOTO_SUCCESS,
} from 'components/Fleet/TabTrucks/TruckProfile/TruckInspections/_redux/truckInspectionsTypes';
import { IWithError, IWithLoaded, IWithPending } from 'utils/models';

import { IInspection } from '../../../../TabInspections/_models/vehicleInspectionModels';

export interface ITrucksInspectionState
  extends IWithPending,
    IWithLoaded,
    IWithError {
  pagination: IPagination | null;
  files: IInspectionFile[];
  currentTruckInspection: {
    inspection: IInspection;
    vehicle_inspections: ITruckInspection[];
  };
}

const initialState: ITrucksInspectionState = {
  pagination: null,
  files: [],
  currentTruckInspection: {
    inspection: { ...defaultInspectionData },
    vehicle_inspections: [],
  },
  _pending: false,
  _loaded: false,
  _error: null,
};

const trucksInspectionReducers = (
  state = initialState,
  action: TruckInspectionActions,
): ITrucksInspectionState => {
  switch (action.type) {
    // DELETE_TRUCK_INSPECTION
    case DELETE_TRUCK_INSPECTION:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case DELETE_TRUCK_INSPECTION_SUCCESS:
      return {
        ...state,
        currentTruckInspection: {
          inspection: { ...defaultInspectionData },
          vehicle_inspections: [],
        },
        files: [],
        _loaded: true,
        _pending: false,
        _error: null,
      };
    case DELETE_TRUCK_INSPECTION_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };

    case GET_TRUCK_INSPECTION:
      return {
        ...state,
        // clear inspection if id is changed
        currentTruckInspection:
          action.payload.data.inspectionId ===
          state.currentTruckInspection.inspection.id
            ? state.currentTruckInspection
            : initialState.currentTruckInspection,
        _error: null,
      };
    case GET_TRUCK_INSPECTION_SUCCESS:
      return {
        ...state,
        currentTruckInspection: {
          inspection: action.payload.inspection,
          vehicle_inspections: action.payload.inspection.vehicle_inspections,
        },
        // _loaded: true,
        // _pending: false,
        _error: null,
      };
    case GET_TRUCK_INSPECTION_FAIL:
      return {
        ...state,
        _loaded: false,
        // _pending: false,
        _error: action.payload,
      };

    // GET_TRUCK_INSPECTION_DRAFT
    case GET_TRUCK_INSPECTION_DRAFT:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case GET_TRUCK_INSPECTION_DRAFT_SUCCESS:
      return {
        ...state,
        currentTruckInspection: {
          inspection: action.payload.inspection,
          vehicle_inspections: action.payload.inspection.vehicle_inspections,
        },
        files: action.payload.inspection.vehicle_inspections[0]?.files || [],
        _loaded: true,
        _pending: false,
        _error: null,
      };
    case GET_TRUCK_INSPECTION_DRAFT_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };
    case DRAFT_TRUCK_INSPECTION:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };

    case DRAFT_TRUCK_INSPECTION_SUCCESS:
      return {
        ...state,
        _error: null,
        currentTruckInspection: {
          ...state.currentTruckInspection,
          inspection: {
            ...state.currentTruckInspection.inspection,
            inventories_data: action.payload.inventories_data,
          },
        },
      };

    case DRAFT_TRUCK_INSPECTION_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };
    case INITIALIZE_DRAFT_TRUCK_INSPECTION:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case INITIALIZE_DRAFT_TRUCK_INSPECTION_SUCCESS:
      return {
        ...state,
        currentTruckInspection: {
          inspection: action.payload.inspection,
          vehicle_inspections: action.payload.inspection.vehicle_inspections,
        },
        _loaded: true,
        _pending: false,
        _error: null,
      };
    case INITIALIZE_DRAFT_TRUCK_INSPECTION_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };
    // CLEAR_CURRENT_TRUCK_INSPECTION
    case CLEAR_CURRENT_TRUCK_INSPECTION:
      return {
        ...state,
        files: [],
        currentTruckInspection: {
          inspection: { ...defaultInspectionData },
          vehicle_inspections: [],
        },
        _error: null,
      };

    // UPLOAD_TRUCK_INSPECTION_PHOTO
    case UPLOAD_TRUCK_INSPECTION_PHOTO:
      return {
        ...state,
        files: [
          ...state.files,
          { ...action.payload.data, loading: true },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ] as any,
        _loaded: false,
        _pending: true,
        _error: null,
      };

    case UPLOAD_TRUCK_INSPECTION_PHOTO_SUCCESS: {
      const { payload, uploadIndex } = action;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const files = state.files.map((file: any) =>
        file.uploadIndex === uploadIndex
          ? {
              ...payload,
              comments: [],
              loading: false,
            }
          : file,
      );

      return {
        ...state,
        files,
        _loaded: true,
        _pending: false,
        _error: null,
      };
    }
    case UPLOAD_TRUCK_INSPECTION_PHOTO_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };

    // DELETE_TRUCK_INSPECTION_PHOTO
    case DELETE_TRUCK_INSPECTION_PHOTO:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case DELETE_TRUCK_INSPECTION_PHOTO_SUCCESS:
      return {
        ...state,
        files: state.files.filter(file => file.id !== action.payload),
        _loaded: true,
        _pending: false,
        _error: null,
      };
    case DELETE_TRUCK_INSPECTION_PHOTO_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };

    // ADD_TRUCK_INSPECTION_PHOTO_COMMENT
    case ADD_TRUCK_INSPECTION_PHOTO_COMMENT:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case ADD_TRUCK_INSPECTION_PHOTO_COMMENT_SUCCESS:
      return {
        ...state,
        files: state.files.map(file =>
          file.id === action.payload.fileId
            ? { ...file, comments: [...file.comments, action.payload.comment] }
            : file,
        ),
        _loaded: true,
        _pending: false,
        _error: null,
      };
    case ADD_TRUCK_INSPECTION_PHOTO_COMMENT_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };

    // UPDATE_TRUCK_INSPECTION_PHOTO_COMMENT
    case UPDATE_TRUCK_INSPECTION_PHOTO_COMMENT:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case UPDATE_TRUCK_INSPECTION_PHOTO_COMMENT_SUCCESS:
      return {
        ...state,
        files: state.files.map(file =>
          file.id === action.payload.fileId
            ? {
                ...file,
                comments: file.comments.map(comment =>
                  comment.id === action.payload.comment.id
                    ? action.payload.comment
                    : comment,
                ),
              }
            : file,
        ),
        _loaded: true,
        _pending: false,
        _error: null,
      };
    case UPDATE_TRUCK_INSPECTION_PHOTO_COMMENT_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };

    // DELETE_TRUCK_INSPECTION_PHOTO_COMMENT
    case DELETE_TRUCK_INSPECTION_PHOTO_COMMENT:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case DELETE_TRUCK_INSPECTION_PHOTO_COMMENT_SUCCESS:
      return {
        ...state,
        files: state.files.map(file =>
          file.id === action.payload.fileId
            ? {
                ...file,
                comments: file.comments.filter(
                  comment => comment.id !== action.payload.commentId,
                ),
              }
            : file,
        ),
        _loaded: true,
        _pending: false,
        _error: null,
      };
    case DELETE_TRUCK_INSPECTION_PHOTO_COMMENT_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };

    // OTHER
    case UNMOUNT_TRUCK_INSPECTION_FORM:
      return {
        ...state,
        files: [],
        currentTruckInspection: {
          inspection: { ...defaultInspectionData },
          vehicle_inspections: [],
        },
        _error: null,
      };

    case CLEAR_INSPECTION_ERROR:
      return {
        ...state,
        _error: null,
      };

    case LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default trucksInspectionReducers;
