import { IPagination } from 'components/_common/Navigation/Pagination/_redux/paginationTypes';
import { LOGOUT } from 'components/Auth/_redux/authTypes';
import { IInspectionFile } from 'components/Fleet/_models/commonModels';
import { defaultInspectionData } from 'components/Fleet/TabInspections/_helpers/defaultInspectionData';
import { CLEAR_INSPECTION_ERROR } from 'components/Fleet/TabInspections/_redux/tabInspectionsTypes';
import { ITrailerInspection } from 'components/Fleet/TabTrailers/TrailerProfile/TrailerInspections/_models/trailerInspection';
import {
  ADD_TRAILER_INSPECTION_PHOTO_COMMENT,
  ADD_TRAILER_INSPECTION_PHOTO_COMMENT_FAIL,
  ADD_TRAILER_INSPECTION_PHOTO_COMMENT_SUCCESS,
  CLEAR_CURRENT_TRAILER_INSPECTION,
  DELETE_TRAILER_INSPECTION,
  DELETE_TRAILER_INSPECTION_FAIL,
  DELETE_TRAILER_INSPECTION_PHOTO,
  DELETE_TRAILER_INSPECTION_PHOTO_COMMENT,
  DELETE_TRAILER_INSPECTION_PHOTO_COMMENT_FAIL,
  DELETE_TRAILER_INSPECTION_PHOTO_COMMENT_SUCCESS,
  DELETE_TRAILER_INSPECTION_PHOTO_FAIL,
  DELETE_TRAILER_INSPECTION_PHOTO_SUCCESS,
  DELETE_TRAILER_INSPECTION_SUCCESS,
  DRAFT_TRAILER_INSPECTION,
  DRAFT_TRAILER_INSPECTION_SUCCESS,
  DRAFT_TRAILER_INSPECTION_FAIL,
  GET_TRAILER_INSPECTION,
  GET_TRAILER_INSPECTION_DRAFT,
  GET_TRAILER_INSPECTION_DRAFT_FAIL,
  GET_TRAILER_INSPECTION_DRAFT_SUCCESS,
  GET_TRAILER_INSPECTION_FAIL,
  GET_TRAILER_INSPECTION_SUCCESS,
  INITIALIZE_DRAFT_TRAILER_INSPECTION,
  INITIALIZE_DRAFT_TRAILER_INSPECTION_FAIL,
  INITIALIZE_DRAFT_TRAILER_INSPECTION_SUCCESS,
  TrailerInspectionActions,
  UNMOUNT_TRAILER_INSPECTION_FORM,
  UPDATE_TRAILER_INSPECTION_PHOTO_COMMENT,
  UPDATE_TRAILER_INSPECTION_PHOTO_COMMENT_FAIL,
  UPDATE_TRAILER_INSPECTION_PHOTO_COMMENT_SUCCESS,
  UPLOAD_TRAILER_INSPECTION_PHOTO,
  UPLOAD_TRAILER_INSPECTION_PHOTO_FAIL,
  UPLOAD_TRAILER_INSPECTION_PHOTO_SUCCESS,
} from 'components/Fleet/TabTrailers/TrailerProfile/TrailerInspections/_redux/trailerInspectionsTypes';
import { IWithError, IWithLoaded, IWithPending } from 'utils/models';

import { IInspection } from '../../../../TabInspections/_models/vehicleInspectionModels';

export interface ITrailersInspectionState
  extends IWithPending,
    IWithLoaded,
    IWithError {
  pagination: IPagination | null;
  files: IInspectionFile[];
  currentTrailerInspection: {
    inspection: IInspection;
    vehicle_inspections: ITrailerInspection[] | [];
  };
}

const initialState: ITrailersInspectionState = {
  pagination: null,
  files: [],
  currentTrailerInspection: {
    inspection: { ...defaultInspectionData },
    vehicle_inspections: [],
  },
  _pending: false,
  _loaded: false,
  _error: null,
};

const trailersInspectionReducers = (
  state = initialState,
  action: TrailerInspectionActions,
): ITrailersInspectionState => {
  switch (action.type) {
    // DELETE_TRAILER_INSPECTION
    case DELETE_TRAILER_INSPECTION:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case DELETE_TRAILER_INSPECTION_SUCCESS:
      return {
        ...state,
        currentTrailerInspection: {
          inspection: { ...defaultInspectionData },
          vehicle_inspections: [],
        },
        files: [],
        _loaded: true,
        _pending: false,
        _error: null,
      };
    case DELETE_TRAILER_INSPECTION_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };

    // GET_TRAILER_INSPECTION
    case GET_TRAILER_INSPECTION:
      return {
        ...state,
        // clear inspection if id is changed
        currentTrailerInspection:
          action.payload.data.inspectionId ===
          state.currentTrailerInspection.inspection.id
            ? state.currentTrailerInspection
            : initialState.currentTrailerInspection,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case GET_TRAILER_INSPECTION_SUCCESS:
      return {
        ...state,
        currentTrailerInspection: {
          inspection: action.payload.inspection,
          vehicle_inspections: action.payload.inspection
            .vehicle_inspections as ITrailerInspection[],
        },
        _loaded: true,
        _pending: false,
        _error: null,
      };
    case GET_TRAILER_INSPECTION_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };

    // GET_TRAILER_INSPECTION_DRAFT
    case GET_TRAILER_INSPECTION_DRAFT:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case GET_TRAILER_INSPECTION_DRAFT_SUCCESS:
      return {
        ...state,
        currentTrailerInspection: {
          inspection: action.payload.inspection,
          vehicle_inspections: action.payload.inspection.vehicle_inspections,
        },
        files: action.payload.inspection.vehicle_inspections[0]?.files || [],
        _loaded: true,
        _pending: false,
        _error: null,
      };
    case GET_TRAILER_INSPECTION_DRAFT_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };
    case DRAFT_TRAILER_INSPECTION:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };

    case DRAFT_TRAILER_INSPECTION_SUCCESS:
      return {
        ...state,
        currentTrailerInspection: {
          ...state.currentTrailerInspection,
          inspection: {
            ...state.currentTrailerInspection.inspection,
            inventories_data: action.payload.inventories_data,
          },
        },
      };
    case DRAFT_TRAILER_INSPECTION_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };
    case INITIALIZE_DRAFT_TRAILER_INSPECTION:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case INITIALIZE_DRAFT_TRAILER_INSPECTION_SUCCESS:
      return {
        ...state,
        currentTrailerInspection: {
          inspection: action.payload.inspection,
          vehicle_inspections: action.payload.inspection.vehicle_inspections,
        },
        _loaded: true,
        _pending: false,
        _error: null,
      };
    case INITIALIZE_DRAFT_TRAILER_INSPECTION_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };
    // CLEAR_CURRENT_TRAILER_INSPECTION
    case CLEAR_CURRENT_TRAILER_INSPECTION:
      return {
        ...state,
        currentTrailerInspection: {
          inspection: { ...defaultInspectionData },
          vehicle_inspections: [],
        },
        files: [],
        _error: null,
      };

    // UPLOAD_TRAILER_INSPECTION_PHOTO
    case UPLOAD_TRAILER_INSPECTION_PHOTO:
      return {
        ...state,
        files: [
          ...state.files,
          {
            ...action.payload.data,
            loading: true,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as any,
        ],
        _loaded: false,
        _pending: true,
        _error: null,
      };

    case UPLOAD_TRAILER_INSPECTION_PHOTO_SUCCESS: {
      const { payload, uploadIndex } = action;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const files = state.files.map((file: any) =>
        file.uploadIndex === uploadIndex
          ? {
              ...payload,
              comments: [],
              loading: false,
            }
          : file,
      );

      return {
        ...state,
        files,
        _loaded: true,
        _pending: false,
        _error: null,
      };
    }
    case UPLOAD_TRAILER_INSPECTION_PHOTO_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };

    // DELETE_TRAILER_INSPECTION_PHOTO
    case DELETE_TRAILER_INSPECTION_PHOTO:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case DELETE_TRAILER_INSPECTION_PHOTO_SUCCESS:
      return {
        ...state,
        files: state.files.filter(file => file.id !== action.payload),
        _loaded: true,
        _pending: false,
        _error: null,
      };
    case DELETE_TRAILER_INSPECTION_PHOTO_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };

    // ADD_TRAILER_INSPECTION_PHOTO_COMMENT
    case ADD_TRAILER_INSPECTION_PHOTO_COMMENT:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case ADD_TRAILER_INSPECTION_PHOTO_COMMENT_SUCCESS:
      return {
        ...state,
        files: state.files.map(file =>
          file.id === action.payload.fileId
            ? { ...file, comments: [...file.comments, action.payload.comment] }
            : file,
        ),
        _loaded: true,
        _pending: false,
        _error: null,
      };
    case ADD_TRAILER_INSPECTION_PHOTO_COMMENT_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };

    // UPDATE_TRAILER_INSPECTION_PHOTO_COMMENT
    case UPDATE_TRAILER_INSPECTION_PHOTO_COMMENT:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case UPDATE_TRAILER_INSPECTION_PHOTO_COMMENT_SUCCESS:
      return {
        ...state,
        files: state.files.map(file =>
          file.id === action.payload.fileId
            ? {
                ...file,
                comments: file.comments.map(comment =>
                  comment.id === action.payload.comment.id
                    ? action.payload.comment
                    : comment,
                ),
              }
            : file,
        ),
        _loaded: true,
        _pending: false,
        _error: null,
      };
    case UPDATE_TRAILER_INSPECTION_PHOTO_COMMENT_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };

    // DELETE_TRAILER_INSPECTION_PHOTO_COMMENT
    case DELETE_TRAILER_INSPECTION_PHOTO_COMMENT:
      return {
        ...state,
        _loaded: false,
        _pending: true,
        _error: null,
      };
    case DELETE_TRAILER_INSPECTION_PHOTO_COMMENT_SUCCESS:
      return {
        ...state,
        files: state.files.map(file =>
          file.id === action.payload.fileId
            ? {
                ...file,
                comments: file.comments.filter(
                  comment => comment.id !== action.payload.commentId,
                ),
              }
            : file,
        ),
        _loaded: true,
        _pending: false,
        _error: null,
      };
    case DELETE_TRAILER_INSPECTION_PHOTO_COMMENT_FAIL:
      return {
        ...state,
        _loaded: false,
        _pending: false,
        _error: action.payload,
      };

    // OTHER
    case UNMOUNT_TRAILER_INSPECTION_FORM:
      return {
        ...state,
        files: [],
        currentTrailerInspection: {
          inspection: { ...defaultInspectionData },
          vehicle_inspections: [],
        },
        _error: null,
      };

    case CLEAR_INSPECTION_ERROR:
      return {
        ...state,
        _error: null,
      };

    case LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default trailersInspectionReducers;
