import {
  PropsWithChildren,
  MouseEventHandler,
  forwardRef,
  useEffect,
  memo,
} from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import useTestAttrRef from 'hooks/useTestAttrRef';
import { isRefObject } from 'utils/helpers';

import styles from './Button.module.scss';

export type ButtonTheme =
  | 'blue'
  | 'white'
  | 'grey'
  | 'warn'
  | 'allow'
  | 'black'
  | 'transparent';

type Props = PropsWithChildren<{
  text?: string;
  theme?: ButtonTheme;
  className?: string;
  icon?: JSX.Element | string | null;
  onClick?: MouseEventHandler;
  name?: string;
  type?: 'button' | 'submit';
  disabled?: boolean;
  dataAttr?: string | number;
  circle?: boolean;
  withHover?: boolean;
  withFocus?: boolean;
  focusOnRender?: boolean;
  withTransition?: boolean;
  link?: {
    to: string;
  };
  tabIndex?: number;
  centralizeContent?: boolean;
  counter?: number;
}>;

/**
 * Button component
 * @param theme - one of ['blue', 'white', 'grey', 'warn', 'allow', 'black', 'transparent']
 * @param ref = ref to the button element
 * @param withFocus - show outline when button focused
 */
const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      onClick,
      icon,
      theme,
      text,
      children,
      className,
      name,
      type = 'button',
      disabled,
      link,
      dataAttr,
      circle,
      withHover = true,
      withFocus = true,
      focusOnRender,
      withTransition = true,
      tabIndex = 0,
      centralizeContent,
      counter,
    },
    ref,
  ) => {
    const btnRef = useTestAttrRef<HTMLButtonElement>(
      isRefObject(ref) ? ref : null,
      name || text || '',
    );

    useEffect(() => {
      if (focusOnRender && btnRef?.current) btnRef.current.focus();
    }, [focusOnRender, btnRef?.current]);

    return (
      <button
        ref={btnRef}
        name={name}
        type={type}
        onClick={onClick}
        disabled={disabled}
        data-attr={dataAttr}
        tabIndex={disabled ? -1 : tabIndex}
        className={clsx(
          styles.root,
          {
            [styles.blue]: theme === 'blue',
            [styles.white]: theme === 'white',
            [styles.grey]: theme === 'grey',
            [styles.warn]: theme === 'warn',
            [styles.black]: theme === 'black',
            [styles.allow]: theme === 'allow',
            [styles.transparent]: theme === 'transparent',
            [styles.circle]: circle,
            [styles.disabled]: disabled,
            [styles.withTransition]: withTransition,
            [styles.withHover]: withHover,
            [styles.withFocus]: withFocus,
            [styles.centralizeContent]: centralizeContent,
          },
          className,
        )}
      >
        {link?.to ? (
          <Link to={link.to} tabIndex={-1}>
            {icon}
            {text}
            {children}
          </Link>
        ) : (
          <>
            {icon}
            {text}
            {children}
          </>
        )}
        {counter && counter > 0 ? (
          <div className={styles.counter}>{counter}</div>
        ) : null}
      </button>
    );
  },
);

export default memo(Button);
