import { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
};

const IconUpload: FC<Props> = ({ className, color }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M2 14H14" stroke={color} strokeWidth="2" strokeLinecap="round" />
    <path
      d="M8 2V10M8 2L5 5M8 2L11 5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default IconUpload;
