import { lazy } from 'react';

import {
  PERMISSION_COMPANY_LIST,
  PERMISSION_ROLES_CREATE,
  PERMISSION_ROLES_LIST,
  PERMISSION_ROLES_UPDATE,
} from '../../constants/permissions';
import { IPermissionObject } from '../index';
import PrivateRoutes, { redirectRoute } from '../PrivateRoutes/PrivateRoutes';

const InternalCompanies = lazy(
  () => import('components/AdminPanel/InternalCompany/InternalCompany'),
);

const addCompanies = lazy(
  () => import('components/AdminPanel/InternalCompany/AddEditInternalCompany'),
);

const editCompanies = lazy(
  () => import('components/AdminPanel/InternalCompany/AddEditInternalCompany'),
);

const RolesAndPermissions = lazy(
  () => import('components/AdminPanel/RolesAndPermissions/RolesAndPermissions'),
);

const RoleSettings = lazy(
  () =>
    import(
      'components/AdminPanel/RolesAndPermissions/RolesSettings/RoleSettings'
    ),
);

const PermissionSet = lazy(
  () =>
    import(
      'components/AdminPanel/RolesAndPermissions/RolesSettings/PermissionForm/PermissionForm'
    ),
);

const getRedirectURL = (permissions?: string[]) => {
  if (permissions?.includes(PERMISSION_COMPANY_LIST))
    return '/admin_panel/internal_companies';
  if (permissions?.includes(PERMISSION_ROLES_LIST))
    return '/admin_panel/roles_and_permissions';
  return '/';
};

export const mainAdminPanelRoute = (permissions?: IPermissionObject[]) => {
  const targetName = 'Admin Panel';
  const targetObject = permissions?.find(obj => obj.name === targetName);
  return redirectRoute(
    '/admin_panel',
    getRedirectURL(targetObject ? targetObject.permissions : []),
  );
};

export const mainInternalCompaniesRoute = (
  <PrivateRoutes
    exact
    path="/admin_panel/internal_companies"
    component={InternalCompanies}
    permissionType={PERMISSION_COMPANY_LIST}
  />
);

export const addCompaniesRoute = (
  <PrivateRoutes
    exact
    path="/admin_panel/internal_companies/create_internal_company"
    component={addCompanies}
    permissionType={PERMISSION_COMPANY_LIST}
  />
);

export const editCompaniesRoute = (
  <PrivateRoutes
    exact
    path="/admin_panel/internal_companies/edit_internal_company/:id"
    component={editCompanies}
    permissionType={PERMISSION_COMPANY_LIST}
  />
);

export const mainRolesAndPermissionsRoute = (
  <PrivateRoutes
    exact
    path="/admin_panel/roles_and_permissions"
    component={RolesAndPermissions}
    permissionType={PERMISSION_ROLES_LIST}
  />
);
export const roleSettingsRoute = (
  <PrivateRoutes
    exact
    path="/admin_panel/roles_and_permissions/role_settings/:id"
    component={RoleSettings}
    permissionType={PERMISSION_ROLES_LIST}
  />
);

export const permissionFormRoute = (
  <PrivateRoutes
    exact
    path="/admin_panel/roles_and_permissions/role_settings/:id/:type"
    component={PermissionSet}
    permissionType={PERMISSION_ROLES_CREATE}
  />
);

export const editSinglePermissionFormRoute = (
  <PrivateRoutes
    exact
    path="/admin_panel/roles_and_permissions/role_settings/:id/:type/:companyId"
    component={PermissionSet}
    permissionType={PERMISSION_ROLES_UPDATE}
  />
);
