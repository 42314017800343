import { FC, memo } from 'react';

type Props = {
  className?: string;
  color?: string;
};

const IconRedirect: FC<Props> = ({
  className,
  color = 'var(--activeText)',
}) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M14 11V13C14 13.5523 13.5523 14 13 14H3C2.44772 14 2 13.5523 2 13V3C2 2.44772 2.44772 2 3 2H5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M9 1C8.44772 1 8 1.44772 8 2C8 2.55228 8.44772 3 9 3L9 1ZM14 2L15 2L15 1L14 1V2ZM6.82172 7.2652C6.4159 7.6398 6.39059 8.27246 6.7652 8.67828C7.1398 9.0841 7.77246 9.10941 8.17828 8.7348L6.82172 7.2652ZM13 6.99219C13 7.54447 13.4477 7.99219 14 7.99219C14.5523 7.99219 15 7.54447 15 6.99219L13 6.99219ZM9 3L14 3V1L9 1L9 3ZM13.3217 1.2652L6.82172 7.2652L8.17828 8.7348L14.6783 2.7348L13.3217 1.2652ZM13 2L13 6.99219L15 6.99219L15 2L13 2Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default memo(IconRedirect);
