import { FC } from 'react';

interface IOwnProps {
  className?: string;
}

const IconMoreOptions: FC<IOwnProps> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="12" cy="5" r="2" fill="#86898E" />
      <circle cx="12" cy="12" r="2" fill="#86898E" />
      <circle cx="12" cy="19" r="2" fill="#86898E" />
    </svg>
  );
};

export default IconMoreOptions;
