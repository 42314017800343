import { FC } from 'react';

interface IOwnProps {
  className?: string;
  color?: string;
  onClick?: () => void;
}

const IconDownload: FC<IOwnProps> = ({
  onClick,
  className,
  color = '#86898E',
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path d="M2 14H14" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path
        d="M8 2V10M8 10L5 7M8 10L11 7"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconDownload;
