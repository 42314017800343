import { FC } from 'react';

type OwnProps = {
  className?: string;
  fillColor?: string;
};

const IconToll: FC<OwnProps> = ({
  className,
  fillColor = 'var(--sideBarIcon)',
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13 1H3C2.44772 1 2 1.44772 2 2V15L5 13L8 15L11 13L14 15V2C14 1.44772 13.5523 1 13 1Z"
        stroke={fillColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M6 5H10"
        stroke={fillColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M6 8H8"
        stroke={fillColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconToll;
