import { FC } from 'react';

interface IOwnProps {
  className?: string;
}

const IconPin: FC<IOwnProps> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9 1L15 7"
        stroke="#2C6AF6"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M13.5 5.5L8.5 13.5" stroke="#2C6AF6" strokeWidth="2" />
      <path d="M10.5 2.5L2.5 7.5" stroke="#2C6AF6" strokeWidth="2" />
      <path
        d="M1 6L10 15"
        stroke="#2C6AF6"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M5 11L1 15"
        stroke="#2C6AF6"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconPin;
